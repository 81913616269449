import {
    SkillsetConfiguration,
    SkillsetDescriptor,
    SkillsetSettingType,
    useGetSkillsetCustomIcon,
} from '@/api/skills';
import {useTranslation} from 'react-i18next';

// Get the input type of the specified skillset setting to determine if it should be a password field
export const getSettingInputType = (configSettingType: SkillsetSettingType) => {
    if (configSettingType == SkillsetSettingType.SecretString) {
        return 'password';
    }
    return 'text';
};

// Search list of existing configurations to get the existing value for the specified skillset setting
export const getConfigDefaultValue = (
    configName: string,
    existingConfigs: SkillsetConfiguration | null,
    authType: string,
) => {
    for (const [key, value] of Object.entries(existingConfigs?.settings ?? [])) {
        if (key === configName) {
            return value;
        }
    }
    for (const [key, value] of Object.entries(existingConfigs?.authConfigurations ?? [])) {
        if (key == authType) {
            for (const [key1, value1] of Object.entries(value.settings ?? [])) {
                if (key1 === configName) {
                    return value1;
                }
            }
        }
    }

    return null;
};
