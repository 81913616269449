import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export const useClasses = makeStyles({
    wrapper: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    smallScreenWrapper: {
        position: 'fixed',
        bottom: 0, // Adjust this value as needed
        width: '100%',
        display: 'flex',
        height: 'auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    editMode: {
        position: 'relative',
        alignItems: 'center',
    },
    skill: {
        // position: 'absolute',
        // top: '16px',
        // left: '16px',
        // width: '20px',
        // '@media (max-width: 479px)': {
        //     bottom: '10px',
        // },
    },
    iconGradient: {
        color: tokens.colorBrandForeground1,
    },
    iconDisabled: {
        color: tokens.colorNeutralForegroundDisabled,
    },
    toolbar: {
        position: 'absolute',
        top: '16px',
        right: '16px',
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.padding(0),
    },
    submit: {
        // position: 'absolute',
        // top: '16px',
        // right: '16px',
        // width: '20px',
    },
    hideSubmit: {
        '@media (max-width: 479px)': {
            display: 'none',
        },
    },
    hoverIndicator: {
        height: '2px',
        backgroundColor: tokens.colorBrandForeground1,
        width: '20%',
        position: 'absolute',
        bottom: 0,
        boxShadow: '0px 0px 5px rgba(88, 211, 219, 0.7)',
    },

    editModeTextarea: {
        backgroundColor: tokens.colorNeutralBackground4,
        ...shorthands.padding('20px', '20px'),
        marginTop: '0px',
    },
    defaultTextarea: {
        ...shorthands.borderRadius('6px'),
        ...shorthands.padding('20px', '136px', '20px', '20px'),
        ...shorthands.borderTop('0px', 'none', 'none'),
        ...shorthands.borderLeft('0px', 'none', 'none'),
        ...shorthands.borderRight('0px', 'none', 'none'),
        ...shorthands.borderBottom('0px', 'none', 'none'),
        minHeight: tokens.lineHeightBase400,
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground3,
        '&:hover': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:focus': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:focus:hover': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:focus-within': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:active': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '> textarea': {
            ...shorthands.padding('0px'),
            fontSize: tokens.fontSizeBase300,
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: `${tokens.colorNeutralBackground1} ${tokens.colorTransparentBackground}`,
            '&::-webkit-scrollbar': {
                width: tokens.spacingHorizontalS,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: tokens.colorTransparentBackground,
            },

            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorNeutralBackground1,
                ...shorthands.borderRadius('20px'),
            },
        },
        '&::placeholder': {
            color: tokens.colorNeutralForeground4,
        },
    },
    mobileTextarea: {
        ...shorthands.borderRadius('0px'),
        ...shorthands.padding('10px', '5px', '10px', '10px'),
        ...shorthands.borderTop('0px', 'none', 'none'),
        ...shorthands.borderLeft('0px', 'none', 'none'),
        ...shorthands.borderRight('0px', 'none', 'none'),
        ...shorthands.borderBottom('0px', 'none', 'none'),
        minHeight: tokens.lineHeightBase100,
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground3,
        '&:hover': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:focus': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:focus:hover': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:focus-within': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '&:active': {
            ...shorthands.borderTop('0px', 'none', 'none'),
            ...shorthands.borderLeft('0px', 'none', 'none'),
            ...shorthands.borderRight('0px', 'none', 'none'),
            ...shorthands.borderBottom('0px', 'none', 'none'),
        },
        '> textarea': {
            ...shorthands.padding('0px'),
            fontSize: tokens.fontSizeBase300,
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: `${tokens.colorNeutralBackground1} ${tokens.colorTransparentBackground}`,
            maxHeight: `calc(3 * 1.5 * ${tokens.fontSizeBase300})`,
            '&::-webkit-scrollbar': {
                width: tokens.spacingHorizontalS,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: tokens.colorTransparentBackground,
            },

            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorNeutralBackground1,
                ...shorthands.borderRadius('20px'),
            },
        },
        '&::placeholder': {
            color: tokens.colorNeutralForeground4,
        },
    },
    tallTextarea: {
        minHeight: '80px',
    },
});
