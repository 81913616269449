import React from 'react';
import {Label} from '@fluentui/react-components';
import useClasses from './MedeinaErrorLabel.styles';
import {ErrorIcon} from '../icons';
import {useViewportSize} from '../Grid';

export interface MedeinaErrorLabelProps {
    errorContent: string;
    needsResponsiveStyles?: boolean;
    id?: string;
}

/**
 * Wraps the ErrorLabel component with accessibility attributes.
 * @param props {errorContent}
 * @returns React Node
 */
export const MedeinaErrorLabel = ({
    errorContent,
    needsResponsiveStyles,
    id,
}: MedeinaErrorLabelProps) => {
    const classes = useClasses();
    const {sm: isSmallScreen} = useViewportSize();

    const labelSize = Boolean(needsResponsiveStyles)
        ? isSmallScreen
            ? 'small'
            : 'medium'
        : 'medium';

    const idProp = id ? {id} : {};

    return (
        <Label {...idProp} className={classes.warningLabel} size={labelSize}>
            <div aria-live="polite">
                <ErrorIcon className={classes.errorIcon} filled />
                {errorContent}
            </div>
        </Label>
    );
};
