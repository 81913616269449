import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import {ExpandIcon, DismissIcon} from '../../../icons';
import Table from '../Table';
import useClasses from './CompactTableDialog.styles';
import {useEvaluationContext} from '@/components/sections/evaluations/EvaluationContext';
import {useTableContext} from '../TableContext';
import ExportToExcelButton from '../ExportToExcelButton';
import {useMarkdownContext} from '../../MarkdownContext';

const CompactTableDialog = ({...props}) => {
    const dialogStylesNew = useClasses();
    const {sessionId, evaluationId, promptId} = useEvaluationContext();
    const {linePositions} = useTableContext();
    const {allowTableDataExport} = useMarkdownContext();

    return (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    appearance="subtle"
                    icon={<ExpandIcon />}
                    aria-label="View expanded table"
                ></Button>
            </DialogTrigger>
            <DialogSurface
                className={dialogStylesNew.root}
                backdrop={<div className={dialogStylesNew.backdrop} aria-hidden="true" />}
            >
                <DialogBody className={dialogStylesNew.bodyStyles}>
                    <div className={dialogStylesNew.title}>
                        <div className={dialogStylesNew.dismissSection}>
                            {!!allowTableDataExport && (
                                <ExportToExcelButton
                                    sessionId={sessionId}
                                    promptId={promptId}
                                    evaluationId={evaluationId}
                                    linePositions={linePositions}
                                    className={dialogStylesNew.excelButtonStyles}
                                ></ExportToExcelButton>
                            )}
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    icon={<DismissIcon />}
                                    aria-label="Close expanded table"
                                ></Button>
                            </DialogTrigger>
                        </div>
                    </div>
                    <DialogContent className={dialogStylesNew.contentStyles}>
                        <Table
                            tableType="modal"
                            {...props}
                            className={dialogStylesNew.contentTable}
                        />
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
export default CompactTableDialog;
