import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
export default makeStyles({
    pillcontainer: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin(0, 0, tokens.spacingVerticalS, tokens.spacingVerticalS),
        flexWrap: 'wrap',
        maxWidth: '692px',
        width: '100vw',
        justifyContent: 'flex-start',
        maxHeight: '22vh',
        alignItems: 'flex-start',
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '80vw',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '80vw',
        },
        '@media (max-width: 479px)': {
            width: '100%',
            height: '35vh',
        },
        '@media (max-width: 320px)': {
            ...shorthands.margin(0),
            maxWidth: `100vw !important`,
        },
    },
    sparkleIcon: {
        color: tokens.colorBrandForeground1,
        fontSize: tokens.fontSizeBase500,
        backgroundColor: 'linear-gradient(180deg, #32C8D1 0%, #1E90C1 100%)',
    },
    pillContainer: {
        display: 'inline-flex',
        maxWidth: '90%',
    },
    buttonContentStyles: {
        ...shorthands.overflow('visible'),
        whiteSpace: 'normal',
        textAlign: 'left',
    },
    pill: {
        height: 'auto',
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,
        color: tokens.colorNeutralForeground2,
        marginRight: tokens.spacingHorizontalS,
        marginBottom: tokens.spacingVerticalS,
        boxSizing: 'border-box',
        backgroundColor: tokens.colorNeutralBackground2,
        ...shorthands.gap('4px'),
        ...shorthands.padding('0px 16px 0px 8px'),
        ...shorthands.border('1px', 'solid', '#1B484C'),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    },
    lightTheme: {
        ...shorthands.border('1px', 'solid', '#74CCD4'),
        '&:hover': {
            backgroundColor: '#99D9DE',
            ...shorthands.border('1px', 'solid', '#04B2BE'),
            color: '#1C565B',
        },
        '&:active': {
            backgroundColor: `#47C0CA !important`,
            ...shorthands.border('1px', 'solid', `#1C737B !important`),
            color: `#1D656B !important`,
        },
        '&:focus': {
            ...shorthands.border('2px', 'solid', '#000000'),
            ...shorthands.borderRadius(tokens.borderRadiusXLarge),
            color: '#3C4449',
        },
    },
    darkTheme: {
        '&:hover': {
            backgroundColor: '#183A3D',
            ...shorthands.border('1px', 'solid', '#1B838B'),
            color: '#47C0CA',
        },
        '&:active': {
            backgroundColor: `#020404 !important`,
            ...shorthands.border('1px', 'solid', `#1D656B !important`),
            color: `#1B838B !important`,
        },
        '&:focus': {
            ...shorthands.border('2px', 'solid', '#FFFFFF'),
            ...shorthands.borderRadius(tokens.borderRadiusXLarge),
            color: '#D2D7D9',
        },
    },
    refreshIcon: {
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeBase500,
    },
    refreshButton: {
        height: '32px',
        width: '40px',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground2,
        boxSizing: 'border-box',
        marginRight: tokens.spacingHorizontalMNudge,
        ...shorthands.gap('4px'),
        ...shorthands.padding('0px 8px'),
        ...shorthands.border('1px', 'solid', '#1B484C'),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    },
});
