import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PatchCapacityRequest, PatchCapacityResponse} from '../capacities/capacities.types';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';

export default function usePatchCapacity() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (request: PatchCapacityRequest) => {
            return await customFetch<PatchCapacityResponse>(
                `${MedeinaVariables.ArmUri}/subscriptions/${request.subscriptionId}/resourceGroups/${request.resourceGroupName}/providers/Microsoft.SecurityCopilot/capacities/${request.capacityName}?api-version=2023-12-01-preview`,
                {
                    method: 'PATCH',
                    scopes: RESOURCE_SCOPES.ARM,
                    body: {
                        properties: request.properties,
                    },
                },
            );
        },
        onSuccess: (_, variables) => {
            // Invalidate the cache
            queryClient.invalidateQueries({queryKey: ['capacities', variables.capacityName]});
        },
    });
}
