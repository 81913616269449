import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    image: {
        marginBottom: '40px',
    },
    cycleWrapper: {
        ...shorthands.margin('0', 'auto'),
        textAlign: 'center',
    },
    wordWrapper: {
        // flexGrow:1,
        // flexShrink: 0,
    },
    blinkingCursor: {
        // flexGrow: 0,
        width: '4px',
        display: 'inline-block',
    },
    rightButton: {
        right: '30px',
    },
    leftButton: {
        left: '30px',
    },
});
