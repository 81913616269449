'use client';

import React from 'react';
import {
    Button,
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
} from '@fluentui/react-components';
import useClasses from './PrototypeWarningBanner.styles';
import {
    clearPrototypePreference,
    includePrototypeRoutingHeader,
    setPrototypePreference,
} from '@/api/api';
import {CookieConsentContext} from '@/components/ui/CookieConsentProvider/CookieConsentProvider';

function PrototypeWarningBanner() {
    const classes = useClasses();
    const siteConsent = React.useContext(CookieConsentContext);

    const [isPrototypeRouting, setIsPrototypeRouting] = React.useState(false);

    React.useEffect(() => {
        setIsPrototypeRouting(includePrototypeRoutingHeader ?? false);
    }, []);

    // TODO: This is a hack to convert a feature flag EnablePrototypeRouting into a session storage value which will then be used to request access to the prototype environment. -kateoztunc
    React.useEffect(() => {
        if (includePrototypeRoutingHeader) {
            setPrototypePreference();
        }
    }, [siteConsent]);

    const clearPrototypeCookieAndRefresh = () => {
        clearPrototypePreference();
        window.location.href = 'https://' + window.location.host;
    };

    return (
        <>
            {isPrototypeRouting && (
                <MessageBar intent="warning">
                    <MessageBarBody>
                        <MessageBarTitle>Prototype warning</MessageBarTitle>
                        You are currently on a prototype environment.{' '}
                    </MessageBarBody>
                    <MessageBarActions>
                        <Button
                            aria-label="return to production"
                            onClick={clearPrototypeCookieAndRefresh}
                        >
                            Return to production
                        </Button>
                    </MessageBarActions>
                </MessageBar>
            )}
        </>
    );
}

export default PrototypeWarningBanner;
