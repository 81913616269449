import {useEffect, useState} from 'react';
import {ErrorMessageBarProps} from './AddRoleMembers';

export enum AddRoleMembersMessages {
    NO_MESSAGE = 'noMessage',
    GENERIC = 'generic',
    MEMBER_CANNOT_HAVE_MORE_ROLES = 'userCannotHaveMoreRoles',
    ERROR_WHILE_UPDATING = 'errorWhileUpdating',
    CANNOT_ADD_EVERYONE = 'cannotAddEveryone',
}

const ADD_ROLE_MESSAGE_MAP: Record<AddRoleMembersMessages, Partial<ErrorMessageBarProps>> = {
    [AddRoleMembersMessages.GENERIC]: {
        title: 'RoleAssignment.AddRoleMembers.GenericError.Title',
        message: 'RoleAssignment.AddRoleMembers.GenericError.Message',
        messageType: 'error',
    },
    [AddRoleMembersMessages.MEMBER_CANNOT_HAVE_MORE_ROLES]: {
        title: 'RoleAssignment.AddRoleMembers.Warning.Title',
        message: 'RoleAssignment.AddRoleMembers.Warning.Message',
        messageType: 'warning',
    },
    [AddRoleMembersMessages.ERROR_WHILE_UPDATING]: {
        title: 'RoleAssignment.AddRoleMembers.UpdateError.Title',
        messageType: 'error',
    },
    [AddRoleMembersMessages.CANNOT_ADD_EVERYONE]: {
        title: 'RoleAssignment.AddRoleMembers.CannotAddEveryone.Title',
        messageType: 'error',
    },
    [AddRoleMembersMessages.NO_MESSAGE]: {},
};

export function useAddRoleMemberErrorHanding(existingError: AddRoleMembersMessages) {
    const [errorMessage, setErrorMessage] = useState<ErrorMessageBarProps>();

    // This useEffect is used to update the error message when the error state changes from any module
    // that generates an error
    useEffect(() => {
        if (existingError === AddRoleMembersMessages.NO_MESSAGE) {
            resetError();
            return;
        }

        !!existingError && generateErrorMessage(existingError as AddRoleMembersMessages);
    }, [existingError]);

    const generateErrorMessage = (
        messageType: AddRoleMembersMessages,
        customization: Partial<ErrorMessageBarProps> = {},
    ) => {
        const message = ADD_ROLE_MESSAGE_MAP[messageType] as ErrorMessageBarProps;
        setErrorMessage({...message, ...customization});
    };

    const resetError = () => {
        setErrorMessage(
            ADD_ROLE_MESSAGE_MAP[AddRoleMembersMessages.NO_MESSAGE] as ErrorMessageBarProps,
        );
    };

    return {
        errorMessage,
        generateErrorMessage,
        resetError,
    };
}
