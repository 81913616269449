import useClasses from './About.styles';
import MedeinaVariables from '@/util/variables';
import {Text} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

export default function About() {
    const classes = useClasses();
    const {t} = useTranslation();
    return (
        <div className={classes.root}>
            <h3 className={classes.firstHeader}>{t('SettingsAppInfo')}</h3>
            <div>
                <Text className={classes.bodyTextBold}>{t('SettingsAppVersion')}: </Text>
                <Text className={classes.bodyText}>{MedeinaVariables.Version}</Text>
            </div>
        </div>
    );
}
