import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    resourceLinksContainer: {
        lineHeight: tokens.lineHeightBase400,
        fontSize: '16px',
        fontWeight: tokens.fontWeightRegular,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        textAlign: 'left',
        marginBottom: '40px',
        '& > div:first-of-type': {
            marginBottom: tokens.spacingVerticalXS,
            display: 'flex',
            flexDirection: 'row',
        },
        backgroundColor: tokens.colorNeutralBackground4,
        ...shorthands.padding('28px'),
    },
    shareLinkBlock: {
        ...shorthands.padding('28px', tokens.spacingHorizontalNone),
        ...shorthands.gap(tokens.spacingVerticalMNudge),
    },
    copyIcon: {
        height: '20px',
        width: '16px',
        marginRight: tokens.spacingVerticalXS,
        marginTop: tokens.spacingHorizontalXXS,

        '@media (max-width: 479px)': {
            height: '16px',
            width: '12px',
            marginRight: tokens.spacingVerticalXS,
            marginTop: tokens.spacingHorizontalXXS,
        },
    },
    titleSpacing: {
        paddingBottom: tokens.spacingVerticalXXL,
    },
    toast: {
        ...shorthands.margin('auto'),
        width: '121px',
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        ...shorthands.padding(tokens.spacingVerticalM),
        backgroundColor: tokens.colorNeutralBackground4,
    },
    resourceLinkItem: {
        marginBottom: tokens.spacingVerticalXS,
        display: 'flex',
        flexDirection: 'row',

        '& span:first-of-type': {
            ...shorthands.flex(7, 1, '0%'),
        },
        '& span:nth-of-type(2)': {
            ...shorthands.flex(13, 1, '0%'),
        },

        '@media (min-width: 480px) and (max-width: 639px)': {
            '& span:first-of-type': {
                ...shorthands.flex(5, 1, '0%'),
            },
            '& span:nth-of-type(2)': {
                ...shorthands.flex(5, 1, '0%'),
            },
        },

        '@media (max-width: 479px)': {
            fontSize: tokens.fontSizeBase200,
            fontWeight: tokens.fontWeightRegular,
            lineHeight: tokens.lineHeightBase200,

            '& span:first-of-type': {
                ...shorthands.flex(5, 1, '0%'),
            },
            '& span:nth-of-type(2)': {
                ...shorthands.flex(5, 1, '0%'),
            },
        },
    },
});
