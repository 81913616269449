import React, {useState, useCallback, useMemo, useRef, useEffect, useLayoutEffect} from 'react';
import {
    DialogSurface,
    DialogBody,
    DialogContent,
    TabList,
    Tab,
    SelectTabEvent,
    SelectTabData,
    useFocusFinders,
    mergeClasses,
} from '@fluentui/react-components';
import useClasses from './Dialog.styles';
import {useViewportSize} from '../Grid';
import TabbedDialogTitle from './DialogTitle';
import {TabbedDialogProps} from './Dialog.types';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';

// Settings specific

/** A helper component that manages tabbed views for Dialogs and more specifically handles reflow
 * Usage: TabList configuration, view configuration
 * The component needs the configuration of Tab lists and the corresponding views that they should show
 * Each view should independently manage the full contents of DialogSurface: DialogTitle, DialogBody, DialogContent, etc
 * The configuration should have a default tab to select if applicable
 * On small screens we will show the tab list first for the user to select a view to go into, selecting changes the view
 * A backbutton on the DialogTitle should be used to return to the Tab selection screen
 */
function TabbedDialog(props: TabbedDialogProps) {
    const {
        onTabChange,
        onClose,
        tabListProps = {},
        tabConfiguration,
        homeTitle,
        className,
        dialogTitleProps,
    } = props;
    const {backLabel, closeLabel, icon} = dialogTitleProps || {};
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {findFirstFocusable} = useFocusFinders();
    const dialogBodyRef = useRef<HTMLDivElement>(null);
    const dialogContentRef = useRef<HTMLDivElement>(null);
    const dialogHeaderRef = useRef<HTMLDivElement>(null);

    // manage the screen ready state to deal with reflow buttons appearing and disappearing
    // as the screen size changes and the component mounts
    const [screenReady, setScreenReady] = useState(false);

    const firstTab = tabConfiguration?.[0]?.value;
    const defaultSelectedTab = props.tabListProps?.defaultSelectedValue || firstTab;
    const [selectedView, setSelectedView] = useState<string>(firstTab || '');

    //manage reflow
    const {sm: isSmallScreen} = useViewportSize();

    // Show the homeTitle if we are not in a view
    const showHomeTitle = isSmallScreen && !selectedView;

    // Show tab list if we are not on a small screen
    // show tab list if we are on a small screen and we are not in a view
    const showTabList = !isSmallScreen || (isSmallScreen && !selectedView);

    const handleTabSelect = useCallback(
        (_event: SelectTabEvent, data: SelectTabData) => {
            const nextTab = data.value as string;
            if (onTabChange) {
                onTabChange(nextTab);
            }
            setSelectedView(nextTab);
        },
        [onTabChange],
    );

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const handleBack = useCallback(() => {
        setSelectedView('');
    }, []);

    const activeTab = useMemo(() => {
        return tabConfiguration?.find((tab) => tab.value === selectedView);
    }, [selectedView, tabConfiguration]);

    const ActiveView = activeTab?.view || null;

    const fallbackFocus = useCallback(
        (nextElement: string) => {
            // a switch of potential focusable elements
            // if the dialog content is not focusable we will focus on the close button
            let element: HTMLElement | null | undefined;
            switch (nextElement) {
                case 'dialogContent':
                    element = findFirstFocusable(dialogContentRef?.current as HTMLElement);
                    break;
                case 'dialogBody':
                    element = findFirstFocusable(dialogBodyRef?.current as HTMLElement);
                    break;
                case 'dialogHeader':
                default:
                    element = findFirstFocusable(dialogHeaderRef?.current as HTMLElement);
            }
            // check if we have a focusable element
            if (element) {
                element.focus();
                // check that the element now has focus or else start the fallback focus behavior
                if (document.activeElement !== element) {
                    fallbackFocus(nextElement);
                }
            } else {
                // if we don't call the fallback again with the next element depending on the order
                if (nextElement === 'dialogContent') {
                    fallbackFocus('dialogBody');
                } else if (nextElement === 'dialogBody') {
                    fallbackFocus('dialogHeader');
                }
            }
        },
        [findFirstFocusable],
    );

    const updateFocus = useCallback(() => {
        // check if the active element is inside the dialog content
        const activeElement = document.activeElement;
        if (dialogContentRef.current?.contains(activeElement)) {
            // if the active element is inside the dialog content we don't want to change focus
            return;
        } else if (dialogBodyRef.current?.contains(activeElement)) {
            // if the active element is inside the dialog body we don't want to change focus
            return;
        }
        // if we haven't returned by this point it means the active element is not inside the dialog
        // we will attempt to focus on the dialog body first
        fallbackFocus('dialogContent');

        if (selectedView) {
            if (dialogContentRef.current) {
                fallbackFocus('dialogContent');
            }
        } else {
            if (dialogBodyRef.current) {
                fallbackFocus('dialogBody');
            }
        }
    }, [selectedView, fallbackFocus]);

    // For accessibility, we need to focus on the first focusable element in the dialog body or tab list
    // depending on the screen size and the selected view
    // We need to do this every time the selected view changes
    useEffect(() => {
        if (!screenReady) {
            return;
        }
        updateFocus();
        // when the view changes also communicate to onTabChange if it exists
        if (onTabChange) {
            onTabChange(selectedView);
        }

        // only update when the selected view changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedView, screenReady]);

    useLayoutEffect(() => {
        // This will be executed after the component mounts, indicating the initial render is complete
        setScreenReady(true);
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <>
            <TabbedDialogTitle
                title={showHomeTitle ? homeTitle : activeTab?.title || homeTitle}
                showBackButton={Boolean(selectedView && isSmallScreen)}
                onBackButtonClick={handleBack}
                onCloseButtonClick={handleClose}
                headerRef={dialogHeaderRef}
                backLabel={backLabel}
                closeLabel={closeLabel}
                icon={icon}
            />
            <DialogBody className={classes.tabbedDialogBody} ref={dialogBodyRef}>
                {showTabList && (
                    <TabList
                        {...tabListProps}
                        className={classes.tabList}
                        selectedValue={selectedView}
                        onTabSelect={handleTabSelect}
                        vertical
                    >
                        {tabConfiguration?.map((tab, i) => {
                            const {value, title, tabTitle, tabProps = {}, testId} = tab;
                            const tabTestId = testId ? testId : `tabbed-dialog-tab-${value}`;
                            return (
                                <Tab {...tabProps} value={value} key={i} data-testid={tabTestId}>
                                    {tabTitle || title}
                                </Tab>
                            );
                        })}
                    </TabList>
                )}
                {selectedView && (
                    <DialogContent
                        ref={dialogContentRef}
                        data-testid={`tabbed-dialog-content-${homeTitle.replace(' ', '-')}`}
                        className={mergeClasses(
                            classes.tabbedContent,
                            scrollClasses.colorNeutralBackground2,
                            className && className,
                        )}
                    >
                        {Boolean(ActiveView) && ActiveView}
                    </DialogContent>
                )}
            </DialogBody>
        </>
    );
}

export default TabbedDialog;
