import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {SkillsetAuthDescriptor} from '@/api/skills/skills.types';

export default function useGetSkillsetSpecificAuthSettings(skillsetName: string) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['skillsets', 'authSettings', skillsetName],
        queryFn: async () => {
            const response = await customFetch<SkillsetAuthDescriptor[]>(
                `/skillsets/${skillsetName}/authSettings`,
                {
                    method: 'GET',
                },
            );
            return response;
        },
    });
}
