import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import {useMemo} from 'react';

/*
This hook is necessary to determine whether a user needs to be redirected to the Forbidden page POST authorization
1. If the user is a non-admin and V2 requirements have not been met for the tenant, then we deny login.
2. For Admins we inherently let the user log in
*/
export default function useDetermineDenyPostAuthAccess() {
    const {
        data: userAuthInfo,
        isSuccess: isUserAuthInfoSuccess,
        isError: isUserAuthInfoError,
    } = useGetUserInfo();

    const {
        data: tenantInfo,
        isSuccess: tenantInfoSuccess,
        isError: tenantInfoError,
    } = useGetTenantInfo();

    const isInvalidUser = useMemo<boolean>(() => {
        // This is a guard clause to prevent the hook from returning a false positive if the data is not yet available
        if (tenantInfo == undefined || userAuthInfo == undefined) {
            return false;
        }

        // This is a guard clause to prevent the hook from returning a false positive if the data is available but the tenant
        // amd user auth info is in an error state. The Forbidden error should not be shown in this case
        if (isUserAuthInfoError || tenantInfoError) {
            return false;
        }

        // If the user is identified to be an admin, we inherently let them log in
        if (isUserAuthInfoSuccess && tenantInfoSuccess && !!userAuthInfo?.isAdmin) {
            return false;
        }

        // If the user is not an admin and the tenant does not meet the V2 requirements, we deny login
        if (
            isUserAuthInfoSuccess &&
            tenantInfoSuccess &&
            !userAuthInfo?.isAdmin &&
            !tenantInfo?.freRequirements?.freRequirementsMet
        ) {
            return true;
        }

        return false;
    }, [
        tenantInfo,
        userAuthInfo,
        isUserAuthInfoError,
        tenantInfoError,
        isUserAuthInfoSuccess,
        tenantInfoSuccess,
    ]);

    return {
        isInvalidUser,
    };
}
