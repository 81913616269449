import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Spinner,
} from '@fluentui/react-components';
import {useEffect, useMemo, useState} from 'react';
import useClasses from './DeleteCapacityConfirmation.styles';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useUpdateWorkspace} from '@/api/workspaces';
import {DismissIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS, CONTROL_LABELS, STATIC_CONTENT} from '../ChangeCapacity.constants';
import {useDeleteCapacity} from '@/api/capacities';

interface DeleteCapacityConfirmationProps {
    isDeleteCapacityConfirmDialogOpen: boolean;
    onDeleteCapacityConfirmDialogClose: () => void;
}

export default function DeleteCapacityConfirmation(props: DeleteCapacityConfirmationProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const {workspaceName, capacityId, capacityName} = useWorkspaceState();
    const {update: providerUpdate} = useWorkspaceState();
    const {isDeleteCapacityConfirmDialogOpen, onDeleteCapacityConfirmDialogClose} = props;
    const tenantId = getCurrentTenantId();
    const {getSubscriptionId, getResourceGroupName} = useAzureCapacityDependencyLinkParts({
        capacityId: capacityId || '',
        tenantId,
    });

    const {subscriptionId, resourceGroupName} = useMemo(() => {
        return {
            subscriptionId: getSubscriptionId(),
            resourceGroupName: getResourceGroupName(),
        };
    }, [getSubscriptionId, getResourceGroupName]);

    const {
        mutate: delinkCapacityFromWorkspace,
        isSuccess: isDelinkCapacitySuccess,
        isError: isDelinkCapacityError,
        isLoading: isDelinkCapacityInProgress,
    } = useUpdateWorkspace();

    const {
        mutate: deleteCapacityFromARM,
        isSuccess: isDeleteCapacitySuccess,
        isError: isDeleteCapacityError,
        isLoading: isDeleteCapacityInProgress,
    } = useDeleteCapacity();

    //Orchestrate deletion of capacity after user clicks on 'Yes' --> First delink and then delete from Azure.
    const deleteCapacity = () => {
        if (workspaceName && capacityName) {
            delinkCapacityFromWorkspace(
                {
                    name: workspaceName,
                    capacity: null,
                },
                {
                    onSuccess: (data) => {
                        deleteCapacityFromARM(
                            {
                                subscriptionId,
                                resourceGroupName,
                                capacityName,
                            },
                            {
                                onSuccess: async () => {
                                    await providerUpdate();
                                },
                                onSettled: () => {
                                    onDeleteCapacityConfirmDialogClose();
                                },
                            },
                        );
                    },
                    onError: () => {
                        onDeleteCapacityConfirmDialogClose();
                    },
                },
            );
        }
    };

    return (
        <>
            <Dialog
                open={isDeleteCapacityConfirmDialogOpen}
                onOpenChange={onDeleteCapacityConfirmDialogClose}
            >
                <DialogSurface>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            data-test-id="delete-capacity-dialog-close-button"
                            className={classes.closeButton}
                            appearance="transparent"
                            aria-label={t(ARIA_LABELS.CLOSE_DELETE_CAPACITY_DIALOG)}
                        >
                            <DismissIcon className={classes.dismissIcon} />
                        </Button>
                    </DialogTrigger>
                    <DialogBody>
                        <DialogTitle>
                            {t(STATIC_CONTENT.DELETE_SECURITY_FABRIC_CAPACITY_HEADING)}
                        </DialogTitle>
                        <DialogContent className={classes.dialogContentSection}>
                            {t(STATIC_CONTENT.DELETE_CAPACITY_CONFIRMATION_MESSAGE, {capacityName})}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-test-id="confirm-delete-capacity"
                                appearance="primary"
                                onClick={deleteCapacity}
                                disabled={isDelinkCapacityInProgress || isDeleteCapacityInProgress}
                            >
                                {isDelinkCapacityInProgress || isDeleteCapacityInProgress ? (
                                    <Spinner size="tiny" />
                                ) : (
                                    t(CONTROL_LABELS.YES)
                                )}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {isDelinkCapacitySuccess && isDeleteCapacitySuccess && (
                <ToastNotification
                    intent={Intent.Success}
                    message={t(STATIC_CONTENT.DELETE_CAPACITY_SUCCESS)}
                    sessionId={''}
                />
            )}
            {isDeleteCapacityError || isDelinkCapacityError ? (
                <ToastNotification
                    intent={Intent.Error}
                    message={t(STATIC_CONTENT.DELETE_CAPACITY_ERROR)}
                    sessionId={''}
                />
            ) : null}
        </>
    );
}
