import {PromptSuggestionDescriptor} from '@/api/prompts/suggestedPrompts.types';
import {Button, Input, ToolbarDivider} from '@fluentui/react-components';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DismissIcon, SendIcon} from '../icons';
import useClasses from './PromptBarSuggestedPromptForm.styles';
import AcrylicBase from '../AcrylicBase';

type PromptBarSuggestedPromptFormProps = {
    suggestedPrompt: PromptSuggestionDescriptor;
    onCancel?: () => void;
    onSuggestedPromptChange?: (value: PromptSuggestionDescriptor) => void;
    onSubmit?: () => void;
};

export default function PromptBarSuggestedPromptForm({
    suggestedPrompt,
    onCancel,
    onSuggestedPromptChange,
    onSubmit,
}: PromptBarSuggestedPromptFormProps) {
    const classes = useClasses();
    const contentRef = useRef<HTMLDivElement>(null);
    const firstInputRef = useRef<HTMLInputElement>(null);

    const [editableContent, setEditableContent] = useState(suggestedPrompt?.prompt);

    useEffect(() => {
        setEditableContent(suggestedPrompt?.prompt);
    }, [suggestedPrompt]);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [suggestedPrompt]);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
            const newContent = editableContent.split(/(<[^>]+>)/g);
            const newValue = event.target.value.trim() === '' ? ' ' : event.target.value;
            newContent[index] = `<${newValue}>`;
            const joinedContent = newContent.join('');
            setEditableContent(joinedContent);
            const updatedSuggestedPrompt = {...suggestedPrompt, prompt: joinedContent};
            onSuggestedPromptChange?.(updatedSuggestedPrompt);
        },
        [editableContent],
    );

    const renderEditablePrompt = () => {
        let firstInputFound = false;
        const parts = editableContent.split(/(<[^>]+>)/g);
        return parts.map((part, index) => {
            if (part.startsWith('<') && part.endsWith('>')) {
                const inputRef = firstInputFound ? null : firstInputRef;
                firstInputFound = true;
                return (
                    <Input
                        key={index}
                        className={classes.editable}
                        onChange={(event) => handleInputChange(event, index)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSubmit?.();
                            }
                        }}
                        placeholder={part.slice(1, -1).toUpperCase()}
                        ref={inputRef}
                    />
                );
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    return (
        <div>
            <AcrylicBase shadow="small" className={classes.root}>
                <div className={classes.promptBar}>
                    <div className={classes.textContainer} ref={contentRef}>
                        {renderEditablePrompt()}
                    </div>
                    <div className={classes.icons}>
                        <Button
                            appearance="transparent"
                            shape="rounded"
                            icon={<DismissIcon />}
                            onClick={onCancel}
                            data-testid="promptbar-remove-skill-button"
                        />
                        <ToolbarDivider className={classes.toolbarDiv} />
                        <Button
                            appearance="transparent"
                            className={classes.submit}
                            icon={<SendIcon />}
                            onClick={onSubmit}
                        />
                    </div>
                </div>
            </AcrylicBase>
        </div>
    );
}
