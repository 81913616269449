import {
    Button,
    Link,
    Persona,
    useFocusFinders,
    useFocusableGroup,
} from '@fluentui/react-components';
import useClasses from './RoleSection.styles';
import {AccordionHeader, AccordionItem, AccordionPanel} from '@fluentui/react-components';
import {PolicyWorkspace, RoleMember, RoleMembers} from '@/api/rbac/rbac.types';
import {useEffect, useMemo, useRef, useState} from 'react';
import {MemberType, RoleType} from '@/components/admin/rbac/rbac.types';
import {usePolicyManager} from '.';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useUpdateWorkspacePolicyById} from '@/api/rbac';
import RemoveMemberButton from './RemoveMemberButton';
import {FailedIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import {MICROSOFT_ENTRA_ID} from '@/api/rbac/rbac.constants';
import {PARTNER_ROLES_ID} from './rbac.constants';
import AddRoles from './AddRoles/AddRoles';
import {Eye24Regular} from '@fluentui/react-icons';
import ViewRolesButton from './ViewRolesButton';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface RoleSectionProps {
    header: string;
    infoContent: string;
    sectionId: string;
    sectionValue: string;
    roleMembers?: RoleMembers;
    workspacePolicy: PolicyWorkspace;
    role: RoleType;
    policiesSuccessfullyFetched: boolean;
    policyProcessed: boolean;
}

export default function RoleSection(props: RoleSectionProps) {
    const classes = useClasses();

    const roleSectionWrapper = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();
    const [noContributor, setNoContributor] = useState<boolean>(false);
    const [errorTag, setErrorTag] = useState<string>('');
    const [isAddRolesOpen, setIsAddRolesOpen] = useState<boolean>(false);
    const is1pRbacEnabled = useFeatureFlag(MedeinaFeatures.Enable1pRbac);
    const {t} = useTranslation('admin');

    useEffect(() => {
        if (roleSectionWrapper?.current) {
            const firstFocusable = findFirstFocusable(roleSectionWrapper.current);
            firstFocusable?.focus();
        }
    }, [roleSectionWrapper]);

    const viewRolesClick = () => {
        setIsAddRolesOpen(!isAddRolesOpen);
    };

    const performActionsOnAddRolesClose = () => {
        setIsAddRolesOpen(false);
    };

    const countOfRoleMembers = useMemo(() => {
        let count = 0;
        if (props?.roleMembers?.roles) {
            count += props.roleMembers.roles.length;
        }
        if (props?.roleMembers?.groups) {
            count += props.roleMembers.groups.length;
        }
        if (props?.roleMembers?.users) {
            count += props.roleMembers.users.length;
        }
        return count;
    }, [props.roleMembers]);

    // To generate secondary text for persona of user
    const generateSecondaryTextUser = (roleMember: RoleMember) => {
        return <>{roleMember.mail}</>;
    };

    // To generate secondary text for persona of group
    const generateSecondaryTextGroup = (roleMember: RoleMember) => {
        return (
            <>
                {t('RoleAssignment.Group')} • {roleMember.memberCount}{' '}
                {roleMember.memberCount!! > 1
                    ? t('RoleAssignment.Users')
                    : t('RoleAssignment.User')}
            </>
        );
    };

    // To generate secondary text for persona of role
    const generateSecondaryTextRole = (roleMember: RoleMember) => {
        return (
            <>
                {t('RoleAssignment.Role')} • {t('RoleAssignment.RoleItemText')}{' '}
                <Link href={MedeinaVariables.MicrosoftEntraUri} target="_blank">
                    {MICROSOFT_ENTRA_ID}
                </Link>
            </>
        );
    };

    const {workspaceName} = useWorkspaceState();

    const policyManager = usePolicyManager({
        workspacePolicy: props.workspacePolicy,
        workspaceName: props?.workspacePolicy?.properties?.entity?.referenceName as string,
    });

    const {mutateAsync: updateWorkspacePolicy, isLoading: updatePolicyLoading} =
        useUpdateWorkspacePolicyById();

    // To handle remove member from the list
    const deleteMember = async (objectId: string, memberType: MemberType) => {
        try {
            const updatedList = policyManager.removeMemberFromPolicy(
                objectId,
                memberType,
                props.role,
            );

            await updateWorkspacePolicy(
                {
                    workspaceName: workspaceName as string,
                    policy: updatedList,
                },
                {
                    onSuccess: () => {
                        setErrorTag('');
                    },
                    onError: () => {
                        setErrorTag(objectId);
                    },
                },
            );
        } catch (e) {
            //TODO: discussions on handling error in progress
            setErrorTag(objectId);
            //throw e;
        }
    };

    // Add or remove the condition for 1P partner roles from the policy
    // This condition is a constant so we just need to add/remove it (toggle)
    const toggleRecommendedRoles = async () => {
        const updatedPolicy = policyManager.togglePartnerRolesCondition();

        if (!!updatedPolicy) {
            await updateWorkspacePolicy({
                workspaceName: workspaceName as string,
                policy: updatedPolicy,
            });
        }
    };
    const attributes = useFocusableGroup({tabBehavior: 'limited'});

    // To set the state of noContributor state to handle scenarion when there are no contributors present.
    useEffect(() => {
        if (props.policiesSuccessfullyFetched && props.policyProcessed) {
            if (
                props.role === RoleType.Contributor &&
                props.roleMembers?.groups.length === 0 &&
                props.roleMembers?.roles.length === 0 &&
                props.roleMembers?.users.length === 0
            ) {
                setNoContributor(true);
            } else {
                setNoContributor(false);
            }
        }
    }, [props.policiesSuccessfullyFetched, props.roleMembers, props.policyProcessed]);

    return (
        <>
            <AccordionItem
                value={props.sectionId}
                className={classes.roleSection}
                id={props.sectionId}
                key={props.sectionId}
            >
                <AccordionHeader>
                    <div className={classes.subtitle}>
                        {props.header}
                        {' ('}
                        {countOfRoleMembers}
                        {')'}
                    </div>
                </AccordionHeader>
                <div className={classes.paragraphContent} ref={roleSectionWrapper}>
                    {props.infoContent}
                </div>

                {noContributor && props.policiesSuccessfullyFetched && (
                    <div className={classes.noContributor}>
                        {t('RoleAssignment.RoleGroup.Contributor.NoContributors')}
                    </div>
                )}

                {props?.roleMembers?.roles?.map((roleMember: RoleMember) => {
                    return (
                        <>
                            <AccordionPanel
                                className={classes.roleMemberPanel}
                                key={roleMember.id}
                                {...attributes}
                                tabIndex={0}
                            >
                                <span>
                                    <Persona
                                        className={classes.roleMemberPersona}
                                        textPosition="after"
                                        name={roleMember.displayName}
                                        secondaryText={generateSecondaryTextRole(roleMember)}
                                        avatar={{color: 'colorful'}}
                                        size="large"
                                    />
                                </span>
                                <RemoveMemberButton
                                    canBeDeleted={roleMember?.canBeDeleted ?? true}
                                    onRemoveButtonClick={() => {
                                        deleteMember(roleMember.id as string, MemberType.Role),
                                            setErrorTag('');
                                    }}
                                    updatePolicyLoading={updatePolicyLoading}
                                    policyProcessed={props.policyProcessed}
                                ></RemoveMemberButton>
                            </AccordionPanel>

                            {errorTag === roleMember.id && (
                                <div className={classes.errorTag}>
                                    <span>
                                        <FailedIcon filled />
                                    </span>
                                    <span>
                                        {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                })}

                {props?.roleMembers?.groups?.map((roleMember: RoleMember) => {
                    return (
                        <>
                            <AccordionPanel
                                className={classes.roleMemberPanel}
                                key={roleMember.id}
                                {...attributes}
                                tabIndex={0}
                            >
                                <span>
                                    <Persona
                                        className={classes.roleMemberPersona}
                                        textPosition="after"
                                        name={roleMember.displayName}
                                        secondaryText={generateSecondaryTextGroup(roleMember)}
                                        avatar={{color: 'colorful'}}
                                        size="large"
                                    />
                                </span>
                                <RemoveMemberButton
                                    canBeDeleted={roleMember?.canBeDeleted || true}
                                    onRemoveButtonClick={() => {
                                        deleteMember(roleMember.id as string, MemberType.Group),
                                            setErrorTag('');
                                    }}
                                    updatePolicyLoading={updatePolicyLoading}
                                    policyProcessed={props.policyProcessed}
                                ></RemoveMemberButton>
                            </AccordionPanel>

                            {errorTag === roleMember.id && (
                                <div className={classes.errorTag}>
                                    <span>
                                        <FailedIcon filled />
                                    </span>
                                    <span>
                                        {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                })}

                {props?.roleMembers?.users?.map((roleMember: RoleMember) => {
                    const isRecommendedRole = roleMember.id === PARTNER_ROLES_ID;

                    return (
                        <>
                            <AccordionPanel
                                className={classes.roleMemberPanel}
                                key={roleMember.id}
                                {...attributes}
                                tabIndex={0}
                            >
                                <span>
                                    <Persona
                                        className={classes.roleMemberPersona}
                                        textPosition="after"
                                        name={roleMember.displayName}
                                        secondaryText={generateSecondaryTextUser(roleMember)}
                                        avatar={
                                            isRecommendedRole
                                                ? {color: 'brand', initials: '1P'}
                                                : {color: 'colorful'}
                                        }
                                        size="large"
                                    />
                                </span>
                                <div className={classes.actionButtonsContainer}>
                                    {isRecommendedRole && is1pRbacEnabled && (
                                        <ViewRolesButton
                                            onViewRolesButtonClick={viewRolesClick}
                                            updatePolicyLoading={updatePolicyLoading}
                                            policyProcessed={props.policyProcessed}
                                        />
                                    )}
                                    <RemoveMemberButton
                                        canBeDeleted={roleMember?.canBeDeleted || true}
                                        onRemoveButtonClick={() => {
                                            isRecommendedRole
                                                ? toggleRecommendedRoles()
                                                : deleteMember(
                                                      roleMember.id as string,
                                                      MemberType.User,
                                                  );

                                            setErrorTag('');
                                        }}
                                        updatePolicyLoading={updatePolicyLoading}
                                        policyProcessed={props.policyProcessed}
                                    />
                                </div>
                            </AccordionPanel>
                            {errorTag === roleMember.id && (
                                <div className={classes.errorTag}>
                                    <span>
                                        <FailedIcon filled />
                                    </span>
                                    <span>
                                        {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                })}
            </AccordionItem>
            {is1pRbacEnabled && (
                <AddRoles
                    viewOnly={true}
                    isAddRolesOpen={isAddRolesOpen}
                    onAddRolesClose={performActionsOnAddRolesClose}
                ></AddRoles>
            )}
        </>
    );
}
