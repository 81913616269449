import {DismissIcon} from '@/components/ui/icons';
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    MessageBarIntent,
    Spinner,
    mergeClasses,
} from '@fluentui/react-components';
import useClasses from './AddRoleMembers.styles';
import MemberList from '../MemberList/MemberList';
import {MemberListItemProps, RoleObjectIdData, MemberType} from '../rbac.types';
import MemberSearch from '../MemberSearch/MemberSearch';
import useMemberManagement from './useMemberManagement';
import {useUpdateWorkspacePolicyById} from '@/api/rbac';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {usePolicyManager} from '@/components/admin/rbac';
import {PolicyWorkspace, RbacErrorResponse} from '@/api/rbac/rbac.types';
import AddRoleMemberErrorMessage from './AddRoleMemberErrorMessage';
import {AddRoleMembersConfirmation, useAddRoleMemberErrorHanding} from '.';
import {AddRoleMembersMessages} from './useAddRoleMemberErrorHanding';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';

interface AddRoleMembersProps {
    isAddRoleMembersOpen: boolean;
    onAddRoleMembersClose?: () => void;
    existingMembers: RoleObjectIdData;
    workspacePolicy: PolicyWorkspace;
    onAddRoleMembersError?: () => void;
    onAddRoleMembersReset?: () => void;
    isRecommendedRolesDetected?: boolean;
}

export type ErrorMessageBarProps = {
    title: string;
    message: string;
    messageType: MessageBarIntent;
};

export default function AddRoleMembers(props: AddRoleMembersProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const [isSearchInFocus, setIsSearchInFocus] = useState(false);
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [numberOfAddedMembers, setNumberOfAddedmembers] = useState<number>(0);
    const {
        members,
        onMemberAdded,
        resetMemberCollection,
        removeMemberItem,
        updateMemberItem,
        error,
        generateMemberMap,
        hasError: hasMemberError,
    } = useMemberManagement({
        existingMembers: props.existingMembers,
        isRecommendedRolesDetected: props.isRecommendedRolesDetected,
    });

    const getUpdateRoleObjectIds = (
        existingMembers: RoleObjectIdData,
        addedMembers: MemberListItemProps[],
    ): RoleObjectIdData => {
        const existingMembersData: RoleObjectIdData = {...existingMembers};

        //Arranged role data
        for (const member of addedMembers) {
            const roleData =
                existingMembersData[member.role] || ({} as Record<MemberType, string[]>);

            const roleObjectIdData = roleData[member.type] || ([] as string[]);

            if (!roleObjectIdData.includes(member.memberId)) {
                roleObjectIdData.push(member.memberId);
            }
        }

        // Need to invoke API Mutation
        return existingMembersData;
    };

    const {errorMessage, generateErrorMessage, resetError} = useAddRoleMemberErrorHanding(
        error as AddRoleMembersMessages,
    );

    const {workspaceName} = useWorkspaceState();

    const policyReferenceName = props?.workspacePolicy?.properties?.entity?.referenceName;

    const {
        mutateAsync: updateWorkspacePolicy,
        isError: updatePolicyError,
        isSuccess: updatePolicySuccess,
        isLoading: updatePolicyLoading,
        error: updatePolicyMutationError,
    } = useUpdateWorkspacePolicyById();

    const {updateExistingPolicy} = usePolicyManager({
        workspacePolicy: props.workspacePolicy,
        workspaceName: policyReferenceName as string,
    });

    const closeAddRoleMembersDialog = () => {
        props?.onAddRoleMembersClose?.();
        props?.onAddRoleMembersReset?.();
        setTimeout(() => {
            resetError();
            resetMemberCollection();
        }, 1000);
    };

    const onMemberSearchResultError = (): void => {
        generateErrorMessage(AddRoleMembersMessages.GENERIC);
    };

    // To reset the errors when search started
    const onMemberSearchStarted = (): void => {
        resetError();
    };

    // To reset error to none and remove focus from the search input field
    const resetStates = () => {
        resetError();
        setIsSearchInFocus(false);
    };

    const queryClient = useQueryClient();

    // Handles adding a new member to the collection by updating the policy by API.
    const addRoleMembers = async () => {
        const updatedMemberList = getUpdateRoleObjectIds(props.existingMembers, members);
        const updatedPolicy = updateExistingPolicy(
            updatedMemberList,
            policyReferenceName as string,
        );
        props?.onAddRoleMembersReset?.();

        if (!!updatedPolicy) {
            try {
                await updateWorkspacePolicy({
                    workspaceName: workspaceName as string,
                    policy: updatedPolicy,
                });
                setConfirmDialogOpen(true);
                closeAddRoleMembersDialog();
            } catch (error) {
                const errorData = error as RbacErrorResponse;
                generateErrorMessage(AddRoleMembersMessages.ERROR_WHILE_UPDATING, {
                    message: errorData.Error.Message,
                });
                props?.onAddRoleMembersError?.();
            }
        }
    };

    // Handle the event when confirm dialog box closed
    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
        closeAddRoleMembersDialog();
        setNumberOfAddedmembers(0);
    };

    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    return (
        <>
            <Dialog open={props.isAddRoleMembersOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>
                            <div className={classes.titleWrapper}>
                                <span className={classes.title}>
                                    {t('RoleAssignment.AddRoleMembers.AddMembers')}
                                </span>
                                <Button
                                    data-test-id="close-add-members-dialog-button"
                                    appearance="transparent"
                                    aria-label={t(
                                        'RoleAssignment.AddRoleMembers.AriaLabels.AddRoleMemberDialogClose',
                                    )}
                                    className={classes.closeButton}
                                    onClick={closeAddRoleMembersDialog}
                                    icon={<DismissIcon />}
                                />
                            </div>
                        </DialogTitle>
                        <DialogContent
                            className={mergeClasses(
                                classes.contentSection,
                                scrollClasses.colorNeutralBackground1,
                            )}
                        >
                            <div className={classes.dialogInfo}>
                                {t('RoleAssignment.AddRoleMembers.AddMembersInfo')}
                            </div>
                            <AddRoleMemberErrorMessage
                                error={errorMessage as ErrorMessageBarProps}
                            />
                            <MemberSearch
                                isSearchBarFocused={isSearchInFocus}
                                onMemberSearchStarted={() => {
                                    onMemberSearchStarted();
                                    setIsSearchInFocus(false);
                                }}
                                onMemberSelected={(memberData: MemberListItemProps) => {
                                    setIsSearchInFocus(false);
                                    onMemberAdded(memberData);
                                }}
                                onMemberSearchError={onMemberSearchResultError}
                            ></MemberSearch>
                            <div
                                className={mergeClasses(
                                    classes.memberListWrapper,
                                    scrollClasses.colorNeutralBackground1,
                                )}
                            >
                                <MemberList
                                    members={members}
                                    onMemberRemoved={(objectId) => {
                                        resetStates();
                                        removeMemberItem(objectId);
                                        setIsSearchInFocus(true);
                                    }}
                                    onMemberUpdated={(key, role) => {
                                        updateMemberItem(key, role);
                                    }}
                                    preConfiguredMemberMap={generateMemberMap()}
                                ></MemberList>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    addRoleMembers();
                                    setNumberOfAddedmembers(members.length);
                                }}
                                disabled={
                                    members.length === 0 || updatePolicyLoading || hasMemberError
                                }
                                aria-label={t(
                                    'RoleAssignment.AddRoleMembers.AriaLabels.AddSelectedRoleMembers',
                                )}
                            >
                                {updatePolicyLoading ? (
                                    <Spinner size="tiny"></Spinner>
                                ) : (
                                    commonLabel('ButtonLabels.Add')
                                )}
                            </Button>
                            <Button onClick={closeAddRoleMembersDialog}>
                                {commonLabel('ButtonLabels.Cancel')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <AddRoleMembersConfirmation
                count={numberOfAddedMembers}
                isConfirmDialogOpen={isConfirmDialogOpen}
                onConfirmDialogClose={handleConfirmDialogClose}
            ></AddRoleMembersConfirmation>
        </>
    );
}
