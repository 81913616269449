import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    dialogContentSection: {
        marginBottom: '36px',
        marginTop: tokens.spacingVerticalS,
    },
    dismissIcon: {
        height: '24px',
        width: '24px',
    },
});
