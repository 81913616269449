import * as React from 'react';
import {
    Button,
    Input,
    ToolbarDivider,
    mergeClasses,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {SearchIcon, DismissIcon} from '@/components/ui/icons';
import {SkillsetFilterTypes} from '@/api/skills';
import useClasses from '../../ConnectorModalContent.styles';
import {FilterButton, FilterButtonRow} from '../../../Buttons/FilterButton';
import {useTranslation} from 'react-i18next';

interface FilterButtonsComponentProps {
    searchText: string;
    showSearchBar: boolean;
    onSearchIconClick: () => void;
    onSearchTextChange: (text: string) => void;
    onClearSearch: () => void;
    enabledCount: number;
    disabledCount: number;
    totalCount: number;
    displayedSkillsetsFilter: SkillsetFilterTypes;
    onFilterButtonClick: (ev: any) => void;
}

export default function FilterButtonsComponent({
    searchText,
    showSearchBar,
    onSearchIconClick,
    onSearchTextChange,
    onClearSearch,
    enabledCount,
    disabledCount,
    totalCount,
    displayedSkillsetsFilter,
    onFilterButtonClick,
}: FilterButtonsComponentProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');

    // focus utility to help restore focus to the correct element after closing the modal, this is where we can come from
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    // focus utility to help restore focus to the correct element after closing the modal, this is what we want to go back to
    const restoreFocusTargetAttribute = useRestoreFocusTarget();

    return (
        <div className={classes.popoverSearch}>
            <Button
                {...restoreFocusTargetAttribute}
                onClick={onSearchIconClick}
                className={mergeClasses(
                    classes.btnSearchStart,
                    showSearchBar && classes.btnSearchHidden,
                )}
                icon={<SearchIcon />}
                aria-label={t('ContentModal.SearchPlugins')}
                tabIndex={0}
            />

            {showSearchBar && (
                <Input
                    {...restoreFocusSourceAttribute}
                    className={classes.searchbar}
                    type="text"
                    autoFocus
                    placeholder={t('ContentModal.SearchPlugins')}
                    value={searchText}
                    onChange={(_event: any, textbox: any) => {
                        onSearchTextChange(textbox.value);
                    }}
                    contentBefore={<SearchIcon />}
                    appearance="underline"
                    contentAfter={
                        <Button
                            onClick={onClearSearch}
                            className={classes.btnCancel}
                            size={'small'}
                            appearance={'outline'}
                            icon={<DismissIcon />}
                            aria-label={t('ContentModal.ClearSearch')}
                        />
                    }
                />
            )}
            <ToolbarDivider className={classes.hrule} />
            <FilterButtonRow>
                <FilterButton
                    size="small"
                    name={SkillsetFilterTypes.All}
                    onClick={onFilterButtonClick}
                    checked={displayedSkillsetsFilter == SkillsetFilterTypes.All}
                >
                    {t('ContentModal.AllPlugins', {totalCount: totalCount})}
                </FilterButton>
                <FilterButton
                    size="small"
                    name={SkillsetFilterTypes.On}
                    onClick={onFilterButtonClick}
                    checked={displayedSkillsetsFilter == SkillsetFilterTypes.On}
                >
                    {t('ContentModal.AllPluginsTurnedOn', {enabledCount})}
                </FilterButton>
                <FilterButton
                    size="small"
                    name={SkillsetFilterTypes.Off}
                    onClick={onFilterButtonClick}
                    checked={displayedSkillsetsFilter == SkillsetFilterTypes.Off}
                >
                    {t('ContentModal.AllPluginsTurnedOff', {disabledCount})}
                </FilterButton>
            </FilterButtonRow>
        </div>
    );
}
