import useClasses from './PromptActionButtons.styles';
import useButtonClasses from '@/components/ui/Buttons/MedeinaButton.styles';
import RerunPromptDialog from './RerunPromptDialog';
import DeletePromptDialog from './DeletePromptDialog';
import {EditIcon, PinIcon} from '@/components/ui/icons';
import {
    Tooltip,
    ToolbarButton,
    ToggleButton,
    Divider,
    mergeClasses,
} from '@fluentui/react-components';
import {
    EvaluationState,
    useCreatePinnedEvaluation,
    useDeletePinnedEvaluation,
    useGetEvaluation,
    useIsEvaluationPinned,
} from '@/api/evaluations';
import {useTourable} from '@/components/ui/Tour/useTourable';
import {useGetPrompts} from '@/api/prompts';
import {useMemo} from 'react';
import {PromptActionButtonsProps} from './Prompt.types';
import {useEvaluationContext} from '../evaluations/EvaluationContext';
import {useLayout} from '@/components/ui/Layout';
import {promptHoverFocus} from './Prompt.styles';
import {useTranslation} from 'react-i18next';
import SwitchPromptModelDialog from './SwitchPromptModelDialog';
import MedeinaFeatures from '@/util/features';

export default function PromptActionButtons({
    onEdit,
    setErrMessage,
    restoreFocusTargetAttribute,
}: PromptActionButtonsProps) {
    const classes = useClasses();
    const btnClasses = useButtonClasses();
    const pinTourRef = useTourable({id: 'investigation-evaluation-toolbar-pin'});
    const {promptId, sessionId, evaluationId} = useEvaluationContext();
    const {t} = useTranslation('session');
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});
    const isEvaluationComplete = evaluation?.state === EvaluationState.Completed;

    // Pinning
    const {mutate: createPinnedEvaluation} = useCreatePinnedEvaluation();
    const {mutate: deletePinnedEvaluation} = useDeletePinnedEvaluation();
    const isPinned = useIsEvaluationPinned({sessionId, evaluationId});
    const {sidePanel} = useLayout();
    const {toggleOpen} = sidePanel || {toggleOpen: () => {}};

    const {data: nextPrompts} = useGetPrompts({sessionId, lastPromptId: promptId});
    const isLastPrompt = useMemo<boolean>(() => {
        return nextPrompts?.value.length === 0 || false;
    }, [nextPrompts]);

    return (
        <div className={classes.root}>
            {evaluationId && (
                <Tooltip
                    content={isPinned ? t('Unpin') : t('PinSummaryButton')}
                    relationship="label"
                    positioning="after"
                >
                    <ToggleButton
                        className={mergeClasses(
                            btnClasses.subtleIconColor,
                            isPinned ? classes.pinBtn : classes.unpinBtn,
                            promptHoverFocus,
                        )}
                        data-testid={isPinned ? 'pinned-prompt-button' : 'unpinned-prompt-button'}
                        checked={isPinned}
                        ref={!isPinned ? pinTourRef : undefined}
                        appearance="subtle"
                        shape="circular"
                        icon={<PinIcon filled={isPinned} />}
                        disabled={!isEvaluationComplete}
                        onClick={() => {
                            toggleOpen?.();
                            if (isPinned) {
                                deletePinnedEvaluation({sessionId, evaluationId});
                            } else {
                                createPinnedEvaluation({sessionId, promptId, evaluationId});
                            }
                        }}
                    />
                </Tooltip>
            )}
            <div className={mergeClasses(classes.promptBtns, promptHoverFocus)}>
                <Divider appearance="subtle" className={classes.promptBtnsDivider} />
                <Tooltip content={t('EditPromptTooltip')} relationship="label" positioning="after">
                    <ToolbarButton
                        data-testid="edit-prompt-button"
                        appearance="subtle"
                        icon={<EditIcon />}
                        onClick={() => onEdit('Edit')}
                        {...restoreFocusTargetAttribute}
                    />
                </Tooltip>
                <RerunPromptDialog
                    {...{sessionId, promptId, isLastPrompt}}
                    setErrorMessage={setErrMessage}
                />
                {MedeinaFeatures.EnableAOAIModelSwitcher && isLastPrompt && (
                    <SwitchPromptModelDialog
                        {...{sessionId, promptId}}
                        setErrorMessage={setErrMessage}
                    />
                )}
                <DeletePromptDialog {...{sessionId, promptId}} />
            </div>
        </div>
    );
}
