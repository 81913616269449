import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    root: {
        paddingTop: '36px',
        paddingBottom: '36px',
        display: 'flex',
        justifyContent: 'center',
    },
});
