import React from 'react';
import type {NavMenuTabListProps} from './NavMenuTabList.types';
import useClasses from './NavMenuTabList.styles';

/**
 * Wrapper of menu tabs
 */
export function NavMenuTabList({children, heading, onClick}: NavMenuTabListProps) {
    const classes = useClasses();

    return (
        <div className={classes.root}>
            {!!heading && <div className={classes.heading}>{heading}</div>}
            <div
                role="tablist"
                aria-orientation="vertical"
                className={classes.navMenuListContainer}
                onClick={onClick}
            >
                {children}
            </div>
        </div>
    );
}
