export interface AssistanceMenuItemsProps {
    onFeedbackClick: () => void;
    onSupportCentralClick: () => void;
    menuType?: string;
}

export interface FeedbackProps {
    openModal: boolean;
    onCloseModal?: () => void;
}

export interface InformationPanelProps {
    title: string;
    content: string;
}

export interface ErrorPanelProps extends InformationPanelProps {}

export enum AssistanceFormStates {
    Edit,
    InProgress,
    Success,
    Error,
}

export interface SupportCentralProps {
    openModal: boolean;
    onCloseModal?: () => void;
}
