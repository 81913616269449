import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    titleText: {
        marginTop: tokens.spacingVerticalXXL,
        marginLeft: tokens.spacingHorizontalL,
        fontSize: tokens.fontSizeBase600,
        lineHeight: tokens.lineHeightBase600,
        fontWeight: tokens.fontWeightSemibold,
        color: tokens.colorNeutralForeground1,
    },
    modalButton: {
        top: tokens.spacingVerticalXXXL,
        position: 'absolute',
        left: tokens.spacingHorizontalXXL,
    },
    content: {
        width: '100%',
    },
    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
        paddingBottom: tokens.spacingVerticalM,
    },
    grid: {
        width: '100%',
        marginRight: tokens.spacingHorizontalM,
    },
    dropdown: {
        width: '70%',
        marginBottom: tokens.spacingVerticalL,
    },
    spacing: {
        marginBottom: tokens.spacingVerticalL,
    },
    typeDropdown: {
        maxWidth: '20%',
        minWidth: '20%',
        marginBottom: tokens.spacingVerticalL,
    },
    topRow: {
        display: 'flex',
        ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalXL),
    },
    subTextRow: {
        display: 'flex',
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
    },
    textareaWrapper: {
        ...shorthands.flex(1, 1, 'auto'),
        width: '70%',
    },
    longTextareaWrapper: {
        ...shorthands.flex(1, 1, 'auto'),
        width: '100%',
    },
    buttons: {
        display: 'flex',
        width: 'max-content',
        marginLeft: 'auto',
        alignSelf: 'flex-end',
        position: 'absolute',
        right: tokens.spacingHorizontalXL,
        bottom: tokens.spacingVerticalXL,
    },
    copilotIcon: {
        height: '40px',
        width: '40px',
        marginRight: tokens.spacingHorizontalM,
    },
    buttonsRow: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        minWidth: '150%',
        marginTop: tokens.spacingVerticalL,
        ...shorthands.gap('16px'),
    },
    compoundButton: {
        alignContent: 'start',
        width: '45%',
    },
    openAiButton: {
        paddingRight: '60px',
        alignContent: 'start',
        width: '45%',
    },
    contentContainer: {
        width: '80%',
    },
    clickedButton: {
        alignContent: 'start',
        width: '45%',
        ...shorthands.border(
            tokens.borderRadiusSmall,
            'solid',
            tokens.colorCompoundBrandForeground1,
        ),
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    clickedOpenAiButton: {
        paddingRight: '60px',
        alignContent: 'start',
        width: '45%',
        ...shorthands.border(
            tokens.borderRadiusSmall,
            'solid',
            tokens.colorCompoundBrandForeground1,
        ),
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    cancelButton: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
        marginRight: tokens.spacingHorizontalL,
    },
    saveButton: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        backgroundColor: tokens.colorPaletteLightTealBackground2,
        ':disabled': {
            backgroundColor: tokens.colorNeutralBackground2,
        },
    },
    summaryOverlay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '680px',
        justifyContent: 'center',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        wordBreak: 'break-word',
        paddingTop: tokens.spacingVerticalXXXL,
    },
    savedButton: {
        color: tokens.colorPaletteGreenBackground3,
        alignSelf: 'center',
        height: tokens.lineHeightHero800,
        width: tokens.lineHeightHero800,
        marginBottom: tokens.spacingVerticalS,
    },
    failedButton: {
        color: tokens.colorPaletteRedForeground1,
        alignSelf: 'center',
        height: '48px',
        width: '48px',
        marginBottom: tokens.spacingVerticalS,
    },
    failedLargeText: {
        textAlign: 'center',
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightSemibold,
        color: tokens.colorNeutralForeground1,
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalL,
    },
    backButton: {
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
        marginTop: tokens.spacingVerticalL,
        alignSelf: 'center',
    },
    successText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground3,
        textAlign: 'center',
        maxWidth: '80%',
    },
    summaryScrollText: {
        maxHeight: '75%',
        overflowY: 'auto',
    },
    confirmationModal: {
        display: 'flex',
        flexDirection: 'column',
        height: '800px',
        justifyContent: 'center',
        paddingLeft: '15%',
        paddingRight: '15%',
    },
    resetIcon: {
        alignSelf: 'center',
        marginBottom: tokens.spacingVerticalL,
    },
    resetTitleText: {
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalL,
        textAlign: 'center',
    },
    resetText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalL,
        textAlign: 'center',
        color: tokens.colorNeutralForeground3,
    },
    resetButtons: {
        marginTop: tokens.spacingVerticalL,
        alignSelf: 'center',
    },
    resetButton: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
        marginRight: tokens.spacingHorizontalXS,
        backgroundColor: tokens.colorPaletteRedBackground3,
        color: tokens.colorNeutralForegroundOnBrand,
    },
    icon: {
        display: 'flex',
        marginTop: tokens.spacingVerticalXXL,
        marginLeft: tokens.spacingHorizontalL,
        marginRight: tokens.spacingHorizontalM,
    },
    switch: {
        display: 'flex',
        alignItems: 'center',
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalS,
    },
});
