export type ControlTriggeredEventParameters = {
    target: string;
    action: string;
    source: string;
};

export type LinkParameters = {
    target?: string;
    url?: string;
    text: string;
};

export enum GeoName {
    ANZ = 'ANZ',
    EU = 'EU',
    UK = 'UK',
    US = 'US',
    NW = 'NW',
}

export enum RegionName {
    westeurope = 'westeurope',
    uksouth = 'uksouth',
    eastus = 'eastus',
    australiaeast = 'australiaeast',
    norwayeast = 'norwayeast',
    centralus = 'centralus',
}

export type Geo = {
    displayName: string;
    formattedDisplayName: string;
    pricing: number;
    defaultAzureRegion: RegionName;
    minCapacity: number;
    maxCapacity: number;
    hideFromDisplay?: boolean;
};

export type Region = {
    displayName: string;
    geo: GeoName;
};

export enum CreateCapacityStatus {
    Success = 'Success',
    Error = 'Error',
    Cancel = 'Cancel',
}
