import {IPublicClientApplication, AuthError} from '@azure/msal-browser';
import {getCurrentTenantId, signInErrorHandler} from './authConfig';

export default async function acquireMsalToken(
    msalInstance: IPublicClientApplication,
    scopes: any,
) {
    // Handle token silent via redirect.
    const redirectResponse = await msalInstance.handleRedirectPromise();
    if (redirectResponse !== null) {
        console.log(redirectResponse);
        return redirectResponse.accessToken;
    }
    // Otherwise, attempt to acquire token silently.
    else {
        // Generate token request.
        const account = msalInstance.getActiveAccount();
        const tokenRequest = {
            ...scopes,
            account,
            authority: `https://login.microsoftonline.com/${getCurrentTenantId()}`,
        };

        try {
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            return tokenResponse.accessToken;
        } catch (error) {
            signInErrorHandler(error as AuthError, tokenRequest);
        }
    }
}
