import * as React from 'react';
import {Image, Link, mergeClasses} from '@fluentui/react-components';
import {SettingsScope, SkillsetDescriptor} from '@/api/skills';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {useTranslation} from 'react-i18next';
import {DialogTitle as CustomDialogTitle} from '@/components/ui/Dialog';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';

interface AdminModalDialogTitleProps {
    selectedSkillset: string | null;
    selectedSkillsetDescriptor: SkillsetDescriptor | null;
    scope: SettingsScope;
    icon: string | undefined;
    onReturn: () => void;
    isSettingUpPlugin: boolean;
    isPlugin: boolean;
    onClose: () => void;
}

export default function AdminModalDialogTitle({
    selectedSkillset,
    selectedSkillsetDescriptor,
    scope,
    icon,
    onReturn,
    onClose,
    isSettingUpPlugin,
    isPlugin: isCustomPlugin,
}: AdminModalDialogTitleProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    const getPluginDialogTitleText = () => {
        const displayName = selectedSkillsetDescriptor?.displayName ?? selectedSkillset;
        const pluginLabel = isSettingUpPlugin ? 'Plugin.SetupPluginLabel' : 'Plugin.PluginSettings';
        return t(pluginLabel, {name: displayName});
    };

    const getPluginDisclaimerText = () => {
        if (isSettingUpPlugin) {
            return isCustomPlugin
                ? t('Admin.OneMoreStepToComplete')
                : isWorkspacesTestingEnabled
                ? t('WorkspacesAdmin.PluginSetupNeeded')
                : t('Admin.PluginSetupNeeded');
        }
        switch (scope) {
            case SettingsScope.User:
                return t('Admin.ConfigurationManageUserText');
            case SettingsScope.Tenant:
                return isWorkspacesTestingEnabled
                    ? t('WorkspacesAdmin.ConfigurationManageWorkspaceText')
                    : t('Admin.ConfigurationManageTenantText');
            default:
                return t('Admin.ManagePlugin');
        }
    };

    return (
        <div className={mergeClasses(classes.content, classes.stickyTop)}>
            <CustomDialogTitle
                backLabel={t('Admin.ReturnToPlugins')}
                onBackButtonClick={onReturn}
                showBackButton
                icon={<Image height={24} width={24} fit="default" src={icon} aria-hidden alt="" />}
                title={getPluginDialogTitleText()}
                onCloseButtonClick={onClose}
            />
            <div className={classes.subTextRow}>
                <p className={classes.subText}>
                    {getPluginDisclaimerText()}
                    {(isCustomPlugin || scope == SettingsScope.User) && (
                        <>
                            {' '}
                            <Link
                                href={
                                    'https://learn.microsoft.com/en-us/security-copilot/manage-plugins'
                                }
                                target="_blank"
                            >
                                {t('Admin.ManagePluginLearnMore')}
                            </Link>
                        </>
                    )}
                </p>
            </div>
        </div>
    );
}
