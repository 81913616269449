import Prompt from './Prompt';
import {PromptbookSectionProps} from './Prompt.types';
import MedeinaFeatures from '@/util/features';
import useClasses from './PromptbookSection.styles';
import {
    PromptbookExecutionStatus,
    useCancelPromptbookExecution,
    useGetPromptbook,
    useGetPromptbookExecution,
} from '@/api/promptbooks';
import {Button, Divider, mergeClasses} from '@fluentui/react-components';
import {useLayout} from '@/components/ui/Layout';
import {CheckboxUncheckedIcon, PromptbookIcon, StopIcon} from '@/components/ui/icons';
import useGetPrompts from '@/api/prompts/useGetPrompts';
import useSessionViewMode from '../sessions/useSessionViewMode';
import {useState} from 'react';
import {useFeatureFlag} from '@/api/user';

export default function PromptbookSection({
    sessionId,
    promptbookExecutionId,
    isReadOnly,
    errorMessage,
    selection,
}: PromptbookSectionProps) {
    const classes = useClasses();
    const {contentArea} = useLayout();

    const [stopDisbaled, setStopButton] = useState<boolean>(false);

    const {data: prompts} = useGetPrompts({sessionId});
    let promptbookPrompts = prompts?.value.filter(
        (prompt) => prompt.promptbookExecutionId === promptbookExecutionId,
    );

    const {data: promptbookExecution} = useGetPromptbookExecution({promptbookExecutionId});
    const promptbookId = promptbookExecution?.promptbookId || '';
    const {data: promptbookData} = useGetPromptbook({promptbookId});

    const isPromptbookEntityEnabled = useFeatureFlag(MedeinaFeatures.EnablePromptbookEntity);

    const promptbookTitle = promptbookData?.name;
    const {isFullWidth: contentModeFull} = useSessionViewMode();

    const {mutate: cancelPromptbookExecution} = useCancelPromptbookExecution();
    const handleCancelClick = () => {
        try {
            if (promptbookExecution) {
                cancelPromptbookExecution({sessionId, promptbookExecutionId});
                setStopButton(true);
            }
        } catch (error) {
            console.log('Failed to cancel the promptbook Execution:', error);
        }
    };
    return (
        <div>
            <div className={mergeClasses(classes.root, classes.fullWidthRoot)}>
                <div className={classes.firstDiv}>
                    <PromptbookIcon className={classes.promptbookIcon} />
                </div>
                <div
                    data-tour-id="promptContent"
                    className={mergeClasses(
                        classes.content,
                        classes.fullWidthContent,
                        classes.titleContainer,
                    )}
                >
                    <h2>{promptbookTitle}</h2>
                    {isPromptbookEntityEnabled &&
                        promptbookExecution?.status == PromptbookExecutionStatus.Running && (
                            <Button
                                icon={<CheckboxUncheckedIcon className={classes.stopIcon} />}
                                onClick={handleCancelClick}
                                className={mergeClasses(classes.stopButton, classes.redBackground)}
                                disabled={stopDisbaled}
                                appearance="primary"
                            >
                                {'Stop'}
                            </Button>
                        )}
                </div>
            </div>
            {promptbookPrompts?.map((prompt) => (
                <div key={prompt.promptId} className={classes.promptItem}>
                    <Prompt
                        key={prompt.promptId}
                        sessionId={prompt.sessionId}
                        promptId={prompt.promptId}
                        selection={selection}
                        isReadOnly={isReadOnly}
                        errorMessage={errorMessage}
                    />
                </div>
            ))}
            <div className={classes.divider} tabIndex={0}>
                <div data-tour-id="promptContent">
                    <Divider></Divider>
                </div>
            </div>
        </div>
    );
}
