import {useGetCapacityByName} from '@/api/capacities';
import {useGetWorkspaceByName, useGetWorkspaces} from '@/api/workspaces';
import {useEffect, useState} from 'react';

interface useManageDoneDetailsProps {
    onWorkspacesError: (error: any) => void;
    onCapacityError: (error: any) => void;
}
export default function useManageDoneDetails(props: useManageDoneDetailsProps) {
    const [allDataFetched, setAllDataFetched] = useState(false);
    const [capacityName, setCapacityName] = useState<string | null>(null);

    const {
        data: workspacesData,
        isFetched: isWorkspacesDataFetched,
        isError: isWorkspacesDataError,
        isLoading: isWorkspacesLoading,
        error: workspacesError,
    } = useGetWorkspaces();

    const {
        data: workspaceData,
        isFetched: isWorkspaceDataFetched,
        isSuccess: isWorkspaceDataSuccess,
        isError: isWorkspaceDataError,
        isLoading: isWorkspaceDataLoading,
        error: workspaceError,
    } = useGetWorkspaceByName(workspacesData?.value?.[0].name || '', {
        enabled: isWorkspacesDataFetched,
        refetchOnMount: true,
    });

    const {
        data: capacityData,
        isFetched: isCapacityDataFetched,
        isSuccess: isCapacityDataSuccess,
        isLoading: isCapacitiesDataLoading,
        isError: isCapacityDataError,
        error: capacityError,
    } = useGetCapacityByName(workspaceData?.capacity?.referenceName || '', {
        enabled: isWorkspacesDataFetched && isWorkspaceDataFetched,
        refetchOnMount: true,
    });

    useEffect(() => {
        if (isWorkspacesDataError) {
            props?.onWorkspacesError(workspacesError);
        } else if (isWorkspaceDataError) {
            props?.onWorkspacesError(workspaceError);
        }
    }, [isWorkspaceDataError, isWorkspacesDataError]);

    useEffect(() => {
        if (isCapacityDataError) {
            props?.onCapacityError(capacityError);
        }
    }, [isCapacityDataError]);

    useEffect(() => {
        if (isWorkspaceDataFetched && isWorkspacesDataFetched) {
            setCapacityName(workspaceData?.capacity?.referenceName || '');
        }
    }, [isWorkspaceDataFetched, isWorkspacesDataFetched]);

    useEffect(() => {
        if (isWorkspaceDataSuccess && isCapacityDataSuccess && isWorkspaceDataSuccess) {
            setAllDataFetched(true);
        }
    }, [isWorkspaceDataFetched, isCapacityDataFetched, isWorkspacesDataFetched]);

    return {
        regionName: capacityData?.location || '',
        capacityId: capacityData?.id || '',
        isWorkspaceDataError,
        isCapacityDataError,
        allDataFetched,
        isDataLoading: isWorkspacesLoading || isWorkspaceDataLoading || isCapacitiesDataLoading,
    };
}
