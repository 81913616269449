import React, {useState, useRef, useCallback, useEffect, useMemo} from 'react';
import {
    Label,
    Dropdown,
    Option,
    Toaster,
    useToastController,
    Toast,
    ToastTitle,
    ToastBody,
    useId,
    MessageBar,
    MessageBarTitle,
    MessageBarBody,
    MessageBarActions,
    Button,
    Switch,
} from '@fluentui/react-components';
import usePreferenceClasses from '../Preferences.styles';
import {LANGUAGE_OPTIONS, LANGUAGE_OPTION_MAP, MedeinaLanguageCode} from '@/util/locale';

import {useUserState} from '@/api/user';
import {useTranslation} from 'react-i18next';
import {useUpdateUserPreferences} from '@/api/user';
import i18n from 'i18next';
import useClasses from './AppLanguageField.styles';

function AppLanguageField() {
    const toasterId = useId('toaster');
    const languageId = useId('language');
    const {dispatchToast} = useToastController(toasterId);
    const {useBrowserLanguage, setUseBrowserLanguage, preferences, setPreferences} = useUserState();
    const {locale} = preferences;
    const {languageCode} = locale;
    // make a copy of the original language code in case we change it
    const originalLanguageCode = useRef(languageCode).current;
    const classes = useClasses();
    const preferenceClasses = usePreferenceClasses();
    const {t} = useTranslation('common');

    const updateUserPreferences = useUpdateUserPreferences();

    const showErrorToast = useCallback(
        (error?: Error) =>
            dispatchToast(
                <Toast>
                    <ToastTitle>{t('SettingsErrorSaveTitle')}</ToastTitle>
                    <ToastBody>
                        {t('SettingsErrorSaveTitle')}${error?.message}
                    </ToastBody>
                </Toast>,
                {intent: 'error'},
            ),
        [dispatchToast],
    );

    const handleLanguageChange = useCallback(
        (languageCode: string, languageLabel: string) => {
            // update our language in i18n to update the strings in the application and the Accept-Language header
            i18n.changeLanguage(languageCode);
            setPreferences({
                locale: {
                    languageCode: languageCode,
                    displayLanguage: languageLabel,
                },
            });
            // persist the user's selection to the database
            updateUserPreferences.mutate(
                {
                    locale: {
                        languageCode: languageCode,
                        displayLanguage:
                            LANGUAGE_OPTION_MAP[languageCode as MedeinaLanguageCode].label || '',
                    },
                },
                {
                    onError: (error) => {
                        showErrorToast(error as Error);
                    },
                },
            );
        },
        [setPreferences, updateUserPreferences, showErrorToast],
    );

    const displayLanguageTextValue = useMemo(() => {
        const displayLabel = LANGUAGE_OPTIONS.find(
            (option) => option.value === languageCode,
        )?.label;
        return t(displayLabel || 'SettingsLanguageDropdownPlaceholder');
    }, [languageCode, t]);

    useEffect(() => {
        if (useBrowserLanguage) {
            // TODO: Once we are ready to roll this out for GA we update this to reset to blank
            // resetting to blank will allow i18n to use the browser language setting instead of our local storage setting
            setPreferences({
                locale: {
                    languageCode: '',
                    displayLanguage: '',
                },
            });
        }
    }, [useBrowserLanguage]);

    return (
        <div className={preferenceClasses.field}>
            <h3>{t('SettingsLanguageHeading')}</h3>
            <Label id={languageId} className={preferenceClasses.label}>
                {t('SettingsLanguageLabel')}
            </Label>
            <div className={preferenceClasses.dropdownWrapper}>
                <Dropdown
                    aria-labelledby={languageId}
                    placeholder={t('SettingsLanguageDropdownPlaceholder')}
                    value={displayLanguageTextValue}
                    defaultSelectedOptions={[preferences.locale.languageCode]}
                    onOptionSelect={(_, data) => {
                        handleLanguageChange(data.optionValue as string, data.optionText as string);
                    }}
                    disabled={useBrowserLanguage}
                >
                    {LANGUAGE_OPTIONS.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {t(option.label)}
                        </Option>
                    ))}
                </Dropdown>
            </div>
            {originalLanguageCode !== languageCode && (
                <MessageBar className={classes.messageBar}>
                    <MessageBarBody aria-live="assertive">
                        {t('SettingsLocaleReloadMessage')}
                    </MessageBarBody>
                    <MessageBarActions>
                        <Button
                            onClick={() => {
                                if (typeof window !== 'undefined' && window.location) {
                                    window.location.reload();
                                }
                            }}
                        >
                            {t('SettingsLocaleReloadButton')}
                        </Button>
                    </MessageBarActions>
                </MessageBar>
            )}
            <Switch
                label={t('SettingsLanguageBrowserPreference')}
                checked={useBrowserLanguage}
                onChange={(_, data) => {
                    setUseBrowserLanguage(data.checked);
                }}
                labelPosition="before"
                className={classes.languageSwitch}
            />

            <Toaster
                toasterId={toasterId}
                position="bottom"
                pauseOnHover
                pauseOnWindowBlur
                timeout={4000}
            />
        </div>
    );
}

export default AppLanguageField;
