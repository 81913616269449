import React, {useContext, useRef, useEffect} from 'react';
import TourContext from '@/components/ui/Tour/TourContext';
import {Button, Caption1} from '@fluentui/react-components';

import useClasses from './PopoverControls.styles';
import {PopoverControlsProps} from './PopoverControls.types';
import {useTranslation} from 'react-i18next';
import {useViewportSize} from '../Grid';

const Controls = ({
    stopNumber,
    totalPopoverStops,
    onBackClicked,
    onNextClicked,
    onRemindMeLaterClicked,
    onDoneClicked,
}: PopoverControlsProps) => {
    const classes = useClasses();
    const {nextStop, prevStop, tour, stopIndex, controls, exitTour} = useContext(TourContext);

    const {allowBack} = controls;
    const {tourStops} = tour || {tourStops: []};
    const {t} = useTranslation('tours');
    const isLastStop = stopNumber === totalPopoverStops;
    const {sm: isSmallScreen} = useViewportSize();

    const handlePrevStop = () => {
        if (onBackClicked) {
            onBackClicked();
        } else {
            prevStop();
        }
    };

    const handleNextStop = () => {
        if (onNextClicked) {
            onNextClicked();
        } else {
            nextStop();
        }
    };

    const handleRemindMeLater = () => {
        if (onRemindMeLaterClicked) {
            onRemindMeLaterClicked();
        } else {
            exitTour();
        }
    };

    const handleDone = () => {
        if (onDoneClicked) {
            onDoneClicked();
        } else {
            exitTour();
        }
    };

    // Create refs for the Next button
    const nextButtonRef = useRef<HTMLButtonElement>(null);

    // Effect to auto-focus the Next button when the component loads
    useEffect(() => {
        if (nextButtonRef.current) {
            nextButtonRef.current.focus();
        }
    }, []);

    const btnSize = isSmallScreen ? 'small' : 'medium';

    return (
        <div className={classes.root}>
            <Caption1>
                {stopNumber ?? stopIndex + 1} {t('common.of')}{' '}
                {totalPopoverStops ?? tourStops?.length}
            </Caption1>
            <div className={classes.buttons}>
                {(stopIndex === 0 || stopNumber === 1) && (
                    <Button onClick={handleRemindMeLater} appearance="transparent" size={btnSize}>
                        {t('common.remindMeLater')}
                    </Button>
                )}
                {stopIndex !== 0 && stopNumber !== 1 && (
                    <Button
                        disabled={stopIndex === 0 || !allowBack}
                        onClick={handlePrevStop}
                        appearance="outline"
                        size={btnSize}
                    >
                        {t('common.back')}
                    </Button>
                )}
                {!isLastStop && (stopNumber === undefined || stopNumber !== totalPopoverStops) && (
                    <Button
                        ref={nextButtonRef}
                        onClick={handleNextStop}
                        appearance="primary"
                        size={btnSize}
                    >
                        {t('common.next')}
                    </Button>
                )}
                {(isLastStop || (stopNumber !== undefined && stopNumber === totalPopoverStops)) && (
                    <Button
                        ref={nextButtonRef}
                        onClick={handleDone}
                        appearance="primary"
                        size={btnSize}
                    >
                        {t('common.done')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Controls;
