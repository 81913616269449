import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';

export default function useGetPersonaBasedSkillsets() {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['personas/skillsets'],
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const skillsets: string[] = await customFetch(`/personas/skillsets`, {
                method: 'GET',
            });
            return skillsets;
        },
    });
}
