import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import MedeinaFeatures from '@/util/features';

export const isGeoPodEnabled = () => {
    // check if the environment has the feature flag for geopods enabled or if its present in the url feature flags
    return (
        hasStaticFeatureFlag(MedeinaFeatures.EnableGeoPod) ||
        hasStaticFeatureFlag(MedeinaFeatures.GeoPod) ||
        hasStaticFeatureFlag(MedeinaFeatures.GeoPodUrlEnabled)
    );
};
