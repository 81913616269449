import {createHighContrastTheme} from '@fluentui/react-components';
import {highContrast} from '../colors';
import overrides from './overrides';
import {MedeinaTheme} from '../theme.types';

const theme = createHighContrastTheme();

/**
 * Uses the default high contrast theme with minor style overrides
 * and tokens present in design but not in theme.
 */
const highContrastTheme: MedeinaTheme = {
    ...theme,
    ...overrides,

    // tokens not in default theme
    colorBrandBackground2Hover: highContrast.highlight,
    colorBrandBackground2Pressed: highContrast.highlight,

    colorBrandForeground2Hover: highContrast.buttonFace,
    colorBrandForeground2Pressed: highContrast.buttonFace,

    //Security Sources
    securitySourcesBackground: highContrast.canvas,
    securitySourcesBackgroundHover: highContrast.highlight,
    securitySourcesBackgroundPressed: highContrast.highlightText,
};

export default highContrastTheme;
