import {CompoundButton, Field, Image} from '@fluentui/react-components';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import {SkillsetUploadType} from '@/api/skills';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {getImagePath} from '../Utils/ExternalConfigurationModal.utils';
import {useTranslation} from 'react-i18next';

interface UploadFormatSelectionFieldProps {
    selectedUploadType: SkillsetUploadType | undefined;
    failedValidationSettings: string[];
    onUploadTypeSelection: (uploadType: SkillsetUploadType) => void;
}

export default function UploadFormatSelectionField({
    selectedUploadType,
    failedValidationSettings,
    onUploadTypeSelection,
}: UploadFormatSelectionFieldProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <Field
            className={classes.dropdown}
            label={{
                children: (
                    <MedeinaInfoLabel
                        labelContent={t('SelectUploadFormat')}
                        infoContent={t('SelectUploadFormatContent')}
                    />
                ),
            }}
            required={true}
            validationState={failedValidationSettings.includes('format') ? 'error' : 'none'}
            validationMessage={
                failedValidationSettings.includes('format') ? t('PluginFormatRequired') : ''
            }
        >
            <div
                className={classes.buttonsRow}
                role="radiogroup"
                aria-label={t('PluginUploadFormatSelection')}
            >
                <CompoundButton
                    className={
                        selectedUploadType == SkillsetUploadType.Copilot
                            ? classes.clickedButton
                            : classes.compoundButton
                    }
                    icon={
                        <Image
                            className={classes.copilotIcon}
                            src={getImagePath('Copilot.svg')}
                            aria-hidden
                            alt=""
                        />
                    }
                    secondaryContent={t('PluginFormatsAllowed')}
                    aria-label={t('PluginFormatsCompoundLabel')}
                    role="radio"
                    aria-checked={selectedUploadType == SkillsetUploadType.Copilot}
                    value={SkillsetUploadType.Copilot}
                    onClick={() => onUploadTypeSelection(SkillsetUploadType.Copilot)}
                >
                    {t('CopilotPlugins')}
                </CompoundButton>
                <CompoundButton
                    className={
                        selectedUploadType == SkillsetUploadType.OpenAI
                            ? classes.clickedOpenAiButton
                            : classes.openAiButton
                    }
                    icon={
                        <Image
                            className={classes.copilotIcon}
                            src={getImagePath('OpenAI.svg')}
                            aria-hidden
                            alt=""
                        />
                    }
                    secondaryContent={t('PluginFormatsSecondaryContent')}
                    aria-label={t('PluginFormatsOAICompoundLabel')}
                    role="radio"
                    aria-checked={selectedUploadType == SkillsetUploadType.OpenAI}
                    value={SkillsetUploadType.OpenAI}
                    onClick={() => onUploadTypeSelection(SkillsetUploadType.OpenAI)}
                >
                    {t('OpenAIPlugins')}
                </CompoundButton>
            </div>
        </Field>
    );
}
