import React from 'react';
import {Link} from 'react-router-dom';
import {mergeClasses} from '@fluentui/react-components';
import type {NavMenuTabProps} from './NavMenuTab.types';
import {useLocation} from 'react-router-dom';
import useClasses from './NavMenuTab.styles';

/**
 * This returns a Router Link styled like a Fluent Tab.
 *
 * @param {Object} props
 */
export function NavMenuTab({to, children}: NavMenuTabProps) {
    const classes = useClasses();
    const {pathname} = useLocation();
    const selected = pathname === to;

    // Wrap in router link if there is a to
    return (
        <Link
            to={to}
            className={mergeClasses(classes.tab, selected && classes.tabSelected)}
            role="tab"
            aria-selected={selected}
        >
            <span className={mergeClasses('MenuTab__content', classes.tabContent)}>{children}</span>
        </Link>
    );
}
