import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {CreatePromptRequest, Prompt, PromptType} from './prompts.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
export default function useCreatePrompt() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: CreatePromptRequest) => {
            return await customFetch<Prompt>(`/sessions/${body?.sessionId}/prompts`, {
                method: 'POST',
                body,
            });
        },
        onSuccess: (prompt, body) => {
            changeSessionUpdatedAt(body.sessionId);
            // Refetch the parent prompt, in case this is either editing or re-running the prompt.
            queryClient.refetchQueries({
                queryKey: ['sessions', body?.sessionId, 'prompts'],
                exact: true,
            });
        },
    });
}
