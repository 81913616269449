import React, {useMemo} from 'react';
import {ConnectIcon, LibraryIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {TabbedDialog, TabbedConfiguration} from '@/components/ui/Dialog';
import {useViewportSize} from '@/components/ui/Grid';
import {ManageSourcesViewProps} from './ManageSources.types';
import PluginsTabContent from './PluginsTabContent';
import Knowledge from '@/components/ui/ConnectorModal/Knowledge';

export default function ManageSourcesView({
    defaultSelectedSkillsets,
    onChangeSkillsets,
    onOpenAdminView,
    onOpenExternalConnectorView,
    skillsetToEnable,
    canHighlightAddPlugin,
    onClose,
}: ManageSourcesViewProps) {
    const {t} = useTranslation('plugins');
    // Manage reflow
    const {sm: isSmallScreen} = useViewportSize();

    // const connectorContentScrollRef = React.useRef<HTMLDivElement | null>(null);
    // const {pluginContentScrollTop, setPluginContentScrollTop} = useConnectorModalContext();

    // Keep scroll position when navigating between plugin dialogs.
    // useEffect(() => {
    //     const connectorScroll = connectorContentScrollRef.current;
    //     if (connectorScroll && pluginContentScrollTop > 0) {
    //         connectorScroll.scrollTop = pluginContentScrollTop;
    //     }
    // });

    // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    //     if (selectedValue === 'plugins') {
    //         const target = event.target as HTMLDivElement;
    //         setPluginContentScrollTop(target.scrollTop || 0);
    //     }
    // };

    const tabConfig: TabbedConfiguration = useMemo(() => {
        return [
            {
                title: isSmallScreen ? t('PluginsText') : t('MangeSources'),
                tabTitle: t('PluginsText'),
                view: (
                    <>
                        <PluginsTabContent
                            defaultSelectedSkillsets={defaultSelectedSkillsets}
                            onChangeSkillsets={onChangeSkillsets}
                            onOpenAdminView={onOpenAdminView}
                            onOpenExternalConnectorView={onOpenExternalConnectorView}
                            skillsetToEnable={skillsetToEnable}
                            canHighlightAddPlugin={canHighlightAddPlugin}
                        />
                    </>
                ),
                value: 'plugins',
                testId: 'plugins-tab',
                tabProps: {
                    icon: <ConnectIcon />,
                },
            },
            {
                title: isSmallScreen ? t('FilesText') : t('MangeSources'),
                tabTitle: t('FilesText'),
                view: <Knowledge />,
                value: 'files',
                testId: 'files-tab',
                tabProps: {
                    icon: <LibraryIcon />,
                },
            },
        ];
    }, [
        isSmallScreen,
        t,
        defaultSelectedSkillsets,
        onChangeSkillsets,
        onOpenAdminView,
        onOpenExternalConnectorView,
        skillsetToEnable,
        canHighlightAddPlugin,
    ]);
    return (
        <>
            <TabbedDialog
                homeTitle={t('ManageSources')}
                onClose={onClose}
                tabConfiguration={tabConfig}
                dialogTitleProps={{closeLabel: t('ClosePlugins')}}
            />
        </>
    );
}
