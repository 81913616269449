import {
    MessageBar,
    MessageBarBody,
    MessageBarIntent,
    MessageBarTitle,
} from '@fluentui/react-components';
import useClasses from './AddRoles.styles';
import {ErrorMessageBarProps} from './AddRoles';
import {useTranslation} from 'react-i18next';

interface AddRoleMemberErrorMessageProps {
    error: ErrorMessageBarProps;
}

export default function AddRolesErrorMessage({error}: AddRoleMemberErrorMessageProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    if (!error || Object.keys(error).length === 0) {
        return null;
    }

    return (
        <MessageBar
            className={classes.messageBar}
            key={error.messageType}
            intent={error.messageType as MessageBarIntent}
            aria-live="assertive"
        >
            <MessageBarBody>
                <MessageBarTitle>{t(error?.title)}</MessageBarTitle>
                {t(error?.message)}
            </MessageBarBody>
        </MessageBar>
    );
}
