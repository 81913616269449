import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    fullOverlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'transparent',
        display: 'block',
        pointerEvents: 'all',
    },
    blackOverlay: {
        backgroundColor: 'black',
        opacity: 0.9,
    },
    video: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 0,
    },
});
