import GraphvizComponent from '../Graphviz/GraphvizComponent';
import SyntaxHighlighterCodeBlock from './SyntaxHighlighterCodeBlock';

export default function CodeBlock({children, className, ...props}: any) {
    // Graphviz dot files.
    if (className === 'language-dot' || className === 'language-graphviz') {
        return (
            <GraphvizComponent className={className} {...props}>
                {children}
            </GraphvizComponent>
        );
    }
    // Otherwise fallback to the syntax highlighter.
    else {
        return (
            <SyntaxHighlighterCodeBlock className={className} {...props}>
                {children}
            </SyntaxHighlighterCodeBlock>
        );
    }
}
