import {FilterAddIcon, SearchIcon, DismissIcon, FilterIcon} from '@/components/ui/icons';
import {
    Toolbar,
    Input,
    Button,
    ToolbarDivider,
    mergeClasses,
    Tooltip,
    ToggleButton,
} from '@fluentui/react-components';
import {FilterButton, FilterButtonRow} from '@/components/ui/Buttons/FilterButton';
import {useState, useEffect, useReducer} from 'react';
import useClasses from './PromptbookFilters.styles';
import {motion, AnimatePresence} from 'framer-motion';
import {useGetTenantInfo} from '@/api/tenant';
import {
    PromptbookFilterSelectedChip,
    PromptbookFiltersActions,
    PromptbookFiltersInput,
    PromptbookFiltersState,
} from './PromptbookFilters.types';
import {PromptbookAvailableFilterValue} from './PromptbookLibraryFilters/PromptbookAdvancedFilters.type';
import PromptbookFilterBar from './PromptbookLibraryFilters/PromptbookFilterBar';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {useTranslation} from 'react-i18next';

const reducer = (state: PromptbookFiltersState, action: PromptbookFiltersActions) => {
    switch (action.type) {
        case 'AllPromptbooks':
            return {
                ...state,
                selectedChip: PromptbookFilterSelectedChip.All,
            };
        case 'GlobalPromptbooks':
            return {
                ...state,
                selectedChip: PromptbookFilterSelectedChip.GlobalPromptbooks,
            };
        case 'TenantPromptbooks':
            return {
                ...state,
                selectedChip: PromptbookFilterSelectedChip.TenantPromptbooks,
            };
        case 'MyPromptbooks':
            return {
                ...state,
                selectedChip: PromptbookFilterSelectedChip.MyPromptbooks,
            };
        case 'SetExpandedSearchBar':
            return {...state, expandedSearchBar: !state.expandedSearchBar};
        case 'FilterClick':
            return {
                ...state,
                expandedFilterButton: true,
            };
        case 'CancelFilterClick':
            return {
                ...state,
                expandedFilterButton: false,
            };
        default:
            return {...state};
    }
};

//This component is used to render the promptbook filters (both the basic and advanced filters) along with search bar in the promptbook library
//This component is supplied with the selected available advanced filter values and the callback to handle the selected advanced filters changes.
export default function PromptbooksFilters(promptbookFiltersInput: PromptbookFiltersInput) {
    const classes = useClasses();
    const {data: tenantInfoData} = useGetTenantInfo();
    const initialState: PromptbookFiltersState = {
        expandedSearchBar: false,
        expandedFilterButton: false,
        selectedChip: PromptbookFilterSelectedChip.All,
        isCancelButton: false,
        availableAdvancedFilterValues: promptbookFiltersInput.availableAdvancedFilterValues,
    };

    const [availableAdvancedFilterValues, setAvailableAdvancedFilterValues] = useState<
        PromptbookAvailableFilterValue[]
    >(initialState.availableAdvancedFilterValues);

    const [reducerState, dispatch] = useReducer(reducer, initialState);

    /* This is the callback to handle the selected advanced filters changes in the filter bar component.
    When selected advanced filters changes it informs the parent component so the parent component will refetch the results
    bsed on the new selected filters.*/
    const handleSelectedFilterValuesChange = (
        selectedFilterValues: PromptbookAvailableFilterValue[],
    ) => {
        let availableFilterValueslocal = availableAdvancedFilterValues;
        if (availableFilterValueslocal !== null && availableFilterValueslocal !== undefined) {
            availableFilterValueslocal.forEach((f) => {
                let selectedFilterValue = selectedFilterValues.find(
                    (pb) =>
                        pb.FilterValueId === f.FilterValueId &&
                        pb.FilterValueType === f.FilterValueType,
                );
                if (selectedFilterValue) {
                    f.IsSelected = selectedFilterValue.IsSelected;
                } else {
                    f.IsSelected = false;
                }
            });
        }

        promptbookFiltersInput.onSelectedAdvancedFiltersChange?.(availableFilterValueslocal);
    };

    const {t} = useTranslation('promptbooks');
    const {t: tCommon} = useTranslation('common');
    useEffect(() => {
        if (promptbookFiltersInput.shouldShowAdvancedFilter) {
            dispatch({type: 'FilterClick'});
        } else {
            dispatch({type: 'CancelFilterClick'});
        }
    }, [promptbookFiltersInput.shouldShowAdvancedFilter]);

    useEffect(() => {
        setAvailableAdvancedFilterValues(promptbookFiltersInput.availableAdvancedFilterValues);
    }, [promptbookFiltersInput.availableAdvancedFilterValues]);

    useEffect(() => {
        promptbookFiltersInput.selectedChipIcon(reducerState.selectedChip);
    }, [reducerState.selectedChip]);

    useEffect(() => {
        promptbookFiltersInput.onAdvancedFilterVisibilityChange?.(
            reducerState.expandedFilterButton,
        );
    }, [reducerState.expandedFilterButton]);

    return (
        <div className={classes.root}>
            <Toolbar size="small">
                <div>
                    <AnimatePresence mode="wait" key="searchbar">
                        {!reducerState.expandedSearchBar && (
                            <Tooltip content={t('SearchPromptbooks')} relationship="label">
                                <Button
                                    onClick={() => dispatch({type: 'SetExpandedSearchBar'})}
                                    className={classes.btnSearchStart}
                                    icon={<SearchIcon />}
                                    shape="circular"
                                    data-testid="SearchPromptbooks"
                                />
                            </Tooltip>
                        )}
                        {reducerState.expandedSearchBar && (
                            <motion.div
                                key="searchBar"
                                initial={{opacity: 0.5, width: 0}}
                                animate={{opacity: 1, width: 324}}
                                transition={{duration: 0.3, ease: 'backOut'}}
                                exit={{
                                    opacity: 0,
                                    width: 20,
                                    transition: {duration: 0.3, ease: 'backOut'},
                                }}
                            >
                                <Input
                                    className={classes.searchbar}
                                    id="searchPromptbooks"
                                    type="text"
                                    autoFocus={true}
                                    placeholder={t('SearchPromptbooks')}
                                    onChange={(event: any, textbox: any) => {
                                        promptbookFiltersInput.handleSearchTextChange(
                                            textbox.value,
                                        );
                                    }}
                                    contentBefore={<SearchIcon />}
                                    appearance="underline"
                                    contentAfter={
                                        <Tooltip
                                            content={tCommon('ClearSearch')}
                                            relationship="label"
                                        >
                                            <Button
                                                onClick={() => {
                                                    promptbookFiltersInput.handleSearchTextChange(
                                                        '',
                                                    );
                                                    dispatch({type: 'SetExpandedSearchBar'});
                                                }}
                                                className={classes.subButton}
                                                size="small"
                                                shape="circular"
                                                appearance="outline"
                                                icon={<DismissIcon />}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <ToolbarDivider />
                <FilterButtonRow>
                    <FilterButton
                        onClick={() => dispatch({type: 'AllPromptbooks'})}
                        checked={reducerState.selectedChip == PromptbookFilterSelectedChip.All}
                        data-testid="AllFilter"
                    >
                        {tCommon('Filters.All')}
                    </FilterButton>
                    <FilterButton
                        onClick={() => dispatch({type: 'MyPromptbooks'})}
                        checked={
                            reducerState.selectedChip == PromptbookFilterSelectedChip.MyPromptbooks
                        }
                        data-testid="MyPromptbooksFilter"
                    >
                        {t('MyPromptbooks')}
                    </FilterButton>
                    <FilterButton
                        onClick={() => dispatch({type: 'TenantPromptbooks'})}
                        checked={
                            reducerState.selectedChip ==
                            PromptbookFilterSelectedChip.TenantPromptbooks
                        }
                        data-testid="TenantPromptbooksFilter"
                    >
                        {tenantInfoData?.name}
                    </FilterButton>
                    <FilterButton
                        onClick={() => dispatch({type: 'GlobalPromptbooks'})}
                        checked={
                            reducerState.selectedChip ==
                            PromptbookFilterSelectedChip.GlobalPromptbooks
                        }
                        data-testid="GlobalPromptbooksFilter"
                    >
                        Microsoft
                    </FilterButton>
                    {!reducerState.expandedFilterButton && (
                        /* This is the component that renders the advanced filters 
                           onAvailableSelectedFiltersChange is the callback to handle the selected advanced filters changes 
                        */
                        <PromptbookFilterBar
                            key="PromptbookFilterBar"
                            formData={
                                availableAdvancedFilterValues != null
                                    ? availableAdvancedFilterValues.filter(
                                          (f: PromptbookAvailableFilterValue) =>
                                              f.IsSelected === true,
                                      )
                                    : []
                            }
                            minimumVisibleFilters={3}
                            onAvailableSelectedFiltersChange={handleSelectedFilterValuesChange}
                            isPromptbookFilterBar={true}
                        ></PromptbookFilterBar>
                    )}
                    {!reducerState.expandedFilterButton && (
                        <Tooltip content={tCommon('Filters.AddFilters')} relationship="label">
                            <Button
                                key="AddFiltersButton"
                                onClick={() => dispatch({type: 'FilterClick'})}
                                shape="circular"
                                appearance="outline"
                                icon={<FilterAddIcon />}
                                data-testid="AddFilter"
                            />
                        </Tooltip>
                    )}
                    {reducerState.expandedFilterButton && (
                        <ToggleButton
                            key="NewFilterButton"
                            className={classes.filterToggleButton}
                            checked={true}
                            aria-label={t('NewFilter')}
                            data-testid="NewFilterButton"
                            icon={<FilterIcon className={classes.iconNewFilter} />}
                            iconPosition="before"
                            shape="circular"
                        >
                            <div className={classes.row}>
                                {t('NewFilter')}
                                <Tooltip
                                    content={tCommon('Filters.ClearFilter')}
                                    relationship="label"
                                >
                                    <Button
                                        onClick={() => dispatch({type: 'CancelFilterClick'})}
                                        shape="circular"
                                        appearance="transparent"
                                        icon={<DismissIcon />}
                                        data-testid="DismissFilter"
                                    ></Button>
                                </Tooltip>
                            </div>
                        </ToggleButton>
                    )}
                </FilterButtonRow>
            </Toolbar>
        </div>
    );
}
