import {useMemo} from 'react';
import {FluentProvider, FluentProviderProps} from '@fluentui/react-components';
import {TextDirectionProvider} from '@griffel/react';
import {getLanguage, RTL_LANGUAGES, MedeinaLanguageCode} from '@/util/locale';
import {darkTheme, lightTheme, highContrastTheme} from './custom';
import {ColorScheme, useAppState} from '@/api/app';
import {MedeinaThemes} from './theme.types';

// Set theme based on OS settings.
const defaultColorScheme: ColorScheme =
    typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches
        ? ColorScheme.Dark
        : ColorScheme.Light;

export const themes: MedeinaThemes = {
    [ColorScheme.Dark]: darkTheme,
    [ColorScheme.Light]: lightTheme,
    [ColorScheme.HighContrast]: highContrastTheme,
    // TODO: Revert to default color scheme when the light theme has UX design parity with dark.
    [ColorScheme.Default]: darkTheme,
    [ColorScheme.OS]: defaultColorScheme === 'Dark' ? darkTheme : lightTheme,
};

export function getRealColorScheme(colorScheme: ColorScheme): ColorScheme {
    if (colorScheme === ColorScheme.Default) {
        // TODO: Revert to default color scheme when the light theme has UX design parity with dark.
        return ColorScheme.Dark;
        // return defaultColorScheme;
    }
    return colorScheme;
}

export default function CustomFluentProvider(props: FluentProviderProps) {
    // Set the theme.
    const {colorScheme} = useAppState();
    const theme = useMemo(() => themes[colorScheme] ?? themes.Default, [colorScheme]);
    const dir = RTL_LANGUAGES.includes(getLanguage() as MedeinaLanguageCode) ? 'rtl' : 'ltr';
    return (
        <TextDirectionProvider dir={dir}>
            <FluentProvider {...props} theme={theme} dir={dir}>
                {props.children}
            </FluentProvider>
        </TextDirectionProvider>
    );
}
