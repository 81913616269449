import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    errorIcon: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorPaletteRedForeground1,
        marginRight: tokens.spacingHorizontalXS,
    },
    warningLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorPaletteCranberryForeground2,
    },
});
