import * as React from 'react';
import {
    SettingsScope,
    SkillsetDescriptor,
    SkillsetFilterTypes,
    SkillsetRequirementsCheckResult,
} from '@/api/skills';
import useClasses from '../../ConnectorModalContent.styles';
import ConnectorCategoryHeader from './ConnectorCategoryHeader';
import AddPluginComponent from '../Components/AddPluginComponent';
import SkillsetsSection from '../Skillsets/SkillsetsSection';
import {categoryConnectorMappings} from '../Utils/ConnectorModalContent.types';
import useConnectorModalContext from '../../Context/useConnectorModalContext';

interface ConnectorCategoryEntryProps {
    category: string;
    categoryName: string;
    categoryDescription: string;
    skillsetsInCategory: SkillsetDescriptor[];
    selectedSkillsets: Set<string>;
    configurableSkillsets: Set<string> | undefined;
    configuredSkillsets: Set<string>;
    displayedSkillsetFilter: SkillsetFilterTypes;
    onOpenAdminView?: (
        skillset: string,
        errors: SkillsetRequirementsCheckResult | null,
        scope: SettingsScope | null,
        skillsetDescriptor: SkillsetDescriptor | null,
    ) => void;
    onOpenExternalView: (
        type: string,
        skillsetDescriptor: SkillsetDescriptor | null,
        showDelete: boolean,
    ) => void;
    onCategoryChangeHandler: (category: string, checked: boolean) => void;
    onSwitchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    categorySelected: boolean;
    addPluginButtonRef: React.RefObject<HTMLButtonElement>;
    switchRefs: React.MutableRefObject<{
        [keyId: string]: HTMLInputElement | null;
    }>;
}

export default function ConnectorCategoryEntry({
    category,
    categoryName,
    categoryDescription,
    skillsetsInCategory,
    selectedSkillsets,
    configurableSkillsets,
    configuredSkillsets,
    displayedSkillsetFilter,
    onOpenAdminView,
    onOpenExternalView,
    onCategoryChangeHandler,
    onSwitchChange,
    categorySelected,
    addPluginButtonRef,
    switchRefs,
}: ConnectorCategoryEntryProps) {
    const classes = useClasses();
    const {expandedCategories, setExpandedCategories} = useConnectorModalContext();

    const categoryFilteredSkillsetsCount = skillsetsInCategory?.length ?? 0;
    const isEditableCategoryEmpty =
        categoryFilteredSkillsetsCount == 0 &&
        Object.keys(categoryConnectorMappings).includes(category);

    // Check if the category has any skillsets that are 1) non-configurable, or 2) configurable and configured.
    // If so, the category should have a switch to enable/disable the entire category.
    // Otherwise, there are no skillsets with switches, so the category switch should be disabled.
    const enableCategoryControlSwitch = skillsetsInCategory.some((skillset) => {
        const isConfigurable = configurableSkillsets?.has(skillset.name);
        if (!isConfigurable) {
            return true;
        }
        const isConfigured = configuredSkillsets.has(skillset.name);
        return isConfigured;
    });

    const handleExpandCollapse = (expanded: boolean) => {
        const newExpandedCategories = new Set(expandedCategories);
        if (expanded) {
            newExpandedCategories.add(category);
        } else {
            newExpandedCategories.delete(category);
        }
        setExpandedCategories(newExpandedCategories);
    };

    return (
        <div key={category} className={classes.category} data-testid={'skillset-category'}>
            <ConnectorCategoryHeader
                category={category}
                categoryDisplayName={categoryName}
                categoryDescription={categoryDescription}
                categoryChecked={categorySelected && enableCategoryControlSwitch}
                enableSwitch={enableCategoryControlSwitch}
                categoryFilteredSkillsetsCount={categoryFilteredSkillsetsCount}
                onCategorySelected={(checked) => onCategoryChangeHandler(category, checked)}
                onOpenExternalView={() => onOpenExternalView(category, null, false)}
            />
            {isEditableCategoryEmpty ? (
                <AddPluginComponent
                    category={category}
                    onAddPluginClick={() => onOpenExternalView(category, null, false)}
                    buttonRef={addPluginButtonRef}
                />
            ) : (
                <SkillsetsSection
                    skillsetsInSection={skillsetsInCategory}
                    selectedSkillsets={selectedSkillsets}
                    configurableSkillsets={configurableSkillsets}
                    configuredSkillsets={configuredSkillsets}
                    displayedSkillsetFilter={displayedSkillsetFilter}
                    onOpenAdminView={onOpenAdminView}
                    onSwitchChange={onSwitchChange}
                    isExpanded={expandedCategories.has(category)}
                    onExpandCollapse={handleExpandCollapse}
                    switchRefs={switchRefs}
                />
            )}
        </div>
    );
}
