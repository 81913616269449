import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground2,
        top: 0,
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    vectorBackground: {
        height: 'calc(100vh)',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
    },
});
