import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    alert: {
        ...shorthands.borderTop('1px', 'solid', tokens.colorPaletteRedBorder1),
        ...shorthands.borderBottom('1px', 'solid', tokens.colorPaletteRedBorder1),
        backgroundColor: tokens.colorStatusDangerBackground1,
        display: 'flex',
        ...shorthands.padding(tokens.spacingHorizontalMNudge, tokens.spacingHorizontalM),
        alignItems: ' center',
        flexGap: '8px',
        alignSelf: 'stretch',
        position: 'absolute',
        top: '80px',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 2000,
        maxWidth: '800px',
    },
});
