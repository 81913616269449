import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    centeredText: {
        textAlign: 'center',
    },
    centeredBlock: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    sectionSpacing: {
        marginBottom: tokens.spacingVerticalM,
    },
    textWrapper: {
        width: '100%',
        maxWidth: '600px',
    },
    progressBar: {
        position: 'fixed',
    },
    blockTitle: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '40px',
        lineHeight: '52px',
        color: tokens.colorNeutralForeground1,
    },

    //general styles, can possible go even 1 level higher
    listBoxHeight: {
        maxHeight: '200px',
        height: 'auto',
    },
});
