import {DeleteIcon} from '@/components/ui/icons';
import {Button, Spinner, mergeClasses} from '@fluentui/react-components';
import useClasses, {buttonFocus, buttonHover} from './RemoveMemberButton.styles';
import {useState} from 'react';
import {memberHoverFocus} from './RoleSection.styles';
import {useTranslation} from 'react-i18next';

interface RemoveMemberButtonProps {
    canBeDeleted: boolean;
    onRemoveButtonClick?: () => void;
    updatePolicyLoading: boolean;
    policyProcessed: boolean;
}

export default function RemoveMemberButton(props: RemoveMemberButtonProps) {
    const classes = useClasses();
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const {t} = useTranslation('admin');

    return (
        <>
            {!props.canBeDeleted && (
                <span className={mergeClasses(classes.remove, memberHoverFocus)}>
                    {t('RoleAssignment.RoleGroup.RemoveAction.CanNotRemoved')}
                </span>
            )}

            {props.canBeDeleted && (
                <span className={mergeClasses(classes.remove, memberHoverFocus)}>
                    <Button
                        className={mergeClasses(classes.removeButton)}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => props.onRemoveButtonClick?.()}
                        aria-label={t('RoleAssignment.RoleGroup.AriaLabels.RemoveMember')}
                    >
                        {props.updatePolicyLoading || !props.policyProcessed ? (
                            <Spinner size="tiny"></Spinner>
                        ) : (
                            <>
                                <span>
                                    <DeleteIcon
                                        className={mergeClasses(
                                            buttonHover,
                                            buttonFocus,
                                            classes.deleteIcon,
                                        )}
                                        filled={isHovered}
                                    />
                                </span>
                                <span>{t('RoleAssignment.RoleGroup.RemoveAction.Remove')}</span>
                            </>
                        )}
                    </Button>
                </span>
            )}
        </>
    );
}
