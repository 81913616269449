import {useMemo} from 'react';
import {FileDescriptor} from '@/api/files';
import Fuse from 'fuse.js';

const useFileSearch = (files: FileDescriptor[], searchText: string): FileDescriptor[] => {
    const filesList = useMemo(() => new Fuse(files, {keys: ['fileName']}), [files]);
    const searchResult = useMemo(() => {
        if (searchText && filesList) {
            return filesList.search(searchText).map((result) => result.item);
        } else if (files) {
            return files;
        } else {
            return [];
        }
    }, [searchText, files, filesList]);

    return searchResult;
};

export default useFileSearch;
