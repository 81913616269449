import {DeleteSessionRequest, SessionSelectionType} from '@/api/sessions';
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import {useState} from 'react';
import useClasses from './DeleteSessionDialog.styles';
import {DismissIcon} from '@/components/ui/icons';
import useDeleteSessions from '@/api/sessions/useDeleteSessions';
import {useTranslation} from 'react-i18next';

export default function DeleteSessionDialog({
    sessionId = undefined,
    sessionIds,
    open,
    onClose = () => null,
    onSuccess = () => null,
}: DeleteSessionRequest) {
    const classes = useClasses();
    const {t} = useTranslation(['mysessions']);
    const {mutate: deleteSessions, isLoading: isLoading} = useDeleteSessions();
    const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
    const selectionType =
        sessionId !== undefined || sessionIds === undefined || sessionIds.length <= 1
            ? SessionSelectionType.Single
            : SessionSelectionType.Multiple;

    return (
        <Dialog
            open={open}
            onOpenChange={(event, data) => {
                setIsOpen(data.open);
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    onClose?.();
                }
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label={t('CloseButton')}
                                    icon={<DismissIcon />}
                                    onClick={() => onClose?.()}
                                />
                            </DialogTrigger>
                        }
                    >
                        {selectionType === SessionSelectionType.Single
                            ? t('DeleteSessionDialogTitleSingle')
                            : t('DeleteSessionDialogTitleMultiple', {0: sessionIds?.length || 0})}
                    </DialogTitle>
                    <DialogContent>
                        {selectionType === SessionSelectionType.Single
                            ? t('DeleteSessionDialogConfirmationSingle')
                            : t('DeleteSessionDialogConfirmationMultiple')}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={() => onClose?.()}>
                                {t('CancelButton')}
                            </Button>
                        </DialogTrigger>
                        <Button
                            appearance="primary"
                            disabled={isLoading}
                            onClick={() => {
                                if (sessionId !== undefined) {
                                    sessionIds = [sessionId];
                                }

                                deleteSessions({sessionIds}, {onSuccess});
                            }}
                        >
                            {t('DeleteSessionDeleteButton')}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
