import SessionsOptionsBreadcrumb from '@/components/sections/investigations/SessionOptionsBreadcrumb';

// Use this to exclude the breadcrumb component from certain routes,
// this will not hide the main Security Copilot header
// this will not prevent the route from being navigated to
export const excludedRoutesFromBreadcrumb = [
    '/auth/v1/callback',
    '/start',
    '/tour/admin',
    '/tour/welcome',
];
// we'll still need this so that we can map paths that will be using custom components
// the key should be a route id from MedeinaRoutes
export const pathBreadcrumbDisplay: any = {
    // these need to match keys in the translation file
    '/sessions': 'BreadcrumbMySessions',
    '/sessions/:sessionId': (params: any) => (
        <SessionsOptionsBreadcrumb sessionId={params.sessionId} />
    ),
    '/promptbooks': 'BreadcrumbPromptbookLibrary',
    '/promptbooks/:promptbookId': (params: any) => '',
    '/owner-settings': 'BreadcrumbOwnerSettings',
    '/usage-monitoring': 'BreadcrumbUsageMonitoring',
    '/role-assignment': 'BreadcrumbRoleAssignment',
    '/manage-workspaces': 'BreadcrumbManageWorkspaces',
    '/plugin-settings': 'BreadcrumbPluginSettings',
};

// These keys correspond to the breadcrumbs in the translation file
// a segment should match exactly to what would be in the url
// common.json > Breadcrumbs.[key]
export const segmentKeys: Record<string, string> = {
    sessions: 'mysessions',
    promptbooks: 'promptbooks',
    'owner-settings': 'ownersettings',
    'manage-workspaces': 'manageworkspaces',
    'usage-monitoring': 'usagemonitoring',
    'role-assignment': 'roleassignment',
    'plugin-settings': 'pluginsettings',
    new: 'newsession',
};

export default pathBreadcrumbDisplay;
