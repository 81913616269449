import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalXXL),
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
    },
    icon: {
        ...shorthands.flex(0, 0, 'auto'),
        marginRight: tokens.spacingHorizontalS,
        fontSize: '120%',
        lineHeight: tokens.lineHeightBase300,
        '& svg': {
            lineHeight: tokens.lineHeightBase300,
            marginTop: tokens.spacingVerticalXS,
        },
    },
    message: {
        ...shorthands.flex(1, 1, 'auto'),
        lineHeight: tokens.lineHeightBase300,
    },
    info: {
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground1,
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralStroke1),
    },
    warning: {
        backgroundColor: tokens.colorPaletteDarkOrangeBackground1,
        color: tokens.colorPaletteDarkOrangeForeground2,
        ...shorthands.borderBottom('1px', 'solid', tokens.colorPaletteDarkOrangeBackground2),
        ...shorthands.borderTop('1px', 'solid', tokens.colorPaletteDarkOrangeBackground2),
    },
});
