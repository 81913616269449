import {Button, Image, Text} from '@fluentui/react-components';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import {SkillsetDescriptor} from '@/api/skills';
import {DeleteIcon} from '@/components/ui/icons';
import {getImagePath} from '../Utils/ExternalConfigurationModal.utils';
import {useTranslation} from 'react-i18next';

interface DeleteConfirmationDialogProps {
    selectedConnector?: SkillsetDescriptor | null;
    handleDelete: () => void;
    handleConnectorReturn: () => void;
}

export default function DeleteConfirmationDialog({
    selectedConnector,
    handleDelete,
    handleConnectorReturn,
}: DeleteConfirmationDialogProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <div className={classes.confirmationModal}>
            <Image
                className={classes.resetIcon}
                height={48}
                width={48}
                fit="default"
                src={getImagePath('Default.svg')}
                alt=""
                aria-hidden
            />
            <Text className={classes.resetTitleText}>
                {t('DeleteExternalPluginText', {pluginName: selectedConnector?.displayName})}
            </Text>
            <Text className={classes.resetText}>{t('ExternalPluginResetText')}</Text>
            <div className={classes.resetButtons}>
                <Button
                    className={classes.resetButton}
                    appearance="subtle"
                    size="medium"
                    icon={<DeleteIcon />}
                    onClick={handleDelete}
                >
                    {t('common.Delete')}
                </Button>
                <Button
                    className={classes.cancelButton}
                    appearance="subtle"
                    size="medium"
                    onClick={handleConnectorReturn}
                >
                    {t('common.Cancel')}
                </Button>
            </div>
        </div>
    );
}
