import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    dailyTips: {
        marginLeft: '100px',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(tokens.spacingVerticalMNudge, 0),
        maxWidth: '85%',
        wordBreak: 'break-word',
    },
    btn: {
        ...shorthands.borderRadius('200px'),
        paddingLeft: tokens.spacingHorizontalXS,
        justifyContent: 'flex-start',
        backgroundColor: tokens.colorTransparentBackground,
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            backgroundColor: tokens.colorTransparentBackground,
            color: 'inherit',
        },
    },
    btnDisabled: {
        ...shorthands.borderRadius('200px'),
        paddingLeft: tokens.spacingHorizontalXS,
        justifyContent: 'flex-start',
        backgroundColor: tokens.colorTransparentStroke,
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        pointerEvents: 'none',
    },
});
