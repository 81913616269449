import React from 'react';
import {useAppState} from '@/api/app';
import {useLayout} from '@/components/ui/Layout';

import {Background} from '@/components/ui/Background';

// Component that we'll use to transition backgrounds from different views
// In the future this could have other reactive effects depending on user actions
export default function BackgroundProvider() {
    const {background} = useLayout();
    // check for the current theme
    const {colorScheme} = useAppState();

    return (
        <Background
            image={background?.image}
            animation={background?.animation}
            colorScheme={colorScheme}
        />
    );
}
