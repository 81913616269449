import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {NavigationBarHeight} from '@/components/App.styles';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 'auto'),
        maxWidth: '85%',
        minHeight: '100%',
    },

    title: {
        ...shorthands.margin(
            `calc(${NavigationBarHeight}px + ${tokens.spacingHorizontalL})`,
            tokens.spacingHorizontalXS,
            tokens.spacingHorizontalXXL,
        ),
        lineHeight: tokens.lineHeightBase300,
        fontSize: tokens.fontSizeBase600,
    },
    subtitle: {
        paddingLeft: tokens.spacingHorizontalXS,
    },

    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
    },
    skillFormWrapper: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
    },

    // For grid
    filteringInvestigations: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalS),
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase400,
        ),
    },
    filterDialog: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalS),
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase400,
        ),
    },
    gridCell: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        ...shorthands.padding('0', tokens.spacingHorizontalL),
    },
    headerCell: {
        '& > button': {
            paddingBottom: tokens.spacingVerticalS,
            paddingTop: tokens.spacingVerticalS,
        },
    },
    overflowContent: {
        wordBreak: 'break-word',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: '-webkit-box',
    },
    overflowNameContent: {
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: '-webkit-box',
        maxWidth: '90%',
    },
    description: {
        // whiteSpace: 'nowrap',
        lineHeight: tokens.lineHeightBase200,
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        maxWidth: '90%',
    },
    row: {
        ...shorthands.margin(tokens.spacingVerticalS, 'auto', tokens.spacingVerticalS),
    },
    promptCount: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,

        '& svg': {
            fontSize: tokens.fontSizeBase400,
            marginBottom: '-4px',
        },
    },
    runIconHeader: {
        ...shorthands.margin('auto', tokens.spacingHorizontalXL),
        fontSize: tokens.fontSizeBase500,
    },
    runButton: {
        ...shorthands.margin('auto', tokens.spacingHorizontalM),
    },
    stickyActions: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalL,
        backgroundColor: tokens.colorNeutralBackground2,
    },
    inputsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '2',
        flexDirection: 'row',
        width: '95%',
    },
    input: {
        height: tokens.fontSizeBase500,
        minWidth: 'auto',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    highlight: {
        fontWeight: tokens.fontWeightBold,
        backgroundColor: 'inherit',
        color: 'inherit',
    },
});
