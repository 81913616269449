import {fileExtension} from '@/components/sections/investigations/ExportSummary.types';
import {saveAs} from 'file-saver';
import ReactDOMServer from 'react-dom/server';
import htmlDocx from 'html-docx-js/dist/html-docx';
import {DocumentDownloadMarkdown} from '@/components/ui/Markdown';

// Download the text as a word document with the specified file name and summary.
const downloadWordDocument = async (summary: string, fileName: string) => {
    const htmlContent = ReactDOMServer.renderToString(
        <DocumentDownloadMarkdown>{summary}</DocumentDownloadMarkdown>,
    );

    const contentBlob = htmlDocx.asBlob(htmlContent);
    saveAs(contentBlob, `${fileName}.${fileExtension.docx}`);
};

export default downloadWordDocument;
