import * as React from 'react';
import {useState} from 'react';
import ConnectorModalContext, {InitialConnectorContext} from './ConnectorModalContext';

interface ConnectorModalContextProviderType {
    children: React.ReactNode;
}

export const ConnectorModalContextProvider = ({children}: ConnectorModalContextProviderType) => {
    // Tracks plugin category sections the user expanded/collapsed. Keeps state when navigating in the modal.
    const [expandedCategories, setExpandedCategories] = useState<Set<string>>(
        InitialConnectorContext.expandedCategories,
    );
    // Tracks the scroll position of the plugin content section. Keeps state when navigating in the modal.
    const [pluginContentScrollTop, setPluginContentScrollTop] = useState<number>(
        InitialConnectorContext.pluginContentScrollTop,
    );

    const value = {
        expandedCategories,
        setExpandedCategories,
        pluginContentScrollTop,
        setPluginContentScrollTop,
        resetConnectorContext: () => {
            setExpandedCategories(InitialConnectorContext.expandedCategories);
            setPluginContentScrollTop(InitialConnectorContext.pluginContentScrollTop);
        },
    };
    return (
        <ConnectorModalContext.Provider value={value}>{children}</ConnectorModalContext.Provider>
    );
};
