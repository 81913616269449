import {InteractionStatus} from '@azure/msal-browser';
import {useMsal, useIsAuthenticated} from '@azure/msal-react';
import {Button} from '@fluentui/react-components';
import {useEffect} from 'react';
import {signIn, signOut} from '../util/msal/authConfig';
import {useTranslation} from 'react-i18next';

export default function SignInSignOutButton() {
    const {instance, inProgress} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const {t} = useTranslation();

    useEffect(() => {
        const automaticLoginRedirect = async () => {
            if (
                !isAuthenticated &&
                inProgress !== InteractionStatus.Startup &&
                inProgress !== InteractionStatus.HandleRedirect
            ) {
                await signIn(instance);
            }
        };
        automaticLoginRedirect();
    }, []);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <Button onClick={() => signOut(instance)} data-testid="signOut" appearance="subtle">
            {t('SettingsSignoutButton')}
        </Button>
    );
}
