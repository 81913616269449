import {mergeClasses} from '@fluentui/react-components';
import useClasses from './ExploreCardList.styles';
import {ExploreCardListProps} from './ExploreCopilot.types';

export default function ExploreCardList({children, className}: ExploreCardListProps) {
    const classes = useClasses();
    return (
        <div className={mergeClasses(classes.root, className)} data-testid="card-list">
            {children}
        </div>
    );
}
