import {useState, useEffect, useMemo} from 'react';
import useClasses from './PromptbookAdvancedFilters.styles';
import {
    Button,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemCheckbox,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
} from '@fluentui/react-components';
import type {MenuProps} from '@fluentui/react-components';

import {
    PromptbookAdvancedFilterProps,
    PromptbookAvailableFilterValue,
    PromptbookFilterValueType,
} from './PromptbookAdvancedFilters.type';
import PromptbookFilterBar from './PromptbookFilterBar';
import {cloneDeep} from 'lodash-es';
import {ColorScheme, useAppState} from '@/api/app';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {useTranslation} from 'react-i18next';

function getPromptbookSearchImage(colorScheme: ColorScheme) {
    switch (colorScheme) {
        case ColorScheme.Dark:
        case ColorScheme.HighContrast:
            return '/images/features/promptbooks/PromptbookSearchDark.svg';
        case ColorScheme.Light:
            return '/images/features/promptbooks/PromptbookSearchLight.svg';
        default:
            return '/images/features/promptbooks/PromptbookSearchDark.svg';
    }
}

// This is a component that allows the user to select advanced filters (both the filter on Tags, Plugins) for the promptbook library.
// The list of PromptbookAvailableFilterValue elements supplied in props are displayed in the form of a menu. The user can select the filters from the menu and apply them.
// The selected filters are then displayed in the form of chips in the PromptbookFilterBar component.
export default function PromptbookAdvancedFilter(props: PromptbookAdvancedFilterProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {colorScheme: color} = useAppState();
    const {t} = useTranslation('promptbooks');
    const [availableFilterValues, setAvailableFilterValues] = useState<
        PromptbookAvailableFilterValue[]
    >(cloneDeep(props.availableFilterValues));

    const availableTagFilterValues: PromptbookAvailableFilterValue[] = useMemo(() => {
        if (availableFilterValues === undefined) return [];
        return availableFilterValues.filter(
            (availableFilterValue) =>
                availableFilterValue !== undefined &&
                availableFilterValue.FilterValueType === PromptbookFilterValueType.Tag,
        );
    }, [availableFilterValues]);

    const handleApplyClick = () => {
        props.onSelectedAdvancedFiltersChange?.(availableFilterValues);
        props.onAdvancedFilterVisibilityChange?.(false);
    };

    function ApplyCheckedValues() {
        return {
            availableTagFilterValuesMenuItemCheckbox:
                availableFilterValues != null
                    ? availableFilterValues
                          .filter(
                              (f: PromptbookAvailableFilterValue) =>
                                  f.IsSelected === true &&
                                  f.FilterValueType === PromptbookFilterValueType.Tag,
                          )
                          .map((f: PromptbookAvailableFilterValue) => f.FilterValueId)
                    : [],
        };
    }

    const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>(
        ApplyCheckedValues(),
    );

    /* This event handles the changes that are happening the PromptbookAdvancedFilterBar component, ex: user deleting an selected chip */
    const handleSelectedFilterValuesChangeInFilterbar = (
        selectedFilterValues: PromptbookAvailableFilterValue[],
    ) => {
        let availableFilterValueslocal = cloneDeep(availableFilterValues);
        if (availableFilterValueslocal !== null && availableFilterValueslocal !== undefined) {
            availableFilterValueslocal.forEach((f) => {
                let selectedAvailableFilterValue = selectedFilterValues.find(
                    (pb) =>
                        pb.FilterValueId === f.FilterValueId &&
                        pb.FilterValueType === f.FilterValueType,
                );
                if (selectedAvailableFilterValue) {
                    f.IsSelected = selectedAvailableFilterValue.IsSelected;
                } else {
                    f.IsSelected = false;
                }
            });
        }

        setAvailableFilterValues(availableFilterValueslocal);
        setCheckedValues(ApplyCheckedValues());
    };

    /* This event handles the changes when items in tags or plugins menu items are checked or unchecked.
     based on the current checked items the corresponding PromptbookAvailableFilterValue object's IsSelected property is set.
     The corresponding object is  present in availableFilterValues collection state variable is */
    const onChange: MenuProps['onCheckedValueChange'] = (e, {name, checkedItems}) => {
        let availableFilterValueslocal = cloneDeep(availableFilterValues);
        if (availableFilterValueslocal === null || availableFilterValueslocal === undefined) {
            return;
        }
        switch (name) {
            case 'availableTagFilterValuesMenuItemCheckbox':
                availableFilterValueslocal
                    .filter((f) => f.FilterValueType === PromptbookFilterValueType.Tag)
                    .forEach((availableFilterValue) => {
                        if (checkedItems.includes(availableFilterValue.FilterValueId)) {
                            availableFilterValue.IsSelected = true;
                        } else {
                            availableFilterValue.IsSelected = false;
                        }
                    });
                break;
            default:
                break;
        }

        setCheckedValues((s) => {
            return s ? {...s, [name]: checkedItems} : {[name]: checkedItems};
        });
        setAvailableFilterValues(availableFilterValueslocal);
    };

    return (
        <div className={classes.root}>
            <Image
                src={getPromptbookSearchImage(color)}
                data-testid="promptbook-search-image"
                className={classes.searchImage}
                fit="default"
                role="none"
            />
            <p className={classes.filterLabelText}>{t('FilterBar.Description')}</p>
            <div className={classes.filterOptions}>
                <div className={classes.filterSelect}>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton shape="circular" data-testid="tags-selection">
                                Tag
                            </MenuButton>
                        </MenuTrigger>

                        <MenuPopover
                            className={mergeClasses(
                                classes.menuPopover,
                                scrollClasses.colorNeutralBackground1,
                            )}
                        >
                            {availableTagFilterValues.length > 0 && (
                                <MenuList
                                    checkedValues={checkedValues}
                                    onCheckedValueChange={onChange}
                                >
                                    {availableTagFilterValues.map((availableFilterValue) => (
                                        <MenuItemCheckbox
                                            name="availableTagFilterValuesMenuItemCheckbox"
                                            value={availableFilterValue.FilterValueId}
                                            key={availableFilterValue.FilterValueId}
                                            data-testid={
                                                'promptbook-availabletag-' +
                                                availableFilterValue.FilterValueId
                                            }
                                        >
                                            {availableFilterValue.FilterValueName}
                                        </MenuItemCheckbox>
                                    ))}
                                </MenuList>
                            )}
                            {availableTagFilterValues.length <= 0 && (
                                <MenuList>
                                    <MenuItem data-testid="NotagsAvaialble">
                                        {t('FilterBar.NoTagsAvailable')}
                                    </MenuItem>
                                </MenuList>
                            )}
                        </MenuPopover>
                    </Menu>
                </div>
                <PromptbookFilterBar
                    formData={
                        availableFilterValues != null
                            ? availableFilterValues.filter(
                                  (f: PromptbookAvailableFilterValue) => f.IsSelected === true,
                              )
                            : []
                    }
                    minimumVisibleFilters={3}
                    onAvailableSelectedFiltersChange={handleSelectedFilterValuesChangeInFilterbar}
                    isPromptbookFilterBar={false}
                ></PromptbookFilterBar>
                <Button appearance="primary" onClick={() => handleApplyClick()}>
                    Apply
                </Button>
            </div>
        </div>
    );
}
