import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {CreateEvaluationRequest, EvaluationCreationResponse} from './evaluations.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist, useClearSessionSummary} from '../sessions';
import {featureFlags} from '@/util/hasStaticFeatureFlags';

export default function useCreateEvaluation() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: clearSessionSummary} = useClearSessionSummary();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: CreateEvaluationRequest) => {
            const featureFlagValues = featureFlags();
            const queryParams = !!featureFlagValues ? `?featureFlags=${featureFlagValues}` : '';

            const {evaluation} = await customFetch<EvaluationCreationResponse>(
                `/sessions/${body?.sessionId}/prompts/${body?.promptId}/evaluations${queryParams}`,
                {
                    method: 'POST',
                    body,
                },
            );
            return evaluation;
        },
        onSuccess: (evaluation, body) => {
            if (body) changeSessionUpdatedAt(body.sessionId);
            // Refetch the parent prompt, in case this is either editing or re-running the prompt.
            clearSessionSummary({sessionId: evaluation.sessionId});
            queryClient.refetchQueries({
                queryKey: ['sessions', body?.sessionId, 'prompts', body?.promptId],
            });
        },
    });
}
