import {LayoutState} from '@/components/ui/Layout/Layout.types';

export enum ColorScheme {
    Default = 'Default',
    Light = 'Light',
    Dark = 'Dark',
    HighContrast = 'High Contrast',
    OS = 'OS',
}

export enum DebugLevel {
    Summary = 'Summary',
    Verbose = 'Verbose',
}

export interface UserTours {
    [key: string]: TourHistory | null;
}

export interface TourHistory {
    started?: number;
    exited?: number;
    completed?: number;
    lastStopName?: string;
}

export type AppState = {
    colorScheme: ColorScheme;
    debugLevel: DebugLevel;
    userTours: UserTours;
    layout: LayoutState;
};

export type AppStateObject = AppState & {
    setColorScheme: (debugLevel: ColorScheme) => void;
    setDebugLevel: (debugLevel: DebugLevel) => void;
    setUserTour: (tourId: string, tourHistory: TourHistory) => void;
    updateLayout: (layout: Partial<LayoutState>) => void;
};

export type AuthorizedResponse = {
    isAuthorized: boolean;
    authorizedCode: string;
    message?: string;
};

export type ExpiryDateResponse = {
    expiryDate: string | null;
};

export type PromptEvaluationLocationKey = 'Allowed' | 'NotAllowed';
