import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground4,
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding('5px'),
        marginTop: '17.5px',
    },
    svg: {
        height: '600px',

        // Remove the generated svg background color.
        '& svg > g > polygon': {
            fill: 'transparent',
        },

        '& g g text': {
            fill: tokens.colorNeutralForeground2,
        },

        '& g g ellipse, & g g path, & g g polygon': {
            fill: 'transparent',
            stroke: tokens.colorNeutralForeground2,
        },

        '& svg:first-of-type': {
            width: '100%',
        },
    },
    popoverContent: {
        lineHeight: tokens.lineHeightBase400,
    },
});
