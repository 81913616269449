import {mergeClasses} from '@fluentui/react-components';
import useClasses from './PromptContent.styles';
import RerunPromptDialog from './RerunPromptDialog';
import {PromptBar} from '@/components/ui';
import {useEffect, useMemo, useState} from 'react';
import {useGetPrompt, useGetPrompts, useUpdatePrompt} from '@/api/prompts';
import {useGetSession} from '@/api/sessions';
import PromptContentLabel from './PromptContentLabel';
import {
    EvaluationLogFormat,
    EvaluationState,
    useCreateEvaluation,
    useGetEvaluation,
} from '@/api/evaluations';
import {useTourable} from '@/components/ui/Tour/useTourable';
import {PromptContentProps} from './Prompt.types';

export default function PromptContent(props: PromptContentProps) {
    const {
        sessionId,
        promptId,
        editMode,
        setEditMode,
        setErrorMessage,
        restoreFocusSourceAttribute,
    } = props;
    const tourRef = useTourable({id: 'promptContentBar'});
    const classes = useClasses();
    const {mutate: updatePrompt} = useUpdatePrompt();
    const {mutate: createEvaluation} = useCreateEvaluation();
    const {data: session} = useGetSession({sessionId});
    const {data: prompt} = useGetPrompt({sessionId, promptId});

    const evaluation = useGetEvaluation({
        sessionId,
        promptId,
        evaluationId: prompt?.latestEvaluationId,
    }).data;

    // Enable Edit mode immediately after an evaluation is cancelled, but not after page refresh or reload of evaluations.
    const [initialEvaluationState, setInitialEvaluationState] = useState<EvaluationState | null>(
        null,
    );

    const [hasDetailedEvaluationState, setHasDetailedEvaluationState] = useState<boolean>(false);
    const promptHeaderClasses = mergeClasses(
        classes.promptHeader,
        hasDetailedEvaluationState && classes.promptHeaderOnSummary,
    );
    const promptRootClasses = mergeClasses(
        classes.root,
        hasDetailedEvaluationState && classes.detailedRoot,
    );

    useEffect(() => {
        if (
            (initialEvaluationState === null ||
                initialEvaluationState == EvaluationState.Cancelled) &&
            evaluation?.state
        ) {
            setInitialEvaluationState(evaluation.state);
        }
    }, [evaluation?.state]);

    useEffect(() => {
        if (!!evaluation?.logFormat && evaluation.logFormat === EvaluationLogFormat.StepLogs) {
            setHasDetailedEvaluationState(true);
        }
    }, [evaluation?.logFormat]);

    const evaluationCancelledAfterSubmit =
        evaluation?.state === EvaluationState.Cancelled &&
        initialEvaluationState &&
        initialEvaluationState !== EvaluationState.Cancelled;

    useEffect(() => {
        if (evaluationCancelledAfterSubmit) {
            setEditMode('Edit');
        }
    }, [evaluationCancelledAfterSubmit]);

    // Determine if this is the last prompt in the session (skip dialogs).
    const {data: nextPrompts} = useGetPrompts({sessionId, lastPromptId: promptId});
    const isLastPrompt = useMemo<boolean>(() => {
        return nextPrompts?.value.length === 0 || false;
    }, [nextPrompts]);

    return (
        <div className={promptRootClasses} ref={tourRef}>
            {editMode ? (
                <div className={classes.content}>
                    <PromptBar
                        editMode
                        defaultSkillsets={session?.skillsets}
                        defaultPrompt={prompt}
                        onCancel={() => setEditMode(false)}
                        restoreFocusSourceAttribute={restoreFocusSourceAttribute}
                        onSubmit={(prompt) => {
                            updatePrompt(
                                {
                                    ...prompt,
                                    sessionId,
                                    promptId,
                                },
                                {
                                    onSuccess: (prompt) => {
                                        // If this is the last prompt, skip the dialog.
                                        if (isLastPrompt) {
                                            createEvaluation({
                                                sessionId,
                                                promptId,
                                            });
                                            setEditMode(false);
                                        } else {
                                            setEditMode('Rerun');
                                        }
                                    },
                                },
                            );
                        }}
                    />
                    {!isLastPrompt && (
                        <RerunPromptDialog
                            {...{sessionId, promptId}}
                            open={editMode === 'Rerun'}
                            onClose={() => {
                                setEditMode(false);
                            }}
                            setErrorMessage={setErrorMessage}
                        />
                    )}
                </div>
            ) : (
                <div data-testid="prompt-query" className={promptHeaderClasses}>
                    <PromptContentLabel prompt={prompt} />
                </div>
            )}
        </div>
    );
}
