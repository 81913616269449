import {useGetUserInfo} from '@/api/app';
import {WorkspaceDetachedForAdmin, WorkspaceDetachedForNonAdmin} from '.';
import MedeinaFeatures from '@/util/features';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useFeatureFlag} from '@/api/user';

// The idea is to be able to use this bar here henceforth for all banner needs
// as the Alert control is deprecated
// The implementation is to be deprecated as well
export default function InfoMessageBar() {
    // const {isWorkspaceDetached, isProviderLoaded} = useWorkspaceState();
    // const {data: userInfo, isFetched: isUserInfoFetched} = useGetUserInfo();
    // const isFidelisEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisRBAC);

    // let banner: React.ReactNode = null;

    // if (isFidelisEnabled && isProviderLoaded && isWorkspaceDetached && isUserInfoFetched) {
    //     if (userInfo?.isAdmin ?? false) {
    //         banner = <WorkspaceDetachedForAdmin />;
    //     } else {
    //         banner = <WorkspaceDetachedForNonAdmin />;
    //     }
    // }

    // return <>{banner}</>;
    return <></>;
}
