import {useMutation} from '@tanstack/react-query';
import useSessionsFetch from '../useSessionsFetch';
import {
    GetSessionSharingTokenRequest,
    SessionSharingToken,
    SessionSharingTokenRequest,
} from './sessions.types';

export default function useShareSession({sessionId}: GetSessionSharingTokenRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useMutation({
        mutationFn: async (body: SessionSharingTokenRequest) => {
            return await sessionsFetch<SessionSharingToken>(`${sessionId}/sharingtoken`, {
                method: 'POST',
                body: {
                    ...body,
                    // TODO: Remove mandatory settings when new use cases are introduced.
                    role: 'viewer',
                    global: false,
                },
            });
        },
    });
}
