import {DeleteIcon} from '@/components/ui/icons';
import {Button, Spinner, mergeClasses} from '@fluentui/react-components';
import useClasses, {buttonFocus, buttonHover} from './ViewRolesButton.styles';
import {useState} from 'react';
import {memberHoverFocus} from './RoleSection.styles';
import {useTranslation} from 'react-i18next';
import {Eye24Regular} from '@fluentui/react-icons';

interface RemoveMemberButtonProps {
    onViewRolesButtonClick?: () => void;
    updatePolicyLoading: boolean;
    policyProcessed: boolean;
}

export default function ViewRolesButton(props: RemoveMemberButtonProps) {
    const classes = useClasses();
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const {t} = useTranslation('admin');

    return (
        <>
            <span className={mergeClasses(classes.viewRoles, memberHoverFocus)}>
                <Button
                    appearance="subtle"
                    className={mergeClasses(classes.viewRolesButton)}
                    onClick={() => props.onViewRolesButtonClick?.()}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                    aria-label={t('RoleAssignment.AddRoles.AriaLabels.ViewRoles')}
                    icon={<Eye24Regular />}
                >
                    {t('RoleAssignment.AddRoles.ViewRoles')}
                </Button>
            </span>
        </>
    );
}
