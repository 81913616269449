import {useMutation} from '@tanstack/react-query';
import {RegisterProviderRequest, RegisterProviderResponse} from '../capacities/capacities.types';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';

export default function useRegisterProviderWithSubscription() {
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: RegisterProviderRequest) => {
            const response = await customFetch<Response>(
                `${MedeinaVariables.ArmUri}/subscriptions/${body.subscriptionId}/providers/Microsoft.SecurityCopilot/register?api-version=2021-04-01`,
                {
                    method: 'POST',
                    scopes: RESOURCE_SCOPES.ARM,
                    body,
                },
                true,
            );
            const data = await response.json();
            return data as RegisterProviderResponse;
        },
        retry: 0,
    });
}
