import {Dropdown, Field, Option, useId} from '@fluentui/react-components';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import {SkillsetCatalogScope} from '@/api/skills';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {useTranslation} from 'react-i18next';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface ScopeSelectionDropdownProps {
    selectedScope: string;
    onScopeSelected: (selectedScope: string) => void;
    externalConfigurationModalRef: React.RefObject<HTMLDivElement>;
}

export default function ScopeSelectionDropdown({
    selectedScope,
    onScopeSelected,
    externalConfigurationModalRef,
}: ScopeSelectionDropdownProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const ScopeSelectionContent = useId('scopeDropdown');

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    const USER_SCOPE_STRING = SkillsetCatalogScope.User.toString();
    const TENANT_SCOPE_STRING = SkillsetCatalogScope.Tenant.toString();
    const WORKSPACE_SCOPE_STRING = SkillsetCatalogScope.Workspace.toString();
    const catalogScopeSelectionStrings = {
        [USER_SCOPE_STRING]: 'ScopeJustMe',
        [isWorkspacesTestingEnabled ? WORKSPACE_SCOPE_STRING : TENANT_SCOPE_STRING]:
            isWorkspacesTestingEnabled ? 'ScopeWorkspace' : 'ScopeOrganization',
    };
    // Ex: { value: 'User', text: 'Just me' }
    const pluginContextDropdownOptions = Object.keys(catalogScopeSelectionStrings).map((scope) => ({
        value: scope,
        text: catalogScopeSelectionStrings[scope],
    }));
    return (
        <div className={classes.grid} ref={externalConfigurationModalRef}>
            <Field
                className={classes.typeDropdown}
                label={{
                    children: (
                        <MedeinaInfoLabel
                            labelContent={t('ScopeSelectionLabel')}
                            infoContent={
                                isWorkspacesTestingEnabled
                                    ? t('WorkspacesScopeSelectionContent')
                                    : t('ScopeSelectionContent')
                            }
                            data-testid="scope-selection-dropdown-label"
                        />
                    ),
                }}
                required={true}
                id="ScopeSelectionContent"
            >
                <Dropdown
                    value={t(catalogScopeSelectionStrings[selectedScope])}
                    selectedOptions={[selectedScope]}
                    onOptionSelect={(_, data) => {
                        onScopeSelected(data.optionValue as string);
                    }}
                    aria-labelledby={ScopeSelectionContent}
                    aria-label={t('ScopeSelectionLabel')}
                >
                    {pluginContextDropdownOptions.map((option) => (
                        <Option key={option.value} value={option.value} text={option.text}>
                            {t(option.text)}
                        </Option>
                    ))}
                </Dropdown>
            </Field>
        </div>
    );
}
