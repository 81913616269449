import {useState, useEffect, useMemo, useCallback} from 'react';
import {StageState, Stage, RenderableStage} from './Stage.types';

interface useStageManagerProps {
    stages: Array<Stage>;
    autoStart?: boolean;
    initialIndex?: number;
}

const stageManagerDefaults: any = {
    initialIndex: 0,
    autoStart: true,
};

// A utility hook that takes in a configuration of stages and manages changing stages and stage states
// this hook can be used to power anything that can traverse forward and backward including staging animations
function useStageManager(props: useStageManagerProps) {
    const {stages, autoStart, initialIndex = stageManagerDefaults.initialIndex} = props;
    const [stageIndex, setStageIndex] = useState(initialIndex || stageManagerDefaults.initialIndex);
    const activeStage = stages[stageIndex];
    const shouldAutoStart =
        (autoStart && activeStage.autoStart) || (!autoStart && activeStage.autoStart)
            ? true
            : false;
    const [stageState, setStageState] = useState<StageState>(
        shouldAutoStart ? StageState.playing : StageState.ready,
    );
    const [keyStamp, setKeyStamp] = useState(Date.now());

    // Reset the stageState every time we move on to a different stage
    useEffect(() => {
        setStageState(activeStage.autoStart ? StageState.playing : StageState.ready);
    }, [stageIndex, activeStage]);

    // if we are allowed to auto advance then call the next stage
    useEffect(() => {
        if (activeStage.autoAdvance && stageState === StageState.completed) {
            nextStage();
        }
    }, [stageState]);

    // Creates a unique key that can be passed to the stage if the desire is to start every stage as a fresh component
    // this will be handy if we need to render a list of things that will all be visible at the same time but require a refresh of animation of set back to active
    const stageKey = useMemo(() => `${stageIndex}-${keyStamp}`, [stageIndex, keyStamp]);

    // Triggers a new key time stamp that will export an updated stageKey
    const resetStamp = useCallback(() => {
        setKeyStamp(Date.now());
    }, []);

    const prevStage = useCallback(() => {
        if (stageIndex !== 0) {
            setStageIndex(stageIndex - 1);
        }
    }, [stageIndex]);

    const nextStage = useCallback(() => {
        if (stages.length - 1 >= stageIndex + 1) {
            setStageIndex(stageIndex + 1);
        }
    }, [stageIndex, stages.length]);

    // to be shared with each individual stage
    // the stage will call this function to signal to all components that all interaction/animation has ended
    const completeStage = useCallback(() => {
        setStageState(StageState.completed);
    }, []);

    // reset everything to the beginning
    // if an index is passed in this will reset the stage to that index
    const resetStage = useCallback(
        (index?: number) => {
            if (index !== undefined && index <= stages.length - 1) {
                setStageIndex(index);
            } else {
                setStageIndex(0);
                setStageState(stages[0].autoStart ? StageState.playing : StageState.ready);
            }
            resetStamp();
        },
        [resetStamp, stages],
    );

    // restart at the first stage
    const resetStages = useCallback(() => {
        setStageIndex(0);
        setStageState(stages[0].autoStart ? StageState.playing : StageState.ready);
        setKeyStamp(Date.now());
    }, [stages]);

    return {
        stageIndex,
        activeStage,
        stageKey,
        stageState,
        setStageState,
        prevStage,
        nextStage,
        completeStage,
        resetStage,
        resetStages,
    };
}

export default useStageManager;
