import React, {useEffect, useState, useMemo} from 'react';
import {getStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import MedeinaFeatures from '@/util/features';
import WorkspacesContext from './WorkspacesContext';
import useGetWorkspaces from '@/api/workspaces/useGetWorkspaces';
import {writeToSessionStorage} from '@/util/sessionStorage.util';
import {WORKSPACE_NAMESPACE} from './workspaces.constants';
import {WorkspaceSessionData, isGeoPodEnabled} from './';
import {useGetWorkspaceByName} from '@/api/workspaces';
import {useGetAccount} from '@/api/accounts';

/** Manages rendering the WorkspacesContextProvider if enabled
 * @returns the WorkspacesProvider component or children
 *
 * WIP - Only available in dev with feature flag
 */
const WorkspacesProvider = ({children}: {children: React.ReactNode}) => {
    const geoPodEnabled = useMemo(() => isGeoPodEnabled(), []);
    const showWorkspacesProvider = useMemo(
        () => getStaticFeatureFlag(MedeinaFeatures.WorkspacesEnabled) || geoPodEnabled,
        [geoPodEnabled],
    );
    const [workspaceName, setWorkspaceName] = useState('');

    const {data: workspaces, refetch} = useGetWorkspaces({enabled: Boolean(geoPodEnabled)});

    const {data: workspace, isLoading} = useGetWorkspaceByName(workspaceName, {
        enabled: !!workspaceName,
    });

    const {data: account} = useGetAccount();

    useEffect(() => {
        if (geoPodEnabled && workspaces) {
            // When geopods are enabled we need to update how we route microsoft security graph requests
            // First we store the geo pod workspace in session storage
            // right now there's only one workspace so pull the first one
            const geoPodWorkspace = workspaces.value[0];
            setWorkspaceName(geoPodWorkspace.name);
            // We want to keep the minimum amount of data for this
            const workspaceData = {
                name: geoPodWorkspace.name,
                path: geoPodWorkspace.path,
                managementUrl: geoPodWorkspace.managementUrl,
                // we want to capture the second part of the path of  pods/82e853fb-c82c-4d62-9812-439bf8a887c3/workspaces/default,
                podId: geoPodWorkspace.path?.split('/')[1],
            };
            console.log('GeoPod enabled', {workspaceData, account});
            writeToSessionStorage<WorkspaceSessionData>(WORKSPACE_NAMESPACE, workspaceData);
        }
    }, [geoPodEnabled, workspaces]);

    useEffect(() => {
        if (workspaceName && !workspace && !isLoading) {
            refetch();
        }
    }, [workspaceName, workspace, isLoading, refetch]);

    useEffect(() => {
        return () => {
            sessionStorage.removeItem(WORKSPACE_NAMESPACE);
        };
    }, []);

    if (!showWorkspacesProvider) {
        return <>{children}</>;
    }

    return <WorkspacesContext>{children}</WorkspacesContext>;
};

export default WorkspacesProvider;
