import {
    Image,
    AccordionItem,
    AccordionHeader,
    AccordionPanel,
    Spinner,
} from '@fluentui/react-components';
import {DetailedEvaluationLogPanelProps} from './DetailedEvaluationLogPanel.types';
import {EvaluationState, EvaluationStepStatus} from '@/api/evaluations';
import {useMemo} from 'react';
import {ErrorIcon, CancelledIcon, CompletionIcon, QuestionCircleIcon} from '@/components/ui/icons';
import imageCodeMap from '@/util/imageCodeMap';
import {DetailedEvaluationLogPanelContent, useGetElapsedTimeForStep} from '.';
import {PreviewBadge} from '@/components/ui/Badges';

export default function DetailedEvaluationLogPanel({
    classes,
    allStepsCompleted,
    log,
    index,
    evaluationState,
}: DetailedEvaluationLogPanelProps) {
    const imagePath = '/images/icons/';

    var {elapsedTimeText} = useGetElapsedTimeForStep({
        stepStatus: log.stepStatus,
        areAllStepsComplete: allStepsCompleted,
        createdAt: log.createdAt,
        updatedAt: log.updatedAt,
    });

    const childPanelObject = useMemo<{
        value: string;
        icon: JSX.Element;
        title: string;
        elapsedTime: string;
        imageSrc?: string | null;
        imageAlt?: string;
        content: JSX.Element;
        disabled: boolean;
    }>(() => {
        let iconComponent;
        let disabled = false;
        switch (log.stepStatus) {
            case EvaluationStepStatus.Errored:
                iconComponent = (
                    <ErrorIcon className={classes.errorIcon} filled data-testid="child-log-error" />
                );
                break;
            case EvaluationStepStatus.Running:
                if (evaluationState === EvaluationState.Cancelled)
                    iconComponent = (
                        <CancelledIcon
                            className={classes.cancelledIcon}
                            data-testid="child-log-cancelled"
                        />
                    );
                else {
                    disabled = true; // disabling the running step when evaluation is running.
                    iconComponent = <Spinner size="tiny" data-testid="spinner" />;
                }
                break;
            case EvaluationStepStatus.Cancelled:
                iconComponent = (
                    <CancelledIcon
                        className={classes.cancelledIcon}
                        data-testid="child-log-cancelled"
                    />
                );
                break;
            case EvaluationStepStatus.CompletedWithWarning:
                iconComponent = (
                    <QuestionCircleIcon
                        className={classes.warningIcon}
                        filled
                        data-testid="child-log-warning"
                    />
                );
                break;
            default:
                iconComponent = (
                    <CompletionIcon
                        className={classes.completeIcon}
                        filled
                        data-testid="child-log-completed"
                    />
                );
        }
        const allContents = log.stepLogs
            .map((stepLog) => stepLog.markdownContent || stepLog.structuredContent?.content)
            .join('\n\n');

        return {
            value: (index + 1).toString(),
            icon: iconComponent,
            title: log.title,
            elapsedTime: elapsedTimeText,
            imageSrc:
                log.imageCode in imageCodeMap ? `${imagePath + imageCodeMap[log.imageCode]}` : null,
            imageAlt: 'Plugin used: ' + log.imageCode,
            content: <DetailedEvaluationLogPanelContent stepContent={allContents} />,
            disabled: disabled,
        };
    }, [elapsedTimeText, log.stepStatus, log.stepLogs]);

    return (
        <>
            {childPanelObject && (
                <AccordionItem
                    key={childPanelObject.value}
                    value={childPanelObject.value}
                    className={classes.accordionItem}
                    disabled={childPanelObject.disabled}
                >
                    <AccordionHeader
                        className={
                            allStepsCompleted
                                ? classes.childheader
                                : childPanelObject.disabled
                                ? classes.headerClass
                                : classes.childheaderNoParent
                        }
                        icon={childPanelObject.icon}
                        data-testid="child-accordion"
                    >
                        <div className={classes.accordionHeaderCol}>
                            {childPanelObject.title}
                            {childPanelObject.elapsedTime && (
                                <span
                                    className={classes.elapsedTime}
                                    data-testid="elapsed-time-child-accordion"
                                >
                                    {childPanelObject.elapsedTime}
                                </span>
                            )}
                            <div className={classes.previewBadgeContainer}>
                                <PreviewBadge previewState={log.previewState} />
                            </div>
                        </div>

                        <div className={classes.childIconCol}>
                            {childPanelObject.imageSrc && (
                                <Image
                                    width={40}
                                    fit="default"
                                    className={classes.image}
                                    src={childPanelObject.imageSrc}
                                    alt={childPanelObject.imageAlt}
                                />
                            )}
                        </div>
                    </AccordionHeader>
                    <AccordionPanel>{childPanelObject.content}</AccordionPanel>
                </AccordionItem>
            )}
        </>
    );
}
