import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

const useGridContainerStyles = makeStyles({
    grid: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap(tokens.spacingVerticalXXL),
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
        },
        '@media (max-width: 479px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
        },
    },
});

export default useGridContainerStyles;
