import {useMsal} from '@azure/msal-react';

export default function useMsalUserId(): string | null {
    const {instance} = useMsal();
    const account = instance.getActiveAccount();
    if (!account) {
        throw new Error('No active account found');
    }
    let userObjectId = account?.idTokenClaims?.oid;

    // For GDAP scenarios, there is no oid in the claims so we need to use the homeAccountId instead.
    if (!userObjectId) {
        userObjectId = account?.homeAccountId?.split('.')[0];
    }

    return userObjectId ?? null;
}
