import React, {useEffect, useCallback} from 'react';
import AnalystHomeTour from './AnalystHomeTour';
import {useTourContext} from '@/components/ui/Tour';
import {useElementBySelector} from '@/components/ui/Tour/util/useGetElement';
import {useAppState} from '@/api/app';
import useTourHistoryTelemetry from '@/components/ui/Tour/useTourHistoryTelemetry';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

function useDefenderIntroTour() {
    const {onTourStart, onTourComplete, onTourExitEarly} = useTourHistoryTelemetry({
        tourName: 'analystHome',
    });
    const {userTours, setUserTour} = useAppState();
    const {analystHome, analyst} = userTours;
    const {started} = analystHome || {};

    // we only want to start this tour if there's a tour parameter in the url for tour
    // const hasTourParam = Boolean(new URLSearchParams(window.location.search).get('tour')) ?? false;
    const isA11yTesting = useFeatureFlag(MedeinaFeatures.a11y);

    const {activeTour, setTour, tour, stopIndex, currentStop, quitTour} = useTourContext();
    const [firstTourElement, fireFirstTourSearch] = useElementBySelector(
        "[data-testid='promptbar-root']",
        3000,
        true,
    );

    // on initial load if this is a11y testing, we should clear the tour history
    useEffect(() => {
        if (isA11yTesting) {
            setUserTour('analystHome', {});
        }
    }, []);

    // when this hook unmounts we should clear the tour
    useEffect(() => {
        return () => {
            quitTour();
        };
    }, []);

    const startTour = useCallback(() => {
        setTour(
            AnalystHomeTour({
                telemetryOptions: {
                    onTourComplete,
                    onTourExitEarly,
                    onTourStart,
                },
            }),
        );
    }, [setTour, onTourStart, onTourComplete, onTourExitEarly]);

    // check for tour params, if we have them, start the tourParam
    useEffect(() => {
        if (!activeTour && firstTourElement && analyst && !analystHome) {
            startTour();
        } else if (!activeTour && firstTourElement && isA11yTesting) {
            startTour();
        }
        // we only care when static values change not functions
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTour, firstTourElement, analystHome, isA11yTesting, analyst]);
}

export default useDefenderIntroTour;
