import {Display} from '@fluentui/react-components';
import useClasses from './components.styles';

export default function MarkdownH1({children, className, ...props}: any) {
    const classes = useClasses();

    return (
        <h1>
            <Display className={classes.h1}>{children}</Display>
        </h1>
    );
}
