import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import {DeleteCapacityRequest} from './capacities.types';
import MedeinaVariables from '@/util/variables';

export default function useDeleteCapacity() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (request: DeleteCapacityRequest) => {
            const response = await customFetch<Response>(
                `${MedeinaVariables.ArmUri}/subscriptions/${request.subscriptionId}/resourceGroups/${request.resourceGroupName}/providers/Microsoft.SecurityCopilot/capacities/${request.capacityName}?api-version=2023-12-01-preview`,
                {
                    method: 'DELETE',
                    scopes: RESOURCE_SCOPES.ARM,
                },
                true,
            );

            if (!response || !response.ok) {
                throw new Error('Error deleting capacity');
            } else return response;
        },
        onSettled: (_data, _error, variables) => {
            queryClient.removeQueries({queryKey: ['capacities']});
        },
    });
}
