const tourOptions = {
    name: 'analystHome',
    telemetryName: 'analystHome',
};

export const tourStopNames = {
    chatWithAI: 'chatWithAI',
    getIdeas: 'getIdeas',
    tryQuickstart: 'tryQuickstart',
    sources: 'sources',
    optimize: 'optimize',
};

export default tourOptions;
