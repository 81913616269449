import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    titleWrapper: {
        display: 'flex',
        width: '100%',
        marginBottom: tokens.spacingVerticalL,
        '@media (max-width: 480px)': {
            marginBottom: tokens.spacingVerticalSNudge,
        },
        fontSize: tokens.fontSizeBase500,
    },

    title: {
        flexGrow: 1,
    },

    dialogInfo: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalXL,
    },

    closeButton: {
        maxHeight: tokens.spacingVerticalXXXL,
    },

    contentSection: {
        marginBottom: tokens.spacingVerticalXXL,
        '@media (max-width: 480px)': {
            marginBottom: tokens.spacingVerticalSNudge,
            overflowY: 'auto',
        },
        overflowY: 'hidden',
    },

    memberListWrapper: {
        maxHeight: '30vh',
        overflowY: 'auto',
    },

    messageBar: {
        marginBottom: tokens.spacingVerticalXL,
    },
    dialogActions: {
        flexDirection: 'row',
        '@media (max-width: 480px)': {
            flexDirection: 'row',
        },
    },
    rolesList: {
        margin: tokens.spacingVerticalS,
        marginRight: tokens.spacingVerticalNone,
        maxHeight: '25vh',
        overflowY: 'auto',
        backgroundColor: tokens.colorNeutralBackground5Selected,
        padding: tokens.spacingVerticalS,
        borderRadius: tokens.borderRadiusXLarge,
    },
    customMenuItemWidth: {
        maxWidth: 'none',
        width: '100%',
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
        backgroundColor: tokens.colorNeutralBackground5Selected,
    },
    roleName: {
        verticalAlign: 'middle',
        lineHeight: tokens.lineHeightBase200,
    },
    personIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: tokens.spacingVerticalXXXL,
        height: tokens.spacingVerticalXXXL,
        ...shorthands.borderRadius(tokens.spacingVerticalXL),
        backgroundColor: '#00666D',
        marginRight: tokens.spacingVerticalS,
        verticalAlign: 'middle',
        fontSize: tokens.spacingVerticalXXL,
        color: '#ffffff',
        fontWeight: tokens.fontWeightBold,
    },
    menuItemHover: {
        pointerEvents: 'none',
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    textLink: {
        color: tokens.colorCompoundBrandStroke,
        textDecoration: 'none',
    },
    accordianItem: {
        borderRadius: tokens.borderRadiusXLarge,
        padding: tokens.spacingVerticalS,
        paddingLeft: tokens.spacingVerticalNone,
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
        },
    },
    selectedPanel: {
        backgroundColor: tokens.colorNeutralBackground1Hover,
    },
    rolesTypeContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    rolesTypeHeader: {
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase500,
    },
    rolesTypeDescription: {
        marginBottom: tokens.spacingVerticalM,
    },
});
