import {MenuType} from '@/components/HeaderMenu.types';
import {ConnectorModalProps} from './ConnectorModal.types';
import {ConnectorModalContextProvider} from './Context/ConnectorModalContextProvider';
import ConnectorModal from './ConnectorModal';

/**
 * Top level component wrapper for Plugin and Files connector sources modal.
 *
 * @param defaultSelectedSkillsets - The default selected skillsets.
 * @param onChangeSkillsets - The function to handle skillset changes.
 * @param menuType - The type of menu.
 * @param open - Indicates whether the modal is open or not.
 * @param onClose - The function to handle modal close.
 * @returns The rendered ConnectorSourcesModal component.
 */
export default function ConnectorSourcesModal({
    defaultSelectedSkillsets,
    onChangeSkillsets,
    menuType = MenuType.Standalone,
    open,
    onClose,
}: ConnectorModalProps) {
    return (
        <ConnectorModalContextProvider>
            <ConnectorModal
                defaultSelectedSkillsets={defaultSelectedSkillsets}
                onChangeSkillsets={onChangeSkillsets}
                menuType={menuType}
                open={open}
                onClose={onClose}
            />
        </ConnectorModalContextProvider>
    );
}
