import {FilterConfiguration} from '@/components/ui/Filter/Filter.types';
import FilterDrawer from '@/components/ui/Filter/FilterDrawer';
import {Drawer} from '@fluentui/react-components';
import {useCallback, useEffect, useState} from 'react';
import {
    FILTER_PANEL_CATEGORY_LABELS,
    UsageMonitoringFilterLabels,
} from '../UsageDashboard.constants';
import useClasses from '../UsageDashboard.styles';
import {useTranslation} from 'react-i18next';
import {SearchFacet, SearchFacetType, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {SearchCategory} from '../UsageDashboard.types';
import useGetUsageDashboardFilterPanels from './useGetUsageDashboardFilterPanels';

interface FilterConfigurationProps {
    filterData: SearchFacet[];
    onFiltersUpdate: (filters: SelectedSearchFacet[]) => void;
    onFilterClose: () => void;
    isOpen: boolean;
    shouldResetFilters: boolean;
    selectedFilters: {[key: string]: string[]};
}

const getWidgetConfiguration = (localizer: any) => {
    return [
        {
            category: SearchCategory.CopilotExperience,
            type: 'multiselectkeyvalue',
            title: localizer(`${FILTER_PANEL_CATEGORY_LABELS.CopilotExperience}`),
        },
        {
            category: SearchCategory.Users,
            type: 'user',
            title: localizer(`${FILTER_PANEL_CATEGORY_LABELS.Users}`),
        },
        {
            category: SearchCategory.PluginUsed,
            type: 'multiselectsearch',
            title: localizer(`${FILTER_PANEL_CATEGORY_LABELS.PluginsUsed}`),
        },
        {
            category: SearchCategory.InvocationType,
            type: 'multiselect',
            title: localizer(`${FILTER_PANEL_CATEGORY_LABELS.InvocationType}`),
        },
        {
            category: SearchCategory.InvocationCategory,
            type: 'multiselect',
            title: localizer(`${FILTER_PANEL_CATEGORY_LABELS.InvocationCategory}`),
        },
    ];
};

export default function UsageDashboardFilterContainer(props: FilterConfigurationProps) {
    const [filterConfig, setFilterConfig] = useState<FilterConfiguration[]>([]);
    const [open, setOpen] = useState(props.isOpen);
    const [selectedFilters, setSelectedFilters] = useState<{[key: string]: string[]}>({});

    useEffect(() => {
        setSelectedFilters(props.selectedFilters);
    }, [props.selectedFilters]);

    const {
        addGetMultiselectWidget,
        addGetSearchableMultiSelectWidget,
        addUserWidget,
        addGetMultiselectWidgetWithKeyValues,
    } = useGetUsageDashboardFilterPanels();

    const closeModal = () => {
        setOpen(false);
        props.onFilterClose();
    };

    const classes = useClasses();
    const {t} = useTranslation('admin');

    const handleApplyAll = useCallback((filters: {[key: string]: string[]}) => {
        const facets: SelectedSearchFacet[] = Object.keys(filters).map((key) => {
            return {
                category: key,
                values: filters[key],
                type: SearchFacetType.Value,
            };
        });

        props.onFiltersUpdate(facets);
        setSelectedFilters(filters);
    }, []);

    const handleResetAll = useCallback(() => {
        setSelectedFilters({});
        props.onFiltersUpdate([]);
    }, []);

    const handleResetPanel = useCallback(
        (updatedFilters: {[key: string]: string[]}) => {
            setSelectedFilters(updatedFilters);
        },
        [selectedFilters],
    );

    useEffect(() => {
        if (props.shouldResetFilters) {
            setSelectedFilters({});
        }
    }, [props.shouldResetFilters]);

    useEffect(() => {
        var order = 0;
        var configurations: FilterConfiguration[] = [];

        const widgetConfigurations = getWidgetConfiguration(t);
        widgetConfigurations.forEach((widget) => {
            var configuration: FilterConfiguration | null = null;
            var matchingFilterData = props.filterData.find((filter) => {
                return (filter.category as SearchCategory) === widget.category;
            });

            if (!Boolean(matchingFilterData)) {
                return;
            }

            const {values} = matchingFilterData as SearchFacet;
            order++;
            switch (widget.type) {
                case 'multiselect':
                    configuration = addGetMultiselectWidget(
                        widget.title,
                        widget.category,
                        values as string[],
                        order,
                    );
                    break;
                case 'multiselectkeyvalue':
                    configuration = addGetMultiselectWidgetWithKeyValues(
                        widget.title,
                        widget.category,
                        matchingFilterData as SearchFacet,
                        order,
                    );
                    break;
                case 'user':
                    configuration = addUserWidget(
                        widget.title,
                        widget.category,
                        matchingFilterData as SearchFacet,
                        order,
                    );
                    break;
                case 'multiselectsearch':
                    configuration = addGetSearchableMultiSelectWidget(
                        widget.title,
                        widget.category,
                        values as string[],
                        order,
                    );
                    break;
            }

            if (Boolean(configuration)) {
                configurations.push(configuration as FilterConfiguration);
            }
        });
        setFilterConfig(configurations);
    }, [props.filterData]);

    useEffect(() => {
        setOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        <Drawer
            type="overlay"
            separator
            open={open}
            onOpenChange={(_, {open}: any) => closeModal()}
            className={classes.drawerRoot}
            modalType="modal"
            position="end"
        >
            <FilterDrawer
                onClose={closeModal}
                filterTitle={t(UsageMonitoringFilterLabels.Title)}
                filterConfiguration={filterConfig}
                onApplyAll={handleApplyAll}
                onResetAll={handleResetAll}
                onResetPanel={handleResetPanel}
                selectedFilters={selectedFilters}
            />
        </Drawer>
    );
}
