import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    spinner: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
    },
    textContainer: {
        display: 'flex',
    },
});
