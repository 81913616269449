import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        '& span': {
            'margin-right': tokens.spacingHorizontalS,
        },
    },
    textStyles: {
        display: 'inline-block',
        fontWeight: tokens.fontWeightRegular,
        width: '100%',
    },
});
