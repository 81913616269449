import {makeStyles} from '@fluentui/react-components';
import {tokens} from '@fluentui/react-components';

export default makeStyles({
    h1: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: '20px',
        lineHeight: tokens.lineHeightBase500,
        color: tokens.colorNeutralForeground4,
    },
    h2: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: '20px',
        lineHeight: tokens.lineHeightBase500,
        color: tokens.colorNeutralForeground4,
    },
    h3: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: '20px',
        lineHeight: tokens.lineHeightBase500,
        color: tokens.colorNeutralForeground4,
    },
    h4: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,
        color: tokens.colorNeutralForeground3,
    },
    h5: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,
        color: tokens.colorNeutralForeground3,
    },
    h6: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,
        color: tokens.colorNeutralForeground3,
    },
    body: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,
        color: tokens.colorNeutralForeground2,
    },
    listItem: {
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightHero700,
        color: tokens.colorNeutralForeground2,
    },
});
