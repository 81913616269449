import React, {useMemo} from 'react';
import useTourContext from './useTourContext';
import {TourState} from './Tour.types';

function Content({children}: any) {
    const {currentStop, tourState} = useTourContext();
    const hasOnBeforeStop = useMemo(() => currentStop?.onBeforeStop, [currentStop?.onBeforeStop]);
    const {onBeforeStop} = currentStop || {};
    return hasOnBeforeStop && tourState === TourState.preloading ? (
        <>{onBeforeStop}</>
    ) : (
        <>{children}</>
    );
}

export default Content;
