export default function shuffleArray<T>(array: T[]): T[] {
    // Copy the array first so that we don't mutate the original.
    const nextArray = array.slice(0);

    // Durstenfeld shuffle
    for (let i = nextArray.length - 1; i > 0; i--) {
        // Randomize.
        let j = Math.floor(Math.random() * (i + 1));
        // Swap values.
        [nextArray[i], nextArray[j]] = [nextArray[j], nextArray[i]];
    }

    return nextArray;
}
