import {Body1, Combobox, OptionGroup, Option} from '@fluentui/react-components';
import {PageSelectionLayoutProps} from './Pagination.types';
import {useEffect, useMemo, useState} from 'react';
import useClasses from './Pagination.styles';
import {useTranslation} from 'react-i18next';

export default function PageSelectionLayout(props: PageSelectionLayoutProps) {
    const {t} = useTranslation('common');
    const classes = useClasses();

    const getPaginationOptions = useMemo(() => {
        return (totalPages: number): string[] => {
            return Array.from({length: totalPages}, (_, index) => {
                var currentValue = String(index + 1);
                return currentValue;
            });
        };
    }, []);

    const pageOptions = useMemo(
        () =>
            getPaginationOptions(props.totalPageCount).map((currentValue) => {
                return (
                    <Option key={currentValue} value={currentValue}>
                        {currentValue}
                    </Option>
                );
            }),
        [props.totalPageCount],
    );

    const [activePage, setActivePage] = useState<number>(props.activePage);

    useEffect(() => {
        setActivePage(props.activePage);
    }, [props.activePage]);

    const getAriaLabelForPageSelection = (currentPage: number) => {
        return t('Pagination.pageSelectionText', {
            0: currentPage,
        });
    };

    return (
        <div data-testid="page-selection-component" className={classes.pageSelectionContainer}>
            <span>
                <Body1>{t('Pagination.page')}</Body1>{' '}
            </span>
            <span>
                <Combobox
                    aria-label={getAriaLabelForPageSelection(activePage)}
                    data-testid="page-selection-combobox"
                    value={String(activePage)}
                    selectedOptions={String(activePage) ? [String(activePage)] : []}
                    onOptionSelect={(_, data) => {
                        setActivePage(parseInt(data.optionValue ?? '1'));
                        props?.onPageChange(Number(data.optionValue));
                    }}
                    className={classes.smallLengthCombo}
                    disabled={props.disabled}
                >
                    <OptionGroup>{pageOptions}</OptionGroup>
                </Combobox>{' '}
            </span>
            <span>
                <Body1>{t('Pagination.of')} </Body1>{' '}
            </span>
            <span>
                <Body1 data-testid="total-page-count">{props.totalPageCount}</Body1>
            </span>
        </div>
    );
}
