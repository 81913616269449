import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    tenantDropdown: {
        ...shorthands.overflow('hidden'),
        ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingHorizontalS),
        backgroundColor: 'transparent',
        width: '100%',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',

        '&:hover': {
            backgroundColor: tokens.colorSubtleBackgroundLightAlphaHover,
        },
    },
});
