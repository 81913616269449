import {useGetCapacities} from '@/api/capacities';
import {useGetWorkspaceByName, useGetWorkspaces} from '@/api/workspaces';
import {useEffect} from 'react';

interface ManageSelectCapacityDetailsProps {
    onWorkspacesFetchError: (error: any) => void;
    onCapacitiesFetchError: (error: any) => void;
}

export default function useManageSelectCapacityDetails(props: ManageSelectCapacityDetailsProps) {
    const {
        data: workspacesData,
        isFetched: isWorkspacesDataFetched,
        isError: isWorkspacesDataError,
        isLoading: isWorkspacesDataLoading,
        error: workspacesError,
    } = useGetWorkspaces();

    const canGetWorkspaceByNameBeLoaded =
        isWorkspacesDataFetched && (workspacesData?.count ?? 0) > 0;

    const {
        data: workspace,
        isSuccess: isWorkspaceFetchSuccess,
        isError: isWorkspaceFetchError,
        isFetched: isWorkspaceFetched,
        isLoading: isWorkspaceLoading,
    } = useGetWorkspaceByName(workspacesData?.value?.[0]?.name || '', {
        enabled: canGetWorkspaceByNameBeLoaded,
        refetchOnMount: true,
    });

    const {
        data: capacitiesData,
        isError: isCapacitiesError,
        isFetched: isCapacitiesFetched,
        isLoading: isCapacitiesLoading,
    } = useGetCapacities();

    useEffect(() => {
        if (isWorkspacesDataError) {
            props?.onWorkspacesFetchError(workspacesError);
        }
    }, [isWorkspacesDataFetched]);

    useEffect(() => {
        if (isWorkspaceFetchError) {
            props?.onWorkspacesFetchError(workspacesError);
        }
    }, [isWorkspaceFetched]);

    useEffect(() => {
        if (isCapacitiesError) {
            props?.onCapacitiesFetchError(capacitiesData);
        }
    }, [isCapacitiesFetched]);

    return {
        isCapacitiesFetched,
        isCapacitiesError,
        capacitiesData,
        existingCapacityName: workspace?.capacity?.referenceName,
        isDataLoading:
            isWorkspacesDataLoading ||
            (canGetWorkspaceByNameBeLoaded && isWorkspaceLoading) ||
            isCapacitiesLoading,
    };
}
