import {NeutralColors} from './colors.types';

/**
 * Neutral colors based of Fluent anchor theme colors.
 */
const neutrals: NeutralColors = {
    // black and white
    white: '#FFFFFF',
    black: '#000000',

    // transparent
    transparent: 'transparent',

    // anchor
    shade50: '#090A0B',
    shade40: '#111315',
    shade30: '#202427',
    shade20: '#2B3135',
    shade10: '#333A3F',
    primary: '#394146',
    tint10: '#4D565C',
    tint20: '#626C72',
    tint30: '#808A90',
    tint40: '#BCC3C7',
    tint50: '#DBDFE1',
    tint55: '#EBEDEF',
    tint60: '#F6F7F8',

    // alpha transparencies
    whiteAlpha: {
        '0': 'rgba(246, 247, 248, 0)',
        '5': 'rgba(246, 247, 248, 0.05)',
        '10': 'rgba(246, 247, 248, 0.1)',
        '20': 'rgba(246, 247, 248, 0.2)',
        '30': 'rgba(246, 247, 248, 0.3)',
        '40': 'rgba(246, 247, 248, 0.4)',
        '50': 'rgba(246, 247, 248, 0.5)',
        '60': 'rgba(246, 247, 248, 0.6)',
        '70': 'rgba(246, 247, 248, 0.7)',
        '80': 'rgba(246, 247, 248, 0.8)',
        '90': 'rgba(246, 247, 248, 0.9)',
    },
    blackAlpha: {
        '0': 'rgba(9, 10, 11, 0)',
        '5': 'rgba(9, 10, 11, 0.05)',
        '10': 'rgba(9, 10, 11, 0.1)',
        '20': 'rgba(9, 10, 11, 0.2)',
        '30': 'rgba(9, 10, 11, 0.3)',
        '40': 'rgba(9, 10, 11, 0.4)',
        '50': 'rgba(9, 10, 11, 0.5)',
        '60': 'rgba(9, 10, 11, 0.6)',
        '70': 'rgba(9, 10, 11, 0.7)',
        '80': 'rgba(9, 10, 11, 0.8)',
        '90': 'rgba(9, 10, 11, 0.9)',
    },
    greyAlpha: {
        '0': 'rgba(57, 65, 70, 0)',
        '5': 'rgba(57, 65, 70, 0.05)',
        '10': 'rgba(57, 65, 70, 0.1)',
        '20': 'rgba(57, 65, 70, 0.2)',
        '30': 'rgba(57, 65, 70, 0.3)',
        '40': 'rgba(57, 65, 70, 0.4)',
        '50': 'rgba(57, 65, 70, 0.5)',
        '60': 'rgba(57, 65, 70, 0.6)',
        '70': 'rgba(57, 65, 70, 0.7)',
        '80': 'rgba(57, 65, 70, 0.8)',
        '90': 'rgba(57, 65, 70, 0.9)',
    },
    lightGreyAlpha: {
        '0': 'rgba(188, 195, 199, 0)',
        '5': 'rgba(188, 199, 199, 0.05)',
        '10': 'rgba(188, 199, 199, 0.1)',
        '20': 'rgba(188, 199, 199, 0.2)',
        '30': 'rgba(188, 199, 199, 0.3)',
        '40': 'rgba(188, 199, 199, 0.4)',
        '50': 'rgba(188, 199, 199, 0.5)',
        '60': 'rgba(188, 199, 199, 0.6)',
        '70': 'rgba(188, 199, 199, 0.7)',
        '80': 'rgba(188, 199, 199, 0.8)',
        '90': 'rgba(188, 199, 199, 0.9)',
    },
};

export default neutrals;
