import {Tour} from '@/components/ui/Tour';
import {TourTelemetryOptions} from '@/components/ui/Tour/Tour.types';
import {PositioningProps} from '@fluentui/react-components';

import {ModalIntro, ModalOpen, SetUpPlugin, AddPlugin, setupHandlers} from './stops/PopoverStops';
import config, {tourStopNames} from './config';
import UILocators, {
    getMicrosoftPluginSection,
    getUnconfiguredNonMicrosoftPlugin,
    getAddCustomPluginButtonArea,
} from '../TourLocators';

const defaultControlOptions = {
    allowSkip: false,
    allowExit: false,
    allowReplay: false,
    showExit: false,
    showControls: false,
    showCounter: false,
};

interface FromDefenderTourProps {
    onExit?: () => void;
    telemetryOptions: TourTelemetryOptions;
}

const defaultPopoverPositioning: PositioningProps = {
    arrowPadding: 10,
};

export function AdminPluginTour(props: FromDefenderTourProps): Tour {
    return {
        name: config.name,
        controlOptions: defaultControlOptions,
        telemetryName: config.telemetryName,
        telemetryOptions: props.telemetryOptions,
        tourStops: [
            {
                name: tourStopNames.home,
                autoAdvance: true,
                key: 'admin.plugin.Intro',
                autoStart: true,
                popover: {
                    targetSelector: UILocators.promptbarSourcesButton,
                    content: ModalIntro,
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                },
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
            },
            {
                name: tourStopNames.openModal,
                autoAdvance: true,
                autoStart: true,
                setup: setupHandlers,
                popover: {
                    content: ModalOpen,
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                    targetSelector: getMicrosoftPluginSection,
                },
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
            },
            {
                name: tourStopNames.setUpPlugin,
                autoAdvance: true,
                autoStart: true,
                popover: {
                    content: SetUpPlugin,
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                    targetSelector: getUnconfiguredNonMicrosoftPlugin,
                },
            },
            {
                name: tourStopNames.addPlugin,
                autoAdvance: true,
                autoStart: true,
                popover: {
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                    content: AddPlugin,
                    withArrow: true,
                    targetSelector: getAddCustomPluginButtonArea,
                },
                controls: {
                    showControls: false,
                },
            },
        ],
    };
}

export default AdminPluginTour;
