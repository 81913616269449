import React, {useContext} from 'react';
import {ApplicationInsights} from '@microsoft/1ds-analytics-web-js';

export const AppInsightsContext = React.createContext<ApplicationInsights | null>(undefined!);

export const AppInsightsProvider = AppInsightsContext.Provider;

export default function useAppInsights() {
    return useContext(AppInsightsContext);
}
