import {MedeinaFeatureFlagReference, MedeinaFeatureFlagValue} from '@/util/features';
import useFeatureFlags from './useFeatureFlags';

// Checks both compiled and runtime user feature flags for access.
// Usage:
//   const isVerboseLoggingEnabled = useFeatureFlag(MedeinaFeatures.EnableVerboseLogging);
export default function useFeatureFlag(
    reference: MedeinaFeatureFlagReference,
): MedeinaFeatureFlagValue {
    return useFeatureFlags([reference])[1];
}
