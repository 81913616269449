import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {GetPromptbookExecutionRequest, PromptbookExecutionDescriptor} from './promptbooks.types';

export default function useGetPromptbookExecution({
    promptbookExecutionId,
}: GetPromptbookExecutionRequest) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['promptbookexecution', promptbookExecutionId],
        queryFn: async () => {
            return await customFetch<PromptbookExecutionDescriptor>(
                `/promptbookexecutions/${promptbookExecutionId}`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
