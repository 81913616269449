import React, {useEffect, useMemo, useRef} from 'react';
import {motion} from 'framer-motion';
import type {IBeakProps} from './Beak.types';
import useClasses from './Beak.styles';
import AnnounceLabel from '../../AnnounceText/AnnounceText';
import {useTranslation} from 'react-i18next';

export const BEAK_HEIGHT = 10;
export const BEAK_WIDTH = 18;

const Beak = (props: IBeakProps) => {
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const {position, onFocus} = props;
    const tipRef = useRef<HTMLDivElement>(null);

    // the position can be used to determine the rotation of the beak
    // the beak by default renders at the bottom of the target
    // this variable will be used to determine the rotation of the beak
    // this style value will be passed to the beak component
    const beakTransform = useMemo(() => {
        switch (position) {
            case 'above':
                return 'rotate(180deg)';
            case 'below':
                return 'rotate(0deg)';
            case 'before':
                return 'rotate(90deg)';
            case 'after':
                return 'rotate(-90deg)';
            default:
                return 'rotate(0deg)';
        }
    }, [position]);

    let svgHeight: number;
    let svgWidth: number;

    svgHeight = BEAK_HEIGHT;
    svgWidth = BEAK_WIDTH;

    let pointOne: string;
    let pointTwo: string;
    let pointThree: string;
    let transform: string;

    pointOne = `${BEAK_WIDTH / 2}, 0`;
    pointTwo = `${BEAK_WIDTH}, ${BEAK_HEIGHT}`;
    pointThree = `0, ${BEAK_HEIGHT}`;
    transform = 'translateY(-100%)';

    // if the beak ref has a value focus on it
    useEffect(() => {
        if (tipRef.current) {
            tipRef.current.focus();
        }
    }, []);

    return (
        <div className={classes.root} style={{transform: beakTransform}}>
            <svg height={svgHeight} width={svgWidth} className={classes.beak}>
                <polygon points={pointOne + ' ' + pointTwo + ' ' + pointThree} />
            </svg>
            <motion.div
                className={classes.pulsingIndicator}
                key="pulse"
                initial={{
                    scale: 0,
                }}
                animate={{
                    scale: [0, 1, 1.5],
                    opacity: [1, 1, 0],
                    transition: {
                        repeatDelay: 2,
                        duration: 4,
                        repeat: Infinity,
                        repeatType: 'loop',
                        ease: 'circOut',
                    },
                }}
            />
            <AnnounceLabel ariaLive="polite" visuallyHidden>
                {t('common.tourHintAppeared')}
            </AnnounceLabel>
            <div
                className={classes.indicator}
                onClick={onFocus}
                data-testid="coachmark-indicator"
                tabIndex={0}
                ref={tipRef}
            />
        </div>
    );
};

export default Beak;
