import {MemberListItemProps, RoleType} from '../rbac.types';
import AddedMemberListItem from '../MemberListItem/AddedMemberListItem';
import {useMemo} from 'react';
import {EVERYONE_IDENTIFIER} from '@/api/rbac/rbac.constants';

interface MemberListProps {
    onMemberUpdated: (key: string, role: RoleType) => void;
    members: MemberListItemProps[];
    preConfiguredMemberMap: Record<string, Set<RoleType>>;
    onMemberRemoved: (memberId: string) => void;
}

export default function MemberList(props: MemberListProps) {
    const currentMemberRoleMap = useMemo(() => {
        return props.members.reduce((groups, member) => {
            const key = member.memberId;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(member.role);
            return groups;
        }, {} as Record<string, RoleType[]>);
    }, [props.members]);

    const filterRestrictedRoles = (member: MemberListItemProps) => {
        const restrictedRoles = new Set(currentMemberRoleMap[member.memberId]);

        //Existing member roles cannot be added if mapping already exists
        const existingRoles = props.preConfiguredMemberMap[member.memberId];
        if (!!existingRoles) {
            existingRoles.forEach((role) => restrictedRoles.add(role));
        }

        //Current member role cannot be considered as restricted
        restrictedRoles.delete(member.role);

        // If the member is `Everyone`, they cannot be an owner
        if (member.memberId === EVERYONE_IDENTIFIER) {
            restrictedRoles.add(RoleType.Owner);
        }

        return restrictedRoles;
    };

    const memberCount = props?.members?.length;

    return (
        <>
            {props?.members?.map((member, index) => {
                const restrictedRoles = filterRestrictedRoles(member);
                return (
                    <AddedMemberListItem
                        isFocused={index == memberCount - 1}
                        key={member.key}
                        onMemberRemoved={(memberId) => props.onMemberRemoved(memberId)}
                        onMemberUpdated={(key, id) => props.onMemberUpdated(key, id)}
                        memberItem={member}
                        restrictedRoles={restrictedRoles}
                    ></AddedMemberListItem>
                );
            })}
        </>
    );
}
