import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalL),
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.margin('0px', '0px', tokens.spacingVerticalXL, '0px'),
    },
    prompt: {
        fontSize: tokens.fontSizeBase300,
    },
    skill: {},
});
