import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {DeletePromptbookRequest} from './promptbooks.types';

export default function useDeletePromptbooks() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: DeletePromptbookRequest) => {
            // Delete all requested promptbooks individually.
            if (body.promptbookIds === undefined) {
                // This condition should not happen.
                return false;
            }

            const results = await Promise.all(
                body.promptbookIds.map((promptbookId) =>
                    customFetch<Response>(
                        `/promptbooks/${promptbookId}`,
                        {
                            method: 'DELETE',
                        },
                        true,
                    ).then((response) => response.status === 200),
                ),
            );

            // Now check the results.
            let deletionResult = true;
            results.forEach((callResult) => {
                deletionResult = deletionResult && callResult;
            });

            return deletionResult;
        },
        onSettled: (success, error, body) => {
            // Refetch the parent session.
            queryClient.refetchQueries({
                queryKey: ['promptbooks'],
            });
        },
    });
}
