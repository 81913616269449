import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserDetails} from './user.types';
import {useMsalUserId} from '@/util/msal';

export default function useGetUserDetails() {
    const {customFetch} = useFetch();
    const userObjectId = useMsalUserId();

    return useQuery({
        queryKey: ['userDetails'],
        queryFn: async () => {
            return await customFetch<UserDetails>(`/users/${userObjectId}/activities`, {
                method: 'GET',
            });
        },
    });
}
