import {Text, TextProps} from '@fluentui/react-components';
import {useMemo} from 'react';
import {formatLocaleDate as format} from '@/util/locale';

export type DateTextProps = TextProps & {
    date: Date;
    dateFormat?: string;
};

// The date format string.
const currentDateFormat = 'MMM d, p';
const pastDateFormat = 'MMM d, y';
const getDateFormat = (date: Date): string => {
    if (date.getFullYear() === new Date().getFullYear()) {
        return currentDateFormat;
    }
    return pastDateFormat;
};

export default function DateText({children, date, dateFormat, ...props}: DateTextProps) {
    const formattedDate = useMemo<string | null>(
        () => (date ? format(date, dateFormat || getDateFormat(date)) : null),
        [date, dateFormat],
    );
    return <Text {...props}>{formattedDate}</Text>;
}
