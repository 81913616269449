import {FailedIcon} from '@/components/ui/icons';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useTranslation} from 'react-i18next';

export default function DataSharingErrorSwitch() {
    const tourClasses = useTourClasses();
    const {t} = useTranslation('tours');

    return (
        <div className={tourClasses.switchError}>
            <span>
                <FailedIcon filled />
            </span>
            <span>{t('adminV2Fre.dataAndPrivacy.ErrorMessage')}</span>
        </div>
    );
}
