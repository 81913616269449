import {UseQueryOptions, useInfiniteQuery} from '@tanstack/react-query';
import {PageResponse} from '../api';
import {GetSessionsRequest, Session} from './sessions.types';
import useFetch from '../useFetch';

// TODO: The API should handle JSON case mapping.
const capitalize = (column: string) => column[0].toUpperCase() + column.slice(1);

export default function useGetSessions(
    body: GetSessionsRequest = {},
    useQueryOptions?: Pick<UseQueryOptions<PageResponse<Session>>, 'enabled'>,
) {
    const {customFetch} = useFetch();
    return useInfiniteQuery({
        ...useQueryOptions,
        queryKey: ['sessions', body],
        queryFn: async ({pageParam: continuationToken = undefined}) => {
            // When the pageParam is null, but fetchNextPage is called, return the empty page. This could happen when the query is refetched sometimes.
            if (continuationToken === null) {
                return {value: [], nextLink: null};
            }
            // Convert the body to URL params.
            const params = [
                body.limit && `limit=${body.limit}`,
                body.sortBy && `sortBy=${capitalize(body.sortBy)}`,
                body.sortDirection && `sortDirection=${body.sortDirection}`,
                body.filter && `$filter=${encodeURIComponent(body.filter)}`,
                body.maxSessionsCount && `maxSessionsCount=${body.maxSessionsCount}`,
                continuationToken && `continuationToken=${continuationToken}`,
                `enablePagination=true`,
            ]
                .filter((param) => !!param)
                .join('&');
            return await customFetch<PageResponse<Session>>(
                `/sessions${params ? `?${params}` : ''}`,
                {method: 'GET'},
            );
        },
        getNextPageParam: (lastPage) => {
            if (lastPage.nextLink === null || lastPage.nextLink === undefined)
                return lastPage.nextLink;
            const urlParams = new URLSearchParams(lastPage.nextLink);
            const continuationToken = urlParams.get('continuationToken');
            const encodedToken = encodeURIComponent(continuationToken || '');
            return encodedToken ?? null;
        },
    });
}
