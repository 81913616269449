import React, {ReactNode} from 'react';
import {Layout as LayoutContextInterface} from './Layout.types';
import useLayoutState from './useLayoutState';
import {useBackgroundState} from './useBackgroundState';

export interface LayoutProviderProps {
    children: ReactNode;
}

const Context = React.createContext<any>({});

export const Provider = (props: LayoutProviderProps) => {
    const {sidePanel, contentArea} = useLayoutState();
    // handle background image changes
    const background = useBackgroundState();

    return (
        <Context.Provider
            value={
                {
                    sidePanel,
                    contentArea,
                    background,
                } as LayoutContextInterface
            }
        >
            {props.children}
        </Context.Provider>
    );
};

export default Context;
