import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 'auto'),
        maxWidth: '85%',
        wordBreak: 'break-word',
    },
    headerCell: {
        '& > button': {
            paddingBottom: tokens.spacingVerticalS,
            paddingTop: tokens.spacingVerticalS,
        },
    },
    gridCell: {
        paddingBottom: tokens.spacingVerticalS,
        paddingTop: tokens.spacingVerticalS,
    },
    sessionLink: {
        wordBreak: 'break-word',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: '-webkit-box',
    },
    // Via https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
    // and https://www.a11yproject.com/posts/how-to-hide-content/
    visuallyHidden: {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '1px',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
    },
});
