import React from 'react';

function QuesionMark({...svgProps}) {
    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.9923 15.0078C39.3249 15.2678 46 22.1045 46 30.5002C46 39.0606 39.0604 46.0002 30.5 46.0002C26.4492 46.0002 22.7614 44.4463 20 41.902V54.8386C23.2196 56.2294 26.7698 57.0002 30.5 57.0002C35.8279 57.0002 40.7886 55.4278 44.9434 52.7218L52.5022 60.2807C54.6501 62.4286 58.1325 62.4286 60.2804 60.2807C62.4282 58.1328 62.4282 54.6504 60.2804 52.5025L52.7216 44.9437C55.4276 40.7889 57 35.8282 57 30.5002C57 19.2798 50.0266 9.6882 40.1774 5.82275L30.9923 15.0078Z"
                fill="#E7E7E7"
            />
            <path
                d="M49.7317 12.2683C44.9025 7.17589 38.0721 4 30.5 4C15.8645 4 4 15.8645 4 30.5C4 45.1355 15.8645 57 30.5 57C30.667 57 30.8337 56.9985 31 56.9954V45.9921C30.834 45.9973 30.6673 46 30.5 46C21.9396 46 15 39.0604 15 30.5C15 21.9396 21.9396 15 30.5 15C35.0346 15 39.1144 16.9473 41.9488 20.0512L49.7317 12.2683Z"
                fill="url(#paint0_linear_2426_13352)"
            />
            <path
                d="M49.7317 12.2683C44.9025 7.17589 38.0721 4 30.5 4C15.8645 4 4 15.8645 4 30.5C4 45.1355 15.8645 57 30.5 57C30.667 57 30.8337 56.9985 31 56.9954V45.9921C30.834 45.9973 30.6673 46 30.5 46C21.9396 46 15 39.0604 15 30.5C15 21.9396 21.9396 15 30.5 15C35.0346 15 39.1144 16.9473 41.9488 20.0512L49.7317 12.2683Z"
                fill="url(#paint1_radial_2426_13352)"
            />
            <path
                d="M49.7317 12.2683C44.9025 7.17589 38.0721 4 30.5 4C15.8645 4 4 15.8645 4 30.5C4 45.1355 15.8645 57 30.5 57C30.667 57 30.8337 56.9985 31 56.9954V45.9921C30.834 45.9973 30.6673 46 30.5 46C21.9396 46 15 39.0604 15 30.5C15 21.9396 21.9396 15 30.5 15C35.0346 15 39.1144 16.9473 41.9488 20.0512L49.7317 12.2683Z"
                fill="url(#paint2_radial_2426_13352)"
            />
            <path
                d="M49.7317 12.2683C44.9025 7.17589 38.0721 4 30.5 4C15.8645 4 4 15.8645 4 30.5C4 45.1355 15.8645 57 30.5 57C30.667 57 30.8337 56.9985 31 56.9954V45.9921C30.834 45.9973 30.6673 46 30.5 46C21.9396 46 15 39.0604 15 30.5C15 21.9396 21.9396 15 30.5 15C35.0346 15 39.1144 16.9473 41.9488 20.0512L49.7317 12.2683Z"
                fill="url(#paint3_radial_2426_13352)"
            />
            <path
                d="M49.7317 12.2683C44.9025 7.17589 38.0721 4 30.5 4C15.8645 4 4 15.8645 4 30.5C4 45.1355 15.8645 57 30.5 57C30.667 57 30.8337 56.9985 31 56.9954V45.9921C30.834 45.9973 30.6673 46 30.5 46C21.9396 46 15 39.0604 15 30.5C15 21.9396 21.9396 15 30.5 15C35.0346 15 39.1144 16.9473 41.9488 20.0512L49.7317 12.2683Z"
                fill="url(#paint4_radial_2426_13352)"
                fillOpacity="0.8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5 24.8182C29.1558 24.8182 28.0661 25.8696 28.0661 27.1667C28.0661 29.0495 26.4843 30.5758 24.5331 30.5758C22.5818 30.5758 21 29.0495 21 27.1667C21 22.1041 25.2533 18 30.5 18C35.7467 18 40 22.1041 40 27.1667C40 31.0246 37.5306 34.3254 34.0331 35.6782V39.5909C34.0331 41.4737 32.4513 43 30.5 43C28.5487 43 26.9669 41.4737 26.9669 39.5909V33.0758C26.9669 31.0749 28.6363 29.5626 30.566 29.5143C31.8787 29.4815 32.9339 28.4425 32.9339 27.1667C32.9339 25.8696 31.8442 24.8182 30.5 24.8182Z"
                fill="#ACE14B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5 24.8182C29.1558 24.8182 28.0661 25.8696 28.0661 27.1667C28.0661 29.0495 26.4843 30.5758 24.5331 30.5758C22.5818 30.5758 21 29.0495 21 27.1667C21 22.1041 25.2533 18 30.5 18C35.7467 18 40 22.1041 40 27.1667C40 31.0246 37.5306 34.3254 34.0331 35.6782V39.5909C34.0331 41.4737 32.4513 43 30.5 43C28.5487 43 26.9669 41.4737 26.9669 39.5909V33.0758C26.9669 31.0749 28.6363 29.5626 30.566 29.5143C31.8787 29.4815 32.9339 28.4425 32.9339 27.1667C32.9339 25.8696 31.8442 24.8182 30.5 24.8182Z"
                fill="url(#paint5_radial_2426_13352)"
                fillOpacity="0.8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5 24.8182C29.1558 24.8182 28.0661 25.8696 28.0661 27.1667C28.0661 29.0495 26.4843 30.5758 24.5331 30.5758C22.5818 30.5758 21 29.0495 21 27.1667C21 22.1041 25.2533 18 30.5 18C35.7467 18 40 22.1041 40 27.1667C40 31.0246 37.5306 34.3254 34.0331 35.6782V39.5909C34.0331 41.4737 32.4513 43 30.5 43C28.5487 43 26.9669 41.4737 26.9669 39.5909V33.0758C26.9669 31.0749 28.6363 29.5626 30.566 29.5143C31.8787 29.4815 32.9339 28.4425 32.9339 27.1667C32.9339 25.8696 31.8442 24.8182 30.5 24.8182Z"
                fill="url(#paint6_linear_2426_13352)"
                fillOpacity="0.7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5 24.8182C29.1558 24.8182 28.0661 25.8696 28.0661 27.1667C28.0661 29.0495 26.4843 30.5758 24.5331 30.5758C22.5818 30.5758 21 29.0495 21 27.1667C21 22.1041 25.2533 18 30.5 18C35.7467 18 40 22.1041 40 27.1667C40 31.0246 37.5306 34.3254 34.0331 35.6782V39.5909C34.0331 41.4737 32.4513 43 30.5 43C28.5487 43 26.9669 41.4737 26.9669 39.5909V33.0758C26.9669 31.0749 28.6363 29.5626 30.566 29.5143C31.8787 29.4815 32.9339 28.4425 32.9339 27.1667C32.9339 25.8696 31.8442 24.8182 30.5 24.8182Z"
                fill="url(#paint7_radial_2426_13352)"
                fillOpacity="0.5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5 24.8182C29.1558 24.8182 28.0661 25.8696 28.0661 27.1667C28.0661 29.0495 26.4843 30.5758 24.5331 30.5758C22.5818 30.5758 21 29.0495 21 27.1667C21 22.1041 25.2533 18 30.5 18C35.7467 18 40 22.1041 40 27.1667C40 31.0246 37.5306 34.3254 34.0331 35.6782V39.5909C34.0331 41.4737 32.4513 43 30.5 43C28.5487 43 26.9669 41.4737 26.9669 39.5909V33.0758C26.9669 31.0749 28.6363 29.5626 30.566 29.5143C31.8787 29.4815 32.9339 28.4425 32.9339 27.1667C32.9339 25.8696 31.8442 24.8182 30.5 24.8182Z"
                fill="url(#paint8_radial_2426_13352)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5 24.8182C29.1558 24.8182 28.0661 25.8696 28.0661 27.1667C28.0661 29.0495 26.4843 30.5758 24.5331 30.5758C22.5818 30.5758 21 29.0495 21 27.1667C21 22.1041 25.2533 18 30.5 18C35.7467 18 40 22.1041 40 27.1667C40 31.0246 37.5306 34.3254 34.0331 35.6782V39.5909C34.0331 41.4737 32.4513 43 30.5 43C28.5487 43 26.9669 41.4737 26.9669 39.5909V33.0758C26.9669 31.0749 28.6363 29.5626 30.566 29.5143C31.8787 29.4815 32.9339 28.4425 32.9339 27.1667C32.9339 25.8696 31.8442 24.8182 30.5 24.8182Z"
                fill="url(#paint9_radial_2426_13352)"
                fillOpacity="0.6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5 24.8182C29.1558 24.8182 28.0661 25.8696 28.0661 27.1667C28.0661 29.0495 26.4843 30.5758 24.5331 30.5758C22.5818 30.5758 21 29.0495 21 27.1667C21 22.1041 25.2533 18 30.5 18C35.7467 18 40 22.1041 40 27.1667C40 31.0246 37.5306 34.3254 34.0331 35.6782V39.5909C34.0331 41.4737 32.4513 43 30.5 43C28.5487 43 26.9669 41.4737 26.9669 39.5909V33.0758C26.9669 31.0749 28.6363 29.5626 30.566 29.5143C31.8787 29.4815 32.9339 28.4425 32.9339 27.1667C32.9339 25.8696 31.8442 24.8182 30.5 24.8182Z"
                fill="url(#paint10_radial_2426_13352)"
                fillOpacity="0.4"
            />
            <circle cx="30.5" cy="51.5" r="5.5" fill="url(#paint11_radial_2426_13352)" />
            <circle
                cx="30.5"
                cy="51.5"
                r="5.5"
                fill="url(#paint12_radial_2426_13352)"
                fillOpacity="0.5"
            />
            <circle
                cx="30.5"
                cy="51.5"
                r="5.5"
                fill="url(#paint13_radial_2426_13352)"
                fillOpacity="0.8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.75 21.375C12.75 21.5821 12.5821 21.75 12.375 21.75C12.1679 21.75 12 21.5821 12 21.375C12 21.1679 12.1679 21 12.375 21C12.5821 21 12.75 21.1679 12.75 21.375ZM10.5 30C10.7761 30 11 29.7761 11 29.5C11 29.2239 10.7761 29 10.5 29C10.2239 29 10 29.2239 10 29.5C10 29.7761 10.2239 30 10.5 30ZM9.75 34.375C9.75 34.5821 9.58211 34.75 9.375 34.75C9.16789 34.75 9 34.5821 9 34.375C9 34.1679 9.16789 34 9.375 34C9.58211 34 9.75 34.1679 9.75 34.375ZM6.375 35.75C6.58211 35.75 6.75 35.5821 6.75 35.375C6.75 35.1679 6.58211 35 6.375 35C6.16789 35 6 35.1679 6 35.375C6 35.5821 6.16789 35.75 6.375 35.75ZM14.75 37.375C14.75 37.5821 14.5821 37.75 14.375 37.75C14.1679 37.75 14 37.5821 14 37.375C14 37.1679 14.1679 37 14.375 37C14.5821 37 14.75 37.1679 14.75 37.375ZM15.375 44.75C15.5821 44.75 15.75 44.5821 15.75 44.375C15.75 44.1679 15.5821 44 15.375 44C15.1679 44 15 44.1679 15 44.375C15 44.5821 15.1679 44.75 15.375 44.75ZM21 46.5C21 46.7761 20.7761 47 20.5 47C20.2239 47 20 46.7761 20 46.5C20 46.2239 20.2239 46 20.5 46C20.7761 46 21 46.2239 21 46.5ZM18.75 47.375C18.75 47.5821 18.5821 47.75 18.375 47.75C18.1679 47.75 18 47.5821 18 47.375C18 47.1679 18.1679 47 18.375 47C18.5821 47 18.75 47.1679 18.75 47.375ZM14.375 21.75C14.5821 21.75 14.75 21.5821 14.75 21.375C14.75 21.1679 14.5821 21 14.375 21C14.1679 21 14 21.1679 14 21.375C14 21.5821 14.1679 21.75 14.375 21.75Z"
                fill="#FFCF25"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2426_13352"
                    x1="34.9289"
                    y1="47.5256"
                    x2="24.1145"
                    y2="-2.83915"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D951C3" />
                    <stop offset="0.248931" stopColor="#FFD94D" />
                    <stop offset="0.382155" stopColor="#FDED5E" />
                    <stop offset="0.826324" stopColor="#FFFCE4" />
                    <stop offset="1" stopColor="#ECECEC" />
                </linearGradient>
                <radialGradient
                    id="paint1_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(44.6495 24.4826) rotate(-28.7654) scale(28.7304 68.1624)"
                >
                    <stop offset="0.257368" stopColor="#E7E7E7" />
                    <stop offset="0.937986" stopColor="#E7E7E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint2_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(20.79 39.8446) rotate(-51.0897) scale(20.4002 28.7672)"
                >
                    <stop offset="0.327504" stopColor="#D53E4E" />
                    <stop offset="0.69963" stopColor="#FFB7A0" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(19.4645 52.1341) rotate(-71.6405) scale(7.01386 17.7311)"
                >
                    <stop offset="0.260417" stopColor="#F692FF" stopOpacity="0.7" />
                    <stop offset="0.817708" stopColor="#F666F9" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint4_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(25.1069 56.4904) rotate(111.29) scale(16.9552 7.98368)"
                >
                    <stop offset="0.26575" stopColor="#510349" />
                    <stop offset="0.450286" stopColor="#741970" />
                    <stop offset="0.576468" stopColor="#741970" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint5_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(30.7355 26.8636) rotate(56.3271) scale(8.92105 8.72792)"
                >
                    <stop offset="0.57043" stopColor="#ACE14B" />
                    <stop offset="0.735505" stopColor="#CAF66C" stopOpacity="0.78" />
                    <stop offset="0.872143" stopColor="#E3FFAF" stopOpacity="0" />
                    <stop offset="1" stopColor="#ACE14B" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint6_linear_2426_13352"
                    x1="27.6736"
                    y1="33.6818"
                    x2="30.3779"
                    y2="30.1785"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7DC02C" />
                    <stop offset="0.772715" stopColor="#ACE14B" />
                    <stop offset="1" stopColor="#ACE14B" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint7_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(31.5992 36.1818) scale(2.74793 17.2742)"
                >
                    <stop offset="0.194299" stopColor="#CAF76C" />
                    <stop offset="0.412587" stopColor="#C2ED67" stopOpacity="0.7" />
                    <stop offset="0.82109" stopColor="#ACE14B" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint8_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(30.9711 26.4848) rotate(23.036) scale(5.03361 4.833)"
                >
                    <stop offset="0.53125" stopColor="#5F8623" />
                    <stop offset="0.739641" stopColor="#7DC02C" />
                    <stop offset="1" stopColor="#ACE14B" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint9_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(36.4669 27.697) rotate(121.692) scale(16.7383 22.1146)"
                >
                    <stop stopColor="#ACE14B" stopOpacity="0" />
                    <stop offset="0.88057" stopColor="#7DC02C" />
                    <stop offset="1" stopColor="#5F8623" />
                </radialGradient>
                <radialGradient
                    id="paint10_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(24.6116 27.1667) rotate(17.1163) scale(3.86109 3.59193)"
                >
                    <stop stopColor="#CAF76C" />
                    <stop offset="0.406976" stopColor="#C2ED67" stopOpacity="0.7" />
                    <stop offset="0.731811" stopColor="#ACE14B" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint11_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(31.5402 48.9794) rotate(108.435) scale(10.1112)"
                >
                    <stop stopColor="#F1FFB7" />
                    <stop offset="0.25" stopColor="#B9DC4E" />
                    <stop offset="0.645065" stopColor="#6FB12F" />
                    <stop offset="1" stopColor="#FF7C03" />
                </radialGradient>
                <radialGradient
                    id="paint12_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(30.3103 57) rotate(-90) scale(3.98276 7.87069)"
                >
                    <stop stopColor="#FFAB5D" />
                    <stop offset="1" stopColor="#FFC499" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint13_radial_2426_13352"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(30.5 51.5) rotate(97.8153) scale(9.7631)"
                >
                    <stop offset="0.446038" stopColor="white" stopOpacity="0" />
                    <stop offset="0.841379" stopColor="white" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default QuesionMark;
