import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    root: {
        maxWidth: '500px',
    },
});
