import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
        marginBottom: tokens.spacingVerticalXL,
    },
    capacityInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: tokens.spacingVerticalL,
        ...shorthands.gap('10px'),
        '& > div': {
            fontSize: tokens.fontSizeBase300,
            fontWeight: tokens.fontWeightRegular,
            lineHeight: tokens.lineHeightBase300,
            '& span:first-of-type': {
                color: tokens.colorNeutralForeground2,
                marginRight: tokens.spacingHorizontalS,
            },
        },
    },
    switchCapacityBtn: {
        ...shorthands.margin(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalS,
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
        ),
    },
});
