import {DismissIcon} from '@/components/ui/icons';
import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Link,
    Spinner,
    Tooltip,
} from '@fluentui/react-components';
import useClasses from './ShareNoAccessDialog.styles';
import {ShareNoAccessDialogProps} from './Promptbook.types';
import {useTranslation} from 'react-i18next';
import {useEffect, useRef} from 'react';

export default function ShareNoAccessDialog({
    errorStatus,
    isLoading,
    open,
    onClose,
}: ShareNoAccessDialogProps) {
    const classes = useClasses();
    const dialogTriggerRef = useRef<HTMLButtonElement>(null);
    const {t} = useTranslation('promptbooks');

    useEffect(() => {
        if (dialogTriggerRef.current) {
            dialogTriggerRef.current.focus();
        }
    }, []);

    return (
        <Dialog data-testid="share-noaccess-promptbook-dialog" open={open}>
            <DialogSurface className={classes.shareNoAccessRoot}>
                <DialogBody>
                    <DialogTitle
                        data-testid="share-noaccess-pb-title"
                        className={classes.header}
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<DismissIcon />}
                                    ref={dialogTriggerRef}
                                    onClick={() => {
                                        onClose?.();
                                    }}
                                />
                            </DialogTrigger>
                        }
                    >
                        {isLoading && (
                            <Spinner
                                labelPosition="below"
                                label={t('FetchingPromptbook')}
                                aria-live="assertive"
                                tabIndex={0}
                            />
                        )}

                        {!isLoading && errorStatus === 403 && (
                            <>
                                <Tooltip
                                    content={t('ShareNoAccessMessageToolTip')}
                                    relationship="description"
                                >
                                    <div
                                        className={classes.MessageDisplay}
                                        tabIndex={0}
                                        aria-live="assertive"
                                    >
                                        {t('ShareNoAccessMessage')}
                                    </div>
                                </Tooltip>
                                <div className={classes.displayText} aria-live="assertive">
                                    <p>
                                        {t('SharePermsMessage')}
                                        <Link
                                            className={classes.leftSpacing}
                                            href={
                                                'https://learn.microsoft.com/en-us/security-copilot/'
                                            }
                                            target="_blank"
                                        >
                                            {t('LearnMore')}
                                        </Link>
                                    </p>
                                </div>
                            </>
                        )}
                        {!isLoading && errorStatus === 404 && (
                            <>
                                <Tooltip
                                    content={t('SharePromptbookNotFoundToolTip')}
                                    relationship="description"
                                >
                                    <div
                                        className={classes.MessageDisplay}
                                        tabIndex={0}
                                        aria-live="assertive"
                                    >
                                        {t('SharePromptbookNotFound')}
                                    </div>
                                </Tooltip>
                            </>
                        )}
                        {!isLoading &&
                            (errorStatus === null ||
                                (errorStatus !== 403 && errorStatus !== 404)) && (
                                <>
                                    <Tooltip
                                        content={t('SharePromptbookNotAvailableToolTip')}
                                        relationship="description"
                                    >
                                        <div
                                            className={classes.MessageDisplay}
                                            tabIndex={0}
                                            aria-live="assertive"
                                        >
                                            {t('SharePromptbookNotAvailable')}
                                        </div>
                                    </Tooltip>
                                    <div className={classes.retryFont} aria-live="assertive">
                                        <p>{t('RetryMessage')}</p>
                                    </div>
                                </>
                            )}
                    </DialogTitle>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
