import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {ExpiryDateResponse} from './app.types';

export default function useGetEapExpired(
    useQueryOptions?: Pick<UseQueryOptions<ExpiryDateResponse>, 'enabled'>,
) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['auth', 'expiryDate'],
        queryFn: async () => {
            return await customFetch<ExpiryDateResponse>(`/auth/expiryDate`, {method: 'GET'});
        },
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchInterval: 300_000,
        ...useQueryOptions,
    });
}
