import {
    Button,
    Divider,
    Input,
    TextareaState,
    useTextareaStyles_unstable,
} from '@fluentui/react-components';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {DismissIcon, SkillIcon, SendIcon} from '@/components/ui/icons';
import Textarea from '@/components/ui/Textarea';
import useClasses from './PromptBarSkillForm.styles';
import {PromptBarSkillFormProps} from './PromptBarSkillForm.types';
import {SkillDescriptor, SkillInputDescriptor} from '@/api/skills';
import {PromptInputs} from '@/api/prompts';

export * from './PromptBarSkillForm.types';

export default function PromptBarSkillForm({
    skill,
    skillInputs,
    onCancel,
    onChange,
    onSubmit,
}: PromptBarSkillFormProps) {
    const classes = useClasses();
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const inputRefs = useRef<{[key: string]: HTMLTextAreaElement | null}>({});

    // Generate input defaults.
    const inputs = skill.inputs;
    const [inputValues, setInputValues] = useState<PromptInputs>(
        skillInputs ??
            inputs.reduce((values, input) => {
                if (input.required) {
                    values[input.name] = '';
                }
                return values;
            }, {} as PromptInputs),
    );

    // Reset parent state initially, otherwise it could include stale or empty data.
    useEffect(() => {
        onChange?.(inputValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Focus the first input on mount.
    useEffect(() => {
        // Per ADO #18471, the revert button should be focused first.
        buttonRef.current?.focus();

        // if (inputs[0]) {
        //     inputRefs.current[inputs[0].name]?.focus();
        // }
    }, [buttonRef.current]);

    // Update state based on form changes.
    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLTextAreaElement>, input: SkillInputDescriptor) => {
            const nextValues = {...inputValues, [input.name]: ev.target.value};
            setInputValues(nextValues);
            onChange?.(nextValues);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    // This callback constructs the aria-label dynamically based on the skill properties
    const createAriaLabel = useCallback((skill: SkillDescriptor) => {
        const skillName = skill.displayName ?? skill.name;
        return `Remove ${skillName}`;
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.skill}>
                <Button
                    ref={buttonRef}
                    appearance="outline"
                    shape="rounded"
                    icon={<SkillIcon className={classes.skillIcon} />}
                    onClick={onCancel}
                    data-testid="promptbar-remove-skill-button"
                    aria-label={createAriaLabel(skill)}
                >
                    {skill.displayName ?? skill.name}{' '}
                    <DismissIcon className={classes.dismissIcon} />
                </Button>
            </div>
            <Divider appearance="subtle" />
            <div className={classes.inputs}>
                {inputs.map((input) => (
                    <Textarea
                        key={input.name}
                        ref={(el) => (inputRefs.current[input.name] = el)}
                        value={inputValues[input.name] ?? ''}
                        size="large"
                        appearance="outline"
                        className={classes.textareaWrapper}
                        placeholder={`${input.placeholderValue ?? input.description ?? input.name}${
                            input.required ? '' : ' (optional)'
                        }`}
                        autosize
                        onChange={(ev) => handleChange(ev, input)}
                        onSubmit={onSubmit}
                        required={input.required}
                    />
                ))}
            </div>
        </div>
    );
}
