import {Title2} from '@fluentui/react-components';
import useClasses from './components.styles';

export default function MarkdownH4({children, className, ...props}: any) {
    const classes = useClasses();

    return (
        <h4>
            <Title2 className={classes.h4}>{children}</Title2>
        </h4>
    );
}
