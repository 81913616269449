import React, {useRef, useEffect} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import useStageClasses from '@/components/sections/tours/Tour.styles';
import useClasses from './Splash.styles';
import useStageManager from '@/components/ui/Tour/util/useStageManager';
import {mergeClasses, Button, useFocusFinders} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useTourContext, TourControls} from '@/components/ui/Tour';
import {useNavigate} from 'react-router-dom';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {find} from 'lodash-es';

const stages = [
    {
        name: 'meet',
        autoAdvance: true,
        autoStart: true,
    },
    {
        name: 'aiPoweredPartner',
        autoAdvance: true,
        autoStart: true,
    },
    {
        name: 'tryIt',
        autoAdvance: true,
        autoStart: true,
    },
];

// Deals with the animating of the app icon and the title, if needed this should be the place where we start making and caching data calls
function Splash() {
    const {activeStage} = useStageManager({stages});
    const stageClasses = useStageClasses();
    const classes = useClasses();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');
    const cycleClasses = mergeClasses(stageClasses.heroTitle, classes.cycleWrapper);
    const navigate = useNavigate();
    const {findLastFocusable} = useFocusFinders();

    const handleContinue = () => {
        tour.quitTour();
        navigate('/');
    };

    const controlsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (controlsRef.current === null) return;
        findLastFocusable(controlsRef.current)?.focus();
    }, [controlsRef]);

    return (
        <>
            <div className={tourClasses.tourContainerContent}>
                <AnimatePresence mode="wait">
                    {activeStage.name === 'meet' && (
                        <motion.div
                            className={mergeClasses(
                                stageClasses.heroTitle,
                                stageClasses.centeredText,
                            )}
                            initial={{opacity: 1}}
                            exit={{opacity: 0, transition: {delay: 4.5, duration: 1.2}}}
                        >
                            <motion.div
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{delay: 0.8, duration: 1.5}}
                                // onAnimationComplete={nextStage}
                                key="meet"
                                aria-live="assertive"
                            >
                                {t('analyst.meetCopilot')}
                            </motion.div>
                            <motion.div
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{delay: 1.8, duration: 1.5}}
                                onAnimationComplete={() => {}}
                                key="meetPartner"
                                aria-live="assertive"
                                className={cycleClasses}
                            >
                                {t('analyst.yourAIPartner')}
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <motion.div
                key="controls"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
                ref={controlsRef}
            >
                <TourControls
                    handlePrevious={tour.prevStop}
                    handleNext={handleContinue}
                    previousButton={t('common.back')}
                    nextButton={t('adminV2Fre.setup.Continue')}
                />
            </motion.div>
        </>
    );
}

export default Splash;
