import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingVerticalS,
            tokens.spacingHorizontalNone,
            tokens.spacingHorizontalS,
        ),
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    filterContainer: {
        backgroundColor: tokens.colorNeutralBackground5,
        width: '100%',
        fontSize: tokens.fontSizeBase300,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralBackground1),
        ':hover > *': {
            visibility: 'visible',
        },
        borderBottomLeftRadius: tokens.borderRadiusLarge,
        borderBottomRightRadius: tokens.borderRadiusLarge,
        borderTopRightRadius: tokens.borderRadiusLarge,
        borderTopLeftRadius: tokens.borderRadiusLarge,
    },
    accordion: {
        width: '100%',
    },
    accordionHeader: {
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalXS,
        ),
    },
    accordionHeaderCol: {
        width: '88%',
        fontWeight: tokens.fontWeightSemibold,
    },
    accordionItem: {
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        marginBottom: tokens.spacingVerticalM,
        marginTop: tokens.spacingVerticalM,
    },
    dropdownSelector: {
        minWidth: '100%',
        ...shorthands.borderStyle('none'),
        backgroundColor: tokens.colorNeutralBackground6,
        display: 'flex',
        height: tokens.lineHeightHero800,
        width: '100%',
        marginBottom: tokens.spacingVerticalL,
    },
    comboInput: {
        width: '100%',
    },
    searchInput: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.borderStyle('none'),
        width: '60%',
        height: tokens.lineHeightHero800,
        marginBottom: tokens.spacingVerticalL,
        '@media (max-width: 479px)': {
            width: '75%',
        },
        '& input::placeholder': {
            color: tokens.colorNeutralForeground1,
        },
    },
    fieldWrapper: {
        ...shorthands.padding(`${tokens.spacingVerticalMNudge} ${tokens.spacingHorizontalMNudge}`),
        backgroundColor: tokens.colorNeutralBackground6,
    },
    stackStyle: {
        marginBottom: '10px',
    },
    checkboxStyle: {
        marginRight: tokens.spacingHorizontalXS,
        marginTop: '5px',
    },
    imageStyle: {
        marginRight: tokens.spacingHorizontalM,
    },
    textStyle: {
        marginTop: tokens.spacingHorizontalXS,
    },
    tagsList: {
        listStyleType: 'none',
        marginBottom: tokens.spacingVerticalL,
        marginTop: tokens.spacingVerticalM,
        paddingLeft: tokens.spacingHorizontalNone,
        display: 'flex',
        flexWrap: 'wrap',
        gridGap: tokens.spacingHorizontalXXS,
    },
    badge: {
        marginLeft: '8px',
    },
    comboOptionGroup: {
        maxHeight: '200px',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorNeutralForeground3} ${tokens.colorTransparentBackground}`,
        '&::-webkit-scrollbar': {
            width: tokens.spacingHorizontalS,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorTransparentBackground,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorTransparentBackground,
            ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        },
    },
});
