export enum AllowedLanguageCodes {
    'en' = 'en',
    'es' = 'es',
    'ja' = 'ja',
    'fr' = 'fr',
    'de' = 'de',
    'pt-BR' = 'pt-BR',
    'it' = 'it',
    'zh-Hans' = 'zh-Hans',
    'ko' = 'ko',
    'nl' = 'nl',
    'sv' = 'sv',
    'pl' = 'pl',
    'pt-PT' = 'pt-PT',
    'no' = 'no',
    'tr' = 'tr',
    'da' = 'da',
    'fi' = 'fi',
    'zh-Hant' = 'zh-Hant',
    'ar' = 'ar',
    'th' = 'th',
    'he' = 'he',
    'cs' = 'cs',
    'hu' = 'hu',
    'uk' = 'uk',
    'ru' = 'ru',
}

export type MedeinaLanguageCode = keyof typeof AllowedLanguageCodes;

export interface LanguageOption {
    value: MedeinaLanguageCode;
    label: string;
}
