import {Button, Text, Title1} from '@fluentui/react-components';
import useClasses from './SessionsEmpty.styles';
import {ColorScheme, useAppState} from '@/api/app';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';

// a switch statement that returns the correct background image based on the current theme
function getBackgroundImage(colorScheme: ColorScheme) {
    switch (colorScheme) {
        case ColorScheme.Dark:
        case ColorScheme.HighContrast:
            return '/images/features/home/start_tour_dark.png';
        case ColorScheme.Light:
            return '/images/features/home/start_tour_light.png';
        default:
            return '/images/features/home/start_tour_dark.png';
    }
}

export default function SessionsEmpty() {
    const classes = useClasses();
    const {colorScheme} = useAppState();
    const {t} = useTranslation('common');
    return (
        <div
            className={classes.root}
            style={{
                backgroundImage: `url(${getBackgroundImage(colorScheme)})`,
            }}
        >
            <div className={classes.content}>
                <Title1 as="h1" className={classes.title}>
                    {t('home.exploreTitle')}
                </Title1>
                <p>
                    <Text>{t('home.exploreSubtitle')}</Text>
                </p>
                <div className={classes.buttonRow}>
                    <Button as="a" href="/tour/welcome">
                        {t('home.trainingButton')}
                    </Button>
                    <Button as="a" href={MedeinaVariables.DocumentationUri}>
                        {t('home.documentationButton')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
