export enum SupportCentralHostingEvents {
    Minimize = 0,
    Maximize = 1,
    LoadSuccess = 2,
    ApiFailure = 3,
    UnexpectedFailure = 4,
    BundledLoadFailure = 5,
}

export interface ISupportCentralAuthConfig {
    /*
     * Host for the Support Central to make api calls.
     */
    endpointUrl: string;

    /*
     * AAD access token to make api calls to https://portal.office.com.
     */
    token: string;

    /*
     * The Support Central will call this method when the provided token expires.
     */
    tokenRefresh: () => Promise<string>;
}

export interface ISupportCentralSettings {
    /*
     * False by default. When it is true, the Support Central will be loaded in maximized state and
     * there wil be no way to minimize the widget.
     */
    disableMinimization: boolean;

    /*
     * False by default. When it is true, the Support Central will be loaded in maximized state.
     */
    maximizeByDefault: boolean;

    /*
     * Optional, when passed it loads the self help articles related to the query when the Support Central is loaded.
     * This needs maximizeByDefault to be true.
     */
    userQuery?: string;

    /*
     * Optional support request GUID, when passed it will open the service request details when the Support Central is loaded.
     */
    requestId?: string;

    /*
     * Locale for the Support Central.
     */
    locale: string;

    /**
     * Boolean to toggle the request history tab
     */
    hideHistory?: boolean;
}

export interface ISupportCentralStateStyles {
    left?: string;
    right?: string;
    bottom?: string;
    top?: string;
}

export interface ISupportCentralStyles {
    /*
     * Style of the Support Central's minimized state.
     */
    minStateStyle?: ISupportCentralStateStyles;

    /*
     * Style of the Support Central's maximized state.
     */
    maxStateStyle?: ISupportCentralStateStyles;
}

export interface ISupportCentralConfig {
    /*
     * Reference to HTMLDivElement in Teams admin center to host the Support Central.
     */
    parentDiv?: HTMLDivElement;

    /*
     * Hosting app name, in this case it is 'teamsadmincenter'.
     */
    appName: string;

    /*
     * Auth config to make api calls to https://portal.office.com from teams admin center.
     */
    auth: ISupportCentralAuthConfig;

    /*
     * Settings for the Support Central.
     */
    settings: ISupportCentralSettings;

    /*
     * Minimized and maximized state styles to be applied on the Support Central.
     */
    styles: ISupportCentralStyles;

    /*
     * If provided, the Support Central will call this method on load success and
     * whenever the widget is minimized or maximized.
     */
    eventListener?: (eventType: SupportCentralHostingEvents) => void;

    /*
     * If provided, the Support Central will call this method to get the client context and
     * will be sent to Alchemy to get context related solutions.
     */
    clientContext?: () => string;
}
