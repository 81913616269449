import {readFromSessionStorage} from '@/util/sessionStorage.util';
import MedeinaVariables from '@/util/variables';
import {WORKSPACE_NAMESPACE} from '@/components/sections/workspaces/WorkspacesProvider/workspaces.constants';
import {WorkspaceSessionData} from '@/components/sections/workspaces/WorkspacesProvider';
import {isGeoPodEnabled} from '@/components/sections/workspaces/WorkspacesProvider';
/** A utility function to help build Microsoft Security Graph APIs
 *  The utility will create return the full URL for the endpoint based on:
 *  the parameters passed in, the current workspace stored in session storage, and the feature flag for this utility
 */

interface MSGApiOptions {
    /** Request path */
    path: string;
    /** API version to append */
    version?: string;
    /** Global endpoints do not include geo/pod information */
    isGlobal?: boolean;
    /** Sets the path from podId and removes workspaces/{workspacename} */
    podPath?: boolean;
}

const API_VERSION = '2024-03-01-preview';

export default function securityGraphApi(options: MSGApiOptions) {
    // Determine the API version and how we'll append
    const apiVersion = `${options.path.includes('?') ? '&' : '?'}api-version=${
        options.version || API_VERSION
    }`;
    // Get the current workspace from session storage, this was set during the app bootstrapping
    const workspace = readFromSessionStorage<WorkspaceSessionData>(WORKSPACE_NAMESPACE);
    const geoEnabled = isGeoPodEnabled();
    if (workspace?.name && geoEnabled) {
        if (options.isGlobal) {
            const globalUrl = `${
                geoEnabled ? MedeinaVariables.SecurityGraphUri : MedeinaVariables.FidelisApiUri
            }${options.path}${apiVersion}`;
            return globalUrl;
        } else if (options.podPath) {
            const podUrl = `https://${workspace.managementUrl}/pods/${workspace.podId}${options.path}${apiVersion}`;
            return podUrl;
        } else {
            const podUrl = `https://${workspace.managementUrl}/${workspace.path}${options.path}${apiVersion}`;
            return podUrl;
        }
    }

    // GA version of the API
    const currentUrl = `${MedeinaVariables.FidelisSecCopUri}${options.path}${apiVersion}`;
    return currentUrl;
}
