import React from 'react';
import {Text, Image, Badge} from '@fluentui/react-components';
import {AdminIcon} from '@/components/ui/icons';
import useClasses from './SkillRowMetadata.styles';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {SkillsetDescriptor, useGetSkillsetCustomIcon} from '@/api/skills';
import {useTranslation} from 'react-i18next';
import {SkillsetMetadata, getSkillsetMetadata} from './Utils/SkillsetMetadata.utils';
import SkillTitle from './SkillTitle';
import {useViewportSize} from '@/components/ui/Grid';

export interface SkillRowMetadataProps {
    skillsetDescriptor?: SkillsetDescriptor;
}

export default function SkillRowMetadata({skillsetDescriptor}: SkillRowMetadataProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const {sm: isSmallScreen} = useViewportSize();

    const {data: icon} = useGetSkillsetCustomIcon(
        skillsetDescriptor?.name,
        skillsetDescriptor?.icon,
    );

    const {
        displayName,
        pluginName,
        descriptionForDisplay,
        isPublishedByMicrosoft,
        isUserScopePlugin,
        msPublishedImagePath,
    }: SkillsetMetadata = getSkillsetMetadata(skillsetDescriptor);

    const renderPluginName = () => {
        return (
            <>
                {isPublishedByMicrosoft ? (
                    <MedeinaInfoLabel
                        labelContent={
                            <SkillTitle
                                displayName={displayName}
                                skillsetDescriptor={skillsetDescriptor}
                            />
                        }
                        infoContent={t('ContentModal.PublishedByMicrosoft', {
                            pluginName: pluginName,
                        })}
                        defaultButtonWrap={true}
                    />
                ) : (
                    <SkillTitle displayName={displayName} skillsetDescriptor={skillsetDescriptor} />
                )}
                {isUserScopePlugin && (
                    <Badge
                        color="important"
                        shape="circular"
                        size="small"
                        appearance="filled"
                        icon={<AdminIcon />}
                        aria-label="Private"
                        className={classes.privateBadge}
                    >
                        {t('PrivateText')}
                    </Badge>
                )}
            </>
        );
    };

    const iconSize = isSmallScreen ? 24 : 40;

    return (
        <div className={classes.wrapper}>
            <div className={classes.iconCol}>
                <Image
                    height={iconSize}
                    width={iconSize}
                    fit="default"
                    className={classes.image}
                    src={isPublishedByMicrosoft ? msPublishedImagePath : icon}
                    aria-label={`${displayName} icon`}
                />
            </div>
            <div className={classes.textCol}>
                <div className={classes.textRow}>{renderPluginName()}</div>
                <Text className={classes.descriptionText}>{descriptionForDisplay}</Text>
            </div>
        </div>
    );
}
