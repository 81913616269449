import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {DeletePinnedEvaluationRequest, PinnedEvaluation} from './evaluations.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
export default function useDeletePinnedEvaluation() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: DeletePinnedEvaluationRequest) => {
            const response = await customFetch<Response>(
                `/sessions/${body.sessionId}/pinnedevaluations/${body.evaluationId}`,
                {
                    method: 'DELETE',
                    body,
                },
                true,
            );
            return response.status === 200;
        },
        onSettled: (evaluation, error, body) => {
            // Refetch anything using pinned evaluations.
            queryClient.refetchQueries({
                queryKey: ['sessions', body.sessionId, 'pinnedevaluations'],
            });
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
    });
}
