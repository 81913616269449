import {useState, useMemo, useEffect} from 'react';
import {
    TourControls,
    TourStageContainer,
    useTourContext,
    ValidTourErrorPropCombination,
} from '@/components/ui/Tour';
import {
    Combobox,
    Link,
    mergeClasses,
    Option,
    OptionGroup,
    useFocusFinders,
    InfoLabel,
    Field,
    LabelProps,
    FieldProps,
    SelectionEvents,
    OptionOnSelectData,
} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useTranslation} from 'react-i18next';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useGetCapacities} from '@/api/capacities';
import {Capacity} from '@/api/capacities';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {useGetWorkspaces} from '@/api/workspaces';
import SwitchCapacity from '@/components/SwitchCapacity';
import useClasses from './CapacitySelect.styles';
import {Workspace} from '@/api/workspaces';

interface CapacitySelectProps {
    value?: string;
    required?: boolean;
    onOptionSelect?: (selectedCapacity: Capacity) => void;
    fieldProps?: FieldProps;
    showOnlyAssignableCapacities?: boolean;
}

export default function CapacitySelect(props: CapacitySelectProps) {
    const {showOnlyAssignableCapacities, value: propValue, fieldProps, required} = props;
    const classes = useClasses();
    // translation related functions by namespace
    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    // data fetching
    const {data, error, isLoading} = useGetCapacities();
    const capacityItems = data?.value || [];
    console.log({capacityItems});
    // when showOnlyAssignableCapacities is true, we only show capacities that are assignable to the workspace
    // we enable fetching of workspaces to get the assignable capacities
    const {data: workspacesData, error: workspaceError} = useGetWorkspaces({
        enabled: showOnlyAssignableCapacities,
    });

    // the value of the component is handled externally, update the internal value only when the prop value changes
    useEffect(() => {
        if (propValue !== value) {
            setValue(propValue || '');
        }
        // only trigger change when the prop value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propValue]);

    //
    const selectableCapacities = useMemo(() => {
        if (!showOnlyAssignableCapacities) {
            return capacityItems || [];
        }
        // from the workspace data get capacity names that are already linked from the capacity property
        const linkedWorkspaces =
            workspacesData?.value?.filter((workspace: Workspace) => workspace.capacity) || [];
        // from linkedWorkspaces create a simple array of capacity names
        const assignedCapacities = linkedWorkspaces.map(
            (workspace: Workspace) => workspace.capacity?.referenceName,
        );
        console.log({assignedCapacities});

        return (
            data?.value?.filter(
                (capacity: Workspace) => !assignedCapacities.includes(capacity.name),
            ) || []
        );
    }, [capacityItems, data?.value, showOnlyAssignableCapacities, workspacesData?.value]);

    const hasSingleSelectableCapacity = selectableCapacities.length === 1;

    const [value, setValue] = useState<string>(
        props.value ||
            (hasSingleSelectableCapacity && selectableCapacities
                ? selectableCapacities[0].name
                : ''),
    );

    // const [capacityItems, setCapacityItems] = useState<CapacityItem[]>([]);
    // const [capacitySelection, setCapacitySelection] = useState<string>('');
    // const [capacitiesLoaded, setCapacitiesLoaded] = useState<boolean>(false);

    // useEffect(() => {
    //     if (!!existingCapacityName && capacityItems.length > 0) {
    //         setCapacitySelection(existingCapacityName);
    //     }
    // }, [existingCapacityName, capacityItems]);

    // const getfooterText = (): JSX.Element => {
    //     const resourceText =
    //         capacityItems.length === 1
    //             ? t('adminV2Fre.capacitySelection.ThisWasAzureCreated')
    //             : t('adminV2Fre.capacitySelection.TheseWereAzureCreated');
    //     return (
    //         <>
    //             {resourceText}&nbsp;
    //             <Link
    //                 href={MedeinaVariables.AzurePortalUrl}
    //                 target="_blank"
    //                 aria-label={t('adminV2Fre.capacitySelection.ariaLabel.AzurePortalLink')}
    //             >
    //                 {commonLabel('AzurePortal')}
    //             </Link>
    //         </>
    //     );
    // };

    return (
        <Field required={required} {...fieldProps}>
            <MedeinaInfoLabel
                labelContent={t('createCapacity.CapacityLabel')}
                infoContent={t('switchCapacity.Info')}
                className={classes.label}
            />
            <Combobox
                //className={mergeClasses(tourClasses.dropDown)}
                placeholder={t('switchCapacity.Placeholder')}
                aria-label={commonLabel('Capacity')}
                data-test-id="capacity-dropdown"
                onOptionSelect={(ev, data) => {
                    console.log({data});
                    if (props.onOptionSelect) {
                        // match to the capacity object
                        const selectedCapacity = selectableCapacities.find(
                            (capacity: Capacity) => capacity.name === data.optionValue,
                        );
                        if (selectedCapacity) {
                            props.onOptionSelect(selectedCapacity);
                        } else {
                            throw new Error(t('ownerSettings.capacity.CapacityNotFound'));
                        }
                    } else {
                        setValue(data.optionValue || '');
                    }
                }}
                value={value}
                selectedOptions={value ? [value] : []}
            >
                <OptionGroup>
                    {selectableCapacities
                        ?.sort((itemA: Capacity, itemB: Capacity) =>
                            itemA.name > itemB.name ? 1 : -1,
                        )
                        .map(({id, name}: Capacity) => {
                            return (
                                <Option key={name} value={name}>
                                    {name}
                                </Option>
                            );
                        })}
                </OptionGroup>
            </Combobox>

            {/* <div className={tourClasses.tourSectionSpacing}>
                <FooterContent>{getfooterText()}</FooterContent>
            </div> */}
        </Field>
    );
}
