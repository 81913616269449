import {useContext, useEffect} from 'react';
import SidePanelContext from './SidePanelContext';
import NavigationContext from '@/components/Navigation/Context';
// hook that allows setting/unsetting the side panel and deals with the content rendering/unmounting
function useSidePanel() {
    const {setChildren} = useContext(SidePanelContext);
    const {setToggableActions} = useContext(NavigationContext);
    useEffect(() => {
        // we'll want to toggle the showSidePanelButton to true when we mount
        // set showSidePanelButton to false for private preview
        setToggableActions({sidePanelButton: true});
        return () => {
            setToggableActions({sidePanelButton: false});
            setChildren(null);
        };
    }, []);

    return setChildren;
}

export default useSidePanel;
