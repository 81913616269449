// Shared styles for stage layouts and fonts/type
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    textWrapper: {
        maxWidth: '600px',
    },
    icon: {
        height: '48px',
        width: 'auto',
        '@media (forced-colors: active)': {
            backgroundColor: 'Canvas',
        },
    },
    title: {
        marginBottom: '16px',
    },
    linksRow: {
        marginTop: '48px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...shorthands.gap(tokens.spacingHorizontalS),

        '@media (max-width: 479px)': {
            flexWrap: 'wrap',
        },
    },
});
