import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetRoleMembersResponse} from '.';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import securityGraphApi from '@/api/securityGraph.api';
import {isGeoPodEnabled} from '@/components/sections/workspaces/WorkspacesProvider';

export default function useGetRoleMembers(
    workspaceName: string,
    useQueryOptions?: Pick<UseQueryOptions<GetRoleMembersResponse>, 'enabled' | 'refetchOnMount'>,
) {
    const url = securityGraphApi({
        path: isGeoPodEnabled()
            ? `/policystore/workspaces/${workspaceName}/workspacePolicy`
            : `/policyStore/workspacePolicies?workspaceName=${workspaceName}`,
        version: '2024-01-01-preview',
        podPath: true,
    });

    return useQuery<GetRoleMembersResponse>({
        refetchOnMount: useQueryOptions?.refetchOnMount ?? false,
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['rbac', workspaceName],
        queryFn: async () =>
            await customFetch<GetRoleMembersResponse>(url, {
                method: 'GET',
                scopes: RESOURCE_SCOPES.FIDELIS,
            }),
        ...useQueryOptions,
    });
}
