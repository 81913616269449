import React, {useContext, useMemo} from 'react';
import {TableConfiguration} from './Table.types';

const TableContext = React.createContext<TableConfiguration>({} as TableConfiguration);

export const TableProvider = ({...props}) => {
    const requireRowHighlighting = props.tableType === 'modal';

    const getSourcePositionLineNumber = (sourcePosition: string): number =>
        parseInt(sourcePosition.split(':')[0]);

    const linePositions: number[] = useMemo(() => {
        const sourcePositionItems = props['data-sourcepos'].split('-');
        return [
            getSourcePositionLineNumber(sourcePositionItems[0]),
            getSourcePositionLineNumber(sourcePositionItems[1]),
        ];
    }, []);

    const tableConfiguration: TableConfiguration = {
        tableHasData: props.hasDataRows,
        rows: {
            highlightOnHover: requireRowHighlighting,
        },
        linePositions: linePositions,
    };

    return (
        <TableContext.Provider value={tableConfiguration}>{props.children}</TableContext.Provider>
    );
};

export const useTableContext = (): TableConfiguration => useContext(TableContext);
