import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetUsageDataFacetResponse, GetUsageDataFacetRequest} from './capacities.types';
import useFetch from '../useFetch';
import {DEFAULT_API_RETRY_COUNT, DEFAULT_API_STALE_TIME} from '../api.constants';

export default function useGetUsageDataFacets(
    {resourceName, startDate, endDate}: GetUsageDataFacetRequest,
    useQueryOptions?: Pick<UseQueryOptions<GetUsageDataFacetResponse>, 'enabled'>,
) {
    const url = `/usage/${resourceName}/facets?startDate=${startDate}&endDate=${endDate}`;
    const {customFetch} = useFetch();
    return useQuery<GetUsageDataFacetResponse>({
        ...useQueryOptions,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['usage-facets', resourceName, startDate, endDate],
        retry: DEFAULT_API_RETRY_COUNT,
        staleTime: DEFAULT_API_STALE_TIME,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                },
                true,
            );

            if (!response.ok) {
                const code = response.status;
                if (code === 500) {
                    throw new Error('Unknown error occurred. Please try again later.');
                } else if (code === 404) {
                    throw new Error('Capacity resource does not exist.');
                } else {
                    throw new Error(`Unexpected status code: ${code}.`);
                }
            }

            const data = await response.json();
            return data as GetUsageDataFacetResponse;
        },
    });
}
