import {Title3} from '@fluentui/react-components';
import useClasses from './components.styles';

export default function MarkdownH5({children, className, ...props}: any) {
    const classes = useClasses();

    return (
        <h5>
            <Title3 className={classes.h5}>{children}</Title3>
        </h5>
    );
}
