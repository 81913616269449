import {makeStyles} from '@fluentui/react-components';
import {m} from 'framer-motion';

export default makeStyles({
    addedMemberListItemWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
        '& > div:nth-of-type(1)': {
            flexGrow: 1,
            width: '100%',
        },
        '& > div:nth-of-type(2)': {
            display: 'flex',
        },
    },
    memberPersona: {
        '& > span.fui-Persona__secondaryText': {
            marginTop: '1px',
        },
    },
});
