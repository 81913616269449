import {useQuery} from '@tanstack/react-query';
import {GetEvaluationImagesRequest, EvaluationImageSource} from './evaluations.types';
import useSessionsFetch from '../useSessionsFetch';
import {PageResponse} from '../api.types';

export default function useGetEvaluationImages({
    sessionId,
    promptId,
    evaluationId,
}: GetEvaluationImagesRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useQuery({
        queryKey: [
            'sessions',
            sessionId,
            'prompts',
            promptId,
            'evaluations',
            evaluationId,
            'images',
        ],
        queryFn: async () => {
            const evaluationImages = await sessionsFetch<PageResponse<EvaluationImageSource>>(
                `${sessionId}/prompts/${promptId}/evaluations/${evaluationId}/images`,
                {
                    method: 'GET',
                },
            );

            return evaluationImages;
        },

        enabled: Boolean(evaluationId),

        // Immediately return errors after they occur, rather than retrying.
        retry: false,
    });
}
