import React, {ReactNode, useCallback, useState} from 'react';
import {useAppState, defaults} from '@/api/app';
import useLayoutScroll from './useLayoutScroll';

// a utility hook that allows for managing the layout state between appState and components
function useLayoutState() {
    const appState = useAppState();
    const {layout, updateLayout} = appState || defaults;
    const {sidePanel, contentArea} = layout;

    // Handle refs for the two areas
    const [sidePanelRef, setSidePanelRef] = useState<any>(null);
    const [contentAreaRef, setContentAreaRef] = useState<any>(null);

    // Handle scrolling for the two areas
    const sidePanelScroll = useLayoutScroll({ref: sidePanelRef});
    const contentAreaScroll = useLayoutScroll({ref: contentAreaRef});

    // setup aliases for the side panel actions
    const toggleClosed = useCallback(
        () => updateLayout({...layout, sidePanel: {...sidePanel, open: false}}),
        [layout, sidePanel, updateLayout],
    );
    const toggleOpen = useCallback(
        () => updateLayout({...layout, sidePanel: {...sidePanel, open: true}}),
        [layout, sidePanel, updateLayout],
    );
    const toggle = useCallback(() => {
        if (sidePanel.open) {
            toggleClosed();
        } else {
            toggleOpen();
        }
    }, [sidePanel, toggleClosed, toggleOpen]);

    const setWidth = useCallback(
        (width: number) => updateLayout({...layout, sidePanel: {...sidePanel, width}}),
        [layout, sidePanel, updateLayout],
    );

    // setup aliases for the content area actions
    const setFullWidth = useCallback(
        (fullWidthMode: boolean) =>
            updateLayout({
                ...layout,
                contentArea: {...contentArea, fullWidth: fullWidthMode},
            }),
        [],
    );

    return {
        sidePanel: {
            ...sidePanel,
            toggle,
            toggleClosed,
            toggleOpen,
            setWidth,
            ref: sidePanelRef,
            setRef: setSidePanelRef,
            scroll: sidePanelScroll,
        },
        contentArea: {
            ...contentArea,
            setFullWidth,
            ref: contentAreaRef,
            setRef: setContentAreaRef,
            scroll: contentAreaScroll,
        },
    };
}

export default useLayoutState;
