import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
        marginBottom: tokens.spacingVerticalXL,
    },
    capacityUnitLabelContainer: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalL,
        height: tokens.lineHeightBase300,
    },
    capacityButtonContainer: {
        '& > button': {
            marginRight: tokens.spacingHorizontalS,
        },
    },
    errorTag: {
        color: tokens.colorPaletteCranberryBorderActive,
        marginBottom: tokens.spacingVerticalL,
        '& > span:first-child': {
            position: 'relative',
            top: '3px',
            paddingRight: '3px',
        },
    },
});
