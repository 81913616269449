import useClasses from './Sessions.styles';
import {useGetSessions} from '@/api/sessions';
import RecentSessions from './RecentSessions';
import SessionsEmpty from './SessionsEmpty';
import {Spinner} from '@fluentui/react-components';
import GridContainer from '@/components/ui/Grid/GridContainer';
import GridItem from '@/components/ui/Grid/GridItem';
import {useMemo} from 'react';

export default function NewHomeSessions() {
    const classes = useClasses();
    const {data: sessions, isLoading} = useGetSessions({
        sortBy: 'updatedAt',
        sortDirection: 'desc',
        limit: 10,
    });
    const sessionsCount = useMemo(
        () => sessions?.pages.map((page) => page.value).flat().length ?? 0,
        [sessions],
    );
    return (
        <div className={classes.root}>
            {isLoading ? (
                <Spinner className={classes.loading} size="extra-large" />
            ) : sessionsCount === 0 ? (
                <GridContainer>
                    <GridItem sm={12} md={12} lg={12} xlg={12} xxlg={12} xxxlg={12}>
                        <SessionsEmpty />
                    </GridItem>
                </GridContainer>
            ) : (
                <RecentSessions />
            )}
        </div>
    );
}
