import {useMemo} from 'react';
import {useGetPinnedEvaluations} from '@/api/evaluations';

interface UseIsEvaluationPinnedProps {
    sessionId: string;
    evaluationId?: string;
}

function useIsEvaluationPinned(props: UseIsEvaluationPinnedProps) {
    const {sessionId, evaluationId} = props;
    const {data: pinnedEvaluations} = useGetPinnedEvaluations({sessionId});

    // Determine pinned state for this evaluation.
    const isPinned = useMemo<boolean>(() => {
        if (!evaluationId) return false;
        if (pinnedEvaluations?.value.find((pin) => pin.evaluationId === evaluationId)) {
            return true;
        }
        return false;
    }, [evaluationId, pinnedEvaluations?.value]);
    return isPinned;
}

export default useIsEvaluationPinned;
