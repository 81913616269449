import {makeStyles, shorthands} from '@fluentui/react-components';

export default makeStyles({
    visuallyHidden: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        ...shorthands.margin('-1'),
        ...shorthands.padding(0),
        ...shorthands.overflow('hidden'),
        clip: 'rect(0, 0, 0, 0)',
        ...shorthands.border(0),
    },
});
