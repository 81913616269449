import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    wrapper: {
        gridColumnSpan: '2',
        display: 'flex',

        '@media (max-width: 479px)': {
            gridColumnStart: '1',
            gridColumnEnd: 'none',
            gridRowStart: '1',
            gridRowEnd: '1',
        },
    },
    textCol: {
        flexGrow: 1,
        marginInlineStart: tokens.spacingHorizontalS,
        marginInlineEnd: tokens.spacingHorizontalS,
    },
    textRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '@media (max-width: 479px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',

            '& .fui-Badge': {
                marginInlineStart: '0',
            },
        },
    },
    skillsetNameText: {
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
    },
    descriptionText: {
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
    },
    iconCol: {
        flexShrink: 0,
        flexGrow: 0,
        marginInlineStart: tokens.spacingHorizontalXS,
        marginInlineEnd: tokens.spacingHorizontalM,

        '@media (max-width: 479px)': {
            marginInlineStart: '0',
            marginInlineEnd: tokens.spacingHorizontalXS,
        },
    },
    image: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),

        '@media (max-width: 479px)': {
            ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXS),
        },
    },
    editIcon: {
        color: tokens.colorBrandForeground1,
    },
    privateBadge: {
        marginInlineStart: tokens.spacingHorizontalXS,
    },
    previewBadge: {
        '@media (max-width: 479px)': {
            marginInlineStart: '0',
        },
    },
});
