import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Option,
    useFocusFinders,
    Label,
    Input,
    Dropdown,
    OptionGroup,
    Checkbox,
    Link,
    mergeClasses,
    useId,
} from '@fluentui/react-components';
import useClasses from './CreateCapacity.styles';
import {useEffect, useState, useRef, useMemo, useCallback} from 'react';
import {DismissIcon} from '@/components/ui/icons';
import {useGetUserInfo} from '@/api/app';
import {useTranslation} from 'react-i18next';
import SubscriptionSelector from './sections/tours/admin-ga/stops/SubscriptionSelector';
import ResourceGroupSelector from './sections/tours/admin-ga/stops/ResourceGroupSelector';
import {GEO_DATA, REGION_DATA} from './App.constants';
import ErrorLabel from './sections/tours/admin-ga/stops/ErrorLabel';
import formatCurrency from '@/util/currencyFormatterUtil';
import MedeinaVariables from '@/util/variables';
import SetupInProgress from './sections/tours/admin-ga/stops/SetupInprogress';
import {useSetupAndProvisionFidelisConstructs} from '@/api/capacities';
import useLocations from '../util/useLocations';
import {useWorkspaceState} from './workspaces/workspaceStateProvider';
import {useQueryClient} from '@tanstack/react-query';
import {CreateCapacityStatus} from './App.types';
import {MedeinaInfoLabel} from './ui/Buttons/MedeinaInfoLabel';
import AnnounceLabel from './ui/AnnounceText/AnnounceText';
import {SubscriptionSelectionResponse} from '@/components/sections/tours/admin-ga/stops/SubscriptionSelector';

type TooltipContent = {
    id: string;
    content: string;
};
interface CreateCapacityProps {
    isCreateCapacityDialogOpen: boolean;
    onCreateCapacityDialogClose?: (status?: string) => void;
}

export default function CreateCapacity(props: CreateCapacityProps) {
    const classes = useClasses();
    const queryClient = useQueryClient();

    // States to handle opening and closing of this component itself
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(
        props.isCreateCapacityDialogOpen,
    );

    const CreateCapacityWrapperRef = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();

    useEffect(() => {
        if (CreateCapacityWrapperRef?.current) {
            const firstFocusable = findFirstFocusable(CreateCapacityWrapperRef.current);
            firstFocusable?.focus();
        }
    }, [CreateCapacityWrapperRef]);

    const {t: createCapacityLabel} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    const capacityNameLabelId = useId('capacity-name');
    const promptLocationLabelId = useId('prompt-location');
    const capacityRegionLabelId = useId('capacity-region');
    const computeUnitsLabelId = useId('compute-units');
    const {data: authData} = useGetUserInfo();
    const [selectedSubscription, setSelectedSubscription] = useState<string>('');
    const [resourceGroupName, setResourceGroupName] = useState<string>('');
    const [isNewResourceGroup, setIsNewResourceGroup] = useState<boolean>(false);
    const [capacityName, setCapacityName] = useState<string>('');
    const [isCapacityNameFocused, setIsCapacityNameFocused] = useState(false);
    const [geo, setGeo] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [regionDisplayName, setRegionDisplayName] = useState<string>('');
    const [pricePerUnit, setPricePerUnit] = useState<number>(0);
    const [maxCapacity, setMaxCapacity] = useState<number>(0);
    const [minCapacity, setMinCapacity] = useState<number>(0);
    const [crossRegionEvaluationChecked, setCrossRegionEvaluationChecked] = useState(false);
    const [capacityUnits, setCapacityUnits] = useState<string>('');
    const [showMaxCapacityUnitsError, setShowMaxCapacityUnitsError] = useState(false);
    const [showMinCapacityUnitsError, setShowMinCapacityUnitsError] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [isSettingUp, setIsSettingUp] = useState(false);
    const [isSubscriptionValid, setIsSubscriptionValid] = useState(false);

    const {update: providerUpdate, capacityName: linkedCapacityName} = useWorkspaceState();
    // Actions to take on close/dismiss of create capacity dialog
    const closeCapacityDialog = (status?: string) => {
        resetFields();
        props.onCreateCapacityDialogClose?.(status);
        setIsCreateCapacityDialogOpen(false);
    };

    const handleSubscriptionSelect = (subscription: string) => {
        setSelectedSubscription(subscription);
    };

    const handleResourceGroupSelect = (resourceGroupName: string, isNewResourceGroup: boolean) => {
        setResourceGroupName(resourceGroupName);
        setIsNewResourceGroup(isNewResourceGroup);
    };

    const handleGeoSelect = (geoKey: keyof typeof GEO_DATA) => {
        const selectedGeo = GEO_DATA[geoKey];
        if (!selectedGeo) {
            console.error(`Geo ${geoKey} not found in GEO_DATA`);
            return;
        }
        // Use the selected geo to find the default region, then set the corresponding region display name
        setGeo(geoKey);
        const defaultRegionKey = selectedGeo.defaultAzureRegion;
        setPricePerUnit(selectedGeo.pricing);
        setMaxCapacity(selectedGeo.maxCapacity);
        setMinCapacity(selectedGeo.minCapacity);
        if (capacityUnits === '') {
            setCapacityUnits('1');
        }
        setRegion(defaultRegionKey);
        const regionDisplayName = REGION_DATA[defaultRegionKey].displayName;
        setRegionDisplayName(regionDisplayName);
    };

    const estimatedCost = parseFloat(capacityUnits) * 30 * 24 * pricePerUnit || 0;

    const handleCreateCapacity = async () => {
        setIsSettingUp(true);
        await provisionCapacityLinkage();
    };

    const resetFields = () => {
        setSelectedSubscription('');
        setResourceGroupName('');
        setCapacityName('');
        setGeo('');
        setRegion('');
        setRegionDisplayName('');
        setPricePerUnit(0);
        setMaxCapacity(0);
        setMinCapacity(0);
        setCrossRegionEvaluationChecked(false);
        setCapacityUnits('');
        setTermsChecked(false);
        setShowMaxCapacityUnitsError(false);
        setShowMinCapacityUnitsError(false);
        setIsSettingUp(false);
    };

    const {provisionCapacityLinkage} = useSetupAndProvisionFidelisConstructs({
        capacityName,
        isNewResourceGroup,
        subscription: selectedSubscription,
        resourceGroup: resourceGroupName,
        geo,
        region,
        capacityUnits: parseInt(capacityUnits, 10),
        isCapacityProvisioned: false,
        isCrossRegionAllowed: crossRegionEvaluationChecked,
        skipWorkspaceAssociation: !!capacityName || !!linkedCapacityName, // Skip workspace association if capacity name is provided, meaning active capacity exists, or workspace already has a valid capacity linked up
        onAccountCreationError: (error: any) => {
            console.log('Account creation error', error);
            setIsSettingUp(false);
            closeCapacityDialog(CreateCapacityStatus.Error);
        },
        onCapacityCreationError: (error: any) => {
            console.log('Capacity creation error', error);
            setIsSettingUp(false);
            closeCapacityDialog(CreateCapacityStatus.Error);
        },
        onWorkspaceProvisionError: (error: any) => {
            console.log('Workspace provision error', error);
            setIsSettingUp(false);
            closeCapacityDialog(CreateCapacityStatus.Error);
        },
        onWorkspaceMappingError: (error: any) => {
            console.log('Workspace mapping error', error);
            setIsSettingUp(false);
            closeCapacityDialog(CreateCapacityStatus.Error);
        },
        onWorkspaceMappingCompletion: async () => {
            await performPostWorkspaceMappingOperations();
        },
        onResourceGroupCreationError: (error: any) => {
            console.log('Resource group creation error', error);
            setIsSettingUp(false);
            closeCapacityDialog(CreateCapacityStatus.Error);
        },
        onSubscriptionRegistrationError: (error: any) => {
            console.log('Subscription registration error', error);
            setIsSettingUp(false);
            closeCapacityDialog(CreateCapacityStatus.Error);
        },
    });

    const performPostWorkspaceMappingOperations = async function () {
        try {
            // Refresh the capacities list
            await queryClient.refetchQueries(['capacities'], {exact: true});
            closeCapacityDialog(CreateCapacityStatus.Success);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setIsSettingUp(false);
        } catch (error) {
            console.error('Error while performing post workspace mapping operations', error);
            setIsSettingUp(false);
            closeCapacityDialog(CreateCapacityStatus.Error);
        }
    };

    const {geoOptions} = useLocations();
    const geoDropdownOptions = useMemo(() => {
        return geoOptions.map(({key, displayName}) => (
            <Option key={key} value={key}>
                {displayName}
            </Option>
        ));
    }, [geoOptions]);

    // To handle open/close of dialog based on parent prop
    useEffect(() => {
        setIsCreateCapacityDialogOpen(props.isCreateCapacityDialogOpen);
    }, [props.isCreateCapacityDialogOpen]);

    useEffect(() => {
        // Check if the current value exceeds the threshold
        const value = parseInt(capacityUnits, 10);
        setShowMaxCapacityUnitsError(!isNaN(value) && value > maxCapacity);
        setShowMinCapacityUnitsError(!isNaN(value) && value < minCapacity);
    }, [capacityUnits, pricePerUnit, maxCapacity, minCapacity]);

    const handleSubscriptionValidation = useCallback((response: SubscriptionSelectionResponse) => {
        const {validationState} = response;
        if (validationState === 'valid') {
            setIsSubscriptionValid(true);
        } else if (validationState === 'invalid') {
            setIsSubscriptionValid(false);
            // If we have invalid subscription, reset the resource group if its been set
            if (resourceGroupName) {
                setResourceGroupName('');
            }
        }
    }, []);

    const isCreateButtonDisabled =
        !selectedSubscription ||
        !resourceGroupName ||
        !capacityName ||
        !capacityUnits ||
        !termsChecked ||
        !isSubscriptionValid;
    isSettingUp;

    return (
        <>
            {authData?.isAdmin && (
                <div ref={CreateCapacityWrapperRef}>
                    <Dialog open={isCreateCapacityDialogOpen}>
                        <DialogSurface className={classes.dialogRoot}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    data-test-id="create-capacity-dismiss-button"
                                    className={classes.closeButton}
                                    appearance="transparent"
                                    aria-label={createCapacityLabel(
                                        'createCapacity.ariaLabel.Close',
                                    )}
                                    onClick={() => closeCapacityDialog(CreateCapacityStatus.Cancel)}
                                >
                                    <DismissIcon className={classes.icon} />
                                </Button>
                            </DialogTrigger>
                            <DialogBody className={classes.container}>
                                {!isSettingUp && (
                                    <>
                                        <DialogTitle className={classes.title}>
                                            {createCapacityLabel('createCapacity.Title')}
                                        </DialogTitle>
                                    </>
                                )}
                                <DialogContent className={classes.dialogContentSection}>
                                    {isSettingUp ? (
                                        <div className={classes.spinnerContainer}>
                                            <SetupInProgress
                                                heading={createCapacityLabel(
                                                    'createCapacity.InProgress',
                                                )}
                                                subHeading={createCapacityLabel(
                                                    'createCapacity.Polite',
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div className={mergeClasses(classes.field)}>
                                                {createCapacityLabel('createCapacity.SubHeading')}{' '}
                                                <Link
                                                    target="_blank"
                                                    href={MedeinaVariables.AzurePortalUrl}
                                                >
                                                    {commonLabel('AzurePortal')}
                                                </Link>
                                                {'.'}
                                            </div>
                                            <SubscriptionSelector
                                                onSubscriptionSelect={handleSubscriptionSelect}
                                                onSubscriptionValidation={
                                                    handleSubscriptionValidation
                                                }
                                            ></SubscriptionSelector>
                                            <ResourceGroupSelector
                                                selectedSubscription={selectedSubscription}
                                                onResourceGroupSelect={handleResourceGroupSelect}
                                                disabled={
                                                    !selectedSubscription || !isSubscriptionValid
                                                }
                                                key={selectedSubscription}
                                            ></ResourceGroupSelector>
                                            <div className={classes.field}>
                                                <div
                                                    className={mergeClasses(
                                                        classes.leftAlignedContainer,
                                                        classes.label,
                                                    )}
                                                >
                                                    <MedeinaInfoLabel
                                                        labelContent={
                                                            <Label
                                                                id={capacityNameLabelId}
                                                                data-test-id="capacity-name-label"
                                                                required
                                                            >
                                                                {createCapacityLabel(
                                                                    'createCapacity.CapacityName',
                                                                )}
                                                            </Label>
                                                        }
                                                        infoContent={createCapacityLabel(
                                                            'createCapacity.info.CapacityNameInfo',
                                                        )}
                                                        size="small"
                                                    />
                                                </div>
                                                <div className={classes.leftAlignedContainer}>
                                                    <Input
                                                        className={classes.textFieldStyle}
                                                        required
                                                        type="text"
                                                        placeholder={createCapacityLabel(
                                                            'createCapacity.placeholder.DefaultSuggestion',
                                                        )}
                                                        aria-label={createCapacityLabel(
                                                            'createCapacity.ariaLabel.CapacityName',
                                                        )}
                                                        data-test-id="capacity-name-input"
                                                        value={capacityName}
                                                        onChange={(event) => {
                                                            setCapacityName(event.target.value);
                                                        }}
                                                        onFocus={() =>
                                                            setIsCapacityNameFocused(true)
                                                        }
                                                        onBlur={() =>
                                                            setIsCapacityNameFocused(false)
                                                        }
                                                    />
                                                </div>
                                                {isCapacityNameFocused && (
                                                    <AnnounceLabel ariaLive="polite">
                                                        <Label className={classes.infoLabel}>
                                                            {createCapacityLabel(
                                                                'createCapacity.CapacityInfo',
                                                            )}
                                                        </Label>
                                                    </AnnounceLabel>
                                                )}
                                            </div>
                                            <div className={classes.field}>
                                                <div
                                                    className={mergeClasses(
                                                        classes.leftAlignedContainer,
                                                        classes.label,
                                                    )}
                                                >
                                                    <MedeinaInfoLabel
                                                        labelContent={
                                                            <Label
                                                                id={promptLocationLabelId}
                                                                data-test-id="prompt-evaluation-location"
                                                                required
                                                            >
                                                                {createCapacityLabel(
                                                                    'createCapacity.PromptEvalLocation',
                                                                )}
                                                            </Label>
                                                        }
                                                        infoContent={createCapacityLabel(
                                                            'createCapacity.PromptEvaluation',
                                                        )}
                                                        size="small"
                                                    />
                                                </div>
                                                <div className={classes.leftAlignedContainer}>
                                                    <Dropdown
                                                        placeholder={createCapacityLabel(
                                                            'createCapacity.PlaceholderGeo',
                                                        )}
                                                        className={classes.dropdownSelector}
                                                        aria-required="true"
                                                        aria-label={createCapacityLabel(
                                                            'createCapacity.ariaLabel.GeoDropdown',
                                                        )}
                                                        data-test-id="geo-dropdown"
                                                        onOptionSelect={(event: any, data: any) => {
                                                            handleGeoSelect(data.optionValue);
                                                        }}
                                                    >
                                                        <OptionGroup className="comboOptionGroup">
                                                            {geoDropdownOptions}
                                                        </OptionGroup>
                                                    </Dropdown>
                                                </div>
                                                <div className={classes.leftAlignedContainer}>
                                                    <Checkbox
                                                        className={classes.checkboxStyle}
                                                        id="crossRegion"
                                                        data-test-id="crossRegion-checkbox"
                                                        checked={crossRegionEvaluationChecked}
                                                        aria-checked={crossRegionEvaluationChecked}
                                                        onChange={() =>
                                                            setCrossRegionEvaluationChecked(
                                                                (checkedValue) => !checkedValue,
                                                            )
                                                        }
                                                    />
                                                    <Label
                                                        className={classes.termsLabel}
                                                        data-test-id="cross-region-label"
                                                    >
                                                        {createCapacityLabel(
                                                            'createCapacity.CrossRegionEvaluation',
                                                        )}
                                                    </Label>
                                                </div>
                                            </div>
                                            <div className={classes.field}>
                                                <div
                                                    className={mergeClasses(
                                                        classes.leftAlignedContainer,
                                                        classes.label,
                                                    )}
                                                >
                                                    <MedeinaInfoLabel
                                                        labelContent={
                                                            <Label
                                                                id={capacityRegionLabelId}
                                                                data-test-id="azure-region-label"
                                                                required
                                                            >
                                                                {createCapacityLabel(
                                                                    'createCapacity.AzureRegionLabel',
                                                                )}
                                                            </Label>
                                                        }
                                                        infoContent={createCapacityLabel(
                                                            'createCapacity.AzureRegion',
                                                        )}
                                                        size="small"
                                                    />
                                                </div>
                                                <div className={classes.leftAlignedContainer}>
                                                    <Input
                                                        className={classes.textFieldStyle}
                                                        required
                                                        type="text"
                                                        aria-label={createCapacityLabel(
                                                            'createCapacity.ariaLabel.AzureRegion',
                                                        )}
                                                        data-test-id="compute-input"
                                                        value={regionDisplayName}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.field}>
                                                <div
                                                    className={mergeClasses(
                                                        classes.leftAlignedContainer,
                                                        classes.label,
                                                    )}
                                                >
                                                    <MedeinaInfoLabel
                                                        labelContent={
                                                            <Label
                                                                id={computeUnitsLabelId}
                                                                data-test-id="compute-label"
                                                                required
                                                            >
                                                                {createCapacityLabel(
                                                                    'createCapacity.SecurityComputeUnits',
                                                                )}
                                                            </Label>
                                                        }
                                                        infoContent={createCapacityLabel(
                                                            'createCapacity.ComputeUnitsInfo',
                                                        )}
                                                        size="small"
                                                    />
                                                </div>
                                                <div className={classes.leftAlignedContainer}>
                                                    <Input
                                                        className={classes.textFieldStyle}
                                                        disabled={!geo}
                                                        required
                                                        type="number"
                                                        aria-label={createCapacityLabel(
                                                            'createCapacity.ariaLabel.CapacityUnits',
                                                        )}
                                                        data-test-id="compute-input"
                                                        value={capacityUnits}
                                                        min={minCapacity}
                                                        max={maxCapacity}
                                                        onChange={(event) => {
                                                            setCapacityUnits(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {showMaxCapacityUnitsError && (
                                                    <ErrorLabel className={classes.warningLabel}>
                                                        {createCapacityLabel(
                                                            'createCapacity.MaxCapacityUnits',
                                                            {
                                                                0: maxCapacity,
                                                            },
                                                        )}
                                                        <Link
                                                            className={classes.termsLinkStyle}
                                                            href="https://example.com/terms-and-conditions"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {createCapacityLabel(
                                                                'createCapacity.Contact',
                                                            )}
                                                        </Link>
                                                        {createCapacityLabel(
                                                            'createCapacity.MicrosoftSupport',
                                                        )}
                                                    </ErrorLabel>
                                                )}
                                                {showMinCapacityUnitsError && (
                                                    <ErrorLabel className={classes.warningLabel}>
                                                        {createCapacityLabel(
                                                            'createCapacity.MinCapacityUnits',
                                                            {
                                                                0: minCapacity,
                                                            },
                                                        )}
                                                    </ErrorLabel>
                                                )}
                                                {
                                                    <Label className={classes.infoLabel}>
                                                        {createCapacityLabel(
                                                            'createCapacity.ApproxMonthlyCost',
                                                            {
                                                                0: formatCurrency(
                                                                    estimatedCost,
                                                                    'USD',
                                                                    'en-US',
                                                                ),
                                                            },
                                                        )}
                                                    </Label>
                                                }
                                            </div>
                                            <div className={classes.field}>
                                                <Label
                                                    className={classes.readMoreLabel}
                                                    data-test-id="read-more-label"
                                                    required
                                                >
                                                    {createCapacityLabel(
                                                        'createCapacity.ReadMoreAbout',
                                                    )}
                                                    <Link
                                                        className={classes.termsLinkStyle}
                                                        href={MedeinaVariables.FreReadMoreurl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {createCapacityLabel(
                                                            'createCapacity.ComputeUnitsLink',
                                                        )}
                                                    </Link>
                                                    {createCapacityLabel(
                                                        'createCapacity.PostReadMoreAbout',
                                                    )}
                                                </Label>
                                            </div>
                                            <div className={classes.termsButtonWrapper}>
                                                <div className={classes.field}>
                                                    <div className={classes.leftAlignedContainer}>
                                                        <Label
                                                            className={classes.termsLabel}
                                                            data-test-id="terms-label"
                                                        >
                                                            <Checkbox
                                                                className={classes.checkboxStyle}
                                                                id="termsCheckbox"
                                                                data-test-id="terms-checkbox"
                                                                required
                                                                checked={termsChecked}
                                                                onChange={() =>
                                                                    setTermsChecked(
                                                                        (checkedValue) =>
                                                                            !checkedValue,
                                                                    )
                                                                }
                                                            />
                                                            {createCapacityLabel(
                                                                'createCapacity.Acknowledgement',
                                                            )}
                                                            <Link
                                                                className={classes.termsLinkStyle}
                                                                href={
                                                                    MedeinaVariables.FreTermsAndConditionsUrl
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {createCapacityLabel(
                                                                    'createCapacity.TermsAndConditions',
                                                                )}
                                                            </Link>
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    {!isSettingUp && (
                                        <Button
                                            data-test-id="create-capacity-button"
                                            appearance="primary"
                                            aria-label={createCapacityLabel(
                                                'createCapacity.ariaLabel.CreateCapacity',
                                            )}
                                            onClick={async () => await handleCreateCapacity()}
                                            disabled={isCreateButtonDisabled}
                                        >
                                            {commonLabel('ButtonLabels.Create')}
                                        </Button>
                                    )}
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button
                                            data-test-id="create-capacity-cancel-button"
                                            appearance="secondary"
                                            aria-label={createCapacityLabel(
                                                'createCapacity.ariaLabel.Cancel',
                                            )}
                                            onClick={() =>
                                                closeCapacityDialog(CreateCapacityStatus.Cancel)
                                            }
                                        >
                                            {commonLabel('ButtonLabels.Cancel')}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </div>
            )}
        </>
    );
}
