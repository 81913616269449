import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {ErrorResponse} from '@/api/api.types';
import {Hello} from './hello.types';

export default function useGetHello({enabled}: Pick<UseQueryOptions<Hello>, 'enabled'>) {
    const {customFetch} = useFetch();
    return useQuery(['hello'], {
        enabled: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 0,
        queryFn: async () => {
            console.log('useGetHello');
            const result = await customFetch<Response>(
                `/hello`,
                {
                    method: 'GET',
                },
                true,
            );

            // if (!result.ok) {
            //     const error: ErrorResponse = {
            //         code: result.status,
            //         message: result.statusText,
            //     };

            //     throw error;
            // }
            const data = await result.json();
            return data;
        },
        retry: 0,
    });
}
