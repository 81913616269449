import {Caption1, Link, MenuGroup, MenuGroupHeader} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {PromptbookDescriptor} from '@/api/promptbooks';
import {PromptBarMenuChildProps, PromptBarMenuListType} from './PromptBarMenu.types';
import useClasses from './PromptBarMenu.styles';
import PromptBarMenuList from './PromptBarMenuList';
import {NextIcon, PromptbookIcon} from '../icons';
import usePromptBarMenuPromptbooksList from './usePromptBarMenuPromptbooksList';
import {useEffect} from 'react';
import PromptBarMenuShowAllList from './PromptBarMenuShowAllList';

export default function PromptBarMenuPromptbooks(
    props: PromptBarMenuChildProps<PromptbookDescriptor>,
) {
    const classes = useClasses();
    const {t} = useTranslation('promptbar');
    const {filter, skillsets, limit, showAll, tabIndex, onShowAll, shouldRepositionMenu} = props;
    const lists = usePromptBarMenuPromptbooksList({filter, skillsets, limit});
    const nextProps = {
        ...props,
        lists,
        noMatches: t('NoMatchingPromptbooks'),
        getName: (item: PromptbookDescriptor) => item.name,
        getDescription: (item: PromptbookDescriptor) => item.description,
        header: (
            <>
                <MenuGroupHeader className={classes.menuGroupHeader}>
                    <PromptbookIcon className={classes.menuGroupIcon} />
                    <div className={classes.menuGroupTitle}>
                        <span
                            data-testid="promptbar-promptbooks-menu-title"
                            className={classes.menuGroupTitleText}
                        >
                            {t('Promptbooks')}
                        </span>
                    </div>
                    {!showAll && (
                        <div className={classes.menuGroupShowAll}>
                            <Link role="menuitem" onClick={() => onShowAll?.()} tabIndex={tabIndex}>
                                {t('ListAllPromptbooks')} <NextIcon />
                            </Link>
                        </div>
                    )}
                </MenuGroupHeader>
                <Caption1 className={classes.groupDescription}>
                    {t('PromptbooksDescription')}
                </Caption1>
            </>
        ),
    };

    // Reposition the menu based on a change in content.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(shouldRepositionMenu, [lists.filteredList, lists.groupedList]);

    return (
        <>
            {(!showAll || showAll === PromptBarMenuListType.Promptbooks) && (
                <MenuGroup>
                    {showAll === PromptBarMenuListType.Promptbooks ? (
                        <PromptBarMenuShowAllList {...nextProps} />
                    ) : (
                        <PromptBarMenuList {...nextProps} />
                    )}
                </MenuGroup>
            )}
        </>
    );
}
