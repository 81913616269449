import {Badge, Tooltip} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {PreviewState} from '@/api/api.types';
import {PreviewBadgeProps, TooltipWrapperProps} from './PreviewBadge.types';
import useClasses from './PreviewBadge.styles';
import {useMemo} from 'react';

/**
 * Tooltip wrapper that wraps the component with a tooltip if the tooltip content is provided
 * @param component The component on which the tooltip should be applied
 * @param tooltipContent The content of the tooltip
 * @param customTooltipProps Custom tooltip props that will be passed to the tooltip directly
 */
const TooltipWrapper = ({component, tooltipContent, customTooltipProps}: TooltipWrapperProps) => {
    const classes = useClasses();

    return Boolean(tooltipContent) ? (
        <Tooltip
            withArrow
            content={tooltipContent ?? ''}
            relationship="description"
            {...customTooltipProps}
        >
            <div className={classes.tooltipChildrenContainer}>{component}</div>
        </Tooltip>
    ) : (
        component
    );
};

/**
 * Renders the preview badge based on the preview state and the tooltip content
 * @param previewState Preview State of the badge
 * @param tooltipContent Tooltip content (if any)
 */
const PreviewBadge = ({previewState, tooltipContent, customTooltipProps}: PreviewBadgeProps) => {
    const {t} = useTranslation('common');
    const classes = useClasses();

    const badge = useMemo(() => {
        let previewBadgeText: string = '';
        switch (previewState) {
            case PreviewState.Public:
                previewBadgeText = t('Preview');
                break;
            case PreviewState.Private:
                previewBadgeText = t('PrivatePreview');
                break;
        }
        return (
            <Badge
                data-testid="preview-badge"
                appearance="filled"
                color="subtle"
                size="large"
                className={classes.badge}
            >
                {previewBadgeText}
            </Badge>
        );
    }, [previewState]);

    return Boolean(previewState) && previewState !== PreviewState.GA ? (
        <TooltipWrapper
            component={badge}
            tooltipContent={tooltipContent}
            customTooltipProps={customTooltipProps}
        />
    ) : (
        <></>
    );
};

export default PreviewBadge;
