import React from 'react';
import {Tour} from '@/components/ui/Tour';
import {
    // TooltipPosition,
    AlignEdge,
    OverlayOptions,
    SpotlightContentStyle,
    TourTelemetryOptions,
} from '@/components/ui/Tour/Tour.types';

import * as Spotlights from './stops/SpotlightContent';
import EarlyExit from '../commonStops/EarlyExit';

const defaultControlOptions = {
    allowSkip: false,
    allowExit: false,
    allowReplay: false,
    showExit: false,
    showControls: false,
    showCounter: false,
};

const defaultSpotlight = {
    padding: 10,
    borderRadius: 5,
    showOverlay: true,
};

interface FromDefenderTourProps {
    onExit?: () => void;
    telemetryOptions: TourTelemetryOptions;
}

export function FromDefenderTour(props: FromDefenderTourProps): Tour {
    return {
        name: 'defenderIntro',
        onEarlyExit: <EarlyExit />,
        controlOptions: defaultControlOptions,
        telemetryName: 'defenderIntro',
        telemetryOptions: props.telemetryOptions,
        tourStops: [
            {
                name: 'prompts',
                autoAdvance: true,
                autoStart: true,
                spotlight: {
                    ...defaultSpotlight,
                    targetSelector: "[data-tour-id='promptContent']",
                    padding: 10,
                    borderRadius: 5,
                    showOverlay: true,
                    content: <Spotlights.Intro />,
                    tooltipPosition: 'before',
                    contentStyle: SpotlightContentStyle.popover,
                },
            },
            {
                name: 'buildOn',
                autoAdvance: true,
                autoStart: true,
                spotlight: {
                    ...defaultSpotlight,
                    targetSelector: "[data-tour-id='promptBar']",
                    padding: 10,
                    borderRadius: 5,
                    showOverlay: true,
                    content: <Spotlights.BuildOn />,
                    tooltipPosition: 'before',
                    contentStyle: SpotlightContentStyle.popover,
                },
            },
            {
                name: 'sessionNavigation',
                autoAdvance: true,
                autoStart: true,
                spotlight: {
                    ...defaultSpotlight,
                    targetSelector: "[data-tour-id='investigationBreadcrumb']",
                    padding: 10,
                    borderRadius: 5,
                    showOverlay: true,
                    content: <Spotlights.SessionNavigation />,
                    tooltipPosition: 'below',
                    contentStyle: SpotlightContentStyle.popover,
                },
            },
        ],
    };
}

export default FromDefenderTour;
