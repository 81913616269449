import {Text, tokens} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {AcceptIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {DialogTitle as CustomDialogTitle} from '@/components/ui/Dialog';

interface OauthHelperModalProps {
    onCancel: () => void;
}

export default function OauthHelperModal({onCancel}: OauthHelperModalProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <div className={classes.confirmationModal}>
            <CustomDialogTitle title={''} onCloseButtonClick={onCancel} />
            <div className={classes.authSelect}>
                <div className={classes.oauthFrame}>
                    <div className={classes.oauthFrameIcon}>
                        <div className={classes.oauthFrameIconWhite}>
                            <div className={classes.oauthFramIconGreySquare} />
                            <div className={classes.oauthFramIconGreyLine} />
                            <div className={classes.oauthFramIconGreyLine} />
                            <div className={classes.oauthFramIconGreyLine} />
                            <div className={classes.ouathFrameCheckIcon}>
                                <AcceptIcon color={tokens.colorNeutralForeground1} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.oauthFrameText}>
                        <Text className={classes.resetTitleText}>{t('Admin.OneMoreThing')}</Text>
                        <Text className={classes.resetText}>
                            {t('Admin.SelectAllowToComplete')}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
}
