import {useQuery} from '@tanstack/react-query';
import {
    GetEvaluationRequest,
    Evaluation,
    EvaluationState,
    CreateEvaluationRunningTimeoutError,
} from './evaluations.types';
import useSessionsFetch from '../useSessionsFetch';
import MedeinaVariables from '@/util/variables';

export default function useGetEvaluation({
    sessionId,
    promptId,
    evaluationId,
}: GetEvaluationRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useQuery({
        queryKey: ['sessions', sessionId, 'prompts', promptId, 'evaluations', evaluationId],
        queryFn: async () => {
            const evaluation = await sessionsFetch<Evaluation>(
                `${sessionId}/prompts/${promptId}/evaluations/${evaluationId}`,
                {
                    method: 'GET',
                },
            );

            // For a newly created evaluation, throw an error if it hasn't started running within a timeout.
            if (
                evaluation.state === EvaluationState.Created &&
                Date.now() - new Date(evaluation.createdAt).getTime() >
                    MedeinaVariables.CreateEvaluationRunningTimeout
            ) {
                return Promise.reject(new CreateEvaluationRunningTimeoutError(evaluation));
            }

            return evaluation;
        },

        enabled: Boolean(evaluationId),

        // Immediately return errors after they occur, rather than retrying.
        retry: false,

        // This hook operates like a subscription, but we want to disable refetching when there's no work.
        refetchInterval: (data) => {
            // Don't refetch if there was an error or the evaluation has completed.
            if (!data || data?.state === EvaluationState.Completed) {
                return false;
            }
            return 1000;
        },
    });
}
