import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    shareNoAccessRoot: {
        maxWidth: '500px',
    },
    header: {
        fontFamily: tokens.fontFamilyBase,
        fontSize: '20px',
        fontWeight: tokens.fontWeightSemibold,
        letterSpacing: '0em',
        textAlign: 'left',
    },
    MessageDisplay: {
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '8px',
    },
    displayText: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalMNudge,
        fontFamily: tokens.fontFamilyBase,
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: tokens.colorNeutralForeground3,
    },
    retryFont: {
        display: 'flex',
        rowGap: tokens.spacingHorizontalMNudge,
        fontFamily: tokens.fontFamilyBase,
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left',
        alignItems: 'center',
        color: tokens.colorNeutralForeground3,
        marginLeft: '16px',
    },
    leftSpacing: {
        marginLeft: '8px',
    },
});
