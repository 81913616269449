import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {evaluationResultProperties} from '../Evaluation.styles';

export default makeStyles({
    feedback: {
        ...shorthands.padding(0, tokens.spacingHorizontalXXXL, tokens.spacingVerticalXL),
        backgroundColor: tokens.colorNeutralBackground6,
        borderBottomLeftRadius: tokens.borderRadiusXLarge,
        borderBottomRightRadius: tokens.borderRadiusXLarge,
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px',
    },
    root: {
        ...evaluationResultProperties,
    },
    spinner: {
        ...evaluationResultProperties,
    },
    toolbar: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
        display: 'flex',
        columnGap: tokens.spacingHorizontalM,
        justifyContent: 'space-between',
        boxShadow: tokens.shadow16,
        borderBottomLeftRadius: tokens.borderRadiusXLarge,
        borderBottomRightRadius: tokens.borderRadiusXLarge,
        overflowY: 'visible',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    feedbackTypes: {
        display: 'flex',
    },

    title: {
        ...shorthands.padding(tokens.spacingVerticalXL, 0),
        display: 'flex',
        columnGap: tokens.spacingHorizontalM,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    feedbackSubtypes: {
        ...shorthands.padding(0, 0, tokens.spacingVerticalXL),
        display: 'flex',
        columnGap: tokens.spacingHorizontalM,
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: tokens.spacingVerticalSNudge,
    },
    feedbackSubtypeButton: {
        ...shorthands.borderWidth(tokens.strokeWidthThin),
        ...shorthands.borderColor(tokens.colorNeutralForeground4),
        minWidth: tokens.lineHeightHero900,
        '&[aria-pressed=true]': {
            backgroundColor: tokens.colorCompoundBrandBackgroundHover,
            color: tokens.colorNeutralForegroundInverted,
        },
    },
    feedbackTextarea: {},
    submitFeedback: {
        ...shorthands.padding(tokens.spacingVerticalM, 0, 0),
        display: 'flex',
        columnGap: tokens.spacingHorizontalM,
        flexDirection: 'row',
        justifyContent: 'right',
    },
    feedbackThanks: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground6,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '19px',
        ...shorthands.padding(tokens.spacingHorizontalXXXL),
        ...shorthands.gap(tokens.spacingVerticalL),
        borderBottomLeftRadius: tokens.borderRadiusXLarge,
        borderBottomRightRadius: tokens.borderRadiusXLarge,
    },
    thanksImage: {
        width: '64px',
        height: '64px',
    },
    feedbackDismiss: {
        position: 'absolute',
        top: 0,
        right: 0,
        marginRight: tokens.spacingHorizontalXL,
        marginTop: tokens.spacingVerticalXL,
    },
});
