import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    invisibleSurface: {
        backgroundColor: 'transparent',
        ...shorthands.border('none'),
        boxShadow: 'none',
    },
    smallSurface: {
        position: 'absolute',
        width: 'calc(100% - var(--spacingHorizontalL))',
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,

        transform: 'translate3d(0px, 0px, 0px) !important',
    },
});
