import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        ...shorthands.gap(tokens.spacingVerticalS),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingVerticalMNudge),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        textAlign: 'center',

        '& > p': {
            ...shorthands.margin(tokens.spacingHorizontalNone, tokens.spacingHorizontalXXL),
            textAlign: 'center',
        },
    },
    headerText: {
        fontWeight: tokens.fontWeightSemibold,
    },
    fullWidth: {
        width: '100% !important',
    },
});
