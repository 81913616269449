import {Session} from '@/api/sessions/sessions.types';
import {TableSelectionState} from '@fluentui/react-components';

export interface SessionProps {
    sessionId: string;
    isReadOnly?: boolean;
}

export type DuplicateSessionProps = {
    session: Session;

    // Optional `open` control
    open?: boolean | undefined;
    onClose?: () => void;
    onSuccess?: () => void;
    refetchSessionList?: () => void;
};

export enum SessionPromptsSelectionType {
    Select = 'select',
    All = 'all',
    None = 'none',
    Pinned = 'pinned',
    NotPinned = 'notPinned',
}

export interface PromptbookGroupProps {
    sessionId: string;
    promptbookExecutionId: string;
    isReadOnly?: boolean;
    errorMessage?: string;
    setErrorMessage?: (message: string) => void;
    selection?: TableSelectionState;
    addPromptbookExecution?: (promptbookExecutionId: string) => void;
}

export interface SessionViewMode {
    isFullWidth: boolean;
}
