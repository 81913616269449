import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    code: {
        fontFamily: tokens.fontFamilyMonospace,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase400,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        ...shorthands.padding('2px'),
    },
    pre: {
        fontFamily: tokens.fontFamilyMonospace,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        backgroundColor: tokens.colorNeutralBackground2,
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding('5px'),
    },
    codeLineStyles: {
        whiteSpace: 'break-spaces',
    },
});
