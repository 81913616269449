import {BrandColors, BrandThemeColors} from './colors.types';

/**
 * Brand colors based off of Fluent Light Teal colors
 */
export const brand: BrandColors = {
    shade50: '#001D1F',
    shade40: '#00373A',
    shade30: '#00666D',
    shade20: '#008B94',
    shade10: '#00A5AF',
    primary: '#00B7C3',
    tint10: '#18BFCA',
    tint20: '#32C8D1',
    tint30: '#58D3DB',
    tint40: '#A6E9ED',
    tint50: '#CEF3F5',
    tint60: '#F2FCFD',
};

// Generated brand colors for theme generation
export const themeBrand: BrandThemeColors = {
    10: '#001213',
    20: '#001D1F',
    30: '#00373A',
    40: '#00575E',
    50: '#00666D',
    60: '#008B94',
    70: '#00A5AF',
    // primary
    80: '#00B7C3',
    90: '#18BFCA',
    100: '#32C8D1',
    110: '#58D3DB',
    120: '#7DE5EB',
    130: '#A6E9ED',
    140: '#CEF3F5',
    150: '#F2FCFD',
    160: '#FAFFFF',
};
