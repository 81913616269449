import {useCheckTenant} from '@/api/accounts';
import {useEffect, useState} from 'react';

interface ManageLocationDisplayDetailsProps {
    onTenantFetchError: (error: any) => void;
}

export default function useManageLocationDisplayDetails(props: ManageLocationDisplayDetailsProps) {
    const [checkAccountLoaded, setCheckAccountLoaded] = useState<boolean>(false);
    const [geoValue, setGeoValue] = useState<string>('');

    const {
        data: accountResult,
        isFetched: isAccountFetched,
        isSuccess: isAccountSuccess,
        isError: isAccountError,
        isLoading: isAccountLoading,
        error: accountError,
    } = useCheckTenant({enabled: !checkAccountLoaded});

    useEffect(() => {
        if (isAccountError) {
            props?.onTenantFetchError(accountError);
            return;
        }
        if (isAccountFetched) {
            setCheckAccountLoaded(true);
        }

        if (isAccountFetched && isAccountSuccess && accountResult?.tenantGeo) {
            setGeoValue(accountResult?.tenantGeo);
        }
    }, [isAccountFetched]);

    return {
        geoValue,
        isAccountLoading,
    };
}
