import * as React from 'react';
const FaceThinking = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path
                fill="url(#f2360id0)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id1)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id2)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id3)"
                fillOpacity=".6"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id4)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id5)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id6)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id7)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2360id8)"
                d="M10 21.5v-3.06A1.44 1.44 0 0 0 8.56 17c-.84 0-1.505.718-1.517 1.559C7.023 19.92 6.835 21.722 6 22c-3 1-4 5.5-1.5 7.5c2 1.6 4 1.5 5.5 1.5h2.764a1.236 1.236 0 0 0 .553-2.342L13 28.5h.72a1 1 0 0 0 .97-.758l.067-.272A.78.78 0 0 0 14 26.5l.33-.165a1.214 1.214 0 0 0 0-2.17L14 24h1.75a1.25 1.25 0 1 0 0-2.5H10Z"
            />
            <path
                stroke="#402A32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 19s1.124-1.303 3.25-1c2.126.303 2.75 2 2.75 2"
            />
            <circle cx="9.017" cy="13.421" r="4.673" fill="url(#f2360id9)" />
            <circle cx="19.244" cy="13.943" r="4.244" fill="url(#f2360ida)" />
            <path
                fill="#fff"
                d="M10.42 16.224a4.206 4.206 0 1 0 0-8.411a4.206 4.206 0 0 0 0 8.411Zm11.148.077a4.244 4.244 0 1 0 0-8.489a4.244 4.244 0 0 0 0 8.49Z"
            />
            <path
                fill="url(#f2360idd)"
                fillRule="evenodd"
                d="M13 11.5a3 3 0 1 1-6 0a3 3 0 0 1 6 0Zm11 0a3 3 0 1 1-6 0a3 3 0 0 1 6 0Z"
                clipRule="evenodd"
            />
            <g filter="url(#f2360idh)">
                <path
                    fill="#E4694E"
                    fillRule="evenodd"
                    d="m12.703 28.688l.055-.218A.78.78 0 0 0 12 27.5l.33-.165a1.214 1.214 0 0 0 0-2.17L12 25h1.75a1.25 1.25 0 0 0 0-2.5H8v-3.06A1.44 1.44 0 0 0 6.56 18c-.84 0-1.505.718-1.517 1.559c-.015 1.058-.132 2.381-.575 3.05c1.537 3.041 4.282 5.287 8.235 6.08Z"
                    clipRule="evenodd"
                />
            </g>
            <path
                fill="url(#f2360idb)"
                d="M10 21.5v-3.06A1.44 1.44 0 0 0 8.56 17c-.84 0-1.505.718-1.517 1.559C7.023 19.92 6.835 21.722 6 22c-3 1-4 5.5-1.5 7.5c2 1.6 4 1.5 5.5 1.5h2.764a1.236 1.236 0 0 0 .553-2.342L13 28.5h.72a1 1 0 0 0 .97-.758l.067-.272A.78.78 0 0 0 14 26.5l.33-.165a1.214 1.214 0 0 0 0-2.17L14 24h1.75a1.25 1.25 0 1 0 0-2.5H10Z"
            />
            <path
                fill="url(#f2360idc)"
                d="M10 21.5v-3.06A1.44 1.44 0 0 0 8.56 17c-.84 0-1.505.718-1.517 1.559C7.023 19.92 6.835 21.722 6 22c-3 1-4 5.5-1.5 7.5c2 1.6 4 1.5 5.5 1.5h2.764a1.236 1.236 0 0 0 .553-2.342L13 28.5h.72a1 1 0 0 0 .97-.758l.067-.272A.78.78 0 0 0 14 26.5l.33-.165a1.214 1.214 0 0 0 0-2.17L14 24h1.75a1.25 1.25 0 1 0 0-2.5H10Z"
            />
            <path
                stroke="#402A32"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.5 4.5c-.333-.333-1.3-1-2.5-1s-2.167.667-2.5 1m11 4s1-1 3-1s3 1 3 1"
            />
            <g filter="url(#f2360idi)" opacity=".8">
                <path fill="#FFA048" d="M10 23h7v1h-7z" />
            </g>
            <path fill="url(#f2360ide)" d="M11 24h3a1 1 0 0 1 1 1l-4-1Z" />
            <path fill="url(#f2360idf)" d="M11 28.5h2a1 1 0 0 1 1 1l-3-1Z" />
            <path fill="url(#f2360idg)" d="M11 26.5h3a1 1 0 0 1 1 1l-4-1Z" />
            <defs>
                <radialGradient
                    id="f2360id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(132.839 10.786 10.065) scale(37.5033)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f2360id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.878 10.74 10.193) scale(38.9487)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f2360id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(101.31 2.876 12.808) scale(17.8466 22.8581)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".788" stopColor="#F59639" stopOpacity="0" />
                    <stop offset=".973" stopColor="#FF7DCE" />
                </radialGradient>
                <radialGradient
                    id="f2360id3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-29 29 -29 -29 18 14)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".315" stopOpacity="0" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="f2360id4"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(77.692 -2.555 18.434) scale(28.1469)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".508" stopColor="#7D6133" stopOpacity="0" />
                    <stop offset="1" stopColor="#715B32" />
                </radialGradient>
                <radialGradient
                    id="f2360id5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(7.5 10.99996 -7.97335 5.4364 16.5 16.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB849" />
                    <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2360id6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(11.49998 2 -2 11.49998 20.5 18)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA64B" />
                    <stop offset=".9" stopColor="#FFAE46" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2360id7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(43.971 -9.827 29.173) scale(59.0529)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".185" stopOpacity="0" />
                    <stop offset="1" stopOpacity=".4" />
                </radialGradient>
                <radialGradient
                    id="f2360id8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-16.50002 -.50009 .46634 -15.38663 15.5 22)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".066" stopColor="#FFEA67" />
                    <stop offset=".593" stopColor="#FFC13F" />
                    <stop offset=".904" stopColor="#DF9030" />
                </radialGradient>
                <radialGradient
                    id="f2360id9"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(135 4.3 7.513) scale(10.2026 5.28051)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392108" />
                    <stop offset="1" stopColor="#C87928" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2360ida"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(133.664 8.996 10.145) scale(9.48022 5.35173)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392108" />
                    <stop offset="1" stopColor="#C87928" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2360idb"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-16.50002 -.50009 .46634 -15.38663 15.5 22)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".066" stopColor="#FFEA67" />
                    <stop offset=".593" stopColor="#FFC13F" />
                    <stop offset=".904" stopColor="#DF9030" />
                </radialGradient>
                <radialGradient
                    id="f2360idc"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-11 1.49997 -1.53804 -11.27922 10.5 22.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".5" stopColor="#FFEA67" stopOpacity="0" />
                    <stop offset=".851" stopColor="#F9708E" />
                </radialGradient>
                <linearGradient
                    id="f2360idd"
                    x1="16.5"
                    x2="15.5"
                    y1="7"
                    y2="14"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#553B3E" />
                    <stop offset="1" stopColor="#3D2432" />
                </linearGradient>
                <linearGradient
                    id="f2360ide"
                    x1="12.5"
                    x2="14"
                    y1="24"
                    y2="25.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FA9428" />
                    <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2360idf"
                    x1="11.5"
                    x2="13"
                    y1="28.5"
                    y2="30"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FA9428" />
                    <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2360idg"
                    x1="11.5"
                    x2="13"
                    y1="26.5"
                    y2="28"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FA9428" />
                    <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
                </linearGradient>
                <filter
                    id="f2360idh"
                    width="14.533"
                    height="14.688"
                    x="2.468"
                    y="16"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_31_62" stdDeviation="1" />
                </filter>
                <filter
                    id="f2360idi"
                    width="10"
                    height="4"
                    x="8.5"
                    y="21.5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_31_62" stdDeviation=".75" />
                </filter>
            </defs>
        </g>
    </svg>
);
export default FaceThinking;
