import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    checked: {
        backgroundColor: tokens.colorStrokeFocus2,
        color: tokens.colorNeutralForegroundInverted,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        minWidth: '0px',
        whiteSpace: 'nowrap',

        ':hover': {
            ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
            backgroundColor: tokens.colorNeutralBackgroundInverted,
            color: tokens.colorNeutralForegroundInverted,
        },
        ':focus': {
            ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground4),
            ...shorthands.outline('2px', 'solid', tokens.colorStrokeFocus2),
        },
    },
    regular: {
        fontWeight: tokens.fontWeightRegular,
        backgroundColor: tokens.colorNeutralBackground4,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        minWidth: '0px',
        whiteSpace: 'nowrap',
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalS,
        flexGrow: '1',
    },
});
