import {useGetWorkspaceByName, useGetWorkspaces, useUpdateWorkspace} from '@/api/workspaces';
import {CustomSwitchTransition} from '@/components/ui/Switch/switch.types';
import {useEffect, useState} from 'react';

interface DataAndPrivacyDetails {
    isAllowProductImprovement: boolean;
    isAllowModelImprovement: boolean;
}

interface ManageDataAndPrivacyDetailsProps {
    onWorkspacesFetchError: (error: any) => void;
    onWorkspacesProductUpdateError: (error: any) => void;
    onWorkspacesModelUpdateError: (error: any) => void;
}

//TODO:: to fix the error handling for this after fixing the issue with switch toggle
export default function useManageDataAndPrivacyDetails(props: ManageDataAndPrivacyDetailsProps) {
    const [isAllowProductImprovement, setIsAllowProductImprovement] = useState<boolean>(false);
    const [isAllowModelImprovement, setIsAllowModelImprovement] = useState<boolean>(false);
    const [updateModelStateChange, setUpdateModelStateChange] = useState<CustomSwitchTransition>({
        isChanging: false,
        isChangeSuccessful: false,
        hasChangeFailed: false,
    });
    const [updateProductStateChange, setUpdateProductStateChange] =
        useState<CustomSwitchTransition>({
            isChanging: false,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });

    const {
        data: workspacesData,
        isFetched: isWorkspacesDataFetched,
        isError: isWorkspacesDataError,
        error: workspacesError,
    } = useGetWorkspaces();

    const {
        data: workspace,
        isSuccess: isWorkspaceFetchSuccess,
        isError: isWorkspaceFetchError,
        isFetched: isWorkspaceFetched,
    } = useGetWorkspaceByName(workspacesData?.value?.[0]?.name || '', {
        enabled: isWorkspacesDataFetched,
        refetchOnMount: true,
    });

    useEffect(() => {
        if (isWorkspaceFetchSuccess) {
            setIsAllowProductImprovement(
                (workspace?.workspaceOptInConfig?.isAllowProductImprovement ?? '') === 'true',
            );
            setIsAllowModelImprovement(
                (workspace?.workspaceOptInConfig?.isAllowModelImprovement ?? '') === 'true',
            );
        }
        if (isWorkspaceFetchError) {
            props?.onWorkspacesFetchError(workspacesError);
        }
    }, [isWorkspaceFetched]);

    useEffect(() => {
        if (isWorkspacesDataError) {
            props?.onWorkspacesFetchError(workspacesError);
        }
    }, [isWorkspacesDataFetched]);

    const {mutate: updateWorkspace} = useUpdateWorkspace();

    const updateProductImprovement = (isAllowProductImprovement: boolean) => {
        setUpdateProductStateChange({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        updatePreferences(
            {
                isAllowModelImprovement,
                isAllowProductImprovement: isAllowProductImprovement || false,
            },
            () =>
                setUpdateProductStateChange({
                    isChanging: false,
                    isChangeSuccessful: true,
                    hasChangeFailed: false,
                }),
            (error) => {
                setUpdateProductStateChange({
                    isChanging: false,
                    isChangeSuccessful: false,
                    hasChangeFailed: true,
                });
                props?.onWorkspacesProductUpdateError(error);
            },
        );
    };

    const updateModelImprovement = (isAllowModelImprovement: boolean) => {
        setUpdateModelStateChange({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        updatePreferences(
            {
                isAllowModelImprovement: isAllowModelImprovement || false,
                isAllowProductImprovement,
            },
            () =>
                setUpdateModelStateChange({
                    isChanging: false,
                    isChangeSuccessful: true,
                    hasChangeFailed: false,
                }),
            (error) => {
                setUpdateModelStateChange({
                    isChanging: false,
                    isChangeSuccessful: false,
                    hasChangeFailed: true,
                });
                props?.onWorkspacesModelUpdateError(error);
            },
        );
    };

    const updatePreferences = (
        preferences: DataAndPrivacyDetails,
        onSuccess: () => void,
        onError: (error: any) => void,
    ) => {
        updateWorkspace(
            {
                name: workspacesData?.value[0]?.name || '',
                workspaceOptInConfig: {
                    isAllowProductImprovement: preferences.isAllowProductImprovement
                        ? 'true'
                        : 'false',
                    isAllowModelImprovement: preferences.isAllowModelImprovement ? 'true' : 'false',
                },
            },
            {
                onSuccess: (data) => {
                    setIsAllowProductImprovement(
                        (data.workspaceOptInConfig?.isAllowProductImprovement ?? '') === 'true',
                    );
                    setIsAllowModelImprovement(
                        (data.workspaceOptInConfig?.isAllowModelImprovement ?? '') === 'true',
                    );
                    onSuccess();
                },
                onError: (error) => {
                    onError(error);
                },
            },
        );
    };

    return {
        isAllowModelImprovement,
        isAllowProductImprovement,
        updateProductImprovement,
        updateModelImprovement,
        updateModelStateChange,
        updateProductStateChange,
    };
}
