import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {PatchFileDescriptorRequest} from './files.types';

export default function useUpdateFile() {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({fileName, ...rest}: PatchFileDescriptorRequest) => {
            return await customFetch<Response>(`/files/${fileName}`, {
                method: 'PATCH',
                body: rest,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['files']);
            queryClient.invalidateQueries(['files', 'files/usage/user']);
        },
    });
}
