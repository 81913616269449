import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {iconFilledClassName, iconRegularClassName} from '@fluentui/react-icons';

export default makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    advancedFilterContainer: {
        width: '100%',
    },
    tagsContainer: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalS,
        rowGap: tokens.spacingVerticalS,
        justifyContent: 'center',
        maxWidth: '40%',
    },
    advancedFilterTagsContainer: {
        flexWrap: 'wrap',
        minWidth: '40%',
    },
    toggleButton: {
        fontWeight: tokens.fontWeightRegular,
        backgroundColor: tokens.colorNeutralBackground5Hover,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        display: 'flex',
        whiteSpace: 'nowrap',
        height: tokens.lineHeightBase600,
        alignItems: 'bottom',
    },
    toggleButtonItems: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalXS,
        flexGrow: '1',
        whiteSpace: 'nowrap',
        alignItems: 'bottom',
    },
    popoverMenuItems: {
        fontWeight: tokens.fontWeightRegular,
        display: 'flex',
        whiteSpace: 'nowrap',
        height: tokens.lineHeightBase600,
        width: '180px',
        ...shorthands.border('0px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.margin(tokens.spacingVerticalNone),
        '&:hover': {
            backgroundColor: tokens.colorSubtleBackgroundLightAlphaHover,
        },
    },
    popoverleftMenuitemsdisplay: {
        fontWeight: tokens.fontWeightRegular,
        display: 'flex',
        flexDirection: 'row',
        height: tokens.lineHeightBase600,
        whiteSpace: 'nowrap',
        columnGap: tokens.spacingHorizontalS,
        width: '80%',
        justifyContent: 'left',
        justifyItems: 'center',
        ...shorthands.padding(
            tokens.spacingVerticalXS,
            tokens.spacingVerticalNone,
            tokens.spacingVerticalNone,
            tokens.spacingVerticalXXS,
        ),
    },
    popoverleftMenuicondisplay: {
        ...shorthands.padding(
            tokens.spacingVerticalXXS,
            tokens.spacingVerticalNone,
            tokens.spacingVerticalNone,
            tokens.spacingVerticalXXS,
        ),
    },
    popoverrightMenuitemsdisplay: {
        fontWeight: tokens.fontWeightRegular,
        alignContent: 'right',
        height: tokens.lineHeightBase600,
        whiteSpace: 'nowrap',
        width: '20%',
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingVerticalNone,
            tokens.spacingVerticalNone,
            tokens.spacingVerticalXXS,
        ),
    },
    scrollableContainer: {
        maxHeight: '200px',
        display: 'flex',
        overflowY: 'auto',
    },
});
