import * as React from 'react';
const SVGComponent = (props: any) => (
    <svg
        width={1896}
        height={1809}
        viewBox="0 0 1896 1809"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity={0.8} filter="url(#filter0_f_1_4)">
            <path
                d="M675.245 420.915C977.038 312.634 1098.84 675.009 1414.92 761.899C1634.72 822.752 1368.42 1026.98 1054.59 1298.75C740.766 1570.53 589.327 1256.61 467.973 1200.95C346.618 1145.29 373.451 529.195 675.245 420.915Z"
                fill="#0D3D7F"
            />
        </g>
        <defs>
            <filter
                id="filter0_f_1_4"
                x={0.427734}
                y={0.903748}
                width={1895.09}
                height={1807.13}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={200} result="effect1_foregroundBlur_1_4" />
            </filter>
        </defs>
    </svg>
);
export default SVGComponent;
