import React, {useEffect} from 'react';
import {useTourContext} from '@/components/ui/Tour';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import AnalystTour from './AnalystTour';
import {useLayout} from '@/components/ui/Layout';
import {BackgroundAnimation, BackgroundImage} from '@/components/ui/Background';
import useTourHistoryTelemetry from '@/components/ui/Tour/useTourHistoryTelemetry';
import {GridContainer} from '@/components/ui/Grid';

function AnalystTourContainer() {
    const {onTourStart, onTourComplete, onTourExitEarly} = useTourHistoryTelemetry({
        tourName: 'analyst',
    });
    const {background} = useLayout();

    const classes = useTourClasses();
    const tour = useTourContext();
    const {currentStop, activeTour} = tour || {};

    const {overlay, content} = currentStop || {};
    useEffect(() => {
        tour.setTour(
            AnalystTour({telemetryOptions: {onTourComplete, onTourExitEarly, onTourStart}}),
        );
    }, []);

    useEffect(() => {
        background?.setImage?.(BackgroundImage.triangles);
        background?.setAnimation?.(BackgroundAnimation.bottomGradient);
        // run only when component loads
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.tourCenterWrapper} data-testid="tour-center-wrapper">
            <GridContainer
                data-testid="analyst-tour-container-root"
                className={classes.tourContainerRoot}
            >
                {activeTour && !tour.showOverlay && content}
            </GridContainer>
        </div>
    );
}

export default AnalystTourContainer;
