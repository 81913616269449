import {Link, Tooltip} from '@fluentui/react-components';
import {Session} from '@/api/sessions';
import useClasses from './TileLink.styles';
import SessionTile from './SessionTile';
import SessionTileEmpty from './SessionTileEmpty';
import {useTranslation} from 'react-i18next';

interface TileLinkProps {
    session: Session | null;
    hero?: boolean;
}

function TileLink(props: TileLinkProps) {
    const {session, hero = false} = props;
    const {t} = useTranslation('common');
    const sessionLabel = t('home.sessionLabel');
    const classes = useClasses();

    return session ? (
        <Tooltip content={t('home.goToSession')} relationship="label" positioning="below">
            <Link
                href={`/sessions/${session.sessionId}`}
                aria-label={`${sessionLabel}: ${session?.name}`}
                className={classes.sessionsLink}
            >
                <SessionTile hero={hero} sessionId={session.sessionId} />
            </Link>
        </Tooltip>
    ) : (
        <SessionTileEmpty />
    );
}

export default TileLink;
