import {EditIcon, AcceptIcon} from '@/components/ui/icons';
import {
    Toolbar,
    ToolbarButton,
    Tooltip,
    mergeClasses,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import useClasses from './InvestigationTitleBar.styles';
import {Session} from '@/api/sessions';
import useUpdateSession from '@/api/sessions/useUpdateSession';
import {useEffect, useRef, useState} from 'react';
import {InterfaceTitleTooltipParams} from './Investigation.types';
import {useTranslation} from 'react-i18next';

type InvestigationTitleProps = {
    investigationTitle: string;
    session: Session;
};

//InvestigationTitleBar component displays the title of an investigation
//and provides an option to edit and save the title.
export default function InvestigationTitleBar({
    investigationTitle,
    session,
}: InvestigationTitleProps) {
    const classes = useClasses();
    const {t} = useTranslation('session');
    const {mutate: updateSessions} = useUpdateSession();
    const inputRef = useRef<HTMLInputElement>(null);

    const [editedTitle, setEditedTitle] = useState(investigationTitle);
    const [editMode, setEditMode] = useState(false);
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    const restoreFocusTargetAttribute = useRestoreFocusTarget();

    const handleEditClick = () => {
        setEditMode(true);
        setEditedTitle(investigationTitle);
    };

    //Focus on the input box on edit mode true
    useEffect(() => {
        if (editMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editMode]);

    //Event handler for the save button click.
    //Updates the investigation title and saves the changes.
    const handleSaveClick = () => {
        const updatedInvestigation = {...session, name: editedTitle};
        setEditMode(false);
        updateSessions(updatedInvestigation);
    };

    const handleInputChange = (name: string) => {
        setEditedTitle(name);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // When editing a prompt, escape can be used to cancel.
        if (event.key === 'Enter') {
            handleSaveClick();
            event.preventDefault();
        } else if (event.key === 'Escape') {
            setEditMode(false);
            setEditedTitle(session.name);
        }
    };

    // Determine the container class name based on edit mode and hover state
    const containerClassName = mergeClasses(
        classes.titleHeader,
        editMode ? classes.edited : classes.hover,
    );

    const toolbarClassName = mergeClasses(
        classes.toolbar,
        editMode ? classes.edited : classes.hover,
    );

    return (
        <div className={containerClassName}>
            <div className={classes.input}>
                {editMode ? (
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(event) => {
                            handleInputChange(event.target.value);
                        }}
                        className={classes.input}
                        ref={inputRef}
                        onKeyDown={handleKeyUp}
                        {...restoreFocusSourceAttribute}
                    />
                ) : (
                    <h3
                        title={t('PinboardHeading')}
                        className={classes.investigationTitle}
                        contentEditable
                        onClick={handleEditClick}
                        onKeyDown={handleEditClick}
                    >
                        {investigationTitle}
                    </h3>
                )}
            </div>
            {
                <Toolbar className={toolbarClassName} size="small">
                    {!editMode && (
                        <Tooltip content={t('InvestigationTitleEdit')} relationship="label">
                            <ToolbarButton
                                {...restoreFocusTargetAttribute}
                                appearance="subtle"
                                tabIndex={0}
                                {...restoreFocusTargetAttribute}
                                icon={<EditIcon />}
                                onClick={handleEditClick}
                            />
                        </Tooltip>
                    )}

                    {editMode && (
                        <Tooltip content={t('InvestigationTitleSubmit')} relationship="label">
                            <ToolbarButton
                                {...restoreFocusTargetAttribute}
                                appearance="subtle"
                                tabIndex={0}
                                {...restoreFocusTargetAttribute}
                                icon={<AcceptIcon />}
                                onClick={handleSaveClick}
                            />
                        </Tooltip>
                    )}
                </Toolbar>
            }
        </div>
    );
}
