import {Button, Field, InfoLabel, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {SkillsetDescriptor} from '@/api/skills';
import {UploadSkillsetIcon} from '@/components/ui/icons';
import {useTenantScopePluginUpload, useUserScopePluginUpload} from '@/api/tenant';
import {useTranslation} from 'react-i18next';

interface CustomPluginSetupControlsProps {
    skillsetDescriptor: SkillsetDescriptor | null;
    onEdit: () => void;
}

export default function CustomPluginSetupControls({
    skillsetDescriptor,
    onEdit,
}: CustomPluginSetupControlsProps) {
    const classes = useClasses();
    const {isTenantScopeUploadAllowed} = useTenantScopePluginUpload();
    const {isUserScopeUploadAllowed} = useUserScopePluginUpload();

    const allowPluginManagement =
        skillsetDescriptor !== null && isTenantScopeUploadAllowed(skillsetDescriptor ?? undefined);
    const allowEditPlugin = allowPluginManagement && isUserScopeUploadAllowed();
    const {t} = useTranslation('plugins');

    return (
        <div className={classes.pluginInfo}>
            <div className={classes.sectionHeading}>
                <Field
                    className={classes.headingTextCol}
                    label={{
                        children: (
                            <InfoLabel>
                                <Text className={classes.headingText} as="h2">
                                    {t('Admin.PluginInfoTitle')}
                                </Text>
                            </InfoLabel>
                        ),
                    }}
                />
                {allowPluginManagement && (
                    <Button
                        onClick={onEdit}
                        className={classes.triggerResetButton}
                        appearance="subtle"
                        size="small"
                        icon={<UploadSkillsetIcon />}
                        disabled={!allowEditPlugin}
                    >
                        {t('Admin.EditFile')}
                    </Button>
                )}
            </div>
            <div className={classes.sectionBody}>
                <div className={classes.pluginInput}>
                    <Field
                        label={{
                            children: (
                                <InfoLabel className={classes.infoLabel}>
                                    <Text className={classes.configText}>
                                        {t('Admin.NameLabel')}
                                    </Text>
                                </InfoLabel>
                            ),
                        }}
                    >
                        <Text className={classes.configSubtext}>{skillsetDescriptor?.name}</Text>
                    </Field>
                    <br />
                    <Field
                        label={{
                            children: (
                                <InfoLabel className={classes.infoLabel}>
                                    <Text className={classes.configText}>
                                        {t('Admin.DescriptionLabel')}
                                    </Text>
                                </InfoLabel>
                            ),
                        }}
                    >
                        <Text className={classes.configSubtext}>
                            {skillsetDescriptor?.descriptionDisplay ??
                                skillsetDescriptor?.description}
                        </Text>
                    </Field>
                </div>
            </div>
        </div>
    );
}
