import * as React from 'react';
const ExplodingHead = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path
                fill="url(#f454id0)"
                d="M14.68 10.94h2.527c10.183-.012 12.823-.014 12.674 5.2c-.22 7.705-4.469 13.723-13.89 13.91h-.094c-9.422-.188-13.67-6.205-13.891-13.91c-.15-5.214 2.491-5.212 12.674-5.2Z"
            />
            <path
                fill="url(#f454id1)"
                d="M14.68 10.94h2.527c10.183-.012 12.823-.014 12.674 5.2c-.22 7.705-4.469 13.723-13.89 13.91h-.094c-9.422-.188-13.67-6.205-13.891-13.91c-.15-5.214 2.491-5.212 12.674-5.2Z"
            />
            <path
                fill="url(#f454id2)"
                d="M14.68 10.94h2.527c10.183-.012 12.823-.014 12.674 5.2c-.22 7.705-4.469 13.723-13.89 13.91h-.094c-9.422-.188-13.67-6.205-13.891-13.91c-.15-5.214 2.491-5.212 12.674-5.2Z"
            />
            <path
                fill="url(#f454id3)"
                d="M25.867 13.082c0 1.11-3.89 2-9.96 2s-10-.9-10-2s3.92-2 10-2s9.96.9 9.96 2Z"
            />
            <path
                fill="url(#f454idy)"
                d="M15.857 29.023c-1.36 0-2.47-1.11-2.47-2.47s1.11-2.47 2.47-2.47s2.47 1.11 2.47 2.47s-1.11 2.47-2.47 2.47Z"
            />
            <g filter="url(#f454idq)">
                <path
                    stroke="#FFF500"
                    strokeLinecap="round"
                    strokeOpacity=".5"
                    d="M28.184 14.222c-.657.703-2.079 1.453-3.063 1.75"
                />
            </g>
            <path
                fill="url(#f454id4)"
                d="M20.092 8.748a4.965 4.965 0 0 1-4.122 2.192a4.965 4.965 0 0 1-4.122-2.192a4 4 0 1 1-.98-6.3c.56.303 1.316.24 1.79-.184A4.952 4.952 0 0 1 15.97 1c1.272 0 2.433.478 3.312 1.264c.474.424 1.23.487 1.79.185a4 4 0 1 1-.98 6.3Z"
            />
            <path
                fill="url(#f454id5)"
                d="M20.092 8.748a4.965 4.965 0 0 1-4.122 2.192a4.965 4.965 0 0 1-4.122-2.192a4 4 0 1 1-.98-6.3c.56.303 1.316.24 1.79-.184A4.952 4.952 0 0 1 15.97 1c1.272 0 2.433.478 3.312 1.264c.474.424 1.23.487 1.79.185a4 4 0 1 1-.98 6.3Z"
            />
            <path
                fill="url(#f454id6)"
                d="M20.092 8.748a4.965 4.965 0 0 1-4.122 2.192a4.965 4.965 0 0 1-4.122-2.192a4 4 0 1 1-.98-6.3c.56.303 1.316.24 1.79-.184A4.952 4.952 0 0 1 15.97 1c1.272 0 2.433.478 3.312 1.264c.474.424 1.23.487 1.79.185a4 4 0 1 1-.98 6.3Z"
            />
            <path
                fill="url(#f454id7)"
                d="M20.092 8.748a4.965 4.965 0 0 1-4.122 2.192a4.965 4.965 0 0 1-4.122-2.192a4 4 0 1 1-.98-6.3c.56.303 1.316.24 1.79-.184A4.952 4.952 0 0 1 15.97 1c1.272 0 2.433.478 3.312 1.264c.474.424 1.23.487 1.79.185a4 4 0 1 1-.98 6.3Z"
            />
            <path
                fill="url(#f454id8)"
                d="M20.092 8.748a4.965 4.965 0 0 1-4.122 2.192a4.965 4.965 0 0 1-4.122-2.192a4 4 0 1 1-.98-6.3c.56.303 1.316.24 1.79-.184A4.952 4.952 0 0 1 15.97 1c1.272 0 2.433.478 3.312 1.264c.474.424 1.23.487 1.79.185a4 4 0 1 1-.98 6.3Z"
            />
            <path
                fill="url(#f454id9)"
                d="M20.092 8.748a4.965 4.965 0 0 1-4.122 2.192a4.965 4.965 0 0 1-4.122-2.192a4 4 0 1 1-.98-6.3c.56.303 1.316.24 1.79-.184A4.952 4.952 0 0 1 15.97 1c1.272 0 2.433.478 3.312 1.264c.474.424 1.23.487 1.79.185a4 4 0 1 1-.98 6.3Z"
            />
            <g filter="url(#f454idr)">
                <path
                    fill="url(#f454ida)"
                    d="M12.241 8.818V3.361c0-.4.302-.725.6-.992a4.74 4.74 0 0 1 3.17-1.21a4.74 4.74 0 0 1 3.172 1.21c.356.318.747.693.747 1.171v5.076a4.776 4.776 0 0 1-2.096 1.697H14.19a4.774 4.774 0 0 1-1.949-1.495Z"
                />
                <path
                    fill="url(#f454idb)"
                    d="M12.241 8.818V3.361c0-.4.302-.725.6-.992a4.74 4.74 0 0 1 3.17-1.21a4.74 4.74 0 0 1 3.172 1.21c.356.318.747.693.747 1.171v5.076a4.776 4.776 0 0 1-2.096 1.697H14.19a4.774 4.774 0 0 1-1.949-1.495Z"
                />
                <path
                    fill="url(#f454idc)"
                    d="M12.241 8.818V3.361c0-.4.302-.725.6-.992a4.74 4.74 0 0 1 3.17-1.21a4.74 4.74 0 0 1 3.172 1.21c.356.318.747.693.747 1.171v5.076a4.776 4.776 0 0 1-2.096 1.697H14.19a4.774 4.774 0 0 1-1.949-1.495Z"
                />
            </g>
            <g filter="url(#f454ids)">
                <path
                    fill="url(#f454idd)"
                    d="M11.717 4.02c0-.56-.585-.962-1.077-1.229a3.61 3.61 0 1 0 .884 5.686c.061.091.126.18.193.266V4.02Z"
                />
                <path
                    fill="url(#f454ide)"
                    d="M11.717 4.02c0-.56-.585-.962-1.077-1.229a3.61 3.61 0 1 0 .884 5.686c.061.091.126.18.193.266V4.02Z"
                />
                <path
                    fill="url(#f454idf)"
                    d="M11.717 4.02c0-.56-.585-.962-1.077-1.229a3.61 3.61 0 1 0 .884 5.686c.061.091.126.18.193.266V4.02Z"
                />
            </g>
            <g filter="url(#f454idt)">
                <path
                    fill="url(#f454idg)"
                    d="M19.93 3.961c0-.577.602-.992 1.11-1.266a3.72 3.72 0 1 1-.91 5.858c-.064.094-.13.185-.2.274V3.96Z"
                />
            </g>
            <path
                fill="url(#f454idh)"
                d="M4 10.03a1.03 1.03 0 1 0 0-2.06a1.03 1.03 0 0 0 0 2.06Z"
            />
            <path fill="url(#f454idi)" d="M26.98 11a1 1 0 1 0 0-2a1 1 0 0 0 0 2Z" />
            <path
                fill="url(#f454idj)"
                d="M23.97 7.35a1.38 1.38 0 1 0 0-2.76a1.38 1.38 0 0 0 0 2.76Z"
            />
            <path
                fill="url(#f454idk)"
                d="M5.47 5.85a1.38 1.38 0 1 0 0-2.76a1.38 1.38 0 0 0 0 2.76Z"
            />
            <path
                fill="url(#f454idz)"
                d="M13.534 13.407a.636.636 0 0 0-.604-.395a3.99 3.99 0 1 1 2.386-7.188a1.08 1.08 0 0 0 1.268 0a3.99 3.99 0 1 1 2.386 7.188a.534.534 0 0 0-.499.326c-.247.613-.32.678-.493 1.703a56.667 56.667 0 0 1-4.01.005c-.104-.756-.199-1.015-.434-1.639Z"
            />
            <path
                fill="url(#f454idl)"
                d="M13.534 13.407a.636.636 0 0 0-.604-.395a3.99 3.99 0 1 1 2.386-7.188a1.08 1.08 0 0 0 1.268 0a3.99 3.99 0 1 1 2.386 7.188a.534.534 0 0 0-.499.326c-.247.613-.32.678-.493 1.703a56.667 56.667 0 0 1-4.01.005c-.104-.756-.199-1.015-.434-1.639Z"
            />
            <path
                fill="url(#f454idm)"
                d="M13.534 13.407a.636.636 0 0 0-.604-.395a3.99 3.99 0 1 1 2.386-7.188a1.08 1.08 0 0 0 1.268 0a3.99 3.99 0 1 1 2.386 7.188a.534.534 0 0 0-.499.326c-.247.613-.32.678-.493 1.703a56.667 56.667 0 0 1-4.01.005c-.104-.756-.199-1.015-.434-1.639Z"
            />
            <g filter="url(#f454idu)">
                <path
                    fill="url(#f454idn)"
                    d="M18.738 13.063H18a2 2 0 0 1-2-2V6.029c.208-.01.414-.078.584-.205a3.99 3.99 0 1 1 2.386 7.188a.56.56 0 0 0-.232.05Z"
                />
                <path
                    fill="url(#f454ido)"
                    d="M18.738 13.063H18a2 2 0 0 1-2-2V6.029c.208-.01.414-.078.584-.205a3.99 3.99 0 1 1 2.386 7.188a.56.56 0 0 0-.232.05Z"
                />
                <path
                    fill="url(#f454idp)"
                    d="M18.738 13.063H18a2 2 0 0 1-2-2V6.029c.208-.01.414-.078.584-.205a3.99 3.99 0 1 1 2.386 7.188a.56.56 0 0 0-.232.05Z"
                />
            </g>
            <g filter="url(#f454idv)">
                <path
                    fill="url(#f454id10)"
                    d="M13.34 13.148a.584.584 0 0 1 .194.259c.235.624.33.883.434 1.639c.618.021 1.265.032 1.939.032h.124v-4.047l-2.692 2.117Z"
                />
            </g>
            <g filter="url(#f454idw)">
                <path
                    fill="#D66A46"
                    fillOpacity=".6"
                    d="M10.23 25.5a4.26 4.26 0 1 0 0-8.52a4.26 4.26 0 0 0 0 8.52Z"
                />
            </g>
            <g filter="url(#f454idx)">
                <path
                    fill="#FF7C32"
                    fillOpacity=".6"
                    d="M20.793 25.5a4.26 4.26 0 1 0 0-8.52a4.26 4.26 0 0 0 0 8.52Z"
                />
            </g>
            <path fill="#fff" d="M10.45 24.94a4.48 4.48 0 1 0 0-8.96a4.48 4.48 0 0 0 0 8.96Z" />
            <path fill="#402933" d="M11.95 22.46a2 2 0 1 0 0-4a2 2 0 0 0 0 4Z" />
            <path fill="#fff" d="M21.45 24.94a4.48 4.48 0 1 0 0-8.96a4.48 4.48 0 0 0 0 8.96Z" />
            <path fill="#412933" d="M19.95 22.46a2 2 0 1 0 0-4a2 2 0 0 0 0 4Z" />
            <defs>
                <radialGradient
                    id="f454id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(0 28.375 -74.5612 0 16.403 13.62)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F89C20" />
                    <stop offset=".172" stopColor="#F8D51D" />
                    <stop offset=".49" stopColor="#F89C20" />
                    <stop offset="1" stopColor="#F5891F" />
                </radialGradient>
                <radialGradient
                    id="f454id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(91.802 1.376 14.531) scale(17.6963 70.196)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EB9037" stopOpacity="0" />
                    <stop offset="1" stopColor="#EB9037" />
                </radialGradient>
                <radialGradient
                    id="f454id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(0 13.875 -19.7411 0 15.943 16.175)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".824" stopColor="#FFB56D" stopOpacity="0" />
                    <stop offset="1" stopColor="#FF63C4" />
                </radialGradient>
                <radialGradient
                    id="f454id3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(.0011 4.5492 -13.8543 .00334 15.886 11.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".223" stopColor="#F89C20" />
                    <stop offset=".9" stopColor="#BD824F" />
                </radialGradient>
                <radialGradient
                    id="f454id4"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(135.922 9.972 4.837) scale(19.227 17.9698)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset=".983" stopColor="#D1D3D4" />
                </radialGradient>
                <radialGradient
                    id="f454id5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(11.19991 -5.9167 4.94721 9.36475 10.367 8.822)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DCC4E7" />
                    <stop offset="1" stopColor="#DCC4E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454id6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(4.59375 -8.34375 7.49567 4.12683 6.875 9.719)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".141" stopColor="#C6C6C6" />
                    <stop offset="1" stopColor="#C1B4B8" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454id7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-53.56 15.058 -3.82) scale(10.8378 8.89035)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B076CB" />
                    <stop offset="1" stopColor="#DCC4E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454id8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-116.432 16.215 -1.506) scale(6.0374 8.13909)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B076CB" />
                    <stop offset="1" stopColor="#DCC4E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454id9"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(17.05825 10.89914 -22.70018 35.52806 -2.619 -3.322)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".141" stopColor="#C6C6C6" />
                    <stop offset="1" stopColor="#C1B4B8" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454ida"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-3.0108 4.87547 -4.56693 -2.82026 17.86 2.913)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset=".983" stopColor="#D1D3D4" />
                </radialGradient>
                <radialGradient
                    id="f454idb"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-54.31 15.215 -9.59) scale(6.7091 6.43579)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DCC4E7" />
                    <stop offset="1" stopColor="#DCC4E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idc"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(74.982 7.398 6.04) scale(7.61966 22.7483)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".141" stopColor="#C6C6C6" />
                    <stop offset="1" stopColor="#C1B4B8" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idd"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(126.323 4.349 4.552) scale(4.7734 4.77317)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset=".983" stopColor="#D1D3D4" />
                </radialGradient>
                <radialGradient
                    id="f454ide"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-49.465 12.273 -3.67) scale(5.65565 5.65551)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DCC4E7" />
                    <stop offset="1" stopColor="#DCC4E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idf"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(54.739 2.166 2.338) scale(9.69723 20.9837)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".141" stopColor="#C6C6C6" />
                    <stop offset="1" stopColor="#C1B4B8" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idg"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(4.37499 -4.09376 13.00577 13.89924 21.375 8.968)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".141" stopColor="#C6C6C6" />
                    <stop offset="1" stopColor="#C1B4B8" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idh"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-.89798 .92132 -1.325 -1.29143 4.372 8.614)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE363" />
                    <stop offset=".536" stopColor="#F4C222" />
                    <stop offset="1" stopColor="#ED8546" />
                </radialGradient>
                <radialGradient
                    id="f454idi"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(134.265 11.64 10.578) scale(1.24907 1.79636)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE363" />
                    <stop offset=".536" stopColor="#F4C222" />
                    <stop offset="1" stopColor="#ED8546" />
                </radialGradient>
                <radialGradient
                    id="f454idj"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-1.20311 1.23438 -1.77525 -1.73027 24.469 5.453)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE363" />
                    <stop offset=".536" stopColor="#F4C222" />
                    <stop offset="1" stopColor="#ED8546" />
                </radialGradient>
                <radialGradient
                    id="f454idk"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-1.20311 1.23438 -1.77525 -1.73027 5.969 3.953)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE363" />
                    <stop offset=".536" stopColor="#F4C222" />
                    <stop offset="1" stopColor="#ED8546" />
                </radialGradient>
                <radialGradient
                    id="f454idl"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(3.6375 -5.03125 10.41461 7.52956 12.312 12.313)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DCC4E7" />
                    <stop offset="1" stopColor="#DCC4E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idm"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(3.125 3.5625 -7.40881 6.49896 8.437 5.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".042" stopColor="#A6A2A2" />
                    <stop offset=".885" stopColor="#C1B4B8" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idn"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(122.51 8.741 9.07) scale(5.07132 4.81595)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset=".983" stopColor="#D1D3D4" />
                </radialGradient>
                <radialGradient
                    id="f454ido"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-53.451 20.127 -11.908) scale(5.94984 5.76258)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".156" stopColor="#DCC4E7" />
                    <stop offset=".693" stopColor="#DCC4E7" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f454idp"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(4.46317 6.89674 -14.1055 9.12826 13.6 1.54)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDD" />
                    <stop offset="1" stopColor="#C1B4B8" stopOpacity="0" />
                </radialGradient>
                <filter
                    id="f454idq"
                    width="6.063"
                    height="4.75"
                    x="23.621"
                    y="12.722"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".5" />
                </filter>
                <filter
                    id="f454idr"
                    width="9.288"
                    height="10.754"
                    x="11.441"
                    y=".358"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".4" />
                </filter>
                <filter
                    id="f454ids"
                    width="8.402"
                    height="9.221"
                    x="4.315"
                    y="1.359"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".5" />
                </filter>
                <filter
                    id="f454idt"
                    width="8.596"
                    height="9.44"
                    x="18.93"
                    y="1.25"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".5" />
                </filter>
                <filter
                    id="f454idu"
                    width="8.96"
                    height="10.03"
                    x="15"
                    y="4.032"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".5" />
                </filter>
                <filter
                    id="f454idv"
                    width="3.692"
                    height="5.047"
                    x="12.839"
                    y="10.531"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".25" />
                </filter>
                <filter
                    id="f454idw"
                    width="10.52"
                    height="10.52"
                    x="4.97"
                    y="15.98"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".5" />
                </filter>
                <filter
                    id="f454idx"
                    width="10.52"
                    height="10.52"
                    x="15.533"
                    y="15.98"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6808_5249" stdDeviation=".5" />
                </filter>
                <linearGradient
                    id="f454idy"
                    x1="15.86"
                    x2="15.86"
                    y1="29.026"
                    y2="24.087"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D3037B" />
                    <stop offset="1" stopColor="#B7039B" />
                </linearGradient>
                <linearGradient
                    id="f454idz"
                    x1="15.375"
                    x2="10.25"
                    y1="13.316"
                    y2="17.513"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset=".983" stopColor="#D1D3D4" />
                </linearGradient>
                <linearGradient
                    id="f454id10"
                    x1="14.685"
                    x2="16.416"
                    y1="9.531"
                    y2="13.91"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".141" stopColor="#C6C6C6" />
                    <stop offset="1" stopColor="#C1B4B8" stopOpacity="0" />
                </linearGradient>
            </defs>
        </g>
    </svg>
);
export default ExplodingHead;
