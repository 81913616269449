import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Button, Textarea, TextareaOnChangeData, mergeClasses} from '@fluentui/react-components';
import {ReportErrorIcon} from '../../icons';
import {useClasses} from './Feedback.styles';

interface ReportProblemProps {
    onDetailsChange: (details: string) => void;
}

const componentLabels = {
    whatDoYouLike: 'What do you like? (Required)',
    whatDoYouNeedHelpWithPlaceholder: 'Provide details without including personal information.',
};

export default function ReportProblem({onDetailsChange}: ReportProblemProps) {
    const classes = useClasses();

    const [additionalDetails, setAdditionalDetails] = useState<string>('');

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    function handleAdditionalDetailsContentChange(
        ev: ChangeEvent<HTMLTextAreaElement>,
        data: TextareaOnChangeData,
    ): void {
        setAdditionalDetails(data.value);
        onDetailsChange(data.value);
    }

    useEffect(() => {
        textAreaRef.current?.focus();
    }, []);

    return (
        <>
            <div className={mergeClasses(classes.controlMargin)}>
                <Button icon={<ReportErrorIcon />} className={mergeClasses(classes.fullWidth)}>
                    Report a problem
                </Button>
            </div>
            <div className={mergeClasses(classes.componentLabel, classes.controlMargin)}>
                {componentLabels.whatDoYouLike}
            </div>
            <div className={mergeClasses(classes.controlMargin)}>
                <Textarea
                    className={mergeClasses(classes.textAreaFullWidth, classes.textAreaControl)}
                    value={additionalDetails}
                    size="large"
                    rows={4}
                    placeholder={componentLabels.whatDoYouNeedHelpWithPlaceholder}
                    onChange={handleAdditionalDetailsContentChange}
                    ref={textAreaRef}
                />
            </div>
        </>
    );
}
