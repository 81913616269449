import {
    Button,
    ToggleButton,
    Textarea,
    Subtitle1,
    useRestoreFocusSource,
} from '@fluentui/react-components';
import {EvaluationFeedbackType, EvaluationFeedbackSubType} from '@/api/feedbacks';
import useClasses from './EvaluationFeedbackForm.styles';
import {DismissIcon, SendIcon} from '@/components/ui/icons';

import {useTourable} from '@/components/ui/Tour/useTourable';
import {EvaluationFeedbackFormProps} from './EvaluationToolbar.types';

const feedbackTitleText = {
    [EvaluationFeedbackType.Confirm]: 'What made you confirm this response?',
    [EvaluationFeedbackType.OffTarget]: 'What was off about this response?',
    [EvaluationFeedbackType.Report]: 'Why would you like to report this response?',
    [EvaluationFeedbackType.Unknown]: '',
};

function EvaluationFeedbackForm(props: EvaluationFeedbackFormProps) {
    const {
        feedbackType,
        setShowFeedbackForm,
        feedbackSubtypesRef: feedbackSubtypes,
        feedbackTextareaRef: feedbackTextarea,
        handleCreateFeedback,
    } = props;
    const feedbackformTourRef = useTourable({id: 'feedback-form'});
    const submitButtonTourRef = useTourable({id: 'feedback-form-submit'});
    const restoreFocusSourceAttribute = useRestoreFocusSource();

    const classes = useClasses();
    const titleText = feedbackTitleText[feedbackType];

    // To generate aria-label for feedback subtypes
    function generateAriaLabel(subType: string): string {
        return `${titleText}, ${subType}`;
    }

    return (
        <div className={classes.feedback} ref={feedbackformTourRef}>
            <div className={classes.title}>
                <Subtitle1>{titleText}</Subtitle1>
                <Button
                    appearance="subtle"
                    icon={<DismissIcon />}
                    onClick={() => setShowFeedbackForm(false)}
                    aria-label="Close response feedback form"
                    {...restoreFocusSourceAttribute}
                />
            </div>
            <div
                className={classes.feedbackSubtypes}
                ref={feedbackSubtypes}
                data-tour-id="feedback-subtypes"
            >
                {feedbackType === EvaluationFeedbackType.Confirm && (
                    <>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Accurate}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Accurate)}
                        >
                            Accurate
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Clear}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Clear)}
                        >
                            Clear
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Complete}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Complete)}
                        >
                            Complete
                        </ToggleButton>
                    </>
                )}
                {feedbackType === EvaluationFeedbackType.OffTarget && (
                    <>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Inaccurate}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Inaccurate)}
                        >
                            Inaccurate
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Unclear}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Unclear)}
                        >
                            Unclear
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Incomplete}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Incomplete)}
                        >
                            Incomplete
                        </ToggleButton>
                    </>
                )}
                {feedbackType === EvaluationFeedbackType.Report && (
                    <>
                        {/* TODO: "Bias" is shown on UI however it's mapped to "Biases" in EvaluationFeedbackSubType enum as well as backend models. */}
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Biases}
                            aria-label={generateAriaLabel('Bias')}
                        >
                            Bias
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Stereotypes}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Stereotypes)}
                        >
                            Stereotypes
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.HateSpeech}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.HateSpeech)}
                        >
                            Hate speech
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Violence}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Violence)}
                        >
                            Violence
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.SelfHarm}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.SelfHarm)}
                        >
                            Self-harm
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.SexualReferences}
                            aria-label={generateAriaLabel(
                                EvaluationFeedbackSubType.SexualReferences,
                            )}
                        >
                            Sexual references
                        </ToggleButton>
                        <ToggleButton
                            appearance="subtle"
                            shape="circular"
                            className={classes.feedbackSubtypeButton}
                            value={EvaluationFeedbackSubType.Other}
                            aria-label={generateAriaLabel(EvaluationFeedbackSubType.Other)}
                        >
                            Other
                        </ToggleButton>
                    </>
                )}
            </div>
            <Textarea
                ref={feedbackTextarea}
                className={classes.feedbackTextarea}
                size="large"
                placeholder="Tell us more"
                aria-label={titleText}
            />
            <div className={classes.submitFeedback}>
                <Button
                    appearance="subtle"
                    data-testid="feedback-submit-button"
                    ref={submitButtonTourRef}
                    icon={<SendIcon />}
                    onClick={handleCreateFeedback}
                    aria-label="Submit response feedback"
                />
            </div>
        </div>
    );
}

export default EvaluationFeedbackForm;
