import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    messageBar: {
        marginTop: tokens.spacingVerticalMNudge,
    },
    languageSwitch: {
        marginTop: tokens.spacingVerticalMNudge,
    },
});
