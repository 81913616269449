import MedeinaFeatures, {MedeinaMetaFeatures} from '@/util/features';
import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import {useContext, useMemo} from 'react';
import {UserStateContext} from './user';

type FeatureLaunchExperience = {
    [MedeinaMetaFeatures.LaunchExperienceAFD]: boolean;
    [MedeinaMetaFeatures.LaunchExperienceFidelisRBAC]: boolean;
    [MedeinaMetaFeatures.LaunchExperienceFidelisGA]: boolean;
    [MedeinaMetaFeatures.LaunchExperienceNonFidelisGA]: boolean;
};

// Based on a number of feature flags, the user will be delivered a different UX.
// IMPORTANT: This hook should not be used directly, rather these flags should be accessed via `useFeatureFlags`.
export default function useFeatureLaunchExperience() {
    // We can't use `useFeatureFlags`, because `useFeatureFlags` consumes `useFeatureLaunchExperience`.
    const {featureFlags} = useContext(UserStateContext);
    const isFidelisProvisioned = useMemo<boolean>(
        () => featureFlags?.includes(MedeinaFeatures.FidelisProvisioned) ?? false,
        [featureFlags],
    );

    return useMemo<FeatureLaunchExperience>(() => {
        const debugEnabled = hasStaticFeatureFlag(MedeinaFeatures.Debug);
        // Fidelis feature flags must be enabled for an environment for any of the following flags to be valid.
        const isFidelisEnabled = MedeinaFeatures.EnableFidelis;
        // One of the three following flags must also be enabled for a Fidelis API endpoint to work:
        // - isFidelisProvisioned
        // - isFidelisApiEnabled
        // - isFidelisAfdApiEnabled
        // TODO: These two flags can be removed after `LaunchAfdProvision` is completed to all environments.
        const isFidelisApiEnabled = hasStaticFeatureFlag(MedeinaFeatures.Fidelis);
        const isFidelisAfdApiEnabled = hasStaticFeatureFlag(MedeinaFeatures.FidelisAfd);

        // These are two flags that will gate functionality that may be merged down but shouldn't be exposed.
        const isLaunchAfdProvision = MedeinaFeatures.LaunchAfdProvision;
        const isLaunchEapFreDisabled = MedeinaFeatures.LaunchDisableEapFre;
        const isLaunchRBACEnabled = MedeinaFeatures.LaunchRBAC;
        const isLaunchGAEnabled = MedeinaFeatures.LaunchGA;

        // Aggregate the prior flags.
        const isFidelisCapable = Boolean(
            isFidelisEnabled &&
                (isLaunchAfdProvision
                    ? isFidelisProvisioned
                    : isFidelisProvisioned || isFidelisApiEnabled || isFidelisAfdApiEnabled),
        );

        if (debugEnabled) {
            console.log('useFeatureLaunchExperience', {
                isFidelisProvisioned,
                isFidelisEnabled,
                isFidelisCapable,
                isLaunchEapFreDisabled,
                isLaunchRBACEnabled,
                isLaunchGAEnabled,
                launchExperienceAfd: isLaunchAfdProvision,
                launchFidelisRBAC: isLaunchRBACEnabled && isFidelisCapable,
                launchFidelisGA: isLaunchGAEnabled && isFidelisCapable,
                launchNonFidelisGA: isLaunchGAEnabled,
            });
        }
        return {
            // GA Launch stage 1: Medeina-Service AFD has been deployed as the primary API and `LaunchAfdProvision` = true.
            // This is primarily for reference, unlikely to be integrated anywhere outside of this file.
            [MedeinaMetaFeatures.LaunchExperienceAFD]: Boolean(isLaunchAfdProvision),
            // GA Launch stage 2: Enable role assignment for Fidelis-migrated EAP and GA customers.
            [MedeinaMetaFeatures.LaunchExperienceFidelisRBAC]: Boolean(
                isLaunchRBACEnabled && isFidelisCapable,
            ),
            // GA Launch stage 3a: Enable GA features that require Fidelis (for GA and Fidelis-migrated EAP customers).
            [MedeinaMetaFeatures.LaunchExperienceFidelisGA]: Boolean(
                isLaunchGAEnabled && isFidelisCapable,
            ),
            // GA Launch stage 3b: Enable GA features that don't require Fidelis (for everyone, including Fidelis opt-out EAP customers).
            [MedeinaMetaFeatures.LaunchExperienceNonFidelisGA]: Boolean(isLaunchGAEnabled),
        };
    }, [isFidelisProvisioned]);
}
