import {Reducer, useCallback, useReducer} from 'react';
import {SwitchCapacityContext} from '../SwitchCapacity';

interface CapacitySwitchState {
    isSwitchCapacityDialogOpen: boolean;
    switchCapacityMode: SwitchCapacityContext | undefined;
}

interface CapacitySwitchPayload {
    isSwitchCapacityDialogOpen: boolean;
    switchCapacityMode: SwitchCapacityContext | undefined;
}

interface Action {
    type: string;
    payload: CapacitySwitchPayload;
}

const DEFAULT_STATE: CapacitySwitchState = {
    isSwitchCapacityDialogOpen: false,
    switchCapacityMode: '',
};

export function useCapacitySwitchOnOwnerSettings() {
    const capacitySwitchingReducer = useCallback(
        (state: CapacitySwitchState, action: Action): CapacitySwitchState => {
            switch (action.type) {
                case 'SET_DIALOG_OPEN':
                    return {...state, ...action.payload};
                case 'SET_DIALOG_CLOSE':
                    return {...state, isSwitchCapacityDialogOpen: false, switchCapacityMode: ''};
                default:
                    return state;
            }
        },
        [],
    );

    const [state, dispatch] = useReducer<Reducer<CapacitySwitchState, Action>>(
        capacitySwitchingReducer,
        DEFAULT_STATE,
    );

    const setSwitchCapacityOpen = (mode: SwitchCapacityContext) => {
        dispatch({
            type: 'SET_DIALOG_OPEN',
            payload: {
                isSwitchCapacityDialogOpen: true,
                switchCapacityMode: mode,
            },
        });
    };

    const setSwitchCapacityClose = () => {
        dispatch({
            type: 'SET_DIALOG_CLOSE',
            payload: DEFAULT_STATE,
        });
    };

    const isSwitchCapacityVisible = state.isSwitchCapacityDialogOpen;
    const switchCapacityMode = state.switchCapacityMode;

    return {
        isSwitchCapacityVisible,
        switchCapacityMode,
        setSwitchCapacityOpen,
        setSwitchCapacityClose,
    };
}
