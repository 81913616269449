import React from 'react';
import {Badge} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

function Preview() {
    const {t} = useTranslation();
    return (
        <Badge appearance="filled" color="informative">
            {t('ApplicationTitleBadge')}
        </Badge>
    );
}

export default Preview;
