import useGridContainerStyles from './useGridContainerStyles.styles';
import {mergeClasses} from '@fluentui/react-components';

function GridContainer({className, children}: {className?: string; children: React.ReactNode}) {
    const styles = useGridContainerStyles();
    return (
        <div className={mergeClasses(styles.grid, Boolean(className) && className)}>{children}</div>
    );
}

export default GridContainer;
