export default function tableProps({...props}) {
    const hasDataRows = function () {
        const bodyItem = fetchSectionFromProps('tbody');
        return bodyItem?.props?.children?.length > 0 ?? false;
    };

    const fetchSectionFromProps = function (sectionName: string) {
        const section = props.children.filter((x: any) => x.type === sectionName)[0];
        return section;
    };

    return {
        fetchSectionFromProps,
        hasDataRows,
    };
}
