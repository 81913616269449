import React, {useMemo} from 'react';
import {Text} from '@fluentui/react-components';
import {DateText} from '@/components/ui';
import useClasses from './EvaluationMetadata.styles';
import {useAppState} from '@/api/app';
import {EvaluationState} from '@/api/evaluations';
import formatTime from '@/util/timeFormatterUtil';

function EvaluationMetadata({evaluation}: any) {
    const classes = useClasses();
    const {debugLevel} = useAppState();

    const elapsedTime = useMemo<string | null>(() => {
        // Only show the elapsed time if the interaction has completed.
        if (evaluation?.state === EvaluationState.Completed) {
            const elapsed =
                (new Date(evaluation.completedAt).getTime() -
                    new Date(evaluation.createdAt).getTime()) /
                1000;

            return `${formatTime(elapsed.toFixed(0))}`;
        }

        return null;
    }, [evaluation, debugLevel]);
    return (
        <div className={classes.captionMetadata}>
            <DateText className={classes.captionMetadata} date={new Date(evaluation.createdAt)} />
            {/* {elapsedTime && (
                <>
                    &ensp;|&ensp;
                    <Text className={classes.captionMetadata}>{elapsedTime}</Text>
                </>
            )} */}
        </div>
    );
}

export default EvaluationMetadata;
