import {useState, useEffect, useMemo} from 'react';
import {
    useGetPinboardSummary,
    useSummarizePins,
    useUpdatePinboardSummary,
    PinnedSummaryResponse,
    Session,
    SessionPinboardModel,
} from '@/api/sessions';
import MedeinaFeatures from '@/util/features';
import {PageResponse} from '../api';
import {PinnedEvaluation} from '../../api/evaluations/evaluations.types';

/**
 * This files takes care of events around sumarrization and pinning/unpinning
 * If the returned pinned summary when there are pinned items is empty it calls summarization to generate the summary and then saves the summary.
 * If the returned pinned summary when there are pinned items is non empty , it renders the retrieved summary
 * When there is any change in the pinned items, it makes a summarization call and then saves the summary
 * @param sessionId
 * @param pinnedItems
 * @param pinnedItemsLength
 * @param sessionData
 * @returns PinnedSummaryResponse
 */
export default function useSessionPinboard({
    sessionId,
    pinnedItems,
    pinnedItemsLength,
    sessionData,
}: SessionPinboardModel) {
    const [loading, setLoading] = useState<boolean>(true);
    const [isFirstLanding, setIsFirstLanding] = useState(true);
    const [pinboardResponse, setPinboardResponse] = useState<PinnedSummaryResponse>();

    const {
        data: getSummaryResponse,
        isLoading: isSummarizing,
        isSuccess: isSummarySuccess,
        isError: getSummaryError,
    } = useGetPinboardSummary({sessionId, pinnedItems, sessionData});

    const {
        data: summarizeResponse,
        refetch: refetchSummarize,
        isLoading: isRefreshing,
        isRefetching: summarizeResponseLoading,
        isSuccess: isSummarizeSuccess,
        isError: summarizePinsError,
        isInitialLoading,
    } = useSummarizePins({sessionId, pinnedItems, sessionData});

    const {
        mutate: updatePinboard,
        isLoading: isLoadingSuggestions,
        isSuccess,
        isError: updateSummaryError,
    } = useUpdatePinboardSummary();

    // when pinnedItems change we need to resummarize our investigation
    useEffect(() => {
        if (pinnedItemsLength == -1 || !MedeinaFeatures.SavePinboardSummary) {
            return;
        }
        // there needs to be pinned items
        // summarization shouldn't happen when the page first loads, only after
        if (pinnedItemsLength && !isFirstLanding) {
            refetchSummarize();
        }
        //whenever the last pinned item is deleted, the summary is cleared from cosmos db
        if (!pinnedItemsLength && !isFirstLanding) {
            handleUpdatePinboard({
                summary: null,
                title: null,
                tags: null,
            });
        }
    }, [pinnedItems]);

    useEffect(() => {
        if (isSummarySuccess) {
            setIsFirstLanding(false);
        }
        if (isSummarySuccess && getSummaryResponse?.pinboardSummary?.summary != null) {
            setPinboardResponse(getSummaryResponse?.pinboardSummary);
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [isSummarySuccess, getSummaryResponse]);

    const handleUpdatePinboard = (pinboardSummary: PinnedSummaryResponse | null) => {
        const updatedSession: Session = {
            ...sessionData,
            sessionId: sessionData?.sessionId || '',
            name: sessionData?.name || '',
            pinboardSummary: pinboardSummary || null,
            sessionSummary: sessionData?.sessionSummary || null,
            createdAt: sessionData?.createdAt || '',
            updatedAt: sessionData?.updatedAt || '',
        };
        updatePinboard({
            session: updatedSession,
            pinnedItems: pinnedItems,
        });
    };

    return {
        pinboardResponse,
        loading: loading || isSummarizing || summarizeResponseLoading || isInitialLoading,
        error: getSummaryError || summarizePinsError || updateSummaryError,
    };
}
