import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {GetRoleByNameResponse} from '.';
import {customFetch} from '../api';
import {useTranslation} from 'react-i18next';
import {EVERYONE_IDENTIFIER, GDAP_SEARCH_IDENTIFIER, GDAP_IDENTIFIER, GDAP} from './rbac.constants';

export default function useGetRoleMemberByName(
    name: string,
    useQueryOptions?: Pick<UseQueryOptions<GetRoleByNameResponse>, 'enabled'>,
) {
    const url = `${MedeinaVariables.ApiUri}/graphData?search=${name}`;
    const {t} = useTranslation('admin');

    return useQuery<GetRoleByNameResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['rbac', name],
        queryFn: async () => {
            // If the user is searching for all members, we need to return an `Everyone` object
            if (name === EVERYONE_IDENTIFIER) {
                return {
                    details: {
                        User: [
                            {
                                id: EVERYONE_IDENTIFIER,
                                displayName: t('RoleAssignment.Everyone'),
                                mail: t('RoleAssignment.EveryoneInfo'),
                            },
                        ],
                        Group: [],
                        Role: [],
                    },
                };
            }
            // If the user is searching for gdap member, we need to return the GDAP object
            if (name === GDAP_SEARCH_IDENTIFIER) {
                return {
                    details: {
                        Role: [
                            {
                                id: GDAP_IDENTIFIER,
                                displayName: GDAP,
                            },
                        ],
                        Group: [],
                        User: [],
                    },
                };
            }

            return await customFetch<GetRoleByNameResponse>(url, {
                method: 'GET',
            });
        },
        staleTime: 0,
        ...useQueryOptions,
    });
}
