import {useUpdateWorkspacePolicyById} from '@/api/rbac';
import {PolicyWorkspace, RbacErrorResponse} from '@/api/rbac/rbac.types';
import {usePolicyManager} from '@/components/admin/rbac';
import {DismissIcon, PersonIcon} from '@/components/ui/icons';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    MenuItem,
    MenuList,
    MessageBarIntent,
    Spinner,
    mergeClasses,
    Text,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RoleObjectIdData} from '../rbac.types';
import useClasses from './AddRoles.styles';
import AddRolesConfirmation from './AddRolesConfirmation';
import AddRolesErrorMessage from './AddRolesErrorMessage';
import {AddRolesMessages, useAddRolesErrorHanding} from './useAddRolesErrorHanding';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {ParagraphContent} from '@/components/ui/Text';

interface AddRolesInfoProps {
    isAddRolesOpen: boolean;
    existingMembers?: RoleObjectIdData;
    onAddRolesClose?: () => void;
    viewOnly?: boolean;
    workspacePolicy?: PolicyWorkspace;
}

export type ErrorMessageBarProps = {
    title: string;
    message: string;
    messageType: MessageBarIntent;
};

export default function AddRoles(props: AddRolesInfoProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [error, setError] = useState<AddRolesMessages>();
    const [hasError, setHasError] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number | null>(null);

    const entraRolesObj = t('RoleAssignment.AddRoles.RoleTypes.EntraRoles.Roles', {
        returnObjects: true,
    }) as {[key: string]: string};

    const entraRoles: string[] = Object.values(entraRolesObj);

    const purviewRolesObj = t('RoleAssignment.AddRoles.RoleTypes.PurviewRoles.Roles', {
        returnObjects: true,
    }) as {[key: string]: string};

    const purviewRoles: string[] = Object.values(purviewRolesObj);

    const closeAddRolesDialog = () => {
        props?.onAddRolesClose?.();
        setSelectedItem(null);
    };

    useEffect(() => {
        setError(AddRolesMessages.NO_MESSAGE);
        setHasError(false);
    }, []);

    const {mutateAsync: updateWorkspacePolicy, isLoading: updatePolicyLoading} =
        useUpdateWorkspacePolicyById();

    const {workspaceName} = useWorkspaceState();

    const policyReferenceName = props?.workspacePolicy?.properties?.entity?.referenceName;

    const {togglePartnerRolesCondition} = usePolicyManager({
        workspacePolicy: props?.workspacePolicy as PolicyWorkspace,
        workspaceName: policyReferenceName as string,
    });

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const {errorMessage, generateErrorMessage} = useAddRolesErrorHanding(error as AddRolesMessages);

    const addRoles = async () => {
        const updatedPolicy = togglePartnerRolesCondition();

        if (!!updatedPolicy) {
            try {
                await updateWorkspacePolicy({
                    workspaceName: workspaceName as string,
                    policy: updatedPolicy,
                });
                closeAddRolesDialog();
                setConfirmDialogOpen(true);
            } catch (error) {
                const errorData = error as RbacErrorResponse;

                generateErrorMessage(AddRolesMessages.UPDATE_ERROR, {
                    message: errorData.Error.Message,
                });
            }
        }
    };

    const handleHeaderClick = (itemNumber: number) => {
        setSelectedItem(selectedItem === itemNumber ? null : itemNumber);
    };

    return (
        <>
            <Dialog open={props.isAddRolesOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>
                            <div className={classes.titleWrapper}>
                                <span className={classes.title}>
                                    {props.viewOnly
                                        ? t('RoleAssignment.AddRoles.ViewOnlyTitle')
                                        : t('RoleAssignment.AddRoles.Title')}
                                </span>
                                <Button
                                    data-test-id="close-add-roles-dialog-button"
                                    appearance="transparent"
                                    aria-label={t('RoleAssignment.AddRoles.AriaLabels.DialogClose')}
                                    className={classes.closeButton}
                                    onClick={closeAddRolesDialog}
                                    icon={<DismissIcon />}
                                />
                            </div>
                        </DialogTitle>
                        <DialogContent
                            className={mergeClasses(
                                classes.contentSection,
                                scrollClasses.colorNeutralBackground1,
                            )}
                        >
                            <div className={classes.dialogInfo}>
                                {t('RoleAssignment.AddRoles.Info')}{' '}
                                <a
                                    href="https://learn.microsoft.com/copilot/security/authentication"
                                    className={classes.textLink}
                                >
                                    {t('RoleAssignment.AddRoles.DocumentationLink')}
                                </a>
                            </div>

                            <AddRolesErrorMessage error={errorMessage as ErrorMessageBarProps} />

                            <Accordion collapsible>
                                <AccordionItem
                                    onClick={() => handleHeaderClick(1)}
                                    value="1"
                                    className={mergeClasses(
                                        classes.accordianItem,
                                        selectedItem === 1 && classes.selectedPanel,
                                    )}
                                >
                                    <AccordionHeader>
                                        <div className={classes.rolesTypeContainer}>
                                            <Text className={classes.rolesTypeHeader}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.EntraRoles.Header',
                                                )}
                                            </Text>
                                            <Text className={classes.rolesTypeDescription}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.EntraRoles.Description',
                                                )}
                                            </Text>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionPanel>
                                        <div className={classes.rolesList}>
                                            <MenuList>
                                                {entraRoles.map((role, index) => (
                                                    <MenuItem
                                                        className={mergeClasses(
                                                            classes.customMenuItemWidth,
                                                            classes.menuItemHover,
                                                        )}
                                                        key={index}
                                                    >
                                                        <div className={classes.personIcon}>
                                                            <PersonIcon />
                                                        </div>
                                                        <span className={classes.roleName}>
                                                            {role}
                                                        </span>
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem
                                    value="2"
                                    onClick={() => handleHeaderClick(2)}
                                    className={mergeClasses(
                                        classes.accordianItem,
                                        selectedItem === 2 && classes.selectedPanel,
                                    )}
                                >
                                    <AccordionHeader size="extra-large">
                                        <div className={classes.rolesTypeContainer}>
                                            <Text className={classes.rolesTypeHeader}>
                                                {' '}
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.PurviewRoles.Header',
                                                )}
                                            </Text>
                                            <Text className={classes.rolesTypeDescription}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.PurviewRoles.Description',
                                                )}
                                            </Text>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionPanel>
                                        <div className={classes.rolesList}>
                                            <MenuList>
                                                {purviewRoles.map((role, index) => (
                                                    <MenuItem
                                                        className={mergeClasses(
                                                            classes.customMenuItemWidth,
                                                            classes.menuItemHover,
                                                        )}
                                                        key={index}
                                                    >
                                                        <div className={classes.personIcon}>
                                                            <PersonIcon />
                                                        </div>
                                                        <span className={classes.roleName}>
                                                            {role}
                                                        </span>
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem
                                    value="3"
                                    onClick={() => handleHeaderClick(3)}
                                    className={mergeClasses(
                                        classes.accordianItem,
                                        selectedItem === 3 && classes.selectedPanel,
                                    )}
                                >
                                    <AccordionHeader size="extra-large">
                                        <div className={classes.rolesTypeContainer}>
                                            <Text className={classes.rolesTypeHeader}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.UsxRoles.Header',
                                                )}
                                            </Text>
                                            <Text className={classes.rolesTypeDescription}>
                                                {t(
                                                    'RoleAssignment.AddRoles.RoleTypes.UsxRoles.Description',
                                                )}
                                            </Text>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionPanel>
                                        <div className={classes.rolesList}>
                                            {t(
                                                'RoleAssignment.AddRoles.RoleTypes.UsxRoles.Roles.CustomRolesLabel',
                                            )}
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            {!props.viewOnly ? (
                                <Button
                                    appearance="primary"
                                    onClick={addRoles}
                                    disabled={updatePolicyLoading}
                                    aria-label={t(
                                        'RoleAssignment.AddRoles.AriaLabels.AddAllRolesButton',
                                    )}
                                >
                                    {updatePolicyLoading ? (
                                        <Spinner size="tiny"></Spinner>
                                    ) : (
                                        commonLabel('ButtonLabels.Add')
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    appearance="primary"
                                    onClick={closeAddRolesDialog}
                                    disabled={updatePolicyLoading}
                                    aria-label={t('RoleAssignment.AddRoles.AriaLabels.DoneButton')}
                                >
                                    {t('RoleAssignment.AddRoles.DoneButton')}
                                </Button>
                            )}
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <AddRolesConfirmation
                isConfirmDialogOpen={isConfirmDialogOpen}
                onConfirmDialogClose={handleConfirmDialogClose}
            ></AddRolesConfirmation>
        </>
    );
}
