export default function redactSharedToken(url: string): string {
    // Regular expression to find the 'st' query parameter and its value
    const tokenRegex = /(\?|&)st=[^&]+/;

    // Check if the URL contains the 'st' parameter and replace its value with 'REDACTED_SHARE_TOKEN'
    if (tokenRegex.test(url)) {
        url = url.replace(tokenRegex, '$1st=REDACTED_SHARE_TOKEN');
    }

    return url;
}
