import baseStyle from '@/components/theme/custom/overrides';
import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground2,
        width: '100%',
        fontSize: baseStyle.fontSizeBase300,
        textAlign: 'left',
    },
});
