import type {
    BackgroundAnimation,
    BackgroundImage,
} from '@/components/ui/Background/Background.types';

export interface SidePanelState {
    open: boolean;
    width: number;
}

export interface SidePanel extends SidePanelState {
    toggleOpen?: () => void;
    toggleClosed?: () => void;
    toggle?: () => void;
    scroll?: Scrollable;
    setWidth?: (width: number) => void;
    ref?: React.RefObject<any>;
    setRef?: (ref: React.RefObject<any>) => void;
}

export interface Scrollable {
    toTop?: () => void;
    toBottom?: () => void;
    to?: (id: string | number) => void;
}

export interface ContentAreaState {
    fullWidth?: boolean;
}
export interface ContentArea extends ContentAreaState {
    setFullWidth?: (fullWidth: boolean) => void;
    scroll?: Scrollable;
    ref?: React.RefObject<any>;
    setRef?: (ref: React.RefObject<any>) => void;
}

export interface Background {
    image?: BackgroundImage;
    animation?: BackgroundAnimation;
    setImage?: (image: BackgroundImage) => void;
    setAnimation?: (animation: BackgroundAnimation) => void;
}

export interface LayoutState {
    sidePanel: SidePanelState;
    contentArea: ContentAreaState;
    background?: Background;
}

export interface Layout {
    sidePanel: SidePanel;
    contentArea: ContentArea;
    background?: Background;
}

export enum LayoutArea {
    contentArea = 'contentArea',
    sidePanel = 'sidePanel',
    background = 'background',
}

export interface useLayoutRefProps {
    area: LayoutArea;
}

export interface useLayoutScrollProps {
    /** a reference to the layout component that will be scrolled */
    ref: React.RefObject<any>;
}
