import {
    Button,
    Dialog,
    DialogTrigger,
    DialogBody,
    DialogSurface,
    MenuItem,
    mergeClasses,
} from '@fluentui/react-components';
import * as React from 'react';
import {ConnectIcon, SkillsetIcon} from '@/components/ui/icons';
import useClasses from './ConnectorModal.styles';
import {ConnectorModalProps} from './ConnectorModal.types';
import AdminConfigurationModalContent from './AdminConfigurationModalContent';
import ExternalConfigurationModalContent from './ExternalConfigurationModalContent';
import {
    SkillsetRequirementsCheckResult,
    SkillsetDescriptor,
    SettingsScope,
    useCheckSkillsetRequirements,
    catalogScopeToSettingsScope,
} from '@/api/skills';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import {useTourable} from '../Tour/useTourable';
import {AlignEdge, useTourContext, TourPopovers, TourPopoverSurface} from '../Tour';
import Tooltip from '../Tour/Tooltip';
import {MenuType} from '@/components/HeaderMenu.types';
import {useTranslation} from 'react-i18next';
import {getCachedUrlParamFlags} from '@/util/hasStaticFeatureFlags';
import useConnectorModalContext from './Context/useConnectorModalContext';
import {ManageSourcesView} from '@/components/sections/sources';
import useDialogClasses from '@/components/ui/Dialog/Dialog.styles';

// Connector/Connections is now known as Plugins
export default function ConnectorModal({
    defaultSelectedSkillsets,
    onChangeSkillsets,
    menuType = MenuType.Standalone,
    open,
    onClose,
}: ConnectorModalProps) {
    const classes = useClasses();
    const dialogClasses = useDialogClasses();
    const {mutate: trackEvent} = useTrackEvent();
    const {t} = useTranslation('plugins');
    const tourRef = useTourable({id: 'connectorModal'});
    const {activeTour, currentStop, showExitOverlay} = useTourContext();
    const closeButtonRef = React.useRef(null);
    const {resetConnectorContext} = useConnectorModalContext();

    React.useEffect(() => {
        // If the exit overlay is closed and we are returning to a modal popover stop, open the connector modal
        if (
            !showExitOverlay &&
            activeTour &&
            (currentStop?.name === 'openModal' ||
                currentStop?.name === 'addPlugin' ||
                currentStop?.name === 'setUpPlugin')
        ) {
            setDialogOpen(true);
        }
        // If the exit overlay is open or we are navigating to a stop without the modal, close the connector modal
        else if (
            showExitOverlay ||
            (activeTour && (currentStop?.name === 'home' || currentStop?.name === 'supportMenu'))
        ) {
            setDialogOpen(false);
        }
    }, [activeTour, currentStop, showExitOverlay]);

    // Determine if the modal is open or not
    const [dialogOpen, setDialogOpen] = React.useState(false);
    // Whether the open modal button is hovered or not
    const [hovered, setHovered] = React.useState(false);
    // Determines if the admin modal should be opened, and if so, the selected skillset to configure
    const [adminDialogOpen, setAdminDialogOpen] = React.useState('');
    // Determines if the modal to configure external connectors such as plugins should be opened
    const [externalDialogOpen, setExternalDialogOpen] = React.useState('');
    // Determines the skillset that should be edited, if any, when the external connectors dialog is opened
    const [externalSkillset, setExternalSkillset] = React.useState<SkillsetDescriptor | null>(null);
    // Determines the skillset that should be edited, if any, when the admin connectors dialog is opened
    const [adminSkillset, setAdminSkillset] = React.useState<SkillsetDescriptor | null>(null);
    // The selected skillset to configure
    const [selectedSkillset, setSelectedSkillset] = React.useState('');

    const [checkRequirementSkillsetDescriptor, setCheckRequirementsSkillsetDescriptor] =
        React.useState<SkillsetDescriptor | null>(null);
    // The default list of skillsets that are already selected
    const [defaultSkillsets, setDefaultSkillsets] = React.useState<string[]>(
        defaultSelectedSkillsets ?? [],
    );
    // The list of errors from checkRequirements for the selected skillset
    const [selectedSkillsetErrors, setSelectedSkillsetErrors] =
        React.useState<SkillsetRequirementsCheckResult | null>();
    const [scope, setScope] = React.useState<SettingsScope>(SettingsScope.Tenant);
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const [isSettingUpPlugin, setIsSettingUpPlugin] = React.useState<boolean>(false);
    const [getChecklistRequirementSkillsetName, setGetChecklistRequirementSkillsetName] =
        React.useState<string>('');

    const [canHighlightAddPlugin, setCanHighlightAddPlugin] = React.useState<boolean>(false);

    const successCallback = (skillsetRequirementResponse: SkillsetRequirementsCheckResult) => {
        if (
            skillsetRequirementResponse !== undefined &&
            skillsetRequirementResponse.skillsetName !== '' &&
            skillsetRequirementResponse.skillsetName === getChecklistRequirementSkillsetName &&
            skillsetRequirementResponse.errors.length > 0
        ) {
            setIsSettingUpPlugin(true);
            openAdminDialog(
                getChecklistRequirementSkillsetName,
                null,
                catalogScopeToSettingsScope(checkRequirementSkillsetDescriptor?.catalogScope),
                checkRequirementSkillsetDescriptor,
            );
        } else if (checkRequirementSkillsetDescriptor) {
            openConnectorModal(getChecklistRequirementSkillsetName);
        } else if (!checkRequirementSkillsetDescriptor) {
            openConnectorModal('');
        }
    };

    const {refetch: getSkillsetRequirements} = useCheckSkillsetRequirements(
        getChecklistRequirementSkillsetName,
        successCallback,
    );

    // Toggle the hover state of the open modal button to show a connected plug when hovered
    const toggleHovered = (hover: boolean) => {
        setHovered(hover);
    };

    const getOverrideScope = (assignedScope: SettingsScope | null) => {
        const flags = getCachedUrlParamFlags();
        if (flags?.includes('ForceUserScope')) {
            return SettingsScope.User;
        } else if (flags?.includes('ForceTenantScope')) {
            return SettingsScope.Tenant;
        } else if (assignedScope) {
            return assignedScope;
        } else {
            return SettingsScope.User;
        }
    };

    React.useEffect(() => {
        // If opening the dialog or returning to connector modal from a different modal,
        // focus the close button at the top of the modal.
        if (externalDialogOpen === '' && adminDialogOpen === '' && closeButtonRef.current) {
            (closeButtonRef.current as any).focus();
        }
    }, [externalDialogOpen, adminDialogOpen]);

    // When mounted via the promptbar, the promptbar controls whether the dialog is open.
    React.useEffect(() => {
        if (menuType === MenuType.PromptBar && typeof open === 'boolean') {
            setDialogOpen(open);
        }
    }, [menuType, open]);

    const openAdminDialog = (
        skillsetName: string,
        errors: SkillsetRequirementsCheckResult | null,
        assignedScope: SettingsScope | null,
        skillsetDescriptor: SkillsetDescriptor | null,
    ) => {
        setCheckRequirementsSkillsetDescriptor(null);
        setGetChecklistRequirementSkillsetName('');
        setScope(getOverrideScope(assignedScope));
        setAdminDialogOpen(skillsetName);
        setSelectedSkillsetErrors(errors);
        setAdminSkillset(skillsetDescriptor);
    };

    const openExternalDialog = (
        categoryType: string,
        skillsetDescriptor: SkillsetDescriptor | null,
        showDelete: boolean,
    ) => {
        setAdminDialogOpen('');
        setExternalDialogOpen(categoryType);
        setExternalSkillset(skillsetDescriptor);
        setShowDelete(showDelete);
    };

    const handleSkillsetChange = (skillsets: string[]) => {
        onChangeSkillsets?.(skillsets);
        setDefaultSkillsets(skillsets);
    };

    // Default to the skillset connector view when the dialog is closed and re-opened
    const handleOpenChange = (open: boolean) => {
        onClose?.();
        setDialogOpen(open || false);
        setAdminDialogOpen('');
        setExternalDialogOpen('');
        setSelectedSkillset('');
        setExternalSkillset(null);
        setIsSettingUpPlugin(false);
        resetConnectorContext();
    };

    const openConnectorModal = (skillset: string) => {
        setCheckRequirementsSkillsetDescriptor(null);
        setGetChecklistRequirementSkillsetName('');
        setSelectedSkillset(skillset);
        setDialogOpen(true);
        setAdminDialogOpen('');
        setExternalDialogOpen('');
        setExternalSkillset(null);
        setIsSettingUpPlugin(false);
    };

    const handleExternalModalReturn = async (descriptor: SkillsetDescriptor | null) => {
        // Immediately open the settings dialog if the skillset can be configured
        setCanHighlightAddPlugin(true);
        setGetChecklistRequirementSkillsetName(descriptor?.name ?? '');
        setCheckRequirementsSkillsetDescriptor(descriptor);
        getSkillsetRequirements();
    };

    const handleDeleteSkillsetReturn = async (descriptor: SkillsetDescriptor | null) => {
        // Immediately open the settings dialog if the skillset can be configured
        if (descriptor) {
            openConnectorModal(descriptor.name ?? '');
        } else if (!descriptor) {
            openConnectorModal('');
        }
        setCanHighlightAddPlugin(true);
    };

    // Add recently configured skillset to lists of configured and selected skillsets
    const setEnabled = (skillset: string) => {
        setSelectedSkillset(skillset);
    };

    const isAdminDialogOpen = adminDialogOpen !== '';
    const isExternalDialogOpen = externalDialogOpen !== '';
    const isSourcesDialogOpen = !isAdminDialogOpen && !isExternalDialogOpen;
    function ConnectorModalDialogBody() {
        if (isAdminDialogOpen) {
            return (
                <AdminConfigurationModalContent
                    selectedSkillset={adminDialogOpen}
                    scope={scope}
                    onSkillsetEnabled={() => setEnabled(adminDialogOpen)}
                    skillsetDescriptor={adminSkillset}
                    onReturn={() => openConnectorModal('')}
                    onEnableReturn={() => openConnectorModal(adminDialogOpen)}
                    requirementsCheck={selectedSkillsetErrors ?? null}
                    isSettingUpPlugin={isSettingUpPlugin}
                    onOpenExternalConnectorView={(categoryType, skillsetDescriptor, showDelete) =>
                        openExternalDialog(categoryType, skillsetDescriptor, showDelete)
                    }
                    onClose={() => handleOpenChange(false)}
                />
            );
        } else if (isExternalDialogOpen) {
            return (
                <ExternalConfigurationModalContent
                    onSuccessReturn={(descriptor) => handleExternalModalReturn(descriptor)}
                    onDeleteReturn={(descriptor) => handleDeleteSkillsetReturn(descriptor)}
                    selectedConnector={externalSkillset}
                    showDeletePanel={showDelete}
                    onClose={() => handleOpenChange(false)}
                />
            );
        } else {
            return (
                <ManageSourcesView
                    defaultSelectedSkillsets={defaultSkillsets}
                    onChangeSkillsets={(skillsets) => handleSkillsetChange(skillsets)}
                    onOpenAdminView={openAdminDialog}
                    onOpenExternalConnectorView={(categoryType, skillsetDescriptor, showDelete) =>
                        openExternalDialog(categoryType, skillsetDescriptor, showDelete)
                    }
                    skillsetToEnable={selectedSkillset}
                    canHighlightAddPlugin={canHighlightAddPlugin}
                    onClose={() => handleOpenChange(false)}
                />
            );
        }
    }

    return (
        <>
            <Dialog
                modalType="alert"
                open={dialogOpen}
                onOpenChange={(e, data) => {
                    handleOpenChange(data.open);
                }}
            >
                {menuType !== MenuType.PromptBar ? (
                    <DialogTrigger disableButtonEnhancement>
                        {menuType === MenuType.Header ? (
                            <MenuItem
                                ref={tourRef}
                                onMouseEnter={() => toggleHovered(true)}
                                onMouseLeave={() => toggleHovered(false)}
                                aria-label={t('OpenPlugins')}
                                onClick={() =>
                                    trackEvent({
                                        name: MedeinaTelemetryEvent.Skills.OpenSkillsetConnector,
                                        eventType: MedeinaEvent.ActionEvent,
                                    })
                                }
                                icon={<ConnectIcon />}
                            >
                                {t('ManagePlugins')}
                            </MenuItem>
                        ) : (
                            <Button
                                ref={tourRef}
                                className={classes.toggleButton}
                                appearance="subtle"
                                size="medium"
                                onMouseEnter={() => toggleHovered(true)}
                                onMouseLeave={() => toggleHovered(false)}
                                aria-label={t('OpenPlugins')}
                                onClick={() =>
                                    trackEvent({
                                        name: MedeinaTelemetryEvent.Skills.OpenSkillsetConnector,
                                        eventType: MedeinaEvent.ActionEvent,
                                    })
                                }
                                icon={<SkillsetIcon />}
                            />
                        )}
                    </DialogTrigger>
                ) : (
                    <></>
                )}
                <DialogSurface
                    className={mergeClasses(classes.tabListStyles, dialogClasses.dialogSurface)}
                    data-testid="sources-dialog"
                >
                    {isSourcesDialogOpen ? (
                        ConnectorModalDialogBody()
                    ) : (
                        <DialogBody className={classes.body}>
                            {ConnectorModalDialogBody()}
                        </DialogBody>
                    )}
                </DialogSurface>
            </Dialog>
        </>
    );
}
