import {NavigationBarHeight} from '@/components/App.styles';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalM,
        overflowY: 'auto',
        maxWidth: '700px',
        height: `calc(100% - ${NavigationBarHeight * 2}px )`,
    },
    flexContainerRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        columnGap: tokens.spacingHorizontalM,
        paddingBottom: tokens.spacingVerticalM,
        ...shorthands.padding('14px', tokens.spacingHorizontalXXXL),
        paddingTop: tokens.spacingVerticalS,
        gridRowGap: tokens.spacingHorizontalXL,
    },
    promptList: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        columnGap: tokens.spacingHorizontalM,
        gridRowGap: tokens.spacingHorizontalXL,
    },
    inputFields: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: tokens.spacingHorizontalXL,
    },
    textareaWrapper: {
        ...shorthands.flex(1, 1, 'auto'),
        width: 'max-content',
        minWidth: '100%',
    },
    cancelButton: {
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
    },
    createButton: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
        backgroundColor: tokens.colorPaletteLightTealBackground2,
    },
    centerContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    fullSize: {
        height: '100%',
    },
    warning: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackgroundAlpha,
    },
    iconContainer: {
        marginRight: '8px',
    },
    editActionButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    editLeftButtons: {
        display: 'flex',
    },
    editRightButtons: {
        display: 'flex',
        marginLeft: 'auto',
    },
});
