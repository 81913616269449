import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    caption: {
        color: tokens.colorNeutralForeground2,
        fontWeight: tokens.fontWeightSemibold,
    },
    captionMetadata: {
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase200,
    },
});
