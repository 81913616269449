import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export const useClasses = makeStyles({
    acrylicBackground: {
        position: 'absolute',
        zIndex: -2,
    },
    acrylicForeground: {
        position: 'absolute',
        backgroundColor: tokens.colorNeutralBackground6,
        opacity: 0.5,
        backdropFilter: 'blur(30px)',
        WebkitBackdropFilter: 'blur(30px)',
        zIndex: -1,
    },
    fluidWrapper: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        zIndex: 0,
    },

    // shadow options
    baseShadow: {
        boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.27), 0px 2px 21px rgba(0, 0, 0, 0.27)',
    },
    xSmallShadow: {
        boxShadow: tokens.shadow2,
    },
    smallShadow: {
        boxShadow: tokens.shadow4,
    },
    mediumShadow: {
        boxShadow: tokens.shadow16,
    },
    largeShadow: {
        boxShadow: tokens.shadow28,
    },
    noShadow: {
        boxShadow: 'none',
    },

    // offset options
    offsetNone: {
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
    },
    offsetSmall: {
        top: '-2px',
        left: '-2px',
        right: '-2px',
        bottom: '-2px',
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    },
    offsetMedium: {
        top: '-4px',
        left: '-4px',
        right: '-4px',
        bottom: '-4px',
        ...shorthands.borderRadius('10px'),
    },
    offsetLarge: {
        top: '-8px',
        left: '-8px',
        right: '-8px',
        bottom: '-8px',
        ...shorthands.borderRadius('12px'),
    },
});
