import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Prompt, RerunPromptsRequest} from '../prompts';
import {Evaluation, useCreateAndWaitForEvaluation} from '../evaluations';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
export default function useRerunPrompts() {
    // We need to `await` the mutation, so use `mutateAsync`.
    const {mutateAsync: createAndWaitForEvaluation} = useCreateAndWaitForEvaluation();
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async ({sessionId, promptIds}: RerunPromptsRequest) => {
            let prompt: Prompt;

            // Temporarily flag each prompt as "queued" to show a spinner in the UI.
            // TODO: Come up with a better way of handling this.
            promptIds.forEach((promptId) => {
                queryClient.setQueriesData(
                    ['sessions', sessionId, 'prompts', promptId],
                    // @ts-ignore
                    (oldData) => ({
                        // @ts-ignore
                        ...oldData,
                        __isQueued: true,
                    }),
                );
            });

            // Iterate through the following prompts in sequence.
            for (const promptId of promptIds) {
                // Wait for each evaluation to complete before continuing with the next one.
                // This is necessary because only the context that exists when the evaluation is created will be used.

                // Grab the prompt so that we can grab the latest evaluation.
                prompt = await customFetch<Prompt>(`/sessions/${sessionId}/prompts/${promptId}`, {
                    method: 'GET',
                });

                // Grab the existing evaluation.
                await createAndWaitForEvaluation({
                    sessionId,
                    promptId: prompt.promptId,
                });
            }
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
    });
}
