import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    titleHeader: {
        width: '100%',
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(tokens.spacingVerticalXS, '0'),
        fontFamily: tokens.fontFamilyBase,
        '& input, & div': {
            fontFamily: 'inherit',
        },
    },
    hover: {
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground5,
        },
    },
    edited: {
        visibility: 'visible',
        '&:hover > *': {
            visibility: 'visible',
        },
        backgroundColor: tokens.colorNeutralBackground5,
    },
    toolbar: {
        ...shorthands.padding(tokens.spacingVerticalNone),
        maxHeight: '32px',
        marginRight: '8px',
    },
    input: {
        backgroundColor: 'transparent',
        flexGrow: 1,
        fontSize: tokens.fontSizeBase500,
        color: tokens.colorNeutralForeground1,
        ...shorthands.border('0px', 'none', 'transparent'),
        ...shorthands.outline('0px'),
        ...shorthands.padding(tokens.spacingHorizontalNone, tokens.spacingHorizontalNone),
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'row',
    },
    investigationTitle: {
        ...shorthands.margin(0),
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightRegular,
        wordBreak: 'break-word',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: '-webkit-box',
        paddingLeft: tokens.spacingHorizontalM,
    },
});
