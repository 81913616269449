import React, {useEffect, useState, useRef, useCallback} from 'react';
import {TourStop, TourStopState} from '@/components/ui/Tour';

interface useTourStopStateProps {
    stopIndex: number;
    tourStops?: TourStop[];
    activeTour: boolean;
}

interface TourStopStateResponse {
    stopState: TourStopState;
    updateTourState: (newStop: TourStop | null, newState: TourStopState) => void;
    currentStop: TourStop | null;
}

// Define a combined state type
type useTourState = {
    currentStop: TourStop | null;
    stopState: TourStopState;
};

function useTourStopState(props: useTourStopStateProps): TourStopStateResponse {
    const {stopIndex, tourStops, activeTour} = props;
    // Initialize combined state
    const [tourState, setTourState] = useState<useTourState>({
        currentStop: null,
        stopState: TourStopState.empty,
    });
    const {currentStop, stopState} = tourState;

    // Update both currentStop and stopState together
    const updateTourState = useCallback((newStop: TourStop | null, newState: TourStopState) => {
        setTourState({currentStop: newStop, stopState: newState});
    }, []);

    const previousStopIndex = useRef<number | null>(null);

    useEffect(() => {
        // If the tour is not active, reset the state
        const nextStop = tourStops?.[stopIndex] ?? null;
        // there's nothing to load set the state to empty
        if (!activeTour || tourStops === undefined) {
            updateTourState(null, TourStopState.empty);
            return;
        }

        const hasTeardown = Boolean(currentStop?.teardown);
        const hasSetup = Boolean(nextStop?.setup);

        // Handle teardown of the current stop
        if (hasTeardown && previousStopIndex.current !== stopIndex) {
            // Handle teardown
            updateTourState(currentStop, TourStopState.teardown);
        } else if (hasSetup && previousStopIndex.current !== stopIndex) {
            // Handle setup
            updateTourState(nextStop, TourStopState.setup);
        } else if (!hasTeardown && previousStopIndex.current !== stopIndex) {
            // Move to the next stop without teardown
            updateTourState(nextStop, TourStopState.playing);
        }

        previousStopIndex.current = stopIndex; // Update the previous stop index
        // turn off the exhaustive-deps rule we only want to run this effect when the stopIndex changes or a new tour is started
        // eslint-disable-next-line
    }, [stopIndex, activeTour]);

    // we want to run the setup code when the tour state changes to TourStopState.setup
    useEffect(() => {
        const currentStopName = currentStop?.name || '';
        if (stopState === TourStopState.setup) {
            // Run setup code here
            currentStop?.setup?.(currentStopName, () => {
                // After the setup is complete, update the state to playing
                updateTourState(currentStop, TourStopState.playing);
            });
        }
        // we want to run the teardown code when the tour state changes to TourStopState.teardown
        if (stopState === TourStopState.teardown) {
            // Run teardown code here
            const nextStop = tourStops?.[stopIndex];
            const nextStopName = nextStop?.name || '';
            currentStop?.teardown?.(currentStopName, nextStopName, () => {
                // After the teardown is complete, update the state to completed
                updateTourState(currentStop, TourStopState.completed);
            });
        }
        // completed condition only happens when the stop has a teardown and the teardown has completed
        // during this time the rendered stop has not changed even though the user may have indicated to move to the next step
        // this is to ensure that the teardown code has completed before moving to the next stop
        // update the current stop to the next stop when the stop state is completed
        if (stopState === TourStopState.completed) {
            const nextStop = tourStops?.[stopIndex];
            if (!nextStop) return;
            const nextStopHasSetup = nextStop?.setup;
            updateTourState(
                nextStop,
                nextStopHasSetup ? TourStopState.setup : TourStopState.playing,
            );
        }
    }, [stopState]);

    return {stopState, updateTourState, currentStop};
}

export default useTourStopState;
