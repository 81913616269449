import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    title: {
        fontSize: '20px',
    },
    contentSection: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        marginBottom: '36px',
        marginTop: tokens.spacingVerticalS,
    },
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    dialogContentSection: {
        marginBottom: '36px',
        marginTop: tokens.spacingVerticalS,
    },
    fullWidthControl: {
        width: '100%',
    },
    capacityDropDown: {
        height: '32px',
        marginBottom: tokens.spacingVerticalSNudge,
    },
    icon: {
        scale: 1.5,
        top: '3px',
    },
    listBoxHeight: {
        maxHeight: '200px',
        height: 'auto',
    },
});
