import {useTranslation} from 'react-i18next';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import useClasses from './OwnerSettings.styles';
import {useFeatureFlag} from '@/api/user';
import {Spinner} from '@fluentui/react-components';
import {AzureResourceLinks, Capacity, OrganizationData, Overview} from '.';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import useGetEapExpired from '@/api/app/useGetEapExpired';
import SwitchCapacity, {SwitchCapacityContext} from '../SwitchCapacity';
import {useCapacitySwitchOnOwnerSettings} from './useCapacitySwitchOnOwnerSettings';
import {useQueryClient} from '@tanstack/react-query';

export default function OwnerSettings() {
    const pageClasses = useClasses();
    const {workspaceName, isProviderLoaded, isWorkspaceCapacityEAP} = useWorkspaceState();
    const [isPageLoadedCompleted, setIsPageLoadCompleted] = useState(false);
    const [shouldFocusGASwitchCapacity, setShouldFocusGASwitchCapacity] = useState(false);
    const [shouldFocusEAPSwitchCapacity, setShouldFocusEAPSwitchCapacity] = useState(false);

    const {
        isSwitchCapacityVisible,
        setSwitchCapacityOpen,
        setSwitchCapacityClose,
        switchCapacityMode,
    } = useCapacitySwitchOnOwnerSettings();

    const isA11yEnabled = useFeatureFlag('a11y');
    const location = useLocation();
    const [isEapUserExperienceDisabled, setisEapUserExperienceDisabled] = useState(false);

    const {data: expiryDateData, isFetched: expiryDateFetched} = useGetEapExpired();

    const {t} = useTranslation('admin');

    // This ensures that the page is reloaded when
    // 1. The location changes
    useEffect(() => {
        setIsPageLoadCompleted(false);
    }, [location]);

    // This ensures that all the data is loaded before rendering the page for the first time. This prevents the
    // flicker of widgets while the component is rendered before the data is loaded.
    useEffect(() => {
        if (isProviderLoaded && !isPageLoadedCompleted && expiryDateFetched) {
            const isEAPTenant =
                !!expiryDateData?.expiryDate || (isProviderLoaded && isWorkspaceCapacityEAP);

            const isUserExperienceDisabled = !isEAPTenant || (isEAPTenant && !!isA11yEnabled);

            setIsPageLoadCompleted(true);
            setisEapUserExperienceDisabled(isUserExperienceDisabled);
        }
    }, [
        isProviderLoaded,
        isPageLoadedCompleted,
        expiryDateFetched,
        isWorkspaceCapacityEAP,
        isA11yEnabled,
    ]);

    return (
        <div className={pageClasses.root}>
            <h1 className={pageClasses.title}>{t('ownerSettings.PageHeading')}</h1>
            <div className={pageClasses.componentContainer}>
                {!isPageLoadedCompleted && (
                    <div className={pageClasses.loadingContainer}>
                        <Spinner
                            labelPosition="below"
                            aria-live="polite"
                            aria-label={t('ownerSettings.PageLoading')}
                            size="medium"
                            label={`${t('ownerSettings.PageLoading')}...`}
                        ></Spinner>
                    </div>
                )}
                {isPageLoadedCompleted && (
                    <>
                        {isEapUserExperienceDisabled && (
                            <>
                                <AzureResourceLinks
                                    onSwitchCapacityButtonClick={() => {
                                        setSwitchCapacityOpen('');
                                    }}
                                    shouldFocusOnSwitchCapacityButton={shouldFocusGASwitchCapacity}
                                    onSwitchCapacityButtonBlur={() => {
                                        setShouldFocusGASwitchCapacity(false);
                                    }}
                                    suppressAutoFocus={isSwitchCapacityVisible}
                                />
                                <Capacity />
                            </>
                        )}
                        {!isEapUserExperienceDisabled && (
                            <Overview
                                onEarlyAccessEnd={() => {
                                    setSwitchCapacityOpen('eap');
                                }}
                                shouldFocusOnSwitchCapacityButton={shouldFocusEAPSwitchCapacity}
                                onSwitchCapacityButtonBlur={() => {
                                    setShouldFocusEAPSwitchCapacity(false);
                                }}
                            />
                        )}
                        <OrganizationData workspaceName={workspaceName ?? ''} />
                    </>
                )}
            </div>
            <SwitchCapacity
                context={switchCapacityMode}
                isSwitchCapacityOpen={isSwitchCapacityVisible}
                onSwitchCapacityDialogClose={(context: SwitchCapacityContext) => {
                    setSwitchCapacityClose();
                    if (context === '' || context === 'eap') {
                        // This is to accommodate focus for 2 scenarios:
                        // 1. Switch capacity is closed while EA=>GA transition is in progress, and incomplete
                        // 2. Switch capacity is closed after EA=>GA transition is complete. After this, the focus should be on the GA switch capacity button
                        setShouldFocusGASwitchCapacity(true);
                    }

                    if (context === 'eap') {
                        setShouldFocusEAPSwitchCapacity(true);
                    }
                }}
                onSwitchCapacityUpdate={async (context: SwitchCapacityContext) => {
                    // Force the visibility of the page widgets to evaluate if the transition is from EAP => GA
                    if (context === 'eap') {
                        setIsPageLoadCompleted(false);
                    }
                }}
            ></SwitchCapacity>
        </div>
    );
}
