import {PinnedEvaluation} from '../evaluations';
import {ApplyPromptbookRequest} from '../promptbooks';
import {CreatePromptRequest, Prompt} from '../prompts';

export type Session = {
    sessionId: string;
    userId?: string;
    name: string;
    skillsets?: string[];
    featureFlags?: string[];
    createdAt: string;
    updatedAt: string;
    pinboardSummary: PinnedSummaryResponse | null;
    sessionSummary: SessionSummaryResponse | null;
};

export type SessionSharingToken = {
    token: string;
};

export type CreateSessionRequest = {
    name?: string;
    source?: string;
    skillsets?: string[];
    featureFlags?: string[];
    prompt?: Omit<CreatePromptRequest, 'sessionId'>;
};

export type CreateSessionWithPromptRequest = CreateSessionRequest & {
    prompt?: Omit<CreatePromptRequest, 'sessionId'>;
};

export type CreateSessionWithPromptbookRequest = CreateSessionRequest & {
    content?: string;
    promptbook?: Omit<ApplyPromptbookRequest, 'sessionId'>;
};

export type GetSessionRequest = {
    sessionId: string;
};

export type GetSessionSummarizeRequest = {
    sessionId: string;
    enabled?: boolean;
};

export type SaveSummaryRequest = {
    session: Session;
    pinnedItems: PinnedEvaluation[];
};

export type SaveSessionSummaryRequest = {
    session: Session;
};

export type GetSessionPinnedRequest = {
    sessionId: string;
    pinnedItems: PinnedEvaluation[];
};

export type SessionPinboardModel = {
    sessionId: string;
    pinnedItems: PinnedEvaluation[];
    pinnedItemsLength: number;
    sessionData: Session | undefined;
};

export type GetSessionPinnedSummaryRequest = {
    sessionId: string;
    pinnedItems: PinnedEvaluation[];
    sessionData: Session | undefined;
};

export type ClearSessionSummaryRequest = {
    sessionId: string;
};

export type GetSessionSummaryRequest = {
    sessionId: string;
    sessionData: Session | undefined;
    isEnabled: boolean;
};

export type GetSessionSummaryV2Request = {
    sessionId: string;
    sessionData: Session | undefined;
    enabled?: boolean;
};

export type PinnedSummaryResponse = {
    summary: string | null;
    title: string | null;
    tags: string[] | null;
};

export type SavePinnedSummaryRequest = {
    sessionId: string;
    summary: string | undefined;
    title: string | undefined;
    tags: string[] | undefined;
    pinnedItems: PinnedEvaluation[];
};

export type GetSessionsRequest = {
    limit?: number;
    sortBy?: string | null;
    sortDirection?: 'asc' | 'desc' | null;
    filter?: string | null;
    maxSessionsCount?: number;
};

export type SessionSummaryResponse = {
    summary: string | null;
    title: string | null;
    tags: string[] | null;
};

export type GetSessionSharingTokenRequest = {
    sessionId: string;
};

export type SessionSharingTokenRequest = {
    sessionId: string;
    role?: string;
    global?: boolean;
    expiresOn?: string;
};

export type DeleteSessionRequest = {
    sessionId?: string;
    sessionIds?: string[];

    // Optional `open` control
    open?: boolean | undefined;
    onClose?: () => void;
    onSuccess?: () => void;
};

export type GetDuplicateSessionRequest = {
    sessionId: string;
};

export type DuplicateSessionRequest = {
    sessionName: string;
};

export type UpdateSessionRequest = {
    session: Session;
    open?: boolean | undefined;
    onClose?: () => void;
    onSuccess?: () => void;
};

export enum SessionSelectionType {
    Single = 'single',
    Multiple = 'multiple',
}

export enum SourceType {
    Immersive = 'immersive',
}

export type GetSessionSharingMailRequest = {
    sessionId: string;
};

export type SessionSharingMailRequest = {
    sessionId: string;
    baseUrl: string;
    investigationName: string;
    senderName: string;
    userRequest: UserSessionSharingTokenRequest[];
};

export type UserSessionSharingTokenRequest = {
    userId: string;
    role?: string;
    global?: boolean;
    Email: string;
    expiresOn?: string;
};
