import * as React from 'react';
const SVGComponent = (props: any) => (
    <svg
        width={2194}
        height={1564}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 2194 1564"
        {...props}
    >
        <g filter="url(#a)" opacity=".3">
            <path
                fill="#296981"
                d="M1165.82 1225.85c-195.408 130.64-329.204-77.93-806.529-671.778-281.9697-433.855 529.446-208.606 788.939-39.915 259.49 168.69 701.24-370.358 743.29-167.709 42.04 202.649-530.28 748.762-725.7 879.402Z"
            />
        </g>
        <defs>
            <filter
                id="a"
                width="2193.13"
                height="1563.07"
                x=".583008"
                y=".193359"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur result="effect1_foregroundBlur_1_8" stdDeviation="150" />
            </filter>
        </defs>
    </svg>
);
export default SVGComponent;
