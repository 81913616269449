export enum PluginRbacSetting {
    ANY = 'ANY',
    ADMIN = 'ADMIN',
}

export enum PluginRbacSettingScope {
    USER = 'USER',
    TENANT = 'TENANT',
}

export enum FileRbacSetting {
    OFF = 'OFF',
    ON = 'ON',
}
