import {ServerApiError, ClientApiError} from './api';

// a utility function that takes a status code and throws the right type of error
export default function throwApiError(response: Response, message?: string) {
    const statusCode = response.status;
    // for 4XX errors, throw a ServerApiError
    if (statusCode >= 400 && statusCode < 500) {
        throw new ClientApiError(
            message || `Client API error for "${response?.url ?? 'unknown'}".`,
            response,
        );
    }
    // for 5XX errors, throw a ServerApiError
    else if (statusCode >= 500) {
        throw new ServerApiError(
            message || `Server API error for "${response?.url ?? 'unknown'}".`,
            response,
        );
    }
}
