import {SkillsetCatalogScope, SkillsetCategory, SkillsetDescriptor} from '@/api/skills';
import {getImagePath} from '../../../ExternalConfigurationModal/Utils/ExternalConfigurationModal.utils';

const Approved3PLogos: string[] = [
    'CIRCL',
    'CrowdSecTI',
    'GreyNoiseCommunity',
    'GreyNoiseEnterprise',
    'UrlScan',
];
const PublishedByMicrosoft: string = 'Published by Microsoft';

export type SkillsetMetadata = {
    pluginName: string;
    displayName: string;
    descriptionForDisplay: string;
    isPublishedByMicrosoft: boolean;
    isUserScopePlugin: boolean;
    msPublishedImagePath: string;
};

export const getSkillsetDescriptionForDisplay = (
    skillsetDescriptor: SkillsetDescriptor | undefined,
): string => {
    return skillsetDescriptor?.descriptionDisplay ?? skillsetDescriptor?.description ?? '';
};

export const getSkillsetMetadata = (
    skillsetDescriptor: SkillsetDescriptor | undefined,
): SkillsetMetadata => {
    const pluginName: string = skillsetDescriptor?.name ?? skillsetDescriptor?.displayName ?? '';
    const displayName: string = skillsetDescriptor?.displayName ?? skillsetDescriptor?.name ?? '';
    const descriptionForDisplay: string = getSkillsetDescriptionForDisplay(skillsetDescriptor);

    // Description may have PublishedByMicrosoft text as a workaround to show an info button next to plugins
    const fallbackDescription: string =
        skillsetDescriptor?.description ?? skillsetDescriptor?.descriptionDisplay ?? '';
    const isPublishedByMicrosoft: boolean = fallbackDescription.includes(PublishedByMicrosoft);

    // Show private badge for user scope plugins
    const isUserScopePlugin =
        skillsetDescriptor?.category == SkillsetCategory.Plugin &&
        skillsetDescriptor?.catalogScope == SkillsetCatalogScope.User;

    // Microsoft published partner 3P plugins will use their actual logo, or Copilot logo
    const msPublishedImagePath = Approved3PLogos.includes(pluginName)
        ? getImagePath(pluginName + '.png')
        : getImagePath('Copilot.svg');

    return {
        pluginName,
        displayName,
        descriptionForDisplay,
        isPublishedByMicrosoft,
        isUserScopePlugin,
        msPublishedImagePath,
    };
};
