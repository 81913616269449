import {makeStyles, tokens} from '@fluentui/react-components';
export default makeStyles({
    messageBarContainer: {
        display: 'flex',
        width: '100%',
        paddingBottom: tokens.spacingVerticalM,
    },
    messageBarBody: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        paddingLeft: tokens.spacingHorizontalXS,
    },
    title: {
        paddingRight: tokens.spacingHorizontalS,
    },
});
