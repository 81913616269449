import {PromptBarSubmitOptions} from '@/components/ui';

export interface ExploreCopilotProps {
    onSubmit?: ({content, skillsets, featureFlags}: PromptBarSubmitOptions) => void;
    className?: string;
}

export interface ExploreCardProps extends ExploreCopilotProps {
    cardRange: number;
    btnClicked: string;
    disablePrev: (value: boolean) => void;
    disableNext: (value: boolean) => void;
    clickCounter: number;
    active: boolean; // on switch of tabs we need this to update the card range and handle the next and prev buttons.
}

export interface ExploreCardListProps {
    children: React.ReactNode;
    className?: string;
}

export enum ExploreTab {
    Prompts = 'prompts',
    Promptbooks = 'promptbooks',
}
