import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    sessionsLink: {
        color: 'inherit',
        height: '100%',
        ':hover': {
            textdecoration: 'none',
            color: 'transparent',
        },
        ':active': {
            textdecoration: 'none',
            color: 'transparent',
        },
    },
});
