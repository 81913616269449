import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-word',
        ...shorthands.margin('48px', '64px'),
        marginLeft: '25%',
        width: '40%',
        minWidth: '280px',
    },
    title: {
        marginTop: tokens.spacingVerticalNone,
        marginBottom: '48px',
        fontSize: tokens.spacingHorizontalXXXL,
        fontWeight: tokens.fontWeightRegular,
    },
    componentContainer: {
        '& > *:not(:last-child)': {
            paddingBottom: '48px',
        },
    },
    loadingContainer: {
        marginTop: tokens.lineHeightHero800,
    },
});
