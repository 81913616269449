import useClasses from './OwnerSettings.styles';
import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import PluginSettings from '@/components/admin/PluginSettings';

export default function PluginSettingsPage() {
    const pageClasses = useClasses();

    const {data: tenantInfo} = useGetTenantInfo();
    const {data: userInfo} = useGetUserInfo();
    const isAdmin = userInfo?.isAdmin ?? false;
    const isGlobalAdmin = userInfo?.isGlobalAdmin ?? false;

    return (
        <div className={pageClasses.root}>
            <PluginSettings
                isAdmin={isAdmin}
                isGlobalAdmin={isGlobalAdmin}
                tenantInfo={tenantInfo}
            />
        </div>
    );
}
