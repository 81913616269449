import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    icon: {
        '& > svg.fui-Icon-regular': {
            height: '40px',
            width: '40px',
            '@media (forced-colors: active)': {
                backgroundColor: 'Canvas',
            },
        },

        marginBottom: '42px',
        '@media (max-width: 479px)': {
            marginBottom: tokens.spacingVerticalS,
        },
    },
});
