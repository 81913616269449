import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {TourStop, TourStopState, TourPopoverSurface} from './Tour.types';
import useTourContext from './useTourContext';
import {useElementBySelector} from '@/components/ui/Tour/util/useGetElement';
import Coachmark from './Coachmark';
import {ensurePopoverVisibility} from './util/scrollElementIntoView';

interface TourPopoversProps {
    surface: TourPopoverSurface;
}
interface TourPopoverRendererProps {
    stop: TourStop;
    tourStopState?: TourStopState;
}

export function TourPopoverRenderer(props: TourPopoverRendererProps) {
    const {stop, tourStopState} = props;
    const {popover} = stop || {};
    const {targetSelector, content, showCoachmark, positioningProps, ...rest} = popover || {};
    // targetSelector can be either a selector or a function that returns a target element
    // if we have a string we will use useElementBySelector to find the target element
    // if we have a function we will call the function to get the target element
    const selectorIsFunction = typeof targetSelector === 'function';
    const [targetElement, setTargetElement] = useState<Element | null>(null);
    const [element, fireSearch] = useElementBySelector(
        selectorIsFunction ? 'body' : targetSelector || 'body',
        2000,
        false,
    );

    const isInSetupOrTeardown = useMemo(
        () => tourStopState === TourStopState.setup || tourStopState === TourStopState.teardown,
        [tourStopState],
    );

    const updateTargetElement = useCallback(() => {
        if (isInSetupOrTeardown) {
            // if the stop has a setup and we are not playing or ready, we should not try to find the target element
            return;
        }
        // if the selector is a function, we should call it to get the target element
        if (selectorIsFunction) {
            setTargetElement(targetSelector());
            return;
        } else {
            // if the selector is a string, we should use useElementBySelector to find the target element
            // this will fire the search for the target element
            fireSearch();
        }
        // only run this when our setup or teardown state changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInSetupOrTeardown]);

    useEffect(() => {
        if (targetElement !== element) {
            setTargetElement(element);
        }
    }, [element]);

    useEffect(() => {
        updateTargetElement();
        // only run this when our setup or teardown state changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourStopState]);

    const fullPositioningProps = useMemo(
        () => (positioningProps ? {...positioningProps, target: targetElement} : {}),
        [targetElement, positioningProps],
    );

    const shouldRenderComponent = !isInSetupOrTeardown && Boolean(targetElement);

    useEffect(() => {
        if (targetElement && shouldRenderComponent) {
            ensurePopoverVisibility(targetElement as HTMLElement);
        }
    }, [targetElement, shouldRenderComponent]);

    return shouldRenderComponent ? (
        <Coachmark
            {...rest}
            positioning={fullPositioningProps}
            open={!isInSetupOrTeardown}
            stop={stop}
            alwaysShow={showCoachmark}
            stopState={tourStopState || TourStopState.playing}
            setStopState={() => {}}
        />
    ) : (
        <></>
    );
}

/** TourPopovers renderer that can be added to an application and custom surfaces */
const TourPopovers = (props: TourPopoversProps) => {
    const {surface} = props;
    const {activeTour, currentStop, stopState} = useTourContext();
    const {popover, name} = currentStop || {};
    const popoverTargetedSurface = popover?.surface ?? TourPopoverSurface.default;
    // we care about which surface the popover is on as only one should be rendered at a time
    // if the surface does not match the current surface, we should not render the popover
    // if the surface is default, we should render the popover if it has a setup
    // if the surface is dialog we should run the setup and teardown code from the default surface
    const matchingSurface = popoverTargetedSurface === surface;
    // TourPopover should only activate if this surface is set for the stop
    // this ensures that the setup and teardown code is run even if the popover cannot be rendered yet
    if (!activeTour || !currentStop || !popover || !matchingSurface) return null;

    return (
        <TourPopoverRenderer {...props} stop={currentStop} tourStopState={stopState} key={name} />
    );
};

export default TourPopovers;
