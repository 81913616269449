import {CreatePromptbookRequest, PromptbookPrompts} from '@/api/promptbooks';
import {useState} from 'react';
import {EMPTY_PROMPTBOOK_PROMPT_CONTENT, FormErrors} from './Promptbook.types';
import {matches, uniq} from 'lodash-es';
import {useTranslation} from 'react-i18next';

export default function useCreatePromptbookFormValidations(
    promptbookCreateRequest: CreatePromptbookRequest | undefined,
) {
    const {t} = useTranslation('promptbooks');
    const [errors, setErrors] = useState<FormErrors>({});

    function validateForm() {
        let newErrors: FormErrors = {};

        // Perform validation
        if (!promptbookCreateRequest) {
            newErrors['promptbookCreateRequest'] = t(
                'CreatePromptbookFormValidations.CreateRequestRequired',
            );
        } else {
            if (!promptbookCreateRequest.name) {
                newErrors['name'] = t('CreatePromptbookFormValidations.NameRequired');
            }

            if (!promptbookCreateRequest.description) {
                newErrors['description'] = t('CreatePromptbookFormValidations.DescriptionRequired');
            }

            if (!promptbookCreateRequest.prompts || promptbookCreateRequest.prompts.length === 0) {
                newErrors['prompts'] = t('CreatePromptbookFormValidations.PromptsRequired');
            }

            // Check if the prompts have text in it or are blank prompts (or default prompts)
            const blankOrDefaultPrompts = promptbookCreateRequest.prompts.filter(
                (p) => !p || !p.content || p.content === t(EMPTY_PROMPTBOOK_PROMPT_CONTENT),
            );
            if (blankOrDefaultPrompts.length > 0) {
                newErrors['prompts'] = t(`CreatePromptbookFormValidations.EmptyPrompts`);
            }

            // Check for input parameter usage in prompts.
            const params = getParamsFromPrompts(promptbookCreateRequest.prompts);

            // Check if all inputs are used in prompts.
            promptbookCreateRequest.promptbookinputs.forEach((input) => {
                if (!params || params.length === 0 || !params.includes(input.name)) {
                    newErrors['promptbookinputs'] = t(
                        'CreatePromptbookFormValidations.InputNotUsed',
                        {inputName: input.name},
                    );
                }
            });

            // Check if all parameters are declared in inputs.
            if (params && params.length > 0) {
                params.forEach((param) => {
                    if (
                        !promptbookCreateRequest.promptbookinputs.map((i) => i.name).includes(param)
                    ) {
                        newErrors['promptbookinputs'] = t(
                            'CreatePromptbookFormValidations.InputNotDeclared',
                            {param},
                        );
                    }
                });
            }
        }

        setErrors(newErrors);

        return newErrors && Object.keys(newErrors).length === 0;
    }

    function getParamsFromPrompts(prompts: PromptbookPrompts[]) {
        const inputsRegEx: RegExp = new RegExp(/<([a-zA-Z0-9_]+)>/, 'g');

        const tempParamsList: string[][] = prompts
            .filter((p) => p! && p.content)
            .map((prompt) => {
                let match;
                const allMatches = [];

                while ((match = inputsRegEx.exec(prompt.content)) !== null) {
                    allMatches.push(match[1]);
                }

                return allMatches;
            });

        let parameters = tempParamsList && tempParamsList.length > 0 ? tempParamsList.flat() : [];
        parameters = uniq(parameters);

        // const params = parameters &&
        //     parameters.filter((p) => p !== null && p!.length > 0).map((p) => p![1]);
        return parameters;
    }

    return {
        validateForm,
        errors,
        isError: errors && Object.keys(errors).length > 0,
        getParamsFromPrompts,
    };
}
