import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CreateWorkspaceRequest} from './workspaces.types';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import securityGraphApi from '@/api/securityGraph.api';

/**
 * DEPRECATED WITH MULTIWORKSPACES: Creates a new workspace during provisioning
 * use usePutWorkspace instead for new workspace creation
 * @returns
 */
export default function useCreateWorkspace() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (body: CreateWorkspaceRequest) => {
            const url = securityGraphApi({
                path: '/account/provisionCopilot',
                version: '2023-12-01-preview',
                isGlobal: true,
            });
            return await customFetch(url, {
                method: 'POST',
                scopes: RESOURCE_SCOPES.FIDELIS,
                body,
            });
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries(['workspaces']);
            queryClient.invalidateQueries(['workspaces', variables.defaultWorkspaceName]);
        },
        retry: 0,
    });
}
