import {Tour} from '@/components/ui/Tour';
import {TourPopoverSurface, TourTelemetryOptions} from '@/components/ui/Tour/Tour.types';
import {PositioningProps} from '@fluentui/react-components';
import config, {tourStopNames} from './config';
import {cleanupHandlers} from './stops/PopoverStops';

import * as Popovers from './stops/PopoverStops';

const defaultControlOptions = {
    allowSkip: false,
    allowExit: false,
    allowReplay: false,
    showExit: false,
    showControls: false,
    showCounter: false,
};

interface FromDefenderTourProps {
    onExit?: () => void;
    telemetryOptions: TourTelemetryOptions;
}

const defaultPopoverPositioning: PositioningProps = {
    arrowPadding: 10,
};

export function FromDefenderTour(props: FromDefenderTourProps): Tour {
    return {
        name: config.name,
        controlOptions: defaultControlOptions,
        telemetryName: config.telemetryName,
        telemetryOptions: props.telemetryOptions,
        onExit: cleanupHandlers,
        tourStops: [
            {
                name: tourStopNames.chatWithAI,
                autoAdvance: true,
                autoStart: true,
                popover: {
                    content: Popovers.ChatWithAI,
                    targetSelector: "[data-testid='promptbar-root']",
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                },
            },
            {
                name: tourStopNames.getIdeas,
                autoAdvance: true,
                autoStart: true,
                popover: {
                    targetSelector: "[data-testid='promptbar-skill-menu-toggle']",
                    content: Popovers.GetIdeas,
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                },
            },
            {
                name: tourStopNames.tryQuickstart,
                autoAdvance: true,
                autoStart: true,
                setup: Popovers.setupHandlers,
                teardown: Popovers.teardownHandlers,
                popover: {
                    targetSelector: "[data-testid='promptbarmenu']",
                    content: Popovers.TryQuickStart,
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                },
            },
            {
                name: tourStopNames.sources,
                autoAdvance: true,
                autoStart: true,
                popover: {
                    targetSelector: "[data-testid='promptbar-sources-button']",
                    content: Popovers.Sources,
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                },
            },
            {
                name: tourStopNames.optimize,
                autoAdvance: true,
                autoStart: true,
                setup: Popovers.setupHandlers,
                teardown: Popovers.teardownHandlers,
                popover: {
                    targetSelector: "[data-testid='tabbed-dialog-content-Manage-sources']",
                    content: Popovers.Optimize,
                    withArrow: true,
                    positioningProps: {
                        ...defaultPopoverPositioning,
                    },
                },
            },
        ],
    };
}

export default FromDefenderTour;
