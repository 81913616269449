import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    /** On background (--colorNeutralBackground1) */
    colorNeutralBackground1: {
        /* Works on Firefox */
        scrollbarWidth: 'thin',
        /* scroll thumb and track */
        scrollbarColor: `${tokens.colorNeutralForeground3} ${tokens.colorTransparentBackground}`,
        /* Works on Chrome, Edge, and Safari */
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorTransparentBackground,
            marginBottom: '35px',
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralForeground3,
            ...shorthands.borderRadius('20px'),
        },
    },
    /** On background (--colorNeutralBackground2) */
    colorNeutralBackground2: {
        /* Works on Firefox */
        scrollbarWidth: 'thin',
        /* scroll thumb and track */
        scrollbarColor: `${tokens.colorNeutralForeground3} ${tokens.colorTransparentBackground}`,
        /* Works on Chrome, Edge, and Safari */
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorTransparentBackground,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralForeground3,
            ...shorthands.borderRadius('20px'),
        },
    },
});
