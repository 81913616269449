import {
    PositioningShorthandValue,
    PopoverProps,
    PositioningProps,
} from '@fluentui/react-components';
export enum TourState {
    // the TourStop is preloading any data and dom modifications it needs
    'preloading' = 'preloading',
    // the TourStop is done preloading and is ready to play
    'ready' = 'ready',
    // TODO: Enable once we are ready to enable the pause functionality
    'paused' = 'paused',
    // the TourStop is moving backwards in the tour
    'rewinding' = 'rewinding',
    // the TourStop is currently playing
    'playing' = 'playing',
    // the TourStop has completed playing
    'completed' = 'completed',
    // there is no active Tour
    'empty' = 'empty',
}
// TODO : if we have time implement these options into the config for the tour or for the stop
export interface ControlOptions {
    // when false will disable the back button
    allowBack: boolean;
    // when false will disable the next button
    allowSkip: boolean;
    // when false will disable the Play/Pause button
    allowPausePlay: boolean;
    // when false will disable the exit button
    allowExit: boolean;
    // when false will not show a 'start from beginning button'
    allowReplay: boolean;
    // when showControls and showExitOnly are true only the exit button control will be shown
    showExitOnly: boolean;
    // when false hides the exit button
    showExit: boolean;
    // shows the current tour stop and the total number of stops
    showCounter: boolean;
    // when false hides all controls, including the exit
    showControls: boolean;
}

export enum AlignEdge {
    start = 'start',
    center = 'center',
    end = 'end',
}

export type TargetSelector = string | (() => Element) | null;

export enum OverlayOptions {
    gradient = 'gradient',
    dark = 'dark',
    light = 'light',
    none = 'none',
}

export enum SpotlightContentStyle {
    // spotlight will render the content in a popover
    popover = 'popover',
    // spotlight will render the content directly on the overlay
    overlay = 'overlay',
}

export interface PopoverViewProps {
    stopState: TourStopState;
    setStopState: (state: TourStopState) => void;
    setShowCoachmark: (show: boolean) => void;
}

export type PopoverView = React.ComponentType<PopoverViewProps>;

export interface SpotLightOptions {
    // the content to render in the tooltip - TODO move this to be part of the tourstop
    content?: any;
    // padding to add to the overlay hole
    padding?: number;
    // the border radius for the hole in the overlay
    borderRadius?: number;
    // TODO drop this as we are no longer supporting this option
    // margin to separate from the target
    marginFromTarget?: number;
    // whether to show/hide the overlay
    showOverlay?: boolean;
    // TODO update this if the fluent popovers have a corresponding option
    // which edge of the tooltip to align with
    alignEdge?: AlignEdge;
    // the origin on the target that the tooltip will be anchored to
    tooltipPosition?: PositioningShorthandValue;
    // the position relative to the target that the tooltip will be anchored to
    position?: Position;
    // the element that the tooltip will attach to
    targetSelector?: TargetSelector;
    // when true allows pointer events to pass through the overlay
    allowInteractions?: boolean;
    // minimize the fade in/out animation to keep the overlay continuously visible
    transitionWithOverlay?: boolean;
    // the overlay style to use
    contentStyle?: SpotlightContentStyle;
}
// a light wrapper over the fluent popover props
export interface TourPopoverOptions extends PopoverProps {
    // the content to render in the popover
    content: PopoverView;
    // the target selector to attach the popover to
    targetSelector: string | (() => Element);
    // used to manually load the popover within a dialog
    dialogPopover?: boolean;
    // Fluent positioning props that are passed into the popover
    positioningProps: Partial<PositioningProps>;
    // start this stop by showing a coachmark indicator, regardless of the screen size
    showCoachmark?: boolean;
    // which surface the popover should be rendered on
    surface?: TourPopoverSurface;
}

// Generic stage model for useStageManager
export interface TourStop {
    name: string;
    // the amount of time in ms that the tour should stop
    // TODO: can move some of the timing to this level?
    // duration?: number;
    // if true will automatically set the stop to start playing if there are any animations
    autoStart?: boolean;
    /** if true when the stageState is set to completed this will call the next stage*/
    autoAdvance?: boolean;
    /** a callback to fire when stageState is set to completed, fires before the stageIndex is changed */
    onCompleted?: (data?: any) => void;
    // DEPRECATED (use TourStop.popover instead): when true this specific stop will be rendered using the Overlay and Tooltip components
    spotlight?: Partial<SpotLightOptions> | null;
    // Fluent popovers that should be use with useTourable
    popover?: Partial<TourPopoverOptions> | null;
    // used to map network calls that need to be overwritten for this TourStop
    data?: any;
    // control options for the tourStop
    controls?: Partial<ControlOptions>;
    // Overlay options, ignored when tour stop is using spotlight
    overlay?: OverlayOptions;
    // content to render for the stop, will render based on the overlay options
    content?: React.ReactNode;
    // content to render before rendering the tour stop, will render based on the overlay options
    onBeforeStop?: React.ReactNode;
    // content to render before going to the previous tour stop, will render based on the overlay options
    onBeforePrevious?: React.ReactNode;
    // simulate network calls options
    simulateNetworkCalls?: boolean;
    //is an error State for the tour
    isError?: boolean;
    // key based value of the tour
    key?: string;
    //Determines if this stop is the first stop in the tour
    isFirstStop?: boolean;
    //Determines the value of progress bar
    stepValue?: number;
    setup?: TourStopSetupHandler;
    teardown?: TourStopTeardownHandler;
}

export interface TourTelemetryOptions {
    onTourStart?: () => void;
    onTourExitEarly?: (lastStopName: string) => void;
    onTourComplete?: () => void;
}

export type ValidTourErrorMap = Record<string, ValidTourErrorPropCombination> & {
    Default: ValidTourErrorPropCombination;
};

export interface Tour {
    // name of the tour
    name: string;
    // the stops for the tour
    tourStops: Array<TourStop>;
    // options to be set as the default for stops, these can be overwritten by individual TourStops
    defaultStopOptions?: Partial<TourStop>;
    // a stop to display if the user exits the tour before the end
    onEarlyExit?: React.ReactNode;
    // control options to be set for the tour, can be overwritten by individual TourStops
    controlOptions?: Partial<ControlOptions>;
    // a callback to fire when the tour has been exited
    onExit?: TourStopTeardownHandler;
    // allow auto play of the tour
    autoPlay?: boolean;
    // tour telemetry name, should align with the name in AppState
    telemetryName?: string;
    // TODO enable this for all tours and embed into useTour
    telemetryOptions?: TourTelemetryOptions;
    // error page to render if there is an error in the tour
    errorPage?: React.ReactNode;
    // error state for the tour, the key being an identifier for the error state
    //and the value corresponding to a configuration for the error state
    //Optional to ensure that legacy tours don't break
    errorStates?: ValidTourErrorMap;
}

export interface useTourProps {
    tour?: Tour | null;
}

/**
 * Lifecycle states of a TourStop
 */
export enum TourStopState {
    'empty' = 'empty',
    'setup' = 'setup',
    'playing' = 'playing',
    'teardown' = 'teardown',
    'completed' = 'completed',
}

export interface TourContext {
    tour: Tour | null;
    // set or unset a tour
    setTour: (tour: Tour | null) => void;
    // indicates whether a tour is currently loaded
    activeTour: boolean;
    // indicates whether the tour will autoplay through stops
    tourAutoplay: boolean;
    // TODO:Review : may no longer need this
    tourState: TourState;
    // Moves the TourState from preloading to playing
    loadContent: () => void;
    // current control configuration for the tour/tour stop
    controls: ControlOptions;
    // Setting the control options, can be called from within a stop
    setControls: (controlOptions: ControlOptions | Partial<ControlOptions> | null) => void;
    // Advance to the next tour stop
    nextStop: () => void;
    // Go back to the previous tour stop
    prevStop: () => void;
    // TODO:Review - may no longer need this
    completeTourStop: () => void;
    // The current TourStop object
    currentStop?: TourStop | null;
    // Override showing an overlay
    showOverlay?: boolean;
    // When available will render a custom overlay over the current stop
    showExitOverlay: boolean;
    // Toggle the exit overlay display
    setShowExitOverlay: (show: boolean) => void;
    // Index of the current stop
    stopIndex: number;
    // Set the tour to start from the first index
    resetTour: () => void;
    // Set the tour to a specific index
    resetTourStop: (tourStopIndex?: number) => void;
    // Stop the auto advance and pause the tour
    pauseTour: () => void;
    // Checks for onEarlyExit component and shows it, if not calls quitTour
    exitTour: () => void;
    // Clear out the current tour stop and overlays
    // use this when exiting from onEarlyExit component
    quitTour: () => void;
    // callback to use when an error in the tour has occurred
    setTourError: (message: string) => void;
    // error message if there's an error present
    tourError: string | undefined;
    setTourErrorV2: (errorProps: ValidTourErrorPropCombination) => void;
    // callback to use when the default error needs to be rendered
    setDefaultV2TourError: (returnStep?: string) => void;
    // error content if there's an error present
    v2TourError: ValidTourErrorPropCombination | null;
    // error page to render for the tour
    errorPage: React.ReactNode;
    setTourStep: (step: string) => void;
    //add parameter to be shared within tour
    addTourSharedParameter: (key: Required<string>, value: Required<string>) => void;
    //Clear all shared parameters
    clearTourSharedParameter: (key: Required<string>) => void;
    //get shared parameter
    getTourSharedParameter: (key: Required<string>) => string | undefined;
    //Clear all shared parameters
    clearTourSharedParameters: () => void;
    // show the loading state
    showLoadingState: (shouldShow: boolean) => void;
    // show the loading state
    loadingState: boolean;
    // set the tour state
    setTourState: (state: TourState) => void;
    // get the count of visible tour stops
    visibleTourStopCount: () => number;
    // the current state of the current stop
    stopState: TourStopState;
    // set the state of the current stop
    setStopState: (state: TourStopState) => void;
}

export interface Position {
    top: number;
    left: number;
    width: number;
    height: number;
}

export interface TourErrorProps {
    heading: string;
    message: string;
    actionButtonStep: string;
    actionButtonString: string;
}

//Keeping this for data persistence if there is a need across steps
// eg when we go back from an error page, a form might want to retain values
// entered if they dont originally come from an API
export enum TOUR_DATA_SHARE_KEYS {}

export type ValidTourErrorPropCombination = Pick<TourErrorProps, 'heading'> &
    Partial<Pick<TourErrorProps, 'message' | 'actionButtonString' | 'actionButtonStep'>>;

//Keeping this for data persistence if there is a need across steps
// eg when we go back from an error page, a form might want to retain values
// entered if they dont originally come from an API
export enum TOUR_DATA_SHARE_KEYS {}

// TourPopovers can be rendered in different surfaces that will affect the positioning of the popover
// this allows the TourPopovers component to determine if it should be the component that renders the popover
// or if it should be a secondary surface that renders the popover
export enum TourPopoverSurface {
    'default' = 'default',
    'dialog' = 'dialog',
    'sidePanel' = 'sidePanel',
}

// Function that can be used during the setup phase of a TourStop
export type TourStopSetupHandler = (currentStopName: string, setStatePlaying: () => void) => void;

// Function that can be used during the teardown phase of a TourStop
export type TourStopTeardownHandler = (
    currentStopName: string,
    nextStopName: string,
    setStateComplete: () => void,
) => void;

export type TourStopStateHandler = (nextTourStopState: TourStopState) => void;
