import * as React from 'react';
import {Badge, Button, Switch, mergeClasses} from '@fluentui/react-components';
import {OrgSettingsIcon, OwnerSettingsIcon} from '@/components/ui/icons';
import useClasses from '../../ConnectorModalContent.styles';
import SkillRowMetadata from './SkillRowMetadata';
import {ComplianceBoundary, SkillsetDescriptor} from '@/api/skills';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import MedeinaFeatures from '@/util/features';
import {
    CompliancesNeedValidation,
    needComplianceValidation,
} from '../Utils/ConnectorModalUtilities';
import {useGetUserInfo} from '@/api/app';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {useFeatureFlag} from '@/api/user';

export type SkillRowData = {
    name: string;
    skillset: SkillsetDescriptor | undefined;
    selected: boolean;
    canConfigure: boolean;
    configured: boolean;
    isLastRow: boolean;
    componentId: string;
};

interface SkillRowProps {
    data: SkillRowData;
    componentRef: React.Ref<HTMLInputElement> | undefined;
    onOpenSettings: () => void;
    onSetUp: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    onSwitchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SkillRow({
    data,
    componentRef,
    onOpenSettings,
    onSetUp,
    onSwitchChange,
}: SkillRowProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const {name, skillset, selected, canConfigure, configured, isLastRow, componentId} = data;
    const userPluginManagementAllowed: boolean = Boolean(
        useFeatureFlag(MedeinaFeatures.IsUserPluginManagementAllowedByAdminEnabled),
    );

    let navigate = useNavigate();

    const {data: userInfo} = useGetUserInfo();
    const isGlobalAdmin = userInfo?.isGlobalAdmin ?? false;
    const isAdmin = userInfo?.isAdmin ?? false;

    const isAuthorizedToToggleConsent = MedeinaFeatures.EnableO365ConsentStepInFRE
        ? isAdmin
        : isGlobalAdmin;

    const pluginUnavailableDueToCompliance =
        MedeinaFeatures.EnableComplianceBasedPluginManagement &&
        !skillset?.canToggle &&
        CompliancesNeedValidation.includes(skillset?.compliance ?? ComplianceBoundary.None);

    const pluginUnavailableDueToAdmin =
        userPluginManagementAllowed && !skillset?.canToggle && !pluginUnavailableDueToCompliance;

    const pluginUnavailable = pluginUnavailableDueToCompliance || pluginUnavailableDueToAdmin;

    const isCompliantBasedPluginManagementEnabled =
        MedeinaFeatures.EnableComplianceBasedPluginManagement;

    const renderPluginUnavailableMessage = (label: string, message: string) => {
        return (
            <div className={mergeClasses(classes.secondaryAction, classes.complianceMessage)}>
                <MedeinaInfoLabel
                    labelContent={<Badge color="important">{label}</Badge>}
                    infoContent={message}
                ></MedeinaInfoLabel>
            </div>
        );
    };

    const renderMenuColumn = () => {
        return (
            <div className={mergeClasses(classes.secondaryAction, classes.menuCol)}>
                {canConfigure && configured && (
                    <Button
                        data-testid={'configured-skill-row-button'}
                        onClick={onOpenSettings}
                        appearance="subtle"
                        size="small"
                        icon={<OrgSettingsIcon />}
                        aria-label={t('Plugin.OpenPluginSettings', {name: name})}
                        disabled={pluginUnavailable}
                    />
                )}

                {isCompliantBasedPluginManagementEnabled &&
                    needComplianceValidation(skillset?.compliance ?? ComplianceBoundary.None) &&
                    !skillset?.canToggle &&
                    isAuthorizedToToggleConsent && (
                        <Button
                            data-testid={'owner-settings-button-in-plugin-modal'}
                            onClick={() => {
                                let path = `/owner-settings`;
                                navigate(path);
                            }}
                            appearance="subtle"
                            size="small"
                            icon={<OwnerSettingsIcon />}
                            aria-label={t('Plugin.OpenPluginSettings', {name: 'owner settings'})}
                        />
                    )}
            </div>
        );
    };

    const renderSkillsetConfigurationControls = () => {
        if (canConfigure && !configured) {
            // all skillsets that are configurable but have not been configured yet
            return (
                <Button
                    data-testid={'unconfigured-skill-row-button'}
                    className={classes.connectButton}
                    value={name}
                    onClick={onSetUp}
                    appearance="subtle"
                    size="medium"
                    aria-label={t('Plugin.SetupPluginLabel', {name: name})}
                    disabled={pluginUnavailable}
                >
                    {t('Plugin.Setup')}
                </Button>
            );
        }

        return (
            <Switch
                data-testid={`${name}-switch`}
                id={componentId}
                ref={componentRef}
                checked={selected}
                onChange={onSwitchChange}
                name={name}
                labelPosition="before"
                className={classes.switch}
                aria-label={t('Plugin.EnablePlugin', {name: name})}
                disabled={pluginUnavailable ?? false}
            />
        );
    };
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    const renderMenuMessageColumn = () => {
        if (pluginUnavailableDueToCompliance) {
            return renderPluginUnavailableMessage(
                t('ContentModal.Unavailable'),
                isAuthorizedToToggleConsent
                    ? t('ContentModal.PluginUnavailableMessageForAdmin')
                    : t('ContentModal.PluginUnavailableMessageForNormalUser'),
            );
        } else if (pluginUnavailableDueToAdmin) {
            return renderPluginUnavailableMessage(
                t('ContentModal.Restricted'),
                isWorkspacesTestingEnabled
                    ? t('WorkspacesContentModal.RestrictedMessage')
                    : t('ContentModal.RestrictedMessage'),
            );
        } else {
            return renderMenuColumn();
        }
    };

    return (
        <div className={mergeClasses(classes.skillRow, isLastRow && classes.skillRowLast)}>
            <div className={pluginUnavailable ? classes.skillRowDisabled : undefined}>
                <SkillRowMetadata skillsetDescriptor={skillset} />
            </div>

            {renderMenuMessageColumn()}

            <div className={classes.switchCol}>{renderSkillsetConfigurationControls()}</div>
        </div>
    );
}
