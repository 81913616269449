import {TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {Button, mergeClasses, useFocusFinders} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useClasses from './Error.styles';
import {WarningTourIcon} from '@/components/ui/icons';
import {motion} from 'framer-motion';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useViewportSize} from '@/components/ui/Grid';
import {LargeTitleSubheader} from '@/components/ui/Text';
import {LargeTitle} from '@/components/ui/Text';

export default function ErrorPage() {
    const tour = useTourContext();
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const {sm: isSmallScreen} = useViewportSize();

    const errorWrapper = useRef<HTMLDivElement>(null);

    const {findFirstFocusable} = useFocusFinders();
    useEffect(() => {
        if (errorWrapper?.current) {
            const firstFocusable = findFirstFocusable(errorWrapper.current);
            firstFocusable?.focus();
        }
    }, [errorWrapper]);

    const props = tour.v2TourError;
    function onBackButtonClick(): void {
        tour.setTourStep(props?.actionButtonStep || '');
    }

    const {t} = useTranslation('tours');

    return (
        <>
            <motion.div
                key="terms"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourStageContainer ref={errorWrapper}>
                    <TourStageContainer.StageContent>
                        <div aria-live="assertive">
                            <div className={tourClasses.tourSectionSpacing}>
                                <WarningTourIcon className={classes.icon}></WarningTourIcon>
                            </div>
                            <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                                <LargeTitle>
                                    {t(props?.heading ?? 'errorMessages.default.Heading')}
                                </LargeTitle>
                            </div>
                            <div className={mergeClasses(tourClasses.tourXLargeSectionSpacing)}>
                                <LargeTitleSubheader>
                                    {t(props?.message ?? 'errorMessages.default.Message')}
                                </LargeTitleSubheader>
                            </div>
                            <div>
                                {(!!props?.actionButtonStep ?? false) && (
                                    <Button
                                        appearance="primary"
                                        onClick={onBackButtonClick}
                                        size={isSmallScreen ? 'small' : 'medium'}
                                    >
                                        {t(
                                            props?.actionButtonString ??
                                                'errorMessages.default.ActionButtonString',
                                        )}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </TourStageContainer.StageContent>
                </TourStageContainer>
            </motion.div>
        </>
    );
}
