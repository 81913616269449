import {makeStyles, shorthands, tokens, typographyStyles} from '@fluentui/react-components';

/**
 * Styles for the tab list.  Necessary since we want to use Router links for
 * behavior instead of the TabList component.  This is styled and behaves
 * "almost" exactly like the TabList.
 *
 * Copied all relevant styles from:
 * https://github.com/microsoft/fluentui/blob/master/packages/react-components/react-tabs/src/components/Tab/useTabStyles.styles.ts#L31
 */
export default makeStyles({
    tabList: {
        display: 'flex',
        flexShrink: 0,
        flexWrap: 'nowrap',
        position: 'relative',
        alignItems: 'stretch',
        flexDirection: 'column',
        width: '100%',
    },

    tab: {
        alignItems: 'center',
        ...shorthands.borderColor('none'),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.borderWidth(0),
        cursor: 'pointer',
        color: tokens.colorNeutralForeground1,
        ...shorthands.textDecoration('none'),
        outlineStyle: 'none',
        position: 'relative',
        ...shorthands.overflow('hidden'),
        ...shorthands.padding(tokens.spacingVerticalSNudge, tokens.spacingHorizontalMNudge),
        textTransform: 'none',
        justifyContent: 'start',
        display: 'block',
        '::after': {
            backgroundColor: tokens.colorTransparentStroke,
            ...shorthands.borderRadius(tokens.borderRadiusCircular),
            content: '""',
            position: 'absolute',
            zIndex: 1,
            transformOrigin: 'top',
            transform:
                'translateY(var(--fui-Tab__indicator--offset)) scaleY(var(--fui-Tab__indicator--scale))',
            bottom: tokens.spacingVerticalS,
            left: 0,
            top: tokens.spacingVerticalS,
            width: tokens.strokeWidthThicker,
        },
        '::before': {
            ...shorthands.borderRadius(tokens.borderRadiusCircular),
            content: '""',
            position: 'absolute',
            zIndex: 1,
            transformOrigin: 'top',
            transform:
                'translateY(var(--fui-Tab__indicator--offset)) scaleY(var(--fui-Tab__indicator--scale))',
            bottom: tokens.spacingVerticalS,
            left: 0,
            top: tokens.spacingVerticalS,
            width: tokens.strokeWidthThicker,
        },
        ':hover::before': {
            backgroundColor: tokens.colorNeutralStroke1Hover,
            ...shorthands.borderRadius(tokens.borderRadiusCircular),
            content: '""',
            position: 'absolute',
        },
        ':active::before': {
            backgroundColor: tokens.colorNeutralStroke1Pressed,
            ...shorthands.borderRadius(tokens.borderRadiusCircular),
            content: '""',
            position: 'absolute',
        },
        '@media (forced-colors: active)': {
            ':hover::before': {
                backgroundColor: 'Highlight',
            },
            ':active::before': {
                backgroundColor: 'Highlight',
            },
        },
        ':focus': {
            boxShadow: `${tokens.shadow4}, 0 0 0 ${tokens.strokeWidthThick} ${tokens.colorStrokeFocus2}`,
        },
    },
    tabSelected: {
        '& .NavMenuTab__content': {
            color: tokens.colorNeutralForeground1,
            fontWeight: tokens.fontWeightSemibold,
        },
        ':hover .NavMenuTab__content': {
            color: tokens.colorNeutralForeground1Hover,
        },
        ':active .NavMenuTab__content': {
            color: tokens.colorNeutralForeground1Pressed,
        },
        '::after': {
            backgroundColor: tokens.colorCompoundBrandStroke,
        },
        ':hover::after': {
            backgroundColor: tokens.colorCompoundBrandStrokeHover,
        },
        ':active::after': {
            backgroundColor: tokens.colorCompoundBrandStrokePressed,
        },
        '@media (forced-colors: active)': {
            '::after': {
                backgroundColor: 'ButtonText',
            },
            ':hover::after': {
                backgroundColor: 'ButtonText',
            },
            ':active::after': {
                backgroundColor: 'ButtonText',
            },
        },
    },
    tabContent: {
        ...typographyStyles.body1,
        ...shorthands.overflow('hidden'),
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXXS),
    },
});
