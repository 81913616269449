import {LinkParameters} from '@/components/App.types';
import {Link} from '@fluentui/react-components';

export interface ResourceLinkProps {
    displayName: string;
    linkAttribute?: LinkParameters;
    dataTestId: string;
}

export default function ResourceLink(props: ResourceLinkProps) {
    return (
        <div>
            <span>{props.displayName}</span>
            <span>
                {!!props.linkAttribute && (
                    <Link
                        href={props.linkAttribute.url}
                        target={props.linkAttribute.target}
                        data-test-id={props.dataTestId}
                    >
                        {props.linkAttribute.text}
                    </Link>
                )}
            </span>
        </div>
    );
}
