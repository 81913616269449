import {ExternalLinkIcon} from '@/components/ui/icons';
import useClasses from './AzureResourceLinks.styles';
import {Button, useFocusFinders} from '@fluentui/react-components';
import {ResourceLink} from '.';
import useGetCapacityParameters from '@/util/useGetCapacityParameters';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useEffect, useRef} from 'react';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {useTranslation} from 'react-i18next';

interface AzureResourceLinksProps {
    onSwitchCapacityButtonClick: () => void;
    shouldFocusOnSwitchCapacityButton: boolean;
    onSwitchCapacityButtonBlur: () => void;

    // If true, the component will not automatically focus on the first focusable element
    // This is useful when the parent component wants to control the focus, or a dialog wants to persist focus
    suppressAutoFocus?: boolean;
}

export default function AzureResourceLinks(props: AzureResourceLinksProps) {
    const classes = useClasses();
    const {capacityId} = useWorkspaceState();
    const tenantId = getCurrentTenantId();

    const capacityParameters = useGetCapacityParameters({
        capacityId: capacityId ?? '',
        tenantId: tenantId,
    });

    const linkValues = capacityParameters.links;
    const {t} = useTranslation('admin');
    const azureResourceLinksWrapper = useRef<HTMLDivElement>(null);
    const switchCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const {findFirstFocusable} = useFocusFinders();

    useEffect(() => {
        if (azureResourceLinksWrapper?.current && !props.suppressAutoFocus) {
            const firstFocusable = findFirstFocusable(azureResourceLinksWrapper.current);
            firstFocusable?.focus();
        }
    }, [azureResourceLinksWrapper]);

    useEffect(() => {
        if (props.shouldFocusOnSwitchCapacityButton && switchCapacityButtonRef.current) {
            switchCapacityButtonRef.current.focus();
        }
    }, [props.shouldFocusOnSwitchCapacityButton]);

    return (
        <div ref={azureResourceLinksWrapper}>
            <h2 className={classes.subtitle}>
                {t('ownerSettings.azureResourceLinks.SectionHeading')}
            </h2>
            <div className={classes.resourceLinksContainer}>
                <ResourceLink
                    linkAttribute={linkValues.capacity}
                    displayName={t('ownerSettings.azureResourceLinks.CapacityName')}
                    dataTestId="capacity-link"
                ></ResourceLink>
                <ResourceLink
                    linkAttribute={linkValues.subscription}
                    displayName={t('ownerSettings.azureResourceLinks.SubscriptionID')}
                    dataTestId="subscription-link"
                ></ResourceLink>
                <ResourceLink
                    linkAttribute={linkValues.resourceGroup}
                    displayName={t('ownerSettings.azureResourceLinks.ResourceGroup')}
                    dataTestId="resource-group-link"
                ></ResourceLink>
            </div>
            <div className={classes.buttonWrapper}>
                <Button
                    className={classes.switchCapacityBtn}
                    ref={switchCapacityButtonRef}
                    onClick={props.onSwitchCapacityButtonClick}
                    onBlur={props.onSwitchCapacityButtonBlur}
                >
                    {t('ownerSettings.azureResourceLinks.SwitchCapacityButtonText')}
                </Button>
                <Button
                    icon={<ExternalLinkIcon />}
                    iconPosition="after"
                    href={linkValues.billingTabUrl.url}
                    as="a"
                    target="_blank"
                    data-test-id="manage-billing-button"
                >
                    {t('ownerSettings.azureResourceLinks.ManageBillingButtonText')}
                </Button>
            </div>
        </div>
    );
}
