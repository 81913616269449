import {Button, mergeClasses} from '@fluentui/react-components';
import useClasses from './PromptSuggestions.styles';
import {useGetEvaluation} from '@/api/evaluations';
import {useCallback, useEffect, useState} from 'react';
import {PromptCategory, PromptType, useGetPrompt} from '@/api/prompts';
import {PromptSuggestion, PromptSuggestionsProps} from './PromptSuggestions.types';
import {useAppState, ColorScheme} from '@/api/app';
import {motion} from 'framer-motion';

export default function PromptSuggestions({
    sessionId,
    promptId,
    evaluationId,
    onSubmit,
}: PromptSuggestionsProps) {
    const classes = useClasses();
    const {colorScheme} = useAppState();
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});
    const [suggestedPrompts, setSuggestedPrompts] = useState<PromptSuggestion[]>([]);

    const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);

    useEffect(() => {
        const handleResize = () => {
            setZoomLevel(window.devicePixelRatio);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (evaluation?.result?.suggestedPrompts) {
            setSuggestedPrompts(evaluation.result.suggestedPrompts);
        }
    }, [evaluation]);

    const container = document.getElementById('pillcontainer') as HTMLElement;
    const buttons = Array.from(document.getElementsByClassName('pill')) as HTMLButtonElement[];

    const handlesubmit = useCallback(
        (suggestion: string) => {
            onSubmit?.({
                promptType: PromptType.Prompt,
                promptCategory: PromptCategory.SystemGenerated,
                content: suggestion,
            });
        },
        [onSubmit],
    );

    useEffect(() => {
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].style.display = 'block';
            if (
                buttons[i].offsetHeight + buttons[i].offsetTop >
                container?.offsetTop + container?.offsetHeight
            ) {
                buttons[i].style.display = 'none';
            }
        }
    }, [container?.offsetHeight, zoomLevel, suggestedPrompts]);

    return (
        <div className={classes.pillcontainer} id="pillcontainer">
            {!!suggestedPrompts ? (
                <>
                    {suggestedPrompts.map((suggestion, key) => {
                        return (
                            <motion.span
                                className={mergeClasses(classes.pillContainer, 'pill')}
                                key={key}
                                initial={{opacity: 0}} // Start from transparent and slightly above
                                animate={{opacity: 1}} // Animate to fully visible and in position
                                transition={{duration: 0.3}}
                            >
                                <Button
                                    appearance="outline"
                                    className={mergeClasses(
                                        classes.pill,
                                        colorScheme === ColorScheme.Light
                                            ? classes.lightTheme
                                            : classes.darkTheme,
                                    )}
                                    onClick={() => handlesubmit(suggestion.prompt)}
                                >
                                    <span className={classes.buttonContentStyles}>
                                        {suggestion.prompt}
                                    </span>
                                </Button>
                            </motion.span>
                        );
                    })}
                </>
            ) : (
                <></>
            )}
        </div>
    );
}
