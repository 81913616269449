import React, {useState, useCallback, useMemo} from 'react';
import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItemRadio,
    MenuPopover,
    MenuProps,
    MenuOpenEvent,
    MenuOpenChangeData,
} from '@fluentui/react-components';
import {DownIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {formatLocaleDate as format} from '@/util/locale';

import {
    SearchFilterMenuProps,
    DateRange,
    DateRangeOptions,
    DateRangeOption,
} from './SearchFilter.types';
import CustomDateRangeEditor from './CustomDateRangeEditor';

// menu button that provides it's own open state and calendar component in popover state
function SearchFilterMenu(props: SearchFilterMenuProps) {
    const {t} = useTranslation('mysessions');
    const {filterValue, onSetFilterValue, filterLabel} = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // add a second state to keep track of the selected menu option selected
    const [checkedMenuItem, setCheckedMenuItem] = useState<Record<string, string[]>>({date: []});
    // when the menu option is 'Custom date range' we want to render a calendar component in the popover that we manually render
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    // date keeps track of the new value that the user wants to submit, it is initialized with the current filter value
    const [date, setDate] = useState(filterValue);
    // our menu options exist in the enum DateRangeOptions
    const menuOptions = Object.values(DateRangeOptions);

    const resetMenus = () => {
        setIsMenuOpen(false);
        setIsCalendarOpen(false);
    };

    const handleOnOpenChange = useCallback(
        (_e: MenuOpenEvent, data: MenuOpenChangeData) => {
            // if the calendar is open don't change the menu state
            if (isCalendarOpen) return;
            if (data.type !== 'menuItemClick') {
                setIsMenuOpen(data.open);
            }
        },
        [isCalendarOpen],
    );

    const handleCustomDateRangeChange = (dateRange: DateRange) => {
        setDate(dateRange);
        onSetFilterValue(dateRange);
        resetMenus();
    };

    const handleMenuSelection: MenuProps['onCheckedValueChange'] = (_e, {name, checkedItems}) => {
        setCheckedMenuItem({date: checkedItems});
        if (checkedItems.includes(DateRangeOptions.CustomDateRange)) {
            setIsCalendarOpen(true);
        } else {
            // reset the calendar state in case the user had it open
            setIsCalendarOpen(false);
            setIsMenuOpen(false);
            onSetFilterValue(checkedItems[0] as DateRangeOption);
        }
    };

    // when the calendar pop over is open if the user presses escape we want to close the popover and unselect the menu option

    const buttonText = useMemo(() => {
        return typeof filterValue === 'object'
            ? `${filterLabel}: ${format(filterValue.startDate, 'yyyy-MM-dd')} to ${format(
                  filterValue.endDate,
                  'yyyy-MM-dd',
              )}`
            : `${filterLabel}: ${t(filterValue)}`;
    }, []);

    return (
        <>
            <Menu
                open={isMenuOpen}
                checkedValues={checkedMenuItem}
                onCheckedValueChange={handleMenuSelection}
                onOpenChange={handleOnOpenChange}
            >
                <MenuTrigger disableButtonEnhancement>
                    <Button icon={<DownIcon />} iconPosition="after" shape="circular">
                        {buttonText}
                    </Button>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        {/* for normal filter options render the menu item directly, for custom date range render a MenuItem that calls a popover */}
                        {menuOptions.map((option, key) => {
                            return (
                                // check if the Menu option is currently active
                                <MenuItemRadio name="date" value={option} key={key}>
                                    {t(option)}
                                </MenuItemRadio>
                            );
                        })}
                    </MenuList>
                </MenuPopover>
            </Menu>
            <CustomDateRangeEditor
                onDismiss={() => setIsCalendarOpen(false)}
                onDateRangeChange={handleCustomDateRangeChange}
                dateRange={date}
                isOpen={isCalendarOpen}
            />
        </>
    );
}

export default SearchFilterMenu;
