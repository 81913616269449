import {createElement, useMemo} from 'react';
import useTableProps from '../useTableProps';

export function useCompactTableProps({...props}) {
    /*TODO:: Need to make this configurable */
    const compactTableColumnCount = 3;
    const compactTableRowCount = 4;

    var {fetchSectionFromProps, hasDataRows} = useTableProps(props);

    const regenerateColumns = function () {
        const headItem = fetchSectionFromProps('thead');
        const columnRow = headItem.props.children[0];
        const reducedColumns = columnRow.props.children.slice(0, compactTableColumnCount);
        const regeneratedColumnRow = createElement(
            columnRow.type,
            {...columnRow.props, key: columnRow.key},
            reducedColumns,
        );

        const regeneratedHeadItem = createElement(
            headItem.type,
            {...headItem.props, key: headItem.key},
            [regeneratedColumnRow],
        );

        return regeneratedHeadItem;
    };

    const regenerateRows = function () {
        if (!hasDataRows()) {
            return null;
        }

        var dataRowArray = [];

        const bodyItem = fetchSectionFromProps('tbody');
        const dataCount = bodyItem.props.children.length;
        const reducedDataCount = Math.min(dataCount, compactTableRowCount);

        for (var i = 0; i < reducedDataCount; i++) {
            var rowItem = bodyItem.props.children[i];
            var columnItems = rowItem.props.children.slice(0, compactTableColumnCount);

            var regeneratedRowItem = createElement(
                rowItem.type,
                {...rowItem.props, key: rowItem.key},
                columnItems,
            );
            dataRowArray.push(regeneratedRowItem);
        }

        var regeneratedBodyItem = createElement(
            bodyItem.type,
            {...bodyItem.props, key: bodyItem.key},
            dataRowArray,
        );

        return regeneratedBodyItem;
    };

    var compactTableProps = useMemo(() => {
        return {
            node: props.node,
            children: [regenerateColumns(), regenerateRows()],
        };
    }, [props]);

    return compactTableProps;
}
