import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {evaluationResultProperties} from './Evaluation.styles';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground4,
        color: tokens.colorNeutralForeground4,
        fontSize: tokens.fontSizeBase200,
        ...evaluationResultProperties,
    },
    caption: {
        color: tokens.colorNeutralForeground3,
        fontWeight: tokens.fontWeightSemibold,
    },
    captionMetadata: {
        color: tokens.colorNeutralForeground4,
        fontSize: tokens.fontSizeBase200,
    },
});
