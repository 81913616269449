import {useMutation} from '@tanstack/react-query';
import useSessionsFetch from '../useSessionsFetch';
import {
    GetInvestigationExportMailRequest,
    InvestigationExportMailRequest,
} from './evaluations.types';

export default function useExportInvestigationSummaryEmail({
    sessionId,
}: GetInvestigationExportMailRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useMutation({
        mutationFn: async (body: InvestigationExportMailRequest) => {
            await sessionsFetch<boolean>(
                `${sessionId}/pinnedevaluations/exportPromptSummary/email`,
                {
                    method: 'POST',
                    body,
                },
            );
        },
    });
}
