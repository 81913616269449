import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {TenantInfo} from './tenant.types';

export default function useGetTenantInfoForAdminFre(
    useQueryOptions?: Pick<UseQueryOptions<TenantInfo>, 'enabled'>,
) {
    const {customFetch} = useFetch();

    return useQuery(['settings', 'datashare-presetup'], {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        queryFn: async () => {
            const result = await customFetch<Response>(
                `/settings/datashare`,
                {
                    method: 'GET',
                },
                true,
            );

            if (!result.ok && result.status === 404) {
                return null;
            }

            var data = await result.json();
            return data as TenantInfo;
        },
        retry: 0,
        ...useQueryOptions,
    });
}
