export const MSW_NAMESPACE = 'medeina_msw';

// These map to the scenario handlers in the scenarioHandlers directory
export const MedeinaScenarios = [
    'helloWorld',
    'multiWorkspaces',
    'default',
    'FRESetupForm',
    'usageDashboardV2',
    'owner',
    'contributor',
    'FREGANewTenant',
];

export const ScenarioFeatureFlagMap: Record<string, string> = {
    // workspaces is the only feature that has a scenario associated with it
    WorkspacesEnabled: MedeinaScenarios[1],

    // hello world is an api demo
    HelloWorld: MedeinaScenarios[0],

    FRESetupForm: MedeinaScenarios[3],
    /** Testing of the full FRE flow, GA version prior to geo/pod changes*/
    FREGANewTenant: MedeinaScenarios[7],

    ViewUsageDashboardV2: MedeinaScenarios[4],
};
