import useClasses from './MemberSearch.styles';
import {Combobox, Spinner, Option, useFocusFinders, mergeClasses} from '@fluentui/react-components';
import {motion} from 'framer-motion';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {MemberListItemProps, MemberType} from '../rbac.types';
import MemberListItem from '../MemberListItem/MemberListItem';
import {useGetRoleMemberByName} from '@/api/rbac';
import useDebounce from '@/util/useDebounce';
import {useTranslation} from 'react-i18next';
import {EVERYONE_IDENTIFIER, GDAP_SEARCH_IDENTIFIER} from '@/api/rbac/rbac.constants';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';

interface MemberSearchProps {
    onMemberSearchStarted: () => void;
    onMemberSelected: (memberData: MemberListItemProps) => void;
    onMemberSearchError: () => void;
    isSearchBarFocused: boolean;
}

export default function MemberSearch(props: MemberSearchProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const memberSearchComboBoxRef = useRef<HTMLInputElement>(null);
    const [matchedMembers, setMatchedMembers] = useState<MemberListItemProps[]>([]); // MemberListItemProps[]
    const [searchKey, setSearchKey] = useState('');
    const debouncedSearch = useDebounce(searchKey, 300);

    const isInvalidKey = () => {
        return (
            debouncedSearch !== EVERYONE_IDENTIFIER &&
            debouncedSearch !== GDAP_SEARCH_IDENTIFIER &&
            debouncedSearch.length <= 3
        );
    };

    const {
        data: memberData,
        isLoading: memberDataLoading,
        isFetched: memberDataFetched,
        isSuccess: memberDataSuccess,
        isError: memberDataError,
        isRefetching: memberDataRefetching,
    } = useGetRoleMemberByName(debouncedSearch, {
        enabled: !isInvalidKey(),
    });

    // To show error message if error occur on searching any member
    useEffect(() => {
        if (memberDataError) {
            props.onMemberSearchError();
        }
    }, [memberDataFetched]);

    // To focus searchbar based on props.isSearchBarFocused value
    useEffect(() => {
        if (props.isSearchBarFocused && !!memberSearchComboBoxRef?.current) {
            memberSearchComboBoxRef.current?.focus();
        }
    }, [props.isSearchBarFocused]);

    // Based on data fetched from Graph API , filter the data in users,roles and groups, which will combine in memberList which will set  the matchedMembers state variable.
    useEffect(() => {
        const memberList: MemberListItemProps[] = [];

        if (memberDataSuccess && memberData) {
            const data = memberData?.details;
            const users = data?.User?.map(
                (user) =>
                    ({
                        memberId: user.id as string,
                        name: user.displayName,
                        email: user.mail,
                        type: MemberType.User,
                    } as MemberListItemProps),
            );

            !!users && memberList.push(...users);

            const groups = data?.Group?.map(
                (group) =>
                    ({
                        memberId: group.id as string,
                        name: group.displayName,
                        memberCount: group.memberCount,
                        type: MemberType.Group,
                    } as MemberListItemProps),
            );

            !!groups && memberList.push(...groups);

            const roles = data?.Role?.map(
                (role) =>
                    ({
                        memberId: role.id as string,
                        name: role.displayName,
                        type: MemberType.Role,
                    } as MemberListItemProps),
            );

            !!roles && memberList.push(...roles);
            setMatchedMembers(memberList);
        }
    }, [memberDataLoading, memberDataRefetching, memberData]);

    // Handle the event when search input value is changed
    const handleMemberSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setMatchedMembers([]);
        props.onMemberSearchStarted();
        setSearchKey(event.target.value.trim());
    };

    // Handle the event when a member is selected from the searched list
    const onMemberSelected = (_: any, data: any): void => {
        const match = matchedMembers.find((member) => member.memberId === data.optionValue);
        props.onMemberSelected(match as MemberListItemProps);
    };

    const isSearchDataBeingLoaded = () => memberDataLoading || memberDataRefetching;
    const {t} = useTranslation('admin');

    return (
        <>
            <Combobox
                className={classes.searchBox}
                freeform
                clearable
                aria-label={t('RoleAssignment.AddRoleMembers.AriaLabels.SearchMember')}
                placeholder={t('RoleAssignment.AddRoleMembers.SearchMemberPlaceholder', {
                    0: EVERYONE_IDENTIFIER,
                })}
                autoComplete="off"
                onChange={handleMemberSearchChange}
                onOptionSelect={onMemberSelected}
                ref={memberSearchComboBoxRef}
                listbox={{
                    className: mergeClasses(classes.listBox, scrollClasses.colorNeutralBackground1),
                }}
            >
                {!isInvalidKey() && isSearchDataBeingLoaded() && (
                    <motion.div
                        className={classes.loader}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0, duration: 0.3}}
                        exit={{opacity: 0, transition: {duration: 0.3}}}
                        key="Loading"
                    >
                        <Spinner
                            labelPosition="after"
                            label={t('RoleAssignment.AddRoleMembers.LoadingMembers')}
                            size="small"
                        />
                    </motion.div>
                )}
                {!isSearchDataBeingLoaded() &&
                    matchedMembers &&
                    matchedMembers.length > 0 &&
                    matchedMembers.map((member) => (
                        <Option
                            data-test-id={`member-search-list-${member.memberId}`}
                            key={member.memberId}
                            value={member.memberId}
                            text={member.name}
                        >
                            <MemberListItem {...member} hideLinks={false}></MemberListItem>
                        </Option>
                    ))}
            </Combobox>
        </>
    );
}
