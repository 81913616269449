import * as React from 'react';
const StarStruck = (props: any) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)">
            <path
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14 0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                fill="url(#b)"
            />
            <path
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14 0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                fill="url(#c)"
            />
            <path
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14 0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                fill="url(#d)"
                fillOpacity={0.6}
            />
            <path
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14 0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                fill="url(#e)"
            />
            <path
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14 0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                fill="url(#f)"
            />
            <path
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14 0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                fill="url(#g)"
            />
            <path
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14 0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                fill="url(#h)"
            />
            <g filter="url(#i)">
                <path d="M25.5 18 19 12.5l6-1.5.5 7Z" fill="url(#j)" />
            </g>
            <g filter="url(#k)">
                <path d="M13.5 14.5 7 14l4.5-4 2 4.5Z" fill="url(#l)" />
            </g>
            <g filter="url(#m)">
                <path d="M16.5 6h2c1.6 1.6 1.333 4.667 1 6l-3 1L18 9.5 16.5 6Z" fill="url(#n)" />
            </g>
            <g filter="url(#o)">
                <path d="M3 8h2c1.6 1.6 1.333 4.667 1 6l-3 2 1.5-4.5L3 8Z" fill="url(#p)" />
            </g>
            <path
                d="m8.624 12.865-3.05 2.372a.15.15 0 0 1-.242-.132l.3-3.285a2.25 2.25 0 0 0-1.149-2.171L2.5 8.548a.5.5 0 0 1 .058-.902l2.529-1.012a2.25 2.25 0 0 0 1.37-1.647l.472-2.359a.47.47 0 0 1 .837-.19l1.364 1.82a2.25 2.25 0 0 0 2.004.89l3.493-.318a.15.15 0 0 1 .133.24l-1.786 2.38a2.25 2.25 0 0 0-.289 2.186l1.412 3.528-3.65-.73a2.25 2.25 0 0 0-1.823.43Z"
                fill="url(#q)"
                stroke="url(#r)"
                strokeWidth={0.5}
            />
            <path
                d="m23.376 12.865 3.05 2.372a.15.15 0 0 0 .242-.132l-.3-3.285a2.25 2.25 0 0 1 1.149-2.171L29.5 8.548a.5.5 0 0 0-.058-.902l-2.529-1.012a2.25 2.25 0 0 1-1.37-1.647l-.472-2.359a.47.47 0 0 0-.837-.19l-1.364 1.82a2.25 2.25 0 0 1-2.004.89l-3.493-.318a.15.15 0 0 0-.133.24l1.786 2.38a2.25 2.25 0 0 1 .289 2.186l-1.412 3.528 3.65-.73a2.25 2.25 0 0 1 1.823.43Z"
                fill="url(#s)"
                stroke="url(#t)"
                strokeWidth={0.5}
            />
            <path d="M16 26c-9 0-9-9-9-9h18s0 9-9 9Z" fill="url(#u)" />
            <path d="M8 17.5V17h16v.5a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" fill="url(#v)" />
        </g>
        <defs>
            <radialGradient
                id="b"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(131.878 10.74 10.193) scale(38.9487)"
            >
                <stop stopColor="#FFF478" />
                <stop offset={0.475} stopColor="#FFB02E" />
                <stop offset={1} stopColor="#F70A8D" />
            </radialGradient>
            <radialGradient
                id="c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(101.31 2.876 12.808) scale(17.8466 22.8581)"
            >
                <stop offset={0.788} stopColor="#F59639" stopOpacity={0} />
                <stop offset={0.973} stopColor="#FF7DCE" />
            </radialGradient>
            <radialGradient
                id="d"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(-29 29 -29 -29 18 14)"
            >
                <stop offset={0.315} stopOpacity={0} />
                <stop offset={1} />
            </radialGradient>
            <radialGradient
                id="e"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(77.692 -2.555 18.434) scale(28.1469)"
            >
                <stop offset={0.508} stopColor="#7D6133" stopOpacity={0} />
                <stop offset={1} stopColor="#715B32" />
            </radialGradient>
            <radialGradient
                id="f"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(7.5 10.99996 -7.97335 5.4364 16.5 16.5)"
            >
                <stop stopColor="#FFB849" />
                <stop offset={1} stopColor="#FFB847" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="g"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(11.49998 2 -2 11.49998 20.5 18)"
            >
                <stop stopColor="#FFA64B" />
                <stop offset={0.9} stopColor="#FFAE46" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="h"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(43.971 -9.827 29.173) scale(59.0529)"
            >
                <stop offset={0.185} stopOpacity={0} />
                <stop offset={1} stopOpacity={0.4} />
            </radialGradient>
            <radialGradient
                id="n"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(-4.5 3 -3 -4.5 20 10)"
            >
                <stop offset={0.116} stopColor="#EC812A" />
                <stop offset={1} stopColor="#FBA84C" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="p"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(158.199 2.248 7.174) scale(5.38516)"
            >
                <stop stopColor="#CC7521" />
                <stop offset={1} stopColor="#E6872B" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="u"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(0 -11 22 0 16 28)"
            >
                <stop stopColor="#F70A8D" />
                <stop offset={1} stopColor="#89029C" />
            </radialGradient>
            <linearGradient
                id="j"
                x1={23.5}
                y1={11.5}
                x2={21.814}
                y2={14.667}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.37} stopColor="#EC812A" />
                <stop offset={1} stopColor="#FBA84C" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="l"
                x1={10.5}
                y1={11.5}
                x2={10.25}
                y2={14.025}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#C85F27" />
                <stop offset={1} stopColor="#C85F27" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="q" x1={7.5} y1={1} x2={10} y2={15} gradientUnits="userSpaceOnUse">
                <stop stopColor="#7912B2" />
                <stop offset={1} stopColor="#CF1DA1" />
            </linearGradient>
            <linearGradient id="r" x1={15} y1={6} x2={8.5} y2={8.5} gradientUnits="userSpaceOnUse">
                <stop stopColor="#A82D9C" />
                <stop offset={1} stopColor="#BF33B1" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="s"
                x1={22}
                y1={2.5}
                x2={22.5}
                y2={14.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7912B2" />
                <stop offset={1} stopColor="#CF1DA1" />
            </linearGradient>
            <linearGradient
                id="t"
                x1={29.5}
                y1={6}
                x2={23.5}
                y2={6.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B51AA6" />
                <stop offset={1} stopColor="#BF33B1" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="v" x1={16} y1={17} x2={16} y2={18.5} gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5A8FE" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <filter
                id="i"
                x={18}
                y={10}
                width={8.5}
                height={9}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.5} result="effect1_foregroundBlur_23_461" />
            </filter>
            <filter
                id="k"
                x={6}
                y={9}
                width={8.5}
                height={6.5}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.5} result="effect1_foregroundBlur_23_461" />
            </filter>
            <filter
                id="m"
                x={15.5}
                y={5}
                width={5.253}
                height={9}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.5} result="effect1_foregroundBlur_23_461" />
            </filter>
            <filter
                id="o"
                x={2}
                y={7}
                width={5.253}
                height={10}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.5} result="effect1_foregroundBlur_23_461" />
            </filter>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h32v32H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default StarStruck;
