import {useMutation} from '@tanstack/react-query';
import {CreateResourceGroupRequest} from './user.types';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';
import handleError from '../handleError';

export default function useCreateResourceGroup() {
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (request: CreateResourceGroupRequest) => {
            const response = await customFetch<Response>(
                `${MedeinaVariables.ArmUri}/subscriptions/${request.subscriptionId}/resourcegroups/${request.resourceGroupName}?api-version=2021-04-01`,
                {
                    method: 'PUT',
                    scopes: RESOURCE_SCOPES.ARM,
                    body: {
                        location: request.location,
                    },
                },
                true,
            );
            if (response.status === 201 || response.status === 200) {
                return true;
            } else {
                handleError(response, 'Failed to create resource group');
            }
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
