import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import {useState} from 'react';
import useClasses from './DeletePromptbookDialog.styles';
import {DismissIcon} from '@/components/ui/icons';
import {
    DeletePromptbookRequest,
    PromptbookSelectionType,
    useDeletePromptbooks,
} from '@/api/promptbooks';
import {useTranslation} from 'react-i18next';

// This opens up a delete model for promptbook. Both Single delete promptbook delete and multiple promptbooks delete are supported
export default function DeletePromptbookDialog({
    promptbook = undefined,
    promptbookId = undefined,
    promptbookIds,
    open,
    onClose = () => null,
    onSuccess = () => null,
}: DeletePromptbookRequest) {
    const classes = useClasses();
    const {mutate: deletePromptbook, isLoading: isLoading} = useDeletePromptbooks();
    const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
    const {t} = useTranslation('promptbooks');
    const {t: tCommon} = useTranslation('common');
    const selectionType =
        promptbookId !== undefined || promptbookIds === undefined || promptbookIds.length <= 1
            ? PromptbookSelectionType.Single
            : PromptbookSelectionType.Multiple;

    const deleteContent = selectionType === PromptbookSelectionType.Single ? promptbook?.name : '';
    return (
        <Dialog
            open={open}
            onOpenChange={(event, data) => {
                setIsOpen(data.open);
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    onClose?.();
                }
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label={tCommon('ButtonLabels.Close')}
                                    icon={<DismissIcon />}
                                    onClick={() => onClose?.()}
                                />
                            </DialogTrigger>
                        }
                    >
                        {selectionType === PromptbookSelectionType.Single
                            ? t('DeleteDialogTitleSingle')
                            : t('DeleteDialogTitleMultiple', {
                                  promptbookCount: promptbookIds?.length ?? '0',
                              })}
                    </DialogTitle>
                    <DialogContent>
                        {selectionType === PromptbookSelectionType.Single
                            ? t('DeleteDialogContentSingle', {promptbookName: promptbook?.name})
                            : t('DeleteDialogContentMultiple')}
                    </DialogContent>
                    <DialogActions position="start" className={classes.actionButton}>
                        <Button
                            data-testid="delete-button"
                            appearance="primary"
                            disabled={isLoading}
                            onClick={() => {
                                if (promptbookIds !== undefined) {
                                    promptbookIds = [promptbookId!];
                                }

                                deletePromptbook({promptbookIds}, {onSuccess});
                            }}
                        >
                            {tCommon('ButtonLabels.Delete')}
                        </Button>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                data-testid="cancel-button"
                                appearance="secondary"
                                onClick={() => onClose?.()}
                            >
                                {t('CancelButton')}
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
