import React, {useRef, useEffect} from 'react';
import {motion} from 'framer-motion';
import {mergeClasses, Button, useFocusFinders} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useTourContext} from '@/components/ui/Tour';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {GridItem} from '@/components/ui/Grid';

import useClasses from './Welcome.styles';

function Terms() {
    const tourClasses = useTourClasses();
    const {t} = useTranslation('tours');
    const mainRef = useRef(null);
    const {findFirstFocusable} = useFocusFinders();

    useEffect(() => {
        if (mainRef.current === null) return;
        const firstFocusableElement = findFirstFocusable(mainRef.current);
        firstFocusableElement?.focus();
    }, [mainRef]);

    const tour = useTourContext();
    const classes = useClasses();
    return (
        <motion.div
            key="terms"
            variants={overlayAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <GridItem
                sm={12}
                md={12}
                lg={12}
                xlg={12}
                xxlg={12}
                xxxlg={12}
                className={tourClasses.tourContainerContent}
            >
                <div
                    className={mergeClasses(tourClasses.centeredText, classes.textWrapper)}
                    ref={mainRef}
                >
                    <h1 className={mergeClasses(tourClasses.blockTitle, classes.title)}>
                        {t('adminV2Fre.welcome.Heading')}
                    </h1>
                    <p className={tourClasses.blockText}>Let&apos;s get you two acquainted.</p>

                    <span className={classes.buttonRow}>
                        <Button
                            onClick={tour.nextStop}
                            className={mergeClasses(
                                tourClasses.paddedButton,
                                tourClasses.brandButtonNoBorder,
                            )}
                            appearance="primary"
                            size="large"
                            tabIndex={0}
                        >
                            {t('adminV2Fre.welcome.GetStarted')}
                        </Button>
                    </span>
                </div>
            </GridItem>
        </motion.div>
    );
}

export default Terms;
