import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        position: 'relative',
    },
    stickyBox: {
        position: 'sticky',
        top: '0',
        height: 'auto',
        maxHeight: 'min-content',
        flexGrow: 0,
    },
    nonStickyBox: {
        position: 'relative',
        flexGrow: 1,
    },
    topSection: {
        ...shorthands.padding(
            tokens.spacingVerticalXL,
            tokens.spacingHorizontalXXXL,
            '0px',
            tokens.spacingHorizontalXXXL,
        ),
        textOverflow: 'ellipsis',
        msTextOverflow: 'ellipsis',
    },
    toolbar: {
        ...shorthands.flex(0, 0, 'auto'),
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: tokens.colorTransparentBackground,
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalXXXL),
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground5),
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '48px',
    },
    pinIcon: {
        paddingRight: tokens.spacingHorizontalSNudge,
    },
    investigationTitle: {
        lineHeight: tokens.lineHeightBase600,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
        // this gives us a max limit of 4 lines for the title
        maxHeight: '116px',
        fontSize: tokens.fontSizeBase500,
        ...shorthands.overflow('hidden'),
    },
    tagsList: {
        ...shorthands.padding(
            tokens.strokeWidthThin,
            tokens.spacingVerticalM,
            tokens.strokeWidthThicker,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: 'auto',
        display: 'inline-block',
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.margin(tokens.spacingHorizontalXS),
        fontSize: tokens.fontSizeBase100,
    },
    summaryTags: {
        ...shorthands.margin(tokens.spacingHorizontalS, '0px', tokens.spacingHorizontalXXS, '-8px'),
    },
    metadata: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin('15px', '24px', '0', '0'),
    },
    metadataItem: {
        ...shorthands.margin('0', tokens.spacingHorizontalXXL, '0', '0'),
    },
    tabList: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        ...shorthands.padding('0px'),
        rowGap: tokens.spacingHorizontalXL,
    },
    firstTab: {
        ...shorthands.margin('0px', '0px', '0px', '-10px'),
    },
    tabContentContainer: {
        flexGrow: 1,
        flexShrink: 1,
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        // medium screens
        '@media (min-width: 480px) and (max-width: 639px)': {
            // we don't want to hide the overflow, we want to render it so that the entire content is visible and the parent handles scrolling
            ...shorthands.overflow('revert'),
        },
        // small screens
        '@media (max-width: 479px)': {
            ...shorthands.overflow('revert'),
        },
    },
    tabContent: {
        flexGrow: 1,
        ...shorthands.overflow('auto'),

        // medium screens
        '@media (min-width: 480px) and (max-width: 639px)': {
            // we don't want to hide the overflow, we want to render it so that the entire content is visible and the parent handles scrolling
            ...shorthands.overflow('revert'),
        },
        // small screens
        '@media (max-width: 479px)': {
            ...shorthands.overflow('revert'),
        },
    },
    paddedContent: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
    },
    pinnedContent: {
        // pinned content contains padding we want to undo
        ...shorthands.margin(tokens.spacingVerticalXL, `-${tokens.spacingHorizontalXXXL}`),
        wordBreak: 'break-word',
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    noPinnedContent: {
        ...shorthands.margin(tokens.spacingVerticalXXXL, tokens.spacingHorizontalXXXL),
    },
    actionWrapper: {
        '& button.fui-Button': {
            marginLeft: '-14px',
            justifyContent: 'flex-start',
        },
    },
    summaryContent: {
        textAlign: 'justify',
    },
    tagsUnorderedList: {
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
    },
    pinBoardHeading: {
        ...shorthands.margin(0),
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
    },
});
