import {useQuery} from '@tanstack/react-query';
import useSessionsFetch from '../useSessionsFetch';
import {GetSessionRequest, Session} from './sessions.types';

export default function useGetSession({sessionId}: GetSessionRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useQuery({
        queryKey: ['session', sessionId],
        refetchOnWindowFocus: false,
        queryFn: async () => {
            return await sessionsFetch<Session>(`${sessionId}`, {method: 'GET'});
        },
    });
}
