import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

/**
 * Styles for the menu tab list.
 */
export default makeStyles({
    root: {
        marginBottom: '36px',

        '& > div:first-child': {
            fontSize: tokens.fontSizeBase200,
        },

        '& > a': {
            ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalSNudge),
        },

        '& > a:not(:last-child) ': {
            marginBottom: tokens.spacingVerticalM,
        },
    },
    heading: {
        marginLeft: tokens.spacingHorizontalM,
    },
    navMenuListContainer: {
        display: 'flex',
        flexShrink: 0,
        flexWrap: 'nowrap',
        position: 'relative',
        alignItems: 'stretch',
        flexDirection: 'column',
        width: '100%',
    },
});
