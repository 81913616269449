/** Helper function that checks for new MSG Geo names and remaps them to Security Copilot documented names
 * This is necessary because our customers expect region based locations and the new MSG change for continent based names would change this
 * if left as is
 */
export default function mapGeo(geo: string) {
    return nameExceptions[geo] || geo;
}

const nameExceptions: Record<string, string> = {
    Singapore: 'Asia',
    Ireland: 'Europe',
    'South Korea': 'Korea',
};
