import React from 'react';
import {BezierEdge} from '@xyflow/react';
import useClasses from './Agent.styles';

const AgentEdge = (props: any) => {
    const classes = useClasses();

    return <BezierEdge {...props} className={classes.agentEdge} />;
};

export default AgentEdge;
