const FilterLabelPrefix = 'Filters.';

export const FilterDrawerLabels = {
    Apply: `${FilterLabelPrefix}Apply`,
    Filter: `${FilterLabelPrefix}Filter`,
    Filters: `${FilterLabelPrefix}Filters`,
    ApplyFilters: `${FilterLabelPrefix}ApplyFilters`,
    Cancel: `${FilterLabelPrefix}Cancel`,
    ResetAll: `${FilterLabelPrefix}ResetAll`,
    Close: `${FilterLabelPrefix}Close`,
};
