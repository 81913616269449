import React from 'react';
import {motion} from 'framer-motion';
import Gradient1 from './gradients/Gradient1';
import Gradient2 from './gradients/Gradient2';
import Gradient3 from './gradients/Gradient3';
import Gradient4 from './gradients/Gradient4';
import Gradient5 from './gradients/Gradient5';
import useClasses from './FullScreenGradients.styles';

const SVGWrapper = motion.div;

const GradientAnimation: React.FC = () => {
    const classes = useClasses();
    return (
        <>
            <SVGWrapper
                initial={{}}
                animate={{
                    opacity: [0.3, 0.4, 0.5],
                    scale: [0.5, 1, 0.5],
                    transition: {
                        x: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 40,
                            ease: 'easeInOut',
                        },
                        opacity: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 50,
                        },
                        rotate: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 30,
                        },
                        scale: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 50,
                        },
                    },
                }}
                className={classes.shape1}
                aria-hidden
            >
                <Gradient1 className={classes.fluidShape} />
            </SVGWrapper>
            <SVGWrapper
                aria-hidden
                initial={{}}
                className={classes.shape2}
                animate={{
                    opacity: [0.3, 0.4, 0.5],
                    rotate: [-30, 60, 0],
                    transition: {
                        x: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 70,
                        },
                        rotate: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 50,
                        },
                        opacity: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 20,
                        },
                    },
                }}
            >
                <Gradient2 className={classes.fluidShape} />
            </SVGWrapper>
            <SVGWrapper
                aria-hidden
                initial={{}}
                className={classes.shape3}
                animate={{
                    opacity: [0.3, 0.4, 0.5],
                    rotate: [30, -60, 0],
                    transition: {
                        x: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 40,
                            type: 'tween',
                        },
                        rotate: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 80,
                        },
                        opacity: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 10,
                        },
                    },
                }}
            >
                <Gradient3 className={classes.fluidShape} />
            </SVGWrapper>

            <SVGWrapper
                initial={{}}
                aria-hidden
                className={classes.shape4}
                animate={{
                    opacity: [0.3, 0.4, 0.5],
                    rotate: [20, 40, 0],
                    transition: {
                        x: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 50,
                            type: 'tween',
                        },
                        rotate: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 30,
                        },
                        opacity: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 20,
                        },
                    },
                }}
            >
                <Gradient4 className={classes.fluidShape} />
            </SVGWrapper>

            <SVGWrapper
                initial={{}}
                aria-hidden
                className={classes.shape5}
                animate={{
                    opacity: [0.3, 0.4, 0.5],
                    rotate: [20, 40, 0],
                    transition: {
                        x: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 40,
                        },
                        rotate: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 30,
                        },
                        opacity: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 20,
                        },
                    },
                }}
            >
                <Gradient5 className={classes.fluidShape} />
            </SVGWrapper>
        </>
    );
};

export default GradientAnimation;
