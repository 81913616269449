import {mergeClasses} from '@fluentui/react-components';
import useClasses from '../Table.styles';
import {useState} from 'react';
import {useTableContext} from '../TableContext';

const TableRow = ({...props}) => {
    const [isRowHovered, setIsRowHovered] = useState(false);

    const {rows: rowConfiguration} = useTableContext();
    const canHoverOverRow = !!rowConfiguration?.highlightOnHover;
    let tableStyles = useClasses();

    const canRenderHoverStyle = (): boolean => {
        return canHoverOverRow && !props.isHeader && isRowHovered;
    };

    const renderHoverStyle = (): string => {
        return canRenderHoverStyle() ? tableStyles.rowHover : '';
    };

    const renderHeightStyle = (): string => {
        return applyRowHeight() ? tableStyles.rowHeight : 'auto';
    };

    const applyRowHeight = (): boolean => {
        return !props.isHeader;
    };

    return (
        <tr
            onMouseEnter={() => setIsRowHovered(true)}
            onMouseLeave={() => setIsRowHovered(false)}
            className={mergeClasses(tableStyles.row, renderHeightStyle(), renderHoverStyle())}
        >
            {props.children}
        </tr>
    );
};

export default TableRow;
