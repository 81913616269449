export const STATIC_CONTENT: Record<string, string> = {
    READ_MORE_COMPUTE_UNITS: 'ChangeCapacityDialog.LinkValues.ReadMoreComputeUnits',
    MANAGE_BILLING_AZURE: 'ChangeCapacityDialog.LinkValues.ManageBillingAzure',
    TENANT_READ_ONLY_ACCESS_MESSAGE: 'ChangeCapacityDialog.TenantReadOnlyAccessMessage',
    ESTIMATED_MONTHLY_COST: 'ChangeCapacityDialog.EstimatedMonthlyCost',
    MONTH: 'ChangeCapacityDialog.Month',
    USD: 'ChangeCapacityDialog.USD',
    SECURITY_COMPUTE_UNITS: 'ChangeCapacityDialog.SecurityComputeUnits',
    UPDATE_CAPACITY_ERROR: 'ChangeCapacityDialog.UpdateCapacityError',
    DELETE_SECURITY_FABRIC_CAPACITY_HEADING:
        'ChangeCapacityDialog.DeleteSecurityFabricCapacityHeading',
    DELETE_CAPACITY_SUCCESS: 'ChangeCapacityDialog.DeleteCapacitySuccess',
    DELETE_CAPACITY_ERROR: 'ChangeCapacityDialog.DeleteCapacityError',
    DELETE_CAPACITY_CONFIRMATION_MESSAGE: 'ChangeCapacityDialog.DeleteCapacityConfirmationMessage',
    MODAL_INFO_TEXT: 'ChangeCapacityDialog.ModalInfoText',
    CAPACITY_UPDATED_SUCCESSFULLY: 'ChangeCapacityDialog.CapacityUpdatedSuccessfully',
    CHANGE_CAPACITY_INFORMATION: 'ChangeCapacityDialog.ChangeCapacityInformation',
    UPDATE_CAPACITY_SUCCESS_HEADER_UNITS_MULTIPLE:
        'ChangeCapacityDialog.UpdateCapacitySuccessHeaderUnitsMultiple',
    UPDATE_CAPACITY_SUCCESS_HEADER_UNIT_SINGLE:
        'ChangeCapacityDialog.UpdateCapacitySuccessHeaderUnitsSingle',
    UPDATE_CAPACITY_SUCCESS_HEADER: 'ChangeCapacityDialog.UpdateCapacitySuccessHeader',
};

export const CONTROL_LABELS: Record<string, string> = {
    DELETE_CAPACITY: 'ChangeCapacityDialog.ControlLabels.DeleteCapacity',
    CANCEL: 'ButtonLabels.Cancel',
    APPLY: 'ButtonLabels.Apply',
    MANAGE_BILLING_AZURE: 'ChangeCapacityDialog.ControlLabels.ManageBillingAzure',
    DONE: 'ChangeCapacityDialog.ControlLabels.Done',
    YES: 'ChangeCapacityDialog.ControlLabels.Yes',
};

export const ARIA_LABELS: Record<string, string> = {
    CLOSE_CHANGE_CAPACITY_DIALOG: 'ChangeCapacityDialog.AriaLabels.CloseChangeCapacityDialog',
    CLOSE_UPDATE_CAPACITY_DIALOG: 'ChangeCapacityDialog.AriaLabels.CloseDeleteCapacityDialog',
    CLOSE_DELETE_CAPACITY_DIALOG: 'ChangeCapacityDialog.AriaLabels.CloseUpdateCapacityDialog',
    CHANGE_CAPACITY_UNITS: 'ChangeCapacityDialog.AriaLabels.ChangeCapacityUnits',
    CONFIRM_DELETE_CAPACITY: 'ChangeCapacityDialog.AriaLabels.ConfirmDeleteCapacity',
};
