import {ChangeEvent, useCallback, useEffect, useReducer, useRef, useState} from 'react';
import useClasses from './PromptbookInputsForm.styles';
import {Badge, Button, Input, Tooltip, mergeClasses} from '@fluentui/react-components';
import {AcceptIcon, DismissIcon, EditIcon} from '@/components/ui/icons';
import {PromptbookInputDescriptor} from '@/api/promptbooks';
import {useTranslation} from 'react-i18next';

export type PromptbookInputsFormProps = {
    edit: boolean;
    showDelete?: boolean;
    formData: PromptbookInputDescriptor[];
    onFormDataChange?: (formData: PromptbookInputDescriptor[]) => void;
};

type PromptbookInputsFormAction = {
    type: 'ADD_TAG' | 'DELETE_TAG' | 'UPDATE_TAG' | 'RESET_TAG';
    value?: string;
};

const PromptbookInputsForm = (props: PromptbookInputsFormProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const classes = useClasses();
    const {t} = useTranslation('promptbooks');
    const initialState: PromptbookInputsFormProps = {
        edit: props.edit,
        formData: props.formData,
    };
    const [editMode, setEditMode] = useState(props.edit);
    const [newData, setNewData] = useState<string>('');

    const reducer = (state: PromptbookInputDescriptor[], action: PromptbookInputsFormAction) => {
        const currentValues = [...state];
        switch (action.type) {
            case 'ADD_TAG':
                const tagValue = action.value || ''; // Provide a default value for action.value

                // Add new element to array if it doesn't already exist
                if (
                    !checkStringIsNullOrEmpty(tagValue.trim()) &&
                    !currentValues.flatMap((v) => v.name).includes(tagValue)
                ) {
                    // Use the tagValue for both name and description.
                    currentValues.push({name: tagValue, description: tagValue});
                }

                formRef.current?.reset();
                setNewData('');

                if (inputRef.current) {
                    inputRef.current.value = '';
                }

                return currentValues;
            case 'DELETE_TAG':
                return currentValues.filter(
                    (item: PromptbookInputDescriptor) => item.name !== action.value,
                );
            case 'UPDATE_TAG':
                return currentValues;
            case 'RESET_TAG':
                setNewData('');
                return currentValues;
            default:
                console.log('nothing done');
                return state;
        }
    };

    const checkStringIsNullOrEmpty = (str: string | undefined | null) => {
        return typeof str === undefined || str === undefined || str === null || str === '';
    };

    const [formData, dispatch] = useReducer(reducer, initialState.formData);

    const handleSubmit = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            dispatch({type: 'ADD_TAG', value: e.currentTarget.value});
            setNewData('');
            formRef.current?.reset();
        } else if (e.key === 'Escape') {
            dispatch({type: 'RESET_TAG'});
            setNewData('');
        }
    }, []);

    const addMissingParameters = () => {
        // We want to make sure the inputs are case-sensitive
        const missingInputs = props.formData.filter(
            (item) => !formData.map((i) => i.name).includes(item.name),
        );

        if (missingInputs.length > 0) {
            missingInputs.forEach((item) => dispatch({type: 'ADD_TAG', value: item.name}));
        }
    };

    useEffect(() => {
        // call formDataChange function whenever formData changes
        props.onFormDataChange?.(formData);
    }, [formData]);

    useEffect(() => {
        // We want to make sure the inputs are case-sensitive
        const missingInputs = props.formData.filter(
            (item) => !formData.map((i) => i.name.toLowerCase()).includes(item.name.toLowerCase()),
        );

        if (missingInputs.length > 0) {
            missingInputs.forEach((item) => dispatch({type: 'ADD_TAG', value: item.name}));
        }
    }, []);

    useEffect(() => {
        addMissingParameters();
    }, [props.formData]);

    return (
        <div
            className={mergeClasses(
                classes.Container,
                props.edit ? classes.ContainerEdit : classes.ContainerShow,
            )}
        >
            <div className={classes.TagsContainer}>
                {formData.map((input: PromptbookInputDescriptor, i) => (
                    <div key={i} className={classes.TagContainer}>
                        <Badge
                            className={classes.Badge}
                            shape="rounded"
                            appearance="outline"
                            color="informative"
                            aria-label={input.name}
                        >
                            {input.name}
                        </Badge>
                        {(props.edit || props.showDelete) && (
                            <Button
                                shape="rounded"
                                className={classes.Button}
                                onClick={() => dispatch({type: 'DELETE_TAG', value: input.name})}
                                aria-label={'Remove input ' + input.name}
                            >
                                <DismissIcon className={classes.Icon}></DismissIcon>
                            </Button>
                        )}
                    </div>
                ))}
                {props.edit && (
                    <Input
                        ref={inputRef}
                        type="text"
                        value={newData}
                        onChange={(e) => setNewData(e.currentTarget.value)}
                        onKeyDown={handleSubmit}
                        placeholder={t('PromptbookInputPlaceholder')}
                        className={classes.TagInput}
                    />
                )}
            </div>
        </div>
    );
};

export default PromptbookInputsForm;
