import {useMutation} from '@tanstack/react-query';
import {PromptbookDescriptor, PromptbookPath, PromptbookVisibility} from './promptbooks.types';
import useSharePromptbook from './useSharePromptbook';
import {useState, useEffect} from 'react';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '../telemetry';

export default function useGetPromptbookLink(path = PromptbookPath.Promptbooks) {
    const {mutateAsync: sharePromptbook} = useSharePromptbook();
    const {mutate: trackEvent} = useTrackEvent();
    const [shareUrl, setShareUrl] = useState<string>('');

    useEffect(() => {
        if (Boolean(shareUrl) && shareUrl !== '' && typeof window !== 'undefined') {
            window.navigator.clipboard.writeText(shareUrl);
            trackEvent({
                name: MedeinaTelemetryEvent.Promptbooks.SharePromptbook,
                eventType: MedeinaEvent.ActionEvent,
            });
        }
    }, [shareUrl, trackEvent]);

    return useMutation({
        mutationFn: async (promptbook: PromptbookDescriptor) => {
            if (!promptbook) return;
            else if (
                promptbook.visibility === PromptbookVisibility.Global ||
                promptbook.visibility === PromptbookVisibility.Tenant
            ) {
                setShareUrl(`${window.location.origin}${path}/${promptbook.promptbookId}`);
            } else if (promptbook && promptbook.visibility === PromptbookVisibility.Private) {
                sharePromptbook(
                    {promptbookId: promptbook.promptbookId},
                    {
                        onSuccess: ({token}) => {
                            if (token && typeof window !== 'undefined') {
                                setShareUrl(
                                    `${window.location.origin}${path}/${promptbook.promptbookId}?st=${token}`,
                                );
                            }
                        },
                    },
                );
            }
        },
    });
}
