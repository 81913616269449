import {useMemo} from 'react';
import {PaginationProps} from './Pagination.types';

function validatePaginationProps(props: PaginationProps): string[] {
    const errors = [];
    if ((props?.pageSize ?? 0) <= 0) {
        errors.push('pageSize must be greater than 0');
    }
    if ((props?.recordCount ?? 0) < 0) {
        errors.push('recordCount must be greater than or equal to 0');
    }
    if ((props?.maxPageNumberDisplay ?? 0) <= 0) {
        errors.push('maxPageNumberDisplay must be greater than 0');
    }

    return errors;
}

export default function usePaginationValidation(props: PaginationProps) {
    const {isErrorState, errors} = useMemo(() => {
        const validationErrors = validatePaginationProps(props);
        const isErrorState = validationErrors.length > 0;
        return {isErrorState, errors: validationErrors};
    }, [props]);

    return {isErrorState, errors};
}
