import React, {useContext} from 'react';
import {Context} from '@/components/ui/Tour';
import {customFetch} from './api';
import {CustomFetchOptions} from './api.types';

const getValueByPath = ({method, url, currentStop}: any) => {
    return currentStop.data[method][url] || null;
};

function fakeFetch<T>(options: any): Promise<T> {
    const {url, fetchOptions, returnResponse, currentStop} = options;

    return new Promise<T>((resolve, reject) => {
        const response = getValueByPath({method: fetchOptions.method, url, currentStop});
        return response ? resolve(response as T) : reject();
    });
}

function useFetch() {
    const {activeTour, currentStop} = useContext(Context);

    return {
        customFetch:
            activeTour && currentStop?.simulateNetworkCalls
                ? <T>(
                      url: string,
                      {method = 'GET', body}: CustomFetchOptions = {},
                      returnResponse: boolean = false,
                  ) =>
                      fakeFetch<T>({url, fetchOptions: {method, body}, returnResponse, currentStop})
                : customFetch,
    };
}

export default useFetch;
