import {Geo, GeoName, Region, RegionName} from './App.types';

export const CUSTOM_EVENTS = {
    CONTROL_TRIGGERED: 'controlTriggered',
};

export const CUSTOM_EVENT_PARAMETERS = {
    SIDE_PANEL_BUTTON: 'sidePanelButton',
    RESIZE_BAR: 'resizeBar',
};

export const MEDEINA_COPY_BUTTON_TIMEOUT: number = 3000;

export const REGION_DATA: Record<RegionName, Region> = {
    [RegionName.australiaeast]: {displayName: 'Australia East', geo: GeoName.ANZ},
    [RegionName.westeurope]: {displayName: 'Europe West', geo: GeoName.EU},
    [RegionName.uksouth]: {displayName: 'UK South', geo: GeoName.UK},
    [RegionName.eastus]: {displayName: 'US East', geo: GeoName.US},
    [RegionName.norwayeast]: {displayName: 'Norway East', geo: GeoName.NW},
    [RegionName.centralus]: {displayName: 'Central US', geo: GeoName.US},
};

export const GEO_DATA: Record<GeoName, Geo> = {
    [GeoName.ANZ]: {
        formattedDisplayName: 'Australia (ANZ)',
        displayName: 'Australia',
        pricing: 4,
        defaultAzureRegion: RegionName.australiaeast,
        minCapacity: 1,
        maxCapacity: 100,
    },
    [GeoName.EU]: {
        formattedDisplayName: 'Europe (EU)',
        displayName: 'Europe',
        pricing: 4,
        defaultAzureRegion: RegionName.westeurope,
        minCapacity: 1,
        maxCapacity: 100,
    },
    [GeoName.UK]: {
        formattedDisplayName: 'United Kingdom (UK)',
        displayName: 'UK',
        pricing: 4,
        defaultAzureRegion: RegionName.uksouth,
        minCapacity: 1,
        maxCapacity: 100,
    },
    [GeoName.US]: {
        formattedDisplayName: 'United States (US)',
        displayName: 'United States',
        pricing: 4,
        defaultAzureRegion: RegionName.eastus,
        minCapacity: 1,
        maxCapacity: 100,
    },
    [GeoName.NW]: {
        formattedDisplayName: 'Norway (NW)',
        displayName: 'Norway',
        pricing: 4,
        defaultAzureRegion: RegionName.eastus,
        minCapacity: 1,
        maxCapacity: 100,
        hideFromDisplay: true,
    },
};

export const CAPACITY_BASELINE_STATIC_CONTENT: Record<string, number> = {
    CAPACITY_UNITS_MAX_THRESHOLD: 100,
    CAPACITY_UNITS_PRICE_PER_UNIT: 4,
};
