import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {SearchCategory} from '../UsageDashboard.types';

export const getUserDisplayValue = (value: string) => {
    const parts = value.split('|');
    return parts[1];
};

export const getDisplayValueForCopilotExperience = (
    value: string,
    copilotExperienceMap: KeyValueFilterDetails[],
) => {
    const displayValue = copilotExperienceMap.find((item) => item.id === value)?.displayValue;

    return displayValue as string;
};

export const removeFilterByCategory = (
    categoryToRemove: string,
    filters: SelectedSearchFacet[],
) => {
    const updatedFilters = filters.filter((filter) => filter.category !== categoryToRemove);
    return updatedFilters;
};

export const removeFilterByValue = (
    category: string,
    value: string,
    filters: SelectedSearchFacet[],
): SelectedSearchFacet[] => {
    return filters.reduce((acc, filter) => {
        if (filter.category === category) {
            const filteredValues = filter.values.filter((val) => val !== value);
            if (filteredValues.length > 0) {
                acc.push({...filter, values: filteredValues});
            }
        } else {
            acc.push(filter);
        }
        return acc;
    }, [] as SelectedSearchFacet[]);
};

export const getDisplayValue = (
    filter: SelectedSearchFacet,
    copilotExperienceMap: KeyValueFilterDetails[],
): string => {
    if (filter.category == SearchCategory.Users) {
        return getUserDisplayValue(filter.values[0]);
    } else if (filter.category == SearchCategory.CopilotExperience) {
        return getDisplayValueForCopilotExperience(filter.values[0], copilotExperienceMap);
    } else {
        return filter.values[0];
    }
};
