import React from 'react';

interface DataVizProps {
    className: string;
}

function DataVizLogo({className, ...svgProps}: DataVizProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} {...svgProps}>
            <path
                d="M36.5 5.99219C39.5376 5.99219 42 8.45462 42 11.4922V36.4922C42 39.5298 39.5376 41.9922 36.5 41.9922H11.5C8.46243 41.9922 6 39.5298 6 36.4922V11.4922C6 8.45462 8.46243 5.99219 11.5 5.99219H36.5ZM36.5 8.99219H11.5C10.1193 8.99219 9 10.1115 9 11.4922V36.4922C9 37.8729 10.1193 38.9922 11.5 38.9922H36.5C37.8807 38.9922 39 37.8729 39 36.4922V11.4922C39 10.1115 37.8807 8.99219 36.5 8.99219ZM15.5 17.9988C16.2594 17.9988 16.887 18.5607 16.9863 19.2897L17 19.4924V32.5052C17 33.3301 16.3284 33.9988 15.5 33.9988C14.7406 33.9988 14.113 33.4369 14.0137 32.7079L14 32.5052V19.4924C14 18.6675 14.6716 17.9988 15.5 17.9988ZM32.5 13.9988C33.2594 13.9988 33.887 14.5476 33.9863 15.2597L34 15.4576V32.54C34 33.3457 33.3284 33.9988 32.5 33.9988C31.7406 33.9988 31.113 33.45 31.0137 32.7379L31 32.54V15.4576C31 14.6519 31.6716 13.9988 32.5 13.9988ZM23.9502 23.9922C24.6917 23.9851 25.3099 24.5255 25.4139 25.2336L25.4293 25.4306L25.4999 32.5252C25.5079 33.3275 24.8587 33.9843 24.0498 33.9923C23.3083 33.9995 22.6901 33.459 22.5861 32.7509L22.5707 32.5539L22.5001 25.4593C22.4921 24.657 23.1413 24.0003 23.9502 23.9922Z"
                fill="white"
            />
        </svg>
    );
}

export default DataVizLogo;
