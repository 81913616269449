import {useViewportSize} from '@/components/ui/Grid';

const LARGE_SCREEN_BAR_WIDTH = 15.17;
const SMALL_SCREEN_BAR_WIDTH = 8.17;
const BAR_RADIUS = 2;
const RESPONSIVE_BAR_GAP_MAX = 4;

export default function useGetBarChartMeasurements() {
    const screenSize = useViewportSize();
    const showLargeBarWidth = screenSize.isXXLargeScreen || screenSize.isXXXLargeScreen;

    // The width of the bars is set to 10 for non-responsive, 15.17 for large screens, and 8.17 for small screens
    let barWidth = showLargeBarWidth ? LARGE_SCREEN_BAR_WIDTH : SMALL_SCREEN_BAR_WIDTH;

    // The radius of the bars is set to 2
    const barRadius = BAR_RADIUS;

    // The gap between bars is set to 2 for non-responsive and 4 for responsive
    const barGapMax = RESPONSIVE_BAR_GAP_MAX;

    return {
        barWidth,
        barRadius,
        barGapMax,
        enableReflow: true,
    };
}
