import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
import {CancelEvaluationRequest} from './evaluations.types';

export default function useCancelEvaluation() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: CancelEvaluationRequest) => {
            const response = await customFetch<Response>(
                `/sessions/${body?.sessionId}/prompts/${body?.promptId}/evaluations/${body?.evaluationId}/cancel`,
                {
                    method: 'POST',
                },
                true,
            );
            return response.ok;
        },
        onSettled: (evaluation, error, body) => {
            // Refetch the parent session.
            queryClient.refetchQueries({
                queryKey: [
                    'sessions',
                    body?.sessionId,
                    'prompts',
                    body?.promptId,
                    'evaluations',
                    body?.evaluationId,
                ],
            });
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
    });
}
