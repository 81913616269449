import {useMutation, useQueries, useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {GetPromptsFromIdRequest, Prompt} from './prompts.types';

export default function useGetPromptsFromPromptIds({
    sessionId,
    promptIds,
}: GetPromptsFromIdRequest) {
    const {customFetch} = useFetch();

    let queries = promptIds.map((promptId: string) => ({
        queryKey: ['sessions', sessionId, 'prompts', promptId],
        queryFn: async () => {
            return await customFetch<Prompt>(`/sessions/${sessionId}/prompts/${promptId}`, {
                method: 'GET',
            });
        },
        enabled: Boolean(promptId) && Boolean(sessionId),
    }));

    return useQueries({
        queries: queries,
    });
}
