import Prompt from './Prompt';
import {PromptWrapperProps} from './Prompt.types';
import PromptbookSection from './PromptbookSection';

export * from './Prompt.types';

export default function PromptWrapper({
    groupedPromptType,
    sessionId,
    id,
    isReadOnly,
    errorMessage,
    selection,
}: PromptWrapperProps) {
    const isPromptbook = groupedPromptType === 'Promptbook' ? true : false;
    return (
        <>
            {!isPromptbook ? (
                <Prompt
                    sessionId={sessionId}
                    promptId={id}
                    selection={selection}
                    isReadOnly={isReadOnly}
                    errorMessage={errorMessage}
                />
            ) : (
                <PromptbookSection
                    sessionId={sessionId}
                    promptbookExecutionId={id}
                    isReadOnly={isReadOnly}
                    errorMessage={errorMessage}
                    selection={selection}
                />
            )}
        </>
    );
}
