import React, {useEffect, useState} from 'react';
import {Label} from '@fluentui/react-components';
import useClasses from '@/components/Navigation/Preferences.styles';
import {useTranslation} from 'react-i18next';
import FileRbacManagementSetting from '@/components/Navigation/Preferences/FileRbacManagement';
import {SettingsProps} from './rbac/OwnerSettings.types';

function FileSettings(props: SettingsProps) {
    const {tenantInfo, isAdmin} = props;
    const classes = useClasses();
    const {t} = useTranslation();
    return (
        <div>
            <h3>{t('SettingsFileUploadsHeading')}</h3>
            <FileRbacManagementSetting tenantInfo={tenantInfo} isAdmin={isAdmin} />
            {!isAdmin && (
                <Label className={classes.warningLabel}>
                    *{t('SettingsPluginsAdminManagedLabel')}
                </Label>
            )}
        </div>
    );
}

export default FileSettings;
