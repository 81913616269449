import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {SidePanelWidth, NavigationBarHeight} from '@/components/App.styles';

export const useClasses = makeStyles({
    root: {
        backgroundImage: `linear-gradient(180deg, ${tokens.colorNeutralBackground1} 0%, ${tokens.colorNeutralBackground2} 100%)`,
        position: 'fixed',
        top: `${NavigationBarHeight}px`,
        right: 0,
        width: `${SidePanelWidth}px`,
        height: `calc(100vh - 48px)`,
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),

        '@media (min-width: 640px) and (max-width: 1023px)': {
            position: 'absolute',
            top: 0,
        },

        '@media (max-width: 479px)': {
            top: 0,
            width: '100%',
            height: '100%',
        },
    },
});
