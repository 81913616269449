import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    divider: {
        color: tokens.colorNeutralForeground2,
    },
    control: {
        color: tokens.colorNeutralForeground1,
        '@media (forced-colors: active)': {
            backgroundColor: 'Canvas',
        },
    },
    controlOverlay: {
        color: '#BEBEBE',
    },
    dividerOverlay: {
        color: '#BEBE33',
    },
});
