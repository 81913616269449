import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {},
    menu: {
        boxSizing: 'border-box',
        ...shorthands.padding(0),
        backgroundColor: tokens.colorNeutralBackground4,
        color: tokens.colorNeutralForeground4,
        maxHeight: '80vh',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralBackgroundStatic,
            ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: tokens.colorNeutralBackgroundStatic,
        },
        width: '100%',
        maxWidth: '600px',
        right: 0,
        zIndex: 1,
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            maxHeight: '60vh',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            maxHeight: '50vh',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            maxHeight: '40vh',
        },
        '@media (max-width: 479px)': {
            maxHeight: '75vh',
        },
    },
    navbar: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalXL),
    },
    searchbar: {
        // TODO: colorNeutralBackground3 and colorNeutralBackground4 are currently the same, but shouldn't be. We need to update our theme.
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground1,
        width: '100%',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXS),
    },
    subButton: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        color: 'inherit',
        marginLeft: tokens.spacingHorizontalXS,
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            color: tokens.colorBrandForegroundInvertedHover,
        },
    },
    showAllContainer: {
        maxHeight: '60vh',
        overflowY: 'auto',
        '@media (max-width: 479px)': {
            maxHeight: 'calc(3 * var(--lineHeightBase600))',
        },
    },
    menuGroupHeader: {
        // ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralStroke1),
        color: tokens.colorNeutralForeground2,
        display: 'flex',
        flexDirection: 'row',
        lineHeight: tokens.lineHeightBase300,
        // ...shorthands.margin(tokens.spacingVerticalM, 0, 0),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalL, 0),
    },
    menuGroupIcon: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorBrandForeground1,
        fontSize: '20px',
        marginRight: tokens.spacingHorizontalM,
    },
    menuGroupTitle: {
        ...shorthands.flex(1, 1, 'auto'),
    },
    menuGroupTitleText: {
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightBold,
        letterSpacing: '0.6px',
        textTransform: 'uppercase',
    },
    menuGroupShowAll: {
        ...shorthands.flex(0, 0, 'auto'),
        fontSize: tokens.fontSizeBase300,
    },
    getStarted: {
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase300,
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    groupDescription: {
        color: tokens.colorNeutralForeground1,
        whiteSpace: 'nowrap',
        lineHeight: tokens.lineHeightBase200,
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
        marginBottom: tokens.spacingVerticalM,
    },
    menuItem: {
        backgroundColor: tokens.colorTransparentBackground,
        '> span': {
            overflowX: 'hidden',
            overflowY: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        ...shorthands.margin(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        maxWidth: '100%',
    },
    promptbookIcon: {
        color: tokens.colorPaletteTealForeground2,
    },
    skillIcon: {
        color: tokens.colorBrandForeground1,
        width: '20px',
        height: 'auto',
        marginRight: '21px',
    },
    skillName: {
        color: tokens.colorNeutralForeground1,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase300,
    },
    skillDescription: {
        color: tokens.colorNeutralForeground1,
        whiteSpace: 'nowrap',
        lineHeight: tokens.lineHeightBase200,
    },
    noMatches: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalXL),
        color: tokens.colorNeutralForeground1,
    },
    navigateBackIcon: {
        color: tokens.colorBrandForeground1,
        width: '20px',
        height: 'auto',
        marginRight: tokens.spacingHorizontalL,
        cursor: 'pointer',
    },
    // menuHeaderText: {
    //     height: '19px',
    //     fontFamily: tokens.fontFamilyBase,
    //     fontStyle: 'normal',
    //     fontWeight: tokens.fontWeightRegular,
    //     fontSize: tokens.fontSizeBase200,
    //     lineHeight: tokens.lineHeightBase300,
    //     color: tokens.colorNeutralForeground3,
    // },
    menugroupHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingTop: tokens.spacingVerticalL,
        height: '35px',
        ...shorthands.flex(0, 0, 'auto'),
        order: '5',
        alignSelf: 'stretch',
        flexGrow: '0',
        zIndex: '5',
    },
    menugroupHeaderText: {
        height: '19px',
        fontFamily: tokens.fontFamilyBase,
        fontStyle: 'normal',
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.fontSizeBase500,
        display: 'flex',
        alignItems: 'center',
        color: tokens.colorPaletteAnchorBorderActive,
        ...shorthands.flex(0, 0, 'auto'),
        order: 1,
        flexGrow: 1,
        marginLeft: '38px',
        marginTop: '7px',
    },
    allCapabilitiesLink: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '54px',
        paddingTop: tokens.spacingVerticalS,
        height: '24px',
        ...shorthands.flex(0, 0, 'auto'),
        order: 6,
        alignSelf: 'stretch',
        flexGrow: 0,
    },
});
