import React from 'react';
import useCrumbClasses from '../Breadcrumbs.styles';
import BreadcrumbItem from './BreadcrumbItem';
import Session from './Session';

interface NewSessionBreadcrumbProps {
    renderOnlyLast?: boolean;
}

function NewSession(props: NewSessionBreadcrumbProps) {
    const {renderOnlyLast} = props;
    const classes = useCrumbClasses();
    return (
        <>
            {!renderOnlyLast && (
                <>
                    <Session />
                    <li role="separator" className={classes.spacer}>
                        /
                    </li>
                </>
            )}
            <BreadcrumbItem segment={'new'} />
        </>
    );
}

export default NewSession;
