import {tokens} from '@fluentui/react-components';
import {makeStyles, shorthands} from '@griffel/react';

export const useClasses = makeStyles({
    root: {
        ...shorthands.padding(tokens.spacingHorizontalXXL),
        width: '612px',
        ...shorthands.border(tokens.spacingHorizontalNone),
    },
    content: {
        marginTop: tokens.spacingVerticalXXL,
    },
    componentLabel: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,
        marginTop: tokens.spacingVerticalXXL,
    },
    controlMargin: {
        marginBottom: tokens.spacingHorizontalS,
        ...shorthands.padding(tokens.spacingVerticalXS),
    },
    fullWidth: {
        width: '100%',
        fontWeight: tokens.fontWeightSemibold,
        display: 'flex',
        justifyContent: 'flex-start',
        ...shorthands.padding(tokens.spacingVerticalL),
    },
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    titleSection: {
        width: '92%',
    },
    textAreaControl: {
        '>textarea': {
            fontSize: tokens.fontSizeBase300,
        },
    },
    actionWrapper: {
        marginTop: tokens.spacingVerticalL,
    },
    textAreaFullWidth: {
        width: '100%',
    },
    textAreaControlMargin: {
        marginTop: tokens.spacingHorizontalL,
    },
});
