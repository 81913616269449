import {useMutation} from '@tanstack/react-query';
import {PageResponse} from '../api.types';
import useFetch from '../useFetch';
import useRerunPrompts from './useRerunPrompts';
import useDeletePrompts from './useDeletePrompts';
import {DeletePromptsRequest, Prompt} from './prompts.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist, useClearSessionSummary} from '../sessions';
export default function useDeletePromptsAndRerunPrompts() {
    // We need to `await` the mutation, so use `mutateAsync`.
    const {mutateAsync: deletePrompts} = useDeletePrompts();
    const {mutateAsync: rerunPrompts} = useRerunPrompts();
    const {mutate: clearSessionSummary} = useClearSessionSummary();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: DeletePromptsRequest) => {
            // Fetch all of the "next" prompts.
            const allNextPromptIds = await Promise.all(
                body.promptIds.map((promptId) =>
                    customFetch<PageResponse<Prompt>>(
                        `/sessions/${body?.sessionId}/prompts?lastPromptId=${promptId}`,
                        {
                            method: 'GET',
                        },
                    ).then((prompts) => {
                        return prompts?.value.map(({promptId}) => promptId);
                    }),
                ),
            );

            // Select the set of "next" prompts that is the longest, which means that it is the earliest prompt to be deleted.
            let nextPromptIds = allNextPromptIds.reduce((earliest, next) => {
                return next.length > earliest.length ? next : earliest;
            }, []);

            // Remove the prompts that are requested for deletion.
            const rerunPromptIds = nextPromptIds.filter(
                (promptId) => !body.promptIds.includes(promptId),
            );

            // Delete the requested prompts.
            await deletePrompts({
                sessionId: body?.sessionId as string,
                promptIds: body.promptIds,
            });

            // Iterate through the following prompts in sequence, if not deleted.
            await rerunPrompts({
                sessionId: body?.sessionId as string,
                promptIds: rerunPromptIds,
            });
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
        onSettled: (data, error, body) => {
            clearSessionSummary({sessionId: body.sessionId});
        },
    });
}
