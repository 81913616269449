import {makeStyles, tokens} from '@fluentui/react-components';
export const buttonHover = 'buttonHover';
export const buttonFocus = 'buttonFocus';

export default makeStyles({
    viewRolesButton: {
        '& > span:first-child': {
            position: 'relative',
            top: tokens.spacingVerticalXS,
            marginRight: tokens.spacingVerticalXS,
            scale: '1.3',
        },
        [`&:hover .${buttonHover}`]: {
            color: tokens.colorStatusDangerForeground3,
        },
        [`&:focus-within .${buttonFocus}`]: {
            color: tokens.colorStatusDangerForeground3,
        },
    },
    viewRoles: {
        display: 'flex',
        alignSelf: 'center',
        marginLeft: 'auto',
        marginRight: tokens.spacingVerticalS,
        opacity: 0.001,
    },
});
