import React, {useContext, useEffect, useRef} from 'react';
import {useLayout, useLayoutRefProps} from '@/components/ui/Layout';

// A utility hook to add a layout component to the layout context
// currently this should only be used in the main app content and the side panel
function useLayoutRef(props: useLayoutRefProps) {
    const layoutContext = useLayout();
    const ref = useRef(null);

    useEffect(() => {
        // once there is a current ref add it to the layout context for the given area
        if (ref.current) {
            if (props.area === 'contentArea') {
                layoutContext.contentArea?.setRef?.(ref);
            } else if (props.area === 'sidePanel') {
                layoutContext.sidePanel?.setRef?.(ref);
            }
        }
    }, []);

    return ref;
}

export default useLayoutRef;
