import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: '#242A2E66',
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalNone),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),

        '& .fui-Persona__primaryText': {
            '@media (max-width: 479px)': {
                fontSize: '10px',
                lineHeight: tokens.lineHeightBase100,
                fontWeight: tokens.fontWeightRegular,
            },
        },
    },
});
