import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    dialogContentSection: {
        marginBottom: tokens.spacingVerticalS,
        marginTop: tokens.spacingVerticalS,
    },
    icon: {
        scale: 1.5,
        top: '3px',
    },
});
