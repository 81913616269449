import {useMutation} from '@tanstack/react-query';
import useSessionsFetch from '../useSessionsFetch';
import {GetSessionSharingMailRequest, SessionSharingMailRequest} from './sessions.types';

export default function useShareSessionEmail({sessionId}: GetSessionSharingMailRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useMutation({
        mutationFn: async (body: SessionSharingMailRequest) => {
            await sessionsFetch<Response>(
                `${body.sessionId}/shareinvestigation`,
                {
                    method: 'POST',
                    body,
                },
                true,
            );
        },
    });
}
