import {Session} from '@/components/sections/sessions';
import {useParams, Params} from 'react-router-dom';

interface SessionParams extends Params {
    sessionId: string;
}

export default function SessionPage() {
    const {sessionId} = useParams() as SessionParams;
    return <Session sessionId={sessionId} />;
}
