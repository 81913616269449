import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import useFetch from '../useFetch';
import {DEFAULT_API_RETRY_COUNT, DEFAULT_API_STALE_TIME} from '../api.constants';
import {
    GetHourlyUsageAggregateDataByDimensionsParameters,
    GetHourlyUsageAggregateDataByDimensionsRequestPayload,
    GetHourlyUsageAggregateDataByDimensionsResponse,
} from './capacities.types';

// Define the additional query options that can be passed to the useQuery hook
type AdditionalQueryOptions = 'enabled';

/**
 * Gets Hourly Usage Aggregate Data that is filtered by dimensions
 * @param URLParameters
 * @param requestPayload
 * @param queryOptions
 * @returns Hourly aggregated data for the given capacity resource name between start date and end date
 */
export default function useGetHourlyUsageAggregateDataByDimensions(
    {capacityResourceName}: GetHourlyUsageAggregateDataByDimensionsParameters,
    requestPayload: GetHourlyUsageAggregateDataByDimensionsRequestPayload,
    queryOptions?: Pick<
        UseQueryOptions<GetHourlyUsageAggregateDataByDimensionsResponse>,
        AdditionalQueryOptions
    >,
) {
    const url = `${MedeinaVariables.ApiUri}/usage/${capacityResourceName}/aggregates/hourly`;
    const {customFetch} = useFetch();

    return useQuery<GetHourlyUsageAggregateDataByDimensionsResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['hourly-capacity-events-aggregate-by-dimensions', capacityResourceName],
        retry: DEFAULT_API_RETRY_COUNT,
        staleTime: DEFAULT_API_STALE_TIME,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'POST',
                    body: JSON.stringify(requestPayload),
                },
                true,
            );

            if (!response.ok) {
                throw new Error(
                    `An error occurred while fetching hourly usage aggregate data by dimensions`,
                );
            }

            const data = await response.json();
            return data as GetHourlyUsageAggregateDataByDimensionsResponse;
        },
        ...queryOptions,
    });
}
