import React, {useEffect, useLayoutEffect, useState, useCallback, useMemo, useRef} from 'react';
import Tooltip from './Tooltip';
import {AlignEdge, SpotlightContentStyle, TourStop} from './Tour.types';
import {motion, usePresence, Variants} from 'framer-motion';

import Content from './Content';

const defaultOverlayOptions = {
    borderRadius: 5,
    padding: 10,
};

const spotlightOverlayAnimation: Variants = {
    initial: {opacity: 0},
    animate: {opacity: 1, transition: {delay: 0.25, duration: 0.75}},
    noEntrance: {opacity: 1},
    exit: {opacity: 0, transition: {duration: 0.8}},
    noExit: {opacity: 1},
};

interface SpotlightOverlayProps {
    stop: TourStop | null | undefined;
    currentStopName: string | null;
    transitionWithOverlay: boolean;
}

// a function that recursively tries to check that there's elements in the dom
// that start with the beginning of the selector data-tour-id
function checkForTourableElements(retry = 0, limit = 1): boolean {
    while (retry < limit) {
        if (document.querySelector(`[data-tour-id]`)) {
            return true;
        }
        retry++;
    }
    return false;
}

// This should only receive
function SpotlightOverlay(props: SpotlightOverlayProps) {
    const ref = useRef(null);

    const [isPresent, safeToRemove] = usePresence();
    // Necessary in order to get the overlays to animate in and out
    // Without this the component will not truly unmount and animate out
    useEffect(() => {
        if (!isPresent) {
            safeToRemove();
        }
    }, [isPresent]);

    const {stop} = props;
    const {spotlight, name} = stop || {};
    const {targetSelector, showOverlay: showSpotlightOverlay, contentStyle} = spotlight || {};
    const [overlayAnimationComplete, setOverlayAnimationComplete] = useState(false);

    const allowInteractions = stop?.spotlight?.allowInteractions ?? false;

    // keep track of the target element and update when there's a new targetSelector
    const [targetElement, setTargetElement] = useState<Element | null>(null);
    const [holePosition, setHolePosition] = useState<{
        top: number;
        left: number;
        width: number;
        height: number;
    } | null>(null);
    const holePadding =
        stop?.spotlight?.padding !== undefined
            ? stop?.spotlight?.padding
            : defaultOverlayOptions.padding;
    const holeBorderRadius =
        stop?.spotlight?.borderRadius !== undefined
            ? stop?.spotlight?.borderRadius
            : defaultOverlayOptions.borderRadius;

    // before rendering the overlays test that the tourable elements are in the dom
    // if they are not then we need to wait until they are
    // if they are then we can render the overlays
    const [tourableElementsExist, setTourableElementsExist] = useState(
        checkForTourableElements(0, 2),
    );

    useEffect(() => {
        const tourableElementsExist = checkForTourableElements(0, 2);
        setTourableElementsExist(tourableElementsExist);
    }, [targetSelector]);

    const updateTargetElement = useCallback(() => {
        if (targetSelector && tourableElementsExist) {
            if (typeof targetSelector === 'string') {
                // options.targetSelector is a string
                setTargetElement(document.querySelector(targetSelector));
            } else if (typeof targetSelector === 'function') {
                // options.targetSelector is an Element
                const functionElement = targetSelector();
                setTargetElement(functionElement);
            } else {
                setTargetElement(targetSelector);
            }
        } else {
            setTargetElement(null);
        }
        // make setting the target element the last thing we do
        // after we set the target element call updateHolePosition
    }, [targetSelector, tourableElementsExist]);

    useEffect(() => {
        updateTargetElement();
    }, [targetSelector, tourableElementsExist]);

    const updateHolePosition = useCallback(() => {
        if (targetElement) {
            const rect = targetElement.getBoundingClientRect();
            setHolePosition({
                top: rect.top - holePadding,
                left: rect.left - holePadding,
                width: rect.width + 2 * holePadding,
                height: rect.height + 2 * holePadding,
            });
        } else {
            setHolePosition(null);
        }
    }, [holePadding, targetElement]);

    // When the layout settles we want to recalculate the hole position or if the targetSelector changes
    useLayoutEffect(() => {
        updateHolePosition();
    }, [targetSelector, targetElement]);

    // As the windows resizes we need to update the hole position
    useEffect(() => {
        const handleResize = () => {
            updateHolePosition();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [updateHolePosition]);

    // if this is a spotlight witht he SVG overlay we need to wait for it to finish animating in before our content is ready
    // if we aren't using the overlay we can render the content immediately
    const overlayCheck = showSpotlightOverlay
        ? Boolean(overlayAnimationComplete && holePosition)
        : true;

    return tourableElementsExist ? (
        <Content>
            <motion.div
                key={stop?.name}
                variants={spotlightOverlayAnimation}
                initial={props.transitionWithOverlay ? 'noEntrance' : 'initial'}
                animate="animate"
                exit={spotlight?.transitionWithOverlay ? 'noExit' : 'exit'}
                data-testid="spotlightOverlay"
                onAnimationComplete={() => {
                    setOverlayAnimationComplete(true);
                }}
            >
                {showSpotlightOverlay && (
                    <svg
                        width="100%"
                        height="100%"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            pointerEvents: allowInteractions ? 'none' : 'all',
                        }}
                        ref={ref}
                    >
                        <defs>
                            <mask id="holeMask">
                                <rect width="100%" height="100%" fill="white" />
                                {holePosition && (
                                    <rect
                                        x={holePosition.left}
                                        y={holePosition.top}
                                        width={holePosition.width}
                                        height={holePosition.height}
                                        fill="black"
                                        rx={holeBorderRadius}
                                        ry={holeBorderRadius}
                                    />
                                )}
                            </mask>
                            f
                        </defs>
                        <rect
                            width="100%"
                            height="100%"
                            fill="rgba(0, 0, 0, 0.9)"
                            mask="url(#holeMask)"
                        />
                    </svg>
                )}
                {overlayCheck && contentStyle === SpotlightContentStyle.popover && (
                    <Tooltip
                        position={holePosition || {top: 0, left: 0, width: 0, height: 0}}
                        alignEdge={stop?.spotlight?.alignEdge || AlignEdge.start}
                        tooltipPosition={stop?.spotlight?.tooltipPosition || 'below'}
                        target={targetElement}
                        key={name}
                    >
                        {stop?.spotlight?.content}
                    </Tooltip>
                )}
                {overlayCheck && contentStyle === SpotlightContentStyle.overlay && (
                    <>{stop?.spotlight?.content}</>
                )}
            </motion.div>
        </Content>
    ) : (
        <></>
    );
}

interface SpotlightOverlaysProps {
    tourStops: TourStop[];
    stopIndex: number;
    currentStop: TourStop | null;
}

const SpotlightOverlays = (props: SpotlightOverlaysProps) => {
    const {currentStop, stopIndex, tourStops = []} = props;

    const {name} = currentStop || {name: null};

    const transitionWithOverlay = () => {
        if (stopIndex === 0 || tourStops?.length === 0) {
            return false;
        }
        const previousStop = tourStops[stopIndex - 1];
        const shouldTransitionWithOverlay = previousStop?.spotlight?.transitionWithOverlay ?? false;
        return shouldTransitionWithOverlay;
    };

    return (
        <SpotlightOverlay
            stop={currentStop}
            currentStopName={name}
            key={name}
            transitionWithOverlay={transitionWithOverlay()}
        />
    );
};

export default SpotlightOverlays;
