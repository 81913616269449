import * as React from 'react';
import {
    SettingsScope,
    SkillsetCategory,
    SkillsetDescriptor,
    SkillsetFilterTypes,
    SkillsetRequirementsCheckResult,
    useGetSkillsets,
} from '@/api/skills';
import ConnectorCategoryEntry from './ConnectorCategoryEntry';
import {categoryStringMappings} from '../Utils/ConnectorModalContent.types';
import {findCategory} from '../Utils/Skillsets.utils';
import {useTranslation} from 'react-i18next';

interface ConnectorCategoryEntriesProps {
    visibleSkillsets: SkillsetDescriptor[];
    selectedSkillsets: Set<string>;
    configurableSkillsets: Set<string> | undefined;
    configuredSkillsets: Set<string>;
    displayedSkillsetFilter: SkillsetFilterTypes;
    onOpenAdminView?: (
        skillset: string,
        errors: SkillsetRequirementsCheckResult | null,
        scope: SettingsScope | null,
        skillsetDescriptor: SkillsetDescriptor | null,
    ) => void;
    onOpenExternalView: (
        type: string,
        skillsetDescriptor: SkillsetDescriptor | null,
        showDelete: boolean,
    ) => void;
    onCategoryChangeHandler: (category: string, checked: boolean) => void;
    onSwitchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    addPluginButtonRef: React.RefObject<HTMLButtonElement>;
    switchRefs: React.MutableRefObject<{
        [keyId: string]: HTMLInputElement | null;
    }>;
}

export default function ConnectorCategoryEntries({
    visibleSkillsets,
    selectedSkillsets,
    configurableSkillsets,
    configuredSkillsets,
    displayedSkillsetFilter,
    onOpenAdminView,
    onOpenExternalView,
    onCategoryChangeHandler,
    onSwitchChange,
    addPluginButtonRef,
    switchRefs,
}: ConnectorCategoryEntriesProps) {
    const {t} = useTranslation('plugins');

    const getAllSkillsetsInCategory = React.useMemo(
        () =>
            (category: string): SkillsetDescriptor[] => {
                return visibleSkillsets.filter((skillset) => skillset.category === category);
            },
        [visibleSkillsets],
    );

    const getSelectedSkillsetsInCategory = React.useMemo(
        () =>
            (category: string): SkillsetDescriptor[] => {
                return visibleSkillsets?.filter(
                    (skillset) =>
                        skillset.category === category && selectedSkillsets.has(skillset.name),
                );
            },
        [visibleSkillsets, selectedSkillsets],
    );

    const getUnselectedSkillsetsInCategory = React.useMemo(
        () =>
            (category: string): SkillsetDescriptor[] => {
                return visibleSkillsets?.filter(
                    (skillset) =>
                        skillset.category === category && !selectedSkillsets.has(skillset.name),
                );
            },
        [visibleSkillsets, selectedSkillsets],
    );

    // checks if the given category has any selectable skillsets after searching/filtering
    const categoryHasSelectable = React.useMemo(() => {
        const hasSelectable = new Map<string, boolean>();
        for (const category of Object.keys(SkillsetCategory)) {
            if (
                (displayedSkillsetFilter !== SkillsetFilterTypes.Off &&
                    getSelectedSkillsetsInCategory(category).length > 0) ||
                (displayedSkillsetFilter !== SkillsetFilterTypes.On &&
                    getUnselectedSkillsetsInCategory(category).length > 0)
            ) {
                hasSelectable.set(category, true);
            } else {
                hasSelectable.set(category, false);
            }
        }
        return hasSelectable;
    }, [displayedSkillsetFilter, getSelectedSkillsetsInCategory, getUnselectedSkillsetsInCategory]);

    const categoryHasSelected = React.useMemo(() => {
        const hasSelected = new Map<string, boolean>();
        for (const category of Object.keys(SkillsetCategory)) {
            if (getSelectedSkillsetsInCategory(category).length > 0) {
                hasSelected.set(category, true);
            } else {
                hasSelected.set(category, false);
            }
        }
        return hasSelected;
    }, [getSelectedSkillsetsInCategory]);

    return (
        <>
            {Object.entries(categoryStringMappings).map(([category, categoryInfoStringIds]) => {
                const skillsetsInCategory = getAllSkillsetsInCategory(category);
                const categoryName = t(categoryInfoStringIds[0]);
                const categoryDescription = t(categoryInfoStringIds[1]);
                const shouldShowComponent =
                    categoryName != '' &&
                    categoryDescription != '' &&
                    category != SkillsetCategory.Testing &&
                    (displayedSkillsetFilter == SkillsetFilterTypes.All ||
                        categoryHasSelectable.get(category));

                if (!shouldShowComponent) {
                    return null;
                }

                return (
                    <ConnectorCategoryEntry
                        key={category}
                        category={category}
                        categoryName={categoryName}
                        categoryDescription={categoryDescription}
                        skillsetsInCategory={skillsetsInCategory}
                        selectedSkillsets={selectedSkillsets}
                        configurableSkillsets={configurableSkillsets}
                        configuredSkillsets={configuredSkillsets}
                        displayedSkillsetFilter={displayedSkillsetFilter}
                        onOpenAdminView={onOpenAdminView}
                        onOpenExternalView={onOpenExternalView}
                        onCategoryChangeHandler={onCategoryChangeHandler}
                        onSwitchChange={onSwitchChange}
                        categorySelected={categoryHasSelected.get(category) ?? false}
                        addPluginButtonRef={addPluginButtonRef}
                        switchRefs={switchRefs}
                    />
                );
            })}
        </>
    );
}
