import type {SyntaxHighlighterProps} from 'react-syntax-highlighter';
export const dark: Partial<SyntaxHighlighterProps> = {
    'hljs-comment': {
        color: '#d13438',
    },
    'hljs-quote': {
        color: '#d13438',
    },
    'hljs-variable': {
        color: '#f7adda',
    },
    'hljs-template-variable': {
        color: '#f7adda',
    },
    'hljs-tag': {
        color: '#f7adda',
    },
    'hljs-name': {
        color: '#f7adda',
    },
    'hljs-selector-id': {
        color: '#f7adda',
    },
    'hljs-selector-class': {
        color: '#f7adda',
    },
    'hljs-regexp': {
        color: '#f7adda',
    },
    'hljs-deletion': {
        color: '#f7adda',
    },
    'hljs-number': {
        color: '#f4bfab',
    },
    'hljs-built_in': {
        color: '#f4bfab',
    },
    'hljs-builtin-name': {
        color: '#f4bfab',
    },
    'hljs-literal': {
        color: '#f4bfab',
    },
    'hljs-type': {
        color: '#f4bfab',
    },
    'hljs-params': {
        color: '#f4bfab',
    },
    'hljs-meta': {
        color: '#f4bfab',
    },
    'hljs-link': {
        color: '#f4bfab',
    },
    'hljs-attribute': {
        color: '#f4bfab',
    },
    'hljs-string': {
        color: '#009b51',
    },
    'hljs-symbol': {
        color: '#009b51',
    },
    'hljs-bullet': {
        color: '#009b51',
    },
    'hljs-addition': {
        color: '#009b51',
    },
    'hljs-title': {
        color: '#5ae0a0',
    },
    'hljs-section': {
        color: '#5ae0a0',
    },
    'hljs-keyword': {
        color: '#e4cc00',
    },
    'hljs-selector-tag': {
        color: '#e4cc00',
    },
    hljs: {
        display: 'block',
        overflowX: 'auto',
        background: '#2b2b2b',
        color: '#f8f8f2',
        padding: '0.5em',
    },
    'hljs-emphasis': {
        fontStyle: 'italic',
    },
    'hljs-strong': {
        fontWeight: 'bold',
    },
};

export const light = {
    'hljs-comment': {
        color: '#d13438',
    },
    'hljs-quote': {
        color: '#d13438',
    },
    'hljs-variable': {
        color: '#f7adda',
    },
    'hljs-template-variable': {
        color: '#f7adda',
    },
    'hljs-tag': {
        color: '#f7adda',
    },
    'hljs-name': {
        color: '#f7adda',
    },
    'hljs-selector-id': {
        color: '#f7adda',
    },
    'hljs-selector-class': {
        color: '#f7adda',
    },
    'hljs-regexp': {
        color: '#f7adda',
    },
    'hljs-deletion': {
        color: '#f7adda',
    },
    'hljs-number': {
        color: '#f4bfab',
    },
    'hljs-built_in': {
        color: '#f4bfab',
    },
    'hljs-builtin-name': {
        color: '#f4bfab',
    },
    'hljs-literal': {
        color: '#f4bfab',
    },
    'hljs-type': {
        color: '#f4bfab',
    },
    'hljs-params': {
        color: '#f4bfab',
    },
    'hljs-meta': {
        color: '#f4bfab',
    },
    'hljs-link': {
        color: '#f4bfab',
    },
    'hljs-attribute': {
        color: '#f4bfab',
    },
    'hljs-string': {
        color: '#009b51',
    },
    'hljs-symbol': {
        color: '#009b51',
    },
    'hljs-bullet': {
        color: '#009b51',
    },
    'hljs-addition': {
        color: '#009b51',
    },
    'hljs-title': {
        color: '#5ae0a0',
    },
    'hljs-section': {
        color: '#5ae0a0',
    },
    'hljs-keyword': {
        color: '#e4cc00',
    },
    'hljs-selector-tag': {
        color: '#e4cc00',
    },
    hljs: {
        display: 'block',
        overflowX: 'auto',
        background: '#fefefe',
        color: '#545454',
        padding: '0.5em',
    },
    'hljs-emphasis': {
        fontStyle: 'italic',
    },
    'hljs-strong': {
        fontWeight: 'bold',
    },
};
