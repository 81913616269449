import {ErrorPanelProps, InformationPanel} from '.';

export default function ErrorPanel() {
    const errorPanelParameters: ErrorPanelProps = {
        title: 'An error occurred',
        content: 'Please try again later.',
    };

    return <InformationPanel {...errorPanelParameters}></InformationPanel>;
}
