import {MedeinaTelemetryEvent} from '@/api/telemetry';
import {MedeinaCopyButton} from '@/components/ui/Buttons/MedeinaCopyButton';
import {MoreIcon, PinIcon, EditIcon} from '@/components/ui/icons';
import {
    Popover,
    PopoverTrigger,
    Button,
    PopoverSurface,
    ToggleButton,
    ToolbarButton,
} from '@fluentui/react-components';
import {ARIA_LABELS} from '../../admin/ChangeCapacityDialog/ChangeCapacity.constants';
import ExportEvaluator from '../ExportEvaluator';
import DeletePromptDialog from '../../prompts/DeletePromptDialog';
import RerunPromptDialog from '../../prompts/RerunPromptDialog';
import {useTranslation} from 'react-i18next';
import useClasses from './PromptActionsMenu.styles';
import {
    EvaluationState,
    useCreatePinnedEvaluation,
    useDeletePinnedEvaluation,
    useGetEvaluation,
    useIsEvaluationPinned,
} from '@/api/evaluations';
import {useEvaluationContext} from '../EvaluationContext';
import {useLayout} from '@/components/ui/Layout';
import {useTourable} from '@/components/ui/Tour';
import {useGetPrompts} from '@/api/prompts';
import {useMemo, useState} from 'react';
import {PromptActionsMenuProps} from '../../prompts/Prompt.types';

export default function PromptActionsMenu({onEdit, setErrMessage}: PromptActionsMenuProps) {
    const classes = useClasses();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const {t: tCommon} = useTranslation('common');
    const {t: tSession} = useTranslation('session');
    const {sessionId, promptId, evaluationId} = useEvaluationContext();
    const isPinned = useIsEvaluationPinned({sessionId, evaluationId});
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});

    const {sidePanel} = useLayout();
    const {toggleOpen} = sidePanel || {toggleOpen: () => {}};

    const pinTourRef = useTourable({id: 'investigation-evaluation-toolbar-pin'});
    const isEvaluationComplete = evaluation?.state === EvaluationState.Completed;

    const {mutate: createPinnedEvaluation} = useCreatePinnedEvaluation();
    const {mutate: deletePinnedEvaluation} = useDeletePinnedEvaluation();

    const {data: nextPrompts} = useGetPrompts({sessionId, lastPromptId: promptId});
    const isLastPrompt = useMemo<boolean>(() => {
        return nextPrompts?.value.length === 0 || false;
    }, [nextPrompts]);

    return (
        <Popover open={isPopoverOpen} closeOnScroll={true} positioning={'before'} size={'small'}>
            <PopoverTrigger disableButtonEnhancement>
                <Button
                    aria-label="More"
                    icon={<MoreIcon />}
                    appearance="subtle"
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                ></Button>
            </PopoverTrigger>
            <PopoverSurface tabIndex={0} className={classes.popoverSurface}>
                <ToggleButton
                    data-testid={isPinned ? 'pinned-prompt-button' : 'unpinned-prompt-button'}
                    checked={isPinned}
                    ref={!isPinned ? pinTourRef : undefined}
                    appearance="subtle"
                    icon={<PinIcon filled={isPinned} />}
                    disabled={!isEvaluationComplete}
                    onClick={() => {
                        toggleOpen?.();
                        setIsPopoverOpen(false);
                        if (isPinned) {
                            deletePinnedEvaluation({sessionId, evaluationId});
                        } else {
                            createPinnedEvaluation({
                                sessionId,
                                promptId,
                                evaluationId,
                            });
                        }
                    }}
                    className={classes.menuButton}
                >
                    {!isPinned ? tCommon('ButtonLabels.Pin') : tCommon('ButtonLabels.Unpin')}
                </ToggleButton>
                <ToolbarButton
                    data-testid="edit-prompt-button"
                    appearance="subtle"
                    icon={<EditIcon />}
                    onClick={() => {
                        onEdit('Edit');
                        setIsPopoverOpen(false);
                    }}
                    className={classes.menuButton}
                >
                    {tCommon('ButtonLabels.Edit')}
                </ToolbarButton>
                <RerunPromptDialog
                    {...{sessionId, promptId, isLastPrompt}}
                    setErrorMessage={setErrMessage}
                    label={tCommon('ButtonLabels.Re-run')}
                    className={classes.menuButton}
                />
                <DeletePromptDialog
                    {...{sessionId, promptId}}
                    label={tCommon('ButtonLabels.Delete')}
                    className={classes.menuButton}
                />
                <MedeinaCopyButton
                    copyText={evaluation?.result?.content ?? ''}
                    eventName={MedeinaTelemetryEvent.Evaluations.ExportAsCopy}
                    tooltipText={tSession(ARIA_LABELS.CopyResponse)}
                    isDisabled={!isEvaluationComplete}
                    label={tCommon('ButtonLabels.Copy')}
                    className={classes.menuButton}
                />
                <ExportEvaluator
                    sessionId={sessionId}
                    promptId={promptId}
                    evaluationId={evaluationId}
                    isDisabled={!isEvaluationComplete}
                    isSummaryExport={false}
                    enableCopy={false}
                    label={tCommon('ButtonLabels.Export')}
                    className={classes.menuButton}
                />
            </PopoverSurface>
        </Popover>
    );
}
