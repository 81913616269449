export type PromptbookAvailableFilterValue = {
    FilterValueType: PromptbookFilterValueType;
    FilterValueId: string;
    FilterValueName: string;
    IsSelected: boolean;
};

export enum PromptbookFilterValueType {
    Tag = 'Tag',
    Plugin = 'Plugin',
}

export type PromptbookAdvancedFilterProps = {
    availableFilterValues: PromptbookAvailableFilterValue[];
    onSelectedAdvancedFiltersChange?: (
        selectedFilterValues: PromptbookAvailableFilterValue[],
    ) => void;
    onAdvancedFilterVisibilityChange?: (expanded: boolean) => void;
};
