import {useQuery} from '@tanstack/react-query';
import useSessionsFetch from '../useSessionsFetch';
import {GetPromptRequest, Prompt} from './prompts.types';

export default function useGetPrompt({sessionId, promptId}: GetPromptRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);
    return useQuery({
        queryKey: ['sessions', sessionId, 'prompts', promptId],
        queryFn: async () => {
            return await sessionsFetch<Prompt>(`${sessionId}/prompts/${promptId}`, {
                method: 'GET',
            });
        },

        // This hook operates like a subscription, but we want to disable refetching when there's no work.
        refetchInterval: (data) => {
            // When running a promptbook, we don't have frontend control over the creation of the initial prompts/evaluations.
            // However, the evaluations for prompts after the first one won't be immediately available.
            // By refetching until an evaluationId is defined, we can ensure that we pick up uncontrolled delayed evaluations.
            if (!data || data?.latestEvaluationId) {
                return false;
            }

            // A list of prompts in a promptbook can take a while, so don't refetch too frequently.
            return 3000;
        },
    });
}
