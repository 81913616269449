import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        fontWeight: tokens.fontWeightRegular,
        ...shorthands.padding(tokens.spacingHorizontalXS),
        '& > span': {
            fontSize: '14px',
        },
    },
    newSession: {
        ...shorthands.padding(tokens.spacingHorizontalXS),
    },
});
