import useClasses from '../ManageWorkspaces.styles';
import {TableCellLayout} from '@fluentui/react-components';
import {Workspace} from '@/api/workspaces';
import {formatDistanceToNow} from 'date-fns';

interface LastUpdatedColumnProps {
    workspace: Workspace;
}
export const LastUpdatedColumn: React.FC<LastUpdatedColumnProps> = ({workspace}) => {
    const classes = useClasses();

    const renderRelativeTime = (timestamp: string) => {
        const date = new Date(timestamp);
        return formatDistanceToNow(date, {addSuffix: true});
    };
    return (
        <>
            <TableCellLayout>
                <div className={classes.overflowContent}>
                    <span>{renderRelativeTime(workspace?.systemData?.lastModifiedAt ?? '')}</span>
                </div>
            </TableCellLayout>
        </>
    );
};
