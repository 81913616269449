import {useEffect, useMemo, useState} from 'react';
import {EvaluationLog, EvaluationState, EvaluationStepStatus} from '@/api/evaluations';
import useClasses from './DetailedEvaluationLogs.styles';
import {DetailedEvaluationProps} from './Evaluation.types';
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
} from '@fluentui/react-components';
import {CancelledIcon, CompletionIcon} from '@/components/ui/icons';
import DetailedEvaluationLogPanel from './DetailedEvaluationLogPanel';
import {EvaluationLogLevels} from './Evaluation.types';
import {PageResponse} from '@/api/api.types';
import {DebugLevel, useAppState} from '@/api/app';
import formatTime from '@/util/timeFormatterUtil';
import {useTranslation} from 'react-i18next';
import {PreviewBadge} from '@/components/ui/Badges';

export default function DetailedEvaluationLogs({
    logs,
    evaluationState,
    isEvaluationComplete,
    previewState,
}: DetailedEvaluationProps) {
    const {debugLevel} = useAppState();
    const classes = useClasses();
    const {t} = useTranslation('session');
    const imagePath = '/images/icons/';
    const [allStepsCompleted, setAllStepsCompleted] = useState(false);

    // Filtering steps logs in logs based on debug level.
    const filteredLogs = useMemo<PageResponse<EvaluationLog> | undefined>(() => {
        if (logs?.value) {
            if (debugLevel === DebugLevel.Verbose) return logs;
            return {
                value: logs.value.reduce((evaluationLogs: EvaluationLog[], log: EvaluationLog) => {
                    const filteredStepLogs = log?.stepLogs?.filter((stepLog) => {
                        return EvaluationLogLevels.has(stepLog.logLevel);
                    });
                    evaluationLogs.push({...log, stepLogs: filteredStepLogs});
                    return evaluationLogs;
                }, [] as EvaluationLog[]),
            };
        }
    }, [logs, debugLevel]);

    useEffect(() => {
        if (
            (evaluationState === EvaluationState.Completed ||
                evaluationState === EvaluationState.Cancelled) &&
            filteredLogs
        ) {
            const hasErroredStep = filteredLogs?.value.some(
                (logItem: EvaluationLog) => logItem.stepStatus === EvaluationStepStatus.Errored,
            );

            setAllStepsCompleted(
                (!hasErroredStep || evaluationState === EvaluationState.Cancelled) &&
                    !isEvaluationComplete,
            );
        }
    }, [filteredLogs, evaluationState, isEvaluationComplete]);

    const isNotCancelledStepLog = (log: EvaluationLog) => {
        return (
            log.stepStatus === EvaluationStepStatus.Running ||
            log.stepStatus === EvaluationStepStatus.CompletedWithWarning
        );
    };

    const childPanelData = useMemo<
        {
            log: EvaluationLog;
            index: number;
        }[]
    >(() => {
        if (!filteredLogs || !filteredLogs.value) return [];
        switch (evaluationState) {
            case EvaluationState.Cancelled:
                const {runningLogs, cancelledLogs} = filteredLogs.value.reduce(
                    (acc, logItem: EvaluationLog) => {
                        if (isNotCancelledStepLog(logItem)) {
                            acc.runningLogs.push(logItem);
                        } else if (logItem.stepStatus === EvaluationStepStatus.Cancelled) {
                            acc.cancelledLogs.push(logItem);
                        }
                        return acc;
                    },
                    {runningLogs: [] as EvaluationLog[], cancelledLogs: [] as EvaluationLog[]},
                );
                // If there is only one log and it is cancelled, return an empty array.
                if (filteredLogs.value.length === 1 && cancelledLogs.length === 1) {
                    return [];
                }

                //setting last running log as cancelled log.
                if (runningLogs.length > 0) {
                    let nonCancelledLogs = filteredLogs.value.filter(
                        (logItem) => logItem.stepStatus !== EvaluationStepStatus.Cancelled,
                    );

                    return nonCancelledLogs?.map((logItem: EvaluationLog, index) => {
                        //If there is a cancelled step then set the updated Time of the last running log to the time of cancellation
                        //for more accuracy, and set the step status to cancelled.
                        if (index === nonCancelledLogs.length - 1 && cancelledLogs.length > 0) {
                            logItem.updatedAt = cancelledLogs[0].updatedAt;
                        }
                        return {
                            log: logItem,
                            index,
                        };
                    });
                } else {
                    // if evaluation log has no running then add cancelled step as last step.
                    return filteredLogs.value.map((logItem: EvaluationLog, index) => {
                        return {
                            log: logItem,
                            index,
                        };
                    });
                }
            default:
                //If evalaution is completed, running or errored then return all logs.
                return filteredLogs.value.map((logItem: EvaluationLog, index) => {
                    return {
                        log: logItem,
                        index,
                    };
                });
        }
    }, [filteredLogs, evaluationState]);

    const parentPanelData = (function () {
        if (
            (evaluationState === EvaluationState.Completed ||
                evaluationState === EvaluationState.Cancelled) &&
            filteredLogs
        ) {
            const completedSteps = filteredLogs.value.filter(
                (logItem) => logItem.stepStatus !== EvaluationStepStatus.Cancelled,
            );
            const stepCount = completedSteps.length; // taking only those steps which are not cancelled.
            const elapsedEvaluationTime = filteredLogs?.value.reduce((accumulator, logItem) => {
                return (
                    accumulator +
                    (new Date(logItem.updatedAt).getTime() -
                        new Date(logItem.createdAt).getTime()) /
                        1000
                );
            }, 0);

            return {
                value: '1',
                icon:
                    evaluationState === EvaluationState.Completed ? (
                        <CompletionIcon
                            className={classes.completeIcon}
                            filled
                            data-testid="parent-log-completed"
                        />
                    ) : (
                        <CancelledIcon
                            className={classes.cancelledIcon}
                            data-testid="parent-log-cancelled"
                        />
                    ),
                title:
                    evaluationState === EvaluationState.Completed
                        ? stepCount === 0
                            ? t('ProcessLog.Completed')
                            : stepCount > 1
                            ? t('ProcessLog.MultipleStepsCompleted', {count: stepCount})
                            : t('ProcessLog.SingleStepCompleted', {count: stepCount})
                        : stepCount === 0
                        ? t('ProcessLog.Cancelled')
                        : stepCount > 1
                        ? t('ProcessLog.CancelledMultipleSteps', {count: stepCount})
                        : t('ProcessLog.CancelledSingleStep', {count: stepCount}),
                time: `${formatTime(elapsedEvaluationTime.toFixed(0))}`,
                imageSrc: `${imagePath + 'Default.svg'}`,
            };
        }
    })();

    return (
        <>
            <div className={classes.root}>
                <div className={classes.debuggerContainer}>
                    {allStepsCompleted ? (
                        <Accordion collapsible className={classes.parentAccordion}>
                            <AccordionItem value={parentPanelData?.value}>
                                <AccordionHeader
                                    icon={parentPanelData?.icon}
                                    data-testid="parent-accordion"
                                >
                                    <div className={classes.topAccordionHeaderCol}>
                                        {parentPanelData?.title}
                                        <span
                                            className={classes.elapsedTime}
                                            data-testid="elapsed-time-parent-accordion"
                                        >
                                            {parentPanelData?.time}
                                        </span>
                                        <div className={classes.previewBadgeContainer}>
                                            <PreviewBadge
                                                previewState={previewState}
                                                tooltipContent={t(
                                                    'ProcessLog.PreviewTooltipContent',
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.iconCol}>
                                        {/* image will be added in parent accordion once we have the image code as a part of the evaluation response from the backend. */}
                                        {/* <Image
                                            height={40}
                                            width={40}
                                            fit="default"
                                            className={classes.image}
                                            src={parentPanelData?.imageSrc}
                                        /> */}
                                    </div>
                                </AccordionHeader>
                                <AccordionPanel
                                    className={classes.parentpanel}
                                    data-testid="child-accordion-panel"
                                >
                                    <Accordion multiple collapsible>
                                        {childPanelData.map((item) => (
                                            <DetailedEvaluationLogPanel
                                                key={item.index}
                                                log={item.log}
                                                index={item.index}
                                                classes={classes}
                                                allStepsCompleted={allStepsCompleted}
                                                evaluationState={evaluationState}
                                            />
                                        ))}
                                    </Accordion>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    ) : (
                        <Accordion multiple collapsible className={classes.parentAccordion}>
                            {childPanelData.map((item) => (
                                <DetailedEvaluationLogPanel
                                    key={item.index}
                                    log={item.log}
                                    index={item.index}
                                    classes={classes}
                                    allStepsCompleted={allStepsCompleted}
                                    evaluationState={evaluationState}
                                />
                            ))}
                        </Accordion>
                    )}
                </div>
            </div>
        </>
    );
}
