import {useEffect, useRef} from 'react';
import useTourContext from './useTourContext';
interface UseTourableProps {
    id: string;
}

// a utility hook to add a tourable element to the tour context
// this allows the element to be found using document locators
export const useTourable = ({id}: UseTourableProps) => {
    const ref = useRef<any>(null);
    const {activeTour} = useTourContext();

    // Detect
    useEffect(() => {
        if (activeTour && ref.current) {
            ref.current.setAttribute('data-tour-id', id);
        }
        // we only want to run these when ref or activeTour changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, activeTour]);

    return ref;
};
