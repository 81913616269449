// Generic raw JSON value.
export type JSONValue = string | number | boolean | null | JSONValue[] | {[key: string]: JSONValue};

export type ErrorResponse = {
    message: string;
    code: number;
};

export type OnErrorData = {
    name: string;
    response: Response;
    type: string;
};

export type PageResponse<T> = {
    [x: string]: any;
    value: T[];
    nextLink?: string | null;
};

export type CapacityUsageResponse<T> = {
    value: T[];
    count: number;
};

export type HttpVerbs = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export interface CustomFetchOptions {
    method?: HttpVerbs;
    body?: string | JSONValue | FormData | void;
    headersFromOptions?: {[key: string]: string} | null;
    scopes?: any;
}

export type SystemData = {
    createdBy: string;
    createdByType: string;
    createdAt: string;
    lastModifiedBy: string;
    lastModifiedByType: string;
    lastModifiedAt: string;
};

export type FidelisCollection<T> = {
    value: T[];
    count: number;
};

export type FidelisApiErrorLineItem = {
    code: string;
    message: string;
    target: string | null;
    details: any[];
};

export type FidelisApiErrorModel = {
    error: FidelisApiErrorLineItem;
};

export enum FidelisErrorType {
    ACCOUNT_GEO_NOT_EXISTS,
    GEO_INVALID_ACCOUNT,
    GEO_INVALID_FOR_ENV,
    ACCOUNT_CONFIGURED_IN_DIFFERENT_GEO,
}

export enum PreviewState {
    GA = 'GA',
    Public = 'Public',
    Private = 'Private',
}

export type SortingDirection = 'ascending' | 'descending';
