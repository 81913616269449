import {makeStyles} from '@fluentui/react-components';
import {
    LARGE_SCREEN_GUTTER_SIZE,
    STANDARD_GUTTER_SIZE,
    ScreenSize,
    ColumnWidth,
    COLUMNS,
    PIXEL,
} from './GridConstants';
import {useMemo} from 'react';

interface CSSClassParams {
    size: ScreenSize;
    columns: number;
    coulmnWidth: number;
    gutterSize: number;
    minWidth?: number;
    maxWidth?: number;
}

export default makeStyles({
    ...generateCSSClasses({
        size: ScreenSize.Small,
        columns: COLUMNS,
        coulmnWidth: ColumnWidth.SmallScreen,
        gutterSize: STANDARD_GUTTER_SIZE,
        maxWidth: 479,
    }),
    ...generateCSSClasses({
        size: ScreenSize.Medium,
        columns: COLUMNS,
        coulmnWidth: ColumnWidth.MediumScreen,
        gutterSize: STANDARD_GUTTER_SIZE,
        minWidth: 480,
        maxWidth: 639,
    }),
    ...generateCSSClasses({
        size: ScreenSize.Large,
        columns: COLUMNS,
        coulmnWidth: ColumnWidth.LargeScreen,
        gutterSize: STANDARD_GUTTER_SIZE,
        minWidth: 640,
        maxWidth: 1023,
    }),
    ...generateCSSClasses({
        size: ScreenSize.XLarge,
        columns: COLUMNS,
        coulmnWidth: ColumnWidth.XLargeScreen,
        gutterSize: STANDARD_GUTTER_SIZE,
        minWidth: 1024,
        maxWidth: 1365,
    }),
    ...generateCSSClasses({
        size: ScreenSize.XXLarge,
        columns: COLUMNS,
        coulmnWidth: ColumnWidth.XXLargeScreen,
        gutterSize: STANDARD_GUTTER_SIZE,
        minWidth: 1366,
        maxWidth: 1919,
    }),
    ...generateCSSClasses({
        size: ScreenSize.XXXLarge,
        columns: COLUMNS,
        coulmnWidth: ColumnWidth.XXXLargeScreen,
        gutterSize: LARGE_SCREEN_GUTTER_SIZE,
        minWidth: 1920,
    }),
});

function generateCSSClasses(params: CSSClassParams) {
    const {size, columns, coulmnWidth, minWidth, maxWidth, gutterSize} = params;
    const classes: {[key: string]: any} = {};

    for (let i = 0; i < columns; i++) {
        const className = `${size}-${i + 1}`;
        let mediaQuery = '';

        if (minWidth && maxWidth) {
            mediaQuery = `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
        } else if (maxWidth) {
            mediaQuery = `@media (max-width: ${maxWidth}px)`;
        } else if (minWidth) {
            mediaQuery = `@media (min-width: ${minWidth}px)`;
        }

        classes[className] = {
            [mediaQuery]: {
                flexBasis: coulmnWidth * (i + 1) + i * gutterSize + PIXEL,
                maxWidth: coulmnWidth * (i + 1) + i * gutterSize + PIXEL,
            },
        };
    }
    return classes;
}
