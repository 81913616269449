import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {TenantInfo} from './tenant.types';
import handleError from '@/api/handleError';

export default function useCreateTenantInfo() {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (body: TenantInfo) => {
            var response = await customFetch<Response>(
                `/settings/datashare`,
                {
                    method: 'POST',
                    body,
                },
                true,
            );
            if (response.status === 204) {
                return true;
            } else {
                handleError(response, 'Failed to create tenant info');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['settings', 'datashare']);
        },
    });
}
