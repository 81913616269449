import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    popoverSurface: {
        paddingLeft: '0px',
        paddingRight: '0px',
        maxHeight: '80vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    menuButton: {justifyContent: 'flex-start'},
});
