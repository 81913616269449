import React, {useEffect, useMemo, useRef} from 'react';
import {InvestigationPanelProps} from './Investigation.types';
import useClasses from './InvestigationPanel.styles';
import {
    Tab,
    TabList,
    SelectTabData,
    SelectTabEvent,
    TabValue,
    mergeClasses,
    Button,
    Tooltip,
} from '@fluentui/react-components';
import {useGetPinnedEvaluations} from '@/api/evaluations';
import {Markdown} from '@/components/ui';
import {DismissIcon, PinIcon} from '@/components/ui/icons';
import {useGetSession, useSessionPinboard} from '@/api/sessions';
import PinnedEvaluation from './PinnedEvaluation';
import {useSummarizeInvestigation} from '@/api/sessions/useSummarizeSession';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {useLayoutRef, LayoutArea, useLayout} from '@/components/ui/Layout';
import InvestigationTitleBar from './InvestigationTitleBar';
import MedeinaFeatures from '@/util/features';
import PinboardSummaryTags from './PinboardSummaryTags';
import {useTranslation} from 'react-i18next';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {useViewportSize, ScreenSize} from '@/components/ui/Grid';

const InvestigationPanel = (props: InvestigationPanelProps) => {
    // Setup layout management
    const {t} = useTranslation('session');
    const {sidePanel} = useLayout();
    const sidePanelRef = useLayoutRef({area: LayoutArea.sidePanel});
    const titleRef = useRef<HTMLDivElement>(null);
    const scrollClasses = useScrollClasses();
    const {sessionId, isReadOnly} = props;
    const classes = useClasses();
    const {data: sessionData} = useGetSession({
        sessionId,
    });
    const {data: pinnedEvaluations} = useGetPinnedEvaluations({sessionId});
    const pinnedItems = useMemo(() => pinnedEvaluations?.value || [], [pinnedEvaluations]);
    // when we are on a small or medium screen we'll want to turn off the sticky header behavior of the investigation panel
    const {sm, md} = useViewportSize();
    const disableSticky = sm || md;

    const {
        data: summaryResponse,
        refetch,
        isLoading,
        isRefetching: isSummarizing,
    } = useSummarizeInvestigation({sessionId, pinnedItems});

    const [selectedValue, setSelectedValue] = React.useState<TabValue>('summary');

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    const pinnedItemsLength = useMemo(
        () => pinnedEvaluations?.value?.length ?? 0,
        [pinnedEvaluations],
    );

    const {pinboardResponse, loading} = useSessionPinboard({
        sessionId,
        pinnedItems,
        pinnedItemsLength,
        sessionData,
    });
    // when pinnedItems change we need to resummarize our investigation
    useEffect(() => {
        // there needs to be pinned items
        // summarization shouldn't happen when the page first loads, only after
        if (pinnedItemsLength && !MedeinaFeatures.SavePinboardSummary) {
            refetch();
        }
    }, [pinnedItems]);

    const investigationTitle = sessionData?.name || t('Session', {sessionId: props.sessionId});

    const WrappingComponent = disableSticky ? 'div' : React.Fragment;
    const wrapperProps = disableSticky
        ? {className: mergeClasses(classes.root, scrollClasses.colorNeutralBackground2)}
        : {};

    return (
        <WrappingComponent {...wrapperProps}>
            <div
                className={mergeClasses(classes.stickyBox, disableSticky && classes.nonStickyBox)}
                data-testid="sticky"
            >
                <div className={classes.toolbar}>
                    <h2 className={classes.pinBoardHeading}>
                        <PinIcon className={classes.pinIcon} />
                        <MedeinaInfoLabel
                            labelContent={t('PinBoard')}
                            infoContent={t('PinBoardAbout')}
                        />
                    </h2>
                    <Tooltip content={t('ClosePinboardButton')} relationship="label">
                        <Button
                            icon={<DismissIcon />}
                            onClick={sidePanel?.toggleClosed}
                            appearance="subtle"
                            size="small"
                        />
                    </Tooltip>
                </div>
                <div className={classes.topSection}>
                    <div
                        ref={titleRef}
                        title={investigationTitle}
                        className={classes.investigationTitle}
                    >
                        {MedeinaFeatures.EditInvestigationNameExperience ? (
                            sessionData && (
                                <InvestigationTitleBar
                                    investigationTitle={investigationTitle}
                                    session={sessionData}
                                />
                            )
                        ) : (
                            <div>{investigationTitle}</div>
                        )}
                    </div>
                    {!(isLoading || isSummarizing) &&
                        !MedeinaFeatures.SavePinboardSummary &&
                        pinnedItemsLength > 0 && (
                            <div
                                className={classes.summaryTags}
                                aria-label={`${
                                    summaryResponse?.tags?.length
                                        ? t('CountOfTags', {0: summaryResponse?.tags?.length})
                                        : ''
                                }`}
                            >
                                <PinboardSummaryTags tags={summaryResponse?.tags || []} />
                            </div>
                        )}
                    {!loading && MedeinaFeatures.SavePinboardSummary && pinnedItemsLength > 0 && (
                        <div
                            className={classes.summaryTags}
                            aria-label={`${
                                pinboardResponse?.tags?.length
                                    ? t('CountOfTags', {0: summaryResponse?.tags?.length})
                                    : ''
                            }`}
                        >
                            <PinboardSummaryTags tags={pinboardResponse?.tags || []} />
                        </div>
                    )}
                    <TabList
                        selectedValue={selectedValue}
                        onTabSelect={onTabSelect}
                        className={classes.tabList}
                        tabIndex={0}
                    >
                        <Tab className={classes.firstTab} id="Summary" value="summary">
                            {t('Summary')}
                        </Tab>
                        <Tab id="Pinned" icon={<PinIcon />} value="pinned">
                            {t('PinnedItems', {0: pinnedItemsLength})}
                        </Tab>
                    </TabList>
                </div>
            </div>
            <div className={classes.tabContentContainer} data-testid="tabContentContainer">
                <div
                    className={mergeClasses(
                        classes.tabContent,
                        scrollClasses.colorNeutralBackground1,
                    )}
                    ref={sidePanelRef}
                    data-testid="tabContent"
                >
                    {selectedValue === 'summary' && (
                        <div className={classes.paddedContent}>
                            <div tabIndex={0}>
                                {pinnedItemsLength > 0 && (
                                    <>
                                        {!MedeinaFeatures.SavePinboardSummary &&
                                            (isLoading || isSummarizing) && (
                                                <p>{t('SummarizingSession')}</p>
                                            )}
                                        {MedeinaFeatures.SavePinboardSummary && loading && (
                                            <p>{t('SummarizingSession')}</p>
                                        )}
                                        <Markdown className={classes.summaryContent}>
                                            {(!MedeinaFeatures.SavePinboardSummary &&
                                            !(isLoading || isSummarizing)
                                                ? summaryResponse?.summary
                                                : MedeinaFeatures.SavePinboardSummary && !loading
                                                ? pinboardResponse?.summary
                                                : '') || ''}
                                        </Markdown>
                                    </>
                                )}
                                {pinnedItemsLength === 0 && (
                                    <p>{t('PinResponsesToSummarizeEvidence')}</p>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedValue === 'pinned' && (
                        <div className={classes.pinnedContent}>
                            {!pinnedItemsLength ? (
                                <div className={classes.noPinnedContent}>
                                    <p>{t('YouDontHaveAnythingPinnedYet')}</p>
                                </div>
                            ) : (
                                pinnedEvaluations?.value?.map((evaluation) => {
                                    return (
                                        <PinnedEvaluation
                                            key={evaluation.evaluationId}
                                            isReadOnly={isReadOnly}
                                            {...evaluation}
                                        />
                                    );
                                })
                            )}
                        </div>
                    )}
                </div>
            </div>
        </WrappingComponent>
    );
};

export default InvestigationPanel;
