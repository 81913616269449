import {
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Popover,
    PopoverSurface,
    PositioningImperativeRef,
} from '@fluentui/react-components';
import {FeedbackThanksMessageProps} from './EvaluationToolbar.types';
import {DismissIcon} from '@/components/ui/icons';
import useClasses from './EvaluationFeedbackThanksMessage.styles';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS} from './EvaluationToolbar.constants';
import {useViewportSize} from '@/components/ui/Grid';

export default function EvaluationFeedbackThanksMessage(props: FeedbackThanksMessageProps) {
    const classes = useClasses();
    const {feedbackThanks, setFeedbackThanks, triggerRef} = props;
    const {t: tSession} = useTranslation('session');
    const {sm: isSmallScreen} = useViewportSize();

    const ThanksImage = feedbackThanks?.image || (() => null);
    const thanksText = feedbackThanks?.text || '';

    // Position the feedback thanks message near the trigger button.
    const positioningRef = useRef<PositioningImperativeRef>(null);
    useEffect(() => {
        if (triggerRef.current) {
            positioningRef.current?.setTarget(triggerRef.current);
        }
    }, [triggerRef.current, isSmallScreen]);

    return !isSmallScreen ? (
        <Popover
            withArrow
            open={!!feedbackThanks}
            positioning={{positioningRef, position: 'after'}}
        >
            <PopoverSurface className={classes.root}>
                <ThanksImage className={classes.image} />
                <div aria-live="assertive">{tSession(thanksText)}</div>
                <Button
                    icon={<DismissIcon />}
                    appearance="subtle"
                    className={classes.dismissBtn}
                    onClick={() => setFeedbackThanks(null)}
                    aria-label={tSession(ARIA_LABELS.DismissEvaluationFeedbackThanksForm)}
                />
            </PopoverSurface>
        </Popover>
    ) : (
        <Drawer open={!!feedbackThanks} position="bottom" className={classes.drawerRoot}>
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            icon={<DismissIcon />}
                            appearance="subtle"
                            className={classes.dismissBtn}
                            onClick={() => setFeedbackThanks(null)}
                            aria-label={tSession(ARIA_LABELS.DismissEvaluationFeedbackThanksForm)}
                        />
                    }
                />
            </DrawerHeader>
            <DrawerBody className={classes.drawerBody}>
                <ThanksImage className={classes.image} />
                <div aria-live="assertive">{tSession(thanksText)}</div>
            </DrawerBody>
        </Drawer>
    );
}
