import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    stackStyle: {
        marginBottom: tokens.spacingVerticalM,
    },
    checkboxStyle: {
        marginRight: tokens.spacingHorizontalXS,
        marginTop: '5px',
    },
    imageStyle: {
        marginRight: tokens.spacingHorizontalM,
    },
    textStyle: {
        marginTop: tokens.spacingHorizontalXS,
    },
});
