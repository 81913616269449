import React, {useRef, useEffect} from 'react';
import {motion} from 'framer-motion';
import {mergeClasses, Link, useFocusFinders} from '@fluentui/react-components';
import {Shield} from '@/components/ui/icons';

import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useTourContext, TourControls} from '@/components/ui/Tour';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {useTranslation} from 'react-i18next';

import useClasses from './Privacy.styles';

function Terms() {
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const classes = useClasses();
    const {t} = useTranslation('tours');

    const mainRef = useRef(null);
    const {findFirstFocusable} = useFocusFinders();

    useEffect(() => {
        if (mainRef.current === null) return;
        const firstFocusableElement = findFirstFocusable(mainRef.current);
        firstFocusableElement?.focus();
    }, [mainRef]);

    return (
        <>
            <div className={tourClasses.tourContainerContent}>
                <motion.div
                    key="terms"
                    variants={overlayAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    ref={mainRef}
                >
                    <div className={mergeClasses(tourClasses.centeredText, classes.textWrapper)}>
                        <Shield className={classes.icon} />
                        <h1 className={mergeClasses(tourClasses.blockTitleMedium, classes.title)}>
                            {t('common.privacyTitle')}
                        </h1>
                        <p className={tourClasses.blockTextMedium}>{t('common.privacyText')}</p>

                        <span className={classes.linksRow}>
                            <Link
                                href="https://privacy.microsoft.com/en-US/privacystatement"
                                aria-label="Microsoft Privacy Statement"
                                target="_blank"
                            >
                                {t('common.microsoftPrivacyStatement')}
                            </Link>{' '}
                            |{' '}
                            <Link
                                href="https://www.microsoft.com/en-us/ai/principles-and-approach/"
                                aria-label="Microsoft Responsible AI"
                                target="_blank"
                            >
                                {t('common.microsoftRAI')}
                            </Link>
                        </span>
                    </div>
                </motion.div>
            </div>
            <motion.div
                key="controls"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
                ref={mainRef}
            >
                <TourControls
                    handlePrevious={tour.prevStop}
                    handleNext={tour.nextStop}
                    previousButton={t('common.back')}
                    nextButton={t('common.iUnderstand')}
                />
            </motion.div>
        </>
    );
}

export default Terms;
