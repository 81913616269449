import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Skillset, SkillsetCatalogScope} from './skills.types';
import {doSkillsetNamespaceInvalidation} from './skillsetUtils';

export default function useDeleteSkillset(
    skillsetName: string,
    catalogScope: SkillsetCatalogScope,
) {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async () => {
            const queryParams = `?scope=${catalogScope}`;
            try {
                return await customFetch<Skillset>(`/skillsets/${skillsetName}${queryParams}`, {
                    method: 'DELETE',
                });
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => doSkillsetNamespaceInvalidation(queryClient),
    });
}
