import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {SidePanelWidth, NavigationBarHeight} from '@/components/App.styles';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 0),
        width: '100%',
        wordBreak: 'break-word',
        position: 'fixed',
        ...shorthands.overflow('hidden'),
        height: `calc(100vh - ${NavigationBarHeight}px)`,
    },
    interactions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...shorthands.margin(0, 'auto'),
        paddingTop: tokens.spacingVerticalL,
        paddingRight: tokens.spacingHorizontalS,
        overflowAnchor: 'none',
    },
    interactionsAnchor: {
        overflowAnchor: 'auto',
        height: '1px',
        alignSelf: 'stretch',
    },
    fullWidthInteractions: {
        marginRight: '70px',
    },
    scrollableContainer: {
        ...shorthands.overflow('auto'),
        flexGrow: 1,
        marginRight: tokens.spacingHorizontalS,
    },

    aiCaption: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        ...shorthands.padding(tokens.spacingHorizontalL),
        color: tokens.colorNeutralForeground2,
    },
    alert: {
        marginTop: tokens.spacingVerticalM,
    },
    pills: {
        ...shorthands.flex(0, 0, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    promptbar: {
        ...shorthands.flex(0, 0, 'auto'),
        paddingTop: tokens.spacingVerticalL,
        marginLeft: '56px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'sticky',
        bottom: 0,
        flexShrink: 0,
    },
    promptsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    promptBarSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: tokens.spacingVerticalL,
        '@media (max-width: 320px)': {
            alignItems: 'flex-start',
        },
    },
    promptbarFullWidth: {
        alignItems: 'flex-start',
        marginLeft: '130px',
        marginRight: '130px',
    },
    promptSectionWrapper: {
        width: '700px',
    },
    promptSectionWrapperFullWidth: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80vw',
    },
});
