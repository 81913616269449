import React, {useRef, useState, useCallback, useMemo, ChangeEvent} from 'react';
import useClasses from './CustomFileInput.styles';
import {Button, Text, Link} from '@fluentui/react-components';
import {DeleteIcon} from '@/components/ui/icons';
import {CustomFileInputProps} from './CustomFileInput.types';

function CustomFileInput({
    fileDescription,
    displayIcon,
    setUploadedFile,
    acceptedFileTypes,
}: CustomFileInputProps) {
    const classes = useClasses();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    // Whether the file input area is hovered or not
    const [hovered, setHovered] = React.useState(false);

    // Toggle the hover state of the file input area
    const toggleHovered = (hover: boolean) => {
        setHovered(hover);
    };

    const handleFileInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0] || null;

            if (file && !isValidFileType(file)) {
                setError('Invalid file type. Please select a valid file.');
                setSelectedFile(null);
                return;
            } else {
                setError(null);
            }

            setSelectedFile(file);
            setUploadedFile?.(file);
        },
        [acceptedFileTypes, fileInputRef],
    );

    const delimitedAcceptedFileTypes = acceptedFileTypes?.join(', ');

    const isValidFileType = useMemo(() => {
        return (file: File) => {
            return acceptedFileTypes?.some((type) => file.name.toLowerCase().endsWith(type));
        };
    }, [acceptedFileTypes]);

    const handleClearClick = () => {
        setSelectedFile(null);
        setError(null);
        setUploadedFile?.(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const file = e.dataTransfer.files[0];
        setSelectedFile(file);
        setUploadedFile?.(file);
    };

    const openFile = (file: File) => {
        if (file) {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
        }
    };

    return (
        <div>
            {!selectedFile && (
                <div
                    className={hovered ? classes.rootHover : classes.root}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onMouseEnter={() => toggleHovered(true)}
                    onMouseLeave={() => toggleHovered(false)}
                    onClick={() => fileInputRef.current?.click()}
                >
                    <Button
                        icon={displayIcon}
                        appearance="subtle"
                        size="large"
                        aria-label="File upload icon"
                    />
                    <Text className={classes.headingText}>Upload file</Text>
                    <Text className={classes.text}>{fileDescription}</Text>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{display: 'none'}}
                        onChange={handleFileInputChange}
                        accept={delimitedAcceptedFileTypes}
                    />

                    {error && <Text className={classes.errorText}>{error}</Text>}
                </div>
            )}
            {selectedFile && (
                <div>
                    <Text>
                        <Link
                            href="#"
                            onClick={() => openFile(selectedFile)}
                            className={classes.selectedFileText}
                        >
                            {selectedFile.name}
                        </Link>
                    </Text>
                    <Button
                        icon={<DeleteIcon />}
                        appearance="subtle"
                        size="medium"
                        onClick={handleClearClick}
                        aria-label="Delete selected file"
                    />
                </div>
            )}
        </div>
    );
}

export default CustomFileInput;
