import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {FileScope, FileUsage} from './files.types';

export default function useGetFileUsage() {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['files/usage/user'],
        queryFn: async () => {
            return await customFetch<FileUsage>(`/files/usage/` + FileScope.User, {
                method: 'GET',
            });
        },
    });
}
