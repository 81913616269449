import {useMutation} from '@tanstack/react-query';
import {useAppInsights} from '@/util/telemetry';
import {
    IMedeinaExtendedTelemetryItem,
    MedeinaEvent,
    MedeinaTelemetryEvent,
} from './telemetry.types';
import MedeinaFeatures from '@/util/features';

/**
 * Custom hook to track events using Application Insights.
 *
 * This hook uses the `useMutation` hook from `@tanstack/react-query` to create a mutation
 * function that tracks events using Application Insights. The event name is set to
 * 'Microsoft.Medeina.ActionEvent' by default, and additional custom properties can be provided.
 *
 * @returns {object} - The mutation object returned by `useMutation`.
 */
export default function useTrackEvent() {
    const appInsights = useAppInsights();
    return useMutation({
        /**
         * Mutation function to track an event.
         *
         * @param {IMedeinaExtendedTelemetryItem} event - The event to be tracked.
         * @returns {Promise<void>} - A promise that resolves when the event has been tracked.
         */
        mutationFn: async (event: IMedeinaExtendedTelemetryItem) => {
            // Added feature flag to disable user interaction data for environments.
            if (
                event.eventType === MedeinaEvent.UserInteractionData &&
                !MedeinaFeatures.EnableUserInteractionData
            ) {
                return null;
            }
            return appInsights?.trackEvent(
                {
                    ...event,
                    name: event.eventType,
                    data: {
                        ...(event.data ?? {}),
                        name: event.name ?? MedeinaTelemetryEvent.Unknown,
                    },
                },
                event.customProperties ?? {},
            );
        },
    });
}
