import {PromptbookPrompts} from '@/api/promptbooks';
import {useEffect, useRef, useState} from 'react';
import useClasses from './PromptbookPrompt.styles';
import {
    Button,
    Label,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Textarea,
    ToolbarButton,
    mergeClasses,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {
    AcceptIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    DeleteIcon,
    DismissIcon,
    EditIcon,
} from '@/components/ui/icons';
import {EMPTY_PROMPTBOOK_PROMPT_CONTENT} from './Promptbook.types';
import {MoreHorizontalIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
export interface PromptbookPromptProps {
    promptDefn: PromptbookPrompts;
    totalItems: number;
    // Optional index for use in lists
    index?: number;
    onPromptDeleted?: (index: number) => void;
    onPromptMovedDown?: (index: number) => void;
    onPromptMovedUp?: (index: number) => void;
    onPromptEdited?: (index: number, promptDefn: PromptbookPrompts) => void;
    onPromptDataLoaded?: (index: number, promptDefn: PromptbookPrompts) => void;
}

export default function PromptbookPrompt(props: PromptbookPromptProps) {
    const classes = useClasses();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const editIconRef = useRef<HTMLButtonElement>(null);
    const [editMode, setEditMode] = useState(false);
    const [tempPrompt, setTempPrompt] = useState('');
    const [promptDefn, setPromptDefn] = useState<PromptbookPrompts>(props.promptDefn);
    const {t} = useTranslation('promptbooks');
    // handlers for restoring focus when switching from edit mode back to normal mode
    const restoreFocusSource = useRestoreFocusSource();
    const restoreFocusTarget = useRestoreFocusTarget();

    const handleEditPrompt = (promptDefn: PromptbookPrompts) => {
        setEditMode(true);
        setTempPrompt(promptDefn.content ?? '');
    };

    const handleDeletePrompt = (index: number) => {
        if (index > -1) {
            props.onPromptDeleted?.(index);
        }
    };

    const handlePromptMoveUp = (index: number) => {
        if (index > -1) {
            props.onPromptMovedUp?.(index);
        }
    };

    const handlePromptMoveDown = (index: number) => {
        if (index > -1) {
            props.onPromptMovedDown?.(index);
        }
    };

    const handleAcceptEditPrompt = (index: number) => {
        const newDefn: PromptbookPrompts = {...promptDefn!, content: tempPrompt, plugins: []};
        setPromptDefn(newDefn);
        setTempPrompt('');
        setEditMode(false);
        props.onPromptEdited?.(index, newDefn);
    };

    const handleClosePromptEditing = (index: number) => {
        setTempPrompt('');
        setEditMode(false);
    };

    useEffect(() => {
        if (editMode && textAreaRef && textAreaRef.current) {
            textAreaRef.current.focus();
        } else if (!editMode && editIconRef && editIconRef.current) {
            editIconRef.current.focus();
        }
    }, [editMode]);

    return (
        <>
            {props.promptDefn &&
                (editMode ? (
                    // edit mode
                    <div
                        className={mergeClasses(classes.flexContainerRow, classes.flexGrow)}
                        data-test-id={'edit-duplicate-prompt'}
                        {...restoreFocusSource}
                    >
                        <Textarea
                            className={classes.flexGrow}
                            placeholder={t(EMPTY_PROMPTBOOK_PROMPT_CONTENT)}
                            value={tempPrompt}
                            data-testid="edit-prompt-textarea"
                            resize="vertical"
                            onChange={(e, data) => {
                                setTempPrompt(data.value);
                            }}
                            ref={textAreaRef}
                        />
                        <div className={classes.promptbookInputActionsContainer}>
                            <ToolbarButton
                                className={classes.promptbookInputActions}
                                appearance="subtle"
                                data-testid="accept-edit-prompt"
                                aria-label={t('AcceptEdit')}
                                icon={<AcceptIcon />}
                                onClick={() => handleAcceptEditPrompt(props.index ?? -1)}
                            />
                            <ToolbarButton
                                className={classes.promptbookInputActions}
                                appearance="subtle"
                                data-testid="close-edit-prompt"
                                aria-label={t('CloseEdit')}
                                icon={<DismissIcon />}
                                onClick={() => handleClosePromptEditing(props.index ?? -1)}
                            />
                        </div>
                    </div>
                ) : (
                    // Normal mode
                    <div
                        className={mergeClasses(
                            classes.flexContainerRow,
                            classes.flexGrow,
                            classes.hoverParentPromptInput,
                            classes.promptLabel,
                        )}
                        role="group"
                        tabIndex={0}
                        data-test-id={'edit-duplicate-prompt'}
                        {...restoreFocusTarget}
                    >
                        <Label className={classes.flexGrow}>
                            <div>
                                <div className={classes.container}>
                                    <div className={classes.promptNumber}>
                                        {props.index ? props.index! + 1 : 1}
                                    </div>
                                    <p className={classes.promptContent}>
                                        {props.promptDefn.content ??
                                            t(EMPTY_PROMPTBOOK_PROMPT_CONTENT)}
                                    </p>
                                </div>
                            </div>
                        </Label>
                        <ToolbarButton
                            className={classes.promptbookInput}
                            appearance="subtle"
                            data-testid="edit-prompt"
                            icon={<EditIcon />}
                            aria-label={t('EditPrompt')}
                            role="button"
                            ref={editIconRef}
                            onClick={() => handleEditPrompt(props.promptDefn)}
                        />
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button
                                    icon={<MoreHorizontalIcon />}
                                    data-testid="more-options"
                                    appearance="subtle"
                                    className={classes.moreOptionsButton}
                                    aria-label={t('SessionOptionsButton')}
                                />
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    <MenuItem
                                        icon={<DeleteIcon />}
                                        data-testid="delete-prompt"
                                        onClick={() => {
                                            handleDeletePrompt(props.index ?? -1);
                                        }}
                                    >
                                        {t('Delete')}
                                    </MenuItem>
                                    {props.index !== 0 && (
                                        <MenuItem
                                            icon={<ArrowUpIcon />}
                                            data-testid="move-up-prompt"
                                            onClick={() => {
                                                handlePromptMoveUp(props.index ?? -1);
                                            }}
                                        >
                                            {t('Move Up')}
                                        </MenuItem>
                                    )}
                                    {props.index !== props.totalItems - 1 && (
                                        <MenuItem
                                            icon={<ArrowDownIcon />}
                                            data-testid="move-down-prompt"
                                            onClick={() => {
                                                handlePromptMoveDown(props.index ?? -1);
                                            }}
                                        >
                                            {t('Move Down')}
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                ))}
        </>
    );
}
