import {Persona} from '@fluentui/react-components';
import {useMemo} from 'react';
import {MemberItemProps} from '../rbac.types';
import {useTranslation} from 'react-i18next';
import useClasses from '../MemberListItem/MemberListItem.styles';

interface GroupItemProps {
    memberCount: number;
}

export default function GroupItem(props: GroupItemProps & MemberItemProps) {
    const {t} = useTranslation('admin');
    const classes = useClasses();
    const generateSecondaryText = useMemo(() => {
        return (
            <>
                {t('RoleAssignment.Group')} • {props.memberCount}{' '}
                {props.memberCount > 1 ? t('RoleAssignment.Users') : t('RoleAssignment.User')}
            </>
        );
    }, [props.memberCount]);

    return (
        <Persona
            name={props.name}
            id={props.id}
            secondaryText={generateSecondaryText}
            avatar={{color: 'colorful'}}
            className={classes.memberPersona}
        ></Persona>
    );
}
