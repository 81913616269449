import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {iconFilledClassName} from '@fluentui/react-icons';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    searchbar: {
        width: '324px',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXS),
    },
    btnSearchStart: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
    },
    subButton: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        color: 'inherit',
        marginLeft: tokens.spacingHorizontalXS,
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            color: tokens.colorBrandForegroundInvertedHover,
        },
    },
    btnAddFilter: {
        ...shorthands.margin(0, tokens.spacingHorizontalSNudge),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
    },
    filterName: {
        fontWeight: tokens.fontWeightRegular,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    filterToggleButton: {
        fontWeight: tokens.fontWeightRegular,
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border('2px', 'solid', tokens.colorNeutralStroke1),
        whiteSpace: 'nowrap',
        height: tokens.lineHeightBase600,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: tokens.spacingHorizontalXXS,
        whiteSpace: 'nowrap',
    },
    iconNewFilter: {
        ':hover': {
            [`& .${iconFilledClassName}`]: {
                display: 'inline',
            },
        },
    },
});
