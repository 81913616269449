import React, {useEffect, useState, useRef} from 'react';
import {supportCentralManager} from './SupportCentralManager';
import {ISupportCentralConfig, SupportCentralHostingEvents} from './SupportCentral.types';
import {SupportCentralHostingDiv} from './SupportCentral.constants';
import {ColorScheme, useAppState} from '@/api/app';
import {SupportCentralProps} from '@/components/ui/PromptBar/PortalAssistance/PortalAssistance/..';

import {Button, mergeClasses, Link, Spinner, Label} from '@fluentui/react-components';
import {DismissIcon} from '@/components/ui/icons';
import useClasses from './SupportCentralWidget.styles';
import {useTranslation} from 'react-i18next';
/**
 * SupportCentralWidget renders a div component that displays the support central widget.
 * @param {SupportCentralProps} props - The props for the component.
 * @returns {JSX.Element} - The rendered dialog component.
 */
const SupportCentralWidget: React.FC<any> = (props: SupportCentralProps) => {
    const classes = useClasses();
    const supportHostDivId = SupportCentralHostingDiv;
    const {colorScheme} = useAppState();
    const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const {t: tCommon} = useTranslation('common');
    const {t: tPlugins} = useTranslation('plugins');
    const closeModal = () => {
        props.onCloseModal?.();
        setDialogOpen(false);
        // Needed for refocus on help button in AssistanceMenuItems.tsx after closing support central widget
        const helpButton = document.getElementById('help-button');
        if (helpButton) {
            helpButton.focus();
        }
    };
    const closeButtonRef = useRef(null);

    // Called when support central is loaded
    const onSupportCentralLoad = () => {
        // Checks if widget is available, logs error if not
        const supportCentralWidget = (window as any).SupportCentral;
        if (!supportCentralWidget) {
            const eventData = {
                message: tCommon('Errors.ErrorDownloading'),
            };
            console.error(eventData);
            setHasError(true);
            setIsLoaded(true);
            return;
        }
        // Gets configuration of widget
        supportCentralManager
            .getConfig()
            .then((config: ISupportCentralConfig) => {
                config.parentDiv = document.getElementById(supportHostDivId) as HTMLDivElement;
                config.eventListener = (eventType: SupportCentralHostingEvents) => {
                    supportCentralEventListener(eventType);
                };
                supportCentralWidget.init(config);
                onThemeChange();
            })
            .catch((reason: any) => {
                const eventData = {
                    message: `${tCommon('Errors.ErrorConfiguring')} ${JSON.stringify(reason)}`,
                };
                console.error(eventData);
                setHasError(true);
            });
    };
    // This function is called when support central fails to load
    const onSupportCentralError = (_ev: Event) => {
        const eventData = {
            message: tCommon('Errors.ErrorDownloading'),
        };
        console.error(eventData);
        setHasError(true);
    };
    // Changes theme of widget respectively to the theme of the portal
    const onThemeChange = () => {
        if (colorScheme === ColorScheme.Dark || colorScheme === ColorScheme.HighContrast) {
            window.document.dispatchEvent(
                new CustomEvent('SupportCentralSetDarkMode', {
                    detail: true,
                    bubbles: true,
                    cancelable: true,
                }),
            );
        } else if (colorScheme === ColorScheme.Light) {
            window.document.dispatchEvent(
                new CustomEvent('SupportCentralSetDarkMode', {
                    detail: false,
                    bubbles: true,
                    cancelable: true,
                }),
            );
        }
    };
    /* 
    This function is called when an event occurs in support central.
    If event is successful, it logs a success message and adds a class to maxStateParent div to change the CSS style.
    If failed, it logs an error message.
    */
    const supportCentralEventListener = (event: SupportCentralHostingEvents) => {
        if (event === SupportCentralHostingEvents.LoadSuccess) {
            const eventData = {
                message: tCommon('LoadedSuccessfully'),
            };
            const maxStateParent = document.getElementById(
                'm365-support-central-max-state-container',
            ) as HTMLDivElement;
            if (!!maxStateParent) {
                maxStateParent.className += ` ${classes.maxStateContainer}`;
            }
            onThemeChange();
        } else if (
            event === SupportCentralHostingEvents.ApiFailure ||
            event === SupportCentralHostingEvents.UnexpectedFailure ||
            event === SupportCentralHostingEvents.BundledLoadFailure
        ) {
            setHasError(true);
        }
        setIsLoaded(true);
    };
    // Loads support central widget by creating a script element and appending it to the body of the page
    const loadSupportCentral = () => {
        if (
            !window.document.querySelector(`script[src="${supportCentralManager.getScriptPath()}"]`)
        ) {
            const ScriptElement = document.createElement('script');
            ScriptElement.src = supportCentralManager.getScriptPath();
            ScriptElement.async = true;

            ScriptElement.addEventListener('load', onSupportCentralLoad);
            ScriptElement.addEventListener('error', onSupportCentralError);
            document.body.appendChild(ScriptElement);
        }
        onThemeChange();
    };

    // Loads support central widget when the component is mounted or when the colorScheme or props.openModel properties change
    useEffect(() => {
        loadSupportCentral();
        if (props.openModal && closeButtonRef.current) {
            (closeButtonRef.current as HTMLButtonElement).focus();
        }
    }, [colorScheme, props.openModal]);
    // Returns div that contains support central widget if no error or loaded, else returns error div or loading spinner
    return (
        <>
            <div
                id={supportHostDivId}
                className={mergeClasses(classes.root)}
                style={{
                    display: props.openModal ? 'block' : 'none',
                }}
            >
                <div style={{visibility: props.openModal ? 'visible' : 'hidden'}}>
                    <Button
                        id="imported-modal-close-button"
                        appearance="subtle"
                        aria-label="close-widget"
                        className={mergeClasses(classes.modalbutton, classes.button)}
                        data-testid="close-support-central-button"
                        icon={<DismissIcon />}
                        onClick={() => {
                            closeModal();
                        }}
                        ref={closeButtonRef}
                    />
                    {!isLoaded && (
                        <Spinner
                            labelPosition="below"
                            className={classes.spinner}
                            size="extra-large"
                            label={tPlugins('LoadingText')}
                        />
                    )}

                    {hasError && isLoaded && (
                        <div className={classes.errorContainer}>
                            <Label className={classes.label}>
                                {tCommon('Errors.UnableToLoadSupportCentral')}
                                <Link
                                    href="https://go.microsoft.com/fwlink/?linkid=2248712"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {tCommon('M365LinkText')}
                                </Link>
                            </Label>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SupportCentralWidget;
