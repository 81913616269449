import {Link, Persona} from '@fluentui/react-components';
import {MemberItemProps} from '../rbac.types';
import {useTranslation} from 'react-i18next';
import useClasses from '../MemberListItem/MemberListItem.styles';
import MedeinaVariables from '@/util/variables';
import {MICROSOFT_ENTRA_ID} from '@/api/rbac/rbac.constants';
export interface RoleItemProps extends MemberItemProps {
    hideLink?: boolean;
}

export default function RoleItem(props: RoleItemProps) {
    const {t} = useTranslation('admin');
    const classes = useClasses();
    const generateSecondaryText = () => {
        return (
            <>
                {t('RoleAssignment.Role')}{' '}
                {!props.hideLink && (
                    <>
                        • {t('RoleAssignment.RoleItemText')}{' '}
                        <Link href={MedeinaVariables.MicrosoftEntraUri} target="_blank">
                            {MICROSOFT_ENTRA_ID}
                        </Link>
                    </>
                )}
            </>
        );
    };

    return (
        <Persona
            name={props.name}
            secondaryText={generateSecondaryText()}
            avatar={{color: 'colorful'}}
            className={classes.memberPersona}
        ></Persona>
    );
}
