// A base component that provides an acrylic base
import React from 'react';
import {mergeClasses} from '@fluentui/react-components';
import {useClasses} from './AcrylicBase.styles';
import {AcrylicBaseProps} from './AcrylicBase.types';

function AcrylicBase(props: AcrylicBaseProps) {
    const {children, shadow, offset, className} = props;
    const classes = useClasses();

    return (
        <div className={mergeClasses(classes.fluidWrapper, className)} data-testid="acrylicWrapper">
            {children}
            <div
                className={mergeClasses(
                    classes.acrylicBackground,
                    classes.baseShadow,
                    shadow === 'xsmall' && classes.xSmallShadow,
                    shadow === 'small' && classes.smallShadow,
                    shadow === 'medium' && classes.mediumShadow,
                    shadow === 'large' && classes.largeShadow,
                    (!offset || offset === 'medium') && classes.offsetMedium,
                    offset === 'small' && classes.offsetSmall,
                    offset === 'large' && classes.offsetLarge,
                    offset === 'none' && classes.offsetNone,
                )}
                data-testid="acrylicBackground"
            />
            <div
                className={mergeClasses(
                    classes.acrylicForeground,
                    (!offset || offset === 'medium') && classes.offsetMedium,
                    offset === 'small' && classes.offsetSmall,
                    offset === 'large' && classes.offsetLarge,
                    offset === 'none' && classes.offsetNone,
                )}
                data-testid="acrylicForeground"
            />
        </div>
    );
}

export default AcrylicBase;
