import React from 'react';
import ConnectorModalContext from './ConnectorModalContext';

const useConnectorModalContext = () => {
    const connectorContext = React.useContext(ConnectorModalContext);

    React.useEffect(() => {
        // Handle updates to state when context changes
    }, [connectorContext]);

    return connectorContext;
};

export default useConnectorModalContext;
