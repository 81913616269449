import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    filterLabelText: {
        paddingBottom: tokens.spacingVerticalXXXL,
    },
    filter: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalSNudge),
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        maxWidth: '85%',
    },
    filterOptions: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalSNudge),
        ...shorthands.gap(tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        paddingBottom: tokens.spacingVerticalXXXL,
    },
    dropDownDPCombo: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    },
    dpButtonsCombo: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin(tokens.spacingVerticalXS),
    },
});
