import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';

import {CreatePromptbookRequest, PromptbookDescriptor} from './promptbooks.types';

export default function useCreatePromptbook() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: CreatePromptbookRequest) => {
            return await customFetch<PromptbookDescriptor>(`/promptbooks`, {
                method: 'POST',
                body,
            });
        },
        onSuccess: (promptbook, body) => {
            queryClient.setQueryData(['promptbooks', promptbook.promptbookId], promptbook);

            queryClient.refetchQueries({
                queryKey: ['promptbooks'],
            });
        },
    });
}
