import {useQuery} from '@tanstack/react-query';
import {GetSessionPinnedSummaryRequest, PinnedSummaryResponse} from './sessions.types';
import useSessionsFetch from '../useSessionsFetch';
import {isEmpty} from 'lodash-es';
import MedeinaFeatures from '@/util/features';
import {Session} from './sessions.types';
import {useUpdatePinboardSummary} from '@/api/sessions';

export default function useSummarizePins({
    sessionId,
    pinnedItems,
    sessionData,
}: GetSessionPinnedSummaryRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);
    const {mutate: updatePinboard} = useUpdatePinboardSummary();
    let evaluationIds =
        pinnedItems?.reduce(
            (accumulator, pinnedEvaluation) =>
                accumulator + `evaluationIds=${pinnedEvaluation.evaluationId}&`,
            '',
        ) || '';

    if (!!evaluationIds) {
        evaluationIds = evaluationIds.slice(0, -1);
    }
    return useQuery({
        queryKey: ['sessionSummary', sessionId, 'pinnedItems', pinnedItems],
        queryFn: async () => {
            return await sessionsFetch<PinnedSummaryResponse>(
                `${sessionId}/pinnedevaluations/summarize?${evaluationIds}`,
                {method: 'GET'},
            );
        },
        enabled: false,

        // This is expensive so don't refetch on browser state changes.
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (pinboard: PinnedSummaryResponse) => {
            if (pinboard != null && MedeinaFeatures.SavePinboardSummary) {
                const updatedSession: Session = {
                    ...sessionData,
                    sessionId: sessionData?.sessionId || '',
                    name: sessionData?.name || '',
                    pinboardSummary: pinboard || null,
                    sessionSummary: sessionData?.sessionSummary || null,
                    createdAt: sessionData?.createdAt || '',
                    updatedAt: sessionData?.updatedAt || '',
                };
                updatePinboard({
                    session: updatedSession,
                    pinnedItems: pinnedItems,
                });
            }
        },
    });
}
