import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {EvaluationFeedback, UpdateEvaluationFeedbackRequest} from '.';

export default function useUpdateEvaluationFeedback() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: UpdateEvaluationFeedbackRequest) => {
            return await customFetch<EvaluationFeedback>(
                `/sessions/${body.sessionId}/prompts/${body.promptId}/evaluations/${body.evaluationId}/feedbacks/${body.feedbackId}`,
                {
                    method: 'PATCH',
                    body,
                },
            );
        },
        onSuccess: (feedback, body) => {
            // Refetch feedback.
            queryClient.refetchQueries({
                queryKey: [
                    'sessions',
                    body.sessionId,
                    'prompts',
                    body.promptId,
                    'evaluations',
                    body.evaluationId,
                    'feedbacks',
                ],
                exact: true,
            });
        },
    });
}
