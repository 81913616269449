import {createLightTheme} from '@fluentui/react-components';
import {Neutrals, Neutral51, Brand, themeBrand} from '../colors';
import overrides from './overrides';
import {MedeinaTheme} from '../theme.types';

const theme = createLightTheme(themeBrand);

const lightTheme: MedeinaTheme = {
    ...theme,
    ...overrides,

    // OVERLAY
    colorBackgroundOverlay: Neutrals.blackAlpha[40],
    colorScrollbarOverlay: Neutrals.blackAlpha[50],

    // BRAND BACKGROUND
    colorBrandBackground: Brand.shade30,
    colorBrandBackgroundHover: Brand.shade20,
    colorBrandBackgroundPressed: Brand.shade40,
    colorBrandBackgroundSelected: Brand.shade10,

    colorCompoundBrandBackground: Brand.shade30,
    colorCompoundBrandBackgroundHover: Brand.shade20,
    colorCompoundBrandBackgroundPressed: Brand.shade40,

    colorBrandBackground2: Brand.shade30,
    colorBrandBackground2Hover: Brand.shade20,
    colorBrandBackground2Pressed: Brand.shade40,

    colorBrandBackgroundInverted: Neutral51[100],
    colorBrandBackgroundInvertedHover: Brand.shade30,
    colorBrandBackgroundInvertedPressed: Brand.shade40,
    colorBrandBackgroundInvertedSelected: Brand.shade10,
    colorBrandBackgroundStatic: Brand.shade20,

    // BRAND    FOREGROUND
    colorBrandForeground1: Brand.shade30,

    colorBrandForeground2: Brand.shade30,
    colorBrandForeground2Hover: Brand.shade20,
    colorBrandForeground2Pressed: Brand.shade40,

    colorBrandForegroundInverted: Brand.shade20,
    colorBrandForegroundInvertedHover: Brand.tint40,
    colorBrandForegroundInvertedPressed: Brand.tint20,

    colorBrandForegroundLink: Brand.shade30,
    colorBrandForegroundLinkHover: Brand.shade20,
    colorBrandForegroundLinkPressed: Brand.shade40,
    colorBrandForegroundLinkSelected: Brand.shade10,

    colorCompoundBrandForeground1: Brand.shade30,
    colorCompoundBrandForeground1Hover: Brand.shade20,
    colorCompoundBrandForeground1Pressed: Brand.shade40,

    colorBrandForegroundOnLight: Brand.shade30,
    colorBrandForegroundOnLightHover: Brand.shade20,
    colorBrandForegroundOnLightPressed: Brand.shade40,
    colorBrandForegroundOnLightSelected: Brand.shade10,

    // BRAND OTHER
    colorBrandStroke1: Brand.shade30,
    colorBrandStroke2: Brand.shade20,

    colorCompoundBrandStroke: Brand.shade30,
    colorCompoundBrandStrokeHover: Brand.shade20,
    colorCompoundBrandStrokePressed: Brand.shade40,

    // BACKGROUNDS
    colorNeutralBackground1: Neutral51[100],
    colorNeutralBackground1Hover: Neutral51[96],
    colorNeutralBackground1Pressed: Neutral51[88],
    colorNeutralBackground1Selected: Neutral51[92],
    colorNeutralBackground2: Neutral51[98],
    colorNeutralBackground2Hover: Neutral51[94],
    colorNeutralBackground2Pressed: Neutral51[86],
    colorNeutralBackground2Selected: Neutral51[90],
    colorNeutralBackground3: Neutral51[96],
    colorNeutralBackground3Hover: Neutral51[92],
    colorNeutralBackground3Pressed: Neutral51[84],
    colorNeutralBackground3Selected: Neutral51[88],
    colorNeutralBackground4: Neutral51[94],
    colorNeutralBackground4Hover: Neutral51[98],
    colorNeutralBackground4Pressed: Neutral51[96],
    colorNeutralBackground4Selected: Neutral51[100],
    colorNeutralBackground5: Neutral51[92],
    colorNeutralBackground5Hover: Neutral51[96],
    colorNeutralBackground5Pressed: Neutral51[94],
    colorNeutralBackground5Selected: Neutral51[98],
    colorNeutralBackground6: Neutral51[90],

    // BACKGROUND INVERTED
    colorNeutralBackgroundInverted: Neutral51[16],
    colorNeutralBackgroundInvertedDisabled: Neutral51[94],

    // SUBTLE BACKGROUNDS
    colorSubtleBackground: Neutrals.whiteAlpha[0],
    colorSubtleBackgroundHover: Neutral51[96],
    colorSubtleBackgroundPressed: Neutral51[88],
    colorSubtleBackgroundSelected: Neutral51[92],

    colorSubtleBackgroundLightAlphaHover: Neutrals.whiteAlpha[70],
    colorSubtleBackgroundLightAlphaPressed: Neutrals.whiteAlpha[50],
    colorSubtleBackgroundLightAlphaSelected: Neutrals.whiteAlpha[0],

    colorSubtleBackgroundInverted: Neutrals.blackAlpha[0],
    colorSubtleBackgroundInvertedHover: Neutrals.blackAlpha[10],
    colorSubtleBackgroundInvertedPressed: Neutrals.blackAlpha[30],
    colorSubtleBackgroundInvertedSelected: Neutrals.blackAlpha[20],

    colorNeutralBackgroundStatic: Neutral51[20],
    colorNeutralBackgroundDisabled: Neutral51[94],

    colorNeutralBackgroundAlpha: Neutrals.whiteAlpha[60],
    colorNeutralBackgroundAlpha2: Neutrals.whiteAlpha[40],

    // FOREGROUNDS
    colorNeutralForeground1: Neutral51[14],
    colorNeutralForeground1Hover: Neutral51[14],
    colorNeutralForeground1Pressed: Neutral51[14],
    colorNeutralForeground1Selected: Neutral51[14],
    colorNeutralForeground1Static: Neutral51[14],

    colorNeutralForeground2: Neutral51[26],
    colorNeutralForeground2Hover: Neutral51[14],
    colorNeutralForeground2Pressed: Neutral51[14],
    colorNeutralForeground2Selected: Neutral51[14],
    colorNeutralForeground2BrandHover: Brand.shade30,
    colorNeutralForeground2BrandPressed: Brand.shade20,
    colorNeutralForeground2BrandSelected: Brand.shade30,

    colorNeutralForeground2Link: Neutral51[26],
    colorNeutralForeground2LinkHover: Neutral51[14],
    colorNeutralForeground2LinkPressed: Neutral51[14],
    colorNeutralForeground2LinkSelected: Neutral51[14],

    colorNeutralForeground3: Neutral51[38],
    colorNeutralForeground3Hover: Neutral51[26],
    colorNeutralForeground3Pressed: Neutral51[26],
    colorNeutralForeground3Selected: Neutral51[26],
    colorNeutralForeground3BrandHover: Brand.shade20,
    colorNeutralForeground3BrandPressed: Brand.shade40,
    colorNeutralForeground3BrandSelected: Brand.shade10,

    colorNeutralForeground4: Neutral51[44],

    colorNeutralForegroundDisabled: Neutral51[74],
    colorNeutralForegroundInverted: Neutral51[100],
    colorNeutralForegroundInvertedHover: Neutral51[100],
    colorNeutralForegroundInvertedPressed: Neutral51[100],
    colorNeutralForegroundInvertedSelected: Neutral51[100],

    colorNeutralForegroundInvertedLink: Neutral51[100],
    colorNeutralForegroundInvertedLinkHover: Neutral51[100],
    colorNeutralForegroundInvertedLinkPressed: Neutral51[100],
    colorNeutralForegroundInvertedLinkSelected: Neutral51[100],

    colorNeutralForegroundOnBrand: Neutral51[100],

    colorNeutralStencil1: Neutral51[90],
    colorNeutralStencil2: Neutral51[98],

    // STROKES
    colorNeutralStroke1: Neutral51[82],
    colorNeutralStroke1Hover: Neutral51[78],
    colorNeutralStroke1Pressed: Neutral51[70],
    colorNeutralStroke1Selected: Neutral51[74],
    colorNeutralStroke2: Neutral51[88],
    colorNeutralStroke3: Neutral51[94],

    colorNeutralStrokeAccessible: Neutral51[38],
    colorNeutralStrokeAccessibleHover: Neutral51[34],
    colorNeutralStrokeAccessiblePressed: Neutral51[30],
    colorNeutralStrokeAccessibleSelected: Brand.shade30,

    colorNeutralStrokeDisabled: Neutral51[88],
    colorNeutralStrokeInvertedDisabled: Neutrals.whiteAlpha[40],

    colorNeutralStrokeOnBrand: Neutral51[100],
    colorNeutralStrokeOnBrand2: Neutral51[100],
    colorNeutralStrokeOnBrand2Hover: Neutral51[100],
    colorNeutralStrokeOnBrand2Pressed: Neutral51[100],
    colorNeutralStrokeOnBrand2Selected: Neutral51[100],

    colorStrokeFocus1: Neutral51[100],
    colorStrokeFocus2: Neutral51[0],

    colorTransparentBackground: Neutrals.whiteAlpha[0],
    colorTransparentBackgroundHover: Neutrals.whiteAlpha[0],
    colorTransparentBackgroundPressed: Neutrals.whiteAlpha[0],
    colorTransparentBackgroundSelected: Neutrals.whiteAlpha[0],
    colorTransparentStroke: Neutrals.whiteAlpha[0],
    colorTransparentStrokeDisabled: Neutrals.whiteAlpha[0],
    colorTransparentStrokeInteractive: Neutrals.whiteAlpha[0],

    // SECURITY SOURCES
    securitySourcesBackground: Neutrals.tint55,
    securitySourcesBackgroundHover: Neutrals.tint50,
    securitySourcesBackgroundPressed: Neutrals.tint40,

    // LEFTOVER STUFF
    // colorBrandShadowAmbient: '#xxxxxx',
    // colorBrandShadowKey: '#xxxxxx',
    // colorNeutralShadowAmbient: '#xxxxxx',
    // colorNeutralShadowAmbientDarker: '#xxxxxx',
    // colorNeutralShadowAmbientLighter: '#xxxxxx',
    // colorNeutralShadowKey: '#xxxxxx',
    // colorNeutralShadowKeyDarker: '#xxxxxx',
    // colorNeutralShadowKeyLighter: '#xxxxxx',
    // colorNeutralStencil1Alpha: '#xxxxxx',
    // colorNeutralStencil2Alpha: '#xxxxxx',
    // colorNeutralForegroundInvertedDisabled: '#xxxxxx',
    // colorNeutralForegroundInverted2: '#xxxxxx',
    // colorNeutralForegroundStaticInverted: '#xxxxxx',
};

export default lightTheme;
