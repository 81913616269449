import {IdValueData, UserDisplayData} from '@/components/ui/Filter/Filter.types';
import UsageMonitoringFilterPanel from './UsageMonitoringFilterPanel';
import {SearchFacet, KeyValueFilterDetails} from '@/api/capacities/capacities.types';

export default function useGetUsageDashboardFilterPanels() {
    const addGetMultiselectWidget = (
        title: string,
        category: string,
        data: string[],
        order: number,
    ) => {
        const idValueData: IdValueData[] = data.map((value) => {
            return {
                id: value,
                value: value,
            };
        });

        return {
            node: (
                <UsageMonitoringFilterPanel
                    order={order}
                    category={category}
                    title={title}
                    data={idValueData}
                    showMultiselect={true}
                    showMultiSelectFilterSearch={false}
                    showUserFilterSearch={false}
                />
            ),
        };
    };

    const addGetMultiselectWidgetWithKeyValues = (
        title: string,
        category: string,
        data: SearchFacet,
        order: number,
    ) => {
        const multiSelectWidgetData: IdValueData[] = data.values.map((value) => {
            const filterDetails = value as KeyValueFilterDetails;
            return {
                id: filterDetails.id,
                value: filterDetails.displayValue,
            } as IdValueData;
        });

        return {
            node: (
                <UsageMonitoringFilterPanel
                    order={order}
                    category={category}
                    title={title}
                    data={multiSelectWidgetData}
                    showMultiselect={true}
                    showMultiSelectFilterSearch={false}
                    showUserFilterSearch={false}
                />
            ),
        };
    };

    const addGetSearchableMultiSelectWidget = (
        title: string,
        category: string,
        data: string[],
        order: number,
    ) => {
        const idValueData: IdValueData[] = data.map((value) => {
            return {
                id: value,
                value: value,
            };
        });

        return {
            node: (
                <UsageMonitoringFilterPanel
                    order={order}
                    title={title}
                    category={category}
                    data={idValueData}
                    showMultiselect={true}
                    showMultiSelectFilterSearch={true}
                    showUserFilterSearch={false}
                />
            ),
        };
    };

    const addUserWidget = (title: string, category: string, data: SearchFacet, order: number) => {
        //convert data to UserDisplayData
        const userDisplayData: UserDisplayData[] = data.values.map((value) => {
            const userDetail = value as KeyValueFilterDetails;
            return {
                id: userDetail.id,
                displayName: userDetail.displayValue,
            };
        });

        return {
            node: (
                <UsageMonitoringFilterPanel
                    order={order}
                    category={category}
                    title={title}
                    data={userDisplayData}
                    showMultiselect={false}
                    showMultiSelectFilterSearch={false}
                    showUserFilterSearch={true}
                />
            ),
        };
    };

    return {
        addGetMultiselectWidget,
        addGetSearchableMultiSelectWidget,
        addUserWidget,
        addGetMultiselectWidgetWithKeyValues,
    };
}
