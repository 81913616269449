export function ensurePopoverVisibility(popoverElement: HTMLElement | null) {
    if (!popoverElement) return;

    const boundingRect = popoverElement.getBoundingClientRect();
    const isInViewportVertically =
        boundingRect.top >= 0 && boundingRect.bottom <= window.innerHeight;
    const isInViewportHorizontally =
        boundingRect.left >= 0 && boundingRect.right <= window.innerWidth;
    if (!isInViewportVertically || !isInViewportHorizontally) {
        // For a universal approach, consider the closest scrollable parent or default to the main window
        const scrollableParent = findScrollableParent(popoverElement) || window;
        if (scrollableParent === window) {
            window.scrollTo({
                top: boundingRect.top - window.innerHeight / 2,
                left: boundingRect.left - window.innerWidth / 2,
                behavior: 'smooth',
            });
        } else if (scrollableParent instanceof HTMLElement) {
            scrollableParent.scroll({
                top: popoverElement.offsetTop - scrollableParent.clientHeight / 2,
                left: popoverElement.offsetLeft - scrollableParent.clientWidth / 2,
                behavior: 'smooth',
            });
        } else {
            popoverElement.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
    }
}

export function findScrollableParent(element: HTMLElement): HTMLElement | null {
    let searchElement: HTMLElement | null = element;
    while (searchElement) {
        // Use searchElement in the loop condition
        if (searchElement === document.body) {
            break;
        } // document.body is the default scrollable parent
        const overflowY = window.getComputedStyle(searchElement).overflowY;
        const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
        if (isScrollable && searchElement.scrollHeight > searchElement.clientHeight) {
            return searchElement;
        }
        searchElement = searchElement.parentElement; // Correctly updates searchElement
    }
    return null;
}
