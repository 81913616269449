import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {PageResponse} from '../api';
import {AvailablePersona} from './prompts.types';

export default function useGetPersona() {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['personas'],
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const personas: string[] = await customFetch(`/personas/values`, {
                method: 'GET',
            });
            const personasWithId: AvailablePersona = {
                personaList: personas.map((persona: string, index: number) => {
                    return {id: index.toString(), name: persona};
                }),
            };
            return personasWithId;
        },
    });
}
