import {Caption1, Text} from '@fluentui/react-components';
import useClasses from './EvaluationLogs.styles';
import {EvaluationLogsSummaryProps} from './Evaluation.types';
import {PageResponse} from '@/api/api.types';
import {DebugLevel, useAppState} from '@/api/app';
import {EvaluationLog, EvaluationLogLevel} from '@/api/evaluations';
import {useMemo} from 'react';

export default function EvaluationLogsSummary({logs}: EvaluationLogsSummaryProps) {
    const {debugLevel} = useAppState();
    const classes = useClasses();

    const filteredLogs = useMemo<PageResponse<EvaluationLog> | undefined>(() => {
        if (logs?.value) {
            return {
                value: logs.value.filter((log) => {
                    if (debugLevel === DebugLevel.Summary) {
                        return [
                            EvaluationLogLevel.Error,
                            EvaluationLogLevel.Warning,
                            EvaluationLogLevel.Information,
                        ].includes(log.logLevel);
                    } else if (debugLevel === DebugLevel.Verbose) {
                        return true;
                    }
                    return false;
                }),
            };
        }
    }, [logs, debugLevel]);
    return (
        <div className={classes.root}>
            {!!filteredLogs &&
                filteredLogs.value.length > 0 &&
                filteredLogs.value.map((log) => (
                    <div key={log.logId}>
                        <Caption1 className={classes.caption}>{log.skillName}</Caption1>
                        {log.content && (
                            <>
                                &ensp;|&ensp;
                                <Text className={classes.captionMetadata}>{log.content}</Text>
                            </>
                        )}
                    </div>
                ))}
        </div>
    );
}
