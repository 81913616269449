import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
export default makeStyles({
    alert: {
        ...shorthands.borderTop('1px', 'solid', tokens.colorStatusWarningBorder1),
        ...shorthands.borderBottom('1px', 'solid', tokens.colorStatusWarningBorder1),
        backgroundColor: tokens.colorStatusWarningBackground1,
        display: 'flex',
        flexGrow: 1,
        ...shorthands.padding(tokens.spacingHorizontalMNudge, tokens.spacingHorizontalM),
        alignItems: ' center',
        flexGap: '8px',
        alignSelf: 'stretch',

        '& > button': {
            marginLeft: tokens.spacingHorizontalMNudge,
        },
    },
});
