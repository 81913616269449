import {useMemo} from 'react';
import {useMsalUserId} from '@/util/msal';
import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {GetSessionRequest, Session} from './sessions.types';

function useGetSessionBase({sessionId}: GetSessionRequest) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['sessionbase', sessionId],
        refetchOnWindowFocus: false,
        queryFn: async () => {
            return await customFetch<Session>(`/sessions/${sessionId}`, {method: 'GET'});
        },
    });
}

/**
 * Determines if the current user is the owner of a session.
 * If the user is not the owner of the session, they should not be able to edit it.
 * Assume the user is the owner until able to determine once the session is loaded.
 * This check is used to determine /sessions vs /sharedsessions route.
 * On first load, the /sessions route is used.
 * Upon useIsUserSessionOwner check complete, if isError, caller can query /sharedsessions route.
 *
 * @param sessionId - The ID of the session.
 * @returns An object containing a boolean value indicating if the user is the session owner.
 */
export default function useIsUserSessionOwner(sessionId: string): {
    isUserSessionOwner: boolean;
} {
    const userObjectId = useMsalUserId();
    // Call default /sessions route to find out if the current user is the session owner.
    const {data: session, isLoading} = useGetSessionBase({sessionId});

    const isUserSessionOwner = useMemo<boolean>(() => {
        const isSessionOwner = isLoading ? true : userObjectId === session?.userId;
        return isSessionOwner;
    }, [userObjectId, session, isLoading]);

    return {
        isUserSessionOwner,
    };
}
