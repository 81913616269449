import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {ColorScheme} from '@/api/app';

import useClasses from './Background.styles';
import {BackgroundAnimation, BackgroundProps, BackgroundImage} from './Background.types';

import FullScreenGradients from './FullScreenGradients';
import BottomScreenGradients from './BottomScreenGradients';

// a switch statement that returns the correct background image based on the current theme
function getBackgroundImage(colorScheme: ColorScheme) {
    switch (colorScheme) {
        case ColorScheme.Dark:
        case ColorScheme.HighContrast:
            return '/images/backgrounds/dark.svg';
        case ColorScheme.Light:
            return '/images/backgrounds/light.svg';
        default:
            return '/images/backgrounds/dark.svg';
    }
}

const defaultProps: Required<BackgroundProps> = {
    image: BackgroundImage.none,
    animation: BackgroundAnimation.none,
    colorScheme: ColorScheme.Dark,
};

// Background component used to serve background images and animations
export default function Background(props: BackgroundProps = defaultProps) {
    const classes = useClasses();
    const {image, animation, colorScheme} = props;
    // use the current theme to pull the right background image
    const backgroundImage = getBackgroundImage(colorScheme || defaultProps.colorScheme);

    return (
        <div className={classes.root} data-testid="background">
            {/* AnimatePresence is a component that allows us to animate components in and out of the DOM
                mode="wait" is a special mode that waits for one component to finish animating before animating the next
            */}
            <AnimatePresence mode="wait">
                {animation === BackgroundAnimation.fullGradient && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {duration: 1}}}
                        exit={{opacity: 0, transition: {duration: 1}}}
                        key="full"
                    >
                        <FullScreenGradients />
                    </motion.div>
                )}
                {animation === BackgroundAnimation.bottomGradient && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {duration: 1}}}
                        exit={{opacity: 0, transition: {duration: 1}}}
                        key="bottom"
                    >
                        <BottomScreenGradients
                            colorScheme={colorScheme || defaultProps.colorScheme}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            {image == BackgroundImage.triangles && (
                <div
                    className={classes.vectorBackground}
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                    }}
                />
            )}
        </div>
    );
}
