export enum FileIndexingStatus {
    NotSet = 'NotSet',
    NotIndexed = 'NotIndexed',
    Indexing = 'Indexing',
    Indexed = 'Indexed',
    Failed = 'Failed',
}

export type FileDescriptor = {
    fileName: string;
    size: string;
    enabled: boolean;
    uploadedTime: string | null;
    indexingStatus: FileIndexingStatus;
};
export type FileUploadRequest = {
    FileName: string;
    ContentType: string;
    FileContent: File;
};

export type FileDeletionRequest = {
    FileName: string;
};

export type PatchFileDescriptorRequest = {
    fileName: string;
    enabled: boolean;
};

export type FileUsage = {
    usage: number;
    limit: number;
};

export enum FileScope {
    User = 'user',
    Tenant = 'tenant',
    Workspace = 'workspace',
}
