import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        color: tokens.colorNeutralForeground1,
        fontWeight: tokens.fontWeightRegular,
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXS),
        minWidth: '0px',
    },
    menuItem: {
        '& span': {
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
        },
    },
});
