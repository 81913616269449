import React from 'react';
import {Caption1, Link, Text} from '@fluentui/react-components';
import {EvaluationSource, EvaluationSourceType} from '@/api/evaluations';
// import {TooltipHost, TooltipOverflowMode, ITooltipHostStyles} from '@fluentui/react/lib/Tooltip';o
import useClasses from './EvaluationSources.styles';
import {Button} from '@fluentui/react-button';

function EvaluationSources({evaluation}: any) {
    const classes = useClasses();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const handleClick = () => setIsExpanded(!isExpanded);
    const evaluationSources = evaluation?.result?.sources;

    if (!evaluationSources || evaluationSources.length === 0) {
        return null;
    }
    const visibleItems = isExpanded ? evaluationSources : evaluationSources.slice(0, 4);

    return (
        <>
            <div>
                <Caption1 className={classes.caption}>References</Caption1>
            </div>
            <div>
                {visibleItems.map((item: EvaluationSource, index: number) => {
                    return (
                        <Link
                            href={
                                item.sourceType != EvaluationSourceType.Uri
                                    ? undefined
                                    : item.sourceContent
                            }
                            key={item.displayName}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                className={classes.button}
                                disabled={item.sourceType != EvaluationSourceType.Uri}
                            >
                                {/* <TooltipHost
                                    overflowMode={TooltipOverflowMode.Parent}
                                    content={item.displayName}
                                > */}
                                {item.displayName}
                                {/* </TooltipHost> */}
                            </Button>
                        </Link>
                    );
                })}
                {evaluationSources.length > 4 && !isExpanded && (
                    <Button className={classes.button} onClick={handleClick}>
                        +{evaluationSources.length - 4} more
                    </Button>
                )}
            </div>
        </>
    );
}

export default EvaluationSources;
