import {useEffect, useState} from 'react';
import {TourStop} from './Tour.types';

export default function useTourStopIndex(tourStops: TourStop[]) {
    const [stopIndex, setStopIndex] = useState<number>(0);

    useEffect(() => {
        let initialIndex: number = 0;
        const matchingIndex = tourStops?.findIndex((stop) => stop.isFirstStop);
        initialIndex = !matchingIndex || matchingIndex === -1 ? 0 : matchingIndex;

        setStopIndex(initialIndex);
    }, [tourStops]);

    return [stopIndex as number, setStopIndex as (stepIndex: number) => void] as TourStopIndexHook;
}

export type TourStopIndexHook = [number, (stepIndex: number) => void];
