import React, {useEffect, useMemo} from 'react';
import {mergeClasses, Button, Spinner} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useViewportSize} from '../Grid';
import {useTranslation} from 'react-i18next';

interface TourControlProps {
    // callback for the previous button click
    handlePrevious?: () => void;
    // callback for the next button click
    handleNext: () => void;
    // text for the previous button or ButtonProps for customization
    previousButton?: string | React.ComponentProps<typeof Button>;
    // text for the next button or ButtonProps for customization
    nextButton: string | React.ComponentProps<typeof Button>;
    // is the previous button disabled
    isNextButtonDisabled?: boolean;
    // should the controls span the full screen width
    spanFullScreen?: boolean;
    // show the previous button
    showPrevious?: boolean;
    // show a progress spinner on the next button
    showProgressSpinner?: boolean;
    // show Progress Spinner text
    progressSpinnerText?: string;
}

// Deals with the animating of the app icon and the title, if needed this should be the place where we start making and caching data calls
function TourControls(props: TourControlProps) {
    const {handlePrevious, handleNext, previousButton, nextButton} = props;
    const tourClasses = useTourClasses();
    const {sm: smallScreen} = useViewportSize();
    const spinnerSize = smallScreen ? 'extra-tiny' : 'tiny';

    const {t: commonLabel} = useTranslation('common');

    const nextDataTestId = smallScreen
        ? 'tour-controls-next-button-small'
        : 'tour-controls-next-button-large';
    const prevDataTestId = smallScreen
        ? 'tour-controls-previous-button-small'
        : 'tour-controls-previous-button-large';

    const showPrevious = props.showPrevious ?? true;

    const controlContainerStyles = useMemo(() => {
        let styles = tourClasses.tourContainerControls;
        if (Boolean(props.spanFullScreen)) {
            if (showPrevious) {
                styles = tourClasses.tourTwoButtonWrapper;
            } else {
                styles = tourClasses.tourMonoButtonWrapper;
            }
        }
        return styles;
    }, [
        props.spanFullScreen,
        showPrevious,
        tourClasses.tourContainerControls,
        tourClasses.tourMonoButtonWrapper,
        tourClasses.tourTwoButtonWrapper,
    ]);

    const showLoadingText = props.progressSpinnerText ?? commonLabel('Loading');

    return (
        <>
            <div className={controlContainerStyles}>
                {showPrevious && (
                    <>
                        {typeof previousButton === 'string' ? (
                            <Button
                                onClick={handlePrevious}
                                className={mergeClasses(tourClasses.tourControlButton)}
                                appearance="secondary"
                                size={smallScreen ? 'small' : 'large'}
                                data-testid={prevDataTestId}
                            >
                                {previousButton}
                            </Button>
                        ) : (
                            <Button {...previousButton} data-testid={prevDataTestId} />
                        )}
                    </>
                )}
                {typeof nextButton === 'string' ? (
                    <Button
                        onClick={handleNext}
                        className={mergeClasses(tourClasses.tourControlButton)}
                        appearance="primary"
                        size="small"
                        data-testid={nextDataTestId}
                        disabled={props?.isNextButtonDisabled ?? false}
                    >
                        {props.showProgressSpinner ?? false ? (
                            <Spinner size={spinnerSize} label={showLoadingText} />
                        ) : (
                            <>{nextButton}</>
                        )}
                    </Button>
                ) : (
                    <Button {...nextButton} data-testid={nextDataTestId} />
                )}
            </div>
        </>
    );
}

export default TourControls;
