import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {TenantInfo} from './tenant.types';
import {ErrorResponse} from '../api.types';

export default function useGetTenantInfo(
    useQueryOptions?: Pick<UseQueryOptions<TenantInfo>, 'enabled'>,
) {
    const {customFetch} = useFetch();

    return useQuery<TenantInfo>(['settings', 'datashare'], {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        queryFn: async () => {
            const result = await customFetch<Response>(
                `/settings/datashare`,
                {
                    method: 'GET',
                },
                true,
            );

            if (!result.ok) {
                const error: ErrorResponse = {
                    code: result.status,
                    message: result.statusText,
                };

                throw error;
            }

            const data = await result.json();
            return data as TenantInfo;
        },
        retry: 0,
        ...useQueryOptions,
    });
}
