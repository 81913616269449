import {Caption1, Subtitle2, TextPresetProps} from '@fluentui/react-components';
import {useViewportSize} from '../Grid';

export default function SmallTitle(props: TextPresetProps) {
    const {isSmallScreen} = useViewportSize();

    return (
        <>{isSmallScreen ? <Caption1 as="h2" {...props} /> : <Subtitle2 as="h2" {...props} />}</>
    );
}
