import {PluginRbacSetting} from '@/components/Navigation/Preferences/Preferences.types';

export const PluginAvailabilityOptionIds = {
    ANY: 'PluginAvailabilityOptionAllUsers',
    ADMIN: 'PluginAvailabilityOptionOwners',
};

export const PluginAvailabilityOptions = Object.keys(PluginRbacSetting).map((setting) => {
    return {
        value: setting,
        text: PluginAvailabilityOptionIds[setting as PluginRbacSetting],
    };
});

export const PluginDataTableColumnSizingOptions = {
    name: {
        defaultWidth: 425,
        minWidth: 325,
        idealWidth: 450,
    },
    category: {
        defaultWidth: 150,
        minWidth: 100,
        idealWidth: 150,
    },
    available: {
        defaultWidth: 175,
        minWidth: 125,
        idealWidth: 225,
    },
};
