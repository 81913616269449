import {Label} from '@fluentui/react-components';
import {SummaryTagProps} from './SummaryTag.types';
import useClasses from './InvestigationPanel.styles';

const PinboardSummaryTags = ({tags}: SummaryTagProps) => {
    const classes = useClasses();
    return (
        <ul className={classes.tagsUnorderedList}>
            {tags.map((tag) => {
                return (
                    <li key={tag} className={classes.tagsList} aria-label="tag">
                        <Label>{tag}</Label>
                    </li>
                );
            })}
        </ul>
    );
};

export default PinboardSummaryTags;
