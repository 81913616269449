import React from 'react';
import {Text, Tooltip} from '@fluentui/react-components';
import useClasses from './SkillRowMetadata.styles';
import {SkillsetDescriptor} from '@/api/skills';
import {PreviewBadge} from '@/components/ui/Badges';
import {useOverflowTooltip} from '@/components/ui/Reflow';

interface SkillTitleProps {
    skillsetDescriptor?: SkillsetDescriptor;
    displayName: string;
}

function SkillTitle(props: SkillTitleProps) {
    const {displayName, skillsetDescriptor} = props;
    const {ref, isOverflowing, showTooltip} = useOverflowTooltip<HTMLDivElement>();
    const classes = useClasses();
    return (
        <>
            <Tooltip
                content={displayName}
                relationship="label"
                visible={isOverflowing && showTooltip}
            >
                <Text ref={ref} className={classes.skillsetNameText}>
                    {displayName}
                </Text>
            </Tooltip>
            <PreviewBadge previewState={skillsetDescriptor?.previewState} />
        </>
    );
}

export default SkillTitle;
