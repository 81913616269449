import {DialogContent, DialogTitle, mergeClasses, Link} from '@fluentui/react-components';
import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import {
    useGetSkillsets,
    useSubmitSkillsetConfigs,
    useGetSkillsetOAuthAuthorizationEndpoint,
    useGetSkillsetConfigurations,
    useDeleteSkillsetConfiguration,
    SkillsetSetting,
    SkillsetSettingType,
    SkillsetConfiguration,
    SkillsetAuthType,
    SkillsetAuthConfiguration,
    SkillsetConfigInput,
    UpdateSkillsetConfigsRequest,
    SkillsetRequest,
    SettingsScope,
    useGetSkillsetSpecificAuthSettings,
    SkillsetCategory,
} from '@/api/skills';
import useClasses from './AdminConfigurationModalContent.styles';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {AdminConfigurationModalContentProps} from './AdminConfigurationModalContent.types';
import {useGetSkillsetAuthToken, SkillsetDescriptor} from '@/api/skills';
import MedeinaFeatures from '@/util/features';
import {ApiError} from '@/api/api';
import {useGetSkillsetCustomIcon} from '@/api/skills';
import {
    AdminModalDialogTitle,
    LegacyAuthSelectionField,
    ConfirmationModal,
    SaveConfirmationModal,
    AdminModalOverlay,
    OauthHelperModal,
    AuthSelectionModal,
    CustomPluginSetupControls,
    SkillsetSetupControls,
    SelectedSkillsetSettingsForm,
} from '@/components/ui/ConnectorModal/AdminConfigurationModal';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import useSkillsetConfigHasValue from './AdminConfigurationModal/SettingsSetup/useSkillsetConfigHasValue';

export default function AdminConfigurationModalContent({
    selectedSkillset,
    scope,
    skillsetDescriptor,
    onSkillsetEnabled,
    onReturn,
    onEnableReturn,
    requirementsCheck,
    isSettingUpPlugin,
    onOpenExternalConnectorView,
    onClose,
}: AdminConfigurationModalContentProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {t} = useTranslation('plugins');

    const textRef = useRef<HTMLDivElement>(null);
    // api calls
    const {data: skillsets} = useGetSkillsets();
    const {data: skillsetConfigurations} = useGetSkillsetConfigurations({
        skillsetName: selectedSkillset ?? '',
        scope,
    });
    const {data: skillsetAuthSettings} = useGetSkillsetSpecificAuthSettings(selectedSkillset!);
    const {mutateAsync: sendData, isLoading, isError, isSuccess} = useSubmitSkillsetConfigs();
    const skillsetRequest: SkillsetRequest = {
        skillsetName: selectedSkillset ?? '',
        scope,
    };
    const {
        mutateAsync: deleteData,
        isLoading: isDeleteLoading,
        isError: isDeleteError,
        isSuccess: isDeleteSuccess,
    } = useDeleteSkillsetConfiguration();
    const {
        data: oauthCode,
        refetch: getOAuthCode,
        isLoading: isOAuthCodeLoading,
        isError: isOAuthCodeError,
        remove: removeOAuthCodeState,
    } = useGetSkillsetOAuthAuthorizationEndpoint({skillsetName: selectedSkillset ?? ''});
    const {
        mutate: getAuthToken,
        isLoading: isAuthLoading,
        isError: isAuthError,
        isSuccess: isAuthSuccess,
    } = useGetSkillsetAuthToken();
    // Toggle to show the overlay when the data is being saved
    const [showOverlay, setShowOverlay] = React.useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
    const [showSaveConfirmationModal, setShowSaveConfirmationModal] = React.useState(false);
    const [showConnectionModal, setShowConnectionModal] = React.useState(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [deleteErrorResponse, setDeleteErrorResponse] = useState('');
    const [authWindow, setAuthWindow] = useState<Window | null>(null);
    // Whether to trigger OAuth "connection" flow. If false, config will be only saved, with no further authorization flow being triggered.
    const [shouldConnect, setShouldConnect] = React.useState(false);

    const selectedSkillsetDescriptor = React.useMemo<SkillsetDescriptor | null>(() => {
        return (
            skillsets?.value.find(
                (skill: {name: string | null}) => skill.name == selectedSkillset,
            ) ?? null
        );
    }, [skillsets?.value]);

    // Open Connector Modal when back button is clicked. Do not enable the selected skillset.
    const handleGoBack = () => {
        onReturn?.();
    };

    // deal with skillset icons.
    const {data: icon} = useGetSkillsetCustomIcon(
        skillsetDescriptor?.name,
        skillsetDescriptor?.icon,
    );

    // Return to Connector Modal when auth is complete
    const handleConfigurationReturn = (shouldEnable: boolean) => {
        shouldEnable ? onEnableReturn?.() : onReturn?.();
    };

    useEffect(() => {
        if (oauthCode && showOverlay) {
            const width = 500;
            const height = 600;
            const left = (screen.width - width) / 2;
            const top = (screen.height - height) / 2;
            const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;
            const authWindow = window.open(`${oauthCode}`, '_blank', windowFeatures);

            setAuthWindow(authWindow);
        }
    }, [oauthCode]);

    const timer = (shouldEnable: boolean = false) => {
        setTimeout(() => {
            handleConfigurationReturn(shouldEnable);
        }, 1500);
    };

    // add listener to new auth window and close after user has authenticated
    useEffect(() => {
        const handleAuthorization = async (event: any) => {
            const {code, state} = event.data;

            window.removeEventListener('message', handleAuthorization);
            if (authWindow !== null) {
                authWindow.close();
            }

            getAuthToken({skillsetName: selectedSkillset, state, authorizationCode: code});
        };

        if (authWindow) {
            window.addEventListener('message', handleAuthorization);
        }
    }, [authWindow]);

    useEffect(() => {
        if (
            isLoading ||
            isError ||
            isSuccess ||
            isDeleteLoading ||
            isDeleteError ||
            isDeleteSuccess
        ) {
            setShowOverlay(true);
        }
    }, [isLoading, isError, isSuccess, isDeleteLoading, isDeleteError, isDeleteSuccess]);

    // Close the modal when the data is saved or there is an error
    useEffect(() => {
        if (isAuthSuccess || isAuthError) {
            timer(isAuthSuccess);
        }
    }, [isAuthSuccess, isAuthError]);

    // oauth code error persists so we need to reset the state
    useEffect(() => {
        if (isOAuthCodeError) {
            timer();
            removeOAuthCodeState();
        }
    }, [isOAuthCodeError]);

    useEffect(() => {
        if (
            isSuccess &&
            shouldConnect &&
            authType === SkillsetAuthType.OAuthAuthorizationCodeFlow
        ) {
            getOAuthCode();
        } else if (isSuccess || isDeleteSuccess || isError) {
            timer(isSuccess);
        }
    }, [isSuccess, isError, isDeleteSuccess, shouldConnect]);

    // Set of non-auth settings for the selected skillset
    const [defaultConfigs, setDefaultConfigs] = useState<Set<SkillsetSetting>>(new Set());
    // Map of setting name to value based on the user's form input
    const [values, setValues] = useState<Map<string, string>>(new Map());
    // Set of all settings, including auth settings, for the selected skillset
    const [selectedConfigs, setSelectedConfigs] = useState(defaultConfigs);
    // Selected auth type for the selected skillset
    const [authType, setAuthType] = useState('None');
    // List of supported auth types for the selected skillset
    const [supportedAuthTypes, setSupportedAuthTypes] = useState<string[]>([]);
    const isPlugin = skillsetDescriptor?.category === 'Plugin';
    // List of settings that failed validation when attempting to submit
    const [failedValidationSettings, setFailedValidationSettings] = useState<string[]>([]);
    const showAuthSelectionModal =
        showConnectionModal && !showOverlay && MedeinaFeatures.OAuthConnectorExperience;
    const showLegacyAuthSelectionModal =
        supportedAuthTypes.length > 1 && !MedeinaFeatures.OAuthConnectorExperience;
    const showInputFields =
        !showOverlay &&
        !showConfirmationModal &&
        !showSaveConfirmationModal &&
        !showConnectionModal;
    const showOauthHelperModal =
        showOverlay &&
        authType == SkillsetAuthType.OAuthAuthorizationCodeFlow &&
        MedeinaFeatures.OAuthConnectorExperience &&
        isSuccess &&
        !isAuthLoading &&
        !isOAuthCodeLoading &&
        !isAuthSuccess &&
        !isOAuthCodeError &&
        !isAuthError;
    const showSuccessIcon =
        ((isSuccess && authType != SkillsetAuthType.OAuthAuthorizationCodeFlow) ||
            isDeleteSuccess ||
            isAuthSuccess) &&
        !isLoading &&
        !isAuthLoading;
    const showFailureIcon =
        (isError || isDeleteError || isAuthError || isOAuthCodeError) &&
        !isLoading &&
        !isAuthLoading;
    const showLoadingIcon =
        (isLoading || isAuthLoading || isOAuthCodeLoading) &&
        !showFailureIcon &&
        !showSuccessIcon &&
        !isDeleteLoading;
    const show3pPluginPreviewTermsOfUse =
        selectedSkillsetDescriptor?.category === SkillsetCategory.Other &&
        selectedSkillsetDescriptor?.description?.includes('Published by Microsoft') &&
        selectedSkillsetDescriptor?.displayName?.includes('(Preview)');

    useEffect(() => {
        if ((showSuccessIcon || showFailureIcon) && textRef.current) {
            textRef.current.focus();
        }
    }, [showSuccessIcon, showFailureIcon]);

    // Retrieve and set the supported auth types and their settings for the selected skillset
    useEffect(() => {
        const newDefaultConfigs = new Set(selectedSkillsetDescriptor?.settings ?? []);
        setDefaultConfigs(newDefaultConfigs);
        const authTypes: string[] =
            selectedSkillsetDescriptor?.supportedAuthTypes?.map((authType) =>
                authType.toString(),
            ) ?? [];
        setSupportedAuthTypes(authTypes);
        const selectedAuthType = existingConfigs?.authType ?? SkillsetAuthType.None;

        setShowConnectionModal(
            authTypes.length > 1 &&
                (requirementsCheck?.errors.length != 0 ||
                    selectedAuthType === SkillsetAuthType.None) &&
                MedeinaFeatures.OAuthConnectorExperience,
        );

        if (
            existingConfigs &&
            selectedAuthType !== SkillsetAuthType.None &&
            requirementsCheck?.errors.length == 0
        ) {
            onAuthTypeSelected(existingConfigs?.authType!);
        }

        // If there is only one supported auth type, then set the auth type to that type
        if (selectedSkillsetDescriptor?.supportedAuthTypes?.length == 1) {
            const authType = selectedSkillsetDescriptor?.supportedAuthTypes?.[0];
            setAuthType(authType?.toString() ?? '');

            setValues(new Map<string, string>());

            const authSettings: Set<SkillsetSetting> = new Set(
                skillsetAuthSettings?.find(
                    (setting: {authType: SkillsetAuthType}) => setting.authType == authType,
                )?.settings,
            );

            const newSelectedConfigs = new Set([...newDefaultConfigs, ...authSettings]);

            setSelectedConfigs(newSelectedConfigs);
        }
    }, [skillsets?.value, skillsetAuthSettings?.values, skillsetConfigurations?.value]);

    const existingConfigs = React.useMemo<SkillsetConfiguration | null>(() => {
        return (
            skillsetConfigurations?.value.find(
                (skillset: {skillsetName: string | null}) =>
                    skillset.skillsetName == selectedSkillset,
            ) ?? null
        );
    }, [skillsetConfigurations?.value]);

    // Based on checkRequirements, determine which settings are missing
    const missingSettings = React.useMemo<string[]>(() => {
        const settings: string[] = [];
        for (const error of requirementsCheck?.errors ?? []) {
            settings.push(error.name);
        }
        return settings;
    }, [requirementsCheck, supportedAuthTypes]);

    // Handle user inputs for skillset settings
    function handleChange(ev: any, config: string) {
        const innerMap = values ?? new Map<string, string>();
        innerMap?.set(config, ev.target.value);
        setValues(new Map(innerMap));
    }

    // Submit all user inputs
    function handleSubmit(shouldConnect: boolean) {
        setShouldConnect(shouldConnect);

        // Perform validations on all required settings
        const failedValidations = [];
        for (const setting of missingSettings) {
            if (
                !values.get(setting) &&
                Array.from(selectedConfigs).find((config) => config.name == setting)
            ) {
                failedValidations.push(setting);
            } else if (values.get(setting)) {
                failedValidations.splice(failedValidations.indexOf(setting), 1);
            }
        }
        setFailedValidationSettings(failedValidations);

        // If any required settings have not been set, do not save the user inputs
        if (failedValidations.length > 0) {
            return;
        }

        // Create map of the setting values to be saved
        const innerMap = values ?? new Map<string, string>();

        // Get the auth type as a SkillsetAuthType enum
        const skillsetAuthType: SkillsetAuthType =
            SkillsetAuthType[authType as keyof typeof SkillsetAuthType];

        // Create maps of the different types of settings to be saved
        const authSettings: SkillsetConfigInput = {};
        const hiddenAuthSettings: SkillsetConfigInput = {};
        const settingsMap: SkillsetConfigInput = {};

        // Pull a list of settings specific to the selected auth type
        const authTypeSettings =
            skillsetAuthSettings
                ?.find(
                    (setting: {authType: {toString: () => string}}) =>
                        setting.authType.toString() == authType,
                )
                ?.settings?.map((setting: {name: any}) => setting.name) ?? [];

        // Iterate through map of user inputs and add to the appropriate map
        for (const [key, value] of innerMap.entries()) {
            if (authTypeSettings.includes(key)) {
                if (
                    Array.from(selectedConfigs)
                        .find((config) => config.name == key)
                        ?.settingType.toString() ==
                    SkillsetSettingType[SkillsetSettingType.SecretString]
                ) {
                    hiddenAuthSettings[key] = value;
                } else {
                    authSettings[key] = value;
                }
            } else {
                settingsMap[key] = value;
            }
        }

        // Construct the SkillsetAuthConfiguration object
        const skillsetAuthConfig: SkillsetAuthConfiguration = {
            authType: skillsetAuthType,
            settings: authSettings,
            hiddenSettings: hiddenAuthSettings,
        };

        // Use something other than a Record here as we should only be setting values for the auth type selected by the user
        const authConfigurationsMap: Record<SkillsetAuthType, SkillsetAuthConfiguration> = {
            [SkillsetAuthType.None]: {},
            [SkillsetAuthType.Basic]: {},
            [SkillsetAuthType.APIKey]: {},
            [SkillsetAuthType.OAuthAuthorizationCodeFlow]: {},
            [SkillsetAuthType.OAuthClientCredentialsFlow]: {},
            [SkillsetAuthType.AAD]: {},
            [SkillsetAuthType.ServiceHttp]: {},
            [SkillsetAuthType.AADDelegated]: {},
            [SkillsetAuthType.OAuthPasswordGrantFlow]: {},
        };
        authConfigurationsMap[skillsetAuthType] = skillsetAuthConfig;

        const skillsetConfig: SkillsetConfiguration = {
            skillsetName: selectedSkillset ?? '',
            settings: settingsMap,
            authType: skillsetAuthType,
            authConfigurations: authConfigurationsMap,
        };

        const skillsetConfigRequest: UpdateSkillsetConfigsRequest = {
            configuration: skillsetConfig,
            scope: scope,
        };

        // Send the user inputs to be stored
        sendData(skillsetConfigRequest).catch((error: any) => {
            if (error instanceof ApiError && error.response) {
                error.response.text().then((e) => setErrorResponse(`${JSON.parse(e).message}`));
            } else {
                setErrorResponse(error.toString());
            }
        });

        !isError && onSkillsetEnabled?.();
    }

    const handleDelete = () => {
        var response = deleteData(skillsetRequest);
        response.catch((error) => {
            if (error instanceof ApiError && error.response) {
                error.response
                    .text()
                    .then((e) => setDeleteErrorResponse(`${JSON.parse(e).message}`));
            } else {
                setDeleteErrorResponse(error.toString());
            }
        });
    };

    const handleCancelledConfirmation = () => {
        setShowSaveConfirmationModal(false);
        handleGoBack();
    };

    // Handle auth type menu selection changes
    const onAuthTypeSelected = (authOption: string) => {
        setAuthType(authOption);

        setShowConnectionModal(false);

        const authSettings: Set<SkillsetSetting> = new Set();

        const authTypeSettings = skillsetAuthSettings?.find(
            (setting) => setting.authType.toString() == authOption,
        )?.settings;

        authTypeSettings?.map((setting: SkillsetSetting) => {
            authSettings.add(setting);
        });

        const newDefaultConfigs = new Set(selectedSkillsetDescriptor?.settings ?? []);
        setSelectedConfigs(new Set([...newDefaultConfigs, ...authSettings]));
    };

    const {existingConfigHasValue} = useSkillsetConfigHasValue({
        existingConfigs,
        authType,
        requirementsCheckErrors: requirementsCheck?.errors ?? [],
    });

    function InputFieldDialogContent() {
        return (
            <>
                <AdminModalDialogTitle
                    selectedSkillset={selectedSkillset}
                    selectedSkillsetDescriptor={selectedSkillsetDescriptor}
                    scope={scope}
                    icon={icon}
                    onReturn={
                        values.size > 0
                            ? () => setShowSaveConfirmationModal(true)
                            : () => onReturn()
                    }
                    isSettingUpPlugin={isSettingUpPlugin}
                    isPlugin={isPlugin}
                    onClose={onClose}
                />
                <DialogContent
                    className={mergeClasses(
                        classes.dialogContent,
                        classes.nonStickySection,
                        scrollClasses.colorNeutralBackground1,
                    )}
                >
                    {showLegacyAuthSelectionModal && (
                        <LegacyAuthSelectionField
                            authType={authType}
                            supportedAuthTypes={supportedAuthTypes}
                            onAuthTypeSelected={onAuthTypeSelected}
                        />
                    )}
                    <div>
                        {selectedConfigs.size > 0 && (
                            <SelectedSkillsetSettingsForm
                                selectedConfigs={selectedConfigs}
                                isSetUp={isSettingUpPlugin}
                                scope={scope}
                                existingConfigs={existingConfigs}
                                authType={authType}
                                failedValidationSettings={failedValidationSettings}
                                requirementsCheckErrors={requirementsCheck?.errors ?? []}
                                onReset={() => setShowConfirmationModal(true)}
                                onInputChange={handleChange}
                            />
                        )}

                        {show3pPluginPreviewTermsOfUse && (
                            <div className={classes.pluginTerms}>
                                <p className={classes.pluginTermsSubText}>
                                    <Link
                                        href={MedeinaVariables.PreviewPluginTosUri}
                                        target="_blank"
                                    >
                                        {t('Admin.PluginTermsOfUse')}
                                    </Link>
                                </p>
                            </div>
                        )}

                        {isPlugin && !isSettingUpPlugin && (
                            <CustomPluginSetupControls
                                skillsetDescriptor={skillsetDescriptor}
                                onEdit={() =>
                                    onOpenExternalConnectorView?.(
                                        skillsetDescriptor?.category ?? '',
                                        skillsetDescriptor,
                                        false,
                                    )
                                }
                            />
                        )}
                        <SkillsetSetupControls
                            authType={authType}
                            scope={scope}
                            onConnectAuth={() => handleSubmit(true)}
                            onSaveSetup={
                                scope === SettingsScope.Tenant
                                    ? () => setShowSaveConfirmationModal(true)
                                    : () => handleSubmit(false)
                            }
                            onCancel={
                                values.size > 0
                                    ? () => setShowSaveConfirmationModal(true)
                                    : () => handleGoBack()
                            }
                            onReturn={handleGoBack}
                            isSetUp={isSettingUpPlugin}
                            isPlugin={isPlugin}
                            hasExistingConfigs={existingConfigHasValue}
                            hasUserSettingValues={values.size > 0}
                            onDelete={() =>
                                onOpenExternalConnectorView?.(
                                    skillsetDescriptor?.category ?? '',
                                    skillsetDescriptor,
                                    true,
                                )
                            }
                            skillsetDescriptor={skillsetDescriptor}
                        />
                    </div>
                </DialogContent>
            </>
        );
    }

    function NoInputDialogContent() {
        return (
            <>
                {/* <DialogTitle></DialogTitle> */}
                <DialogContent className={classes.configurationModalRoot}>
                    {showAuthSelectionModal && (
                        <AuthSelectionModal
                            selectedSkillset={selectedSkillset}
                            supportedAuthTypes={supportedAuthTypes}
                            icon={icon}
                            onAuthTypeSelected={onAuthTypeSelected}
                        />
                    )}
                    {showOauthHelperModal && (
                        <OauthHelperModal onCancel={() => setShowOverlay(false)} />
                    )}
                    {showOverlay && (
                        <AdminModalOverlay
                            authType={authType}
                            showLoadingIcon={showLoadingIcon}
                            isDeleteLoading={isDeleteLoading}
                            showSuccessIcon={showSuccessIcon}
                            isAuthSuccess={isAuthSuccess}
                            isDeleteSuccess={isDeleteSuccess}
                            showFailureIcon={showFailureIcon}
                            isAuthError={isAuthError}
                            isOAuthCodeError={isOAuthCodeError}
                            isDeleteError={isDeleteError}
                            deleteErrorResponse={deleteErrorResponse}
                            errorResponse={errorResponse}
                            textRef={textRef}
                        />
                    )}
                    {showSaveConfirmationModal && !showOverlay && (
                        <SaveConfirmationModal
                            selectedSkillset={selectedSkillset}
                            skillsetDescriptor={skillsetDescriptor}
                            scope={scope}
                            icon={icon}
                            onSave={() => handleSubmit(false)}
                            onCancel={handleCancelledConfirmation}
                        />
                    )}
                    {showConfirmationModal && !showOverlay && (
                        <ConfirmationModal
                            selectedSkillset={selectedSkillset}
                            selectedSkillsetDescriptor={selectedSkillsetDescriptor}
                            scope={scope}
                            icon={icon}
                            onReset={handleDelete}
                            onCancel={() => setShowConfirmationModal(false)}
                        />
                    )}
                </DialogContent>
            </>
        );
    }

    return showInputFields ? InputFieldDialogContent() : NoInputDialogContent();
}
