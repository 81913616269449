import React from 'react';
import {segmentKeys} from '../Breadcrumbs.constants';
import BreadcrumbItem from './BreadcrumbItem';

interface DefaultBreadcrumbItemsProps {
    pathSegments: Array<string>;
    renderOnlyLast?: boolean;
}

// A render component that will render the Default breadcrumb item
const DefaultBreadcrumbItems = (props: DefaultBreadcrumbItemsProps) => {
    const {pathSegments, renderOnlyLast} = props;

    // if renderOnlyLast is true, only render the last breadcrumb item
    if (renderOnlyLast) {
        return <BreadcrumbItem segment={pathSegments[pathSegments.length - 1]} />;
    }

    return (
        <>
            {pathSegments.map((segment, index) => {
                const segmentKey = segmentKeys[segment] || segment;
                return <BreadcrumbItem key={index} segment={segmentKey} />;
            })}
        </>
    );
};

export default DefaultBreadcrumbItems;
