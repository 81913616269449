import {LargeTitle} from '@fluentui/react-components';
import useClasses from './components.styles';

export default function MarkdownH2({children, className, ...props}: any) {
    const classes = useClasses();

    return (
        <h2>
            <LargeTitle className={classes.h2}>{children}</LargeTitle>
        </h2>
    );
}
