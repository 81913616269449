import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 42px)',
        backgroundColor: tokens.colorNeutralBackground2,

        '@media (min-width: 480px) and (max-width: 639px)': {
            justifyContent: 'flex-start',
        },
        '@media (max-width: 479px)': {
            justifyContent: 'flex-start',
        },
    },
    transparentDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    flexBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    // when not using a dialog this is so that the content is centered withing it's parent container
    centeredBlock: {
        position: 'relative',
        maxWidth: '600px',
        width: '100%',
        ...shorthands.padding(tokens.spacingVerticalM),

        // on smaller screens use different widths
        '@media (max-width: 479px)': {
            maxWidth: '95%',
        },
    },
    centered: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        ...shorthands.gap(tokens.spacingVerticalM),
    },
    icon: {
        fontSize: '48px',
        paddingBottom: tokens.spacingVerticalM,
    },
    title: {
        fontSize: tokens.fontSizeHero800,
        fontWeight: tokens.fontWeightSemibold,
        paddingBottom: tokens.spacingVerticalL,
    },
    message: {
        fontSize: '24px',
        paddingBottom: tokens.spacingVerticalL,
        textAlign: 'center',
        lineHeight: tokens.lineHeightBase600,
    },
    errorMessage: {
        fontFamily: tokens.fontFamilyMonospace,
        fontSize: tokens.fontSizeBase300,
        paddingBottom: tokens.spacingVerticalM,
        whiteSpace: 'pre-wrap',
        height: '30%',
        overflowY: 'auto',
        textAlign: 'left',
    },
    actionRow: {
        marginTop: tokens.spacingVerticalL,
    },
});
