import {useMutation} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UpdatePromptRequest, Prompt} from './prompts.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
export default function useUpdatePrompt() {
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: UpdatePromptRequest) => {
            return await customFetch<Prompt>(
                `/sessions/${body?.sessionId}/prompts/${body?.promptId}`,
                {
                    method: 'PUT',
                    body,
                },
            );
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
    });
}
