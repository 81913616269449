import {tokens, shorthands, makeStyles} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalSNudge,
        ...shorthands.padding(
            tokens.spacingHorizontalL,
            '0px',
            tokens.spacingHorizontalL,
            tokens.spacingVerticalS,
        ),
        alignItems: 'center',
    },
    promptBtnsDivider: {
        width: tokens.lineHeightBase200,
        ...shorthands.borderWidth(tokens.strokeWidthThin),
        backgroundColor: tokens.colorNeutralStroke1,
        maxHeight: '0px',
    },
    promptBtns: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalSNudge,
        alignItems: 'center',
        //Will be overridden by Prompt.styles on hover/focus
        visibility: 'hidden',
        opacity: 0,
    },
    pinBtn: {
        visibility: 'visible',
        opacity: 1,
    },
    unpinBtn: {
        visibility: 'hidden',
        opacity: 0,
    },
});
