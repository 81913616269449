import {useMemo} from 'react';
import {Body1Strong, Caption1, Link, mergeClasses} from '@fluentui/react-components';
import {Session, useGetSessions} from '@/api/sessions';
import useClasses from './RecentSessions.styles';
import {SubmitIcon} from '@/components/ui/icons';
import GridContainer from '@/components/ui/Grid/GridContainer';
import GridItem from '@/components/ui/Grid/GridItem';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import {ScreenSize} from '@/components/ui/Grid/GridConstants';
import {useTranslation} from 'react-i18next';
import TileLink from './TileLink';

//Cannot be more than 10 or check to fetch the next page if there is continuation token has to be added.
const MaxSessionTiles = 5;
export default function RecentSessions() {
    const classes = useClasses();
    const {t} = useTranslation('common');
    const {data: sessions} = useGetSessions({
        sortBy: 'updatedAt',
        sortDirection: 'desc',
        limit: 10,
    });
    const recentSessions = useMemo<Array<Session | null>>(() => {
        let list: Array<Session | null> = sessions?.pages.map((page) => page.value).flat() ?? [];
        return list.concat(Array(MaxSessionTiles).fill(null)).slice(0, MaxSessionTiles);
    }, [sessions]);

    const screenSize = useViewportSize();
    const isSmallScreen = screenSize.activeViewport === ScreenSize.Small;
    const isMediumScreen = screenSize.activeViewport === ScreenSize.Medium;
    const isXXXLargeScreen = screenSize.activeViewport === ScreenSize.XXXLarge;

    const heroSession = recentSessions[0];
    const sessionsForColumnOne = recentSessions.slice(1, 3);
    const sessionsForColumnTwo = recentSessions.slice(3, 5);

    return (
        <div className={classes.root}>
            <Body1Strong as="h1" block className={classes.continueLastSession}>
                {t('home.continueLastSession')}
            </Body1Strong>
            <GridContainer className={classes.gridContainer}>
                <GridItem
                    sm={12}
                    md={12}
                    lg={12}
                    xlg={7}
                    xxlg={8}
                    xxxlg={6}
                    className={mergeClasses(classes.recentSessionsCard, classes.heroCard)}
                >
                    {heroSession && <TileLink session={heroSession} hero />}
                </GridItem>
                {!isSmallScreen && !isMediumScreen && (
                    <GridItem
                        lg={12}
                        xlg={5}
                        xxlg={4}
                        xxxlg={3}
                        className={mergeClasses(
                            classes.recentSessionsCard,
                            classes.alignCards,
                            classes.secondColumn,
                            classes.nonHeroCard,
                        )}
                    >
                        {sessionsForColumnOne.map((session, index) => (
                            <TileLink session={session} key={index.toString()} />
                        ))}
                    </GridItem>
                )}

                {isXXXLargeScreen && (
                    <GridItem
                        xxxlg={3}
                        className={mergeClasses(
                            classes.recentSessionsCard,
                            classes.alignCards,
                            classes.secondColumn,
                            classes.nonHeroCard,
                        )}
                    >
                        {sessionsForColumnTwo.map((session, index) => (
                            <TileLink session={session} key={index.toString()} />
                        ))}
                    </GridItem>
                )}
            </GridContainer>
            <Caption1 block className={classes.viewSessions}>
                <Link href="/sessions" data-testid="my-session-button">
                    {t('home.viewAllSessions')} <SubmitIcon />
                </Link>
            </Caption1>
        </div>
    );
}
