import * as React from 'react';
const MartialArtsUniform = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <g filter="url(#f1334idh)">
                <path
                    fill="url(#f1334id0)"
                    d="m29.697 16.972l-5.81-10.06c-.34-.6-.98-.96-1.67-.96H9.447c-.69 0-1.33.37-1.67.96l-5.81 10.07c-.28.48-.11 1.1.37 1.38l3.71 2.14c.48.28 1.1.11 1.38-.37l.91-1.57v7.75c0 .37.3.66.66.66h13.68c.37 0 .66-.3.66-.66v-7.74l.9 1.56c.28.48.9.65 1.38.37l3.72-2.14c.47-.29.64-.9.36-1.39Z"
                />
            </g>
            <path
                fill="url(#f1334id1)"
                d="m29.697 16.972l-5.81-10.06c-.34-.6-.98-.96-1.67-.96H9.447c-.69 0-1.33.37-1.67.96l-5.81 10.07c-.28.48-.11 1.1.37 1.38l3.71 2.14c.48.28 1.1.11 1.38-.37l.91-1.57v7.75c0 .37.3.66.66.66h13.68c.37 0 .66-.3.66-.66v-7.74l.9 1.56c.28.48.9.65 1.38.37l3.72-2.14c.47-.29.64-.9.36-1.39Z"
            />
            <path
                fill="url(#f1334id2)"
                d="M8.337 26.498c0 .265.3.474.66.474h13.68c.33 0 .596-.172.65-.392V12.123a2.503 2.503 0 0 0-1.11-.248H9.447c-.404 0-.79.091-1.11.252v14.37Z"
            />
            <path
                fill="url(#f1334id3)"
                d="M8.337 26.498c0 .265.3.474.66.474h13.68c.33 0 .596-.172.65-.392V12.123a2.503 2.503 0 0 0-1.11-.248H9.447c-.404 0-.79.091-1.11.252v14.37Z"
            />
            <path
                fill="url(#f1334ids)"
                d="M8.337 26.498c0 .265.3.474.66.474h13.68c.33 0 .596-.172.65-.392V12.123a2.503 2.503 0 0 0-1.11-.248H9.447c-.404 0-.79.091-1.11.252v14.37Z"
            />
            <path
                fill="url(#f1334idt)"
                d="M8.337 26.498c0 .265.3.474.66.474h13.68c.33 0 .596-.172.65-.392V12.123a2.503 2.503 0 0 0-1.11-.248H9.447c-.404 0-.79.091-1.11.252v14.37Z"
            />
            <path
                fill="url(#f1334id4)"
                d="m29.697 16.972l-5.81-10.06c-.34-.6-.98-.96-1.67-.96H9.447c-.69 0-1.33.37-1.67.96l-5.81 10.07c-.28.48-.11 1.1.37 1.38l3.71 2.14c.48.28 1.1.11 1.38-.37l.91-1.57v7.75c0 .37.3.66.66.66h13.68c.37 0 .66-.3.66-.66v-7.74l.9 1.56c.28.48.9.65 1.38.37l3.72-2.14c.47-.29.64-.9.36-1.39Z"
            />
            <g filter="url(#f1334idi)">
                <path
                    fill="#A09DA7"
                    d="M15.741 16.922L20.336 7.1a.4.4 0 0 0-.362-.57h-.752a.6.6 0 0 0-.543.346l-4.7 10.045h1.762Z"
                />
            </g>
            <g filter="url(#f1334idj)">
                <path
                    fill="#A09DA7"
                    d="M16 14.136L12.448 6.55a.6.6 0 0 0-.544-.346h-.92a.4.4 0 0 0-.362.57l4.408 9.432l.97-2.07Z"
                />
            </g>
            <path fill="#524D75" d="M19.445 5.952L15.85 13.68l-3.621-7.728h7.217Z" />
            <path fill="url(#f1334id5)" d="M19.445 5.952L15.85 13.68l-3.621-7.728h7.217Z" />
            <path fill="url(#f1334id6)" d="M19.445 5.952L15.85 13.68l-3.621-7.728h7.217Z" />
            <path fill="url(#f1334idu)" d="M19.445 5.952L15.85 13.68l-3.621-7.728h7.217Z" />
            <path fill="url(#f1334id7)" d="M19.445 5.952L15.85 13.68l-3.621-7.728h7.217Z" />
            <g filter="url(#f1334idk)">
                <path
                    fill="#A9A3B2"
                    d="m16.18 16.922l5.055-10.675a.4.4 0 0 0-.362-.571h-.918a.6.6 0 0 0-.543.343l-5.161 10.903h1.93Z"
                />
            </g>
            <g filter="url(#f1334idl)">
                <path
                    fill="#A9A3B2"
                    d="m15.817 13.612l-3.553-7.587a.6.6 0 0 0-.543-.345h-.92a.4.4 0 0 0-.362.569l4.408 9.433l.97-2.07Z"
                />
            </g>
            <path
                fill="url(#f1334id8)"
                d="m15.817 13.612l-3.553-7.587a.6.6 0 0 0-.543-.345h-.92a.4.4 0 0 0-.362.569l4.408 9.433l.97-2.07Z"
            />
            <path
                fill="url(#f1334id9)"
                d="M8.337 6.302c-.22.16-.41.36-.56.61l-5.81 10.07c-.28.48-.11 1.1.37 1.38l3.71 2.14c.48.28 1.1.11 1.38-.37l.91-1.57V6.302Z"
            />
            <path
                fill="url(#f1334idv)"
                d="M8.337 6.302c-.22.16-.41.36-.56.61l-5.81 10.07c-.28.48-.11 1.1.37 1.38l3.71 2.14c.48.28 1.1.11 1.38-.37l.91-1.57V6.302Z"
            />
            <path
                fill="url(#f1334ida)"
                d="M23.327 6.302c.22.16.41.36.56.61l5.81 10.07c.28.48.11 1.1-.37 1.38l-3.71 2.14c-.48.28-1.1.11-1.38-.37l-.91-1.57V6.302Z"
            />
            <path
                fill="url(#f1334idb)"
                d="M23.327 6.302c.22.16.41.36.56.61l5.81 10.07c.28.48.11 1.1-.37 1.38l-3.71 2.14c-.48.28-1.1.11-1.38-.37l-.91-1.57V6.302Z"
            />
            <g filter="url(#f1334idm)">
                <path
                    fill="#6E5A87"
                    d="M9.029 17.238a.54.54 0 0 0-.54.54v.112c0 .298.242.54.54.54h4.444l-1.416 3.073a.715.715 0 0 0 1.288.62l1.728-3.722a.83.83 0 0 0 .013-.029a.542.542 0 0 0 .146-.107a.542.542 0 0 0 .187.126l.005.01l1.727 3.723a.714.714 0 1 0 1.288-.62l-1.415-3.074h5.348a.54.54 0 0 0 .54-.54v-.112a.54.54 0 0 0-.54-.54H15.62a.538.538 0 0 0-.388.165a.538.538 0 0 0-.389-.165H9.03Z"
                />
            </g>
            <rect width="7.663" height="1.477" x="8.337" y="16.508" fill="url(#f1334idc)" rx=".6" />
            <g filter="url(#f1334idn)">
                <rect width="7.663" height="1.477" x="8.337" y="16.508" fill="#55486E" rx=".6" />
                <rect
                    width="7.663"
                    height="1.477"
                    x="8.337"
                    y="16.508"
                    fill="url(#f1334idd)"
                    rx=".6"
                />
            </g>
            <g filter="url(#f1334ido)">
                <rect width="7.663" height="1.477" x="15.664" y="16.508" fill="#605E87" rx=".6" />
                <rect
                    width="7.663"
                    height="1.477"
                    x="15.664"
                    y="16.508"
                    fill="url(#f1334idw)"
                    rx=".6"
                />
            </g>
            <g filter="url(#f1334idp)">
                <rect
                    width="1.589"
                    height="6.014"
                    x="14.569"
                    y="16.547"
                    fill="#564B71"
                    rx=".794"
                    transform="rotate(25.727 14.569 16.547)"
                />
                <rect
                    width="1.589"
                    height="6.014"
                    x="14.569"
                    y="16.547"
                    fill="url(#f1334ide)"
                    rx=".794"
                    transform="rotate(25.727 14.569 16.547)"
                />
                <rect
                    width="1.589"
                    height="6.014"
                    x="14.569"
                    y="16.547"
                    fill="url(#f1334idf)"
                    rx=".794"
                    transform="rotate(25.727 14.569 16.547)"
                />
                <rect
                    width="1.589"
                    height="6.014"
                    x="14.569"
                    y="16.547"
                    fill="url(#f1334idx)"
                    rx=".794"
                    transform="rotate(25.727 14.569 16.547)"
                />
            </g>
            <g filter="url(#f1334idq)">
                <rect
                    width="1.589"
                    height="6.014"
                    fill="#5F5A81"
                    rx=".794"
                    transform="scale(-1 1) rotate(25.727 -44.796 -29.236)"
                />
                <rect
                    width="1.589"
                    height="6.014"
                    fill="url(#f1334idg)"
                    rx=".794"
                    transform="scale(-1 1) rotate(25.727 -44.796 -29.236)"
                />
            </g>
            <g filter="url(#f1334idr)">
                <path
                    fill="#605584"
                    d="m14.85 17.46l-.077-.49a.4.4 0 0 1 .396-.462h1.41a.4.4 0 0 1 .392.48l-.101.5a.6.6 0 0 1-.588.48h-.84a.6.6 0 0 1-.592-.507Z"
                />
                <path
                    fill="url(#f1334idy)"
                    d="m14.85 17.46l-.077-.49a.4.4 0 0 1 .396-.462h1.41a.4.4 0 0 1 .392.48l-.101.5a.6.6 0 0 1-.588.48h-.84a.6.6 0 0 1-.592-.507Z"
                />
            </g>
            <defs>
                <linearGradient
                    id="f1334id0"
                    x1="25.012"
                    x2="8.504"
                    y1="9.159"
                    y2="23.811"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D4CFDF" />
                    <stop offset="0" stopColor="#D4CFDE" />
                    <stop offset="1" stopColor="#C9C2D7" />
                </linearGradient>
                <linearGradient
                    id="f1334id1"
                    x1="15.832"
                    x2="15.832"
                    y1="27.074"
                    y2="26.427"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9E7FBE" />
                    <stop offset="1" stopColor="#9E7FBE" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id2"
                    x1="7.29"
                    x2="10.226"
                    y1="21.561"
                    y2="21.474"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A9A1B6" />
                    <stop offset="1" stopColor="#A9A1B6" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id3"
                    x1="23.852"
                    x2="21.352"
                    y1="19.423"
                    y2="19.423"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E0DCEC" />
                    <stop offset="1" stopColor="#E0DCEC" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id4"
                    x1="15.832"
                    x2="15.832"
                    y1="27.074"
                    y2="26.427"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9E7FBE" />
                    <stop offset="1" stopColor="#9E7FBE" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id5"
                    x1="15.837"
                    x2="15.837"
                    y1="5.719"
                    y2="11.094"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5F4B73" />
                    <stop offset="1" stopColor="#5F4B73" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id6"
                    x1="17.837"
                    x2="16.977"
                    y1="9.688"
                    y2="9.328"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#333259" />
                    <stop offset="1" stopColor="#333259" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id7"
                    x1="15.837"
                    x2="15.837"
                    y1="13.906"
                    y2="11.719"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2B2C57" />
                    <stop offset="1" stopColor="#2B2C57" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id8"
                    x1="15.462"
                    x2="14.993"
                    y1="14.531"
                    y2="14.313"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".016" stopColor="#7B7782" />
                    <stop offset="1" stopColor="#7B7782" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334id9"
                    x1="4.009"
                    x2="4.384"
                    y1="19.453"
                    y2="18.813"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9A86B1" />
                    <stop offset="1" stopColor="#9A86B1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334ida"
                    x1="22.602"
                    x2="25.915"
                    y1="20.637"
                    y2="16.625"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B8AECB" />
                    <stop offset="1" stopColor="#B8AECB" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334idb"
                    x1="27.655"
                    x2="27.28"
                    y1="19.453"
                    y2="18.813"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9A86B1" />
                    <stop offset="1" stopColor="#9A86B1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334idc"
                    x1="9.063"
                    x2="15.052"
                    y1="17.469"
                    y2="17.469"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#56496E" />
                    <stop offset="1" stopColor="#64648D" />
                </linearGradient>
                <linearGradient
                    id="f1334idd"
                    x1="14.771"
                    x2="11.95"
                    y1="17.526"
                    y2="17.526"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#594F74" />
                    <stop offset="1" stopColor="#594F74" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334ide"
                    x1="16.219"
                    x2="15.62"
                    y1="20.106"
                    y2="20.087"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5C5376" />
                    <stop offset="1" stopColor="#5C5376" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334idf"
                    x1="16.558"
                    x2="15.89"
                    y1="18.978"
                    y2="19.067"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#362B5A" />
                    <stop offset="1" stopColor="#362B5A" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f1334idg"
                    x1="-.066"
                    x2=".535"
                    y1="2.484"
                    y2="2.469"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".085" stopColor="#797497" />
                    <stop offset="1" stopColor="#797497" stopOpacity="0" />
                </linearGradient>
                <filter
                    id="f1334idh"
                    width="29.001"
                    height="21.52"
                    x="1.332"
                    y="5.952"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".5" dy=".5" />
                    <feGaussianBlur stdDeviation=".5" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.768627 0 0 0 0 0.764706 0 0 0 0 0.784314 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-.5" dy=".5" />
                    <feGaussianBlur stdDeviation=".5" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.937255 0 0 0 0 0.933333 0 0 0 0 0.960784 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_2663"
                        result="effect2_innerShadow_18_2663"
                    />
                </filter>
                <filter
                    id="f1334idi"
                    width="7.395"
                    height="11.391"
                    x="13.479"
                    y="6.031"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_2663" stdDeviation=".25" />
                </filter>
                <filter
                    id="f1334idj"
                    width="6.416"
                    height="11.002"
                    x="10.084"
                    y="5.704"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_2663" stdDeviation=".25" />
                </filter>
                <filter
                    id="f1334idk"
                    width="7.323"
                    height="11.546"
                    x="14.101"
                    y="5.526"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".15" dy="-.15" />
                    <feGaussianBlur stdDeviation=".15" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.572549 0 0 0 0 0.537255 0 0 0 0 0.627451 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-.15" dy=".15" />
                    <feGaussianBlur stdDeviation=".15" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.737255 0 0 0 0 0.737255 0 0 0 0 0.760784 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_2663"
                        result="effect2_innerShadow_18_2663"
                    />
                </filter>
                <filter
                    id="f1334idl"
                    width="5.716"
                    height="10.302"
                    x="10.251"
                    y="5.53"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".15" dy="-.15" />
                    <feGaussianBlur stdDeviation=".2" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.572549 0 0 0 0 0.537255 0 0 0 0 0.627451 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-.15" dy=".15" />
                    <feGaussianBlur stdDeviation=".15" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.737255 0 0 0 0 0.737255 0 0 0 0 0.760784 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_2663"
                        result="effect2_innerShadow_18_2663"
                    />
                </filter>
                <filter
                    id="f1334idm"
                    width="16.422"
                    height="7.29"
                    x="7.489"
                    y="16.238"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_2663" stdDeviation=".5" />
                </filter>
                <filter
                    id="f1334idn"
                    width="7.663"
                    height="1.877"
                    x="8.337"
                    y="16.258"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy=".15" />
                    <feGaussianBlur stdDeviation=".075" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.368627 0 0 0 0 0.317647 0 0 0 0 0.470588 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-.25" />
                    <feGaussianBlur stdDeviation=".2" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.25098 0 0 0 0 0.192157 0 0 0 0 0.337255 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_2663"
                        result="effect2_innerShadow_18_2663"
                    />
                </filter>
                <filter
                    id="f1334ido"
                    width="7.663"
                    height="1.877"
                    x="15.664"
                    y="16.258"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy=".15" />
                    <feGaussianBlur stdDeviation=".075" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.384314 0 0 0 0 0.376471 0 0 0 0 0.54902 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-.25" />
                    <feGaussianBlur stdDeviation=".2" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.270588 0 0 0 0 0.25098 0 0 0 0 0.439216 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_2663"
                        result="effect2_innerShadow_18_2663"
                    />
                </filter>
                <filter
                    id="f1334idp"
                    width="3.76"
                    height="5.825"
                    x="12.224"
                    y="16.813"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".25" dy=".25" />
                    <feGaussianBlur stdDeviation=".25" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.27451 0 0 0 0 0.231373 0 0 0 0 0.360784 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                </filter>
                <filter
                    id="f1334idq"
                    width="3.76"
                    height="5.825"
                    x="15.966"
                    y="16.563"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".25" dy="-.25" />
                    <feGaussianBlur stdDeviation=".25" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.235294 0 0 0 0 0.196078 0 0 0 0 0.356863 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                </filter>
                <filter
                    id="f1334idr"
                    width="2.461"
                    height="1.711"
                    x="14.768"
                    y="16.258"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".25" dy="-.25" />
                    <feGaussianBlur stdDeviation=".325" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.243137 0 0 0 0 0.180392 0 0 0 0 0.4 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2663" />
                </filter>
                <radialGradient
                    id="f1334ids"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(1.65625 2.03125 -3.8408 3.13173 8.009 12.219)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".251" stopColor="#CDC7DA" />
                    <stop offset="1" stopColor="#CDC7DA" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f1334idt"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(122.229 8.284 12.56) scale(2.88146 4.18365)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D3CEDE" />
                    <stop offset="1" stopColor="#D3CEDE" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f1334idu"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(154.771 9.107 4.926) scale(4.17899 2.69621)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5B4A6F" />
                    <stop offset="1" stopColor="#5B4A6F" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f1334idv"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-.78125 1.875 -1.19808 -.4992 8.79 18.125)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8C7AA1" />
                    <stop offset="1" stopColor="#8C7AA1" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f1334idw"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-1.70752 1.59614 -2.25351 -2.41077 23.123 15.93)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7A7BA3" />
                    <stop offset="1" stopColor="#7A7BA3" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f1334idx"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(89.793 -.966 16.307) scale(5.34682 1.76229)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".893" stopColor="#44395B" stopOpacity="0" />
                    <stop offset="1" stopColor="#44395B" />
                </radialGradient>
                <radialGradient
                    id="f1334idy"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(105.031 2.055 14.902) scale(1.45648 1.14474)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7C759C" />
                    <stop offset="1" stopColor="#7C759C" stopOpacity="0" />
                </radialGradient>
            </defs>
        </g>
    </svg>
);
export default MartialArtsUniform;
