import * as React from 'react';
import {Field, Input, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {SkillsetSetting, SkillsetSettingType} from '@/api/skills';
import {getSettingInputType} from '../Utils/AdminConfigModal.utils';
import {useTranslation} from 'react-i18next';

interface SelectedSkillsetSettingConfigurationProps {
    config: SkillsetSetting;
    defaultInputValue: string;
    hasRequirementError: boolean;
    hasFailedValidation: boolean;
    isSettingUp: boolean;
    onInputChange: (ev: any) => void;
    autoFocus: boolean;
}

export default function SelectedSkillsetSettingConfiguration({
    config,
    defaultInputValue,
    hasRequirementError,
    hasFailedValidation,
    isSettingUp,
    onInputChange,
    autoFocus,
}: SelectedSkillsetSettingConfigurationProps) {
    const classes = useClasses();
    const [inputText, setInputText] = React.useState<string>(defaultInputValue);
    const {t} = useTranslation('plugins');
    React.useEffect(() => {
        setInputText(defaultInputValue);
    }, [defaultInputValue]);

    const configSettingType: SkillsetSettingType = config.settingType ?? SkillsetSettingType.String;
    const isHiddenSetting = configSettingType == SkillsetSettingType.SecretString;

    // We need an api update to flag when an optional hidden setting is configured.
    // This logic will only work for required hidden settings.
    // `isHiddenSetting && !hasRequirementError && !isSettingUp;`
    const isConfiguredHiddenSetting = false;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(e.currentTarget.value);
        onInputChange(e);
    };

    const getInputPlaceholderText = () => {
        if (config.hintText) {
            return config.hintText;
        }

        const placeholderKey = isConfiguredHiddenSetting
            ? 'Plugin.ConfigurePluginNewInputPlaceholder'
            : 'Plugin.ConfigurePluginInputPlaceholder';

        return t(placeholderKey, {
            parameterName: config.name,
        });
    };

    const getValidationMessage = () => {
        if (hasFailedValidation) {
            return t('Plugin.ConfigurePluginInputRequired', {
                parameterName: config.name,
            });
        }

        if (isConfiguredHiddenSetting) {
            return (
                <Text className={classes.noIconValidationMessage}>
                    {t('Plugin.ConfigurePluginNewInputRequired', {
                        parameterName: config.name,
                    })}
                </Text>
            );
        }

        return '';
    };

    return (
        <div key={config.name} className={classes.settingConfigurationInput}>
            <Field
                label={{
                    children: (
                        <MedeinaInfoLabel
                            labelContent={
                                <Text className={classes.configText}>
                                    {config.label ?? config.name}
                                </Text>
                            }
                            infoContent={config.description ?? ''}
                        />
                    ),
                }}
                required={config.required ?? false}
                validationState={hasFailedValidation ? 'error' : 'none'}
                validationMessage={getValidationMessage()}
            >
                <Input
                    aria-label={config.label ?? config.name}
                    type={getSettingInputType(configSettingType)}
                    value={inputText}
                    key={config.name}
                    autoFocus={autoFocus}
                    size="large"
                    appearance="outline"
                    className={
                        isHiddenSetting ? classes.longTextareaWraper : classes.textareaWrapper
                    }
                    placeholder={getInputPlaceholderText()}
                    onChange={handleInputChange}
                />
            </Field>
            <br />
        </div>
    );
}
