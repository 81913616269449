import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: tokens.colorNeutralBackground1,
        width: 'auto',
        height: 'auto',
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding(
            tokens.spacingVerticalM,
            tokens.spacingHorizontalL,
            tokens.spacingVerticalL,
            tokens.spacingHorizontalL,
        ),
        ...shorthands.gap(tokens.spacingVerticalM),
    },
    usageStatus: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalSNudge),
    },
    usageStatusIcon: {
        height: tokens.lineHeightBase100,
        width: tokens.lineHeightBase100,
    },
    usageStatusText: {
        fontWeight: tokens.fontWeightSemibold,
    },
    usageBox: {
        display: 'flex',
        flexDirection: 'row',
    },
    usageBar: {
        height: tokens.lineHeightHero800,
        width: '4px',
    },
    regularBar: {
        backgroundColor: tokens.colorBrandForegroundLink,
    },
    highDemandBar: {
        backgroundColor: tokens.colorPalettePeachBorderActive,
    },
    usageValueBox: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalS,
        ),
    },
    usageValue: {
        fontWeight: tokens.fontWeightBold,
    },
});
