// Shared styles for stage layouts and fonts/type
import {makeStyles, shorthands, GriffelStyle, tokens} from '@fluentui/react-components';

export const normalForegroundText: GriffelStyle = {
    // #FFF
    color: tokens.colorNeutralForeground1,
};

export const lightForegroundText: GriffelStyle = {
    color: tokens.colorNeutralForeground2,
};

export const lightestForegroundText: GriffelStyle = {
    // #DBDFE1
    color: tokens.colorNeutralForeground3,
};

export const invertedForegroundText: GriffelStyle = {
    color: tokens.colorNeutralForegroundOnBrand,
};

export const overlayText: GriffelStyle = {
    color: '#F6F7F8',
};

export const spotLightHeaderText: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    width: '288px',
};

export const spotLightText: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    width: '288px',
};

export const popoverTitle: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    maxWidth: '288px',
};

export const popoverText: GriffelStyle = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
};

export const heroTitle: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '60px',
    ...normalForegroundText,
};

export const heroSubTitle: GriffelStyle = {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: '600',
};

export const heroDescription: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '32px',
    //foreground 2
    ...lightForegroundText,
};

// used for the Terms view
export const blockTitle: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '52px',
    ...normalForegroundText,
};

export const blockTitleMedium: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '40px',
    ...normalForegroundText,
};

// the paragraph for the Terms view
export const blockText: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    ...lightestForegroundText,
};

export const blockTextMedium: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    ...lightestForegroundText,
};

// used for the geography view
export const blockSubtext: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '26px',
};

export const exitTitle: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '40px',
    paddingBottom: '50px',
    paddingTop: '30px',
    ...overlayText,
};

export const exitText: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'center',
    paddingBottom: '40px',
    ...overlayText,
};

export const paddedButton: GriffelStyle = {
    ...shorthands.padding('8px', '49px', '10px'),
    ...shorthands.border('1px', 'solid', '#fff'),
};

export const controlButton: GriffelStyle = {
    position: 'absolute',
    bottom: '30px',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
};

export const controlButtonText: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    ...shorthands.padding('8px', '16px', '8px', '16px'),
};

export const whiteButton: GriffelStyle = {
    backgroundColor: '#fff',
    color: '#000',
};

export const centeredText: GriffelStyle = {
    textAlign: 'center',
};

export const leftText: GriffelStyle = {
    textAlign: 'left',
};

export const rightText: GriffelStyle = {
    textAlign: 'right',
};

export const centeredBlock: GriffelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

export const centeredBottomPrompt: GriffelStyle = {
    position: 'absolute',
    bottom: '52px',
    left: '50%',
    transform: 'translateX(-50%)',
};

export const centeredBottomBlock: GriffelStyle = {
    position: 'absolute',
    bottom: '212px',
    left: '50%',
    transform: 'translateX(-50%)',
};

export const promptInputWrapper: GriffelStyle = {
    display: 'flex',
};

export const containerWidth: GriffelStyle = {
    width: '100%',
    minWidth: '500px',
    maxWidth: '700px',
};

export const primaryColor: GriffelStyle = {
    color: tokens.colorBrandForeground1,
};

export const darkOverlay: GriffelStyle = {
    backgroundColor: 'rgba(0,0,0,0.9)',
};

export const fullOverlay: GriffelStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
};

export const brandButtonNoBorder: GriffelStyle = {
    ...shorthands.borderBottom('none'),
    ...shorthands.borderTop('none'),
    ...shorthands.borderLeft('none'),
    ...shorthands.borderRight('none'),
};

export const textWrap: GriffelStyle = {
    maxWidth: '100%',
};

export const buttonsRow: GriffelStyle = {
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap('15px'),
};

export const paddedBottom: GriffelStyle = {
    paddingBottom: '64px',
};

export const whiteText: GriffelStyle = {
    color: tokens.colorNeutralForegroundOnBrand,
};

export default makeStyles({
    popoverTitle,
    popoverText,
    spotLightText,
    leftText,
    rightText,
    centeredText,
    centeredBottomPrompt,
    centeredBlock,
    blockTitle,
    blockTitleMedium,
    blockText,
    blockTextMedium,
    blockSubtext,
    heroDescription,
    heroTitle,
    heroSubTitle,
    centeredBottomBlock,
    promptInputWrapper,
    containerWidth,
    exitTitle,
    exitText,
    paddedButton,
    controlButton,
    controlButtonText,
    whiteButton,
    primaryColor,
    darkOverlay,
    fullOverlay,
    brandButtonNoBorder,
    textWrap,
    buttonsRow,
    paddedBottom,
    normalForegroundText,
    invertedForegroundText,
    lightForegroundText,
    lightestForegroundText,
    overlayText,
    whiteText,
});
