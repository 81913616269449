// #region Evaluation Feedback Models
export type EvaluationFeedback = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    feedbackId: string;
    feedbackType: EvaluationFeedbackType;
    feedbackSubTypes?: EvaluationFeedbackSubType[];
    content?: string;
    userId: string;
    tenantId?: string;
    createdAt: string;
    updatedAt?: string;
};

export enum EvaluationFeedbackType {
    Unknown = 'Unknown',
    Confirm = 'Confirm',
    OffTarget = 'OffTarget',
    Report = 'Report',
}

export enum EvaluationFeedbackSubType {
    Unknown = 'Unknown',

    // Confirm sub-types
    Accurate = 'Accurate',
    Clear = 'Clear',
    Complete = 'Complete',

    // OffTarget sub-types
    Inaccurate = 'Inaccurate',
    Unclear = 'Unclear',
    Incomplete = 'Incomplete',

    // Report sub-types
    Biases = 'Biases',
    Stereotypes = 'Stereotypes',
    HateSpeech = 'HateSpeech',
    Violence = 'Violence',
    SelfHarm = 'SelfHarm',
    SexualReferences = 'SexualReferences',
    Other = 'Other',
}

export type CreateEvaluationFeedbackRequest = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    feedbackType: EvaluationFeedbackType;
    feedbackSubTypes: EvaluationFeedbackSubType[];
    content: string;
};

export type GetEvaluationFeedbackRequest = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
};

export type UpdateEvaluationFeedbackRequest = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    feedbackId: string;
    feedbackType: EvaluationFeedbackType;
    feedbackSubTypes: EvaluationFeedbackSubType[];
    content: string;
};

export type DeleteEvaluationFeedbackRequest = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
};

// #endregion

// #region Customer Experience Feedback Models
export type FeedbackRequest = {
    category: FeedbackCategory;
    description: string;
};

export enum FeedbackCategory {
    Compliment = 'Compliment',
    ReportProblem = 'ReportProblem',
    Suggestion = 'Suggestion',
}
//#endregion
