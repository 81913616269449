import React from 'react';
import useClasses from './PromptDisplay.styles';
import type {EvaluationPromptSnapshot} from '@/api/evaluations';
import PromptContentLabel from '@/components/sections/prompts/PromptContentLabel';
import {Prompt} from '@/api/prompts';

interface PromptDisplayProps {
    promptSnapshot: EvaluationPromptSnapshot | undefined;
}

// A component that shows a read-only text version of a submitted prompt
function PromptDisplay(props: PromptDisplayProps) {
    const classes = useClasses();
    return (
        <div className={classes.root}>
            {/*  */}
            {/* <div className={classes.skill}></div> */}
            {props.promptSnapshot && (
                <PromptContentLabel
                    className={classes.prompt}
                    prompt={props.promptSnapshot as Partial<Prompt>}
                    pinned
                />
            )}
        </div>
    );
}

export default PromptDisplay;
