import {Table, mergeClasses} from '@fluentui/react-components';
import {useMarkdownContext} from '../../MarkdownContext';
import useClasses from './CompactTable.styles';
import CompactTableDialog from './CompactTableDialog';
import {useCompactTableProps} from './useCompactTableProps';
import {useTableContext} from '../TableContext';
import {useEvaluationContext} from '@/components/sections/evaluations/EvaluationContext';
import ExportToExcelButton from '../ExportToExcelButton';
const CompactTable = ({...props}) => {
    const componentStyles = useClasses();
    const modifiedProps = useCompactTableProps(props);
    const {tableHasData, linePositions} = useTableContext();
    const {allowTableDataExport} = useMarkdownContext();
    const {promptId, sessionId, evaluationId} = useEvaluationContext();

    const getStylesForNoDataDisplay = function () {
        return !tableHasData ? componentStyles.noTableDataDisplay : '';
    };

    return (
        <div className={mergeClasses(componentStyles.root, getStylesForNoDataDisplay())}>
            <div className={componentStyles.contentWrapper}>
                {tableHasData && (
                    <>
                        <div className={componentStyles.headerContainer}>
                            <div className={componentStyles.headingContainer}>
                                <span></span>
                            </div>

                            <div className={componentStyles.buttonContainer}>
                                {!!allowTableDataExport && (
                                    <ExportToExcelButton
                                        linePositions={linePositions}
                                        sessionId={sessionId}
                                        promptId={promptId}
                                        evaluationId={String(evaluationId)}
                                        className={componentStyles.excelButtonStyles}
                                    ></ExportToExcelButton>
                                )}
                                <CompactTableDialog {...props} />
                            </div>
                        </div>
                        <Table {...modifiedProps}></Table>
                    </>
                )}
            </div>
        </div>
    );
};

export default CompactTable;
