import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    pluginsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: tokens.spacingHorizontalS,
        rowGap: tokens.spacingVerticalXS,
    },
    pluginContainer: {
        height: tokens.fontSizeBase500,
        minWidth: 'auto',
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        backgroundColor: tokens.colorNeutralBackgroundAlpha,
    },
    badge: {
        height: tokens.fontSizeBase500,
        paddingLeft: tokens.spacingHorizontalXXS,
    },
    image: {
        backgroundColor: tokens.colorNeutralBackgroundAlpha,
        borderTopLeftRadius: tokens.borderRadiusMedium,
        borderBottomLeftRadius: tokens.borderRadiusMedium,
        ...shorthands.padding(tokens.spacingHorizontalXS),
        marginRight: tokens.spacingHorizontalXS,
        height: tokens.fontSizeBase500,
        width: tokens.fontSizeBase500,
    },
});
