import {useQuery} from '@tanstack/react-query';
import {PageResponse} from '../api';
import useFetch from '../useFetch';
import {SettingsScope, SkillsetConfiguration, SkillsetRequest} from './skills.types';

export default function useGetSkillsetConfigurations({scope}: SkillsetRequest) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['skillsets', 'configurations'],
        queryFn: async () => {
            return await customFetch<PageResponse<SkillsetConfiguration>>(
                `/skillsets/configurations?scope=${scope ?? SettingsScope.User}`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
