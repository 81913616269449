import {InfoLabel, mergeClasses} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useManageDataAndPrivacyDetails from './useManageDataAndPrivacyDetails';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import DataSharingErrorSwitch from './DataSharingErrorSwitch';
import ProgressiveSwitch from '@/components/ui/Switch';

export default function DataSharingSwitches() {
    const tourClasses = useTourClasses();

    const {t} = useTranslation('tours');

    const [errorTagForProduct, setErrorTagForProduct] = useState<boolean>(false);
    const [errorTagForModel, setErrorTagForModel] = useState<boolean>(false);
    const [errorTag, setErrorTag] = useState<boolean>(false);

    const {
        isAllowProductImprovement,
        isAllowModelImprovement,
        updateProductImprovement,
        updateModelImprovement,
        updateModelStateChange,
        updateProductStateChange,
    } = useManageDataAndPrivacyDetails({
        onWorkspacesFetchError: (error) => {
            setErrorTag(true);
        },
        onWorkspacesModelUpdateError: (error) => {
            setErrorTagForModel(true);
        },
        onWorkspacesProductUpdateError: (error) => {
            setErrorTagForProduct(true);
        },
    });

    return (
        <>
            <div className={tourClasses.switchLayout}>
                <ProgressiveSwitch
                    dataTestId="data-sharing-switch"
                    className={mergeClasses(tourClasses.switchStyles)}
                    state={updateProductStateChange}
                    onClick={(checked) => {
                        updateProductImprovement(checked);
                        setErrorTag(false);
                        setErrorTagForProduct(false);
                    }}
                    ariaLabel={t('adminV2Fre.dataAndPrivacy.DataSharingToggleHelp')}
                    checked={isAllowProductImprovement}
                    label={t('adminV2Fre.dataAndPrivacy.ImproveSecurityCopilot')}
                    labelPosition="before"
                ></ProgressiveSwitch>
                {(errorTag || errorTagForProduct) && <DataSharingErrorSwitch />}
            </div>
            <div className={tourClasses.switchLayout}>
                <ProgressiveSwitch
                    className={mergeClasses(tourClasses.switchStyles)}
                    dataTestId="ai-model-training-switch"
                    state={updateModelStateChange}
                    onClick={(checked) => {
                        updateModelImprovement(checked);
                        setErrorTag(false);
                        setErrorTagForModel(false);
                    }}
                    ariaLabel={t('adminV2Fre.dataAndPrivacy.AIModelTrainingToggleHelp')}
                    checked={isAllowModelImprovement}
                    label={
                        <>
                            {t('adminV2Fre.dataAndPrivacy.TrainAIModels')}
                            {
                                <InfoLabel
                                    info={t('adminV2Fre.dataAndPrivacy.AIModelTooltip')}
                                ></InfoLabel>
                            }
                        </>
                    }
                    labelPosition="before"
                ></ProgressiveSwitch>
                {(errorTag || errorTagForModel) && <DataSharingErrorSwitch />}
            </div>
        </>
    );
}
