import {
    MenuTrigger,
    MenuPopover,
    MenuList,
    Menu,
    MenuDivider,
    Button,
} from '@fluentui/react-components';
import {MoreHorizontal24Filled} from '@fluentui/react-icons';
import {useMemo, useState} from 'react';
import ConnectorSourcesModal from './ui/ConnectorModal/ConnectorSourcesModal';
import {AssistanceMenuItems, Feedback} from './ui/PromptBar/PortalAssistance';
import {MenuType} from './HeaderMenu.types';
import {useNavActions} from './Navigation';

export default function HeaderMenu() {
    const {navActions} = useNavActions();
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [isSupportCentralOpen, setIsSupportCentralOpen] = useState(false);
    const [skillsets, setSkillsets] = useState<string[] | null>(null);

    const userSkillsets = useMemo<string[] | undefined>(() => {
        const params = new URLSearchParams(window.location.search);
        return (
            params
                .get('skillsets')
                ?.split(',')
                .map((item: string) => item.trim()) ?? undefined
        );
    }, [typeof window !== 'undefined' && window.location.search]);

    return (
        <div>
            <Menu>
                <MenuTrigger>
                    <Button
                        size="small"
                        appearance="subtle"
                        aria-label="More"
                        icon={<MoreHorizontal24Filled />}
                    />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {navActions && navActions.length > 0 && (
                            <>
                                {navActions}
                                <MenuDivider />
                            </>
                        )}
                        <ConnectorSourcesModal
                            defaultSelectedSkillsets={userSkillsets}
                            onChangeSkillsets={(skillsets: string[]) => setSkillsets(skillsets)}
                            menuType={MenuType.Header}
                        />
                        <AssistanceMenuItems
                            onFeedbackClick={() => setIsFeedbackDialogOpen(true)}
                            onSupportCentralClick={() => setIsSupportCentralOpen(true)}
                            menuType={MenuType.Header}
                        />
                    </MenuList>
                </MenuPopover>
            </Menu>
            <Feedback
                openModal={isFeedbackDialogOpen}
                onCloseModal={() => setIsFeedbackDialogOpen(false)}
            />
        </div>
    );
}
