import React from 'react';
import {Tour, TourTelemetryOptions} from '@/components/ui/Tour';

// tour stops
import Welcome from './stops/Welcome';
import Splash from './stops/Splash';

import Preferences from '../commonStops/Preferences';
import Privacy from './stops/Privacy';

const defaultControlOptions = {
    allowSkip: true,
    allowExit: true,
    allowReplay: false,
    showExit: true,
    showControls: true,
    showCounter: true,
};

export interface AnalystTourProps {
    telemetryOptions?: TourTelemetryOptions;
}

export function AnalystTour(props: AnalystTourProps): Tour {
    return {
        name: 'AnalystTour',
        controlOptions: defaultControlOptions,
        autoPlay: false,
        telemetryName: 'analyst',
        telemetryOptions: props.telemetryOptions,
        tourStops: [
            {
                name: 'welcome',
                autoAdvance: false,
                autoStart: true,
                content: <Welcome />,
                controls: {
                    showControls: false,
                },
            },

            {
                name: 'preferences',
                autoAdvance: false,
                autoStart: true,
                content: <Preferences />,
                controls: {
                    showControls: false,
                },
            },
            {
                name: 'privacy',
                autoAdvance: false,
                autoStart: true,
                content: <Privacy />,
                controls: {
                    showControls: false,
                },
            },
            {
                name: 'splash',
                autoAdvance: false,
                autoStart: true,
                content: <Splash />,
                controls: {
                    showControls: false,
                },
            },
        ],
    };
}

export default AnalystTour;
