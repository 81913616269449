import {tokens, makeStyles, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground2,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('20px', '17px', '2.5px', '17px'),
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        color: tokens.colorNeutralForeground4,
    },
    headerContainer: {
        display: 'flex',
        paddingBottom: tokens.spacingVerticalXXL,
    },
    headingContainer: {
        textAlign: 'justify',
        '& span': {
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: '28px',
            fontWeight: tokens.fontWeightSemibold,
        },
    },
    buttonContainer: {
        flexGrow: 1,
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    noTableDataDisplay: {
        paddingBottom: '20px',
    },
    noDataWrapperStyles: {
        paddingBottom: tokens.spacingVerticalMNudge,
    },
    excelButtonStyles: {
        marginRight: `${tokens.spacingHorizontalM} !important`,
    },
});
