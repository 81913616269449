import * as React from 'react';
const SpeechBubbleLeft = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <g clipPath="url(#f828id8)">
                <g filter="url(#f828id3)">
                    <path
                        fill="url(#f828id0)"
                        d="M29.497 14.54C27.563 10.75 23.114 6.268 16 6.268c-7.134 0-11.531 4.248-13.457 8.312a2.61 2.61 0 0 0-.04 2.73C5.007 21.636 8.886 25.584 16 25.584c.947 0 1.878-.041 2.73-.162c.46-.066.933-.008 1.342.213c1.155.624 2.315 1.3 3.794 2.136a.657.657 0 0 0 .98-.614l-.216-3.408a1.599 1.599 0 0 1 .584-1.315c1.73-1.456 3.085-3.289 4.243-5.171c.524-.852.544-1.86.04-2.722Z"
                    />
                    <path
                        fill="url(#f828id1)"
                        d="M29.497 14.54C27.563 10.75 23.114 6.268 16 6.268c-7.134 0-11.531 4.248-13.457 8.312a2.61 2.61 0 0 0-.04 2.73C5.007 21.636 8.886 25.584 16 25.584c.947 0 1.878-.041 2.73-.162c.46-.066.933-.008 1.342.213c1.155.624 2.315 1.3 3.794 2.136a.657.657 0 0 0 .98-.614l-.216-3.408a1.599 1.599 0 0 1 .584-1.315c1.73-1.456 3.085-3.289 4.243-5.171c.524-.852.544-1.86.04-2.722Z"
                    />
                    <path
                        fill="url(#f828id6)"
                        d="M29.497 14.54C27.563 10.75 23.114 6.268 16 6.268c-7.134 0-11.531 4.248-13.457 8.312a2.61 2.61 0 0 0-.04 2.73C5.007 21.636 8.886 25.584 16 25.584c.947 0 1.878-.041 2.73-.162c.46-.066.933-.008 1.342.213c1.155.624 2.315 1.3 3.794 2.136a.657.657 0 0 0 .98-.614l-.216-3.408a1.599 1.599 0 0 1 .584-1.315c1.73-1.456 3.085-3.289 4.243-5.171c.524-.852.544-1.86.04-2.722Z"
                    />
                    <path
                        fill="url(#f828id2)"
                        d="M29.497 14.54C27.563 10.75 23.114 6.268 16 6.268c-7.134 0-11.531 4.248-13.457 8.312a2.61 2.61 0 0 0-.04 2.73C5.007 21.636 8.886 25.584 16 25.584c.947 0 1.878-.041 2.73-.162c.46-.066.933-.008 1.342.213c1.155.624 2.315 1.3 3.794 2.136a.657.657 0 0 0 .98-.614l-.216-3.408a1.599 1.599 0 0 1 .584-1.315c1.73-1.456 3.085-3.289 4.243-5.171c.524-.852.544-1.86.04-2.722Z"
                    />
                </g>
                <g filter="url(#f828id4)">
                    <path
                        fill="url(#f828id7)"
                        d="m24.083 20.832l.287 5.818a.25.25 0 0 1-.442.172l-3.647-4.365l2.344-3.531l1.458 1.906Z"
                    />
                </g>
                <g filter="url(#f828id5)">
                    <ellipse
                        fill="#EBE4FB"
                        fillOpacity=".75"
                        rx="8.72"
                        ry="5.69"
                        transform="matrix(-1 0 0 1 13.624 16)"
                    />
                </g>
            </g>
            <defs>
                <radialGradient
                    id="f828id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(8.43748 22.5 -41.97265 15.7397 11.813 12.051)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset="1" stopColor="#D7C6DE" />
                </radialGradient>
                <radialGradient
                    id="f828id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-149.997 13.982 9.678) scale(27.4972 39.7665)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".616" stopColor="#CECECE" stopOpacity="0" />
                    <stop offset=".827" stopColor="#CECECE" />
                </radialGradient>
                <radialGradient
                    id="f828id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(5.01202 .9375 -1.57128 8.40027 29.863 24.551)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B6A8F2" />
                    <stop offset="1" stopColor="#B6A8F2" stopOpacity="0" />
                </radialGradient>
                <filter
                    id="f828id3"
                    width="27.726"
                    height="21.598"
                    x="2.137"
                    y="6.268"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy=".01" />
                    <feGaussianBlur stdDeviation=".25" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.758333 0 0 0 0 0.758333 0 0 0 0 0.758333 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_7155_6142" />
                </filter>
                <filter
                    id="f828id4"
                    width="6.089"
                    height="9.987"
                    x="19.281"
                    y="17.926"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7155_6142" stdDeviation=".5" />
                </filter>
                <filter
                    id="f828id5"
                    width="27.44"
                    height="21.379"
                    x="-.096"
                    y="5.311"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7155_6142" stdDeviation="2.5" />
                </filter>
                <linearGradient
                    id="f828id6"
                    x1="27.567"
                    x2="10.188"
                    y1="-8.515"
                    y2="29.739"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".731" stopColor="#B6A8F2" stopOpacity="0" />
                    <stop offset=".986" stopColor="#B6A8F2" />
                </linearGradient>
                <linearGradient
                    id="f828id7"
                    x1="21.903"
                    x2="18.981"
                    y1="27.295"
                    y2="21.822"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset=".779" stopColor="#fff" stopOpacity="0" />
                </linearGradient>
                <clipPath id="f828id8">
                    <path fill="#fff" d="M0 0h32v32H0z" />
                </clipPath>
            </defs>
        </g>
    </svg>
);
export default SpeechBubbleLeft;
