import {useMutation} from '@tanstack/react-query';
import {customFetch, FetchApiError} from '../api';
import useFetch from '../useFetch';
import {CreateSessionRequest, Session} from './sessions.types';

export default function useCreateSession() {
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: CreateSessionRequest) => {
            let session;
            try {
                session = await customFetch<Session>(`/sessions`, {
                    method: 'POST',
                    body,
                });
            } catch (error: unknown) {
                // Usually a failure here is that the `name` was blocked by the WAF.
                // It could be a service outage too.
                if (error instanceof FetchApiError) {
                    // Try again with a `null` name.
                    try {
                        session = await customFetch<Session>(`/sessions`, {
                            method: 'POST',
                            body: {
                                ...body,
                                name: null,
                            },
                        });
                    } catch (error: unknown) {
                        // If it failed again, it's a service outage or authentication issue.
                        throw error;
                    }
                } else {
                    throw error;
                }
            }

            return session;
        },
    });
}
