import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '240px',
        maxHeight: '200px',
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        textAlign: 'center',
        ...shorthands.padding(tokens.spacingHorizontalL, tokens.spacingHorizontalL),
        ...shorthands.gap(tokens.spacingHorizontalL),
        borderBottomLeftRadius: tokens.borderRadiusMedium,
        borderBottomRightRadius: tokens.borderRadiusMedium,
        backgroundColor: tokens.colorNeutralBackground1,
    },
    image: {
        ...shorthands.margin(
            tokens.spacingVerticalL,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
        ),
        width: '64px',
        height: '64px',
    },
    dismissBtn: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    drawerRoot: {
        maxHeight: '200px',
    },
    drawerBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        textAlign: 'center',
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalL,
            tokens.spacingVerticalXXXL,
        ),
        ...shorthands.gap(tokens.spacingHorizontalL),
        backgroundColor: tokens.colorNeutralBackground1,
    },
});
