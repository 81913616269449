import useClasses from './ResponsivePersona.styles';
import {useViewportSize} from '@/components/ui/Grid';
import {AvatarNamedColor, Persona, mergeClasses} from '@fluentui/react-components';

interface ResponsivePersonaProps {
    content: string;
    color: 'neutral' | 'brand' | 'colorful' | AvatarNamedColor | undefined;
    classes?: string;
}

export default function ResponsivePersona(props: ResponsivePersonaProps) {
    const {sm: isSmallScreen} = useViewportSize();
    const personaSize = isSmallScreen ? 'small' : 'large';

    const classes = useClasses();
    const styles = props?.classes ? mergeClasses(classes.root, props.classes) : classes.root;

    const testId = isSmallScreen ? 'persona-small' : 'persona-large';

    return (
        <>
            <Persona
                data-testid={testId}
                className={styles}
                textPosition="below"
                name={props.content}
                avatar={{color: props.color}}
                size={personaSize}
            />
        </>
    );
}
