import {NavigationBarHeight} from '@/components/App.styles';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    dialogSurface: {
        minHeight: '70%',
        display: 'flex',
        overflowY: 'auto',
    },
    dialogBody: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        overflowY: 'auto',
        rowGap: tokens.spacingHorizontalXXXL,
        width: '100%',
        flexShrink: 0,
    },
    chipsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    chips: {
        ...shorthands.margin(tokens.spacingHorizontalXS),
    },
});
