// This file is used to define the fallbacks for languages that are not supported by date-fns
// The fallbacks are used to map the language code to a supported language code
// This is used to ensure that the date-fns library can handle the language
// this is not an extensive list but it covers the most common languages that we support and we can add to it as needed
// the fallbacks were determined using the msft guidelines for language fallback

const en_fallbacks = {
    'en-AG': 'en-US',
    'en-AU': 'en-AU',
    'en-AT': 'en-US',
    'en-BS': 'en-US',
    'en-BB': 'en-US',
    'en-BE': 'en-US',
    'en-BW': 'en-US',
    'en-BI': 'en-US',
    'en-CM': 'en-US',
    'en-CA': 'en-CA',
    'en-CY': 'en-US',
    'en-DK': 'en-US',
    'en-DM': 'en-US',
    'en-ER': 'en-US',
    'en-SZ': 'en-US',
    'en-FJ': 'en-US',
    'en-FI': 'en-US',
    'en-GM': 'en-US',
    'en-DE': 'en-US',
    'en-GH': 'en-US',
    'en-GD': 'en-US',
    'en-GY': 'en-US',
    'en-HK': 'en-US',
    'en-IN': 'en-IN',
    'en-IE': 'en-IE',
    'en-IL': 'en-US',
    'en-JM': 'en-US',
    'en-KE': 'en-US',
    'en-KI': 'en-US',
    'en-LS': 'en-US',
    'en-LR': 'en-US',
    'en-MO': 'en-US',
    'en-MG': 'en-US',
    'en-MW': 'en-US',
    'en-MY': 'en-US',
    'en-MT': 'en-US',
    'en-MH': 'en-US',
    'en-MU': 'en-US',
    'en-FM': 'en-US',
    'en-NA': 'en-US',
    'en-NR': 'en-US',
    'en-NL': 'en-US',
    'en-NZ': 'en-NZ',
    'en-NG': 'en-US',
    'en-NU': 'en-US',
    'en-PK': 'en-US',
    'en-PW': 'en-US',
    'en-PG': 'en-US',
    'en-PH': 'en-US',
    'en-RW': 'en-US',
    'en-WS': 'en-US',
    'en-SC': 'en-US',
    'en-SL': 'en-US',
    'en-SG': 'en-US',
    'en-SX': 'en-US',
    'en-SI': 'en-US',
    'en-SB': 'en-US',
    'en-ZA': 'en-ZA',
    'en-SS': 'en-US',
    'en-KN': 'en-US',
    'en-LC': 'en-US',
    'en-VC': 'en-US',
    'en-SD': 'en-US',
    'en-SE': 'en-US',
    'en-CH': 'en-US',
    'en-TZ': 'en-US',
    'en-TO': 'en-US',
    'en-TT': 'en-US',
    'en-TV': 'en-US',
    'en-UG': 'en-US',
    'en-GB': 'en-US',
    'en-US': 'en-US',
    'en-VU': 'en-US',
    'en-ZM': 'en-US',
    'en-ZW': 'en-US',
    en: 'en-US',
};

const es_fallbacks = {
    'eu-ES': 'es',
    'es-AR': 'es',
    'es-BO': 'es',
    'es-CL': 'es',
    'es-CO': 'es',
    'es-CR': 'es',
    'es-CU': 'es',
    'es-DO': 'es',
    'es-EC': 'es',
    'es-SV': 'es',
    'es-GQ': 'es',
    'es-GT': 'es',
    'es-HN': 'es',
    'es-MX': 'es',
    'es-NI': 'es',
    'es-PA': 'es',
    'es-PY': 'es',
    'es-PE': 'es',
    'es-PH': 'es',
    'es-ES': 'es',
    'es-US': 'es',
    'es-UY': 'es',
    'es-VE': 'es',
    es: 'es',
};

const ja_fallbacks = {
    'ja-JP': 'ja',
    ja: 'ja',
};

const fr_fallbacks = {
    'agq-CM': 'fr',
    'ksf-CM': 'fr',
    'bas-CM': 'fr',
    'dua-CM': 'fr',
    'ewo-CM': 'fr',
    'fr-DZ': 'fr',
    'fr-BE': 'fr',
    'fr-BJ': 'fr',
    'fr-BF': 'fr',
    'fr-BI': 'fr',
    'fr-CM': 'fr',
    'fr-CA': 'fr',
    'fr-CF': 'fr',
    'fr-TD': 'fr',
    'fr-KM': 'fr',
    'fr-CG': 'fr',
    'fr-CI': 'fr',
    'fr-DJ': 'fr',
    'fr-GQ': 'fr',
    'fr-FR': 'fr',
    'fr-GF': 'fr',
    'fr-PF': 'fr',
    'fr-GA': 'fr',
    'fr-GP': 'fr',
    'fr-GN': 'fr',
    'fr-HT': 'fr',
    'fr-LU': 'fr',
    'fr-MG': 'fr',
    'fr-ML': 'fr',
    'fr-MQ': 'fr',
    'fr-MR': 'fr',
    'fr-MU': 'fr',
    'fr-YT': 'fr',
    'fr-MC': 'fr',
    'fr-MA': 'fr',
    'fr-NC': 'fr',
    'fr-NE': 'fr',
    'fr-RE': 'fr',
    'fr-RW': 'fr',
    'fr-SN': 'fr',
    'fr-SC': 'fr',
    'fr-BL': 'fr',
    'fr-MF': 'fr',
    'fr-PM': 'fr',
    'fr-CH': 'fr',
    'fr-SY': 'fr',
    'fr-TG': 'fr',
    'fr-TN': 'fr',
    'fr-VU': 'fr',
    'fr-WF': 'fr',
    'fr-CD': 'fr',
    'kkj-CM': 'fr',
    'nmg-CM': 'fr',
    'lo-LA': 'fr',
    'mgo-CM': 'fr',
    'mua-CM': 'fr',
    'nnh-CM': 'fr',
    'jgo-CM': 'fr',
    'yav-CM': 'fr',
    fr: 'fr',
};

const de_fallbacks = {
    'de-AT': 'de',
    'de-BE': 'de',
    'de-DE': 'de',
    'de-LI': 'de',
    'de-LU': 'de',
    'de-CH': 'de',
    de: 'de',
};
// To offer pt-BR is very common when we can’t offer a pt-PT setting or content.
// Although different teams have their own fallback rules, we should first offer pt-BR if pr-PT is not available and then en-US. Based on an opinion from Mara Ferreira Martins, MSCOM localization manager, December 2017.
const pt_br = {
    pt: 'pt',
    'pt-BR': 'pt',
};

const it = {
    'fur-IT': 'it',
    'it-SM': 'it',
    'it-CH': 'it',
    'it-IT': 'it',
    fur: 'it',
    it: 'it',
};
// Simplified chinese is not supported by date-fns
// we fall back to supporting traditional chinese
const zh_Hans = {
    'zh-CN': 'zh-CN',
    'zh-Hans-HK': 'zh-CN',
    'zh-Hans-MO': 'zh-CN',
    'zh-SG': 'zh-CN',
    'zh-Hans': 'zh-CN',
};

const ko = {
    kok: 'ko',
    'kok-IN': 'ko',
    'ko-KR': 'ko',
    ko: 'ko',
};

const nl = {
    'nl-AW': 'nl',
    'nl-BE': 'nl',
    'nl-CW': 'nl',
    'nl-SX': 'nl',
    'nl-SR': 'nl',
    'pap-029': 'nl',
    'fy-NL': 'nl',
    fy: 'nl',
    'nl-NL': 'nl',
    nl: 'nl',
};

const sv = {
    'smj-SE': 'sv',
    'se-SE': 'sv',
    'sma-SE': 'sv',
    'sv-AX': 'sv',
    'sv-FI': 'sv',
    'yi-001': 'sv',
    'sv-SE': 'sv',
    sv: 'sv',
};

const pl = {
    'prg-001': 'pl',
    'pl-PL': 'pl',
    pl: 'pl',
};

// To offer pt-BR is very common when we can’t offer a pt-PT setting or content.
// Although different teams have their own fallback rules, we should first offer pt-BR if pr-PT is not available and then en-US. Based on an opinion from Mara Ferreira Martins, MSCOM localization manager, December 2017.
const pt_pt = {
    'kea-CV': 'pt',
    'ln-AO': 'pt',
    'mgh-MZ': 'pt',
    'pt-AO': 'pt',
    'pt-CV': 'pt',
    'pt-GW': 'pt',
    'pt-MO': 'pt',
    'pt-MZ': 'pt',
    'pt-ST': 'pt',
    'pt-TL': 'pt',
    'pt-PT': 'pt',
    pt: 'pt',
};

// date-fns does not support Norwegian, so we fall back to nb
// this follows msft guidelines for language fallback
const no = {
    'smj-SE': 'nb',
    'sma-SE': 'nb',
    'se-FI': 'nb',
    'se-SE': 'nb',
    'nb-NO': 'nb',
    'nn-NO': 'nb',
    'smj-NO': 'nb',
    'se-NO': 'nb',
    'sma-NO': 'nb',
    nn: 'nb',
    nb: 'nb',
    no: 'nb',
};

const tr = {
    'tr-CY': 'tr',
    'tr-TR': 'tr',
    tr: 'tr',
};

const da = {
    dav: 'da',
    'dav-KE': 'da',
    'da-GL': 'da',
    'fo-DK': 'da',
    'fo-FO': 'da',
    'kl-GL': 'da',
    'da-DK': 'da',
    da: 'da',
};

const fi = {
    smn: 'fi',
    se: 'fi',
    'smn-FI': 'fi',
    'se-FI': 'fi',
    'sms-FI': 'fi',
    'sv-AX': 'fi',
    'sv-FI': 'fi',
    'fi-FI': 'fi',
    fi: 'fi',
};
// Chinese traditional is not supported by date-fns
// we fall back to the 3rd option in the fallback order that is available
const zh_hant = {
    'zh-HK': 'zh-HK',
    'zh-MO': 'zh-TW',
    'zh-TW': 'zh-TW',
    'zh-Hant': 'zh-TW',
};

const ar = {
    'aa-DJ': 'ar',
    'aa-ER': 'ar',
    'ar-DZ': 'ar',
    'ar-BH': 'ar',
    'ar-TD': 'ar',
    'ar-KM': 'ar',
    'ar-DJ': 'ar',
    'ar-EG': 'ar',
    'ar-ER': 'ar',
    'ar-IQ': 'ar',
    'ar-IL': 'ar',
    'ar-JO': 'ar',
    'ar-KW': 'ar',
    'ar-LB': 'ar',
    'ar-LY': 'ar',
    'ar-MR': 'ar',
    'ar-MA': 'ar',
    'ar-OM': 'ar',
    'ar-PS': 'ar',
    'ar-QA': 'ar',
    'ar-SA': 'ar',
    'ar-SO': 'ar',
    'ar-SS': 'ar',
    'ar-SD': 'ar',
    'ar-SY': 'ar',
    'ar-TN': 'ar',
    'ar-AE': 'ar',
    'ar-YE': 'ar',
    'byn-ER': 'ar',
    'tzm-Arab-MA': 'ar',
    'tzm-Latn-DZ': 'ar',
    'tzm-Latn-MA': 'ar',
    'tzm-Tfng-MA': 'ar',
    'ku-Arab-IQ': 'ar',
    'fr-DZ': 'ar',
    'fr-TD': 'ar',
    'fr-KM': 'ar',
    'fr-DJ': 'ar',
    'fr-MR': 'ar',
    'fr-SY': 'ar',
    'fr-TN': 'ar',
    'kab-DZ': 'ar',
    'lrc-IQ': 'ar',
    'ssy-ER': 'ar',
    'so-DJ': 'ar',
    'so-SO': 'ar',
    'zgh-Tfng-MA': 'ar',
    'syr-SY': 'ar',
    'shi-Latn-MA': 'ar',
    'shi-Tfng-MA': 'ar',
    'tig-ER': 'ar',
    'ti-ER': 'ar',
    ar: 'ar',
};

const th = {
    'th-TH': 'th',
    th: 'th',
};

const he = {
    'he-IL': 'he',
    'yi-001': 'he',
    he: 'he',
};

const cs = {
    'cs-CZ': 'cs',
    cs: 'cs',
};

const hu = {
    'hu-HU': 'hu',
    hu: 'hu',
};

const uk = {
    'uk-UA': 'uk',
    uk: 'uk',
};

const ru = {
    'az-Cyrl-AZ': 'ru',
    'az-Latn-AZ': 'ru',
    'ba-RU': 'ru',
    'be-BY': 'ru',
    'ce-RU': 'ru',
    'cu-RU': 'ru',
    'kk-KZ': 'ru',
    'ky-KG': 'ru',
    'mn-MN': 'ru',
    'mn-Mong-MN': 'ru',
    'os-GE': 'ru',
    'os-RU': 'ru',
    'ro-MD': 'ru',
    'ru-BY': 'ru',
    'ru-KZ': 'ru',
    'ru-KG': 'ru',
    'ru-MD': 'ru',
    'ru-RU': 'ru',
    'ru-UA': 'ru',
    'sah-RU': 'ru',
    'tg-Cyrl-TJ': 'ru',
    'tt-RU': 'ru',
    'tk-TM': 'ru',
    ru: 'ru',
};

export const language_fallbacks: Record<string, string> = {
    ...en_fallbacks,
    ...es_fallbacks,
    ...ja_fallbacks,
    ...fr_fallbacks,
    ...de_fallbacks,
    ...pt_br,
    ...it,
    ...zh_Hans,
    ...ko,
    ...nl,
    ...sv,
    ...pl,
    ...pt_pt,
    ...no,
    ...tr,
    ...da,
    ...fi,
    ...zh_hant,
    ...ar,
    ...th,
    ...he,
    ...cs,
    ...hu,
    ...uk,
    ...ru,
};

export default language_fallbacks;
