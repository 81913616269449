import React, {ErrorInfo, Component, useCallback} from 'react';
import {ErrorBoundaryProps, ErrorBoundaryState} from './ErrorBoundary.types';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {useTrackEvent, MedeinaTelemetryEvent, MedeinaEvent} from '@/api/telemetry';

function extractComponentName(componentStack: string): string | undefined {
    const lines = componentStack.split('\n');
    if (lines.length > 1) {
        // The second line usually contains the component name
        const match = lines[1].match(/at (\w+)/);
        if (match) {
            return match[1];
        }
    }
    return undefined;
}

/** A generic ErrorBoundary for react components that passes underlying error message to the fallback component */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {hasError: false, error: undefined, errorInfo: undefined};
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return {hasError: true, error};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.props.onError?.(error, errorInfo);
        if (this.props.enabled) {
            this.setState({error, errorInfo});
        }
    }

    render() {
        // when the feature is not enabled let the children render
        if (this.props.enabled === false) {
            return this.props.children;
        }

        if (this.state.hasError) {
            return (
                <this.props.FallbackComponent
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                    showErrorDetails={this.props.showErrorDetails}
                />
            );
        }

        return this.props.children;
    }
}

export default function ErrorBoundaryWrapper(props: ErrorBoundaryProps) {
    const {children, FallbackComponent, showErrorDetails} = props;
    const showErrorBoundary = useFeatureFlag(MedeinaFeatures.EnableErrorBoundary);
    const showErrorDetailsEnabled = useFeatureFlag(
        MedeinaFeatures.EnableErrorBoundaryShowErrorDetails,
    );
    const {mutate: trackEvent, error} = useTrackEvent();
    const handleError = useCallback(
        (error: Error, errorInfo: ErrorInfo) => {
            trackEvent({
                name: MedeinaTelemetryEvent.App.ViewErrorBoundary,
                data: {component: extractComponentName(errorInfo.componentStack) || 'unknown'},
                eventType: MedeinaEvent.ActionEvent,
            });
            if (props.onError) {
                props.onError(error, errorInfo);
            }
        },
        [trackEvent, props.onError],
    );
    return (
        <ErrorBoundary
            showErrorDetails={showErrorDetails || Boolean(showErrorDetailsEnabled)}
            FallbackComponent={FallbackComponent}
            onError={handleError}
            enabled={Boolean(showErrorBoundary)}
        >
            {children}
        </ErrorBoundary>
    );
}
