import React, {useContext, useMemo} from 'react';
import {mergeClasses} from '@fluentui/react-components';
import TourContext from './TourContext';
import {OverlayOptions} from './Tour.types';
import {motion, AnimatePresence, Variants} from 'framer-motion';
import useClasses from './Overlay.styles';
import SpotlightOverlay from './SpotlightOverlay';

export const overlayAnimation: Variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {delay: 1, duration: 0.5},
    },
    exit: {
        opacity: 0,
        transition: {duration: 1},
    },
};

// The TourContext overlay that renders all tour stops
// this will render a tooltip highlighting and element or a ReactNode
// tooltip options: Spotlight tooltip, Tooltip w/no overlay
const TourOverlay = () => {
    const classes = useClasses();
    const {tour, stopIndex, activeTour, showOverlay, showExitOverlay, currentStop} =
        useContext(TourContext);
    const {overlay, spotlight, content} = currentStop || {};
    const {allowInteractions} = spotlight || {};
    // generate a new key for animations that need to be repeated
    const keyStamp = useMemo(() => `spotlight-${stopIndex}-${Date.now()}`, [stopIndex]);
    // when the spotlight overlay is up we need to modify the pointer events for the larger overlay to not interfere
    // with the click events on the spotlight overlay
    return activeTour && showOverlay ? (
        <motion.div
            style={{
                zIndex: 1,
                pointerEvents:
                    allowInteractions || allowInteractions !== undefined ? 'none' : 'all',
            }}
            className={classes.fullOverlay}
        >
            <>
                <AnimatePresence mode="wait">
                    {/* A normal overlay with a dark background */}
                    {content && overlay === OverlayOptions.dark && (
                        <motion.div
                            variants={overlayAnimation}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            key={`darkOverlayAnimation-${keyStamp}`}
                        >
                            <div
                                className={mergeClasses(classes.fullOverlay, classes.blackOverlay)}
                                data-testid="darkOverlay"
                            />
                            {!spotlight && content}
                        </motion.div>
                    )}
                    {/* A normal overlay with no background color */}
                    {content && overlay === OverlayOptions.none && (
                        <motion.div
                            variants={overlayAnimation}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            key="overlayNone-content"
                            data-testid="overlayNone-content"
                        >
                            {content}
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence mode="wait">
                    {spotlight &&
                        SpotlightOverlay({
                            tourStops: tour?.tourStops || [],
                            stopIndex,
                            currentStop: currentStop || null,
                        })}
                    {showExitOverlay && tour?.onEarlyExit && (
                        <motion.div
                            variants={overlayAnimation}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            key="exitOverlayAnimation"
                        >
                            <div
                                key="exitOverlay"
                                className={mergeClasses(classes.fullOverlay, classes.blackOverlay)}
                                data-testid="exitOverlay"
                            />
                            {tour.onEarlyExit}
                        </motion.div>
                    )}
                </AnimatePresence>
            </>
        </motion.div>
    ) : null;
};

export default TourOverlay;
