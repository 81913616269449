import {tokens} from '@fluentui/react-components';
import {makeStyles, shorthands} from '@griffel/react';

export const useClasses = makeStyles({
    help: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    supportButton: {
        justifyContent: 'left',
    },
});
