import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserPreferences} from './user.types';

export default function useUpdateUserPreferences() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    const fetchPreferences = async (newPreferences: Partial<UserPreferences>) => {
        return await customFetch<UserPreferences>(`/userPreferences`, {
            method: 'PUT',
            body: newPreferences,
        });
    };

    return useMutation({
        mutationFn: fetchPreferences,
        onSettled: (updatedPreferences, error, variables, context) => {
            if (!error) {
                queryClient.invalidateQueries(['userPreferences']);
            }
        },
    });
}
