import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    useFocusFinders,
} from '@fluentui/react-components';
import {useEffect, useRef, useState} from 'react';
import useClasses from './SwitchCapacityConfirmation.styles';
import {DismissIcon} from '@/components/ui/icons';
import {ResourceLink} from '@/components/admin';
import useGetCapacityParameters from '@/util/useGetCapacityParameters';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {useTranslation} from 'react-i18next';

interface SwitchCapacityConfirmationProps {
    isSwitchCapacityConfirmDialogOpen: boolean;
    onSwitchCapacityConfirmDialogClose?: () => void;
    capacityId?: string;
    regionName: string;
}

export default function SwitchCapacityConfirmation(props: SwitchCapacityConfirmationProps) {
    const classes = useClasses();

    // States to handle opening and closing of this component itself
    const [isSwitchCapacityConfirmDialogOpen, setSwitchCapacityConfirmDialogOpen] =
        useState<boolean>(props.isSwitchCapacityConfirmDialogOpen);

    const {findFirstFocusable} = useFocusFinders();
    const switchCapacityConfirmationRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (switchCapacityConfirmationRef?.current) {
            const firstFocusable = findFirstFocusable(switchCapacityConfirmationRef.current);
            firstFocusable?.focus();
        }
    }, [switchCapacityConfirmationRef]);

    // Actions to take on close/dismiss of confirm switch capacity dialog
    const closeUpdateCapacityConfirmation = () => {
        props.onSwitchCapacityConfirmDialogClose?.();
        setSwitchCapacityConfirmDialogOpen(false);
    };

    const tenantId = getCurrentTenantId();

    const {links} = useGetCapacityParameters({
        capacityId: props?.capacityId ?? '',
        tenantId: tenantId,
    });

    const {t: switchCapacityConfirmationLabel} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');

    // To handle open/close of dialog based on parent prop
    useEffect(() => {
        setSwitchCapacityConfirmDialogOpen(props.isSwitchCapacityConfirmDialogOpen);
    }, [props.isSwitchCapacityConfirmDialogOpen]);

    return (
        <div ref={switchCapacityConfirmationRef}>
            <Dialog open={isSwitchCapacityConfirmDialogOpen}>
                <DialogSurface>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            data-test-id="switch-capacity-confirmation-close-button"
                            className={classes.closeButton}
                            appearance="transparent"
                            aria-label={switchCapacityConfirmationLabel(
                                'switchCapacity.switchCapacityConfirmation.ariaLabel.CloseCapacityConfirmation',
                            )}
                            onClick={() => closeUpdateCapacityConfirmation()}
                        >
                            <DismissIcon className={classes.icon} />
                        </Button>
                    </DialogTrigger>
                    <DialogBody>
                        <DialogTitle>
                            {switchCapacityConfirmationLabel(
                                'switchCapacity.switchCapacityConfirmation.ModalTitle',
                            )}
                        </DialogTitle>
                        <DialogContent className={classes.dialogContentSection}>
                            {switchCapacityConfirmationLabel(
                                'switchCapacity.switchCapacityConfirmation.ModalInfoText',
                            )}

                            <div className={classes.resourceLinksContainer}>
                                <div className={classes.azureResouceLinks}>
                                    {switchCapacityConfirmationLabel(
                                        'switchCapacity.switchCapacityConfirmation.AzureResourceLinks',
                                    )}
                                </div>

                                <ResourceLink
                                    linkAttribute={links.capacity}
                                    displayName={switchCapacityConfirmationLabel(
                                        'switchCapacity.switchCapacityConfirmation.Capacity',
                                    )}
                                    dataTestId="switch-capacity-confirmation-capacity-link"
                                ></ResourceLink>
                                <ResourceLink
                                    linkAttribute={links.subscription}
                                    displayName={switchCapacityConfirmationLabel(
                                        'switchCapacity.switchCapacityConfirmation.Subscription',
                                    )}
                                    dataTestId="switch-capacity-confirmation-subscription-link"
                                ></ResourceLink>
                                <ResourceLink
                                    linkAttribute={links.resourceGroup}
                                    displayName={switchCapacityConfirmationLabel(
                                        'switchCapacity.switchCapacityConfirmation.ResourceGroup',
                                    )}
                                    dataTestId="switch-capacity-confirmation-resource-group-link"
                                ></ResourceLink>
                                <div>
                                    <span>
                                        {switchCapacityConfirmationLabel(
                                            'switchCapacity.switchCapacityConfirmation.Location',
                                        )}
                                    </span>
                                    <span>{props?.regionName}</span>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-test-id="switch-capacity-confirmation-done-button"
                                appearance="primary"
                                aria-label={switchCapacityConfirmationLabel(
                                    'switchCapacity.switchCapacityConfirmation.ariaLabel.Done',
                                )}
                                onClick={closeUpdateCapacityConfirmation}
                            >
                                {commonLabel('ButtonLabels.Done')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
}
