import {useMemo} from 'react';
import useGetAllPromptbooks from './useGetAllPromptbooks';

export default function useGetAllPromptbooksTags() {
    const {
        data: promptbooks,
        isLoading: promptbooksLoading,
        isError: getAllPromptbooksFailed,
    } = useGetAllPromptbooks();

    const availableTags =
        useMemo<string[]>(() => {
            if (getAllPromptbooksFailed || promptbooks === undefined) {
                return [] as string[];
            }

            return promptbooks?.value
                .filter((promptbook) => promptbook.tags !== null)
                .flatMap((obj) => obj.tags)
                .filter(
                    (promptbookTag) =>
                        promptbookTag !== null &&
                        promptbookTag !== undefined &&
                        promptbookTag.tag !== undefined,
                )
                .map((promptbookTag) => {
                    return promptbookTag !== undefined ? promptbookTag.tag : '';
                })
                .filter((value, index, self) => self.indexOf(value) === index && value !== '');
        }, [promptbooks, promptbooksLoading, getAllPromptbooksFailed]) || [];

    return {
        data: availableTags,
    };
}
