import React, {useEffect, useState} from 'react';
import useClasses from './MultiSelect.styles';
import {Input} from '@fluentui/react-components';
import CheckboxGroup from './CheckboxGroup';
import {SearchIcon} from '@/components/ui/icons';
import {MultiSelectProps} from '@/components/ui/Filter/Filter.types';
import {useTranslation} from 'react-i18next';
import {FilterPanelMultiSelectLabels} from '../UsageDashboard.constants';

export default function MultiSelect({
    options,
    showFilterSearch,
    selectedOptions,
    onSelectionChange,
}: MultiSelectProps) {
    const classes = useClasses();
    const {t: tAdmin} = useTranslation('admin');
    const [filterText, setFilterText] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        setFilteredOptions(
            options.filter((option) =>
                option.value.toLocaleLowerCase().includes(filterText.toLowerCase()),
            ),
        );
    }, [filterText, options]);

    const handleCheckboxChange = (option: string) => {
        const newSelectedOptions = selectedOptions.includes(option)
            ? selectedOptions.filter((selected) => selected !== option)
            : [...selectedOptions, option];

        onSelectionChange(newSelectedOptions);
    };

    return (
        <div>
            {showFilterSearch && (
                <Input
                    className={classes.searchInput}
                    required
                    type="text"
                    contentBefore={<SearchIcon />}
                    placeholder={tAdmin(FilterPanelMultiSelectLabels.SearchPlugin)}
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
            )}
            <CheckboxGroup
                options={filteredOptions}
                selectedOptions={selectedOptions}
                onCheckboxChange={handleCheckboxChange}
            />
        </div>
    );
}
