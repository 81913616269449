import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    buttonRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    deleteButton: {
        '&:hover': {
            backgroundColor: tokens.colorPaletteRedBackground3,
            color: tokens.colorNeutralForegroundOnBrand,
        },
    },
});
