import React, {useRef, useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {Button, useFocusFinders} from '@fluentui/react-components';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import useClasses from './Welcome.styles';
import {useNavigate} from 'react-router-dom';
import {useManageTenantInfo} from '@/api/tenant';
import {useTranslation} from 'react-i18next';
import {TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import useSetupAndProvisionFidelisConstructs from '@/api/capacities/useSetupAndProvisionFidelisConstructs';
import {useGetCapacitiesForAdminFre} from '@/api/capacities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import SetupInProgress from './SetupInprogress';
import {useQueryClient} from '@tanstack/react-query';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useViewportSize} from '@/components/ui/Grid';
import {Body, TextH1} from '@/components/ui/Text';
import MedeinaFeatures from '@/util/features';
import useManageTourTransition from './useManageTourTransition';
import {useFeatureFlag} from '@/api/user';

export interface WelcomeProps {
    freStage?: boolean;
    isFreComplete?: boolean;
    workspaceName?: string;
}

function Welcome(props: WelcomeProps) {
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const queryClient = useQueryClient();

    const {sm: isSmallScreen} = useViewportSize();

    const {setTourErrorV2, setTourStep, currentStop, tourError} = useTourContext();
    const navigate = useNavigate();

    const {validateWelcomeScreenTransition} = useManageTourTransition();

    const {t} = useTranslation('tours');
    const {update: providerUpdate} = useWorkspaceState();
    const [isProvisioningInProgress, setIsProvisioningInProgress] = useState(false);

    const welcomeWrapper = useRef(null);
    const getStartedButtonRef = useRef(null);

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    const {provisionAccountAndWorkspaceAsync} = useSetupAndProvisionFidelisConstructs({
        shouldPreLoadWorkspace: false,

        //API response handlers
        onWorkspaceProvisionError: () => {
            setIsProvisioningInProgress(false);
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.Welcome,
            });
        },
        onAccountCreationError: () => {
            setIsProvisioningInProgress(false);
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.Welcome,
            });
        },
        onInitialAccountAndWorkspaceProvisioningSuccess: async () => {
            // COntinuation of the provisioning process post account and workspace validation and creation
            await manageContinuationPostAccountProvisioning();
        },
    });

    const {refetch: getCapacities} = useGetCapacitiesForAdminFre({
        enabled: false,
    });

    const {initializeTenantFreInfo} = useManageTenantInfo();

    const {findFirstFocusable} = useFocusFinders();

    useEffect(() => {
        if (welcomeWrapper?.current && getStartedButtonRef?.current) {
            const firstFocusable = findFirstFocusable(welcomeWrapper.current);
            firstFocusable?.focus();
        }
    }, [welcomeWrapper, getStartedButtonRef]);

    const progressToNextScreen = (postSuccessOperation: () => void) => {
        initializeTenantFreInfo()
            .then(() => {
                setIsProvisioningInProgress(false);
                postSuccessOperation();
            })
            .catch(() => {
                setIsProvisioningInProgress(false);
                setTourErrorV2({
                    ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                    actionButtonStep: TOUR_STEPS.Welcome,
                });
            });
    };

    const manageContinuationPostAccountProvisioning = async () => {
        try {
            const capacities = await getCapacities();

            if (!!capacities.error) {
                setIsProvisioningInProgress(false);
                setTourErrorV2({
                    ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                    actionButtonStep: TOUR_STEPS.Welcome,
                });
                return;
            }

            // We go to the capacity selection screen if capacities are present.
            // Else we attempt to create a new capacity if no capacities exist
            if (capacities?.data?.count ?? 0 > 0) {
                progressToNextScreen(() => setTourStep(TOUR_STEPS.SelectCapacity));
            } else {
                progressToNextScreen(() => setTourStep(TOUR_STEPS.CreateCapacity));
            }
        } catch (error) {
            setIsProvisioningInProgress(false);
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.Welcome,
            });
            return;
        }
    };

    const performActionsOnContinue = async () => {
        try {
            const step = await validateWelcomeScreenTransition();
            if (step !== TOUR_STEPS.SelectLocation) {
                setIsProvisioningInProgress(true);
                // If the account isn't created or the Geo isn't set up the tenant record
                // will be created in the Select Location screen by design
                await initializeTenantFreInfo();
            }
            setTourStep(step);
        } catch (error) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.Welcome,
            });
        } finally {
            setIsProvisioningInProgress(false);
        }
    };

    const handleClick = async () => {
        // if the FRE is complete we don't want to take the user through any of the setup steps
        if (props.isFreComplete) {
            setTourStep(TOUR_STEPS.HOME);
            await providerUpdate();
            await queryClient.invalidateQueries(['auth', 'getUserInfo']);
            navigate('/');
            return;
        } else {
            if (!MedeinaFeatures.EnableGeoSelection) {
                setIsProvisioningInProgress(true);
                await provisionAccountAndWorkspaceAsync();
            } else {
                await performActionsOnContinue();
            }
        }
    };

    return (
        <motion.div
            key="welcome"
            variants={overlayAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <TourStageContainer ref={welcomeWrapper}>
                <TourStageContainer.StageContent>
                    <>
                        {!MedeinaFeatures.EnableGeoSelection && isProvisioningInProgress && (
                            <SetupInProgress
                                heading={t('adminV2Fre.welcome.SetupInProgress')}
                                subHeading={t('adminV2Fre.welcome.Polite')}
                            ></SetupInProgress>
                        )}
                        {MedeinaFeatures.EnableGeoSelection && isProvisioningInProgress && (
                            <SetupInProgress
                                heading={t('adminV2Fre.locationSelection.SetupInProgress')}
                                subHeading={t('adminV2Fre.locationSelection.Polite')}
                            ></SetupInProgress>
                        )}
                        {!isProvisioningInProgress && (
                            <>
                                <div className={tourClasses.tourMediumSectionSpacing}>
                                    <TextH1 role="heading">
                                        {t('adminV2Fre.welcome.Heading')}
                                    </TextH1>
                                </div>
                                <div className={classes.paragraphOverride}>
                                    <Body>
                                        {!!props.freStage || !!props.isFreComplete
                                            ? t('adminV2Fre.welcome.FreNotStartedInfoMessage')
                                            : isWorkspacesTestingEnabled
                                            ? t(
                                                  'adminV2Fre.WorkspacesWelcome.FreStartedInfoMessage',
                                              )
                                            : t('adminV2Fre.welcome.FreStartedInfoMessage')}
                                    </Body>
                                </div>
                                <span className={classes.buttonRow}>
                                    <Button
                                        ref={getStartedButtonRef}
                                        onClick={handleClick}
                                        appearance="primary"
                                        size={isSmallScreen ? 'small' : 'medium'}
                                        tabIndex={0}
                                        disabled={
                                            !Boolean(props?.isFreComplete) &&
                                            (Boolean(tourError) || currentStop?.isFirstStop)
                                        }
                                        className={classes.buttonContent}
                                    >
                                        {t('adminV2Fre.welcome.GetStarted')}
                                    </Button>
                                </span>
                            </>
                        )}
                    </>
                </TourStageContainer.StageContent>
            </TourStageContainer>
        </motion.div>
    );
}

export default Welcome;
