import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {PageResponse} from '../api';
import {PromptbookDescriptor, GetPromptbooksRequest} from './promptbooks.types';

// Cache for 10 minutes.
const CACHE_TIME = 10 * 60 * 1000;

export default function useGetAllPromptbooks(body: GetPromptbooksRequest = {}) {
    const {customFetch} = useFetch();
    return useQuery({
        // Cache _per unique key_.
        // Typically this would only need to be refetched when a user or tenant creates a promptbook.
        // This is helpful for promptbar menus, where it would otherwise frequently refetch.
        cacheTime: CACHE_TIME,
        staleTime: CACHE_TIME,
        refetchOnWindowFocus: false,

        queryKey: ['promptbooks', `${body?.filter ?? ''}&${body?.search ?? ''}`],
        queryFn: async () => {
            const params = [
                body.filter ? `$filter=${encodeURIComponent(body.filter)}` : ``,
                body.search ? `$search="${encodeURIComponent(body.search)}"` : ``,
            ] as string[];

            const querystringParam = params.filter((param) => !!param).join('&');
            return await customFetch<PageResponse<PromptbookDescriptor>>(
                `/promptbooks${querystringParam ? `?${querystringParam}` : ''}`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
