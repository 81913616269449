import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    textWrapper: {
        width: 'max-content',
        maxWidth: '600px',
    },
    errorContent: {
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.lineHeightBase600,
        fontWeight: tokens.fontWeightSemibold,
    },
    contentSectionSpacing: {
        marginBottom: tokens.spacingVerticalL,
        lineHeight: tokens.lineHeightBase600,
    },
    headerContainer: {
        '& > h1': {
            marginBottom: tokens.spacingVerticalNone,
            fontSize: tokens.fontSizeHero900,
        },
    },
    icon: {
        height: '48px',
        width: '48px',
    },
});
