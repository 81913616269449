import React, {useEffect, useCallback} from 'react';
import AdminPluginTour from './AdminPluginTour';
import {useTourContext} from '@/components/ui/Tour';
import {useElementBySelector} from '@/components/ui/Tour/util/useGetElement';
import {useAppState} from '@/api/app';
import useTourHistoryTelemetry from '@/components/ui/Tour/useTourHistoryTelemetry';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import config from './config';

function useAdminPluginTour() {
    const {onTourStart, onTourComplete, onTourExitEarly} = useTourHistoryTelemetry({
        tourName: config.telemetryName,
    });
    const {userTours, setUserTour} = useAppState();
    const {adminPlugins, admin} = userTours;
    const {started} = adminPlugins || {};

    const isA11yTesting = useFeatureFlag(MedeinaFeatures.a11yAdmin);

    const {activeTour, setTour, tour, stopIndex, currentStop, quitTour} = useTourContext();
    const [firstTourElement, fireFirstTourSearch] = useElementBySelector(
        "[data-testid='promptbar-root']",
        3000,
        true,
    );

    // on initial load if this is a11y testing, we should ignore the tour history
    useEffect(() => {
        if (isA11yTesting) {
            setUserTour(config.name, {});
        }
    }, []);

    // when this hook unmounts we should clear the tour
    useEffect(() => {
        return () => {
            quitTour();
        };
    }, []);

    const startTour = useCallback(() => {
        setTour(
            AdminPluginTour({
                telemetryOptions: {
                    onTourComplete,
                    onTourExitEarly,
                    onTourStart,
                },
            }),
        );
    }, [setTour, onTourStart, onTourComplete, onTourExitEarly]);

    // check for tour params, if we have them, start the tourParam
    useEffect(() => {
        // disable the tour for now until we have a11y testing completed
        // if (!activeTour && firstTourElement && admin && !adminPlugins) {
        //     startTour();
        // } else
        if (!activeTour && firstTourElement && isA11yTesting) {
            startTour();
        }
        // we only care when static values change not functions
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTour, firstTourElement, adminPlugins, isA11yTesting, admin]);
}

export default useAdminPluginTour;
