import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        maxWidth: '500px',
    },
    fields: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
        marginBottom: tokens.spacingVerticalM,
    },
    copyInput: {
        ...shorthands.flex(1, 1, 'auto'),
    },
    actionButton: {
        ...shorthands.flex(0, 0, 'auto'),
    },
    spinner: {
        ...shorthands.padding(tokens.spacingVerticalM, 0),
    },
    sharePopoverContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    sharePopoverTextContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    sharePopoverIcon: {
        marginRight: tokens.spacingHorizontalXS,
    },
    button: {
        ...shorthands.flex(1, 1, 'auto'),
        marginTop: tokens.spacingVerticalM,
    },
    peoplepicker: {
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalMNudge,
        ...shorthands.margin(0, 0, tokens.spacingVerticalXS),
        marginTop: tokens.spacingVerticalS,
    },
    header: {
        fontFamily: tokens.fontFamilyBase,
        fontSize: '20px',
        fontWeight: tokens.fontWeightSemibold,
        letterSpacing: '0em',
        textAlign: 'left',
    },
    sessionNameDisplay: {
        wordBreak: 'break-word',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: '-webkit-box',
    },
});
