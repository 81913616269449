import {IExtendedTelemetryItem} from '@microsoft/1ds-analytics-web-js';

export interface IMedeinaExtendedTelemetryItem extends Omit<IExtendedTelemetryItem, 'name'> {
    name: AnyMedeinaTelemetryEvent;
    customProperties?: CustomProperties;
    eventType: MedeinaEvent;
}

export enum MedeinaEvent {
    ActionEvent = 'Microsoft.Medeina.ActionEvent',
    UserInteractionData = 'Microsoft.Medeina.UserInteractionData',
}

// Telemetry namespaces and their events.
// These are purposely not exported in favor of using `MedeinaTelemetryEvent`.
enum AppTelemetryEvent {
    ViewErrorBoundary = 'App.ViewErrorBoundary',
}

enum EvaluationsTelemetryEvent {
    ExportAsWord = 'Evaluations.ExportAsWord',
    ExportAsMail = 'Evaluations.ExportAsMail',
    ExportAsCopy = 'Evaluations.ExportAsCopy',
}

enum FeedbacksTelemetryEvent {}

enum InvestigationsTelemetryEvent {}

enum PromptbooksTelemetryEvent {
    SharePromptbook = 'Promptbooks.SharePromptbook',
}

enum PromptsTelemetryEvent {}

enum SessionsTelemetryEvent {
    ExportSummaryAsWord = 'Sessions.ExportSummaryAsWord',
    ExportSummaryAsMail = 'Sessions.ExportSummaryAsMail',
    ExportSummaryAsCopy = 'Sessions.ExportSummaryAsCopy',
    ShareSession = 'Sessions.ShareSession',
}

enum SettingsTelemetryEvent {}

enum SkillsTelemetryEvent {
    ApplySkillsets = 'Skills.ApplySkillsets',
    OpenSkillsetConnector = 'Skills.OpenSkillsetConnector',
}

enum UnknownTelemetryEvent {
    Unknown = 'UnknownEvent',
}

enum UserTelemetryEvent {}

enum TourTelemetryEvent {
    TourStarted = 'Tours.Started',
    TourCompleted = 'Tours.Completed',
    TourEarlyExited = 'Tours.EarlyExited',
}

enum CapacityTelemetryEvent {
    SwitchCapacity = 'Capacity.SwitchCapacity',
    AzureBillingLink = 'Capacity.AzureBillingLink',
    CreateCapacity = 'Capacity.CreateCapacity',
    ApplyCapacity = 'Capacity.ApplyCapacity',
    ChangeCapacity = 'Capacity.ChangeCapacity',
    UpdateCapacity = 'Capacity.UpdateCapacity',
    DeleteCapacity = 'Capacity.DeleteCapacity',
}

enum UserInteractionDataTelemetryEvent {
    UserInfo = 'UserInteractionData.UserInfo',
    Subscriptions = 'UserInteractionData.Subscriptions',
    DataShare = 'UserInteractionData.DataShare',
    Workspace = 'UserInteractionData.Workspace',
    Capacities = 'UserInteractionData.Capacities',
    WorkspaceCapacity = 'UserInteractionData.WorkspaceCapacity',
}

// Create an exportable namespace of telemetry events.
export const MedeinaTelemetryEvent = {
    App: AppTelemetryEvent,
    Evaluations: EvaluationsTelemetryEvent,
    Feedbacks: FeedbacksTelemetryEvent,
    Investigations: InvestigationsTelemetryEvent,
    Promptbooks: PromptbooksTelemetryEvent,
    Prompts: PromptsTelemetryEvent,
    Sessions: SessionsTelemetryEvent,
    Settings: SettingsTelemetryEvent,
    Skills: SkillsTelemetryEvent,
    Unknown: UnknownTelemetryEvent.Unknown,
    User: UserTelemetryEvent,
    Tour: TourTelemetryEvent,
    UserInteractionData: UserInteractionDataTelemetryEvent,
    Capacity: CapacityTelemetryEvent,
};

export type CustomProperties = {
    [key: string]: any;
};

// Create a merged nested enum type for all telemetry event values.
export type AnyMedeinaTelemetryEvent =
    | AppTelemetryEvent
    | EvaluationsTelemetryEvent
    | FeedbacksTelemetryEvent
    | InvestigationsTelemetryEvent
    | PromptbooksTelemetryEvent
    | PromptsTelemetryEvent
    | SessionsTelemetryEvent
    | SettingsTelemetryEvent
    | SkillsTelemetryEvent
    | UnknownTelemetryEvent
    | UserTelemetryEvent
    | TourTelemetryEvent
    | UserInteractionDataTelemetryEvent
    | CapacityTelemetryEvent;
