import {DateDropdownValues} from '../UsageDashboard.constants';
import {DateRange} from '../UsageDashboard.types';

export type DateFilterDropdownProps = {
    className?: string;
    startDate?: Date;
    dateRange: DateRange;
    isVisible: boolean;
    setDateRange: (dateRange: DateRange) => void;
    onOptionChanged: (title: DateFilterChangedProps) => void;
    selectedOptionText: string;
    minSearchStartDate: number;
};

export type DateFilterDropdownV2Props = {
    className?: string;
    isVisible: boolean;
    onOptionChanged: (title: DateFilterChangedProps) => void;
    selectedOptionText: string;
    minSearchStartDate: number;
};

export interface DateFilterChangedProps {
    title: string;
    startDate: Date;
    endDate: Date;
}

export const DateFilterDropdownValues = {
    Last24Hours: DateDropdownValues.LAST_24_HOURS,
    Last3Days: DateDropdownValues.LAST_3_DAYS,
    Last7Days: DateDropdownValues.LAST_7_DAYS,
    LastMonth: DateDropdownValues.LAST_MONTH,
    CustomDateRange: DateDropdownValues.CUSTOM_DATE_RANGE,
};
