import * as React from 'react';
const SVGComponent = (props: any) => (
    <svg
        width={1503}
        height={1873}
        viewBox="0 0 1503 1873"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#filter0_f_1_7)">
            <path
                d="M304.763 1032.08C322.754 882.254 504.204 765.19 1066.28 354.857C1445.45 108.426 935.046 767.857 716.964 983.441C498.882 1199.02 596.53 1525.88 461.697 1569.33C326.863 1612.78 286.771 1181.91 304.763 1032.08Z"
                fill="#296981"
            />
        </g>
        <defs>
            <filter
                id="filter0_f_1_7"
                x={0.709961}
                y={0.526367}
                width={1501.71}
                height={1871.87}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={150} result="effect1_foregroundBlur_1_7" />
            </filter>
        </defs>
    </svg>
);
export default SVGComponent;
