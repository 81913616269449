import {
    TourControls,
    TourStageContainer,
    useTourContext,
    ValidTourErrorPropCombination,
} from '@/components/ui/Tour';
import {
    Combobox,
    Link,
    mergeClasses,
    Option,
    OptionGroup,
    useFocusFinders,
} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useClasses from './CapacitySelection.styles';
import {NestedSquareIcon} from '@/components/ui/icons';
import {useState, useRef, useEffect} from 'react';
import {motion} from 'framer-motion';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {Capacity} from '@/api/capacities/capacities.types';
import {FreStage, useManageTenantInfo} from '@/api/tenant';
import {useTranslation} from 'react-i18next';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import useManageSelectCapacityDetails from './useManageSelectCapacityDetails';
import MedeinaVariables from '@/util/variables';
import SetupInProgress from './SetupInprogress';
import useManageTourTransition from './useManageTourTransition';
import {useNavigate} from 'react-router-dom';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useSetupAndProvisionFidelisConstructs} from '@/api/capacities';
import {useQueryClient} from '@tanstack/react-query';
import {Body, FooterContent, TextH1} from '@/components/ui/Text';

interface CapacityItem {
    key: string;
    value: string;
}

const PROVISIONING_SUCCEEDED_STATE = 'Succeeded';

export default function CapacitySelectionPage() {
    const tour = useTourContext();
    const {setTourErrorV2} = useTourContext();
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const {update: providerUpdate} = useWorkspaceState();
    const queryClient = useQueryClient();

    const [capacityItems, setCapacityItems] = useState<CapacityItem[]>([]);
    const [capacitySelection, setCapacitySelection] = useState<string>('');
    const [capacitiesLoaded, setCapacitiesLoaded] = useState<boolean>(false);

    const capacitySelectionRootRef = useRef<HTMLDivElement>(null);
    const [isProvisioningInProgress, setIsProvisioningInProgress] = useState<boolean>(false);
    const {validatePreSwitchCapacitySetup} = useManageTourTransition();

    const navigate = useNavigate();

    const setupScreenError = (error: ValidTourErrorPropCombination) => {
        setIsProvisioningInProgress(false);
        setTourErrorV2(error);
    };

    const {provisionCapacityLinkage} = useSetupAndProvisionFidelisConstructs({
        capacityName: capacitySelection,
        isNewResourceGroup: false,
        isCapacityProvisioned: true,
        onWorkspaceMappingCompletion: async () => {
            await performPostWorkspaceMappingOperations();
        },

        onWorkspaceMappingError(error: any) {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        },

        onAccountCreationError(error: any) {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        },

        onWorkspaceProvisionError(error: any) {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        },
    });

    const {patchFreMetadata} = useManageTenantInfo({
        onFetchTenantError: (error: any) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        },
    });

    const {
        capacitiesData,
        isCapacitiesError,
        isCapacitiesFetched,
        existingCapacityName,
        isDataLoading,
    } = useManageSelectCapacityDetails({
        onWorkspacesFetchError: (error: any) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        },
        onCapacitiesFetchError: (error: any) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        },
    });

    //Actions on the end of loading capacities
    useEffect(() => {
        if (isCapacitiesFetched) {
            setCapacitiesLoaded(true);
        }
    }, [isCapacitiesFetched]);

    //Action on error of get capacities
    useEffect(() => {
        if (isCapacitiesError) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        }
    }, [isCapacitiesError]);

    //Set Capacities from JSON response
    useEffect(() => {
        if (capacitiesLoaded) {
            setCapacityItems(
                capacitiesData?.value
                    ?.filter(
                        (capacity: Capacity) =>
                            capacity.properties?.provisioningState ===
                                PROVISIONING_SUCCEEDED_STATE && capacity.id !== '',
                    )
                    ?.map((capacity: any) => {
                        return {
                            key: capacity.id,
                            value: capacity.name,
                        };
                    })
                    .sort((x: any) => x.value) || [],
            );
        }
    }, [capacitiesLoaded]);

    useEffect(() => {
        if (!!existingCapacityName && capacityItems.length > 0) {
            setCapacitySelection(existingCapacityName);
        }
    }, [existingCapacityName, capacityItems]);

    const {findFirstFocusable} = useFocusFinders();
    useEffect(() => {
        if (capacitySelectionRootRef?.current) {
            const firstFocusable = findFirstFocusable(capacitySelectionRootRef.current);
            firstFocusable?.focus();
        }
    }, [capacitySelectionRootRef]);

    const getfooterText = (): JSX.Element => {
        const resourceText =
            capacityItems.length === 1
                ? t('adminV2Fre.capacitySelection.ThisWasAzureCreated')
                : t('adminV2Fre.capacitySelection.TheseWereAzureCreated');
        return (
            <>
                {resourceText}&nbsp;
                <Link
                    href={MedeinaVariables.AzurePortalUrl}
                    target="_blank"
                    aria-label={t('adminV2Fre.capacitySelection.ariaLabel.AzurePortalLink')}
                >
                    {commonLabel('AzurePortal')}
                </Link>
            </>
        );
    };

    const performPostWorkspaceMappingOperations = async function () {
        try {
            await patchFreMetadata(FreStage.CapacitySetup);
            setIsProvisioningInProgress(false);
            //Go to the next page on the FRE
            tour.addTourSharedParameter('capacityStep', 'Select');
            tour.setTourStep(TOUR_STEPS.Locations);
        } catch (error) {
            setIsProvisioningInProgress(false);
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        }
    };

    async function onContinueClick() {
        try {
            setIsProvisioningInProgress(true);
            const step = await validatePreSwitchCapacitySetup();
            if (step === TOUR_STEPS.None) {
                await provisionCapacityLinkage();
            } else {
                setIsProvisioningInProgress(false);
                if (step === TOUR_STEPS.HOME) {
                    await providerUpdate();
                    await queryClient.invalidateQueries(['auth', 'getUserInfo']);
                    navigate('/');
                    tour.quitTour();
                } else {
                    tour.setTourStep(step);
                }
            }
        } catch (error) {
            setIsProvisioningInProgress(false);
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.SelectCapacity,
            });
        }
    }

    return (
        <>
            {!isDataLoading && (
                <motion.div
                    key="capacity-selection"
                    variants={overlayAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <TourStageContainer ref={capacitySelectionRootRef}>
                        <TourStageContainer.StageContent>
                            {isProvisioningInProgress ? (
                                <SetupInProgress
                                    heading={t('adminV2Fre.setup.InProgress')}
                                    subHeading={t('adminV2Fre.setup.Polite')}
                                ></SetupInProgress>
                            ) : (
                                <>
                                    <div className={mergeClasses(classes.icon)}>
                                        <NestedSquareIcon></NestedSquareIcon>
                                    </div>
                                    <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                                        <TextH1>{t('adminV2Fre.capacitySelection.Title')}</TextH1>
                                    </div>
                                    <div
                                        className={mergeClasses(
                                            tourClasses.tourXLargeSectionSpacing,
                                        )}
                                    >
                                        <Body>
                                            {t('adminV2Fre.capacitySelection.SupplementaryContent')}
                                        </Body>
                                    </div>
                                    <div
                                        className={mergeClasses(
                                            tourClasses.tourXLargeSectionSpacing,
                                        )}
                                    >
                                        <Combobox
                                            className={mergeClasses(tourClasses.dropDown)}
                                            placeholder={t(
                                                'adminV2Fre.capacitySelection.SelectCapacityPlaceholder',
                                            )}
                                            aria-label={commonLabel('Capacity')}
                                            data-test-id="capacity-dropdown"
                                            onOptionSelect={(ev, data) => {
                                                setCapacitySelection(data.optionValue ?? '');
                                            }}
                                            value={capacitySelection}
                                            selectedOptions={
                                                capacitySelection ? [capacitySelection] : []
                                            }
                                            listbox={{className: classes.listBoxHeight}}
                                        >
                                            <OptionGroup>
                                                {capacityItems
                                                    .sort((itemA, itemB) =>
                                                        itemA.value > itemB.value ? 1 : -1,
                                                    )
                                                    .map(({key, value}: any) => {
                                                        return (
                                                            <Option key={key} value={value}>
                                                                {value}
                                                            </Option>
                                                        );
                                                    })}
                                            </OptionGroup>
                                        </Combobox>
                                    </div>
                                    <div className={tourClasses.tourSectionSpacing}>
                                        <FooterContent>{getfooterText()}</FooterContent>
                                    </div>
                                </>
                            )}
                        </TourStageContainer.StageContent>
                        <TourStageContainer.FooterContent>
                            {!isProvisioningInProgress && (
                                <>
                                    <TourControls
                                        spanFullScreen={true}
                                        nextButton={commonLabel('Continue')}
                                        handleNext={onContinueClick}
                                        isNextButtonDisabled={!capacitySelection}
                                        showPrevious={false}
                                    ></TourControls>
                                </>
                            )}
                        </TourStageContainer.FooterContent>
                    </TourStageContainer>
                </motion.div>
            )}
        </>
    );
}
