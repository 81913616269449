import {Spinner, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {SkillsetAuthType} from '@/api/skills';
import {FailedIcon, SubmittedIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';

interface AdminModalOverlayProps {
    authType: string;
    showLoadingIcon: boolean;
    isDeleteLoading: boolean;
    showSuccessIcon: boolean;
    isAuthSuccess: boolean;
    isDeleteSuccess: boolean;
    showFailureIcon: boolean;
    isAuthError: boolean;
    isOAuthCodeError: boolean;
    isDeleteError: boolean;
    deleteErrorResponse: string;
    errorResponse: string;
    textRef: React.RefObject<any>;
}

export default function AdminModalOverlay({
    authType,
    showLoadingIcon,
    isDeleteLoading,
    showSuccessIcon,
    isAuthSuccess,
    isDeleteSuccess,
    showFailureIcon,
    isAuthError,
    isOAuthCodeError,
    isDeleteError,
    deleteErrorResponse,
    errorResponse,
    textRef,
}: AdminModalOverlayProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <div className={classes.loading}>
            {showLoadingIcon && (
                <Spinner
                    size="small"
                    labelPosition="below"
                    label={
                        authType === SkillsetAuthType.OAuthAuthorizationCodeFlow
                            ? t('Admin.Connecting')
                            : t('Admin.SettingUpPlugin')
                    }
                />
            )}
            {isDeleteLoading && (
                <Spinner size="large" labelPosition="below" label={t('Admin.Resetting')} />
            )}
            {showSuccessIcon && (
                <div className={classes.loading} aria-live="assertive">
                    <SubmittedIcon className={classes.savedButton} filled />
                    <Text
                        className={classes.successText}
                        ref={textRef}
                        aria-live="assertive"
                        tabIndex={0}
                        role="status"
                    >
                        {isAuthSuccess
                            ? t('Admin.SetupComplete')
                            : isDeleteSuccess
                            ? t('common.Reset')
                            : t('common.Saved')}
                    </Text>
                </div>
            )}
            {showFailureIcon && (
                <div className={classes.loading}>
                    <FailedIcon className={classes.failedButton} filled />
                    <Text
                        className={classes.successText}
                        ref={textRef}
                        aria-live="assertive"
                        tabIndex={0}
                        role="status"
                    >
                        {isAuthError || isOAuthCodeError
                            ? t('Admin.FailedToConnect')
                            : isDeleteError
                            ? t('Admin.FailedToResetWith', {error: deleteErrorResponse})
                            : t('Admin.FailedToSaveWith', {error: errorResponse})}
                    </Text>
                </div>
            )}
        </div>
    );
}
