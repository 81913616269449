import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import useClasses from './Markdown.styles';
import components from './components';
import {MarkdownProvider} from './MarkdownContext';
import {CustomMarkdownProps, MarkdownComponentConfiguration} from './Markdown.types';

export default function CustomMarkdown(props: CustomMarkdownProps) {
    const classes = useClasses();

    const markdownConfiguration: MarkdownComponentConfiguration = {
        ...props,
        allowTableDataExport: props?.allowTableDataExport ?? true,
    };

    return (
        <MarkdownProvider {...markdownConfiguration}>
            <div className={classes.root} data-testid="markdown">
                <ReactMarkdown
                    {...props}
                    sourcePos={true}
                    components={components}
                    remarkPlugins={[remarkGfm]}
                >
                    {props.children}
                </ReactMarkdown>
            </div>
        </MarkdownProvider>
    );
}
