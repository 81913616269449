import {useState, useEffect, useMemo} from 'react';
import useClasses from '../ManageWorkspaces.styles';
import {
    TableCellLayout,
    Menu,
    MenuButton,
    MenuTrigger,
    MenuGroup,
    MenuGroupHeader,
    MenuDivider,
    MenuPopover,
    MenuList,
    MenuItem,
    Link,
} from '@fluentui/react-components';
import {AddIcon, OpenIcon, CheckmarkIcon} from '@/components/ui/icons';
import {Capacity} from '@/api/capacities/capacities.types';
import {Workspace} from '@/api/workspaces';
import {useGetCapacityByName, useGetCapacities} from '@/api/capacities';
import {REGION_DATA} from '@/components/App.constants';
import {RegionName} from '@/components/App.types';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import DisconnectCapacityDialog from '../DisconnectCapacityDialog';

interface CapacityColumnProps {
    workspace: Workspace;
    isWorkspacesDataFetched: boolean;
}
export const CapacityColumn: React.FC<CapacityColumnProps> = ({
    workspace,
    isWorkspacesDataFetched,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const {data: capacityData} = useGetCapacityByName(workspace?.capacity?.referenceName || '', {
        enabled: isWorkspacesDataFetched,
        refetchOnMount: true,
    });
    const {data: data} = useGetCapacities({});
    const capacities: {value: Capacity[]} = useMemo(() => data || {value: []}, [data]);

    const capacityDisplayName = REGION_DATA[capacityData?.location as RegionName]?.displayName;
    const [selectedCapacity, setSelectedCapacity] = useState<string>(capacityDisplayName);

    useEffect(() => {
        setSelectedCapacity(capacityDisplayName);
    }, [capacityDisplayName]);
    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);

    const handleMenuItemClick = (capacity: Capacity) => {
        setSelectedCapacity(REGION_DATA[capacity?.location as RegionName]?.displayName);
        setDisconnectCapacityDialogOpen(true);
    };
    let hasError = false;
    if (workspace.name == 'Elm') {
        hasError = true;
    }

    return (
        <>
            <TableCellLayout>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        {hasError ? (
                            <MenuButton
                                data-testid="capacity-menu-button"
                                className={classes.errorMenuButton}
                            >
                                {t('ManageWorkspaces.MenuButtons.NoCapacitySelected')}
                            </MenuButton>
                        ) : (
                            <MenuButton appearance="transparent" data-testid="capacity-menu-button">
                                {selectedCapacity}
                            </MenuButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuGroup>
                                <MenuGroupHeader>
                                    {t('ManageWorkspaces.MenuButtons.CapacitiesYouOwn')}
                                </MenuGroupHeader>
                                {hasError
                                    ? capacities.value.map((capacity: Capacity, index: number) => (
                                          <MenuItem
                                              key={index}
                                              className={classes.errorMenuButton}
                                              onClick={() => handleMenuItemClick(capacity)}
                                          >
                                              {REGION_DATA[capacity.location as RegionName]
                                                  ?.displayName +
                                                  ' ' +
                                                  t(
                                                      'ManageWorkspaces.MenuButtons.NotConnectedError',
                                                  )}
                                          </MenuItem>
                                      ))
                                    : capacities.value.map((capacity: Capacity, index: number) => (
                                          <MenuItem
                                              key={index}
                                              icon={
                                                  REGION_DATA[capacity?.location as RegionName]
                                                      ?.displayName === selectedCapacity ? (
                                                      <CheckmarkIcon />
                                                  ) : (
                                                      <div className={classes.fillerIcon} />
                                                  )
                                              }
                                              onClick={() => handleMenuItemClick(capacity)}
                                          >
                                              {
                                                  REGION_DATA[capacity.location as RegionName]
                                                      ?.displayName
                                              }
                                          </MenuItem>
                                      ))}
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem icon={<AddIcon />} data-testid="new-capacity-menu-item">
                                    {t('ManageWorkspaces.MenuButtons.NewCapacity')}
                                </MenuItem>
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<OpenIcon />}
                                    data-testid="manage-capacities-menu-item"
                                >
                                    <Link
                                        href={MedeinaVariables.ManageCapacityAzureUri}
                                        target="_blank"
                                        aria-label={t(
                                            'ManageWorkspaces.ariaLabel.ManageCapacityAzureUri',
                                        )}
                                        appearance="subtle"
                                    >
                                        {t('ManageWorkspaces.MenuButtons.ManageCapacitiesAzure')}
                                    </Link>
                                </MenuItem>
                            </MenuGroup>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <DisconnectCapacityDialog
                    isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                    newCapacityName={selectedCapacity}
                    workspaceName={workspace.name}
                    onClose={() => {
                        setDisconnectCapacityDialogOpen(false);
                    }}
                />
            </TableCellLayout>
        </>
    );
};
