import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        maxWidth: '500px',
    },
    actionButton: {
        ...shorthands.flex(0, 0, 'auto'),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalMNudge,
        ...shorthands.margin(0, 0, tokens.spacingVerticalXS),
    },
});
