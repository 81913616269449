import {useState, useEffect, useMemo} from 'react';
import Head from 'next/head';
import {
    createDOMRenderer,
    GriffelRenderer,
    RendererProvider,
    TextDirectionProvider,
} from '@griffel/react';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import {EventType, EventMessage, AuthenticationResult} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {
    ApplicationInsights,
    ITelemetryItem,
    ITelemetryPlugin,
} from '@microsoft/1ds-analytics-web-js';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {AppStateProvider} from '@/api/app';
import MedeinaApp from '@/components/App';
import {msalInstance, CustomNavigationClient} from '@/util/msal';
import {AppInsightsProvider, onedsConfig, initializeTelemetry} from '@/util/telemetry';
import MedeinaFeatures from '@/util/features';
import MedeinaVariables from '@/util/variables';
import Routes from './_routes';
import {UserStateProvider} from '@/api/user';
import TelemetryErrorBoundary from '@/util/telemetry/TelemetryErrorBoundary';
import {CookieConsentProvider} from '@/components/ui/CookieConsentProvider/CookieConsentProvider';
import '@/components/i18n';
import {useTranslation} from 'react-i18next';
import {WorkspaceStateProvider} from '@/components/workspaces';
import {clarity} from 'react-microsoft-clarity';
import MswInitializer from '../../msw/App/MswInitializer';
import {WorkspacesProvider} from '@/components/sections/workspaces/WorkspacesProvider';

// Create a placeholder for telemetry.
let appInsights: ApplicationInsights;
if (typeof window !== 'undefined' && MedeinaFeatures.Telemetry) {
    appInsights = new ApplicationInsights();
    appInsights.addTelemetryInitializer(initializeTelemetry);
    appInsights.initialize(onedsConfig, []);
}

// Create API client.
const queryClient = new QueryClient();

// Handle login events.
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

// Safely disable hydration errors, since we're not using SSR.
export function SafeHydrate({children}: {children: React.ReactNode}) {
    // With React 18, we also need to flag that the client is ready.
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setReady(true);
    }, []);

    return (
        <div suppressHydrationWarning>
            {typeof document === 'undefined' || !ready ? null : children}
        </div>
    );
}

export function AppContent() {
    // Integrate MSAL with react-router.
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    msalInstance.setNavigationClient(navigationClient);
    const {t} = useTranslation('common');

    useEffect(() => {
        if (MedeinaFeatures.EnableMSClarity === true) {
            const clarityProjectId = MedeinaVariables.MSClarityId;
            if (clarityProjectId !== '') {
                clarity.init(clarityProjectId);
            }
        }
    }, []);

    return (
        <MsalProvider instance={msalInstance}>
            <Head>
                <title>{t('ApplicationTitle')}</title>
                <meta name="description" content={t('ApplicationTitle')} />
                <link rel="icon" href="/favicon/favicon.ico" />
                <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
                <link rel="manifest" href="/favicon/site.webmanifest" />
                <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#247fe0" />
                <meta name="msapplication-TileColor" content="#00aba9" />
                <meta name="theme-color" content="#ffffff" />
            </Head>
            <CookieConsentProvider>
                <MedeinaApp>
                    <Routes />
                </MedeinaApp>
            </CookieConsentProvider>
        </MsalProvider>
    );
}

export default function NextApp() {
    // Create and cache a Griffel renderer.
    const renderer = useMemo<GriffelRenderer>(() => createDOMRenderer(), []);

    return (
        <SafeHydrate>
            <MswInitializer>
                <div data-clarity-mask="True">
                    <RendererProvider renderer={renderer}>
                        <TelemetryErrorBoundary>
                            <AppInsightsProvider value={appInsights}>
                                <BrowserRouter>
                                    <AppStateProvider>
                                        <QueryClientProvider client={queryClient}>
                                            <UserStateProvider>
                                                <WorkspacesProvider>
                                                    <WorkspaceStateProvider>
                                                        <AppContent />
                                                    </WorkspaceStateProvider>
                                                </WorkspacesProvider>
                                            </UserStateProvider>
                                        </QueryClientProvider>
                                    </AppStateProvider>
                                </BrowserRouter>
                            </AppInsightsProvider>
                        </TelemetryErrorBoundary>
                    </RendererProvider>
                </div>
            </MswInitializer>
        </SafeHydrate>
    );
}
