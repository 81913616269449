import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalM,
        overflowY: 'auto',
        maxWidth: '700px',
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralBackgroundStatic,
            ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: tokens.colorNeutralBackgroundStatic,
        },
    },
    flexcontainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    promptbook: {
        ...shorthands.flex(0, 0, 'auto'),
        //paddingRight: tokens.spacingHorizontalM,
    },
    promptbookBody: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    promptbookIcon: {
        color: tokens.colorBrandForeground1,
        width: tokens.spacingHorizontalXL,
        height: 'auto',
        paddingTop: tokens.spacingVerticalS,
    },
    runDisabled: {
        fontColor: tokens.colorNeutralForegroundDisabled,
    },
    dismissIcon: {
        paddingLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase100,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    visuallyHidden: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        position: 'absolute',
        width: '1px',
        height: '1px',
        marginTop: '-1px',
        paddingTop: '0',
        clip: 'rect(0, 0, 0, 0)',
        ...shorthands.borderTop('0px', 'none', 'none'),
    },
    labelStyle: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left',
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalM,
        ...shorthands.flex(1, 1, 'auto'),
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalL,
    },
    promptTitle: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
    },
    prompts: {
        display: 'flex',
        flexDirection: 'column',
    },
    promptHeader: {},
    textareaWrapper: {
        marginTop: '-10px',
    },
});
