import useGetEvaluation from '@/api/evaluations/useGetEvaluation';
import {ExportEvaluationProps} from './Evaluation.types';
import ExportDocument from '@/util/Export/ExportDocument';
import {useGetSession} from '@/api/sessions';

export default function ExportEvaluator({
    sessionId,
    promptId,
    evaluationId,
    isDisabled,
    isSummaryExport,
    enableCopy = true,
    label,
    className,
}: ExportEvaluationProps) {
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});
    const {data: session} = useGetSession({sessionId: sessionId});
    const defaultFileName = 'Document';

    return (
        <ExportDocument
            evaluation={evaluation?.result?.content ?? ''}
            sessionId={session?.sessionId ?? ''}
            evaluationIds={[evaluationId]}
            investigationTitle={
                isSummaryExport
                    ? session?.name || defaultFileName
                    : evaluation?.promptSnapshot?.content || defaultFileName
            }
            promptTitle={evaluation?.promptSnapshot?.content}
            isDisabled={isDisabled}
            isSummaryExport={isSummaryExport}
            enableCopy={enableCopy}
            label={label}
            className={className}
        />
    );
}
