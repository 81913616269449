import React, {useMemo} from 'react';
import useCrumbClasses from '../Breadcrumbs.styles';
import {useLocation} from 'react-router-dom';
import RecentInvestigationMenu from '@/components/sections/investigations/RecentInvestigationMenu';
import SessionOptionsBreadcrumb from '@/components/sections/investigations/SessionOptionsBreadcrumb';
import {getParamsFromPath} from '../util';

import {MedeinaRoutesMap} from '../../MedeinaRoutes';

interface SessionBreadcrumbProps {
    renderOnlyLast?: boolean;
}

function Session(props: SessionBreadcrumbProps) {
    const {renderOnlyLast} = props;
    const classes = useCrumbClasses();
    const location = useLocation();

    const params = useMemo(
        () => getParamsFromPath(MedeinaRoutesMap.session.path, location.pathname),
        [location],
    );
    return (
        <>
            {!renderOnlyLast && (
                <li className={classes.crumb}>
                    <RecentInvestigationMenu />
                </li>
            )}
            {params?.sessionId && params.sessionId !== 'new' && (
                <>
                    {!renderOnlyLast && (
                        <li role="separator" className={classes.spacer}>
                            /
                        </li>
                    )}
                    <li className={classes.crumb}>
                        <SessionOptionsBreadcrumb sessionId={params.sessionId} />
                    </li>
                </>
            )}
        </>
    );
}

export default Session;
