import {signInErrorHandler} from '@/util/msal/authConfig';
import {useMsal} from '@azure/msal-react';
import {useState, useEffect} from 'react';
import {acquireMsalToken, msalConfig} from '@/util/msal';
import MedeinaVariables from '../variables';

/** utility hook to fetch an ARM token using MSAL authentication */
function useArmToken(customScopes?: string[]): {
    token: string | undefined;
    loading: boolean;
    error: Error | null;
} {
    const {instance, accounts} = useMsal();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (accounts.length > 0) {
            setLoading(true);
            acquireMsalToken(instance, {
                ...msalConfig,
                scopes: customScopes || [`${MedeinaVariables.ArmUri}/.default`],
            })
                .then((token) => {
                    setAccessToken(token);
                    setError(null);
                })
                .catch((err) => {
                    setError(err);
                    setAccessToken(undefined);
                    signInErrorHandler(err, {
                        scopes: customScopes || [`${MedeinaVariables.ArmUri}/.default`],
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [accounts, instance, customScopes]);

    return {token: accessToken, loading, error};
}

export default useArmToken;
