import React, {ReactNode} from 'react';
import {Label} from '@fluentui/react-components';
import {ErrorIcon} from '@/components/ui/icons';
import useClasses from './ErrorLabel.styles';
import {ScreenSize, useViewportSize} from '@/components/ui/Grid';

type ErrorLabelProps = {
    children: ReactNode;
    className?: string;
    id?: string;
    needsResponsiveStyles?: boolean;
};

const ErrorLabel: React.FC<ErrorLabelProps> = ({
    children,
    className,
    id,
    needsResponsiveStyles,
}) => {
    const classes = useClasses();
    const idProp = id ? {id: id} : {};
    const {sm: isSmallScreen} = useViewportSize();

    const labelSize = Boolean(needsResponsiveStyles)
        ? isSmallScreen
            ? 'small'
            : 'medium'
        : 'medium';

    return (
        <Label {...idProp} aria-live="assertive" className={className} size={labelSize}>
            <ErrorIcon className={classes.errorIcon} filled />
            {children}
        </Label>
    );
};

export default ErrorLabel;
