import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {DismissIcon} from '@/components/ui/icons';
import {Button, MenuItem, MenuList, MenuPopover, mergeClasses} from '@fluentui/react-components';
import useClasses from './FilterPills.styles';
import {SearchCategory} from '../UsageDashboard.types';
import {getDisplayValueForCopilotExperience, getUserDisplayValue} from './Filter.utils';
import {SelectedFilterPillAriaLabelsPrefix} from '../UsageDashboard.constants';
import {useTranslation} from 'react-i18next';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
interface PillMenuItemsProps {
    index: number;
    filter: SelectedSearchFacet;
    onFilterRemoval: (category: string, value: string) => void;
    copilotExperienceMap: KeyValueFilterDetails[];
}

export const PillMenuItems: React.FC<PillMenuItemsProps> = (props: PillMenuItemsProps) => {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();

    const {t} = useTranslation('admin');

    return (
        <MenuPopover key={props.index}>
            <MenuList
                key={props.index}
                className={mergeClasses(
                    scrollClasses.colorNeutralBackground1,
                    classes.listOverflow,
                )}
            >
                {props?.filter?.values?.map((value, valueIndex) => (
                    <MenuItem
                        key={valueIndex}
                        className={classes.menuItem}
                        secondaryContent={
                            <Button
                                icon={<DismissIcon />}
                                size="small"
                                appearance="transparent"
                                onClick={() => props.onFilterRemoval(props.filter.category, value)}
                                className={classes.dismissButton}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        props.onFilterRemoval(props.filter.category, value);
                                    }
                                }}
                                aria-label={t(`${SelectedFilterPillAriaLabelsPrefix}.RemoveFilter`)}
                            />
                        }
                    >
                        <div className={classes.nestedMenuContent}>
                            {props.filter.category == SearchCategory.Users
                                ? getUserDisplayValue(value)
                                : props.filter.category == SearchCategory.CopilotExperience
                                ? getDisplayValueForCopilotExperience(
                                      value,
                                      props.copilotExperienceMap,
                                  )
                                : value}
                        </div>
                    </MenuItem>
                ))}
            </MenuList>
        </MenuPopover>
    );
};
