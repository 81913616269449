import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.margin(0, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100%',
        width: '800px',
    },
    prompt: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    footer: {
        ...shorthands.flex(0, 0, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('10px'),
        width: '100%',
    },
    cards: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('10px'),
        width: '100%',
    },
    cardLink: {
        ...shorthands.flex(1, 1, 'auto'),
    },
    card: {
        backgroundColor: tokens.colorNeutralBackground3,
        minHeight: '20vh',
    },
});
