// Shared styles for stage layouts and fonts/type
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    errorIcon: {
        ...shorthands.flex(0, 0, 'auto'),
        color: '#E37D80',
        marginRight: tokens.spacingHorizontalXS,
    },
});
