import * as React from 'react';
const PerseveringFace = (props: any) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_31_1666)">
            <path
                d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
                fill="url(#paint0_radial_31_1666)"
            />
            <path
                d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
                fill="url(#paint1_radial_31_1666)"
            />
            <path
                d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
                fill="url(#paint2_radial_31_1666)"
                fillOpacity={0.6}
            />
            <path
                d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
                fill="url(#paint3_radial_31_1666)"
            />
            <path
                d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
                fill="url(#paint4_radial_31_1666)"
            />
            <path
                d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
                fill="url(#paint5_radial_31_1666)"
            />
            <path
                d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
                fill="url(#paint6_radial_31_1666)"
            />
            <g filter="url(#filter0_f_31_1666)">
                <path
                    d="M9.44746 19.3275C8.95348 19.5745 8.35281 19.3743 8.10582 18.8803C7.85883 18.3863 8.05906 17.7856 8.55303 17.5387L10.7595 16.4354H14.0002C13.9994 16.8133 13.7856 17.1585 13.4475 17.3275L9.44746 19.3275Z"
                    fill="url(#paint7_radial_31_1666)"
                    fillOpacity={0.6}
                    style={{
                        mixBlendMode: 'multiply',
                    }}
                />
                <path
                    d="M9.44746 13.5433C8.95348 13.2963 8.35281 13.4965 8.10582 13.9905C7.85883 14.4845 8.05906 15.0852 8.55303 15.3321L10.7595 16.4354H14.0002C13.9994 16.0575 13.7856 15.7123 13.4475 15.5433L9.44746 13.5433Z"
                    fill="url(#paint8_radial_31_1666)"
                    fillOpacity={0.6}
                    style={{
                        mixBlendMode: 'multiply',
                    }}
                />
            </g>
            <path
                d="M9.44746 18.8898C8.95348 19.1368 8.35281 18.9366 8.10582 18.4426C7.85883 17.9486 8.05906 17.3479 8.55303 17.101L10.7595 15.9977H14.0002C13.9994 16.3756 13.7856 16.7208 13.4475 16.8898L9.44746 18.8898Z"
                fill="url(#paint9_linear_31_1666)"
            />
            <path
                d="M9.44746 13.1058C8.95348 12.8588 8.35281 13.059 8.10582 13.553C7.85883 14.047 8.05906 14.6477 8.55303 14.8946L10.7595 15.9979H14.0002C13.9994 15.62 13.7856 15.2748 13.4475 15.1058L9.44746 13.1058Z"
                fill="url(#paint10_linear_31_1666)"
            />
            <g filter="url(#filter1_f_31_1666)">
                <path
                    d="M21.959 19.3275C22.453 19.5745 23.0537 19.3743 23.3007 18.8803C23.5477 18.3863 23.3474 17.7856 22.8535 17.5387L20.647 16.4354H17.4062C17.4071 16.8133 17.6209 17.1585 17.959 17.3275L21.959 19.3275Z"
                    fill="url(#paint11_radial_31_1666)"
                />
                <path
                    d="M21.959 13.5433C22.453 13.2963 23.0537 13.4965 23.3007 13.9905C23.5477 14.4845 23.3474 15.0852 22.8535 15.3321L20.647 16.4354H17.4062C17.4071 16.0575 17.6209 15.7123 17.959 15.5433L21.959 13.5433Z"
                    fill="url(#paint12_radial_31_1666)"
                />
            </g>
            <path
                d="M22.5372 18.8898C23.0311 19.1368 23.6318 18.9366 23.8788 18.4426C24.1258 17.9486 23.9256 17.3479 23.4316 17.101L21.2251 15.9977H17.9844C17.9852 16.3756 18.1991 16.7208 18.5372 16.8898L22.5372 18.8898Z"
                fill="url(#paint13_linear_31_1666)"
            />
            <path
                d="M22.5372 13.1058C23.0311 12.8588 23.6318 13.059 23.8788 13.553C24.1258 14.047 23.9256 14.6477 23.4316 14.8946L21.2251 15.9979H17.9844C17.9852 15.62 18.1991 15.2748 18.5372 15.1058L22.5372 13.1058Z"
                fill="url(#paint14_linear_31_1666)"
            />
            <path
                d="M10.5 7.5C10.5 7.5 10 10.5 6.5 10.5"
                stroke="url(#paint15_linear_31_1666)"
                strokeWidth={1.25}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 7.5C10.5 7.5 10 10.5 6.5 10.5"
                stroke="url(#paint16_radial_31_1666)"
                strokeWidth={1.25}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.5 7.5C21.5 7.5 22 10.5 25.5 10.5"
                stroke="url(#paint17_radial_31_1666)"
                strokeWidth={1.25}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 23C13 23 14 22 16 22C18 22 19 23 19 23"
                stroke="url(#paint18_linear_31_1666)"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_f_31_1666"
                x={7.5}
                y={12.9375}
                width={7.00024}
                height={6.99585}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.25} result="effect1_foregroundBlur_31_1666" />
            </filter>
            <filter
                id="filter1_f_31_1666"
                x={16.9062}
                y={12.9375}
                width={7.00024}
                height={6.99585}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.25} result="effect1_foregroundBlur_31_1666" />
            </filter>
            <radialGradient
                id="paint0_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25.5 9) rotate(131.878) scale(38.9487)"
            >
                <stop stopColor="#FFF478" />
                <stop offset={0.474827} stopColor="#FFB02E" />
                <stop offset={1} stopColor="#F70A8D" />
            </radialGradient>
            <radialGradient
                id="paint1_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(16 12.5) rotate(101.31) scale(17.8466 22.8581)"
            >
                <stop offset={0.787821} stopColor="#F59639" stopOpacity={0} />
                <stop offset={0.972509} stopColor="#FF7DCE" />
            </radialGradient>
            <radialGradient
                id="paint2_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(18 14) rotate(135) scale(41.0122)"
            >
                <stop offset={0.314853} stopOpacity={0} />
                <stop offset={1} />
            </radialGradient>
            <radialGradient
                id="paint3_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(16 17) rotate(77.692) scale(28.1469)"
            >
                <stop offset={0.507903} stopColor="#7D6133" stopOpacity={0} />
                <stop offset={1} stopColor="#715B32" />
            </radialGradient>
            <radialGradient
                id="paint4_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(16.5 16.5) rotate(55.7131) scale(13.3135 9.65032)"
            >
                <stop stopColor="#FFB849" />
                <stop offset={1} stopColor="#FFB847" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint5_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(20.5 18) rotate(9.86581) scale(11.6726)"
            >
                <stop stopColor="#FFA64B" />
                <stop offset={0.900412} stopColor="#FFAE46" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint6_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(17.5 15) rotate(43.9708) scale(59.0529)"
            >
                <stop offset={0.185425} stopOpacity={0} />
                <stop offset={1} stopOpacity={0.4} />
            </radialGradient>
            <radialGradient
                id="paint7_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(9.78128 13.4375) rotate(110.225) scale(7.59317 9.92428)"
            >
                <stop stopColor="#9F5E0F" />
                <stop offset={0.889024} stopColor="#9F5E0F" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint8_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(9.78128 13.4375) rotate(110.225) scale(7.59317 9.92428)"
            >
                <stop stopColor="#9F5E0F" />
                <stop offset={0.889024} stopColor="#9F5E0F" stopOpacity={0} />
            </radialGradient>
            <linearGradient
                id="paint9_linear_31_1666"
                x1={10.5784}
                y1={19.7321}
                x2={8.00003}
                y2={14.3883}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.24955} stopColor="#482641" />
                <stop offset={0.406361} stopColor="#503A4A" />
                <stop offset={0.511203} stopColor="#482641" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_31_1666"
                x1={8.85962}
                y1={16.1385}
                x2={11.2502}
                y2={11.451}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.24955} stopColor="#482641" />
                <stop offset={0.406361} stopColor="#503A4A" />
                <stop offset={0.511203} stopColor="#482641" />
            </linearGradient>
            <radialGradient
                id="paint11_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(26.8281 20.4375) rotate(-135.995) scale(13.3597 17.4611)"
            >
                <stop stopColor="#E9830A" />
                <stop offset={0.801655} stopColor="#F08B13" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint12_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(26.8281 20.4375) rotate(-135.995) scale(13.3597 17.4611)"
            >
                <stop stopColor="#E9830A" />
                <stop offset={0.801655} stopColor="#F08B13" stopOpacity={0} />
            </radialGradient>
            <linearGradient
                id="paint13_linear_31_1666"
                x1={21.4063}
                y1={19.7321}
                x2={23.9846}
                y2={14.3883}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.24955} stopColor="#482641" />
                <stop offset={0.406361} stopColor="#503A4A" />
                <stop offset={0.511203} stopColor="#482641" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_31_1666"
                x1={23.125}
                y1={16.1385}
                x2={20.7344}
                y2={11.451}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.24955} stopColor="#482641" />
                <stop offset={0.406361} stopColor="#503A4A" />
                <stop offset={0.511203} stopColor="#482641" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_31_1666"
                x1={9.5}
                y1={7}
                x2={9.5}
                y2={8.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.0299084} stopColor="#524049" />
                <stop offset={1} stopColor="#4A2C42" />
            </linearGradient>
            <radialGradient
                id="paint16_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(7 7) rotate(54.4623) scale(4.30116)"
            >
                <stop offset={0.541667} stopColor="#493032" />
                <stop offset={0.806723} stopColor="#5E445A" />
                <stop offset={0.955326} stopColor="#4A2C42" />
            </radialGradient>
            <radialGradient
                id="paint17_radial_31_1666"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25 7) rotate(125.538) scale(4.30116)"
            >
                <stop stopColor="#C2B4B5" />
                <stop offset={0.887405} stopColor="#462B45" />
                <stop offset={1} stopColor="#4A2C42" />
            </radialGradient>
            <linearGradient
                id="paint18_linear_31_1666"
                x1={16}
                y1={20}
                x2={16}
                y2={22}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4F3C43" />
                <stop offset={1} stopColor="#512756" />
            </linearGradient>
            <clipPath id="clip0_31_1666">
                <rect width={32} height={32} fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default PerseveringFace;
