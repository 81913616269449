import {useEffect, useReducer, useState} from 'react';
import {
    Card,
    Caption1,
    Caption1Strong,
    mergeClasses,
    Button,
    Tooltip,
    Body1,
    Spinner,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
} from '@fluentui/react-components';
import {
    DeleteIcon,
    DuplicateIcon,
    EditIcon,
    MoreHorizontalIcon,
    PromptbookDetailsIcon,
    ShareIcon,
} from '@/components/ui/icons';
import {PromptbookIcon, QuestionMarkIcon, SendIcon} from '@/components/ui/icons';
import {
    useGetAllPromptbooks,
    PromptbookDescriptor,
    PromptInputs,
    PromptbookVisibility,
    useGetPromptbookLink,
    useGetPromptbookPermission,
    PromptbookPath,
} from '@/api/promptbooks';
import useClasses from './ExploreCardList.styles';
import ExploreCardList from './ExploreCardList';
import ExploreCardEmpty from './ExploreCardEmpty';
import {ExploreCardProps, PersonaRoleMapping} from './ExploreCopilot.types';
import {useParams} from 'react-router-dom';
import {useMsalUserId} from '@/util/msal';
import PromptbookLibrarySharedForm from '@/components/ui/PromptBar/PromptbookLibrarySharedForm';
import {
    DeletePromptbookDialog,
    PromptbookCreateForm,
    PromptbookOperationMode,
} from '../promptbooks';
import LinkCopiedDialog from '../promptbooks/LinkCopiedDialog';
import ShareNoAccessDialog from '../promptbooks/ShareNoAccessDialog';
import {useNavigate} from 'react-router-dom';
import PromptbookLibraryForm from '@/components/ui/PromptBar/PromptbookLibraryForm';
import {useTranslation} from 'react-i18next';
import useGetSkillsets from '@/api/skills/useGetSkillsets';
import ExploreCardBadge from './ExploreCardBadge';

type PromptbookPluginBadgeProps = {
    plugins: string[];
};

type State = {
    promptbooksList: Array<PromptbookDescriptor>;
    filteredPromptbooks: Array<PromptbookDescriptor>;
};

type Action =
    | {type: 'SET_PROMPTBOOKS'; payload: Array<PromptbookDescriptor>}
    | {type: 'FILTER'; payload: string[][]};

const initialState: State = {
    promptbooksList: [],
    filteredPromptbooks: [],
};

function arraysEqual(a: PromptbookDescriptor[], b: PromptbookDescriptor[]): boolean {
    if (a.length !== b.length) return false;
    return a.every((item, index) => item.promptbookId === b[index].promptbookId);
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_PROMPTBOOKS':
            return {
                ...state,
                promptbooksList: action.payload,
                filteredPromptbooks: action.payload,
            };
        case 'FILTER':
            const [plugins, persona] = action.payload;
            const filteredPromptbooks = state.promptbooksList.filter((promptbook) => {
                const promptPlugins = promptbook.prompts.flatMap((prompt) => prompt.plugins ?? []);
                const promptPersona: string[] =
                    promptbook.validPersonaTypes?.flatMap((type) => {
                        const mappedPersona =
                            PersonaRoleMapping[type as keyof typeof PersonaRoleMapping];
                        return Array(mappedPersona);
                    }) ?? [];

                const matchesPlugins =
                    plugins.length === 0 ||
                    promptPlugins.some((plugin) => plugins.includes(plugin));
                const matchesPersona =
                    persona.length === 0 || promptPersona.some((p) => persona.includes(p));

                return matchesPlugins && matchesPersona;
            });
            if (arraysEqual(state.filteredPromptbooks, filteredPromptbooks)) {
                return state;
            }
            return {
                ...state,
                filteredPromptbooks,
            };
        default:
            return state;
    }
};

const emptyArray: string[] = [];

export default function ExplorePromptbooksLibrary({
    className,
    filter = "(cast(visibility, Edm.String) eq 'Global')",
    search = '',
    plugins = emptyArray,
    persona = emptyArray,
    handleAvailablePlugins = () => {},
}: ExploreCardProps & {
    filter?: string;
    search?: string;
    plugins?: string[];
    persona?: string[];
    handleAvailablePlugins: (availablePlugins: string[]) => void;
}) {
    const classes = useClasses();
    const {t} = useTranslation('common');
    const {t: tPromptbooks} = useTranslation('promptbooks');
    const {data: skillsets, isLoading: isskillsetsLoading} = useGetSkillsets();

    const {promptbookId: propsPromptbookId} = useParams(); // fetch if any promptbookId in the url

    const {
        data: promptbooks,
        isLoading: promptbooksLoading,
        isError: getAllPromptbooksFailed,
        refetch: refetchPromptbooks,
    } = useGetAllPromptbooks({
        filter,
        search,
    });

    const {
        mutate: getPromptbookLink,
        isError: getPromptbookLinkError,
        reset,
        error,
        isLoading: getPromptbookLinkLoading,
    } = useGetPromptbookLink(PromptbookPath.HomeLibrary);

    const {
        data: sharedPromptbook,
        isLoading: sharedPromptbookLoading,
        isError: sharedGetPromptbookFailed,
        error: sharedPromptbookError,
    } = useGetPromptbookPermission({promptbookId: propsPromptbookId});
    const userObjectId = useMsalUserId();
    const navigate = useNavigate();

    const [defaultPromptbook, setDefaultPromptbook] = useState<PromptbookDescriptor | null>(null);
    const [openPromptbookViewDialog, setOpenPromptbookViewDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openPromptbookViewShareDialog, setOpenPromptbookViewShareDialog] =
        useState<boolean>(false);

    const [showLinkToast, setShowLinkToast] = useState<boolean>(false);
    const [showNoAccessDialog, setShowNoAccessDialog] = useState<boolean>(false);

    const startPromptbookLabel = t('tooltips.startPromptbook');

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (promptbooks?.value) {
            dispatch({type: 'SET_PROMPTBOOKS', payload: promptbooks.value});
            dispatch({type: 'FILTER', payload: [plugins, persona]});
        }
        const availablePlugins = promptbooks?.value
            ? Array.from(
                  new Set(
                      promptbooks.value.flatMap((pb) =>
                          pb.prompts
                              .flatMap((p) => p.plugins)
                              .filter((plugin): plugin is string => plugin != null),
                      ),
                  ),
              )
            : [];
        handleAvailablePlugins(availablePlugins);
    }, [promptbooks?.value]);

    useEffect(() => {
        if (plugins || persona) {
            dispatch({type: 'FILTER', payload: [plugins, persona]});
        }
    }, [plugins, persona]);

    const isSelfAuthored = (promptbook: PromptbookDescriptor): boolean => {
        return (
            userObjectId === promptbook?.userId &&
            promptbook.visibility !== PromptbookVisibility.Global
        );
    };

    const isShareable = (promptbook: PromptbookDescriptor): boolean => {
        return (
            userObjectId === promptbook?.userId ||
            promptbook.visibility === PromptbookVisibility.Global ||
            promptbook.visibility === PromptbookVisibility.Tenant
        );
    };

    const handlePromptbookOperationClicked = (promptbook: PromptbookDescriptor) => {
        setDefaultPromptbook(promptbook);
    };

    useEffect(() => {
        if (sharedPromptbook) setDefaultPromptbook(sharedPromptbook);
    }, [sharedPromptbook]);

    useEffect(() => {
        if (propsPromptbookId) {
            if (
                sharedPromptbookLoading ||
                (sharedGetPromptbookFailed && !sharedPromptbookLoading)
            ) {
                // render unauthorized modal
                setShowNoAccessDialog(true);
            } else if (sharedPromptbook?.visibility === PromptbookVisibility.Private) {
                setShowNoAccessDialog(false);
                if (sharedPromptbook?.userId !== userObjectId) {
                    // if shared by other user
                    setOpenPromptbookViewShareDialog(true);
                } // own private promptbook
                else {
                    setOpenPromptbookViewDialog(true);
                }
            } else if (
                sharedPromptbook?.visibility === PromptbookVisibility.Global ||
                sharedPromptbook?.visibility === PromptbookVisibility.Tenant
            ) {
                setShowNoAccessDialog(false);
                setOpenPromptbookViewDialog(true);
            }
        }
    }, [propsPromptbookId, sharedPromptbook, sharedGetPromptbookFailed, sharedPromptbookLoading]);

    const handleShareClicked = (promptbook: PromptbookDescriptor) => {
        handlePromptbookOperationClicked(promptbook);
        getPromptbookLink(promptbook, {
            onSuccess: () => {
                setShowLinkToast(true);
            },
        });
    };

    const returnToHomeUrl = () => {
        navigate(`/`);
    };

    const PromptbookPluginBadge = ({plugins}: PromptbookPluginBadgeProps) => {
        const uniquePlugins = Array.from(new Set(plugins));

        if (!uniquePlugins || uniquePlugins.length === 0) {
            return <span></span>;
        }

        const skillset = skillsets?.value?.find((skillset) => skillset.name === uniquePlugins[0]);
        const foundPlugin = {
            skillset: skillset?.name || '',
            icon: skillset?.icon || '',
            displayName: skillset?.displayName || '',
        };

        return <ExploreCardBadge className={className} key={skillset?.name} plugin={foundPlugin} />;
    };

    const conditionalClass =
        className && className in classes ? classes[className as keyof typeof classes] : '';

    return (
        <div className={conditionalClass} data-testid="PromptbookLibraryContainer">
            {promptbooksLoading && (
                <div className={classes.loadingbox}>
                    <Spinner labelPosition="below" label={t('homeLibrary.loadingPromptbooks')} />
                </div>
            )}
            {!promptbooksLoading && state.filteredPromptbooks?.length === 0 && (
                <div className={classes.emptybox}>
                    <QuestionMarkIcon />
                    <Body1 className={classes.notFoundBody}>{t('homeLibrary.noPromptbooks')}</Body1>
                </div>
            )}
            {showLinkToast && (
                <LinkCopiedDialog
                    open={showLinkToast}
                    onClose={() => setShowLinkToast(false)}
                ></LinkCopiedDialog>
            )}
            {showNoAccessDialog && (
                <ShareNoAccessDialog
                    errorStatus={(sharedPromptbookError as any)?.response?.status}
                    isLoading={sharedPromptbookLoading}
                    open={showNoAccessDialog}
                    onClose={() => {
                        setShowNoAccessDialog(false);
                        returnToHomeUrl();
                    }}
                ></ShareNoAccessDialog>
            )}
            {!promptbooksLoading &&
                state.filteredPromptbooks &&
                state.filteredPromptbooks.length > 0 && (
                    <ExploreCardList>
                        {state.filteredPromptbooks?.map((promptbook, index) =>
                            promptbook ? (
                                <Card
                                    className={mergeClasses(classes.card, classes.promptbookCard)}
                                    focusMode="off"
                                    key={index}
                                    aria-label={`${t('homeLibrary.viewPromptbook')}: ${
                                        promptbook.name
                                    }`}
                                    data-testid="promptbook-card"
                                    tabIndex={-1}
                                >
                                    <div
                                        className={mergeClasses(
                                            classes.cardContent,
                                            classes.promptbookCardContent,
                                        )}
                                    >
                                        <div className={classes.cardHeader}>
                                            <PromptbookIcon className={classes.promptbookBadge} />
                                            <Caption1Strong
                                                block
                                                truncate
                                                wrap={false}
                                                title={promptbook.name}
                                                className={classes.cardTitle}
                                                data-testid="promptbook-title"
                                                onClick={() => {
                                                    setDefaultPromptbook(promptbook);
                                                    setOpenPromptbookViewDialog(true);
                                                }}
                                                onKeyDown={(
                                                    event: React.KeyboardEvent<HTMLDivElement>,
                                                ) => {
                                                    if (event.key === 'Enter') {
                                                        event.preventDefault();
                                                        setDefaultPromptbook(promptbook);
                                                        setOpenPromptbookViewDialog(true);
                                                    }
                                                }}
                                                tabIndex={0}
                                            >
                                                {promptbook.name}
                                            </Caption1Strong>
                                        </div>
                                        <Caption1 className={classes.sequenceLabel}>
                                            {promptbook.prompts.length + '-prompt sequence'}
                                        </Caption1>
                                        <Caption1 className={classes.cardDescription}>
                                            {promptbook.description}
                                        </Caption1>
                                    </div>
                                    <div className={mergeClasses(classes.cardFooter)}>
                                        <div className={classes.footerActions}>
                                            <Tooltip
                                                content={`${startPromptbookLabel}: ${promptbook.name}`}
                                                relationship="label"
                                            >
                                                <Button
                                                    aria-label={`${startPromptbookLabel}: ${promptbook.name}`}
                                                    appearance="primary"
                                                    size="medium"
                                                    icon={<SendIcon />}
                                                    data-testid="promptbook-run-button"
                                                    onClick={() =>
                                                        navigate(
                                                            `/sessions/new/${promptbook?.promptbookId}`,
                                                        )
                                                    }
                                                >
                                                    Get Started
                                                </Button>
                                            </Tooltip>
                                            <Menu>
                                                <MenuTrigger disableButtonEnhancement>
                                                    <Button
                                                        data-testid="options-button"
                                                        icon={<MoreHorizontalIcon />}
                                                        appearance="subtle"
                                                        aria-label={t('PromptbookOptions')}
                                                    />
                                                </MenuTrigger>

                                                <MenuPopover>
                                                    <MenuList>
                                                        <MenuItem
                                                            data-testid="view-button"
                                                            icon={<PromptbookDetailsIcon />}
                                                            onClick={() => {
                                                                handlePromptbookOperationClicked(
                                                                    promptbook,
                                                                );
                                                                setOpenPromptbookViewDialog(true);
                                                            }}
                                                        >
                                                            {t('ButtonLabels.Details')}
                                                        </MenuItem>
                                                        <MenuItem
                                                            data-testid="duplicate-button"
                                                            icon={<DuplicateIcon />}
                                                            onClick={() => {
                                                                handlePromptbookOperationClicked(
                                                                    promptbook,
                                                                );
                                                                setOpenDuplicateDialog(true);
                                                            }}
                                                        >
                                                            {t('ButtonLabels.Duplicate')}
                                                        </MenuItem>
                                                        {isShareable(promptbook) && (
                                                            <>
                                                                <MenuItem
                                                                    data-testid="share-button"
                                                                    icon={<ShareIcon />}
                                                                    onClick={() =>
                                                                        handleShareClicked(
                                                                            promptbook,
                                                                        )
                                                                    }
                                                                >
                                                                    {tPromptbooks(
                                                                        'SharePromptbookButton',
                                                                    )}
                                                                </MenuItem>
                                                            </>
                                                        )}
                                                        {isSelfAuthored(promptbook) && (
                                                            <>
                                                                <MenuItem
                                                                    data-testid="edit-button"
                                                                    icon={<EditIcon />}
                                                                    onClick={() => {
                                                                        handlePromptbookOperationClicked(
                                                                            promptbook,
                                                                        );
                                                                        setOpenEditDialog(true);
                                                                    }}
                                                                >
                                                                    {t('ButtonLabels.Edit')}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    data-testid="delete-button"
                                                                    icon={<DeleteIcon />}
                                                                    onClick={() => {
                                                                        handlePromptbookOperationClicked(
                                                                            promptbook,
                                                                        );
                                                                        setOpenDeleteDialog(true);
                                                                    }}
                                                                >
                                                                    {t('ButtonLabels.Delete')}
                                                                </MenuItem>
                                                            </>
                                                        )}
                                                    </MenuList>
                                                </MenuPopover>
                                            </Menu>
                                        </div>
                                        <PromptbookPluginBadge
                                            plugins={promptbook.prompts?.flatMap(
                                                (prompt) => prompt.plugins ?? [],
                                            )}
                                        />
                                    </div>
                                </Card>
                            ) : (
                                <ExploreCardEmpty key={index} />
                            ),
                        )}
                        {defaultPromptbook && openPromptbookViewDialog && (
                            <div>
                                <PromptbookLibraryForm
                                    promptbooksPath={PromptbookPath.HomeLibrary}
                                    promptbook={defaultPromptbook!}
                                    promptbookInputs={defaultPromptbook?.promptbookinputs.reduce(
                                        (acc, input) => {
                                            const {name, description} = input;
                                            acc[name] = description;
                                            return acc;
                                        },
                                        {} as PromptInputs,
                                    )}
                                    onCancel={() => setOpenPromptbookViewDialog(false)}
                                    onSubmit={() => setOpenPromptbookViewDialog(false)}
                                    open={openPromptbookViewDialog}
                                />
                            </div>
                        )}

                        {defaultPromptbook && openPromptbookViewShareDialog && (
                            <div>
                                <PromptbookLibrarySharedForm
                                    promptbook={defaultPromptbook!}
                                    promptbookInputs={defaultPromptbook?.promptbookinputs.reduce(
                                        (acc, input) => {
                                            const {name, description} = input;
                                            acc[name] = description;
                                            return acc;
                                        },
                                        {} as PromptInputs,
                                    )}
                                    onCancel={() => {
                                        setOpenPromptbookViewShareDialog(false);
                                        returnToHomeUrl();
                                    }}
                                    onSubmit={() => {
                                        setOpenPromptbookViewShareDialog(false);
                                        returnToHomeUrl();
                                    }}
                                    open={openPromptbookViewShareDialog}
                                    key={'PromptbookLibrarySharedForm'}
                                />
                            </div>
                        )}
                        {defaultPromptbook && openDeleteDialog && (
                            <div>
                                <DeletePromptbookDialog
                                    {...{
                                        promptbook: defaultPromptbook,
                                        promptbookId: defaultPromptbook?.promptbookId,
                                        promptbookIds: [],
                                    }}
                                    open={openDeleteDialog}
                                    onClose={() => {
                                        setOpenDeleteDialog(false);
                                    }}
                                    onSuccess={() => {
                                        setOpenDeleteDialog(false);
                                        refetchPromptbooks();
                                    }}
                                />
                            </div>
                        )}
                        {defaultPromptbook && openDuplicateDialog && (
                            <div>
                                <PromptbookCreateForm
                                    promptbook={defaultPromptbook}
                                    mode={PromptbookOperationMode.Duplicate}
                                    open={openDuplicateDialog}
                                    onClose={() => {
                                        setOpenDuplicateDialog(false);
                                    }}
                                    onSuccess={() => {
                                        setOpenDuplicateDialog(false);
                                        refetchPromptbooks();
                                    }}
                                    sessionId={''}
                                    promptIds={[]}
                                    promptbookId={''}
                                />
                            </div>
                        )}
                        {defaultPromptbook && openEditDialog && (
                            <div>
                                <PromptbookCreateForm
                                    promptbook={defaultPromptbook}
                                    mode={PromptbookOperationMode.Edit}
                                    open={openEditDialog}
                                    onClose={() => {
                                        setOpenEditDialog(false);
                                    }}
                                    onSuccess={() => {
                                        setOpenEditDialog(false);
                                        refetchPromptbooks();
                                    }}
                                    sessionId={''}
                                    promptIds={[]}
                                    promptbookId={''}
                                    key={'PromptbookEditDialog'}
                                />
                            </div>
                        )}
                    </ExploreCardList>
                )}
        </div>
    );
}
