import {useEffect, useState} from 'react';
import {ErrorMessageBarProps} from './AddRoles';

export enum AddRolesMessages {
    NO_MESSAGE = 'noMessage',
    GENERIC = 'generic',
    UPDATE_ERROR = 'errorWhileUpdating',
}

const ADD_ROLE_MESSAGE_MAP: Record<AddRolesMessages, Partial<ErrorMessageBarProps>> = {
    [AddRolesMessages.GENERIC]: {
        title: 'RoleAssignment.AddRoles.GenericError.Title',
        message: 'RoleAssignment.AddRoles.GenericError.Message',
        messageType: 'error',
    },
    [AddRolesMessages.UPDATE_ERROR]: {
        title: 'RoleAssignment.AddRoles.UpdateError.Title',
        messageType: 'error',
    },
    [AddRolesMessages.NO_MESSAGE]: {},
};

export function useAddRolesErrorHanding(existingError: AddRolesMessages) {
    const [errorMessage, setErrorMessage] = useState<ErrorMessageBarProps>();

    // This useEffect is used to update the error message when the error state changes from any module
    // that generates an error
    useEffect(() => {
        if (existingError === AddRolesMessages.NO_MESSAGE) {
            resetError();
            return;
        }

        !!existingError && generateErrorMessage(existingError as AddRolesMessages);
    }, [existingError]);

    const generateErrorMessage = (
        messageType: AddRolesMessages,
        customization: Partial<ErrorMessageBarProps> = {},
    ) => {
        const message = ADD_ROLE_MESSAGE_MAP[messageType] as ErrorMessageBarProps;
        setErrorMessage({...message, ...customization});
    };

    const resetError = () => {
        setErrorMessage(ADD_ROLE_MESSAGE_MAP[AddRolesMessages.NO_MESSAGE] as ErrorMessageBarProps);
    };

    return {
        errorMessage,
        generateErrorMessage,
        resetError,
    };
}
