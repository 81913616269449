import {useQuery} from '@tanstack/react-query';
import {PageResponse} from '../api';
import useSessionsFetch from '../useSessionsFetch';
import {GetPromptsRequest, Prompt} from './prompts.types';

export default function useGetPrompts({sessionId, lastPromptId}: GetPromptsRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);
    return useQuery({
        queryKey: lastPromptId
            ? ['sessions', sessionId, 'prompts', `lastPromptId=${lastPromptId}`]
            : ['sessions', sessionId, 'prompts'],
        queryFn: async () => {
            return sessionsFetch<PageResponse<Prompt>>(
                `${sessionId}/prompts${lastPromptId ? `?lastPromptId=${lastPromptId}` : ''}`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
