import {GetAllTopSkillsRequest, SkillDescriptor} from './skills.types';
import useGetAllSkills from './useGetAllSkills';
import useGetSkillsets from './useGetSkillsets';
import {useMemo} from 'react';

export default function useGetAllTopSkills({skillsets}: GetAllTopSkillsRequest = {}) {
    // Retrieve a list of skillsets, for when skillsets are undefined.
    const {
        data: allSkillsets,
        isLoading: isSkillsetsLoading,
        error: skillsetsError,
    } = useGetSkillsets();
    const availableSkillsets = useMemo<string[]>(() => {
        return (
            skillsets ??
            allSkillsets?.value
                .filter((skillset) => skillset.enabled)
                .map((skillset) => skillset.name) ??
            []
        );
    }, [skillsets, allSkillsets?.value]);

    // Retrieve a list of all skills (will be permanently cached).
    const {
        data: allSkills,
        isLoading: isAllSkillsLoading,
        error: allSkillsError,
    } = useGetAllSkills();
    // Limit available skills to the selected skillsets.
    const availableSkills = useMemo<SkillDescriptor[]>(() => {
        if (allSkills?.value) {
            return allSkills?.value
                .filter((skill) => skill.skillset && availableSkillsets?.includes(skill.skillset))
                .filter((skill) => !!skill.displayName)
                .sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
        return [];
    }, [allSkills?.value]);

    return {
        data: availableSkills,
        loading: isSkillsetsLoading || isAllSkillsLoading,
        error: skillsetsError ?? allSkillsError,
    };
}
