import {createDarkTheme} from '@fluentui/react-components';
import {Neutrals, Brand, themeBrand} from '../colors';
import overrides from './overrides';
import {MedeinaTheme} from '../theme.types';

const theme = createDarkTheme(themeBrand);

const darkTheme: MedeinaTheme = {
    ...theme,
    ...overrides,

    // OVERLAY
    colorBackgroundOverlay: Neutrals.blackAlpha[50],

    // BRAND BACKGROUND
    colorBrandBackground: Brand.shade30,
    colorBrandBackgroundHover: Brand.shade20,
    colorBrandBackgroundPressed: Brand.shade40,
    colorBrandBackgroundSelected: Brand.shade10,

    colorCompoundBrandBackground: Brand.tint30,
    colorCompoundBrandBackgroundHover: Brand.tint40,
    colorCompoundBrandBackgroundPressed: Brand.tint20,

    colorBrandBackground2: Brand.tint30,
    colorBrandBackground2Hover: Brand.tint40,
    colorBrandBackground2Pressed: Brand.tint20,

    colorBrandBackgroundInverted: Neutrals.white,
    colorBrandBackgroundInvertedHover: Brand.tint30,
    colorBrandBackgroundInvertedPressed: Brand.tint20,
    colorBrandBackgroundInvertedSelected: Brand.tint10,
    colorBrandBackgroundStatic: Brand.shade20,

    // BRAND FOREGROUND
    colorBrandForeground1: Brand.tint30,

    colorBrandForeground2: Brand.tint30,
    colorBrandForeground2Hover: Brand.tint40,
    colorBrandForeground2Pressed: Brand.tint20,

    colorBrandForegroundInverted: Brand.tint30,
    colorBrandForegroundInvertedHover: Brand.tint40,
    colorBrandForegroundInvertedPressed: Brand.tint20,

    colorBrandForegroundLink: Brand.tint30,
    colorBrandForegroundLinkHover: Brand.tint40,
    colorBrandForegroundLinkPressed: Brand.tint20,
    colorBrandForegroundLinkSelected: Brand.tint10,

    colorCompoundBrandForeground1: Brand.tint30,
    colorCompoundBrandForeground1Hover: Brand.tint40,
    colorCompoundBrandForeground1Pressed: Brand.tint20,

    colorBrandForegroundOnLight: Brand.shade30,
    colorBrandForegroundOnLightHover: Brand.shade20,
    colorBrandForegroundOnLightPressed: Brand.shade40,
    colorBrandForegroundOnLightSelected: Brand.shade10,

    // BRAND OTHER
    colorBrandStroke1: Brand.tint30,
    colorBrandStroke2: Brand.shade30,

    colorCompoundBrandStroke: Brand.tint30,
    colorCompoundBrandStrokeHover: Brand.tint40,
    colorCompoundBrandStrokePressed: Brand.tint40,

    // BACKGROUNDS
    colorNeutralBackground1: Neutrals.shade30,
    colorNeutralBackground1Hover: Neutrals.shade20,
    colorNeutralBackground1Pressed: Neutrals.shade40,
    colorNeutralBackground1Selected: Neutrals.primary,

    colorNeutralBackground2: Neutrals.shade40,
    colorNeutralBackground2Hover: Neutrals.shade30,
    colorNeutralBackground2Pressed: Neutrals.shade50,
    colorNeutralBackground2Selected: Neutrals.shade10,

    colorNeutralBackground3: Neutrals.shade50,
    colorNeutralBackground3Hover: Neutrals.shade40,
    colorNeutralBackground3Pressed: Neutrals.black,
    colorNeutralBackground3Selected: Neutrals.shade20,

    colorNeutralBackground4: Neutrals.shade50,
    colorNeutralBackground4Hover: Neutrals.shade40,
    colorNeutralBackground4Pressed: Neutrals.black,
    colorNeutralBackground4Selected: Neutrals.shade20,

    colorNeutralBackground5: Neutrals.shade20,
    colorNeutralBackground5Hover: Neutrals.shade10,
    colorNeutralBackground5Pressed: Neutrals.shade30,
    colorNeutralBackground5Selected: Neutrals.tint10,

    colorNeutralBackground6: Neutrals.primary,

    // SUBTLE BACKGROUNDS
    colorSubtleBackground: Neutrals.greyAlpha[0],
    colorSubtleBackgroundHover: Neutrals.tint20,
    colorSubtleBackgroundPressed: Neutrals.tint10,
    colorSubtleBackgroundSelected: Neutrals.primary,

    colorSubtleBackgroundLightAlphaHover: Neutrals.greyAlpha[80],
    colorSubtleBackgroundLightAlphaPressed: Neutrals.greyAlpha[50],
    colorSubtleBackgroundLightAlphaSelected: Neutrals.greyAlpha[0],

    colorSubtleBackgroundInverted: Neutrals.blackAlpha[0],
    colorSubtleBackgroundInvertedHover: Neutrals.blackAlpha[10],
    colorSubtleBackgroundInvertedPressed: Neutrals.blackAlpha[30],
    colorSubtleBackgroundInvertedSelected: Neutrals.blackAlpha[20],

    colorNeutralBackgroundDisabled: Neutrals.shade40,
    colorNeutralBackgroundInverted: Neutrals.white,
    colorNeutralBackgroundInvertedDisabled: Neutrals.whiteAlpha[10],
    colorNeutralBackgroundStatic: Neutrals.tint10,

    colorNeutralBackgroundAlpha: Neutrals.blackAlpha[40],
    colorNeutralBackgroundAlpha2: Neutrals.lightGreyAlpha[20],

    // FOREGROUNDS
    colorNeutralForeground1: Neutrals.white,
    colorNeutralForeground1Hover: Neutrals.white,
    colorNeutralForeground1Pressed: Neutrals.white,
    colorNeutralForeground1Selected: Neutrals.white,
    colorNeutralForeground1Static: Neutrals.primary,

    colorNeutralForeground2: Neutrals.tint60,
    colorNeutralForeground2Hover: Neutrals.white,
    colorNeutralForeground2Pressed: Neutrals.white,
    colorNeutralForeground2Selected: Neutrals.white,
    colorNeutralForeground2BrandHover: Brand.tint30,
    colorNeutralForeground2BrandPressed: Brand.tint20,
    colorNeutralForeground2BrandSelected: Brand.tint30,

    colorNeutralForeground2Link: Neutrals.tint60,
    colorNeutralForeground2LinkHover: Neutrals.white,
    colorNeutralForeground2LinkPressed: Neutrals.white,
    colorNeutralForeground2LinkSelected: Neutrals.white,

    colorNeutralForeground3: Neutrals.tint50,
    colorNeutralForeground3Hover: Neutrals.tint60,
    colorNeutralForeground3Pressed: Neutrals.tint60,
    colorNeutralForeground3Selected: Neutrals.tint60,
    colorNeutralForeground3BrandHover: Brand.tint30,
    colorNeutralForeground3BrandPressed: Brand.tint20,
    colorNeutralForeground3BrandSelected: Brand.tint30,

    colorNeutralForeground4: Neutrals.tint40,

    colorNeutralForegroundDisabled: Neutrals.tint20,
    colorNeutralForegroundInverted: Neutrals.primary,
    colorNeutralForegroundInvertedHover: Neutrals.primary,
    colorNeutralForegroundInvertedPressed: Neutrals.primary,
    colorNeutralForegroundInvertedSelected: Neutrals.primary,

    colorNeutralForegroundInvertedLink: Neutrals.white,
    colorNeutralForegroundInvertedLinkHover: Neutrals.white,
    colorNeutralForegroundInvertedLinkPressed: Neutrals.white,
    colorNeutralForegroundInvertedLinkSelected: Neutrals.white,

    colorNeutralForegroundOnBrand: Neutrals.white,

    colorNeutralStencil1: Neutrals.shade30,
    colorNeutralStencil2: Neutrals.shade20,

    // STROKES
    colorNeutralStroke1: Neutrals.tint10,
    colorNeutralStroke1Hover: Neutrals.tint20,
    colorNeutralStroke1Pressed: Neutrals.primary,
    colorNeutralStroke1Selected: Neutrals.tint20,
    colorNeutralStroke2: Neutrals.shade20,
    colorNeutralStroke3: Neutrals.shade10,

    colorNeutralStrokeAccessible: Neutrals.tint50,
    colorNeutralStrokeAccessibleHover: Neutrals.tint60,
    colorNeutralStrokeAccessiblePressed: Neutrals.tint40,
    colorNeutralStrokeAccessibleSelected: Brand.tint30,
    colorNeutralStrokeDisabled: Neutrals.shade40,
    colorNeutralStrokeInvertedDisabled: Neutrals.whiteAlpha[40],

    colorNeutralStrokeOnBrand: Neutrals.shade20,
    colorNeutralStrokeOnBrand2: Neutrals.white,
    colorNeutralStrokeOnBrand2Hover: Neutrals.white,
    colorNeutralStrokeOnBrand2Pressed: Neutrals.white,
    colorNeutralStrokeOnBrand2Selected: Neutrals.white,

    colorScrollbarOverlay: Neutrals.whiteAlpha[60],
    colorStrokeFocus1: Neutrals.black,
    colorStrokeFocus2: Neutrals.white,

    colorTransparentBackground: Neutrals.greyAlpha[0],
    colorTransparentBackgroundHover: Neutrals.greyAlpha[0],
    colorTransparentBackgroundPressed: Neutrals.greyAlpha[0],
    colorTransparentBackgroundSelected: Neutrals.greyAlpha[0],
    colorTransparentStroke: Neutrals.greyAlpha[0],
    colorTransparentStrokeDisabled: Neutrals.greyAlpha[0],
    colorTransparentStrokeInteractive: Neutrals.greyAlpha[0],

    //Security Sources

    securitySourcesBackground: Neutrals.shade10,
    securitySourcesBackgroundHover: Neutrals.primary,
    securitySourcesBackgroundPressed: Neutrals.shade20,

    // LEFTOVER STUFF
    // colorBrandShadowAmbient: '#xxxxxx',
    // colorBrandShadowKey: '#xxxxxx',
    // colorNeutralShadowAmbient: '#xxxxxx',
    // colorNeutralShadowAmbientDarker: '#xxxxxx',
    // colorNeutralShadowAmbientLighter: '#xxxxxx',
    // colorNeutralShadowKey: '#xxxxxx',
    // colorNeutralShadowKeyDarker: '#xxxxxx',
    // colorNeutralShadowKeyLighter: '#xxxxxx',
    // colorNeutralStencil1Alpha: '#xxxxxx',
    // colorNeutralStencil2Alpha: '#xxxxxx',
    // colorNeutralForegroundInvertedDisabled: '#xxxxxx',
    // colorNeutralForegroundInverted2: '#xxxxxx',
    // colorNeutralForegroundStaticInverted: '#xxxxxx',
};

export default darkTheme;
