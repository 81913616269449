import {makeStyles, tokens} from '@fluentui/react-components';

export const useClasses = makeStyles({
    resizeBar: {
        width: tokens.strokeWidthThickest,
        height: '100%',
        backgroundColor: tokens.colorTransparentBackground,
        position: 'absolute',
        zIndex: 1,
    },
    visible: {
        backgroundColor: tokens.colorBrandForeground2,
        cursor: 'ew-resize',
    },
});
