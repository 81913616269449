import {Button} from '@fluentui/react-components';
import useClasses from './ErrorDisplay.styles';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {QueryKey} from '@tanstack/react-query';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';

export function ErrorDisplay() {
    const queryClient = useQueryClient();
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const {workspaceName} = useWorkspaceState();
    const refreshPage = () => {
        queryClient.refetchQueries({queryKey: ['rbac', workspaceName]});
    };
    return (
        <div className={classes.root}>
            <div className={classes.headerText}>{t('RoleAssignment.ErrorDisplay.Title')}</div>
            <div className={classes.subtitle}>{t('RoleAssignment.ErrorDisplay.Subtitle')}</div>
            <Button onClick={refreshPage} appearance="primary">
                {t('RoleAssignment.ErrorDisplay.Refresh')}
            </Button>
        </div>
    );
}
