import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    dialogContentSection: {
        marginBottom: '36px',
        marginTop: tokens.spacingVerticalS,
    },
    resourceLinksContainer: {
        lineHeight: tokens.lineHeightBase400,
        fontSize: '16px',
        fontWeight: tokens.fontWeightRegular,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        textAlign: 'left',
        marginTop: '36px',

        '& > div': {
            marginBottom: tokens.spacingVerticalXS,
            display: 'flex',
            flexDirection: 'row',
            '& span:first-of-type': {
                width: '180px',
            },
        },
        backgroundColor: tokens.colorNeutralBackground4,
        ...shorthands.padding('28px'),
    },
    azureResouceLinks: {
        paddingBottom: tokens.spacingVerticalS,
        fontWeight: tokens.fontWeightSemibold,
    },
    icon: {
        scale: 1.5,
        top: '3px',
    },
});
