import {useMutation, useQueryClient} from '@tanstack/react-query';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import {DeleteWorkspaceRequest} from './workspaces.types';
import securityGraphApi from '@/api/securityGraph.api';

/**
 * Hook to delete a workspace
 * TODO: finish implementation, currently not used until multiworkspaces
 * @returns GetWorkspaceByNameResponse
 */
export default function useDeleteWorkspace() {
    const queryClient = useQueryClient();

    const getUrl = (workspaceName: string) =>
        securityGraphApi({
            path: `/account/workspaces/${workspaceName}`,
            version: '2023-12-01-preview',
        });

    return useMutation({
        mutationFn: async (request: DeleteWorkspaceRequest) => {
            try {
                return await customFetch<Response>(
                    getUrl(request.name),
                    {
                        method: 'DELETE',
                        body: request,
                        scopes: RESOURCE_SCOPES.FIDELIS,
                    },
                    false,
                );
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.removeQueries(['workspaces']);
            queryClient.removeQueries(['workspaces', variables.name]);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
