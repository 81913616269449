import * as React from 'react';
const FaceNerd = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path
                fill="url(#f1428id0)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f1428id1)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f1428id2)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f1428id3)"
                fillOpacity=".6"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f1428id4)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f1428id5)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f1428id6)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f1428id7)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path fill="url(#f1428id8)" d="M23 20c0 3.87-3.13 7-7 7s-7-3.13-7-7h14Z" />
            <path fill="url(#f1428idd)" d="M13 20h6v.5a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V20Z" />
            <path
                fill="#fff"
                d="M9.5 18a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9Zm13 0a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9Z"
            />
            <path
                fill="#402A32"
                fillRule="evenodd"
                d="M10.5 11a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5Zm11 0a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f1428ide)"
                fillRule="evenodd"
                d="M1 13a1 1 0 0 1 1-1h2.5v2H2a1 1 0 0 1-1-1Zm28.913 1H27.5v-2h2.413c.598 0 1.087.45 1.087 1s-.49 1-1.087 1ZM14 12h4v2h-4v-2Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f1428id9)"
                fillRule="evenodd"
                d="M9.5 9a4.5 4.5 0 1 0 0 9a4.5 4.5 0 0 0 0-9ZM4 13.5a5.5 5.5 0 1 1 11 0a5.5 5.5 0 0 1-11 0Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f1428ida)"
                fillRule="evenodd"
                d="M22.5 9.007a4.493 4.493 0 1 0 0 8.986a4.493 4.493 0 0 0 0-8.986ZM17 13.5a5.5 5.5 0 1 1 11 0a5.5 5.5 0 0 1-11 0Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f1428idb)"
                fillRule="evenodd"
                d="M9.5 9a4.5 4.5 0 1 0 0 9a4.5 4.5 0 0 0 0-9ZM4 13.5a5.5 5.5 0 1 1 11 0a5.5 5.5 0 0 1-11 0Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f1428idc)"
                fillRule="evenodd"
                d="M22.5 9a4.5 4.5 0 1 0 0 9a4.5 4.5 0 0 0 0-9ZM17 13.5a5.5 5.5 0 1 1 11 0a5.5 5.5 0 0 1-11 0Z"
                clipRule="evenodd"
            />
            <defs>
                <radialGradient
                    id="f1428id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(132.839 10.786 10.065) scale(37.5033)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f1428id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.878 10.74 10.193) scale(38.9487)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f1428id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(101.31 2.876 12.808) scale(17.8466 22.8581)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".788" stopColor="#F59639" stopOpacity="0" />
                    <stop offset=".973" stopColor="#FF7DCE" />
                </radialGradient>
                <radialGradient
                    id="f1428id3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-29 29 -29 -29 18 14)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".315" stopOpacity="0" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="f1428id4"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(77.692 -2.555 18.434) scale(28.1469)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".508" stopColor="#7D6133" stopOpacity="0" />
                    <stop offset="1" stopColor="#715B32" />
                </radialGradient>
                <radialGradient
                    id="f1428id5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(7.5 10.99996 -7.97335 5.4364 16.5 16.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB849" />
                    <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f1428id6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(11.49998 2 -2 11.49998 20.5 18)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA64B" />
                    <stop offset=".9" stopColor="#FFAE46" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f1428id7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(43.971 -9.827 29.173) scale(59.0529)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".185" stopOpacity="0" />
                    <stop offset="1" stopOpacity=".4" />
                </radialGradient>
                <radialGradient
                    id="f1428id8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(0 -9.55556 19.1111 0 16 28.556)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F70A8D" />
                    <stop offset="1" stopColor="#89029C" />
                </radialGradient>
                <radialGradient
                    id="f1428id9"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.186 4.004 8.45) scale(10.6301)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#54424A" />
                    <stop offset="1" stopColor="#52394D" />
                </radialGradient>
                <radialGradient
                    id="f1428ida"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.186 10.504 11.399) scale(10.6301)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#54424A" />
                    <stop offset="1" stopColor="#52394D" />
                </radialGradient>
                <radialGradient
                    id="f1428idb"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-6 .99997 -.99997 -6 10 13.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".899" stopColor="#3B2131" stopOpacity="0" />
                    <stop offset="1" stopColor="#422833" />
                </radialGradient>
                <radialGradient
                    id="f1428idc"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-6 .99997 -.99997 -6 23 13.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".899" stopColor="#3B2131" stopOpacity="0" />
                    <stop offset="1" stopColor="#422833" />
                </radialGradient>
                <linearGradient
                    id="f1428idd"
                    x1="16"
                    x2="16"
                    y1="20"
                    y2="21.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CEA4F3" />
                    <stop offset=".28" stopColor="#ECCDFF" />
                    <stop offset="1" stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="f1428ide"
                    x1="16"
                    x2="16"
                    y1="12"
                    y2="14"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5F5253" />
                    <stop offset="1" stopColor="#4D2C4F" />
                </linearGradient>
            </defs>
        </g>
    </svg>
);
export default FaceNerd;
