import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'grid',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    field: {
        marginBottom: tokens.spacingVerticalXXXL,
    },
    label: {
        display: 'block',
        marginBottom: tokens.spacingVerticalMNudge,
    },
    warningLabel: {
        display: 'block',
        color: tokens.colorStatusDangerForeground3,
        marginTop: tokens.spacingVerticalMNudge,
    },
    hidden: {
        display: 'none',
    },
    firstHeader: {
        marginTop: tokens.spacingVerticalSNudge,
    },
    dropdownSelector: {
        minWidth: '65%',
    },
    btnSearchStart: {
        ...shorthands.borderRadius('50%'),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
    },
    btnSearchHidden: {
        display: 'none',
    },
    btnUpload: {
        ...shorthands.border('none'),
    },
    fileActionBar: {
        display: 'inline-flex',
    },
    subText: {
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase500,
    },
    subTextWrapper: {
        marginLeft: tokens.spacingHorizontalM,
        marginRight: tokens.spacingHorizontalM,
    },
    popoverSearch: {
        display: 'flex',
        marginTop: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalM,
        marginRight: tokens.spacingHorizontalM,
        marginBottom: tokens.spacingVerticalS,
        justifyContent: 'space-between',

        '@media (max-width: 479px)': {
            flexDirection: 'column',
            marginBottom: tokens.spacingVerticalM,
        },
    },
    blockDisplay: {
        display: 'block',
    },
    popoverTop: {
        marginLeft: tokens.spacingHorizontalM,
        marginTop: tokens.spacingVerticalNone,
        marginBottom: tokens.spacingVerticalS,
    },
    popoverTopText: {
        fontSize: '16px',
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: '18px',
    },
    hrule: {
        ...shorthands.borderRadius('50%'),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
        pointerEvents: 'none',
        ...shorthands.padding(tokens.borderRadiusNone),
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,
    },
    categoryRow: {
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground1),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge, tokens.borderRadiusXLarge, 0, 0),
        marginLeft: tokens.spacingHorizontalM,
        marginRight: tokens.spacingHorizontalM,
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground5,
        paddingLeft: tokens.spacingHorizontalL,
        paddingRight: tokens.spacingHorizontalL,
        paddingTop: tokens.spacingVerticalM,
        paddingBottom: tokens.spacingVerticalS,

        '@media (max-width: 479px)': {
            marginLeft: '0',
            marginRight: '0',
        },
    },
    categoryTextCol: {
        width: '86%',
        marginTop: tokens.spacingVerticalXS,
    },
    categoryNameText: {
        fontSize: tokens.fontSizeBase400,
        color: tokens.colorNeutralForeground3,
        fontWeight: tokens.fontWeightSemibold,
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    skillsetAddCol: {
        width: '5%',
        marginRight: tokens.spacingHorizontalS,
        marginTop: tokens.spacingVerticalXXS,
    },
    noFilesText: {
        marginLeft: tokens.spacingHorizontalM,
        marginRight: tokens.spacingHorizontalM,
        display: 'flex',
        paddingLeft: tokens.spacingHorizontalL,
        paddingRight: tokens.spacingHorizontalL,
        paddingTop: tokens.spacingVerticalM,
        paddingBottom: tokens.spacingVerticalS,
    },
    switch: {
        fontWeight: 'bold',
        display: 'flex',
        marginRight: tokens.spacingHorizontalS,
    },
    // Via https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
    // and https://www.a11yproject.com/posts/how-to-hide-content/
    visuallyHidden: {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '1px',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
    },
    searchbar: {
        width: '272px',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXS),
    },
    narrowSearchbar: {
        width: '260px',
    },
    errorBar: {
        backgroundColor: tokens.colorStatusDangerBackground1, // Replace with the actual token for dark red
        color: tokens.colorNeutralForeground1, // Replace with the actual token for white
        position: 'relative',
    },
    cancelledIcon: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        ...shorthands.borderRadius('50%'),
        fontSize: '18px',
        position: 'absolute',
        left: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: tokens.colorPaletteRedForeground2,
        color: '#000000',
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    btnCancel: {
        ...shorthands.borderRadius('50%'),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            backgroundColor: tokens.colorTransparentBackground, // Remove the hover background color
            color: 'inherit', // Inherit the text color on hover
        },
    },
    emptySearchText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
        marginBottom: tokens.spacingVerticalM,
        textAlign: 'center',
    },
    emptySearchText2: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground4,
        textAlign: 'center',
        textOverflow: 'wrap',
    },
    emptyCategory: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground1),
        ...shorthands.borderRadius(0, 0, tokens.borderRadiusXLarge, tokens.borderRadiusXLarge),
        marginLeft: tokens.spacingHorizontalM,
        marginRight: tokens.spacingHorizontalM,
        backgroundColor: tokens.colorNeutralBackground5,
        paddingTop: tokens.spacingVerticalXXL,
        paddingLeft: '15%',
        paddingRight: '15%',
        paddingBottom: tokens.spacingVerticalXXL,
    },
});
