import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {PageResponse} from '../api';
import {PromptSuggestions, GetStarterPromtsRequest} from './prompts.types';

// Cache for 10 minutes.
const CACHE_TIME = 10 * 60 * 1000;

export default function useGetStarterPrompts(body: GetStarterPromtsRequest = {}) {
    const {customFetch} = useFetch();
    return useQuery({
        // Cache _per unique key_.
        // Typically this would only need to be refetched when a user or tenant creates a promptbook.
        // This is helpful for promptbar menus, where it would otherwise frequently refetch.
        refetchOnWindowFocus: false,

        queryKey: [
            'prompt-suggestions/personas',
            `${body?.personas ?? ''}&${body?.skillsets ?? ''}`,
        ],
        queryFn: async () => {
            const params = [
                body.personas ? `personasJson=${JSON.stringify(body.personas)}` : ``,
                body.skillsets ? `skillsetsJson=${JSON.stringify(body.skillsets)}` : ``,
            ] as string[];

            const querystringParam = params.filter((param) => !!param).join('&');
            return await customFetch<PageResponse<PromptSuggestions>>(
                `/prompt-suggestions/personas${querystringParam ? `?${querystringParam}` : ''}`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
