import React, {useRef} from 'react';
import {useClasses} from './SidePanel.styles';
import {motion, useDragControls} from 'framer-motion';
import {SidePanelProps} from './SidePanel.types';
import {useLayout} from '@/components/ui/Layout';
import {useViewportSize} from './ui/Grid';

function SidePanel(props: SidePanelProps) {
    const classes = useClasses();
    const constraintsRef = useRef(null);
    const {sidePanel} = useLayout();
    const {width} = sidePanel;
    const {sm: isSmallScreen} = useViewportSize();

    return (
        <>
            <aside
                data-testid="sidepanel"
                ref={constraintsRef}
                className={classes.root}
                style={{width: isSmallScreen ? '100%' : `${width}%`}}
            >
                {props.children}
            </aside>
        </>
    );
}

export default SidePanel;
