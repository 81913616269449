import {FileIndexingStatus, FileUploadRequest} from '@/api/files';
import {ErrorFileIcon, InProgressFileIcon, PdfIcon, SuccessFileIcon} from '@/components/ui/icons';
import {PresenceBadgeStatus} from '@fluentui/react-components';

export function objectToFormData(request: FileUploadRequest): FormData {
    const formData = new FormData();
    for (const key in request) {
        if (request.hasOwnProperty(key)) {
            const value = request[key as keyof FileUploadRequest];
            if (value) {
                if (key === 'fileContent') {
                    formData.append(key, value as File);
                } else {
                    formData.append(key, value.toString());
                }
            }
        }
    }
    return formData;
}
export function getFileIcon(indexingStatus: FileIndexingStatus): JSX.Element {
    switch (indexingStatus) {
        case FileIndexingStatus.Indexed:
            return <SuccessFileIcon />;
        case FileIndexingStatus.Indexing:
            return <InProgressFileIcon />;
        case FileIndexingStatus.NotIndexed:
            return <InProgressFileIcon />;
        case FileIndexingStatus.Failed:
            return <ErrorFileIcon />;
        case FileIndexingStatus.NotSet:
            return <InProgressFileIcon />;
        default:
            return <InProgressFileIcon />;
    }
}
