import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: 'black',
        width: 'calc(100vw)',
        height: 'calc(100vh)',
        position: 'fixed',
        top: '0',
        left: '0',
    },
    shape1: {
        top: 'calc(10vh)',
        left: 'calc(20vw)',
        position: 'absolute',
        opacity: '30%',
        mixBlendMode: 'normal',
        backgroundColor: 'transparent',
        transformY: '50%',
        transformX: '50%',
    },
    shape2: {
        bottom: 'calc(-60vh)',
        right: 'calc(-20vw)',
        position: 'absolute',
        opacity: '30%',
        mixBlendMode: 'normal',
        backgroundColor: 'transparent',
        transformY: '50%',
        transformX: '50%',
    },
    shape3: {
        top: '-20%',
        left: '-10%',
        position: 'absolute',
        opacity: '1',
        mixBlendMode: 'normal',
        backgroundColor: 'transparent',
        transformY: '50%',
        transformX: '50%',
    },
    shape4: {
        top: '0%',
        left: '20%',
        position: 'absolute',
        opacity: '90%',
        mixBlendMode: 'normal',
        backgroundColor: 'transparent',
        transformY: '50%',
        transformX: '50%',
    },
    shape5: {
        top: 'calc(-90vh)',
        left: 'calc(11vw)',
        position: 'absolute',
        opacity: 1,
        mixBlendMode: 'normal',
        backgroundColor: 'transparent',
        width: '50%',
        height: '50%',
        transformY: '50%',
        transformX: '50%',
        // minWidth: "1896px",
        // minHeight: "1809px"
    },
    fluidShape: {
        height: 'auto',
        minHeight: '60%',
        minWidth: '100%',
    },
});
