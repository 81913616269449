import React, {useEffect, useState} from 'react';
import {BackIcon, NextIcon} from '../../icons';
import useClasses from './Pagination.styles';
import {PaginationProps, PaginationType} from './Pagination.types';
import PaginationButton from './PaginationButton';
import PageNumberDisplayLayout from './PageNumberDisplayLayout';
import PageSelectionLayout from './PageSelectionLayout';
import usePaginationValidation from './usePaginationValidation';
import {useFocusableGroup} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {DEFAULT_PAGE_NUMBER} from '@/components/sections/admin/UsageDashboard/UsageDashboard.constants';

export default function Pagination(props: PaginationProps) {
    const {t} = useTranslation('common');

    const DEFAULT_PAGE_SIZE = 50;
    const DEFAULT_MAX_PAGE_NUMBER_DISPLAY = 6;

    const [overrideDisableNextButton, setOverrideDisableNextButton] = useState(false);

    const {
        pageSize = DEFAULT_PAGE_SIZE,
        maxPageNumberDisplay = DEFAULT_MAX_PAGE_NUMBER_DISPLAY,
        children,
        mode = PaginationType.NumberSelection,
    } = props;

    const {isErrorState} = usePaginationValidation({
        ...props,
        pageSize,
        maxPageNumberDisplay,
        mode,
    });

    const classes = useClasses();

    const [currentPage, setCurrentPage] = useState(
        props.overridenPageNumber ?? DEFAULT_PAGE_NUMBER,
    );

    useEffect(() => {
        if (props.resetPagination) {
            setCurrentPage(DEFAULT_PAGE_NUMBER);
        }
    }, [props.resetPagination]);

    const totalPages = Math.ceil((props.recordCount ?? 0) / pageSize);
    const isNextButtonDisabled = currentPage === totalPages || !!overrideDisableNextButton;

    //Focus management
    const attributes = useFocusableGroup({tabBehavior: 'limited'});
    return (
        <>
            {!isErrorState && !props.hidden && (
                <div
                    className={classes.root}
                    data-testid="pagination-component"
                    {...attributes}
                    tabIndex={0}
                >
                    <span className={classes.buttonContainer}>
                        <PaginationButton
                            aria-label={t('Pagination.previousPage')}
                            tabIndex={0}
                            buttonDirection="left"
                            onClick={() => {
                                setCurrentPage((currentPage) => currentPage - 1);
                                props.onPageChange?.(currentPage - 1);
                            }}
                            disabled={currentPage === 1 || props.disabled}
                            icon={<BackIcon />}
                        ></PaginationButton>
                    </span>
                    <span>
                        {mode === PaginationType.NumberDisplay && (
                            <PageNumberDisplayLayout
                                totalPageCount={totalPages}
                                activePage={currentPage}
                                onPageChange={(data) => {
                                    setCurrentPage(data);
                                    props.onPageChange?.(data);
                                }}
                                maxPageNumberDisplay={maxPageNumberDisplay}
                                onMaxPageLimitEvaluated={() => setOverrideDisableNextButton(true)}
                                enableNextButton={() => setOverrideDisableNextButton(false)}
                                disabled={props.disabled}
                            ></PageNumberDisplayLayout>
                        )}
                        {mode === PaginationType.NumberSelection && (
                            <PageSelectionLayout
                                activePage={currentPage}
                                totalPageCount={totalPages}
                                onPageChange={(data) => {
                                    setCurrentPage(data);
                                    props.onPageChange?.(data);
                                }}
                                disabled={props.disabled}
                            ></PageSelectionLayout>
                        )}
                    </span>
                    <span className={classes.buttonContainer}>
                        <PaginationButton
                            aria-label={t('Pagination.nextPage')}
                            tabIndex={0}
                            buttonDirection="right"
                            onClick={() => {
                                setCurrentPage((currentPage) => currentPage + 1);
                                props.onPageChange?.(currentPage + 1);
                            }}
                            disabled={isNextButtonDisabled || props.disabled}
                            icon={<NextIcon />}
                        ></PaginationButton>
                    </span>
                    <div>{children}</div>
                </div>
            )}
        </>
    );
}
