import {Button, Image, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {BasicAuthIcon, OAuthIcon} from '@/components/ui/icons';
import {SkillsetAuthType} from '@/api/skills';
import {AuthDescriptionForType, AuthTitleForType} from '../Utils/AdminConfigAuthMappings';
import {useTranslation} from 'react-i18next';

interface AuthSelectionModalProps {
    selectedSkillset: string | null;
    supportedAuthTypes: string[];
    icon: string | undefined;
    onAuthTypeSelected: (authOption: string) => void;
}

export default function AuthSelectionModal({
    selectedSkillset,
    supportedAuthTypes,
    icon,
    onAuthTypeSelected,
}: AuthSelectionModalProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <div className={classes.authModal}>
            <div className={classes.authSelect}>
                <Image
                    className={classes.connectionIcon}
                    height={64}
                    width={64}
                    fit="default"
                    src={icon}
                    alt={''}
                    aria-hidden
                />
                <div className={classes.authFrame}>
                    <div className={classes.authText}>
                        <Text className={classes.authTitleText}>{t('Admin.HowToConnect')}</Text>
                        <Text className={classes.authSubText}>
                            {t('Admin.SelectAuthMethod', {pluginName: selectedSkillset})}
                        </Text>
                    </div>
                    {supportedAuthTypes.map((option, index) => (
                        <div key={option}>
                            <Button
                                autoFocus={index == 0}
                                className={classes.authCompoundButton}
                                onClick={() => onAuthTypeSelected(option)}
                            >
                                <div className={classes.authCompountButtonBase}>
                                    <div className={classes.authIconPlaceholder}>
                                        {option == SkillsetAuthType.OAuthAuthorizationCodeFlow ? (
                                            <OAuthIcon className={classes.authIcon} />
                                        ) : (
                                            <BasicAuthIcon className={classes.authIcon} />
                                        )}
                                    </div>
                                    <div className={classes.authButtonTextContainer}>
                                        <Text className={classes.authButtonText}>
                                            {t(AuthTitleForType(option))}
                                        </Text>
                                        <br />
                                        <Text className={classes.authSubButtonText}>
                                            {t(AuthDescriptionForType(option))}
                                        </Text>
                                    </div>
                                </div>
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
