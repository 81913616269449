import {useEffect, useState} from 'react';
import useClasses from './PromptbookFilterBar.styles';
import {
    Button,
    Label,
    Menu,
    MenuButton,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowItem,
    ToggleButton,
    useIsOverflowItemVisible,
    useOverflowMenu,
    mergeClasses,
} from '@fluentui/react-components';
import {DismissIcon} from '@/components/ui/icons';
import {
    PromptbookAvailableFilterValue,
    PromptbookFilterValueType,
} from './PromptbookAdvancedFilters.type';

import {
    PromptbookFilterBarOverflowMenuItemProps,
    PromptbookFilterBarOverflowProps,
    PromptbookFilterBarProps,
} from './PromptbookFilterBar.type';
import {
    Apps16Filled,
    AppsFilled,
    Dismiss16Filled,
    Tag16Filled,
    TagFilled,
} from '@fluentui/react-icons';
import {useTranslation} from 'react-i18next';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';

// PromptbookFilterBar Component displays the selected filters. It also provides the functionality to remove the selected filters.
// This component is supplied with only the list of selected filters and the callback to remove the selected filters.
// When a filter is removed, the callback is called with the updated list of selected filters and in the parent component, the filters are updated and the state varialble promptbookselectedfilters is update as the input data is refetched again.
const PromptbookFilterBar = (props: PromptbookFilterBarProps) => {
    const classes = useClasses();
    const {t} = useTranslation('promptbooks');
    const [promptbookselectedfilters, setPromptbookselectedfilters] = useState<
        PromptbookAvailableFilterValue[]
    >(props.formData);

    useEffect(() => {
        setPromptbookselectedfilters(props.formData);
    }, [props.formData]);

    const handleFilterDismissed = (
        promptbookAvailableFilterValue: PromptbookAvailableFilterValue,
    ) => {
        let promptbookselectedfilterslocal = promptbookselectedfilters;
        let dismissedItem = promptbookselectedfilterslocal.find(
            (pb) =>
                pb.FilterValueId === promptbookAvailableFilterValue.FilterValueId &&
                pb.FilterValueType === promptbookAvailableFilterValue.FilterValueType,
        );

        if (dismissedItem) {
            dismissedItem.IsSelected = false;
        }
        props.onAvailableSelectedFiltersChange?.(
            promptbookselectedfilterslocal.filter((x) => x.IsSelected),
        );
    };

    return (
        <div
            className={mergeClasses(
                classes.container,
                props.isPromptbookFilterBar ? undefined : classes.advancedFilterContainer,
            )}
        >
            {promptbookselectedfilters && promptbookselectedfilters.length > 0 && (
                <Overflow
                    minimumVisible={props.minimumVisibleFilters}
                    overflowAxis="horizontal"
                    key={'Overflow' + promptbookselectedfilters.length}
                >
                    <div
                        className={mergeClasses(
                            classes.tagsContainer,
                            props.isPromptbookFilterBar
                                ? undefined
                                : classes.advancedFilterTagsContainer,
                        )}
                    >
                        {promptbookselectedfilters.map((item: PromptbookAvailableFilterValue) => (
                            <OverflowItem
                                key={item.FilterValueId}
                                id={item.FilterValueId.toString()}
                                data-testid={'OverflowItem-' + item.FilterValueId.toString()}
                            >
                                <div>
                                    <ToggleButton
                                        className={classes.toggleButton}
                                        checked={true}
                                        aria-label={item.FilterValueName}
                                        data-testid={
                                            'CustomFilterChip-' + item.FilterValueId.toString()
                                        }
                                        icon={
                                            item.FilterValueType ===
                                            PromptbookFilterValueType.Tag ? (
                                                <TagFilled />
                                            ) : (
                                                <AppsFilled />
                                            )
                                        }
                                        iconPosition="before"
                                        shape="circular"
                                    >
                                        <div className={classes.toggleButtonItems}>
                                            {item.FilterValueName}
                                            <Button
                                                onClick={() => handleFilterDismissed(item)}
                                                size="small"
                                                shape="circular"
                                                appearance="transparent"
                                                icon={<DismissIcon />}
                                                data-testid={
                                                    'DismissFilter-' + item.FilterValueId.toString()
                                                }
                                                aria-label={t('ClearNamedFilter', {
                                                    filterName: item.FilterValueName,
                                                })}
                                            ></Button>
                                        </div>
                                    </ToggleButton>
                                </div>
                            </OverflowItem>
                        ))}
                        <OverflowMenu
                            key={'OverflowMenu' + promptbookselectedfilters.length}
                            onFilterDismissed={handleFilterDismissed}
                            promptbookItems={promptbookselectedfilters}
                        />
                    </div>
                </Overflow>
            )}
        </div>
    );
};

const OverflowMenuItem = (props: PromptbookFilterBarOverflowMenuItemProps) => {
    const item = props.promptbookItem;
    const isVisible = useIsOverflowItemVisible(props.promptbookItem.FilterValueId.toString());
    const classes = useClasses();
    const {t} = useTranslation('promptbooks');
    if (isVisible) {
        return null;
    }

    return (
        <OverflowItem
            key={item.FilterValueId}
            id={item.FilterValueId.toString()}
            data-testid={'OverflowItem-' + item.FilterValueId.toString()}
        >
            <div className={classes.popoverMenuItems}>
                <div className={classes.popoverleftMenuitemsdisplay}>
                    {item.FilterValueType === PromptbookFilterValueType.Tag ? (
                        <Tag16Filled className={classes.popoverleftMenuicondisplay} />
                    ) : (
                        <Apps16Filled className={classes.popoverleftMenuicondisplay} />
                    )}
                    <Label onClick={() => props.onFilterDismissed(item)}>
                        {item.FilterValueName}
                    </Label>
                </div>
                <Button
                    icon={<Dismiss16Filled />}
                    className={classes.popoverrightMenuitemsdisplay}
                    appearance="transparent"
                    onClick={() => props.onFilterDismissed(item)}
                    data-testid={'DismissFilter-' + item.FilterValueId.toString()}
                    aria-label={t('ClearNamedFilter', {
                        filterName: item.FilterValueName,
                    })}
                ></Button>
            </div>
        </OverflowItem>
    );
};

const OverflowMenu = (props: PromptbookFilterBarOverflowProps) => {
    const {ref, overflowCount, isOverflowing} = useOverflowMenu<HTMLButtonElement>();
    const {t} = useTranslation('common');
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <MenuButton shape="circular" ref={ref}>
                    {t('MoreCount', {count: overflowCount})}
                </MenuButton>
            </MenuTrigger>

            <MenuPopover
                className={mergeClasses(
                    classes.scrollableContainer,
                    scrollClasses.colorNeutralBackground1,
                )}
            >
                <MenuList>
                    {props.promptbookItems.map((item) => {
                        return (
                            <OverflowMenuItem
                                key={'OverflowMenuItem-' + item.FilterValueId.toString()}
                                promptbookItem={item}
                                onFilterDismissed={props.onFilterDismissed}
                            />
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export default PromptbookFilterBar;
