const tourOptions = {
    name: 'adminPlugins',
    telemetryName: 'adminPlugins',
};

export const tourStopNames = {
    home: 'home',
    openModal: 'openModal',
    setUpPlugin: 'setUpPlugin',
    addPlugin: 'addPlugin',
};

export default tourOptions;
