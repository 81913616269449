import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CreateSessionWithPromptbookRequest, useCreateSessionWithPrompt} from '@/api/sessions';
import {
    MessageBar,
    MessageBarBody,
    MessageBarActions,
    Link,
    Button,
} from '@fluentui/react-components';
import {PromptBar, PromptBarSubmitOptions} from '@/components/ui';
import {DismissIcon} from '@/components/ui/icons';
import useClasses from './Home.styles';
import {SkillDescriptor} from '@/api/skills';
import useGetUserDetails from '@/api/user/useGetUserDetails';
import {subtractHoursFromDate} from '@/util/subtractHoursFromDate';
import React from 'react';
import {useFeatureFlag, useUserState} from '@/api/user';
import useCreateSessionWithPromptbook from '@/api/sessions/useCreateSessionWithPromptbook';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {useLayout} from '@/components/ui/Layout';
import {BackgroundAnimation, BackgroundImage} from '@/components/ui/Background';
import {getErrorMessage, getErrorMessageWorkspaces} from '@/api/errors';
import {featureFlags} from '@/util/hasStaticFeatureFlags';
import {ErrorMessage} from '@/api/errors.types';
import {useTranslation} from 'react-i18next';
import MedeinaFeatures from '@/util/features';

export default function Home() {
    const classes = useClasses();
    const navigate = useNavigate();
    const {background} = useLayout();
    const {t} = useTranslation('common');
    const [errMessage, setErrMessage] = useState<ErrorMessage | undefined>(undefined);
    const [defaultPromptSkill, setDefaultPromptSkill] = useState<SkillDescriptor | undefined>(
        undefined,
    );
    const {mutate: createSessionWithPrompt, isError, reset, error} = useCreateSessionWithPrompt();
    const {
        mutate: createSessionWithPromptbook,
        isLoading: promptbookLoading,
        isSuccess: promptbookApplySuccess,
    } = useCreateSessionWithPromptbook();

    const params = new URLSearchParams(window.location.search);
    const featureFlagsParams = featureFlags();
    const skillsetsParams = params.get('skillsets');

    // To check if daily tip needs to be shown or not
    const {data: userDetails} = useGetUserDetails();
    const {toggleDailyTip} = useUserState();

    useEffect(() => {
        background?.setImage?.(BackgroundImage.triangles);
        background?.setAnimation?.(BackgroundAnimation.bottomGradient);

        return () => {
            background?.setImage?.(BackgroundImage.none);
            background?.setAnimation?.(BackgroundAnimation.none);
        };
    }, [background]);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    useEffect(() => {
        if (isError) {
            if (isWorkspacesTestingEnabled) {
                var message = getErrorMessageWorkspaces((error as any).response.status);
                setErrMessage(message);
            } else {
                var message = getErrorMessage((error as any).response.status);
                setErrMessage(message);
            }
        }
    }, [isError]);

    // Create a new session with its first interaction on submit.
    const onSubmit = useCallback(
        ({skillsets, featureFlags, ...prompt}: PromptBarSubmitOptions) => {
            // Before prompt creation, check if user is new/inactive and set showDailyTip accordingly.
            toggleDailyTip(
                !!userDetails?.lastPromptExecutedAt
                    ? new Date(userDetails?.lastPromptExecutedAt) <
                          subtractHoursFromDate(new Date(), 12)
                    : true,
            );
            // creating session with prompt only for cases when it is not promptbook
            if (!prompt.promptbookId) {
                createSessionWithPrompt(
                    {skillsets, featureFlags, prompt},
                    {
                        onSuccess: (session) => {
                            // Only include params if they're defined.
                            const params = [
                                skillsetsParams && `skillsets=${skillsetsParams}`,
                                featureFlagsParams && `featureFlags=${featureFlagsParams}`,
                            ].filter((param) => !!param);

                            // Push a URL change to go to the session.
                            navigate(
                                `/sessions/${session.sessionId}${
                                    params ? `?${params.join('&')}` : ''
                                }`,
                            );
                        },
                    },
                );
            } else {
                // creating the body for the promptbook apply.
                const promptbook: CreateSessionWithPromptbookRequest = {
                    content: prompt.content,
                    promptbook: {
                        promptbookId: prompt.promptbookId,
                        overriddenPrompts: prompt.promptbookPrompts,
                        inputs: prompt.inputs,
                    },
                    skillsets: skillsets,
                    featureFlags: featureFlags,
                };

                createSessionWithPromptbook(promptbook, {
                    onSuccess: (session) => {
                        // Only include params if they're defined.
                        const params = [
                            skillsetsParams && `skillsets=${skillsetsParams}`,
                            featureFlagsParams && `featureFlags=${featureFlagsParams}`,
                        ].filter((param) => !!param);

                        // Push a URL change to go to the session.
                        navigate(
                            `/sessions/${session.sessionId}${params ? `?${params.join('&')}` : ''}`,
                        );
                    },
                });
            }
        },
        [
            createSessionWithPrompt,
            navigate,
            skillsetsParams,
            featureFlagsParams,
            toggleDailyTip,
            userDetails,
            createSessionWithPromptbook,
        ],
    );

    return (
        <div className={classes.root}>
            <div className={classes.prompt}>
                <PromptBar
                    defaultSkill={defaultPromptSkill}
                    onSubmit={onSubmit}
                    enableSkillsetConnector
                    enableSupportAssistance
                />
                {isError && (
                    <MessageBar intent="error" politeness="assertive">
                        <MessageBarBody>
                            {errMessage?.message}
                            {errMessage?.learnMoreText && errMessage?.learnMoreUri && (
                                <>
                                    {' '}
                                    <Link href={errMessage.learnMoreUri}>
                                        {errMessage.learnMoreText}
                                    </Link>
                                </>
                            )}
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    onClick={reset}
                                    aria-label={t('ButtonLabels.Close')}
                                    appearance="transparent"
                                    icon={<DismissIcon />}
                                />
                            }
                        />
                    </MessageBar>
                )}
                {promptbookLoading && (
                    <ToastNotification
                        intent="progress"
                        message={'Starting promptbook..'}
                        sessionId={''}
                        timeout={10000}
                    ></ToastNotification>
                )}
                {promptbookApplySuccess && (
                    <ToastNotification
                        intent="success"
                        message={'Promptbook started successfully..'}
                        sessionId={''}
                        timeout={10000}
                    ></ToastNotification>
                )}
            </div>
        </div>
    );
}
