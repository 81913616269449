import MedeinaVariables from '@/util/variables';

export const RESOURCE_SCOPES = {
    FIDELIS: {scopes: [MedeinaVariables.FidelisScope]},
    ARM: {scopes: [MedeinaVariables.ArmScope]},
};

export const WORKSPACE_PROVIDER_DEFAULT_STALE_TIME = 30 * 60 * 1000;
export const DEFAULT_API_RETRY_COUNT = 0;
export const DEFAULT_API_STALE_TIME = 0;

export const CAPACITY_REFERENCE_NAME = 'CapacityReference';

export const FIDELIS_ERROR_CODES: Record<any, number> = {
    TENANT_NOT_EXISTS: 3000,
    INVALID_GEO_FOR_ACCOUNT: 40002,
    INVALID_GEO_FOR_ENV: 13009,
    ACCOUNT_CONFIGURED_IN_DIFFERENT_GEO: 2005,
};
