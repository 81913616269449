import {LinkParameters} from '@/components/App.types';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import {useMemo} from 'react';

interface UseGetCapacityParameters {
    links: Record<string, LinkParameters>;
    isCapacityIdValid: boolean;
}

export default function useGetCapacityParameters(
    capacityId: string,
    tenantId: string,
): UseGetCapacityParameters {
    const AZURE_PORTAL = 'Azure Portal';

    const {
        getSubscriptionUrl,
        getResourceGroupUrl,
        getCapacityUrl,
        getCapacityName,
        getSubscriptionId,
        getResourceGroupName,
        getBillingTabUrlOnSubscriptionPage,
        isCapacityIdValid,
    } = useAzureCapacityDependencyLinkParts({
        capacityId,
        tenantId,
    });

    const links = useMemo<Record<string, LinkParameters>>(() => {
        return {
            subscription: {
                url: getSubscriptionUrl(),
                text: getSubscriptionId(),
                target: '_blank',
            },
            resourceGroup: {
                url: getResourceGroupUrl(),
                text: getResourceGroupName(),
                target: '_blank',
            },
            capacity: {
                url: getCapacityUrl(),
                text: getCapacityName(),
                target: '_blank',
            },
            billingTabUrl: {
                url: getBillingTabUrlOnSubscriptionPage(),
                text: AZURE_PORTAL,
                target: '_blank',
            },
        };
    }, [capacityId]);

    return {
        links,
        isCapacityIdValid,
    };
}
