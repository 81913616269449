import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorBrandForeground1,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL),
        boxShadow: tokens.shadow4,
        height: '100%',
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground4Pressed,
        },
    },
    heroRoot: {
        ...shorthands.padding(tokens.spacingVerticalXXXL, tokens.spacingHorizontalXXXL),
        width: '100%',
        '@media (min-width:480px) (max-width: 639px)': {
            ...shorthands.padding(tokens.spacingVerticalXXXL, tokens.spacingVerticalXXL),
        },
        '@media (max-width: 479px)': {
            ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalL),
        },
    },
    emptyRoot: {
        backgroundColor: tokens.colorNeutralBackgroundAlpha,
        width: '100%',
    },
    spinner: {
        ...shorthands.margin(tokens.spacingVerticalXXXL, 0),
        '@media (max-width: 479px)': {
            ...shorthands.margin(tokens.spacingVerticalL, 0),
        },
        height: 'auto',
    },
    title: {
        ...shorthands.flex(0, 0, 'auto'),
        marginBottom: tokens.spacingVerticalS,

        // Collapse to 2 lines.
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        minHeight: 0,
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        color: tokens.colorNeutralForeground1,

        '> a': {
            color: tokens.colorNeutralForeground1,

            ':hover': {
                color: tokens.colorNeutralForeground1,
                textDecorationThickness: '2px',
                textDecorationColor: tokens.colorBrandStroke1,
            },
        },
    },
    heroTitle: {
        marginBottom: tokens.spacingVerticalXL,
        color: tokens.colorNeutralForeground1,
    },
    tags: {
        ...shorthands.flex(0, 0, 'auto'),
        marginBottom: tokens.spacingVerticalS,
        display: 'flex',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        minWidth: 0,
        ...shorthands.overflow('hidden'),
    },
    heroTags: {
        marginBottom: tokens.spacingVerticalXL,
    },
    tagItem: {
        ...shorthands.padding(
            tokens.strokeWidthThin,
            tokens.spacingVerticalM,
            tokens.strokeWidthThicker,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: 'auto',
        display: 'inline-block',
        backgroundColor: tokens.colorNeutralBackground5,
        marginRight: tokens.spacingHorizontalS,
        fontSize: tokens.fontSizeBase300,
    },
    summary: {
        ...shorthands.flex(0, 0, 'auto'),

        // Collapse to 3 lines.
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        height: `calc(${tokens.lineHeightBase400} * 2)`,
        minHeight: 0,
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',

        '@media (max-width: 479px)': {
            '-webkit-line-clamp': '1',
            height: `calc(${tokens.lineHeightBase400} * 1)`,
        },
        // Body1
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,
        color: tokens.colorNeutralForeground2,
    },
    heroSummary: {
        '-webkit-line-clamp': '10',
    },
    date: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorNeutralForeground4,
        display: 'none',
    },
    heroDate: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'row',

        '> *': {},
    },
    hideElement: {
        '@media (max-width: 479px)': {
            display: 'none',
        },
    },
});
