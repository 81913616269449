import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetCapacityByNameResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import {ErrorResponse} from '../api';
import useFetch from '../useFetch';
import securityGraphApi from '@/api/securityGraph.api';

export default function useGetCapacityByName(
    name: string,
    useQueryOptions?: Pick<
        UseQueryOptions<GetCapacityByNameResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const {customFetch} = useFetch();
    const url = securityGraphApi({
        path: `/account/capacities/${name}`,
        version: '2023-12-01-preview',
        isGlobal: true,
    });

    return useQuery<GetCapacityByNameResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['capacities', name],
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    scopes: RESOURCE_SCOPES.FIDELIS,
                    method: 'GET',
                },
                true,
            );

            if (!response.ok) {
                const error: ErrorResponse = {
                    code: response.status,
                    message: response.statusText,
                };
                throw error;
            }

            const result = await response.json();
            return result as GetCapacityByNameResponse;
        },
        ...useQueryOptions,
    });
}
