import {GraphvizImage} from './GraphvizDotCodeBlock.types';

export const graphvizImages: GraphvizImage[] = [
    ['asset_external', 42, 42],
    ['asset_external_suspicious', 42, 42],
    ['asset_external_active', 52, 52],
    ['asset_external_active_suspicious', 52, 52],
    ['asset_internal', 42, 42],
    ['asset_internal_suspicious', 42, 42],
    ['asset_internal_active', 52, 52],
    ['asset_internal_active_suspicious', 52, 52],
    ['script_external', 52, 52],
    ['script_external_suspicious', 52, 52],
    ['script_external_active', 64, 64],
    ['script_external_active_suspicious', 64, 64],
    ['script_internal', 52, 52],
    ['script_internal_suspicious', 52, 52],
    ['script_internal_active', 64, 64],
    ['script_internal_active_suspicious', 64, 64],
    ['threat_external', 52, 52],
    ['threat_external_active', 64, 64],
    ['threat_internal', 52, 52],
    ['threat_internal_active', 64, 64],
    ['user_external', 42, 42],
    ['user_external_suspicious', 42, 42],
    ['user_external_active', 52, 52],
    ['user_external_active_suspicious', 52, 52],
    ['user_internal', 42, 42],
    ['user_internal_suspicious', 42, 42],
    ['user_internal_active', 52, 52],
    ['user_internal_active_suspicious', 52, 52],
    ['unknown', 42, 42],
];

export const graphvizTypeMap = {
    ALERT: 'threat',
    ASSET: 'asset',
    EXECUTABLE: 'script',
    INCIDENT: 'threat',
    SCRIPT: 'script',
    SERVICE: 'script',
    THREAT: 'threat',
    USER: 'user',
    UNKNOWN: 'unknown',
};

export const graphvizRiskMap = {
    SUSPICIOUS: 'suspicious',
    MALICIOUS: 'suspicious',
    UNKNOWN: 'unknown',
};
