import React, {useRef, useCallback, useEffect} from 'react';

interface useTimerProps {
    time: number;
    onTimerEnd: (data?: any) => void;
}

function useTimer(props: useTimerProps) {
    const timeoutRef = useRef<any>();

    const startTimer = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            props.onTimerEnd();
        }, props.time);
    }, [props]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return startTimer;
}

export default useTimer;
