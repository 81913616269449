import {useMutation, useQueryClient} from '@tanstack/react-query';
import {customFetch} from '../api';
import useFetch from '../useFetch';
import {Prompt} from '../prompts';
import {
    CreateEvaluationRequest,
    Evaluation,
    EvaluationCreationResponse,
    EvaluationState,
} from './evaluations.types';
import useClearSessionSummary from '../sessions/useClearSessionSummary';

// This is typically only used in other Evaluation/Prompt-related APIs and not in integrations.
export default function useCreateAndWaitForEvaluation() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: clearSessionSummary} = useClearSessionSummary();
    return useMutation({
        mutationFn: async (body: CreateEvaluationRequest) => {
            let {
                evaluation: {evaluationId},
            } = await customFetch<EvaluationCreationResponse>(
                `/sessions/${body?.sessionId}/prompts/${body?.promptId}/evaluations`,
                {
                    method: 'POST',
                    body,
                },
            );

            // Refetch the parent prompt, in case this is either editing or re-running the prompt, forcing the UI to update.
            queryClient.refetchQueries({
                queryKey: ['sessions', body?.sessionId, 'prompts', body?.promptId],
            });

            // Polling until the evaluation is finished.
            let evaluation: Evaluation | undefined;
            do {
                // After the first check, wait between requests.
                if (evaluation !== undefined) {
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                }

                // Refetch the evaluation.
                evaluation = await customFetch<Evaluation>(
                    `/sessions/${body?.sessionId}/prompts/${body?.promptId}/evaluations/${evaluationId}`,
                    {
                        method: 'GET',
                    },
                );
            } while (evaluation.state !== EvaluationState.Completed);

            return evaluation;
        },
        onSuccess: (evaluation, body) => {
            // Refetch the parent prompt, in case this is either editing or re-running the prompt.
            clearSessionSummary({sessionId: evaluation.sessionId});
            queryClient.refetchQueries({
                queryKey: ['sessions', body?.sessionId, 'prompts', body?.promptId],
            });
        },
    });
}
