import MedeinaFeatures, {
    MedeinaFeatureFlagReference,
    MedeinaFeatureFlagValue,
    MedeinaUserFeatures,
    MedeinaUrlParamFeatures,
} from './features';

// These default to `{}` to ensure tests that partially mock `@/util/features` don't fail.
const MedeinaUserFeaturesKeys = new Set(
    Object.keys(MedeinaUserFeatures ?? {}).map((key) => key.toLowerCase()),
);
const MedeinaUrlParamFeaturesKeys = new Set(
    Object.keys(MedeinaUrlParamFeatures ?? {}).map((key) => key.toLowerCase()),
);
// Environment feature flags are always going to be values, not keys.
const MedeinaEnvFeaturesStringValues = Object.entries(MedeinaFeatures ?? {})
    .filter(
        // Filter known flags from user and URL param flags.
        ([key, value]) => {
            const keyLower = key.toLowerCase();
            return (
                !MedeinaUserFeaturesKeys.has(keyLower) &&
                !MedeinaUrlParamFeaturesKeys.has(keyLower) &&
                typeof value === 'string'
            );
        },
    )
    .map(([key, value]) => value);

// Checks compiled and URL param user feature flags for access.
// Usage:
//   const isVerboseLoggingEnabled = hasStaticFeatureFlag(MedeinaFeatures.EnableVerboseLogging);
export function getStaticFeatureFlag(
    reference: MedeinaFeatureFlagReference,
): MedeinaFeatureFlagValue {
    // Flags are stored in their original case, but we want to compare case-insensitively.
    const urlParamFlags = new Set(getCachedUrlParamFlags(true));
    //const urlParamFlags = getCachedUrlParamFlags().;

    // Typically any boolean value is a `.env` configured frontend feature flag, defined at build time.
    if (typeof reference === 'boolean') {
        return reference;
    }

    if (typeof reference === 'string') {
        const referenceLower = reference.toLowerCase();

        // Handle known URL param flags (case insensitive)
        if (MedeinaUrlParamFeaturesKeys.has(referenceLower)) {
            return urlParamFlags.has(referenceLower);
        }

        // Rarely, there are string values in `.env` configured frontend feature flags.
        // Verify that it is a known string before returning the string value.
        if (MedeinaEnvFeaturesStringValues.includes(reference)) {
            return reference;
        }

        // Not all URL param flags are documented in `MedeinaUrlParamFeatures`,
        // but we still want to allow those.
        if (urlParamFlags.has(reference.toLowerCase())) {
            return true;
        }
    }

    return false;
}

// Checks compiled and URL param user feature flags for access.
// Usage:
//   const isVerboseLoggingEnabled = hasStaticFeatureFlag(MedeinaFeatures.EnableVerboseLogging);
export function hasStaticFeatureFlag(reference: MedeinaFeatureFlagReference): boolean {
    return Boolean(getStaticFeatureFlag(reference));
}

// Checks compiled and URL param user feature flags for access.
// This will return first whether _all_ flags are truthy.
// Note: This should only be used for non-React code. For React code, use `useFeatureFlags`.
// Usage:
//   const [enabled, isFirstRunExperience] = useFeatureFlags([MedeinaFeatures.FirstRunExperience]);
export function hasStaticFeatureFlags(references: Array<MedeinaFeatureFlagReference>): boolean {
    const results = references.map((reference) => {
        return hasStaticFeatureFlag(reference);
    });

    return results.every((result) => Boolean(result));
}

export const MedeinaFeatureFlagSessionKey = 'cfs-featureFlags';

// Returns an array of feature flags that were saved in session storage
export function getSessionFeatureFlags(): string[] {
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        return JSON.parse(sessionStorage.getItem(MedeinaFeatureFlagSessionKey) as string) || [];
    } else {
        return [];
    }
}

export function setSessionFeatureFlags(newFeatureFlags: string[]) {
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(MedeinaFeatureFlagSessionKey, JSON.stringify(newFeatureFlags));
    }
}

export function clearSessionFeatureFlags() {
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        sessionStorage.removeItem(MedeinaFeatureFlagSessionKey);
    }
}

export function replaceUrlFeatureFlagsAndReload() {
    if (typeof window !== 'undefined' && window.history.replaceState && window.location) {
        // clear the url params
        const url = new URL(window.location.href);
        url.searchParams.delete('featureFlags');
        url.searchParams.delete('scenario');
        window.history.replaceState({}, '', url.toString());
        // reload the page to clear everything
        window.location.reload();
    }
}
// Returns an array of feature flags from the URL, feature flags in the URL should be comma separated
export function getFeatureFlagsFromUrl(): string[] {
    if (typeof window !== 'undefined' && window.location.search) {
        return new URLSearchParams(window.location.search).get('featureFlags')?.split(',') ?? [];
    }
    return [];
}

// Return the feature flags in a single string, comma separated
// these feature flags get stored from the URL
export const featureFlags = (): string | undefined => {
    const flags = getSessionFeatureFlags();
    if (flags.length > 0) {
        return flags.join(',');
    } else {
        return undefined;
    }
};

// get the url param flags that have been saved in session storage
export const getCachedUrlParamFlags = (lowercase?: boolean): Array<string> => {
    const cachedUrls = getSessionFeatureFlags();
    if (lowercase) {
        return cachedUrls?.map((url) => url?.toLowerCase());
    }
    return cachedUrls;
};
