import {Node, Edge} from '@xyflow/react';
import {Session} from '@/api/sessions';
import {EvaluationSkillInvocation} from '@/api/evaluations';
import {Prompt} from '@/api/prompts';

export const convertToNodesAndEdges = (
    session: Session,
    prompts: {value: Prompt[]},
    evaluationsSkillInvocations: {data: {value: EvaluationSkillInvocation[]}}[],
    onClick: (id: string, data: any) => void,
): {nodes: Node[]; edges: Edge[]} => {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const yOffset = 200; // Offset for the first EvaluationSkillInvocation node
    let currentYOffset = 0; // Track the current y position for new nodes

    if (session) {
        const sessionNode: Node = {
            id: session.sessionId,
            position: {x: 0, y: 0},
            data: {
                Session: session.name,
                'Session ID': session.sessionId,
                onClick,
            },
            type: 'customNode',
        };

        nodes.push(sessionNode);

        prompts?.value?.forEach((prompt: Prompt, index: number) => {
            const promptNode: Node = {
                id: `prompt-${prompt.promptId}`,
                position: {x: 0, y: currentYOffset + yOffset},
                data: {
                    [`Prompt ${index + 1} ID`]: prompt.promptId,
                    [`Prompt ${index + 1} Input`]: prompt?.inputs?.Input,
                    onClick,
                },
                type: 'customNode',
            };

            nodes.push(promptNode);

            if (prompt.latestEvaluationId) {
                promptNode.data[`Evaluation ${index + 1} ID`] = prompt.latestEvaluationId;

                const parentXPositions = new Map<string, number>();
                let currentXPosition = 400;
                let promptYOffset = currentYOffset + yOffset + 100; // Start with the initial offset for each prompt

                const allInvocations = evaluationsSkillInvocations.flatMap(
                    (invocationGroup) => invocationGroup.data?.value || [],
                );

                const filteredInvocations = allInvocations?.filter(
                    (invocation: EvaluationSkillInvocation) =>
                        invocation.promptId === prompt.promptId,
                );

                filteredInvocations.forEach(
                    (invocation: EvaluationSkillInvocation, idx: number) => {
                        const invocationId = `invocation-${invocation.skillInvocationId}`;

                        // Determine the x position based on parentSkillInvocationId
                        let xPosition;
                        if (parentXPositions.has(invocation.parentSkillInvocationId)) {
                            xPosition =
                                parentXPositions.get(invocation.parentSkillInvocationId) ?? 0;
                            currentXPosition = xPosition + 400;
                        } else {
                            xPosition = currentXPosition;
                            parentXPositions.set(invocation.parentSkillInvocationId, xPosition);
                            currentXPosition += 400; // Indent further for new parentSkillInvocationId
                        }

                        nodes.push({
                            id: invocationId,
                            position: {x: xPosition ?? 0, y: promptYOffset},
                            data: {
                                'Skillset Name': invocation.skillsetName,
                                'Skill Name': invocation.skillName,
                                'Skill Invocation ID': invocation.skillInvocationId,
                                'Parent Skill Invocation ID': invocation.parentSkillInvocationId,
                                'Skill Inputs': JSON.stringify(invocation.skillInputs, null, 2),
                                'Skill Output': invocation.skillOutput,
                                Exception: invocation.exception,
                                onClick,
                            },
                            type: 'evaluationNode',
                        });

                        // Increment the y position for the next node
                        promptYOffset += 200;

                        // Create edge from parentSkillInvocationId if it exists, otherwise from promptNode
                        const sourceId = invocation.parentSkillInvocationId
                            ? `invocation-${invocation.parentSkillInvocationId}`
                            : promptNode.id;

                        edges.push({
                            id: `edge-${sourceId}-${invocationId}`,
                            source: sourceId,
                            target: invocationId,
                            type: 'customEdge',
                        });
                    },
                );

                // Update currentYOffset to the lowest point of the current prompt's evaluation skill invocations
                currentYOffset = promptYOffset;
            }

            // Create edge from sessionNode to promptNode
            edges.push({
                id: `edge-${sessionNode.id}-${promptNode.id}`,
                source: sessionNode.id,
                target: promptNode.id,
                type: 'customEdge',
            });
        });
    }

    return {nodes, edges};
};
