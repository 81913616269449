import {SkillsetDescriptor} from '@/api/skills';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import {Image} from '@fluentui/react-components';
import {getImagePath} from '../Utils/ExternalConfigurationModal.utils';
import {useTranslation} from 'react-i18next';
import {DialogTitle} from '@/components/ui/Dialog';

interface UploadPluginDialogHeaderProps {
    selectedConnector?: SkillsetDescriptor | null;
    handleConnectorReturn: () => void;
    onClose: () => void;
}

export default function UploadPluginDialogHeader({
    selectedConnector,
    handleConnectorReturn,
    onClose,
}: UploadPluginDialogHeaderProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <>
            <DialogTitle
                title={selectedConnector ? t('UploadNewFile') : t('AddPluginText')}
                onCloseButtonClick={onClose}
                onBackButtonClick={handleConnectorReturn}
                showBackButton
                backLabel={t('GoBackToManagePlugins')}
                icon={
                    <Image
                        height={24}
                        width={24}
                        fit="default"
                        src={getImagePath('Default.svg')}
                        alt=""
                        aria-hidden
                    />
                }
            />
        </>
    );
}
