import * as React from 'react';
const FaceUpsideDown = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path
                fill="url(#f2431id0)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2431id1)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2431id2)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2431id3)"
                fillOpacity=".6"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2431id4)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2431id5)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2431id6)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f2431id7)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <circle r="4.673" fill="url(#f2431id8)" transform="matrix(1 0 0 -1 9.017 18.58)" />
            <circle r="4.244" fill="url(#f2431id9)" transform="matrix(1 0 0 -1 19.244 18.057)" />
            <path
                fill="#fff"
                d="M10.42 15.776a4.206 4.206 0 1 1 0 8.411a4.206 4.206 0 0 1 0-8.411Zm11.148-.077a4.244 4.244 0 1 1 0 8.489a4.244 4.244 0 0 1 0-8.49Z"
            />
            <path fill="url(#f2431idb)" d="M11 17a3 3 0 1 1 0 6a3 3 0 0 1 0-6Z" />
            <path fill="url(#f2431idc)" d="M21 17a3 3 0 1 1 0 6a3 3 0 0 1 0-6Z" />
            <path
                fill="url(#f2431ida)"
                fillRule="evenodd"
                d="M10.4 13.8a1 1 0 0 0 1.4-.2c.31-.412 1.712-1.6 4.2-1.6c2.488 0 3.89 1.188 4.2 1.6a1 1 0 1 0 1.6-1.2c-.69-.92-2.688-2.4-5.8-2.4c-3.112 0-5.11 1.48-5.8 2.4a1 1 0 0 0 .2 1.4Z"
                clipRule="evenodd"
            />
            <defs>
                <radialGradient
                    id="f2431id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(132.839 10.786 10.065) scale(37.5033)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f2431id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.878 10.74 10.193) scale(38.9487)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f2431id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(101.31 2.876 12.808) scale(17.8466 22.8581)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".788" stopColor="#F59639" stopOpacity="0" />
                    <stop offset=".973" stopColor="#FF7DCE" />
                </radialGradient>
                <radialGradient
                    id="f2431id3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-29 29 -29 -29 18 14)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".315" stopOpacity="0" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="f2431id4"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(77.692 -2.555 18.434) scale(28.1469)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".508" stopColor="#7D6133" stopOpacity="0" />
                    <stop offset="1" stopColor="#715B32" />
                </radialGradient>
                <radialGradient
                    id="f2431id5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(7.5 10.99996 -7.97335 5.4364 16.5 16.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB849" />
                    <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2431id6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(11.49998 2 -2 11.49998 20.5 18)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA64B" />
                    <stop offset=".9" stopColor="#FFAE46" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2431id7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(43.971 -9.827 29.173) scale(59.0529)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".185" stopOpacity="0" />
                    <stop offset="1" stopOpacity=".4" />
                </radialGradient>
                <radialGradient
                    id="f2431id8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(135 3.939 2.24) scale(9.10579 4.71285)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392108" />
                    <stop offset="1" stopColor="#C87928" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2431id9"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(135 3.577 2.034) scale(7.66968 4.32966)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392108" />
                    <stop offset="1" stopColor="#C87928" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2431ida"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(0 -5.5 8.41855 0 16 15)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".348" stopColor="#241A1A" />
                    <stop offset=".628" stopColor="#57444A" />
                    <stop offset="1" stopColor="#4E2553" />
                    <stop offset="1" stopColor="#502A56" />
                </radialGradient>
                <linearGradient
                    id="f2431idb"
                    x1="16.5"
                    x2="15.5"
                    y1="24"
                    y2="17"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#553B3E" />
                    <stop offset="1" stopColor="#3D2432" />
                </linearGradient>
                <linearGradient
                    id="f2431idc"
                    x1="16.5"
                    x2="15.5"
                    y1="24"
                    y2="17"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#553B3E" />
                    <stop offset="1" stopColor="#3D2432" />
                </linearGradient>
            </defs>
        </g>
    </svg>
);
export default FaceUpsideDown;
