import {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Subtitle2} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/Tour.styles';
import useStyles from './PopoverStops.styles';

import PopoverControls from '@/components/ui/Tour/PopoverControls';
import PopoverDismiss from '@/components/ui/Tour/PopoverDismiss';
import {
    TourStopState,
    PopoverView,
    useTourContext,
    TourStopSetupHandler,
    TourStopTeardownHandler,
} from '@/components/ui/Tour';
import {useTranslation} from 'react-i18next';
import useTimer from '@/util/useTimer';
import {closeSourcesDialog, openSourcesDialog, isSourcesDialogOpen} from '../../TourLocators';
import {tourStopNames} from '../config';

// function that runs on the setup of the stop
export const setupHandlers: TourStopSetupHandler = (stopName, setTourStopPlaying) => {
    // depending on the stop we will want to run a certain block of code
    // if we do not have an entry for the stop we will just return
    // handle with a switch statement
    switch (stopName) {
        case tourStopNames.openModal:
            if (!isSourcesDialogOpen()) {
                openSourcesDialog();
            }
            setTourStopPlaying();
            break;
        default:
            break;
    }
};

export const cleanupHandlers: TourStopTeardownHandler = (
    currentStopName,
    nextStopName,
    setStateComplete,
) => {
    if (isSourcesDialogOpen()) {
        closeSourcesDialog();
    }
};

export const ModalIntro: PopoverView = (props) => {
    const {stopState, setStopState} = props;
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const navigate = useNavigate();
    const {t} = useTranslation('tours');

    const resetPage = () => {
        navigate('/tour/admin');
    };

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle}>
                    {t('admin.ManagePluginsTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('admin.ManagePluginsDescription')}</p>
            <PopoverControls stopNumber={1} totalPopoverStops={4} onBackClicked={resetPage} />
        </div>
    );
};

export const ModalOpen: PopoverView = (props) => {
    const {stopState, setStopState} = props;
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    const handlePrevious = useCallback(() => {
        setStopState(TourStopState.teardown);
        tour.prevStop();
    }, []);

    const handleNext = useCallback(() => {
        setStopState(TourStopState.teardown);
        tour.nextStop();
    }, []);

    const handleExit = useCallback(() => {
        tour.quitTour();
    }, []);

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle}>
                    {t('admin.PluginsOnOffTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('admin.PluginsOnOffDescription')}</p>
            <PopoverControls
                stopNumber={2}
                totalPopoverStops={4}
                onBackClicked={handlePrevious}
                onNextClicked={handleNext}
                onDoneClicked={handleExit}
            />
        </div>
    );
};

export const SetUpPlugin = () => {
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle}>
                    {t('admin.SetupPluginsTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('admin.SetupPluginsDescription')}</p>
            <PopoverControls stopNumber={3} totalPopoverStops={4} />
        </div>
    );
};

export const AddPlugin = () => {
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle}>
                    {t('admin.AddPluginTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('admin.AddPluginDescription')}</p>
            <PopoverControls stopNumber={4} totalPopoverStops={4} />
        </div>
    );
};

export const SupportIntro = () => {
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle}>
                    {t('common.GetHelpTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('common.GetHelpDescription')}</p>
        </div>
    );
};

export const TryQuickStart: PopoverView = (props) => {
    const {stopState, setStopState} = props;
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');
    const startTimer = useTimer({
        onTimerEnd: () => setStopState(TourStopState.playing),
        time: 1200,
    });

    const onLoad = useCallback(() => {
        const promptsButton = document.querySelector<HTMLButtonElement>(
            "[data-testid='promptbar-skill-menu-toggle']",
        );
        promptsButton?.click();

        startTimer();
    }, []);

    const onTeardown = useCallback(() => {
        const modalButton = document.querySelector<HTMLButtonElement>(
            "[data-testid='promptbar-skill-menu-toggle']",
        );
        modalButton?.click();
    }, []);

    // we want to call onLoad or onTeardown depending on the state
    useEffect(() => {
        if (stopState == TourStopState.setup) {
            onLoad();
        }
        if (stopState == TourStopState.teardown) {
            onTeardown();
        }
    }, [onLoad, onTeardown, stopState]);

    const handlePrevious = useCallback(() => {
        setStopState(TourStopState.teardown);
        tour.prevStop();
    }, []);

    const handleNext = useCallback(() => {
        setStopState(TourStopState.teardown);
        tour.nextStop();
    }, []);

    const handleExit = useCallback(() => {
        onTeardown();
        tour.quitTour();
    }, []);

    return stopState == TourStopState.setup || stopState == TourStopState.teardown ? (
        <></>
    ) : (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.TryQuickStartTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={handleExit} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.TryQuickStartDescription')}</p>
            <PopoverControls
                stopNumber={3}
                totalPopoverStops={5}
                onBackClicked={handlePrevious}
                onNextClicked={handleNext}
            />
        </div>
    );
};

export const Sources: PopoverView = (props) => {
    const classes = useStyles();

    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.SourcesTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.SourcesDescription')}</p>
            <PopoverControls stopNumber={4} totalPopoverStops={5} />
        </div>
    );
};

export const Optimize: PopoverView = (props) => {
    const {stopState, setStopState} = props;
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    const handlePrevious = useCallback(() => {
        setStopState(TourStopState.teardown);
        tour.prevStop();
    }, []);

    const handleNext = useCallback(() => {
        setStopState(TourStopState.teardown);
        tour.nextStop();
    }, []);

    const handleExit = useCallback(() => {
        tour.quitTour();
    }, []);

    return stopState == TourStopState.setup || stopState == TourStopState.teardown ? (
        <></>
    ) : (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.OptimizeTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.OptimizeDescription')}</p>
            <PopoverControls
                stopNumber={5}
                totalPopoverStops={5}
                onBackClicked={handlePrevious}
                onNextClicked={handleNext}
                onDoneClicked={handleExit}
            />
        </div>
    );
};
