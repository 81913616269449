import {useMemo} from 'react';
import Fuse from 'fuse.js';
import {PromptbookDescriptor, PromptbookVisibility, useGetAllPromptbooks} from '@/api/promptbooks';
import {PromptBarMenuChildOptions, PromptBarMenuLists} from './PromptBarMenu.types';
import {useTranslation} from 'react-i18next';

const FUSE_OPTIONS = {
    keys: [{name: 'name', weight: 2}, 'description'],
};

const PRIVATE_VISIBILITY = PromptbookVisibility.Private.toString();

export default function usePromptBarMenuPromptbooksList({
    filter,
    limit,
}: PromptBarMenuChildOptions): PromptBarMenuLists<PromptbookDescriptor> {
    const {data: allPromptbooks, isLoading} = useGetAllPromptbooks();
    const {t} = useTranslation('promptbooks');

    // Searchable list of All promptbooks
    const allPromptbooksList = useMemo<Fuse<PromptbookDescriptor>>(() => {
        return new Fuse<PromptbookDescriptor>(allPromptbooks?.value || [], FUSE_OPTIONS);
    }, [allPromptbooks]);

    //All available promptbooks list.
    let filteredPromptbooksList = useMemo<
        Array<Pick<Fuse.FuseResult<PromptbookDescriptor>, 'item'>>
    >(() => {
        // Filter the list of all skipromptbookslls, if a filter exists.
        if (filter && allPromptbooksList) {
            return allPromptbooksList.search(filter, {
                limit,
            });
        }
        // Otherwise fallback to the list of top promptbooks.
        else if (allPromptbooks?.value) {
            return allPromptbooks?.value.slice(0, limit).map((promptbook) => ({item: promptbook}));
        } else {
            return [];
        }
    }, [allPromptbooksList, allPromptbooks, filter, limit]);

    // Generate a list of all available skills filtered.
    let allAvailablePromptbooksList = useMemo<Fuse.FuseResult<PromptbookDescriptor>[]>(() => {
        if (filter && allPromptbooksList) {
            return allPromptbooksList.search(filter);
        } else if (allPromptbooks?.value) {
            return allPromptbooks?.value.map(
                (promptbook) => ({item: promptbook} as Fuse.FuseResult<PromptbookDescriptor>),
            );
        } else {
            return [];
        }
    }, [allPromptbooksList, allPromptbooks, filter]);

    // Group promptbooks by visibility.
    const groupedPromptbooks = useMemo<{[key: string]: Array<PromptbookDescriptor>}>(() => {
        let grouped = allAvailablePromptbooksList
            .map((obj) => obj.item)
            .reduce(
                (
                    groups: {[key: string]: Array<PromptbookDescriptor>},
                    item: PromptbookDescriptor,
                ) => {
                    let key = item.visibility;
                    if (!groups[key]) {
                        groups[key] = [];
                    }
                    groups[key].push(item);
                    return groups;
                },
                {},
            );

        // Rename groups with owner name.
        Object.keys(grouped).forEach((key) => {
            // For promptbooks with Private visibility, group them under "My Promptbooks".
            const nextKey =
                grouped[key][0].visibility == PRIVATE_VISIBILITY
                    ? t('MyPromptbooks')
                    : grouped[key][0]?.ownerName ?? key;
            if (nextKey !== key) {
                grouped[nextKey] = grouped[key];
                delete grouped[key];
            }

            // Then alphabetize.
            grouped[nextKey] = grouped[nextKey].sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        });

        return grouped;
    }, [allAvailablePromptbooksList]);

    return {
        allList: allPromptbooksList,
        filteredList: filteredPromptbooksList,
        groupedList: groupedPromptbooks,
        isLoading,
    };
}
