import {useGetCapacitiesForAdminFre} from '@/api/capacities';
import {FreStage} from '@/api/tenant';
import useGetTenantInfoForAdminFre from '@/api/tenant/useGetTenantInfoForAdminFre';
import {useGetWorkspacesForAdminFre} from '@/api/workspaces';
import {useEffect, useState} from 'react';
import {TOUR_STEPS} from './AdminTour.types';
import {useGetAccount} from '@/api/accounts';
import MedeinaFeatures from '@/util/features';
import {FidelisApiError, FidelisErrorType} from '@/api/api';

export default function useDetermineInitialFreSetup(isEnabled: boolean) {
    const [dataLoadedSuccessfully, setDataLoadedSuccessfully] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [freStage, setFreStage] = useState<FreStage | undefined>();
    const [isFreCompleted, setIsFreCompleted] = useState(false);
    const [workspaceName, setWorkspaceName] = useState<string | undefined>('');
    const [overrideStepRedirect, setOverrideStepRedirect] = useState<TOUR_STEPS | null>(null);

    const {
        data: dataShareResponse,
        isLoading: dataShareLoading,
        isFetched: dataShareFetched,
        isSuccess: dataShareIsSuccessful,
        isError: dataShareIsError,
    } = useGetTenantInfoForAdminFre({enabled: isEnabled});

    const freRequirementsMet = dataShareResponse?.freRequirements?.freRequirementsMet;

    const {
        data: workspacesResponse,
        isLoading: workspacesLoading,
        isFetched: workspacesFetched,
        isSuccess: workspacesIsSuccessful,
        isError: workspacesIsError,
    } = useGetWorkspacesForAdminFre({enabled: isEnabled});

    const {
        data: capacitiesData,
        isLoading: capacitiesDataLoading,
        isFetched: capacitiesDataFetched,
        isSuccess: capacitiesDataSuccessful,
        isError: capacitiesIsDataError,
    } = useGetCapacitiesForAdminFre({enabled: isEnabled});

    const {
        data: accountResponse,
        error: accountError,
        isLoading: accountLoading,
        isFetched: accountFetched,
        isSuccess: accountIsSuccessful,
        isError: accountIsError,
    } = useGetAccount({enabled: isEnabled && MedeinaFeatures.EnableGeoSelection});

    // This use effect's only purpose is to determine if the data is still loading
    // This should not be used to track accountLoading status if MedeinaFeatures.EnableGeoSelection is disabled
    useEffect(() => {
        if (
            dataShareLoading ||
            workspacesLoading ||
            capacitiesData ||
            !MedeinaFeatures.EnableGeoSelection ||
            (MedeinaFeatures.EnableGeoSelection && accountLoading)
        ) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
    }, [dataShareLoading, workspacesLoading, capacitiesData, accountLoading]);

    //Setting the current step of the Fre
    //Determine if capacity has a workspace
    useEffect(() => {
        if (
            dataShareFetched &&
            workspacesFetched &&
            capacitiesDataFetched &&
            (!MedeinaFeatures.EnableGeoSelection ||
                (MedeinaFeatures.EnableGeoSelection && accountFetched))
        ) {
            //If the dataShareResponse is null although successful,
            // then we can assume that the FRE has not started
            if (!dataShareResponse) {
                setFreStage(undefined);
            } else if (!dataShareResponse?.freRequirements.freRequirementsMet) {
                setFreStage(dataShareResponse?.freRequirements.freStage || undefined);
            } else if (!!dataShareResponse?.freRequirements.freRequirementsMet) {
                setIsFreCompleted(true);
            }

            if (
                accountError instanceof FidelisApiError &&
                accountError.errorType === FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS
            ) {
                // If the account is not present, or the geo is not configured, then we should redirect to the location setup screen
                setFreStage(undefined);
            } else if (
                workspacesIsSuccessful &&
                capacitiesDataSuccessful &&
                dataShareIsSuccessful &&
                !freRequirementsMet
            ) {
                const hasCapacities = (capacitiesData?.count ?? 0) > 0;
                const hasWorkspaces = (workspacesResponse?.count ?? 0) > 0;
                const isWorkpaceAttachedToCapacity =
                    !!workspacesResponse?.value?.[0]?.capacity?.referenceName;

                const isTenantRecordAvailable = !!dataShareResponse;

                if (hasWorkspaces) {
                    setWorkspaceName(workspacesResponse?.value[0]?.name);
                }

                // Determining redirection overrides irrespective of FRE stage if Tenant Record is available
                // It could be that there need not be an override
                // If there are no capacities, then we should redirect to the capacity setup screen,
                if (isTenantRecordAvailable) {
                    // If no workspace is present then we should redirect to the Tenant Creation phase
                    if (!hasWorkspaces) {
                        setFreStage(undefined);
                    } else if (!hasCapacities) {
                        setOverrideStepRedirect(TOUR_STEPS.CreateCapacity);
                    } else {
                        // Conditions while capacities are present and tenant record is available
                        if (hasWorkspaces && !isWorkpaceAttachedToCapacity) {
                            // If a workspace is present and is not attached to a capacity,
                            // then we should redirect to the capacity selection screen
                            setOverrideStepRedirect(TOUR_STEPS.SelectCapacity);
                        } else if (
                            hasWorkspaces &&
                            dataShareResponse?.freRequirements?.freStage === FreStage.TenantCreation
                        ) {
                            // If a workspace is present and is attached to a capacity,
                            // then we should redirect to the workspace setup screen
                            setOverrideStepRedirect(TOUR_STEPS.SelectCapacity);
                        }
                    }
                }
            }
        }

        if (
            dataShareFetched &&
            workspacesFetched &&
            capacitiesDataFetched &&
            (!MedeinaFeatures.EnableGeoSelection ||
                (MedeinaFeatures.EnableGeoSelection && accountFetched))
        ) {
            setDataLoading(false);

            //If either of the endpoints are not working after both are fetched,
            //then we can assume that the data was not loaded successfully
            if (dataShareIsSuccessful && workspacesIsSuccessful && capacitiesDataSuccessful) {
                if (MedeinaFeatures.EnableGeoSelection) {
                    if (
                        accountIsSuccessful ||
                        (accountError instanceof FidelisApiError &&
                            accountError.errorType === FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS)
                    ) {
                        setDataLoadedSuccessfully(true);
                    } else {
                        setDataLoadedSuccessfully(false);
                    }
                    return;
                }
                setDataLoadedSuccessfully(true);
            } else {
                setDataLoadedSuccessfully(false);
            }
        }
    }, [dataShareFetched, workspacesFetched, capacitiesDataFetched, accountFetched]);

    return {
        dataLoadedSuccessfully,
        freStage,
        overrideStepRedirect,
        dataLoading,
        workspacesIsError,
        dataShareIsError,
        isFreCompleted,
        workspaceName,
        capacitiesIsDataError,
    };
}
