import React from 'react';
import {Stack} from '@fluentui/react';
import useClasses from './CheckboxGroup.styles';
import {CheckboxGroupProps} from '@/components/ui/Filter/Filter.types';
import {Checkbox} from '@fluentui/react-components';

export default function CheckboxGroup({
    options,
    selectedOptions,
    onCheckboxChange,
}: CheckboxGroupProps) {
    const classes = useClasses();
    const selectedOptionsSet = new Set(selectedOptions);
    return (
        <Stack className={classes.stackStyle} tokens={{childrenGap: 10}}>
            {options.map((option) => (
                <Checkbox
                    key={option.id}
                    label={option.value}
                    checked={selectedOptionsSet.has(option.id)}
                    onChange={() => onCheckboxChange(option.id)}
                    aria-label={option.value}
                />
            ))}
        </Stack>
    );
}
