import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';

import {EditPromptbookRequest, PromptbookDescriptor} from './promptbooks.types';

export default function useEditPromptbook() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: EditPromptbookRequest) => {
            return await customFetch<PromptbookDescriptor>(`/promptbooks/${body.promptbookId}`, {
                method: 'PUT',
                body,
            });
        },
        onSettled: (success, error, body) => {
            queryClient.refetchQueries({
                queryKey: ['promptbooks'],
            });
        },
    });
}
