import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalM),

        '& > button': {
            ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXS),
        },
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
});
