import {
    Body1,
    Body1Strong,
    Subtitle1,
    Button,
    Caption1,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    MenuItem,
    MessageBar,
    MessageBarBody,
    Spinner,
    Title3,
    mergeClasses,
} from '@fluentui/react-components';
import {useEffect, useMemo, useRef, useState} from 'react';
import {DismissIcon, CopyIcon, PromptbookListIcon, SaveIcon} from '@/components/ui/icons';
import useClasses from './PromptbookLibrarySharedForm.styles';
import {PromptInputs} from '@/api/prompts';
import {PromptBarPromptbookFormProps} from './PromptBarPromptbookForm.types';
import PromptbookPrompt from '@/components/sections/promptbook/PromptbookPrompt';
import {useNavigate} from 'react-router-dom';
import {CircleSmallFilled} from '@fluentui/react-icons';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {
    CreatePromptbookRequest,
    PromptbookDescriptor,
    PromptbookVisibility,
} from '@/api/promptbooks';
import PromptbookInputsForm from '@/components/sections/promptbooks/PromptbookInputsForm';
import LinkCopiedDialog from '@/components/sections/promptbooks/LinkCopiedDialog';
import {useTrackEvent} from '@/api/telemetry';
import useCreatePromptbook from '@/api/promptbooks/useCreatePromptbook';
import {ApiError} from '@/api/api';
import PromptbookLibraryForm from './PromptbookLibraryForm';
import {useGetSkillsets} from '@/api/skills';
import PromptbookPlugins from '@/components/sections/promptbooks/PromptbookPlugins';

export * from './PromptBarSkillForm.types';

//This function opens up a view model for promptbook which will have option to delete, duplicate, edit and dismiss.
export default function PromptbookLibrarySharedForm({
    promptbook,
    promptbookInputs,
    promptbookPrompts,
    onCancel,
    onChange,
    onSubmit,
    open,
}: PromptBarPromptbookFormProps) {
    const classes = useClasses();
    const inputRefs = useRef<{[key: string]: HTMLTextAreaElement | null}>({});
    const [showLinkToast, setShowLinkToast] = useState<boolean>(false);
    const {mutate: trackEvent} = useTrackEvent();
    // Generate input defaults.
    const inputs = promptbook.promptbookinputs;
    const prompts = promptbook.prompts;
    const navigate = useNavigate();
    const scrollClasses = useScrollClasses();
    const [closeDisabled, setCloseDisabled] = useState<boolean>(false);
    const [promptbookCopy, setPromptbookCopy] = useState<PromptbookDescriptor | null>(null);

    const [inputValues, setInputValues] = useState<PromptInputs>(
        promptbookInputs ??
            inputs.reduce((values, input) => {
                return values;
            }, {} as PromptInputs),
    );
    const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(open);
    const [copyFormDialogOpen, setCopyFormDialogOpen] = useState<boolean>(open);

    const handleCopySubmit = () => {
        createPromptbook(createPromptbookRequest!, {
            onSuccess: (promptbook: PromptbookDescriptor) => {
                setDialogOpen(false);
                setPromptbookCopy(promptbook);
                setCopyFormDialogOpen(true);
            },
            onError: (error: any) => {
                // Since the backend code uses Problem object which has {title, detail, status} properties,
                // we are checking if the error is of type ApiError and then extracting the detail property from it.
                if (error instanceof ApiError && error.response) {
                    if (error.response?.status === 403) {
                        const errorMessage =
                            "You don't have permission to access this feature. If you need access, contact an administrator.";
                        setApiErrorMessage(errorMessage);
                    } else {
                        error.response.text().then((e) => {
                            setApiErrorMessage(
                                `${
                                    JSON.parse(e).detail ??
                                    JSON.parse(e).message ??
                                    JSON.parse(e).value ??
                                    e.toString()
                                }`,
                            );
                        });
                    }
                } else {
                    setApiErrorMessage(error.toString());
                }
            },
        });
    };

    const [createPromptbookRequest, setCreatePromptbookRequest] =
        useState<CreatePromptbookRequest>();

    const {
        mutate: createPromptbook,
        isError: createPromptbookError,
        reset,
        error,
        isLoading: isCreatePromptbookOperationInProgress,
    } = useCreatePromptbook();

    useEffect(() => {
        const promptbookToUpdate: CreatePromptbookRequest = {
            ...createPromptbookRequest,
            name: promptbook?.name + ' Copy'!,
            description: promptbook?.description!,
            prompts: promptbook?.prompts!,
            promptbookinputs: promptbook?.promptbookinputs!,
            visibility: PromptbookVisibility.Private, // shared promptbook will be created private initially
            tags: promptbook?.tags,
        };
        setCreatePromptbookRequest(promptbookToUpdate);
    }, [promptbook]);

    // Reset parent state initially, otherwise it could include stale or empty data.
    useEffect(() => {
        onChange?.(inputValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Focus the first input on mount.
    useEffect(() => {
        if (inputs && inputs.length > 0 && inputs[0].name) {
            inputRefs.current[inputs[0].name]?.focus();
        }
    }, []);

    const {data: skillsets} = useGetSkillsets();

    const promptbookPlugins = useMemo(() => {
        return promptbook.prompts.reduce((acc, prompt) => {
            prompt.plugins?.forEach((plugin) => {
                const matchedSkillset = skillsets?.value.find(
                    (skillset) => skillset.name === plugin,
                );
                if (matchedSkillset) {
                    acc.push({
                        skillset: matchedSkillset.name,
                        icon: matchedSkillset.icon || '',
                        displayName: matchedSkillset.displayName || '',
                    });
                }
            });
            return acc;
        }, [] as {skillset: string; icon: string; displayName: string}[]);
    }, [promptbook.prompts, skillsets?.value]);

    const handleCopy = () => {
        navigator.clipboard
            .writeText('*' + promptbook!.name)
            .then(() => {})
            .catch((error) => {
                console.error('Error copying promptbook name to clipboard:', error);
            });
    };

    return (
        <>
            {showLinkToast && (
                <LinkCopiedDialog
                    open={showLinkToast}
                    onClose={() => setShowLinkToast(false)}
                ></LinkCopiedDialog>
            )}
            {copyFormDialogOpen && promptbookCopy && (
                <PromptbookLibraryForm
                    promptbook={promptbookCopy!}
                    promptbookInputs={promptbookCopy?.promptbookinputs.reduce((acc, input) => {
                        const {name, description} = input;
                        acc[name] = description;
                        return acc;
                    }, {} as PromptInputs)}
                    onCancel={() => {
                        setCopyFormDialogOpen(false);
                        navigate('/promptbooks');
                    }}
                    onSubmit={() => {
                        setCopyFormDialogOpen(false);
                        navigate('/promptbooks');
                    }}
                    open={copyFormDialogOpen}
                />
            )}
            <Dialog modalType="modal" open={dialogOpen}>
                <DialogSurface
                    className={mergeClasses(classes.root, scrollClasses.colorNeutralBackground1)}
                >
                    {!isCreatePromptbookOperationInProgress && (
                        <DialogActions className={classes.buttonContainer}>
                            <div>
                                <Button
                                    icon={<SaveIcon />}
                                    onClick={() => handleCopySubmit()}
                                    appearance="primary"
                                >
                                    {'Save a copy'}
                                </Button>
                            </div>
                            <div className={classes.rightIcons} role="menu">
                                <MenuItem
                                    icon={<DismissIcon />}
                                    onClick={onCancel}
                                    aria-label="close"
                                    disabled={closeDisabled}
                                    className={classes.dismissIcon}
                                ></MenuItem>
                            </div>
                        </DialogActions>
                    )}
                    {!isCreatePromptbookOperationInProgress && (
                        <DialogBody className={classes.flexcontainer}>
                            {createPromptbookError && (
                                <MessageBar intent="error">
                                    <MessageBarBody>{apiErrorMessage}</MessageBarBody>
                                </MessageBar>
                            )}
                            <div className={classes.promptbookBody}>
                                <div className={classes.promptbookNameAndDescription}>
                                    <div className={classes.promptbookName}>
                                        <Subtitle1
                                            data-testid="promptbook-title"
                                            className={classes.titleContainer}
                                            tabIndex={0}
                                        >
                                            {promptbook.name}
                                            <div role="menu">
                                                <MenuItem
                                                    aria-label="Copy Promptbook Name"
                                                    role="menuitem"
                                                    icon={<CopyIcon />}
                                                    onClick={handleCopy}
                                                ></MenuItem>
                                            </div>
                                        </Subtitle1>
                                        <div className={classes.createdByContainer}>
                                            <Caption1 tabIndex={0}>
                                                Created by {promptbook.ownerName}
                                            </Caption1>
                                            <CircleSmallFilled />
                                            <Caption1>
                                                {promptbook.prompts.length ?? '0'} prompts&nbsp;
                                            </Caption1>
                                            <PromptbookListIcon />
                                        </div>
                                        <div className={classes.createdByContainer} tabIndex={0}>
                                            <Caption1>Tags:&nbsp;</Caption1>
                                            {promptbook.tags?.map((currentTag, index) => (
                                                <Caption1 key={index + '-' + currentTag.tag}>
                                                    {currentTag.tag}
                                                    {index < promptbook.tags!.length - 1 ? ',' : ''}
                                                    &nbsp;
                                                </Caption1>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.promptbookDescription}>
                                        <Body1 tabIndex={0}>{promptbook.description}</Body1>
                                    </div>
                                </div>

                                {inputs && inputs.length > 0 && (
                                    <div className={classes.inputs}>
                                        <Body1Strong tabIndex={0}>
                                            Inputs you&apos;ll need{' '}
                                        </Body1Strong>
                                        <PromptbookInputsForm
                                            edit={false}
                                            formData={[...inputs]}
                                            onFormDataChange={() => {}}
                                        />
                                    </div>
                                )}

                                {promptbookPlugins && promptbookPlugins.length > 0 && (
                                    <div className={classes.inputs}>
                                        <Body1Strong tabIndex={0}>Plugins required</Body1Strong>
                                        <PromptbookPlugins
                                            shouldOverflow={false}
                                            plugins={promptbookPlugins}
                                        />
                                    </div>
                                )}

                                <div className={classes.prompts}>
                                    <Body1Strong tabIndex={0}>Prompts</Body1Strong>
                                    <div className={classes.promptsContainer}>
                                        {prompts &&
                                            prompts.map((prompt, index) => (
                                                <PromptbookPrompt
                                                    key={index}
                                                    index={index}
                                                    prompt={prompt}
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </DialogBody>
                    )}
                    {isCreatePromptbookOperationInProgress && (
                        <DialogBody className={classes.fullSize}>
                            <DialogTitle
                                action={
                                    <DialogTrigger action="close" disableButtonEnhancement>
                                        <Button
                                            data-testid="close-button"
                                            appearance="subtle"
                                            aria-label="close"
                                            icon={<DismissIcon />}
                                            onClick={onCancel}
                                        ></Button>
                                    </DialogTrigger>
                                }
                            ></DialogTitle>
                            <DialogContent className={classes.centerContent}>
                                <Spinner
                                    data-testid="createpromptbook-loading-spinner"
                                    labelPosition="below"
                                    size="extra-large"
                                    label={`Creating promptbook...`}
                                />
                            </DialogContent>
                        </DialogBody>
                    )}
                </DialogSurface>
            </Dialog>
        </>
    );
}
