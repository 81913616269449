import React, {useContext} from 'react';
import LayoutContext from './Context';
import {Layout} from './Layout.types';

// A utility hook that allows a component to handle the state of the layout
// this works with the Layout Context
function useLayoutContext() {
    const layoutContext = useContext(LayoutContext);
    return layoutContext as Layout;
}

export default useLayoutContext;
