import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    caption: {
        color: tokens.colorNeutralForeground4,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
    },
    dialog: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    dialogSurface: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        marginRight: tokens.spacingHorizontalM,
    },
});
