import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: tokens.colorNeutralBackground1,
        borderTopLeftRadius: tokens.borderRadiusXLarge,
        borderTopRightRadius: tokens.borderRadiusXLarge,
    },
    detailedRoot: {
        ...shorthands.padding(
            tokens.spacingVerticalS,
            tokens.spacingVerticalS,
            tokens.spacingHorizontalNone,
            tokens.spacingHorizontalS,
        ),
    },
    content: {
        ...shorthands.flex(1, 1, 'auto'),
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    contentText: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    promptTitle: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
    },
    promptHeader: {
        backgroundColor: tokens.colorNeutralBackground5,
        width: '100%',
        fontSize: tokens.fontSizeBase300,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        ...shorthands.padding(tokens.spacingHorizontalL, tokens.spacingHorizontalL),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ':hover > *': {
            visibility: 'visible',
        },
    },
    promptHeaderOnSummary: {
        ...shorthands.borderRadius(
            tokens.borderRadiusLarge,
            tokens.borderRadiusLarge,
            tokens.borderRadiusNone,
            tokens.borderRadiusNone,
        ),
    },
    toolbar: {
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(0),
        maxHeight: '18px',
        marginTop: '4px',
        '& > button': {
            maxWidth: '28px',
            maxHeight: '28px',
        },
        '& > button span': {
            maxWidth: '18px',
            maxHeight: '18px',
        },
    },
    skillIcon: {
        color: tokens.colorBrandForeground1,
    },
});
