import {getCurrentTenantId} from '@/util/msal/authConfig';
import useCreateCapacity from './useCreateCapacity';
import {useGetWorkspaceByName, useGetWorkspaces, useGetWorkspacesForAdminFre} from '../workspaces';
import useProvisionAccount from '../accounts/useProvisionAccount';
import useCreateResourceGroup from '../user/useCreateResourceGroup';
import useCreateWorkspace from '../workspaces/useCreateFirstWorkspace';
import useUpdateWorkspace from '../workspaces/useUpdateWorkspace';
import {CrossRegionCompute} from './capacities.types';
import MedeinaVariables from '@/util/variables';
import {useGetTenantInfo} from '../tenant';
import {useRegisterProviderWithSubscription} from '.';
import {useGetAccount} from '../accounts';
import {FidelisApiError, FidelisErrorType} from '../api';
import {AccountDetails, ProvisionAccountRequest} from '../accounts/accounts.types';
import MedeinaFeatures from '@/util/features';

interface SetupAccountAndWorkspaceProps {
    selectedGeo: string;
}

interface SetupAndProvisionCapacityProps {
    capacityName?: string;
    isNewResourceGroup?: boolean;
    subscription?: string;
    resourceGroup?: string;
    geo?: string;
    region?: string;
    capacityUnits?: number;
    isCapacityProvisioned?: boolean;
    isCrossRegionAllowed?: boolean;
    shouldPreLoadWorkspace?: boolean;
    skipWorkspaceAssociation?: boolean;
    onAccountCreationError?: (error: any) => void;
    onCapacityCreationError?: (error: any) => void;
    onWorkspaceProvisionError?: (error: any) => void;
    onWorkspaceMappingError?: (error: any) => void;
    onWorkspaceMappingCompletion?: () => Promise<void>;
    onResourceGroupCreationError?: (error: any) => void;
    onServicePlanCreationError?: (error: any) => void;
    onInitialAccountAndWorkspaceProvisioningSuccess?: () => void;
    onSubscriptionRegistrationError?: (error: any) => void;
    onAccountFetchError?: (error: any) => void;
}

// This hook is used to handle the setup and provisioning of capacity and other cosntructs
export default function useSetupAndProvisionFidelisConstructs({
    capacityName,
    isNewResourceGroup,
    subscription,
    resourceGroup,
    geo,
    region,
    capacityUnits,
    isCapacityProvisioned,
    isCrossRegionAllowed,
    shouldPreLoadWorkspace,
    skipWorkspaceAssociation,
    onInitialAccountAndWorkspaceProvisioningSuccess, // This function is called when the initial account and workspace provisioning is successful
    onAccountCreationError, // This function is called when an error occurs during account creation
    onCapacityCreationError, // This function is called when an error occurs during capacity creation
    onWorkspaceProvisionError, // This function is called when an error occurs during workspace provisioning
    onWorkspaceMappingError, // This function is called when an error occurs during workspace mapping
    onWorkspaceMappingCompletion, // This function is called when workspace mapping is completed
    onResourceGroupCreationError, // This function is called when an error occurs during resource group creation
    onSubscriptionRegistrationError, // This function is called when an error occurs during subscription registration
    onAccountFetchError, // This function is called when an unhandled error occurs during account fetching of accounts
}: SetupAndProvisionCapacityProps) {
    const newWorkspaceName = 'default';
    const PROVISION_ATTEMPT_COUNT = 20;
    const PROVISION_ATTEMPT_INTERVAL = 5000;
    const PROVISION_WORKSPACE_CONFIRM_INTERVAL = 5000;
    const SUBSCRIPTION_REGISTRATION_ATTEMPT_COUNT = 20;
    const SUBSCRIPTION_REGISTRATION_ATTEMPT_INTERVAL = 5000;

    const tenantInfo = useGetTenantInfo();
    const {refetch: getWorkspaceBeforeFre} = useGetWorkspacesForAdminFre({
        enabled: false,
    });

    const {refetch: getAccount} = useGetAccount({
        enabled: false,
    });

    const {data: workspaceInfo, refetch: getWorkspaces} = useGetWorkspaces({
        enabled: !!tenantInfo?.data?.freRequirements?.freRequirementsMet,
    });

    const {mutateAsync: registerSubscriptionWithProvider} = useRegisterProviderWithSubscription();
    const {mutateAsync: mapWorkspace} = useUpdateWorkspace();
    const {mutateAsync: provisionWorkspace} = useCreateWorkspace();
    const {mutateAsync: createResourceGroup} = useCreateResourceGroup();
    const {mutateAsync: provisionAccount} = useProvisionAccount(getCurrentTenantId());
    const {mutateAsync: createCapacity} = useCreateCapacity(
        subscription || '',
        resourceGroup || '',
        capacityName || '',
    );

    const {refetch: refetchWorkspaceByName} = useGetWorkspaceByName(newWorkspaceName, {
        refetchOnMount: true,
        enabled:
            !!tenantInfo?.data?.freRequirements?.freRequirementsMet || !!shouldPreLoadWorkspace,
    });

    // This function creates a new resource group if it doesn't exist
    const createResourceGroupIfNew = async () => {
        const resourceGroupRequest = {
            location: region || '',
            subscriptionId: subscription || '',
            resourceGroupName: resourceGroup || '',
        };
        try {
            await createResourceGroup(resourceGroupRequest);
        } catch (error) {
            onResourceGroupCreationError?.(error);
        }
    };

    // This function creates a new account
    const createAccountAsync = async () => {
        try {
            await provisionAccount(null);
            if (!isCapacityProvisioned) {
                // Try to register the subscription with the provider. The error handling is done internally
                const registrationResult = await provisionSubscriptionWithProvider();

                if (!registrationResult) {
                    // If the registration failed we return. The
                    return;
                }
                await createCapacityAsync();
            } else {
                await checkAndProvisionWorkspace();
            }
        } catch (error) {
            onAccountCreationError?.(error);
        }
    };

    // This function checks if a workspace exists and provisions a new one if it doesn't
    const checkAndProvisionWorkspace = async () => {
        if ((workspaceInfo?.count ?? 0) > 0 && workspaceInfo?.value?.[0]?.name !== undefined) {
            if (!skipWorkspaceAssociation) {
                await mapExistingWorkspaceAsync(workspaceInfo.value?.[0].name);
            } else {
                onInitialAccountAndWorkspaceProvisioningSuccess?.();
                onWorkspaceMappingCompletion?.();
                return;
            }
        } else {
            await provisionAndMapNewWorkspaceAsync();
        }
    };

    // This function creates a new capacity
    const createCapacityAsync = async () => {
        try {
            const capacityRegion =
                !!MedeinaVariables.DefaultCapacityRegion &&
                MedeinaVariables.DefaultCapacityRegion !== 'none'
                    ? MedeinaVariables.DefaultCapacityRegion
                    : region || '';

            await createCapacity({
                name: capacityName || '',
                location: capacityRegion,
                properties: {
                    numberOfUnits: Number(capacityUnits),
                    crossGeoCompute: isCrossRegionAllowed
                        ? CrossRegionCompute.Allowed
                        : CrossRegionCompute.NotAllowed,
                    geo: geo || '',
                },
            });
            await checkAndProvisionWorkspace();
        } catch (error) {
            console.log({error});
            onCapacityCreationError?.(error);
            return;
        }
    };

    // This function provisions a new workspace and maps it to the capacity
    const provisionAndMapNewWorkspaceAsync = async () => {
        try {
            await provisionWorkspaceWithRetries();
            // Wait for the workspace to be created at Fidelis
            await new Promise((resolve) =>
                setTimeout(resolve, PROVISION_WORKSPACE_CONFIRM_INTERVAL),
            );

            // Get the workspace after the creation to confirm it was really created
            const workspace = await getWorkspaces();
            const workspaceName = workspace?.data?.value?.[0]?.name;
            if (!workspaceName || workspaceName !== newWorkspaceName) {
                // If we do not have a workspace after the creation and the retries we emit an error
                onWorkspaceProvisionError?.('No workspace found after creation');
                return;
            }

            await mapExistingWorkspaceAsync(newWorkspaceName);
        } catch (error) {
            onWorkspaceProvisionError?.(error);
        }
    };

    const provisionWorkspaceWithRetries = async () => {
        let isWorkspaceFound = false;
        for (let i = 0; i < PROVISION_ATTEMPT_COUNT && !isWorkspaceFound; i++) {
            try {
                // Attempt to provision the workspace
                await provisionWorkspace({
                    defaultWorkspaceName: newWorkspaceName,
                });
                // If the workspace was provisioned successfully, exit the function
                return;
            } catch (error) {
                // If the workspace was not provisioned successfully and we have no retries left, throw the error
                // Wait for 1 second before the next attempt
                await new Promise((resolve) => setTimeout(resolve, PROVISION_ATTEMPT_INTERVAL));
            }
        }
        if (!isWorkspaceFound) {
            throw new Error('Failed to provision workspace');
        }
    };

    const provisionSubscriptionWithProvider = async (): Promise<boolean> => {
        try {
            let subscriptionRegistered = false;
            for (var i = 0; i < SUBSCRIPTION_REGISTRATION_ATTEMPT_COUNT; i++) {
                // Attempt to register the subscription with the provider
                const result = await registerSubscriptionWithProvider({
                    subscriptionId: subscription || '',
                });

                // If the subscription was registered successfully, update the exist state
                subscriptionRegistered = result?.registrationState === 'Registered';

                if (subscriptionRegistered) {
                    // If the subscription was registered successfully, exit the loop
                    break;
                } else {
                    // Wait for 2 seconds before the next attempt, if the subscription was not registered
                    await new Promise((resolve) =>
                        setTimeout(resolve, SUBSCRIPTION_REGISTRATION_ATTEMPT_INTERVAL),
                    );
                }
            }

            if (!subscriptionRegistered) {
                // If the subscription is not registered even after repeated attempts, then we exit the capacity creation process
                throw new Error('Failed to register subscription with provider');
            }

            return true;
        } catch (error) {
            onSubscriptionRegistrationError?.(error);
            return false;
        }
    };

    // This function maps an existing workspace to the capacity
    const mapExistingWorkspaceAsync = async (workspaceName: string) => {
        // Call the useGetWorkspaceByName function to get current workspace data
        const {data: workspaceData} = await refetchWorkspaceByName();

        const optInConfig = workspaceData?.workspaceOptInConfig;
        try {
            await mapWorkspace({
                name: workspaceName || '',
                capacity: {
                    type: 'CapacityReference',
                    referenceName: capacityName || '',
                },
                workspaceOptInConfig: {
                    isAllowModelImprovement: !!optInConfig
                        ? optInConfig.isAllowModelImprovement
                        : 'true',
                    isAllowProductImprovement: !!optInConfig
                        ? optInConfig.isAllowProductImprovement
                        : 'true',
                },
            });
            onWorkspaceMappingCompletion?.(); // Assuming there's a way to pass relevant data or handle completion
        } catch (error) {
            onWorkspaceMappingError?.(error);
        }
    };

    // This function is the main entry point for the setup and provisioning of capacity
    const provisionCapacityLinkage = async () => {
        if (isNewResourceGroup) {
            await createResourceGroupIfNew();
        }
        await createAccountAsync();
    };

    const provisionAccountAndWorkspaceAsync = async (request?: SetupAccountAndWorkspaceProps) => {
        let accountDetails: AccountDetails | undefined;

        if (MedeinaFeatures.EnableGeoSelection) {
            try {
                const result = await getAccount();
                accountDetails = result.data;
            } catch (error) {
                if (error instanceof FidelisApiError) {
                    if (error.errorType !== FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS) {
                        onAccountFetchError?.(error);
                        return;
                    }
                } else if (Boolean(error)) {
                    onAccountFetchError?.(error);
                    return;
                }
            }
        }

        if (
            (!Boolean(accountDetails) && MedeinaFeatures.EnableGeoSelection) ||
            !MedeinaFeatures.EnableGeoSelection
        ) {
            // If we do not have an account we provision one. This is only if Geo Selection feature is available

            try {
                // Try to perform the account provisioning

                const provisionAccountParams = MedeinaFeatures.EnableGeoSelection
                    ? ({
                          configuredGeo: request?.selectedGeo,
                      } as ProvisionAccountRequest)
                    : null;

                await provisionAccount(provisionAccountParams);
            } catch (error) {
                // If there is an error we emit the error
                onAccountCreationError?.(error);
                return;
            }
        }

        try {
            // Get the existing workspace
            const existingWorkspace = await getWorkspaceBeforeFre();

            // Step out if the workspace already exists
            if ((existingWorkspace?.data?.count ?? 0) > 0) {
                onInitialAccountAndWorkspaceProvisioningSuccess?.();
                return;
            }

            // If we do not have a workspace we provision one
            await provisionWorkspaceWithRetries();
            // Wait for the workspace to be created at Fidelis
            await new Promise((resolve) =>
                setTimeout(resolve, PROVISION_WORKSPACE_CONFIRM_INTERVAL),
            );
            // Get the workspace after the creation to confirm it was really created
            const workspace = await getWorkspaces();
            const workspaceName = workspace?.data?.value?.[0]?.name;
            if (!workspaceName || workspaceName !== newWorkspaceName) {
                // If we do not have a workspace after the creation and the retries we emit an error
                onWorkspaceProvisionError?.('No workspace found after creation');
            }
            // Return the control to the caller
            onInitialAccountAndWorkspaceProvisioningSuccess?.();
        } catch (error) {
            onWorkspaceProvisionError?.(error);
            return;
        }
    };

    return {
        provisionCapacityLinkage,
        provisionAccountAndWorkspaceAsync,
    };
}
