import {useEffect, useState, useCallback} from 'react';

export const useElementBySelector = (
    selector: string,
    // in ms
    maxTime?: number,
    searchOnMount = true,
    onFail?: () => void,
): [Element | null, () => void] => {
    const [element, setElement] = useState<Element | null>(null);
    const [startTime, setStartTime] = useState<number | null>(null);
    const [searchStarted, setSearchStarted] = useState<boolean>(false);

    useEffect(() => {
        if (searchOnMount) {
            setSearchStarted(true);
        }
    }, [searchOnMount]);

    useEffect(() => {
        if (searchStarted) {
            const intervalId = setInterval(() => {
                const el = document.querySelector(selector);
                if (el) {
                    setElement(el);
                    clearInterval(intervalId);
                } else {
                    if (onFail) {
                        onFail();
                    }
                }
            }, 1000);

            setStartTime(Date.now());

            return () => clearInterval(intervalId);
        }
    }, [selector, searchStarted]);

    useEffect(() => {
        if (!searchStarted && maxTime && startTime && Date.now() - startTime >= maxTime) {
            setSearchStarted(true);
        } else if (searchStarted && maxTime && startTime && Date.now() - startTime >= maxTime) {
            setElement(null);
        }
    }, [maxTime, startTime, searchStarted]);

    const fireSearch = useCallback(() => {
        setSearchStarted(true);
    }, []);

    return [element, fireSearch];
};
