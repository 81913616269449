import React from 'react';
import {Text, ToolbarDivider, Avatar} from '@fluentui/react-components';
import useClasses from './ConnectorFileContent.styles';
import {ConnectorFileContentProps} from './ConnectorFileContent.types';
import {getFileIcon} from './ConnectorModalContent/Utils/FileUtilities';
import {DateTimeFormat} from '@/components/date';
import {useTranslation} from 'react-i18next';
import {FileIndexingStatus} from '@/api/files/files.types';

export default function ConnectorFileContent({fileDescriptor}: ConnectorFileContentProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const displayName = fileDescriptor?.fileName;

    return (
        <div className={classes.wrapper}>
            <div className={classes.iconCol}>
                <Avatar
                    icon={getFileIcon(fileDescriptor?.indexingStatus || FileIndexingStatus.NotSet)}
                    // badge={{status: 'available'}}
                    shape="square"
                    data-testid="avatar-icon"
                    aria-hidden
                />
            </div>
            <div className={classes.textCol}>
                <Text className={classes.skillsetNameText}>{displayName}</Text>
                <br />
                <div className={classes.subheaders}>
                    {fileDescriptor?.uploadedTime && (
                        <Text className={classes.descriptionText}>
                            {t('UploadedDateText')}
                            <DateTimeFormat date={fileDescriptor.uploadedTime} />
                        </Text>
                    )}
                    <ToolbarDivider vertical className={classes.hrule} />
                    <Text className={classes.descriptionText}>{fileDescriptor?.size}</Text>
                </div>
            </div>
        </div>
    );
}
