import {
    FidelisCollection,
    PageResponse,
    SystemData,
    CapacityUsageResponse,
    SortingDirection,
} from '../api';

export type GetCapacitiesResponse = FidelisCollection<Capacity>;
export type GetCapacityByNameResponse = Capacity;
export type GetAggregatedCapacityUsageResponse = PageResponse<HourlyCapacityUsage>;
export type GetHourlyUsageAggregateDataByDimensionsResponse = PageResponse<HourlyCapacityUsage>;

export type Capacity = {
    id: string;
    name: string;
    systemData: Partial<SystemData>;
    properties: Partial<CapacityProperties>;
    location: string;
    type?: string;
};

type CapacityProperties = {
    provisioningState: string;
    numberOfUnits: number;
    crossGeoCompute: string;
    expiresAt: string;
    geo: string;
};

export type CreateCapacityRequest = {
    name: string;
    location: string;
    properties: Partial<CapacityProperties>;
};

export type RegisterProviderRequest = {
    subscriptionId: string;
};

export type RegisterProviderResponse = {
    id: string;
    namespace: string;
    registrationPolicy: string;
    registrationState: string;
};

export type CreateCapacityResponse = {
    id: string;
    name: string;
    location: string;
    properties: CapacityProperties & {provisioningState: string};
};

export interface CapacityUsageExportResponse {
    fileBlob?: Blob;
    fileName?: string;
    isError: boolean;
}

export type GetAggregatedCapacityUsageRequest = {
    capacityResourceName: string;
    startTime: string;
    endTime: string;
};

export type HourlyCapacityUsage = {
    aggregateStartTime: string;
    assignedCapacity: number;
    usedCapacity: number;
    maxAllowedCapacity: number;
};

export type PatchCapacityRequest = {
    subscriptionId: string;
    resourceGroupName: string;
    capacityName: string;
    properties: Partial<CapacityProperties>;
};

export type DeleteCapacityRequest = {
    subscriptionId: string;
    resourceGroupName: string;
    capacityName: string;
};

export type PatchCapacityResponse = Capacity;

export enum CrossRegionCompute {
    Allowed = 'Allowed',
    NotAllowed = 'NotAllowed',
}

export type GetUsageDataFacetResponse = PageResponse<SearchFacet>;
export type GetAggregatedCapacityUsageByDimensionsResponse = CapacityUsageResponse<CapacityUsage>;

export type SearchFacet = {
    category: string;
    type: SearchFacetType;
    values: string[] | KeyValueFilterDetails[];
};

// This type is used to represent the selected filters from the filter drawer
export type SelectedSearchFacet = {
    category: string;
    type: SearchFacetType;
    values: string[];
};

export type KeyValueFilterDetails = {
    id: string;
    displayValue: string;
};

export enum SearchFacetType {
    Value = 'Value',
    KeyValue = 'KeyValue',
}

export enum CapacityResourceUsageType {
    Normal = 'Normal',
    PrivatePreview = 'PrivatePreview',
}

export type GetUsageDataFacetRequest = {
    resourceName: string;
    startDate: string;
    endDate: string;
};

export type GetAggregatedCapacityUsageByDimensionsRequest = {
    body: GetAggregatedCapacityUsageParameters;
    resourceName: string;
};

export type GetAggregatedCapacityUsageParameters = {
    startDate: string;
    endDate: string;
    users?: string[];
    experiences?: string[];
    invocationTypes?: string[];
    plugins?: string[];
    categories?: string[];
    invocationCategories?: string[];
    pageSize?: number;
    pageNumber?: number;
    usageType?: CapacityResourceUsageType;
    sortingDirection?: SortingDirection;
};

export type GetExportedUsageRequestParameters = {
    startDate: string;
    endDate: string;
    users?: string[];
    experiences?: string[];
    invocationTypes?: string[];
    plugins?: string[];
    categories?: string[];
    invocationCategories?: string[];
    usageType?: CapacityResourceUsageType;
    roundOffDecimals?: number;
    timeZoneId?: string;
};

export type CapacityUsage = {
    aggregateStartTime: string;
    assignedCapacity: number;
    usedCapacity: number;
    maxAllowedCapacity: number;
    userId: string;
    userName: string;
    sessionId: string;
    invocationType: string;
    invocationCategory: string;
    source: string;
    skillSetNames: string[];
    copilotExperience: string;
};

export type GetHourlyUsageAggregateDataByDimensionsParameters = {
    capacityResourceName: string;
};

export type GetHourlyUsageAggregateDataByDimensionsRequestPayload = {
    startDate: string;
    endDate: string;
    users?: string[];
    experiences?: string[];
    invocationTypes?: string[];
    plugins?: string[];
    invocationCategories?: string[];
    usageType?: CapacityResourceUsageType;
};
