import {SkillsetUploadType} from '@/api/skills';
import CopilotPluginUpload from './CopilotPluginUploadField';
import OpenAIPluginUpload from './OpenAIPluginUploadField';

interface SelectedPluginUploadFieldProps {
    pluginUploadType: SkillsetUploadType | undefined;
    failedValidationSettings: string[];
    isUrlUpload: boolean;
    onSwitchChangeHandler: (ev: any) => void;
    onFormValuesUpdated: (ev: any, config: string) => void;
    onFileTypeSelected: (ev: any, data: any) => void;
    onFileUploaded: (file: File | null) => void;
}

export default function SelectedPluginUploadField({
    pluginUploadType,
    failedValidationSettings,
    isUrlUpload,
    onSwitchChangeHandler,
    onFormValuesUpdated,
    onFileTypeSelected,
    onFileUploaded,
}: SelectedPluginUploadFieldProps) {
    if (pluginUploadType == SkillsetUploadType.Copilot) {
        return (
            <CopilotPluginUpload
                failedValidationSettings={failedValidationSettings}
                isUrlUpload={isUrlUpload}
                onSwitchChangeHandler={onSwitchChangeHandler}
                onFormValuesUpdated={onFormValuesUpdated}
                onFileTypeSelected={onFileTypeSelected}
                onFileUploaded={onFileUploaded}
            />
        );
    }

    if (pluginUploadType == SkillsetUploadType.OpenAI) {
        return (
            <OpenAIPluginUpload
                failedValidationSettings={failedValidationSettings}
                onFormValuesUpdated={onFormValuesUpdated}
            />
        );
    }

    return null;
}
