import React from 'react';
import {
    TourContext as TourContextType,
    TourState,
    TourStopState,
    ValidTourErrorPropCombination,
} from './Tour.types';
import {defaultControlOptions} from './defaults';

export const contextDefault: TourContextType = {
    tour: null,
    activeTour: false,
    tourAutoplay: false,
    tourState: TourState.empty,
    loadContent: () => {},
    setTour: (tour) => {},
    controls: defaultControlOptions,
    setControls: () => {},

    pauseTour: () => {},
    nextStop: () => {},
    prevStop: () => {},
    completeTourStop: () => {},
    currentStop: null,
    showOverlay: true,
    showExitOverlay: false,
    setShowExitOverlay: () => {},

    stopIndex: 0,
    resetTour: () => {},
    resetTourStop: (tourStopIndex) => {},
    exitTour: () => {},
    quitTour: () => {},
    setTourError: () => {},
    tourError: undefined,

    setTourErrorV2: (errorProps?: ValidTourErrorPropCombination): void => {},
    setDefaultV2TourError: () => {},
    v2TourError: null,
    errorPage: undefined,
    setTourStep: (stepName: string) => {},
    addTourSharedParameter: (key: Required<string>, value: Required<string>) => null,
    clearTourSharedParameter: (key: Required<string>) => null,
    getTourSharedParameter: (key: Required<string>): string | undefined => '',
    clearTourSharedParameters: () => null,
    showLoadingState(shouldShow: boolean = false): void {},
    loadingState: false,
    setTourState: (state: TourState) => {},
    visibleTourStopCount: () => -1,
    stopState: TourStopState.playing,
    setStopState: (state: TourStopState) => {},
};

const TourContext = React.createContext<TourContextType>(contextDefault);

export default TourContext;
