import {PromptType} from '@/api/prompts';
import {useMemo} from 'react';
import {PromptContentLabelProps, PromptContentLabelComponents} from './PromptContent.types';
import PromptContentPromptLabel from './PromptContentPromptLabel';
import PromptContentSkillLabel from './PromptContentSkillLabel';
import PromptContentUnknownLabel from './PromptContentUnknownLabel';

const Components: PromptContentLabelComponents = {
    [PromptType.Prompt]: PromptContentPromptLabel,
    [PromptType.Skill]: PromptContentSkillLabel,
    [PromptType.SkillDirect]: PromptContentSkillLabel,
    [PromptType.Context]: PromptContentUnknownLabel,
    [PromptType.Unknown]: PromptContentUnknownLabel,
};

export default function PromptContentLabel(props: PromptContentLabelProps) {
    const {prompt} = props;

    // Determine which child interaction component should be used.
    const LabelComponent = useMemo(() => {
        // Use the fallback component by default.
        let Component = PromptContentUnknownLabel;
        if (prompt?.promptType && Components[prompt?.promptType]) {
            Component = Components[prompt?.promptType];
        }
        return Component;
    }, [prompt]);

    return <LabelComponent {...props} />;
}
