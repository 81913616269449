const locators = {
    // sources/connector modal dialog locators
    sourcesDialog: "[data-testid='sources-dialog']",
    sourcesModalCloseButton: "[data-testid='tabbed-dialog-close-button']",

    // promptbar locators
    promptbarSourcesButton: "[data-testid='promptbar-sources-button']",
    promptbarPromptsButton: "[data-testid='promptbar-skill-menu-toggle']",
    promptbarMenu: "[data-testid='promptbarmenu']",

    // plugin locators
    pluginCategory: "[data-testid='skillset-category']",
    pluginCategoryExpansionButton: "[data-testid='skillset-category-expansion-button']",
    pluginHeaderAddPluginButton: "[data-testid='category-header-add-plugin-button']",
    unconfiguredPlugin: "[data-testid='unconfigured-skill-row-button']",
    addFirstCustomPluginButton: "[data-testid='add-first-plugin-button']",
};

export const togglePromptbarMenu = () => {
    const promptbarMenu = document.querySelector<HTMLButtonElement>(
        locators.promptbarPromptsButton,
    );
    promptbarMenu?.click();
};

export const openSourcesDialog = () => {
    const sourcesDialog = document.querySelector<HTMLButtonElement>(
        locators.promptbarSourcesButton,
    );
    sourcesDialog?.click();
};

export const closeSourcesDialog = () => {
    const sourcesDialog = document.querySelector<HTMLButtonElement>(
        locators.sourcesModalCloseButton,
    );
    sourcesDialog?.click();
};

export const isSourcesDialogOpen = () => {
    return document.querySelector(locators.sourcesDialog) !== null;
};

export const isPromptbarMenuOpen = () => {
    return document.querySelector(locators.promptbarMenu) !== null;
};

export const getMicrosoftPluginSection = () => {
    return document.querySelectorAll(locators.pluginCategory)[0];
};

export const getNonMicrosoftPluginSection = () => {
    return document.querySelectorAll(locators.pluginCategory)[1];
};

export const getCustomPluginSection = () => {
    return document.querySelectorAll(locators.pluginCategory)[3];
};

const getAddFirstCustomPluginButton = (): HTMLButtonElement | null => {
    return document.querySelector<HTMLButtonElement>(locators.addFirstCustomPluginButton);
};

const getHeaderAddPluginButton = (): HTMLButtonElement | null => {
    return document.querySelector<HTMLButtonElement>(locators.pluginHeaderAddPluginButton);
};

const getExpansionButton = (searchElement?: Element): HTMLButtonElement => {
    const searchContainer = searchElement || document;
    return searchContainer.querySelectorAll<HTMLButtonElement>(
        locators.pluginCategoryExpansionButton,
    )[0];
};

const getFirstUnconfiguredPlugin = (searchElement?: Element): Element => {
    const searchContainer = searchElement || document;
    return searchContainer.querySelectorAll<HTMLButtonElement>(locators.unconfiguredPlugin)[0];
};

export const getUnconfiguredNonMicrosoftPlugin = (): Element => {
    // the general non-microsoft plugin section
    const nonMicrosoftPluginSection = getNonMicrosoftPluginSection();

    // there may or may not be an unconfigured plugin visible on first load
    const initialPluginSetup = getFirstUnconfiguredPlugin(nonMicrosoftPluginSection);
    // if there is an unconfigured plugin visible, we should return it
    if (initialPluginSetup) {
        return initialPluginSetup;
    } else {
        // if there is no unconfigured plugin visible, we need to toggle the show more button if it exists
        const expansionButton = getExpansionButton(nonMicrosoftPluginSection);
        if (expansionButton) {
            expansionButton.click();
            // now that we've clicked the show more button, we should check for an unconfigured plugin again
            const expandedPluginSection = getNonMicrosoftPluginSection();
            const expandedPluginSetupButton = getFirstUnconfiguredPlugin(expandedPluginSection);
            if (expandedPluginSetupButton) {
                return expandedPluginSetupButton;
            } else {
                // if there is no unconfigured plugin visible after expanding the section, we should return the entire section instead
                expansionButton.click();
                return expandedPluginSection;
            }
        } else {
            // if we don't have an expansion button, we should return the entire section
            return nonMicrosoftPluginSection;
        }
    }
};

export const getAddCustomPluginButtonArea = (): Element => {
    // the general custom plugin section
    const customPluginSection = getCustomPluginSection();

    // If this is the first time the user is adding a custom plugin, the button will be in the main area
    // check for this first
    const addFirstCustomPluginButton = getAddFirstCustomPluginButton();
    if (addFirstCustomPluginButton) {
        return addFirstCustomPluginButton;
    } else {
        // if this button is not available check for the add button in the header
        const headerAddPluginButton = getHeaderAddPluginButton();
        if (headerAddPluginButton) {
            return headerAddPluginButton;
        } else {
            // if neither button is available, we should return the entire section
            return customPluginSection;
        }
    }
};

export default locators;
