import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    Container: {
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    TagsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '2',
        flexDirection: 'row',
        width: '95%',
    },
    ContainerEdit: {
        backgroundColor: tokens.colorSubtleBackgroundSelected,
    },
    ContainerShow: {},
    TagContainer: {
        height: '24px',
        minWidth: 'auto',
        ...shorthands.margin('4px'),
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    Tag: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
    },
    Badge: {
        height: '24px',
    },
    Button: {
        ...shorthands.padding('0px'),
        ...shorthands.border('0px'),
        minWidth: 'auto',
        backgroundColor: tokens.colorSubtleBackgroundPressed,
        height: '24px',
        ...shorthands.borderRadius(
            '0px',
            tokens.borderRadiusMedium,
            tokens.borderRadiusMedium,
            '0px',
        ),
    },
    Icon: {
        fontSize: tokens.fontSizeBase200,
        ...shorthands.padding('0px'),
        ...shorthands.border('0px'),
        minWidth: 'auto',
        backgroundColor: tokens.colorSubtleBackgroundPressed,
        ...shorthands.padding('6px'),
    },
    TagInput: {
        height: '20px',
        backgroundColor: tokens.colorSubtleBackgroundSelected,
        ...shorthands.margin('0px'),
        display: 'flex',
        ...shorthands.borderRadius(
            tokens.borderRadiusMedium,
            tokens.borderRadiusMedium,
            tokens.borderRadiusMedium,
            tokens.borderRadiusMedium,
        ),
        ...shorthands.borderWidth('0px'),
        ...shorthands.padding('5px', '16px', '7px', '16px'),
        ...shorthands.border('0px', 'none', 'transparent'),
        ...shorthands.outline('0px'),
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        fontFamily: tokens.fontFamilyBase,
        width: '100%',
    },
    EditIcon: {
        ...shorthands.margin('9px', '4px', '9px', '4px'),
    },
    EditTag: {
        ...shorthands.margin('1px', '6px', '3px', '12px'),
    },
    NoEditTag: {
        ...shorthands.margin('1px', '12px', '3px', '12px'),
    },
});
