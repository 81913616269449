import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {FileDeletionRequest} from './files.types';

export default function useDeleteFile() {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (body: FileDeletionRequest) => {
            return await customFetch<Response>(
                `/files/${body.FileName}`,
                {
                    method: 'DELETE',
                },
                true,
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['files']);
            queryClient.invalidateQueries(['files', 'files/usage/user']);
        },
    });
}
