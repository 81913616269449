import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    flexContainerColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    promptList: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        columnGap: tokens.spacingHorizontalM,
        gridRowGap: tokens.spacingHorizontalS,
    },
});
