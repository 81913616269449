/**
 * Spacing between columns(In px) for screens with width >= 1920px.
 */
export const LARGE_SCREEN_GUTTER_SIZE = 24;

/**
 * Spacing between columns(In px) for screens with width < 1920px.
 */
export const STANDARD_GUTTER_SIZE = 16;

/**
 * Total number of gutters in a row.
 */
export const TOTAL_GUTTERS = 11;

/**
 * Total number of columns in a row
 */
export const COLUMNS = 12;

export const PIXEL = 'px';

/**
 * Represents the different screen sizes.
 */
export enum ScreenSize {
    /**
     * Small screen size.
     * max-width: 479px
     */
    Small = 'sm',

    /**
     * Medium screen size.
     * min-width: 480px and max-width: 639px.
     */
    Medium = 'md',

    /**
     * Large screen size.
     * min-width: 640px and max-width: 1023px.
     */
    Large = 'lg',

    /**
     * Extra large screen size.
     * min-width: 1024px and max-width: 1365px.
     */
    XLarge = 'xlg',

    /**
     * Extra extra large screen size.
     * min-width: 1366px and max-width: 1919px.
     */
    XXLarge = 'xxlg',

    /**
     * Extra extra extra large screen size.
     * min-width: 1920px.
     */
    XXXLarge = 'xxxlg',
}

/**
 * Represents the different column widths for different screen sizes.(In px)
 */
export enum ColumnWidth {
    SmallScreen = 8,
    MediumScreen = 21.33,
    LargeScreen = 30.67,
    XLargeScreen = 60.75,
    XXLargeScreen = 75.83,
    XXXLargeScreen = 100,
}

export interface ScreenSizeRange {
    minWidth: number;
    maxWidth?: number;
}
export const ViewportBreakpoints: Record<ScreenSize, ScreenSizeRange> = {
    sm: {minWidth: 0, maxWidth: 479},
    md: {minWidth: 480, maxWidth: 639},
    lg: {minWidth: 640, maxWidth: 1023},
    xlg: {minWidth: 1024, maxWidth: 1365},
    xxlg: {minWidth: 1366, maxWidth: 1919},
    xxxlg: {minWidth: 1920, maxWidth: Infinity},
};

export const ViewportBreakpointsArray = [
    ScreenSize.XXXLarge,
    ScreenSize.XXLarge,
    ScreenSize.XLarge,
    ScreenSize.Large,
    ScreenSize.Medium,
    ScreenSize.Small,
];
