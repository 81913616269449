import {useMutation} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {CancelPromptbookExecutionRequest} from './promptbooks.types';

export default function useCancelEvaluation() {
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: CancelPromptbookExecutionRequest) => {
            const response = await customFetch<Response>(
                `/promptbookexecutions/${body?.promptbookExecutionId}/cancel`,
                {
                    method: 'POST',
                    body: {
                        SessionId: body?.sessionId,
                    },
                },
                true,
            );
            return response.ok;
        },
    });
}
