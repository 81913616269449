import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const caption1StrongClassNames = {
    root: 'fui-Caption1Strong'
};
/**
 * Styles for the root slot
 */ export const useCaption1StrongStyles = makeStyles({
    root: typographyStyles.caption1Strong
});
