import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    icon: {
        textAlign: 'center',
    },
    spacing: {
        marginLeft: '80px',
        marginRight: '80px',
    },
    footerSpacing: {
        marginTop: tokens.spacingVerticalXL,
    },
});
