import {Body1, Divider, Label} from '@fluentui/react-components';
import {PromptProps} from './PromptbookPrompt.types';
import useClasses from './PromptbookPrompt.styles';

export default function PromptbookPrompt(props: PromptProps) {
    const {prompt} = props;
    const classes = useClasses();
    return (
        <div className={classes.promptLabel} data-test-id="promptbook-prompt" tabIndex={0}>
            <div className={classes.promptNumber}>{props.index ? props.index! + 1 : 1}</div>
            <Body1>{prompt.content}</Body1>
        </div>
    );
}
