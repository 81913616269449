import React, {useContext, useCallback} from 'react';
import TourContext from '@/components/ui/Tour/TourContext';
import {Button, Tooltip, Caption1} from '@fluentui/react-components';
import {DividerTallRegular} from '@fluentui/react-icons';
import {BackIcon, NextIcon, ExitIcon, PlayIcon, PauseIcon} from '@/components/ui/icons';
import useClasses from './Controls.styles';

const Controls = () => {
    const classes = useClasses();
    const {
        nextStop,
        prevStop,
        tour,
        activeTour,
        stopIndex,
        controls,
        pauseTour,
        setShowExitOverlay,
        tourState,
        exitTour,
    } = useContext(TourContext);

    const {
        allowExit,
        allowSkip,
        allowPausePlay,
        showControls,
        showCounter,
        showExit,
        allowBack,
        showExitOnly,
    } = controls;
    const {tourStops} = tour || {tourStops: []};

    return activeTour && showControls ? (
        <>
            {/* Leaving this code commented for testing purposes */}
            {/* <Caption1 className={classes.control}>{tourState}</Caption1>
                    <DividerTallRegular className={classes.divider} /> */}
            {(showExit || showExitOnly) && (
                <Tooltip relationship="label" content="Exit to home">
                    <Button
                        onClick={exitTour}
                        appearance="subtle"
                        disabled={!allowExit}
                        icon={<ExitIcon />}
                        iconPosition="before"
                        className={classes.control}
                        aria-label="Exit tour"
                    >
                        Exit tour
                    </Button>
                </Tooltip>
            )}
            {!showExitOnly && (
                <>
                    <Tooltip relationship="label" content="Previous">
                        <Button
                            disabled={stopIndex === 0 || !allowBack}
                            onClick={() => prevStop()}
                            appearance="subtle"
                            aria-label="Previous"
                            icon={<BackIcon className={classes.control} />}
                        />
                    </Tooltip>
                    <Tooltip
                        relationship="label"
                        content={tourState === 'playing' ? 'Pause' : 'Play'}
                    >
                        <Button
                            onClick={pauseTour}
                            appearance="subtle"
                            disabled={!allowPausePlay}
                            icon={tourState === 'playing' ? <PauseIcon /> : <PlayIcon />}
                            iconPosition="after"
                            aria-label={tourState === 'playing' ? 'Pause' : 'Play'}
                            className={classes.control}
                        />
                    </Tooltip>
                    <Tooltip relationship="label" content="Next">
                        <Button
                            disabled={stopIndex === tourStops.length - 1 || !allowSkip}
                            onClick={nextStop}
                            appearance="subtle"
                            aria-label="Next"
                            icon={<NextIcon className={classes.control} />}
                        />
                    </Tooltip>
                </>
            )}
        </>
    ) : null;
};

export default Controls;
