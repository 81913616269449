import {tokens} from '@fluentui/react-components';
import {makeStyles, shorthands} from '@griffel/react';

export default makeStyles({
    loader: {
        display: 'flex',
        flexDirection: 'column',
        height: '1vh',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalXS),
        ...shorthands.margin(
            tokens.spacingVerticalXXL,
            tokens.spacingHorizontalXS,
            tokens.spacingVerticalXXL,
        ),
    },
    searchBox: {
        width: '100%',
        height: '40px',
        marginBottom: '28px',
        '& .fui-Combobox__expandIcon': {
            display: 'none',
        },
        '@media (max-width: 480px)': {
            marginBottom: '5px',
        },
    },
    listBox: {
        display: 'flex',
        maxHeight: '200px',
        height: 'auto',
        '@media (max-width: 480px)': {
            maxHeight: '100px',
            overflowY: 'auto',
        },
    },
});
