import useClasses from './DetailedEvaluationLogPanelContent.styles';
import React from 'react';
import {Markdown} from '@/components/ui';
import {DetailedEvaluationLogPanelContentProps} from './DetailedEvaluationLogPanel.types';

export default function DetailedEvaluationLogPanelContent({
    stepContent,
}: DetailedEvaluationLogPanelContentProps) {
    const classes = useClasses();
    return (
        // returning empty element if no stepContent is provided.
        <>
            {stepContent && (
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Markdown>{stepContent}</Markdown>
                    </div>
                </div>
            )}
        </>
    );
}
