import {Title3} from '@fluentui/react-components';
import useClasses from './components.styles';

export default function MarkdownH6({children, className, ...props}: any) {
    const classes = useClasses();

    return (
        <h6>
            <Title3 className={classes.h6}>{children}</Title3>
        </h6>
    );
}
