import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {PluginManagementInfo} from './plugin.types';
import {ErrorResponse} from '../api.types';

export default function useGetPluginManagementInfo(
    useQueryOptions?: Pick<UseQueryOptions<PluginManagementInfo>, 'enabled'>,
) {
    const {customFetch} = useFetch();

    return useQuery<PluginManagementInfo>(['settings', 'pluginManagementInfo'], {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        queryFn: async () => {
            const result = await customFetch<Response>(
                `/settings/pluginManagementInfo`,
                {
                    method: 'GET',
                },
                true,
            );

            if (!result.ok) {
                const error: ErrorResponse = {
                    code: result.status,
                    message: result.statusText,
                };

                throw error;
            }

            const data = await result.json();
            return data as PluginManagementInfo;
        },
        retry: 0,
        ...useQueryOptions,
    });
}
