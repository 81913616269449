import {mergeClasses} from '@griffel/react';
import useClasses from './SessionTile.styles';

interface SessionTileProps {
    className?: string;
    hero?: boolean;
}

export default function SessionTileEmpty({className, hero = false}: SessionTileProps) {
    const classes = useClasses();
    return (
        <div
            className={mergeClasses(
                classes.root,
                hero && classes.heroRoot,
                classes.emptyRoot,
                className,
            )}
        ></div>
    );
}
