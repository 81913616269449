import * as React from 'react';
import {Button, Switch, mergeClasses} from '@fluentui/react-components';
import {DeleteIcon} from '@/components/ui/icons';
import useClasses from '../../ConnectorModalContent.styles';
import {
    FileDeletionRequest,
    FileDescriptor,
    PatchFileDescriptorRequest,
    useDeleteFile,
    useUpdateFile,
} from '@/api/files';
import ConnectorFileContent from '../../ConnectorFileContent';
import {useTranslation} from 'react-i18next';

interface FileRowProps {
    file: FileDescriptor | undefined;
    onSwitchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    isLastRow: boolean;
    selected: boolean;
    componentId: string;
    componentRef: React.Ref<HTMLInputElement> | undefined;
    tenantEnabled: boolean;
}

export default function FileRow({
    file,
    onSwitchChange,
    isLastRow,
    selected,
    componentId,
    componentRef,
    tenantEnabled,
}: FileRowProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const {mutateAsync: deleteData} = useDeleteFile();
    const {mutate: updateData} = useUpdateFile();

    const handleDeleteClicked = (fileName: string) => {
        const fileDeletionRequest: FileDeletionRequest = {
            FileName: fileName,
        };
        deleteData(fileDeletionRequest).catch((error: any) => {
            // need to flush this out more
        });
    };

    const handleUpdateClicked = (file: FileDescriptor) => {
        const patchFileDescriptorRequest: PatchFileDescriptorRequest = {
            fileName: file.fileName,
            enabled: !file.enabled,
        };

        try {
            updateData(patchFileDescriptorRequest);
        } catch (error) {
            // TODO: handle this error
        }

        onSwitchChange({} as React.ChangeEvent<HTMLInputElement>); // Invoke the function
    };

    return (
        <div
            className={mergeClasses(classes.skillRow, isLastRow && classes.skillRowLast)}
            data-testid={'file-row'}
        >
            <ConnectorFileContent fileDescriptor={file} />
            <div className={classes.fileOptions}>
                <Button
                    className={classes.deleteButton}
                    data-testid={`${file?.fileName}-delete`}
                    icon={<DeleteIcon />}
                    onClick={() => {
                        if (file?.fileName) {
                            handleDeleteClicked(file.fileName);
                        }
                    }}
                    appearance="secondary"
                    aria-label={t('FilesTab.DeleteFile', {fileName: file?.fileName})}
                />

                <Switch
                    id={componentId}
                    data-testid={`${file?.fileName}-switch`}
                    ref={componentRef}
                    checked={file?.enabled || false}
                    onChange={() => {
                        handleUpdateClicked(file as FileDescriptor);
                    }}
                    disabled={!tenantEnabled}
                    labelPosition="before"
                    className={classes.switch}
                    aria-label={t('FilesTab.EnableFile', {fileName: file?.fileName})}
                />
            </div>
        </div>
    );
}
