import {InteractionType} from '@azure/msal-browser';
import {MsalAuthenticationTemplate} from '@azure/msal-react';
import {msalLoginRequest} from '@/util/msal';

interface AuthorizedTemplateProps {
    children: React.ReactNode;
}

interface ErrorComponentProps {
    error: any;
}

function ErrorComponent({error}: ErrorComponentProps) {
    return <p>An Error Occurred: {error.toString()}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function SuccessAuthResult({children}: {children: React.ReactNode}) {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={msalLoginRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            {children}
        </MsalAuthenticationTemplate>
    );
}
