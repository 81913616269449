import {Switch, SwitchOnChangeData, mergeClasses} from '@fluentui/react-components';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {ProgressiveSwitchProps} from './switch.types';
import useClasses from './ProgressiveSwitch.styles';

export default function ProgressiveSwitch(props: ProgressiveSwitchProps) {
    const {disabled: isDisabled, onClick, state, checked} = props;
    const {hasChangeFailed, isChangeSuccessful, isChanging} = state;
    const [disabled, setDisabled] = useState(isDisabled ?? false);
    const classes = useClasses();

    useEffect(() => {
        setDisabled(Boolean(isDisabled));
    }, [isDisabled]);

    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>, data: SwitchOnChangeData) => {
            if (!disabled) {
                const checked = data.checked;
                if (onClick) {
                    onClick(checked);
                }
            }
        },
        [disabled, onClick],
    );

    useEffect(() => {
        if (!disabled) {
            if (isChanging) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }
    }, [isChanging]);

    useEffect(() => {
        if (isChangeSuccessful) {
            setDisabled(false);
        }
    }, [isChangeSuccessful]);

    useEffect(() => {
        if (hasChangeFailed) {
            setDisabled(false);
        }
    }, [hasChangeFailed, checked]);

    return (
        <Switch
            data-test-id={props.dataTestId}
            data-testid={props.dataTestId || 'progressive-switch'}
            checked={checked}
            onChange={handleChange}
            aria-disabled={disabled ? 'true' : 'false'}
            label={props.label}
            labelPosition={props.labelPosition}
            aria-label={props.ariaLabel}
            className={mergeClasses(
                props.className && props.className,
                disabled && classes.disabledStyle,
            )}
            onClick={(e) => e.preventDefault()}
        ></Switch>
    );
}
