export enum DateRangeOptions {
    'AllTime' = 'AllTime',
    'Today' = 'Today',
    'Days7' = 'Days7',
    'Days14' = 'Days14',
    'Days30' = 'Days30',
    'CustomDateRange' = 'CustomDateRange',
}

export type DateRangeOption = keyof typeof DateRangeOptions;

export interface DateRange {
    startDate: Date;
    endDate: Date;
}

export interface CustomDateRangeEditorProps {
    onDateRangeChange: (dateRange: DateRange) => void;
    dateRange?: DateRange | string | undefined;
    onDismiss: () => void;
    isOpen: boolean;
}
// This is a generic search filter type that should be extended by other search filter types
export interface SearchFilterMenuProps {
    onSetFilterValue: (filterValue: DateRangeOption | DateRange) => void;
    filterValue: DateRangeOption | DateRange;
    filterName: string;
    filterLabel: string;
}

export interface DateFilterMenuProps extends SearchFilterMenuProps {}
