import React, {useEffect} from 'react';
import {Tour, useTour, Context as TourContext} from '@/components/ui/Tour';
import useCheckForOnboarding from '@/components/ui/Tour/useCheckForOnboarding';
import useClasses from './TourProviderWithContext.styles';

interface TourContextProps {
    pathname?: string;
    tour?: Tour;
    children: React.ReactNode;
}
// TODO - Integrate with the LayoutContext
// This is the level where we'll want to integrate with the LayoutContext so that when a tour's background option changes
// we can update the background of the app
export const TourProviderWithContext = (props: TourContextProps) => {
    const {pathname} = props;
    const classes = useClasses();
    const tour = useTour({tour: props.tour});
    const {checkForOnboarding, isReady} = useCheckForOnboarding(tour?.activeTour);

    useEffect(() => {
        checkForOnboarding();
    }, []);

    // check for error messages on the tour and load them over the children
    const {tourError} = tour || {};

    // Create a fake loading state while our check for onboarding is happening
    const tourValue = isReady ? tour : {...tour, activeTour: false, tour: null};

    return (
        <TourContext.Provider
            value={{
                ...tourValue,
            }}
        >
            {tourError && <div className={classes.alert}>{tourError}</div>}
            {props.children}
        </TourContext.Provider>
    );
};

export default TourProviderWithContext;
