import React, {useState, useEffect, useMemo} from 'react';
import {Popover, PopoverSurface, mergeClasses} from '@fluentui/react-components';
import {TourStopState} from '@/components/ui/Tour';
import {useViewportSize} from '@/components/ui/Grid';
import Beak from './Beak/Beak';
import {CoachmarkProps} from './Coachmark.types';
import useClasses from './Coachmark.styles';
import {PopoverView} from './Tour.types';

/**
 * The Coachmark component is a wrapper around the Popover component
 * that provides a small tooltip that points to a target element
 * when on a small screen so that the popover does not cover the target
 * element.
 *
 * @param {CoachmarkProps} props
 * @returns {React.ReactElement}
 */
function Coachmark(props: CoachmarkProps) {
    const {
        positioning,
        onFocus,
        open,
        stop,
        withArrow,
        alwaysShow,
        stopState,
        setStopState,
        ...rest
    } = props;

    const {position = 'above', target} = positioning || {};
    const classes = useClasses();

    const {sm: isSmallScreen} = useViewportSize();
    // this lets us know that nothing should be visible on the screen
    // during these states the stop may be running code to get the UI to a specific state
    // like opening a dialog or menu
    const inSetupOrTeardown =
        stopState === TourStopState.setup || stopState === TourStopState.teardown;

    const [showCoachmark, setShowCoachmark] = useState(isSmallScreen || alwaysShow);
    const useArrow = isSmallScreen ? false : withArrow;
    // the coachmark should only be shown if we are on small screens and not in setup or teardown
    // or else the coachmark may not be pointing to the correct target
    const isCoachmarkVisible = useMemo(
        () => showCoachmark && !inSetupOrTeardown,
        [showCoachmark, inSetupOrTeardown],
    );

    // add a listener for the enter or space key to hide the Coachmark if it is currently visible
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                // we want to stop the event from propagating so that the target element
                // this is to prevent the target element from triggering an action like a button click or closing a dialog
                e.stopPropagation();
                setStopState(TourStopState.playing);
                setShowCoachmark(false);
            }
        };

        if (isCoachmarkVisible) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isCoachmarkVisible]);
    // we want to make sure that Component is an instance of PopoverView
    // so we can pass the correct props to it
    const Component = stop?.popover?.content ?? null;
    let Element = null;
    if (Component !== null) {
        const ComponentElement = Component as PopoverView;
        Element = (
            <ComponentElement
                stopState={stopState}
                setStopState={setStopState}
                setShowCoachmark={setShowCoachmark}
            />
        );
    }
    return (
        <Popover
            positioning={positioning}
            trapFocus
            inertTrapFocus
            open={open}
            withArrow={useArrow}
            {...rest}
        >
            {isCoachmarkVisible ? (
                <PopoverSurface
                    className={classes.invisibleSurface}
                    aria-live="assertive"
                    data-testid="coachmark-beak-surface"
                >
                    <Beak position={position} onFocus={() => setShowCoachmark(false)} />
                </PopoverSurface>
            ) : (
                <PopoverSurface
                    className={mergeClasses(isSmallScreen && classes.smallSurface)}
                    aria-live="assertive"
                    data-testid="coachmark-normal-surface"
                >
                    {Element}
                </PopoverSurface>
            )}
        </Popover>
    );
}

export default Coachmark;
