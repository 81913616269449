import {useMutation} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {PromptbookSharingToken, PromptbookSharingTokenRequest} from './promptbooks.types';

export default function useSharePromptbook() {
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: PromptbookSharingTokenRequest) => {
            return await customFetch<PromptbookSharingToken>(
                `/promptbooks/${body.promptbookId}/sharingtoken`,
                {
                    method: 'POST',
                    body: {
                        ...body,
                        // TODO: Remove mandatory settings when new use cases are introduced.
                        role: 'viewer',
                        global: false,
                    },
                },
            );
        },
    });
}
