import {useQuery} from '@tanstack/react-query';
import {GetSessionSummaryV2Request, SessionSummaryResponse} from './sessions.types';
import useFetch from '../useFetch';
import MedeinaFeatures from '@/util/features';
import {Session} from './sessions.types';
import {useUpdateSessionSummary} from '@/api/sessions';

export default function useSummarizeSessionV2({
    sessionId,
    sessionData,
    enabled = true,
}: GetSessionSummaryV2Request) {
    const {customFetch} = useFetch();
    const {
        mutate: updateSessionSummary,
        isLoading: isLoadingSuggestions,
        isSuccess,
        isError,
    } = useUpdateSessionSummary();

    return useQuery({
        queryKey: ['fullsessionsummary', sessionId],
        queryFn: async () => {
            return await customFetch<SessionSummaryResponse>(`/sessions/${sessionId}/summarizeV2`, {
                method: 'GET',
            });
        },
        enabled,

        // This is expensive so don't refetch on browser state changes.
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (sessionSummaryResponse: SessionSummaryResponse) => {
            if (sessionSummaryResponse != null && MedeinaFeatures.SaveSessionSummary) {
                const updatedSession: Session = {
                    ...sessionData,
                    sessionId: sessionData?.sessionId || '',
                    name: sessionData?.name || '',
                    pinboardSummary: sessionData?.pinboardSummary || null,
                    sessionSummary: sessionSummaryResponse || null,
                    createdAt: sessionData?.createdAt || '',
                    updatedAt: sessionData?.updatedAt || '',
                };
                updateSessionSummary({
                    session: updatedSession,
                });
            }
        },
    });
}
