import * as React from 'react';
import {useEffect} from 'react';
import {useClasses} from './_3pOAuthRedirect.styles';

export default function CallbackPage() {
    const classes = useClasses();

    useEffect(() => {
        const handleOAuthRedirect = async () => {
            if (window.location.pathname === '/auth/v1/callback') {
                try {
                    const searchParams = new URLSearchParams(window.location.search);
                    const code = searchParams.get('code');
                    const state = searchParams.get('state');

                    window.opener.postMessage({code, state}, '*');
                } catch (error) {}
            }
        };

        handleOAuthRedirect();
    }, []); // Empty dependency array to run the effect only once

    return (
        <div className={classes.root}>
            <text>Your account has been successfully linked.</text>
        </div>
    );
}
