import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
        marginBottom: tokens.spacingVerticalXL,
    },
    dataLocationFieldSection: {
        marginBottom: tokens.spacingVerticalL,

        '& > div:first-of-type': {
            marginBottom: tokens.spacingVerticalSNudge,
        },
    },
});
