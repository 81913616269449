import {PromptbookAvailableFilterValue} from './PromptbookLibraryFilters/PromptbookAdvancedFilters.type';

export type PromptbookFiltersState = {
    expandedSearchBar: boolean;
    expandedFilterButton: boolean;
    isCancelButton: boolean;
    selectedChip: PromptbookFilterSelectedChip;
    availableAdvancedFilterValues: PromptbookAvailableFilterValue[];
};

export enum PromptbookFilterSelectedChip {
    All = 'ALL',
    GlobalPromptbooks = 'GLOBALPROMPTBOOKS',
    TenantPromptbooks = 'TENANTPROMPTBOOKS',
    MyPromptbooks = 'MYPROMPTBOOKS',
    FilterChecked = 'FILTERCHECKED',
}

export type PromptbookFiltersInput = {
    selectedChipIcon: (selectedChip: PromptbookFilterSelectedChip) => void;
    availableAdvancedFilterValues: PromptbookAvailableFilterValue[];
    shouldShowAdvancedFilter: boolean;
    handleSearchTextChange: (textFilter: string) => void;
    onSelectedAdvancedFiltersChange?: (
        selectedAdvancedFilters: PromptbookAvailableFilterValue[],
    ) => void;
    onAdvancedFilterVisibilityChange?: (expanded: boolean) => void;
};

export type PromptbookFiltersActions = {
    type:
        | 'AllPromptbooks'
        | 'GlobalPromptbooks'
        | 'TenantPromptbooks'
        | 'MyPromptbooks'
        | 'SetExpandedSearchBar'
        | 'CancelFilterClick'
        | 'FilterClick';
};
