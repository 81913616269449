import {useQuery, useQueryClient} from '@tanstack/react-query';
import {PageResponse} from '../api';
import useFetch from '../useFetch';
import {SkillsetDescriptor} from './skills.types';
import {featureFlags} from '@/util/hasStaticFeatureFlags';

export default function useGetSkillsets() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useQuery({
        // Not override default caching = 0 as we want to fetch skillsets when plugin modal is opened.
        // useCreateSkillset, useUpdateSkillset, useDeleteSkillset already call queryClient.invalidateQueries(['skillsets']) which will invalidate this and also other queries having queryKey starting with ['skillsets']
        queryKey: ['skillsets'],
        queryFn: async () => {
            const queryParams =
                featureFlags() !== undefined && featureFlags() !== null
                    ? `?featureFlags=${featureFlags()}`
                    : '';
            const page = await customFetch<PageResponse<SkillsetDescriptor>>(
                `/skillsets${queryParams}`,
                {
                    method: 'GET',
                },
            );
            // If displayName exists, sort based on displayName, otherwise sort based on name
            page.value = page.value.sort((a, b) =>
                a.displayName == null
                    ? a.name.localeCompare(b.displayName == null ? b.name : b.displayName)
                    : a.displayName.localeCompare(b.displayName == null ? b.name : b.displayName),
            );
            return page;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['skillsets', 'requirements']);
        },
    });
}
