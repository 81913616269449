import {DialogContent, DialogTitle} from '@fluentui/react-components';
import {InformationPanelProps} from './PortalAssistance.types';

export default function InformationPanel(props: InformationPanelProps) {
    return (
        <>
            <DialogTitle>{props?.title}</DialogTitle>
            <DialogContent>{props.content}</DialogContent>
        </>
    );
}
