import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    smallLengthCombo: {
        width: '70px',
        minWidth: tokens.spacingHorizontalNone,

        '& input': {
            width: '100%',
        },
    },
    button: {
        width: tokens.spacingVerticalXXXL,
    },
    buttonContainer: {
        display: 'flex',
    },
    pageSelectionContainer: {
        //All span except the last one whould have a space after it
        '& span:not(:last-child)': {
            marginRight: tokens.spacingHorizontalSNudge,
        },
    },
});
