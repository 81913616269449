import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {customFetch} from '../api';
import {AuthorizedResponse} from './app.types';
import {UserState} from '../user';

export default function useAuthorized(
    useQueryOptions?: Pick<UseQueryOptions<AuthorizedResponse>, 'enabled'>,
) {
    return useQuery({
        ...useQueryOptions,
        queryKey: ['auth'],
        queryFn: async (): Promise<AuthorizedResponse> => {
            // Typically, we're expecting that any user with a valid workspace will get a 204.
            // However, it is _not_ expected to be 204 in three main use cases:
            //   1. The user is truly unauthorized.
            //   2. The user is unauthorized because admin FRE was not completed yet.
            //   3. The user is unauthorized, but they are an admin who is capable of completing the admin FRE.
            const response = await customFetch<Response>(`/auth`, {method: 'GET'}, true);
            const responseStatus = Number(response.status);

            // This covers use case #3, where we fallback to checking whether the user is an admin.
            if (responseStatus !== 204) {
                const userInfo = await customFetch<UserState>(`/auth/userInfo`, {method: 'GET'});

                // If the user is an admin, they are authorized to load the app and onboard via admin FRE.
                if (userInfo.isAdmin) {
                    return {
                        isAuthorized: true,
                        authorizedCode: '204',
                    } as AuthorizedResponse;
                }
            }

            // Otherwise return the normal authorization response.
            let responseBody = null;
            if (responseStatus === 403) {
                if (parseInt(response.headers.get('Content-Length') || '0') !== 0) {
                    responseBody = await response.json();
                }
            }
            return {
                isAuthorized: responseStatus === 204,
                authorizedCode: responseStatus.toString(),
                message: responseBody?.Message,
            } as AuthorizedResponse;
        },
        retry: false,
        refetchInterval: 300_000,
        onError: (error) => {
            return;
        },
    });
}
