import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    categoryRow: {
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground1),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge, tokens.borderRadiusXLarge, 0, 0),
        marginLeft: tokens.spacingHorizontalM,
        marginRight: tokens.spacingHorizontalM,
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        backgroundColor: tokens.colorNeutralBackground5,
        paddingLeft: tokens.spacingHorizontalL,
        paddingRight: tokens.spacingHorizontalL,
        paddingTop: tokens.spacingVerticalM,
        paddingBottom: tokens.spacingVerticalS,

        '@media (max-width: 479px)': {
            marginLeft: '0',
            marginRight: '0',
            gridTemplateColumns: '1fr auto auto',
            gridTemplateRows: 'auto auto',
        },
    },
    categoryTextCol: {
        flexGrow: 1,
        marginTop: tokens.spacingVerticalXS,
    },
    categoryNameText: {
        fontSize: tokens.fontSizeBase400,
        color: tokens.colorNeutralForeground3,
        fontWeight: tokens.fontWeightSemibold,
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    skillsetAddCol: {
        marginTop: tokens.spacingVerticalMNudge,
        marginLeft: tokens.spacingHorizontalL,
        alignItems: 'center',

        '@media (max-width: 479px)': {
            marginRight: '0',
            marginTop: tokens.spacingVerticalSNudge,

            gridRowStart: '2',
            gridColumnStart: '2',
            gridColumnEnd: '2',
        },
    },
    switchColumn: {
        '@media (max-width: 479px)': {
            marginRight: '0',

            gridRowStart: '2',
            gridColumnStart: '3',
            gridColumnEnd: '3',
        },
    },
    switch: {
        fontWeight: 'bold',
        display: 'flex',
        marginLeft: tokens.spacingHorizontalMNudge,
        marginRight: tokens.spacingHorizontalM,

        '@media (max-width: 479px)': {
            marginLeft: '0',
            marginRight: '0',
        },
    },
});
