import {useQuery} from '@tanstack/react-query';
import {PageResponse} from '../api';
import useSessionsFetch from '../useSessionsFetch';
import {GetPinnedEvaluationsRequest, PinnedEvaluation} from './evaluations.types';

export default function useGetPinnedEvaluations({sessionId}: GetPinnedEvaluationsRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);
    return useQuery({
        queryKey: ['sessions', sessionId, 'pinnedevaluations'],
        queryFn: async () => {
            return await sessionsFetch<PageResponse<PinnedEvaluation>>(
                `${sessionId}/pinnedevaluations`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
