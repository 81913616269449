import {utcToZonedTime, format} from 'date-fns-tz';
import windowsZones from 'cldr-core/supplemental/windowsZones.json';

interface TimeZoneInfo {
    value: string;
    children: string;
}

function formatOffset(offsetStr: string, utcLabel: string): string {
    // assuming offsetStr is like '+02:00', we want to transform it to 'UTC+02:00'
    const sign = offsetStr[0];
    const offsetFormatted = `${utcLabel}${sign}${offsetStr.substring(1)}`;
    return offsetFormatted;
}

interface MapZoneInfo {
    mapZone: {
        _other: string;
        _territory: string;
        //contains the
        _type: string;
    };
}

interface buildTimeZoneListProps {
    locale: string;
    UTCLabel: string;
    translate: (city: string) => string;
}

export function buildTimeZoneList({locale, UTCLabel, translate}: buildTimeZoneListProps) {
    const timeZones: TimeZoneInfo[] = [];
    const timeZoneMap: Record<string, TimeZoneInfo> = {};
    // uncomment to regenerate our timezone.json file
    // we will need to do this when there's updated to cldr-core
    // const timeZoneCities: Record<string, Record<string, string>> = {};

    const mapTimezones: Array<MapZoneInfo> = windowsZones.supplemental.windowsZones.mapTimezones;
    mapTimezones.forEach((mapZoneInfo) => {
        // as we populate our dropdown list create a mapped object to prevent duplicates
        // we'll also use this for reverse lookup of the full name for the timezone
        const {mapZone} = mapZoneInfo;
        const {_other, _type} = mapZone;
        // a mapZone can have multiple ianaTimezoneIds, we'll want to split them and create a label for each
        const ianaTimezoneIds = _type.split(' ');
        // ianaTimezoneIds are what we'll want to use to create as our label to show the city name attached to the timezone
        const now = new Date();

        // use the first ianaTimezoneId as the entry for the mapZone in our timeZones list
        const ianaTimezoneId = ianaTimezoneIds[0];
        // we don't want to add duplicates, if this timezone is already in the map, skip it
        if (timeZoneMap[ianaTimezoneId]) {
            // console.log('Skipping duplicate timezone: ', ianaTimezoneId);
            return;
        }

        try {
            const zonedDate = utcToZonedTime(now, ianaTimezoneId);
            const offsetStr = format(zonedDate, 'XXX', {timeZone: ianaTimezoneId}); // Output format e.g. "+02:00"
            const formattedOffset = formatOffset(offsetStr, translate('UTC'));
            // construct the key for the city and timezone that we'll need for our translation function
            const cityKey = `${ianaTimezoneId}.cities`;
            const timezoneKey = `${ianaTimezoneId}.zone`;
            // grab the translated version of the city and timezone
            const exampleCities = translate(cityKey);
            const timezone = translate(timezoneKey);
            const citiesLabel = exampleCities ? ` - ${exampleCities}` : '';
            const label = `(${formattedOffset}) ${timezone}${citiesLabel}`;
            timeZones.push({
                value: ianaTimezoneId,
                children: label,
            });
            // store off of the main ianaTimezoneId
            timeZoneMap[ianaTimezoneId] = {
                value: ianaTimezoneId,
                children: label,
            };
            // uncomment to regenerate our timezone.json file
            // timeZoneCities[ianaTimezoneId] = {
            //     cities: exampleCities,
            //     zone: _other,
            // };
        } catch (e) {
            console.log(e);
        }
    });
    // This is the list of timezones you can use to export to timezones.json
    // uncomment this and the timeZoneCities lines above
    // console.log({timeZoneCities});
    return {timeZones, timeZoneMap};
}
