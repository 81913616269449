const geoMappings: Record<string, string> = {
    ANZ: 'Australia',
    LATAM: 'Brazil',
    CAN: 'Canada',
    EUR: 'Macro Region Geography 1 - EMEA',
    JPN: 'Japan',
    NAM: 'Macro Region Geography 3 - Americas',
    UK: 'United Kingdom',
};

export function getFullGeoName(shortName: string | undefined): string | undefined {
    if (!shortName) {
        return undefined;
    }
    return geoMappings[shortName.toUpperCase()];
}
