import {Persona} from '@fluentui/react-components';
import {MemberItemProps} from '../rbac.types';
import useClasses from '../MemberListItem/MemberListItem.styles';

interface UserItemProps {
    email: string;
}

export default function UserItem(props: UserItemProps & MemberItemProps) {
    const classes = useClasses();
    return (
        <Persona
            name={props.name}
            id={props.id}
            secondaryText={props.email}
            avatar={{color: 'colorful'}}
            className={classes.memberPersona}
        ></Persona>
    );
}
