function getRandomElementFromArray<T>(array: T[]) {
    if (array.length === 1) {
        return array[0];
    }
    if (array.length === 0) {
        return null;
    }
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export default getRandomElementFromArray;
