import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {NavigationBarHeight} from '@/components/App.styles';

export default makeStyles({
    fullSize: {
        height: '100%',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalM,
        overflowY: 'auto',
        maxWidth: '700px',
        maxHeight: `calc(100% - ${NavigationBarHeight * 2}px )`,
    },
    flexcontainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    promptbookNameAndDescription: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: tokens.spacingHorizontalS,
    },
    promptbookName: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: tokens.spacingHorizontalXS,
    },
    titleContainer: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    dismissIcon: {
        paddingLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase100,
    },
    promptbookBody: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalXXL,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    rightIcons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalXS,
        ...shorthands.flex(1, 1, 'auto'),
    },
    promptsContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
    },
    prompts: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalXS,
    },
    createdByContainer: {
        display: 'flex',
        rowGap: '8px',
        alignItems: 'center',
        color: tokens.colorNeutralForeground2,
    },
    promptbookDescription: {
        color: tokens.colorNeutralForeground1,
    },
});
