import {useCallback} from 'react';
import {SkillsetCatalogScope, SkillsetCategory, SkillsetDescriptor} from '../skills';
import useGetTenantInfo from './useGetTenantInfo';
import {useGetUserInfo} from '../app';

/**
 * This hook is used to determine whether the user is allowed to manage Plugin category skillsets.
 * @returns {isTenantScopeUploadAllowed} `isTenantScopeUploadAllowed` function that takes a `SkillsetDescriptor` and returns a boolean
 */
export default function useTenantScopePluginUpload() {
    const {data: tenantInfo, isFetched: isTenantInfoFetched} = useGetTenantInfo();
    const {data: userInfo, isSuccess: adminSuccess} = useGetUserInfo();

    const isTenantScopeUploadAllowed = useCallback(
        (descriptor?: SkillsetDescriptor): boolean => {
            if (!descriptor) {
                return false;
            }

            // Only Plugin category skillsets are affected by Tenant scope upload setting
            if (descriptor.category !== SkillsetCategory.Plugin) {
                return true;
            }

            // Only gate Tenant scope plugins by Tenant setting
            if (descriptor.catalogScope !== SkillsetCatalogScope.Tenant) {
                return true;
            }

            // If the user is an admin, they can manage Tenant plugins.
            if (adminSuccess && userInfo?.isAdmin) {
                return true;
            }

            // For non-admins, check the tenant setting.
            if (isTenantInfoFetched && tenantInfo) {
                // If setting not defined, default to allowing plugin upload
                if (tenantInfo.allowTenantPluginUpload == undefined) {
                    return true;
                }
                return tenantInfo.allowTenantPluginUpload;
            }

            return false;
        },
        [userInfo, adminSuccess, isTenantInfoFetched, tenantInfo],
    );

    return {isTenantScopeUploadAllowed};
}
