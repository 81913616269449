// Shared styles for stage layouts and fonts/type
import {makeStyles, shorthands} from '@fluentui/react-components';

export default makeStyles({
    popOver: {
        width: 'max-content',
        maxWidth: '288px',
    },
    controlRow: {
        marginTop: '100px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    stack: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});
