import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UpdateSkillsetRequest, Skillset} from './skills.types';
import {doSkillsetNamespaceInvalidation} from './skillsetUtils';

export default function useCreateSkillset() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async ({
            name,
            scope,
            format,
            body,
            contentType,
            contentUrl,
        }: UpdateSkillsetRequest) => {
            const queryParams = `?scope=${scope}&skillsetFormat=${format}`;
            const url = contentUrl ? `&skillsetUrl=${contentUrl}` : '';
            const headers = contentType ? {'Content-Type': contentType} : null;
            try {
                return await customFetch<Skillset>(
                    `/skillsets/${encodeURIComponent(name)}${queryParams}${url}`,
                    {
                        method: 'PUT',
                        body,
                        headersFromOptions: headers,
                    },
                );
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => doSkillsetNamespaceInvalidation(queryClient),
    });
}
