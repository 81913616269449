import {useQuery} from '@tanstack/react-query';
import {
    GetSessionPinnedRequest,
    GetSessionSummarizeRequest,
    PinnedSummaryResponse,
} from './sessions.types';
import useSessionsFetch from '../useSessionsFetch';
import {isEmpty} from 'lodash-es';
import MedeinaFeatures from '@/util/features';

export default function useSummarizeSession({
    sessionId,
    enabled = true,
}: GetSessionSummarizeRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);
    return useQuery({
        queryKey: ['sessionSummary', sessionId],
        queryFn: async () => {
            return await sessionsFetch<string>(`${sessionId}/summarize`, {
                method: 'GET',
            });
        },

        // This is expensive so don't refetch on browser state changes.
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled,
    });
}

// TODO: Refactor this.
export function useSummarizeInvestigation({sessionId, pinnedItems}: GetSessionPinnedRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);
    let evaluationIds =
        pinnedItems?.reduce(
            (accumulator, pinnedEvaluation) =>
                accumulator + `evaluationIds=${pinnedEvaluation.evaluationId}&`,
            '',
        ) || '';

    if (!!evaluationIds) {
        evaluationIds = evaluationIds.slice(0, -1);
    }
    return useQuery({
        queryKey: ['sessionSummary', sessionId, 'pinnedItems', pinnedItems],
        queryFn: async () => {
            return await sessionsFetch<PinnedSummaryResponse>(
                `${sessionId}/pinnedevaluations/summarize?${evaluationIds}`,
                {method: 'GET'},
            );
        },
        enabled: !MedeinaFeatures.SavePinboardSummary && !!pinnedItems && !isEmpty(pinnedItems),

        // This is expensive so don't refetch on browser state changes.
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });
}
