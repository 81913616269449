import {PromptbookDescriptor, PromptbookPrompts} from '@/api/promptbooks';

export interface PromptbookPageProps {
    promptbookId: string;
}

export const EMPTY_PROMPTBOOK_PROMPT_CONTENT: string = 'AddPromptText';

export enum PromptbookCloseResult {
    success = 'success',
    failed = 'failed',
    closed = 'closed',
}

export enum PromptbookOperationMode {
    Create = 'Create',
    Edit = 'Edit',
    Duplicate = 'Duplicate',
}

export interface PromptbookFormProps {
    promptbook?: PromptbookDescriptor;
    sessionId: string;
    promptIds: string[];
    open: boolean;
    promptbookId: string;
    mode: PromptbookOperationMode;

    // Optional fields for Open/Close functionality
    onClose?: (createResult: PromptbookCloseResult, promptbookDefn?: PromptbookDescriptor) => void;
    onSuccess?: () => void;
}

export interface PromptbookUpsertFormProps {
    promptbook: PromptbookDescriptor;
    open: boolean;
    isEdit: boolean;

    // Optional fields for Open/Close functionality
    onClose?: (createResult: PromptbookCloseResult, promptbookDefn?: PromptbookDescriptor) => void;
    onSuccess?: () => void;
}

export interface PromptbookPromptListProps {
    promptDefns: PromptbookPrompts[];
    isReadOnly?: boolean;

    onPromptDataChange?: (promptDefns: PromptbookPrompts[]) => void;
}

export interface FormErrors {
    [key: string]: any;
}

export interface LinkCopiedDialogProps {
    open: boolean;
    onClose: () => void;
}

export interface ShareNoAccessDialogProps {
    errorStatus: number;
    isLoading: boolean;
    open: boolean;
    onClose: () => void;
}
