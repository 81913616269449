import {Home} from '@/components/sections';
import {NewHome} from '@/components/sections/home/NewHome';
import MedeinaFeatures from '@/util/features';

export default function HomePage() {
    if (MedeinaFeatures.NewHomePage) {
        return <NewHome />;
    }

    return <Home />;
}
