import {ChangeEvent, useCallback, useEffect, useReducer, useRef, useState} from 'react';
import useClasses from './PromptbookTags.styles';
import {
    Badge,
    Button,
    Input,
    Label,
    Overflow,
    OverflowItem,
    Tooltip,
    mergeClasses,
    useOverflowCount,
    useOverflowMenu,
} from '@fluentui/react-components';
import {AcceptIcon, DismissIcon, EditIcon, PersonIcon} from '@/components/ui/icons';
import {PromptbookTag} from '@/api/promptbooks';
import {useTranslation} from 'react-i18next';

export type PromptbookTagsProps = {
    edit: boolean;
    formData: PromptbookTag[];
    onFormDataChange?: (formData: PromptbookTag[]) => void;
};

type PromptbookTagsAction = {
    type: 'ADD_TAG' | 'DELETE_TAG' | 'UPDATE_TAG' | 'RESET_TAG' | 'RESET_ALL_TAGS';
    value?: string;
};

const PromptbookTags = (props: PromptbookTagsProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const classes = useClasses();
    const {t} = useTranslation('promptbooks');
    const initialState: PromptbookTagsProps = {
        edit: props.edit,
        formData: props.formData ?? [],
    };

    const [newData, setNewData] = useState<string>('');

    const reducer = (state: PromptbookTag[] = [], action: PromptbookTagsAction) => {
        const currentValues = [...state];
        switch (action.type) {
            case 'ADD_TAG':
                const tagValue = action.value || ''; // Provide a default value for action.value

                // Add new element to array if it doesn't already exist
                if (
                    !checkStringIsNullOrEmpty(tagValue.trim()) &&
                    !currentValues.flatMap((v) => v.tag).includes(tagValue)
                ) {
                    // Use the tagValue for tag and keep autogenerated as false
                    currentValues.push({tag: tagValue, autogenerated: false});
                }

                setNewData('');

                if (inputRef.current) {
                    inputRef.current.value = '';
                }

                return currentValues;
            case 'DELETE_TAG':
                return currentValues.filter((item: PromptbookTag) => item.tag !== action.value);
            case 'UPDATE_TAG':
                // Currently not supporting.
                return currentValues;
            case 'RESET_TAG':
                setNewData('');
                return currentValues;
            case 'RESET_ALL_TAGS':
                setNewData('');
                return [];
            default:
                console.log('unsupported action.');
                return state;
        }
    };

    const checkStringIsNullOrEmpty = (str: string | undefined | null) => {
        return typeof str === undefined || str === undefined || str === null || str === '';
    };

    const [tagsData, dispatch] = useReducer(reducer, initialState.formData);

    const handleSubmit = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            dispatch({type: 'ADD_TAG', value: e.currentTarget.value});
        } else if (e.key === 'Escape') {
            e.preventDefault();
            dispatch({type: 'RESET_TAG'});
        }
    }, []);

    useEffect(() => {
        // call formDataChange function whenever formData changes
        if (props.edit) {
            props.onFormDataChange?.(tagsData);
        }
    }, [tagsData]);

    useEffect(() => {
        if (!props.edit) {
            // When in view mode we want to show all the tags that are passed in.
            dispatch({type: 'RESET_ALL_TAGS'});
            props.formData &&
                props.formData.forEach((item) => dispatch({type: 'ADD_TAG', value: item.tag}));
        }
    }, [props.formData]);

    return (
        <div
            className={mergeClasses(
                classes.container,
                props.edit ? classes.containerEdit : classes.containerShow,
            )}
        >
            {tagsData && tagsData.length > 0 && (
                <Overflow minimumVisible={props.edit ? 100 : 1}>
                    <div className={classes.tagsContainer}>
                        {tagsData.map((item: PromptbookTag, i) => (
                            <OverflowItem
                                key={i}
                                id={i.toString()}
                                data-testid={'key-' + i.toString()}
                            >
                                <div className={classes.tagContainer}>
                                    <Badge
                                        className={classes.badge}
                                        shape="rounded"
                                        appearance="outline"
                                        color="informative"
                                        aria-label={item.tag}
                                        icon={<PersonIcon />}
                                        iconPosition="after"
                                    >
                                        {item.tag}
                                    </Badge>
                                    {props.edit && (
                                        <Button
                                            shape="rounded"
                                            className={classes.button}
                                            onClick={() =>
                                                dispatch({type: 'DELETE_TAG', value: item.tag})
                                            }
                                            aria-label={t('RemoveTag', {tagName: item.tag})}
                                            data-testid={'remove-tag-' + item.tag}
                                        >
                                            <DismissIcon className={classes.icon}></DismissIcon>
                                        </Button>
                                    )}
                                </div>
                            </OverflowItem>
                        ))}
                        <OverflowCount />
                    </div>
                </Overflow>
            )}
            {props.edit && (
                <Input
                    ref={inputRef}
                    type="text"
                    value={newData}
                    onChange={(e) => {
                        setNewData(e.currentTarget.value);
                    }}
                    onKeyDown={handleSubmit}
                    placeholder={t('AddTag')}
                    className={classes.tagInput}
                    data-testid="tag-inputbox"
                />
            )}
        </div>
    );
};

const OverflowCount = () => {
    const classes = useClasses();
    const count = useOverflowCount();
    const {t} = useTranslation('promptbooks');
    const {isOverflowing, overflowCount} = useOverflowMenu();

    if (!isOverflowing) {
        return null;
    }

    return (
        <div key={'badgeCount'} className={classes.tagContainer}>
            <Badge
                className={classes.badge}
                shape="rounded"
                appearance="outline"
                color="informative"
                aria-label={t('MoreTags', {tagCount: count})}
                iconPosition="after"
            >
                +{overflowCount}
            </Badge>
        </div>
    );
};

export default PromptbookTags;
