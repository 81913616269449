import {format as formatDateFns, parseISO} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import MedeinaFeatures from '../features';
import {hasStaticFeatureFlag} from '../hasStaticFeatureFlags';
import {readFromLocalStorage} from '@/api/user/user';

/**
 * Formats a date in a given format and time zone.
 *
 * @param {Date | string} date - The date to format. Can be a Date object or an ISO string.
 * @param {string} formatStr - The string of tokens to format the date.
 * @param {string} [timeZone='UTC'] - The time zone to interpret the date in. Defaults to 'UTC'.
 * @returns {string} - The formatted date string.
 */

const isDebugEnabled = hasStaticFeatureFlag(MedeinaFeatures.Debug);

const determineTimezone = (userTimezone: string) => {
    // If the user has set their time zone preference, use that
    // check if the env has timezone support enabled
    // and if it has not been disabled via ECS
    if (userTimezone) {
        return userTimezone;
    } else {
        // Otherwise, use the browser's time zone
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
};

function formatLocaleDate(date: Date | string, formatStr: string): string {
    const {preferences, useBrowserTime} = readFromLocalStorage();
    const {timezone: userTimeZone} = preferences;

    const determinedTimezone = determineTimezone(userTimeZone);

    if (isDebugEnabled) {
        console.log({
            determinedTimezone,
            userTimeZone,
            useBrowserTime,
        });
    }
    let dateObj: Date;
    try {
        // If the date is a string, attempt to parse it to a Date object
        dateObj = typeof date === 'string' ? parseISO(date) : date;
        // If the date is a string, parse it to a Date object
        // Convert the date to the specified time zone
        const zonedDate: Date = utcToZonedTime(dateObj, determinedTimezone);

        // we use the original date-fns format function to format the date
        return formatDateFns(zonedDate, formatStr);
    } catch (error) {
        console.error('Error parsing date:', error);
        // Log the error and return the original date string
        return `Invalid date format: ${date}`;
    }
}

export default formatLocaleDate;
