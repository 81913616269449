import {NavigationBarHeight} from '@/components/App.styles';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalM,
        overflowY: 'auto',
        maxWidth: '700px',
        height: `calc(90vh - ${NavigationBarHeight}px - ${tokens.spacingVerticalXS})`,
    },
    flexcontainer: {
        display: 'flex',
        flexDirection: 'row',
    },

    promptbook: {
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
    },

    promptbookName: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },

    promptbookDescription: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },

    visuallyHidden: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        position: 'absolute',
        width: '1px',
        height: '1px',
        marginTop: '-1px',
        paddingTop: '0',
        clip: 'rect(0, 0, 0, 0)',
        ...shorthands.borderTop('0px', 'none', 'none'),
    },

    promptbookIcon: {
        color: tokens.colorBrandForeground1,
        width: tokens.spacingHorizontalXL,
        height: 'auto',
        paddingTop: tokens.spacingVerticalL,
        paddingRight: tokens.spacingHorizontalXS,
    },
    runDisabled: {
        fontColor: tokens.colorNeutralForegroundDisabled,
    },
    dismissIcon: {
        paddingLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase100,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    promptbookBody: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalM,
        ...shorthands.flex(1, 1, 'auto'),
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalL,
    },
    promptTitle: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
    },
    prompts: {
        display: 'flex',
        flexDirection: 'column',
    },
    promptHeader: {},
    textareaWrapper: {
        backgroundColor: tokens.colorNeutralBackground3,
        marginTop: '-10px',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    labelStyle: {
        fontFamily: tokens.fontFamilyBase,
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left',
    },
    runButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    errorMessage: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalNone),
        maxWidth: '650px',
    },
});
