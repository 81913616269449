import * as React from 'react';
import {SkillsetDescriptor, SkillsetFilterTypes} from '@/api/skills';
import {MIN_COLLAPSED_SKILLSETS} from './SkillsetsSection.constants';
import {findSkillset} from '../../Utils/Skillsets.utils';
import {useTenantScopePluginUpload, useUserScopePluginUpload} from '@/api/tenant';
import {PageResponse} from '@/api/api.types';

/**
 * Custom hook that calculates the visible skillsets based on the provided filters and configurations.
 *
 * @param skillsetsInCategory - The list of skillsets filtered by category.
 * @param configurableSkillsets - The set of skillsets that can be configured.
 * @param configuredSkillsets - The set of skillsets that are already configured.
 * @param displayedSkillsetFilter - The filter type for displayed skillsets.
 * @returns An object containing the visible skillsets, expand/collapse button visibility, and count of additional skillsets.
 */
export const useVisibleCategorySkillsets = (
    skillsetsInCategory: SkillsetDescriptor[],
    configurableSkillsets: Set<string> | undefined,
    configuredSkillsets: Set<string>,
    displayedSkillsetFilter: SkillsetFilterTypes,
) => {
    const {isTenantScopeUploadAllowed} = useTenantScopePluginUpload();
    const {isUserScopeUploadAllowed} = useUserScopePluginUpload();

    const showExpandCollapseButton =
        displayedSkillsetFilter === SkillsetFilterTypes.All &&
        skillsetsInCategory.length > MIN_COLLAPSED_SKILLSETS;

    const moreSkillsetsCount = skillsetsInCategory.length - MIN_COLLAPSED_SKILLSETS;

    /**
     * Retrieves the visible skillsets based on the provided filters and configurations.
     *
     * @param showAll - A boolean indicating whether to show all skillsets or limit the number of displayed skillsets.
     * @returns The visible skillsets.
     */
    const getVisibleSkillsets = (showAll: boolean): SkillsetDescriptor[] => {
        const skillsetsToConfigure: SkillsetDescriptor[] = [];
        const skillsetsConfigured: SkillsetDescriptor[] = [];

        skillsetsInCategory.forEach((skillset) => {
            const canConfigure =
                isUserScopeUploadAllowed() &&
                isTenantScopeUploadAllowed(skillset) &&
                configurableSkillsets?.has(skillset.name);
            const isConfigured = configuredSkillsets.has(skillset.name);

            if (canConfigure && !isConfigured) {
                skillsetsToConfigure.push(skillset);
            } else {
                skillsetsConfigured.push(skillset);
            }
        });

        const sortedSkillsets = [...skillsetsConfigured, ...skillsetsToConfigure];

        // Only limit the number of skillsets displayed when the filter is set to All
        if (displayedSkillsetFilter === SkillsetFilterTypes.All && !showAll) {
            return sortedSkillsets.slice(0, MIN_COLLAPSED_SKILLSETS);
        }

        return sortedSkillsets;
    };

    return {getVisibleSkillsets, showExpandCollapseButton, moreSkillsetsCount};
};
