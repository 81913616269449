import {Title1} from '@fluentui/react-components';
import useClasses from './components.styles';

export default function MarkdownH3({children, className, ...props}: any) {
    const classes = useClasses();

    return (
        <h3>
            <Title1 className={classes.h3}>{children}</Title1>
        </h3>
    );
}
