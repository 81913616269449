import React, {useCallback} from 'react';
import {useLayoutScrollProps} from './Layout.types';

const defaultScrollOptions: ScrollToOptions = {
    behavior: 'smooth',
};

// generic handler to scroll different areas of the layout
// current used by the main content area and the side panel of investigations
function useLayoutScroll({ref}: useLayoutScrollProps) {
    const scrollToPosition = useCallback(
        (yPosition: number) => {
            if (ref?.current) {
                ref.current.scrollTo({...defaultScrollOptions, top: yPosition});
            }
        },
        [ref],
    );

    const scrollToBottom = useCallback(() => {
        if (ref?.current) {
            scrollToPosition(ref.current.scrollHeight);
        }
    }, [scrollToPosition, ref]);

    const scrollToTop = useCallback(() => {
        if (ref?.current) {
            scrollToPosition(0);
        }
    }, [scrollToPosition, ref]);

    // We accept a string for a selector and extract the y position to scroll to
    const scrollTo = useCallback(
        (elementSelector: string | number) => {
            if (typeof elementSelector === 'number') {
                scrollToPosition(elementSelector);
            } else {
                const selectedElement = document.querySelector(elementSelector);
                if (selectedElement) {
                    scrollToPosition(selectedElement.getBoundingClientRect().top);
                }
            }
        },
        [scrollToPosition],
    );
    return {to: scrollTo, toBottom: scrollToBottom, toTop: scrollToTop};
}

export default useLayoutScroll;
