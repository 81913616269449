import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    content: {
        color: tokens.colorNeutralForeground3,
        ...shorthands.padding(tokens.spacingVerticalSNudge, tokens.spacingHorizontalL),
    },
});
