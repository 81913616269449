import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXL),
        display: 'flex',
        columnGap: tokens.spacingHorizontalM,
        justifyContent: 'space-between',
        borderBottomLeftRadius: tokens.borderRadiusXLarge,
        borderBottomRightRadius: tokens.borderRadiusXLarge,
        overflowY: 'visible',
        backgroundColor: tokens.colorNeutralBackground1,
        '@media (max-width: 479px)': {
            ...shorthands.padding(
                tokens.spacingVerticalXL,
                tokens.spacingHorizontalS,
                tokens.spacingVerticalXL,
                tokens.spacingHorizontalS,
            ),
        },
    },
    feedbackBtn: {
        whiteSpace: 'nowrap',
        '& svg': {color: tokens.colorNeutralForeground2},
    },
    disableFeedbackBtn: {
        '& svg': {color: tokens.colorNeutralForegroundDisabled},
    },
    feedbackBtnTitle: {
        ...shorthands.overflow('hidden'),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '140px',
    },
    confirmBtn: {
        '&[aria-pressed="true"] svg': {
            color: tokens.colorPaletteGreenForeground1,
        },
        '&:hover svg': {
            color: tokens.colorPaletteGreenForeground1,
        },
    },
    offTargetBtn: {
        '&[aria-pressed="true"] svg': {
            color: tokens.colorPaletteDarkOrangeForeground1,
        },
        '&:hover svg': {
            color: tokens.colorPaletteDarkOrangeForeground1,
        },
    },
    reportBtn: {
        '&[aria-pressed="true"] svg': {
            color: tokens.colorPaletteRedForeground1,
        },
        '&:hover svg': {
            color: tokens.colorPaletteRedForeground1,
        },
    },
    popoverSurface: {
        paddingLeft: '0px',
        paddingRight: '0px',
        maxHeight: '80vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    menuButton: {justifyContent: 'flex-start'},
});
