import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    wrapper: {
        gridColumnSpan: '2',
        display: 'flex',

        '@media (max-width: 479px)': {
            gridColumnStart: '1',
            gridColumnEnd: 'none',
            gridRowStart: '1',
            gridRowEnd: '1',
        },
    },
    textCol: {
        flexGrow: 1,
        marginLeft: tokens.spacingHorizontalS,
        marginRight: tokens.spacingHorizontalS,
    },
    skillsetNameText: {
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
        marginRight: tokens.spacingHorizontalS,
        wordWrap: 'break-word',
        overflowWrap: 'anywhere',
    },
    descriptionText: {
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
    },
    iconCol: {
        flexShrink: 0,
        flexGrow: 0,
        marginLeft: tokens.spacingHorizontalXS,
        marginRight: tokens.spacingHorizontalM,

        '@media (max-width: 479px)': {
            marginInlineStart: '0',
            marginInlineEnd: tokens.spacingHorizontalXS,
        },
    },
    image: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    },
    editIcon: {
        color: tokens.colorBrandForeground1,
    },
    hrule: {
        ...shorthands.borderRadius('50%'),
        // ...shorthands.borderColor(tokens.colorTransparentStroke),
        // backgroundColor: tokens.colorTransparentBackground,
        // color: tokens.colorNeutralForeground4,
        pointerEvents: 'none',
        // ...shorthands.padding(tokens.borderRadiusNone),
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,
        '::before': {
            ...shorthands.borderColor(tokens.colorNeutralForeground2),
        },
        '::after': {
            ...shorthands.borderColor(tokens.colorNeutralForeground2),
        },
    },
    subheaders: {
        display: 'flex',
    },
});
