const imageCodeMap = {
    Azure: 'Azure.svg',
    CISA: 'CISA.svg',
    Confluence: 'Confluence.svg',
    Defender: 'Defender.svg',
    Entra: 'Entra.svg',
    Intune: 'Intune.svg',
    Mitre: 'Mitre.svg',
    'ThreatIntelligence.NVD': 'NVD.svg',
    ServiceNow: 'Copilot.svg',
    Splunk: 'Copilot.svg',
    'ThreatIntelligence.VirusTotal': 'Copilot.svg',
    Sentinel: 'Azure.svg',
    Fusion: 'Azure.svg',
    ADO: 'Azure.svg',
    ARM: 'Azure.svg',
} as {[key: string]: string};

export default imageCodeMap;
