import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground5,
        marginTop: tokens.spacingVerticalS,
        paddingTop: tokens.spacingVerticalXL,
        paddingBottom: tokens.spacingVerticalXXL,
        ...shorthands.border(tokens.strokeWidthThin, 'dashed', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        cursor: 'pointer',
    },
    rootHover: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground1Selected,
        marginTop: tokens.spacingVerticalS,
        paddingTop: tokens.spacingVerticalXL,
        paddingBottom: tokens.spacingVerticalXXL,
        ...shorthands.border(
            tokens.strokeWidthThick,
            'solid',
            tokens.colorNeutralForeground2BrandHover,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        cursor: 'pointer',
    },
    headingText: {
        color: tokens.colorNeutralForeground2,
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalXS,
        textAlign: 'center',
    },
    text: {
        color: tokens.colorNeutralForeground4,
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalXS,
        textAlign: 'center',
    },
    selectedFileText: {
        color: tokens.colorBrandForegroundLink,
        fontStyle: 'underline',
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalXS,
        textAlign: 'center',
    },
    errorText: {
        color: tokens.colorPaletteRedBackground3,
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalXS,
        textAlign: 'center',
    },
});
