import {SkillsetAuthType} from '@/api/skills';

export const AdminConfigAuthMappings: {[key in SkillsetAuthType]: [string, string]} = {
    [SkillsetAuthType.None]: ['AuthTypes.NoneTitle', 'AuthTypes.NoneDescription'],
    [SkillsetAuthType.Basic]: ['AuthTypes.BasicTitle', 'AuthTypes.BasicDescription'],
    [SkillsetAuthType.APIKey]: ['AuthTypes.APIKeyTitle', 'AuthTypes.APIKeyDescription'],
    [SkillsetAuthType.OAuthAuthorizationCodeFlow]: [
        'AuthTypes.OAuthAuthorizationCodeFlowTitle',
        'AuthTypes.OAuthAuthorizationCodeFlowDescription',
    ],
    [SkillsetAuthType.OAuthClientCredentialsFlow]: [
        'AuthTypes.OAuthClientCredentialsFlowTitle',
        'AuthTypes.OAuthClientCredentialsFlowDescription',
    ],
    [SkillsetAuthType.AAD]: ['AuthTypes.AADTitle', 'AuthTypes.AADDescription'],
    [SkillsetAuthType.ServiceHttp]: [
        'AuthTypes.ServiceHttpTitle',
        'AuthTypes.ServiceHttpDescription',
    ],
    [SkillsetAuthType.AADDelegated]: [
        'AuthTypes.AADDelegatedTitle',
        'AuthTypes.AADDelegatedDescription',
    ],
    [SkillsetAuthType.OAuthPasswordGrantFlow]: [
        'AuthTypes.OAuthPasswordGrantFlowTitle',
        'AuthTypes.OAuthPasswordGrantFlowDescription',
    ],
};

export function AuthTitleForType(authType: string) {
    return AdminConfigAuthMappings[authType as keyof typeof AdminConfigAuthMappings][0];
}

export function AuthDescriptionForType(authType: string) {
    return AdminConfigAuthMappings[authType as keyof typeof AdminConfigAuthMappings][1];
}
