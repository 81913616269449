import {useQueryClient} from '@tanstack/react-query';
import {
    EvaluationLog,
    EvaluationLogFormat,
    EvaluationState,
    EvaluationStepStatus,
    useGetEvaluation,
    useGetEvaluationLogs,
} from '@/api/evaluations';
import {EvaluationProps} from './Evaluation.types';
import DetailedEvaluationLogs from './DetailedEvaluationLogs';
import EvaluationLogsSummary from './EvaluationLogsSummary';
import {useState, useEffect} from 'react';

export default function EvaluationLogs({sessionId, promptId, evaluationId}: EvaluationProps) {
    const queryClient = useQueryClient();

    // refetching logs when evaluation is complete and latest logs are not fetched.
    const [areStepsRunningAfterEvaluationFulfilment, setAreStepsRunningAfterEvaluationFulfilment] =
        useState<boolean>(false);
    // Grab session interaction progress state.
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});

    // Determine whether we need to continue subscribing to updates.
    const shouldRefetchInteraction =
        areStepsRunningAfterEvaluationFulfilment ||
        (evaluation && evaluation.state === EvaluationState.Running);

    // If the debug level was changed, fetch the data if it wasn't already.
    const isNotCached =
        undefined ===
        queryClient.getQueryData([
            'sessions',
            sessionId,
            'prompts',
            promptId,
            'evaluations',
            evaluationId,
            'logs',
        ]);

    // Summarize these conditions into a simple "should fetch/refetch" flag.
    const enabled = shouldRefetchInteraction || isNotCached;

    // Retrieve events if the appropriate conditions are met.
    const {data: logs} = useGetEvaluationLogs({
        sessionId,
        promptId,
        evaluationId,
        enabled,
    });

    useEffect(() => {
        setAreStepsRunningAfterEvaluationFulfilment(
            (evaluation?.state === EvaluationState.Completed &&
                logs &&
                logs?.value.some(
                    (logItem: EvaluationLog) => logItem.stepStatus === EvaluationStepStatus.Running,
                )) ??
                true,
        );
    }, [evaluation?.state, logs]);

    //condition to toggle between older and newer experience goes here
    const shouldShowNewExperience =
        !!evaluation?.logFormat && evaluation.logFormat === EvaluationLogFormat.StepLogs;

    // If debugging is enabled, display interaction events.
    return (
        <>
            {evaluation && //checking evaluation to Remove flicker in UI before evaluation starts
                (!shouldShowNewExperience ? (
                    <EvaluationLogsSummary logs={logs} />
                ) : (
                    <DetailedEvaluationLogs
                        logs={logs}
                        evaluationState={evaluation?.state}
                        isEvaluationComplete={areStepsRunningAfterEvaluationFulfilment}
                        previewState={evaluation?.result?.previewState}
                    />
                ))}
        </>
    );
}
