import * as React from 'react';
import {AppInsightsContext} from './useAppInsights';

// This is only being used for catching exceptions, it doesn't handle displaying error messages.
export default class TelemetryErrorBoundary extends React.Component {
    static contextType = AppInsightsContext;
    context!: React.ContextType<typeof AppInsightsContext>;
    props!: {children: React.ReactNode};

    componentDidMount() {
        this.trackPageView();
    }

    componentDidCatch(error: Error) {
        this.context?.trackException({exception: error});
    }

    render() {
        this.trackPageView();
        return this.props.children;
    }

    trackPageView() {
        const name = window.location.pathname;
        // TODO: Should we automate some properties?
        const properties = {};

        this.context?.trackPageView({name, properties});
    }
}
