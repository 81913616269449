import React from 'react';
import {MessageBar, MessageBarBody, MessageBarTitle} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

function MSManagedBanner() {
    const {t} = useTranslation('admin');
    return (
        <MessageBar intent="warning">
            <MessageBarBody>
                <MessageBarTitle>{t('ManagedTenant')}</MessageBarTitle>
                {t('ManagedTenantDescription')}
            </MessageBarBody>
        </MessageBar>
    );
}

export default MSManagedBanner;
