import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {DeleteEvaluationFeedbackRequest} from './feedbacks.types';

export default function useDeleteEvaluationFeedback() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: DeleteEvaluationFeedbackRequest) => {
            return await customFetch(
                `/sessions/${body.sessionId}/prompts/${body.promptId}/evaluations/${body.evaluationId}/feedbacks`,
                {
                    method: 'DELETE',
                    body,
                },
            );
        },
        onSettled: (data, error, body) => {
            // Clear feedback cache.
            queryClient.removeQueries({
                queryKey: [
                    'sessions',
                    body.sessionId,
                    'prompts',
                    body.promptId,
                    'evaluations',
                    body.evaluationId,
                    'feedbacks',
                ],
                exact: true,
            });
        },
    });
}
