import {useQuery} from '@tanstack/react-query';
import {PageResponse} from '../api.types';
import useSessionsFetch from '../useSessionsFetch';
import {EvaluationLog, GetEvaluationLogsRequest} from './evaluations.types';

export default function useGetEvaluationLogs({
    sessionId,
    promptId,
    evaluationId,
    enabled = true,
}: GetEvaluationLogsRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useQuery({
        queryKey: ['sessions', sessionId, 'prompts', promptId, 'evaluations', evaluationId, 'logs'],
        queryFn: async () => {
            return await sessionsFetch<PageResponse<EvaluationLog>>(
                `${sessionId}/prompts/${promptId}/evaluations/${evaluationId}/logs`,
                {
                    method: 'GET',
                },
            );
        },
        refetchInterval: 500,
        enabled,
    });
}
