import React from 'react';
import useClasses from './useGridItemStyles.styles';
import {ScreenSize} from './GridConstants';
import {mergeClasses} from '@fluentui/react-components';

interface GridItemProps {
    sm?: number;
    md?: number;
    lg?: number;
    xlg?: number;
    xxlg?: number;
    xxxlg?: number;
    className?: string; // Allow custom classes to be passed
    children: React.ReactNode;
}

const GridItem: React.FC<GridItemProps> = ({
    sm,
    md,
    lg,
    xlg,
    xxlg,
    xxxlg,
    className = '',
    children,
}) => {
    const styles = useClasses();

    const getClassNames = () => {
        let classNames = className;

        if (sm) classNames = mergeClasses(classNames, styles[`${ScreenSize.Small}-${sm}`]);
        if (md) classNames = mergeClasses(classNames, styles[`${ScreenSize.Medium}-${md}`]);
        if (lg) classNames = mergeClasses(classNames, styles[`${ScreenSize.Large}-${lg}`]);
        if (xlg) classNames = mergeClasses(classNames, styles[`${ScreenSize.XLarge}-${xlg}`]);
        if (xxlg) classNames = mergeClasses(classNames, styles[`${ScreenSize.XXLarge}-${xxlg}`]);
        if (xxxlg) classNames = mergeClasses(classNames, styles[`${ScreenSize.XXXLarge}-${xxxlg}`]);

        return classNames.trim();
    };

    return <div className={getClassNames()}>{children}</div>;
};
export default GridItem;
