import {EvaluationState, useGetEvaluation} from '@/api/evaluations';
import {
    EvaluationFeedbackType,
    useDeleteEvaluationFeedback,
    useGetEvaluationFeedback,
} from '@/api/feedbacks';
import useClasses from './EvaluationToolbarNew.styles';
import ExportEvaluator from '../ExportEvaluator';
import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
    ToggleButton,
    mergeClasses,
} from '@fluentui/react-components';
import {EditIcon, UndoIcon} from '@/components/ui/icons';
import {FeedbackThanksMessage} from './EvaluationToolbar.types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import EvaluationFeedbackFormNew from './EvaluationFeedbackFormNew';
import EvaluationFeedbackThanksMessage from './EvaluationFeedbackThanksMessage';
import {useEvaluationContext} from '../EvaluationContext';
import {useTourable} from '@/components/ui/Tour';
import {MedeinaCopyButton} from '@/components/ui/Buttons/MedeinaCopyButton';
import {MedeinaTelemetryEvent} from '@/api/telemetry/telemetry.types';
import {useTranslation} from 'react-i18next';
import {
    ARIA_LABELS,
    BUTTON_LABELS,
    EvaluationFeedbackSubTypeDisplayValues,
    FEEDBACK_OPT_OUT_TOOLTIP,
    feedbackAttributesMap,
} from './EvaluationToolbar.constants';
import {useGetTenantInfo} from '@/api/tenant';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import {EditMode} from '../../prompts';
import {ErrorMessage} from '@/api/errors.types';
import PromptActionsMenu from './PromptActionsMenu';

interface EvaluationToolbarNewProps {
    onEdit: (mode: EditMode) => void;
    setErrMessage?: (message: ErrorMessage) => void;
}

export default function EvaluationToolbarNew(props: EvaluationToolbarNewProps) {
    const classes = useClasses();
    const {sm: isSmallScreen} = useViewportSize();
    const {sessionId, promptId, evaluationId} = useEvaluationContext();

    //TODO: Use offTarget tour with new toolbar
    const offtargetTourRef = useTourable({id: 'investigation-evaluation-toolbar-offtarget'});
    const tourRef = useTourable({id: 'investigation-evaluation-toolbar'});
    //Localisation translations
    const {t: tSession} = useTranslation('session');
    const {t: tCommon} = useTranslation('common');

    // Disable toolbar buttons till evaluation completes or if organisation has chosen to opt-out
    const {data: tenantInfo} = useGetTenantInfo();
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});

    const isEvaluationComplete = evaluation?.state === EvaluationState.Completed;
    const isFeedbackSubmissionAllowed = tenantInfo?.allowSubmitFeedback ?? true;

    //Feedback APIs - Get and Delete
    const {mutate: deleteFeedback} = useDeleteEvaluationFeedback();
    const {data: feedback} = useGetEvaluationFeedback(
        {sessionId, promptId, evaluationId},
        {enabled: isFeedbackSubmissionAllowed},
    );
    // To persist feedback primary type if feedback exists
    const feedbackType = useMemo(() => {
        return feedback?.feedbackType ?? EvaluationFeedbackType.Unknown;
    }, [feedback]);

    // Enable viewing of feedback form popover and feedback thanks message
    const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false);
    const [feedbackThanks, setFeedbackThanks] = useState<FeedbackThanksMessage | null>(null);

    // To understand if feedback already exists or not and display relevant ifnormation between Send/Update on the popover form
    const [isEditFeedback, setIsEditFeedback] = useState<boolean>(false);

    // To store user selected feedback type and create/update it on feedback form open without hampering any existing feedback
    const [selectedFeedbackType, setSelectedFeedbackType] =
        useState<EvaluationFeedbackType>(feedbackType);

    useEffect(() => {
        setSelectedFeedbackType(feedback?.feedbackType ?? EvaluationFeedbackType.Unknown);
    }, [feedback]);

    // To store the icon, title, class to be used for the feedback menu button
    const feedbackIcon = feedbackAttributesMap[feedbackType].icon;
    const [feedbackBtnTitle, feedbackBtnClass] =
        feedback?.feedbackSubTypes && feedback.feedbackSubTypes.length > 0
            ? [
                  feedback.feedbackSubTypes
                      .map((subtype) => tSession(EvaluationFeedbackSubTypeDisplayValues[subtype]))
                      .join(', '),
                  classes.feedbackBtnTitle,
              ]
            : [tSession(feedbackAttributesMap[feedbackType].title), ''];
    const feedbackBtnTooltipContent = isFeedbackSubmissionAllowed
        ? feedbackBtnTitle
        : tSession(FEEDBACK_OPT_OUT_TOOLTIP);
    const feedbackBtnRef = useRef<HTMLButtonElement>(null);

    // Actions on click of primary feedback type
    const handleFeedbackClick = useCallback(
        (feedbackType: EvaluationFeedbackType) => {
            setSelectedFeedbackType(feedbackType);
            setShowFeedbackForm(true);
        },
        [setShowFeedbackForm],
    );

    // Actions on click of edit feedback button
    const handleEditFeedback = useCallback(() => {
        setIsEditFeedback(true);
        setShowFeedbackForm(true);
        setFeedbackThanks(null);
    }, [setIsEditFeedback, setShowFeedbackForm, setFeedbackThanks]);

    // Actions on click of reset feedback button
    const handleResetFeedback = useCallback(() => {
        setIsEditFeedback(false);
        setShowFeedbackForm(false);
        setSelectedFeedbackType(EvaluationFeedbackType.Unknown);
        setFeedbackThanks(null);
        deleteFeedback({sessionId, promptId, evaluationId});
    }, [setIsEditFeedback, setShowFeedbackForm, setFeedbackThanks, deleteFeedback]);

    return (
        <>
            <div className={classes.root}>
                <span ref={tourRef} data-testid="feedbackTypes">
                    <Menu positioning={{align: 'center'}}>
                        <MenuTrigger disableButtonEnhancement>
                            <Tooltip content={feedbackBtnTooltipContent} relationship="label">
                                <ToggleButton
                                    className={mergeClasses(
                                        classes.feedbackBtn,
                                        classes[
                                            feedbackAttributesMap[feedbackType]
                                                .className as keyof typeof classes
                                        ],
                                        !isFeedbackSubmissionAllowed && classes.disableFeedbackBtn,
                                    )}
                                    icon={feedbackIcon}
                                    disabled={!isFeedbackSubmissionAllowed || !isEvaluationComplete}
                                    appearance="subtle"
                                    shape="circular"
                                    data-testid="feedbackTypes"
                                    checked={!!feedback}
                                    ref={feedbackBtnRef}
                                >
                                    <span className={feedbackBtnClass}>{feedbackBtnTitle}</span>
                                </ToggleButton>
                            </Tooltip>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                {feedback ? (
                                    <>
                                        <MenuItem icon={<EditIcon />} onClick={handleEditFeedback}>
                                            {tSession(BUTTON_LABELS.EditFeedback)}
                                        </MenuItem>
                                        <MenuItem icon={<UndoIcon />} onClick={handleResetFeedback}>
                                            {tCommon(BUTTON_LABELS.ResetFeedback)}
                                        </MenuItem>
                                    </>
                                ) : (
                                    <>
                                        <MenuItem
                                            className={classes.confirmBtn}
                                            icon={
                                                feedbackAttributesMap[
                                                    EvaluationFeedbackType.Confirm
                                                ].icon
                                            }
                                            onClick={() =>
                                                handleFeedbackClick(EvaluationFeedbackType.Confirm)
                                            }
                                            data-testid="feedbackTypes-confirm"
                                        >
                                            {tSession(
                                                feedbackAttributesMap[
                                                    EvaluationFeedbackType.Confirm
                                                ].title,
                                            )}
                                        </MenuItem>
                                        <MenuItem
                                            ref={offtargetTourRef}
                                            className={classes.offTargetBtn}
                                            icon={
                                                feedbackAttributesMap[
                                                    EvaluationFeedbackType.OffTarget
                                                ].icon
                                            }
                                            onClick={() =>
                                                handleFeedbackClick(
                                                    EvaluationFeedbackType.OffTarget,
                                                )
                                            }
                                            data-testid="feedbackTypes-offTarget"
                                        >
                                            {tSession(
                                                feedbackAttributesMap[
                                                    EvaluationFeedbackType.OffTarget
                                                ].title,
                                            )}
                                        </MenuItem>
                                        <MenuItem
                                            className={classes.reportBtn}
                                            icon={
                                                feedbackAttributesMap[EvaluationFeedbackType.Report]
                                                    .icon
                                            }
                                            onClick={() =>
                                                handleFeedbackClick(EvaluationFeedbackType.Report)
                                            }
                                            data-testid="feedbackTypes-report"
                                        >
                                            {tSession(
                                                feedbackAttributesMap[EvaluationFeedbackType.Report]
                                                    .title,
                                            )}
                                        </MenuItem>
                                    </>
                                )}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </span>
                {!isSmallScreen ? (
                    <span>
                        <ExportEvaluator
                            sessionId={sessionId}
                            promptId={promptId}
                            evaluationId={evaluationId}
                            isDisabled={!isEvaluationComplete}
                            isSummaryExport={false}
                            enableCopy={false}
                        />
                        <MedeinaCopyButton
                            copyText={evaluation?.result?.content ?? ''}
                            eventName={MedeinaTelemetryEvent.Evaluations.ExportAsCopy}
                            tooltipText={tSession(ARIA_LABELS.CopyResponse)}
                            isDisabled={!isEvaluationComplete}
                        />
                    </span>
                ) : (
                    <PromptActionsMenu {...props} />
                )}
            </div>
            <EvaluationFeedbackFormNew
                feedbackType={selectedFeedbackType}
                showFeedbackForm={showFeedbackForm}
                setShowFeedbackForm={setShowFeedbackForm}
                setFeedbackThanks={setFeedbackThanks}
                triggerRef={feedbackBtnRef}
                isEditFeedback={isEditFeedback}
                isFeedbackSubmissionAllowed={isFeedbackSubmissionAllowed}
            />
            <EvaluationFeedbackThanksMessage
                feedbackThanks={feedbackThanks}
                setFeedbackThanks={setFeedbackThanks}
                triggerRef={feedbackBtnRef}
            />
        </>
    );
}
