import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

// export const evaluationResultProperties = {
//     ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
// };

export default makeStyles({
    summaryRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase200,
    },
    content: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalL),
        ...shorthands.margin('0px', '0px', tokens.spacingVerticalXL, '0px'),
        fontSize: tokens.fontSizeBase400,
    },
    unpinButton: {
        ...shorthands.padding('0', tokens.spacingHorizontalXXXL, tokens.spacingVerticalXL),
    },
    collapseBtn: {
        ...shorthands.margin('0px', tokens.spacingHorizontalSNudge, '0px', '0px'),
    },
});
