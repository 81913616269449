import * as React from 'react';
import {Link, mergeClasses} from '@fluentui/react-components';
import useClasses from '../../ConnectorModalContent.styles';
import {useTranslation} from 'react-i18next';
import {DownIcon, UpIcon} from '@/components/ui/icons';

interface SkillsetsExpansionButtonProps {
    isExpanded: boolean;
    moreSkillsetsCount: number;
    onClick: () => void;
}

export default function SkillsetsExpansionButton({
    isExpanded,
    moreSkillsetsCount,
    onClick,
}: SkillsetsExpansionButtonProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');

    return (
        <div className={mergeClasses(classes.skillRow, classes.skillRowLast)}>
            <div className={classes.skillRowContent}>
                <Link
                    onClick={onClick}
                    className={classes.linkSvgButton}
                    data-testid="skillset-category-expansion-button"
                >
                    {isExpanded
                        ? t('Plugin.ShowLess')
                        : t('Plugin.ShowMore', {count: moreSkillsetsCount})}
                    {isExpanded ? <UpIcon /> : <DownIcon />}
                </Link>
            </div>
        </div>
    );
}
