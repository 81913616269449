import React from 'react';
import {useEffect, useId, useMemo, useRef} from 'react';
import {graphviz} from 'd3-graphviz';
import {tokens} from '@fluentui/react-components';
import {fromDot, toDot, attribute} from 'ts-graphviz';
import {GraphvizDotCodeBlockRenderer} from './GraphvizDotCodeBlock.types';
import {graphvizImages} from './GraphvizDotCodeBlock.data';
import {ColorScheme, useAppState} from '@/api/app';
import {generateSvgName} from './util';
import {select} from 'd3';
import useKeyboardZoomConfigurator from './useKeyboardZoomConfigurator';

const graphvizColorScheme = {
    [ColorScheme.Default]: ColorScheme.Dark,
    [ColorScheme.Dark]: ColorScheme.Dark,
    [ColorScheme.HighContrast]: ColorScheme.Light,
    [ColorScheme.Light]: ColorScheme.Light,
    [ColorScheme.OS]: ColorScheme.Light,
};

export default function useGraphvizRenderer(
    children: React.ReactNode,
    handleGraphSelected?: () => void,
): GraphvizDotCodeBlockRenderer {
    const {colorScheme} = useAppState();
    const imagePath = `/images/graphviz/${graphvizColorScheme[colorScheme]}`;

    // useId generates values like `:value:`, but d3 doesn't play well with `:` characters.
    const id = useId().replace(/:/g, '');
    const {handleVisualizationChange} = useKeyboardZoomConfigurator({
        id,
    });

    const rendererRef = useRef<HTMLDivElement>(null);

    const handleGraphAccesibilitySelection = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleGraphSelected?.();
        }
    };

    function handleKeyDownForGraph(event: any) {
        const keyboardEvent = event as KeyboardEvent;
        handleGraphAccesibilitySelection(keyboardEvent);
        handleVisualizationChange(keyboardEvent);
    }

    // Cache the dot string.
    const dot = useMemo<string>(() => {
        const model = fromDot(String(children ?? ''));

        // Re-map each node so that we can use our own visual style.
        model.nodes.forEach((node) => {
            model.node(node.id, {
                [attribute.shape]: 'plaintext',
                [attribute.tooltip]: `${node.attributes.get(attribute.label)}`,
                [attribute.label]: `<<TABLE BORDER="0" CELLSPACING="0"><TR><TD CELLPADDING="0"><IMG SRC="${imagePath}/${generateSvgName(
                    node,
                )}.svg" /></TD></TR><TR><TD CELLPADDING="0">${node.attributes.get(
                    attribute.label,
                )}</TD></TR>${
                    // @ts-ignore
                    node.attributes.get('_description') &&
                    // @ts-ignore
                    `<TR><TD CELLPADDING="0">${node.attributes.get('_description')}</TD></TR>`
                }</TABLE>>`,
            });
        });

        return toDot(model);
    }, [children]);

    useEffect(() => {
        // Generate the graphviz chart, but only in browsers.
        if (rendererRef.current) {
            const renderer = graphviz(`#${id}`, {
                height: rendererRef.current.offsetHeight,
                width: rendererRef.current.offsetWidth,
                fit: true,
            }).attributer(function (d) {
                // Apply Fluent font styles.
                if (d.tag == 'text') {
                    d.attributes['font-family'] = tokens.fontFamilyBase;
                    d.attributes['font-size'] = tokens.fontSizeBase100;
                }
            });

            // Add image definitions.
            graphvizImages.forEach((image) => {
                renderer.addImage(`${imagePath}/${image[0]}.svg`, image[1], image[2]);
            });

            renderer.on('end', () => {
                select(`#${id}`).attr('tabindex', 0);
                select(`#${id}`).attr(
                    'aria-label',
                    'Press the Enter Key to get instructions for navigating around the graph.',
                );
                select(`#${id}`).on('keydown', handleKeyDownForGraph);
            });

            renderer.renderDot(dot);
        }
    }, [rendererRef.current, dot, id]);

    return {id, ref: rendererRef};
}
