export const MEMBER_REMOVE_PROPS = {
    owner: {
        User: [],
        Role: [
            {
                id: '194ae4cb-b126-40b2-bd5b-6091b380977d',
                canBeDeleted: false,
            },
            {
                id: '62e90394-69f5-4237-9190-012177145e10',
                canBeDeleted: false,
            },
        ],
        Group: [],
    },
    contributor: {
        User: [],
        Role: [],
        Group: [],
    },
};
