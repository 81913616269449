import {Button, Link, Title1} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import useClasses from './OrganizationData.styles';
import {OpenIcon} from '../ui/icons';
import AuditLoggingOptingSwitch from './AuditLoggingOptingSwitch';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface AuditLoggingOptingSettingsProps {
    includeInfoLabel: boolean;
}

export default function AuditLoggingOptingSettings(props: AuditLoggingOptingSettingsProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    return (
        <div className={classes.wrapper}>
            <Title1 role="heading" className={classes.subtitle}>
                {t('ownerSettings.auditLoggingOpting.SectionHeading')}
            </Title1>
            <div className={classes.paragraphContentAudit}>
                {isWorkspacesTestingEnabled
                    ? t('ownerSettings.auditLoggingOpting.WorkspacesInfoContent')
                    : t('ownerSettings.auditLoggingOpting.InfoContent')}{' '}
                <Link
                    data-test-id="data-access-article-link"
                    href={MedeinaVariables.DataResidency}
                    target="_blank"
                    className={classes.linkContent}
                >
                    {t('ownerSettings.auditLoggingOpting.LearnMoreDataResidencyLinkText')}{' '}
                </Link>
            </div>
            <div className={classes.dataSwitcher}>
                <AuditLoggingOptingSwitch includeInfoLabel={props.includeInfoLabel} />
            </div>
            <div className={classes.purviewLink}>
                <Button
                    icon={<OpenIcon />}
                    iconPosition="after"
                    href={MedeinaVariables.AuditMicrosoftPurview}
                    as="a"
                    target="_blank"
                    data-test-id="audit-microsoft-purview-button"
                >
                    {t('ownerSettings.auditLoggingOpting.AuditMicrosoftPurviewLink')}
                </Button>
            </div>
        </div>
    );
}
