import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {DataLocationResponse} from './data.types';
import {featureFlags} from '@/util/hasStaticFeatureFlags';

export default function useGetTenantDataLocation(
    useQueryOptions?: Pick<UseQueryOptions<DataLocationResponse>, 'enabled'>,
) {
    const {customFetch} = useFetch();

    return useQuery({
        ...useQueryOptions,
        queryKey: ['datalocations'],
        queryFn: async () => {
            const featureFlagValues = featureFlags();
            const queryParams = !!featureFlagValues ? `?featureFlags=${featureFlagValues}` : '';

            return await customFetch<DataLocationResponse>(`/datalocations${queryParams}`, {
                method: 'GET',
            });
        },
    });
}
