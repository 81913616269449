import {useState, useLayoutEffect, useCallback, useMemo} from 'react';
import {ViewportBreakpoints, ViewportBreakpointsArray, ScreenSize} from './GridConstants';

interface ViewportSizeMap {
    sm: boolean;
    isSmallScreen: boolean;
    md: boolean;
    isMediumScreen: boolean;
    lg: boolean;
    isLargeScreen: boolean;
    xlg: boolean;
    isXLargeScreen: boolean;
    xxlg: boolean;
    isXXLargeScreen: boolean;
    xxxlg: boolean;
    isXXXLargeScreen: boolean;
    activeViewport: ScreenSize;
}

function getScreenSize(): Omit<ViewportSizeMap, 'activeViewport'> {
    const width = window.innerWidth;
    return {
        xxxlg: width >= ViewportBreakpoints.xxxlg.minWidth,
        isXXXLargeScreen: width >= ViewportBreakpoints.xxxlg.minWidth,
        xxlg:
            width < ViewportBreakpoints.xxxlg.minWidth &&
            width >= ViewportBreakpoints.xxlg.minWidth,
        isXXLargeScreen:
            width < ViewportBreakpoints.xxxlg.minWidth &&
            width >= ViewportBreakpoints.xxlg.minWidth,
        xlg: width < ViewportBreakpoints.xxlg.minWidth && width >= ViewportBreakpoints.xlg.minWidth,
        isXLargeScreen:
            width < ViewportBreakpoints.xxlg.minWidth && width >= ViewportBreakpoints.xlg.minWidth,
        lg: width < ViewportBreakpoints.xlg.minWidth && width >= ViewportBreakpoints.lg.minWidth,
        isLargeScreen:
            width < ViewportBreakpoints.xlg.minWidth && width >= ViewportBreakpoints.lg.minWidth,
        md: width < ViewportBreakpoints.lg.minWidth && width >= ViewportBreakpoints.md.minWidth,
        isMediumScreen:
            width < ViewportBreakpoints.lg.minWidth && width >= ViewportBreakpoints.md.minWidth,
        sm: width < ViewportBreakpoints.md.minWidth, // Assuming sm is everything smaller than md
        isSmallScreen: width < ViewportBreakpoints.md.minWidth, // Assuming sm is everything smaller than md
    };
}

const useViewportSize = () => {
    const [viewportSize, setViewportSize] = useState(getScreenSize());

    // it's important to know what the current highest viewport is for use cases we change the layout
    // and the number of items rendered in a grid
    const activeViewport = useMemo(() => {
        // look through the viewPortSize object in order and return the first key that is true
        // based on the order of ViewportBreakpointsArray
        // expected output is the first key that is true
        const detected = ViewportBreakpointsArray.find((key) => viewportSize[key]);
        // we should always have a detected viewport, but if we don't, we default to 'sm'
        return detected || ScreenSize.Small;
    }, [viewportSize]);

    const updateSize = useCallback(() => {
        setViewportSize(getScreenSize());
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        setViewportSize(getScreenSize());
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const result: ViewportSizeMap = useMemo(() => {
        return {...viewportSize, activeViewport};
    }, [viewportSize, activeViewport]);

    return result;
};

export default useViewportSize;
