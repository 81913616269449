import React, {useEffect, useState, useMemo} from 'react';
import {Label, Input, useId, mergeClasses, useAnnounce} from '@fluentui/react-components';
import useClasses from '../Setup.styles';

import ErrorLabel from '../ErrorLabel';
import {useTranslation} from 'react-i18next';
import formatCurrency from '@/util/currencyFormatterUtil';
import {AnnounceText} from '@/components/ui/AnnounceText';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {useViewportSize} from '@/components/ui/Grid';

interface CapacityUnitsProps {
    capacityUnits?: number;
    setCapacityUnits: (value: number) => void;
    minCapacity: number;
    maxCapacity: number;
    pricePerUnit: number;
    disabled?: boolean;
    computeUnitsStyle?: string;
    needsResponsiveStyles?: boolean;
}

export const CapacityUnitsField = ({
    capacityUnits = 0,
    setCapacityUnits,
    minCapacity,
    maxCapacity,
    pricePerUnit,
    disabled,
    computeUnitsStyle,
    needsResponsiveStyles = false,
}: CapacityUnitsProps) => {
    const classes = useClasses();
    const defaultLabelId = useId('compute-units');
    const errorLabelId = useId('compute-units-error');
    const maxCapacityUnitsErrorId = useId('max-capacity-units-error');
    const minCapacityUnitsErrorId = useId('min-capacity-units-error');
    const {t} = useTranslation('tours');
    // const [capacityUnits, setCapacityUnits] = useState<string>('');
    const [showMaxCapacityUnitsError, setShowMaxCapacityUnitsError] = useState(false);
    const [showMinCapacityUnitsError, setShowMinCapacityUnitsError] = useState(false);

    const capacityUnitsInputStyle = useMemo(() => {
        return Boolean(computeUnitsStyle)
            ? mergeClasses(classes.textFieldStyle, computeUnitsStyle)
            : classes.textFieldStyle;
    }, [computeUnitsStyle]);

    const {sm: isSmallScreen} = useViewportSize();

    const labelSize = Boolean(needsResponsiveStyles)
        ? isSmallScreen
            ? 'small'
            : 'medium'
        : 'medium';

    useEffect(() => {
        //  if the current value exceeds the threshold
        const value = capacityUnits;
        setShowMaxCapacityUnitsError(!isNaN(value) && value > maxCapacity);
        setShowMinCapacityUnitsError(!isNaN(value) && value < minCapacity);
    }, [capacityUnits, pricePerUnit, maxCapacity, minCapacity]);

    const estimatedCost = capacityUnits * 30 * 24 * pricePerUnit || 0;
    // to make the input field accessible we need to change the label aria-describedby to the input id and the error labels when appropriate
    const inputLabeledBy = showMaxCapacityUnitsError
        ? maxCapacityUnitsErrorId
        : showMinCapacityUnitsError
        ? minCapacityUnitsErrorId
        : defaultLabelId;

    const maxCapacityUnitsError = useMemo(
        () =>
            t('adminV2Fre.setup.MaxCapacityUnits', {
                0: maxCapacity,
            }),
        [maxCapacity],
    );
    const minCapacityUnitsError = useMemo(
        () =>
            t('adminV2Fre.setup.MinCapacityUnits', {
                0: minCapacity,
            }),
        [minCapacity],
    );

    const errorMessage = useMemo(() => {
        if (showMaxCapacityUnitsError) {
            return maxCapacityUnitsError;
        } else if (showMinCapacityUnitsError) {
            return minCapacityUnitsError;
        }
        return '';
    }, [
        showMaxCapacityUnitsError,
        showMinCapacityUnitsError,
        maxCapacityUnitsError,
        minCapacityUnitsError,
    ]);

    return (
        <div className={classes.field}>
            <div className={mergeClasses(classes.leftAlignedContainer, classes.label)}>
                <MedeinaInfoLabel
                    labelContent={
                        <Label
                            id={defaultLabelId}
                            data-test-id="compute-label"
                            required
                            size={labelSize}
                        >
                            {t('adminV2Fre.setup.SecurityComputeUnits')}
                        </Label>
                    }
                    infoContent={t('adminV2Fre.setup.ComputeUnitsInfo')}
                    size="small"
                />
            </div>
            <div className={classes.leftAlignedContainer}>
                <Input
                    className={capacityUnitsInputStyle}
                    disabled={disabled}
                    required
                    type="number"
                    data-test-id="compute-input"
                    aria-labelledby={inputLabeledBy}
                    aria-describedby={errorLabelId}
                    value={capacityUnits.toString()}
                    min={minCapacity}
                    max={maxCapacity}
                    onChange={(event, data) => {
                        // we only want whole numbers, if we get a float we ignore it
                        // this will allow the value 12. to be entered but not 12.5
                        // this is because the input type is number and the value is a string
                        // parseInt will ignore the decimal point
                        if (event.target.value.includes('.')) {
                            return;
                        } else {
                            setCapacityUnits(parseInt(data.value, 10));
                        }
                    }}
                />
            </div>
            {(showMaxCapacityUnitsError || showMinCapacityUnitsError) && errorMessage && (
                <ErrorLabel
                    id={errorLabelId}
                    className={classes.warningLabel}
                    needsResponsiveStyles={needsResponsiveStyles}
                >
                    {errorMessage}
                </ErrorLabel>
            )}

            {!showMinCapacityUnitsError && !showMaxCapacityUnitsError && !disabled && (
                <AnnounceText ariaLive="polite">
                    <Label className={classes.infoLabel} size={labelSize}>
                        {t('adminV2Fre.setup.ApproxMonthlyCost', {
                            0: formatCurrency(estimatedCost, 'USD', 'en-US'),
                        })}
                    </Label>
                </AnnounceText>
            )}
        </div>
    );
};

export default CapacityUnitsField;
