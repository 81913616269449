import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    promptLabel: {
        ...shorthands.padding(
            tokens.spacingHorizontalM,
            tokens.spacingHorizontalL,
            tokens.spacingHorizontalM,
            tokens.spacingHorizontalS,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: 'auto',
        backgroundColor: tokens.colorNeutralBackground5,
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalS,
        alignItems: 'center',
    },
    promptNumber: {
        ...shorthands.padding(tokens.spacingHorizontalMNudge, tokens.spacingHorizontalSNudge),
        alignSelf: 'center',
    },
});
