import * as React from 'react';
const FaceLying = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <g clipPath="url(#f884idg)">
                <path
                    fill="url(#f884id0)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f884id1)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f884id2)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f884id3)"
                    fillOpacity=".6"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f884id4)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f884id5)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f884id6)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f884id7)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <circle cx="9" cy="13" r="5" fill="url(#f884id8)" />
                <circle cx="19.5" cy="13.5" r="4.5" fill="url(#f884id9)" />
                <path
                    fill="#fff"
                    d="M10.5 16a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9Zm11 0a4.5 4.5 0 1 0 0-9a4.5 4.5 0 0 0 0 9Z"
                />
                <path fill="url(#f884idc)" d="M12 15a2 2 0 1 0 0-4a2 2 0 0 0 0 4Z" />
                <path fill="url(#f884idd)" d="M20 15a2 2 0 1 0 0-4a2 2 0 0 0 0 4Z" />
                <rect width="20" height="20" x="8" y="8" fill="url(#f884ida)" rx="10" />
                <rect width="19" height="5" x="13" y="15" fill="url(#f884ide)" rx="2.5" />
                <path
                    fill="url(#f884idb)"
                    fillRule="evenodd"
                    d="M29.739 19.989A3 3 0 0 1 27.764 15H29.5a2.5 2.5 0 0 1 .239 4.989Z"
                    clipRule="evenodd"
                />
                <path
                    stroke="url(#f884idf)"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M12 24c.333-.667 1.6-2 4-2s3.667 1.333 4 2"
                />
            </g>
            <defs>
                <radialGradient
                    id="f884id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(132.839 10.786 10.065) scale(37.5033)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f884id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.878 10.74 10.193) scale(38.9487)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f884id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(101.31 2.876 12.808) scale(17.8466 22.8581)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".788" stopColor="#F59639" stopOpacity="0" />
                    <stop offset=".973" stopColor="#FF7DCE" />
                </radialGradient>
                <radialGradient
                    id="f884id3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-29 29 -29 -29 18 14)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".315" stopOpacity="0" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="f884id4"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(77.692 -2.555 18.434) scale(28.1469)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".508" stopColor="#7D6133" stopOpacity="0" />
                    <stop offset="1" stopColor="#715B32" />
                </radialGradient>
                <radialGradient
                    id="f884id5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(7.5 10.99996 -7.97335 5.4364 16.5 16.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB849" />
                    <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f884id6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(11.49998 2 -2 11.49998 20.5 18)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA64B" />
                    <stop offset=".9" stopColor="#FFAE46" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f884id7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(43.971 -9.827 29.173) scale(59.0529)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".185" stopOpacity="0" />
                    <stop offset="1" stopOpacity=".4" />
                </radialGradient>
                <radialGradient
                    id="f884id8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(135 4.557 7.225) scale(9.74236 5.04232)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392108" />
                    <stop offset="1" stopColor="#C87928" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f884id9"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-5.75 5.75 -3.24597 -3.24597 23 10)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392108" />
                    <stop offset="1" stopColor="#C87928" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f884ida"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(8.5 0 0 4.87224 17.5 19)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EA7545" />
                    <stop offset="1" stopColor="#E86F48" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f884idb"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-.50001 1.7 -2.125 -.62502 30.5 16.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFDA66" />
                    <stop offset="1" stopColor="#FFDA62" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="f884idc"
                    x1="20.615"
                    x2="19.927"
                    y1="11"
                    y2="15.03"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#553B3E" />
                    <stop offset="1" stopColor="#3D2432" />
                </linearGradient>
                <linearGradient
                    id="f884idd"
                    x1="20.615"
                    x2="19.927"
                    y1="11"
                    y2="15.03"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#553B3E" />
                    <stop offset="1" stopColor="#3D2432" />
                </linearGradient>
                <linearGradient
                    id="f884ide"
                    x1="22.497"
                    x2="22.497"
                    y1="15.324"
                    y2="19.986"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2BD4B" />
                    <stop offset=".419" stopColor="#FFAE4B" />
                    <stop offset=".801" stopColor="#FF9B60" />
                    <stop offset="1" stopColor="#F57C6C" />
                </linearGradient>
                <linearGradient
                    id="f884idf"
                    x1="16"
                    x2="16"
                    y1="21"
                    y2="23"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4F3C43" />
                    <stop offset="1" stopColor="#512756" />
                </linearGradient>
                <clipPath id="f884idg">
                    <path fill="#fff" d="M0 0h32v32H0z" />
                </clipPath>
            </defs>
        </g>
    </svg>
);
export default FaceLying;
