import {useMutation} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {DocumentDownloadRequest, DocumentDownloadResponse} from './evaluations.types';

export default function useDocumentDownload() {
    const {customFetch} = useFetch();
    const getFileNameFromResponseHeaders = (response: Response): string => {
        return (
            response.headers
                .get('content-disposition')
                ?.split(';')
                ?.filter((x: String) => x.indexOf('filename') > -1)[0]
                ?.split('=')[1]
                ?.replace(/"/g, '') || ''
        );
    };

    const errorResponse = (): DocumentDownloadResponse => ({isError: true});

    return useMutation({
        mutationFn: async (request: DocumentDownloadRequest): Promise<DocumentDownloadResponse> => {
            try {
                var response = await customFetch<Response>(
                    `/sessions/${request.sessionId}/prompts/${request.promptId}/evaluations/${request.evaluationId}/export/table`,
                    {
                        method: 'POST',
                        body: {
                            startLinePosition: request.linePositions[0],
                            endLinePosition: request.linePositions[1],
                        },
                    },
                    true,
                );

                if (!response) {
                    return errorResponse();
                }

                const fileBlob = await response.blob();

                if (!fileBlob || !response.ok) {
                    return errorResponse();
                }

                return {
                    fileName: getFileNameFromResponseHeaders(response),
                    fileBlob: fileBlob,
                    isError: false,
                };
            } catch (error) {
                return errorResponse();
            }
        },
    });
}
