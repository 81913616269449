import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: `${tokens.shadow16}`,
        paddingTop: tokens.spacingVerticalXS,
        paddingBottom: tokens.spacingVerticalS,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground5),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        ...shorthands.flex(1, 1, 'auto'),
    },
    tabListStyles: {
        height: 'auto',
        maxHeight: '900px',
        width: 'auto',
        maxWidth: '800px',

        '@media (max-width: 479px)': {
            maxWidth: 'none',
            width: '100%',
            maxHeight: 'none',
            height: '100%',
        },
    },
    body: {
        height: '100%',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100vw',

        '@media (min-width: 480px) and (max-width: 639px)': {
            overflowY: 'auto',
        },

        '@media (max-width: 479px)': {
            overflowY: 'auto',
        },
    },
    connectedIcon: {
        color: tokens.colorNeutralForeground1,
    },
    toggleButton: {
        ...shorthands.padding(tokens.spacingHorizontalMNudge),
        backgroundColor: tokens.colorNeutralBackground3,
        maxWidth: 'auto',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3Hover,
        },
    },
    closeButton: {
        position: 'absolute',
        right: tokens.spacingHorizontalL,
        top: tokens.spacingHorizontalL,
        zIndex: 2,
    },
    overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorBackgroundOverlay,
        backdropFilter: 'blur(5px)',
        zIndex: 1,
    },
});
