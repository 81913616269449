import {WorkspaceFormProps} from './WorkspaceForm.types';
import {DialogContent, DialogActions, DialogTrigger, Button} from '@fluentui/react-components';
import React, {useCallback} from 'react';
import {useDeleteWorkspace} from '@/api/workspaces';
import {useTranslation} from 'react-i18next';
import useClasses from './WorkspaceForm.styles';
import useButtonClasses from '@/components/ui/Buttons/Button.styles';

function Delete(props: WorkspaceFormProps) {
    const {workspace, onClose, onCancel} = props;
    const classes = useClasses();
    const buttonClasses = useButtonClasses();
    const {t} = useTranslation('admin');
    const {t: tCommon} = useTranslation('common');
    const {mutate: deleteWorkspace} = useDeleteWorkspace();
    const workspaceName = workspace?.name || '';

    const handleDelete = useCallback(() => {
        if (workspaceName) {
            deleteWorkspace(
                {
                    name: workspaceName,
                },
                {
                    onSuccess: () => {
                        onClose?.();
                    },
                    onError: (error) => {
                        // TODO, handle error on WorkspaceForm
                        console.error({error});
                    },
                },
            );
        }
    }, [deleteWorkspace, onClose, workspaceName]);

    return (
        <>
            <DialogContent>
                {t('Workspaces.Form.Delete.Confirmation', {workspaceName})}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div></div>
                <div className={classes.rightActions}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" onClick={onCancel}>
                            {tCommon('ButtonLabels.Cancel', {workspaceName})}
                        </Button>
                    </DialogTrigger>
                    <Button className={buttonClasses.redButton} onClick={handleDelete}>
                        {t('Workspaces.Form.Delete.DeleteButton', {workspaceName})}
                    </Button>
                </div>
            </DialogActions>
        </>
    );
}

export default Delete;
