import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    sharePopoverContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    shareDialogRoot: {
        maxWidth: '500px',
    },
    header: {
        fontFamily: tokens.fontFamilyBase,
        fontSize: '20px',
        fontWeight: tokens.fontWeightSemibold,
        letterSpacing: '0em',
        textAlign: 'left',
    },
    promptbookNameDisplay: {
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    MessageDisplay: {
        marginLeft: '8px',
    },
});
