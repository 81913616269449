import {ScreenSize} from '@/components/ui/Grid/GridConstants';

export const ExploreCardCountBasedOnScreenSize = {
    [ScreenSize.Small]: 1,
    [ScreenSize.Medium]: 1,
    [ScreenSize.Large]: 2,
    [ScreenSize.XLarge]: 3,
    [ScreenSize.XXLarge]: 3,
    [ScreenSize.XXXLarge]: 4,
};

export const getExploreCardCountBasedOnScreenSize = (ScreenSize: ScreenSize) => {
    return ExploreCardCountBasedOnScreenSize[ScreenSize];
};
