import {useMutation} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {HelloRequest} from './hello.types';

export default function useUpdateHello() {
    const {customFetch} = useFetch();
    return useMutation(['hello'], {
        mutationFn: async (body: HelloRequest) => {
            console.log('useUpdateHello');
            const sanitizedBody = {
                ...body,
            };
            const result = await customFetch<Response>(
                `/hello`,
                {
                    method: 'PUT',
                    body: sanitizedBody,
                },
                true,
            );
            console.log({result});
            // Check if the response status is 200
            if (result.status !== 200) {
                throw new Error(`Unexpected status code: ${result.status}`);
            }

            // No need to parse JSON as there is no data expected
            return result.status;
        },
    });
}
