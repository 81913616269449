import * as React from 'react';
const Compass = (props: any) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M27 8C25.35 8 24 6.65 24 5C24 3.35 25.35 2 27 2C28.65 2 30 3.35 30 5C30 6.65 28.65 8 27 8ZM27 3.38C26.11 3.38 25.39 4.1 25.39 4.99C25.39 5.88 26.11 6.6 27 6.6C27.89 6.6 28.61 5.88 28.61 4.99C28.61 4.1 27.88 3.38 27 3.38Z"
            fill="url(#paint0_linear_18_4887)"
        />
        <path
            d="M27 8C25.35 8 24 6.65 24 5C24 3.35 25.35 2 27 2C28.65 2 30 3.35 30 5C30 6.65 28.65 8 27 8ZM27 3.38C26.11 3.38 25.39 4.1 25.39 4.99C25.39 5.88 26.11 6.6 27 6.6C27.89 6.6 28.61 5.88 28.61 4.99C28.61 4.1 27.88 3.38 27 3.38Z"
            fill="url(#paint1_radial_18_4887)"
        />
        <path
            d="M27 8C25.35 8 24 6.65 24 5C24 3.35 25.35 2 27 2C28.65 2 30 3.35 30 5C30 6.65 28.65 8 27 8ZM27 3.38C26.11 3.38 25.39 4.1 25.39 4.99C25.39 5.88 26.11 6.6 27 6.6C27.89 6.6 28.61 5.88 28.61 4.99C28.61 4.1 27.88 3.38 27 3.38Z"
            fill="url(#paint2_radial_18_4887)"
        />
        <path
            d="M27 8C25.35 8 24 6.65 24 5C24 3.35 25.35 2 27 2C28.65 2 30 3.35 30 5C30 6.65 28.65 8 27 8ZM27 3.38C26.11 3.38 25.39 4.1 25.39 4.99C25.39 5.88 26.11 6.6 27 6.6C27.89 6.6 28.61 5.88 28.61 4.99C28.61 4.1 27.88 3.38 27 3.38Z"
            fill="url(#paint3_radial_18_4887)"
        />
        <path
            d="M27 8C25.35 8 24 6.65 24 5C24 3.35 25.35 2 27 2C28.65 2 30 3.35 30 5C30 6.65 28.65 8 27 8ZM27 3.38C26.11 3.38 25.39 4.1 25.39 4.99C25.39 5.88 26.11 6.6 27 6.6C27.89 6.6 28.61 5.88 28.61 4.99C28.61 4.1 27.88 3.38 27 3.38Z"
            fill="url(#paint4_radial_18_4887)"
        />
        <path
            d="M27 8C25.35 8 24 6.65 24 5C24 3.35 25.35 2 27 2C28.65 2 30 3.35 30 5C30 6.65 28.65 8 27 8ZM27 3.38C26.11 3.38 25.39 4.1 25.39 4.99C25.39 5.88 26.11 6.6 27 6.6C27.89 6.6 28.61 5.88 28.61 4.99C28.61 4.1 27.88 3.38 27 3.38Z"
            fill="url(#paint5_radial_18_4887)"
        />
        <g filter="url(#filter0_i_18_4887)">
            <path
                d="M25.35 7.99994L23.99 6.63994L25.35 5.27994C25.72 4.90994 26.33 4.90994 26.71 5.27994C27.08 5.64994 27.08 6.25994 26.71 6.63994L25.35 7.99994Z"
                fill="url(#paint6_radial_18_4887)"
            />
        </g>
        <g filter="url(#filter1_ii_18_4887)">
            <path
                d="M15.5 27.9975C21.8499 27.9975 26.9975 22.8499 26.9975 16.5C26.9975 10.1501 21.8499 5.00244 15.5 5.00244C9.15006 5.00244 4.00244 10.1501 4.00244 16.5C4.00244 22.8499 9.15006 27.9975 15.5 27.9975Z"
                fill="url(#paint7_radial_18_4887)"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 16.5C29 23.9558 22.9558 30 15.5 30C8.04416 30 2 23.9558 2 16.5C2 9.04416 8.04416 3 15.5 3C22.9558 3 29 9.04416 29 16.5ZM26.54 16.5C26.54 22.5972 21.5972 27.54 15.5 27.54C9.40274 27.54 4.45996 22.5972 4.45996 16.5C4.45996 10.4027 9.40274 5.45996 15.5 5.45996C21.5972 5.45996 26.54 10.4027 26.54 16.5Z"
            fill="url(#paint8_linear_18_4887)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 16.5C29 23.9558 22.9558 30 15.5 30C8.04416 30 2 23.9558 2 16.5C2 9.04416 8.04416 3 15.5 3C22.9558 3 29 9.04416 29 16.5ZM26.54 16.5C26.54 22.5972 21.5972 27.54 15.5 27.54C9.40274 27.54 4.45996 22.5972 4.45996 16.5C4.45996 10.4027 9.40274 5.45996 15.5 5.45996C21.5972 5.45996 26.54 10.4027 26.54 16.5Z"
            fill="url(#paint9_radial_18_4887)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 16.5C29 23.9558 22.9558 30 15.5 30C8.04416 30 2 23.9558 2 16.5C2 9.04416 8.04416 3 15.5 3C22.9558 3 29 9.04416 29 16.5ZM26.54 16.5C26.54 22.5972 21.5972 27.54 15.5 27.54C9.40274 27.54 4.45996 22.5972 4.45996 16.5C4.45996 10.4027 9.40274 5.45996 15.5 5.45996C21.5972 5.45996 26.54 10.4027 26.54 16.5Z"
            fill="url(#paint10_radial_18_4887)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 16.5C29 23.9558 22.9558 30 15.5 30C8.04416 30 2 23.9558 2 16.5C2 9.04416 8.04416 3 15.5 3C22.9558 3 29 9.04416 29 16.5ZM26.54 16.5C26.54 22.5972 21.5972 27.54 15.5 27.54C9.40274 27.54 4.45996 22.5972 4.45996 16.5C4.45996 10.4027 9.40274 5.45996 15.5 5.45996C21.5972 5.45996 26.54 10.4027 26.54 16.5Z"
            fill="url(#paint11_radial_18_4887)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 16.5C29 23.9558 22.9558 30 15.5 30C8.04416 30 2 23.9558 2 16.5C2 9.04416 8.04416 3 15.5 3C22.9558 3 29 9.04416 29 16.5ZM26.54 16.5C26.54 22.5972 21.5972 27.54 15.5 27.54C9.40274 27.54 4.45996 22.5972 4.45996 16.5C4.45996 10.4027 9.40274 5.45996 15.5 5.45996C21.5972 5.45996 26.54 10.4027 26.54 16.5Z"
            fill="url(#paint12_radial_18_4887)"
        />
        <g filter="url(#filter2_i_18_4887)">
            <path
                d="M17.0799 16.4999L15.7899 8.24994C15.7399 7.91994 15.2599 7.91994 15.2099 8.24994L13.9199 16.4999L15.2099 24.7499C15.2599 25.0799 15.7399 25.0799 15.7899 24.7499L17.0799 16.4999Z"
                fill="url(#paint13_linear_18_4887)"
            />
            <path
                d="M17.0799 16.4999L15.7899 8.24994C15.7399 7.91994 15.2599 7.91994 15.2099 8.24994L13.9199 16.4999L15.2099 24.7499C15.2599 25.0799 15.7399 25.0799 15.7899 24.7499L17.0799 16.4999Z"
                fill="url(#paint14_radial_18_4887)"
            />
        </g>
        <path
            d="M15.4999 18.0799L23.7499 16.7899C24.0799 16.7399 24.0799 16.2599 23.7499 16.2099L15.4999 14.9199L7.24994 16.2099C6.91994 16.2599 6.91994 16.7399 7.24994 16.7899L15.4999 18.0799Z"
            fill="url(#paint15_linear_18_4887)"
        />
        <path
            d="M15.4999 18.0799L23.7499 16.7899C24.0799 16.7399 24.0799 16.2599 23.7499 16.2099L15.4999 14.9199L7.24994 16.2099C6.91994 16.2599 6.91994 16.7399 7.24994 16.7899L15.4999 18.0799Z"
            fill="url(#paint16_radial_18_4887)"
        />
        <path
            d="M16.2899 17.29L19.5799 12.78C19.7599 12.54 19.4599 12.24 19.2099 12.41L14.6999 15.7L11.4099 20.21C11.2299 20.45 11.5299 20.75 11.7799 20.58L16.2899 17.29Z"
            fill="url(#paint17_linear_18_4887)"
        />
        <path
            d="M16.2899 17.29L19.5799 12.78C19.7599 12.54 19.4599 12.24 19.2099 12.41L14.6999 15.7L11.4099 20.21C11.2299 20.45 11.5299 20.75 11.7799 20.58L16.2899 17.29Z"
            fill="url(#paint18_radial_18_4887)"
        />
        <path
            d="M14.7 17.2899L19.21 20.5799C19.45 20.7599 19.75 20.4599 19.58 20.2099L16.29 15.6999L11.78 12.4099C11.54 12.2299 11.24 12.5299 11.41 12.7799L14.7 17.2899Z"
            fill="#CCCCCC"
        />
        <g filter="url(#filter3_ii_18_4887)">
            <path
                d="M13.78 18.21L10.05 11.69C9.81995 11.28 10.27 10.83 10.68 11.06L17.21 14.78L13.78 18.21Z"
                fill="url(#paint19_linear_18_4887)"
            />
        </g>
        <g filter="url(#filter4_ii_18_4887)">
            <path
                d="M17.21 14.78L20.91 21.29C21.14 21.7 20.69 22.15 20.28 21.92L13.78 18.21L17.21 14.78Z"
                fill="#6B656E"
            />
        </g>
        <g filter="url(#filter5_i_18_4887)">
            <path
                d="M15.4999 18.9299C16.842 18.9299 17.9299 17.842 17.9299 16.4999C17.9299 15.1579 16.842 14.0699 15.4999 14.0699C14.1579 14.0699 13.0699 15.1579 13.0699 16.4999C13.0699 17.842 14.1579 18.9299 15.4999 18.9299Z"
                fill="url(#paint20_radial_18_4887)"
            />
        </g>
        <g filter="url(#filter6_f_18_4887)">
            <path
                d="M15.7779 28.75C8.96538 28.75 1.52787 22.1875 4.02788 12.625"
                stroke="url(#paint21_linear_18_4887)"
                strokeWidth={0.5}
            />
        </g>
        <g filter="url(#filter7_f_18_4887)">
            <path
                d="M15.5 3.43748C22.3125 3.43748 30.4966 9.84372 27.7048 20.2959"
                stroke="url(#paint22_linear_18_4887)"
                strokeWidth={0.75}
            />
        </g>
        <g filter="url(#filter8_f_18_4887)">
            <path
                d="M28.0278 2.53125C28.5799 2.69271 29.6935 3.39375 29.731 4.90625"
                stroke="url(#paint23_linear_18_4887)"
                strokeWidth={0.5}
                strokeLinecap="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_i_18_4887"
                x={23.99}
                y={5.00244}
                width={3.14756}
                height={2.99756}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={0.15} />
                <feGaussianBlur stdDeviation={0.25} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.741176 0 0 0 0 0.537255 0 0 0 0 0.309804 0 0 0 1 0"
                />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_4887" />
            </filter>
            <filter
                id="filter1_ii_18_4887"
                x={3.00244}
                y={5.00244}
                width={23.995}
                height={23.995}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={-1} dy={1} />
                <feGaussianBlur stdDeviation={1.5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.623529 0 0 0 0 0.482353 0 0 0 0 0.537255 0 0 0 1 0"
                />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_4887" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={-0.5} dy={0.5} />
                <feGaussianBlur stdDeviation={0.5} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.556863 0 0 0 0 0.431373 0 0 0 0 0.482353 0 0 0 1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_innerShadow_18_4887"
                    result="effect2_innerShadow_18_4887"
                />
            </filter>
            <filter
                id="filter2_i_18_4887"
                x={13.9199}
                y={8.00244}
                width={3.21003}
                height={16.995}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={0.05} />
                <feGaussianBlur stdDeviation={0.05} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.584314 0 0 0 0 0.521569 0 0 0 0 0.576471 0 0 0 1 0"
                />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_4887" />
            </filter>
            <filter
                id="filter3_ii_18_4887"
                x={9.88755}
                y={10.7476}
                width={7.32241}
                height={7.4624}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={-0.5} />
                <feGaussianBlur stdDeviation={0.05} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.952941 0 0 0 0 0.239216 0 0 0 0 0.435294 0 0 0 1 0"
                />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_4887" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={-0.5} />
                <feGaussianBlur stdDeviation={0.125} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                    mode="normal"
                    in2="effect1_innerShadow_18_4887"
                    result="effect2_innerShadow_18_4887"
                />
            </filter>
            <filter
                id="filter4_ii_18_4887"
                x={13.78}
                y={14.63}
                width={7.19238}
                height={7.45239}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={0.5} />
                <feGaussianBlur stdDeviation={0.05} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.47451 0 0 0 0 0.462745 0 0 0 0 0.470588 0 0 0 1 0"
                />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_4887" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={-0.35} />
                <feGaussianBlur stdDeviation={0.075} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                    mode="normal"
                    in2="effect1_innerShadow_18_4887"
                    result="effect2_innerShadow_18_4887"
                />
            </filter>
            <filter
                id="filter5_i_18_4887"
                x={13.0699}
                y={13.9199}
                width={5.00999}
                height={5.00999}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx={0.15} dy={-0.15} />
                <feGaussianBlur stdDeviation={0.075} />
                <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.447059 0 0 0 0 0.368627 0 0 0 0 0.415686 0 0 0 1 0"
                />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_4887" />
            </filter>
            <filter
                id="filter6_f_18_4887"
                x={2.78198}
                y={12.0618}
                width={13.4958}
                height={17.4382}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.25} result="effect1_foregroundBlur_18_4887" />
            </filter>
            <filter
                id="filter7_f_18_4887"
                x={14.75}
                y={2.3125}
                width={14.641}
                height={18.8302}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.375} result="effect1_foregroundBlur_18_4887" />
            </filter>
            <filter
                id="filter8_f_18_4887"
                x={27.5277}
                y={2.03113}
                width={2.70325}
                height={3.37512}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={0.125} result="effect1_foregroundBlur_18_4887" />
            </filter>
            <linearGradient
                id="paint0_linear_18_4887"
                x1={25.2425}
                y1={7.49792}
                x2={30}
                y2={3.04178}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2881C" />
                <stop offset={1} stopColor="#F6C942" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(24.6302 6.74298) rotate(-32.0054) scale(6.76952 6.23488)"
            >
                <stop offset={0.712329} stopColor="#FFEA6A" stopOpacity={0} />
                <stop offset={0.942173} stopColor="#FFEA6A" />
            </radialGradient>
            <radialGradient
                id="paint2_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(27.2887 4.81383) rotate(-48.1396) scale(2.00321 2.04026)"
            >
                <stop offset={0.614468} stopColor="#C0813E" />
                <stop offset={1} stopColor="#C0813E" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint3_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(28.0778 3.95896) rotate(134.772) scale(4.64777 4.46393)"
            >
                <stop offset={0.709501} stopColor="#CA7412" stopOpacity={0} />
                <stop offset={1} stopColor="#CA7412" />
            </radialGradient>
            <radialGradient
                id="paint4_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(24.5279 6.68748) rotate(-112.148) scale(1.40019 3.90267)"
            >
                <stop offset={0.0734402} stopColor="#7C3D03" />
                <stop offset={0.735787} stopColor="#7C3D03" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint5_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25.4341 8) rotate(-3.98039) scale(0.90061 2.51023)"
            >
                <stop offset={0.307476} stopColor="#7C3D03" />
                <stop offset={0.735787} stopColor="#7C3D03" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint6_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(26.106 6.21873) rotate(-135.277) scale(1.14354 2.65509)"
            >
                <stop stopColor="#FFE160" />
                <stop offset={0.787818} stopColor="#F3B535" />
            </radialGradient>
            <radialGradient
                id="paint7_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(5.20764 11.6953) rotate(20.7627) scale(18.333 24.9855)"
            >
                <stop stopColor="#B5A7B0" />
                <stop offset={1} stopColor="#DFD2E2" />
            </radialGradient>
            <linearGradient
                id="paint8_linear_18_4887"
                x1={5.59036}
                y1={25.8125}
                x2={24.2154}
                y2={7.18748}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#DE8D39" />
                <stop offset={1} stopColor="#EDC14B" />
            </linearGradient>
            <radialGradient
                id="paint9_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(4.49971 23.9406) rotate(-29.785) scale(28.2295 22.1446)"
            >
                <stop offset={0.873189} stopColor="#FFED6A" stopOpacity={0} />
                <stop offset={1} stopColor="#FFED6A" />
            </radialGradient>
            <radialGradient
                id="paint10_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(18.5935 10.2718) rotate(119.722) scale(21.8559 17.2879)"
            >
                <stop offset={0.873137} stopColor="#B66635" stopOpacity={0} />
                <stop offset={0.974704} stopColor="#B66635" />
            </radialGradient>
            <radialGradient
                id="paint11_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(16.1529 16.0625) rotate(-45.8425) scale(12.0221 12.6674)"
            >
                <stop offset={0.862629} stopColor="#B88245" />
                <stop offset={0.939433} stopColor="#B88245" stopOpacity={0} />
            </radialGradient>
            <radialGradient
                id="paint12_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(31.0511 16.5) rotate(180) scale(29.8039 19.6734)"
            >
                <stop offset={0.952381} stopColor="#F58D49" stopOpacity={0} />
                <stop offset={1} stopColor="#F58D49" />
            </radialGradient>
            <linearGradient
                id="paint13_linear_18_4887"
                x1={15.4999}
                y1={8.00244}
                x2={15.4999}
                y2={24.9974}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B6ACB7" />
                <stop offset={1} stopColor="#968C98" />
            </linearGradient>
            <radialGradient
                id="paint14_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(14.9966 18.0937) rotate(105.642) scale(1.21695 2.23861)"
            >
                <stop offset={0.324704} stopColor="#5F4D63" />
                <stop offset={0.983093} stopColor="#5F4D63" stopOpacity={0} />
            </radialGradient>
            <linearGradient
                id="paint15_linear_18_4887"
                x1={21.1476}
                y1={15.9298}
                x2={8.24252}
                y2={15.9298}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B6ABB8" />
                <stop offset={1} stopColor="#988E9A" />
            </linearGradient>
            <radialGradient
                id="paint16_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(14.3803 17.0314) rotate(159.274) scale(1.55648 1.47903)"
            >
                <stop offset={0.573163} stopColor="#5F4D63" />
                <stop offset={1} stopColor="#5F4D63" stopOpacity={0} />
            </radialGradient>
            <linearGradient
                id="paint17_linear_18_4887"
                x1={19.6347}
                y1={12.8125}
                x2={11.7153}
                y2={21.1875}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#B6ACB8" />
                <stop offset={1} stopColor="#958B97" />
            </linearGradient>
            <radialGradient
                id="paint18_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(14.1914 17.8656) rotate(129.144) scale(0.872566 1.33231)"
            >
                <stop offset={0.458686} stopColor="#5F4D63" />
                <stop offset={1} stopColor="#5F4D63" stopOpacity={0} />
            </radialGradient>
            <linearGradient
                id="paint19_linear_18_4887"
                x1={10.4539}
                y1={11.8024}
                x2={14.5215}
                y2={16.548}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E43465" />
                <stop offset={1} stopColor="#E6386A" />
            </linearGradient>
            <radialGradient
                id="paint20_radial_18_4887"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(17.3402 15.3358) rotate(137.291) scale(4.64886 4.84678)"
            >
                <stop stopColor="#C0BCBF" />
                <stop offset={1} stopColor="#856F79" />
            </radialGradient>
            <linearGradient
                id="paint21_linear_18_4887"
                x1={2.46538}
                y1={14.75}
                x2={13.7154}
                y2={28.9063}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBD6A" stopOpacity={0} />
                <stop offset={0.516456} stopColor="#FFBD6A" />
                <stop offset={1} stopColor="#FFBD6A" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="paint22_linear_18_4887"
                x1={31.3619}
                y1={18.3545}
                x2={19.1715}
                y2={2.05544}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFE55A" stopOpacity={0} />
                <stop offset={0.314667} stopColor="#FFE55A" />
                <stop offset={0.672089} stopColor="#FFE55A" />
                <stop offset={1} stopColor="#FFE55A" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="paint23_linear_18_4887"
                x1={27.856}
                y1={2.53125}
                x2={29.9028}
                y2={4.67187}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFE967" stopOpacity={0} />
                <stop offset={0.463542} stopColor="#FFE967" />
                <stop offset={1} stopColor="#FFE967" stopOpacity={0} />
            </linearGradient>
        </defs>
    </svg>
);
export default Compass;
