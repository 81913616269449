import {Button, Spinner, Text, mergeClasses} from '@fluentui/react-components';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import {FailedIcon, ReturnIcon, SubmittedIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';

interface SummaryOverlayDialogProps {
    isCreateLoading: boolean;
    isUpdateLoading: boolean;
    isDeleteLoading: boolean;
    isCreateSuccess: boolean;
    isUpdateSuccess: boolean;
    isDeleteSuccess: boolean;
    isCreateOrUpdateError: boolean;
    isDeleteError: boolean;
    errorResponse: string;
    deleteErrorResponse: string;
    handleConnectorReturn: () => void;
    onReturnClick: () => void;
}

export default function SummaryOverlayDialog({
    isCreateLoading,
    isUpdateLoading,
    isDeleteLoading,
    isCreateSuccess,
    isUpdateSuccess,
    isDeleteSuccess,
    isCreateOrUpdateError,
    isDeleteError,
    errorResponse,
    deleteErrorResponse,
    handleConnectorReturn,
    onReturnClick,
}: SummaryOverlayDialogProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {t} = useTranslation('plugins');

    return (
        <div>
            {!isCreateLoading && !isUpdateLoading && !isDeleteLoading && (
                <div className={classes.topRow}>
                    <Button
                        className={classes.modalButton}
                        onClick={handleConnectorReturn}
                        appearance="subtle"
                        size="medium"
                        icon={<ReturnIcon />}
                        data-testid={'back-button'}
                        aria-label={t('common.GoBackToManagePlugins')}
                    />
                </div>
            )}
            <div className={classes.summaryOverlay}>
                {(isCreateLoading || isUpdateLoading) && (
                    <Spinner size="small" labelPosition="below" label={t('common.AddingPlugin')} />
                )}
                {isDeleteLoading && (
                    <Spinner size="small" labelPosition="below" label={t('common.Deleting')} />
                )}
                {(isCreateSuccess || isUpdateSuccess) && (
                    <>
                        <SubmittedIcon className={classes.savedButton} filled />
                        <Text className={classes.successText}>{t('common.PluginAdded')}</Text>
                    </>
                )}
                {isDeleteSuccess && (
                    <>
                        <SubmittedIcon className={classes.savedButton} filled />
                        <Text className={classes.successText}>{t('common.Deleted')}</Text>
                    </>
                )}
                {isCreateOrUpdateError && (
                    <>
                        <FailedIcon className={classes.failedButton} filled />
                        <Text className={classes.failedLargeText}>
                            {t('common.PluginNotAdded')}
                        </Text>
                        <Text
                            className={mergeClasses(
                                classes.successText,
                                classes.summaryScrollText,
                                scrollClasses.colorNeutralBackground1,
                            )}
                        >
                            {errorResponse}
                        </Text>
                        <Button
                            className={classes.backButton}
                            appearance="subtle"
                            size="medium"
                            onClick={onReturnClick}
                        >
                            {t('common.GoBack')}
                        </Button>
                    </>
                )}
                {isDeleteError && (
                    <>
                        <FailedIcon className={classes.failedButton} filled />
                        <Text
                            className={mergeClasses(
                                classes.successText,
                                classes.summaryScrollText,
                                scrollClasses.colorNeutralBackground1,
                            )}
                        >
                            {t('FailedToDelete', {error: deleteErrorResponse})}
                        </Text>
                    </>
                )}
            </div>
        </div>
    );
}
