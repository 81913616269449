import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    toolbar: {
        ...shorthands.flex(0, 0, 'auto'),
        position: 'sticky',
        top: 0,
        backgroundColor: tokens.colorNeutralBackground2,
        width: '100%',
        boxShadow: tokens.shadow16,
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStencil1),
    },
    toolbarContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...shorthands.gap('18px'),
        alignItems: 'center',
        ...shorthands.margin(0, 'auto'),
    },
    toolbarRight: {
        position: 'absolute',
        right: tokens.spacingHorizontalXL,
    },
    toolbarSelect: {
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(
            tokens.spacingVerticalS,
            0,
            tokens.spacingVerticalS,
            tokens.spacingVerticalXXL,
        ),
        '@media (max-width: 479px)': {
            ...shorthands.padding(tokens.spacingVerticalS, 0, tokens.spacingVerticalS, 0),
        },
    },
    toolbarSelectFullWidth: {
        ...shorthands.padding(
            tokens.spacingVerticalS,
            0,
            tokens.spacingVerticalS,
            tokens.spacingVerticalXL,
        ),
    },
    toolbarButtons: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.padding(tokens.spacingVerticalS, 0),
        maxWidth: '700px',
        justifyContent: 'flex-start',
    },
    toolbarButtonsFullWidth: {
        maxWidth: 'none',
    },
    toolbarBumper: {
        width: tokens.spacingHorizontalXXXL,
    },
    toolbarButtonsWrapper: {
        ...shorthands.padding(0),
    },
});
