import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {doSkillsetNamespaceInvalidation} from '../skills/skillsetUtils';
import {ScopedSettings} from './settings.types';

export default function usePatchSettings() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: ScopedSettings) => {
            const {scope, ...dataWithoutScope} = body;
            var response = await customFetch<ScopedSettings>(`/settings?scope=${scope}`, {
                method: 'PATCH',
                body: dataWithoutScope,
            });
            return response;
        },
        onSuccess: () => doSkillsetNamespaceInvalidation(queryClient),
    });
}
