// Tooltip.tsx
import React, {useRef, useEffect} from 'react';
import {
    Popover,
    PopoverSurface,
    PopoverTrigger,
    PositioningShorthand,
    PositioningShorthandValue,
} from '@fluentui/react-components';
import type {PositioningImperativeRef} from '@fluentui/react-components';
import {Position, AlignEdge} from './Tour.types';
import {useInView} from 'framer-motion';

interface TooltipProps {
    position: Position;
    tooltipPosition: PositioningShorthandValue;
    alignEdge: AlignEdge;
    children: React.ReactNode;
    target: Element | null;
}
const Tooltip = (props: TooltipProps) => {
    const ref = useRef(null);
    // create a ref for the element we are going to target
    const targetRef = useRef(props.target);

    const isInView = useInView(ref);
    const positioningRef = useRef<PositioningImperativeRef>(null);
    const {position, tooltipPosition, alignEdge, children, target} = props;
    useEffect(() => {
        // if isInView is false, then we want to scroll the element into view
        if (!isInView) {
            window.scrollTo({top: position.top + position.height, behavior: 'smooth'});
        }
        // only run the first time this renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ref.current) {
            positioningRef.current?.setTarget(ref.current);
        }
    }, [ref, positioningRef]);

    // add a div to the dom with absolute positioning to hang the popover off of and layer it correctly in the overlay
    return (
        <>
            <div ref={ref} style={{position: 'absolute', ...position, zIndex: 2}} />
            <Popover
                positioning={{positioningRef}}
                mountNode={ref.current}
                size="large"
                trapFocus
                defaultOpen
                open
                withArrow
                inline={false}
            >
                <PopoverSurface aria-live="polite">{children}</PopoverSurface>
            </Popover>
        </>
    );
};

export default Tooltip;
