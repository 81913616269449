import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import type {GriffelStyle} from '@fluentui/react-components';
import {NavigationBarHeight} from '../App.styles';

const rootCommon: Partial<GriffelStyle> = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    backgroundColor: tokens.colorNeutralBackground4,
    paddingLeft: tokens.spacingHorizontalM,
    paddingRight: tokens.spacingHorizontalM,
    height: `${NavigationBarHeight}px`,
};

export default makeStyles({
    root: {
        ...rootCommon,
    },
    leftNav: {
        columnGap: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    rightNav: {
        ...shorthands.flex(0, 0, 'auto'),
        justifyContent: 'right',
        alignSelf: 'center',

        columnGap: tokens.spacingHorizontalXS,
    },
    breadcrumbs: {
        ...shorthands.flex(0, 0, 'auto'),
        justifyContent: 'left',
        alignSelf: 'center',
        paddingLeft: tokens.spacingHorizontalS,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
    title: {
        ...shorthands.flex(1, 1, 'auto'),
        backgroundColor: tokens.colorNeutralBackground1,
        marginRight: '64px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    titleLink: {
        backgroundColor: tokens.colorNeutralBackground1,
        ':link': {
            backgroundColor: tokens.colorNeutralBackground1,
        },
        ':visited': {
            backgroundColor: tokens.colorNeutralBackground1,
        },
        ':hover': {
            textDecorationLine: 'underline',
        },
        textDecorationLine: 'none',
    },
    context: {
        ...shorthands.flex(0, 0, 'auto'),
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    user: {
        fontSize: '80%',
        fontWeight: 500,
        marginRight: '10px',
    },
});
