import {useMemo} from 'react';
import {
    Button,
    Menu,
    MenuGroup,
    MenuGroupHeader,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components';
import {AddIcon, SelectAllIcon} from '@/components/ui/icons';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useGetSessions} from '@/api/sessions';
import useClasses from './RecentInvestigationMenu.styles';
import MedeinaFeatures from '@/util/features';

const TOP_SESSIONS_TO_FETCH = 2;

function RecentInvestigationMenu() {
    const classes = useClasses();
    const navigate = useNavigate();
    const {t} = useTranslation('session');

    const {data: sessions} = useGetSessions({
        sortBy: 'updatedAt',
        sortDirection: 'desc',
        limit: 10,
    });

    let topSessions = useMemo(() => {
        return sessions?.pages
            .map((page) => page.value)
            .flat()
            .slice(0, TOP_SESSIONS_TO_FETCH);
    }, [sessions]);

    return (
        <Menu>
            <MenuTrigger>
                <Tooltip content={t('CreateOrViewSessionsTooltip')} relationship="description">
                    <Button
                        data-testid="my-investigation-options"
                        appearance="subtle"
                        className={classes.root}
                    >
                        {t('MySessionsButton')}
                    </Button>
                </Tooltip>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuGroup>
                        <MenuItem
                            className={classes.menuItem}
                            onClick={() => navigate('/sessions')}
                            icon={<SelectAllIcon />}
                        >
                            {t('ViewMySessions')}
                        </MenuItem>
                        <MenuItem
                            data-testid="new session"
                            icon={<AddIcon />}
                            onClick={() =>
                                MedeinaFeatures.NewSessionPage
                                    ? navigate('/sessions/new')
                                    : navigate('/')
                            }
                        >
                            {t('NewSession')}
                        </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup>
                        <MenuGroupHeader>{t('RecentSessions')}</MenuGroupHeader>
                        {topSessions &&
                            topSessions.length > 0 &&
                            topSessions.map(
                                (session, index) =>
                                    session && (
                                        <MenuItem
                                            className={classes.menuItem}
                                            data-testid="recent-investigation"
                                            key={index}
                                            onClick={() =>
                                                navigate(`/sessions/${session.sessionId}`)
                                            }
                                        >
                                            {session.name}
                                        </MenuItem>
                                    ),
                            )}
                    </MenuGroup>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

export default RecentInvestigationMenu;
