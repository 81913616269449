import {Button} from '@fluentui/react-components';
import useClasses from './ExportToExcelButton.styles';
import downloadFileFromBlob from '../../util/domUtilities';
import {ExportToExcelButtonProps} from './Table.types';
import useDocumentDownload from '@/api/evaluations/useDocumentDownload';
import {Image} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

const ExportToExcelButton = function ({
    className,
    sessionId,
    promptId,
    evaluationId,
    linePositions,
}: ExportToExcelButtonProps) {
    const excelImagePath = '/images/excel.svg';
    const {t} = useTranslation('common');
    const styles = useClasses();

    const {mutateAsync: downloadDocument} = useDocumentDownload();

    const performDocumentExport = async function () {
        const {fileBlob, fileName, isError} = await downloadDocument({
            sessionId,
            promptId,
            evaluationId,
            linePositions,
        });

        if (!isError) {
            downloadFileFromBlob(fileBlob as Blob, fileName as string);
        } else {
            // TODO: show error message?
        }
    };

    return (
        <div className={className}>
            <Button
                appearance="subtle"
                icon={<Image width="20px" height="20px" src={excelImagePath} alt="" />}
                onClick={async () => await performDocumentExport()}
            >
                <span className={styles.textStyles}>{t('ButtonLabels.ExportExcel')}</span>
            </Button>
        </div>
    );
};

export default ExportToExcelButton;
