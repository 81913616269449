import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Session} from './sessions.types';

export default function useUpdateSession() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: Session) => {
            if (body.sessionId === undefined) {
                // This condition should not happen.
                return false;
            }

            // Perform the actual API call to update the session
            return customFetch<Session>(`/sessions/${body.sessionId}`, {
                method: 'PUT',
                body,
            })
                .then((updatedSession) => {
                    return updatedSession;
                })
                .catch((error) => {
                    throw error;
                });
        },

        //Callback function called after the mutation is settled which refreshes the queries to reflect on UI
        onSettled: (updatedSession, error, variables, context) => {
            if (!error) {
                //invalidate Queries, Lazy refetch from server
                // the next time the query is accessed, it will trigger a refetch to fetch fresh data from the server
                queryClient.invalidateQueries(['session', variables.sessionId]);
                queryClient.invalidateQueries(['sessions']);
            }
        },
    });
}
