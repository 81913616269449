import {ZoomBehavior, ZoomTransform, select, zoomIdentity, zoomTransform} from 'd3';
import {VisualizationTypes, ZoomConfigurationAxes} from './CodeBlock.types';

export default function useD3TransformationUtilities(
    id: string,
    visualizationElement: VisualizationTypes,
) {
    const repositionGraph = function (x: number, y: number, k: number) {
        getElement().attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
    };

    const getElement = function () {
        return select(`#${id} svg ${visualizationElement}`);
    };

    const getTransformForElement = (
        value: number,
        transformationType: ZoomConfigurationAxes,
    ): ZoomTransform => {
        const element = getElement();
        const {x: oldX, y: oldY, k: oldK} = zoomTransform(element.node() as Element);

        const newX = transformationType === 'x' ? value : oldX;
        const newY = transformationType === 'y' ? value : oldY;
        const newScale = transformationType === 'k' ? value : oldK;

        return zoomIdentity.translate(newX, newY).scale(newScale);
    };

    const applyTransformationOnInstance = function (
        transform: ZoomTransform,
        zoomInstance: ZoomBehavior<Element, unknown>,
    ) {
        getElement().call(zoomInstance.transform as any, transform);
    };

    return {
        getElement,
        repositionGraph,
        getTransformForElement,
        applyTransformationOnInstance,
    };
}
