import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        position: 'relative',
        maxWidth: '700px',
        width: '100%',
    },
    rootResponsive: {
        maxWidth: '100%',
        width: '100%',
        position: 'relative',
    },
    rootHome: {
        position: 'relative',
        '@media (min-width: 1920px)': {
            width: '692px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '692px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '692px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '446px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '431px',
        },
        '@media (max-width: 479px)': {
            width: '100%',
        },
    },
    rootSession: {
        position: 'relative',
        ...shorthands.margin(0, 0, 0, '10px'),
        maxWidth: '692px',
        width: '90vw',
        '@media (min-width: 640px) and (max-width: 1023px)': {
            maxwidth: '83vw',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            maxwidth: '78vw',
        },
        '@media (min-width: 321px) and (max-width: 479px)': {
            maxwidth: '83vw',
        },
        '@media (max-width: 320px)': {
            // position: 'fixed',
            ...shorthands.margin(0),
            maxwidth: '100vw',
        },
    },
    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
    },
    skillFormWrapper: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
    },
    textareaWrapper: {
        ...shorthands.flex(1, 1, 'auto'),
    },
    context: {
        ...shorthands.padding('10px', '0'),
        ...shorthands.flex(0, 0, 'auto'),
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    toolbar: {
        justifyContent: 'right',
    },
    actionsToolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
    },
    skillIcon: {
        color: tokens.colorPaletteRedForeground1,
    },
    connectorModal: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 'auto',
        justifyContent: 'flex-start',
        position: 'fixed',
        left: '38.5px',
        bottom: '40px',
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            left: '47px',
            bottom: '43px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            left: '44px',
            bottom: tokens.spacingVerticalXXL,
        },
        '@media (max-width: 639px)': {
            display: 'none',
        },
    },
    assistance: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 'auto',
        justifyContent: 'flex-end',
        position: 'fixed',
        right: '38.5px',
        bottom: '40px',
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            right: '47px',
            bottom: '43px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            right: '44px',
            bottom: tokens.spacingVerticalXXL,
        },
    },
});
