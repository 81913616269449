import {MedeinaRoutesMap} from '@/components/Navigation/MedeinaRoutes';
import {Prompt} from '../prompts';
import {SourceType} from '../sessions';

export type PromptbookDescriptor = {
    promptbookId: string;
    name: string;
    description: string;
    prompts: PromptbookPrompts[];
    promptbookinputs: PromptbookInputDescriptor[];
    tags?: PromptbookTag[];
    ownerName: string;
    visibility: PromptbookVisibility;
    userId: string;
    validPersonaTypes?: string[];
};

export type PromptbookExecutionDescriptor = {
    promptbookExecutionId: string;
    promptbookId: string;
    userId: string;
    status: PromptbookExecutionStatus;
};

export type PromptbookTag = {
    [x: string]: any;
    tag: string;
    autogenerated: boolean;
};

export enum PromptbookVisibility {
    Unknown = 'Unknown',
    Private = 'Private',
    Tenant = 'Tenant',
    Global = 'Global',
}

export type GetPromptbookRequest = {
    promptbookId?: string;
};

export type GetPromptbookExecutionRequest = {
    promptbookExecutionId: string;
};

export enum PromptbookExecutionStatus {
    Unknown = 'Unknown',
    Started = 'Started',
    Running = 'Running',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Error = 'Error',
    AgedOutOfQueue = 'AgedOutOfQueue',
}

export type CancelPromptbookExecutionRequest = {
    sessionId: string;
    promptbookExecutionId: string;
};

export type GetPromptbooksRequest = {
    sortBy?: string | null;
    sortDirection?: 'asc' | 'desc' | null;
    filter?: string | null;
    search?: string | null;
};

export type PromptbookInputDescriptor = {
    name: string;
    description: string;
};

export type PromptbookInputs = {[key: string]: string};

export type PromptbookPrompts = {
    promptType?: string;
    content: string;
    skillName?: string;
    inputs?: PromptInputs;
    sequenceNumber?: number;
    plugins?: string[];
};

export type PromptInputs = {
    [key: string]: string;
};

export type ApplyPromptbookRequest = {
    sessionId: string;
    promptbookId: string;
    inputs?: PromptInputs;
    overriddenPrompts?: PromptbookPrompts[] | null;
    source?: SourceType;
};

export type ApplyPromptbookResponse = {
    prompts: Prompt;
};

export type CreatePromptbookRequest = Omit<
    PromptbookDescriptor,
    'promptbookId' | 'author' | 'ownerName' | 'userId'
>;

export type EditPromptbookRequest = Omit<PromptbookDescriptor, 'author' | 'ownerName' | 'userId'>;

export type DeletePromptbookRequest = {
    promptbook?: PromptbookDescriptor;
    promptbookId?: string;
    promptbookIds?: string[];

    // Optional `open` control
    open?: boolean | undefined;
    onClose?: () => void;
    onSuccess?: () => void;
};

export enum PromptbookSelectionType {
    Single = 'single',
    Multiple = 'multiple',
}

export const PromptbookPath = {
    Promptbooks: MedeinaRoutesMap.promptbooks.path,
    HomeLibrary: MedeinaRoutesMap.newsessions.path.split('/:')[0],
} as const;

export type PromptbookSharingTokenRequest = {
    promptbookId: string;
    role?: string;
    global?: boolean;
    expiresOn?: string;
};

export type PromptbookSharingToken = {
    token: string;
};
