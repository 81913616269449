import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {SkillsetRequest} from './skills.types';

export default function useDeleteSkillsetConfiguration() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: SkillsetRequest) => {
            const queryParams = `?scope=${body?.scope}`;
            try {
                return await customFetch<Response>(
                    `/skillsets/${body.skillsetName}/configuration${queryParams}`,
                    {
                        method: 'DELETE',
                    },
                    true,
                );
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['skillsets', 'configurations']);
        },
    });
}
