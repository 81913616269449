export interface ExportProps {
    sessionId: string;
    summary?: string;
    investigationTitle?: string;
    onClose?: () => void;
}

export enum DocumentType {
    Word = 'Word',
    PPT = 'PPT',
}

export enum fileExtension {
    docx = 'docx',
}
