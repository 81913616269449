import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useCreatePrompt} from '../prompts';
import {useCreateEvaluation} from '../evaluations';
import {CreateSessionWithPromptRequest, SourceType} from './sessions.types';
import useCreateSession from './useCreateSession';
import useCreatePromptAndEvaluation from '../combined/useCreatePromptAndEvaluation';
import {useGetUserInfo} from '../app';
import {useEffect, useState} from 'react';

export default function useCreateSessionWithPrompt() {
    const queryClient = useQueryClient();
    const {data: userInfo, isSuccess: isUserInfoSuccess, error: isUserInfoError} = useGetUserInfo();
    const [useCombinedController, setUseCombinedController] = useState<boolean>(false);
    const {mutateAsync: createSession} = useCreateSession();
    const {mutateAsync: createPrompt} = useCreatePrompt();
    const {mutateAsync: createEvaluation} = useCreateEvaluation();
    const {mutateAsync: createPromptAndEvaluation} = useCreatePromptAndEvaluation();

    useEffect(() => {
        if (userInfo && userInfo.featureFlags?.includes('EnableCombinedController')) {
            setUseCombinedController(true);
        }
    }, [userInfo]);

    return useMutation({
        mutationFn: async (body: CreateSessionWithPromptRequest) => {
            const {prompt: promptBody, ...sessionBody} = body;
            const session = await createSession({
                ...sessionBody,
                name: sessionBody.name ?? promptBody?.content ?? promptBody?.skillName ?? undefined,
                source: SourceType.Immersive,
            });
            // If a prompt was passed, also create a prompt and its evaluation too.
            if (session && promptBody) {
                if (useCombinedController) {
                    const evaluation = await createPromptAndEvaluation({
                        ...promptBody,
                        sessionId: session.sessionId,
                        source: SourceType.Immersive,
                    });
                } else {
                    const prompt = await createPrompt({
                        ...promptBody,
                        sessionId: session.sessionId,
                        source: SourceType.Immersive,
                    });

                    const evaluation = await createEvaluation({
                        sessionId: session.sessionId,
                        promptId: prompt.promptId,
                    });
                }
            }

            return session;
        },

        onSuccess: () => {
            queryClient.invalidateQueries(['sessions']);
        },
    });
}
