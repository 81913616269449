import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.padding(
            tokens.spacingVerticalL,
            tokens.spacingHorizontalXL,
            tokens.spacingVerticalNone,
        ),
        borderBottomLeftRadius: tokens.borderRadiusMedium,
        borderBottomRightRadius: tokens.borderRadiusMedium,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '320px',
        height: 'auto',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    subtypeBtn: {
        ...shorthands.borderWidth(tokens.strokeWidthThin),
        ...shorthands.borderColor(tokens.colorNeutralForeground4),
        minWidth: tokens.lineHeightHero900,
        '&[aria-pressed=true]': {
            backgroundColor: tokens.colorNeutralStroke1Selected,
        },
    },
    subtypes: {
        ...shorthands.padding(0, 0, tokens.spacingVerticalXL),
        display: 'flex',
        columnGap: tokens.spacingHorizontalS,
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: tokens.spacingVerticalS,
    },
    title: {
        ...shorthands.padding(0, 0, tokens.spacingVerticalL),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    submitBtn: {
        display: 'flex',
        columnGap: tokens.spacingHorizontalM,
        flexDirection: 'row',
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalL,
        ),
    },
    disclaimer: {
        fontSize: '11px',
        color: tokens.colorNeutralForeground3,
        lineHeight: tokens.lineHeightBase100,
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalNone),
    },
    disclaimerLink: {
        fontSize: '11px',
    },
    textarea: {
        width: '100%',
    },
    drawerHeader: {
        ...shorthands.padding(
            tokens.spacingVerticalL,
            tokens.spacingHorizontalXXL,
            tokens.spacingVerticalL,
        ),
    },
});
