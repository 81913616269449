import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    titleSection: {
        marginBottom: tokens.spacingVerticalMNudge,
        display: 'flex',
        paddingRight: tokens.spacingHorizontalL,
    },
    contentSection: {
        marginBottom: tokens.spacingVerticalM,
    },
    titleContainer: {
        display: 'flex',
    },
    disconnectButton: {
        backgroundColor: tokens.colorPaletteDarkOrangeBorder1,
        minWidth: 'fill-available',
        display: 'flex',
        '&:hover': {
            backgroundColor: tokens.colorPaletteDarkOrangeBorder1,
        },
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});
