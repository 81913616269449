export const LightThemeSVGIcon = () => (
    <svg
        width="141"
        height="81"
        viewBox="0 0 141 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id=".Theme card">
            <rect x="0.5" y="0.5" width="140" height="80" rx="6" fill="#EBEDEF" />
            <rect
                id="Rectangle 3469288"
                x="42.5"
                y="66.5"
                width="56"
                height="8"
                rx="1"
                fill="white"
            />
            <path
                id="Rectangle 3469291"
                d="M42.5 30.5C42.5 29.9477 42.9477 29.5 43.5 29.5H97.5C98.0523 29.5 98.5 29.9477 98.5 30.5V37.5H42.5V30.5Z"
                fill="white"
            />
            <path
                id="Rectangle 3469290"
                d="M42.5 38.5H98.5V59.5C98.5 60.0523 98.0523 60.5 97.5 60.5H43.5C42.9477 60.5 42.5 60.0523 42.5 59.5V38.5Z"
                fill="white"
            />
            <rect
                id="Rectangle 3469294"
                x="6.5"
                y="7.5"
                width="31"
                height="4"
                rx="2"
                fill="#00666D"
            />
            <path
                id="Rectangle 3469293"
                d="M42.5 8.5C42.5 7.94772 42.9477 7.5 43.5 7.5H97.5C98.0523 7.5 98.5 7.94772 98.5 8.5V21.5C98.5 22.0523 98.0523 22.5 97.5 22.5H43.5C42.9477 22.5 42.5 22.0523 42.5 21.5V8.5Z"
                fill="white"
            />
        </g>
    </svg>
);

export const DarkThemeSVGIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="141"
        height="81"
        viewBox="0 0 141 81"
        fill="none"
    >
        <rect x="0.5" y="0.5" width="140" height="80" rx="6" fill="#111315" />
        <rect x="42.5" y="66.5" width="56" height="8" rx="1" fill="#2B3135" />
        <path
            d="M42.5 30.5C42.5 29.9477 42.9477 29.5 43.5 29.5H97.5C98.0523 29.5 98.5 29.9477 98.5 30.5V37.5H42.5V30.5Z"
            fill="#2B3135"
        />
        <path
            d="M42.5 38.5H98.5V59.5C98.5 60.0523 98.0523 60.5 97.5 60.5H43.5C42.9477 60.5 42.5 60.0523 42.5 59.5V38.5Z"
            fill="#2B3135"
        />
        <path
            d="M42.5 8.5C42.5 7.94772 42.9477 7.5 43.5 7.5H97.5C98.0523 7.5 98.5 7.94772 98.5 8.5V21.5C98.5 22.0523 98.0523 22.5 97.5 22.5H43.5C42.9477 22.5 42.5 22.0523 42.5 21.5V8.5Z"
            fill="#2B3135"
        />
        <rect x="6.5" y="7.5" width="31" height="4" rx="2" fill="#58D3DB" />
    </svg>
);

export const HighContrastThemeSVGIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="141"
        height="81"
        viewBox="0 0 141 81"
        fill="none"
    >
        <rect x="0.5" y="0.5" width="140" height="80" rx="6" fill="#111315" />
        <rect x="43" y="67" width="55" height="7" rx="0.5" stroke="white" />
        <path
            d="M43 30.5C43 30.2239 43.2239 30 43.5 30H97.5C97.7761 30 98 30.2239 98 30.5V37H43V30.5Z"
            stroke="white"
        />
        <path
            d="M43 39H98V59.5C98 59.7761 97.7761 60 97.5 60H43.5C43.2239 60 43 59.7761 43 59.5V39Z"
            stroke="white"
        />
        <path
            d="M43 8.5C43 8.22386 43.2239 8 43.5 8H97.5C97.7761 8 98 8.22386 98 8.5V21.5C98 21.7761 97.7761 22 97.5 22H43.5C43.2239 22 43 21.7761 43 21.5V8.5Z"
            stroke="white"
        />
        <rect x="6.5" y="7.5" width="31" height="4" rx="2" fill="#E5D55D" />
        <rect x="45.5" y="11.5" width="31" height="2" rx="1" fill="white" />
        <rect x="45.5" y="32.5" width="31" height="2" rx="1" fill="white" />
        <rect x="45.5" y="69.5" width="31" height="2" rx="1" fill="white" />
        <rect x="45.5" y="42.5" width="50" height="2" rx="1" fill="white" />
        <rect x="45.5" y="15.5" width="50" height="2" rx="1" fill="white" />
        <rect x="45.5" y="46.5" width="50" height="2" rx="1" fill="white" />
        <rect x="45.5" y="50.5" width="50" height="2" rx="1" fill="white" />
        <rect x="45.5" y="54.5" width="39" height="2" rx="1" fill="white" />
    </svg>
);
