import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {DeleteSessionRequest} from './sessions.types';

export default function useDeleteSessions() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: DeleteSessionRequest) => {
            // Delete all requested sessions individually.
            if (body.sessionIds === undefined) {
                // This condition should not happen.
                return false;
            }

            const results = await Promise.all(
                body.sessionIds.map((sessionId) =>
                    customFetch<Response>(
                        `/sessions/${sessionId}`,
                        {
                            method: 'DELETE',
                        },
                        true,
                    ).then((response) => response.status === 200),
                ),
            );

            // Now check the results.
            let deletionResult = true;
            results.forEach((callResult) => {
                deletionResult = deletionResult && callResult;
            });

            return deletionResult;
        },
        onSettled: (success, error, body) => {
            // Refetch the parent session.
            queryClient.refetchQueries({
                queryKey: ['sessions'],
            });
        },
    });
}
