import React, {useContext} from 'react';
import {EvaluationContextData} from './Evaluation.types';

const EvaluationContext = React.createContext<EvaluationContextData>({} as EvaluationContextData);

export const EvaluationProvider = ({...props}) => {
    const promptContextProps: EvaluationContextData = {
        sessionId: props.sessionId,
        promptId: props.promptId,
        evaluationId: props.evaluationId,
        children: props.children,
    };

    return (
        <EvaluationContext.Provider value={promptContextProps}>
            {props.children}
        </EvaluationContext.Provider>
    );
};

export const useEvaluationContext = (): EvaluationContextData => useContext(EvaluationContext);
