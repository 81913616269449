import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        width: '100%',
        ...shorthands.margin(tokens.spacingVerticalL, tokens.spacingHorizontalNone),
    },
    axisTicks: {
        '& .tick text': {
            fill: tokens.colorNeutralForeground4,
            width: tokens.spacingHorizontalXL,
        },
        '& .tick line': {
            stroke: tokens.colorNeutralForeground2,
        },
        '@media (forced-colors: active)': {
            '& .tick text': {
                fill: 'Highlight',
            },
            '& .tick line': {
                stroke: 'Highlight',
            },
        },
    },
    axisTitle: {
        fill: tokens.colorNeutralForeground4,
        '@media (forced-colors: active)': {
            fill: 'Highlight',
        },
    },
    chartWrapper: {
        // This represents the assigned capacity line on y-axis
        '& line[stroke="var(--colorStrokeFocus2)"]': {
            strokeWidth: tokens.strokeWidthThick,
        },
        // This represents the small dot shown on hover over a specific data point on assigned capacity line
        '& circle': {
            r: tokens.borderRadiusSmall,
        },
        '@media (forced-colors: active)': {
            // This applies to assigned capacity line by choosing line with specified stroke
            '& line[stroke="var(--colorStrokeFocus2)"]': {
                stroke: 'Highlight',
            },
            // This represents an individual bar on x-axis which are usually rect, however the last bar for each stacked bar is displayed via path
            // To choose red bar, we need to select path with fill color as specified in utils.ts (chartData transformation)
            '& path[fill="var(--colorStatusDangerBackground3)"]': {
                fill: 'CanvasText',
            },
        },
    },
});
