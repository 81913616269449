import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import {useCallback} from 'react';

/**
 * This hook is used to determine whether the user is allowed to manage User scope custom plugins.
 * @returns {isUserScopeUploadAllowed} `isUserScopeUploadAllowed` function that returns a boolean
 */
export default function useUserScopePluginUpload() {
    const {data: tenantInfo, isFetched: isTenantInfoFetched} = useGetTenantInfo();
    const {data: userInfo, isSuccess: adminSuccess} = useGetUserInfo();

    const isUserScopeUploadAllowed = useCallback((): boolean => {
        // If the user is an admin, they can add custom User plugins.
        if (adminSuccess && userInfo?.isAdmin) {
            return true;
        }

        // For non-admins, check the User setting.
        if (isTenantInfoFetched && tenantInfo) {
            // If setting not defined, default to allowing plugin upload
            if (tenantInfo.allowUserPluginUpload === undefined) {
                return true;
            }
            return tenantInfo.allowUserPluginUpload;
        }

        return false;
    }, [userInfo, adminSuccess, isTenantInfoFetched, tenantInfo]);

    return {isUserScopeUploadAllowed};
}
