import SyntaxHighlighter, {SyntaxHighlighterProps} from 'react-syntax-highlighter';
import {dark, light} from './SyntaxHighlighterThemes';
import {useAppState} from '@/api/app';
import useClasses from './CodeBlock.styles';

export default function SyntaxHighlighterCodeBlock({children, className, ...props}: any) {
    const classes = useClasses();
    // Beautify JSON, but otherwise keep the original value.
    let content: string = '';

    if (className === 'language-json') {
        try {
            content = String(children);
            content = JSON.stringify(JSON.parse(content), null, 2);
        } catch (error) {
            // TODO - Add error reporting here, if the system is responding with incomplete JSON we should know about it
            console.log(error);
        }
    }

    // replace any traiing newline characters with an empty string
    let sanitize = (code: string) => (code.endsWith('\n') ? code.slice(0, code.length - 1) : code);

    const {colorScheme} = useAppState();
    const style = colorScheme !== 'Default' && colorScheme !== 'Dark' ? light : dark;
    const language = className ? className.split('-')[1] : 'text';
    // syntaxhighlighter expects blocks of code, when we have an inline code segment in text we'll want to fallback to <code>
    return !props?.inline ? (
        <SyntaxHighlighter
            language={language}
            style={style as SyntaxHighlighterProps}
            lineProps={{style: {whiteSpace: 'break-spaces', marginTop: '17.5px'}}}
            wrapLines={true}
            showLineNumbers
            PreTag={({children}) => <pre className={classes.pre}>{content || children}</pre>}
        >
            {sanitize(String(children))}
        </SyntaxHighlighter>
    ) : (
        <code className={classes.pre}>{content || children}</code>
    );
}
