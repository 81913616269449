// Shared styles for stage layouts and fonts/type
import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    paragraphOverride: {
        marginBottom: tokens.spacingVerticalNone,
        marginTop: tokens.spacingVerticalNone,
    },
    buttonContent: {
        fontSize: '16px',
        fontWeight: tokens.fontWeightSemibold,
    },
    buttonRow: {
        marginTop: '48px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
