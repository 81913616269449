import {MedeinaLanguageCode, LanguageOption} from './locale.types';

export const LANGUAGE_OPTIONS: Array<LanguageOption> = [
    {value: 'en', label: 'LanguageOptionEnglish'},
    {value: 'es', label: 'LanguageOptionSpanish'},
    {value: 'ja', label: 'LanguageOptionJapanese'},
    {value: 'fr', label: 'LanguageOptionFrench'},
    {value: 'de', label: 'LanguageOptionGerman'},
    {value: 'pt-BR', label: 'LanguageOptionPortugueseBrazil'},
    {value: 'it', label: 'LanguageOptionItalian'},
    {value: 'zh-Hans', label: 'LanguageOptionChineseSimplified'},
    {value: 'ko', label: 'LanguageOptionKorean'},
    {value: 'nl', label: 'LanguageOptionDutch'},
    {value: 'sv', label: 'LanguageOptionSwedish'},
    {value: 'pl', label: 'LanguageOptionPolish'},
    {value: 'pt-PT', label: 'LanguageOptionPortuguesePortugal'},
    {value: 'no', label: 'LanguageOptionNorwegian'},
    {value: 'tr', label: 'LanguageOptionTurkish'},
    {value: 'da', label: 'LanguageOptionDanish'},
    {value: 'fi', label: 'LanguageOptionFinnish'},
    {value: 'zh-Hant', label: 'LanguageOptionChineseTraditional'},
    {value: 'ar', label: 'LanguageOptionArabic'},
    {value: 'th', label: 'LanguageOptionThai'},
    {value: 'he', label: 'LanguageOptionHebrew'},
    {value: 'cs', label: 'LanguageOptionCzech'},
    {value: 'hu', label: 'LanguageOptionHungarian'},
    {value: 'uk', label: 'LanguageOptionUkrainian'},
    {value: 'ru', label: 'LanguageOptionRussian'},
];

export type LanguageOptionMap = Record<MedeinaLanguageCode, LanguageOption>;

export const LANGUAGE_OPTION_MAP: LanguageOptionMap = LANGUAGE_OPTIONS.reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
}, {} as LanguageOptionMap);

// this is a map of our language options to the date-fns locale codes
// date-fns/locale
export const LANGUAGE_DATE_FN_MAP: Record<MedeinaLanguageCode, string> = {
    en: 'en-US',
    es: 'es',
    ja: 'ja',
    fr: 'fr',
    de: 'de',
    'pt-BR': 'pt-BR',
    it: 'it',
    'zh-Hans': 'zh-CN',
    ko: 'ko',
    nl: 'nl',
    sv: 'sv',
    pl: 'pl',
    'pt-PT': 'pt',
    no: 'nb',
    tr: 'tr',
    da: 'da',
    fi: 'fi',
    'zh-Hant': 'zh-CN',
    ar: 'ar',
    th: 'th',
    he: 'he',
    cs: 'cs',
    hu: 'hu',
    uk: 'uk',
    ru: 'ru',
};

export const RTL_LANGUAGES: Array<MedeinaLanguageCode> = ['ar', 'he'];
