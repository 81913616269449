import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    textWrapper: {
        maxWidth: '600px',
    },
    buttomMargin: {
        marginBottom: '48px',
    },
});
