import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {customTokens} from '@/components/theme/index';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    caption: {
        color: tokens.colorNeutralForeground4,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
    },
    button: {
        ...shorthands.margin('10px', '10px', '0px', '0px'),
        ...shorthands.border('0px'),
        fontFamily: tokens.fontFamilyBase,
        color: tokens.colorCompoundBrandBackground,
        backgroundColor: customTokens.securitySourcesBackground,
        fontWeight: tokens.fontWeightRegular,
        minWidth: '50px',
        maxWidth: '300px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textAlign: 'left',
        ':hover': {
            backgroundColor: customTokens.securitySourcesBackgroundHover,
            color: tokens.colorCompoundBrandBackground,
        },
        '&:disabled, &:disabled:hover': {
            color: tokens.colorNeutralForeground1,
        },
        '&:enabled:active': {
            backgroundColor: customTokens.securitySourcesBackgroundPressed,
            color: tokens.colorCompoundBrandBackground,
        },
    },
});
