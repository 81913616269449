import {mergeClasses} from '@fluentui/react-components';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {motion} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {TourStageContainer} from '@/components/ui/Tour';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';

interface PoweringUpProps {
    onDisplayComplete: () => void;
}

export default function PoweringUp(props: PoweringUpProps) {
    const tourClasses = useTourClasses();
    const {t} = useTranslation('tours');

    const POWERING_UP_SCREEN_PERSISTENCE_TIME = 1200;
    const DOT_COUNT = 3;
    const DOT_INCREMENT_TIME = 375;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            props.onDisplayComplete();
        }, POWERING_UP_SCREEN_PERSISTENCE_TIME);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [props.onDisplayComplete]);

    const [dotCount, setDotCount] = useState(1);
    const dots = (dotCount % DOT_COUNT) + 1;
    const dot = '.'.repeat(dots);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDotCount((prevValue) => prevValue + 1);
        }, DOT_INCREMENT_TIME);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <motion.div
            key="poweringup"
            variants={overlayAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <TourStageContainer>
                <TourStageContainer.StageContent>
                    <div>
                        <span className={mergeClasses(tourClasses.blockTitle)}>
                            {t('common.PoweringUp')}
                            {dot}
                        </span>
                    </div>
                </TourStageContainer.StageContent>
            </TourStageContainer>
        </motion.div>
    );
}
