import React, {useCallback, useMemo} from 'react';
import {
    Field,
    Button,
    Dialog,
    DialogTrigger,
    DialogTitle,
    DialogSurface,
    DialogActions,
} from '@fluentui/react-components';
import {DatePicker} from '@fluentui/react-datepicker-compat';

import {CustomDateRangeEditorProps} from './SearchFilter.types';
import useClasses from './CustomDateRangeEditor.styles';
import {useTranslation} from 'react-i18next';

type OnSelectDateInput = Date | null | undefined;

const CustomDateRangeEditor = (props: CustomDateRangeEditorProps) => {
    const {dateRange, onDateRangeChange, onDismiss, isOpen} = props;
    const classes = useClasses();
    const defaultStartDate = typeof dateRange === 'object' ? dateRange.startDate : undefined;
    const defaultEndDate = typeof dateRange === 'object' ? dateRange.endDate : undefined;
    const [startDate, setStartDate] = React.useState<OnSelectDateInput>(defaultStartDate);
    const [endDate, setEndDate] = React.useState<OnSelectDateInput>(defaultEndDate);

    const isFormEmpty = !Boolean(startDate) || !Boolean(endDate);
    const isDateRangeInvalid = useMemo(() => {
        return !(startDate && endDate && startDate < endDate);
    }, [startDate, endDate]);

    const handleSubmit = useCallback(() => {
        if (startDate && endDate) {
            onDateRangeChange({startDate, endDate});
            onDismiss();
        }
    }, [startDate, endDate, onDateRangeChange, onDismiss]);

    const {t} = useTranslation('mysessions');
    return (
        <Dialog
            open={isOpen}
            onOpenChange={(event, data) => {
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    onDismiss();
                }
            }}
        >
            <DialogSurface className={classes.root} tabIndex={0}>
                <DialogTitle className={classes.title} tabIndex={0}>
                    {t('SelectACustomDateRange')}
                </DialogTitle>
                <Field label={t('BetweenLabel')} required>
                    <DatePicker
                        placeholder={t('SelectADatePlaceholder')}
                        value={typeof dateRange === 'object' ? dateRange.startDate : startDate}
                        onSelectDate={setStartDate}
                        maxDate={new Date()}
                        onKeyDown={(event) => {
                            if (event.key === 'Escape') {
                                onDismiss();
                            }
                        }}
                    />
                </Field>
                <Field label={t('AndLabel')} required>
                    <DatePicker
                        placeholder={t('SelectADatePlaceholder')}
                        value={typeof dateRange === 'object' ? dateRange.endDate : endDate}
                        onSelectDate={setEndDate}
                        maxDate={new Date()}
                        onKeyDown={(event) => {
                            if (event.key === 'Escape') {
                                onDismiss();
                            }
                        }}
                    />
                </Field>
                {startDate && endDate && isDateRangeInvalid && (
                    <div aria-live="polite">{t('PleaseEnterValidDateRange')}</div>
                )}
                <DialogActions className={classes.actions}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            aria-label={t('CancelButton')}
                            appearance="secondary"
                            onClick={onDismiss}
                        >
                            {t('CancelButton')}
                        </Button>
                    </DialogTrigger>
                    <Button
                        aria-label={t('ApplyButtonLabel')}
                        appearance="primary"
                        onClick={handleSubmit}
                        disabled={isDateRangeInvalid || isFormEmpty}
                    >
                        {t('ApplyButtonLabel')}
                    </Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};

export default CustomDateRangeEditor;
