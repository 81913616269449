import {readFromLocalStorage} from '@/api/user/user';
import i18next from 'i18next';
import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import MedeinaFeatures from '@/util/features';

const fallbackLanguage = 'en';

export default function getEffectiveLanguage(): string {
    // Read from local storage once
    const localStorageData = readFromLocalStorage();
    // the resolved language from i18next based on the browser language
    // it should match the file name in the languages directory
    const i18Language = i18next.resolvedLanguage;
    // Check if the necessary properties exist
    const languageCode = localStorageData?.preferences?.locale?.languageCode;
    const useBrowserLanguage = localStorageData?.useBrowserLanguage;
    // Get the browser language, if available
    const browserLanguageCode = typeof window !== 'undefined' && window?.navigator.languages[0];
    if (hasStaticFeatureFlag(MedeinaFeatures.Debug)) {
        console.log({
            useBrowserLanguage,
            resolvedLanguage: i18Language,
            userSavedLanguage: languageCode,
            browserLanguageCode,
            fallback: fallbackLanguage,
        });
    }
    // Determine the effective language
    if (useBrowserLanguage) {
        return i18Language || browserLanguageCode || fallbackLanguage; // Fallback to 'en' if browser language is not available
    } else if (languageCode) {
        return languageCode;
    } else {
        return browserLanguageCode || fallbackLanguage; // Fallback to 'en' if browser language is not available
    }
}
