import {
    Dialog,
    DialogSurface,
    DialogActions,
    DialogTrigger,
    Button,
    DialogTitle,
    DialogContent,
    DialogBody,
    mergeClasses,
    Spinner,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {Dismiss24Regular} from '@fluentui/react-icons';
import {AssistanceFormStates, ErrorPanel, FeedbackProps, InformationPanel} from '.';
import {useClasses} from './Feedback.styles';
import {ComplimentIcon, GiveFeedbackIcon, ReportErrorIcon} from '../../icons';
import useGiveFeedback from '@/api/feedbacks/useGiveFeedback';
import Compliment from './Compliment';
import Suggestion from './Suggestion';
import ReportProblem from './ReportProblem';
import {FeedbackCategory, FeedbackRequest} from '@/api/feedbacks/feedbacks.types';

const successPanelParams = {
    title: 'Thank you!',
    content: 'Your request has been submitted.',
};

const componentLabels = {
    title: 'Submit feedback to Microsoft',
    selectCategoryText: 'Select a category',
    selectCategoryPlaceholder: 'Category Name',
    whatDoYouNeedHelpWithText: 'What do you need help with?',
    whatDoYouNeedHelpWithPlaceholder: 'Provide details without including personal information.',
    whatDoYouLike: 'What do you like? (Required)',
    submitButtonText: 'Submit',
    cancelButtonText: 'Cancel',
};

export default function Feedback(props: FeedbackProps) {
    const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

    const [additionalDetails, setAdditionalDetails] = useState<string>('');
    const [formState, setFormState] = useState<AssistanceFormStates>(AssistanceFormStates.Edit);
    const {mutate: giveFeedbackRequest} = useGiveFeedback();

    const [selectedFeedback, setSelectedFeedback] = useState<FeedbackCategory | null>(null);

    const classes = useClasses();

    const closeModal = () => {
        props.onCloseModal?.();
        setDialogOpen(false);
        setSelectedFeedback(null);
        setAdditionalDetails('');
        setFormState(AssistanceFormStates.Edit);
    };

    useEffect(() => {
        setDialogOpen(props.openModal);
    }, [props.openModal]);

    const iSubmitDisabled = function () {
        return (
            !selectedFeedback || !additionalDetails || formState === AssistanceFormStates.InProgress
        );
    };

    function handleFeedbackClick(feedbackType: FeedbackCategory): void {
        setSelectedFeedback(feedbackType);
    }

    const submitForm = () => {
        setFormState(AssistanceFormStates.InProgress);

        const feedbackRequest: FeedbackRequest = {
            category: selectedFeedback!,
            description: additionalDetails,
        };

        giveFeedbackRequest(feedbackRequest, {
            onSuccess: (data) => {
                if (!!data) {
                    setFormState(AssistanceFormStates.Success);
                } else {
                    setFormState(AssistanceFormStates.Error);
                }
            },
            onError: () => {
                setFormState(AssistanceFormStates.Error);
            },
        });
    };

    return (
        <Dialog open={isDialogOpen} inertTrapFocus>
            <DialogSurface className={classes.root}>
                <DialogTrigger disableButtonEnhancement>
                    <Button
                        className={classes.closeButton}
                        appearance="transparent"
                        aria-label="dismiss-button"
                        onClick={() => closeModal()}
                    >
                        <Dismiss24Regular />
                    </Button>
                </DialogTrigger>
                <DialogBody>
                    {formState === AssistanceFormStates.Success && (
                        <InformationPanel {...successPanelParams}></InformationPanel>
                    )}

                    {formState === AssistanceFormStates.Error && <ErrorPanel />}

                    {(formState === AssistanceFormStates.Edit ||
                        formState === AssistanceFormStates.InProgress) && (
                        <>
                            <DialogTitle>{componentLabels.title}</DialogTitle>
                            <DialogContent className={classes.content}>
                                {selectedFeedback === null ? (
                                    <>
                                        <div className={mergeClasses(classes.controlMargin)}>
                                            <Button
                                                icon={<ComplimentIcon />}
                                                className={mergeClasses(classes.fullWidth)}
                                                onClick={() =>
                                                    handleFeedbackClick(FeedbackCategory.Compliment)
                                                }
                                            >
                                                Give a compliment
                                            </Button>
                                        </div>
                                        <div className={mergeClasses(classes.controlMargin)}>
                                            <Button
                                                icon={<ReportErrorIcon />}
                                                className={mergeClasses(classes.fullWidth)}
                                                onClick={() =>
                                                    handleFeedbackClick(
                                                        FeedbackCategory.ReportProblem,
                                                    )
                                                }
                                            >
                                                Report a problem
                                            </Button>
                                        </div>
                                        <div className={mergeClasses(classes.controlMargin)}>
                                            <Button
                                                icon={<GiveFeedbackIcon />}
                                                className={mergeClasses(classes.fullWidth)}
                                                onClick={() =>
                                                    handleFeedbackClick(FeedbackCategory.Suggestion)
                                                }
                                            >
                                                Make a suggestion
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {selectedFeedback === FeedbackCategory.Compliment && (
                                            <Compliment
                                                onDetailsChange={(details) =>
                                                    setAdditionalDetails(details)
                                                }
                                            />
                                        )}
                                        {selectedFeedback === FeedbackCategory.ReportProblem && (
                                            <ReportProblem
                                                onDetailsChange={(details) =>
                                                    setAdditionalDetails(details)
                                                }
                                            />
                                        )}
                                        {selectedFeedback === FeedbackCategory.Suggestion && (
                                            <Suggestion
                                                onDetailsChange={(details) =>
                                                    setAdditionalDetails(details)
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions position="start" className={classes.actionWrapper}>
                                <Button
                                    appearance="primary"
                                    disabled={iSubmitDisabled()}
                                    onClick={() => submitForm()}
                                >
                                    {formState === AssistanceFormStates.Edit &&
                                        componentLabels.submitButtonText}

                                    {formState === AssistanceFormStates.InProgress && (
                                        <Spinner size="tiny" />
                                    )}
                                </Button>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary" onClick={() => closeModal()}>
                                        {componentLabels.cancelButtonText}
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </>
                    )}
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
