import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PageResponse} from '../api.types';
import useFetch from '../useFetch';
import {Prompt, useRerunPrompts} from '../prompts';
import {CreateEvaluationRequest, Evaluation} from './evaluations.types';
import useCreateAndWaitForEvaluation from './useCreateAndWaitForEvaluation';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
export default function useCreateEvaluationAndRerunPrompts() {
    // We need to `await` the mutation, so use `mutateAsync`.
    const {mutateAsync: createAndWaitForEvaluation} = useCreateAndWaitForEvaluation();
    const {mutateAsync: rerunPrompts} = useRerunPrompts();
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: CreateEvaluationRequest) => {
            const [ignoredEvaluation, nextPrompts] = await Promise.all([
                // Start the edited/re-run evaluation.
                createAndWaitForEvaluation(body),
                // Grab the rest of the prompts to be re-run while we wait.
                customFetch<PageResponse<Prompt>>(
                    `/sessions/${body?.sessionId}/prompts?lastPromptId=${body?.promptId}`,
                    {
                        method: 'GET',
                    },
                ).then((prompts) => {
                    // Temporarily flag each prompt as "queued" to show a spinner in the UI.
                    // TODO: Come up with a better way of handling this.
                    prompts?.value.forEach(({promptId}) => {
                        queryClient.setQueriesData(
                            ['sessions', body?.sessionId, 'prompts', promptId],
                            // @ts-ignore
                            (oldData) => ({
                                // @ts-ignore
                                ...oldData,
                                __isQueued: true,
                            }),
                        );
                    });
                    return prompts;
                }),
            ]);

            // Iterate through the following prompts in sequence.
            await rerunPrompts({
                sessionId: body?.sessionId as string,
                promptIds: nextPrompts.value.map((prompt) => prompt.promptId),
            });
        },
        onSuccess: (data, body) => {
            if (body) changeSessionUpdatedAt(body.sessionId);
        },
    });
}
