import React, {useCallback, useMemo} from 'react';
import {
    Field,
    Button,
    Dialog,
    DialogTrigger,
    DialogTitle,
    DialogSurface,
    DialogActions,
} from '@fluentui/react-components';
import {DatePicker} from '@fluentui/react-datepicker-compat';
import useClasses from './CustomDateRangePickerDialog.styles';
import {CustomDateRangePickerDialogProps} from './CustomDateRangePickerDialog.types';
import {useTranslation} from 'react-i18next';
import {ButtonLabels, DatePickerLabels} from '../../UsageDashboard.constants';

export default function CustomDateRangePickerDialog(props: CustomDateRangePickerDialogProps) {
    const {dateRange, onDateRangeChange, onDismiss, isOpen} = props;
    const classes = useClasses();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');

    const [startDate, setStartDate] = React.useState<Date | null | undefined>(dateRange.startDate);
    const [endDate, setEndDate] = React.useState<Date | null | undefined>(dateRange.endDate);

    const presentDate = new Date();
    const searchableDays = new Date();
    searchableDays.setDate(searchableDays.getDate() - props.minSearchStartDate);
    const isDateRangeInvalid = useMemo(() => {
        if (startDate) {
            startDate.setHours(0, 0, 0, 0);
        }
        if (endDate) {
            endDate.setHours(23, 59, 59, 999);
        }
        return !(startDate && endDate && startDate <= endDate);
    }, [startDate, endDate]);

    const handleSubmit = useCallback(() => {
        if (startDate && endDate) {
            onDateRangeChange({startDate, endDate});
            onDismiss();
        }
    }, [startDate, endDate, onDateRangeChange, onDismiss]);

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(event, data) => {
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    onDismiss();
                }
            }}
        >
            <DialogSurface className={classes.root} tabIndex={0}>
                <DialogTitle className={classes.title} tabIndex={0}>
                    {tAdmin(DatePickerLabels.HEADER)}
                </DialogTitle>
                <Field label={tAdmin(DatePickerLabels.RANGE_LABEL_BETWEEN)} required>
                    <DatePicker
                        placeholder={tAdmin(DatePickerLabels.START_DATE_PLACEHOLDER)}
                        value={startDate}
                        onSelectDate={setStartDate}
                        maxDate={presentDate}
                        minDate={searchableDays}
                        onKeyDown={(event) => {
                            if (event.key === 'Escape') {
                                onDismiss();
                            }
                        }}
                        calendar={{className: classes.calendar}}
                    />
                </Field>
                <Field label={tAdmin(DatePickerLabels.RANGE_LABEL_AND)} required>
                    <DatePicker
                        placeholder={tAdmin(DatePickerLabels.END_DATE_PLACEHOLDER)}
                        value={endDate}
                        onSelectDate={setEndDate}
                        maxDate={presentDate}
                        minDate={searchableDays}
                        onKeyDown={(event) => {
                            if (event.key === 'Escape') {
                                onDismiss();
                            }
                        }}
                        calendar={{className: classes.calendar}}
                    />
                </Field>
                {isDateRangeInvalid && (
                    <div aria-live="polite">{tAdmin(DatePickerLabels.RANGE_ERROR_MESSAGE)}</div>
                )}
                <DialogActions className={classes.actions}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            aria-label={tCommon(ButtonLabels.CANCEL)}
                            appearance="secondary"
                            onClick={onDismiss}
                        >
                            {tCommon(ButtonLabels.CANCEL)}
                        </Button>
                    </DialogTrigger>
                    <Button
                        aria-label={tCommon(ButtonLabels.APPLY)}
                        appearance="primary"
                        onClick={handleSubmit}
                        disabled={isDateRangeInvalid}
                    >
                        {tCommon(ButtonLabels.APPLY)}
                    </Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
}
