import {ValidTourErrorMap, ValidTourErrorPropCombination} from '@/components/ui/Tour';

export enum TourErrorTypes {
    DataLoadError = 'Default',
    CapacityError = 'CapacityError',
    CapacityPermissionError = 'CapacityPermissionError',
    SelectionError = 'SelectionError',
}

export enum TOUR_STEPS {
    None = 'none',
    Welcome = 'welcome',
    SelectLocation = 'selectLocation',
    CreateCapacity = 'createCapacity',
    SelectCapacity = 'SelectCapacity',
    Locations = 'locations',
    RoleInfo = 'roleInfo',
    RoleInfoNew = 'roleInfoNew',
    DataAndPrivacy = 'dataAndPrivacy',
    O365Consent = 'O365Consent',
    AuditLoggingConsent = 'AuditLoggingConsent',
    TourCompleted = 'tourCompleted',
    HOME = 'home',
    OPENMODAL = 'openModal',
    SETUPPLUGIN = 'setUpPlugin',
    ADDPLUGIN = 'addPlugin',
    SUPPORTMENU = 'supportMenu',
}

export const TOUR_ERRORS: ValidTourErrorMap = {
    [TourErrorTypes.DataLoadError]: {
        heading: 'errorMessages.dataLoadError.Heading',
        message: 'errorMessages.dataLoadError.Message',
        actionButtonString: 'errorMessages.dataLoadError.ActionButtonString',
    },
    [TourErrorTypes.CapacityError]: {
        heading: 'errorMessages.capacityError.Heading',
        message: 'errorMessages.capacityError.Message',
        actionButtonString: 'errorMessages.capacityError.ActionButtonString',
    },
    [TourErrorTypes.CapacityPermissionError]: {
        heading: 'errorMessages.capacityErrorPermission.Heading',
        message: 'errorMessages.capacityErrorPermission.Message',
        actionButtonString: 'errorMessages.capacityErrorPermission.ActionButtonString',
    },
    [TourErrorTypes.SelectionError]: {
        heading: 'errorMessages.selectionError.Heading',
        message: 'errorMessages.selectionError.Message',
        actionButtonString: 'errorMessages.selectionError.ActionButtonString',
    },
};

export enum FreRbacTypeSelection {
    None = 'none',
    RecommendedRoles = 'recommendedRoles',
    everyone = 'everyone',
}
