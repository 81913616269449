import * as React from 'react';
const SVGComponent = (props: any) => (
    <svg
        width={1557}
        height={1252}
        viewBox="0 0 1557 1252"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity={0.7} filter="url(#filter0_f_1_5)">
            <path
                d="M305.165 599.255C366.968 411.202 737.787 460.096 973.114 324.698C1137.13 230.671 1175.16 430.007 1246.47 674.476C1317.78 918.945 973.114 900.14 863.77 945.273C754.426 990.405 243.362 787.308 305.165 599.255Z"
                fill="#0D3D7F"
            />
        </g>
        <defs>
            <filter
                id="filter0_f_1_5"
                x={0}
                y={0.952637}
                width={1556.1}
                height={1250.69}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={150} result="effect1_foregroundBlur_1_5" />
            </filter>
        </defs>
    </svg>
);
export default SVGComponent;
