import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    titleWrapper: {
        display: 'flex',
        width: '100%',
        marginBottom: '15px',
        '@media (max-width: 480px)': {
            marginBottom: '5px',
        },
    },

    title: {
        flexGrow: 1,
    },

    dialogInfo: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: '20px',
    },

    closeButton: {
        maxHeight: '32px',
    },

    contentSection: {
        marginBottom: '28px',
        '@media (max-width: 480px)': {
            marginBottom: '5px',
            overflowY: 'auto',
        },
        overflowY: 'hidden',
    },

    memberListWrapper: {
        maxHeight: '30vh',
        overflowY: 'auto',
    },

    messageBar: {
        marginBottom: tokens.spacingVerticalXL,
    },
    dialogActions: {
        flexDirection: 'row',
        '@media (max-width: 480px)': {
            flexDirection: 'row',
        },
    },
});
