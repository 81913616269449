import React, {useState, useMemo, useContext, useEffect} from 'react';
import {merge as _merge} from 'lodash-es';
import {AppState, AppStateObject, ColorScheme, DebugLevel, TourHistory} from './app.types';
import {LayoutState} from '@/components/ui/Layout/Layout.types';
import useDebounce from '@/util/useDebounce';
import {readFromLocalStorage, writeToLocalStorage} from './appStorage';

export * from './app.types';
export * from './appStorage';

const AppStateContext = React.createContext<AppStateObject>(undefined!);

const defaultAppState = readFromLocalStorage();

export function useAppState() {
    return useContext(AppStateContext);
}

export function AppStateProvider({children}: {children: React.ReactNode}) {
    const [appState, setAppState] = useState<AppState>(defaultAppState);
    const debouncedAppState = useDebounce(appState, 3000);

    // Whenever AppState changes, write it to localStorage.
    useEffect(() => {
        // we debounce the save to local storage to avoid writing to it too often
        // in the future this should be replaced with an API call to save the state to the server
        writeToLocalStorage(appState);
    }, [debouncedAppState]);

    // Return the AppState value context.
    const value = useMemo<AppStateObject>(
        () => ({
            ...appState,
            setColorScheme: (colorScheme: ColorScheme) => setAppState({...appState, colorScheme}),
            setDebugLevel: (debugLevel: DebugLevel) => setAppState({...appState, debugLevel}),
            setUserTour: (tourID: string, tourHistory: Partial<TourHistory>) => {
                // Prepare to merge the new tour history with the existing tour history
                const currentTourHistory = appState.userTours?.[tourID] || {};
                // Merge the new tour history with the existing tour history
                const mergedHistory = _merge(currentTourHistory, tourHistory);
                return setAppState({
                    ...appState,
                    userTours: {
                        ...appState.userTours,
                        [tourID]: mergedHistory,
                    },
                });
            },
            updateLayout: (layout: Partial<LayoutState>) => {
                setAppState({...appState, layout: _merge(appState.layout, layout)});
            },
        }),
        [appState, setAppState],
    );

    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}
