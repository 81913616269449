import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '85%',
    },
    searchbar: {
        width: '324px',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXS),
    },
    btnSearchStart: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
    },
    subButton: {
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        color: 'inherit',
        marginLeft: tokens.spacingHorizontalXS,
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            color: tokens.colorBrandForegroundInvertedHover,
        },
    },
    btnAddFilter: {
        ...shorthands.margin(0, tokens.spacingHorizontalSNudge),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
    },
    filterName: {
        fontWeight: tokens.fontWeightRegular,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
});
