import {useGetEvaluation} from '@/api/evaluations';
import {Markdown} from '@/components/ui';
import useClasses from './Evaluation.styles';
import {EvaluationProps} from './Evaluation.types';

export default function ErrorEvaluationResult({
    sessionId,
    promptId,
    evaluationId,
}: EvaluationProps) {
    const classes = useClasses();
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});

    return (
        <div className={classes.evaluation}>
            <Markdown>{evaluation?.result?.content ?? ''}</Markdown>
        </div>
    );
}
