import * as React from 'react';
const FaceMonocle = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path
                fill="url(#f470id0)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f470id1)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f470id2)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f470id3)"
                fillOpacity=".6"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f470id4)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f470id5)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f470id6)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <path
                fill="url(#f470id7)"
                d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
            />
            <g filter="url(#f470ide)">
                <path
                    fill="url(#f470idm)"
                    d="m26.684 25.521l.33-.324l-2.815-3.669l-1.402 2.34l3.887 1.653Z"
                />
            </g>
            <g filter="url(#f470idf)">
                <path
                    stroke="#D46A37"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeOpacity=".6"
                    strokeWidth="1.3"
                    d="M17.791 5.787s.979-.78 2.473-.78c1.494 0 2.71.912 2.71.912"
                />
            </g>
            <g filter="url(#f470idg)">
                <path
                    stroke="#AC552B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeOpacity=".6"
                    strokeWidth="1.3"
                    d="M7.001 7.822s.979-.78 2.473-.78c1.494 0 2.71.913 2.71.913"
                />
            </g>
            <g filter="url(#f470idh)">
                <circle cx="20.383" cy="13.014" r="5.263" fill="#CD6330" fillOpacity=".6" />
            </g>
            <g filter="url(#f470idi)">
                <circle cx="9.753" cy="12.358" r="4.105" fill="#D56A37" fillOpacity=".6" />
            </g>
            <g filter="url(#f470idj)">
                <path
                    fill="#DF7644"
                    fillRule="evenodd"
                    d="M25.354 11.376c.158 0 .286.269.286.6v13.438c0 .331-.128.6-.286.6c-.157 0-.285-.269-.285-.6V11.976c0-.331.128-.6.285-.6Z"
                    clipRule="evenodd"
                />
            </g>
            <circle cx="21.454" cy="11.923" r="4.453" fill="#fff" />
            <circle cx="10.22" cy="11.97" r="4.219" fill="#fff" />
            <path
                fill="#402A32"
                fillRule="evenodd"
                d="M21.001 8.251A3 3 0 1 0 21 14.25a3 3 0 0 0 .001-5.999Zm-11 .25a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
                clipRule="evenodd"
            />
            <path
                stroke="url(#f470id8)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M7.962 6.613s.873-.756 2.434-.756c1.665 0 2.644.888 2.644.888"
            />
            <path
                stroke="url(#f470id9)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M18.91 4.369s.978-.78 2.472-.78s2.71.912 2.71.912"
            />
            <path
                stroke="url(#f470ida)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.8"
                d="M13.098 18.62s1.35-1.062 3.358-.556c1.673.421 2.446 1.944 2.446 1.944"
            />
            <path
                fill="url(#f470idn)"
                fillRule="evenodd"
                d="M27.354 11.376a.6.6 0 0 1 .6.6v13.438a.6.6 0 0 1-1.2 0V11.976a.6.6 0 0 1 .6-.6Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f470ido)"
                fillRule="evenodd"
                d="M27.354 11.376a.6.6 0 0 1 .6.6v13.438a.6.6 0 0 1-1.2 0V11.976a.6.6 0 0 1 .6-.6Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f470idp)"
                fillRule="evenodd"
                d="M27.354 11.376a.6.6 0 0 1 .6.6v13.438a.6.6 0 0 1-1.2 0V11.976a.6.6 0 0 1 .6-.6Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f470idb)"
                fillRule="evenodd"
                d="M27.357 12.958c-.209.121-.401.294-.468.526a5.653 5.653 0 1 1 0-3.122c.067.232.26.405.468.526a1.195 1.195 0 0 1 0 2.07Zm-1.45-1.035a4.453 4.453 0 1 1-8.906 0a4.453 4.453 0 0 1 8.906 0Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f470idq)"
                fillRule="evenodd"
                d="M27.357 12.958c-.209.121-.401.294-.468.526a5.653 5.653 0 1 1 0-3.122c.067.232.26.405.468.526a1.195 1.195 0 0 1 0 2.07Zm-1.45-1.035a4.453 4.453 0 1 1-8.906 0a4.453 4.453 0 0 1 8.906 0Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f470idc)"
                fillRule="evenodd"
                d="M27.357 12.958c-.209.121-.401.294-.468.526a5.653 5.653 0 1 1 0-3.122c.067.232.26.405.468.526a1.195 1.195 0 0 1 0 2.07Zm-1.45-1.035a4.453 4.453 0 1 1-8.906 0a4.453 4.453 0 0 1 8.906 0Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f470idd)"
                fillRule="evenodd"
                d="M27.357 12.958c-.209.121-.401.294-.468.526a5.653 5.653 0 1 1 0-3.122c.067.232.26.405.468.526a1.195 1.195 0 0 1 0 2.07Zm-1.45-1.035a4.453 4.453 0 1 1-8.906 0a4.453 4.453 0 0 1 8.906 0Z"
                clipRule="evenodd"
            />
            <g filter="url(#f470idk)">
                <circle cx="21.454" cy="11.923" r="5.194" stroke="url(#f470idr)" strokeWidth=".2" />
            </g>
            <g filter="url(#f470idl)">
                <ellipse
                    cx="27.114"
                    cy="11.571"
                    fill="url(#f470ids)"
                    rx=".581"
                    ry=".531"
                    transform="rotate(44.538 27.114 11.571)"
                />
            </g>
            <defs>
                <radialGradient
                    id="f470id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(132.839 10.786 10.065) scale(37.5033)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f470id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.878 10.74 10.193) scale(38.9487)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f470id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(101.31 2.876 12.808) scale(17.8466 22.8581)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".788" stopColor="#F59639" stopOpacity="0" />
                    <stop offset=".973" stopColor="#FF7DCE" />
                </radialGradient>
                <radialGradient
                    id="f470id3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-29 29 -29 -29 18 14)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".315" stopOpacity="0" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="f470id4"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(77.692 -2.555 18.434) scale(28.1469)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".508" stopColor="#7D6133" stopOpacity="0" />
                    <stop offset="1" stopColor="#715B32" />
                </radialGradient>
                <radialGradient
                    id="f470id5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(7.5 10.99996 -7.97335 5.4364 16.5 16.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB849" />
                    <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f470id6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(11.49998 2 -2 11.49998 20.5 18)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA64B" />
                    <stop offset=".9" stopColor="#FFAE46" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f470id7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(43.971 -9.827 29.173) scale(59.0529)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".185" stopOpacity="0" />
                    <stop offset="1" stopOpacity=".4" />
                </radialGradient>
                <radialGradient
                    id="f470id8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(1.00489 -7.69537 15.46203 2.01909 11.307 8.068)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".07" stopColor="#2E1529" />
                    <stop offset=".297" stopColor="#5F4357" />
                    <stop offset=".438" stopColor="#4B2744" />
                </radialGradient>
                <radialGradient
                    id="f470id9"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-.10646 -6.55826 12.1842 -.19779 21.17 5.37)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".07" stopColor="#2E1529" />
                    <stop offset=".297" stopColor="#5F4357" />
                    <stop offset=".438" stopColor="#4B2744" />
                </radialGradient>
                <radialGradient
                    id="f470ida"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(2.81237 -14.74998 16.00664 3.05198 15.512 22.129)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".07" stopColor="#2E1529" />
                    <stop offset=".297" stopColor="#5F4357" />
                    <stop offset=".438" stopColor="#4B2744" />
                </radialGradient>
                <radialGradient
                    id="f470idb"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-3.43751 7.0125 -7.53775 -3.69499 24.689 10.564)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A09DA8" />
                    <stop offset="1" stopColor="#938D9A" />
                </radialGradient>
                <radialGradient
                    id="f470idc"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(106.682 8.347 12.307) scale(9.68901 9.47937)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".679" stopColor="#987AB5" stopOpacity="0" />
                    <stop offset="1" stopColor="#987AB5" />
                </radialGradient>
                <radialGradient
                    id="f470idd"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(2.9688 -5.68747 10.31888 5.38633 21.189 15.752)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#837890" />
                    <stop offset=".208" stopColor="#837890" stopOpacity="0" />
                </radialGradient>
                <filter
                    id="f470ide"
                    width="5.218"
                    height="4.993"
                    x="22.297"
                    y="21.028"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation=".25" />
                </filter>
                <filter
                    id="f470idf"
                    width="10.483"
                    height="6.212"
                    x="15.141"
                    y="2.357"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation="1" />
                </filter>
                <filter
                    id="f470idg"
                    width="10.483"
                    height="6.212"
                    x="4.351"
                    y="4.392"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation="1" />
                </filter>
                <filter
                    id="f470idh"
                    width="12.526"
                    height="12.526"
                    x="14.12"
                    y="6.751"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation=".5" />
                </filter>
                <filter
                    id="f470idi"
                    width="10.21"
                    height="10.21"
                    x="4.648"
                    y="7.253"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation=".5" />
                </filter>
                <filter
                    id="f470idj"
                    width="4.571"
                    height="18.637"
                    x="23.069"
                    y="9.376"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation="1" />
                </filter>
                <filter
                    id="f470idk"
                    width="11.187"
                    height="11.188"
                    x="15.861"
                    y="6.329"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation=".15" />
                </filter>
                <filter
                    id="f470idl"
                    width="1.614"
                    height="1.613"
                    x="26.306"
                    y="10.765"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_6815_5229" stdDeviation=".125" />
                </filter>
                <linearGradient
                    id="f470idm"
                    x1="26.486"
                    x2="24.846"
                    y1="26.5"
                    y2="24.992"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B95A2D" />
                    <stop offset="1" stopColor="#D46A37" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f470idn"
                    x1="27.292"
                    x2="23.68"
                    y1="12.376"
                    y2="13.077"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAC6D5" />
                    <stop offset="1" stopColor="#B9B2C1" />
                </linearGradient>
                <linearGradient
                    id="f470ido"
                    x1="27.954"
                    x2="26.126"
                    y1="19.47"
                    y2="19.47"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8C8693" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C8693" />
                </linearGradient>
                <linearGradient
                    id="f470idp"
                    x1="28.439"
                    x2="24.308"
                    y1="8.189"
                    y2="9.039"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".679" stopColor="#B08CD2" stopOpacity="0" />
                    <stop offset="1" stopColor="#BB96DE" />
                </linearGradient>
                <linearGradient
                    id="f470idq"
                    x1="22.814"
                    x2="27.954"
                    y1="13.095"
                    y2="19.001"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8C8693" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C8693" />
                </linearGradient>
                <linearGradient
                    id="f470idr"
                    x1="24.636"
                    x2="21.701"
                    y1="6.434"
                    y2="14.313"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B5AEBE" />
                    <stop offset="1" stopColor="#B5AEBE" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f470ids"
                    x1="26.923"
                    x2="27.02"
                    y1="10.981"
                    y2="12.183"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B5AEBE" />
                    <stop offset="1" stopColor="#B5AEBE" stopOpacity="0" />
                </linearGradient>
            </defs>
        </g>
    </svg>
);
export default FaceMonocle;
