import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {DEFAULT_API_RETRY_COUNT, RESOURCE_SCOPES} from '../api.constants';
import {CheckTenantResponse} from '.';
import securityGraphApi from '@/api/securityGraph.api';

export default function useCheckTenant(
    useQueryOptions?: Pick<UseQueryOptions<CheckTenantResponse>, 'enabled'>,
) {
    const url = securityGraphApi({path: '/provisioning/checkTenant', isGlobal: true});

    const {customFetch} = useFetch();

    return useQuery<CheckTenantResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['checkTenant'],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                const error = {
                    code: response.status,
                    message: response.statusText,
                };

                throw error;
            }

            const data = await response.json();
            return data as CheckTenantResponse;
        },

        ...useQueryOptions,
    });
}
