import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Input,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';

import useClasses from './DuplicateSessionDialog.styles';
import {DismissIcon} from '@/components/ui/icons';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {DuplicateSessionProps} from './Session.types';
import useCreateDuplicateSession from '@/api/sessions/useCreateDuplicateSession';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';

import {useTranslation} from 'react-i18next';

export default function DuplicateSessionDialog({
    session,
    open,
    onClose = () => null,
    onSuccess = () => null,
    refetchSessionList = () => null,
}: DuplicateSessionProps) {
    const classes = useClasses();
    const {t} = useTranslation(['mysessions']);
    const {t: tSession} = useTranslation(['session']);
    const getLocaleSessionTitle = (sessionName: string) => {
        return tSession('DuplicateSessionTitle', {0: sessionName});
    };
    const [sessionTitle, setSessionTitle] = useState(getLocaleSessionTitle(session.name));
    const [duplicateSessionId, setDuplicateSessionId] = useState<string>('');
    const [disableDuplicate, setDisableDuplicate] = useState<boolean>(false);

    useEffect(() => {
        setSessionTitle(session.name);
    }, [session.name]);

    const {
        mutate: createDuplicateSession,
        isLoading: isLoadingSuggestions,
        isSuccess,
        isError,
    } = useCreateDuplicateSession({sessionId: session.sessionId});

    const handleDuplicateSession = () => {
        onSuccess?.();
        createDuplicateSession(
            {
                sessionName: sessionTitle,
            },
            {
                onSuccess: (duplicateSession) => {
                    duplicateSession && setDuplicateSessionId(duplicateSession.sessionId);
                    updateInvestigationTitle();
                    refetchSessionList();
                },
                onError: () => {
                    updateInvestigationTitle();
                },
            },
        );
    };

    const updateInvestigationTitle = () => {
        setSessionTitle(session.name);
    };
    const handleOnChange = (investigationTitle: string) => {
        setDisableDuplicate(investigationTitle === '');
        setSessionTitle(investigationTitle);
    };

    const handleDuplicateEnterClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !disableDuplicate) {
            handleDuplicateSession();
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onOpenChange={(event, data) => {
                    if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                        onClose?.();
                    }
                }}
            >
                <DialogSurface className={classes.root}>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={t('CloseButton')}
                                        icon={<DismissIcon />}
                                        onClick={() => {
                                            onClose?.();
                                            updateInvestigationTitle();
                                        }}
                                    />
                                </DialogTrigger>
                            }
                        >
                            {getLocaleSessionTitle(session.name)}
                        </DialogTitle>
                        <DialogContent className={classes.content}>
                            <Input
                                required
                                type="text"
                                defaultValue={sessionTitle}
                                onChange={(event) => {
                                    handleOnChange(event.target.value);
                                }}
                                onKeyDown={handleDuplicateEnterClick}
                            />
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    appearance="secondary"
                                    onClick={() => {
                                        onClose?.();
                                        updateInvestigationTitle();
                                    }}
                                    data-testid="duplicate-cancel-button"
                                >
                                    {t('CancelButton')}
                                </Button>
                            </DialogTrigger>
                            <Button
                                disabled={disableDuplicate}
                                appearance="primary"
                                onClick={() => {
                                    handleDuplicateSession();
                                }}
                                data-testid="duplicate-investigation-confirm-button"
                            >
                                {t('DuplicateButton')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {isLoadingSuggestions && (
                <ToastNotification
                    intent={Intent.Progress}
                    message={t('DuplicationInProgress')}
                    sessionId={session.sessionId}
                ></ToastNotification>
            )}
            {isSuccess && (
                <ToastNotification
                    intent={Intent.Success}
                    message={t('DuplicateSessionSuccess')}
                    sessionId={duplicateSessionId}
                ></ToastNotification>
            )}
            {isError && (
                <ToastNotification
                    intent={Intent.Error}
                    message={t('DuplicationFailed')}
                    sessionId={session.sessionId}
                ></ToastNotification>
            )}
        </div>
    );
}
