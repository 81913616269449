import {useEffect, useMemo, useState} from 'react';
import {PageNumberDisplayLayoutProps} from './Pagination.types';
import {SelectTabData, SelectTabEvent, Tab, TabList} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

export default function PageNumberDisplayLayout(props: PageNumberDisplayLayoutProps) {
    const {t} = useTranslation('common');

    const PAGE_DISPLAY_COUNT = 6;
    const displayPageCount = props.maxPageNumberDisplay ?? PAGE_DISPLAY_COUNT;

    const computedTabs = useMemo(() => {
        let length = 0;
        let startValue = 0;
        const startPage = props.activePage;

        // If the total page count is less than the viewable tab section, display all pages
        if (props.totalPageCount < displayPageCount) {
            length = props.totalPageCount;
            startValue = 1;
        } else {
            // If the total page count is greater than the viewable tab section,
            // display the entire viewable tab section
            length = displayPageCount;

            startValue =
                startPage + length > props.totalPageCount
                    ? props.totalPageCount - length + 1
                    : startPage;
        }

        return Array.from({length}, (_, index) => startValue + index).map((currentValue) => (
            <Tab
                tabIndex={0}
                key={currentValue}
                value={currentValue}
                role="tab"
                aria-label={t('Pagination.pageNumber', {
                    0: currentValue,
                })}
            >
                {currentValue}
            </Tab>
        ));
    }, [props.totalPageCount, props.activePage]);

    if (props.totalPageCount <= displayPageCount) {
        props.onMaxPageLimitEvaluated?.();
    } else {
        props.enableNextButton?.();
    }

    const [activePage, setActivePage] = useState<number>(props.activePage);

    useEffect(() => {
        setActivePage(props.activePage);
    }, [props.activePage]);

    return (
        <>
            <TabList
                data-testid="pagination-tab"
                selectedValue={activePage}
                selectTabOnFocus={true}
                onTabSelect={(event: SelectTabEvent, data: SelectTabData) => {
                    setActivePage(parseInt(String(data.value)));
                    props?.onPageChange(Number(data.value));
                }}
                disabled={props.disabled}
            >
                {computedTabs}
            </TabList>
        </>
    );
}
