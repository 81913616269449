import React from 'react';
import {
    Body1Strong,
    Button,
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuList,
    // MenuPopover,
    // MenuTrigger,
    mergeClasses,
    Tooltip,
} from '@fluentui/react-components';
import {AcceptIcon, NextIcon, BackIcon, SkillIcon, PromptbookIcon} from '@/components/ui/icons';
//import {FilterButton, FilterButtonRow} from '@/components/ui/Buttons/FilterButton';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import {ScreenSize} from '@/components/ui/Grid/GridConstants';
import {ExploreTab} from './ExploreCopilot.types';
import useClasses from './ExploreCopilot.styles';
import useFeaturedClasses from './FeaturedContentTabs.styles';
//import {FEATURED_PROMPTS, PROMPTBOOKS} from './NewHomeConstants';
import {useTranslation} from 'react-i18next';

interface FeaturedContentTabsProps {
    activeTab: ExploreTab;
    setActiveTab: (tab: ExploreTab) => void;
    disablePrev: boolean;
    setDisablePrev: (disable: boolean) => void;
    disableNext: boolean;
    setDisableNext: (disable: boolean) => void;
    setPromptClick: (click: number) => void;
    setPromptbookClick: (click: number) => void;
    promptClick: number;
    promptbookClick: number;
    setBtnClicked: (click: string) => void;
}

function FeaturedContentTabs(props: FeaturedContentTabsProps) {
    const {
        activeTab,
        disableNext,
        disablePrev,
        promptClick,
        promptbookClick,
        setActiveTab,
        setBtnClicked,
        setDisableNext,
        setDisablePrev,
        setPromptClick,
        setPromptbookClick,
    } = props;
    const classes = useClasses();
    const featuredClasses = useFeaturedClasses();
    const {t} = useTranslation('common');

    const previousLabel = `${
        activeTab === ExploreTab.Prompts ? 'prompt' : t('home.previousPromptbook')
    }`;
    const nextLabel = `${activeTab === ExploreTab.Prompts ? 'prompt' : t('home.nextPromptbook')}`;

    return (
        <>
            {/* <div className={classes.title} data-testid="explore-copilot-title">
                <Body1Strong>
                    <SkillIcon />
                    {t('home.getStartedSubtitle')}
                </Body1Strong>
            </div> */}
            <div className={classes.container}>
                {/* <span>
                    {isSmallOrMediumScreen ? (
                        <Menu hasIcons>
                            <MenuTrigger disableButtonEnhancement>
                                <MenuButton
                                    className={classes.checked}
                                    shape="circular"
                                    data-testid="explore-menu-button"
                                >
                                    {activeTab === ExploreTab.Prompts
                                        ? t('home.featuredPromptsTab')
                                        : t('home.featuredPromptbooksTab')}
                                </MenuButton>
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    <MenuItem
                                        style={{justifyContent: 'center'}}
                                        icon={
                                            activeTab === ExploreTab.Prompts ? (
                                                <AcceptIcon />
                                            ) : (
                                                <></> // need this to show space before text if selected icon is not shown.
                                            )
                                        }
                                        onClick={() => setActiveTab(ExploreTab.Prompts)}
                                        data-testid="featured-prompt-button"
                                    >
                                        {FEATURED_PROMPTS}
                                    </MenuItem>
                                    <MenuItem
                                        style={{justifyContent: 'center'}}
                                        icon={
                                            activeTab === ExploreTab.Promptbooks ? (
                                                <AcceptIcon />
                                            ) : (
                                                <></> // need this to show space before text if selected icon is not shown.
                                            )
                                        }
                                        onClick={() => setActiveTab(ExploreTab.Promptbooks)}
                                        data-testid="promptbooks-button"
                                    >
                                        {PROMPTBOOKS}
                                    </MenuItem>
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    ) : (
                        <FilterButtonRow>
                            <FilterButton
                                icon={<SkillIcon />}
                                onClick={() => setActiveTab(ExploreTab.Prompts)}
                                checked={activeTab === ExploreTab.Prompts}
                                data-testid="featured-prompts-button"
                            >
                                {FEATURED_PROMPTS}
                            </FilterButton>

                            <FilterButton
                                icon={<PromptbookIcon />}
                                onClick={() => setActiveTab(ExploreTab.Promptbooks)}
                                checked={activeTab === ExploreTab.Promptbooks}
                                data-testid="promptbooks-button"
                            >
                                {PROMPTBOOKS}
                            </FilterButton>
                        </FilterButtonRow>
                    )}
                </span> */}
                <span>
                    <Body1Strong as="h2" className={classes.title}>
                        {t('home.featuredPromptbooksTitle')}
                    </Body1Strong>
                </span>
                <span>
                    <Tooltip content={previousLabel} relationship="label">
                        <Button
                            icon={<BackIcon filled />}
                            disabled={disablePrev}
                            className={mergeClasses(
                                classes.carouselButton,
                                disablePrev ? classes.carouselButtonDisabled : '',
                            )}
                            aria-label={previousLabel}
                            onClick={() => {
                                setBtnClicked('prev');
                                activeTab === ExploreTab.Prompts
                                    ? setPromptClick(promptClick + 1)
                                    : setPromptbookClick(promptbookClick + 1);
                            }}
                        />
                    </Tooltip>
                    <Tooltip content={nextLabel} relationship="label">
                        <Button
                            icon={<NextIcon filled />}
                            disabled={disableNext}
                            className={mergeClasses(
                                classes.carouselButton,
                                disableNext ? classes.carouselButtonDisabled : '',
                            )}
                            aria-label={nextLabel}
                            onClick={() => {
                                setBtnClicked('next');
                                activeTab === ExploreTab.Prompts
                                    ? setPromptClick(promptClick + 1)
                                    : setPromptbookClick(promptbookClick + 1);
                            }}
                        />
                    </Tooltip>
                </span>
            </div>
        </>
    );
}

export default FeaturedContentTabs;
