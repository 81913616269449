import {useEffect, useState} from 'react';
import useClasses from './PromptbookList.styles';
import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridCellFocusMode,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    Link,
    Spinner,
    TableCellLayout,
    TableColumnDefinition,
    TableColumnId,
    TableRowId,
    Tooltip,
    Text,
    createTableColumn,
    TableColumnSizingOptions,
    Badge,
    mergeClasses,
    Body1Strong,
    TableCellActions,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
} from '@fluentui/react-components';
import {
    PromptInputs,
    PromptbookDescriptor,
    PromptbookTag,
    useGetAllPromptbooks,
    useGetPromptbookPermission,
} from '@/api/promptbooks';
import {AnimatePresence, motion} from 'framer-motion';
import {
    DeleteIcon,
    DuplicateIcon,
    EditIcon,
    PromptbookDetailsIcon,
    PromptbookListIcon,
    RunIcon,
    SaveIcon,
    MoreHorizontalIcon,
    ShareIcon,
    DataGridSortIcon,
    ExternalLinkIcon,
} from '@/components/ui/icons';
import MedeinaFeatures from '@/util/features';
import {useNavigate, useParams} from 'react-router-dom';
import {PromptbookVisibility} from '@/api/promptbooks';
import PromptbookLibraryForm from '@/components/ui/PromptBar/PromptbookLibraryForm';
import {useMsalUserId} from '@/util/msal';
import PromptbookFilters from './PromptbookFilters';
import PromptbookCreateForm from './PromptbookCreateForm';
import {PromptbookOperationMode} from './Promptbook.types';
import DeletePromptbookDialog from './DeletePromptbookDialog';
import {PromptbookFilterSelectedChip} from './PromptbookFilters.types';
import PromptbookTags from './PromptbookTags';
import PromptbookPlugins from './PromptbookPlugins';
import {useGetSkillsets} from '@/api/skills';
import {useFeatureFlag} from '@/api/user';
import PromptbookAdvancedFilter from './PromptbookLibraryFilters/PromptbookAdvancedFilters';
import {
    PromptbookAvailableFilterValue,
    PromptbookFilterValueType,
} from './PromptbookLibraryFilters/PromptbookAdvancedFilters.type';
import useGetPromptbookLink from '@/api/promptbooks/useGetPromptbookLink';
import LinkCopiedDialog from './LinkCopiedDialog';
import PromptbookLibrarySharedForm from '@/components/ui/PromptBar/PromptbookLibrarySharedForm';
import ShareNoAccessDialog from './ShareNoAccessDialog';
import {useTranslation} from 'react-i18next';
import useGetAllPromptbooksTags from '@/api/promptbooks/useGetAllPromptbooksTags';
import MedeinaVariables from '@/util/variables';
import Highlighter from 'react-highlight-words';

// Set focus mode for the cell, for complex cells that contain multiple focusable elements switching to
// group allows the user to press enter and navigate within the cell
const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
    switch (columnId) {
        case 'name':
        case 'inputs':
        case 'tags':
        case 'plugins':
        case 'run':
            // the name column contains a link and an options button, use group focus
            return 'group';
        default:
            return 'cell';
    }
};

// PromptbookList component is used to display the promptbook library grid along the selected filters.
// It also handles the promptbook operations like run, view, edit, delete, duplicate and share.
// It also handles the promptbook advanced filter options. When advanced filter is expanded, it displays the advanced filter options.
// It also handles the promptbook shared promptbook view and share operations.
export default function PromptbookList() {
    const classes = useClasses();
    const {promptbookId: propsPromptbookId} = useParams(); // fetch if any promptbookId in the url
    const [openPromptbookViewDialog, setOpenPromptbookViewDialog] = useState<boolean>(false);
    const [openPromptbookViewShareDialog, setOpenPromptbookViewShareDialog] =
        useState<boolean>(false);
    const [promptbookId, setPromptbookId] = useState<string>('');
    const [selectedPromptbook, setSelectedPromptbook] = useState<PromptbookDescriptor>();
    const userObjectId = useMsalUserId();
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [filterString, setFilterString] = useState<string>('');
    const [showLinkToast, setShowLinkToast] = useState<boolean>(false);
    const [showNoAccessDialog, setShowNoAccessDialog] = useState<boolean>(false);
    const [filterState, setFilterState] = useState({
        filter: '',
    });

    const [selectedChip, setSelectedChip] = useState<PromptbookFilterSelectedChip>(
        PromptbookFilterSelectedChip.All,
    );

    const [
        promptbookFilterAdvancedOptionsIsExpanded,
        setpromptbookFilterAdvancedOptionsIsExpanded,
    ] = useState<boolean>(false);
    const {t} = useTranslation('promptbooks');
    const {t: tCommon} = useTranslation('common');
    const {
        data: promptbooks,
        isLoading: promptbooksLoading,
        isError: getAllPromptbooksFailed,
    } = useGetAllPromptbooks({
        filter: ((filterState.filter as string) = filterString),
        search: searchText,
    });

    // get the promptbook permission details of the shared promptbook
    const {
        data: sharedPromptbook,
        isLoading: sharedPromptbookLoading,
        isError: sharedGetPromptbookFailed,
        error: sharedPromptbookError,
    } = useGetPromptbookPermission({promptbookId: propsPromptbookId});

    const {
        mutate: getPromptbookLink,
        isError: getPromptbookLinkError,
        reset,
        error,
        isLoading: getPromptbookLinkLoading,
    } = useGetPromptbookLink();

    const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>(undefined));
    const {data: availablePromptbookTags} = useGetAllPromptbooksTags();

    /*
    The below line creates the list of PromptbookAvailableFilterValues for every distinct tag available in the promptbooks. 
    */
    const [promptbookAvailableFilterValues, setpromptbookAvailableFilterValues] = useState<
        PromptbookAvailableFilterValue[]
    >(
        availablePromptbookTags.map((tag) => {
            return {
                FilterValueType: PromptbookFilterValueType.Tag,
                FilterValueId: tag,
                FilterValueName: tag,
                IsSelected: false,
            };
        }) as PromptbookAvailableFilterValue[],
    );

    /*
    This method reads all the prompt books 
    when the list of prompt books changes(by adding , updating, delete) operations
    and updates the list of PromptbookAvailableFilterValues with any new additional tags that have been added.
    */
    useEffect(() => {
        let promptbookAvailableFilterValuesLocal = promptbookAvailableFilterValues;
        availablePromptbookTags.forEach((promptbookTag) => {
            if (
                promptbookTag != undefined &&
                promptbookAvailableFilterValues.findIndex(
                    (f) => f.FilterValueId === promptbookTag,
                ) <= -1
            ) {
                promptbookAvailableFilterValuesLocal = [
                    ...promptbookAvailableFilterValuesLocal,
                    {
                        FilterValueType: PromptbookFilterValueType.Tag,
                        FilterValueId: promptbookTag,
                        FilterValueName: promptbookTag,
                        IsSelected: false,
                    },
                ];
            }
        });
        setpromptbookAvailableFilterValues(promptbookAvailableFilterValuesLocal);
    }, [availablePromptbookTags]);

    const onSelectedFilterValuesChange = (
        selectedFilterValues: PromptbookAvailableFilterValue[],
    ) => {
        setpromptbookAvailableFilterValues(selectedFilterValues);
        setFilterQuery();
    };

    const {data: skillsets} = useGetSkillsets();

    const navigate = useNavigate();
    useEffect(() => {
        setFilterQuery();
    }, [selectedChip, promptbookAvailableFilterValues]);

    useEffect(() => {
        if (sharedPromptbook) setSelectedPromptbook(sharedPromptbook);
    }, [sharedPromptbook]);

    const handlePromptbookOperationClicked = (promptbookId: string) => {
        setPromptbookId(promptbookId);
        const selectedPbook: PromptbookDescriptor | undefined = promptbooks?.value.find(
            (pb) => pb.promptbookId === promptbookId,
        );
        setSelectedPromptbook(selectedPbook);
    };

    useEffect(() => {
        if (propsPromptbookId) {
            if (
                sharedPromptbookLoading ||
                (sharedGetPromptbookFailed && !sharedPromptbookLoading)
            ) {
                // render unauthorized modal
                setShowNoAccessDialog(true);
            } else if (sharedPromptbook?.visibility === PromptbookVisibility.Private) {
                setShowNoAccessDialog(false);
                if (sharedPromptbook?.userId !== userObjectId) {
                    // if shared by other user
                    setOpenPromptbookViewShareDialog(true);
                } // own private promptbook
                else {
                    setOpenPromptbookViewDialog(true);
                }
            } else if (
                sharedPromptbook?.visibility === PromptbookVisibility.Global ||
                sharedPromptbook?.visibility === PromptbookVisibility.Tenant
            ) {
                setShowNoAccessDialog(false);
                setOpenPromptbookViewDialog(true);
            }
        }
    }, [propsPromptbookId, sharedPromptbook, sharedGetPromptbookFailed, sharedPromptbookLoading]);

    const onSelectionChange: DataGridProps['onSelectionChange'] = (e: any, data: any) => {
        setSelectedRows(data.selectedItems);
    };

    const handleRunClicked = (promptbookId: string) => {
        navigate(`/sessions/new/${promptbookId}`);
    };

    const handleSelectedChip = (selectedChip: PromptbookFilterSelectedChip) => {
        setSelectedChip(selectedChip);
    };

    const handleSearchTextChange = (textFilter: string) => {
        setSearchText(textFilter);
    };

    const handlePromptbookAdvancedFilterVisibiltyChange = (expanded: boolean) => {
        setpromptbookFilterAdvancedOptionsIsExpanded(expanded);
    };

    const handleShareClicked = (promptbook: PromptbookDescriptor) => {
        handlePromptbookOperationClicked(promptbook.promptbookId);
        getPromptbookLink(promptbook, {
            onSuccess: () => {
                setShowLinkToast(true);
            },
        });
    };

    const returnToHomeUrl = () => {
        navigate(`/promptbooks`);
    };

    const setFilterQuery = () => {
        var filterstringlocal: string = '';
        if (selectedChip == PromptbookFilterSelectedChip.MyPromptbooks) {
            filterstringlocal = "(cast(visibility, Edm.String) eq 'Private')";
        } else if (selectedChip == PromptbookFilterSelectedChip.TenantPromptbooks) {
            filterstringlocal = "(cast(visibility, Edm.String) eq 'Tenant')";
        } else if (selectedChip == PromptbookFilterSelectedChip.GlobalPromptbooks) {
            filterstringlocal = "(cast(visibility, Edm.String) eq 'Global')";
        } else {
            filterstringlocal = '';
        }

        /* This logic is creating a odata filter string for the selected tags . */
        var tagsFilterString: string = '';
        if (
            promptbookAvailableFilterValues !== null &&
            promptbookAvailableFilterValues !== undefined
        ) {
            tagsFilterString = promptbookAvailableFilterValues
                .filter(
                    (f) =>
                        f.IsSelected === true &&
                        f.FilterValueType === PromptbookFilterValueType.Tag,
                )
                .map((f) => `c/tag eq '${f.FilterValueId}'`)
                .join(' or ');

            if (tagsFilterString !== '') {
                filterstringlocal =
                    filterstringlocal !== ''
                        ? filterstringlocal + ` and tags/any(c: ${tagsFilterString})`
                        : `tags/any(c: ${tagsFilterString})`;
            }
        }
        setFilterString(filterstringlocal);
    };

    const isSelfAuthored = (promptbook: PromptbookDescriptor): boolean => {
        return (
            userObjectId === promptbook?.userId &&
            promptbook.visibility !== PromptbookVisibility.Global
        );
    };

    const isShareable = (promptbook: PromptbookDescriptor): boolean => {
        return (
            userObjectId === promptbook?.userId ||
            promptbook.visibility === PromptbookVisibility.Global ||
            promptbook.visibility === PromptbookVisibility.Tenant
        );
    };

    const columnSizingOptions: TableColumnSizingOptions = {
        // run: {idealWidth: 150, minWidth: 100},
        name: {idealWidth: 400},
        tags: {idealWidth: 250},
        plugins: {idealWidth: 300},
        inputs: {idealWidth: 200, minWidth: 150},
        promptsCount: {idealWidth: 100, minWidth: 50},
        // access: {idealWidth: 250, minWidth: 150},
    };

    const columns: TableColumnDefinition<PromptbookDescriptor>[] = [
        createTableColumn<PromptbookDescriptor>({
            columnId: 'name',
            renderHeaderCell: () => {
                return (
                    <DataGridHeaderCell sortIcon={<DataGridSortIcon />}>
                        {tCommon('TableHeaders.Name')}
                    </DataGridHeaderCell>
                );
            },
            renderCell: (promptbook) => {
                return (
                    <>
                        <TableCellLayout className={classes.overflowNameContent}>
                            <Tooltip content={promptbook.name} relationship="label">
                                <Link
                                    className={classes.overflowNameContent}
                                    data-testid="promptbook-link"
                                    onClick={() => {
                                        handlePromptbookOperationClicked(promptbook.promptbookId);
                                        setOpenPromptbookViewDialog(true);
                                    }}
                                >
                                    <Highlighter
                                        highlightClassName={classes.highlight}
                                        searchWords={[searchText]}
                                        textToHighlight={promptbook.name}
                                    />
                                </Link>
                            </Tooltip>
                            <Tooltip content={promptbook.description} relationship="label">
                                <p className={classes.description}>
                                    <Highlighter
                                        highlightClassName={classes.highlight}
                                        searchWords={[searchText]}
                                        textToHighlight={promptbook.description}
                                    />
                                </p>
                            </Tooltip>
                        </TableCellLayout>
                        <TableCellActions
                            onClick={(e: {
                                preventDefault: () => void;
                                stopPropagation: () => void;
                            }) => {
                                // We do not want row selection when the table-cell-action button is pressed.
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            aria-haspopup="true"
                        >
                            <Tooltip relationship="label" content={t('tooltips.startNewSession')}>
                                <Button
                                    data-testid="run-button"
                                    className={classes.runButton}
                                    icon={<RunIcon />}
                                    onClick={() => {
                                        handleRunClicked(promptbook.promptbookId);
                                    }}
                                    appearance="secondary"
                                ></Button>
                            </Tooltip>
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button
                                        data-testid="options-button"
                                        icon={<MoreHorizontalIcon />}
                                        appearance="subtle"
                                        aria-label={t('PromptbookOptions')}
                                    />
                                </MenuTrigger>

                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem
                                            data-testid="view-button"
                                            icon={<PromptbookDetailsIcon />}
                                            onClick={() => {
                                                handlePromptbookOperationClicked(
                                                    promptbook.promptbookId,
                                                );
                                                setOpenPromptbookViewDialog(true);
                                            }}
                                        >
                                            {tCommon('ButtonLabels.Details')}
                                        </MenuItem>
                                        <MenuItem
                                            data-testid="duplicate-button"
                                            icon={<DuplicateIcon />}
                                            onClick={() => {
                                                handlePromptbookOperationClicked(
                                                    promptbook.promptbookId,
                                                );
                                                setOpenDuplicateDialog(true);
                                            }}
                                        >
                                            {tCommon('ButtonLabels.Duplicate')}
                                        </MenuItem>
                                        {isShareable(promptbook) && (
                                            <>
                                                <MenuItem
                                                    data-testid="share-button"
                                                    icon={<ShareIcon />}
                                                    onClick={() => handleShareClicked(promptbook)}
                                                >
                                                    {t('SharePromptbookButton')}
                                                </MenuItem>
                                            </>
                                        )}
                                        {isSelfAuthored(promptbook) && (
                                            <>
                                                <MenuItem
                                                    data-testid="edit-button"
                                                    icon={<EditIcon />}
                                                    onClick={() => {
                                                        handlePromptbookOperationClicked(
                                                            promptbook.promptbookId,
                                                        );
                                                        setOpenEditDialog(true);
                                                    }}
                                                >
                                                    {tCommon('ButtonLabels.Edit')}
                                                </MenuItem>
                                                <MenuItem
                                                    data-testid="delete-button"
                                                    icon={<DeleteIcon />}
                                                    onClick={() => {
                                                        handlePromptbookOperationClicked(
                                                            promptbook.promptbookId,
                                                        );
                                                        setOpenDeleteDialog(true);
                                                    }}
                                                >
                                                    {tCommon('ButtonLabels.Delete')}
                                                </MenuItem>
                                            </>
                                        )}
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </TableCellActions>
                    </>
                );
            },
        }),
        createTableColumn<PromptbookDescriptor>({
            columnId: 'inputs',
            renderHeaderCell: () => tCommon('TableHeaders.Inputs'),
            renderCell: (promptbook) => {
                return (
                    <TableCellLayout>
                        <div className={classes.inputsContainer}>
                            {promptbook.promptbookinputs &&
                                promptbook.promptbookinputs.map((promptbookInput, i) => {
                                    return (
                                        <div key={i} className={classes.input}>
                                            <Badge
                                                shape="rounded"
                                                key={i}
                                                appearance="outline"
                                                color="informative"
                                            >
                                                {promptbookInput.name}
                                            </Badge>
                                        </div>
                                    );
                                })}
                        </div>
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<PromptbookDescriptor>({
            columnId: 'plugins',
            renderHeaderCell: () => tCommon('TableHeaders.Plugins'),
            renderCell: (promptbook) => (
                <TableCellLayout>
                    <div className={classes.overflowContent}>
                        <PromptbookPlugins
                            shouldOverflow={true}
                            // Construct list of objects containing plugin name, icon and display name
                            // from returned skillsets.
                            plugins={promptbook.prompts.reduce((acc, prompt) => {
                                prompt.plugins?.forEach((plugin) => {
                                    const matchedSkillset = skillsets?.value.find(
                                        (skillset) => skillset.name === plugin,
                                    );
                                    if (matchedSkillset) {
                                        acc.push({
                                            skillset: matchedSkillset.name,
                                            icon: matchedSkillset.icon || '',
                                            displayName: matchedSkillset.displayName || '',
                                        });
                                    }
                                });
                                return acc;
                            }, [] as {skillset: string; icon: string; displayName: string}[])}
                        />
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn<PromptbookDescriptor>({
            columnId: 'tags',
            renderHeaderCell: () => tCommon('TableHeaders.Tags'),
            renderCell: (promptbook) => (
                <TableCellLayout>
                    {/* PromptbookTags from grid should never be editable. */}
                    <PromptbookTags edit={false} formData={promptbook.tags ?? []} />
                </TableCellLayout>
            ),
        }),
        createTableColumn<PromptbookDescriptor>({
            columnId: 'promptsCount',
            renderHeaderCell: () => tCommon('TableHeaders.Prompts'),
            renderCell: (promptbook) => (
                <TableCellLayout>
                    <div className={classes.promptCount}>
                        <span>
                            {promptbook.prompts.length ?? '0'}&nbsp;
                            <PromptbookListIcon />
                        </span>
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn<PromptbookDescriptor>({
            columnId: 'access',
            renderHeaderCell: () => tCommon('TableHeaders.Owner'),
            renderCell: (promptbook) => (
                <TableCellLayout>
                    <div className={classes.overflowContent}>
                        {promptbook.visibility == PromptbookVisibility.Global
                            ? 'Microsoft'
                            : promptbook.ownerName}
                    </div>
                </TableCellLayout>
            ),
        }),
    ];

    return (
        <div className={classes.root} data-testid="promptbook-list-page">
            <div className={classes.title}>{t('PromptbookLibrary')}</div>
            <Body1Strong className={classes.subtitle}>
                {t('PromptbookLibrarySubtitle')}
                <Link href={MedeinaVariables.LearnMorePromptbooksUrl} target="_blank">
                    &nbsp;{t('LearnMore')}
                    <ExternalLinkIcon />
                </Link>
            </Body1Strong>
            <>
                {showLinkToast && (
                    <LinkCopiedDialog
                        open={showLinkToast}
                        onClose={() => setShowLinkToast(false)}
                    ></LinkCopiedDialog>
                )}
                {showNoAccessDialog && (
                    <ShareNoAccessDialog
                        errorStatus={(sharedPromptbookError as any)?.response?.status}
                        isLoading={sharedPromptbookLoading}
                        open={showNoAccessDialog}
                        onClose={() => {
                            setShowNoAccessDialog(false);
                            returnToHomeUrl();
                        }}
                    ></ShareNoAccessDialog>
                )}
                <div className={classes.stickyActions}>
                    {/* This component displays the search, filter chips, advanced filters the user selected.
                    selectedChipIcon event is raised when their is a change is selected chip.
                    shouldShowAdvancedFilter event is raised when user clicked on the add advanced filters button. */}
                    <PromptbookFilters
                        selectedChipIcon={handleSelectedChip}
                        handleSearchTextChange={handleSearchTextChange}
                        onSelectedAdvancedFiltersChange={onSelectedFilterValuesChange}
                        availableAdvancedFilterValues={promptbookAvailableFilterValues}
                        onAdvancedFilterVisibilityChange={
                            handlePromptbookAdvancedFilterVisibiltyChange
                        }
                        shouldShowAdvancedFilter={promptbookFilterAdvancedOptionsIsExpanded}
                    />
                </div>
                <AnimatePresence mode="wait">
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0, duration: 0.3}}
                        exit={{opacity: 0, transition: {duration: 0}}}
                        key="FilteringPromptbooks"
                    >
                        {promptbooksLoading &&
                            ((filterState.filter as string) !== '' || searchText !== '') && (
                                <div className={classes.filteringInvestigations}>
                                    <Spinner
                                        labelPosition="below"
                                        label={t('FilteringPromptbooks')}
                                    />
                                </div>
                            )}
                        {promptbooksLoading &&
                            (filterState.filter as string) === '' &&
                            searchText === '' && (
                                <div className={classes.filteringInvestigations}>
                                    <Spinner
                                        labelPosition="below"
                                        label={t('LoadingPromptbooks')}
                                    />
                                </div>
                            )}
                    </motion.div>
                    {promptbookFilterAdvancedOptionsIsExpanded !== true &&
                        !promptbooksLoading &&
                        promptbooks?.value?.length == 0 && (
                            <p className={classes.filterDialog}>{t('NoPromptbooksFound')}</p>
                        )}
                    {promptbookFilterAdvancedOptionsIsExpanded !== true &&
                        !promptbooksLoading &&
                        promptbooks &&
                        promptbooks.value?.length > 0 && (
                            <div>
                                <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    transition={{delay: 0, duration: 0.3}}
                                    exit={{opacity: 0, transition: {duration: 0}}}
                                    key="FilterPage"
                                >
                                    {selectedPromptbook && openPromptbookViewDialog && (
                                        <PromptbookLibraryForm
                                            promptbook={selectedPromptbook!}
                                            promptbookInputs={selectedPromptbook?.promptbookinputs.reduce(
                                                (acc, input) => {
                                                    const {name, description} = input;
                                                    acc[name] = description;
                                                    return acc;
                                                },
                                                {} as PromptInputs,
                                            )}
                                            onCancel={() => {
                                                setOpenPromptbookViewDialog(false);
                                                returnToHomeUrl();
                                            }}
                                            onSubmit={() => {
                                                setOpenPromptbookViewDialog(false);
                                                returnToHomeUrl();
                                            }}
                                            open={openPromptbookViewDialog}
                                            key={'PromptbookLibraryForm'}
                                        />
                                    )}
                                    {selectedPromptbook && openPromptbookViewShareDialog && (
                                        <PromptbookLibrarySharedForm
                                            promptbook={selectedPromptbook!}
                                            promptbookInputs={selectedPromptbook?.promptbookinputs.reduce(
                                                (acc, input) => {
                                                    const {name, description} = input;
                                                    acc[name] = description;
                                                    return acc;
                                                },
                                                {} as PromptInputs,
                                            )}
                                            onCancel={() => {
                                                setOpenPromptbookViewShareDialog(false);
                                                returnToHomeUrl();
                                            }}
                                            onSubmit={() => {
                                                setOpenPromptbookViewShareDialog(false);
                                                returnToHomeUrl();
                                            }}
                                            open={openPromptbookViewShareDialog}
                                            key={'PromptbookLibrarySharedForm'}
                                        />
                                    )}
                                    {selectedPromptbook && openDeleteDialog && (
                                        <div>
                                            <DeletePromptbookDialog
                                                {...{
                                                    promptbook: selectedPromptbook,
                                                    promptbookId: selectedPromptbook?.promptbookId,
                                                    promptbookIds: [],
                                                }}
                                                open={openDeleteDialog}
                                                onClose={() => {
                                                    setOpenDeleteDialog(false);
                                                }}
                                                onSuccess={() => {
                                                    setOpenDeleteDialog(false);
                                                }}
                                            />
                                        </div>
                                    )}
                                    {selectedPromptbook && openDuplicateDialog && (
                                        <div>
                                            <PromptbookCreateForm
                                                promptbook={selectedPromptbook}
                                                mode={PromptbookOperationMode.Duplicate}
                                                open={openDuplicateDialog}
                                                onClose={() => {
                                                    setOpenDuplicateDialog(false);
                                                }}
                                                onSuccess={() => {
                                                    setOpenDuplicateDialog(false);
                                                }}
                                                sessionId={''}
                                                promptIds={[]}
                                                promptbookId={''}
                                            />
                                        </div>
                                    )}
                                    {selectedPromptbook && openEditDialog && (
                                        <div>
                                            <PromptbookCreateForm
                                                promptbook={selectedPromptbook}
                                                mode={PromptbookOperationMode.Edit}
                                                open={openEditDialog}
                                                onClose={() => {
                                                    setOpenEditDialog(false);
                                                }}
                                                onSuccess={() => {
                                                    setOpenEditDialog(false);
                                                }}
                                                sessionId={''}
                                                promptIds={[]}
                                                promptbookId={''}
                                                key={'PromptbookEditDialog'}
                                            />
                                        </div>
                                    )}
                                    <DataGrid
                                        items={promptbooks.value}
                                        columns={columns}
                                        columnSizingOptions={columnSizingOptions}
                                        resizableColumns
                                        selectionAppearance="neutral"
                                        onSelectionChange={onSelectionChange}
                                        selectedItems={selectedRows}
                                        getRowId={(promptbook: {promptbookId: any}) =>
                                            promptbook.promptbookId
                                        }
                                        className={classes.gridCell}
                                        data-testid="promptbook-library-grid"
                                    >
                                        <DataGridHeader>
                                            <DataGridRow>
                                                {({renderHeaderCell}: any) => (
                                                    <DataGridHeaderCell
                                                        className={mergeClasses(
                                                            classes.headerCell,
                                                            classes.row,
                                                        )}
                                                    >
                                                        {renderHeaderCell()}
                                                    </DataGridHeaderCell>
                                                )}
                                            </DataGridRow>
                                        </DataGridHeader>
                                        <DataGridBody<PromptbookDescriptor>>
                                            {({item, rowId}: any) => (
                                                <DataGridRow<PromptbookDescriptor> key={rowId}>
                                                    {({renderCell, columnId}: any) => (
                                                        <DataGridCell
                                                            className={classes.row}
                                                            focusMode={getCellFocusMode(columnId)}
                                                        >
                                                            {renderCell(item)}
                                                        </DataGridCell>
                                                    )}
                                                </DataGridRow>
                                            )}
                                        </DataGridBody>
                                    </DataGrid>
                                </motion.div>
                            </div>
                        )}
                    {promptbookFilterAdvancedOptionsIsExpanded === true && !promptbooksLoading && (
                        /* This component displays the tags and plugins filters component. 
                                onSelectedAdvancedFiltersChange is an event that is raised by this component when any user 
                                selected filters are changed by clicking on the apply button.
                                onAdvancedFilterVisibilityChange is an event that is raised by this component to change 
                                the visibility of the component when apply button is clicked.   */
                        <PromptbookAdvancedFilter
                            availableFilterValues={promptbookAvailableFilterValues}
                            onSelectedAdvancedFiltersChange={onSelectedFilterValuesChange}
                            onAdvancedFilterVisibilityChange={
                                handlePromptbookAdvancedFilterVisibiltyChange
                            }
                        />
                    )}
                </AnimatePresence>
            </>
        </div>
    );
}
