import {ClearSessionSummaryRequest, Session, SessionSummaryResponse} from './sessions.types';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';

export default function useClearSessionSummary() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: ClearSessionSummaryRequest) => {
            if (body === undefined || body.sessionId === undefined || body.sessionId === '') {
                // This condition should not happen.
                return false;
            }

            const sessionData: Session = await customFetch<Session>(`/sessions/${body.sessionId}`, {
                method: 'GET',
            });
            const sessionSummary: SessionSummaryResponse = {
                summary: null,
                title: null,
                tags: null,
            };
            const updatedSession: Session = {
                ...sessionData,
                sessionSummary: sessionSummary || null,
            };

            // Perform the API call to update the session
            return customFetch<Session>(`/sessions/${updatedSession.sessionId}`, {
                method: 'PUT',
                body: updatedSession,
            });
        },
        onSettled: (success, error, {sessionId}) => {
            // Refetch the parent session.
            if (!error) {
                queryClient.refetchQueries({
                    queryKey: ['sessiongetsessionsummary', sessionId],
                });
            }
        },
    });
}
