/**
 *  A set of utility functions for reading and writing to session storage
 *
 */

/**
 * Reads a value from sessionStorage and merges it with a default value.
 *
 * @template T - The type of the value to be read.
 * @param {string} namespace - The key under which the value is stored in sessionStorage.
 * @param {Partial<T>} [defaultValue] - An optional default value to merge with the stored value.
 * @returns {T} - The merged value from sessionStorage and the default value.
 */
export function readFromSessionStorage<T>(namespace: string, defaultValue?: Partial<T>): T {
    if (!namespace) {
        throw new Error('Namespace is required');
    }
    const currentValue = typeof window !== 'undefined' && window.sessionStorage.getItem(namespace);

    const DEFAULT_VALUE = defaultValue || {};
    return currentValue
        ? Object.assign({}, DEFAULT_VALUE, JSON.parse(currentValue))
        : Object.assign({}, DEFAULT_VALUE);
}

/**
 * Writes a partial value to the session storage under the specified namespace.
 *
 * @template T - The type of the value to be stored.
 * @param {string} namespace - The key under which the value will be stored in session storage.
 * @param {Partial<T>} [value] - The partial value to be stored. If not provided, `undefined` will be stored.
 * @returns {void}
 */
export function writeToSessionStorage<T>(namespace: string, value: Partial<T>): void {
    if (!namespace) {
        throw new Error('Namespace is required');
    }
    if (!value) {
        throw new Error('Value is required');
    }
    window.sessionStorage.setItem(namespace, JSON.stringify(value));
}

/**
 * Deletes an item from the session storage.
 *
 * @template T - The type of the item to be deleted.
 * @param namespace - The key of the item to be removed from session storage.
 * @returns {void}
 */
export function deleteFromSessionStorage<T>(namespace: string): void {
    if (!namespace) {
        throw new Error('Namespace is required');
    }
    window.sessionStorage.removeItem(namespace);
}
