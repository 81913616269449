import React from 'react';

export interface ConnectorModalContextType {
    expandedCategories: Set<string>;
    setExpandedCategories: (categories: Set<string>) => void;
    pluginContentScrollTop: number;
    setPluginContentScrollTop: (scrollTop: number) => void;
    resetConnectorContext: () => void;
}

export const InitialConnectorContext: ConnectorModalContextType = {
    expandedCategories: new Set<string>(),
    setExpandedCategories: () => {},
    pluginContentScrollTop: 0,
    setPluginContentScrollTop: () => {},
    resetConnectorContext: () => {},
};

const ConnectorModalContext =
    React.createContext<ConnectorModalContextType>(InitialConnectorContext);

export default ConnectorModalContext;
