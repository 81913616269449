import {useState} from 'react';
import {BackgroundImage, BackgroundAnimation} from '@/components/ui/Background';
import {Background} from './Layout.types';

export function useBackgroundState(): Background {
    const [image, setImage] = useState<BackgroundImage>(BackgroundImage.none);
    const [animation, setAnimation] = useState<BackgroundAnimation>(BackgroundAnimation.none);

    return {
        image,
        animation,
        setImage,
        setAnimation,
    };
}
