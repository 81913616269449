import * as React from 'react';
const HundredPoints = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path
                fill="url(#f718id0)"
                d="M4.949 23.844c1.048-.161 1.757-1.438 1.914-2.25c.539-3.657 1.258-8.399 1.601-11.625c.09-.84-.305-1.54-.734-1.867c-.852-.632-1.719-.29-2.469.437c-.822.787-1.43 1.524-2.758 3.133c-.478.58-.81 1.906 0 2.523c.571.418 1.383.125 1.883-.226c-.383 2.71-.906 6.312-1.086 8.383c.002 1.059.531 1.664 1.649 1.492Z"
            />
            <path
                fill="url(#f718id1)"
                d="M4.949 23.844c1.048-.161 1.757-1.438 1.914-2.25c.539-3.657 1.258-8.399 1.601-11.625c.09-.84-.305-1.54-.734-1.867c-.852-.632-1.719-.29-2.469.437c-.822.787-1.43 1.524-2.758 3.133c-.478.58-.81 1.906 0 2.523c.571.418 1.383.125 1.883-.226c-.383 2.71-.906 6.312-1.086 8.383c.002 1.059.531 1.664 1.649 1.492Z"
            />
            <g filter="url(#f718idk)">
                <path
                    fill="url(#f718id2)"
                    d="m6.738 11.813l-2.078 2l-1 8.109l1.859.125l1.219-10.235Z"
                />
            </g>
            <path
                fill="url(#f718id3)"
                d="M5.408 23.696a1.565 1.565 0 0 1-.46.148c-1.117.172-1.646-.433-1.648-1.492c.162-1.86.6-4.954.965-7.53l.113-.792l.032-.046l2.625-1.75l-1.627 11.462Z"
            />
            <path
                fill="url(#f718id4)"
                d="M9.042 15.383c-.109 1.203-.224 2.762.711 3.828c.86.97 1.68 1.18 3.235 1.086c1.523-.281 2.828-1.266 3.437-1.875c.89-.89 1.743-2.333 1.985-3.82c.181-.983.278-2.123.445-3.118c.305-1.812.164-3.18-.563-4.172c-.53-.828-1.646-1.341-2.914-1.265c-1.317.079-2.909.834-4.03 2.148a8.15 8.15 0 0 0-1.89 4.087c-.119.913-.283 2.109-.416 3.1Zm3.61-.086c.18-1.438.32-2.594.531-4.086c.141-.82.883-1.29 1.328-1.29c.639-.037.91.452.813 1.29c-.157 1.336-.304 2.617-.524 3.89c-.141.82-.71 1.335-1.35 1.373c-.532.032-.853-.333-.798-1.177Z"
            />
            <path
                fill="url(#f718idu)"
                d="M9.042 15.383c-.109 1.203-.224 2.762.711 3.828c.86.97 1.68 1.18 3.235 1.086c1.523-.281 2.828-1.266 3.437-1.875c.89-.89 1.743-2.333 1.985-3.82c.181-.983.278-2.123.445-3.118c.305-1.812.164-3.18-.563-4.172c-.53-.828-1.646-1.341-2.914-1.265c-1.317.079-2.909.834-4.03 2.148a8.15 8.15 0 0 0-1.89 4.087c-.119.913-.283 2.109-.416 3.1Zm3.61-.086c.18-1.438.32-2.594.531-4.086c.141-.82.883-1.29 1.328-1.29c.639-.037.91.452.813 1.29c-.157 1.336-.304 2.617-.524 3.89c-.141.82-.71 1.335-1.35 1.373c-.532.032-.853-.333-.798-1.177Z"
            />
            <path
                fill="url(#f718id5)"
                d="M9.042 15.383c-.109 1.203-.224 2.762.711 3.828c.86.97 1.68 1.18 3.235 1.086c1.523-.281 2.828-1.266 3.437-1.875c.89-.89 1.743-2.333 1.985-3.82c.181-.983.278-2.123.445-3.118c.305-1.812.164-3.18-.563-4.172c-.53-.828-1.646-1.341-2.914-1.265c-1.317.079-2.909.834-4.03 2.148a8.15 8.15 0 0 0-1.89 4.087c-.119.913-.283 2.109-.416 3.1Zm3.61-.086c.18-1.438.32-2.594.531-4.086c.141-.82.883-1.29 1.328-1.29c.639-.037.91.452.813 1.29c-.157 1.336-.304 2.617-.524 3.89c-.141.82-.71 1.335-1.35 1.373c-.532.032-.853-.333-.798-1.177Z"
            />
            <path
                fill="url(#f718id6)"
                d="M9.042 15.383c-.109 1.203-.224 2.762.711 3.828c.86.97 1.68 1.18 3.235 1.086c1.523-.281 2.828-1.266 3.437-1.875c.89-.89 1.743-2.333 1.985-3.82c.181-.983.278-2.123.445-3.118c.305-1.812.164-3.18-.563-4.172c-.53-.828-1.646-1.341-2.914-1.265c-1.317.079-2.909.834-4.03 2.148a8.15 8.15 0 0 0-1.89 4.087c-.119.913-.283 2.109-.416 3.1Zm3.61-.086c.18-1.438.32-2.594.531-4.086c.141-.82.883-1.29 1.328-1.29c.639-.037.91.452.813 1.29c-.157 1.336-.304 2.617-.524 3.89c-.141.82-.71 1.335-1.35 1.373c-.532.032-.853-.333-.798-1.177Z"
            />
            <path
                fill="url(#f718id7)"
                d="M19.843 13.07c-.11 1.203-.225 2.762.71 3.828c.86.97 1.68 1.18 3.235 1.086c1.524-.28 2.828-1.265 3.438-1.875c.89-.89 1.742-2.333 1.984-3.82c.182-.983.278-2.123.445-3.117c.305-1.813.164-3.18-.562-4.172c-.531-.828-1.646-1.342-2.914-1.266c-1.318.08-2.91.835-4.031 2.149a8.15 8.15 0 0 0-1.889 4.086c-.12.914-.283 2.11-.416 3.101Zm3.61-.086c.179-1.437.32-2.593.53-4.086c.142-.82.883-1.289 1.329-1.289c.639-.038.91.451.812 1.29c-.156 1.335-.304 2.617-.523 3.89c-.142.82-.711 1.334-1.35 1.373c-.532.031-.854-.334-.799-1.178Z"
            />
            <path
                fill="url(#f718idv)"
                d="M19.843 13.07c-.11 1.203-.225 2.762.71 3.828c.86.97 1.68 1.18 3.235 1.086c1.524-.28 2.828-1.265 3.438-1.875c.89-.89 1.742-2.333 1.984-3.82c.182-.983.278-2.123.445-3.117c.305-1.813.164-3.18-.562-4.172c-.531-.828-1.646-1.342-2.914-1.266c-1.318.08-2.91.835-4.031 2.149a8.15 8.15 0 0 0-1.889 4.086c-.12.914-.283 2.11-.416 3.101Zm3.61-.086c.179-1.437.32-2.593.53-4.086c.142-.82.883-1.289 1.329-1.289c.639-.038.91.451.812 1.29c-.156 1.335-.304 2.617-.523 3.89c-.142.82-.711 1.334-1.35 1.373c-.532.031-.854-.334-.799-1.178Z"
            />
            <path
                fill="url(#f718id8)"
                d="M19.843 13.07c-.11 1.203-.225 2.762.71 3.828c.86.97 1.68 1.18 3.235 1.086c1.524-.28 2.828-1.265 3.438-1.875c.89-.89 1.742-2.333 1.984-3.82c.182-.983.278-2.123.445-3.117c.305-1.813.164-3.18-.562-4.172c-.531-.828-1.646-1.342-2.914-1.266c-1.318.08-2.91.835-4.031 2.149a8.15 8.15 0 0 0-1.889 4.086c-.12.914-.283 2.11-.416 3.101Zm3.61-.086c.179-1.437.32-2.593.53-4.086c.142-.82.883-1.289 1.329-1.289c.639-.038.91.451.812 1.29c-.156 1.335-.304 2.617-.523 3.89c-.142.82-.711 1.334-1.35 1.373c-.532.031-.854-.334-.799-1.178Z"
            />
            <path
                fill="url(#f718id9)"
                d="M19.843 13.07c-.11 1.203-.225 2.762.71 3.828c.86.97 1.68 1.18 3.235 1.086c1.524-.28 2.828-1.265 3.438-1.875c.89-.89 1.742-2.333 1.984-3.82c.182-.983.278-2.123.445-3.117c.305-1.813.164-3.18-.562-4.172c-.531-.828-1.646-1.342-2.914-1.266c-1.318.08-2.91.835-4.031 2.149a8.15 8.15 0 0 0-1.889 4.086c-.12.914-.283 2.11-.416 3.101Zm3.61-.086c.179-1.437.32-2.593.53-4.086c.142-.82.883-1.289 1.329-1.289c.639-.038.91.451.812 1.29c-.156 1.335-.304 2.617-.523 3.89c-.142.82-.711 1.334-1.35 1.373c-.532.031-.854-.334-.799-1.178Z"
            />
            <g filter="url(#f718idl)">
                <path
                    stroke="url(#f718ida)"
                    strokeLinecap="round"
                    d="M25.398 5.36c.385-.157 1.337-.326 2.062.25c.906.718.781 1.75.656 2.406c-.1.525-.395 3.854-.656 5.406"
                />
            </g>
            <g filter="url(#f718idm)">
                <path
                    stroke="url(#f718idb)"
                    strokeLinecap="round"
                    d="M25.116 14.953c-.312.388-1.469.963-2.194.388c-.906-.719-.781-1.75-.656-2.406c.1-.525.396-2.149.656-3.7"
                />
            </g>
            <path
                fill="url(#f718idc)"
                fillRule="evenodd"
                d="M27.672 19.63a1.05 1.05 0 0 1-.814 1.242l-8.634 1.795l-6.586 1.467a1.05 1.05 0 0 1-.457-2.05l6.601-1.47l8.648-1.798a1.05 1.05 0 0 1 1.242.814Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f718idd)"
                fillRule="evenodd"
                d="M25.53 23.843a1.05 1.05 0 0 1-.808 1.246l-8.792 1.874l-6.594 1.438a1.05 1.05 0 1 1-.447-2.052l6.599-1.439l8.797-1.875a1.05 1.05 0 0 1 1.245.808Z"
                clipRule="evenodd"
            />
            <g filter="url(#f718idn)">
                <circle cx="7.331" cy="9.344" r=".781" fill="#FF596F" />
            </g>
            <g filter="url(#f718ido)">
                <path
                    stroke="url(#f718ide)"
                    strokeLinecap="round"
                    strokeWidth=".75"
                    d="m5.785 21.516l1.53-11.032"
                />
            </g>
            <g filter="url(#f718idp)">
                <path
                    stroke="url(#f718idf)"
                    strokeLinecap="round"
                    strokeWidth=".75"
                    d="m3.66 13.184l1.937-2.313"
                />
            </g>
            <g filter="url(#f718idq)">
                <path
                    stroke="url(#f718idg)"
                    strokeLinecap="round"
                    d="M14.597 7.672c.386-.156 1.338-.325 2.063.25c.906.719.78 1.75.656 2.406c-.1.525-.396 3.854-.656 5.406"
                />
            </g>
            <g filter="url(#f718idr)">
                <path
                    stroke="url(#f718idh)"
                    strokeLinecap="round"
                    d="M14.316 17.265c-.313.388-1.47.963-2.194.388c-.907-.718-.782-1.75-.657-2.406c.1-.525.396-2.148.657-3.7"
                />
            </g>
            <g filter="url(#f718ids)">
                <ellipse
                    cx="26.753"
                    cy="19.711"
                    fill="url(#f718idi)"
                    rx=".703"
                    ry=".461"
                    transform="rotate(-12.626 26.753 19.71)"
                />
            </g>
            <g filter="url(#f718idt)">
                <ellipse
                    cx="24.535"
                    cy="23.992"
                    fill="url(#f718idj)"
                    rx=".703"
                    ry=".461"
                    transform="rotate(-12.626 24.535 23.992)"
                />
            </g>
            <defs>
                <linearGradient
                    id="f718id0"
                    x1="6.812"
                    x2="4.094"
                    y1="23.903"
                    y2="3.713"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC008C" />
                    <stop offset="1" stopColor="#E81123" />
                </linearGradient>
                <linearGradient
                    id="f718id1"
                    x1="3.816"
                    x2=".024"
                    y1="14.359"
                    y2="12.359"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".151" stopColor="#A11749" stopOpacity="0" />
                    <stop offset=".895" stopColor="#A21749" />
                </linearGradient>
                <linearGradient
                    id="f718id2"
                    x1="5.941"
                    x2="4.941"
                    y1="11.797"
                    y2="18.828"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DB1136" />
                    <stop offset="1" stopColor="#DB1136" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f718id3"
                    x1="4.878"
                    x2="3.3"
                    y1="16.891"
                    y2="16.672"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".151" stopColor="#A11749" stopOpacity="0" />
                    <stop offset=".895" stopColor="#A21749" />
                </linearGradient>
                <linearGradient
                    id="f718id4"
                    x1="14.495"
                    x2="13.984"
                    y1="23.228"
                    y2="6.484"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC008C" />
                    <stop offset="1" stopColor="#E81123" />
                </linearGradient>
                <linearGradient
                    id="f718id5"
                    x1="11.152"
                    x2="7.666"
                    y1="11.823"
                    y2="8.102"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".151" stopColor="#A11749" stopOpacity="0" />
                    <stop offset=".895" stopColor="#A21749" />
                </linearGradient>
                <linearGradient
                    id="f718id6"
                    x1="15.566"
                    x2="18.941"
                    y1="16.797"
                    y2="20.047"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".151" stopColor="#A11749" stopOpacity="0" />
                    <stop offset=".895" stopColor="#A21749" />
                </linearGradient>
                <linearGradient
                    id="f718id7"
                    x1="25.296"
                    x2="24.784"
                    y1="20.916"
                    y2="4.172"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC008C" />
                    <stop offset="1" stopColor="#E81123" />
                </linearGradient>
                <linearGradient
                    id="f718id8"
                    x1="21.952"
                    x2="18.467"
                    y1="9.51"
                    y2="5.789"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".151" stopColor="#A11749" stopOpacity="0" />
                    <stop offset=".895" stopColor="#A21749" />
                </linearGradient>
                <linearGradient
                    id="f718id9"
                    x1="26.366"
                    x2="29.741"
                    y1="14.484"
                    y2="17.734"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".151" stopColor="#A11749" stopOpacity="0" />
                    <stop offset=".895" stopColor="#A21749" />
                </linearGradient>
                <linearGradient
                    id="f718ida"
                    x1="28.366"
                    x2="28.491"
                    y1="2.422"
                    y2="16.141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6579" stopOpacity="0" />
                    <stop offset=".367" stopColor="#FF6579" />
                    <stop offset="1" stopColor="#FF596F" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f718idb"
                    x1="22.016"
                    x2="21.891"
                    y1="18.528"
                    y2="4.81"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6579" stopOpacity="0" />
                    <stop offset=".367" stopColor="#FF6579" />
                    <stop offset="1" stopColor="#FF596F" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f718idc"
                    x1="18.316"
                    x2="17.628"
                    y1="22.797"
                    y2="19.172"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC008C" />
                    <stop offset=".828" stopColor="#E81123" />
                </linearGradient>
                <linearGradient
                    id="f718idd"
                    x1="17.753"
                    x2="16.517"
                    y1="26.297"
                    y2="21.505"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EC008C" />
                    <stop offset=".668" stopColor="#E81123" />
                </linearGradient>
                <linearGradient
                    id="f718ide"
                    x1="7.785"
                    x2="5.378"
                    y1="12.172"
                    y2="28.109"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6579" />
                    <stop offset="1" stopColor="#FF596F" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f718idf"
                    x1="6.19"
                    x2="6.105"
                    y1="11.225"
                    y2="14.64"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF47B4" stopOpacity="0" />
                    <stop offset="1" stopColor="#FF47B4" />
                </linearGradient>
                <linearGradient
                    id="f718idg"
                    x1="17.566"
                    x2="17.691"
                    y1="4.734"
                    y2="18.453"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6579" stopOpacity="0" />
                    <stop offset=".367" stopColor="#FF6579" />
                    <stop offset="1" stopColor="#FF596F" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f718idh"
                    x1="11.215"
                    x2="11.09"
                    y1="20.841"
                    y2="7.122"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF6579" stopOpacity="0" />
                    <stop offset=".367" stopColor="#FF6579" />
                    <stop offset="1" stopColor="#FF596F" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f718idi"
                    x1="27.607"
                    x2="25.689"
                    y1="19.902"
                    y2="19.961"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF5E84" />
                    <stop offset="1" stopColor="#FF698D" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f718idj"
                    x1="25.388"
                    x2="23.47"
                    y1="24.183"
                    y2="24.242"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF5E84" />
                    <stop offset="1" stopColor="#FF698D" stopOpacity="0" />
                </linearGradient>
                <filter
                    id="f718idk"
                    width="3.578"
                    height="10.734"
                    x="3.41"
                    y="11.563"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".125" />
                </filter>
                <filter
                    id="f718idl"
                    width="5.806"
                    height="11.213"
                    x="23.897"
                    y="3.709"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".5" />
                </filter>
                <filter
                    id="f718idm"
                    width="5.937"
                    height="9.35"
                    x="20.679"
                    y="7.734"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".5" />
                </filter>
                <filter
                    id="f718idn"
                    width="3.563"
                    height="3.563"
                    x="5.55"
                    y="7.563"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".5" />
                </filter>
                <filter
                    id="f718ido"
                    width="4.281"
                    height="13.781"
                    x="4.41"
                    y="9.109"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".5" />
                </filter>
                <filter
                    id="f718idp"
                    width="4.688"
                    height="5.063"
                    x="2.285"
                    y="9.496"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".5" />
                </filter>
                <filter
                    id="f718idq"
                    width="5.806"
                    height="11.213"
                    x="13.097"
                    y="6.022"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".5" />
                </filter>
                <filter
                    id="f718idr"
                    width="5.937"
                    height="9.35"
                    x="9.879"
                    y="10.047"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".5" />
                </filter>
                <filter
                    id="f718ids"
                    width="1.987"
                    height="1.551"
                    x="25.76"
                    y="18.935"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".15" />
                </filter>
                <filter
                    id="f718idt"
                    width="1.987"
                    height="1.551"
                    x="23.541"
                    y="23.217"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_7137_6158" stdDeviation=".15" />
                </filter>
                <radialGradient
                    id="f718idu"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-172.166 7.451 6.232) scale(1.74657 4.54588)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".417" stopColor="#B50A47" />
                    <stop offset="1" stopColor="#CE0D52" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f718idv"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-172.166 12.772 4.706) scale(1.74657 4.54588)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".417" stopColor="#B50A47" />
                    <stop offset="1" stopColor="#CE0D52" stopOpacity="0" />
                </radialGradient>
            </defs>
        </g>
    </svg>
);
export default HundredPoints;
