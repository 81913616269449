import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.padding(
            tokens.spacingVerticalNone,
            tokens.spacingVerticalS,
            tokens.spacingHorizontalNone,
            tokens.spacingHorizontalS,
        ),
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    debuggerContainer: {
        backgroundColor: tokens.colorNeutralBackground5,
        width: '100%',
        fontSize: tokens.fontSizeBase300,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralBackground1),
        ':hover > *': {
            visibility: 'visible',
        },
        borderBottomLeftRadius: tokens.borderRadiusLarge,
        borderBottomRightRadius: tokens.borderRadiusLarge,
    },
    parentAccordion: {
        width: '100%',
    },
    accordionItem: {
        width: '100%',
        ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralBackground1),
    },
    completeIcon: {
        ...shorthands.flex(0, 0, 'auto'),
        color: '#54B054',
    },
    errorIcon: {
        ...shorthands.flex(0, 0, 'auto'),
        color: '#E37D80',
    },
    cancelledIcon: {
        ...shorthands.flex(0, 0, 'auto'),
    },
    warningIcon: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorPaletteYellowForeground1,
    },
    elapsedTime: {
        ...shorthands.flex(0, 0, 'auto'),
        marginLeft: '10px',
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground3,
    },
    iconCol: {
        width: '40px',
        height: '40px',
        display: 'inline-block',
        ...shorthands.padding(tokens.spacingVerticalSNudge, tokens.spacingHorizontalSNudge),
    },
    childIconCol: {
        width: '40px',
        height: '40px',
        display: 'inline-block',
        ...shorthands.padding(tokens.spacingVerticalSNudge, tokens.spacingHorizontalSNudge),
    },
    image: {
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    },
    accordionHeaderCol: {
        width: '88%',
        fontWeight: tokens.fontWeightSemibold,
    },
    topAccordionHeaderCol: {
        width: '92%',
        fontWeight: tokens.fontWeightSemibold,
    },
    childheader: {
        ...shorthands.padding('0px', '0px', '0px', '30px'),
    },
    childheaderNoParent: {},
    headerClass: {
        color: 'inherit !important',
    },
    parentpanel: {
        ...shorthands.margin(tokens.spacingVerticalNone),
        ...shorthands.padding(tokens.spacingVerticalNone),
    },
    previewBadgeContainer: {
        float: 'right',
    },
});
