import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    badge: {
        display: 'flex',
        alignSelf: 'center',
        backgroundColor: tokens.colorNeutralBackground3Hover,
        marginRight: tokens.spacingHorizontalS,
        color: tokens.colorNeutralForeground2,
        borderRadius: tokens.borderRadiusMedium,
        border: '1px solid ' + tokens.colorTransparentStroke,
        fontSize: tokens.fontSizeBase300,
        fontWeight: 'normal',
        height: '24px',
        '-webkit-box-sizing': 'border-box',
    },
    badgeImage: {
        backgroundColor: tokens.colorNeutralBackground3Hover,
        borderTopLeftRadius: tokens.borderRadiusMedium,
        borderBottomLeftRadius: tokens.borderRadiusMedium,
        ...shorthands.padding(tokens.spacingHorizontalXS),
        marginRight: tokens.spacingHorizontalXS,
        height: tokens.fontSizeBase500,
        width: tokens.fontSizeBase500,
    },
    label: {
        maxWidth: '120px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    promptLibraryContainer: {
        maxWidth: '100%',
    },
    promptbookLibraryContainer: {
        '@media (max-width: 479px)': {
            display: 'none',
        },
    },
});
