import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    mergeClasses,
    Option,
    useFocusFinders,
    Spinner,
    MessageBar,
    MessageBarTitle,
    MessageBarBody,
    Combobox,
} from '@fluentui/react-components';
import useClasses from './SwitchCapacity.styles';
import {useEffect, useState, useRef, useMemo} from 'react';
import {DismissIcon} from '@/components/ui/icons';
import {useGetCapacities} from '@/api/capacities';
import {useUpdateWorkspace} from '@/api/workspaces';
import {CAPACITY_REFERENCE_NAME} from '@/api/api.constants';
import {useWorkspaceState} from './workspaces/workspaceStateProvider';
import {useGetUserInfo} from '@/api/app';
import SwitchCapacityConfirmation from './SwitchCapacityConfirmation';
import {useTranslation} from 'react-i18next';
import CreateCapacity from './CreateCapacity';
import useLocations from '@/util/useLocations';
import {Capacity} from '@/api/capacities/capacities.types';
import {useQueryClient} from '@tanstack/react-query';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {CreateCapacityStatus} from './App.types';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';

interface SwitchCapacityProps {
    isSwitchCapacityOpen: boolean;
    context?: SwitchCapacityContext;
    onSwitchCapacityUpdate?: (context: SwitchCapacityContext) => Promise<void>;
    onSwitchCapacityDialogClose: (context: SwitchCapacityContext) => void;
}

export enum SwitchCapacityMode {
    CreateCapacity,
    SwitchCapacity,
    Hidden,
}

export type SwitchCapacityContext = '' | 'eap';

export default function SwitchCapacity(props: SwitchCapacityProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {t: tAdmin} = useTranslation('admin');
    const {t: tCommon} = useTranslation('common');
    const {workspaceName, capacityName, update, isProviderLoaded} = useWorkspaceState();
    const {data: authData} = useGetUserInfo();
    const {getRegionDisplayName} = useLocations();
    const queryClient = useQueryClient();
    const {mutate: trackEvent} = useTrackEvent();

    const {findFirstFocusable} = useFocusFinders();
    const switchCapacityWrapperRef = useRef<HTMLDivElement>(null);

    const {onSwitchCapacityDialogClose} = props;
    const context = props?.context || '';

    const [isComponentOpen, setIsComponentOpen] = useState<boolean>(props.isSwitchCapacityOpen);
    const [capacityMode, setCapacityMode] = useState<SwitchCapacityMode>(SwitchCapacityMode.Hidden);

    // API call to fetch capacity list
    const {data: capacities, isSuccess: isGetCapacitiesSuccess} = useGetCapacities({
        enabled: props.isSwitchCapacityOpen,
    });

    // Filter out empty capacity values from the list of capacities
    const filteredCapacities: Capacity[] = useMemo(() => {
        return capacities?.value?.filter((item: Capacity) => item.id !== '') || [];
    }, [capacities]);

    useEffect(() => {
        if (props.isSwitchCapacityOpen) {
            setShowCreateError(false);
            setShowCreateSuccess(false);
        }
    }, [props.isSwitchCapacityOpen]);

    const shouldCreateCapacityOpen = function () {
        return isGetCapacitiesSuccess && filteredCapacities?.length === 0;
    };

    useEffect(() => {
        if (props.isSwitchCapacityOpen && isGetCapacitiesSuccess && isProviderLoaded) {
            setIsComponentOpen(true);
            if (shouldCreateCapacityOpen()) {
                setCapacityMode(SwitchCapacityMode.CreateCapacity);
            } else {
                setCapacityMode(SwitchCapacityMode.SwitchCapacity);
            }
        }
    }, [
        props.isSwitchCapacityOpen,
        isComponentOpen,
        isGetCapacitiesSuccess,
        capacities,
        isProviderLoaded,
    ]);

    // API call to update workspace capacity linkage
    const {mutate: updateWorkspace, isLoading: isUpdateWorkspaceLoading} = useUpdateWorkspace();

    // Selected capacity value from dropdown
    // This ensures that a valid capacity is available
    const capacityNameComboBox =
        isProviderLoaded && filteredCapacities?.some((x) => x.name === capacityName)
            ? capacityName
            : '';
    const [capacityValue, setCapacityValue] = useState<string>(capacityNameComboBox ?? '');
    const [capacityId, setCapacityId] = useState<string>('');
    const [capacityRegion, setCapacityRegion] = useState<string>('');
    const [showCreateSuccess, setShowCreateSuccess] = useState<boolean>(false);
    const [showCreateError, setShowCreateError] = useState<boolean>(false);

    // Show confirmation dialog on successful capacity switch
    const [isSwitchCapacityConfirmDialogOpen, setSwitchCapacityConfirmDialogOpen] =
        useState<boolean>(false);
    const [errorTag, setErrorTag] = useState<boolean>(false);

    // To check that capacity value got updated or not.
    const isCapacityValueUpdated = useMemo(() => {
        return capacityValue !== capacityName;
    }, [capacityValue, capacityName]);

    // Actions to take on close/dismiss of switch capacity dialog
    const closeCapacityDialog = () => {
        setCapacityMode(SwitchCapacityMode.Hidden);
        setShowCreateError(false);
        setShowCreateSuccess(false);
        onSwitchCapacityDialogClose(context);
        setSwitchCapacityConfirmDialogOpen(false);
        // Smoothening the closure of dialog
        setTimeout(() => {
            setIsComponentOpen(false);
        }, 500);
    };

    // Action on apply button click. Do PUT workspace API call to update workspace capacity linkage.
    const onApply = () => {
        if (!!workspaceName && !!capacityValue) {
            updateWorkspace(
                {
                    name: workspaceName,
                    capacity: {
                        type: CAPACITY_REFERENCE_NAME,
                        referenceName: capacityValue,
                    },
                },
                {
                    onSuccess: async () => {
                        await update();

                        setTimeout(async () => {
                            await queryClient.refetchQueries(['auth', 'expiryDate']);
                            await props?.onSwitchCapacityUpdate?.(context);
                            const currentCapacity = filteredCapacities.find(
                                (x) => x.name === capacityValue,
                            );
                            const currentlyUpdatedCapacityId = currentCapacity?.id;
                            const location = currentCapacity?.location;
                            const region = getRegionDisplayName(location || '');
                            setCapacityRegion(region);
                            setCapacityId(currentlyUpdatedCapacityId || '');
                            setSwitchCapacityConfirmDialogOpen(true);
                        }, 500);
                    },
                    onError: () => {
                        setErrorTag(true);
                    },
                },
            );
        }
    };

    const onCapacitySelection = (event: any, data: any) => {
        setShowCreateError(false);
        setShowCreateSuccess(false);
        setCapacityValue(data.optionValue);
        setErrorTag(false);
    };

    // Focus on first focusable element in dialog on open
    useEffect(() => {
        if (switchCapacityWrapperRef?.current) {
            const firstFocusable = findFirstFocusable(switchCapacityWrapperRef.current);
            firstFocusable?.focus();
        }
    }, [switchCapacityWrapperRef]);

    // Action to be perform on create capacity dialog closure
    const actionsOnDialogClosure = (status?: string) => {
        if (
            status === CreateCapacityStatus.Cancel &&
            filteredCapacities.length === 0 &&
            isGetCapacitiesSuccess
        ) {
            closeCapacityDialog();
        } else {
            if (status === CreateCapacityStatus.Success) {
                setShowCreateSuccess(true);
            } else if (status === CreateCapacityStatus.Error) {
                setShowCreateError(true);
            }
            setCapacityMode(SwitchCapacityMode.SwitchCapacity);
        }
    };

    // Set capacity value to API response
    useEffect(() => {
        if (
            !!capacityName &&
            isGetCapacitiesSuccess &&
            filteredCapacities?.some((x) => x.name === (capacityName || ''))
        ) {
            setCapacityValue(capacityName);
        } else {
            setCapacityValue('');
        }
    }, [capacityName, capacities, isGetCapacitiesSuccess]);

    return (
        <>
            {isComponentOpen && authData?.isAdmin && (
                <div ref={switchCapacityWrapperRef}>
                    <Dialog open={capacityMode === SwitchCapacityMode.SwitchCapacity}>
                        <DialogSurface>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    data-test-id="switch-capacity-dismiss-button"
                                    className={classes.closeButton}
                                    appearance="transparent"
                                    aria-label={tAdmin('switchCapacity.ariaLabel.Close')}
                                    onClick={closeCapacityDialog}
                                >
                                    <DismissIcon className={classes.icon} />
                                </Button>
                            </DialogTrigger>
                            <DialogBody>
                                <DialogTitle className={classes.title}>
                                    {tAdmin('switchCapacity.Title')}
                                </DialogTitle>
                                <DialogContent className={classes.dialogContentSection}>
                                    {showCreateSuccess && (
                                        <MessageBar shape="rounded" key="error" intent="success">
                                            <MessageBarBody>
                                                <MessageBarTitle>
                                                    {tAdmin(
                                                        'switchCapacity.CreateCapacitySuccessful',
                                                    )}
                                                </MessageBarTitle>
                                            </MessageBarBody>
                                        </MessageBar>
                                    )}
                                    {showCreateError && (
                                        <MessageBar shape="rounded" key="error" intent="error">
                                            <MessageBarBody>
                                                <MessageBarTitle>
                                                    {tAdmin('switchCapacity.CreateCapacityError')}
                                                </MessageBarTitle>
                                                {tAdmin('switchCapacity.TryAgain')}
                                            </MessageBarBody>
                                        </MessageBar>
                                    )}
                                    {errorTag && (
                                        <MessageBar shape="rounded" key="error" intent="error">
                                            <MessageBarBody>
                                                <MessageBarTitle>
                                                    {tAdmin('switchCapacity.ErrorMessage')}
                                                </MessageBarTitle>
                                                {tAdmin('switchCapacity.TryAgain')}
                                            </MessageBarBody>
                                        </MessageBar>
                                    )}
                                    <div className={classes.contentSection}>
                                        {tAdmin('switchCapacity.Info')}
                                    </div>
                                    <div>
                                        <Combobox
                                            placeholder={tAdmin('switchCapacity.Placeholder')}
                                            className={mergeClasses(
                                                classes.fullWidthControl,
                                                classes.capacityDropDown,
                                            )}
                                            aria-label={tAdmin(
                                                'switchCapacity.ariaLabel.CapacityList',
                                            )}
                                            data-test-id="switch-capacity-list-dropdown"
                                            onOptionSelect={onCapacitySelection}
                                            listbox={{
                                                className: mergeClasses(
                                                    classes.listBoxHeight,
                                                    scrollClasses.colorNeutralBackground2,
                                                ),
                                            }}
                                            selectedOptions={!!capacityValue ? [capacityValue] : []}
                                            value={capacityValue}
                                            disabled={
                                                filteredCapacities.length === 0 || !isProviderLoaded
                                            }
                                        >
                                            {filteredCapacities
                                                ?.sort((itemA, itemB) =>
                                                    itemA.name > itemB.name ? 1 : -1,
                                                )
                                                .map(({id, name}: any) => {
                                                    return (
                                                        <Option key={id} value={name}>
                                                            {name}
                                                        </Option>
                                                    );
                                                })}
                                        </Combobox>
                                    </div>
                                </DialogContent>
                                <div>
                                    <Button
                                        data-test-id="switch-capacity-apply-button"
                                        appearance="secondary"
                                        aria-label={tAdmin(
                                            'switchCapacity.ariaLabel.CreateCapacity',
                                        )}
                                        onClick={() => {
                                            setShowCreateError(false);
                                            setShowCreateSuccess(false);
                                            setCapacityMode(SwitchCapacityMode.CreateCapacity);
                                            trackEvent({
                                                name: MedeinaTelemetryEvent.Capacity.CreateCapacity,
                                                eventType: MedeinaEvent.ActionEvent,
                                            });
                                        }}
                                        disabled={isUpdateWorkspaceLoading}
                                    >
                                        {tAdmin('switchCapacity.CreateCapacity')}
                                    </Button>
                                </div>
                                <DialogActions>
                                    <Button
                                        data-test-id="switch-capacity-apply-button"
                                        appearance="secondary"
                                        aria-label={tAdmin(
                                            'switchCapacity.ariaLabel.ApplyCapacity',
                                        )}
                                        onClick={() => {
                                            onApply();
                                            trackEvent({
                                                name: MedeinaTelemetryEvent.Capacity.ApplyCapacity,
                                                eventType: MedeinaEvent.ActionEvent,
                                            });
                                        }}
                                        disabled={
                                            !isCapacityValueUpdated ||
                                            isUpdateWorkspaceLoading ||
                                            capacityValue === ''
                                        }
                                    >
                                        {!isUpdateWorkspaceLoading ? (
                                            tCommon('ButtonLabels.Apply')
                                        ) : (
                                            <Spinner size="tiny" />
                                        )}
                                    </Button>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button
                                            data-test-id="switch-capacity-cancel-button"
                                            appearance="secondary"
                                            aria-label={tAdmin('switchCapacity.ariaLabel.Cancel')}
                                            onClick={closeCapacityDialog}
                                        >
                                            {tCommon('ButtonLabels.Cancel')}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                    <SwitchCapacityConfirmation
                        isSwitchCapacityConfirmDialogOpen={isSwitchCapacityConfirmDialogOpen}
                        onSwitchCapacityConfirmDialogClose={closeCapacityDialog}
                        capacityId={capacityId}
                        regionName={capacityRegion}
                    />
                    <CreateCapacity
                        isCreateCapacityDialogOpen={
                            capacityMode == SwitchCapacityMode.CreateCapacity
                        }
                        onCreateCapacityDialogClose={actionsOnDialogClosure}
                    />
                </div>
            )}
        </>
    );
}
