import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 'auto'),
        maxWidth: '85%',
        wordBreak: 'break-word',
        minWidth: '650px',
    },
    headerCell: {
        '& > button': {
            paddingBottom: tokens.spacingVerticalS,
            paddingTop: tokens.spacingVerticalS,
        },
    },
    gridCell: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    },
    textBox: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'absolute',
        width: '323px',
        height: tokens.spacingHorizontalXXXL,
        left: tokens.borderRadiusNone,
        top: tokens.borderRadiusNone,
    },
    filterDialog: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalS),
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase400,
        ),
    },
    title: {
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase100,
        ),
        fontSize: tokens.spacingHorizontalXXXL,
        fontWeight: tokens.fontWeightRegular,
    },
    newInvestigation: {
        position: 'sticky',
        top: tokens.lineHeightBase500,
        zIndex: 2,
        marginLeft: 'auto',
        marginTop: '-22px',
        justifyContent: 'flex-end',
    },
    stickyActions: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: tokens.colorNeutralBackground2,
        paddingTop: tokens.spacingVerticalL,
    },
    filteringInvestigations: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalS),
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase400,
        ),
    },
    skeleton: {
        height: '45px',
    },
    highlight: {
        fontWeight: tokens.fontWeightBold,
        backgroundColor: 'inherit',
        color: 'inherit',
    },
});
