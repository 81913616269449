import {ColorScheme} from '@/api/app/app.types';

export enum BackgroundImage {
    // no image
    'none' = 'none',
    // a static geometric background
    'triangles' = 'triangles',
}

export enum BackgroundAnimation {
    // no animation
    'none' = 'none',
    // fullscreen background animation
    'fullGradient' = 'fullGradient',
    // bottom gradient animation with a gradient
    'bottomGradient' = 'bottomGradient',
}

export interface BackgroundOptions {
    image?: BackgroundImage;
    animation?: BackgroundAnimation;
}

export interface GradientProps {
    colorScheme: ColorScheme;
}

export interface BackgroundProps extends BackgroundOptions {
    colorScheme?: ColorScheme;
}
