import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    field: {
        marginBottom: tokens.spacingVerticalXXXL,
    },
    adminNotice: {
        color: tokens.colorPaletteRedForeground1,
    },
    hidden: {
        display: 'none',
    },
    firstHeader: {
        marginTop: tokens.spacingVerticalSNudge,
    },
    geoData: {
        ...shorthands.margin(0),
    },
    dataSwitch: {
        ...shorthands.padding(0),
    },
});
