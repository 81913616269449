import {useState, useEffect, useCallback} from 'react';
import {Dropdown, Option, OptionGroup} from '@fluentui/react-components';
import {useUserState} from '@/api/user';
import useClasses from './TenantSwitcher.styles';
import {featureFlags} from '@/util/hasStaticFeatureFlags';
import {useGetUserTenants, Tenant} from '@/api/user';
import {useTranslation} from 'react-i18next';
import TenantSwitchOverlay from './TenantSwitchOverlay';

export default function TenantSwitcher() {
    // styling stuff
    const classes = useClasses();
    const {tenant, setTenant} = useUserState();
    const {data: listTenants} = useGetUserTenants({enabled: true});
    const {t} = useTranslation();

    // When the user selects a new tenant, we hold the new value temporarily in state
    const [newTenant, setNewTenant] = useState<Tenant>();
    const [overlay, setOverlay] = useState<boolean>(false);

    // when a new tenant is selected, we need to update the MSAL config and refresh the app
    // the UserStateProvider then updates the msal config and refreshes the app
    useEffect(() => {
        const featureFlagParams = () => (featureFlags() ? `&featureFlags=${featureFlags()}` : '');
        if (newTenant) {
            // use userState and set the new tenant in localStorage before leaving the page
            setTimeout(() => {
                setTenant(newTenant);
                window.location.href = `/?tenantId=${newTenant.tenantId}${featureFlagParams()}`;
            }, 2000);
        }
    }, [newTenant]);

    // when a user selects a
    const onHandleTenantSelect = useCallback(
        (data: any) => {
            // do nothing if we selected the same tenant again
            if (tenant.tenantId === data.optionValue) {
                return;
            }
            // start our loading state
            setOverlay(true);
            // get the display name of the current tenant
            setNewTenant({tenantId: data.optionValue, displayName: data.optionText as string});
        },
        [tenant.tenantId],
    );

    // Render
    return (
        <>
            <Dropdown
                className={classes.tenantDropdown}
                appearance="filled-darker"
                value={tenant.displayName || tenant.tenantId}
                selectedOptions={tenant.displayName ? [tenant.tenantId] : []}
                aria-label={t('SettingsTenantDropdownLabel')}
                onOptionSelect={(_, data) => onHandleTenantSelect(data)}
            >
                <OptionGroup label={t('SettingsTenantsLabel')} role="listbox">
                    {listTenants?.map((tenant) => (
                        <Option role="option" key={tenant.tenantId} value={tenant.tenantId}>
                            {tenant.displayName || tenant.tenantId}
                        </Option>
                    ))}
                </OptionGroup>
            </Dropdown>
            {overlay && newTenant && (
                <TenantSwitchOverlay currentTenant={newTenant.displayName || newTenant.tenantId} />
            )}
        </>
    );
}
