import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground3,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        boxSizing: 'border-box',
        height: '448px',
        width: '100%',
    },
    title: {
        backgroundImage: `linear-gradient(140.1deg, ${tokens.colorNeutralForeground3} 19.55%, ${tokens.colorBrandForeground2} 77.23%)`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    content: {
        maxWidth: '370px',
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXXL),
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
});
