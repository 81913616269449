import React, {useRef, useEffect} from 'react';
import ThemeSwitcher from '@/components/ui/ThemeSwitcher/ThemeSwitcher';
import useTourClasses from '../TourContainer.styles';
import {motion} from 'framer-motion';
import {useTourContext, TourControls} from '@/components/ui/Tour';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {mergeClasses, useFocusFinders} from '@fluentui/react-components';
import useClasses from './Preferences.styles';
import {useTranslation} from 'react-i18next';

function Preferences() {
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const tour = useTourContext();

    const mainRef = useRef(null);
    const {findFirstFocusable} = useFocusFinders();

    useEffect(() => {
        if (mainRef.current === null) return;
        const firstFocusableElement = findFirstFocusable(mainRef.current);
        firstFocusableElement?.focus();
    }, [mainRef]);

    return (
        <>
            <div className={tourClasses.tourContainerContent} data-testid="centered-tour-container">
                <motion.div
                    key="terms"
                    variants={overlayAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    ref={mainRef}
                >
                    <div className={mergeClasses(tourClasses.centeredText, classes.textWrapper)}>
                        <h1 className={mergeClasses(tourClasses.blockTitleMedium)}>
                            {t('common.chooseALook')}
                        </h1>
                        <p className={mergeClasses(tourClasses.blockText, classes.buttomMargin)}>
                            {t('common.selectTheme')}
                        </p>
                        <ThemeSwitcher />
                    </div>
                </motion.div>
            </div>
            <motion.div
                key="controls"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourControls
                    handleNext={tour.nextStop}
                    nextButton={t('common.next')}
                    handlePrevious={tour.nextStop}
                    previousButton={t('common.notNow')}
                />
            </motion.div>
        </>
    );
}

export default Preferences;
