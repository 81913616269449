// SetupInProgress.tsx
import React from 'react';
import {Spinner, mergeClasses} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useClasses from './SetupInProgress.styles';
import AnnounceLabel from '@/components/ui/AnnounceText/AnnounceText';
import {useViewportSize} from '@/components/ui/Grid';
import {Body, TextH1} from '@/components/ui/Text';

interface SetupInProgressProps {
    heading?: string;
    subHeading?: string;
}

const SetupInProgress = (props: SetupInProgressProps) => {
    const tourClasses = useTourClasses();
    const pageClasses = useClasses();
    const {sm: isSmallScreen} = useViewportSize();

    return (
        <>
            <div>
                <AnnounceLabel ariaLive="polite">
                    <div aria-live="polite">
                        <Spinner
                            tabIndex={0}
                            className={pageClasses.spinner}
                            size={isSmallScreen ? 'tiny' : 'medium'}
                        />
                    </div>
                    <div
                        className={mergeClasses(
                            tourClasses.tourSectionSpacing,
                            pageClasses.textContainer,
                        )}
                    >
                        <TextH1 align="center">{props.heading}</TextH1>
                    </div>
                    <Body>{props.subHeading}</Body>
                </AnnounceLabel>
            </div>
        </>
    );
};

export default SetupInProgress;
