import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
import {ApplyPromptbookRequest, ApplyPromptbookResponse} from './promptbooks.types';
import {featureFlags} from '@/util/hasStaticFeatureFlags';

export default function useApplyPromptbook() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: ApplyPromptbookRequest) => {
            const featureFlagValues = featureFlags();
            const queryParams = !!featureFlagValues ? `?featureFlags=${featureFlagValues}` : '';

            return await customFetch<ApplyPromptbookResponse>(
                `/promptbooks/${body?.promptbookId}/apply${queryParams}`,
                {
                    method: 'POST',
                    body,
                },
            );
        },
    });
}
