import React, {useCallback} from 'react';
import {Button, Tooltip, MenuItem} from '@fluentui/react-components';
import {ExportIcon} from '@/components/ui/icons';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useViewportSize} from '@/components/ui/Grid';
import {SearchCategory, UsageDashboardState} from './UsageDashboard.types';
import useExportAggregateCapacityUsage from '@/api/capacities/useExportAggregateCapacityUsage';
import downloadFileFromBlob from '@/components/ui/util/domUtilities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {
    USAGE_EXPORT_LABEL,
    USAGE_EXPORT_BUTTON,
    USAGE_EXPORT_IN_PROGRESS,
    USAGE_EXPORT_ERROR,
    USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
} from './UsageDashboard.constants';
import useClasses from './UsageDashboard.styles';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';
import {GetExportedUsageRequestParameters} from '@/api/capacities/capacities.types';

function mapUsageDashboardStateToExportUsageParameters(
    state: UsageDashboardState,
): GetExportedUsageRequestParameters {
    const body: GetExportedUsageRequestParameters = {
        startDate: state.startDate.toISOString(),
        endDate: state.endDate.toISOString(),
    };

    state.searchFilters.forEach((facet) => {
        switch (facet.category) {
            case SearchCategory.CopilotExperience:
                body.experiences = facet.values.map((value) => value.toString());
                break;
            case SearchCategory.InvocationCategory:
                body.invocationCategories = facet.values.map((value) => value.toString());
                break;
            case SearchCategory.InvocationType:
                body.invocationTypes = facet.values.map((value) => value.toString());
                break;
            case SearchCategory.PluginUsed:
                body.plugins = facet.values.map((value) => value.toString());
                break;
            case SearchCategory.Users:
                body.users = facet.values.map((value) => value.toString());
                break;
            default:
                break;
        }
    });

    return body;
}

function UsageDashboardDataExport(props: UsageDashboardState) {
    const [isExporting, setIsExporting] = useState(false);
    const [isExportError, setIsExportError] = useState(false);
    const {mutateAsync: downloadDocument} = useExportAggregateCapacityUsage();
    const {capacityName} = useWorkspaceState();
    const screenSize = useViewportSize();
    const renderInHeaderMenu = screenSize.md || screenSize.sm;
    const {t} = useTranslation('admin');
    const classes = useClasses();

    const IN_PROGRESS_TIMEOUT = 30 * 60;

    const performDocumentExport = useCallback(
        async function () {
            let request = mapUsageDashboardStateToExportUsageParameters(props);
            request = {
                ...request,
                roundOffDecimals: USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
                timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
            const {fileBlob, fileName, isError} = await downloadDocument({
                capacityName: String(capacityName),
                exportParameters: request,
            });

            if (!isError) {
                downloadFileFromBlob(fileBlob as Blob, fileName as string);
            } else {
                throw new Error('Error exporting usage data');
            }
        },
        [props, capacityName],
    );
    const onClick = async () => {
        try {
            setIsExportError(false);
            setIsExporting(true);
            await performDocumentExport();
            setIsExporting(false);
        } catch (error) {
            setIsExporting(false);
            setIsExportError(true);
            console.error('Error exporting usage data', error);
        }
    };
    const [showTooltip, setShowTooltip] = useState(false);

    const content = renderInHeaderMenu ? (
        <MenuItem data-testid="usage-export-button" icon={<ExportIcon />} onClick={onClick}>
            {t(USAGE_EXPORT_BUTTON)}
        </MenuItem>
    ) : (
        <Tooltip visible={showTooltip} content={t(USAGE_EXPORT_LABEL)} relationship="label">
            <Button
                data-testid="usage-export-button"
                icon={<ExportIcon />}
                appearance="subtle"
                size="medium"
                onClick={onClick}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {t(USAGE_EXPORT_BUTTON)}
            </Button>
        </Tooltip>
    );

    return (
        <div className={classes.exportButtonContainer}>
            {content}
            {isExporting && (
                <ToastNotification
                    timeout={IN_PROGRESS_TIMEOUT}
                    intent={Intent.Progress}
                    message={t(USAGE_EXPORT_IN_PROGRESS)}
                ></ToastNotification>
            )}
            {isExportError && (
                <ToastNotification
                    intent={Intent.Error}
                    message={t(USAGE_EXPORT_ERROR)}
                ></ToastNotification>
            )}
        </div>
    );
}

export default UsageDashboardDataExport;
