import useClasses from './Table.styles';
import {CompactTable} from './Compact';
import {ModalTable} from './ModalTable';
import React from 'react';

import useTableProps from './useTableProps';
import {mergeClasses} from '@griffel/react';
import {TableProvider} from './TableContext';

const Table = ({...props}) => {
    const shouldDefaultToCompactTable = !props.tableType;
    const isModalTableType = props.tableType === 'modal';

    const containerStyles = useClasses();

    const {hasDataRows} = useTableProps(props);
    props.hasDataRows = hasDataRows();

    const getFitContentStyle = function () {
        return isModalTableType ? containerStyles.fitContent : '';
    };

    const getStylesForNoDataDisplay = function () {
        return !hasDataRows() ? containerStyles.noDataStyles : '';
    };

    return (
        <TableProvider {...props}>
            <div
                className={mergeClasses(
                    containerStyles.root,
                    getFitContentStyle(),
                    getStylesForNoDataDisplay(),
                )}
            >
                {shouldDefaultToCompactTable && <CompactTable {...props} />}
                {isModalTableType && <ModalTable {...props} />}
            </div>
        </TableProvider>
    );
};

export default Table;
