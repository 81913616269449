import {useEffect, useState} from 'react';
import useClasses from './ExploreCopilot.styles';
import {mergeClasses} from '@fluentui/react-components';
import {ExploreCopilotProps, ExploreTab} from './ExploreCopilot.types';
import ExplorePromptbooks from './ExplorePromptbooks';
import ExplorePrompts from './ExplorePrompts';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import {getExploreCardCountBasedOnScreenSize} from './util';
import FeaturedContentTabs from './FeaturedContentTabs';

export default function ExploreCopilot({onSubmit}: ExploreCopilotProps) {
    const classes = useClasses();
    const [activeTab, setActiveTab] = useState<ExploreTab>(ExploreTab.Promptbooks);

    const screenSize = useViewportSize();
    const numberOfExploreCards = getExploreCardCountBasedOnScreenSize(screenSize.activeViewport);

    const [btnClicked, setBtnClicked] = useState(''); // To know which button is clicked.
    const [disablePrev, setDisablePrev] = useState(false);
    const [disableNext, setDisableNext] = useState(false);

    const [cardRange, setCardRange] = useState(numberOfExploreCards);
    const [promptClick, setPromptClick] = useState(0); // Counter to know prompt tile to move and handle continuous click on same button.
    const [promptbookClick, setPromptbookClick] = useState(0); // Counter to know promptbook tile to move and handle continuous click on same button.

    useEffect(() => {
        setCardRange(getExploreCardCountBasedOnScreenSize(screenSize.activeViewport));
    }, [screenSize]);

    return (
        <div className={classes.root}>
            <FeaturedContentTabs
                activeTab={activeTab}
                disableNext={disableNext}
                disablePrev={disablePrev}
                promptClick={promptClick}
                promptbookClick={promptbookClick}
                setActiveTab={setActiveTab}
                setBtnClicked={setBtnClicked}
                setDisableNext={setDisableNext}
                setDisablePrev={setDisablePrev}
                setPromptClick={setPromptClick}
                setPromptbookClick={setPromptbookClick}
            />
            <div>
                {/* <ExplorePrompts
                    className={mergeClasses(activeTab !== ExploreTab.Prompts && classes.hidden)}
                    cardRange={cardRange}
                    btnClicked={btnClicked}
                    disablePrev={setDisablePrev}
                    disableNext={setDisableNext}
                    clickCounter={promptClick}
                    active={activeTab === ExploreTab.Prompts}
                /> */}
                <ExplorePromptbooks
                    className={mergeClasses(activeTab !== ExploreTab.Promptbooks && classes.hidden)}
                    cardRange={cardRange}
                    btnClicked={btnClicked}
                    disablePrev={setDisablePrev}
                    disableNext={setDisableNext}
                    clickCounter={promptbookClick}
                    active={activeTab === ExploreTab.Promptbooks}
                />
            </div>
        </div>
    );
}
