import {mergeClasses} from '@fluentui/react-components';
import {GridItem} from '../Grid';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {forwardRef} from 'react';

interface TourStageContainerProps {
    children: React.ReactNode;
}

interface TourStageContentProps {
    children: React.ReactNode;
}

interface TourStageFooterProps {
    children: React.ReactNode;
}

// Declaration for the StageContent
const StageContent: React.FC<TourStageContentProps> = (props: TourStageContentProps) => {
    const tourClasses = useTourClasses();

    return (
        <div
            className={mergeClasses(tourClasses.centeredText, tourClasses.tourStageContentWrapper)}
        >
            {props.children}
        </div>
    );
};
StageContent.displayName = 'StageContent';

// Declaration for the Footer Container
const FooterContent: React.FC<TourStageFooterProps> = (props: TourStageFooterProps) => {
    return <>{props.children}</>;
};
FooterContent.displayName = 'StageFooterContent';

// Declaration for the Tour Stage Container
const TourStageContainer = forwardRef<HTMLDivElement, TourStageContainerProps>(
    (props: TourStageContainerProps, ref) => {
        const tourClasses = useTourClasses();

        return (
            <GridItem sm={12} md={12} lg={12} xlg={12} xxlg={12} xxxlg={12}>
                <div className={tourClasses.tourStageWrapper} ref={ref}>
                    {props.children}
                </div>
            </GridItem>
        );
    },
) as React.ForwardRefExoticComponent<
    TourStageContainerProps & React.RefAttributes<HTMLDivElement>
> & {
    StageContent: typeof StageContent;
    FooterContent: typeof FooterContent;
};

TourStageContainer.displayName = 'TourStageContainer';

TourStageContainer.StageContent = StageContent;
TourStageContainer.FooterContent = FooterContent;

export default TourStageContainer;
