import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    MenuDivider,
    Button,
    Text,
} from '@fluentui/react-components';
import {useGetSession, useIsUserSessionOwner} from '@/api/sessions';
import MedeinaFeatures from '@/util/features';
import DuplicateSessionDialog from '../sessions/DuplicateSessionDialog';
import EditInvestigationNameDialog from './EditInvestigationNameDialog';
import {DuplicateIcon, EditIcon, DownIcon, AddIcon} from '@/components/ui/icons';
import {Session} from '@/api/sessions/sessions.types';
import useClasses from './SessionOptionsBreadcrumb.styles';
import {useTranslation} from 'react-i18next';

interface InvestigationBreadcrumbProps {
    sessionId: string;
}

const MAX_CRUMB_NAME_LENGTH = 80;

// Custom breadcrumb component for the sessionID segment of the breadcrumb
// TODO: lookup session information to retrieve the actual Summarized Title
function Breadcrumb(props: InvestigationBreadcrumbProps) {
    const classes = useClasses();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const isNewSession = props.sessionId === 'new';

    const [openEditNameDialog, setOpenEditNameDialog] = useState(false);
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);

    const handleOpenEditNameDialog = () => {
        setOpenEditNameDialog(true);
    };

    const handleCloseEditNameDialog = () => {
        setOpenEditNameDialog(false);
    };

    function ExistingSession() {
        const {data, isSuccess} = useGetSession({sessionId: props.sessionId});
        const {isUserSessionOwner} = useIsUserSessionOwner(props.sessionId);

        // TODO: Temporary fix, handle this with flexbox styling.
        const name =
            data?.name && data?.name?.length > MAX_CRUMB_NAME_LENGTH
                ? `${data.name.substring(0, MAX_CRUMB_NAME_LENGTH)}...`
                : data?.name;

        return (
            <>
                <Menu positioning="below-end">
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            appearance="subtle"
                            size="medium"
                            icon={<DownIcon />}
                            iconPosition="after"
                            className={classes.root}
                            data-testid="session-options-breadcrumb"
                        >
                            {name || t('BreadcrumbSessionButtonDefault') + props.sessionId}
                        </Button>
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList role="menu">
                            <MenuItem
                                icon={<EditIcon />}
                                onClick={handleOpenEditNameDialog}
                                role="menuitem"
                                disabled={!isUserSessionOwner}
                            >
                                {t('BreadcrumbSessionEditName')}
                            </MenuItem>
                            {MedeinaFeatures.DuplicateSessionExperience && (
                                <MenuItem
                                    data-testid="duplicate-button"
                                    role="menuitem"
                                    icon={<DuplicateIcon />}
                                    onClick={(e: {
                                        preventDefault: () => void;
                                        stopPropagation: () => void;
                                    }) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setOpenDuplicateDialog(true);
                                    }}
                                >
                                    {t('BreadcrumbSessionDuplicate')}
                                </MenuItem>
                            )}
                            <MenuDivider />
                            <MenuItem
                                data-testid="new session"
                                icon={<AddIcon />}
                                onClick={() =>
                                    MedeinaFeatures.NewSessionPage
                                        ? navigate('/sessions/new')
                                        : navigate('/')
                                }
                            >
                                {t('BreadcrumbSessionNewSession')}
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                {isSuccess && (
                    <>
                        <DuplicateSessionDialog
                            session={data as Session}
                            open={openDuplicateDialog}
                            onClose={() => {
                                setOpenDuplicateDialog(false);
                            }}
                            onSuccess={() => {
                                setOpenDuplicateDialog(false);
                            }}
                        />
                        <EditInvestigationNameDialog
                            open={openEditNameDialog}
                            onClose={handleCloseEditNameDialog}
                            onSuccess={handleCloseEditNameDialog}
                            session={data as Session}
                        />
                    </>
                )}
            </>
        );
    }

    function NewSession() {
        return <Text className={classes.newSession}>{t('BreadcrumbSessionUntitledSession')}</Text>;
    }

    return (
        <>{MedeinaFeatures.NewSessionPage && isNewSession ? <NewSession /> : <ExistingSession />}</>
    );
}

export default Breadcrumb;
