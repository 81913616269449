import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    useFocusableGroup,
} from '@fluentui/react-components';
import {MemberListItemProps, RoleType} from '../rbac.types';
import MemberListItem from './MemberListItem';
import {DismissIcon} from '@/components/ui/icons';
import {useEffect, useRef, useState} from 'react';
import useClasses from './MemberListItem.styles';
import {useTranslation} from 'react-i18next';

const roleValues = Object.values(RoleType);

interface MemberActionProps {
    onMemberRemoved: (memberId: string) => void;
    onMemberUpdated: (key: string, role: RoleType) => void;
}

interface AddedMemberListItemProps {
    key: string;
    memberItem: MemberListItemProps;
    restrictedRoles: Set<RoleType>;
    isFocused: boolean;
}

export default function AddedMemberListItem(props: AddedMemberListItemProps & MemberActionProps) {
    const {memberItem} = props;
    const memberListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.isFocused) {
            memberListRef.current?.focus();
        }
    }, [props.isFocused]);

    const [selectedRole, setSelectedRole] = useState(memberItem.role || RoleType.Contributor);
    const classes = useClasses();

    const handleRoleChange = (role: RoleType) => {
        setSelectedRole(role);
        props.onMemberUpdated(memberItem.key, role);
    };

    const availableRoles = roleValues?.filter((role) => !props.restrictedRoles.has(role));
    const attributes = useFocusableGroup({tabBehavior: 'limited'});
    const {t} = useTranslation('admin');
    return (
        <div
            ref={memberListRef}
            className={classes.addedMemberListItemWrapper}
            {...attributes}
            tabIndex={0}
        >
            <div>
                <MemberListItem {...memberItem}></MemberListItem>
            </div>
            <div>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <MenuButton
                            shape="square"
                            appearance="transparent"
                            aria-label={t('RoleAssignment.AddRoleMembers.AriaLabels.SelectRole', {
                                0: selectedRole,
                            })}
                        >
                            {selectedRole}
                        </MenuButton>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            {availableRoles?.map((role) => {
                                return (
                                    <MenuItem key={role} onClick={() => handleRoleChange(role)}>
                                        {role}
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <Button
                    data-test-id="delete-member-button"
                    appearance="transparent"
                    aria-label={t('RoleAssignment.AddRoleMembers.AriaLabels.DeleteMemberButton')}
                    onClick={() => props.onMemberRemoved(memberItem.key)}
                    icon={<DismissIcon />}
                />
            </div>
        </div>
    );
}
