import {useMutation} from '@tanstack/react-query';
import {FIDELIS_ERROR_CODES, RESOURCE_SCOPES} from '../api.constants';
import {ProvisionAccountRequest, ProvisionAccountResponse} from './accounts.types';
import useFetch from '../useFetch';
import {CustomFetchOptions, FidelisApiError, FidelisApiErrorModel, FidelisErrorType} from '../api';
import MedeinaFeatures from '@/util/features';
import securityGraphApi from '@/api/securityGraph.api';

const FIDELIS_HTTP_CODES_TO_HANDLE = new Set([400, 409]);

const API_URL = securityGraphApi({path: '/provisioning/create', isGlobal: true});

export default function useProvisionAccount(tenantId: string) {
    const {customFetch} = useFetch();

    const provisionAccount = async (request: ProvisionAccountRequest | null): Promise<Response> => {
        let requestBody: {
            geoName?: string;
        } = {};

        const headers = {'x-ms-client-tenant': tenantId};

        let fetchOptions: CustomFetchOptions = {
            method: 'POST',
            scopes: RESOURCE_SCOPES.FIDELIS,
            headersFromOptions: headers,
        };

        if (Boolean(request?.configuredGeo)) {
            requestBody.geoName = request?.configuredGeo;
        }

        if (MedeinaFeatures.EnableGeoSelection) {
            fetchOptions = {
                ...fetchOptions,
                body: JSON.stringify(requestBody),
            };
        }

        return await customFetch<Response>(API_URL, fetchOptions, true);
    };

    return useMutation({
        mutationFn: async (request: ProvisionAccountRequest | null) => {
            const response = await provisionAccount(request);

            if (!response.ok) {
                if (FIDELIS_HTTP_CODES_TO_HANDLE.has(response.status)) {
                    try {
                        var errorContent = (await response.json()) as FidelisApiErrorModel;
                    } catch (error) {
                        // This is for the case where the error is not a JSON object
                        throw new Error(`Failed to get account: ${response.statusText}`);
                    }

                    if (response.status === 400) {
                        if (
                            errorContent?.error?.code ===
                            String(FIDELIS_ERROR_CODES.INVALID_GEO_FOR_ACCOUNT)
                        ) {
                            // If the Fidelis error code is 3000 for http code 404 , the Geo submitted is invalid
                            throw new FidelisApiError(
                                errorContent?.error?.message,
                                response,
                                FidelisErrorType.GEO_INVALID_ACCOUNT,
                            );
                        } else if (
                            errorContent?.error?.code ===
                            String(FIDELIS_ERROR_CODES.INVALID_GEO_FOR_ENV)
                        ) {
                            // If the Fidelis error code is 3001 for http code 404 , the Geo submitted is invalid for the environment
                            throw new FidelisApiError(
                                errorContent?.error?.message,
                                response,
                                FidelisErrorType.GEO_INVALID_FOR_ENV,
                            );
                        }
                    } else if (response.status === 409) {
                        if (
                            errorContent?.error?.code ===
                            String(FIDELIS_ERROR_CODES.ACCOUNT_CONFIGURED_IN_DIFFERENT_GEO)
                        ) {
                            // If the Fidelis error code is 3002 for http code 409 , the account is already configured to another geo in the environment
                            throw new FidelisApiError(
                                errorContent?.error?.message,
                                response,
                                FidelisErrorType.ACCOUNT_CONFIGURED_IN_DIFFERENT_GEO,
                            );
                        }
                    }
                }

                // This is for the fallback case where the error is not a FidelisApiError
                throw new Error(`Failed to get account: ${response.statusText}`);
            }

            const data = await response.json();
            return data as ProvisionAccountResponse;
        },
        retry: 0,
    });
}
