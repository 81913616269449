import { makeResetStyles, mergeClasses, tokens, typographyStyles } from '@fluentui/react-components';
import type { ChatInputTokenSlots, ChatInputTokenState } from './ChatInputToken.types';
import type { SlotClassNames } from '@fluentui/react-components';

export const chatInputTokenClassNames: SlotClassNames<ChatInputTokenSlots> = {
  root: 'fai-ChatInputToken',
};

/**
 * Styles for the root slot
 */
const useBaseClassName = makeResetStyles({
  ...typographyStyles.caption1,
  lineHeight: `calc(${tokens.lineHeightBase200} + (2 * ${tokens.spacingVerticalXXS}) + (2 * ${tokens.strokeWidthThin}))`,
  backgroundColor: tokens.colorNeutralBackground3,
  border: `${tokens.strokeWidthThin} solid ${tokens.colorTransparentStroke}`,
  borderRadius: tokens.borderRadiusMedium,
  boxDecorationBreak: 'clone',
  color: tokens.colorNeutralForeground2,
  padding: `${tokens.spacingVerticalXXS} 0`,
});

/**
 * Apply styling to the ChatInputToken slots based on the state
 */
export const useChatInputTokenStyles_unstable = (state: ChatInputTokenState): ChatInputTokenState => {
  'use no memo';

  const baseClassName = useBaseClassName();
  state.root.className = mergeClasses(chatInputTokenClassNames.root, baseClassName, state.root.className);

  return state;
};
