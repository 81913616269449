import {useGetSupportedGeos} from '@/api/location';
import {useEffect, useMemo} from 'react';

interface GetLocationSelectionData {
    onSupportedGeosFetchError: () => void;
}

export interface GetLocationSelectionDataResponse {
    isDataLoading: boolean;
    locationItems:
        | {
              key: string;
              value: string;
          }[]
        | undefined;
}

export default function useGetLocationSelectionData(
    props: GetLocationSelectionData,
): GetLocationSelectionDataResponse {
    const {
        data: supportedGeos,
        isLoading: isSupportedGeosLoading,
        isError: isSupportedGeosError,
    } = useGetSupportedGeos();

    const locationItems = useMemo(
        () =>
            supportedGeos?.supportedGeos
                ?.map((geo) => ({
                    key: geo,
                    value: geo,
                }))
                ?.sort((itemA, itemB) =>
                    itemA.value.localeCompare(itemB.value, undefined, {
                        numeric: true,
                    }),
                ),
        [supportedGeos],
    );

    useEffect(() => {
        if (isSupportedGeosError) {
            props.onSupportedGeosFetchError();
        }
    }, [isSupportedGeosError]);

    const isDataLoading = isSupportedGeosLoading;

    return {
        isDataLoading,
        locationItems,
    };
}
