import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CreateSessionWithPromptbookRequest, SourceType} from './sessions.types';
import useCreateSession from './useCreateSession';
import useApplyPromptbook from '../promptbooks/useApplyPromptbook';

export default function useCreateSessionWithPromptbook() {
    const queryClient = useQueryClient();
    const {mutateAsync: createSession} = useCreateSession();
    const {mutateAsync: applyPromptbook} = useApplyPromptbook();

    return useMutation({
        mutationFn: async (body: CreateSessionWithPromptbookRequest) => {
            const {promptbook: promptbookBody, ...sessionBody} = body;
            const session = await createSession({
                ...sessionBody,
                name: sessionBody.name ?? sessionBody.content ?? '',
                source: SourceType.Immersive,
            });

            if (!session.name) {
                throw new Error('Session name cannot be empty');
            }

            // applying promptbook for the newly created session.
            if (session && promptbookBody) {
                await applyPromptbook({
                    sessionId: session.sessionId,
                    promptbookId: promptbookBody?.promptbookId,
                    inputs: promptbookBody?.inputs,
                    overriddenPrompts: promptbookBody?.overriddenPrompts,
                    source: SourceType.Immersive,
                });
            }

            return session;
        },

        onSuccess: () => {
            queryClient.invalidateQueries(['sessions']);
        },
    });
}
