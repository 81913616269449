import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        maxWidth: '80vw',
        minWidth: '30vw',
        ...shorthands.padding('0px'),
        ...shorthands.borderRadius('0px'),
        ...shorthands.border('0px'),
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'none',
    },

    dismissSection: {
        color: tokens.colorNeutralForeground2,
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },

    title: {
        backgroundColor: tokens.colorNeutralBackground2,
        ...shorthands.padding('20px', '17px', '17px', '17px'),
        gridColumnStart: '1',
        gridColumnEnd: '-1',
    },

    bodyStyles: {
        rowGap: '0px',
        maxHeight: '80vh',
        width: 'fit-content',
        display: 'grid',
    },

    contentTable: {
        ...shorthands.margin('0px', '17px', '2.5px', '17px'),
        minWidth: '250px',
        width: 'max-content',

        '& td': {
            minWidth: '10vw',
            maxWidth: '40vw',
        },
    },

    backdrop: {
        backgroundColor: 'rgba(9, 10, 11, 0.9)',
        display: 'flex',
        width: '100%',
        height: '100%',
    },

    contentStyles: {
        gridColumnStart: '1',
        gridColumnEnd: '-1',
    },

    excelButtonStyles: {
        marginRight: `${tokens.spacingHorizontalM} !important`,
    },
});
