import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        width: '350px',
        '& > .fui-Field': {
            paddingBottom: tokens.spacingVerticalL,
        },
        overflowY: 'auto', // Scroll for dialog surface
    },
    title: {
        paddingBottom: tokens.spacingVerticalXXL,
    },
    actions: {
        paddingTop: tokens.spacingVerticalXXL,
        justifyContent: 'flex-end',
    },
    calendar: {
        maxHeight: '40vh', // Enable scroll for both date picker calendars
        overflowY: 'auto',
    },
});
