import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import baseStyle from '@/components/theme/custom/overrides';

export default makeStyles({
    root: {
        color: tokens.colorNeutralForeground1,

        '& tbody > tr:last-child': {
            borderBottomWidth: '0px',
        },
    },
    row: {
        borderBottomWidth: '1px',
        borderBottomColor: tokens.colorNeutralStroke3,
        borderBottomStyle: 'solid',

        '& td': {
            ...shorthands.padding('5px'),
            fontSize: baseStyle.fontSizeBase300,
            fontWeight: tokens.fontWeightRegular,
            color: tokens.colorNeutralForeground3,
            lineHeight: '22px',
            borderLeftWidth: '0px',
            borderRightWidth: '0px',
        },
        '& th': {
            ...shorthands.padding('0px', '5px', '5px', '5px'),
            fontSize: baseStyle.fontSizeBase300,
            fontWeight: tokens.fontWeightSemibold,
            color: tokens.colorNeutralForeground3,
            lineHeight: '22px',
        },
    },
    rowHover: {
        backgroundColor: tokens.colorNeutralBackground5,
    },
    rowHeight: {
        height: '40px',
    },
    fitContent: {
        width: 'fit-content',
    },
    noDataStyles: {
        paddingBottom: tokens.spacingVerticalMNudge,
    },
});
