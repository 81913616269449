import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    infoContent: {
        maxWidth: '420px',
    },
    infoLabel: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});
