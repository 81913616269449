import React, {useCallback, useEffect, useState, useRef} from 'react';
import {
    useGetSkillsets,
    useCheckRequirements,
    SkillsetCategory,
    SkillsetDescriptor,
    SkillsetFilterTypes,
    SettingsScope,
} from '@/api/skills';
import {usePatchSettings, ScopedSettings} from '@/api/settings';
import useSkillsetSearch from '@/components/ui/ConnectorModal/useSkillsetSearch';
import useConnectorClasses from '@/components/ui/ConnectorModal/ConnectorModalContent.styles';
import {PluginsTabContentProps} from './ManageSources.types';
import {useTourContext} from '@/components/ui/Tour';
import {categoryConnectorMappings} from '@/components/ui/ConnectorModal/ConnectorModalContent/Utils/ConnectorModalContent.types';
import {
    EmptySearchComponent,
    ConnectorCategoryEntries,
} from '@/components/ui/ConnectorModal/ConnectorModalContent/index';
import {useTranslation} from 'react-i18next';
import {Text, Link, mergeClasses} from '@fluentui/react-components';
import {FilterButtonsComponent} from '@/components/ui/ConnectorModal/ConnectorModalContent/index';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';

export default function PluginsTabContent({
    defaultSelectedSkillsets,
    onChangeSkillsets,
    onOpenAdminView,
    onOpenExternalConnectorView,
    skillsetToEnable,
    canHighlightAddPlugin,
}: PluginsTabContentProps) {
    const connectorClasses = useConnectorClasses();
    const scrollClasses = useScrollClasses();
    const {t} = useTranslation('plugins');
    const adminTourScrollRef = React.useRef<HTMLDivElement | null>(null);
    const {activeTour, currentStop} = useTourContext();

    const {data: skillsets} = useGetSkillsets();
    const {data: requirementsCheck} = useCheckRequirements();
    const {mutate: sendSettings} = usePatchSettings();

    const [displayedSkillsetFilter, setDisplayedSkillsetFilter] = useState(SkillsetFilterTypes.All);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedSwitchId, setSelectedSwitchId] = useState<string | undefined>(undefined);
    const switchRefs = useRef<{[keyId: string]: HTMLInputElement | null}>({});
    const [newSkillsetToEnable, setNewSkillsetToEnable] = useState<string>(skillsetToEnable || '');

    const addPluginButtonRef = React.useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const triggerElement = addPluginButtonRef.current;
        if (canHighlightAddPlugin && triggerElement) {
            triggerElement.scrollIntoView({behavior: 'smooth'});
            triggerElement.focus();
        }
    }, [canHighlightAddPlugin, addPluginButtonRef]);

    useEffect(() => {
        const scrollableElement = adminTourScrollRef.current;
        if (activeTour && scrollableElement) {
            switch (currentStop?.name) {
                case 'openModal':
                    scrollableElement.scrollTop = 0;
                    break;
                case 'setUpPlugin':
                    scrollableElement.scrollTop = 625;
                    break;
                case 'addPlugin':
                    scrollableElement.scrollTop = 850;
                    break;
                default:
                    break;
            }
        }
    }, [activeTour, currentStop]);

    // Use the user overrides if present.
    const defaultSelected = React.useMemo<Set<string> | undefined>(() => {
        var defaultSkillsets = new Set<string>();
        if (defaultSelectedSkillsets && defaultSelectedSkillsets.length > 0) {
            defaultSkillsets = new Set(defaultSelectedSkillsets);
        }
        // Otherwise rely on the enabled flags.
        else if (skillsets?.value) {
            defaultSkillsets = new Set(
                skillsets.value
                    .filter(
                        (skillset) => skillset.enabled && skillset.category.toString() !== 'Hidden',
                    )
                    .map((skillset: {name: string}) => skillset.name),
            );
        }
        if (
            !defaultSkillsets.has(newSkillsetToEnable) &&
            newSkillsetToEnable !== '' &&
            requirementsCheck?.value
        ) {
            const checkResult = requirementsCheck?.value
                ? requirementsCheck?.value?.find(
                      (entry) => entry.skillsetName === newSkillsetToEnable,
                  )
                : null;
            // only add the skillset if it doesn't have any settings to configure
            if (
                checkResult?.errors?.length == 0 &&
                skillsets?.value.find((s: {name: any}) => s.name == checkResult.skillsetName)
            ) {
                defaultSkillsets.add(newSkillsetToEnable);

                const settings: ScopedSettings = {
                    enabledSkillsets: Array.from(defaultSkillsets.values()),
                    scope: SettingsScope.User,
                };
                sendSettings(settings);

                onChangeSkillsets?.(Array.from(defaultSkillsets.values()));
            }
        }
        return defaultSkillsets;
    }, [
        defaultSelectedSkillsets,
        skillsets?.value,
        newSkillsetToEnable,
        requirementsCheck?.value,
        onChangeSkillsets,
        sendSettings,
    ]);

    // Determine the skillsets that should be displayed in the connector modal
    const visibleSkillsets = React.useMemo<SkillsetDescriptor[]>(() => {
        const defaultSearchable: SkillsetDescriptor[] =
            skillsets?.value.filter(
                (skillset: {category: string}) => skillset.category !== 'Hidden',
            ) ?? [];
        return defaultSearchable;
    }, [skillsets?.value]);

    // Determine the skillsets that can be edited/deleted or have configurable settings/auth settings
    const configurableSkillsets = React.useMemo<Set<string> | undefined>(() => {
        var defaultConfigurable = new Set<string>();

        // Add all connectors that can be edited and deleted
        Object.keys(categoryConnectorMappings).map((category) =>
            skillsets?.value
                .filter((skillset: {category: string}) => skillset.category == category)
                .map((skillset: {name: string}) => defaultConfigurable.add(skillset.name)),
        );

        skillsets?.value.map(
            (skillset) =>
                ((skillset?.settings?.length && skillset?.settings?.length > 0) ||
                    (skillset?.supportedAuthTypes?.length &&
                        skillset?.supportedAuthTypes?.length > 0)) &&
                defaultConfigurable.add(skillset.name),
        );
        return defaultConfigurable;
    }, [skillsets?.value]);

    const [selected, setSelected] = useState<Set<string>>(new Set(defaultSelected ?? []));
    const [selectable, setSelectable] = useState<Set<string>>(new Set());
    // Skillsets where checkRequirements returns no errors
    const [configured, setConfigured] = useState<Set<string>>(new Set());

    React.useEffect(() => {
        setSelected(new Set(defaultSelected ?? []));
    }, [defaultSelected]);

    // Focus the switch of the skillset that was just enabled/disabled.
    // Ensures focus returns to the switch after the skillset patch api returns.
    useEffect(() => {
        if (selectedSwitchId) {
            const switchElement = switchRefs.current[selectedSwitchId];

            if (switchElement) {
                switchElement.focus();
                setSelectedSwitchId(undefined);
            }
        }
    }, [selected, selectedSwitchId]);

    React.useEffect(() => {
        // set skillsets that pass requirements check or don't need to be configured
        var configured = new Set<string>();
        var selectable = new Set<string>();
        for (var entry of requirementsCheck?.value ?? []) {
            if (
                (configurableSkillsets as Set<string>).has(entry.skillsetName) &&
                entry.errors?.length == 0 &&
                skillsets?.value.find((s: {name: any}) => s.name == entry.skillsetName)
            ) {
                configured.add(entry.skillsetName);
                selectable.add(entry.skillsetName);
            }
        }
        setConfigured(configured);

        // set skillsets that don't need to be configured or have already been configured
        skillsets?.value.map(
            (skillset) =>
                !skillset?.settings?.length &&
                !skillset?.supportedAuthTypes?.length &&
                skillset?.category.toString() !== 'Hidden' &&
                selectable.add(skillset.name),
        );
        setSelectable(selectable);
    }, [configurableSkillsets, requirementsCheck?.value, skillsets?.value]);

    const visibleSkillsetSearchResults: SkillsetDescriptor[] = useSkillsetSearch(
        visibleSkillsets,
        searchText,
    );

    const skillsetCounts = React.useMemo(() => {
        return {
            total: visibleSkillsets.length,
            enabled: selected.size,
            disabled:
                visibleSkillsets.length - selected.size > 0
                    ? visibleSkillsets.length - selected.size
                    : 0,
            searchResults: visibleSkillsetSearchResults.length,
        };
    }, [visibleSkillsets, selected, visibleSkillsetSearchResults]);

    // Update list of selected skillsets when a skillset is toggled
    const onChangeHandler = (ev: any) => {
        setSelectedSwitchId(ev.currentTarget.id);

        // Create a new Set to store the updated selected skillsets
        const newSelected = new Set(selected);

        const targetSkillsetName = ev.currentTarget.name;
        if (ev.currentTarget.checked) {
            // Add the skillset to the newSelected Set if it's not already selected
            if (!selected.has(targetSkillsetName)) {
                newSelected.add(targetSkillsetName);
            }
        } else {
            // Remove the skillset from the newSelected Set
            newSelected.delete(targetSkillsetName);

            // Clear `newSkillsetToEnable` if it is being disabled
            if (targetSkillsetName === newSkillsetToEnable) {
                setNewSkillsetToEnable('');
            }
        }

        setSelected(newSelected);
        onChangeSkillsets?.(Array.from(newSelected.values()));

        const settings: ScopedSettings = {
            enabledSkillsets: Array.from(newSelected.values()),
            scope: SettingsScope.User,
        };
        sendSettings(settings);
    };

    // Update list of selected skillsets when a category is toggled
    const onCategoryChangeHandler = (category: string, checked: boolean) => {
        var newSelected = selected;

        if (checked) {
            skillsets?.value
                .filter(
                    (skillset: {category: string; canToggle?: boolean}) =>
                        skillset?.canToggle && skillset.category == category,
                )
                .map((skillset: {name: string}) =>
                    !selected.has(skillset.name) && selectable.has(skillset.name)
                        ? newSelected.add(skillset.name)
                        : null,
                );
        } else {
            skillsets?.value
                .filter((skillset: {category: string}) => skillset.category == category)
                .map((skillset: {name: string}) => newSelected.delete(skillset.name));
        }
        setSelected(newSelected);
        onChangeSkillsets?.(Array.from(selected.values()));

        const settings: ScopedSettings = {
            enabledSkillsets: Array.from(selected.values()),
            scope: SettingsScope.User,
        };
        sendSettings(settings);
    };

    const setDisplay = (ev: any) => {
        setShowSearchBar(false);
        setSearchText('');
        setDisplayedSkillsetFilter(ev.currentTarget.name);
    };

    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
    };

    const handleOpenExternalView = (
        type: string,
        skillsetDescriptor: SkillsetDescriptor | null,
        showDelete: boolean,
    ) => {
        onOpenExternalConnectorView?.(type, skillsetDescriptor, showDelete);
    };

    // Set the text to be announced by screen reader when updated filtered search results are displayed.
    const getSearchResultsAriaLiveText = useCallback((): string => {
        // If no search term is entered, do not announce search results.
        if (searchText == '') {
            return '';
        }

        // If a search term is entered, announce the number of results found.
        if (skillsetCounts.searchResults == 1) {
            return t('SearchResultSingle');
        } else {
            return t('SearchResultMultiple', {count: skillsetCounts.searchResults});
        }
    }, [searchText, skillsetCounts.searchResults, t]);

    const title = t('ManagePlugins');

    const noSearchResults = skillsetCounts.searchResults === 0;
    const noSkillsetsFilterOn =
        displayedSkillsetFilter === SkillsetFilterTypes.On && skillsetCounts.enabled === 0;
    const noSkillsetsFilterOff =
        displayedSkillsetFilter === SkillsetFilterTypes.Off && skillsetCounts.disabled === 0;

    return (
        <>
            <div className={connectorClasses.stickyTop}>
                <div className={connectorClasses.popoverTop}>
                    <Text className={mergeClasses(connectorClasses.popoverTopText)} as="h2">
                        {title}
                    </Text>
                </div>

                <div>
                    <div className={connectorClasses.subTextWrapper}>
                        <Text className={connectorClasses.subText}>
                            {t('PluginsTabDescription')}
                            <Link
                                href="https://learn.microsoft.com/en-us/security-copilot/manage-plugins?tabs=securitycopilotplugin"
                                target="_blank"
                            >
                                {t('PluginsTabLearnMoreLink')}
                            </Link>
                        </Text>
                    </div>

                    {skillsets && (
                        <FilterButtonsComponent
                            searchText={searchText}
                            showSearchBar={showSearchBar}
                            onSearchIconClick={() => setShowSearchBar(true)}
                            onSearchTextChange={handleSearch}
                            onClearSearch={() => {
                                handleSearch('');
                                setShowSearchBar(false);
                            }}
                            enabledCount={skillsetCounts.enabled}
                            disabledCount={skillsetCounts.disabled}
                            totalCount={skillsetCounts.total}
                            displayedSkillsetsFilter={displayedSkillsetFilter}
                            onFilterButtonClick={setDisplay}
                        />
                    )}
                </div>
            </div>
            <div
                className={scrollClasses.colorNeutralBackground1}
                ref={adminTourScrollRef}
                data-testid="tabbed-dialog-plugins-dialog-content"
            >
                <div>
                    {noSearchResults || noSkillsetsFilterOn || noSkillsetsFilterOff ? (
                        <EmptySearchComponent
                            isUserSearching={searchText !== ''}
                            displayedSkillsetsFilter={displayedSkillsetFilter}
                            onAddPluginClick={() =>
                                handleOpenExternalView(SkillsetCategory.Plugin, null, false)
                            }
                            addPluginButtonRef={addPluginButtonRef}
                        />
                    ) : (
                        <ConnectorCategoryEntries
                            visibleSkillsets={visibleSkillsetSearchResults}
                            selectedSkillsets={selected}
                            configurableSkillsets={configurableSkillsets}
                            configuredSkillsets={configured}
                            displayedSkillsetFilter={displayedSkillsetFilter}
                            onOpenAdminView={onOpenAdminView}
                            onOpenExternalView={handleOpenExternalView}
                            onCategoryChangeHandler={onCategoryChangeHandler}
                            onSwitchChange={onChangeHandler}
                            addPluginButtonRef={addPluginButtonRef}
                            switchRefs={switchRefs}
                        />
                    )}
                    <div className={connectorClasses.visuallyHidden}>
                        <div aria-live="polite">{getSearchResultsAriaLiveText()}</div>
                    </div>
                </div>
            </div>
        </>
    );
}
