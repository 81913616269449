import {Subtitle1, Subtitle2, Text} from '@fluentui/react-components';
import {PromptContentLabelProps} from './PromptContent.types';
import useClasses from './PromptContent.styles';
import {useEffect, useState} from 'react';
import {PromptType} from '@/api/prompts';
import {SkillDescriptor, useGetAllSkills} from '@/api/skills';
import {SkillIcon} from '@/components/ui/icons';

export default function PromptContentSkillLabel({
    prompt,
    pinned,
    className,
}: PromptContentLabelProps) {
    const classes = useClasses();
    // TODO: Remove pinning-specific styling after updating to MS Secure styles.
    const SkillComponent = pinned ? Text : Subtitle1;
    const InputComponent = pinned ? Text : Subtitle2;

    // Retrieve skill metadata.
    const [skill, setSkill] = useState<SkillDescriptor | null>(null);
    const {data: allSkills} = useGetAllSkills({includeHidden: true});
    useEffect(() => {
        if (
            prompt?.promptType &&
            [PromptType.Skill, PromptType.SkillDirect].includes(prompt.promptType) &&
            allSkills?.value
        ) {
            setSkill(allSkills.value.find((skill) => skill.name === prompt.skillName) ?? null);
        }
    }, [prompt, allSkills?.value]);

    return (
        <div className={className ?? classes.content}>
            <SkillComponent className={classes.contentText}>
                <SkillIcon className={classes.skillIcon} />{' '}
                {skill?.displayName ?? skill?.name ?? prompt?.skillName}
            </SkillComponent>
            <div>
                <InputComponent className={classes.promptTitle}>
                    {Object.entries(prompt?.inputs ?? {}).map(
                        ([name, value]) => ` ${name}:${value}`,
                    )}
                </InputComponent>
            </div>
        </div>
    );
}
