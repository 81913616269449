import {useForbiddenUserFriendlyMessage} from '@/util/forbiddenMessageMappings';
import useClasses from './Forbidden.styles';
import {WarningRegular} from '@fluentui/react-icons';
import {BackgroundImage} from '@/components/ui/Background';
import {useEffect} from 'react';
import {useLayout} from '../ui/Layout';
import {mergeClasses} from '@griffel/react';
import {Button} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

interface ForbiddenProps {
    message?: string;
}

export default function Forbidden({message}: ForbiddenProps = {}) {
    const classes = useClasses();

    const {heading, subHeading, actionButtonProps} = useForbiddenUserFriendlyMessage(message ?? '');
    const {background} = useLayout();

    const {t} = useTranslation('common');

    useEffect(() => {
        background?.setImage?.(BackgroundImage.triangles);
        // run only when component loads
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <div className={mergeClasses(classes.centeredContent, classes.centeredBlock)}>
                <WarningRegular className={classes.warningLogo} />
                <h1
                    aria-live="assertive"
                    className={classes.field}
                    data-test-id="unauthorizedRoleAccess"
                >
                    {t(heading)}
                </h1>
                <h2 className={classes.field} data-test-id="providedSolutionMessage">
                    {!!subHeading && t(subHeading)}
                </h2>
                {!!actionButtonProps && (
                    <div>
                        <Button {...actionButtonProps} appearance="primary">
                            {t(actionButtonProps.children as string)}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
