import {Popover, PopoverSurface, PopoverTrigger} from '@fluentui/react-components';
import useClasses from './GraphvizDotCodeBlock.styles';
import useGraphvizRenderer from './useGraphvizRenderer';
import {useCallback, useRef} from 'react';

export default function GraphvizDotCodeBlock({children}: any) {
    const classes = useClasses();
    const popoverSurfaceRef = useRef<HTMLDivElement>(null);

    const performGraphSelectionActions = useCallback(() => {
        if (popoverSurfaceRef.current) {
            setTimeout(() => {
                popoverSurfaceRef?.current?.focus();
            }, 0);
        }
    }, [popoverSurfaceRef]);

    const renderer = useGraphvizRenderer(children, performGraphSelectionActions);

    return (
        <div className={classes.root}>
            <Popover withArrow>
                <PopoverTrigger disableButtonEnhancement>
                    <div {...renderer} className={classes.svg} />
                </PopoverTrigger>
                <PopoverSurface>
                    <p ref={popoverSurfaceRef} tabIndex={0} className={classes.popoverContent}>
                        Use Ctrl and Arrow keys for panning, &apos;+&apos; key for zoom in and
                        &apos;-&apos; key for zoom out.
                        <br />
                        Use mouse scroll for zooming in and out.
                    </p>
                </PopoverSurface>
            </Popover>
        </div>
    );
}
