import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {
    Combobox,
    OptionGroup,
    mergeClasses,
    useFocusFinders,
    Option,
} from '@fluentui/react-components';
import {motion} from 'framer-motion';
import {useEffect, useRef, useState} from 'react';
import useAppClasses from '@/components/App.styles';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useClasses from './LocationSelection.styles';
import {EarthIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import useGetLocationSelectionData from './useGetLocationSelectionData';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from '../AdminTour.types';
import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {Body, TextH1} from '@/components/ui/Text';

/*
This component is used to capture the Location for a tenant that will serve as the `configuredGeo`
for the Fidelis Account. A set of selected geos is provided to the user on the basis of the logged in
tenant. The user can select one of the geos from the dropdown and choose to continue ahead. During
which the selected geo will be saved as the `configuredGeo` for the account.
*/ import {useSetupAndProvisionFidelisConstructs} from '@/api/capacities';
import {useManageTenantInfo} from '@/api/tenant';
import useManageTourTransition from '../useManageTourTransition';
import SetupInProgress from '../SetupInprogress';

export default function LocationSelection() {
    const locationSelectionRootRef = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();

    const appStyles = useAppClasses();
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const {setTourErrorV2, setTourStep} = useTourContext();

    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');

    const [locationSelection, setLocationSelection] = useState<string>('');
    const [isDataProcessing, setIsDataProcessing] = useState(false);

    const {locationItems, isDataLoading} = useGetLocationSelectionData({
        onSupportedGeosFetchError: () => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        },
    });

    const {provisionAccountAndWorkspaceAsync} = useSetupAndProvisionFidelisConstructs({
        shouldPreLoadWorkspace: false,

        //API response handlers
        onWorkspaceProvisionError: () => {
            setIsDataProcessing(false);
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        },
        onAccountCreationError: () => {
            setIsDataProcessing(false);
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        },
        onInitialAccountAndWorkspaceProvisioningSuccess: async () => {
            // COntinuation of the provisioning process post account and workspace validation and creation
            await actionsAfterAccountSetup();
        },
    });

    const {initializeTenantFreInfo} = useManageTenantInfo();
    const {getStepAfterLocationSelection} = useManageTourTransition();

    useEffect(() => {
        if (locationSelectionRootRef?.current) {
            const firstFocusable = findFirstFocusable(locationSelectionRootRef.current);
            firstFocusable?.focus();
        }
    }, [locationSelectionRootRef]);

    const handleBackClick = (): void => {
        setTourStep(TOUR_STEPS.Welcome);
    };

    const handleContinueClick = async () => {
        setIsDataProcessing(true);
        await provisionAccountAndWorkspaceAsync({
            selectedGeo: locationSelection,
        });
    };

    const actionsAfterAccountSetup = async () => {
        try {
            initializeTenantFreInfo();

            const nextStep = await getStepAfterLocationSelection();
            setTourStep(nextStep);
        } catch (error) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        } finally {
            setIsDataProcessing(false);
        }
    };

    const isFormFilled = Boolean(locationSelection);

    return (
        <>
            {!isDataLoading && (
                <motion.div
                    key="location-selection"
                    variants={overlayAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <TourStageContainer ref={locationSelectionRootRef}>
                        <TourStageContainer.StageContent>
                            {isDataProcessing && (
                                <SetupInProgress
                                    heading={t('adminV2Fre.locationSelection.SetupInProgress')}
                                    subHeading={t('adminV2Fre.locationSelection.Polite')}
                                ></SetupInProgress>
                            )}
                            {!isDataProcessing && (
                                <>
                                    <div className={classes.icon}>
                                        <EarthIcon></EarthIcon>
                                    </div>
                                    <div className={tourClasses.tourSectionSpacing}>
                                        <TextH1>{t('adminV2Fre.locationSelection.Title')}</TextH1>
                                    </div>
                                    <div className={tourClasses.tourLargeSectionSpacing}>
                                        <Body>
                                            {t('adminV2Fre.locationSelection.SupplementaryContent')}
                                        </Body>
                                    </div>
                                    <div className={classes.dropDownContainer}>
                                        <Combobox
                                            className={mergeClasses(tourClasses.dropDown)}
                                            placeholder={t(
                                                'adminV2Fre.locationSelection.DataStorageLocationPlaceholder',
                                            )}
                                            aria-label={t(
                                                'adminV2Fre.locationSelection.DataStorageLocation',
                                            )}
                                            data-test-id="location-dropdown"
                                            onOptionSelect={(_, data) => {
                                                setLocationSelection(data.optionValue ?? '');
                                            }}
                                            value={locationSelection}
                                            selectedOptions={
                                                locationSelection ? [locationSelection] : []
                                            }
                                            listbox={{className: appStyles.listBoxHeight}}
                                        >
                                            <OptionGroup>
                                                {locationItems?.map(({key, value}: any) => {
                                                    return (
                                                        <Option key={key} value={value}>
                                                            {value}
                                                        </Option>
                                                    );
                                                })}
                                            </OptionGroup>
                                        </Combobox>
                                    </div>
                                </>
                            )}
                        </TourStageContainer.StageContent>
                        <TourStageContainer.FooterContent>
                            {!isDataProcessing && (
                                <TourControls
                                    spanFullScreen={true}
                                    previousButton={commonLabel('Back')}
                                    handlePrevious={handleBackClick}
                                    nextButton={commonLabel('Continue')}
                                    handleNext={handleContinueClick}
                                    isNextButtonDisabled={!isFormFilled}
                                ></TourControls>
                            )}
                        </TourStageContainer.FooterContent>
                    </TourStageContainer>
                </motion.div>
            )}
        </>
    );
}
