import React, {useState} from 'react';
import useClasses from './ErrorBoundary.styles';
import {FallbackProps} from './ErrorBoundary.types';
import {Background, BackgroundImage} from '../Background';
import {WarningIcon} from '../icons';
import {Button, Text, mergeClasses} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {UpIcon, DownIcon} from '../icons';

function ViewErrorBoundary(props: FallbackProps) {
    const {showErrorDetails, error, errorInfo} = props;
    const [showError, setShowError] = useState(false);
    const classes = useClasses();
    const {t} = useTranslation('common');

    return (
        <div className={classes.root}>
            <Background image={BackgroundImage.triangles} />
            <div
                className={mergeClasses(classes.centeredBlock, classes.centered)}
                aria-live="polite"
            >
                {!showError && (
                    <>
                        <WarningIcon className={classes.icon} aria-hidden />
                        <Text className={classes.title}>{t('ErrorBoundary.errorTitle')}</Text>
                        <Text className={classes.message}>
                            {t('ErrorBoundary.errorDescription')}
                        </Text>
                    </>
                )}
                {showErrorDetails && (
                    <>
                        <Button
                            appearance="transparent"
                            onClick={() => setShowError(!showError)}
                            icon={showError ? <UpIcon /> : <DownIcon />}
                            iconPosition="after"
                        >
                            {!showError
                                ? t('ErrorBoundary.showFullError')
                                : t('ErrorBoundary.hideFullError')}
                        </Button>

                        {showError && (
                            <div className={classes.errorMessage}>
                                <div>{error?.toString()}</div>
                                <div>{errorInfo && errorInfo.componentStack}</div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default ViewErrorBoundary;
