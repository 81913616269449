import React from 'react';
import {Label, Input, useId, mergeClasses} from '@fluentui/react-components';
import useClasses from '../Setup.styles';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';

import {useTranslation} from 'react-i18next';

interface CapacityNameProps {
    setCapacityName: (value: string) => void;
    capacityName: string;
    labelSize?: 'small' | 'medium';
}

export const CapacityNameField = ({
    setCapacityName,
    capacityName,
    labelSize = 'medium',
}: CapacityNameProps) => {
    const classes = useClasses();
    const tourClasses = useTourClasses();
    const {t} = useTranslation('tours');
    const labelId = useId('capacity-name');

    return (
        <>
            <Input
                className={mergeClasses(classes.textFieldStyle, tourClasses.textBox)}
                required
                type="text"
                placeholder={t('adminV2Fre.setup.DefaultSuggestion')}
                aria-label={t('adminV2Fre.setup.ariaLabel.CapacityName')}
                aria-describedby={labelId}
                data-testid="capacity-name-input"
                value={capacityName}
                onChange={(event) => {
                    setCapacityName(event.target.value);
                }}
            />
            <Label id={labelId} className={classes.infoLabel} size={labelSize}>
                {t('adminV2Fre.setup.CapacityInfo')}
            </Label>
        </>
    );
};

export default CapacityNameField;
