import * as React from 'react';
const GoalNet = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <g fillRule="evenodd" clipRule="evenodd" filter="url(#f607id7)">
                <path
                    fill="url(#f607id0)"
                    d="M9 10.425c.318 0 .575.257.575.575v4.275c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15V11a.575.575 0 0 1 1.15 0v4.275c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15V11a.575.575 0 0 1 1.15 0v4.584a.15.15 0 0 0 .074.13l5.64 3.29a.575.575 0 1 1-.579.993l-4.91-2.864a.15.15 0 0 0-.225.13v3.32a.15.15 0 0 0 .074.13l5.64 3.29a.575.575 0 1 1-.579.994l-4.91-2.864a.15.15 0 0 0-.225.13v3.32a.15.15 0 0 0 .074.13l5.64 3.29a.575.575 0 1 1-.579.994l-5.83-3.402a.15.15 0 0 0-.076-.02H9.196a.15.15 0 0 0-.076.02l-5.83 3.402a.575.575 0 0 1-.58-.994l5.64-3.29a.15.15 0 0 0 .075-.13v-3.32a.15.15 0 0 0-.226-.13l-4.91 2.864a.575.575 0 0 1-.579-.994l5.64-3.29a.15.15 0 0 0 .075-.13v-3.32a.15.15 0 0 0-.226-.13l-4.91 2.864a.575.575 0 0 1-.579-.994l5.64-3.29a.15.15 0 0 0 .075-.13V11c0-.318.257-.575.575-.575Zm.725 6.15a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15v-3.55a.15.15 0 0 0-.15-.15h-5.55Zm7 0a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15v-3.55a.15.15 0 0 0-.15-.15h-5.55Zm5.7 5.15a.15.15 0 0 0-.15-.15h-5.55a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15v-3.55Zm-7.15 3.7a.15.15 0 0 0 .15-.15v-3.55a.15.15 0 0 0-.15-.15h-5.55a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55Z"
                />
                <path
                    fill="url(#f607id1)"
                    d="M9 10.425c.318 0 .575.257.575.575v4.275c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15V11a.575.575 0 0 1 1.15 0v4.275c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15V11a.575.575 0 0 1 1.15 0v4.584a.15.15 0 0 0 .074.13l5.64 3.29a.575.575 0 1 1-.579.993l-4.91-2.864a.15.15 0 0 0-.225.13v3.32a.15.15 0 0 0 .074.13l5.64 3.29a.575.575 0 1 1-.579.994l-4.91-2.864a.15.15 0 0 0-.225.13v3.32a.15.15 0 0 0 .074.13l5.64 3.29a.575.575 0 1 1-.579.994l-5.83-3.402a.15.15 0 0 0-.076-.02H9.196a.15.15 0 0 0-.076.02l-5.83 3.402a.575.575 0 0 1-.58-.994l5.64-3.29a.15.15 0 0 0 .075-.13v-3.32a.15.15 0 0 0-.226-.13l-4.91 2.864a.575.575 0 0 1-.579-.994l5.64-3.29a.15.15 0 0 0 .075-.13v-3.32a.15.15 0 0 0-.226-.13l-4.91 2.864a.575.575 0 0 1-.579-.994l5.64-3.29a.15.15 0 0 0 .075-.13V11c0-.318.257-.575.575-.575Zm.725 6.15a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15v-3.55a.15.15 0 0 0-.15-.15h-5.55Zm7 0a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15v-3.55a.15.15 0 0 0-.15-.15h-5.55Zm5.7 5.15a.15.15 0 0 0-.15-.15h-5.55a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55a.15.15 0 0 0 .15-.15v-3.55Zm-7.15 3.7a.15.15 0 0 0 .15-.15v-3.55a.15.15 0 0 0-.15-.15h-5.55a.15.15 0 0 0-.15.15v3.55c0 .083.067.15.15.15h5.55Z"
                />
            </g>
            <g filter="url(#f607id8)">
                <path
                    stroke="url(#f607id2)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".4"
                    d="M3 19.5L9 16m0 0v-5m0 5h7m-7 0v5m14-5l6 3.5M23 16v-5m0 5h-7m7 0v5m-7-5v-5m0 5v5m-7 0l-6 3.5M9 21v5m0-5h7m-7 5l-6 3.5M9 26h7m0-5v5m0-5h7m-7 5h7m0-5v5m0-5l6 3.5M23 26l6 3.5"
                />
            </g>
            <path
                stroke="url(#f607id3)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.15"
                d="M3 19.5L9 16m0 0v-5m0 5h7m-7 0v5m14-5l6 3.5M23 16v-5m0 5h-7m7 0v5m-7-5v-5m0 5v5m-7 0l-6 3.5M9 21v5m0-5h7m-7 5l-6 3.5M9 26h7m0-5v5m0-5h7m-7 5h7m0-5v5m0-5l6 3.5M23 26l6 3.5"
            />
            <path
                stroke="url(#f607id4)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.15"
                d="M3 19.5L9 16m0 0v-5m0 5h7m-7 0v5m14-5l6 3.5M23 16v-5m0 5h-7m7 0v5m-7-5v-5m0 5v5m-7 0l-6 3.5M9 21v5m0-5h7m-7 5l-6 3.5M9 26h7m0-5v5m0-5h7m-7 5h7m0-5v5m0-5l6 3.5M23 26l6 3.5"
            />
            <g filter="url(#f607id9)">
                <path
                    fill="url(#f607id5)"
                    d="M5.081 12.699C4.47 13.139 4 13.838 4 15.019v14.554a.5.5 0 0 1-.5.502h-1a.5.5 0 0 1-.5-.502V15.02c0-1.83.781-3.138 1.919-3.954C5.009 10.283 6.357 10 7.5 10h17c1.143 0 2.49.283 3.581 1.065C29.218 11.881 30 13.188 30 15.02v14.554a.501.501 0 0 1-.5.502h-1a.501.501 0 0 1-.5-.502V15.02c0-1.181-.468-1.88-1.081-2.32c-.66-.473-1.562-.691-2.419-.691h-17c-.857 0-1.76.219-2.419.69Z"
                />
            </g>
            <g filter="url(#f607ida)">
                <path
                    fill="url(#f607id6)"
                    d="M3.5 28.822V15h-1v13.822h1ZM3.5 15c0-1.338.547-2.187 1.29-2.718c.768-.548 1.781-.782 2.71-.782v-1c-1.071 0-2.308.266-3.29.968c-1.007.719-1.71 1.87-1.71 3.532h1Zm4-3.5h17v-1h-17v1Zm17 0c.929 0 1.942.234 2.71.782c.743.531 1.29 1.38 1.29 2.718h1c0-1.662-.703-2.813-1.71-3.532c-.982-.702-2.219-.968-3.29-.968v1Zm4 3.5v14.306h1V15h-1Z"
                />
            </g>
            <defs>
                <linearGradient
                    id="f607id0"
                    x1="4"
                    x2="28"
                    y1="20.5"
                    y2="20.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D1C6D2" />
                    <stop offset=".5" stopColor="#CDB1E1" />
                    <stop offset="1" stopColor="#CEB3E3" />
                </linearGradient>
                <linearGradient
                    id="f607id1"
                    x1="16"
                    x2="16"
                    y1="11"
                    y2="29.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".781" stopColor="#C598EB" stopOpacity="0" />
                    <stop offset="1" stopColor="#C598EB" stopOpacity=".4" />
                </linearGradient>
                <linearGradient
                    id="f607id2"
                    x1="4"
                    x2="28"
                    y1="20.5"
                    y2="20.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F8EBFF" />
                    <stop offset=".078" stopColor="#F6E8FF" />
                    <stop offset="1" stopColor="#E4CAFA" />
                </linearGradient>
                <linearGradient
                    id="f607id3"
                    x1="4"
                    x2="28"
                    y1="20.5"
                    y2="20.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".583" stopColor="#826484" stopOpacity="0" />
                    <stop offset="1" stopColor="#826484" stopOpacity=".5" />
                </linearGradient>
                <linearGradient
                    id="f607id4"
                    x1="16"
                    x2="16"
                    y1="11"
                    y2="29.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#87477B" stopOpacity=".8" />
                    <stop offset=".193" stopColor="#87477B" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f607id5"
                    x1="28.619"
                    x2="1.529"
                    y1="22.514"
                    y2="22.514"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F32A8D" />
                    <stop offset="1" stopColor="#F22D5C" />
                </linearGradient>
                <linearGradient
                    id="f607id6"
                    x1="29"
                    x2="1.5"
                    y1="10.5"
                    y2="30"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF58A3" />
                    <stop offset=".51" stopColor="#FF5A83" />
                    <stop offset="1" stopColor="#FF4B75" />
                </linearGradient>
                <filter
                    id="f607id7"
                    width="27.15"
                    height="19.8"
                    x="2.425"
                    y="10.275"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-.15" />
                    <feGaussianBlur stdDeviation=".2" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.541176 0 0 0 0 0.85098 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2723" />
                </filter>
                <filter
                    id="f607id8"
                    width="27.4"
                    height="19.9"
                    x="2.3"
                    y="10.3"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_2723" stdDeviation=".25" />
                </filter>
                <filter
                    id="f607id9"
                    width="28.6"
                    height="20.075"
                    x="2"
                    y="10"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".6" />
                    <feGaussianBlur stdDeviation=".5" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.709804 0 0 0 0 0.207843 0 0 0 0 0.290196 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_2723" />
                </filter>
                <filter
                    id="f607ida"
                    width="28.5"
                    height="20.306"
                    x="1.75"
                    y="9.75"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_2723" stdDeviation=".375" />
                </filter>
            </defs>
        </g>
    </svg>
);
export default GoalNet;
