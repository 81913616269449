import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '75vh',
    },
    centeredBlock: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        minWidth: '40%',
    },
    centeredContent: {
        textAlign: 'center',

        '& h1': {
            fontSize: '40px',
            lineHeight: '52px',
            fontWeight: tokens.fontWeightSemibold,
            ...shorthands.margin(
                tokens.spacingVerticalNone,
                tokens.spacingHorizontalNone,
                tokens.spacingVerticalL,
                tokens.spacingHorizontalNone,
            ),
        },

        '& h2': {
            fontSize: tokens.fontSizeBase500,
            lineHeight: tokens.lineHeightBase500,
            fontWeight: tokens.fontWeightRegular,
        },
    },
    field: {
        marginBottom: tokens.spacingVerticalXXL,
    },
    warningLogo: {
        fontSize: '48px',
        marginBottom: '40px',
    },
    title: {
        fontSize: tokens.fontSizeHero800,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightHero800,
        letterSpacing: '0px',
        textAlign: 'center',
        marginBottom: '-12px',
    },
    titleContent: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase400,
        textAlign: 'center',
    },
});
