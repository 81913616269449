import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {GetPromptbookRequest, PromptbookDescriptor} from './promptbooks.types';

export default function useGetPromptbook({promptbookId}: GetPromptbookRequest) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['promptbook', promptbookId],
        queryFn: async () => {
            return await customFetch<PromptbookDescriptor>(`/promptbooks/${promptbookId}`, {
                method: 'GET',
            });
        },
        enabled: Boolean(promptbookId),
    });
}
