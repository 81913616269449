import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        '@media (min-width: 640px) and (max-width: 1023px)': {
            paddingLeft: tokens.spacingHorizontalM,
            paddingRight: tokens.spacingHorizontalM,
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: tokens.spacingHorizontalM,
            paddingRight: tokens.spacingHorizontalM,
        },
        '@media (max-width: 479px)': {
            paddingLeft: tokens.spacingHorizontalM,
            paddingRight: tokens.spacingHorizontalM,
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: 'webkit-fill-available',
        height: 'auto',

        '@media (min-width: 1366px)': {
            maxWidth: '1919px',
            minWidth: '1366px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
            maxWidth: '1365px',
            minWidth: '1024px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
            maxWidth: '1023px',
            minWidth: '640px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
            minWidth: '480px',
            maxWidth: '639px',
        },
        '@media (max-width: 479px)': {
            ...shorthands.gap(tokens.spacingHorizontalL),
            maxWidth: '479px',
        },
    },
});
