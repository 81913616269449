import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        maxWidth: '350px',
    },
    actionButton: {
        ...shorthands.flex(0, 0, 'auto'),
    },
    titleContent: {
        marginTop: tokens.spacingVerticalXS,
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase400,
        textAlign: 'center',
    },
    dropdown: {
        width: '80%',
        marginLeft: '10%',
    },
});
