import {Button, Link, Switch} from '@fluentui/react-components';
import MedeinaFeatures from '@/util/features';
import useClasses from './DataAndPrivacy.styles';
import {ExternalLinkIcon, WindowNewIcon} from '../ui/icons';
import MedeinaVariables from '@/util/variables';
import {getFullGeoName} from '@/util/geoMappings';
import {useGetTenantDataLocation} from '@/api/data';
import {useGetUserInfo} from '@/api/app';
import {usePatchTenantSettings} from '@/api/tenant';
import {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useGetTenantInfo} from '@/api/tenant';
import React from 'react';
import {CookieConsentContext} from '../ui/CookieConsentProvider/CookieConsentProvider';
import {useFeatureFlag} from '@/api/user';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import {getLanguage} from '@/util/locale';
import MSManagedBanner from '../admin/MSManagedBanner';

interface DataPrivacyModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DataAndPrivacy({setOpen}: DataPrivacyModalProps) {
    const isCookieBannerEnabled = MedeinaFeatures.CookieBannerExperience;
    const classes = useClasses();
    const {t} = useTranslation();
    const [isAdmin, setIsAdmin] = useState(false);
    const siteConsent = useContext(CookieConsentContext);

    const {data: userInfo, refetch, isLoading: getAdminIsLoading} = useGetUserInfo();
    const {mutate: sendDataShare, isLoading: patchDataShareIsLoading} = usePatchTenantSettings();
    const {data: dataOptStatus} = useGetTenantInfo();
    const {data: tenantDataLocation} = useGetTenantDataLocation();
    const isFidelisEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisGA);
    const disableSettingsUpdate =
        dataOptStatus && MedeinaVariables.MSManagedTenants.includes(dataOptStatus.tenantId ?? '');

    const locale = useMemo(() => getLanguage(), []);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    const {geoName} = useWorkspaceState();

    const handleManageCookiesClick = () => {
        setOpen(false);
        siteConsent?.manageConsent();
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (userInfo?.isAdmin !== undefined) {
            setIsAdmin(userInfo.isAdmin);
        }
    }, [userInfo]);

    return (
        <div>
            <div className={classes.field}>
                <h3 className={classes.firstHeader}>{t('privacyHeader')}</h3>
                <Link href={MedeinaVariables.PrivacyStatementUri} target="_blank">
                    {t('privacyStatementLink')}
                </Link>
            </div>
            {MedeinaVariables.TermsAndConditionsUri && (
                <div className={classes.field}>
                    <h3>{t('termsAndConditionsText')}</h3>
                    <Link href={MedeinaVariables.TermsAndConditionsUri} target="_blank">
                        {t('termsAndConditionsText')}
                    </Link>
                </div>
            )}
            {locale == 'fr' && (
                <div className={classes.field}>
                    <h3>{t('Accessibility')}</h3>
                    <Link href={MedeinaVariables.FRa11yComplianceUri} target="_blank">
                        {t('AccessibilityStatement')} <ExternalLinkIcon />
                    </Link>
                </div>
            )}
            {locale == 'it' && (
                <div className={classes.field}>
                    <h3>{t('Accessibility')}</h3>
                    <Link href={MedeinaVariables.ITa11yComplianceUri} target="_blank">
                        {t('AccessibilityStatement')} <ExternalLinkIcon />
                    </Link>
                </div>
            )}

            {!isFidelisEnabled && (
                <>
                    <div>
                        <h3>{t('yourOrganizationDataHeader')}</h3>
                        <p>
                            {isWorkspacesTestingEnabled
                                ? t('WorkspacesyourOrganizationDataDescription')
                                : t('yourOrganizationDataDescription')}{' '}
                            <Link
                                href="https://learn.microsoft.com/en-us/security-copilot/privacy-data-security#how-to-opt-in-to-or-out-of-data-sharing"
                                target="_blank"
                            >
                                {t('learnMoreLink')}
                                <ExternalLinkIcon></ExternalLinkIcon>
                            </Link>
                        </p>
                        {!getAdminIsLoading && !isAdmin && (
                            <p className={classes.adminNotice}>{t('adminNotice')}</p>
                        )}
                    </div>
                    <div className={classes.field}>
                        {disableSettingsUpdate && <MSManagedBanner />}
                        <Switch
                            label={t('allowMicrosoftUseDataLabel')}
                            className={classes.dataSwitch}
                            disabled={!isAdmin || disableSettingsUpdate}
                            labelPosition="before"
                            checked={
                                dataOptStatus?.freRequirements?.allowProductImprovement ?? false
                            }
                            onChange={(_, checked) => {
                                sendDataShare({
                                    allowProductImprovement: checked.checked,
                                });
                            }}
                        />
                        <Switch
                            label={t('allowMicrosoftTrainModelLabel')}
                            className={classes.dataSwitch}
                            disabled={!isAdmin || disableSettingsUpdate}
                            labelPosition="before"
                            checked={dataOptStatus?.freRequirements?.allowModelImprovement ?? false}
                            onChange={(_, checked) => {
                                sendDataShare({
                                    allowModelImprovement: checked.checked,
                                });
                            }}
                        />
                    </div>
                    <div className={classes.field}>
                        <h3>{t('dataStorageHeader')}</h3>
                        <p className={classes.geoData}>
                            {t('dataStorageDescription')}{' '}
                            {getFullGeoName(tenantDataLocation?.configuredGeo ?? '')}
                        </p>
                        <p className={classes.geoData}>
                            {t('changeDataStorageText')}{' '}
                            <Link href={MedeinaVariables.SupportUri} target="_blank">
                                {t('changeDataStorageLink')}
                            </Link>
                        </p>
                    </div>
                </>
            )}
            {isFidelisEnabled && geoName && (
                <div className={classes.field}>
                    <h3>{t('Location')}</h3>
                    {!!geoName && (
                        <p className={classes.geoData}>
                            {t('dataStorageDescription')} {geoName}
                        </p>
                    )}
                    {!geoName && <p className={classes.geoData}>{t('LocationUnavailable')}</p>}
                </div>
            )}
            {isCookieBannerEnabled && siteConsent?.isConsentRequired && (
                <div className={classes.field}>
                    <h3>{t('cookiesHeader')}</h3>
                    <Button icon={<WindowNewIcon />} onClick={handleManageCookiesClick}>
                        {t('manageCookiesButton')}
                    </Button>
                </div>
            )}
        </div>
    );
}
