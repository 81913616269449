import {Body1Strong, TextPresetProps} from '@fluentui/react-components';
import {useViewportSize} from '../Grid';
import {LargeTitle as FluentLargeTitle} from '@fluentui/react-components';

export default function LargeTitle(props: TextPresetProps) {
    const {sm: isSmallScreen} = useViewportSize();

    return (
        <>
            {isSmallScreen ? (
                <Body1Strong as="h1" {...props} />
            ) : (
                <FluentLargeTitle as="h1" {...props} />
            )}
        </>
    );
}
