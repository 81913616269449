import {ButtonProps} from '@fluentui/react-components';

export enum PaginationType {
    NumberDisplay = 'NumberDisplay',
    NumberSelection = 'NumberSelection',
}

export interface PageNumberDisplayProps {
    activePage: number;
    onPageChange: (pageNumber: number) => void;
    totalPageCount: number;
    disabled?: boolean;
}

export interface PageSelectionLayoutProps extends PageNumberDisplayProps {}

export interface PageNumberDisplayLayoutProps extends PageSelectionLayoutProps {
    maxPageNumberDisplay?: number;
    onMaxPageLimitEvaluated: () => void;
    enableNextButton: () => void;
}

export type PaginationButtonProps = ButtonProps & {
    buttonDirection: 'left' | 'right';
};

/**
 * Represents the props for the Pagination component.
 */
export interface PaginationProps {
    /**
     * The mode of pagination. The options are NumberDisplay and NumberSelection. The default type is Number Selection
     */
    mode?: PaginationType;

    /**
     * The number of items to display per page. The default value is 100.
     */
    pageSize?: number;

    /**
     * The total number of records.
     */
    recordCount?: number;

    /**
     * The maximum number of page numbers to display in the pagination component. The default value is 6.
     */
    maxPageNumberDisplay?: number;

    /**
     * The children of the Pagination component.
     */
    children?: React.ReactNode;

    /**
     * @param pageNumber
     * @returns
     */
    onPageChange?: (pageNumber: number) => void;

    /**
     * Resets the pagination to the initial state.
     */
    resetPagination?: boolean;

    disabled?: boolean;

    hidden?: boolean;

    /**
     * In case the pagination state is controlled by the parent component, this prop can be used to override the next button disable state.
     */
    overridenPageNumber?: number;
}
