import {DeleteIcon} from '@/components/ui/icons';
import {Toolbar, Button} from '@fluentui/react-components';
import useClasses from './SessionsOperations.styles';
import {SessionOperationsProps} from './SessionOperations.types';
import {useTranslation} from 'react-i18next';

export default function SessionsOperations({selectedRows, onDeleteClick}: SessionOperationsProps) {
    const classes = useClasses();
    const {t} = useTranslation(['mysessions']);
    return (
        <div className={classes.root}>
            <Toolbar size="small">
                <Button
                    icon={<DeleteIcon />}
                    className={
                        selectedRows === undefined || selectedRows.size === 0
                            ? classes.btnDisabled
                            : classes.btn
                    }
                    appearance="transparent"
                    disabled={selectedRows === undefined || selectedRows.size === 0}
                    onClick={() => onDeleteClick?.()}
                >
                    {t('DeleteSessionButton')}
                </Button>
            </Toolbar>
        </div>
    );
}
