import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        position: 'absolute',
        boxShadow: 'inherit',
        ...shorthands.border('none'),
        boxSizing: 'border-box',
    },

    beak: {
        position: 'relative',
        top: '3px',
        left: '7px',
        fill: tokens.colorBrandForeground1,
        display: 'block',
        '@media screen and (-ms-high-contrast: active), screen and (forced-colors: active)': {
            fill: 'WindowText',
        },
    },

    indicator: {
        display: 'block',
        position: 'absolute',
        ...shorthands.outline('none'),
        ...shorthands.overflow('hidden'),
        ...shorthands.borderRadius('32px'),
        width: '32px',
        height: '32px',
        cursor: 'pointer',
        backgroundColor: tokens.colorBrandForeground1,
    },
    pulsingIndicator: {
        display: 'block',
        position: 'absolute',
        height: '50px',
        width: '50px',
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderRadius('225px'),
        ...shorthands.borderColor(tokens.colorBrandForeground1),
        // we want to set the center of the pulsing indicator to be the same as the center of the indicator
        top: '-25%',
        left: '-55%',
    },
});
