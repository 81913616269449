import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        ...shorthands.gap(tokens.spacingVerticalS),
        ...shorthands.margin(tokens.spacingVerticalL, tokens.spacingHorizontalL),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    headerText: {
        fontWeight: tokens.fontWeightMedium,
        fontSize: tokens.fontSizeBase400,
        lineHeight: tokens.fontSizeBase400,
        textAlign: 'center',
    },
    subtitle: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'center',
    },
    icon: {
        height: '48px',
        width: '48px',
    },
});
