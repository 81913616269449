import React, {useCallback, useEffect} from 'react';
import {Text, Button} from '@fluentui/react-components';
import {GridItem} from '@/components/ui/Grid';
import useGetHello from '@/msw/App/apiHelloExample/useGetHello';
import useCreateHello from '@/msw/App/apiHelloExample/useCreateHello';
import useUpdateHello from '@/msw/App/apiHelloExample/useUpdateHello';
import useDeleteHello from '@/msw/App/apiHelloExample/useDeleteHello';
import useClasses from './Dev.styles';

/** An example of the MSW integration with the hello API */
function MockServiceWorker() {
    const classes = useClasses();
    const {data, refetch} = useGetHello({enabled: true});
    const {mutate} = useCreateHello();
    const {mutate: updateHello} = useUpdateHello();
    const {mutate: deleteHello} = useDeleteHello();

    const handleMutate = useCallback(() => {
        mutate(
            {greeting: 'carlos', includeExclamation: true},
            {
                onSuccess: (data, variables, context) => {
                    console.log('mutated hello world:', {data});
                },
                onError: (error, variables, context) => {
                    console.error('error mutating hello world:', {error});
                },
            },
        );
    }, [mutate]);

    const handleUpdate = useCallback(() => {
        updateHello(
            {greeting: 'carlos', includeExclamation: true},
            {
                onSuccess: (data, variables, context) => {
                    console.log('updated hello world:', {data});
                },
                onError: (error, variables, context) => {
                    console.error('error updating hello world:', {error});
                },
            },
        );
    }, [updateHello]);

    const handleDelete = useCallback(() => {
        deleteHello(
            {greeting: 'carlos', includeExclamation: true},
            {
                onSuccess: (data, variables, context) => {
                    console.log('deleted hello world:', {data});
                },
                onError: (error, variables, context) => {
                    console.error('error deleting hello world:', {error});
                },
            },
        );
    }, [deleteHello]);

    return (
        <>
            <GridItem className={classes.row} sm={12} md={12} lg={12} xlg={12} xxlg={12} xxxlg={12}>
                <div>
                    <h2>Mock service worker</h2>
                    <Text>
                        This library lets us mock network responses to compose application
                        scenarios. Use the buttons below to trigger network calls that will be
                        intercepted by MSW. Check your console for logs.
                    </Text>
                    <div>
                        <Button onClick={() => refetch()}>GET Hello</Button>
                        <Button onClick={handleMutate}>POST Hello</Button>
                        <Button onClick={handleUpdate}>PUT Hello</Button>
                        <Button onClick={handleDelete}>DELETE Hello</Button>
                    </div>
                </div>
            </GridItem>
        </>
    );
}

export default MockServiceWorker;
