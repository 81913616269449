import React, {useEffect, useState} from 'react';
import {Text} from '@fluentui/react-components';
import useClasses from './FileUsageMetrics.styles';
import {FileUsage} from '@/api/files/files.types';
import {useTranslation} from 'react-i18next';
import {bytesToMegabytes} from '@/util/converters';

interface FileUsageMetricsProps {
    fileUsage: FileUsage | undefined;
}

export default function FileUsageMetrics({fileUsage}: FileUsageMetricsProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');

    return (
        <Text className={classes.root}>
            {fileUsage &&
                t('FilesTab.FileUsageMetrics', {
                    remainingCount: bytesToMegabytes(fileUsage.limit - fileUsage.usage),
                    limitCount: bytesToMegabytes(fileUsage.limit),
                })}
        </Text>
    );
}
