import React from 'react';
import {Text, TextProps, mergeClasses} from '@fluentui/react-components';
import useClasses from './AnnounceText.styles';

interface AnnounceLabelProps {
    className?: string;
    children: React.ReactNode;
    visuallyHidden?: boolean;
    textProps?: TextProps;
    ariaLive?: 'off' | 'assertive' | 'polite';
}

// Assuming useClasses is defined elsewhere and includes visuallyHidden styling
function AnnounceLabel({
    children,
    textProps = {},
    className = '',
    visuallyHidden = false,
    ariaLive = 'assertive', // Default to 'assertive', but allow override
}: AnnounceLabelProps) {
    const classes = useClasses();
    const rootClassName = mergeClasses(visuallyHidden && classes.visuallyHidden, className);
    return (
        <Text {...textProps} className={rootClassName} aria-live={ariaLive} aria-atomic="true">
            {children}
        </Text>
    );
}

export default AnnounceLabel;
