import {Button, Image, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {SettingsScope, SkillsetDescriptor} from '@/api/skills';
import {DismissIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';

interface SaveConfirmationModalProps {
    selectedSkillset: string | null;
    skillsetDescriptor: SkillsetDescriptor | null;
    scope: SettingsScope;
    icon: string | undefined;
    onSave: () => void;
    onCancel: () => void;
}

export default function SaveConfirmationModal({
    selectedSkillset,
    skillsetDescriptor,
    scope,
    icon,
    onSave,
    onCancel,
}: SaveConfirmationModalProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <div className={classes.confirmationModal}>
            <Image
                className={classes.resetIcon}
                height={48}
                width={48}
                fit="default"
                src={icon}
                alt={''}
                aria-hidden
            />
            <Text className={classes.resetTitleText} as="h1">
                {t('Admin.SavePluginChanges', {
                    pluginName: skillsetDescriptor?.displayName ?? selectedSkillset,
                })}
            </Text>
            <Text className={classes.resetText}>
                {scope == SettingsScope.User
                    ? t('Admin.SaveWarningUserScope')
                    : t('Admin.SaveWarningTenantScope')}
            </Text>
            <div className={classes.resetButtons}>
                <Button
                    className={classes.saveButton}
                    appearance="primary"
                    type="submit"
                    onClick={onSave}
                >
                    {t('common.Save')}
                </Button>
                <Button
                    className={classes.cancelButton}
                    appearance="subtle"
                    size="medium"
                    icon={<DismissIcon />}
                    onClick={onCancel}
                >
                    {t('common.Cancel')}
                </Button>
            </div>
        </div>
    );
}
