import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {isValidURL} from './skillsetUtils';

// base path for skillset/connector icons
const imagePath = '/images/icons/';
const defaultImage = `${imagePath + 'Default.svg'}`;

export default function useGetSkillsetCustomIcon(
    skillsetName?: string | null,
    icon?: string | null,
) {
    const {customFetch} = useFetch();

    return useQuery({
        // Cache for entire session, as this rarely changes.
        // Also constantly creating URL.createObjectURL is not good for memory
        cacheTime: Infinity,
        staleTime: Infinity,
        queryKey: ['skillsets', skillsetName, 'icon'],
        queryFn: async () => {
            try {
                // Try fetching remote icon via the API as CSP blocks img-src other than self
                let imgUrl = defaultImage;
                const iconRes = await customFetch<Response>(
                    `/skillsets/${skillsetName}/icon`,
                    {
                        method: 'GET',
                    },
                    true,
                );

                // Return data if there is a valid response from api by converting blob to object url
                // For errors cases, use default icon
                if (iconRes.status >= 200 && iconRes.status < 400) {
                    const imgBlob = await iconRes.blob();
                    imgUrl = URL.createObjectURL(imgBlob);
                    return imgUrl;
                } else {
                    if (!isValidURL(icon) && icon) {
                        // If icon exists, but is not a valid URL, return default icon
                        // For invalid URLs and null icons, query the icon API, return default on error
                        return `${imagePath + icon}`;
                    }

                    console.log('using default skillset icon on error.');
                    return imgUrl;
                }
            } catch (_) {
                console.log('using default skillset icon on exception.');
                return defaultImage;
            }
        },
    });
}
