import React, {useCallback, useState, useLayoutEffect, useEffect, useRef, useMemo} from 'react';
import {motion} from 'framer-motion';
import {useClasses} from './ResizeBar.styles';
import {useLayout} from '@/components/ui/Layout';
import {Tooltip, mergeClasses} from '@fluentui/react-components';
import {NavigationBarHeight} from '@/components/App.styles';
import {ControlTriggeredEventParameters} from '@/components/App.types';
import {CUSTOM_EVENTS, CUSTOM_EVENT_PARAMETERS} from '@/components/App.constants';

function ResizeBar() {
    const classes = useClasses();

    // we keep the hover state in state so we can animate it correctly
    const [hover, setHover] = useState(false);
    // keep a reference to the window width so we can calculate the constraints for the resize bar
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // useLayout gives us an API to manage the overall app layout
    const {sidePanel} = useLayout();
    const {width, setWidth} = sidePanel;
    const initialWidth = sidePanel.width > 100 ? 25 : sidePanel.width;
    const RESIZE_FACTOR_VIA_KEYBOARD: number = 10;

    // The Framer resize control adds/removes a certain amount of pixels in between scrolls. The shift after the right scroll happens
    // to be more than the left. This buffer, although not exact, tries to main the parity between right & left scrolls from the
    // keyboard.
    const RESIZE_BUFFER_FOR_RIGHT_KEYBOARD_SCROLL = 7.5;

    const ARROW_KEY_LEFT = 'ArrowLeft';
    const ARROW_KEY_RIGHT = 'ArrowRight';

    // resize position
    const [position, setPosition] = useState(initialWidth);
    const [prevPosition, setPrevPosition] = useState(initialWidth);

    // ref to the resize bar so we can check it's current position
    const resizeRef = useRef<HTMLDivElement>(null);

    const setWidthAndPosition = (newWidth: number) => {
        setWidth?.(newWidth);
        setPosition(newWidth);
    };
    // the top constraint is the height of side panel
    // the left constraint is the left most edge of the window
    // the bottom constraint is the bottom of the window
    const getDragConstraints = () => {
        // the right constraint should keep in mind that the minimum width of the side panel is 500px and should check for the current width of the sidepanel
        // the constraint itself is based off of the current position of the resizeRef, as it moves the right constraint should update never letting it go below the minimum width
        const resizeRefLeft = resizeRef.current?.offsetLeft || 0;
        const rightConstraint = windowWidth - resizeRefLeft - 500;
        const leftConstraint = resizeRefLeft - 700;
        const bottomConstraint = 0;
        const topConstraint = NavigationBarHeight;
        const constraints = {
            right: rightConstraint >= 0 ? rightConstraint : 0,
            top: topConstraint,
            left: leftConstraint >= 0 ? leftConstraint : 0,
            bottom: bottomConstraint,
        };

        return constraints;
    };

    // handle key presses
    const handleKeyPress = function (e: KeyboardEvent) {
        // if the resize bar is not in focus, we don't want to do anything
        if (document.activeElement !== resizeRef.current) {
            return;
        }

        if (e.key === 'Tab' && e.shiftKey) {
            e.preventDefault();
            highlightToggleBtn();
        }

        // if the key pressed is the left arrow key or the right arrow key, we want to move the resize bar
        if (e.key === ARROW_KEY_LEFT || e.key === ARROW_KEY_RIGHT) {
            e.preventDefault();

            setResizedWidthViaDirection(e.key === ARROW_KEY_LEFT);

            //If executed sequentially, the focus is lost after the second sequential
            // invocation of a keyboard command. From what appears the focus command
            // should be pushed to the end of the event loop to work around this.
            setTimeout(() => {
                resizeRef?.current?.focus();
            }, 0);
        }
    };

    const handleControlTriggered = (event: CustomEvent<ControlTriggeredEventParameters>) => {
        if (!event?.detail) {
            return;
        }

        const {target, action, source} = event?.detail;

        if (
            target === CUSTOM_EVENT_PARAMETERS.RESIZE_BAR &&
            action === 'click' &&
            source === CUSTOM_EVENT_PARAMETERS.SIDE_PANEL_BUTTON
        ) {
            resizeRef?.current?.focus();
            setHover(true);
        }
    };

    //take the focus back to toggle source button on shift tab experience on resize bar
    const highlightToggleBtn = () => {
        const eventArguments: ControlTriggeredEventParameters = {
            target: CUSTOM_EVENT_PARAMETERS.SIDE_PANEL_BUTTON,
            action: 'keydown',
            source: CUSTOM_EVENT_PARAMETERS.RESIZE_BAR,
        };

        const controlTriggeredEvent = new CustomEvent(CUSTOM_EVENTS.CONTROL_TRIGGERED, {
            detail: eventArguments,
        });

        window.dispatchEvent(controlTriggeredEvent);
    };

    // when the window resizes, update the window width and recalculate the constraints and widths
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener(
            CUSTOM_EVENTS.CONTROL_TRIGGERED,
            handleControlTriggered as EventListener,
        );

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener(
                CUSTOM_EVENTS.CONTROL_TRIGGERED,
                handleControlTriggered as EventListener,
            );
            resizeRef?.current?.blur();
            setHover(false);
        };
    }, []);

    // when the resizeBar first mounts, we need to calculate the window width and set the width of the side panel
    // we want to check that the current width of the side panel is not less than the minimum width (500px but convert into percentage)
    // if it is, we need to update the width of the side panel to the minimum width (in percentage)
    useLayoutEffect(() => {
        const minWidth = 500;
        const minWidthPercentage = (minWidth / windowWidth) * 100;
        let currentWidth: number;

        // on initial implementation we will we be converting the current side width panel to a percentage
        if (width > 100) {
            // the current width is in pixels, we need to convert it to a percentage
            currentWidth = (width / windowWidth) * 100;
        } else {
            currentWidth = width;
        }
        if (width < minWidthPercentage) {
            setWidthAndPosition(minWidthPercentage);
        } else {
            setWidthAndPosition(currentWidth);
        }
    }, []);

    const setResizedWidthViaDirection = function (shouldResizeBarShiftLeft: boolean) {
        const currentPosition = resizeRef.current?.getBoundingClientRect().left || 0;

        const resizeFactor: number =
            (shouldResizeBarShiftLeft ? -1 : 1) * RESIZE_FACTOR_VIA_KEYBOARD;

        let adjustedPosition: number =
            currentPosition + resizeFactor >= 0 ? currentPosition + resizeFactor : currentPosition;

        adjustedPosition = shouldResizeBarShiftLeft
            ? adjustedPosition
            : adjustedPosition + RESIZE_BUFFER_FOR_RIGHT_KEYBOARD_SCROLL;

        setResizedWidth(adjustedPosition);
    };

    const setResizedWidth = function (resizePosition: number) {
        const newSidePanelWidth =
            windowWidth - resizePosition >= 500 ? windowWidth - resizePosition : 500;
        const newContentWidth = windowWidth - newSidePanelWidth;
        const newContentWidthPercentage = (newContentWidth / windowWidth) * 100;
        const newSidePanelWidthPercentage = 100 - newContentWidthPercentage;
        setWidthAndPosition(newSidePanelWidthPercentage);
    };

    // Handle resizing of the side panel
    // const [sidePanelResize, setSidePanelResize] = useState<any>(null);
    const handleSidePanelResize = useCallback(
        (e: MouseEvent) => {
            const resizeBarPosition = e.clientX;
            setResizedWidth(resizeBarPosition);
        },
        // setWidth comes from LayoutContext and can cause an infinite loop if we don't exclude it from the dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [windowWidth, setWidth],
    );
    const handleSidePanelResizeEnd = useCallback(() => {
        setHover(false);
        document.removeEventListener('mousemove', handleSidePanelResize);
        document.removeEventListener('mouseup', handleSidePanelResizeEnd);
    }, [handleSidePanelResize]);

    const handleSidePanelResizeStart = useCallback(
        (e: MouseEvent) => {
            setHover(true);
            document.addEventListener('mousemove', handleSidePanelResize);
            document.addEventListener('mouseup', handleSidePanelResizeEnd);
        },
        [handleSidePanelResize, handleSidePanelResizeEnd],
    );

    useEffect(() => {
        if (prevPosition !== position && resizeRef.current) {
            setPrevPosition(position);
            resizeRef.current.focus();
        }
    }, [position]);

    return (
        <Tooltip content="Resize pin board" relationship="label">
            <motion.div
                key={position}
                tabIndex={0}
                className={mergeClasses(classes.resizeBar, hover && classes.visible)}
                drag="x"
                dragConstraints={getDragConstraints()}
                initial={{right: `${position}%`, top: NavigationBarHeight}}
                onDragStart={handleSidePanelResizeStart}
                onDragEnd={handleSidePanelResizeEnd}
                onHoverStart={() => setHover(true)}
                onHoverEnd={() => setHover(false)}
                onBlur={() => setHover(false)}
                onFocus={() => setHover(true)}
                ref={resizeRef}
                role="slider"
                aria-orientation="vertical"
                aria-valuemin={0}
                aria-valuemax={100}
                aria-valuenow={position}
                aria-valuetext={`${position.toFixed(0)}%`}
            />
        </Tooltip>
    );
}

export default ResizeBar;
