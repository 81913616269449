import {useMutation, useQueryClient} from '@tanstack/react-query';
import {GetWorkspaceByNameResponse, Workspace} from '.';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import {PutWorkspaceRequest} from './workspaces.types';
import securityGraphApi from '@/api/securityGraph.api';

/**
 * Hook to create or update a workspace
 * if the workspace does not already exist the workspace will be created
 * This is a new version of the useCreateWorkspace hook
 * @returns GetWorkspaceByNameResponse
 */
export default function usePutWorkspace() {
    const queryClient = useQueryClient();

    const getUrl = (workspaceName: string) =>
        securityGraphApi({
            path: `/account/workspaces/${workspaceName}`,
            version: '2023-12-01-preview',
        });

    return useMutation({
        mutationFn: async (request: PutWorkspaceRequest) => {
            try {
                return await customFetch<GetWorkspaceByNameResponse>(getUrl(request.name), {
                    method: 'PUT',
                    body: request,
                    scopes: RESOURCE_SCOPES.FIDELIS,
                });
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.removeQueries(['workspaces']);
            queryClient.removeQueries(['workspaces', variables.name]);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
