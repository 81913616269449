import {Body1} from '@fluentui/react-components';
import useClasses from './components.styles';

export default function MarkdownBody({children, className, ...props}: any) {
    const classes = useClasses();

    return (
        <p>
            <Body1 className={classes.body}>{children}</Body1>
        </p>
    );
}
