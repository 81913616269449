import React, {useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {Subtitle2, mergeClasses, Button} from '@fluentui/react-components';
import Image from 'next/image';

import useTourClasses from '@/components/sections/tours/Tour.styles';
import PopoverControls from '@/components/ui/Tour/PopoverControls';

import useClasses from './SpotlightContent.styles';
import PopoverDismiss from '@/components/ui/Tour/PopoverDismiss';
import {useTourContext} from '@/components/ui/Tour';

export const Intro = () => {
    const tourClasses = useTourClasses();
    const classes = useClasses();
    return (
        <div className={classes.popOver}>
            <Image
                src="/images/tours/defenderIntro/defenderToCopilot.png"
                width={288}
                height={162}
                alt="Screenshot of two interfaces side by side"
            />
            <Subtitle2 className={tourClasses.popoverTitle}>
                Your Security Copilot activities from Microsoft Defender 365
            </Subtitle2>
            <p className={tourClasses.popoverText}>
                Each activity is shown as a separate prompt and response
            </p>
            <PopoverControls />
        </div>
    );
};

export const BuildOn = () => {
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const tour = useTourContext();

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle}>
                    Build on what you learned
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>
                Select a suggested next step or enter your own prompt.
            </p>
            <PopoverControls />
        </div>
    );
};

export const SessionNavigation = () => {
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const tour = useTourContext();

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle}>
                    Revisit this session or start fresh
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>
                All these prompts and responses are automatically saved as a single session. You can
                access it at anytime or start a new one.
            </p>
            <PopoverControls />
        </div>
    );
};
