import {useCallback} from 'react';
import {Subtitle2} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/Tour.styles';
import useStyles from './PopoverStops.styles';

import PopoverControls from '@/components/ui/Tour/PopoverControls';
import PopoverDismiss from '@/components/ui/Tour/PopoverDismiss';
import {
    TourStopState,
    PopoverView,
    useTourContext,
    TourStopSetupHandler,
    TourStopTeardownHandler,
} from '@/components/ui/Tour';
import {useTranslation} from 'react-i18next';
import {
    togglePromptbarMenu,
    openSourcesDialog,
    closeSourcesDialog,
    isPromptbarMenuOpen,
    isSourcesDialogOpen,
} from '../../TourLocators';
import {tourStopNames} from '../config';

// function that runs on the setup of the stop
export const setupHandlers: TourStopSetupHandler = (stopName, setTourStopPlaying) => {
    // depending on the stop we will want to run a certain block of code
    // if we do not have an entry for the stop we will just return
    // handle with a switch statement
    switch (stopName) {
        case tourStopNames.chatWithAI:
            //console.log('setup chatWithAI');
            break;
        case tourStopNames.tryQuickstart:
            if (!isPromptbarMenuOpen()) {
                togglePromptbarMenu();
            }
            // After 1200ms, set the stop state to playing
            setTimeout(() => {
                setTourStopPlaying();
            }, 500);
            break;
        case tourStopNames.optimize:
            if (!isSourcesDialogOpen()) {
                openSourcesDialog();
            }
            setTourStopPlaying();
            break;
        default:
            // if we don't match anything continue to the next stop
            setTourStopPlaying();
            break;
    }
};

export const teardownHandlers: TourStopTeardownHandler = (
    currentStopName,
    nextStopName,
    setStateComplete,
) => {
    switch (currentStopName) {
        case tourStopNames.chatWithAI:
            //console.log('teardown chatWithAI');
            setStateComplete();
            break;
        case tourStopNames.getIdeas:
            // the getIdeas stop opens the promptbar menu
            // our cleanup should make sure we close it
            // it doesn't matter if we are going forward or backward in this case
            if (isPromptbarMenuOpen()) {
                togglePromptbarMenu();
            }
            setStateComplete();
            break;
        case tourStopNames.optimize:
            // the optimize stop opens the sources dialog
            // our cleanup should make sure we close it
            if (isSourcesDialogOpen()) {
                closeSourcesDialog();
            }
            setStateComplete();
            break;
        default:
            setStateComplete();
            break;
    }
};

export const cleanupHandlers: TourStopTeardownHandler = (
    currentStopName,
    nextStopName,
    setStateComplete,
) => {
    // We want to make sure all the surfaces we could have opened are closed
    // when we are done with the tour no matter what stop we were on
    if (isPromptbarMenuOpen()) {
        togglePromptbarMenu();
    }
    if (isSourcesDialogOpen()) {
        closeSourcesDialog();
    }
};

export const ChatWithAI: PopoverView = () => {
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.ChatWithAITitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.ChatWithAIDescription')}</p>
            <PopoverControls
                stopNumber={1}
                totalPopoverStops={5}
                onRemindMeLaterClicked={tour.exitTour}
            />
        </div>
    );
};

export const GetIdeas: PopoverView = (props) => {
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.GetIdeasTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.GetIdeasDescription')}</p>
            <PopoverControls stopNumber={2} totalPopoverStops={5} />
        </div>
    );
};

export const TryQuickStart: PopoverView = (props) => {
    const {stopState} = props;
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    const handlePrevious = useCallback(() => {
        tour.prevStop();
    }, []);

    const handleNext = useCallback(() => {
        tour.nextStop();
    }, []);

    const handleExit = useCallback(() => {
        tour.quitTour();
    }, []);

    return stopState == TourStopState.setup || stopState == TourStopState.teardown ? (
        <></>
    ) : (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.TryQuickStartTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={handleExit} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.TryQuickStartDescription')}</p>
            <PopoverControls
                stopNumber={3}
                totalPopoverStops={5}
                onBackClicked={handlePrevious}
                onNextClicked={handleNext}
            />
        </div>
    );
};

export const Sources: PopoverView = (props) => {
    const classes = useStyles();

    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    return (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.SourcesTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.SourcesDescription')}</p>
            <PopoverControls stopNumber={4} totalPopoverStops={5} />
        </div>
    );
};

export const Optimize: PopoverView = (props) => {
    const {stopState, setStopState} = props;
    const classes = useStyles();
    const tourClasses = useTourClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');

    const handlePrevious = useCallback(() => {
        tour.prevStop();
    }, []);

    const handleNext = useCallback(() => {
        tour.nextStop();
    }, []);

    const handleExit = useCallback(() => {
        tour.quitTour();
    }, []);

    return stopState == TourStopState.setup || stopState == TourStopState.teardown ? (
        <></>
    ) : (
        <div className={classes.popOver}>
            <div className={classes.stack}>
                <Subtitle2 className={tourClasses.popoverTitle} role="heading">
                    {t('analyst.home.OptimizeTitle')}
                </Subtitle2>
                <PopoverDismiss onDismissClicked={tour.exitTour} />
            </div>
            <p className={tourClasses.popoverText}>{t('analyst.home.OptimizeDescription')}</p>
            <PopoverControls
                stopNumber={5}
                totalPopoverStops={5}
                onBackClicked={handlePrevious}
                onNextClicked={handleNext}
                onDoneClicked={handleExit}
            />
        </div>
    );
};
