import React, {useContext} from 'react';
import {MarkdownComponentConfiguration, MarkdownRendererConfiguration} from './Markdown.types';

const MarkdownContext = React.createContext<MarkdownRendererConfiguration>(
    {} as MarkdownRendererConfiguration,
);

export const MarkdownProvider = (props: MarkdownComponentConfiguration) => {
    const markdownConfiguration: MarkdownRendererConfiguration = {
        allowTableDataExport: props.allowTableDataExport,
    };

    return (
        <MarkdownContext.Provider value={markdownConfiguration}>
            {props.children}
        </MarkdownContext.Provider>
    );
};

export const useMarkdownContext = (): MarkdownRendererConfiguration => useContext(MarkdownContext);
