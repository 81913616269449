import {useState, useEffect} from 'react';
import useClasses from './SearchFilter.styles';
import {ToolbarButton} from '@fluentui/react-components';
export * from '../Session.types';
import DateFilterMenu from './DateFilterMenu';
import {DateRange, DateRangeOption, DateRangeOptions} from './SearchFilter.types';
import {useTranslation} from 'react-i18next';

// This function converts the filter values to OData filter query
// using the current date to offset the date range with our menu options
const getODataFilter = (filterField: string, selectedOption: DateRangeOption | DateRange) => {
    const currentDate = new Date();
    const startDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
    );
    let filter = '';

    // If we are using a date range we need to concatenate the filter with the start and end date
    if (typeof selectedOption === 'object') {
        const CustomStartDay = new Date(selectedOption.startDate);
        const CustomEndDay = new Date(selectedOption.endDate);
        CustomEndDay.setDate(CustomEndDay.getDate() + 1);
        filter = `${filterField} ge ${CustomStartDay.toISOString()} and ${filterField} lt ${CustomEndDay.toISOString()}`;
        return filter;
    } else {
        // otherwise we have selected a menu option with a preset date range
        switch (selectedOption) {
            case DateRangeOptions.AllTime:
                // No filter needed, return empty string
                break;
            case DateRangeOptions.Today:
                filter = `${filterField} ge ${startDay.toISOString()}`;
                break;
            case DateRangeOptions.Days7:
                const SevenDaysAgo = new Date(startDay);
                SevenDaysAgo.setDate(SevenDaysAgo.getDate() - 6);
                filter = `${filterField} ge ${SevenDaysAgo.toISOString()}`;
                break;
            case DateRangeOptions.Days14:
                const FourteenDaysAgo = new Date(startDay);
                FourteenDaysAgo.setDate(FourteenDaysAgo.getDate() - 13);
                filter = `${filterField} ge ${FourteenDaysAgo.toISOString()}`;
                break;
            case DateRangeOptions.Days30:
                const ThirtyDaysAgo = new Date(startDay);
                ThirtyDaysAgo.setDate(ThirtyDaysAgo.getDate() - 29);
                filter = `${filterField} ge ${ThirtyDaysAgo.toISOString()}`;
                break;
            default:
                break;
        }
        return filter;
    }
};

interface SearchFilterProps {
    onFilterApply: (
        customFilter: string,
        createdAt: DateRangeOption | DateRange,
        updatedAt: DateRangeOption | DateRange,
    ) => void;
    createdAt: DateRangeOption | DateRange;
    updatedAt: DateRangeOption | DateRange;
    setCreatedAt: (createdAt: DateRangeOption | DateRange) => void;
    setUpdatedAt: (updatedAt: DateRangeOption | DateRange) => void;
}

export default function SearchFilter({
    onFilterApply,
    createdAt,
    updatedAt,
    setCreatedAt,
    setUpdatedAt,
}: SearchFilterProps) {
    const classes = useClasses();
    const {t} = useTranslation(['mysessions']);
    const [applyButtonClicked, setApplyButtonClicked] = useState<boolean>(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    // convert filter values to OData filter query
    const getFilterQuery = () => {
        if (createdAt !== DateRangeOptions.AllTime && updatedAt !== DateRangeOptions.AllTime) {
            return `${getODataFilter('createdAt', createdAt)} and ${getODataFilter(
                'updatedAt',
                updatedAt,
            )}`;
        } else if (
            createdAt !== DateRangeOptions.AllTime &&
            updatedAt === DateRangeOptions.AllTime
        ) {
            return `${getODataFilter('createdAt', createdAt)}`;
        } else if (
            createdAt === DateRangeOptions.AllTime &&
            updatedAt !== DateRangeOptions.AllTime
        ) {
            return `${getODataFilter('updatedAt', updatedAt)}`;
        } else {
            return '';
        }
    };

    // Set a default filter on initial query if there's no filter already applied
    useEffect(() => {
        if (isInitialLoad || !applyButtonClicked) {
            setIsInitialLoad(false);
            return;
        }
        onFilterApply(getFilterQuery(), createdAt, updatedAt);
    }, [applyButtonClicked]);

    const handleFilterApply = () => {
        setApplyButtonClicked((prevState) => !prevState);
    };

    return (
        <div className={classes.filter}>
            <p className={classes.filterLabelText}>{t('SearchFilterSelectFiltersLabel')}</p>
            <div className={classes.filterOptions}>
                <div className={classes.dropDownDPCombo}>
                    <DateFilterMenu
                        filterLabel={t('SearchFilterCreatedAtLabel')}
                        filterName="createdAt"
                        filterValue={createdAt}
                        onSetFilterValue={(newCreatedAt) => setCreatedAt(newCreatedAt)}
                    />
                </div>
                <div className={classes.dropDownDPCombo}>
                    <DateFilterMenu
                        filterLabel={t('SearchFilterUpdatedAtLabel')}
                        filterName="updatedAt"
                        filterValue={updatedAt}
                        onSetFilterValue={(newUpdatedAt) => setUpdatedAt(newUpdatedAt)}
                    />
                </div>
            </div>

            <ToolbarButton appearance="primary" onClick={handleFilterApply}>
                {t('SearchApplyFilterButton')}
            </ToolbarButton>
        </div>
    );
}
