import React from 'react';
import {Button, Tooltip, MenuItem} from '@fluentui/react-components';
import {ShareIcon} from '@/components/ui/icons';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useViewportSize} from '@/components/ui/Grid';

interface ShareSessionButtonProps {
    onClick: () => void;
    isUserSessionOwner: boolean;
}

// Button responsible for rendering the correct share session button and handles firing the callback
// to the Session component
function ShareSessionButton(props: ShareSessionButtonProps) {
    const {onClick, isUserSessionOwner} = props;
    const {t} = useTranslation('session');
    const screenSize = useViewportSize();
    const renderInHeaderMenu = screenSize.md || screenSize.sm;
    const [showTooltip, setShowTooltip] = useState(false);

    return renderInHeaderMenu ? (
        <MenuItem
            data-testid="share-session-button"
            icon={<ShareIcon />}
            disabled={!isUserSessionOwner}
            onClick={onClick}
        >
            {t('ShareSessionDialogShare')}
        </MenuItem>
    ) : (
        <Tooltip visible={showTooltip} content={t('ShareSessionDialogTitle')} relationship="label">
            <Button
                data-testid="share-session-button"
                icon={<ShareIcon />}
                appearance="subtle"
                size="medium"
                onClick={onClick}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                disabled={!isUserSessionOwner}
            >
                {t('ShareSessionDialogShare')}
            </Button>
        </Tooltip>
    );
}

export default ShareSessionButton;
