import {Neutral51} from './colors.types';

const neutrals: Neutral51 = {
    '0':   "#000000",
    '2':   "#040506",
    '4':   "#090A0C",
    '6':   "#0D0F10",
    '8':   "#121416",
    '10':  "#181B1E",
    '12':  "#1C2023",
    '14':  "#202528",
    '16':  "#242A2E",
    '18':  "#292F34",
    '20':  "#2E353A",
    '22':  "#323A3F",
    '24':  "#373F44",
    '26':  "#3C4449",
    '28':  "#41494E",
    '30':  "#474F54",
    '32':  "#4B5459",
    '34':  "#51595F",
    '36':  "#555E64",
    '38':  "#5A6369",
    '40':  "#5F686E",
    '42':  "#636D73",
    '44':  "#687278",
    '46':  "#6D777D",
    '48':  "#727C82",
    '50':  "#788288",
    '52':  "#7D878C",
    '54':  "#828C92",
    '56':  "#879197",
    '58':  "#8D969C",
    '60':  "#919BA0",
    '62':  "#97A0A5",
    '64':  "#9CA5AA",
    '66':  "#A2AAAF",
    '68':  "#A7AFB3",
    '70':  "#ADB5B9",
    '72':  "#B3BABE",
    '74':  "#B8BFC3",
    '76':  "#BDC4C8",
    '78':  "#C2C8CC",
    '80':  "#C7CDD0",
    '82':  "#CDD2D5",
    '84':  "#D2D7D9",
    '86':  "#D8DCDE",
    '88':  "#DDE1E3",
    '90':  "#E4E6E8",
    '92':  "#E9EBED",
    '94':  "#EEF0F2",
    '96':  "#F4F5F6",
    '98':  "#F9FAFB",
    '100': "#FFFFFF",
};

export default neutrals;