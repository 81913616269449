import {
    SkillsetAuthType,
    SkillsetConfiguration,
    SkillsetError,
    SkillsetSetting,
} from '@/api/skills';
import {useMemo} from 'react';

export interface useSkillsetConfigHasValueProps {
    existingConfigs: SkillsetConfiguration | null;
    authType: string;
    requirementsCheckErrors: SkillsetError[];
}

export default function useSkillsetConfigHasValue({
    existingConfigs,
    authType,
    requirementsCheckErrors,
}: useSkillsetConfigHasValueProps) {
    const existingConfigHasValue = useMemo(() => {
        if (!existingConfigs) {
            return false;
        }

        const isOAuthConfig =
            authType === SkillsetAuthType.OAuthClientCredentialsFlow ||
            authType === SkillsetAuthType.OAuthAuthorizationCodeFlow;

        if (isOAuthConfig) {
            const oauthSettings = existingConfigs?.authConfigurations?.[authType]?.settings;
            return Boolean(oauthSettings?.ClientId || oauthSettings?.ClientSecret);
        }

        // As config can exist without value, we need to check if there is any config with value to mark as existing config
        return !requirementsCheckErrors.some((error) => error.name === 'Value');
    }, [existingConfigs, authType, requirementsCheckErrors]);

    return {existingConfigHasValue};
}
