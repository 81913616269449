import {NodeModel} from 'ts-graphviz';
import {graphvizImages, graphvizRiskMap, graphvizTypeMap} from './GraphvizDotCodeBlock.data';

// Determine the appropriate SVG based on the node attributes.
export function generateSvgName(node: NodeModel): string {
    const classes: string[] = [];

    // Add an entity svg based on the type.
    classes.push(
        // @ts-ignore
        graphvizTypeMap[node.attributes.get('_type') ?? graphvizTypeMap.UNKNOWN] ??
            graphvizTypeMap.UNKNOWN,
    );

    // Add network location.
    // TODO: Add support for external once an attribute exists.
    classes.push('internal');

    // Add whether the entity is active.
    // TODO: Add support for external once an attribute exists.
    // classes.push('active');

    // Add whether the entity is suspicious.
    // TODO: Add support for external once an attribute exists.
    // @ts-ignore
    const suspicious: string | null = graphvizRiskMap[node.attributes.get('_type') ?? ''] ?? null;
    // There is no threat suspicious svg.
    if (classes[0] !== 'threat' && suspicious) {
        classes.push(suspicious);
    }

    // Return the svg if it is known.
    const svg = classes.join('_');
    if (graphvizImages.find((image) => image[0] === svg)) {
        return svg;
    }

    // Otherwise return the default.
    return 'unknown';
}
