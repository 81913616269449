import * as React from 'react';
const FaceRelieved = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <g clipPath="url(#f2095idd)">
                <path
                    fill="url(#f2095id0)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f2095id1)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f2095id2)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f2095id3)"
                    fillOpacity=".6"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f2095id4)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f2095id5)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f2095id6)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    fill="url(#f2095id7)"
                    d="M15.999 29.998c9.334 0 13.999-6.268 13.999-14c0-7.73-4.665-13.998-14-13.998C6.665 2 2 8.268 2 15.999c0 7.731 4.664 13.999 13.999 13.999Z"
                />
                <path
                    stroke="url(#f2095id9)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 17s1 1 3 1s3-1 3-1"
                />
                <path
                    stroke="url(#f2095ida)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 17s1 1 3 1s3-1 3-1"
                />
                <path
                    stroke="url(#f2095idb)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.5 10.5c.333-1 1.6-3 4-3"
                />
                <path
                    stroke="url(#f2095idc)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M25.5 10.5c-.333-1-1.6-3-4-3"
                />
                <path
                    fill="url(#f2095id8)"
                    fillRule="evenodd"
                    d="M10.4 23.2a1 1 0 0 1 1.4.2c.31.413 1.712 1.6 4.2 1.6c2.488 0 3.89-1.188 4.2-1.6a1 1 0 1 1 1.6 1.2c-.69.92-2.688 2.4-5.8 2.4c-3.112 0-5.11-1.48-5.8-2.4a1 1 0 0 1 .2-1.4Z"
                    clipRule="evenodd"
                />
            </g>
            <defs>
                <radialGradient
                    id="f2095id0"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(132.839 10.786 10.065) scale(37.5033)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f2095id1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(131.878 10.74 10.193) scale(38.9487)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF478" />
                    <stop offset=".475" stopColor="#FFB02E" />
                    <stop offset="1" stopColor="#F70A8D" />
                </radialGradient>
                <radialGradient
                    id="f2095id2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(101.31 2.876 12.808) scale(17.8466 22.8581)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".788" stopColor="#F59639" stopOpacity="0" />
                    <stop offset=".973" stopColor="#FF7DCE" />
                </radialGradient>
                <radialGradient
                    id="f2095id3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-29 29 -29 -29 18 14)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".315" stopOpacity="0" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="f2095id4"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(77.692 -2.555 18.434) scale(28.1469)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".508" stopColor="#7D6133" stopOpacity="0" />
                    <stop offset="1" stopColor="#715B32" />
                </radialGradient>
                <radialGradient
                    id="f2095id5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(7.5 10.99996 -7.97335 5.4364 16.5 16.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB849" />
                    <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2095id6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(11.49998 2 -2 11.49998 20.5 18)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA64B" />
                    <stop offset=".9" stopColor="#FFAE46" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2095id7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(43.971 -9.827 29.173) scale(59.0529)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".185" stopOpacity="0" />
                    <stop offset="1" stopOpacity=".4" />
                </radialGradient>
                <radialGradient
                    id="f2095id8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(0 5.5 -8.41855 0 16 22)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".348" stopColor="#241A1A" />
                    <stop offset=".628" stopColor="#57444A" />
                    <stop offset="1" stopColor="#4E2553" />
                    <stop offset="1" stopColor="#502A56" />
                </radialGradient>
                <linearGradient
                    id="f2095id9"
                    x1="11.2"
                    x2="10.965"
                    y1="16.75"
                    y2="18.161"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#52383E" />
                    <stop offset="1" stopColor="#432A35" />
                </linearGradient>
                <linearGradient
                    id="f2095ida"
                    x1="23.2"
                    x2="22.965"
                    y1="16.75"
                    y2="18.161"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#52383E" />
                    <stop offset="1" stopColor="#432A35" />
                </linearGradient>
                <linearGradient
                    id="f2095idb"
                    x1="6.833"
                    x2="6.833"
                    y1="11.4"
                    y2="9.6"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".03" stopColor="#524049" />
                    <stop offset="1" stopColor="#4A2C42" />
                </linearGradient>
                <linearGradient
                    id="f2095idc"
                    x1="25.167"
                    x2="25.167"
                    y1="11.4"
                    y2="9.6"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".03" stopColor="#524049" />
                    <stop offset="1" stopColor="#4A2C42" />
                </linearGradient>
                <clipPath id="f2095idd">
                    <path fill="#fff" d="M0 0h32v32H0z" />
                </clipPath>
            </defs>
        </g>
    </svg>
);
export default FaceRelieved;
