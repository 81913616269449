import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    tokens,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {DismissIcon, WarningIcon} from '../ui/icons';
import {useTranslation} from 'react-i18next';
import useClasses from './EapSwitchConfirmation.styles';

interface EapSwitchConfirmationProps {
    isEapSwitchCapacityOpen: boolean;
    onConfirmEndEarlyAccess: () => void;
    onClose: () => void;
}

export default function EapSwitchConfirmation(props: EapSwitchConfirmationProps) {
    const [isEapConfirmationOpen, setIsEapConfirmationOpen] = useState<boolean>(false);
    const {t: commonLabel} = useTranslation('common');
    const {t: adminLabel} = useTranslation('admin');
    const classes = useClasses();

    const closeEapSwitchConfirmation = () => {
        props.onClose();
    };

    const confirmEarlyAccessClosure = () => {
        props.onConfirmEndEarlyAccess();
    };

    useEffect(() => {
        setIsEapConfirmationOpen(props.isEapSwitchCapacityOpen);
    }, [props.isEapSwitchCapacityOpen]);

    return (
        <Dialog open={isEapConfirmationOpen}>
            <DialogSurface>
                <DialogTrigger disableButtonEnhancement>
                    <Button
                        className={classes.closeButton}
                        data-test-id="switch-capacity-confirmation-close-button"
                        appearance="transparent"
                        aria-label="Close EAP switch confirmation dialog"
                        onClick={closeEapSwitchConfirmation}
                    >
                        <DismissIcon className={classes.closeIcon} />
                    </Button>
                </DialogTrigger>
                <DialogBody>
                    <DialogTitle className={classes.titleSection}>
                        <div className={classes.titleContainer}>
                            <div className={classes.titleImage}>
                                <WarningIcon
                                    filled
                                    color={tokens.colorPaletteDarkOrangeForeground3}
                                    className={classes.icon}
                                />
                            </div>
                            <div>{adminLabel('switchCapacity.earlyExpiryConfirmation.Title')}</div>
                        </div>
                    </DialogTitle>
                    <DialogContent className={classes.contentSection}>
                        <div>
                            {adminLabel('switchCapacity.earlyExpiryConfirmation.Description')}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-test-id="eap-confirmation-cancel-button"
                            appearance="primary"
                            aria-label={adminLabel(
                                'switchCapacity.earlyExpiryConfirmation.ariaLabel.Cancel',
                            )}
                            onClick={closeEapSwitchConfirmation}
                        >
                            {commonLabel('ButtonLabels.Cancel')}
                        </Button>
                        <Button
                            data-test-id="confirm-end-eap-button"
                            appearance="outline"
                            aria-label={adminLabel(
                                'switchCapacity.earlyExpiryConfirmation.ariaLabel.ConfirmButton',
                            )}
                            onClick={confirmEarlyAccessClosure}
                        >
                            {adminLabel('switchCapacity.earlyExpiryConfirmation.Confirmation')}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
