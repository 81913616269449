import React from 'react';
import {TipsMessage} from './DailyTips.types';
import useClasses, {cardHoverFocus} from './DailyTips.styles';
import {useMemo, useState} from 'react';
import {DismissIcon} from '../icons';
import {Button, Text, Tooltip, mergeClasses} from '@fluentui/react-components';
import getRandomElementFromArray from '@/util/getRandomElement';
import {dailyTipMessages} from './DailyTips.messages';
import {useTranslation} from 'react-i18next';

function DailyTips() {
    const classes = useClasses();
    const {t} = useTranslation('session');
    const [isVisible, setIsVisible] = useState(true);
    const tip = useMemo(() => {
        return getRandomElementFromArray<TipsMessage>(dailyTipMessages);
    }, [dailyTipMessages]);
    const Icon = tip?.image;
    const title = tip?.title || '';
    const information = tip?.information || '';

    return (
        <>
            {isVisible && (
                <div className={classes.tipContainer}>
                    <div className={classes.content}>
                        <div className={mergeClasses(classes.alignedCenter, classes.tipTitleRow)}>
                            <h2 className={mergeClasses(classes.tipType, classes.alignedCenter)}>
                                {t(tip?.type || 'DailyTip.DailyTip')}
                            </h2>
                            <Tooltip
                                content={t('DailyTip.CloseDailyTip')}
                                relationship="label"
                                positioning="below"
                            >
                                <Button
                                    icon={<DismissIcon />}
                                    appearance="subtle"
                                    className={mergeClasses(classes.closeButton, cardHoverFocus)}
                                    onClick={() => {
                                        setIsVisible(false);
                                    }}
                                />
                            </Tooltip>
                        </div>
                        <Icon className={mergeClasses(classes.tipIcon, classes.alignedCenter)} />
                        <h3 className={mergeClasses(classes.tipTitle, classes.alignedCenter)}>
                            {t(title)}
                        </h3>
                        <Text className={classes.tipInformation}>{t(information)}</Text>
                    </div>
                </div>
            )}
        </>
    );
}

export default DailyTips;
