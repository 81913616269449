import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Accordion,
    Button,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerHeaderTitle,
} from '@fluentui/react-components';
import {FilterDrawerProps} from './Filter.types';
import useClasses from './Filter.styles';
import {useTranslation} from 'react-i18next';
import {DismissIcon} from '../icons';
import {FilterDrawerLabels} from './Filter.constants';

export default function FilterDrawer(props: FilterDrawerProps) {
    const classes = useClasses();
    const {t: tCommon} = useTranslation('common');
    const {
        onClose,
        filterTitle: FilterTitle,
        filterConfiguration: FilterConfiguration,
        onApplyAll,
        onResetAll,
        selectedFilters,
    } = props;

    const [internalSelectedFilters, setInternalSelectedFilters] = useState(selectedFilters);
    const [initialSelectedFilters, setInitialSelectedFilters] = useState(selectedFilters);

    useEffect(() => {
        setInternalSelectedFilters(selectedFilters); // Sync with the external state when opening the drawer
        setInitialSelectedFilters(selectedFilters); // Set the initial state
    }, [selectedFilters]);

    const handleSelectionChange = useCallback((key: string, selectedOptions: string[]) => {
        setInternalSelectedFilters((prevSelected) => {
            const newSelected = {...prevSelected, [key]: selectedOptions};
            if (selectedOptions.length === 0) {
                delete newSelected[key];
            }
            return newSelected;
        });
    }, []);

    const handleResetPanel = useCallback((key: string) => {
        setInternalSelectedFilters((prevSelected) => {
            const newSelected = {...prevSelected, [key]: []};
            delete newSelected[key];
            return newSelected;
        });
    }, []);

    const handleResetAll = useCallback(() => {
        setInternalSelectedFilters({});
        if (onResetAll) onResetAll();
        if (onClose) onClose();
    }, [onResetAll, onClose]);

    const handleApplyAll = useCallback(() => {
        if (onApplyAll) onApplyAll(internalSelectedFilters);
        if (onClose) onClose();
    }, [onApplyAll, onClose, internalSelectedFilters]);

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, [onClose]);

    const filterCount = Object.keys(internalSelectedFilters).length;
    const hasSelections = filterCount > 0;

    const hasChanges = useMemo(() => {
        return JSON.stringify(internalSelectedFilters) !== JSON.stringify(initialSelectedFilters);
    }, [internalSelectedFilters, initialSelectedFilters]);

    const memoizedAccordionItems = useMemo(() => {
        return FilterConfiguration?.map((filter, index) => {
            return React.cloneElement(filter.node as React.ReactElement<any>, {
                selectedOptions:
                    internalSelectedFilters[
                        (filter.node as React.ReactElement<any>).props.category
                    ] || [],
                onSelectionChange: (selectedOptions: string[]) => {
                    handleSelectionChange(
                        (filter.node as React.ReactElement<any>).props.category,
                        selectedOptions,
                    );
                },
                onReset: () =>
                    handleResetPanel((filter.node as React.ReactElement<any>).props.category),
                key: index,
            });
        });
    }, [FilterConfiguration, internalSelectedFilters, handleSelectionChange, handleResetPanel]);

    return (
        <>
            <DrawerHeader>
                <DrawerHeaderTitle
                    className={classes.drawerHeader}
                    action={
                        <Button
                            appearance="subtle"
                            aria-label={tCommon(FilterDrawerLabels.Close)}
                            icon={<DismissIcon />}
                            onClick={handleClose}
                            className={classes.drawerClose}
                        />
                    }
                >
                    {FilterTitle}
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody className={classes.drawerBody}>
                <div className={classes.filterRoot}>
                    <div className={classes.filterContainer}>
                        <Accordion multiple collapsible className={classes.accordion}>
                            {memoizedAccordionItems}
                        </Accordion>
                    </div>
                </div>
            </DrawerBody>
            <DrawerFooter className={classes.drawerFooter}>
                <Button
                    className={classes.leftButtons}
                    onClick={handleResetAll}
                    disabled={!hasSelections}
                >
                    {tCommon(FilterDrawerLabels.ResetAll)}
                </Button>
                <div className={classes.rightButtons}>
                    <Button onClick={handleClose}>{tCommon(FilterDrawerLabels.Cancel)}</Button>
                    <Button appearance="primary" onClick={handleApplyAll} disabled={!hasChanges}>
                        {hasChanges && hasSelections
                            ? `${tCommon(FilterDrawerLabels.Apply)} ${
                                  filterCount === 1
                                      ? `${filterCount} ${tCommon(FilterDrawerLabels.Filter)}`
                                      : `${filterCount} ${tCommon(FilterDrawerLabels.Filters)}`
                              }`
                            : tCommon(FilterDrawerLabels.ApplyFilters)}
                    </Button>
                </div>
            </DrawerFooter>
        </>
    );
}
