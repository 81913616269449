import {useMemo, useEffect, useState} from 'react';
import {Toolbar, ToolbarButton, Checkbox, Tooltip, mergeClasses} from '@fluentui/react-components';
import {useGetPrompts, PromptType} from '@/api/prompts';
import useClasses from './SessionToolbar.styles';
import {SessionToolbarProps} from './SessionToolbar.types';
import {useCreatePinnedEvaluations} from '@/api/evaluations';
import {CreatePromptbookIcon, DeleteIcon, PinIcon, RerunIcon} from '@/components/ui/icons';
import {useRerunPrompts} from '@/api/prompts';
import PinboardToggleButton from '@/components/ui/SidePanel/ToggleButton';
import ContentWidthButton from '@/components/ui/Layout/ContentWidthButton';
import {useLayout} from '@/components/ui/Layout';
import {PromptbookDescriptor} from '@/api/promptbooks';
import {
    PromptbookCloseResult,
    PromptbookCreateForm,
    PromptbookCreateSuccessForm,
    PromptbookOperationMode,
} from '@/components/sections/promptbooks';
import {useTranslation} from 'react-i18next';
import {useViewportSize} from '@/components/ui/Grid';
import useSessionViewMode, {shouldShowWideView} from './useSessionViewMode';
import AgentViewButton from '@/components/ui/Layout/AgentViewButton';
import Agent from '@/components/sections/agents/Agent';
import {useFeatureFlag} from '@/api/user';
import {MedeinaUrlParamFeatures} from '@/util/features';

export * from './Session.types';

// This is used for prompts associated with the session. The operations are Select, delete, rerun, pin and create promptbook
export default function SessionPrompts({
    sessionId,
    isReadOnly,
    selection,
    onSetDeleteMode,
}: SessionToolbarProps) {
    // Setup app layout management
    const {isFullWidth: viewModeFull} = useSessionViewMode();
    const {sidePanel} = useLayout();
    const {t} = useTranslation('session');
    const {toggleOpen} = sidePanel || {toggleOpen: () => {}};
    // Instantiate styles
    const classes = useClasses();

    // Get viewport size
    const screen = useViewportSize();
    const forcedFullWidth = shouldShowWideView(screen.activeViewport);
    // Retrieve session data
    const {data: prompts, refetch, isLoading: isGetPromptsLoading} = useGetPrompts({sessionId});

    const {mutate: createPinnedEvaluations} = useCreatePinnedEvaluations();
    const {mutate: rerunPrompts} = useRerunPrompts();

    // Promptbook related
    const [showPromptbookDialog, setShowPromptbookDialog] = useState<boolean>(false);
    const [showPromptbookCreateSuccessDialog, setShowPromptbookCreateSuccessDialog] =
        useState<boolean>(false);
    const [enablePromptbookCreate, setEnablePromptbookCreate] = useState<boolean>(false);

    const selectedRows = useMemo(() => selection?.selectedRows || [], [selection?.selectedRows]);

    // Cache the selected rows.
    const promptIds = useMemo<string[]>(() => Array.from(selectedRows) as string[], [selectedRows]);

    // Feature flags
    const isAgentViewEnabled = useFeatureFlag(MedeinaUrlParamFeatures.AgentView);

    useEffect(() => {
        if (!isGetPromptsLoading && promptIds) {
            if (
                promptIdsWithEvaluationIdsForPromptbooks &&
                promptIdsWithEvaluationIdsForPromptbooks.length > 0
            ) {
                setEnablePromptbookCreate(true);
                setPromptbookProps({
                    ...promptbookProps,
                    promptIds: promptIdsWithEvaluationIdsForPromptbooks,
                });
            } else {
                setEnablePromptbookCreate(false);
            }
        }
    }, [promptIds, isGetPromptsLoading]);

    const checkBoxLabel = selection?.allRowsSelected
        ? t('UnselectAllPromptsText')
        : t('SelectAllPromptsText');

    const onCloseCreatePromptbookForm = (
        result: PromptbookCloseResult,
        promptbookDefn?: PromptbookDescriptor,
    ) => {
        switch (result) {
            case PromptbookCloseResult.success:
                setShowPromptbookDialog(false);
                const newProps = {
                    ...promptbookProps,
                    promptbookId: (promptbookDefn && promptbookDefn.promptbookId) ?? '',
                    open: true,
                };
                setPromptbookProps(newProps);
                setShowPromptbookCreateSuccessDialog(true);
                break;
            case PromptbookCloseResult.failed:
                break;
            case PromptbookCloseResult.closed:
                setPromptbookProps({...promptbookProps, promptbookId: '', open: false});
                setShowPromptbookDialog(false);
                setShowPromptbookCreateSuccessDialog(false);
                break;
        }
    };

    const promptIdsWithEvaluationIdsForPromptbooks = useMemo(() => {
        if (
            !isGetPromptsLoading &&
            promptIds.length > 0 &&
            prompts?.value &&
            prompts?.value?.length > 0
        ) {
            return prompts.value
                ?.filter(
                    (prompt) =>
                        promptIds.includes(prompt.promptId) &&
                        prompt.promptType === PromptType.Prompt &&
                        prompt.latestEvaluationId,
                )
                .map((prompt) => prompt.promptId);
        }

        return [];
    }, [promptIds, isGetPromptsLoading]);

    // This is used to get the selected prompts to create promptbook
    const [promptbookProps, setPromptbookProps] = useState({
        open: showPromptbookDialog,
        promptIds: promptIdsWithEvaluationIdsForPromptbooks,
        sessionId: sessionId,
        onClose: onCloseCreatePromptbookForm,
        promptbookId: '',
        mode: PromptbookOperationMode.Create,
    });

    // State to track whether the Agent component should be displayed
    const [showAgent, setShowAgent] = useState(false);

    return (
        <div className={classes.toolbar} data-testid="session-toolbar">
            <div className={mergeClasses(classes.toolbarContent)}>
                <div
                    className={mergeClasses(
                        classes.toolbarSelect,
                        viewModeFull && classes.toolbarSelectFullWidth,
                    )}
                >
                    <Checkbox
                        size="medium"
                        checked={
                            selection?.allRowsSelected
                                ? true
                                : selection?.someRowsSelected
                                ? 'mixed'
                                : false
                        }
                        disabled={isReadOnly}
                        onClick={selection?.toggleAllRows}
                        aria-label={checkBoxLabel}
                    />
                </div>
                <div
                    className={mergeClasses(
                        classes.toolbarButtons,
                        viewModeFull && classes.toolbarButtonsFullWidth,
                    )}
                >
                    <Toolbar className={classes.toolbarButtonsWrapper}>
                        <Tooltip content={t('PinSummaryButton')} relationship="label">
                            <ToolbarButton
                                icon={<PinIcon />}
                                disabled={isReadOnly || !selection?.someRowsSelected}
                                onClick={() => {
                                    toggleOpen?.();
                                    createPinnedEvaluations({
                                        sessionId,
                                        promptIds,
                                    });
                                }}
                            />
                        </Tooltip>
                        <Tooltip content={t('RerunPromptsButton')} relationship="label">
                            <ToolbarButton
                                icon={<RerunIcon />}
                                disabled={isReadOnly || !selection?.someRowsSelected}
                                onClick={() =>
                                    rerunPrompts({
                                        sessionId,
                                        promptIds,
                                    })
                                }
                            />
                        </Tooltip>
                        <Tooltip content={t('DeletePromptsButton')} relationship="label">
                            <ToolbarButton
                                icon={<DeleteIcon />}
                                disabled={isReadOnly || !selection?.someRowsSelected}
                                onClick={() => onSetDeleteMode('Rerun')}
                            />
                        </Tooltip>
                        <Tooltip content={t('CreatePromptbook')} relationship="label">
                            <ToolbarButton
                                icon={<CreatePromptbookIcon />}
                                disabled={
                                    isReadOnly ||
                                    isGetPromptsLoading ||
                                    !selection?.someRowsSelected ||
                                    !enablePromptbookCreate
                                }
                                onClick={() => {
                                    setShowPromptbookDialog(true);
                                    setPromptbookProps({
                                        ...promptbookProps,
                                        promptIds: promptIdsWithEvaluationIdsForPromptbooks,
                                        open: true,
                                    });
                                }}
                            />
                        </Tooltip>
                    </Toolbar>

                    {showPromptbookDialog && <PromptbookCreateForm {...promptbookProps} />}
                    {showPromptbookCreateSuccessDialog && (
                        <PromptbookCreateSuccessForm {...promptbookProps} />
                    )}
                </div>
                <div className={classes.toolbarBumper} />
                <div className={classes.toolbarRight}>
                    {!forcedFullWidth && <ContentWidthButton />}
                    {isAgentViewEnabled && (
                        <AgentViewButton showAgent={showAgent} setShowAgent={setShowAgent} />
                    )}
                    <PinboardToggleButton />
                </div>
            </div>
            {showAgent && <Agent sessionId={sessionId} />}
        </div>
    );
}
