import {NavigationClient, NavigationOptions} from '@azure/msal-browser';
import {NavigateFunction} from 'react-router-dom';

// Overrides the default function that MSAL uses to navigate to URLs.
export default class CustomNavigationClient extends NavigationClient {
    public navigate: NavigateFunction;

    constructor(navigate: NavigateFunction) {
        super();
        this.navigate = navigate;
    }

    // Navigates to other pages within the same web application leveraging react-router.
    async navigateInternal(url: string, options: NavigationOptions) {
        const relativePath = url.replace(window.location.origin, '');
        if (options.noHistory) {
            this.navigate(relativePath, {replace: true});
        } else {
            this.navigate(relativePath);
        }

        return false;
    }
}
