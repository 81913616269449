import {useQuery} from '@tanstack/react-query';
import {customFetch, PageResponse} from '../api';
import {SkillsetRequirementsCheckResult} from './skills.types';

export default function useCheckRequirements() {
    return useQuery({
        queryKey: ['skillsets', 'requirements'],
        queryFn: async () => {
            return await customFetch<PageResponse<SkillsetRequirementsCheckResult>>(
                `/skillsets/checkRequirements`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
