import {
    Dialog,
    DialogSurface,
    DialogContent,
    Tooltip,
    ToolbarButton,
    Dropdown,
    Option,
} from '@fluentui/react-components';
import {ModelSwitcherIcon} from '@/components/ui/icons';
import {useCreateEvaluation} from '@/api/evaluations';
import useClasses from './SwitchPromptModelDialog.styles';
import {SwitchPromptModelDialogProps} from './SwitchPromptModelDialog.types';
import {useEffect, useRef, useState} from 'react';
import {useErrorMessages, useErrorMessagesWorkspaces} from '@/api/errors';
export * from './RerunPromptDialog.types';
import {useTranslation} from 'react-i18next';
import {getSessionFeatureFlags, setSessionFeatureFlags} from '@/util/hasStaticFeatureFlags';
import {PromptCategory, PromptType, useCreatePrompt, useGetPrompt} from '@/api/prompts';
import {SourceType} from '@/api/sessions';
import {useLayout} from '@/components/ui/Layout';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export default function SwitchPromptModelDialog({
    sessionId,
    promptId,
    open,
    onClose = () => null,
    setErrorMessage = () => null,
}: SwitchPromptModelDialogProps) {
    const classes = useClasses();
    const {t} = useTranslation('session');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    const {getErrorMessage} = useErrorMessages();
    const {getErrorMessageWorkspaces} = useErrorMessagesWorkspaces();
    const {mutate: createPrompt, isError: isCreatePromptError, reset} = useCreatePrompt();
    const {mutate: createEvaluation, isError, error} = useCreateEvaluation();
    const {data: prompt} = useGetPrompt({sessionId, promptId});

    const {contentArea} = useLayout();
    // This is only used for focusing, not managing dialog `open` state.
    const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
    const [model, setModel] = useState<string>('gpt-4-32k-v0613 (Default)');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    // Define the options for the dropdown
    const modelFeatureFlags: {[key: string]: string} = {
        'gpt-4o': 'Enable4oEndpoint',
        'gpt-4-32k-v0613 (Default)': '',
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        // Focus on first visible render.
        if (isOpen && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [isOpen, buttonRef.current]);

    useEffect(() => {
        if (isError) {
            if (isWorkspacesTestingEnabled) {
                var message = getErrorMessageWorkspaces((error as any).response.status);
                setErrorMessage(message);
            } else {
                var message = getErrorMessage((error as any).response.status);
                setErrorMessage(message);
            }
        }
    }, [isError]);

    const handleModelChange = (value: string) => {
        setModel(value);

        var flags = getSessionFeatureFlags();
        // remove any existing model feature flags from the list
        if (flags.includes('Enable4oEndpoint')) {
            flags = flags.filter((flag) => flag !== 'Enable4oEndpoint');
        }
        modelFeatureFlags[value] !== '' ? flags.push(modelFeatureFlags[value]) : null;
        setSessionFeatureFlags(flags);
        setIsDropdownOpen(false);

        createPrompt(
            {
                sessionId,
                source: SourceType.Immersive,
                promptType: PromptType.Prompt,
                promptCategory: PromptCategory.UserGenerated,
                ...prompt,
            },
            {
                onSuccess: (prompt) => {
                    createEvaluation(
                        {
                            sessionId,
                            promptId: prompt.promptId,
                        },
                        {
                            onSuccess: () => {
                                contentArea.scroll?.toBottom?.(); // scrolling to latest prompt at bottom of screen.
                            },
                        },
                    );
                },
            },
        );
    };

    return (
        <>
            <Tooltip content={'Change AOAI Model'} relationship="label" positioning="after">
                <ToolbarButton
                    appearance="subtle"
                    icon={<ModelSwitcherIcon />}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                />
            </Tooltip>
            {isDropdownOpen && (
                <Dialog open={true} onOpenChange={() => setIsDropdownOpen(false)}>
                    <DialogSurface className={classes.root}>
                        <DialogContent>
                            <h4
                                className={classes.titleContent}
                                // data-test-id="expiry subHeading"
                            >
                                Switch AOAI model:
                            </h4>
                            <Dropdown
                                className={classes.dropdown}
                                placeholder={model}
                                onOptionSelect={(event: any, data: any) => {
                                    handleModelChange(data.optionText);
                                }}
                            >
                                {Object.entries(modelFeatureFlags).map(
                                    ([modelName, featureFlag]) => (
                                        <Option key={modelName} value={featureFlag}>
                                            {modelName}
                                        </Option>
                                    ),
                                )}
                            </Dropdown>
                        </DialogContent>
                    </DialogSurface>
                </Dialog>
            )}
        </>
    );
}
