import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    header: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalM),
        maxWidth: '100%',
        marginBottom: tokens.spacingVerticalM,
    },
    titleWrapper: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
        ...shorthands.gap(tokens.spacingHorizontalM),
    },
    title: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: tokens.fontSizeBase600,
        lineHeight: tokens.lineHeightBase600,
    },
    backButton: {
        maxWidth: 'min-content',
        boxSizing: 'border-box',
    },
    closeButton: {
        maxWidth: 'min-content',
        boxSizing: 'border-box',
    },
    icon: {
        fontSize: tokens.fontSizeBase600,
        height: '24px',
        width: 'auto',
        maxHeight: '24px',
        maxWidth: '24px',
    },
});
