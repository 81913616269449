import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    radioGroup: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    themeOption: {
        ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingVerticalM),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground2),
        boxShadow: tokens.shadow16,
    },
    radioRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    label: {
        marginRight: 'auto',
        textSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
    },
    selected: {
        ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingVerticalM),
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorBrandForeground1),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
    },
});
