import * as React from 'react';
import {Button, Text} from '@fluentui/react-components';
import {SkillsetsIcon} from '@/components/ui/icons';
import useClasses from '../../ConnectorModalContent.styles';
import {SkillsetFilterTypes} from '@/api/skills';
import {useTranslation} from 'react-i18next';

interface EmptySearchComponentProps {
    isUserSearching: boolean;
    displayedSkillsetsFilter: SkillsetFilterTypes;
    onAddPluginClick: () => void;
    addPluginButtonRef: React.RefObject<HTMLButtonElement>;
}

export default function EmptySearchComponent({
    isUserSearching,
    displayedSkillsetsFilter,
    onAddPluginClick,
    addPluginButtonRef,
}: EmptySearchComponentProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    var titleText = t('ContentModal.PluginNotFound');
    var descriptionText = t('ContentModal.PluginNotFoundDescription');
    if (!isUserSearching) {
        if (displayedSkillsetsFilter === SkillsetFilterTypes.On) {
            // No enabled skillsets. All are off
            titleText = t('ContentModal.AllPluginsTurnedOffTitle');
            descriptionText = t('ContentModal.AllPluginsTurnedOffDescription');
        } else if (displayedSkillsetsFilter === SkillsetFilterTypes.Off) {
            // No disabled skillsets. All are on
            titleText = t('ContentModal.AllPluginsTurnedOnTitle');
            descriptionText = t('ContentModal.AllPluginsTurnedOnDescription');
        } else {
            // No skillsets found.
            titleText = t('ContentModal.AllPluginsNotFoundTitle');
            descriptionText = t('ContentModal.AllPluginsNotFoundDescription');
        }
    }

    return (
        <div className={classes.emptySearch}>
            <Text className={classes.emptySearchText}>{titleText}</Text>
            <Text className={classes.emptySearchText2}>{descriptionText}</Text>
            {isUserSearching && (
                <Button
                    className={classes.connectServicesButton}
                    icon={<SkillsetsIcon className={classes.connectionIcon} />}
                    onClick={onAddPluginClick}
                    appearance="subtle"
                    size="medium"
                    ref={addPluginButtonRef}
                >
                    {t('ContentModal.AddPlugin')}
                </Button>
            )}
        </div>
    );
}
