import {useMemo} from 'react';
import {GeoName, RegionName} from '@/components/App.types';
import {GEO_DATA, REGION_DATA} from '@/components/App.constants';

// This hook is used to get the list of locations that are available for selection in the Set up Capacity of FRE experience and Create Capacity modal pop up.
export default function useLocations() {
    const geoData = GEO_DATA;
    const locationData = REGION_DATA;

    const geoOptions = useMemo(() => {
        return Object.keys(geoData)
            .reduce((acc, key) => {
                const item = geoData[key as GeoName];
                if (!item.hideFromDisplay) {
                    acc.push({
                        key: key,
                        displayName: item.displayName,
                    });
                }
                return acc;
            }, [] as Array<{key: string; displayName: string}>)
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
    }, [geoData]);

    const getRegionDisplayName = (regionName: string) => {
        const log = regionName as RegionName;
        return locationData[regionName as RegionName].displayName;
    };

    return {
        geoOptions,
        getRegionDisplayName,
    };
}
