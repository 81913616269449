// Shared styles for stage layouts and fonts/type
import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    popOver: {
        width: 'max-content',
        maxWidth: '288px',

        '@media (max-width: 479px)': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
        },
    },
    stack: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});
