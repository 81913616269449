import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {CreatePinnedEvaluationRequest, PinnedEvaluation} from './evaluations.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
export default function useCreatePinnedEvaluation() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: CreatePinnedEvaluationRequest) => {
            return await customFetch<PinnedEvaluation>(
                `/sessions/${body.sessionId}/pinnedevaluations`,
                {
                    method: 'POST',
                    body,
                },
            );
        },
        onSettled: (evaluation, error, body) => {
            // Refetch anything using pinned evaluations.
            queryClient.refetchQueries({
                queryKey: ['sessions', body.sessionId, 'pinnedevaluations'],
            });
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
    });
}
