import * as React from 'react';
const SafetyPin = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path stroke="#DACAEC" strokeWidth="3.4" d="m22.66 17.098l-9.737 9.661" />
            <path stroke="url(#f2144id0)" strokeWidth="3.4" d="m22.66 17.098l-9.737 9.661" />
            <path stroke="url(#f2144idc)" strokeWidth="3.4" d="m22.66 17.098l-9.737 9.661" />
            <g filter="url(#f2144id8)">
                <path
                    fill="#D0C5DD"
                    fillRule="evenodd"
                    d="M13.627 8.42L4.38 17.59l.007.007a7.116 7.116 0 1 0 6.5-1.671l5.135-5.093l-2.394-2.414ZM5.48 22.84a3.716 3.716 0 1 1 7.433 0a3.716 3.716 0 0 1-7.433 0Z"
                    clipRule="evenodd"
                />
            </g>
            <path
                fill="url(#f2144id1)"
                d="m13.852 12.986l-.013-.013l-2.953 2.954l-4.02 4.02a3.716 3.716 0 0 1 4.791 5.68l2.407 2.406a7.12 7.12 0 0 0-3.178-12.106l2.966-2.942Z"
            />
            <path
                fill="url(#f2144idd)"
                d="m13.852 12.986l-.013-.013l-2.953 2.954l-4.02 4.02a3.716 3.716 0 0 1 4.791 5.68l2.407 2.406a7.12 7.12 0 0 0-3.178-12.106l2.966-2.942Z"
            />
            <path
                fill="url(#f2144id2)"
                d="M16 10.813L13.627 8.42L4.38 17.59l.007.007a7.114 7.114 0 0 0-2.073 3.433l3.5-2.125l.49 1.604a3.73 3.73 0 0 1 .563-.563L16 10.813Z"
            />
            <path
                fill="url(#f2144id3)"
                d="M16 10.813L13.627 8.42L4.38 17.59l.007.007a7.114 7.114 0 0 0-2.073 3.433l3.5-2.125l.49 1.604a3.73 3.73 0 0 1 .563-.563L16 10.813Z"
            />
            <g filter="url(#f2144id9)">
                <path stroke="url(#f2144id4)" d="M14.719 9.402L5.47 18.573" />
            </g>
            <g filter="url(#f2144ida)">
                <circle cx="9.303" cy="22.616" r="5.416" stroke="url(#f2144id5)" />
            </g>
            <g filter="url(#f2144idb)">
                <path
                    fill="url(#f2144id6)"
                    d="M16.12 4.407a8 8 0 0 1 11.314 0l.124.124a8 8 0 0 1 .198 11.108l-.002.025c-1.422 1.422-2.837 2.873-4.242 4.28a.25.25 0 0 1-.353.001l-3.53-3.515l.008-.008l-.48-.48a3.697 3.697 0 1 1 5.234-5.221l.138.122c.09.08.23.06.272-.052c.267-.73-.166-2.027-.883-2.744a2.895 2.895 0 0 0-4.094 0l-2.345 2.344a2.75 2.75 0 0 1-3.889 0l-1.55-1.55a.25.25 0 0 1 0-.354l4.08-4.08Z"
                />
                <path
                    fill="url(#f2144id7)"
                    d="M16.12 4.407a8 8 0 0 1 11.314 0l.124.124a8 8 0 0 1 .198 11.108l-.002.025c-1.422 1.422-2.837 2.873-4.242 4.28a.25.25 0 0 1-.353.001l-3.53-3.515l.008-.008l-.48-.48a3.697 3.697 0 1 1 5.234-5.221l.138.122c.09.08.23.06.272-.052c.267-.73-.166-2.027-.883-2.744a2.895 2.895 0 0 0-4.094 0l-2.345 2.344a2.75 2.75 0 0 1-3.889 0l-1.55-1.55a.25.25 0 0 1 0-.354l4.08-4.08Z"
                />
            </g>
            <path
                fill="url(#f2144ide)"
                d="M16.12 4.407a8 8 0 0 1 11.314 0l.124.124a8 8 0 0 1 .198 11.108l-.002.025c-1.422 1.422-2.837 2.873-4.242 4.28a.25.25 0 0 1-.353.001l-3.53-3.515l.008-.008l-.48-.48a3.697 3.697 0 1 1 5.234-5.221l.138.122c.09.08.23.06.272-.052c.267-.73-.166-2.027-.883-2.744a2.895 2.895 0 0 0-4.094 0l-2.345 2.344a2.75 2.75 0 0 1-3.889 0l-1.55-1.55a.25.25 0 0 1 0-.354l4.08-4.08Z"
            />
            <defs>
                <linearGradient
                    id="f2144id0"
                    x1="16.625"
                    x2="19.281"
                    y1="19.625"
                    y2="22.438"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".181" stopColor="#93919B" />
                    <stop offset="1" stopColor="#C7BBD6" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2144id1"
                    x1="6.094"
                    x2="9.886"
                    y1="20.503"
                    y2="20.852"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".152" stopColor="#B9A5CD" />
                    <stop offset="1" stopColor="#B9A5CD" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2144id2"
                    x1="15.688"
                    x2="13.922"
                    y1="11"
                    y2="12.766"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B4A0C9" />
                    <stop offset="1" stopColor="#B4A0C9" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2144id3"
                    x1="11.75"
                    x2="10.219"
                    y1="16.5"
                    y2="14.969"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".398" stopColor="#C5B5D8" />
                    <stop offset="1" stopColor="#C5B5D8" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2144id4"
                    x1="3.688"
                    x2="9.475"
                    y1="15.75"
                    y2="17.192"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DFD4EC" />
                    <stop offset="1" stopColor="#DFD4EC" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2144id5"
                    x1="6.563"
                    x2="9.303"
                    y1="25.875"
                    y2="16.875"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DFD4EC" />
                    <stop offset="1" stopColor="#DFD4EC" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2144id6"
                    x1="20.934"
                    x2="16.63"
                    y1="10.03"
                    y2="5.037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CEC2DD" />
                    <stop offset="1" stopColor="#B5AFBF" />
                </linearGradient>
                <linearGradient
                    id="f2144id7"
                    x1="12.563"
                    x2="15.344"
                    y1="7.188"
                    y2="9.344"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".127" stopColor="#9C9B9F" />
                    <stop offset="1" stopColor="#B0ABB8" stopOpacity="0" />
                </linearGradient>
                <filter
                    id="f2144id8"
                    width="14.883"
                    height="22.188"
                    x="2.08"
                    y="7.77"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".65" />
                    <feGaussianBlur stdDeviation=".6" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.635294 0 0 0 0 0.631373 0 0 0 0 0.647059 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_26095" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-.65" />
                    <feGaussianBlur stdDeviation=".6" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.647059 0 0 0 0 0.811765 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_26095"
                        result="effect2_innerShadow_18_26095"
                    />
                </filter>
                <filter
                    id="f2144id9"
                    width="10.952"
                    height="10.881"
                    x="4.619"
                    y="8.547"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_26095" stdDeviation=".25" />
                </filter>
                <filter
                    id="f2144ida"
                    width="12.833"
                    height="12.833"
                    x="2.886"
                    y="16.2"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_26095" stdDeviation=".25" />
                </filter>
                <filter
                    id="f2144idb"
                    width="17.934"
                    height="18.204"
                    x="11.967"
                    y="1.814"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-.25" />
                    <feGaussianBlur stdDeviation=".25" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.690196 0 0 0 0 0.635294 0 0 0 0 0.752941 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_26095" />
                </filter>
                <radialGradient
                    id="f2144idc"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-3.317 3.42898 -2.1829 -2.1116 22.66 18.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AB93C0" />
                    <stop offset="1" stopColor="#AB93C0" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2144idd"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(4.78125 -4.8125 7.32972 7.28214 10.688 22.219)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".548" stopColor="#A8A5B0" stopOpacity="0" />
                    <stop offset=".955" stopColor="#A8A5B0" />
                </radialGradient>
                <radialGradient
                    id="f2144ide"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-8.45099 4.85188 -5.92359 -10.31768 31.304 6.189)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".258" stopColor="#E0DFE4" />
                    <stop offset="1" stopColor="#E0DFE4" stopOpacity="0" />
                </radialGradient>
            </defs>
        </g>
    </svg>
);
export default SafetyPin;
