import React from 'react';
import {motion} from 'framer-motion';
import useClasses from './BottomScreenGradients.styles';
import {ColorScheme} from '@/api/app';
import {GradientProps} from './Background.types';

const GradientAnimation = (props: GradientProps) => {
    const classes = useClasses();
    return (
        <>
            <motion.div
                aria-hidden
                className={classes.leftGradient}
                style={{
                    backgroundImage: `url("/images/gradients/${
                        props.colorScheme == ColorScheme.Light ? 'light' : 'dark'
                    }_left.svg")`,
                }}
                initial={{
                    scale: 0,
                }}
                animate={{
                    x: ['-150px'],
                    scale: [0.3, 1, 0.6],
                    transition: {
                        scale: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 55,
                        },
                    },
                }}
            />
            <motion.div
                aria-hidden
                className={classes.centerGradient}
                style={{
                    backgroundImage: `url("/images/gradients/${
                        props.colorScheme == ColorScheme.Light ? 'light' : 'dark'
                    }_center.svg")`,
                }}
                initial={{
                    scale: 0,
                }}
                animate={{
                    x: ['100px'],
                    scale: [1, 0.6, 0.7],
                    transition: {
                        scale: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 45,
                        },
                    },
                }}
            />
            <motion.div
                aria-hidden
                className={classes.rightGradient}
                style={{
                    backgroundImage: `url("/images/gradients/${
                        props.colorScheme == ColorScheme.Light ? 'light' : 'dark'
                    }_right.svg")`,
                }}
                initial={{
                    scale: 0,
                }}
                animate={{
                    x: ['250px'],
                    scale: [0.8, 0.3, 1],
                    transition: {
                        scale: {
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 50,
                        },
                    },
                }}
            />
        </>
    );
};

export default GradientAnimation;
