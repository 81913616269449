import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Session} from './sessions.types';
import MedeinaFeatures from '@/util/features';
export default function useBumpSessionUpdatedAtUntilInvestigationsExist() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (sessionId: string) => {
            if (sessionId === undefined) {
                // This condition should not happen.
                throw new Error('The sessionId is undefined here');
            }

            // Perform the actual API call to update the session
            if (MedeinaFeatures.ChangeSessionUpdatedAt) {
                return customFetch<Session>(`/sessions/${sessionId}`, {
                    method: 'PUT',
                    body: {sessionId},
                })
                    .then((updatedSession) => {
                        return updatedSession;
                    })
                    .catch((error) => {
                        throw error;
                    });
            }
        },

        //Callback function called after the mutation is settled which refreshes the queries to reflect on UI
        onSettled: (updatedSession, error, variables, context) => {
            if (!error) {
                //invalidate Queries, Lazy refetch from server
                // the next time the query is accessed, it will trigger a refetch to fetch fresh data from the server
                queryClient.invalidateQueries(['session', variables]);
                queryClient.invalidateQueries(['sessions']);
            }
        },
    });
}
