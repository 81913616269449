import React, {useEffect, useRef} from 'react';
import {
    Dialog,
    DialogBody,
    DialogSurface,
    Spinner,
    useFocusFinders,
} from '@fluentui/react-components';
import useClasses from './TenantSwitchOverlay.styles';
import {useTranslation} from 'react-i18next';

interface TenantSwitchOverlayProps {
    currentTenant: string;
}

export default function TenantSwitchOverlay({currentTenant}: TenantSwitchOverlayProps) {
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const {findFirstFocusable} = useFocusFinders();
    const classes = useClasses();
    const {t} = useTranslation('common');

    useEffect(() => {
        if (dialogRef.current) {
            findFirstFocusable(dialogRef.current)?.focus();
        }
    }, [dialogRef, findFirstFocusable]);

    return (
        <Dialog open>
            <DialogSurface>
                <DialogBody className={classes.root} ref={dialogRef}>
                    <Spinner
                        labelPosition="below"
                        size="extra-large"
                        label={t('TenantSwitchingToOrgLabel', {0: currentTenant})}
                        tabIndex={0}
                    />
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
