import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {DismissIcon} from '@/components/ui/icons';
import {Button, Menu, MenuItem, MenuTrigger} from '@fluentui/react-components';
import {PillMenuItems} from './PillMenuItems';
import {removeFilterByCategory, removeFilterByValue} from './Filter.utils';
import useGetSelectedFilterPillsLabels from './useGetSelectedFilterPillsLabels';
import {SelectedFilterPillAriaLabelsPrefix} from '../UsageDashboard.constants';
import {useTranslation} from 'react-i18next';
import useClasses from './FilterPills.styles';

interface FilterPillsProps {
    filters: SelectedSearchFacet[];
    onFiltersUpdated: (filters: SelectedSearchFacet[]) => void;
    copilotExperienceMap: KeyValueFilterDetails[];
}

interface FilterMenuItemProps {
    filter: SelectedSearchFacet;
    index: number;
    filterPillsProps: FilterPillsProps;
}

export const FilterMenuItem: React.FC<FilterMenuItemProps> = (
    menuItemProps: FilterMenuItemProps,
) => {
    const {filter, index, filterPillsProps} = menuItemProps;
    const {filters, copilotExperienceMap} = filterPillsProps;

    const {multiSelectedLabels, singleSelectedLabels} = useGetSelectedFilterPillsLabels({
        filters,
        copilotExperienceMap,
    });

    const classes = useClasses();
    const {t} = useTranslation('admin');

    if (filter?.values?.length == 1) {
        return (
            <MenuItem
                key={index}
                className={classes.menuItem}
                secondaryContent={
                    <Button
                        icon={<DismissIcon />}
                        size="small"
                        appearance="transparent"
                        className={classes.dismissButton}
                        onClick={() => {
                            const updatedFilters = removeFilterByCategory(
                                filter.category,
                                filterPillsProps.filters,
                            );
                            filterPillsProps.onFiltersUpdated(updatedFilters);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                const updatedFilters = removeFilterByCategory(
                                    filter.category,
                                    filterPillsProps.filters,
                                );
                                filterPillsProps.onFiltersUpdated(updatedFilters);
                            }
                        }}
                        aria-label={t(`${SelectedFilterPillAriaLabelsPrefix}.RemoveFilter`)}
                    />
                }
            >
                <div className={classes.nestedMenuContent}>
                    {singleSelectedLabels[filter.category]}
                </div>
            </MenuItem>
        );
    } else {
        return (
            <Menu key={index}>
                <MenuTrigger disableButtonEnhancement>
                    <MenuItem key={index}>{multiSelectedLabels[filter.category]}</MenuItem>
                </MenuTrigger>
                <PillMenuItems
                    filter={filter}
                    onFilterRemoval={(category, value) => {
                        const updatedFilters = removeFilterByValue(
                            category,
                            value,
                            filterPillsProps.filters,
                        );
                        filterPillsProps.onFiltersUpdated(updatedFilters);
                    }}
                    index={index}
                    copilotExperienceMap={filterPillsProps.copilotExperienceMap}
                ></PillMenuItems>
            </Menu>
        );
    }
};
