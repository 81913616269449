import {useContext, useMemo} from 'react';
import {UserStateContext} from './user';
import {
    MedeinaFeatureFlagReference,
    MedeinaFeatureFlagValue,
    MedeinaMetaFeatures,
    MedeinaUserFeatures,
} from '@/util/features';
import {getStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import useFeatureLaunchExperience from './useFeatureLaunchExperience';

// These default to `{}` to ensure tests that partially mock `@/util/features` don't fail.
const MedeinaUserFeaturesKeysValues = new Set(
    Object.entries(MedeinaUserFeatures ?? {})
        .map(([key, value]) => [key.toLowerCase(), value.toLowerCase()])
        .flat(),
);
const MedeinaMetaFeaturesKeysValues = new Set(Object.entries(MedeinaMetaFeatures ?? {}).flat());

// Checks compiled, URL param, and dynamic runtime user feature flags for access.
// This will return first whether _all_ flags are truthy, then individual flags.
// Note: This can only be used for React code. For non-React code, use `hasStaticFeatureFlags`.
// Usage:
//   const [enabled, isVerboseLoggingEnabled] = useFeatureFlags([MedeinaFeatures.EnableVerboseLogging]);
export default function useFeatureFlags(
    references: Array<MedeinaFeatureFlagReference>,
): Array<MedeinaFeatureFlagValue> {
    // Grab user feature flags.
    const {featureFlags} = useContext(UserStateContext);
    const launchExperienceFeatures = useFeatureLaunchExperience();

    return useMemo(() => {
        const results = references.map(
            (reference) => {
                if (typeof reference === 'string') {
                    const referenceLower = reference.toLowerCase();

                    // Handle known user feature flags.
                    if (MedeinaUserFeaturesKeysValues.has(referenceLower)) {
                        return featureFlags
                            .map((flag) => flag.toLowerCase())
                            .includes(referenceLower);
                    }

                    // Handle known meta feature flags.
                    if (MedeinaMetaFeaturesKeysValues.has(reference)) {
                        // Handle features implemented by `useFeatureLaunchExperience`.
                        if (reference.startsWith('LaunchExperience')) {
                            return (
                                launchExperienceFeatures[reference as MedeinaMetaFeatures] ?? false
                            );
                        }

                        // Shouldn't reach this code.
                        return false;
                    }
                }

                return getStaticFeatureFlag(reference);
            },
            [references, featureFlags],
        );

        return [results.every((result) => Boolean(result)), ...results];
    }, [featureFlags, references]);
}
