import {acquireMsalToken, msalInstance} from '@/util/msal';
import {
    SupportCentralScriptPath,
    AppName,
    SupportCentralBaseURL,
    SupportCentralScopes,
} from './SupportCentral.constants';
import {ISupportCentralConfig} from './SupportCentral.types';
import {AccountInfo, AuthError, SilentRequest} from '@azure/msal-browser';
import {getCurrentTenantId, signInErrorHandler} from '@/util/msal/authConfig';
import {getLanguage} from '@/util/locale';

class SupportCentralManager {
    public getScriptPath() {
        const supportCentralEndpoint = this.getEndpoint();
        return `${supportCentralEndpoint + SupportCentralScriptPath}`;
    }

    public getConfig(): Promise<ISupportCentralConfig> {
        return this.getToken().then((token) => {
            return {
                appName: AppName,
                auth: {
                    endpointUrl: this.getEndpoint(),
                    token: token,
                    tokenRefresh: this.getToken,
                },
                settings: {
                    disableMinimization: true,
                    locale: getLanguage(),
                    maximizeByDefault: true,
                    hideHistory: false,
                },
                styles: {
                    minStateStyle: {
                        right: '0',
                        top: '0',
                    },
                    maxStateStyle: {
                        right: '0',
                        bottom: '0',
                    },
                },
            } as unknown as ISupportCentralConfig;
        });
    }

    private getEndpoint(): string {
        return SupportCentralBaseURL;
    }

    private getToken = async () => {
        const silentRequest: SilentRequest = {
            account: msalInstance.getActiveAccount() as AccountInfo,
            scopes: SupportCentralScopes,
            redirectUri: window.location.href,
            forceRefresh: false,
            authority: `https://login.microsoftonline.com/${getCurrentTenantId()}`,
        };
        try {
            const response = await msalInstance.acquireTokenSilent(silentRequest);
            return response.accessToken;
        } catch (error) {
            signInErrorHandler(error as AuthError, silentRequest);
        }
    };
}

export const supportCentralManager = new SupportCentralManager();
