import React, {useEffect, useCallback} from 'react';
import FromDefenderTour from './FromDefenderTour';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTourContext} from '@/components/ui/Tour';
import {useElementBySelector} from '@/components/ui/Tour/util/useGetElement';
import {useAppState} from '@/api/app';
import useTourHistoryTelemetry from '@/components/ui/Tour/useTourHistoryTelemetry';

function useDefenderIntroTour() {
    const {onTourStart, onTourComplete, onTourExitEarly} = useTourHistoryTelemetry({
        tourName: 'defenderIntro',
    });
    const {userTours} = useAppState();
    const {defenderIntro} = userTours;
    const {started} = defenderIntro || {};

    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const tourParam = params.get('tour');
    const navigate = useNavigate();
    const {activeTour, setTour, tour, stopIndex, currentStop} = useTourContext();
    const [firstTourElement, fireFirstTourSearch] = useElementBySelector(
        "[data-tour-id='promptContent']",
        3000,
        Boolean(tourParam),
    );

    const clearTourParam = useCallback(() => {
        const newParams = new URLSearchParams(search);
        newParams.delete('tour');
        navigate({search: newParams.toString()});
    }, [search, navigate]);

    // check for tour params, if we have them, start the tourParam
    useEffect(() => {
        if (tourParam && !activeTour && !defenderIntro && firstTourElement) {
            setTour(
                FromDefenderTour({
                    onExit: clearTourParam,
                    telemetryOptions: {
                        onTourComplete,
                        onTourExitEarly,
                        onTourStart,
                    },
                }),
            );
            //onTourStart();
        }
    }, [
        tourParam,
        activeTour,
        setTour,
        firstTourElement,
        started,
        clearTourParam,
        onTourStart,
        onTourComplete,
        onTourExitEarly,
        defenderIntro,
    ]);
}

export default useDefenderIntroTour;
