import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    disabledStyle: {
        color: tokens.colorNeutralForegroundDisabled,
        '& svg': {
            color: tokens.colorNeutralForegroundDisabled,
        },
    },
});
