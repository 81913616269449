import {EvaluationStepStatus} from '@/api/evaluations';
import {useEffect, useRef, useState} from 'react';
import {GetElapsedTimeForStepRequest} from './Evaluation.types';
import formatTime from '@/util/timeFormatterUtil';

export default function useGetElapsedTimeForStep({
    stepStatus,
    areAllStepsComplete,
    createdAt,
    updatedAt,
}: GetElapsedTimeForStepRequest) {
    const [stepTimeInSec, setStepTimeInSec] = useState(0);
    const [elapsedTimeText, setElapsedTimeText] = useState('');
    const stepTimeElapsedUpdater = useRef<NodeJS.Timeout | null>(null);
    const UPDATE_LOG_TIME_IN_MS: number = 1000;
    const MAX_ITERATIONS = 300;

    const clearStepTimeElapsedUpdater = function () {
        if (stepTimeElapsedUpdater.current) {
            clearInterval(stepTimeElapsedUpdater.current);
        }
    };

    const shouldAttemptTimeUpdate = function () {
        return (
            !areAllStepsComplete &&
            stepStatus === EvaluationStepStatus.Running &&
            stepTimeInSec <= MAX_ITERATIONS
        );
    };

    useEffect(() => {
        if (shouldAttemptTimeUpdate()) {
            stepTimeElapsedUpdater.current = setInterval(() => {
                setStepTimeInSec((prevTimeElapsed) => prevTimeElapsed + 1);
                clearStepTimeElapsedUpdater();
            }, UPDATE_LOG_TIME_IN_MS);
        }
        return () => clearStepTimeElapsedUpdater();
    }, [stepTimeInSec, stepStatus]);

    useEffect(() => {
        let number: number = 0;
        if (stepStatus === EvaluationStepStatus.Running && !areAllStepsComplete) {
            number = stepTimeInSec;
        } else {
            number = (new Date(updatedAt).getTime() - new Date(createdAt).getTime()) / 1000;
        }
        setElapsedTimeText(`${formatTime(number.toFixed(0))}`);
    }, [stepTimeInSec, stepStatus]);

    return {
        elapsedTimeText,
    };
}
