import {useMemo} from 'react';
import MedeinaVariables from './variables';

interface AzureCapacityDependencyLinkPartsProps {
    capacityId: string;
    tenantId: string;
}
export default function useAzureCapacityDependencyLinkParts({
    capacityId,
    tenantId,
}: AzureCapacityDependencyLinkPartsProps) {
    const parts = capacityId.split('/');

    const subscriptionSection = parts.length < 9 ? '' : `/${parts[1]}/${parts[2]}`;
    const resourceSection =
        parts.length < 9 ? '' : `${subscriptionSection}/${parts[3]}/${parts[4]}`;

    const validateCapacityId = (link: string): boolean => {
        const capacityIdRegex =
            /^\/subscriptions\/[a-fA-F0-9-]*\/resourceGroups\/[-\w\._\(\)]*\/providers\/microsoft.securitycopilot\/capacities\/[a-zA-Z0-9-]*$/;
        return capacityIdRegex.test(link);
    };

    const isCapacityIdValid = useMemo<boolean>(() => {
        return validateCapacityId(capacityId);
    }, [capacityId]);

    const getUrl = (section: string): string => {
        if (!isCapacityIdValid) {
            return '';
        }

        return `${MedeinaVariables.AzurePortalUrl}/#@${tenantId}/resource${section}`;
    };

    const getBillingTabUrlOnSubscriptionPage = () => {
        return `${MedeinaVariables.AzurePortalUrl}/#@${tenantId}/resource${subscriptionSection}/invoices`;
    };

    const getSubscriptionUrl = (): string => {
        return getUrl(subscriptionSection);
    };

    const getResourceGroupUrl = (): string => {
        return getUrl(resourceSection);
    };

    const getCapacityUrl = (): string => {
        return getUrl(capacityId);
    };
    const getCapacityName = (): string => {
        return isCapacityIdValid ? parts[8] : '';
    };

    const getSubscriptionId = (): string => {
        return isCapacityIdValid ? parts[2] : '';
    };

    const getResourceGroupName = (): string => {
        return isCapacityIdValid ? parts[4] : '';
    };

    return {
        getSubscriptionUrl,
        getResourceGroupUrl,
        getCapacityUrl,
        getCapacityName,
        getSubscriptionId,
        getResourceGroupName,
        getBillingTabUrlOnSubscriptionPage,
        isCapacityIdValid,
    };
}
