import {ErrorResponse} from '@/api/api';
import {TenantInfo, useGetTenantInfo} from '@/api/tenant';

export default function useDetermineFrePending() {
    const {
        data: tenantInfo,
        isSuccess: isTenantInfoSuccess,
        error: tenantInfoError,
        isError: isTenantInfoError,
    } = useGetTenantInfo();

    const error = tenantInfoError as ErrorResponse;
    const tenantRecord = tenantInfo as TenantInfo;

    /**
     * Determines if the FRE (Functional Requirements) is pending for the current tenant.
     *
     * @remarks
     * The FRE is considered pending if any of the following conditions are met:
     * - There is an error retrieving the tenant information and the HTTP status code is 403 or 404.
     * - The tenant information is successfully retrieved, but the tenant record is missing or does not have FRE requirements or the FRE requirements are not met.
     *
     * @returns A boolean value indicating whether the FRE is pending for the current tenant.
     */
    const isFrePending =
        (isTenantInfoError && (error?.code === 403 || error?.code === 404)) ||
        (isTenantInfoSuccess &&
            (!tenantRecord ||
                !tenantRecord.freRequirements ||
                !tenantRecord.freRequirements.freRequirementsMet));

    return isFrePending;
}
