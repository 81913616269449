import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.margin(
            tokens.spacingVerticalM,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalM,
            tokens.spacingHorizontalNone,
        ),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: tokens.spacingVerticalXXL,
    },
    title: {
        marginTop: tokens.spacingVerticalNone,
        marginBottom: tokens.spacingVerticalXXXL,
        fontSize: tokens.spacingHorizontalXXXL,
        fontWeight: tokens.fontWeightRegular,
    },
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
    datatable: {
        display: 'flex',
        flexDirection: 'column',
    },
    grid: {
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
        backgroundColor: tokens.colorNeutralBackground5,
    },
    gridHeaderCell: {
        color: tokens.colorNeutralForeground4,
        fontWeight: tokens.fontWeightSemibold,
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
    },
    gridRow: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        paddingTop: tokens.spacingVerticalXXS,
        paddingBottom: tokens.spacingVerticalXXS,
    },
    gridRowCell: {
        fontWeight: tokens.fontWeightRegular,
    },
    dropdown: {
        minWidth: '100%',
    },
});
