export enum StageState {
    'ready' = 'ready',
    'waiting' = 'waiting',
    'playing' = 'playing',
    'completed' = 'completed',
}

// Generic stage model for useStageManager
export interface Stage {
    name: string;
    autoStart?: boolean;
    /** if true when the stageState is set to completed this will call the next stage*/
    autoAdvance?: boolean;
    /** a callback to fire when stageState is set to completed, fires before the stageIndex is changed */
    onCompleted?: (data?: any) => void;
}

export interface RenderableStage extends Stage {
    Component?: any;
}

export interface StageProps {
    /** a call back to fire when the stage has started playing */
    onStageStart: () => void;
    /** a call back to fire when the stage has completed playing */
    onStageComplete: () => void;
    /** A function that can be used to set the stage to specific state */
    onSetStageState: () => void;
    /** the current stage state */
    stageStage: StageState;
    /** children */
    children: React.ReactNode | Array<React.ReactNode>;
}
