import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {PageResponse} from '../api';
import {FileDescriptor} from './files.types';

export default function useGetAllFiles(trigger: boolean) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['files'],
        queryFn: async () => {
            return await customFetch<PageResponse<FileDescriptor>>(`/files`, {
                method: 'GET',
            });
        },
    });
}
