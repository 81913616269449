import {Variants} from 'framer-motion';
import {useMemo} from 'react';

export default function useDataTableV2Transitions() {
    const transitionVariant: Variants = useMemo(
        () => ({
            initial: {
                opacity: 0,
            },
            animate: {
                opacity: 1,
                transition: {delay: 0.1, duration: 0.2},
            },
            exit: {
                opacity: 0,
                transition: {delay: 0.1, duration: 0.1},
            },
        }),
        [],
    );

    const dataGridVariant: Variants = useMemo(
        () => ({
            initial: {
                height: 0,
                opacity: 0,
            },
            animate: {
                height: 'auto',
                opacity: 1,
                transition: {
                    duration: 0.2,
                    ease: 'easeInOut',
                },
            },
            exit: {
                height: 0,
                opacity: 0,
                transition: {
                    duration: 0.2,
                    ease: 'easeInOut',
                },
            },
        }),
        [],
    );

    return {transitionVariant, dataGridVariant};
}
