import {PolicyWorkspace} from './rbac.types';
import {
    PARTNER_ROLES_CONDITION,
    ROLE_POLICY_NAME_PREFIX_MAP,
} from '@/components/admin/rbac/rbac.constants';
import {RoleType} from '@/components/admin/rbac/rbac.types';
export function getHasRecommendedRoles(workspacePolicy: PolicyWorkspace): boolean {
    return workspacePolicy.properties.attributeRules.some(
        (rule) =>
            rule.id ===
                `${ROLE_POLICY_NAME_PREFIX_MAP[RoleType.Contributor]}:${
                    workspacePolicy.properties.entity.referenceName
                }` &&
            rule.dnfCondition.some(
                (condition) =>
                    JSON.stringify(condition) === JSON.stringify(PARTNER_ROLES_CONDITION),
            ),
    );
}
