import * as React from 'react';
const GemStone = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path fill="#72ADF1" d="m9.234 14.25l6.75 15.914l7.532-15.914H9.234Z" />
            <path fill="url(#f589id0)" d="m9.234 14.25l6.75 15.914l7.532-15.914H9.234Z" />
            <path fill="url(#f589id1)" d="m9.234 14.25l6.75 15.914l7.532-15.914H9.234Z" />
            <path fill="url(#f589id2)" d="m7.547 7.969l-5.5 7.406h9.031L16 7.969H7.547Z" />
            <path fill="url(#f589id3)" d="m7.547 7.969l-5.5 7.406h9.031L16 7.969H7.547Z" />
            <path fill="url(#f589id4)" d="m7.547 7.969l-5.5 7.406h9.031L16 7.969H7.547Z" />
            <path fill="url(#f589idb)" d="m24.43 7.969l5.5 7.406h-9.032l-4.921-7.406h8.453Z" />
            <path fill="url(#f589id5)" d="m24.43 7.969l5.5 7.406h-9.032l-4.921-7.406h8.453Z" />
            <path fill="url(#f589id6)" d="M15.99 30.174L2.047 15.375h9.031l4.912 14.799Z" />
            <path fill="url(#f589id7)" d="M15.99 30.174L2.047 15.375h9.031l4.912 14.799Z" />
            <path fill="url(#f589idc)" d="m15.984 30.164l13.95-14.789h-9.032l-4.918 14.79Z" />
            <path fill="url(#f589id8)" d="m15.984 30.164l13.95-14.789h-9.032l-4.918 14.79Z" />
            <path fill="url(#f589id9)" d="m15.984 30.164l13.95-14.789h-9.032l-4.918 14.79Z" />
            <path fill="url(#f589ida)" d="M11.078 15.375h9.82l-4.91-7.389l-4.91 7.389Z" />
            <defs>
                <linearGradient
                    id="f589id0"
                    x1="16.375"
                    x2="16.375"
                    y1="14.848"
                    y2="16.848"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72C8F9" />
                    <stop offset="1" stopColor="#73AEF2" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589id1"
                    x1="19.191"
                    x2="15.947"
                    y1="25.113"
                    y2="24.491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#73D2FF" />
                    <stop offset="1" stopColor="#73AEF2" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589id2"
                    x1="11.172"
                    x2="7.234"
                    y1="15"
                    y2="9.875"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".33" stopColor="#2E97D8" />
                    <stop offset="1" stopColor="#1C82CA" />
                </linearGradient>
                <linearGradient
                    id="f589id3"
                    x1="2.484"
                    x2="6.297"
                    y1="16.063"
                    y2="13.125"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2E8BDB" />
                    <stop offset="1" stopColor="#2E8BDB" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589id4"
                    x1="13.57"
                    x2="12.704"
                    y1="12.471"
                    y2="11.916"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#32B3EA" />
                    <stop offset="1" stopColor="#32B3EA" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589id5"
                    x1="19.578"
                    x2="20.203"
                    y1="13.594"
                    y2="13.188"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".17" stopColor="#20D0F6" />
                    <stop offset="1" stopColor="#20D0F6" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589id6"
                    x1="9.019"
                    x2="15.99"
                    y1="15.375"
                    y2="29.734"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".379" stopColor="#296BCC" />
                    <stop offset="1" stopColor="#3E7DE1" />
                </linearGradient>
                <linearGradient
                    id="f589id7"
                    x1="9.019"
                    x2="9.019"
                    y1="15.375"
                    y2="15.781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2D75D2" />
                    <stop offset="1" stopColor="#2D75D2" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589id8"
                    x1="17.563"
                    x2="18.063"
                    y1="24.898"
                    y2="25.094"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#368FE1" />
                    <stop offset="1" stopColor="#368FE1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589id9"
                    x1="22.959"
                    x2="22.959"
                    y1="15.063"
                    y2="16.094"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#309EE9" />
                    <stop offset="1" stopColor="#309EE9" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f589ida"
                    x1="19.672"
                    x2="11.078"
                    y1="11.312"
                    y2="17.312"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6CE8FE" />
                    <stop offset=".642" stopColor="#68CAEA" />
                </linearGradient>
                <radialGradient
                    id="f589idb"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(8.25 -.82813 1.11925 11.15027 17.984 12.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#20D5FC" />
                    <stop offset="1" stopColor="#20BFF7" />
                </radialGradient>
                <radialGradient
                    id="f589idc"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(5.72464 -10.31253 5.40695 3.00148 17.234 29.563)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#42A2EC" />
                    <stop offset="1" stopColor="#3294E4" />
                </radialGradient>
            </defs>
        </g>
    </svg>
);
export default GemStone;
