import {Session, useGetSessions} from '@/api/sessions';
import useClasses from './SessionsList.styles';
import {AddIcon, EditIcon, DeleteIcon, DuplicateIcon} from '@/components/ui/icons';
import {useNavigate} from 'react-router-dom';
import {
    ToolbarButton,
    DataGrid,
    DataGridBody,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    DataGridCell,
    DataGridProps,
    TableRowId,
    TableColumnDefinition,
    createTableColumn,
    Link,
    Button,
    TableCellLayout,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuItem,
    MenuList,
    TableCellActions,
    TableColumnId,
    DataGridCellFocusMode,
    Text,
    Spinner,
    Tooltip,
    Skeleton,
    SkeletonItem,
} from '@fluentui/react-components';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {columnSizingOptionsEnhancedSearch} from './SessionsListColumnsEnhancedSearch';
import SessionFilters from './SessionFilters';
import SessionsOperations from './SessionsOperations';
import {DateRangeOption, SearchFilter, DateRangeOptions} from './SearchFilter';
import DeleteSessionDialog from './DeleteSessionDialog';
import {MoreHorizontal24Regular} from '@fluentui/react-icons';
import MedeinaFeatures from '@/util/features';
import useClassesSerachEnhanced from './SessionsListEnhancedSearch.styles';
import EditInvestigationNameDialog from '../investigations/EditInvestigationNameDialog';
import {AnimatePresence, motion} from 'framer-motion';
import DuplicateSessionDialog from './DuplicateSessionDialog';
import {DateTimeFormat} from '@/components/date';
import {useTranslation} from 'react-i18next';
import AnnounceLabel from '@/components/ui/AnnounceText/AnnounceText';
import Highlighter from 'react-highlight-words';

// Set focus mode for the cell, for complex cells that contain multiple focusable elements switching to
// group allows the user to press enter and navigate within the cell
const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
    switch (columnId) {
        case 'name':
            // the name column contains a link and an options button, use group focus
            return 'group';
        default:
            return 'cell';
    }
};

export default function SessionsList() {
    const classes = useClasses();
    const newClasses = useClassesSerachEnhanced();
    const {t} = useTranslation(['mysessions']);
    var filterName: string = "name ne null and contains(tolower(name),'{val}')";
    const [searchText, setSearchText] = useState<string>('');
    const [filterString, setFilterString] = useState<string>('');
    const [customfilterString, setCustomfilterString] = useState<string>('');
    const [recentlyOpenedSelected, setRecentlyOpenedSelected] = useState<boolean>(false);
    const [filterState, setFilterState] = useState({
        filter: '$filter',
    });

    let [createdAt, setCreatedAt] = useState<DateRangeOption | DateRange>(DateRangeOptions.AllTime);
    let [updatedAt, setUpdatedAt] = useState<DateRangeOption | DateRange>(DateRangeOptions.AllTime);
    const [selectedChip, setSelectedChip] = useState<string>('ALL');

    const {
        data: onChangeSession,
        isLoading: apiLoading,
        isSuccess: isSuccess,
        refetch,
        fetchNextPage: fetchNextPage,
        hasNextPage: hasNextPage,
    } = useGetSessions({
        filter: ((filterState.filter as string) = filterString),
        sortBy: 'UpdatedAt',
        sortDirection: 'desc',
        maxSessionsCount: 100,
    });
    const [filterCloseWait, setFilterCloseWait] = useState(false);
    const flatSessions: Session[] | undefined = useMemo(
        () => onChangeSession?.pages.map((page) => page.value).flat() ?? undefined,
        [onChangeSession],
    );
    useEffect(() => {
        if (apiLoading || (filterCloseWait && isSuccess)) {
            setFilterClick(false);
            setFilterCloseWait(false);
        }
    }, [apiLoading, filterCloseWait]);

    useEffect(() => {
        setFilterQuery();
        clearSelectedRows();
    }, [searchText, recentlyOpenedSelected, customfilterString, selectedChip]);

    const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>(undefined));
    const onSelectionChange: DataGridProps['onSelectionChange'] = (e: any, data: any) => {
        setSelectedRows(data.selectedItems);
    };

    const clearSelectedRows = () => {
        setSelectedRows(new Set<TableRowId>());
    };

    // Delete sessions
    const [sessionId, setSessionId] = useState<string | undefined>(undefined);

    const sessionIds = useMemo<string[]>(
        () => Array.from(selectedRows) as string[],
        [selectedRows],
    );

    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openDuplicateSessionDialog, setOpenDuplicateSessionDialog] = useState<boolean>(false);
    const [openEditNameDialog, setOpenEditNameDialog] = useState(false);
    const [selectedSession, setSelectedSession] = useState<Session | undefined>(undefined);

    const debounce = (func: any, wait: any) => {
        let timeout: string | number | NodeJS.Timeout | undefined;
        return function executedFunction(...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    const handleObjectChange = debounce((textFilter: string) => {
        setSearchText(textFilter);
    }, 500); // setting debounce to 500 milliseconds

    const setFilterQuery = () => {
        filterName =
            searchText.trim() !== ''
                ? filterName.replace(
                      '{val}',
                      searchText !== '' ? searchText.toLowerCase() : searchText,
                  )
                : '';
        if (selectedChip == 'ALL') {
            setFilterString(filterName);
        } else if (selectedChip == 'RECENTLYOPENED') {
            const currentDate = new Date();
            const startDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
            );
            const SevenDaysAgo = new Date(startDay);
            SevenDaysAgo.setDate(SevenDaysAgo.getDate() - 6);
            const filterRecentlyOpened = `createdAt ge ${SevenDaysAgo.toISOString()} or updatedAt ge ${SevenDaysAgo.toISOString()}`;
            if (searchText !== '') {
                setFilterString(`(${filterName}) and (${filterRecentlyOpened})`);
            } else if (searchText === '') {
                setFilterString(filterRecentlyOpened);
            }
        } else if (selectedChip == 'FILTERCHECKED') {
            if (customfilterString !== '' && searchText !== '') {
                setFilterString(`(${filterName}) and (${customfilterString})`);
            } else if (customfilterString !== '' && searchText === '') {
                setFilterString(customfilterString);
            } else if (customfilterString == '') {
                setFilterString(filterName);
            }
        }
    };

    const handleRecentlyChanged = (isSelected: boolean) => {
        setRecentlyOpenedSelected(isSelected);
    };

    const handleDeleteSessions = () => {
        setOpenDeleteDialog(true);
    };

    const handleRowDeleteClicked = (sessionId: string) => {
        setSessionId(sessionId);
        setOpenDeleteDialog(true);
    };

    const handleDuplicateSessionClicked = (session: Session) => {
        setSelectedSession(session);
        setOpenDuplicateSessionDialog(true);
    };

    const refetchSessionList = () => {
        refetch();
    };

    const handleEditNameClicked = (session: Session) => {
        setSelectedSession(session);
        setOpenEditNameDialog(true);
    };

    const handleCloseEditNameDialog = () => {
        setOpenEditNameDialog(false);
        setSelectedSession(undefined);
    };

    const [filterClick, setFilterClick] = useState(false);

    const handleFilterClick = (filterState: boolean) => {
        if (!filterState) {
            setCustomfilterString('');
        }
        setFilterClick(filterState);
    };

    const handleSelectedChip = (selectedChip: string) => {
        setSelectedChip(selectedChip);
    };

    const handleCustomFilterApply = (
        customFilter: string,
        createdAt: DateRangeOption | DateRange,
        updatedAt: DateRangeOption | DateRange,
    ) => {
        setCustomfilterString(customFilter);
        setCreatedAt(createdAt);
        setUpdatedAt(updatedAt);
        setFilterCloseWait(true);
    };

    // Set the text to be announced by screen reader when updated filtered search results are displayed.
    const getSearchResultsAriaLiveText = useCallback((): string => {
        // If no search term is entered, do not announce search results.
        if (searchText == '') {
            return '';
        }
        // If a search term is entered, announce the number of results found.
        if (flatSessions == undefined) {
            return t('SearchResults');
        } else if (flatSessions.length == 1) {
            return t('OneTotalResultText');
        } else {
            return t('nTotalResultsText', {0: flatSessions?.length});
        }
    }, [searchText, flatSessions]);

    //To fetch the next set of investigations once user scrolls down to bottom
    let observer = useRef<IntersectionObserver | null>(null);
    let lastElementRef = useCallback(
        (node: HTMLDivElement) => {
            if (apiLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });
            if (node) observer.current.observe(node);
        },
        [apiLoading, hasNextPage],
    );
    const selectAllCheckboxLabel = t('SelectAllSessions');

    const columns: TableColumnDefinition<Session>[] = [
        createTableColumn<Session>({
            columnId: 'name',
            renderHeaderCell: () => t('TableHeaderNameText'),
            renderCell: (session) => {
                return (
                    <>
                        <TableCellLayout>
                            <div>
                                <Tooltip
                                    content={
                                        session.name || `${t('SessionText')}: ${session.sessionId}`
                                    }
                                    relationship="label"
                                >
                                    <Link
                                        href={`/sessions/${session.sessionId}`}
                                        data-testid="investigation-link"
                                        className={classes.sessionLink}
                                    >
                                        <Highlighter
                                            highlightClassName={newClasses.highlight}
                                            searchWords={[searchText]}
                                            textToHighlight={
                                                session.name ||
                                                `${t('SessionText')}: ${session.sessionId}`
                                            }
                                        />
                                    </Link>
                                </Tooltip>
                            </div>
                        </TableCellLayout>
                        <TableCellActions
                            onClick={(e: {
                                preventDefault: () => void;
                                stopPropagation: () => void;
                            }) => {
                                // We do not want row selection when the table-cell-action button is pressed.
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            aria-haspopup="true"
                        >
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button
                                        icon={<MoreHorizontal24Regular />}
                                        appearance="subtle"
                                        aria-label={t('SessionOptionsButton')}
                                    />
                                </MenuTrigger>

                                <MenuPopover>
                                    <MenuList>
                                        {MedeinaFeatures.DuplicateSessionExperience && (
                                            <MenuItem
                                                icon={<DuplicateIcon />}
                                                onClick={() => {
                                                    handleDuplicateSessionClicked(session);
                                                }}
                                            >
                                                {t('DuplicateSessionButton')}
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            icon={<DeleteIcon />}
                                            onClick={() => {
                                                handleRowDeleteClicked(session.sessionId);
                                            }}
                                        >
                                            {t('DeleteSessionButton')}
                                        </MenuItem>
                                        {MedeinaFeatures.EditInvestigationNameExperience && (
                                            <MenuItem
                                                icon={<EditIcon />}
                                                onClick={() => {
                                                    handleEditNameClicked(session);
                                                }}
                                            >
                                                {t('EditSessionNameButton')}
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </TableCellActions>
                    </>
                );
            },
        }),
        createTableColumn<Session>({
            columnId: 'updatedAt',
            renderHeaderCell: () => t('TableHeaderUpdatedText'),
            renderCell: (session) => (
                <TableCellLayout>
                    <Text>
                        <DateTimeFormat date={session.updatedAt} />
                    </Text>
                </TableCellLayout>
            ),
        }),
        createTableColumn<Session>({
            columnId: 'createdAt',
            renderHeaderCell: () => t('TableHeaderCreatedText'),
            renderCell: (session) => (
                <TableCellLayout>
                    <Text>
                        <DateTimeFormat date={session.createdAt} />
                    </Text>
                </TableCellLayout>
            ),
        }),
    ];

    const navigate = useNavigate();

    return (
        <div className={newClasses.root}>
            <h1 className={newClasses.title}>{t('Title')}</h1>
            <div className={newClasses.newInvestigation}>
                <ToolbarButton
                    appearance="primary"
                    data-testid="new-session-button"
                    icon={<AddIcon />}
                    onClick={() =>
                        MedeinaFeatures.NewSessionPage ? navigate('/sessions/new') : navigate('/')
                    }
                    className={newClasses.newInvestigation}
                >
                    {t('NewSessionButton')}
                </ToolbarButton>
            </div>
            <div className={newClasses.stickyActions}>
                <SessionFilters
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    onObjectChange={handleObjectChange}
                    recentlyOpened={handleRecentlyChanged}
                    onFilterClick={handleFilterClick}
                    setCreatedAt={setCreatedAt}
                    setUpdatedAt={setUpdatedAt}
                    selectedChipIcon={handleSelectedChip}
                />

                {(!filterClick || selectedChip !== 'FILTERCHECKED') && (
                    <SessionsOperations {...{selectedRows}} onDeleteClick={handleDeleteSessions} />
                )}
                <DeleteSessionDialog
                    {...{sessionId: sessionId, sessionIds: sessionIds}}
                    open={openDeleteDialog}
                    onClose={() => {
                        setOpenDeleteDialog(false);
                        setSessionId(undefined);
                    }}
                    onSuccess={() => {
                        setOpenDeleteDialog(false);

                        // clear the selected rows
                        clearSelectedRows();
                        setSessionId(undefined);
                    }}
                />
            </div>
            {selectedSession && (
                <div>
                    <DuplicateSessionDialog
                        key={selectedSession.name}
                        session={selectedSession}
                        open={openDuplicateSessionDialog}
                        onClose={() => {
                            setOpenDuplicateSessionDialog(false);
                            setSelectedSession(undefined);
                        }}
                        onSuccess={() => {
                            setOpenDuplicateSessionDialog(false);
                            setSelectedSession(undefined);
                        }}
                        refetchSessionList={refetchSessionList}
                    />
                    <EditInvestigationNameDialog
                        session={selectedSession}
                        open={openEditNameDialog}
                        onClose={handleCloseEditNameDialog}
                        onSuccess={handleCloseEditNameDialog}
                    />
                </div>
            )}

            <AnimatePresence mode="wait">
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0, duration: 0.3}}
                    exit={{opacity: 0, transition: {duration: 0}}}
                    key="FilteringInvestigation"
                >
                    {apiLoading && (
                        <AnnounceLabel ariaLive="polite">
                            <div className={newClasses.filteringInvestigations}>
                                <Spinner labelPosition="below" label={t('FilteringSessions')} />
                            </div>
                        </AnnounceLabel>
                    )}
                </motion.div>
                {(!filterClick || selectedChip !== 'FILTERCHECKED') &&
                    isSuccess &&
                    flatSessions?.length == 0 && (
                        <AnnounceLabel ariaLive="polite">
                            <p className={newClasses.filterDialog}>{t('NoSessionsFound')}</p>
                        </AnnounceLabel>
                    )}

                {(!filterClick || selectedChip !== 'FILTERCHECKED') &&
                    isSuccess &&
                    (flatSessions?.length ?? 0) > 0 && (
                        <div>
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{delay: 0, duration: 0.3}}
                                exit={{opacity: 0, transition: {duration: 0}}}
                                key="FilterPage"
                            >
                                <DataGrid
                                    items={flatSessions ?? []}
                                    columns={columns}
                                    columnSizingOptions={columnSizingOptionsEnhancedSearch}
                                    resizableColumns
                                    selectionAppearance="neutral"
                                    selectionMode="multiselect"
                                    onSelectionChange={onSelectionChange}
                                    selectedItems={selectedRows}
                                    getRowId={(session: {sessionId: any}) => session.sessionId}
                                    className={newClasses.gridCell}
                                >
                                    <DataGridHeader>
                                        <DataGridRow
                                            selectionCell={{
                                                checkboxIndicator: {
                                                    'aria-label': selectAllCheckboxLabel,
                                                },
                                            }}
                                        >
                                            {({renderHeaderCell}: any) => (
                                                <DataGridHeaderCell
                                                    className={newClasses.headerCell}
                                                >
                                                    {renderHeaderCell()}
                                                </DataGridHeaderCell>
                                            )}
                                        </DataGridRow>
                                    </DataGridHeader>
                                    <DataGridBody<Session>>
                                        {({item, rowId}: any) => (
                                            <DataGridRow<Session>
                                                key={rowId}
                                                selectionCell={{
                                                    checkboxIndicator: {
                                                        'aria-label': t('SelectSessionGridRow', {
                                                            0: item.name,
                                                        }),
                                                    },
                                                }}
                                                data-testid={rowId}
                                            >
                                                {({renderCell, columnId}: any) => (
                                                    <DataGridCell
                                                        focusMode={getCellFocusMode(columnId)}
                                                    >
                                                        {renderCell(item)}
                                                    </DataGridCell>
                                                )}
                                            </DataGridRow>
                                        )}
                                    </DataGridBody>
                                </DataGrid>
                                {hasNextPage && (
                                    <Skeleton>
                                        <SkeletonItem
                                            className={newClasses.skeleton}
                                            appearance="translucent"
                                            animation="pulse"
                                            ref={lastElementRef}
                                            data-testid="sessions-skeleton"
                                        ></SkeletonItem>
                                    </Skeleton>
                                )}
                            </motion.div>
                        </div>
                    )}
                {filterClick && !apiLoading && selectedChip == 'FILTERCHECKED' && (
                    <div className={newClasses.filterDialog}>
                        <SearchFilter
                            onFilterApply={handleCustomFilterApply}
                            createdAt={createdAt}
                            updatedAt={updatedAt}
                            setCreatedAt={setCreatedAt}
                            setUpdatedAt={setUpdatedAt}
                        ></SearchFilter>
                    </div>
                )}
            </AnimatePresence>
            <div className={classes.visuallyHidden} data-testid="SearchResults">
                <div aria-live="polite">{getSearchResultsAriaLiveText()}</div>
            </div>
        </div>
    );
}

export interface DateRange {
    startDate: Date;
    endDate: Date;
}
