import {useMutation, useQueries, useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {GetEvaluationsFromIdRequest, Evaluation} from './evaluations.types';

export default function useGetEvaluationsFromEvaluationIds({
    sessionId,
    promptEvaluationIds,
}: GetEvaluationsFromIdRequest) {
    const {customFetch} = useFetch();

    let queries = promptEvaluationIds.map(([promptId, evaluationId]: [string, string]) => ({
        queryKey: ['sessions', sessionId, 'prompts', promptId, 'evaluations', evaluationId],
        queryFn: async () => {
            return await customFetch<Evaluation>(
                `/sessions/${sessionId}/prompts/${promptId}/evaluations/${evaluationId}`,
                {
                    method: 'GET',
                },
            );
        },
        enabled: Boolean(promptId) && Boolean(sessionId) && Boolean(evaluationId),
    }));

    return useQueries({
        queries: queries,
    });
}
