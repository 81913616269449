import {mergeClasses} from '@griffel/react';
import useClasses from './ExploreCardList.styles';

export default function ExploreCardEmpty() {
    const classes = useClasses();
    return (
        <div
            className={mergeClasses(classes.card, classes.cardEmpty)}
            data-testid="featured-prompt-card"
        >
            &nbsp;
        </div>
    );
}
