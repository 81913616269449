import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        width: '100%',
        marginTop: '48px',
    },
    tab: {
        marginRight: tokens.spacingHorizontalSNudge,

        // Disabled because light mode breaks.
        /*
        backgroundColor: tokens.colorNeutralBackground2,
        color: tokens.colorNeutralForeground2Link,

        '& svg': {
            color: tokens.colorNeutralForeground2Link,
        },

        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2Hover,
            color: tokens.colorNeutralForegroundOnBrand,
        },

        '&:hover svg': {
            color: tokens.colorNeutralForegroundOnBrand,
        },

        '&[aria-pressed=true], &[aria-pressed=true]:hover': {
            backgroundColor: tokens.colorNeutralBackgroundInverted,
            color: tokens.colorNeutralForegroundInverted,
        },

        '&[aria-pressed=true] svg, &[aria-pressed=true]:hover svg': {
            color: tokens.colorNeutralForegroundInverted,
        },
        */
    },
    title: {
        marginBottom: tokens.spacingVerticalM,
    },
    hidden: {
        display: 'none',
    },
    checked: {
        backgroundColor: tokens.colorNeutralBackgroundInverted,
        color: tokens.colorNeutralForegroundInverted,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
        minWidth: '0px',
        whiteSpace: 'nowrap',

        ':hover': {
            ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
            backgroundColor: tokens.colorNeutralBackgroundInverted,
            color: tokens.colorNeutralForegroundInverted,
        },
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    carouselButton: {
        backgroundColor: tokens.colorTransparentBackground,
        ...shorthands.border('none'),
        ':hover': {
            backgroundColor: tokens.colorTransparentBackground,
        },
    },

    carouselButtonDisabled: {
        ':hover': {
            cursor: 'pointer !important', // This will change the cursor to an arrow.
        },
    },
});
