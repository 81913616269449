import React, {useMemo, useCallback, useEffect} from 'react';
import {
    Combobox,
    useComboboxFilter,
    useId,
    Toaster,
    useToastController,
    Toast,
    ToastTitle,
    ToastBody,
    Label,
    Switch,
    Body1Strong,
} from '@fluentui/react-components';
import {useUpdateUserPreferences} from '@/api/user';
import {useUserState} from '@/api/user';
import {buildTimeZoneList} from './util';
import usePreferenceClasses from '../Preferences.styles';
import {useTranslation} from 'react-i18next';
import {LANGUAGE_OPTION_MAP, MedeinaLanguageCode, formatLocaleDate as format} from '@/util/locale';
import getEffectiveLanguage from '@/util/locale/getLanguage';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

const TimeZoneField = () => {
    const preferenceClasses = usePreferenceClasses();
    const {t} = useTranslation('common');
    const {t: tCity} = useTranslation('timezones');
    const comboId = useId();
    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);
    const noResultsLabel = t('locale.tryAnotherTimezone');
    const debugEnabled = useFeatureFlag(MedeinaFeatures.Debug);

    // handle saving the data to our model
    const {preferences, setPreferences, useBrowserTime, useBrowserLanguage, setUseBrowserTime} =
        useUserState();
    const {locale} = preferences;
    if (debugEnabled) console.log({preferences, locale, useBrowserTime});
    const {timeZones: timeZoneList, timeZoneMap} = useMemo(
        () =>
            buildTimeZoneList({
                locale: locale.languageCode || 'en',
                translate: tCity,
                UTCLabel: t('utc'),
            }),
        // we only want to rerun the buildTimeZoneList function when the locale changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [locale],
    );
    const [query, setQuery] = React.useState<string>('');

    const [value, setValue] = React.useState<string>(preferences.timezone || '');

    const selectedValueText = useMemo(
        () => (value ? timeZoneMap[value].children : ''),
        [value, timeZoneMap],
    );
    const children = useComboboxFilter(query, timeZoneList, {
        noOptionsMessage: noResultsLabel,
        optionToText: (option) => option.children,
    });
    const updateUserPreferences = useUpdateUserPreferences();

    const showErrorToast = useCallback(
        (error?: Error) =>
            dispatchToast(
                <Toast>
                    <ToastTitle>{t('SettingsErrorSaveTitle')}</ToastTitle>
                    <ToastBody>
                        {t('SettingsErrorSaveTitle')}${error?.message}
                    </ToastBody>
                </Toast>,
                {intent: 'error'},
            ),
        [dispatchToast],
    );

    const handleOnOptionSelect = (ev: any, data: any) => {
        // if there's a value, set it, otherwise set the query
        if (data.optionValue) {
            setValue(data.optionValue);
            setQuery('');
            // save our data first to our local storage and then to the server
            setPreferences({
                ...preferences,
                timezone: data.optionValue,
            });
            const effectiveLanguage = getEffectiveLanguage();
            // persist the data to the server
            // we need to construct the locale object with the language code and the display language
            const localeUpdate = useBrowserLanguage
                ? {
                      languageCode: effectiveLanguage,
                      displayLanguage:
                          LANGUAGE_OPTION_MAP[effectiveLanguage as MedeinaLanguageCode].label ||
                          effectiveLanguage,
                  }
                : locale;

            updateUserPreferences.mutate(
                {
                    locale: localeUpdate,
                    timezone: data.optionValue,
                },
                {
                    onError: (error) => {
                        showErrorToast(error as Error);
                    },
                },
            );
        }
    };

    useEffect(() => {
        if (useBrowserTime) {
            setPreferences({
                ...preferences,
                timezone: '',
            });
            setValue('');
        }
    }, [useBrowserTime]);

    return (
        <div className={preferenceClasses.field}>
            <h3>{t('SettingsTimezoneHeading')}</h3>
            <Label id={comboId} className={preferenceClasses.label}>
                {t('SettingsTimezoneDescription')}
            </Label>
            <div className={preferenceClasses.dropdownWrapper}>
                <Combobox
                    aria-labelledby={comboId}
                    className={preferenceClasses.fullWidthDropdown}
                    placeholder={t('locale.selectTimezone')}
                    onChange={(ev) => setQuery(ev.target.value)}
                    value={query || selectedValueText}
                    onOptionSelect={handleOnOptionSelect}
                    selectedOptions={value ? [value] : []}
                    positioning="below"
                    disabled={useBrowserTime}
                >
                    {children}
                </Combobox>
            </div>
            <Switch
                label={t('SettingsTimeBrowserPreference')}
                checked={useBrowserTime}
                onChange={(_, data) => {
                    setUseBrowserTime(data.checked);
                }}
                labelPosition="before"
                className={preferenceClasses.switch}
            />
            <Toaster
                toasterId={toasterId}
                position="bottom"
                pauseOnHover
                pauseOnWindowBlur
                timeout={4000}
            />
            {debugEnabled && (
                <>
                    <Body1Strong>{format(new Date(), 'h:mm a')}</Body1Strong>
                    {` | `}
                    <Body1Strong>{format(new Date(), 'M/d/y')}</Body1Strong>
                </>
            )}
        </div>
    );
};

export default TimeZoneField;
