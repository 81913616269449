import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';

import {FileUploadRequest} from './files.types';

export default function useUploadFile() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: FileUploadRequest) => {
            const formData = new FormData();
            for (const key in body) {
                if (body.hasOwnProperty(key)) {
                    const value = body[key as keyof FileUploadRequest];
                    if (value) {
                        if (key === 'FileContent') {
                            formData.append(key, value as File);
                        } else {
                            formData.append(key, value.toString());
                        }
                    }
                }
            }
            return await customFetch<Response>(`/files/${body.FileName}`, {
                method: 'PUT',
                body: formData,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['files']);
            queryClient.invalidateQueries(['files', 'files/usage/user']);
        },
    });
}
