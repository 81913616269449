import {DialogContent, DialogActions, DialogTrigger, Button} from '@fluentui/react-components';
import React, {useCallback, useEffect} from 'react';
import {WorkspaceFormProps} from './WorkspaceForm.types';
import useClasses from './WorkspaceForm.styles';
import CapacitySelectField from './CapacitySelect';
import StorageLocation from './StorageLocationField';
import WorkspaceName from './WorkspaceNameField';
import ModelImprovement from './ModelImprovementField';
import ProductImprovement from './ProductImprovementField';
import TermsConditions from './TermsConditionsField';
import {useTranslation} from 'react-i18next';
import {useGetWorkspaceByName, usePutWorkspace, Workspace} from '@/api/workspaces';
import AuditLoggingOptingSwitch from '@/components/admin/AuditLoggingOptingSwitch';

function CreateEdit(props: WorkspaceFormProps) {
    const {mode, onCancel, onClose, onDelete, workspace} = props;
    const classes = useClasses();
    const showDelete = mode === 'edit' && onDelete;
    const {t} = useTranslation();
    const {t: tCommon} = useTranslation('common');

    // field state values
    const [workspaceName, setWorkspaceName] = React.useState(workspace?.name || '');
    const [dataStorageLocation, setDataStorageLocation] = React.useState('');
    const [capacity, setCapacity] = React.useState('');
    const [modelImprovement, setModelImprovement] = React.useState(true);
    const [productImprovement, setProductImprovement] = React.useState(true);
    const [termsConditions, setTermsConditions] = React.useState(false);
    const {data: workspaceData} = useGetWorkspaceByName(workspaceName, {enabled: mode === 'edit'});

    const {mutate: createWorkspace, isLoading, isError, isSuccess} = usePutWorkspace();

    useEffect(() => {
        // if we are in edit mode, we should populate the fields with the workspace data
        if ((mode === 'edit' || mode === 'duplicate') && workspaceData) {
            console.log('prefilling data', workspaceData);
            // prefill the fields with the workspace data
            setCapacity(workspaceData.capacity?.referenceName || '');
            setDataStorageLocation(workspaceData?.dataStorageLocation || '');
            setModelImprovement(
                workspaceData?.workspaceOptInConfig?.isAllowModelImprovement === 'true'
                    ? true
                    : false,
            );
            setProductImprovement(
                workspaceData?.workspaceOptInConfig?.isAllowProductImprovement === 'true'
                    ? true
                    : false,
            );
            // setTermsConditions(workspaceData.termsConditions);
        }
    }, [mode, workspaceData]);

    const handleCreate = useCallback(() => {
        console.log('Create workspace');
        const workspace: Workspace = {
            name: workspaceName,
            capacity: {type: 'capacity', referenceName: capacity},
            dataStorageLocation,
            workspaceOptInConfig: {
                isAllowModelImprovement: modelImprovement.toString(),
                isAllowProductImprovement: productImprovement.toString(),
            },
        };
        console.log({workspace});

        createWorkspace(
            {name: workspaceName},
            {
                onSuccess: () => {
                    console.log('Workspace created');
                    onClose?.();
                },
                onError: (error) => {
                    console.error('Error creating workspace', error);
                },
            },
        );
    }, [
        capacity,
        createWorkspace,
        dataStorageLocation,
        modelImprovement,
        onClose,
        productImprovement,
        workspaceName,
    ]);

    const handleFormSubmit = useCallback(
        (ev: React.FormEvent) => {
            // prevent the form from firing a submit event
            ev.preventDefault();
            console.log(ev);
            // add any additional validation checks here if needed before sending out any requests to the server
            // if we are in create mode, we should create the workspace
            if (mode === 'create') {
                handleCreate();
            } else if (mode === 'edit') {
                // if we are in edit mode, we should update the workspace
                console.log('Update workspace');
            } else if (mode === 'duplicate') {
                // if we are in duplicate mode, we should duplicate the workspace
                console.log('Duplicate workspace');
                handleCreate();
            }
        },
        [handleCreate, mode],
    );

    const primaryButtonLabel =
        mode === 'create'
            ? tCommon('ButtonLabels.Create')
            : mode === 'edit'
            ? tCommon('ButtonLabels.Update')
            : tCommon('ButtonLabels.Duplicate');

    return (
        <form onSubmit={handleFormSubmit}>
            <DialogContent className={classes.dialogContent}>
                <CapacitySelectField
                    value={capacity}
                    onOptionSelect={(capacity) => setCapacity(capacity.name)}
                    required
                />
                <StorageLocation
                    value={dataStorageLocation}
                    onOptionSelect={(selectedLocation) => setDataStorageLocation(selectedLocation)}
                />
                <WorkspaceName
                    value={workspaceName}
                    preventDuplicate
                    disabled={mode !== 'create' && mode !== 'duplicate'}
                    onChange={(value) => setWorkspaceName(value)}
                />
                <ProductImprovement
                    value={productImprovement}
                    onChange={(checked) => setProductImprovement(checked)}
                />
                <ModelImprovement
                    value={modelImprovement}
                    onChange={(checked) => setModelImprovement(checked)}
                />
                <AuditLoggingOptingSwitch includeInfoLabel={true} />
                <TermsConditions
                    value={termsConditions}
                    onChange={(checked) => setTermsConditions(checked)}
                    required
                />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.leftActions}>
                    {showDelete && (
                        <Button appearance="secondary" onClick={onDelete}>
                            {tCommon('ButtonLabels.Delete')}
                        </Button>
                    )}
                </div>
                <div className={classes.rightActions}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">{t('ButtonLabels.Cancel')}</Button>
                    </DialogTrigger>
                    <Button appearance="primary" type="submit">
                        {primaryButtonLabel}
                    </Button>
                </div>
            </DialogActions>
        </form>
    );
}

export default CreateEdit;
