import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    titleSection: {
        marginBottom: tokens.spacingVerticalMNudge,
        display: 'block',
    },
    contentSection: {
        marginBottom: tokens.spacingVerticalM,
    },
    titleContainer: {
        display: 'flex',
    },
    titleImage: {
        flexWrap: 'wrap',
        height: '28px',
        display: 'flex',
        alignContent: 'center',
        marginRight: tokens.spacingVerticalS,
    },
});
