import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalXL),
    },
    /** Shared styling for white text on a red background that meets contrast ratios */
    redButton: {
        backgroundColor: tokens.colorPaletteRedBackground3,
        color: tokens.colorNeutralForegroundOnBrand,
    },
});
