import {Components} from 'react-markdown';
import MarkdownTh from './th';
import MarkdownTd from './td';
import MarkdownTable from './table';

const documentDownloadComponents: Components = {
    table: ({node, ...props}) => <MarkdownTable {...props}></MarkdownTable>,
    th: ({node, ...props}) => <MarkdownTh {...props}></MarkdownTh>,
    td: ({node, ...props}) => <MarkdownTd {...props}></MarkdownTd>,
};

export default documentDownloadComponents;
