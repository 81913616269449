import React, {useState, useMemo, useEffect} from 'react';
import {Button, ToolbarDivider, mergeClasses, Tooltip} from '@fluentui/react-components';
import {SendIcon, SkillIcon, SkillsetIcon} from '../icons';
import {useClasses} from './PromptBarInput.styles';
import Textarea from '../Textarea';
import {motion} from 'framer-motion';
import {PromptBarInputProps} from './PromptBarInput.types';
import AcrylicBase from '../AcrylicBase/AcrylicBase';
import {useTranslation} from 'react-i18next';
import {ScreenName} from './PromptBar.types';
import {useViewportSize} from '../Grid';

const expandCenterOut = {
    initial: {scaleX: 0},
    animate: {
        scaleX: [0, 1.2, 1],
        transition: {
            duration: 0.5,
            ease: 'easeOut',
        },
    },
};

function PromptBarInput(props: PromptBarInputProps) {
    const {t} = useTranslation('promptbar');
    const {
        textareaRef,
        value,
        disabled,
        handleSubmit,
        handleChange,
        handleKeyUp,
        enableSkillsetConnector,
        enableSupportAssistance,
        editMode,
        screenName,
        ...rest
    } = props;
    const isTextareaFocused = useMemo(() => document.activeElement === textareaRef.current, []);
    // handles showing the custom hover indicator that only span part of the input
    const [showHover, setShowHover] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 320);

    const screenSize = useViewportSize();
    const isSmallOrMediumScreen = screenSize.sm || screenSize.md;
    // deal with focus state so properly set the size of the textarea
    const [focused, setFocused] = useState(false);

    const updateSize = () => {
        setIsSmallScreen(window.innerWidth <= 320);
    };
    useEffect(() => {
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // only rely on this method for the initial render, after that manually set the focused state
    useEffect(() => {
        isTextareaFocused && setFocused(true);
        // only run this once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleFocus = () => {
        handleHideHover();
        setFocused(true);
    };
    const handleBlur = () => {
        handleHideHover;
        setFocused(false);
    };

    const handleClick = () => {
        props.handleSkillMenuToggle();
        props.handleAgentViewSkillMenuToggle(false);
    };

    const handleShowHover = () => setShowHover(true);
    const handleHideHover = () => setShowHover(false);
    const classes = useClasses();
    const textareaClasses = useMemo(
        () =>
            editMode
                ? mergeClasses(classes.defaultTextarea, classes.editModeTextarea)
                : isSmallScreen
                ? mergeClasses(classes.mobileTextarea, '')
                : mergeClasses(classes.defaultTextarea, focused ? classes.tallTextarea : ''),
        // classes shouldn't change and they should not retrigger this
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editMode, focused, isSmallScreen],
    );

    const WrapperComponent = editMode ? React.Fragment : AcrylicBase;

    return (
        <div
            className={
                isSmallScreen
                    ? mergeClasses(classes.smallScreenWrapper, editMode ? classes.editMode : '')
                    : classes.wrapper
            }
            ref={props.inputWrapperRef}
        >
            {showHover && !isTextareaFocused && (
                <motion.div
                    variants={expandCenterOut}
                    initial="initial"
                    animate="animate"
                    className={classes.hoverIndicator}
                />
            )}
            <WrapperComponent shadow="small">
                <Textarea
                    ref={textareaRef}
                    value={value}
                    placeholder={
                        disabled
                            ? t('WaitingForResponse')
                            : isSmallScreen
                            ? t('ShortPlaceholder')
                            : screenName === ScreenName.Home
                            ? t('HomePlaceholder')
                            : t('DefaultPlaceholder')
                    }
                    className={textareaClasses}
                    data-testid="promptbar-input"
                    disabled={disabled}
                    autosize
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    onMouseEnter={handleShowHover}
                    onMouseLeave={handleHideHover}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    {...rest}
                />
            </WrapperComponent>
            {!editMode && (
                <div className={classes.toolbar}>
                    <Tooltip
                        content={t('tooltips.prompts')}
                        relationship="label"
                        positioning="above"
                    >
                        <Button
                            appearance="subtle"
                            icon={<SkillIcon className={disabled ? classes.iconDisabled : ''} />}
                            className={classes.skill}
                            onClick={handleClick}
                            disabled={disabled}
                            data-testid="promptbar-skill-menu-toggle"
                        />
                    </Tooltip>
                    {enableSkillsetConnector && (
                        <Tooltip
                            content={t('tooltips.sources')}
                            relationship="label"
                            positioning="above"
                        >
                            <Button
                                appearance="subtle"
                                icon={
                                    <SkillsetIcon
                                        className={disabled ? classes.iconDisabled : ''}
                                    />
                                }
                                className={classes.skill}
                                onClick={props.handleShowSkillsetConnector}
                                disabled={disabled}
                                data-testid="promptbar-sources-button"
                            />
                        </Tooltip>
                    )}
                    {!isSmallOrMediumScreen && <ToolbarDivider aria-hidden="true" />}
                    <Tooltip
                        content={t('tooltips.submitPrompt')}
                        relationship="label"
                        positioning="above"
                    >
                        <Button
                            appearance="subtle"
                            icon={<SendIcon className={disabled ? classes.iconDisabled : ''} />}
                            className={mergeClasses(classes.submit, classes.hideSubmit)}
                            onClick={props?.handleSubmit || (() => {})}
                            disabled={disabled}
                            data-testid="promptbar-submit-button"
                        />
                    </Tooltip>
                </div>
            )}
        </div>
    );
}

export default PromptBarInput;
