import {useLayout} from '@/components/ui/Layout';
import {useViewportSize, ScreenSize} from '@/components/ui/Grid';
import {SessionViewMode} from './Session.types';

// For reflow: on Large and below screens we should always default to wide view
// this function can be used to update the criteria for when we should start fallback to wide view
export function shouldShowWideView(screenSize: ScreenSize): boolean {
    return (
        screenSize === ScreenSize.Small ||
        screenSize === ScreenSize.Medium ||
        screenSize === ScreenSize.Large
    );
}

// a utility hook that determines what view mode to use for the session based on the current screen size
// and the user preference
function useSessionViewMode(): SessionViewMode {
    const {contentArea} = useLayout();
    const screenSize = useViewportSize();

    // if the user has set the session to always be full width, we should respect that
    // but if we are below a certain screen size we should always default to wide view
    const contentModeFullWidth =
        contentArea.fullWidth || shouldShowWideView(screenSize.activeViewport);

    return {isFullWidth: contentModeFullWidth};
}

export default useSessionViewMode;
