import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'grid',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    firstHeader: {
        marginTop: tokens.spacingVerticalSNudge,
        fontSize: '16px',
        lineHeight: tokens.lineHeightBase400,
        fontWeight: tokens.fontWeightSemibold,
    },
    bodyTextBold: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightSemibold,
    },
    bodyText: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
    },
});
