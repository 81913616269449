import {SelectTabData, SelectTabEvent, Tab, TabList, TabValue} from '@fluentui/react-components';
import GraphvizDotCodeBlock from '../CodeBlock/GraphvizDotCodeBlock';
import SyntaxHighlighterCodeBlock from '../CodeBlock/SyntaxHighlighterCodeBlock';
import {CodeIcon, FlowchartIcon} from '../../icons';
import {useState} from 'react';
import {GraphVisualizationTabs} from './GraphvizComponent.types';
import useClasses from './GraphwizComponent.styles';

export default function GraphvizComponent({children, className, ...props}: any) {
    const [selectedTab, setSelectedTab] = useState<TabValue>(GraphVisualizationTabs.Visualizer);

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTab(data.value);
    };

    const componentStyles = useClasses();

    return (
        <>
            <TabList
                className={componentStyles.root}
                selectedValue={selectedTab}
                onTabSelect={onTabSelect}
                tabIndex={0}
            >
                <Tab
                    icon={<CodeIcon />}
                    value={GraphVisualizationTabs.Codeblock}
                    aria-label="Switch view mode - Code Block"
                />
                <Tab
                    icon={<FlowchartIcon />}
                    value={GraphVisualizationTabs.Visualizer}
                    aria-label="Switch view mode - Visualizer"
                />
            </TabList>
            <div className={componentStyles.contentWrapper}>
                {selectedTab === GraphVisualizationTabs.Visualizer && (
                    <GraphvizDotCodeBlock {...props}>{children}</GraphvizDotCodeBlock>
                )}
                {selectedTab === GraphVisualizationTabs.Codeblock && (
                    <SyntaxHighlighterCodeBlock className={className} {...props}>
                        {children}
                    </SyntaxHighlighterCodeBlock>
                )}
            </div>
        </>
    );
}
