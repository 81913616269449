import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Session, SaveSessionSummaryRequest} from './sessions.types';

export default function useUpdateSessionSummary() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: SaveSessionSummaryRequest) => {
            if (
                body === undefined ||
                body.session === undefined ||
                body.session.sessionId === undefined ||
                body.session.sessionId === ''
            ) {
                // This condition should not happen.
                return false;
            }
            // Perform the API call to update the session
            return customFetch<Session>(`/sessions/${body.session.sessionId}`, {
                method: 'PUT',
                body: body.session,
            });
        },
        onSettled: (success, error, {session}) => {
            // Refetch the parent session.
            if (!error) {
                queryClient.refetchQueries({
                    queryKey: ['sessiongetsessionsummary', session.sessionId],
                });
            }
        },
    });
}
