import MedeinaVariables from '@/util/variables';

export const SupportCentralBaseURL: string = MedeinaVariables.SupportCentralUri ?? '';
export const cacheBuster = '?cacheBuster=' + Date.now();
export const SupportCentralScriptPath: string =
    '/js/support/concierge/external/supportcentralbootstrap.js';
export const SupportCentralScopes: string[] = [`https://portal.office.com/.default`];
export const AppName: string = 'SecurityCopilot';
export const Locale: string = 'en';
export const SupportCentralHostingDiv: string = 'supportCentralHostingDiv';
export const enableSupportCentralFeatureFlag: boolean = true;
