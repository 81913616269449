import {Prompt} from '@/api/prompts/prompts.types';
import {TableSelectionState} from '@fluentui/react-components';
import {ErrorMessage} from '@/api/errors.types';

export interface PromptProps {
    sessionId: string;
    promptId: string;
    selection?: TableSelectionState;
    isReadOnly?: boolean;
    errorMessage?: ErrorMessage;
    setErrorMessage?: (message: ErrorMessage) => void;
}

export type EditMode = 'Edit' | 'Rerun' | false;

export interface PromptContentProps extends PromptProps {
    editMode: EditMode;
    setEditMode: (mode: EditMode) => void;
    restoreFocusSourceAttribute?: any;
}

export interface PromptActionButtonsProps {
    onEdit: (mode: EditMode) => void;
    setErrMessage: (message: ErrorMessage) => void;
    restoreFocusTargetAttribute?: any;
}

export interface PromptWrapperProps {
    groupedPromptType: 'Prompt' | 'Promptbook';
    sessionId: string;
    id: string;
    isReadOnly?: boolean;
    errorMessage?: ErrorMessage;
    setErrorMessage?: (message: ErrorMessage | undefined) => void;
    selection?: TableSelectionState;
}

export interface PromptbookSectionProps {
    sessionId: string;
    promptbookExecutionId: string;
    isReadOnly?: boolean;
    errorMessage?: ErrorMessage;
    setErrorMessage?: (message: ErrorMessage) => void;
    selection?: TableSelectionState;
}

export enum GroupedPromptType {
    Prompt = 'Prompt',
    Promptbook = 'Promptbook',
}

export interface PromptActionsMenuProps {
    onEdit: (mode: EditMode) => void;
    setErrMessage?: (message: ErrorMessage) => void;
}
