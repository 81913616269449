import {Field, Input, Link, Text} from '@fluentui/react-components';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {useTranslation} from 'react-i18next';

interface OpenAIPluginUploadProps {
    failedValidationSettings: string[];
    onFormValuesUpdated: (ev: any, config: string) => void;
}

export default function OpenAIPluginUploadField({
    failedValidationSettings,
    onFormValuesUpdated,
}: OpenAIPluginUploadProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <Field
            label={{
                children: (
                    <MedeinaInfoLabel
                        infoContent={
                            <Text>
                                {t('OAIPluginInstructions')}
                                <Link
                                    href={
                                        'https://platform.openai.com/docs/plugins/getting-started'
                                    }
                                    target="_blank"
                                >
                                    {t('common.LearnMore')}
                                </Link>
                            </Text>
                        }
                        labelContent={t('OAIPluginInstructionsContent')}
                    />
                ),
            }}
            required={true}
            validationState={failedValidationSettings.includes('url') ? 'error' : 'none'}
            validationMessage={
                failedValidationSettings.includes('url') ? t('common.LinkToFileIsRequired') : ''
            }
        >
            <Input
                type={'text'}
                autoFocus
                size="large"
                appearance="outline"
                className={classes.longTextareaWrapper}
                placeholder={`https://example.com/.well-known/ai-plugin.json`}
                onChange={(ev: any) => onFormValuesUpdated(ev, 'url')}
            />
        </Field>
    );
}
