// ResourceGroupSelector.jsx
import React, {useCallback, useMemo, useState} from 'react';
import {Input, Button, useId} from '@fluentui/react-components';
import useClasses from '../ResourceGroupSelector.styles';
import {ResourceGroup} from '@/api/user/user.types';
import {useTranslation} from 'react-i18next';
import {MedeinaErrorLabel} from '@/components/ui/Buttons/MedeinaErrorLabel';
import {useViewportSize} from '@/components/ui/Grid';
import {ParagraphContent, TextSubtitle2} from '@/components/ui/Text';

interface ResourceGroupFieldProps {
    handleOkResourceGroupClick: (resourceGroup: string) => void;
    newResourceGroup?: ResourceGroup;
    dropdownResourceGroups: Array<ResourceGroup>;
    onClose: () => void;
}

function ResourceGroupField({
    newResourceGroup,
    handleOkResourceGroupClick,
    dropdownResourceGroups,
    onClose,
}: ResourceGroupFieldProps) {
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const classes = useClasses();
    const {sm: isSmallScreen} = useViewportSize();

    const buttonSize = isSmallScreen ? 'small' : 'medium';

    const paragraphContent = (
        <ParagraphContent>{t('adminV2Fre.setup.ResourceGroupSubTitle')}</ParagraphContent>
    );

    const headingContent = <TextSubtitle2>{commonLabel('CreateResourceGroup')}</TextSubtitle2>;

    const errorLabelID = useId('resource-group-error');
    const [resourceGroup, setResourceGroup] = useState(newResourceGroup?.name || '');
    // Function to validate the resource group name
    const isValidResourceGroupName = useCallback(() => {
        const pattern = /^[-\w\._\(\)]+$/;
        return pattern.test(resourceGroup) && resourceGroup.length > 0;
    }, [resourceGroup]);

    const checkResourceGroup = () => {
        if (isValidResourceGroupName()) {
            return dropdownResourceGroups?.some((rg: ResourceGroup) => {
                return rg.name === `(new) ${resourceGroup}`;
            });
        }
    };

    const ariaDescribedProp = useMemo(
        () => (!isValidResourceGroupName() ? {'aria-describedby': errorLabelID} : {}),
        [errorLabelID, isValidResourceGroupName],
    );

    return (
        <div className={classes.resourceGroupPopoverContainer}>
            <div className={classes.largeSectionSpacing}>{headingContent}</div>
            <div className={classes.mediumSectionSpacing}>{paragraphContent}</div>
            <Input
                className={classes.popoverInput}
                aria-required="true"
                type="text"
                aria-label={t('adminV2Fre.setup.ariaLabel.ResourceGroup')}
                placeholder={t('adminV2Fre.setup.NameResourceGroup')}
                value={resourceGroup}
                onChange={(e) => setResourceGroup(e.target.value)}
                {...ariaDescribedProp}
            />
            {resourceGroup.length === 0 && (
                <MedeinaErrorLabel
                    errorContent={t('adminV2Fre.setup.EmptyResourceGroup')}
                    needsResponsiveStyles={true}
                    id={errorLabelID}
                />
            )}
            {resourceGroup.length > 0 && !isValidResourceGroupName() && (
                <MedeinaErrorLabel
                    errorContent={t('adminV2Fre.setup.InvalidResourceGroupRegex')}
                    needsResponsiveStyles={true}
                    id={errorLabelID}
                />
            )}
            {checkResourceGroup() && (
                <MedeinaErrorLabel
                    errorContent={t('adminV2Fre.setup.ExistingResourceGroup')}
                    needsResponsiveStyles={true}
                    id={errorLabelID}
                />
            )}
            <div className={classes.popoverButtonsContainer}>
                <Button
                    size={buttonSize}
                    appearance="primary"
                    onClick={() => {
                        handleOkResourceGroupClick(resourceGroup);
                    }}
                    disabled={
                        !(resourceGroup.length > 0) ||
                        !isValidResourceGroupName() ||
                        checkResourceGroup()
                    }
                >
                    {t('adminV2Fre.setup.ResourceGroupOk')}
                </Button>
                <Button
                    size={buttonSize}
                    appearance="secondary"
                    onClick={() => {
                        onClose();
                    }}
                >
                    {t('adminV2Fre.setup.ResourceGroupCancel')}
                </Button>
            </div>
        </div>
    );
}

export default ResourceGroupField;
