import {useMutation, useQueryClient} from '@tanstack/react-query';
import useSessionsFetch from '../useSessionsFetch';
import {DuplicateSessionRequest, GetDuplicateSessionRequest, Session} from './sessions.types';
import {isEmpty} from 'lodash-es';

export default function useCreateDuplicateSession({sessionId}: GetDuplicateSessionRequest) {
    const queryClient = useQueryClient();
    const {sessionsFetch} = useSessionsFetch(sessionId);
    return useMutation({
        mutationFn: async ({sessionName}: DuplicateSessionRequest) => {
            if (isEmpty(sessionId)) {
                return false;
            }
            try {
                return await sessionsFetch<Session>(`${sessionId}/duplicate`, {
                    method: 'POST',
                    body: {
                        sessionName,
                    },
                });
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: ['sessions'],
            });
        },
    });
}
