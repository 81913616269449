export default function formatCurrency(
    amount: number,
    currencyCode: string,
    locale: string,
): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        currency: currencyCode,
    });
    return formatter.format(amount);
}
