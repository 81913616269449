// Shared styles for stage layouts and fonts/type
import {makeStyles, shorthands} from '@fluentui/react-components';

export default makeStyles({
    textWrapper: {
        maxWidth: '600px',
    },
    title: {
        marginBottom: '16px',
    },
    buttonRow: {
        marginTop: '48px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
});
