import {useMutation} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {FeedbackRequest} from '.';

export default function useGiveFeedback() {
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async (body: FeedbackRequest) => {
            const sanitizedBody = {
                ...body,
                category: body.category || null,
            };
            var response = await customFetch<Response>(
                `/customer/feedback`,
                {
                    method: 'POST',
                    body: sanitizedBody,
                },
                true,
            );

            return response.status === 204;
        },
    });
}
