import React from 'react';
import {
    NavActionsInput,
    NavigationContext as NavigationContextType,
    ToggableNavActions,
} from './Navigation.types';

export const defaultToggableActions = {
    sidePanelButton: false,
};

const NavigationContext = React.createContext<NavigationContextType>({
    navActions: [],
    setNavActions: (actions: NavActionsInput) => {},
    toggableActions: defaultToggableActions,
    setToggableActions: (nextActions) => {},
});

export default NavigationContext;
