import {NavigationBarHeight} from '@/components/App.styles';
import {makeStyles, shorthands} from '@fluentui/react-components';
import {tokens} from '@fluentui/react-components';

export const promptHoverFocus = 'promptHoverFocus';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        position: 'sticky',
        top: 0,
        justifyContent: 'center',
        marginBottom: tokens.spacingVerticalXXXL,
        width: '100%',
        zIndex: 1,
        opacity: 1,
        backgroundColor: tokens.colorNeutralBackground2,
    },
    fullWidthRoot: {
        overflowX: 'hidden',
    },
    promptbookIcon: {
        width: tokens.spacingHorizontalXL,
        color: tokens.colorBrandForeground1,
        height: 'auto',
    },
    stopButton: {
        marginLeft: 'auto', // This ensures the button is pushed to the far right
        justifyContent: 'flex-start',
        overflowX: 'hidden',
        width: '79px',
        height: '32px',
        fontSize: tokens.fontSizeBase400,
    },
    redBackground: {
        backgroundColor: tokens.colorPaletteRedBackground3, // Adjust to the appropriate red color from your tokens
        color: tokens.colorNeutralForegroundOnBrand,
        '&:hover': {
            backgroundColor: tokens.colorPaletteRedBackground2, // Slightly darker red on hover
        },
        '&:active': {
            backgroundColor: tokens.colorPaletteRedBackground1, // Even darker red on active
        },
    },
    stopIcon: {
        color: 'white', //tokens.colorNeutralForegroundInverted, // Ensure the icon is white
        overflowX: 'hidden',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.borderRadius('10px'),
        ...shorthands.flex(1, 1, 'auto'),
        boxShadow: tokens.shadow8,
        maxWidth: '700px',
        // we add a margin to account for the border width, this helps align the content with the prompt bar
        marginLeft: '16px',
        '@media (max-width: 479px)': {
            marginLeft: '0px',
            marginRight: '5px',
            width: '90vw',
            overflowX: 'auto',
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalXS),
    },
    promptItem: {
        ...shorthands.margin(tokens.spacingVerticalS), // Adjust this value based on your preference
    },
    fullWidthContent: {
        maxWidth: '80%',
    },
    firstDiv: {
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(
            tokens.spacingVerticalXL,
            0,
            tokens.spacingVerticalXL,
            tokens.spacingHorizontalXXXL,
        ),
        '@media (max-width: 479px)': {
            ...shorthands.padding(
                tokens.spacingVerticalXL,
                0,
                tokens.spacingVerticalXL,
                tokens.spacingVerticalS,
            ),
        },
    },
    buttonContainer: {
        justifyContent: 'right',
        ...shorthands.padding(0, 0, 0, tokens.spacingVerticalXXXL),
    },
    divider: {
        maxWidth: '800px',
        marginLeft: tokens.spacingHorizontalXXL,
        ...shorthands.padding(0, 0, tokens.spacingVerticalXXXL, 0),
    },
});
