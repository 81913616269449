import React, {useState} from 'react';
import type {EvaluationProps} from '@/components/sections/evaluations/Evaluation.types';
import {useGetEvaluation} from '@/api/evaluations';
import {Button} from '@fluentui/react-components';
import {PinOffIcon} from '@/components/ui/icons';
import {useDeletePinnedEvaluation} from '@/api/evaluations';
import {ChevronDown24Regular, ChevronRight24Regular} from '@fluentui/react-icons';
import useClasses from './PinnedEvaluation.styles';
import useEvaluationClasses from '../evaluations/Evaluation.styles';
import PromptDisplay from './PromptDisplay';
import EvaluationMetadata from '../evaluations/EvaluationMetadata';
import {Markdown} from '@/components/ui';
import {EvaluationProvider} from '../evaluations/EvaluationContext';
import {useTranslation} from 'react-i18next';

function PinnedEvaluation(props: EvaluationProps) {
    const {sessionId, promptId, evaluationId, isReadOnly} = props;
    const {t} = useTranslation('session');
    const classes = useClasses();
    const evaluationClasses = useEvaluationClasses();
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});
    const [collapsed, toggleCollapsed] = useState(true);
    const {mutate: deletePinnedEvaluation} = useDeletePinnedEvaluation();

    return (
        <EvaluationProvider {...{sessionId, promptId, evaluationId}}>
            <div className={evaluationClasses.evaluation}>
                <PromptDisplay promptSnapshot={evaluation?.promptSnapshot} />
                <div className={classes.summaryRow}>
                    <Button
                        appearance="subtle"
                        onClick={() => toggleCollapsed(!collapsed)}
                        icon={collapsed ? <ChevronRight24Regular /> : <ChevronDown24Regular />}
                        className={classes.collapseBtn}
                        aria-label={collapsed ? t('ExpandEvaluation') : t('CollapseEvaluation')}
                    />
                    {evaluation?.createdAt && <EvaluationMetadata evaluation={evaluation} />}
                </div>

                {!collapsed && (
                    <div className={classes.content}>
                        <Markdown>{evaluation?.result?.content ?? ''}</Markdown>
                        {!isReadOnly && (
                            <Button
                                appearance="transparent"
                                onClick={() =>
                                    deletePinnedEvaluation({
                                        sessionId: props.sessionId,
                                        evaluationId: props.evaluationId,
                                    })
                                }
                                icon={<PinOffIcon />}
                            >
                                {t('UnpinFromSummary')}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </EvaluationProvider>
    );
}

export default PinnedEvaluation;
