import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        width: '350px',
        '& > .fui-Field': {
            paddingBottom: tokens.spacingVerticalL,
        },
    },
    title: {
        paddingBottom: tokens.spacingVerticalXXL,
    },
    actions: {
        paddingTop: tokens.spacingVerticalXXL,
        justifyContent: 'flex-end',
    },
});
