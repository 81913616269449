/**
 * Base styles to use for the security design system.
 */

// The number of sizes in the Security type ramp (7) are fewer than
// Fluent has (10).  Because of that we repeat some of the numbers
// that are shown.  The smallest font size allowed is 12 and the
// largest 68.
const fontSizes = {
    fontSizeBase100: '12px',
    fontSizeBase200: '12px',
    fontSizeBase300: '14px',
    fontSizeBase400: '18px',
    fontSizeBase500: '24px',
    fontSizeBase600: '24px',
    fontSizeHero700: '32px',
    fontSizeHero800: '32px',
    fontSizeHero900: '40px',
    fontSizeHero1000: '68px',
};

// Added light and semilight font weights.
const fontWeights = {
    fontWeightLight: 200,
    fontWeightSemiLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemibold: 600,
    fontWeightBold: 700,
};

// spacings a multiple of 4
const spacing = ['4', '8', '12', '16', '18', '20', '24', '28', '32', '36', '40', '44', '48', '52'];

const baseStyle = {
    ...fontSizes,
    ...fontWeights,
    spacing,
};

export default baseStyle;
