import React, {useState} from 'react';
import {Button, ButtonProps, Tooltip} from '@fluentui/react-components';
import {CompactViewIcon, FullwidthViewIcon} from '../icons';
import {useLayout} from '@/components/ui/Layout';
import {useTranslation} from 'react-i18next';

function ContentWidthButton(props: ButtonProps) {
    const {contentArea} = useLayout();
    const {t} = useTranslation('session');
    const {fullWidth, setFullWidth} = contentArea;
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <Tooltip
            visible={showTooltip}
            content={fullWidth ? t('CompactViewButton') : t('FullWidthViewButton')}
            relationship="label"
        >
            <Button
                appearance="transparent"
                {...props}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false)}
                aria-label={fullWidth ? t('CompactViewButton') : t('FullWidthViewButton')}
                icon={fullWidth ? <CompactViewIcon /> : <FullwidthViewIcon />}
                onClick={() => {
                    setShowTooltip(false);
                    setFullWidth?.(!fullWidth);
                }}
            />
        </Tooltip>
    );
}

export default ContentWidthButton;
