import {
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Link,
    Popover,
    PopoverSurface,
    PositioningImperativeRef,
    Subtitle2,
    Textarea,
    ToggleButton,
} from '@fluentui/react-components';
import useClasses from './EvaluationFeedbackFormNew.styles';
import {useTourable} from '@/components/ui/Tour';
import {DismissIcon, SendIcon} from '@/components/ui/icons';
import {
    EvaluationFeedbackSubType,
    useCreateEvaluationFeedback,
    useGetEvaluationFeedback,
    useUpdateEvaluationFeedback,
} from '@/api/feedbacks';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {EvaluationFeedbackFormNewProps, FeedbackThanksMessage} from './EvaluationToolbar.types';
import getRandomElementFromArray from '@/util/getRandomElement';
import useTimer from '@/util/useTimer';
import {useEvaluationContext} from '../EvaluationContext';
import {useTranslation} from 'react-i18next';
import {
    ARIA_LABELS,
    BUTTON_LABELS,
    DISCLAIMER_LINKS,
    DISCLAIMER_TEXT,
    EvaluationFeedbackSubTypeDisplayValues,
    FEEDBACK_POPOVER_TITLE,
    FEEDBACK_THANKS_DISPLAY_TIME,
    MAX_DESCRIPTION_LENGTH,
    feedbackAttributesMap,
} from './EvaluationToolbar.constants';
import {useViewportSize} from '@/components/ui/Grid';

export default function EvaluationFeedbackFormNew(props: EvaluationFeedbackFormNewProps) {
    const {
        feedbackType,
        showFeedbackForm,
        setShowFeedbackForm,
        setFeedbackThanks,
        triggerRef,
        isEditFeedback,
        isFeedbackSubmissionAllowed,
    } = props;
    const classes = useClasses();
    const {sessionId, promptId, evaluationId} = useEvaluationContext();
    const {sm: isSmallScreen} = useViewportSize();

    // Localisation translations
    const {t: tSession} = useTranslation('session');
    const {t: tCommon} = useTranslation('common');

    const feedbackformTourRef = useTourable({id: 'feedback-form'});
    const submitButtonTourRef = useTourable({id: 'feedback-form-submit'});

    // Feedback APIs - Create, Update and Get
    const {data: feedback} = useGetEvaluationFeedback(
        {sessionId, promptId, evaluationId},
        {enabled: isFeedbackSubmissionAllowed},
    );
    const {mutate: createFeedback} = useCreateEvaluationFeedback();
    const {mutate: updateFeedback} = useUpdateEvaluationFeedback();

    // To save user inputs of description and subtypes selected
    const [description, setDescription] = useState<string>(feedback?.content || '');
    const [selectedSubtypes, setSelectedSubtypes] = useState<EvaluationFeedbackSubType[]>(
        feedback?.feedbackSubTypes || [],
    );

    // Check if user has made any changes to the feedback form
    const isSubmitDisabled = useMemo(() => {
        const subtypesUnchanged =
            JSON.stringify(selectedSubtypes.sort()) ===
            JSON.stringify(feedback?.feedbackSubTypes?.sort() || []);
        const contentUnchanged = description === (feedback?.content || '');

        return subtypesUnchanged && contentUnchanged;
    }, [feedback, selectedSubtypes, description]);

    // Position the feedback form near the trigger button.
    const positioningRef = useRef<PositioningImperativeRef>(null);
    useEffect(() => {
        if (triggerRef.current) {
            positioningRef.current?.setTarget(triggerRef.current);
        }
    }, [triggerRef.current, isSmallScreen]);

    // Load existing feedback into the form
    useEffect(() => {
        setSelectedSubtypes(feedback?.feedbackSubTypes || []);
        setDescription(feedback?.content || '');
    }, [feedback]);

    // Handle user selections for feedback subtypes.
    const handleSubTypeToggle = (subtype: EvaluationFeedbackSubType) => {
        setSelectedSubtypes((existingSubtypes) => {
            let existingSubtypesSet = new Set(existingSubtypes);
            if (existingSubtypesSet.has(subtype)) {
                // If the subtype is already selected, remove it from the array
                existingSubtypesSet.delete(subtype);
            } else {
                // If the subtype is not selected, add it to the array
                existingSubtypesSet.add(subtype);
            }
            return Array.from(existingSubtypesSet);
        });
    };

    // Handle description changes by user - limit to 200 characters.
    const handleTextareaChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (ev.target.value.length <= MAX_DESCRIPTION_LENGTH) {
            setDescription(ev.target.value);
        }
    };

    // Hide feedback thanks message after a few seconds.
    const feedbackThanksTimer = useTimer({
        time: FEEDBACK_THANKS_DISPLAY_TIME,
        onTimerEnd: () => setFeedbackThanks(null),
    });

    // Handle feedback submission. Updates feedback, resets user changes, hides form, and displays a thank you message on successful update.
    const handleFeedbackSubmit = useCallback(() => {
        if (!isEditFeedback) {
            createFeedback(
                {
                    sessionId,
                    promptId,
                    evaluationId,
                    feedbackType,
                    feedbackSubTypes: selectedSubtypes,
                    content: description,
                },
                {
                    onSuccess: () => {
                        setFeedbackThanks(
                            getRandomElementFromArray<FeedbackThanksMessage>(
                                feedbackAttributesMap[feedbackType].thanksMessage,
                            ),
                        );
                        feedbackThanksTimer();
                    },
                },
            );
        } else {
            if (feedback) {
                updateFeedback(
                    {
                        sessionId,
                        promptId,
                        evaluationId,
                        feedbackId: feedback.feedbackId,
                        feedbackType,
                        feedbackSubTypes: selectedSubtypes,
                        content: description,
                    },
                    {
                        onSuccess: () => {
                            setFeedbackThanks(
                                getRandomElementFromArray<FeedbackThanksMessage>(
                                    feedbackAttributesMap[feedbackType].thanksMessage,
                                ),
                            );
                            feedbackThanksTimer();
                        },
                    },
                );
            }
        }
        setShowFeedbackForm(false);
    }, [
        feedback,
        feedbackType,
        updateFeedback,
        setShowFeedbackForm,
        setFeedbackThanks,
        selectedSubtypes,
        description,
    ]);

    // Handle cancel or dismiss feedback form buttons
    const handleCancelOrDismissFeedback = () => {
        setShowFeedbackForm(false);
        setSelectedSubtypes(feedback?.feedbackSubTypes || []);
        setDescription(feedback?.content || '');
    };

    return !isSmallScreen ? (
        <Popover
            withArrow
            trapFocus
            open={showFeedbackForm}
            positioning={{positioningRef, position: 'after'}}
        >
            <PopoverSurface className={classes.root} ref={feedbackformTourRef}>
                <div className={classes.title}>
                    <Subtitle2>{tSession(FEEDBACK_POPOVER_TITLE)}</Subtitle2>
                    <Button
                        appearance="subtle"
                        icon={<DismissIcon />}
                        onClick={handleCancelOrDismissFeedback}
                        aria-label={tSession(ARIA_LABELS.DismissEvaluationFeedbackForm)}
                    />
                </div>
                <div className={classes.subtypes} data-tour-id="feedback-subtypes">
                    {feedbackAttributesMap[feedbackType].subtypes?.map((subtype) => (
                        <ToggleButton
                            key={subtype}
                            appearance="subtle"
                            shape="circular"
                            className={classes.subtypeBtn}
                            value={subtype}
                            onClick={() => handleSubTypeToggle(subtype)}
                            checked={selectedSubtypes.includes(subtype)}
                            aria-label={
                                tSession(FEEDBACK_POPOVER_TITLE) +
                                tSession(EvaluationFeedbackSubTypeDisplayValues[subtype])
                            }
                        >
                            {tSession(EvaluationFeedbackSubTypeDisplayValues[subtype])}
                        </ToggleButton>
                    ))}
                </div>
                <Textarea
                    value={description}
                    onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => handleTextareaChange(ev)}
                    size="medium"
                    placeholder={tSession(feedbackAttributesMap[feedbackType].textareaPlaceholder)}
                    resize="none"
                    aria-label={tSession(feedbackAttributesMap[feedbackType].textareaLabel)}
                    className={classes.textarea}
                />
                <div className={classes.disclaimer}>
                    {tSession(DISCLAIMER_TEXT)}
                    <br />
                    <Link
                        inline
                        target="_blank"
                        href={DISCLAIMER_LINKS.WhatsCollected.url}
                        className={classes.disclaimerLink}
                    >
                        {tCommon(DISCLAIMER_LINKS.WhatsCollected.text)}
                    </Link>{' '}
                    |{' '}
                    <Link
                        inline
                        target="_blank"
                        href={DISCLAIMER_LINKS.PrivacyStatement.url}
                        className={classes.disclaimerLink}
                    >
                        {tCommon(DISCLAIMER_LINKS.PrivacyStatement.text)}
                    </Link>
                </div>
                <div className={classes.submitBtn}>
                    <Button
                        appearance="primary"
                        data-testid="feedback-submit-button"
                        ref={submitButtonTourRef}
                        icon={<SendIcon />}
                        onClick={handleFeedbackSubmit}
                        disabled={isSubmitDisabled}
                    >
                        {isEditFeedback
                            ? tCommon(BUTTON_LABELS.UpdateFeedback)
                            : tCommon(BUTTON_LABELS.SendFeedback)}
                    </Button>
                    <Button
                        appearance="secondary"
                        data-testid="feedback-cancel-button"
                        onClick={handleCancelOrDismissFeedback}
                        aria-label={tSession(ARIA_LABELS.CancelFeedback)}
                    >
                        {tCommon(BUTTON_LABELS.CancelFeedback)}
                    </Button>
                </div>
            </PopoverSurface>
        </Popover>
    ) : (
        <Drawer open={showFeedbackForm} position="bottom">
            <DrawerHeader className={classes.drawerHeader}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            icon={<DismissIcon />}
                            onClick={handleCancelOrDismissFeedback}
                            aria-label={tSession(ARIA_LABELS.DismissEvaluationFeedbackForm)}
                        />
                    }
                >
                    <Subtitle2>{tSession(FEEDBACK_POPOVER_TITLE)}</Subtitle2>
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <div className={classes.subtypes} data-tour-id="feedback-subtypes">
                    {feedbackAttributesMap[feedbackType].subtypes?.map((subtype) => (
                        <ToggleButton
                            key={subtype}
                            appearance="subtle"
                            shape="circular"
                            className={classes.subtypeBtn}
                            value={subtype}
                            onClick={() => handleSubTypeToggle(subtype)}
                            checked={selectedSubtypes.includes(subtype)}
                            aria-label={
                                tSession(FEEDBACK_POPOVER_TITLE) +
                                tSession(EvaluationFeedbackSubTypeDisplayValues[subtype])
                            }
                        >
                            {tSession(EvaluationFeedbackSubTypeDisplayValues[subtype])}
                        </ToggleButton>
                    ))}
                </div>
                <Textarea
                    value={description}
                    onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => handleTextareaChange(ev)}
                    size="medium"
                    placeholder={tSession(feedbackAttributesMap[feedbackType].textareaPlaceholder)}
                    resize="none"
                    aria-label={tSession(feedbackAttributesMap[feedbackType].textareaLabel)}
                    className={classes.textarea}
                />
                <div className={classes.disclaimer}>
                    {tSession(DISCLAIMER_TEXT)}
                    <br />
                    <Link
                        inline
                        target="_blank"
                        href={DISCLAIMER_LINKS.WhatsCollected.url}
                        className={classes.disclaimerLink}
                    >
                        {tCommon(DISCLAIMER_LINKS.WhatsCollected.text)}
                    </Link>{' '}
                    |{' '}
                    <Link
                        inline
                        target="_blank"
                        href={DISCLAIMER_LINKS.PrivacyStatement.url}
                        className={classes.disclaimerLink}
                    >
                        {tCommon(DISCLAIMER_LINKS.PrivacyStatement.text)}
                    </Link>
                </div>
                <div className={classes.submitBtn}>
                    <Button
                        appearance="primary"
                        data-testid="feedback-submit-button"
                        ref={submitButtonTourRef}
                        icon={<SendIcon />}
                        onClick={handleFeedbackSubmit}
                        disabled={isSubmitDisabled}
                    >
                        {isEditFeedback
                            ? tCommon(BUTTON_LABELS.UpdateFeedback)
                            : tCommon(BUTTON_LABELS.SendFeedback)}
                    </Button>
                    <Button
                        appearance="secondary"
                        data-testid="feedback-cancel-button"
                        onClick={handleCancelOrDismissFeedback}
                        aria-label={tSession(ARIA_LABELS.CancelFeedback)}
                    >
                        {tCommon(BUTTON_LABELS.CancelFeedback)}
                    </Button>
                </div>
            </DrawerBody>
        </Drawer>
    );
}
