import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export const cardHoverFocus = 'cardHoverFocus';

export default makeStyles({
    tipContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        boxSizing: 'border-box',
        ...shorthands.border('1px', 'solid', 'transparent'),
        '&:hover ': {
            ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
            '& .fui-Button': {
                display: 'flex',
            },
        },
        marginTop: tokens.spacingVerticalL,
        marginBottom: tokens.spacingVerticalXXXL,

        [`&:hover .${cardHoverFocus}, &:focus-within .${cardHoverFocus}`]: {
            visibility: 'visible',
            opacity: 1,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'center',
    },
    tipType: {
        marginTop: tokens.spacingHorizontalXXL,
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightBold,
        fontSize: '10px',
        lineHeight: '13px',
        textAlign: 'center',
        color: tokens.colorPaletteAnchorBorderActive,
    },
    tipTitleRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tipTitle: {
        marginTop: tokens.spacingVerticalL,
        left: '19%',
        right: '19%',
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: '19px',
        textAlign: 'center',
        color: tokens.colorNeutralForeground1,
    },
    tipInformation: {
        marginTop: tokens.spacingVerticalM,
        left: '21%',
        right: '21%',
        width: '420px',
        fontFamily: tokens.fontFamilyBase,
        fontHeight: '400',
        fontSize: tokens.fontSizeBase300,
        lineWeight: '19px',
        display: 'flex',
        textAlign: 'center',
        color: tokens.colorNeutralForeground3,
        marginBottom: tokens.spacingHorizontalXXL,
    },
    tipIcon: {
        marginTop: tokens.spacingVerticalL,
        width: '64px',
        height: '64px',
        textAlign: 'center',
    },
    closeButton: {
        visibility: 'hidden',
        position: 'absolute',
        right: tokens.spacingHorizontalM,
        top: tokens.spacingHorizontalM,
    },
    alignedCenter: {
        alignSelf: 'center',
    },
});
