import {differenceInDays, isToday} from 'date-fns';
import {GivenDateTimeProps} from './DateTimeFormat.types';
import {useMemo} from 'react';
import {formatLocaleDate as format} from '@/util/locale';

const todayFormat = 'h:mm a';
const weekFormat = 'EEEE';
const dateFormat = 'M/d/y';

export default function DateTimeFormat({date}: GivenDateTimeProps) {
    var givenDate = new Date(date);
    const currentDate = new Date();
    const daysDifference = differenceInDays(currentDate, givenDate);

    const formatDate = useMemo(() => {
        if (isToday(givenDate)) {
            return format(givenDate, todayFormat);
        } else if (daysDifference < 7) {
            return format(givenDate, weekFormat);
        } else {
            return format(givenDate, dateFormat);
        }
    }, [date, daysDifference]);

    return <>{formatDate}</>;
}
