import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    tooltipChildrenContainer: {
        cursor: 'pointer',
        display: 'inline',
    },
    badge: {
        ...shorthands.margin(0, tokens.spacingHorizontalXS),
        ...shorthands.padding(0, tokens.spacingHorizontalS),
    },
});
