import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalM,
    },
    skill: {
        ...shorthands.flex(0, 0, 'auto'),
        paddingRight: tokens.spacingHorizontalM,
    },
    skillIcon: {
        color: tokens.colorBrandForeground1,
    },
    dismissIcon: {
        paddingLeft: tokens.spacingHorizontalM,
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalM,
        ...shorthands.flex(1, 1, 'auto'),
        maxHeight: '60vh',
        '@media (min-width: 640px) and (max-width: 1023px)': {
            maxHeight: '45vh',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            maxHeight: '40vh',
        },
        '@media (min-width: 321px) and (max-width: 479px)': {
            maxHeight: '50vh',
        },
        '@media (max-width: 320px)': {
            maxHeight: '20vh',
        },
        overflowY: 'auto',
    },
    textareaWrapper: {},
});
