import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tagsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '2',
        flexDirection: 'row',
        width: '95%',
    },
    containerEdit: {
        backgroundColor: tokens.colorSubtleBackgroundSelected,
    },
    containerShow: {},
    tagContainer: {
        height: tokens.fontSizeBase500,
        minWidth: 'auto',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    badge: {
        height: tokens.fontSizeBase500,
    },
    button: {
        ...shorthands.padding('0px'),
        ...shorthands.border('0px'),
        minWidth: 'auto',
        backgroundColor: tokens.colorSubtleBackgroundPressed,
        height: tokens.spacingHorizontalXXL,
        ...shorthands.borderRadius(
            '0px',
            tokens.borderRadiusMedium,
            tokens.borderRadiusMedium,
            '0px',
        ),
    },
    icon: {
        fontSize: tokens.fontSizeBase200,
        ...shorthands.padding('0px'),
        ...shorthands.border('0px'),
        minWidth: 'auto',
        backgroundColor: tokens.colorSubtleBackgroundPressed,
        ...shorthands.padding(tokens.spacingHorizontalSNudge),
    },
    tagInput: {
        height: tokens.lineHeightBase300,
        backgroundColor: tokens.colorSubtleBackgroundSelected,
        ...shorthands.margin('0px'),
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.borderWidth('0px'),
        ...shorthands.padding('5px', tokens.spacingHorizontalL, '7px', tokens.spacingHorizontalL),
        ...shorthands.border('0px', 'none', 'transparent'),
        ...shorthands.outline('0px'),
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        fontFamily: tokens.fontFamilyBase,
        width: '100%',
    },
});
