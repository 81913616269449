import {useMemo, ReactNode} from 'react';
import {mergeClasses} from '@griffel/react';
import {useTranslation} from 'react-i18next';
import {formatLocaleDate as format} from '@/util/locale';
import useClasses from './NavigationBanner.styles';
import useNavigationBanner from './useNavigationBanner';
import {useGetUserInfo} from '@/api/app';
import {Link} from '@fluentui/react-components';
import MedeinaVariables from '@/util/variables';
import {InfoFilled, WarningFilled} from '@fluentui/react-icons';

export default function NavigationBanner() {
    const classes = useClasses();
    const {t} = useTranslation('common');
    const {t: tTours} = useTranslation('tours');
    const {data: userInfo} = useGetUserInfo();
    const bannerState = useNavigationBanner();
    const {isVisible, isUntrustedApi, isFidelisApi, isInfo, isWarning} = bannerState;

    // Determine the banner message.
    const message = useMemo<ReactNode>(() => {
        const isAdmin = Boolean(userInfo?.isAdmin);

        // For API endpoint overrides.
        if (isUntrustedApi) {
            return `The portal is currently communicating with ${
                isFidelisApi ? 'the Fidelis' : 'an untrusted'
            } backend service. If you are not doing ${
                isFidelisApi ? 'Fidelis' : 'local'
            } development, please close the page.`;
        }
        // For EAP subscription expiration.
        else if (bannerState.eapExpiryDate && (isInfo || isWarning)) {
            let expiryMessage = null;
            const expiresAt = format(bannerState.eapExpiryDate, 'MMMM d');
            if (bannerState.isEapExpired) {
                expiryMessage = tTours(`eapExpiry.EAPExpired${isAdmin ? 'Admin' : 'Analyst'}`, {
                    expiresAt,
                });
            } else if (bannerState.isEapExpiresIn0Days) {
                expiryMessage = tTours(
                    `eapExpiry.EAPExpiresIn0Days${isAdmin ? 'Admin' : 'Analyst'}`,
                    {expiresAt},
                );
            } else if (bannerState.isEapExpiresIn1Day) {
                expiryMessage = tTours(
                    `eapExpiry.EAPExpiresIn1Day${isAdmin ? 'Admin' : 'Analyst'}`,
                    {expiresAt},
                );
            } else if (bannerState.isEapExpiresIn7Days) {
                expiryMessage = tTours(
                    `eapExpiry.EAPExpiresIn7Days${isAdmin ? 'Admin' : 'Analyst'}`,
                    {expiresAt},
                );
            } else if (bannerState.isEapExpiresIn14Days) {
                expiryMessage = tTours(
                    `eapExpiry.EAPExpiresIn14Days${isAdmin ? 'Admin' : 'Analyst'}`,
                    {expiresAt},
                );
            }

            if (expiryMessage) {
                return (
                    <>
                        {`${expiryMessage} `}
                        <Link
                            data-test-id="read-more-about-security-compute-units-link"
                            target="_blank"
                            href={MedeinaVariables.SecurityComputeUnitsUri}
                        >
                            {t('LearnMore')}
                        </Link>
                    </>
                );
            }
        }

        return null;
    }, [tTours, t, isUntrustedApi, isFidelisApi, isInfo, isWarning, bannerState, userInfo]);

    return (
        <>
            {isVisible && message && (
                <div
                    className={mergeClasses(
                        classes.root,
                        !isWarning && classes.info,
                        isWarning && classes.warning,
                    )}
                    data-testid={isUntrustedApi ? 'untrusted-warning' : 'navigation-banner'}
                >
                    <div className={classes.icon}>
                        {isWarning ? <WarningFilled /> : <InfoFilled />}
                    </div>
                    <div className={classes.message}>{message}</div>
                </div>
            )}
        </>
    );
}
