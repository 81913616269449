import {
    Flag,
    StarStruck,
    ThumbsUp,
    PerseveringFace,
    FaceSunGlasses,
    Compass,
} from '@/components/ui/emojis';
import {
    HelpfulFeedbackIcon,
    OffTargetFeedbackIcon,
    ReportFeedbackIcon,
    SubmitEvaluationFeedbackIcon,
} from '@/components/ui/icons';
import {EvaluationFeedbackAttributes, FeedbackThanksMessage} from './EvaluationToolbar.types';
import {EvaluationFeedbackSubType, EvaluationFeedbackType} from '@/api/feedbacks/feedbacks.types';
import MedeinaVariables from '@/util/variables';

export const MAX_DESCRIPTION_LENGTH: number = 200;
export const FEEDBACK_THANKS_DISPLAY_TIME: number = 3500;
export const DISCLAIMER_TEXT = 'EvaluationFeedbacks.DisclaimerText';
export const FEEDBACK_POPOVER_TITLE = 'EvaluationFeedbacks.FeedbackFormTitle';
export const FEEDBACK_OPT_OUT_TOOLTIP = 'EvaluationFeedbacks.FeedbackOptOutTooltip';
export const DISCLAIMER_LINKS = {
    PrivacyStatement: {
        text: 'privacyStatementLink',
        url: MedeinaVariables.PrivacyStatementUri,
    },
    WhatsCollected: {
        text: 'learnWhatsCollectedLink',
        url: 'https://learn.microsoft.com/en-us/security-copilot/privacy-data-security',
    },
};
export const BUTTON_LABELS = {
    EditFeedback: 'EvaluationFeedbacks.ButtonLabels.EditFeedback',
    ResetFeedback: 'ButtonLabels.Reset',
    UpdateFeedback: 'ButtonLabels.Update',
    SendFeedback: 'ButtonLabels.Send',
    CancelFeedback: 'ButtonLabels.Cancel',
};
export const ARIA_LABELS = {
    DismissEvaluationFeedbackForm: 'EvaluationFeedbacks.AriaLabels.DismissEvaluationFeedbackForm',
    DismissEvaluationFeedbackThanksForm:
        'EvaluationFeedbacks.AriaLabels.DismissEvaluationFeedbackThanksForm',
    CancelFeedback: 'EvaluationFeedbacks.AriaLabels.CancelFeedback',
    CopyResponse: 'EvaluationFeedbacks.AriaLabels.CopyResponse',
};

export const confirmFeedbackThanks: Array<FeedbackThanksMessage> = [
    {
        text: 'EvaluationFeedbacks.Confirm.ThanksMessage.StarStruck',
        image: StarStruck,
    },
    {
        text: 'EvaluationFeedbacks.Confirm.ThanksMessage.ThumbsUp',
        image: ThumbsUp,
    },
    {
        text: 'EvaluationFeedbacks.Confirm.ThanksMessage.FaceSunGlasses',
        image: FaceSunGlasses,
    },
];

export const offtargetFeedbackThanks: Array<FeedbackThanksMessage> = [
    {
        text: 'EvaluationFeedbacks.OffTarget.ThanksMessage.Compass',
        image: Compass,
    },
    {
        text: 'EvaluationFeedbacks.OffTarget.ThanksMessage.PerseveringFace',
        image: PerseveringFace,
    },
];

export const reportFeedbackThanks: Array<FeedbackThanksMessage> = [
    {
        text: 'EvaluationFeedbacks.Report.ThanksMessage.Flag',
        image: Flag,
    },
];

export const unknownFeedbackThanks: Array<FeedbackThanksMessage> = [
    {text: 'EvaluationFeedbacks.Unknown.ThanksMessage.Flag', image: Flag},
];

export const feedbackAttributesMap: Record<EvaluationFeedbackType, EvaluationFeedbackAttributes> = {
    [EvaluationFeedbackType.Confirm]: {
        icon: <HelpfulFeedbackIcon />,
        title: 'EvaluationFeedbacks.Confirm.Title',
        className: 'confirmBtn',
        thanksMessage: confirmFeedbackThanks,
        textareaPlaceholder: `EvaluationFeedbacks.Confirm.TextareaPlaceholder`,
        textareaLabel: 'EvaluationFeedbacks.Confirm.TextareaLabel',
        subtypes: [
            EvaluationFeedbackSubType.Accurate,
            EvaluationFeedbackSubType.Clear,
            EvaluationFeedbackSubType.Complete,
        ],
    },
    [EvaluationFeedbackType.OffTarget]: {
        icon: <OffTargetFeedbackIcon />,
        title: 'EvaluationFeedbacks.OffTarget.Title',
        className: 'offTargetBtn',
        thanksMessage: offtargetFeedbackThanks,
        textareaPlaceholder: `EvaluationFeedbacks.OffTarget.TextareaPlaceholder`,
        textareaLabel: 'EvaluationFeedbacks.OffTarget.TextareaLabel',
        subtypes: [
            EvaluationFeedbackSubType.Inaccurate,
            EvaluationFeedbackSubType.Unclear,
            EvaluationFeedbackSubType.Incomplete,
        ],
    },
    [EvaluationFeedbackType.Report]: {
        icon: <ReportFeedbackIcon />,
        title: 'EvaluationFeedbacks.Report.Title',
        className: 'reportBtn',
        thanksMessage: reportFeedbackThanks,
        textareaPlaceholder: `EvaluationFeedbacks.Report.TextareaPlaceholder`,
        textareaLabel: 'EvaluationFeedbacks.Report.TextareaLabel',
        subtypes: [
            EvaluationFeedbackSubType.Biases,
            EvaluationFeedbackSubType.Stereotypes,
            EvaluationFeedbackSubType.HateSpeech,
            EvaluationFeedbackSubType.Violence,
            EvaluationFeedbackSubType.SelfHarm,
            EvaluationFeedbackSubType.SexualReferences,
            EvaluationFeedbackSubType.Other,
        ],
    },
    [EvaluationFeedbackType.Unknown]: {
        icon: <SubmitEvaluationFeedbackIcon />,
        title: 'EvaluationFeedbacks.Unknown.Title',
        className: '',
        thanksMessage: unknownFeedbackThanks,
        textareaPlaceholder: 'EvaluationFeedbacks.Unknown.TextareaPlaceholder',
        textareaLabel: 'EvaluationFeedbacks.Unknown.TextareaLabel',
        subtypes: [],
    },
};

export const EvaluationFeedbackSubTypeDisplayValues = {
    [EvaluationFeedbackSubType.Unknown]: 'EvaluationFeedbacks.Unknown.Subtypes.Unknown',

    [EvaluationFeedbackSubType.Accurate]: 'EvaluationFeedbacks.Confirm.Subtypes.Accurate',
    [EvaluationFeedbackSubType.Clear]: 'EvaluationFeedbacks.Confirm.Subtypes.Clear',
    [EvaluationFeedbackSubType.Complete]: 'EvaluationFeedbacks.Confirm.Subtypes.Complete',

    [EvaluationFeedbackSubType.Inaccurate]: 'EvaluationFeedbacks.OffTarget.Subtypes.Inaccurate',
    [EvaluationFeedbackSubType.Unclear]: 'EvaluationFeedbacks.OffTarget.Subtypes.Unclear',
    [EvaluationFeedbackSubType.Incomplete]: 'EvaluationFeedbacks.OffTarget.Subtypes.Incomplete',

    [EvaluationFeedbackSubType.Biases]: 'EvaluationFeedbacks.Report.Subtypes.Biases',
    [EvaluationFeedbackSubType.Stereotypes]: 'EvaluationFeedbacks.Report.Subtypes.Stereotypes',
    [EvaluationFeedbackSubType.HateSpeech]: 'EvaluationFeedbacks.Report.Subtypes.HateSpeech',
    [EvaluationFeedbackSubType.Violence]: 'EvaluationFeedbacks.Report.Subtypes.Violence',
    [EvaluationFeedbackSubType.SelfHarm]: 'EvaluationFeedbacks.Report.Subtypes.SelfHarm',
    [EvaluationFeedbackSubType.SexualReferences]:
        'EvaluationFeedbacks.Report.Subtypes.SexualReferences',
    [EvaluationFeedbackSubType.Other]: 'EvaluationFeedbacks.Report.Subtypes.Other',
};
