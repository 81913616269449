import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr auto',
    },
    button: {
        width: '100%',
        ...shorthands.outline('none'),
        ...shorthands.border('none'),
        ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingHorizontalL),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        justifyContent: 'flex-start',
    },
    modal: {},
    closeButton: {
        minWidth: 'unset',
    },
    modalWrapper: {
        maxWidth: '800px',
        height: '650px',
        overflowY: 'hidden',
    },
    modalWrapperSmall: {
        ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingVerticalM),
    },
    settingContent: {
        overflowY: 'auto',
        maxHeight: '550px',
    },
    hidden: {
        display: 'none',
    },
    dialogTitle: {
        marginBottom: tokens.spacingVerticalXXL,
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
