import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        '&>button': {
            paddingLeft: tokens.spacingHorizontalNone,
            paddingRight: tokens.spacingHorizontalNone,
            marginRight: '21.06px',
            paddingBottom: '5.71px',
            paddingTop: tokens.spacingVerticalNone,

            '&::after': {
                width: tokens.spacingHorizontalL,
                left: tokens.spacingHorizontalXXS,
                height: '1.5px',
                top: '25.71px',
            },
        },
    },
    contentWrapper: {
        '& pre:first-of-type': {
            marginTop: '17.5px',
        },
    },
});
