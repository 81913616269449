import * as React from 'react';
import {Button, Combobox, Option, Persona, Spinner, useId} from '@fluentui/react-components';
import type {ComboboxProps} from '@fluentui/react-components';
import useGetUserProfiles from '@/api/user/useGetUserProfiles';
import {Dismiss12Regular} from '@fluentui/react-icons';
import {UserProfile} from '@/api/user/user.types';
import {useEffect} from 'react';
import useClasses from './PeoplePicker.styles';
import PersonaElement from '@/components/ui/Persona/PersonaElement';
import useDebounce from '@/util/useDebounce';
import {motion} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';

export default function PeoplePicker(
    props: Partial<ComboboxProps> & {sendDataToParent: (data: any) => void},
) {
    const comboId = useId('combo-default');
    const selectedListId = `${comboId}-selection`;
    const classes = useClasses();
    const {t} = useTranslation('session');
    // refs for managing focus when removing tags
    const selectedListRef = React.useRef<HTMLUListElement>(null);
    const comboboxInputRef = React.useRef<HTMLInputElement | null>(null);

    // State for selected user profiles and search query
    const [selectedUserProfiles, setSelectedUserProfiles] = React.useState<UserProfile[]>([]);
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
    const [userProfileSearch, setUserProfileSearch] = React.useState<string>('');
    const debouncedSearch = useDebounce(userProfileSearch, 300);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    // Get user profiles based on userProfileSearch
    const {
        data: userProfiles,
        refetch: fetchProfiles,
        isLoading,
    } = useGetUserProfiles(debouncedSearch);

    const handleObjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setUserProfileSearch(value);
    };

    // Fetch userprofiles only when userProfileSearch is not empty
    useEffect(() => {
        if (userProfileSearch && fetchProfiles) {
            // Check if fetchProfiles is not null
            fetchProfiles();
        }
    }, [userProfileSearch, fetchProfiles]);

    // Focus the Combobox input element when the component mounts
    useEffect(() => {
        if (comboboxInputRef.current) {
            comboboxInputRef.current.focus();
        }
    }, []);

    // Update the selected userProfiles in the Combobox.
    const onSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
        setSelectedOptions(data.selectedOptions);
        const matchingProfiles = userProfiles!.filter((o) => o.mail === data.optionValue);
        const updatedSelectedUserProfiles = [...selectedUserProfiles, ...matchingProfiles];
        setSelectedUserProfiles(updatedSelectedUserProfiles);
        // setUserProfileSearch('');// commenting code to let users select multiple users at a time.
        props.sendDataToParent(updatedSelectedUserProfiles);
    };

    // Modifying the tag when clicked and uodating the selected users
    const onTagClick = (option: string, index: number) => {
        // remove selected option
        setSelectedOptions(selectedOptions.filter((o) => o !== option));
        const updatedSelectedUserProfiles = selectedUserProfiles.filter((o) => o.mail !== option);
        setSelectedUserProfiles(updatedSelectedUserProfiles);
        props.sendDataToParent(updatedSelectedUserProfiles);

        //focus previous or next option, defaulting to focusing back to the combo input
        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${comboId}-remove-${indexToFocus}`,
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
    };

    return (
        <div className={classes.root}>
            {selectedOptions.length ? (
                <ul
                    data-testid="share-session-selected-users"
                    id={selectedListId}
                    className={classes.tagsList}
                    ref={selectedListRef}
                >
                    {/* The "Remove" span is used for naming the buttons without affecting the Combobox name */}
                    <span id={`${comboId}-remove`} hidden>
                        {t('CloseButton')}
                    </span>
                    {selectedOptions.map((option, i) => (
                        <li key={option}>
                            <Button
                                size="small"
                                shape="circular"
                                appearance="primary"
                                icon={<Dismiss12Regular />}
                                iconPosition="after"
                                onClick={() => onTagClick(option, i)}
                                id={`${comboId}-remove-${i}`}
                                aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                            >
                                {option}
                            </Button>
                        </li>
                    ))}
                </ul>
            ) : null}
            <Combobox
                data-testid="share-session-peoplepicker-combo"
                aria-labelledby={comboId}
                freeform
                placeholder={t('InviteByNameOrEmailText')}
                onChange={handleObjectChange}
                onOptionSelect={onSelect}
                multiselect={true}
                selectedOptions={selectedOptions}
                ref={comboboxInputRef}
                autoComplete="off"
                {...props}
            >
                {isLoading && (
                    <motion.div
                        className={classes.loader}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{delay: 0, duration: 0.3}}
                        exit={{opacity: 0, transition: {duration: 0.3}}}
                        key="Loading"
                    >
                        <Spinner labelPosition="after" label={t('LoadingText')} />
                    </motion.div>
                )}
                {userProfiles?.map((profile) => (
                    <Option
                        data-testid="share-session-userlist-user"
                        key={profile.id}
                        value={profile.mail}
                        text={profile.displayName}
                    >
                        <div>
                            <PersonaElement profile={profile}></PersonaElement>
                        </div>
                    </Option>
                ))}
            </Combobox>
            <div className={classes.displayText}>
                {isWorkspacesTestingEnabled
                    ? t('PeopleInYourWorkspace')
                    : t('PeopleInYourOrganization')}
            </div>
        </div>
    );
}
