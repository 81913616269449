import * as React from 'react';
import {useEffect, useState} from 'react';

import {GetRoleMembersResponse} from '@/api/rbac';
import {Condition, MemberType, PolicyWorkspace, RoleMemberIds} from '@/api/rbac/rbac.types';
import {RoleObjectIdData, RoleType} from '@/components/admin/rbac/rbac.types';
import UserPile from './UserPile';
import {ROLE_POLICY_NAME_PREFIX_MAP} from '@/components/admin/rbac/rbac.constants';

interface WorkspaceRoleMembersProps {
    workspacePolicy: PolicyWorkspace;
    werePoliciesSuccessfullyFetched: boolean;
    dataRefetching: boolean;
    policyReferenceName: string;
    onRecommendedRolesDetected?: (hasRecommendedRoles: boolean) => void;
    getOwnerMembers: boolean;
}
export default function WorkspaceRoleMembers({
    workspacePolicy,
    werePoliciesSuccessfullyFetched,
    dataRefetching,
    policyReferenceName,
    onRecommendedRolesDetected,
    getOwnerMembers,
}: WorkspaceRoleMembersProps) {
    const [roleData, setRoleData] = useState<GetRoleMembersResponse>();
    const [roleMemberIds, setRoleMemberIds] = useState<RoleMemberIds>();
    const [roleObjectIdData, setRoleObjectIdData] = useState<RoleObjectIdData>();

    const [policyProcessed, setPolicyProcessed] = useState<boolean>(false);

    useEffect(() => {
        if (dataRefetching) {
            setPolicyProcessed(false);
        }
    }, [dataRefetching]);

    // TO generate the owner and contributors data with respective users,roles and groups memebrs list using graph and policy APIs.
    useEffect(() => {
        if (werePoliciesSuccessfullyFetched && !!workspacePolicy && !!policyReferenceName) {
            setRoleData(workspacePolicy);
            const roleType = getOwnerMembers
                ? ROLE_POLICY_NAME_PREFIX_MAP[RoleType.Owner]
                : ROLE_POLICY_NAME_PREFIX_MAP[RoleType.Contributor];
            const roleMemberIds: RoleMemberIds = {IamItems: {User: [], Role: [], Group: []}};

            workspacePolicy.properties.attributeRules
                .find((x) => x.id === `${roleType}:${policyReferenceName}`)
                ?.dnfCondition?.forEach((conditions) =>
                    getRoleMemberIds(conditions, roleMemberIds),
                );

            setRoleMemberIds(roleMemberIds);
            const roleTypeKey = getOwnerMembers ? RoleType.Owner : RoleType.Contributor;
            setRoleObjectIdData({
                [roleTypeKey]: {
                    [MemberType.User]: roleMemberIds.IamItems.User,
                    [MemberType.Role]: roleMemberIds.IamItems.Role,
                    [MemberType.Group]: roleMemberIds.IamItems.Group,
                },
            });
        }
    }, [werePoliciesSuccessfullyFetched, workspacePolicy, policyReferenceName]);

    // To filter the user,role and group ids from all members in policy.
    function getRoleMemberIds(conditions: Condition[], roleMemberIds: RoleMemberIds) {
        if (conditions[0].attributeName === 'principal.microsoft.id') {
            roleMemberIds.IamItems.User = getRoleMemberIdsList(conditions);
        }
        if (conditions[0].attributeName === 'principal.microsoft.aad.approles') {
            roleMemberIds.IamItems.Role = getRoleMemberIdsList(conditions);
        }
        if (conditions[0].attributeName === 'principal.microsoft.groups') {
            roleMemberIds.IamItems.Group = getRoleMemberIdsList(conditions);
        }
    }

    //  Returns a list of unique member ids based on policy API data.
    function getRoleMemberIdsList(condition: Condition[]) {
        return (
            condition[0]?.attributeValueIncludedIn?.map((id) => {
                return id;
            }) ?? []
        );
    }
    return roleMemberIds ? (
        <UserPile
            roleMemberIds={roleMemberIds}
            workspacePolicy={workspacePolicy}
            onRecommendedRolesDetected={onRecommendedRolesDetected}
            getOwnerMembers={getOwnerMembers}
            setPolicyProcessed={setPolicyProcessed}
            roleObjectIdData={roleObjectIdData ?? ({} as RoleObjectIdData)}
        />
    ) : null;
}
