import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export const promptHoverFocus = 'promptHoverFocus';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: tokens.spacingVerticalXXXL,
        width: '100%',
        [`&:hover .${promptHoverFocus}, &:focus-within .${promptHoverFocus}`]: {
            visibility: 'visible',
            opacity: 1,
        },
    },
    fullWidthRoot: {
        justifyContent: 'flex-start',
        overflowX: 'hidden',
    },
    checkbox: {
        visibility: 'hidden',
        opacity: 0,
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(
            tokens.spacingVerticalXL,
            0,
            tokens.spacingVerticalXL,
            tokens.spacingVerticalXL,
        ),
        '@media (max-width: 479px)': {
            ...shorthands.padding(
                tokens.spacingVerticalXL,
                0,
                tokens.spacingVerticalXL,
                tokens.spacingVerticalS,
            ),
        },
    },
    selected: {
        visibility: 'visible',
        opacity: 1,
    },
    content: {
        ...shorthands.borderRadius('10px'),
        ...shorthands.flex(1, 1, 'auto'),
        boxShadow: tokens.shadow8,
        // we add a margin to account for the border width, this helps align the content with the prompt bar
        maxWidth: '700px',
        marginLeft: '16px',
        '@media (max-width: 479px)': {
            marginLeft: '0px',
            marginRight: '5px',
            width: '90vw',
            overflowX: 'auto',
        },
    },
    fullWidthContent: {
        maxWidth: '100%',
    },
    pinnedPrompt: {
        marginLeft: '10px',
        ...shorthands.borderLeft(tokens.strokeWidthThicker, 'solid', tokens.colorBrandForeground1),
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
    },
    divider: {
        ...shorthands.padding('0px', tokens.spacingHorizontalXXL),
        '::before': {
            ...shorthands.borderColor(tokens.colorNeutralBackground5),
            ...shorthands.borderWidth('2px'),
            left: 0,
        },
        '::after': {
            ...shorthands.borderColor(tokens.colorNeutralBackground5),
            ...shorthands.borderWidth('2px'),
            right: 0,
        },
        backgroundColor: tokens.colorNeutralBackground1,
    },
    spinner: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
    },
    evaluation: {
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),
        backgroundColor: tokens.colorNeutralBackground1,
    },
    visuallyHidden: {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '1px',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
    },
});
