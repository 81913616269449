import * as React from 'react';
import FileRow from './FileRow';
import {useTranslation} from 'react-i18next';
import {FileDescriptor} from '@/api/files';

interface FilesSectionProps {
    onSwitchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    switchRefs: React.MutableRefObject<{
        [keyId: string]: HTMLInputElement | null;
    }>;
    files: FileDescriptor[];
    tenantEnabled: boolean;
}

export default function FilesSection({
    onSwitchChange,
    switchRefs,
    files,
    tenantEnabled,
}: FilesSectionProps) {
    const {t} = useTranslation('plugins');
    const getSwitchId = (itemName: string, index: number): string => {
        return `${itemName}-switch${index}`;
    };

    if (!files) {
        return null;
    }

    return (
        <div data-testid={'files-section'}>
            {files.length === 0 && <div>{t('FilesNotFound')}</div>}
            {files.map((file, index) => {
                const fileName = file.fileName;
                const switchId = getSwitchId(fileName, index);
                return (
                    <FileRow
                        key={fileName}
                        file={file}
                        onSwitchChange={onSwitchChange}
                        isLastRow={index === files.length - 1}
                        componentId={switchId}
                        componentRef={(el) => (switchRefs.current[switchId] = el)}
                        selected={false}
                        tenantEnabled={tenantEnabled}
                    />
                );
            })}
        </div>
    );
}
