import {useMemo} from 'react';
import {CustomFetchOptions} from './api.types';
import useFetch from './useFetch';
import useIsUserSessionOwner from '@/api/sessions/useIsUserSessionOwner';

/**
 * Custom hook for fetching sessions data.
 * The returned function handles `/sessions` vs `/sharedsessions` logic so the caller only provides the rest of the route.
 *
 * @param sessionId - The ID of the session.
 * @returns An object containing the sessionsFetch function for making API requests.
 */
export default function useSessionsFetch(sessionId: string) {
    const {customFetch} = useFetch();
    const {isUserSessionOwner} = useIsUserSessionOwner(sessionId);

    const sessionsRoute = useMemo(() => {
        let hasSharingToken = false;
        if (typeof window !== 'undefined' && window.location.search) {
            const params = new URLSearchParams(window.location.search);
            hasSharingToken = params.has('st');
        }
        return isUserSessionOwner || !hasSharingToken ? 'sessions' : 'sharedsessions';
    }, [isUserSessionOwner]);

    return {
        sessionsFetch: <T>(
            url: string,
            options?: CustomFetchOptions,
            returnResponse: boolean = false,
        ) => customFetch<T>(`/${sessionsRoute}/${url}`, options, returnResponse),
    };
}
