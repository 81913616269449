import React from 'react';
import {DialogTitle, Button, Tooltip} from '@fluentui/react-components';
import {DialogTitleProps} from './Dialog.types';
import {DismissIcon, BackIcon} from '../icons';
import useClasses from './DialogTitle.styles';
import {useOverflowTooltip} from '../Reflow';
import {useTranslation} from 'react-i18next';

// Companion component to render titles for TabbedDialog that includes a back button if needed
// and includes a tooltip for the title when there is a long title
function TabbedDialogTitle(props: DialogTitleProps) {
    const {
        title,
        showBackButton,
        onBackButtonClick,
        onCloseButtonClick,
        headerRef,
        icon,
        backLabel,
        closeLabel,
    } = props;
    const classes = useClasses();
    const {t} = useTranslation();
    const {ref, isOverflowing, showTooltip} = useOverflowTooltip<HTMLDivElement>();

    return (
        <div className={classes.header} ref={headerRef}>
            <div className={classes.titleWrapper}>
                {onBackButtonClick && showBackButton && (
                    <Tooltip content={backLabel ?? t('Back')} relationship="label">
                        <Button
                            appearance="subtle"
                            className={classes.backButton}
                            size="small"
                            icon={<BackIcon />}
                            onClick={onBackButtonClick}
                            data-testid="tabbed-dialog-back-button"
                        />
                    </Tooltip>
                )}
                {icon && <div className={classes.icon}>{icon}</div>}
                <Tooltip
                    content={title}
                    relationship="description"
                    visible={isOverflowing && showTooltip}
                >
                    <DialogTitle
                        data-testid="tabbed-dialog-title"
                        ref={ref}
                        className={classes.title}
                        as="h1"
                        action=""
                        role="heading"
                    >
                        {title}
                    </DialogTitle>
                </Tooltip>
            </div>

            {onCloseButtonClick && (
                <Tooltip content={closeLabel ?? t('ButtonLabels.Close')} relationship="label">
                    <Button
                        appearance="subtle"
                        className={classes.closeButton}
                        size="small"
                        icon={<DismissIcon />}
                        onClick={onCloseButtonClick}
                        data-testid="tabbed-dialog-close-button"
                    />
                </Tooltip>
            )}
        </div>
    );
}

export default TabbedDialogTitle;
