import {Combobox, Field, Option} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {useTranslation} from 'react-i18next';

interface LegacyAuthSelectionFieldProps {
    authType: string;
    supportedAuthTypes: string[];
    onAuthTypeSelected: (authOption: string) => void;
}

export default function LegacyAuthSelectionField({
    authType,
    supportedAuthTypes,
    onAuthTypeSelected,
}: LegacyAuthSelectionFieldProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <div className={classes.authType}>
            <Field label={t('Admin.AuthorizationTypeLabel')}>
                <Combobox
                    value={authType}
                    onOptionSelect={(_event: any, data: any) => {
                        onAuthTypeSelected(data.optionValue);
                    }}
                >
                    {supportedAuthTypes.map((option) => (
                        <Option key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </Combobox>
            </Field>
            <br />
        </div>
    );
}
