// Shared styles for stage layouts and fonts/type
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        columnGap: tokens.spacingVerticalL,
        flexDirection: 'column',
        height: '100%',
    },
    textWrapper: {
        width: 'max-content',
        maxWidth: '600px',
        marginTop: '30px',
    },
    title: {
        fontSize: tokens.fontSizeHero800,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightHero800,
        letterSpacing: '0px',
        textAlign: 'center',
        marginBottom: '-12px',
    },
    titleContent: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase400,
        textAlign: 'center',
    },
    field: {
        marginBottom: tokens.spacingVerticalM,
    },
    label: {
        display: 'block',
        marginBottom: tokens.spacingVerticalMNudge,
        textAlign: 'left',
    },
    warningLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorStatusDangerForeground3,
    },
    infoLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorNeutralForeground4,
    },
    readMoreLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        color: tokens.colorNeutralForeground2,
    },
    termsLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        color: tokens.colorNeutralForeground3,
    },
    allowCrossRegionLabel: {
        display: 'block',
        marginTop: tokens.spacingVerticalMNudge,
        textAlign: 'left',
        color: tokens.colorNeutralForeground3,

        '@media (max-width: 479px)': {
            marginTop: '8px !important',
            textAlign: 'left',
        },
    },
    dropdownSelector: {
        minWidth: '100%',
        ...shorthands.borderStyle('none'),
        backgroundColor: tokens.colorNeutralBackground5,
        display: 'flex',
        height: tokens.lineHeightHero800,
    },
    leftAlignedContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    columnLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    textFieldStyle: {
        minWidth: '100%',
        ...shorthands.borderStyle('none'),
        backgroundColor: tokens.colorNeutralBackground5,
    },
    termsLinkStyle: {
        display: 'inline-block',
        marginLeft: '6px',
        marginRight: '6px',
    },
    resourceGroupLinkStyle: {
        display: 'inline-block',
        marginLeft: tokens.spacingHorizontalSNudge,
        marginRight: tokens.spacingHorizontalSNudge,
    },
    rgLabel: {
        maxWidth: '20%',
    },
    checkboxStyle: {
        marginRight: tokens.spacingHorizontalXS,
    },
    termsAndConditionsCheckBoxStyle: {
        marginTop: '-4px',
    },
    promptEvalLocationCheckBoxStyle: {
        marginTop: '5px',
    },
    labelStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: tokens.fontSizeBase200,
        ...shorthands.border('0px'),
        minWidth: '12px',
        minHeight: '12px',
        ...shorthands.padding('6px', '6px', '0px', '0px'),
        alignSelf: 'center',
        position: 'relative',
    },
    tooltipContent: {
        backgroundColor: tokens.colorNeutralBackground5,
    },
    visible: {
        color: tokens.colorNeutralForeground2BrandSelected,
    },
    errorIcon: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorPaletteRedForeground1,
        marginRight: tokens.spacingHorizontalXS,
    },
    resourceGroupPopoverContainer: {
        color: tokens.colorNeutralForeground1,
        width: '300px',
    },
    popoverTitle: {
        fontWeight: tokens.fontWeightSemibold,
        marginBottom: tokens.spacingVerticalM,
    },
    popoverSubtitle: {
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase300,
        marginBottom: tokens.spacingVerticalXL,
    },
    popoverInput: {
        minWidth: '100%',
        ...shorthands.borderStyle('none'),
        backgroundColor: tokens.colorNeutralBackground5,
    },
    popoverButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        ...shorthands.gap('8px'),
        marginTop: '4%',
    },
    tooltipRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    termsButtonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 'auto',
    },
    buttonWrapper: {
        justifyContent: 'flex-end',
        marginTop: '5%',
        marginRight: '8%',
    },
    comboOptionGroup: {
        maxHeight: '368px',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorNeutralBackground5} ${tokens.colorTransparentBackground}`,
        '&::-webkit-scrollbar': {
            width: tokens.spacingHorizontalS,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorTransparentBackground,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralBackground5,
            ...shorthands.borderRadius('20px'),
        },
    },
    checkBoxContentLabel: {
        display: 'inline-flex',
        flexDirection: 'row',
    },
});
