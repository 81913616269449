export enum MemberType {
    User = 'User',
    Group = 'Group',
    Role = 'Role',
}

export type GetRoleMembersResponse = PolicyWorkspace;
export type GetRoleByNameResponse = {
    details: {
        User: MemberResponseItem[];
        Group: MemberResponseItem[];
        Role: MemberResponseItem[];
    };
};

export type MemberResponseItem = {
    id?: string;
    displayName?: string;
    mail?: string;
    memberCount?: number;
    canBeDeleted?: boolean;
};
export type useUpdateWorkspacePolicyRequest = {
    workspaceName: string;
    policy: PolicyWorkspace;
};

export type RoleMembers = {
    users: RoleMember[];
    roles: RoleMember[];
    groups: RoleMember[];
};

export type RoleMember = {
    id?: string;
    type?: MemberType;
    displayName?: string;
    memberCount?: number;
    mail?: string;
    canBeDeleted?: boolean;
};

export type PolicyWorkspace = {
    name: string;
    id: string;
    version: number;
    properties: {
        description: string;
        decisionRules: DecisionRule[];
        attributeRules: AttributeRule[];
        entity: PolicyEntity;
    };
};

type PolicyEntity = {
    type: string;
    referenceName: string;
};

type DecisionRule = {
    kind: string;
    effect: string;
    dnfCondition: Condition[][];
};

export type AttributeRule = {
    kind: string;
    name?: string;
    id: string;
    dnfCondition: Condition[][];
};

export type Condition = {
    attributeName: string;
    attributeValueIncludedIn?: string[];
    fromRule?: string;
    attributeValueIncludes?: string;
};

export type RoleMemberIds = {
    IamItems: {
        User: string[];
        Role: string[];
        Group: string[];
    };
};

export type RbacErrorResponse = {
    ErrorCode: number;
    Error: {
        Code: string;
        Message: string;
    };
};
