import {useState, useEffect} from 'react';
import {useGetUserInfo} from '../app';

/**
 * This file decides whether copylink model should be rendered . useGetUserInfo contains a flag isEmailTriggerAllowed
 * which is used to take this decision.
 *  @returns showLinkCopiedModel flag to decide whether to show the model or not
 */
export default function useSessionCopyLink() {
    const {data: userInfo, isSuccess: isUserInfoSuccess, error: isUserInfoError} = useGetUserInfo();
    const [showLinkCopiedModel, setShowLinkCopiedModel] = useState<boolean>(false);

    useEffect(() => {
        if (userInfo && !userInfo.isEmailTriggerAllowed) {
            setShowLinkCopiedModel(!userInfo.isEmailTriggerAllowed);
        }
    }, [userInfo]);

    return {
        showLinkCopiedModel,
        success: isUserInfoSuccess,
        error: isUserInfoError,
    };
}
