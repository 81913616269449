import {useMemo} from 'react';
import Fuse from 'fuse.js';
import {SkillsetDescriptor} from '@/api/skills';
import {getSkillsetDescriptionForDisplay} from './ConnectorModalContent/Skillsets/Utils/SkillsetMetadata.utils';

const FUSE_OPTIONS = {
    keys: [
        'displayName',
        {
            name: 'description',
            getFn: (skillset: SkillsetDescriptor) => getSkillsetDescriptionForDisplay(skillset),
        },
    ],
    threshold: 0, // Find exact match
    ignoreLocation: true, // Search beyond first 60 characters
    shouldSort: false, // Keep skillsets in original order
};

/**
 * Custom hook to filter skillsets based on a search text.
 * @param skillsets - The array of skillset descriptors.
 * @param searchText - The search text to filter skillsets.
 * @returns An array of filtered skillsets based on the search text.
 */
const useSkillsetSearch = (
    skillsets: SkillsetDescriptor[],
    searchText: string,
): SkillsetDescriptor[] => {
    const fuzzySearchSkillsets = useMemo<Fuse<SkillsetDescriptor>>(() => {
        return new Fuse<SkillsetDescriptor>(skillsets, FUSE_OPTIONS);
    }, [skillsets]);

    if (searchText) {
        const searchResults = fuzzySearchSkillsets?.search(searchText);
        return searchResults.map((skillset) => skillset.item);
    }

    return skillsets;
};

export default useSkillsetSearch;
