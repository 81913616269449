import {LinkParameters} from '@/components/App.types';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import {useMemo} from 'react';

interface UseGetCapacityParameters {
    links: Record<string, LinkParameters>;
    isCapacityIdValid: boolean;
}

const BLANK = '_blank';

const AZURE_PORTAL = 'Azure Portal';

interface useGetCapacityParametersProps {
    capacityId: string;
    tenantId: string;
}

export default function useGetCapacityParameters({
    capacityId,
    tenantId,
}: useGetCapacityParametersProps): UseGetCapacityParameters {
    const {
        getSubscriptionUrl,
        getResourceGroupUrl,
        getCapacityUrl,
        getCapacityName,
        getSubscriptionId,
        getResourceGroupName,
        isCapacityIdValid,
        getBillingTabUrlOnSubscriptionPage,
    } = useAzureCapacityDependencyLinkParts({
        capacityId: capacityId,
        tenantId: tenantId,
    });

    const links = useMemo<Record<string, LinkParameters>>(() => {
        return {
            subscription: {
                url: getSubscriptionUrl(),
                text: getSubscriptionId(),
                target: BLANK,
            },
            resourceGroup: {
                url: getResourceGroupUrl(),
                text: getResourceGroupName(),
                target: BLANK,
            },
            capacity: {
                url: getCapacityUrl(),
                text: getCapacityName(),
                target: BLANK,
            },
            billingTabUrl: {
                url: getBillingTabUrlOnSubscriptionPage(),
                text: AZURE_PORTAL,
                target: BLANK,
            },
        };
    }, [capacityId]);

    return {
        links,
        isCapacityIdValid,
    };
}
