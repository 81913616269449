import React, {useRef, useEffect, useState} from 'react';
import {
    Button,
    Link,
    mergeClasses,
    useToastController,
    Toast,
    ToastTitle,
    Toaster,
    useId,
    useFocusFinders,
    Tooltip,
} from '@fluentui/react-components';
import usePageClasses from './Done.styles';
import {CompletionIcon} from '@/components/ui/icons';
import {CopyIcon} from '@/components/ui/icons';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {motion} from 'framer-motion';
import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import useGetCapacityParameters from './useGetDoneLinkParameters';
import {REGION_DATA} from '@/components/App.constants';
import {useNavigate} from 'react-router-dom';
import useManageDoneDetails from './useManageDoneDetails';
import ResourceLink from '../stops/ResourceLink';
import {useTranslation} from 'react-i18next';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {RegionName} from '@/components/App.types';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import MedeinaVariables from '@/util/variables';
import {FreStage, useManageTenantInfo} from '@/api/tenant';
import useManageTransitionDetails from './useManageTourTransition';
import {useQueryClient} from '@tanstack/react-query';
import {useViewportSize} from '@/components/ui/Grid';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {Body, SmallFooterContent, TextH1, TextSubtitle2} from '@/components/ui/Text';

export default function Done() {
    const CopilotForSecurityText = MedeinaVariables.PortalUri.match(
        /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i,
    )?.[1];
    const pageClasses = usePageClasses();
    const tourClasses = useTourClasses();

    const toasterId = useId('toaster');
    const queryClient = useQueryClient();

    const {update: providerUpdate} = useWorkspaceState();

    const {t: tourLabel} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const {validatePostWorkspaceSetup} = useManageTransitionDetails();
    // New finishing state for the continue button
    const [isFinishLoading, setIsFinishLoading] = useState(false);
    const tour = useTourContext();
    const {setTourErrorV2} = useTourContext();
    const {dispatchToast} = useToastController(toasterId);
    const doneWrapper = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();
    const navigate = useNavigate();
    const [regionNameState, setRegionNameState] = useState<string>('');

    const {sm: isSmallScreen} = useViewportSize();

    const {regionName, capacityId, allDataFetched, isDataLoading} = useManageDoneDetails({
        onWorkspacesError: () => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.TourCompleted,
            });
        },
        onCapacityError: () => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.TourCompleted,
            });
        },
    });

    const {patchFreMetadata} = useManageTenantInfo({
        onFetchTenantError: (error: any) => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.TourCompleted,
            });
        },
    });

    const {links} = useGetCapacityParameters(capacityId, getCurrentTenantId());

    //Handle scenarios for errors
    useEffect(() => {
        if (allDataFetched) {
            setRegionNameState(REGION_DATA[regionName as RegionName]?.displayName);
            return;
        }
    }, [allDataFetched]);

    const linkCopiedToast = () =>
        dispatchToast(
            <Toast className={pageClasses.toast}>
                <ToastTitle>{commonLabel('LinkCopied')}</ToastTitle>
            </Toast>,
            {intent: 'success'},
        );

    const handleCopy = () => {
        navigator.clipboard
            .writeText(MedeinaVariables.PortalUri ?? '')
            .then(() => {
                linkCopiedToast();
            })
            .catch((error) => {
                console.error('Error copying link:', error);
            });
    };

    useEffect(() => {
        if (doneWrapper?.current) {
            const firstFocusable = findFirstFocusable(doneWrapper.current);
            firstFocusable?.focus();
        }
    }, [doneWrapper, findFirstFocusable]);

    const handleFinishClick = async () => {
        try {
            setIsFinishLoading(true);
            const step = await validatePostWorkspaceSetup();

            if (step === TOUR_STEPS.None || step === TOUR_STEPS.HOME) {
                await patchFreMetadata(FreStage.WorkspaceSetup, true);
                tour.clearTourSharedParameters();
                await providerUpdate();
                await queryClient.invalidateQueries(['auth', 'getUserInfo']);
                navigate('/');
                tour.quitTour();
                // COmmenting this to unlock the FRE and prevent the plugin instructions from coming up
                //tour.nextStop();
                //tour.setTourStep(TOUR_STEPS.HOME);
            } else {
                tour.setTourStep(step);
            }
        } catch (error) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.TourCompleted,
            });
        } finally {
            setIsFinishLoading(false); // Stop loading regardless of outcome
        }
    };

    const handleBackClick = () => {
        tour.prevStop();
    };

    return (
        <>
            {!isDataLoading && (
                <motion.div
                    key="terms"
                    variants={overlayAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <TourStageContainer ref={doneWrapper}>
                        <TourStageContainer.StageContent>
                            <div tabIndex={0}>
                                <CompletionIcon
                                    className={mergeClasses(
                                        tourClasses.tourSectionSpacing,
                                        tourClasses.largeIcon,
                                    )}
                                    aria-label={tourLabel('adminV2Fre.done.ariaLabel.DoneIcon')}
                                />
                            </div>
                            <div className={pageClasses.titleSpacing}>
                                <TextH1>{tourLabel('adminV2Fre.done.Title')}</TextH1>
                            </div>
                            <div className={tourClasses.tourLargeSectionSpacing}>
                                <Body>{tourLabel('adminV2Fre.done.SupplementaryContent')}</Body>
                            </div>
                            <div className={pageClasses.resourceLinksContainer}>
                                <div>
                                    <TextSubtitle2>
                                        {commonLabel('AzureResourceLinks')}
                                    </TextSubtitle2>
                                </div>
                                <ResourceLink
                                    linkAttribute={links.capacity}
                                    displayName={commonLabel('CapacityName')}
                                    dataTestId="capacity-link"
                                    classes={pageClasses.resourceLinkItem}
                                ></ResourceLink>
                                <ResourceLink
                                    linkAttribute={links.subscription}
                                    displayName={commonLabel('Subscription')}
                                    dataTestId="subscription-link"
                                    classes={pageClasses.resourceLinkItem}
                                ></ResourceLink>
                                <ResourceLink
                                    linkAttribute={links.resourceGroup}
                                    displayName={commonLabel('ResourceGroup')}
                                    dataTestId="resource-group-link"
                                    classes={pageClasses.resourceLinkItem}
                                ></ResourceLink>
                                <div className={pageClasses.resourceLinkItem}>
                                    <span>{commonLabel('Location')}</span>
                                    <span>{!!regionNameState && regionNameState}</span>
                                </div>
                            </div>
                            <div className={pageClasses.shareLinkBlock}>
                                <div className={tourClasses.tourSectionSpacing}>
                                    <Body>{tourLabel('adminV2Fre.done.ShareLinkHint')}</Body>
                                </div>

                                <Toaster
                                    className={tourClasses.tourCenteredToast}
                                    toasterId={toasterId}
                                    position="bottom"
                                />

                                <div>
                                    <Tooltip
                                        content={tourLabel('adminV2Fre.done.CopyLinkTooltip')}
                                        relationship="label"
                                    >
                                        <Button
                                            appearance="primary"
                                            onClick={handleCopy}
                                            size={isSmallScreen ? 'small' : 'medium'}
                                            aria-label={tourLabel(
                                                'adminV2Fre.done.ariaLabel.CopyLink',
                                                {portalUri: CopilotForSecurityText},
                                            )}
                                        >
                                            <CopyIcon className={pageClasses.copyIcon} />{' '}
                                            {CopilotForSecurityText}
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div>
                                <SmallFooterContent>
                                    {tourLabel('adminV2Fre.done.ManageBillingHint')}{' '}
                                    <Link
                                        href={links.billingTabUrl.url}
                                        target={links.billingTabUrl.target}
                                    >
                                        {commonLabel('AzurePortal')}
                                    </Link>
                                </SmallFooterContent>
                            </div>
                        </TourStageContainer.StageContent>
                        <TourStageContainer.FooterContent>
                            <TourControls
                                spanFullScreen={true}
                                previousButton={commonLabel('Back')}
                                nextButton={commonLabel('Finish')}
                                handlePrevious={handleBackClick}
                                handleNext={handleFinishClick}
                                isNextButtonDisabled={isFinishLoading}
                                showPrevious={!tour.currentStop?.isFirstStop}
                                showProgressSpinner={isFinishLoading}
                                progressSpinnerText={commonLabel('Finishing')}
                            ></TourControls>
                        </TourStageContainer.FooterContent>
                    </TourStageContainer>
                </motion.div>
            )}
        </>
    );
}
