import useClasses from '../../AdminConfigurationModalContent.styles';
import {
    SettingsScope,
    SkillsetAuthType,
    SkillsetConfiguration,
    SkillsetError,
    SkillsetSetting,
} from '@/api/skills';
import SelectedConfigsInfoResetHeader from '../Components/SelectedConfigsInfoResetHeader';
import SelectedSkillsetSettingConfiguration from './SelectedSkillsetSettingConfiguration';
import {getConfigDefaultValue} from '../Utils/AdminConfigModal.utils';
import useSkillsetConfigHasValue from './useSkillsetConfigHasValue';

/**
 * SelectedSkillsetSettingsFormProps
 *
 * @interface SelectedSkillsetSettingsFormProps
 * @property {Set<SkillsetSetting>} selectedConfigs - The settings to show to the user for configuration. Includes configs from apis /authSettings and /skillsets.
 * @property {boolean} isSetUp - Indicates if the user is currently setting up this skillset.
 * @property {SettingsScope} scope - If skillset is configured for tenant/user/workspace scope.
 * @property {SkillsetConfiguration | null} existingConfigs - The current skillset configurations returned via /configurations api.
 * @property {string} authType - `SkillsetAuthType` of the skillset.
 * @property {string[]} failedValidationSettings - List of required user inputs that are missing. Alerts the user to fill in when submitting.
 * @property {SkillsetError[]} requirementsCheckErrors - Settings that need configuration based on /checkRequirements.
 * @property {() => void} onReset - Clears user configurations for this skillsets
 * @property {(ev: any, config: string) => void} onInputChange - Handles updates for local dictionary state of user inputs.
 */
interface SelectedSkillsetSettingsFormProps {
    selectedConfigs: Set<SkillsetSetting>;
    isSetUp: boolean;
    scope: SettingsScope;
    existingConfigs: SkillsetConfiguration | null;
    authType: string;
    failedValidationSettings: string[];
    requirementsCheckErrors: SkillsetError[];
    onReset: () => void;
    onInputChange: (ev: any, config: string) => void;
}

export default function SelectedSkillsetSettingsForm({
    selectedConfigs,
    isSetUp,
    scope,
    existingConfigs,
    authType,
    failedValidationSettings,
    requirementsCheckErrors,
    onReset,
    onInputChange,
}: SelectedSkillsetSettingsFormProps) {
    const classes = useClasses();

    const {existingConfigHasValue} = useSkillsetConfigHasValue({
        existingConfigs,
        authType,
        requirementsCheckErrors,
    });

    const getDefaultInputValue = (config: SkillsetSetting): string => {
        const defaultValue = getConfigDefaultValue(config.name, existingConfigs, authType);
        return defaultValue || config.defaultValue || '';
    };

    const hasRequirementError = (config: SkillsetSetting): boolean => {
        return requirementsCheckErrors.some((error) => error.name === config.name);
    };

    return (
        <form className={classes.form}>
            <div className={classes.grid}>
                {!isSetUp && (
                    <SelectedConfigsInfoResetHeader
                        scope={scope}
                        existingConfigHasValue={existingConfigHasValue}
                        onResetClick={onReset}
                    />
                )}
                <div className={isSetUp ? '' : classes.sectionBody}>
                    {Array.from(selectedConfigs.values()).map((config, index) => (
                        <SelectedSkillsetSettingConfiguration
                            key={index}
                            autoFocus={index === 0}
                            config={config}
                            defaultInputValue={getDefaultInputValue(config)}
                            hasRequirementError={hasRequirementError(config)}
                            hasFailedValidation={failedValidationSettings.includes(config.name)}
                            isSettingUp={isSetUp}
                            onInputChange={(ev: any) => onInputChange(ev, config.name)}
                        />
                    ))}
                </div>
            </div>
        </form>
    );
}
