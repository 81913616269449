import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    switch: {
        textAlign: 'left',
    },
    errorTag: {
        color: tokens.colorPaletteCranberryBorderActive,
        '& > span:first-child': {
            position: 'relative',
            top: '3px',
            paddingRight: '3px',
        },
    },
});
