import {Button, Tooltip} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {AcceptIcon, CopyIcon} from '../icons';
import {AnyMedeinaTelemetryEvent, MedeinaEvent, useTrackEvent} from '@/api/telemetry';
import {MEDEINA_COPY_BUTTON_TIMEOUT} from '@/components/App.constants';

export interface MedeinaCopyButtonProps {
    copyText: string;
    eventName: AnyMedeinaTelemetryEvent;
    tooltipText?: string;
    isDisabled: boolean;
    label?: string;
    className?: string | undefined;
}

export const MedeinaCopyButton = (props: MedeinaCopyButtonProps) => {
    const {copyText, eventName, tooltipText, isDisabled} = props;
    const [isCopied, setIsCopied] = useState(false);
    const {mutate: trackEvent} = useTrackEvent();

    const handleCopy = () => {
        trackEvent({name: eventName, eventType: MedeinaEvent.ActionEvent});

        navigator.clipboard
            .writeText(copyText)
            .then(() => {
                setIsCopied(true);
            })
            .catch((error) => {
                console.error('Error copying text:', error);
            });
    };

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => setIsCopied(false), MEDEINA_COPY_BUTTON_TIMEOUT);
        }
    }, [isCopied]);

    return (
        <Tooltip
            content={isCopied ? 'Copied to clipboard' : tooltipText || 'Copy'}
            relationship="label"
        >
            <Button
                disabled={isDisabled}
                icon={isCopied ? <AcceptIcon /> : <CopyIcon />}
                appearance="subtle"
                onClick={handleCopy}
                className={props.className}
            >
                {props.label}
            </Button>
        </Tooltip>
    );
};
