import {ControlOptions, TourStop} from './Tour.types';
import {OverlayOptions} from './Tour.types';

export const defaultControlOptions: ControlOptions = {
    allowBack: true,
    allowSkip: true,
    allowExit: true,
    allowReplay: true,
    allowPausePlay: true,
    showExit: true,
    showControls: true,
    showCounter: true,
    showExitOnly: false,
};
