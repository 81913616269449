import {useState, useMemo, useEffect, useCallback, ChangeEvent} from 'react';
import {Field, FieldProps} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {ValidationState, FieldValidationState} from '@/components/ui/forms';
import ProgressiveSwitch from '@/components/ui/Switch';

interface ModelImprovementProps {
    value?: boolean;
    required?: boolean;
    onChange?: (checked: boolean) => void;
    fieldProps?: FieldProps;
    disabled?: boolean;
}

export default function ModelImprovement(props: ModelImprovementProps) {
    const {value: propValue, fieldProps, required, disabled, onChange} = props;
    // translation related functions by namespace
    const {t} = useTranslation('admin');

    // the value of the component is handled externally, update the internal value only when the prop value changes
    useEffect(() => {
        if (propValue !== value) {
            setValue(propValue || false);
        }
        // only trigger change when the prop value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propValue]);

    const [value, setValue] = useState<boolean>(propValue || false);
    const [validationState, setValidationState] = useState<FieldValidationState>({});

    // When the input changes and we have a value AND we are preventing duplicates
    // use the workspace data to update the validation state of the input
    const handleChange = useCallback(
        (checked: boolean) => {
            if (!disabled) {
                if (onChange) {
                    onChange(checked);
                }
            }
        },
        [disabled, onChange],
    );

    const fieldValidationProps = useMemo(() => {
        if (validationState.error) {
            return {
                validationState: 'error' as ValidationState,
                validationMessage: validationState.message,
            };
        }
        // if there's no error return a blank object that will be spread over Field
        return {};
    }, [validationState]);

    return (
        <Field {...fieldValidationProps} required={required}>
            <ProgressiveSwitch
                state={{isChanging: false, isChangeSuccessful: true, hasChangeFailed: false}}
                data-test-id="model-improvement-switch"
                checked={value}
                label={t('ownerSettings.organizationData.TrainSecurityCopilotSwitchLabel')}
                onClick={handleChange}
                labelPosition="before"
                aria-label={t('ownerSettings.organizationData.ariaLabel.ModelTraining')}
            />
        </Field>
    );
}
