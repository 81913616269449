import React from 'react';
import {Button} from '@fluentui/react-components';
import {DismissIcon} from '@/components/ui/icons';
import {PopoverDismissProps} from './PopoverDismiss.types';

const DismissControls = ({onDismissClicked}: PopoverDismissProps) => {
    return (
        <Button
            onClick={onDismissClicked}
            appearance="subtle"
            size="large"
            icon={<DismissIcon />}
            aria-label="Close popover"
        />
    );
};

export default DismissControls;
