import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    supplementaryContentWidth: {
        alignSelf: 'center',
        maxWidth: '400px',
        width: '100%',
        textAlign: 'left',
    },
    roleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        ...shorthands.gap(tokens.spacingHorizontalS),
        width: '100%',
    },
    contributorRolePersona: {
        width: '100%',
        maxWidth: '400px',
    },
    ownerRolePersona: {
        width: '194px',
    },
    icon: {
        height: '40px',
        width: '40px',
        '@media (forced-colors: active)': {
            backgroundColor: 'Canvas',
        },
    },
    radioGroupContainer: {
        ...shorthands.padding(tokens.spacingVerticalL),
        backgroundColor: tokens.colorNeutralBackground2Hover,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        marginBottom: tokens.spacingVerticalL,
        marginTop: tokens.spacingVerticalL,
        textAlign: 'left',
        '& .fui-RadioGroup': {
            rowGap: tokens.spacingVerticalS,
        },
        maxWidth: '400px',
        boxSizing: 'border-box',
    },
    whoToAddContainer: {
        marginTop: tokens.spacingVerticalXS,
        marginBottom: tokens.spacingVerticalXS,
        textAlign: 'left',
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
    },
    whoToAddInfoContainer: {
        marginBottom: tokens.spacingVerticalM,
        fontSize: tokens.fontSizeBase200,
    },
});
