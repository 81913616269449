import {ZoomTransform, zoom, zoomTransform} from 'd3';
import {KeyboardZoomConfiguratorProps} from './CodeBlock.types';
import useD3TransformationUtilities from './useD3TransformationUtilities';
import {useEffect} from 'react';

export default function useKeyboardZoomConfigurator(props: KeyboardZoomConfiguratorProps) {
    const {id} = props;

    const PLUS_SIGN = '+';
    const MINUS_SIGN = '-';
    const ARROW_RIGHT = 'ArrowRight';
    const ARROW_LEFT = 'ArrowLeft';
    const ARROW_UP = 'ArrowUp';
    const ARROW_DOWN = 'ArrowDown';

    const graphViewKeys = new Set([
        PLUS_SIGN,
        MINUS_SIGN,
        ARROW_RIGHT,
        ARROW_LEFT,
        ARROW_UP,
        ARROW_DOWN,
    ]);
    const zoomKeys = new Set([PLUS_SIGN, MINUS_SIGN]);
    const panKeys = new Set([ARROW_RIGHT, ARROW_DOWN, ARROW_LEFT, ARROW_UP]);
    const horizontalPanKeys = new Set([ARROW_RIGHT, ARROW_LEFT]);
    const verticalPanKeys = new Set([ARROW_UP, ARROW_DOWN]);

    const PAN_FACTOR = 10;
    const ZOOM_IN_SCALE_FACTOR = 1.05;
    const ZOOM_OUT_SCALE_FACTOR = 0.95;

    const ZOOM_EVENT = 'zoom';

    const {repositionGraph, applyTransformationOnInstance, getTransformForElement, getElement} =
        useD3TransformationUtilities(id, 'g');

    const onElementZoom = function (event: any) {
        const {x: transformX, y: transformY, k: transformK} = event.transform;
        repositionGraph(transformX, transformY, transformK);
    };

    const zoomInstance = zoom().on(ZOOM_EVENT, onElementZoom);

    useEffect(
        () => () => {
            zoom().on(ZOOM_EVENT, null);
        },
        [],
    );

    const handlePanEvent = function (
        event: KeyboardEvent,
        oldTransform: ZoomTransform,
        newTransform: ZoomTransform | null,
    ) {
        if (horizontalPanKeys.has(event.key)) {
            const newX =
                event.key === ARROW_RIGHT
                    ? oldTransform.x + PAN_FACTOR
                    : oldTransform.x - PAN_FACTOR;
            newTransform = getTransformForElement(newX, 'x');
        } else if (verticalPanKeys.has(event.key)) {
            const newY =
                event.key === ARROW_UP ? oldTransform.y - PAN_FACTOR : oldTransform.y + PAN_FACTOR;
            newTransform = getTransformForElement(newY, 'y');
        }
        return newTransform;
    };

    const handleZoomEvent = function (
        event: KeyboardEvent,
        newTransform: ZoomTransform | null,
        oldTransform: ZoomTransform,
    ) {
        const scaleFactor = event.key === PLUS_SIGN ? ZOOM_IN_SCALE_FACTOR : ZOOM_OUT_SCALE_FACTOR;
        newTransform = getTransformForElement(oldTransform.k * scaleFactor, 'k');
        return newTransform;
    };

    const handleVisualizationChange = function (event: KeyboardEvent) {
        const svg = getElement();

        const oldTransform = zoomTransform(svg.node() as Element);

        if (event.ctrlKey && graphViewKeys.has(event.key)) {
            event.preventDefault();

            let newTransform: ZoomTransform | null = null;

            if (zoomKeys.has(event.key)) {
                newTransform = handleZoomEvent(event, newTransform, oldTransform);
            } else if (panKeys.has(event.key)) {
                newTransform = handlePanEvent(event, oldTransform, newTransform);
            }

            applyTransformationOnInstance(newTransform as ZoomTransform, zoomInstance);
        }
    };

    return {
        handleVisualizationChange,
    };
}
