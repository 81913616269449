import {useState, useLayoutEffect, useRef, useEffect} from 'react';

{
    /**
a utility hook that checks if an element is overflowing and sets up a tooltip 
to display the full content when the element is hovered. 

Example usage: 
const {ref, isOverflowing, showTooltip} = useOverflowTooltip<HTMLButtonElement>();
return (
    <Tooltip
        content={applicationLabel}
        relationship="label"
        visible={isOverflowing && showTooltip}
    >
        <Button
            className={classes.link}
            href="/"
            as="a"
            appearance="subtle"
            size="medium"
            ref={ref}
        >
            {applicationLabel}
        </Button>
    </Tooltip>
);
*/
}
function useOverflowTooltip<T extends HTMLElement>() {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef<T>(null);

    // Manual control of the tooltip visibility requires an event listener on the target element
    useEffect(() => {
        const element = ref.current;
        if (element) {
            const handleMouseOver = () => {
                setShowTooltip(true);
            };
            const handleMouseOut = () => {
                setShowTooltip(false);
            };
            element.addEventListener('mouseover', handleMouseOver);
            element.addEventListener('mouseout', handleMouseOut);

            return () => {
                element.removeEventListener('mouseover', handleMouseOver);
                element.removeEventListener('mouseout', handleMouseOut);
            };
        }
    }, [ref]);

    // we need to setup a listener for when to activate the tooltip
    useLayoutEffect(() => {
        const checkOverflow = () => {
            const element = ref.current;
            if (element) {
                const overflow = element.scrollWidth > element.clientWidth;
                setIsOverflowing(overflow);
            }
        };

        window.addEventListener('resize', checkOverflow);
        checkOverflow();

        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return {ref, isOverflowing, showTooltip};
}

export default useOverflowTooltip;
