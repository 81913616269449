import {Dropdown, Field, InfoLabel, Input, Option, Switch} from '@fluentui/react-components';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import CustomFileInput from '../../../FileInput/CustomFileInput';
import {UploadIcon} from '@/components/ui/icons';
import {AcceptedFileType} from '@/api/skills';
import {useTranslation} from 'react-i18next';

const acceptedFileTypes: AcceptedFileType[] = ['.json', '.yaml', '.yml'];
const fileDescriptionText = '.yaml and .json';

interface CopilotPluginUploadProps {
    failedValidationSettings: string[];
    isUrlUpload: boolean;
    onSwitchChangeHandler: (ev: any) => void;
    onFormValuesUpdated: (ev: any, config: string) => void;
    onFileTypeSelected: (ev: any, data: any) => void;
    onFileUploaded: (file: File | null) => void;
}

export default function CopilotPluginUploadField({
    failedValidationSettings,
    isUrlUpload,
    onSwitchChangeHandler,
    onFormValuesUpdated,
    onFileTypeSelected,
    onFileUploaded,
}: CopilotPluginUploadProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <Field
            label={{
                children: <InfoLabel>{t('common.UploadFileText')}</InfoLabel>,
            }}
            required={true}
            validationState={failedValidationSettings.includes('file') ? 'error' : 'none'}
            validationMessage={
                failedValidationSettings.includes('file') ? t('common.PluginFileIsRequired') : ''
            }
        >
            <Switch
                className={classes.switch}
                label={t('common.UploadAsALink')}
                checked={isUrlUpload}
                onChange={onSwitchChangeHandler}
                required={false}
            />
            {isUrlUpload ? (
                <div>
                    <Field
                        className={classes.spacing}
                        required={true}
                        validationState={
                            failedValidationSettings.includes('url') ? 'error' : 'none'
                        }
                        validationMessage={
                            failedValidationSettings.includes('url')
                                ? t('common.LinkToFileIsRequired')
                                : ''
                        }
                    >
                        <Input
                            type={'text'}
                            autoFocus
                            size="large"
                            appearance="outline"
                            className={classes.longTextareaWrapper}
                            placeholder={t('common.PasteALink')}
                            onChange={(ev: any) => onFormValuesUpdated(ev, 'url')}
                        />
                    </Field>
                    <Field
                        className={classes.typeDropdown}
                        label={{
                            children: <InfoLabel>{t('common.FileType')}</InfoLabel>,
                        }}
                        required={true}
                        validationState={
                            failedValidationSettings.includes('type') ? 'error' : 'none'
                        }
                        validationMessage={
                            failedValidationSettings.includes('type')
                                ? t('common.PluginTypeIsRequired')
                                : ''
                        }
                    >
                        <Dropdown
                            placeholder={t('common.SpecifyType')}
                            onOptionSelect={onFileTypeSelected}
                        >
                            <Option key=".json" value=".json">
                                .json
                            </Option>
                            <Option key=".yaml" value=".yaml">
                                .yaml
                            </Option>
                        </Dropdown>
                    </Field>
                </div>
            ) : (
                <CustomFileInput
                    fileDescription={fileDescriptionText}
                    setUploadedFile={onFileUploaded}
                    acceptedFileTypes={acceptedFileTypes}
                    displayIcon={<UploadIcon />}
                />
            )}
        </Field>
    );
}
