import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    subtleIconColor: {
        '&[aria-pressed="true"] svg': {
            color: tokens.colorBrandForeground2,
        },
        '& svg': {color: tokens.colorNeutralForeground2},
        '&:hover svg': {
            color: tokens.colorBrandForeground2,
        },
    },
});
