export default function downloadFileFromBlob(fileBlob: Blob, fileName: string) {
    const blob = new Blob([fileBlob]);
    const objectUrl = URL.createObjectURL(blob);

    const transientLink = document.createElement('a');
    transientLink.href = objectUrl;
    transientLink.download = fileName;

    transientLink.click();
    transientLink.remove();
    URL.revokeObjectURL(objectUrl);
}
