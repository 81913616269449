import {
    ChangeEvent,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    KeyboardEvent,
    RefObject,
    Ref,
    MutableRefObject,
} from 'react';
import {Textarea as FluentTextarea} from '@fluentui/react-components';
import useClasses from './Textarea.styles';
import {TextareaProps} from './Textarea.types';
import {useFeatureFlag} from '@/api/user';
import {MedeinaUrlParamFeatures} from '@/util/features';

export * from './Textarea.types';

// An autosizing <textarea>
const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({autosize, onSubmit, rows = 1, ...props}, forwardedRef) => {
        const classes = useClasses();

        // Forward the handle back to the innerRef.
        const innerRef = useRef<HTMLTextAreaElement>(null);

        // Feature flags
        const isAgentViewEnabled = useFeatureFlag(MedeinaUrlParamFeatures.AgentView);

        // Automatically resize the textarea when content changes.
        const autoResizeTextarea = useCallback(() => {
            if (autosize && innerRef.current) {
                innerRef.current.style.height = 'auto';
                innerRef.current.style.height = `${innerRef.current.scrollHeight}px`;
            }
        }, [autosize, innerRef]);

        // Handle change events.
        const handleChange = useCallback(
            (ev: ChangeEvent<HTMLTextAreaElement>, data: any) => {
                autoResizeTextarea();
                props.onChange?.(ev, data);

                // Check if the value starts with '@' for agent invocation
                const value = ev.target.value;
                if (isAgentViewEnabled && value.startsWith('@') && value.length === 1) {
                    props.handleSkillMenuToggle(true);
                    props.handleAgentViewSkillMenuToggle(true);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [props.onChange, autoResizeTextarea],
        );

        // Handle key down events.
        const handleKeyDown = useCallback(
            (ev: KeyboardEvent<HTMLTextAreaElement>) => {
                // Submit the prompt on `Enter` keypress, unless the `shiftKey` is active.
                if (ev.key === 'Enter' && !ev.shiftKey) {
                    onSubmit?.(null);
                    ev.preventDefault();
                }

                props.onKeyDown?.(ev);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [onSubmit, props.onKeyDown],
        );

        // Focus the promptbar on load.
        useEffect(() => {
            if (innerRef.current) {
                autoResizeTextarea();
            }
        }, [innerRef, autoResizeTextarea]);

        return (
            <FluentTextarea
                ref={(element) => {
                    (innerRef as MutableRefObject<HTMLTextAreaElement | null>).current = element;
                    if (typeof forwardedRef === 'function') {
                        forwardedRef(element);
                    } else if (forwardedRef) {
                        forwardedRef.current = element;
                    }
                }}
                size="large"
                appearance="outline"
                resize="none"
                textarea={autosize ? {className: classes.textarea, rows} : {}}
                {...props}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        );
    },
);

Textarea.displayName = 'MedeinaTextarea';
export default Textarea;
