import {useMemo} from 'react';
import {formatDistanceToNow} from 'date-fns';
import {mergeClasses} from '@griffel/react';
import {useGetSession, useGetSessionSummary, useSessionPinboard} from '@/api/sessions';
import {
    Title1,
    Subtitle1,
    Link,
    Spinner,
    Body1Strong,
    Overflow,
    OverflowItem,
    Label,
    useOverflowCount,
    Subtitle2,
} from '@fluentui/react-components';
import {useGetPinnedEvaluations} from '@/api/evaluations';
import useSummarizeSession, {useSummarizeInvestigation} from '@/api/sessions/useSummarizeSession';
import useClasses from './SessionTile.styles';
import MedeinaFeatures from '@/util/features';
import {isEmpty} from 'lodash-es';
import useViewportSize from '@/components/ui/Grid/useViewportSize';

interface SessionTileProps {
    sessionId: string;
    className?: string;
    hero?: boolean;
}

export default function SessionTile({sessionId, className, hero = false}: SessionTileProps) {
    const classes = useClasses();
    const {sm: isSmallScreen} = useViewportSize();
    const Title = hero ? (isSmallScreen ? Subtitle2 : Title1) : Subtitle1;
    const {data: session, isLoading: isLoadingSession} = useGetSession({sessionId});
    const {data: pinnedEvaluations, isLoading: isLoadingPins} = useGetPinnedEvaluations({
        sessionId,
    });
    const pinnedItems = useMemo(() => pinnedEvaluations?.value || [], [pinnedEvaluations]);
    // Use the default summary if there are no pins.
    const {
        data: summarySession,
        isLoading: isLoadingSummarySession,
        isFetching: isFetchingSummarySession,
    } = useSummarizeSession({
        sessionId,
        enabled:
            !MedeinaFeatures.SaveSessionSummary &&
            !isLoadingSession &&
            !isLoadingPins &&
            !pinnedEvaluations?.value?.length,
    });

    // Otherwise use the pinned items summary.
    const {
        data: summary,
        isLoading: isLoadingSummary,
        isFetching: isFetchingSummary,
    } = useSummarizeInvestigation({
        sessionId,
        pinnedItems: pinnedEvaluations?.value || [],
    });

    const {
        data: sessionSummaryResponse,
        isLoading: sessionSummaryResponseloading,
        isError: getSessionSummaryError,
    } = useGetSessionSummary({
        sessionId,
        sessionData: session,
        isEnabled:
            MedeinaFeatures.SaveSessionSummary &&
            !isLoadingSession &&
            !isLoadingPins &&
            !pinnedEvaluations?.value?.length,
    });

    const {pinboardResponse, loading} = useSessionPinboard({
        sessionId,
        pinnedItems,
        pinnedItemsLength: -1,
        sessionData: session,
    });

    // TODO: Improve this.
    // The summarization text isn't currently organized with section headers.
    const actualSummary = useMemo<string | null>(() => {
        return (
            summarySession ??
            summary?.summary?.match(/^(#### .*|(\*\*.*\*\*))[ \r\n]+([^(*|#)]+)/i)?.[3]?.trim() ??
            pinboardResponse?.summary
                ?.match(/^(#### .*|(\*\*.*\*\*))[ \r\n]+([^(*|#)]+)/i)?.[3]
                ?.trim() ??
            sessionSummaryResponse?.summary
                ?.match(/^(#### .*|(\*\*.*\*\*))[ \r\n]+([^(*|#)]+)/i)?.[3]
                ?.trim() ??
            null
        );
    }, [summarySession, summary, sessionSummaryResponse, pinboardResponse]);

    const actualTags = useMemo<string[] | null>(() => {
        return summary?.tags ?? pinboardResponse?.tags ?? sessionSummaryResponse?.tags ?? null;
    }, [summarySession, summary, sessionSummaryResponse, pinboardResponse]);

    const showSpinner =
        isLoadingSession ||
        isLoadingPins ||
        isFetchingSummary ||
        isFetchingSummarySession ||
        (MedeinaFeatures.SavePinboardSummary && !isEmpty(pinnedEvaluations?.value) && loading) ||
        (MedeinaFeatures.SaveSessionSummary &&
            isEmpty(pinnedEvaluations?.value) &&
            (sessionSummaryResponseloading || getSessionSummaryError));

    return (
        <div
            className={mergeClasses(classes.root, hero && classes.heroRoot, className)}
            data-testid="session-tile"
        >
            <div className={mergeClasses(classes.title, hero && classes.heroTitle)}>
                <div aria-label={`Session: ${session?.name}`}>
                    <Title title={session?.name}>{session?.name}</Title>
                </div>
            </div>
            {showSpinner && (
                <Spinner className={classes.spinner} size="extra-large" data-testid="spinner" />
            )}
            {actualTags && actualTags.length > 0 && !showSpinner && (
                <Overflow padding={100}>
                    <div
                        className={mergeClasses(
                            mergeClasses(classes.tags, hero && classes.heroTags),
                        )}
                    >
                        {actualTags?.map((tag, i) => (
                            <OverflowItem key={i} id={i.toString()}>
                                <Label className={classes.tagItem}>{tag}</Label>
                            </OverflowItem>
                        ))}
                        <OverflowCount />
                    </div>
                </Overflow>
            )}
            {isSmallScreen && actualTags
                ? null
                : !showSpinner && (
                      <div className={mergeClasses(classes.summary, hero && classes.heroSummary)}>
                          {actualSummary ?? ''}
                      </div>
                  )}
            {session && (
                <div
                    className={mergeClasses(
                        classes.date,
                        hero && classes.heroDate,
                        classes.hideElement,
                    )}
                >
                    <Body1Strong block>
                        Updated {formatDistanceToNow(new Date(session.updatedAt || ''))} ago
                    </Body1Strong>
                </div>
            )}
        </div>
    );
}

const OverflowCount = () => {
    const classes = useClasses();
    const count = useOverflowCount();

    if (count === 0) {
        return null;
    }

    return <Label className={classes.tagItem}>+{count}</Label>;
};
