import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {
    GetAggregatedCapacityUsageByDimensionsRequest,
    GetAggregatedCapacityUsageByDimensionsResponse,
} from './capacities.types';
import useFetch from '../useFetch';
import {DEFAULT_API_RETRY_COUNT, DEFAULT_API_STALE_TIME} from '../api.constants';

export default function useGetAggregatedCapacityUsageByDimensions(
    {resourceName, body}: GetAggregatedCapacityUsageByDimensionsRequest,
    useQueryOptions?: Pick<
        UseQueryOptions<GetAggregatedCapacityUsageByDimensionsResponse>,
        'enabled'
    >,
) {
    const url = `/usage/${resourceName}/aggregates/dimensional`;
    const {customFetch} = useFetch();
    return useQuery<GetAggregatedCapacityUsageByDimensionsResponse>({
        ...useQueryOptions,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['dimensions', resourceName, body],
        retry: DEFAULT_API_RETRY_COUNT,
        staleTime: DEFAULT_API_STALE_TIME,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                },
                true,
            );

            if (!response.ok) {
                const code = response.status;
                if (code === 500) {
                    throw new Error('Unknown error occurred. Please try again later.');
                } else if (code === 404) {
                    throw new Error(`Unknown capacity resource name: ${resourceName}`);
                } else {
                    throw new Error(`Unexpected status code: ${code}.`);
                }
            }

            const data = await response.json();
            return data as GetAggregatedCapacityUsageByDimensionsResponse;
        },
    });
}
