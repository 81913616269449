import {SkillsetCategory} from '@/api/skills';

export const categoryStringMappings: {[key in SkillsetCategory]: [string, string]} = {
    [SkillsetCategory.Microsoft]: [
        'SkillsetCategories.Microsoft',
        'SkillsetCategories.MicrosoftInfo',
    ],
    [SkillsetCategory.MicrosoftConnectors]:
        // Unused
        ['', ''],
    [SkillsetCategory.Other]: ['SkillsetCategories.Other', 'SkillsetCategories.OtherInfo'],
    [SkillsetCategory.Web]: ['SkillsetCategories.Websites', 'SkillsetCategories.WebsitesInfo'],
    [SkillsetCategory.Testing]: ['SkillsetCategories.Internal', 'SkillsetCategories.InternalInfo'],
    [SkillsetCategory.Plugin]: ['SkillsetCategories.Custom', 'SkillsetCategories.CustomInfo'],
};

// Categories mapped to the connector type that can be uploaded within that category
export const categoryConnectorMappings: {[key: string]: string} = {
    MicrosoftConnectors: 'Logic App',
    Plugin: 'Custom',
};
