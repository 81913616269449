import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    Button,
    DialogActions,
    Tooltip,
    ToolbarButton,
} from '@fluentui/react-components';
import {DismissIcon, DeleteIcon} from '@/components/ui/icons';
import useClasses from './DeletePromptDialog.styles';
import {DeletePromptDialogProps} from './DeletePromptDialog.types';
import {useDeletePrompts, useDeletePromptsAndRerunPrompts} from '@/api/prompts';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DialogTitle as MedeinaDialogTitle} from '@/components/ui/Dialog';

export * from './DeletePromptDialog.types';

export default function DeletePromptDialog({
    sessionId,
    promptId,
    promptIds,
    open,
    onClose = () => null,
    onSuccess = () => null,
    label,
    className,
}: DeletePromptDialogProps) {
    const classes = useClasses();
    const {t} = useTranslation('session');
    const {mutate: deletePrompts} = useDeletePrompts();
    const {mutate: deletePromptsAndRerunPrompts} = useDeletePromptsAndRerunPrompts();

    // Focus the primary button by default.
    // This is only used for focusing, not managing dialog `open` state.
    const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        // Focus on first visible render.
        if (isOpen && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [isOpen, buttonRef.current]);

    return (
        <Dialog open={open} onOpenChange={(event, data) => setIsOpen(data.open)}>
            {open === undefined ? (
                <DialogTrigger disableButtonEnhancement>
                    <Tooltip
                        content={t('DeletePromptTooltip')}
                        relationship="label"
                        positioning="after"
                    >
                        <ToolbarButton
                            data-testid="delete-prompt-button"
                            appearance="subtle"
                            icon={<DeleteIcon />}
                            className={className}
                        >
                            {label}
                        </ToolbarButton>
                    </Tooltip>
                </DialogTrigger>
            ) : (
                <></>
            )}
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogContent>
                        <MedeinaDialogTitle
                            title={
                                promptId
                                    ? t('DeletePromptSingle')
                                    : t('DeletePromptMultiple', {0: promptIds?.length ?? 0})
                            }
                            onCloseButtonClick={onClose}
                            showBackButton={false}
                        />
                        <p>
                            {promptId
                                ? t('RerunPromptsAfterDeletionSingle')
                                : t('RerunPromptsAfterDeletionMultiple')}
                        </p>
                    </DialogContent>
                    <DialogActions position="start">
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                ref={buttonRef}
                                appearance="primary"
                                className={classes.actionButton}
                                onClick={() => {
                                    deletePromptsAndRerunPrompts(
                                        {
                                            sessionId,
                                            promptIds: promptId ? [promptId] : promptIds ?? [],
                                        },
                                        {onSuccess},
                                    );
                                    onClose?.();
                                }}
                            >
                                {t('YesButton')}
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="secondary"
                                className={classes.actionButton}
                                onClick={() => {
                                    deletePrompts(
                                        {
                                            sessionId,
                                            promptIds: promptId ? [promptId] : promptIds ?? [],
                                        },
                                        {onSuccess},
                                    );
                                    onClose?.();
                                }}
                            >
                                {promptId || promptIds?.length === 1
                                    ? t('NoButtonSingle')
                                    : t('NoButtonMultiple')}
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
