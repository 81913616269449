import {ToggleButton, ToggleButtonProps, mergeClasses} from '@fluentui/react-components';
import useClasses from './FilterButton.styles';
import React from 'react';

export interface BasicTypes {
    children: React.ReactNode;
    className?: string;
}

/**
 * Filter button that mimics Office / Fluent's Filter / Pill Button controls
 * @param props ToggleButtonProps
 * @returns React Node
 */

export const FilterButton = React.forwardRef<HTMLButtonElement, ToggleButtonProps>((props, ref) => {
    const classes = useClasses();

    return (
        <ToggleButton
            {...props}
            ref={ref}
            className={mergeClasses(
                props.checked ? classes.checked : classes.regular,
                props.className,
            )}
            shape="circular"
        />
    );
});

FilterButton.displayName = 'FilterButton';

/**
 * Filter Button Row.  Wraps the filter button in a simple flexbox and spaces
 * the buttons appropriately.
 * @param props {children, className}
 * @returns React Node
 */
export const FilterButtonRow = ({children, className}: BasicTypes) => {
    const classes = useClasses();

    return <div className={mergeClasses(classes.row, className)}>{children}</div>;
};

FilterButtonRow.displayName = 'FilterButtonRow';
