import {Body1Strong, Subtitle2, TextPresetProps} from '@fluentui/react-components';
import {useViewportSize} from '../Grid';

export default function TextSubtitle2(props: TextPresetProps) {
    const {sm: isSmallScreen} = useViewportSize();

    return (
        <>{isSmallScreen ? <Body1Strong as="h2" {...props} /> : <Subtitle2 as="h2" {...props} />}</>
    );
}
