import React from 'react';
import useTourClasses from '@/components/sections/tours/Tour.styles';
import useClasses from './EarlyExit.styles';
import {mergeClasses, Text, Button} from '@fluentui/react-components';
import {motion} from 'framer-motion';
import {useTourContext} from '@/components/ui/Tour';
import {useNavigate} from 'react-router-dom';

function Exit() {
    const tour = useTourContext();
    const classes = useClasses();
    const tourClasses = useTourClasses();
    const navigate = useNavigate();
    return (
        <>
            <div className={mergeClasses(tourClasses.fullOverlay, tourClasses.darkOverlay)}></div>

            <motion.div
                key="fre-early-exit"
                className={mergeClasses(tourClasses.centeredBlock, tourClasses.containerWidth)}
            >
                <div className={classes.root}>
                    <Text className={mergeClasses(tourClasses.exitTitle)}>Exit tutorial?</Text>
                    <Text className={mergeClasses(tourClasses.exitText)}>
                        You can access this tutorial anytime in the main navigation.
                    </Text>
                    <div className={tourClasses.buttonsRow}>
                        <Button
                            className={mergeClasses(
                                tourClasses.paddedButton,
                                tourClasses.whiteText,
                            )}
                            onClick={() => {
                                navigate('/');
                                tour.quitTour();
                            }}
                            appearance="outline"
                            size="large"
                        >
                            Exit
                        </Button>
                        <Button
                            onClick={() => {
                                tour.setShowExitOverlay(false);
                            }}
                            appearance="outline"
                            size="large"
                            className={mergeClasses(
                                tourClasses.paddedButton,
                                tourClasses.whiteText,
                            )}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default Exit;
