import React, {useRef, useEffect, useState} from 'react';
import {Button, ButtonProps, Tooltip} from '@fluentui/react-components';
import {SidePanelContractIcon, SidePanelExpandIcon} from '../icons';
import {useLayout} from '@/components/ui/Layout';
import {ControlTriggeredEventParameters} from '@/components/App.types';
import {CUSTOM_EVENTS, CUSTOM_EVENT_PARAMETERS} from '@/components/App.constants';
import {useTranslation} from 'react-i18next';

function ToggleButton(props: ButtonProps) {
    const {sidePanel} = useLayout();
    const {t} = useTranslation('session');
    const {open, toggle} = sidePanel || {open: false, toggle: () => {}};
    const [showTooltip, setShowTooltip] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleShiftTabTriggered = (event: CustomEvent<ControlTriggeredEventParameters>) => {
        if (!event?.detail) {
            return;
        }

        const {target, action, source} = event?.detail;

        if (
            target === CUSTOM_EVENT_PARAMETERS.SIDE_PANEL_BUTTON &&
            action === 'keydown' &&
            source === CUSTOM_EVENT_PARAMETERS.RESIZE_BAR
        ) {
            buttonRef?.current?.focus();
        }
    };

    useEffect(() => {
        window.addEventListener(
            CUSTOM_EVENTS.CONTROL_TRIGGERED,
            handleShiftTabTriggered as EventListener,
        );
        return () => {
            window.removeEventListener(
                CUSTOM_EVENTS.CONTROL_TRIGGERED,
                handleShiftTabTriggered as EventListener,
            );
        };
    }, []);

    const highlightResizeBar = () => {
        const eventArguments: ControlTriggeredEventParameters = {
            target: CUSTOM_EVENT_PARAMETERS.RESIZE_BAR,
            action: 'click',
            source: CUSTOM_EVENT_PARAMETERS.SIDE_PANEL_BUTTON,
        };

        const controlTriggeredEvent = new CustomEvent(CUSTOM_EVENTS.CONTROL_TRIGGERED, {
            detail: eventArguments,
        });

        setTimeout(() => {
            window.dispatchEvent(controlTriggeredEvent);
        }, 0);
    };

    return (
        <Tooltip
            visible={showTooltip}
            content={open ? t('ClosePinboardButton') : t('OpenPinboardButton')}
            relationship="label"
        >
            <Button
                tabIndex={0}
                appearance="transparent"
                {...props}
                data-testid="side-panel-button"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false)}
                aria-label={open ? t('ClosePinboardButton') : t('OpenPinboardButton')}
                ref={buttonRef}
                icon={open ? <SidePanelContractIcon /> : <SidePanelExpandIcon />}
                onClick={() => {
                    setShowTooltip(false);
                    toggle?.();
                    highlightResizeBar();
                }}
            />
        </Tooltip>
    );
}

export default ToggleButton;
