import React from 'react';
import {Button, Tooltip} from '@fluentui/react-components';
import useClasses from '../Breadcrumbs.styles';
import {useOverflowTooltip} from '@/components/ui/Reflow';
import {useTranslation} from 'react-i18next';

function Home() {
    const classes = useClasses();
    const {t} = useTranslation();
    const applicationLabel = t('ApplicationTitle');
    const {ref, isOverflowing, showTooltip} = useOverflowTooltip<HTMLButtonElement>();

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Tooltip
                content={applicationLabel}
                relationship="label"
                visible={isOverflowing && showTooltip}
            >
                <Button
                    className={classes.link}
                    href="/"
                    as="a"
                    appearance="subtle"
                    size="medium"
                    ref={ref}
                >
                    {applicationLabel}
                </Button>
            </Tooltip>
        </div>
    );
}

export default Home;
