import {Button} from '@fluentui/react-components';
import useClasses from './Pagination.styles';
import {PaginationButtonProps} from './Pagination.types';
import React, {forwardRef} from 'react';

const PaginationButton = forwardRef<HTMLButtonElement, PaginationButtonProps>(
    function PaginationButton(props: PaginationButtonProps, ref) {
        const classes = useClasses();
        const buttonId =
            props.buttonDirection === 'left' ? 'pagination-button-left' : 'pagination-button-right';
        return (
            <Button
                data-testid={buttonId}
                className={classes.button}
                appearance="transparent"
                {...props}
            >
                {props.children}
            </Button>
        );
    },
);

export default PaginationButton;
