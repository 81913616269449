import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useSessionsFetch from '../useSessionsFetch';
import {EvaluationFeedback, GetEvaluationFeedbackRequest} from '.';

// The earlier design had one to many mapping for evaluation to feedbacks and hence the plural name in URL.
// The modified design has one to one mapping and hence it's expected to return a single feedback item.
export default function useGetEvaluationFeedback(
    {sessionId, promptId, evaluationId}: GetEvaluationFeedbackRequest,
    useQueryOptions?: Pick<UseQueryOptions<EvaluationFeedback>, 'enabled'>,
) {
    const {sessionsFetch} = useSessionsFetch(sessionId);

    return useQuery<EvaluationFeedback>({
        queryKey: [
            'sessions',
            sessionId,
            'prompts',
            promptId,
            'evaluations',
            evaluationId,
            'feedbacks',
        ],
        queryFn: async () => {
            return await sessionsFetch<EvaluationFeedback>(
                `${sessionId}/prompts/${promptId}/evaluations/${evaluationId}/feedbacks`,
                {method: 'GET'},
            );
        },
        // Retry is off to avoid unnecessary UI calls when a 204 status (empty JSON) is received.
        // Incase of other errors (4xx, 5xx), users can simply refresh the page.
        // This is a GET request, so it won't affect user actions during failures.
        retry: false,
        ...useQueryOptions,
    });
}
