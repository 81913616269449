import {useEffect, useState} from 'react';
import useClasses from './PromptbookPlugins.styles';
import {
    Badge,
    Overflow,
    OverflowItem,
    useOverflowCount,
    useOverflowMenu,
    Image,
} from '@fluentui/react-components';
import {useGetSkillsetCustomIcon} from '@/api/skills';
import {useTranslation} from 'react-i18next';

export type PromptbookPlugin = {
    skillset: string;
    icon: string;
    displayName: string;
};

export type PromptbookPluginsProps = {
    plugins: PromptbookPlugin[];
    shouldOverflow: boolean;
};

type PluginIconProps = {
    plugin: PromptbookPlugin;
};

const PromptbookPlugins = ({plugins, shouldOverflow}: PromptbookPluginsProps) => {
    const classes = useClasses();
    const [uniquePlugins, setUniquePlugins] = useState<(PromptbookPlugin | undefined)[]>([]);

    useEffect(() => {
        const newUniquePlugins = Array.from(new Set(plugins.map((plugin) => plugin.skillset)))
            .map((skillset) => plugins.find((plugin) => plugin.skillset === skillset))
            .filter(Boolean); // remove undefined plugins

        setUniquePlugins(newUniquePlugins);
    }, [plugins]);

    const renderPlugins = (plugins: (PromptbookPlugin | undefined)[]) =>
        plugins.map((plugin, i) => (
            <div tabIndex={0} key={i}>
                <OverflowItem key={i} id={i.toString()}>
                    <div className={classes.pluginContainer} data-testid={plugin?.skillset}>
                        <Badge
                            className={classes.badge}
                            shape="rounded"
                            appearance="outline"
                            color="informative"
                            aria-label={plugin!.displayName}
                            icon={
                                <PluginIcon aria-hidden key={plugin!.skillset} plugin={plugin!} />
                            }
                            iconPosition="before"
                        >
                            {plugin!.displayName}
                        </Badge>
                    </div>
                </OverflowItem>
            </div>
        ));

    return (
        <div>
            {uniquePlugins &&
                uniquePlugins.length > 0 &&
                (shouldOverflow ? (
                    <Overflow minimumVisible={1}>
                        <div className={classes.pluginsContainer}>
                            {renderPlugins(uniquePlugins)}
                            <OverflowCount />
                        </div>
                    </Overflow>
                ) : (
                    <div className={classes.pluginsContainer}>{renderPlugins(uniquePlugins)}</div>
                ))}
        </div>
    );
};

const PluginIcon = ({plugin}: PluginIconProps) => {
    const classes = useClasses();
    const {data: iconPath, isLoading: loading} = useGetSkillsetCustomIcon(
        plugin.skillset,
        plugin.icon,
    );

    return (
        <Image fit="default" className={classes.image} alt={''} aria-hidden src={iconPath || ''} />
    );
};

const OverflowCount = () => {
    const {t} = useTranslation('promptbooks');
    const classes = useClasses();
    const count = useOverflowCount();
    const {isOverflowing, overflowCount} = useOverflowMenu();

    if (!isOverflowing) {
        return null;
    }

    return (
        <div key={'badgeCount'} className={classes.pluginContainer}>
            <Badge
                className={classes.badge}
                shape="rounded"
                appearance="outline"
                color="informative"
                aria-label={t('Plugins.OverflowBadgeAriaLabel', {count})}
                iconPosition="after"
            >
                +{overflowCount}
            </Badge>
        </div>
    );
};

export default PromptbookPlugins;
