import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {DeletePromptsRequest} from './prompts.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist, useClearSessionSummary} from '../sessions';
export default function useDeletePrompt() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: clearSessionSummary} = useClearSessionSummary();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: DeletePromptsRequest) => {
            // Delete all requested prompts individually.
            const results = await Promise.all(
                body.promptIds.map((promptId) =>
                    customFetch<Response>(
                        `/sessions/${body?.sessionId}/prompts/${promptId}`,
                        {
                            method: 'DELETE',
                        },
                        true,
                    ).then((response) => response.status === 200),
                ),
            );

            // Determine the success based on all of the results.
            let result = true;
            results.forEach((promptResult) => {
                result = result && promptResult;
            });
            return result;
        },
        onSettled: (success, error, body) => {
            // Refetch the parent session.
            clearSessionSummary({sessionId: body.sessionId});
            queryClient.refetchQueries({
                queryKey: ['sessions', body?.sessionId, 'prompts'],
            });
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
    });
}
