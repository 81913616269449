import {useEffect, useState} from 'react';
import {GroupedPrompt, Prompt} from './prompts.types';

const useGroupedPrompts = (prompts: Prompt[] | undefined) => {
    const [groupedPromptData, setGroupedPromptData] = useState<GroupedPrompt[]>([]);

    useEffect(() => {
        if (!prompts) return;

        const groupedPrompts: GroupedPrompt[] = [];
        let currentType: 'Prompt' | 'Promptbook' | null = null;
        let currentPrompts: Prompt[] = [];
        let currentPromptbookExecutionId: string | undefined | null = null;

        prompts.forEach((prompt) => {
            const type = prompt.promptbookExecutionId ? 'Promptbook' : 'Prompt';
            if (type !== currentType) {
                if (currentType !== null) {
                    groupedPrompts.push({type: currentType, prompts: currentPrompts});
                }

                currentType = type;
                currentPrompts = [];
                currentPromptbookExecutionId = prompt.promptbookExecutionId;
            } else if (
                type === currentType &&
                prompt.promptbookExecutionId !== currentPromptbookExecutionId &&
                currentPromptbookExecutionId !== null
            ) {
                groupedPrompts.push({type: currentType, prompts: currentPrompts});
                currentPromptbookExecutionId = prompt.promptbookExecutionId;
                currentPrompts = [];
            } else if (type === currentType && type === 'Prompt') {
                groupedPrompts.push({type: currentType, prompts: currentPrompts});
                currentPromptbookExecutionId = prompt.promptbookExecutionId;
                currentPrompts = [];
            }
            currentPrompts.push(prompt);
        });

        if (currentType !== null) {
            groupedPrompts.push({type: currentType, prompts: currentPrompts});
        }

        setGroupedPromptData(groupedPrompts);
    }, [prompts]);

    return groupedPromptData;
};

export default useGroupedPrompts;
