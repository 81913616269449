import {customTokens} from '@/components/theme';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        //display: 'flex',
        //flexDirection: 'row',
        justifyContent: 'center',
        //marginBottom: tokens.spacingVerticalXXXL,
        //width: '100%',
        position: 'fixed',
        //visibility: props.openModal ? 'visible' : 'hidden',
        height: '100%',
        width: '30vw',
        right: '0',
        bottom: '0',
        zIndex: 3000,
        display: 'block',
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border(tokens.spacingHorizontalNone),
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset',

        '& .ms-Panel.is-open': {
            left: 'auto',
            right: '0px',
        },
        '& [class^="searchBarDescription"]': {
            minWidth: 'auto',
        },

        '& div.ms-Panel-main': {
            minHeight: 'auto',
        },

        '& [class^="tabContainer"]': {
            overflowX: 'auto',
            overflowY: 'hidden',
        },

        '& [class^="customPlaceholderOnFocus]': {
            minWidth: 'auto',
            textWrap: 'wrap',
            width: '-webkit-fill-available',
        },
    },
    leftLanguagePanel: {
        right: '0',
        left: 'auto',

        '& .ms-Panel.is-open': {
            left: '0',
            right: 'auto',
        },
    },
    maxStateContainer: {
        height: 'calc(100% - 50px)',
        //color: tokens.colorNeutralForeground1,
        color: tokens.colorCompoundBrandBackground,
        borderTopLeftRadius: '100px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        backgroundColor: '#333A3F',
    },
    mainPanel: {
        backgroundColor: '#333A3F',
    },

    modalbutton: {
        position: 'absolute',
        top: '0',
        right: '0',
        //backGround: 'none',
        //border: 'None',
        cursor: 'pointer',
        //verticalAlign: 'middle',
        //vertical: top,
        zIndex: 1000,
    },
    fullWidthRoot: {
        ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalXXL),
        justifyContent: 'flex-start',
        overflowX: 'hidden',
    },
    select: {
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
    },
    content: {
        ...shorthands.borderRadius('10px'),
        ...shorthands.flex(1, 1, 'auto'),
        boxShadow: tokens.shadow8,
        // we add a margin to account for the border width, this helps align the content with the prompt bar
        maxWidth: '700px',
        marginLeft: '16px',
    },
    fullWidthContent: {
        maxWidth: '100%',
        marginRight: tokens.spacingHorizontalXXXL,
    },
    pinnedPrompt: {
        marginLeft: '10px',
        ...shorthands.borderLeft(tokens.strokeWidthThicker, 'solid', tokens.colorBrandForeground1),
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
    },
    divider: {
        ...shorthands.padding('0px', tokens.spacingHorizontalXXL),
        '::before': {
            ...shorthands.borderColor(tokens.colorNeutralBackground5),
            ...shorthands.borderWidth('2px'),
            left: 0,
        },
        '::after': {
            ...shorthands.borderColor(tokens.colorNeutralBackground5),
            ...shorthands.borderWidth('2px'),
            right: 0,
        },
        backgroundColor: tokens.colorNeutralBackground1,
    },
    spinner: {
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
        paddingTop: '20%',
    },
    evaluation: {
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),
        backgroundColor: tokens.colorNeutralBackground1,
    },
    button: {
        zIndex: 1000,
        ...shorthands.margin('10px', '10px', '0px', '0px'),
        ...shorthands.border('0px'),
        fontFamily: tokens.fontFamilyBase,
        color: tokens.colorCompoundBrandBackground,
        //backgroundColor: customTokens.securitySourcesBackground,
        fontWeight: tokens.fontWeightRegular,
        minWidth: '50px',
        maxWidth: '300px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textAlign: 'left',
        ':hover': {
            backgroundColor: customTokens.securitySourcesBackgroundHover,
            color: tokens.colorCompoundBrandBackground,
        },
        '&:disabled, &:disabled:hover': {
            color: tokens.colorNeutralForeground1,
        },
        '&:enabled:active': {
            backgroundColor: customTokens.securitySourcesBackgroundPressed,
            color: tokens.colorCompoundBrandBackground,
        },
    },
    errorContainer: {
        color: tokens.colorNeutralForeground1,
        paddingTop: '20%',
        display: 'inline-block',
        textAlign: 'center',
    },
    label: {
        display: 'block',
        marginBottom: tokens.spacingVerticalMNudge,
        fontSize: tokens.fontSizeBase600,
        whiteSpace: 'break-spaces',
    },
});
