import {EvaluationOutputComponentType, useGetEvaluation} from '@/api/evaluations';
import {Markdown} from '@/components/ui';
import {EvaluationProps} from './Evaluation.types';
import useClasses from './SuccessEvaluationResult.styles';
import EvaluationMetadata from './EvaluationMetadata';
import EvaluationSources from './EvaluationSources';
import EvaluationImages from './EvaluationImages';

export default function SuccessEvaluationResult({
    sessionId,
    promptId,
    evaluationId,
}: EvaluationProps) {
    const classes = useClasses();

    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});

    // Determine elapsed time when in debug mode.
    return (
        <div className={classes.root}>
            {evaluation && (
                <>
                    <div className={classes.content}>
                        <EvaluationMetadata evaluation={evaluation} />
                        <Markdown>{evaluation?.result?.content ?? ''}</Markdown>
                        {evaluation?.result?.outputComponents &&
                        evaluation.result.outputComponents.filter(
                            (component) => component?.type === EvaluationOutputComponentType.Image,
                        )?.length > 0 ? (
                            <EvaluationImages evaluation={evaluation} />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className={classes.sources}>
                        <EvaluationSources evaluation={evaluation} />
                    </div>
                </>
            )}
        </div>
    );
}
