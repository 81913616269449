import {PromptbookCloseResult, PromptbookFormProps} from './Promptbook.types';
import useClasses from './PromptbookCreateSuccessForm.styles';
import {
    Button,
    Dialog,
    DialogSurface,
    DialogBody,
    Title3,
    Image,
    DialogContent,
    Spinner,
    mergeClasses,
    DialogTrigger,
} from '@fluentui/react-components';
import useGetPromptbook from '@/api/promptbooks/useGetPromptbook';
import {DismissIcon, LibraryIcon, PromptbookIcon, ShareIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import useScrollStyles from '@/components/ui/util/MedeinaScrollbar.styles';
import {useState} from 'react';
import {PromptbookDescriptor, PromptbookVisibility, useGetPromptbookLink} from '@/api/promptbooks';
import LinkCopiedDialog from './LinkCopiedDialog';
import {useMsalUserId} from '@/util/msal';
import {useNavigate} from 'react-router-dom';
import {ColorScheme, useAppState} from '@/api/app';

const PromptbookCreateSuccessForm = ({promptbookId, onClose, open}: PromptbookFormProps) => {
    const {colorScheme: color} = useAppState();
    const classes = useClasses();
    const scrollClasses = useScrollStyles();
    const {t} = useTranslation('promptbooks');
    const {t: tCommon} = useTranslation('common');
    const {data: promptbookDefn, isLoading: promptbookLoading} = useGetPromptbook({promptbookId});
    const [showLinkToast, setShowLinkToast] = useState<boolean>(false);
    const userObjectId = useMsalUserId();
    const navigate = useNavigate();

    function getPromptbookSaveImage(colorScheme: ColorScheme) {
        switch (colorScheme) {
            case ColorScheme.Dark:
            case ColorScheme.HighContrast:
                return '/images/features/promptbooks/PromptbookSaveDark.svg';
            case ColorScheme.Light:
                return '/images/features/promptbooks/PromptbookSaveLight.svg';
            default:
                return '/images/features/promptbooks/PromptbookSaveDark.svg';
        }
    }

    const {
        mutate: getPromptbookLink,
        isError: getPromptbookLinkError,
        reset,
        error,
        isLoading: getPromptbookLinkLoading,
    } = useGetPromptbookLink();

    const isShareable = (promptbook: PromptbookDescriptor): boolean => {
        return (
            userObjectId === promptbook?.userId ||
            promptbook.visibility === PromptbookVisibility.Global ||
            promptbook.visibility === PromptbookVisibility.Tenant
        );
    };

    const handleShareClicked = () => {
        getPromptbookLink(promptbookDefn!, {
            onSuccess: () => {
                setShowLinkToast(true);
            },
        });
    };

    return (
        <>
            {showLinkToast && (
                <LinkCopiedDialog
                    open={showLinkToast}
                    onClose={() => setShowLinkToast(false)}
                ></LinkCopiedDialog>
            )}
            <Dialog modalType="modal" open={open}>
                <DialogSurface
                    className={mergeClasses(
                        classes.dialogSurface,
                        scrollClasses.colorNeutralBackground1,
                    )}
                >
                    <DialogBody className={classes.dialogBody}>
                        <div>
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label={tCommon('ButtonLabels.Close')}
                                    icon={<DismissIcon />}
                                    onClick={() => onClose?.(PromptbookCloseResult.closed)}
                                ></Button>
                            </DialogTrigger>
                        </div>
                        {!promptbookLoading && promptbookDefn && (
                            <DialogContent
                                className={mergeClasses(
                                    classes.dialogContent,
                                    scrollClasses.colorNeutralBackground1,
                                )}
                            >
                                <Image
                                    data-testid="promptbook-created-image"
                                    src={getPromptbookSaveImage(color)}
                                    alt=""
                                    aria-hidden
                                    fit="default"
                                />
                                <Title3>{t('PromptbookCreated')}</Title3>
                                <div className={classes.chipsContainer}>
                                    <Button
                                        data-testid="view-button"
                                        aria-label={t('ViewButton')}
                                        icon={<PromptbookIcon />}
                                        appearance="secondary"
                                        onClick={() =>
                                            navigate(`/promptbooks/${promptbookDefn.promptbookId}`)
                                        }
                                        className={classes.chips}
                                    >
                                        {t('ViewButton')}
                                    </Button>
                                    {isShareable(promptbookDefn) && (
                                        <>
                                            <Button
                                                data-testid="share-button"
                                                aria-label={t('SharePromptbookButton')}
                                                icon={<ShareIcon />}
                                                appearance="secondary"
                                                onClick={handleShareClicked}
                                                className={classes.chips}
                                            >
                                                {t('SharePromptbookButton')}
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        data-testid="promptbooklibrary-button"
                                        aria-label={t('PromptbookLibrary')}
                                        icon={<LibraryIcon />}
                                        appearance="secondary"
                                        onClick={() => navigate(`/promptbooks/`)}
                                        className={classes.chips}
                                    >
                                        {t('PromptbookLibrary')}
                                    </Button>
                                </div>
                            </DialogContent>
                        )}
                        {promptbookLoading && (
                            <DialogContent className={classes.dialogContent}>
                                <Spinner size="extra-large" />
                            </DialogContent>
                        )}
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};
export default PromptbookCreateSuccessForm;
