import ExplodingHead from '../emojis/ExplodingHead';
import FaceLying from '../emojis/FaceLying';
import FaceMonocle from '../emojis/FaceMonocle';
import FaceNerd from '../emojis/FaceNerd';
import FaceRelieved from '../emojis/FaceRelieved';
import FaceThinking from '../emojis/FaceThinking';
import FaceUpsideDown from '../emojis/FaceUpsideDown';
import GemStone from '../emojis/GemStone';
import GoalNet from '../emojis/GoalNet';
import HundredPoints from '../emojis/HundredPoints';
import MartialArtsUniform from '../emojis/MartialArtsUniform';
import RacingCar from '../emojis/RacingCar';
import SafetyPin from '../emojis/SafetyPin';
import SpeechBubbleLeft from '../emojis/SpeechBubbleLeft';
import TestTube from '../emojis/TestTube';
import {TipsMessage} from './DailyTips.types';

export const dailyTipMessages: Array<TipsMessage> = [
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.AIisNotPerfectTitle',
        information: 'DailyTip.AIisNotPerfect',
        image: FaceUpsideDown,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.RateCopilotTitle',
        information: 'DailyTip.RateCopilot',
        image: HundredPoints,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.LeverageCopilotTitle',
        information: 'DailyTip.LeverageCopilot',
        image: ExplodingHead,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.PilotSessionsTitle',
        information: 'DailyTip.PilotSessions',
        image: FaceRelieved,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.GoodInputTitle',
        information: 'DailyTip.GoodInput',
        image: SafetyPin,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.FactCheckTitle',
        information: 'DailyTip.FactCheck',
        image: FaceNerd,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.CopilotsGoalsTitle',
        information: 'DailyTip.CopilotsGoals',
        image: GoalNet,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.QualityInTitle',
        information: 'DailyTip.QualityIn',
        image: GemStone,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.HelpMicrosoftTitle',
        information: 'DailyTip.HelpMicrosoft',
        image: SpeechBubbleLeft,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.ExperimentationEncouragedTitle',
        information: 'DailyTip.ExperimentationEncouraged',
        image: TestTube,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.DriversSeatTitle',
        information: 'DailyTip.DriversSeat',
        image: RacingCar,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.AIMightBeWrongTitle',
        information: 'DailyTip.AIMightBeWrong',
        image: FaceLying,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.BringYourSkepticTitle',
        information: 'DailyTip.BringYourSkeptic',
        image: FaceThinking,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.CopilotProfessionalGuesserTitle',
        information: 'DailyTip.CopilotProfessionalGuesser',
        image: FaceMonocle,
    },
    {
        type: 'DailyTip.DailyTip',
        title: 'DailyTip.YouAreTheSecurityExpertTitle',
        information: 'DailyTip.YouAreTheSecurityExpert',
        image: MartialArtsUniform,
    },
];
