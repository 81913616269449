import * as React from 'react';
const SVGComponent = (props: any) => (
    <svg
        width={2263}
        height={1451}
        viewBox="0 0 2263 1451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#filter0_f_1_6)">
            <path
                d="M726.565 449.591C850.565 343.223 1058.57 414.96 1738.57 598.012C2174.57 746.433 1342.57 815.696 1036.57 790.959C730.565 766.222 550.927 1124.91 428.927 1035.85C306.927 946.801 602.565 555.959 726.565 449.591Z"
                fill="#296981"
            />
        </g>
        <defs>
            <filter
                id="filter0_f_1_6"
                x={0}
                y={0}
                width={2262.36}
                height={1450.09}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={200} result="effect1_foregroundBlur_1_6" />
            </filter>
        </defs>
    </svg>
);
export default SVGComponent;
