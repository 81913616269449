import {useMutation, useQueryClient} from '@tanstack/react-query';
import {PageResponse} from '../api';
import useFetch from '../useFetch';
import {Prompt} from '../prompts';
import {
    CreatePinnedEvaluationRequest,
    CreatePinnedEvaluationsRequest,
    PinnedEvaluation,
} from './evaluations.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist} from '../sessions';
export default function useCreatePinnedEvaluation() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: CreatePinnedEvaluationsRequest) => {
            // Retrieve already pinned evaluations, otherwise creation might fail.
            const pinnedEvaluations = await customFetch<PageResponse<PinnedEvaluation>>(
                `/sessions/${body.sessionId}/pinnedevaluations`,
                {
                    method: 'GET',
                },
            );

            // Retrieve the list of sessionId/promptId/evaluationId sets.
            const pinnedEvaluationRequests = await customFetch<PageResponse<Prompt>>(
                `/sessions/${body.sessionId}/prompts`,
                {
                    method: 'GET',
                },
            ).then((prompts) => {
                return (
                    prompts.value
                        // Only include selected prompts.
                        .filter(({promptId}) => body.promptIds.includes(promptId))
                        // Exclude already pinned evaluations.
                        .filter(
                            ({promptId, latestEvaluationId}) =>
                                !pinnedEvaluations.value.find(
                                    (pin) =>
                                        pin.promptId === promptId &&
                                        pin.evaluationId === latestEvaluationId,
                                ),
                        )
                        // Map as a normal pin creation request.
                        .map(
                            (prompt) =>
                                ({
                                    sessionId: prompt.sessionId,
                                    promptId: prompt.promptId,
                                    evaluationId: prompt.latestEvaluationId,
                                } as CreatePinnedEvaluationRequest),
                        )
                );
            });

            // Pin each set.
            let createdPinnedEvaluations: PinnedEvaluation[] = [];
            for (const request of pinnedEvaluationRequests) {
                // Create pins sequentially, because there's an API bug that results in corrupted pins when called in parallel.
                // TODO: Improve this solution when APIs are updated.
                createdPinnedEvaluations.push(
                    await customFetch<PinnedEvaluation>(
                        `/sessions/${request.sessionId}/pinnedevaluations`,
                        {
                            method: 'POST',
                            body: request,
                        },
                    ).catch(),
                );
            }

            return {value: createdPinnedEvaluations} as PageResponse<PinnedEvaluation>;
        },
        onSettled: (evaluation, error, body) => {
            // Refetch anything using pinned evaluations.
            queryClient.refetchQueries({
                queryKey: ['sessions', body.sessionId, 'pinnedevaluations'],
            });
        },
        onSuccess: (data, body) => {
            changeSessionUpdatedAt(body.sessionId);
        },
    });
}
