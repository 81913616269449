import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
        marginBottom: tokens.spacingVerticalXL,
    },
    resourceLinksContainer: {
        marginBottom: tokens.spacingVerticalL,

        '& > div': {
            marginBottom: tokens.spacingVerticalXS,
            fontSize: tokens.fontSizeBase300,
            fontWeight: tokens.fontWeightRegular,

            '& span:first-of-type': {
                marginRight: tokens.spacingHorizontalS,
            },
        },
    },
    switchCapacityBtn: {
        ...shorthands.margin(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalS,
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
        ),
    },
    buttonWrapper: {
        display: 'flex',
    },
});
