import {useQuery} from '@tanstack/react-query';
import {GetSessionSummaryRequest, SessionSummaryResponse} from './sessions.types';
import useFetch from '../useFetch';
import {Session} from './sessions.types';
import {useSummarizeSessionV2} from '@/api/sessions';
import {useEffect, useState} from 'react';

export default function useGetSessionSummary({
    sessionId,
    sessionData,
    isEnabled = true,
}: GetSessionSummaryRequest) {
    const [loading, setLoading] = useState<boolean>(true);
    const {customFetch} = useFetch();
    const {
        data: summarizeResponse,
        refetch: refetchSummarize,
        isLoading: summarizeResponseLoading,
        isRefetching: summarizeResponseRefetching,
        isSuccess: isSummarizeSuccess,
        isError: isSummarizeError,
    } = useSummarizeSessionV2({sessionId, sessionData, enabled: false});

    useEffect(() => {
        if ((isSummarizeSuccess || isSummarizeError) && !summarizeResponseLoading) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [isSummarizeSuccess, isSummarizeError, summarizeResponse, summarizeResponseLoading]);

    const query = useQuery({
        queryKey: ['sessiongetsessionsummary', sessionId],
        queryFn: async () => {
            const dbSession = await customFetch<Session>(`/sessions/${sessionId}`, {method: 'GET'});
            return dbSession.sessionSummary as SessionSummaryResponse;
        },
        enabled: isEnabled,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (dbSummary: SessionSummaryResponse) => {
            if (isEnabled && (dbSummary == null || dbSummary.summary == null)) {
                refetchSummarize();
            } else {
                setLoading(false);
            }
        },
    });

    return {
        ...query,
        isLoading: loading || query.isLoading,
        isSuccess: isSummarizeSuccess || query.isSuccess,
        isError: isSummarizeError || query.isError,
    };
}
