import React, {useCallback} from 'react';
import {useAppState, TourHistory} from '@/api/app';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';

interface telemetryProps {
    tourName: string;
}

// this hook will be responsible for marking to AppState when the tour has started, completed, and exitedEarly
// it will also use useTrackEvent to send telemetry to AppInsights
// it should return two functions that can be used to send telemetry to AppInsights
function useTourHistoryTelemetry(props: telemetryProps) {
    const {tourName} = props;
    const {mutate: trackEvent} = useTrackEvent();

    const {userTours, setUserTour} = useAppState();

    // function that updates the tour history in AppState
    const updateTourHistory = useCallback(
        (tourState: Partial<TourHistory>) => {
            setUserTour(tourName, tourState);
        },
        [tourName, setUserTour],
    );

    const onTourStart = useCallback(() => {
        updateTourHistory({
            started: Date.now(),
        });
        trackEvent({
            name: MedeinaTelemetryEvent.Tour.TourStarted,
            eventType: MedeinaEvent.ActionEvent,
        });
    }, [trackEvent, updateTourHistory]);

    const onTourComplete = useCallback(() => {
        updateTourHistory({
            completed: Date.now(),
        });
        trackEvent({
            name: MedeinaTelemetryEvent.Tour.TourCompleted,
            eventType: MedeinaEvent.ActionEvent,
        });
    }, [trackEvent, updateTourHistory]);

    const onTourExitEarly = useCallback(
        (lastStopName: string) => {
            updateTourHistory({
                exited: Date.now(),
                lastStopName: lastStopName,
            });
            trackEvent({
                name: MedeinaTelemetryEvent.Tour.TourEarlyExited,
                eventType: MedeinaEvent.ActionEvent,
            });
        },
        [trackEvent, updateTourHistory],
    );

    return {onTourStart, onTourComplete, onTourExitEarly};
}

export default useTourHistoryTelemetry;
