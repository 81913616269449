import React, {useRef, useEffect, useState} from 'react';
import {Link, mergeClasses, useFocusFinders} from '@fluentui/react-components';
import usePageClasses from './DataAndPrivacy.styles';
import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {HeartBeatSizeIcon} from '@/components/ui/icons';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {motion} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import useManageTransitionDetails from './useManageTourTransition';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import {useNavigate} from 'react-router-dom';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useQueryClient} from '@tanstack/react-query';
import DataSharingSwitches from './DataSharingSwitches';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {Body, SmallBody, SmallFooterContent, TextH1} from '@/components/ui/Text';

export function DataAndPrivacy() {
    const tourClasses = useTourClasses();

    const pageClasses = usePageClasses();
    const queryClient = useQueryClient();

    const tour = useTourContext();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const navigate = useNavigate();
    const {update: providerUpdate} = useWorkspaceState();

    const dataAndPrivacyWrapper = useRef<HTMLDivElement>(null);
    const {validatePostWorkspaceSetup} = useManageTransitionDetails();
    // New loading state for the continue button
    const [isContinueLoading, setIsContinueLoading] = useState(false);
    const [isContentRendered, setIsContentRendered] = useState(false);
    const {findFirstFocusable} = useFocusFinders();
    useEffect(() => {
        if (dataAndPrivacyWrapper?.current) {
            const firstFocusable = findFirstFocusable(dataAndPrivacyWrapper.current);
            setTimeout(() => firstFocusable?.focus(), 0);
        }
    }, [dataAndPrivacyWrapper]);

    async function handleContinueClick(): Promise<void> {
        try {
            setIsContinueLoading(true);
            const step = await validatePostWorkspaceSetup();
            if (step === TOUR_STEPS.None) {
                tour.nextStop();
            } else {
                if (step === TOUR_STEPS.HOME) {
                    await providerUpdate();
                    await queryClient.invalidateQueries(['auth', 'getUserInfo']);
                    navigate('/');
                    tour.quitTour();
                } else {
                    tour.setTourStep(step);
                }
            }
        } catch (error) {
            tour.setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.Locations,
            });
        } finally {
            setIsContinueLoading(false); // Stop loading regardless of outcome
        }
    }

    function handleBackClick(): void {
        tour.prevStop();
    }

    return (
        <>
            <motion.div
                key="terms"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourStageContainer ref={dataAndPrivacyWrapper}>
                    <TourStageContainer.StageContent>
                        <div>
                            <HeartBeatSizeIcon
                                className={mergeClasses(
                                    tourClasses.largeIcon,
                                    tourClasses.tourSectionSpacing,
                                )}
                            />
                        </div>
                        <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                            <TextH1>{t('adminV2Fre.dataAndPrivacy.Title')}</TextH1>
                        </div>
                        <div className={tourClasses.tourSectionSpacing}>
                            <Body>{t('adminV2Fre.dataAndPrivacy.SupplementaryContent')}</Body>
                        </div>
                        <div
                            className={mergeClasses(
                                pageClasses.dataAndPrivacyToggleSection,
                                tourClasses.tourSectionSpacing,
                            )}
                        >
                            <DataSharingSwitches />
                        </div>
                        <div className={tourClasses.tourSectionSpacing}>
                            <SmallBody>{t('adminV2Fre.dataAndPrivacy.BaseContent')}</SmallBody>
                        </div>
                        <div>
                            <SmallFooterContent>
                                <>
                                    {t('adminV2Fre.dataAndPrivacy.ReadAbout')}{' '}
                                    <Link
                                        href="https://learn.microsoft.com/en-us/security-copilot/privacy-data-security#how-to-opt-in-to-or-out-of-data-sharing"
                                        target="_blank"
                                        aria-label={t(
                                            'adminV2Fre.dataAndPrivacy.ariaLabel.PrivacyDataSecurity',
                                        )}
                                    >
                                        {t('adminV2Fre.dataAndPrivacy.PrivacyDataSecurity')}
                                    </Link>
                                </>
                            </SmallFooterContent>
                        </div>
                    </TourStageContainer.StageContent>
                    <TourStageContainer.FooterContent>
                        <TourControls
                            spanFullScreen={true}
                            previousButton={commonLabel('Back')}
                            nextButton={commonLabel('Continue')}
                            handlePrevious={handleBackClick}
                            handleNext={handleContinueClick}
                            isNextButtonDisabled={isContinueLoading}
                            showPrevious={!tour.currentStop?.isFirstStop}
                            showProgressSpinner={isContinueLoading}
                        />
                    </TourStageContainer.FooterContent>
                </TourStageContainer>
            </motion.div>
        </>
    );
}

export default DataAndPrivacy;
