import {useTranslation} from 'react-i18next';
import useClasses from './O365ConsentStep.styles';
import {Body1Strong, Link, mergeClasses, useFocusFinders} from '@fluentui/react-components';
import {DataVizIcon} from '@/components/ui/icons';
import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import usePatchTenantInfo from '@/api/tenant/usePatchTenantInfo';
import useGetTenantInfo from '@/api/tenant/useGetTenantInfo';
import {LargeTitleSubheader, ParagraphContent, SmallFooterContent} from '@/components/ui/Text';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {motion} from 'framer-motion';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {useEffect, useRef, useState} from 'react';
import useManageTransitionDetails from './useManageTourTransition';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import MedeinaVariables from '@/util/variables';
import {DataUsageRegular} from '@fluentui/react-icons';

export function O365ConsentStep() {
    const classes = useClasses();
    const tourClasses = useTourClasses();

    const {t: tCommon} = useTranslation('common');

    const tour = useTourContext();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [isContinueLoading, setIsContinueLoading] = useState(false);
    const O365RootRef = useRef<HTMLDivElement>(null);

    const {data: tenantInfo} = useGetTenantInfo();
    const {mutateAsync: patchTenantInfo} = usePatchTenantInfo();
    const {update: providerUpdate} = useWorkspaceState();
    const {validatePostWorkspaceSetup} = useManageTransitionDetails();

    const {findFirstFocusable} = useFocusFinders();
    useEffect(() => {
        if (O365RootRef?.current) {
            const firstFocusable = findFirstFocusable(O365RootRef.current);
            firstFocusable?.focus();
        }
    }, [O365RootRef]);

    function handleBackClick(): void {
        tour.prevStop();
    }

    async function patchTenantInfoAndUpdateState() {
        try {
            setIsContinueLoading(true);
            const step = await validatePostWorkspaceSetup();
            await handlePostWorkspaceSetup(step);
        } catch (error) {
            handlePatchTenantInfoError();
        } finally {
            setIsContinueLoading(false);
        }
    }

    async function handlePostWorkspaceSetup(step: TOUR_STEPS) {
        if (step === TOUR_STEPS.None) {
            await patchTenantInfo({
                name: tenantInfo?.name,
                tenantId: tenantInfo?.tenantId,
                complianceRequirements: {
                    allowO365DataCollection: {
                        enabled: true,
                    },
                },
            });
            tour.nextStop();
        } else {
            if (step === TOUR_STEPS.HOME) {
                navigateHomeAndUpdateState();
            } else {
                tour.setTourStep(step);
            }
        }
    }

    async function navigateHomeAndUpdateState() {
        await providerUpdate();
        await queryClient.invalidateQueries(['auth', 'getUserInfo']);
        navigate('/');
        tour.quitTour();
    }

    function handlePatchTenantInfoError() {
        tour.setTourErrorV2({
            ...TOUR_ERRORS[TourErrorTypes.SelectionError],
            actionButtonStep: TOUR_STEPS.O365Consent,
        });
    }

    function handleContinueClick(): void {
        patchTenantInfoAndUpdateState();
    }

    return (
        <>
            <motion.div
                key="O365-consent"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourStageContainer ref={O365RootRef}>
                    <TourStageContainer.StageContent>
                        <div className={classes.icon}>
                            <DataUsageRegular fontSize={48}></DataUsageRegular>
                        </div>
                        <div className={tourClasses.tourSectionSpacing}>
                            <LargeTitleSubheader>
                                {tCommon('O365ConsentSettingsPluginsHeading')}
                            </LargeTitleSubheader>
                        </div>
                        <div
                            className={mergeClasses(
                                tourClasses.tourSectionSpacing,
                                classes.spacing,
                            )}
                        >
                            <ParagraphContent>
                                {tCommon('O365ConsentSettingsPluginsDescriptionFRE')}
                            </ParagraphContent>
                        </div>
                        <div className={tourClasses.tourSectionSpacing}>
                            <Body1Strong>
                                {tCommon('O365ConsentSettingsPluginsSubHeadingFRE')}
                            </Body1Strong>
                        </div>
                        <div
                            className={mergeClasses(
                                tourClasses.tourSectionSpacing,
                                classes.spacing,
                            )}
                        >
                            <ParagraphContent>
                                {tCommon('O365ConsentSettingsPluginsOptOutDescriptionFRE')}
                            </ParagraphContent>
                        </div>
                        <div
                            className={mergeClasses(
                                tourClasses.tourSectionSpacing,
                                classes.footerSpacing,
                            )}
                        >
                            <SmallFooterContent>
                                <Link
                                    href={MedeinaVariables.PrivacyAndDataSecurity}
                                    target="_blank"
                                    aria-label={tCommon(
                                        'O365ConsentSettingsLinkDescriptionFREAriaLabel',
                                    )}
                                >
                                    {tCommon('O365ConsentSettingsLinkDescriptionFRE')}
                                </Link>
                            </SmallFooterContent>
                        </div>
                    </TourStageContainer.StageContent>
                    <TourStageContainer.FooterContent>
                        <TourControls
                            spanFullScreen={true}
                            previousButton={tCommon('Back')}
                            nextButton={tCommon('Continue')}
                            handlePrevious={handleBackClick}
                            handleNext={handleContinueClick}
                            isNextButtonDisabled={isContinueLoading}
                            showPrevious={!tour.currentStop?.isFirstStop}
                            showProgressSpinner={isContinueLoading}
                        />
                    </TourStageContainer.FooterContent>
                </TourStageContainer>
            </motion.div>
        </>
    );
}
