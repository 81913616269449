import {Table} from '@fluentui/react-components';
import useClasses from './ModalTable.styles';

const ModalTable = ({...props}) => {
    const componentStyles = useClasses();

    const tableProps = {
        ...props,
        rowProperties: {
            highlightOnHover: true,
        },
    };
    return (
        <div className={componentStyles.root}>
            <Table {...tableProps}></Table>
        </div>
    );
};

export default ModalTable;
