import {useCallback, useEffect, useRef, useState} from 'react';
import {
    Button,
    ToggleButton,
    useFocusFinders,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {
    useCreateEvaluationFeedback,
    EvaluationFeedbackType,
    EvaluationFeedbackSubType,
    useUpdateEvaluationFeedback,
    useGetEvaluationFeedback,
} from '@/api/feedbacks';
import useClasses from './EvaluationToolbar.styles';
import {EvaluationProps} from '../Evaluation.types';
import {
    DismissIcon,
    HelpfulFeedbackIcon,
    UnhelpfulFeedbackIcon,
    ReportFeedbackIcon,
} from '@/components/ui/icons';
import {EvaluationState, useGetEvaluation} from '@/api/evaluations';

import {FeedbackThanksMessage} from './EvaluationToolbar.types';
import getRandomElementFromArray from '@/util/getRandomElement';
import ExportEvaluator from '../ExportEvaluator';
import {useTourable} from '@/components/ui/Tour/useTourable';
import EvaluationFeedbackForm from './EvaluationFeedbackForm';
import useTimer from '../../../../util/useTimer';
import {
    confirmFeedbackThanks,
    offtargetFeedbackThanks,
    reportFeedbackThanks,
    unknownFeedbackThanks,
} from './EvaluationToolbar.constants';

const feedbackMap = {
    [EvaluationFeedbackType.Confirm]: confirmFeedbackThanks,
    [EvaluationFeedbackType.OffTarget]: offtargetFeedbackThanks,
    [EvaluationFeedbackType.Report]: reportFeedbackThanks,
    [EvaluationFeedbackType.Unknown]: unknownFeedbackThanks,
};

export default function EvaluationToolbar(props: EvaluationProps) {
    const tourRef = useTourable({id: 'investigation-evaluation-toolbar'});
    const offtargetTourRef = useTourable({id: 'investigation-evaluation-toolbar-offtarget'});
    const {sessionId, promptId, evaluationId} = props;
    const classes = useClasses();

    // Feedback.
    const feedbackTextarea = useRef<HTMLTextAreaElement>(null);
    const feedbackSubtypes = useRef<HTMLDivElement>(null);
    const {data: feedback} = useGetEvaluationFeedback({
        sessionId,
        promptId,
        evaluationId,
    });
    const [feedbackType, setFeedbackType] = useState<EvaluationFeedbackType | undefined>();
    const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>();
    const [feedbackThanks, setFeedbackThanks] = useState<FeedbackThanksMessage | null>();
    const ThanksImage = feedbackThanks?.image || <></>;
    const {mutate: createFeedback} = useCreateEvaluationFeedback();
    const {mutate: updateFeedback} = useUpdateEvaluationFeedback();

    // Highlight feedback if any exists or onClick of a new feedback type.
    useEffect(() => {
        if (feedback) {
            setFeedbackType(feedback.feedbackType);
        }
    }, [feedback]);

    // Focus the textarea after clicking a feedback button.
    useEffect(() => {
        if (feedbackType && feedbackTextarea?.current) {
            feedbackTextarea.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackTextarea.current, feedbackType]);

    // Focus restoration for additional details form
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    const feedbackThanksRef = useRef<HTMLDivElement>(null);
    const {findNextFocusable} = useFocusFinders();

    // Focus feedback thanks dismiss button after submitting additional details form
    useEffect(() => {
        if (feedbackThanks && feedbackThanksRef.current) {
            findNextFocusable(feedbackThanksRef.current)?.focus();
        }
    }, [feedbackThanks, feedbackThanksRef]);

    // Hide feedback "thanks" message after a few seconds.
    // Timer for hiding feedback "thanks" message.
    const feedbackThanksTimer = useTimer({
        time: 6000,
        onTimerEnd: () => setFeedbackThanks(null),
    });

    // Disable toolbar buttons till evaluation completes
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});
    const isEvaluationComplete = evaluation?.state === EvaluationState.Completed;

    // handle feedback submission
    const handleFeedbackSubmit = useCallback(() => {
        // Gather selected feedback subtypes, falling back to the feedback type.
        if (!feedback || !feedbackType) {
            return;
        }

        const content = feedbackTextarea.current?.value?.trim();
        const subtypes: Array<EvaluationFeedbackSubType> = Array.from(
            feedbackSubtypes.current?.querySelectorAll('button[aria-pressed=true]') ?? [],
        )
            .map((el) => el.getAttribute('value') as EvaluationFeedbackSubType)
            .filter((value) => value !== null);

        // Update the feedback along with subtypes and content.
        updateFeedback(
            {
                sessionId,
                promptId,
                evaluationId,
                feedbackId: feedback.feedbackId,
                feedbackType: feedbackType,
                feedbackSubTypes: subtypes,
                content: content || '',
            },
            {
                onSuccess: () => {
                    setShowFeedbackForm(false);
                    setFeedbackThanks(
                        getRandomElementFromArray<FeedbackThanksMessage>(feedbackMap[feedbackType]),
                    );
                    feedbackThanksTimer();
                },
            },
        );
    }, [feedback, feedbackType, updateFeedback]);

    return (
        <>
            <div className={classes.toolbar}>
                <span ref={tourRef} className={classes.feedbackTypes} data-testid="feedbackTypes">
                    <ToggleButton
                        className={classes.feedbackConfirmBtn}
                        checked={feedbackType === EvaluationFeedbackType.Confirm}
                        shape="circular"
                        data-testid="feedbackTypes-confirm"
                        appearance="subtle"
                        disabled={!isEvaluationComplete}
                        icon={
                            <HelpfulFeedbackIcon
                                filled={feedbackType === EvaluationFeedbackType.Confirm}
                            />
                        }
                        onClick={() => {
                            if (feedbackType !== EvaluationFeedbackType.Confirm) {
                                setFeedbackThanks(null);
                                setFeedbackType(EvaluationFeedbackType.Confirm);
                                setShowFeedbackForm(true);
                                createFeedback({
                                    sessionId,
                                    promptId,
                                    evaluationId,
                                    feedbackType: EvaluationFeedbackType.Confirm,
                                    feedbackSubTypes: [],
                                    content: '',
                                });
                            }
                        }}
                        {...restoreFocusTargetAttribute}
                    >
                        Looks right
                    </ToggleButton>
                    <ToggleButton
                        className={classes.feedbackOffTargetBtn}
                        checked={feedbackType === EvaluationFeedbackType.OffTarget}
                        appearance="subtle"
                        shape="circular"
                        data-testid="feedbackTypes-offTarget"
                        ref={offtargetTourRef}
                        disabled={!isEvaluationComplete}
                        icon={
                            <UnhelpfulFeedbackIcon
                                filled={feedbackType === EvaluationFeedbackType.OffTarget}
                            />
                        }
                        onClick={() => {
                            if (feedbackType !== EvaluationFeedbackType.OffTarget) {
                                setFeedbackThanks(null);
                                setFeedbackType(EvaluationFeedbackType.OffTarget);
                                setShowFeedbackForm(true);
                                createFeedback({
                                    sessionId,
                                    promptId,
                                    evaluationId,
                                    feedbackType: EvaluationFeedbackType.OffTarget,
                                    feedbackSubTypes: [],
                                    content: '',
                                });
                            }
                        }}
                        {...restoreFocusTargetAttribute}
                    >
                        Needs improvement
                    </ToggleButton>
                    <ToggleButton
                        className={classes.feedbackReportBtn}
                        checked={feedbackType === EvaluationFeedbackType.Report}
                        appearance="subtle"
                        shape="circular"
                        data-testid="feedbackTypes-report"
                        disabled={!isEvaluationComplete}
                        icon={
                            <ReportFeedbackIcon
                                filled={feedbackType === EvaluationFeedbackType.Report}
                            />
                        }
                        onClick={() => {
                            if (feedbackType !== EvaluationFeedbackType.Report) {
                                setFeedbackThanks(null);
                                setFeedbackType(EvaluationFeedbackType.Report);
                                setShowFeedbackForm(true);
                                createFeedback({
                                    sessionId,
                                    promptId,
                                    evaluationId,
                                    feedbackType: EvaluationFeedbackType.Report,
                                    feedbackSubTypes: [],
                                    content: '',
                                });
                            }
                        }}
                        {...restoreFocusTargetAttribute}
                    >
                        Inappropriate
                    </ToggleButton>
                </span>
                <span className={classes.rightActions} data-testid="PinnedPrompt">
                    <ExportEvaluator
                        {...props}
                        isDisabled={!isEvaluationComplete}
                        isSummaryExport={false}
                    />
                </span>
            </div>
            {showFeedbackForm && feedbackType && (
                // Insert FeedbackForm back here
                <EvaluationFeedbackForm
                    feedbackType={feedbackType}
                    setShowFeedbackForm={setShowFeedbackForm}
                    feedbackSubtypesRef={feedbackSubtypes}
                    feedbackTextareaRef={feedbackTextarea}
                    handleCreateFeedback={handleFeedbackSubmit}
                />
            )}
            {feedbackThanks && (
                <div className={classes.feedbackThanks} ref={feedbackThanksRef}>
                    <ThanksImage className={classes.thanksImage} />
                    {feedbackThanks?.text}
                    <Button
                        icon={<DismissIcon />}
                        appearance="subtle"
                        className={classes.feedbackDismiss}
                        onClick={() => setFeedbackThanks(null)}
                        //Focus back to selected category on dismiss of feedback thanks
                        {...restoreFocusSourceAttribute}
                    />
                </div>
            )}
        </>
    );
}
