import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserProfile} from './user.types';

export default function useGetUserProfiles(name: string | undefined) {
    const {customFetch} = useFetch();

    const fetchProfiles = async () => {
        if (name) {
            return await customFetch<UserProfile[]>(`/users?name=${name}`, {
                method: 'GET',
            });
        }
        // Return an empty result if name is empty
        return [];
    };

    return useQuery({
        queryKey: ['usersFilterList', name],
        queryFn: fetchProfiles,
    });
}
