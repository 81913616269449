import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    dropdownTriggerBtn: {
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground1,
        fontWeight: tokens.fontWeightRegular,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius('18px'),
        marginRight: tokens.spacingHorizontalS,
    },
    menuButton: {
        ...shorthands.padding(
            tokens.spacingVerticalSNudge,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalSNudge,
            tokens.spacingHorizontalM,
        ),
        maxWidth: '200px',
        textOverflow: 'ellipsis',
    },
    listOverflow: {
        maxHeight: '50vh',
        overflow: 'auto',
    },
    nestedMenuContent: {
        ...shorthands.padding(tokens.spacingVerticalSNudge, tokens.spacingHorizontalNone),
    },
    dismissButton: {
        ...shorthands.padding(tokens.spacingVerticalSNudge, tokens.spacingHorizontalNone),
    },
    moreFiltersButton: {
        fontWeight: tokens.fontWeightRegular,
        marginRight: tokens.spacingHorizontalS,
    },
    menuItem: {
        '& > span:first-of-type': {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    ellipsisText: {
        display: 'inline-block',
        maxWidth: '200px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});
