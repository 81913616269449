import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {evaluationResultProperties} from './Evaluation.styles';

export default makeStyles({
    root: {},
    prompt: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius('6px'),
        ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalL),
        ...shorthands.margin('0px', '0px', tokens.spacingVerticalXL, '0px'),
        fontSize: tokens.fontSizeBase400,
    },
    content: {
        color: tokens.colorNeutralForeground3,
        marginBottom: tokens.spacingVerticalXXXL,
    },
    sources: {},
    caption: {
        color: tokens.colorNeutralForeground2,
        fontWeight: tokens.fontWeightSemibold,
    },
});
