import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'repeat(1fr)',
        justifyItems: 'start',
        ...shorthands.gap(tokens.spacingVerticalXXS),
        maxWidth: '500px',
    },
    tagsList: {
        listStyleType: 'none',
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: tokens.spacingVerticalNone,
        paddingLeft: tokens.spacingHorizontalNone,
        display: 'flex',
        flexWrap: 'wrap',
        gridGap: tokens.spacingHorizontalXXS,
    },
    displayText: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalMNudge,
        fontFamily: tokens.fontFamilyBase,
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: '42px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: tokens.colorNeutralForeground3,
    },
    loader: {
        display: 'flex',
        flexDirection: 'column',
        height: '1vh',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalXS),
        ...shorthands.margin(
            tokens.lineHeightBase200,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase200,
        ),
    },
});
