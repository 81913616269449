import * as React from 'react';
const RacingCar = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path
                fill="url(#f2046idl)"
                d="M20.059 24.035h-.994v-1.988c0-.547.447-.994.994-.994v2.982Z"
            />
            <path fill="#EE3124" d="M28.012 24.035h-8.947v2.983h8.947v-2.983Z" />
            <path fill="#EE3124" d="M28.012 24.035H13.1v2.983h14.912v-2.983Z" />
            <path fill="url(#f2046idm)" d="M28.012 24.035H13.1v2.983h14.912v-2.983Z" />
            <path
                fill="url(#f2046id0)"
                d="M14.094 23.041c-2.614 0-5.19.607-7.535 1.78l-4.394 2.197h14.911v-2.983l-.994-.994h-1.988Z"
            />
            <path
                fill="url(#f2046id1)"
                fillRule="evenodd"
                d="M16.086 27.018v-3.974l.99.991v2.983h-.99Z"
                clipRule="evenodd"
            />
            <path fill="url(#f2046id2)" d="M28.012 25.03H6.142l-1.989.993h23.859v-.994Z" />
            <path fill="url(#f2046id3)" d="M28.012 25.03H6.142l-1.989.993h23.859v-.994Z" />
            <path fill="url(#f2046idn)" d="M26.024 26.498H30v-6.462h-3.976v6.462Z" />
            <path fill="url(#f2046id4)" d="M26.024 22.047H30v-.994h-3.976v.994Z" />
            <path fill="url(#f2046id5)" d="M26.024 22.047H30v-.994h-3.976v.994Z" />
            <path fill="url(#f2046id6)" d="M26.024 20.059H30v-.994h-3.976v.994Z" />
            <path fill="url(#f2046id7)" d="M26.024 20.059H30v-.994h-3.976v.994Z" />
            <path fill="url(#f2046id8)" d="M26.024 20.059H30v-.994h-3.976v.994Z" />
            <path fill="#A0393E" d="M28.012 27.018H18.07v1.988h9.94v-1.988Z" />
            <path fill="url(#f2046ido)" d="M28.012 27.018H18.07v1.988h9.94v-1.988Z" />
            <path fill="url(#f2046id9)" d="M28.012 27.018H18.07v1.988h9.94v-1.988Z" />
            <path fill="url(#f2046ida)" d="M19.808 27.018H2.165v1.988h17.643v-1.988Z" />
            <path fill="url(#f2046idb)" d="M19.808 27.018H2.165v1.988h17.643v-1.988Z" />
            <path fill="url(#f2046idc)" d="M19.808 27.018H2.165v1.988h17.643v-1.988Z" />
            <path fill="url(#f2046idp)" d="M19.808 27.018H2.165v1.988h17.643v-1.988Z" />
            <path fill="url(#f2046idd)" d="M19.808 27.018H2.165v1.988h17.643v-1.988Z" />
            <path
                fill="url(#f2046idq)"
                d="M26.024 24.035h-5.965v-2.982c1.909 0 3.738.755 5.09 2.107l.875.875Z"
            />
            <path
                fill="url(#f2046idr)"
                d="M20.556 29.006h-2.485v-2.983h2.485a1.49 1.49 0 0 1 1.491 1.492a1.49 1.49 0 0 1-1.491 1.49Z"
            />
            <path
                fill="url(#f2046ids)"
                d="M20.556 29.006h-2.485v-2.983h2.485a1.49 1.49 0 0 1 1.491 1.492a1.49 1.49 0 0 1-1.491 1.49Z"
            />
            <path
                fill="url(#f2046ide)"
                d="M20.556 29.006h-2.485v-2.983h2.485a1.49 1.49 0 0 1 1.491 1.492a1.49 1.49 0 0 1-1.491 1.49Z"
            />
            <path fill="url(#f2046idf)" d="M18.07 26.023h-.994v2.983h.995v-2.983Z" />
            <path fill="url(#f2046idg)" d="M18.07 26.023h-.994v2.983h.995v-2.983Z" />
            <g filter="url(#f2046idz)">
                <path
                    fill="url(#f2046idh)"
                    d="M8.627 30a3.48 3.48 0 1 0 0-6.959a3.48 3.48 0 0 0 0 6.959Z"
                />
                <path
                    fill="url(#f2046idt)"
                    d="M8.627 30a3.48 3.48 0 1 0 0-6.959a3.48 3.48 0 0 0 0 6.959Z"
                />
                <path
                    fill="url(#f2046idu)"
                    d="M8.627 30a3.48 3.48 0 1 0 0-6.959a3.48 3.48 0 0 0 0 6.959Z"
                />
            </g>
            <g filter="url(#f2046id10)">
                <path
                    fill="#D9D2E8"
                    d="M8.627 27.806a1.286 1.286 0 1 0 0-2.571a1.286 1.286 0 0 0 0 2.571Z"
                />
            </g>
            <path
                fill="url(#f2046idv)"
                fillRule="evenodd"
                d="m23.28 21.813l-2.226 2.222h4.97l-.875-.875a7.193 7.193 0 0 0-1.87-1.347Z"
                clipRule="evenodd"
            />
            <path
                fill="url(#f2046idw)"
                fillRule="evenodd"
                d="m23.28 21.813l-2.226 2.222h4.97l-.875-.875a7.193 7.193 0 0 0-1.87-1.347Z"
                clipRule="evenodd"
            />
            <g filter="url(#f2046id11)">
                <path
                    fill="url(#f2046idi)"
                    d="M26.52 30a3.48 3.48 0 1 0 0-6.959a3.48 3.48 0 0 0 0 6.959Z"
                />
                <path
                    fill="url(#f2046idx)"
                    d="M26.52 30a3.48 3.48 0 1 0 0-6.959a3.48 3.48 0 0 0 0 6.959Z"
                />
                <path
                    fill="url(#f2046idy)"
                    d="M26.52 30a3.48 3.48 0 1 0 0-6.959a3.48 3.48 0 0 0 0 6.959Z"
                />
            </g>
            <g filter="url(#f2046id12)">
                <path
                    fill="#D2CBE1"
                    d="M26.52 27.806a1.286 1.286 0 1 0 0-2.571a1.286 1.286 0 0 0 0 2.571Z"
                />
            </g>
            <g filter="url(#f2046id13)">
                <circle cx="8.923" cy="26.37" r="2.334" stroke="url(#f2046idj)" />
            </g>
            <g filter="url(#f2046id14)">
                <circle cx="26.798" cy="26.37" r="2.334" stroke="url(#f2046idk)" />
            </g>
            <defs>
                <linearGradient
                    id="f2046id0"
                    x1="4.054"
                    x2="17.076"
                    y1="27.018"
                    y2="27.018"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C03131" />
                    <stop offset="1" stopColor="#FC443F" />
                </linearGradient>
                <linearGradient
                    id="f2046id1"
                    x1="16.581"
                    x2="16.581"
                    y1="23.535"
                    y2="27.018"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF5752" />
                    <stop offset="1" stopColor="#FF5752" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046id2"
                    x1="8.324"
                    x2="18.72"
                    y1="25.531"
                    y2="25.531"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C5C5C7" />
                    <stop offset="1" stopColor="#F0EBED" />
                </linearGradient>
                <linearGradient
                    id="f2046id3"
                    x1="23.652"
                    x2="22.027"
                    y1="25.767"
                    y2="25.767"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B9B3BA" />
                    <stop offset="1" stopColor="#B9B3BA" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046id4"
                    x1="26.021"
                    x2="29.997"
                    y1="21.555"
                    y2="21.555"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E4AA2D" />
                    <stop offset="1" stopColor="#FF8213" />
                    <stop offset="1" stopColor="#F38320" />
                </linearGradient>
                <linearGradient
                    id="f2046id5"
                    x1="25.705"
                    x2="26.775"
                    y1="21.734"
                    y2="21.734"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B58B2C" />
                    <stop offset="1" stopColor="#B58B2C" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046id6"
                    x1="26.024"
                    x2="30"
                    y1="19.562"
                    y2="19.562"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B9AAB5" />
                    <stop offset=".693" stopColor="#D8D4E0" />
                    <stop offset="1" stopColor="#C7C5CC" />
                </linearGradient>
                <linearGradient
                    id="f2046id7"
                    x1="25.918"
                    x2="26.847"
                    y1="20.059"
                    y2="20.059"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#94858A" />
                    <stop offset="1" stopColor="#94858A" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046id8"
                    x1="30.136"
                    x2="29.855"
                    y1="20.059"
                    y2="20.059"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#94858A" />
                    <stop offset="1" stopColor="#94858A" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046id9"
                    x1="23.927"
                    x2="23.927"
                    y1="27.018"
                    y2="27.11"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B11B27" />
                    <stop offset="1" stopColor="#B11B27" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046ida"
                    x1="3.401"
                    x2="20.428"
                    y1="29.006"
                    y2="29.006"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AB3138" />
                    <stop offset="1" stopColor="#C8494E" />
                </linearGradient>
                <linearGradient
                    id="f2046idb"
                    x1="16.808"
                    x2="15.134"
                    y1="29.423"
                    y2="28.848"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#722026" />
                    <stop offset="1" stopColor="#722026" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046idc"
                    x1="15.183"
                    x2="15.183"
                    y1="29.485"
                    y2="28.204"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#722026" />
                    <stop offset="1" stopColor="#722026" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046idd"
                    x1="12.558"
                    x2="12.558"
                    y1="27.018"
                    y2="27.11"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BF212E" />
                    <stop offset="1" stopColor="#BF212E" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046ide"
                    x1="19.058"
                    x2="19.058"
                    y1="29.954"
                    y2="28.173"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#97594D" />
                    <stop offset=".93" stopColor="#97594D" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046idf"
                    x1="17.574"
                    x2="17.574"
                    y1="26.023"
                    y2="27.515"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#626162" />
                    <stop offset="1" stopColor="#646367" />
                </linearGradient>
                <linearGradient
                    id="f2046idg"
                    x1="18.071"
                    x2="18.071"
                    y1="29.006"
                    y2="26.845"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2B1B41" />
                    <stop offset="1" stopColor="#2B1B41" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="f2046idh"
                    x1="7.022"
                    x2="12.248"
                    y1="25.646"
                    y2="25.646"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".239" stopColor="#34214F" />
                    <stop offset="1" stopColor="#241337" />
                </linearGradient>
                <linearGradient
                    id="f2046idi"
                    x1="24.916"
                    x2="30.142"
                    y1="25.646"
                    y2="25.646"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".239" stopColor="#34214F" />
                    <stop offset="1" stopColor="#241337" />
                </linearGradient>
                <linearGradient
                    id="f2046idj"
                    x1="10.666"
                    x2="7.145"
                    y1="24.035"
                    y2="28.191"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".083" stopColor="#615B6B" />
                    <stop offset=".391" stopColor="#615B6B" stopOpacity="0" />
                    <stop offset=".692" stopColor="#615B6B" stopOpacity="0" />
                    <stop offset=".935" stopColor="#615B6B" />
                </linearGradient>
                <linearGradient
                    id="f2046idk"
                    x1="28.541"
                    x2="25.02"
                    y1="24.035"
                    y2="28.191"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".083" stopColor="#615B6B" />
                    <stop offset=".391" stopColor="#615B6B" stopOpacity="0" />
                    <stop offset=".692" stopColor="#615B6B" stopOpacity="0" />
                    <stop offset=".935" stopColor="#615B6B" />
                </linearGradient>
                <radialGradient
                    id="f2046idl"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(0 2.67188 -2.24513 0 19.965 21.767)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#727273" />
                    <stop offset="1" stopColor="#4E2A2A" />
                </radialGradient>
                <radialGradient
                    id="f2046idm"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-1.0625 0 0 -2.18104 23.37 27.018)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".155" stopColor="#AA2E30" />
                    <stop offset="1" stopColor="#AA2E30" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2046idn"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(.14952 -4.78674 11.96636 .37378 28.012 23.267)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED4C48" />
                    <stop offset="1" stopColor="#B5312D" />
                </radialGradient>
                <radialGradient
                    id="f2046ido"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-2.03125 0 0 -2.78041 24.37 28.012)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".429" stopColor="#620B25" />
                    <stop offset="1" stopColor="#620B25" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2046idp"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-3.09375 .50496 -.35787 -2.19258 7.214 28.012)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#722026" />
                    <stop offset="1" stopColor="#722026" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2046idq"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-68.122 28.102 -3.256) scale(3.52226 7.52437)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF5A56" />
                    <stop offset="1" stopColor="#FF4940" />
                </radialGradient>
                <radialGradient
                    id="f2046idr"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(3.6139 0 0 2.4375 18.433 26.267)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE042" />
                    <stop offset="1" stopColor="#DC8B38" />
                </radialGradient>
                <radialGradient
                    id="f2046ids"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(3.73755 0 0 3.11637 18.07 27.515)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".749" stopColor="#E57A14" stopOpacity="0" />
                    <stop offset="1" stopColor="#E57A14" />
                </radialGradient>
                <radialGradient
                    id="f2046idt"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(.78617 -1.72802 1.80486 .82113 8.627 26.21)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".523" stopColor="#2D114D" />
                    <stop offset="1" stopColor="#2B1249" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2046idu"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(.6425 .42504 -.4776 .72196 9.576 26.761)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392A49" />
                    <stop offset="1" stopColor="#392A49" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2046idv"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(1.875 1 -.64922 1.2173 22.074 22.595)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F8F2F5" />
                    <stop offset="1" stopColor="#F2DEE2" />
                </radialGradient>
                <radialGradient
                    id="f2046idw"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-52.409 36.507 -11.31) scale(2.48457 4.389)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".688" stopColor="#C8BBBA" stopOpacity="0" />
                    <stop offset="1" stopColor="#C8BBBA" />
                </radialGradient>
                <radialGradient
                    id="f2046idx"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(.78617 -1.72802 1.80486 .82113 26.52 26.21)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".523" stopColor="#2D114D" />
                    <stop offset="1" stopColor="#2B1249" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="f2046idy"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(.6425 .42504 -.4776 .72196 27.47 26.761)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#392A49" />
                    <stop offset="1" stopColor="#392A49" stopOpacity="0" />
                </radialGradient>
                <filter
                    id="f2046idz"
                    width="6.959"
                    height="7.109"
                    x="5.147"
                    y="23.041"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation=".5" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.145098 0 0 0 0 0.0823529 0 0 0 0 0.215686 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_10979" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy=".15" />
                    <feGaussianBlur stdDeviation=".125" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.207843 0 0 0 0 0.0313726 0 0 0 0 0.235294 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_10979"
                        result="effect2_innerShadow_18_10979"
                    />
                </filter>
                <filter
                    id="f2046id10"
                    width="2.671"
                    height="2.671"
                    x="7.291"
                    y="25.185"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".05" dy="-.05" />
                    <feGaussianBlur stdDeviation=".05" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.478431 0 0 0 0 0.364706 0 0 0 0 0.584314 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_10979" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-.05" dy=".05" />
                    <feGaussianBlur stdDeviation=".05" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.807843 0 0 0 0 0.764706 0 0 0 0 0.858824 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_10979"
                        result="effect2_innerShadow_18_10979"
                    />
                </filter>
                <filter
                    id="f2046id11"
                    width="6.959"
                    height="7.109"
                    x="23.041"
                    y="23.041"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation=".5" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.145098 0 0 0 0 0.0823529 0 0 0 0 0.215686 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_10979" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy=".15" />
                    <feGaussianBlur stdDeviation=".125" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.207843 0 0 0 0 0.0313726 0 0 0 0 0.235294 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_10979"
                        result="effect2_innerShadow_18_10979"
                    />
                </filter>
                <filter
                    id="f2046id12"
                    width="2.671"
                    height="2.671"
                    x="25.185"
                    y="25.185"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx=".05" dy="-.05" />
                    <feGaussianBlur stdDeviation=".05" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.478431 0 0 0 0 0.364706 0 0 0 0 0.584314 0 0 0 1 0" />
                    <feBlend in2="shape" result="effect1_innerShadow_18_10979" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-.05" dy=".05" />
                    <feGaussianBlur stdDeviation=".05" />
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix values="0 0 0 0 0.807843 0 0 0 0 0.764706 0 0 0 0 0.858824 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_innerShadow_18_10979"
                        result="effect2_innerShadow_18_10979"
                    />
                </filter>
                <filter
                    id="f2046id13"
                    width="7.169"
                    height="7.169"
                    x="5.339"
                    y="22.785"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_10979" stdDeviation=".375" />
                </filter>
                <filter
                    id="f2046id14"
                    width="7.169"
                    height="7.169"
                    x="23.214"
                    y="22.785"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur_18_10979" stdDeviation=".375" />
                </filter>
            </defs>
        </g>
    </svg>
);
export default RacingCar;
