import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    centerGradient: {
        position: 'absolute',
        width: '1746px',
        height: '1322px',
        bottom: '-900px',
    },
    leftGradient: {
        position: 'absolute',
        width: '1761px',
        height: '1458px',
        bottom: '-900px',
    },
    rightGradient: {
        position: 'absolute',
        width: '1855px',
        height: '1142px',
        bottom: '-700px',
    },
});
