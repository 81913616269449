import {useQueries} from '@tanstack/react-query';
import {GetEvaluationSkillInvocationsRequest, EvaluationSkillInvocation} from './evaluations.types';
import useFetch from '../useFetch';
import {PageResponse} from '../api.types';

export default function useGetEvaluationSkillInvocations({
    sessionId,
    promptIds,
    evaluationIds,
    enabled = false,
}: GetEvaluationSkillInvocationsRequest) {
    const {customFetch} = useFetch();
    let queries = promptIds.map((promptId: string, index) => {
        const evaluationId = evaluationIds[index]; // get the corresponding evaluationId

        return {
            queryKey: [
                'sessions',
                sessionId,
                'prompts',
                promptId,
                'evaluations',
                evaluationId,
                'skillinvocations',
            ],
            queryFn: async () => {
                return await customFetch<PageResponse<EvaluationSkillInvocation>>(
                    `/sessions/${sessionId}/prompts/${promptId}/evaluations/${evaluationId}/skillinvocations`,
                    {
                        method: 'GET',
                    },
                );
            },
            enabled: enabled,
        };
    });

    return useQueries({
        queries: queries,
    });
}
