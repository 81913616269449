import {MedeinaRouteConfiguration} from './../MedeinaRoutes';
import {useTranslation} from 'react-i18next';
import {pathBreadcrumbDisplay} from './Breadcrumbs.constants';
import i18n from '../../i18n';

// a utility to get the parameters from a path string based on the path template
export function getParamsFromPath(
    pathTemplate: string,
    path: string,
): Record<string, string> | null {
    const pathTemplateSegments = getPathSegments(pathTemplate);
    const pathSegments = getPathSegments(path);

    const params: Record<string, string> = {};

    for (let i = 0; i < pathTemplateSegments.length; i++) {
        const pathTemplateSegment = pathTemplateSegments[i];
        const pathSegment = pathSegments[i];

        if (pathTemplateSegment.startsWith(':')) {
            const paramName = pathTemplateSegment.slice(1);
            params[paramName] = pathSegment;
        } else if (pathTemplateSegment !== pathSegment) {
            return null;
        }
    }
    return params;
}

export function getLastPathDisplay(pathTemplate: string, path: any, segment: string, link: string) {
    // grab the corresponding display information if it exists
    const display = path ? pathBreadcrumbDisplay[pathTemplate] : undefined;
    // if nothing matched default to the path string
    if (!display) {
        return segment;
    }
    // if there is a display value generate a parameters object to pass into the custom function
    const params = display ? getParamsFromPath(pathTemplate, path) : {};

    // some paths are functions that expect url params
    if (typeof display === 'function') {
        return display(params);
    }

    if (typeof display === 'string') {
        return i18n.t(display);
    }
}

export function getDisplayName(segment: string) {
    const displayName = pathBreadcrumbDisplay['/' + segment];
    if (displayName) {
        // Otherwise, return the displayName
        return i18n.t(displayName);
    }

    // If we didn't find a matching route, just return the original segment
    return segment;
}

export function DisplayName(segment: string) {
    const {t} = useTranslation('common');
    const displayName = pathBreadcrumbDisplay['/' + segment];
    if (displayName) {
        // Otherwise, return the displayName
        return t(displayName);
    }

    // If we didn't find a matching route, just return the original segment
    return segment;
}

export function getPathSegments(path: string) {
    if (!path) return [];
    return path.split('/').filter((segment) => segment !== '');
}

export function getPathsWithParameters(routeConfig: Array<any>) {
    return routeConfig.filter((route) => route.path.includes(':'));
}

export function findMatchingRoute(
    pathname: string,
    pathSegments: Array<any>,
    parametizedRoutes: Array<any>,
) {
    const firstSegment = pathSegments[0]; // get the first segment of the pathname
    // find any parametizedRoutes objects where the path property starts with a forward slash and the first segment of the pathname
    const matchingRoutes = parametizedRoutes.filter((route) => {
        // get the total number of segments in the pathname
        const routeTotalSegments = getPathSegments(route.path).length;
        return (
            route.path.startsWith('/' + firstSegment) && routeTotalSegments === pathSegments.length
        );
    });

    return matchingRoutes || [];
}

export function getMatchingScore(url: string, routeTemplate: string): number {
    const urlSegments = url.split('/').filter((segment) => segment !== '');
    const routeSegments = routeTemplate.split('/').filter((segment) => segment !== '');
    // if the total number of segments from the url is greater than the route template we can return early
    if (urlSegments.length > routeSegments.length) {
        return 0;
    }
    let score = 0;
    for (let i = 0; i < routeSegments.length; i++) {
        const urlSegment = urlSegments[i];
        const routeSegment = routeSegments[i];
        if (routeSegment.startsWith(':')) {
            // Parameter segment in route template
            if (routeSegment.endsWith('?') && urlSegment === undefined) {
                // Optional parameter segment in route template with no corresponding segment in URL
                continue;
            }
            score += 1;
        } else if (urlSegment === routeSegment) {
            score += 2;
        } else if (urlSegment === undefined && routeSegment.endsWith('?')) {
            // Optional static segment in route template with no corresponding segment in URL
            continue;
        } else {
            return 0;
        }
    }

    return score;
}

// a utility to find a matching route configuration based on the pathname
// takes into account the total number of segments in the path and any parameters
// also leverages our scoreRoute system to find the best match
export function findMatchingRouteConfiguration(
    pathname: string,
    routeConfig: Array<MedeinaRouteConfiguration>,
): MedeinaRouteConfiguration | null {
    let matchingRoutes = [];
    const pathSegments = getPathSegments(pathname);
    // if the pathname is '/' we can return early
    if (pathname === '/') {
        return routeConfig.find((route) => route.path === '/') || null;
    }

    // filter down to the routes that match the first segment of the pathname
    matchingRoutes = routeConfig.filter((route) => route.path.startsWith('/' + pathSegments[0]));

    // if we already matched and there's only one route we can return early
    if (matchingRoutes.length === 1) {
        return matchingRoutes[0];
    }

    // we still have multiple routes that may match, lets sort them based on which match the larger number of segments
    const scoredRoutes = matchingRoutes
        .map((route) => {
            return {
                route,
                score: getMatchingScore(pathname, route.path),
            };
        })
        .sort((a, b) => b.score - a.score);

    // if we've matched more than one item we are probably on a path that has sub path with or without parameters
    // filter down to the routes that match the total number of segments in the pathname
    // and the segments that are parameters should be in the right place
    const filteredMatchingRoutes = scoredRoutes.filter((scoredRoute) => {
        const {route, score} = scoredRoute;
        // get the total number of segments in the pathname
        const routeConfigTotalSegments = getPathSegments(route.path).length;
        const routeConfigTotalRequiredSegments = getPathSegments(route.path).filter(
            (segment: string) => !segment.endsWith('?'),
        ).length;

        // our route config will have hard coded strings that we want to check with our pathname
        const routeConfigSegments = getPathSegments(route.path);
        // test the number of segments in the pathname against the route config
        // if the current path has less segments than those REQUIRED by the config OR
        // if the current path has more segments than the config allows we can return early with null
        if (
            routeConfigTotalRequiredSegments > pathSegments.length ||
            routeConfigTotalSegments < pathSegments.length
        ) {
            return null;
        }

        // get the indexes of the segments that are parameters
        // we want to check if the parameters are in the right place
        // but we also need to know if any of those are optional
        const paramIndexes = routeConfigSegments
            .map((segment, index) => (segment.startsWith(':') ? index : null))
            .filter((index) => index !== null);

        // if we have no parameters in our route config we can just check if the segments match
        if (paramIndexes.length === 0) {
            return routeConfigTotalSegments === pathSegments.length;
        }

        // if we have parameters we need to check if the segments match the known segments and the total number of segments match
        // check if the segments match the known segments
        // check if the parameters are in the right place if they are not optional
        return routeConfigSegments.every((segment, index) => {
            if (segment.startsWith(':')) {
                // if the segment is optional we can skip it
                if (segment.endsWith('?')) {
                    return true;
                }
                return paramIndexes.includes(index);
            }
            return segment === pathSegments[index];
        });
    });

    return filteredMatchingRoutes[0]?.route || null;
}
