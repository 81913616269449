import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserPreferences} from './user.types';

export default function useGetUserPreferences(options?: UseQueryOptions<UserPreferences>) {
    const {customFetch} = useFetch();

    const fetchPreferences = async () => {
        try {
            return await customFetch<UserPreferences>(`/userPreferences`, {
                method: 'GET',
            });
        } catch (error) {
            throw error;
        }
    };

    return useQuery({
        queryFn: fetchPreferences,
        queryKey: ['userPreferences'],
        // we only want to fetch the user preferences once per session
        // so we set staleTime to Infinity
        staleTime: Infinity,
        // don't refetch the user preferences when the window regains focus
        refetchOnWindowFocus: false,
        // don't refetch the user preferences when the user is idle
        refetchOnMount: false,
    });
}
