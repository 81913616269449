import {Components} from 'react-markdown';
import {Display, LargeTitle, Title1, Title2, Title3, Body1, Link} from '@fluentui/react-components';
import CodeBlock from './CodeBlock';
import MarkdownH1 from './h1';
import MarkdownH2 from './h2';
import MarkdownH3 from './h3';
import MarkdownH4 from './h4';
import MarkdownH5 from './h5';
import MarkdownH6 from './h6';
import MarkdownBody from './body';
import Table from './Table/Table';
import {TableRow} from '../Markdown/Table/TableRow';
import MarkdownListItem from './MarkdownListItem';

const components: Components = {
    code({children, className, ...props}) {
        return (
            <CodeBlock className={className} {...props}>
                {children}
            </CodeBlock>
        );
    },
    h1({children}) {
        return <MarkdownH1>{children}</MarkdownH1>;
    },
    h2({children}) {
        return <MarkdownH2>{children}</MarkdownH2>;
    },
    h3({children}) {
        return <MarkdownH3>{children}</MarkdownH3>;
    },
    h4({children}) {
        return <MarkdownH4>{children}</MarkdownH4>;
    },
    h5({children}) {
        return <MarkdownH5>{children}</MarkdownH5>;
    },
    h6({children}) {
        return <MarkdownH6>{children}</MarkdownH6>;
    },
    p({children}) {
        return <MarkdownBody>{children}</MarkdownBody>;
    },
    li({children}) {
        return (
            <li>
                <MarkdownListItem>{children}</MarkdownListItem>
            </li>
        );
    },
    a({children, href}) {
        return (
            <Link href={href ?? '#'} rel="noopener noreferrer nofollow" target="_blank">
                {children}
            </Link>
        );
    },
    table(props) {
        return <Table {...props}></Table>;
    },
    tr(props) {
        return <TableRow {...props} />;
    },
};

export default components;
