import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    icon: {
        '& > svg.fui-Icon-regular': {
            height: '28px',
            width: '28px',
            '@media (forced-colors: active)': {
                backgroundColor: 'Canvas',
            },
        },

        marginBottom: '42px',
    },
    listBoxHeight: {
        maxHeight: '200px',
        height: 'auto',
    },
});
