import {
    Text,
    Button,
    DialogContent,
    mergeClasses,
    useRestoreFocusTarget,
    ToolbarDivider,
    Field,
    Input,
} from '@fluentui/react-components';
import useClasses from './Knowledge.styles';
import {useCallback, useRef, useState} from 'react';
import {CancelledIcon, DismissIcon, ErrorIcon, SearchIcon} from '../icons';
import {ArrowUpload24Filled} from '@fluentui/react-icons';
import {FileUploadRequest, useGetAllFiles, useUploadFile} from '@/api/files';
import FilesSection from './ConnectorModalContent/Files/FilesSection';
import {MedeinaInfoLabel} from '../Buttons/MedeinaInfoLabel';
import {useTranslation} from 'react-i18next';
import useFileSearch from './useFileSearch';
import {MessageBar, MessageBarType} from '@fluentui/react';
import Preview from '../Badges/Preview';
import FileUsageMetrics from './FileUsageMetrics';
import useGetFileUsage from '@/api/files/useGetFileUsage';
import {useGetTenantInfo} from '@/api/tenant';

export default function Knowledge() {
    const classes = useClasses();
    const {mutateAsync: sendData, isLoading, isError, isSuccess} = useUploadFile();
    const fileInputRef = useRef<HTMLInputElement>(null); // Specifies the type for useRef
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [reloadDependency, setReloadDependency] = useState(false);
    const {t} = useTranslation('plugins');
    const {
        data: files,
        isLoading: filesLoading,
        isError: getAllFilesFailed,
    } = useGetAllFiles(reloadDependency);

    const {
        data: fileUsage,
        isLoading: fileUsageLoading,
        isError: getFileUsageFailed,
    } = useGetFileUsage();

    // Tenant Info is used to determine if the user is allowed to upload files
    const {data: tenantInfo} = useGetTenantInfo();

    // Map error codes to localized error strings
    const errorCodesMap: Record<string, string> = {
        FileSizeExceedsLimit: t('FilesTab.FileSizeExceedsLimit'),
        FileExtensionNotAllowed: t('FilesTab.FileExtensionNotAllowed'),
        UserTotalStorageLimitExceeded: t('FilesTab.UserTotalStorageLimitExceeded'),
        InvalidFileName: t('FilesTab.InvalidFileName'),
        FileNameAlreadyExists: t('FilesTab.FileNameAlreadyExists'),
    };

    const [searchText, setSearchText] = useState('');

    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
    };
    // Dismiss error message for file upload
    const handleDismissError = (index: number) => {
        setErrorMessages(errorMessages.filter((_, i) => i !== index));
    };
    const filteredFiles = useFileSearch(files?.value ?? [], searchText);
    const getSearchResultsAriaLiveText = useCallback((): string => {
        // If no search term is entered, do not announce search results.
        if (searchText == '') {
            return '';
        }

        // If a search term is entered, announce the number of results found.
        if (filteredFiles.length == 1) {
            return t('SearchResultSingle');
        } else {
            return t('SearchResultMultiple', {count: filteredFiles.length});
        }
    }, [searchText, filteredFiles.length]);

    // Handle file change event by uploading the file
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // clear error messages
        if (event.target.files && event.target.files.length > 0) {
            setErrorMessages([]);
            handleFileUpload();
            const fileUploadRequest: FileUploadRequest = {
                FileContent: event.target.files[0],
                FileName: event.target.files[0].name,
                ContentType: event.target.files[0].type,
            };
            sendData(fileUploadRequest).catch(async (error) => {
                if (error && error.response) {
                    const responseBody = await error.response.json();
                    if (responseBody && responseBody.failures) {
                        setErrorMessages(responseBody.failures);
                    }
                }
            });
        }
    };
    const handleFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    function fileUploadButton(
        fileInputRef: React.RefObject<HTMLInputElement>,
        handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
        handleFileUpload: () => void,
    ): React.ReactNode {
        return (
            <>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    id="fileUpload"
                    name="fileUpload"
                    style={{display: 'none'}}
                ></input>
                <Button
                    {...restoreFocusTargetAttribute}
                    onClick={handleFileUpload}
                    icon={<ArrowUpload24Filled />}
                    tabIndex={0}
                    className={classes.btnUpload}
                >
                    {t('common.UploadFileText')}
                </Button>
            </>
        );
    }

    const [showSearchBar, setShowSearchBar] = useState(false);

    const switchRefs = useRef<{[keyId: string]: HTMLInputElement | null}>({});
    const restoreFocusTargetAttribute = useRestoreFocusTarget();

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReloadDependency(!reloadDependency);
    };

    return (
        <DialogContent>
            {errorMessages.map((errorMessage, index) => (
                <div key={index}>
                    <MessageBar
                        className={classes.errorBar}
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                    >
                        <CancelledIcon className={classes.cancelledIcon} />
                        {/* TODO: Map error message to localized error string */}
                        {errorCodesMap[errorMessage] || errorMessage}
                        <Button
                            className={classes.closeButton}
                            appearance="subtle"
                            size="large"
                            icon={<DismissIcon />}
                            aria-label={t('FilesTab.CloseFileUploadError')}
                            onClick={() => handleDismissError(index)}
                        />
                    </MessageBar>
                </div>
            ))}{' '}
            <div className={classes.popoverTop}>
                <Text className={classes.popoverTopText} as="h2">
                    {t('FilesText')}
                </Text>
                <Preview />
            </div>
            <div className={classes.subTextWrapper}>
                <Text className={classes.subText}>{t('KnowledgeTabDescription')}</Text>
                <br />
                <br />
                <Text className={classes.subText}>{t('KnowledgeTabRestrictions')}</Text>
            </div>
            {tenantInfo?.allowUserFileUpload && (
                <div className={mergeClasses(classes.popoverSearch)}>
                    <div className={classes.fileActionBar}>
                        <Button
                            {...restoreFocusTargetAttribute}
                            onClick={() => setShowSearchBar(true)}
                            className={mergeClasses(
                                classes.btnSearchStart,
                                showSearchBar && classes.btnSearchHidden,
                            )}
                            icon={<SearchIcon />}
                            aria-label={t('ContentModal.SearchFiles')}
                            tabIndex={0}
                        />
                        {showSearchBar && (
                            <Input
                                {...restoreFocusTargetAttribute}
                                className={mergeClasses(classes.searchbar, classes.narrowSearchbar)}
                                type="text"
                                autoFocus
                                placeholder={t('ContentModal.SearchFiles')}
                                value={searchText}
                                onChange={(_event: any, textbox: any) => {
                                    handleSearch(textbox.value);
                                }}
                                contentBefore={<SearchIcon />}
                                appearance="underline"
                                contentAfter={
                                    <Button
                                        onClick={() => {
                                            handleSearch('');
                                            setShowSearchBar(false);
                                        }}
                                        className={classes.btnCancel}
                                        size={'small'}
                                        appearance={'outline'}
                                        icon={<DismissIcon />}
                                        aria-label={t('ContentModal.ClearSearch')}
                                    />
                                }
                            />
                        )}

                        <ToolbarDivider className={classes.hrule} />
                        {fileUploadButton(fileInputRef, handleFileChange, handleFileUpload)}
                    </div>
                    {!fileUsageLoading && (
                        <FileUsageMetrics fileUsage={fileUsage}></FileUsageMetrics>
                    )}
                </div>
            )}
            <div className={classes.categoryRow}>
                <Field
                    className={classes.categoryTextCol}
                    label={{
                        children: (
                            <MedeinaInfoLabel
                                labelContent={
                                    <Text as="h2" className={classes.categoryNameText}>
                                        {t('ContentModal.Uploads')}
                                    </Text>
                                }
                                infoContent={t('KnowledgeSearchInfoContent')}
                            />
                        ),
                    }}
                />
            </div>
            <div>
                {!filesLoading && files && files.value?.length === 0 && (
                    <>
                        <div className={classes.emptyCategory}>
                            <Text className={classes.emptySearchText}>
                                {t('NoFilesFoundHeader')}
                            </Text>
                            <Text className={classes.emptySearchText2}>
                                {t('NoFilesFoundText')}
                            </Text>
                        </div>
                    </>
                )}
                {filesLoading && <Text className={classes.field}>{t('LoadingText')}</Text>}
                {!filesLoading && files && files.value?.length > 0 && (
                    <FilesSection
                        onSwitchChange={onChangeHandler}
                        switchRefs={switchRefs}
                        files={filteredFiles}
                        tenantEnabled={tenantInfo?.allowUserFileUpload ?? false}
                    />
                )}
                <div aria-live="polite" className={classes.visuallyHidden}>
                    {getSearchResultsAriaLiveText()}
                </div>
            </div>
        </DialogContent>
    );
}
