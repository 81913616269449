import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {SkillsetConfiguration, UpdateSkillsetConfigsRequest} from './skills.types';

export default function useSubmitSkillsetConfigs() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();

    return useMutation({
        mutationFn: async ({configuration, scope}: UpdateSkillsetConfigsRequest) => {
            const body = configuration;
            const queryParams = `?scope=${scope}`;
            try {
                return await customFetch<SkillsetConfiguration>(
                    `/skillsets/${configuration?.skillsetName}/configure${queryParams}`,
                    {
                        method: 'PATCH',
                        body,
                    },
                );
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['skillsets', 'configurations']);
        },
    });
}
