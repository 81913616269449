import {
    Button,
    Divider,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemLink,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from '@fluentui/react-components';
import {
    CommunityIcon,
    DocumentIcon,
    GiveFeedbackIcon,
    HelpMenuIcon,
    SupportIcon,
    ExternalLinkIcon,
    TutorialIcon,
} from '../../icons';
import {AssistanceMenuItemsProps} from './PortalAssistance.types';
import {useClasses} from './AssistanceMenuItems.styles';
import {useTourable} from '../../Tour/useTourable';
import MedeinaFeatures from '@/util/features';
import MedeinaVariables from '@/util/variables';
import {useTranslation} from 'react-i18next';

export default function AssistanceMenuItems({
    onFeedbackClick,
    onSupportCentralClick,
    menuType = 'promptBar',
}: AssistanceMenuItemsProps) {
    const classes = useClasses();
    const {t} = useTranslation('common');
    const tourRef = useTourable({id: 'helpMenu'});

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                {menuType === 'header' ? (
                    <MenuItem
                        id="help-button" // Needed for refocus on help button after closing support central widget in SupportCentralWidget.tsx
                        className={classes.supportButton}
                        ref={tourRef}
                        data-testid="help-button"
                        icon={<HelpMenuIcon filled />}
                    >
                        {t('tooltips.helpAndFeedback')}
                    </MenuItem>
                ) : (
                    <Tooltip content={t('tooltips.help')} relationship="label" positioning="before">
                        <MenuButton
                            id="help-button"
                            ref={tourRef}
                            className={classes.help}
                            aria-label={t('tooltips.help')}
                            data-testid="help-button"
                            appearance="subtle"
                            shape="circular"
                            icon={<HelpMenuIcon filled />}
                            size="large"
                        />
                    </Tooltip>
                )}
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuItemLink
                        href={MedeinaVariables.DocumentationUri as string}
                        target="_blank"
                        icon={<DocumentIcon />}
                        role="menuitem"
                        secondaryContent={<ExternalLinkIcon aria-label={t('externalLink')} />}
                    >
                        {t('help.documentation')}
                    </MenuItemLink>
                    <MenuItemLink
                        href={MedeinaVariables.SecurityCopilotTrainingUri as string}
                        target="_blank"
                        icon={<TutorialIcon />}
                        role="menuitem"
                        secondaryContent={<ExternalLinkIcon aria-label={t('externalLink')} />}
                    >
                        {t('help.training')}
                    </MenuItemLink>
                    <Divider appearance="subtle" />
                    {/* <MenuItem icon={<GiveFeedbackIcon />} role="menuitem" onClick={onFeedbackClick}>
                        {t('help.giveFeedback')}
                    </MenuItem> */}
                    {MedeinaFeatures.SupportCentral ? (
                        <MenuItem
                            icon={<SupportIcon />}
                            data-testid="help-menu-button"
                            role="menuitem"
                            onClick={onSupportCentralClick}
                        >
                            {t('help.help')}
                        </MenuItem>
                    ) : null}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}
