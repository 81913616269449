import {HighContrastColors} from './colors.types';

/**
 * Colors taken from fluent high contrast theme directly.  Full set here for reference
 */
export const highContrast: HighContrastColors = {
    highlight: '#8EE3F0',
    hyperlink: '#75E9FC',
    buttonFace: '#202020',
    canvas: '#202020',
    text: '#FFFFFF',
    highlightText: '#263B50',
    disabled: '#A6A6A6',
};
