import {DismissIcon} from '@/components/ui/icons';
import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Tooltip,
} from '@fluentui/react-components';
import useClasses from './LinkCopiedDialog.styles';
import {LinkCopiedDialogProps} from './Promptbook.types';
import {CheckmarkCircle24Regular} from '@fluentui/react-icons';
import {useTranslation} from 'react-i18next';

export default function LinkCopiedDialog({open, onClose}: LinkCopiedDialogProps) {
    const classes = useClasses();
    const {t} = useTranslation('promptbooks');
    return (
        <Dialog
            data-testid="share-promptbook-dialog"
            open={open}
            onOpenChange={(event, data) => {
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    onClose?.();
                }
            }}
        >
            <DialogSurface className={classes.shareDialogRoot}>
                <DialogBody>
                    <DialogTitle
                        data-testid="share-pb-title"
                        className={classes.header}
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<DismissIcon />}
                                    onClick={() => {
                                        onClose?.();
                                    }}
                                />
                            </DialogTrigger>
                        }
                    >
                        <Tooltip content={t('LinkCopied')} relationship="description">
                            <div className={classes.promptbookNameDisplay}>
                                <CheckmarkCircle24Regular />
                                <div
                                    data-testid="LinkCopiedModel"
                                    className={classes.MessageDisplay}
                                    aria-live="assertive"
                                >
                                    {t('LinkCopied')}
                                </div>
                            </div>
                        </Tooltip>
                    </DialogTitle>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
