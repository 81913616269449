import {Caption1, MenuItem} from '@fluentui/react-components';
import {PromptBarMenuChildListProps} from './PromptBarMenu.types';
import useClasses from './PromptBarMenu.styles';

export default function PromptBarMenuList<T>({
    onSelect,
    lists,
    noMatches,
    getName,
    getDescription,
    header,
}: PromptBarMenuChildListProps<T>) {
    const classes = useClasses();
    const {filteredList} = lists;

    return (
        <>
            {header}
            {filteredList.length > 0 ? (
                filteredList.map((result, i) => (
                    <MenuItem
                        key={i.toString()}
                        onClick={() => onSelect?.(result.item)}
                        className={classes.menuItem}
                    >
                        <div className={classes.skillName}>{getName(result.item) || ''}</div>
                        <Caption1 className={classes.skillDescription}>
                            {getDescription(result.item) || ''}
                        </Caption1>
                    </MenuItem>
                ))
            ) : (
                <div className={classes.noMatches}>{noMatches || ''}</div>
            )}
        </>
    );
}
