import React from 'react';
import {Caption1, Dialog, DialogSurface, Image} from '@fluentui/react-components';
import {Evaluation, EvaluationImageSource} from '@/api/evaluations';
import useClasses from './EvaluationImages.styles';
import useGetEvaluationImages from '@/api/evaluations/useGetEvaluationImages';
import mime from 'mime-types';

function EvaluationImages({evaluation}: {evaluation: Evaluation}) {
    const classes = useClasses();

    const {data: evaluationImages} = useGetEvaluationImages({
        sessionId: evaluation.sessionId,
        promptId: evaluation.promptId,
        evaluationId: evaluation.evaluationId,
    });
    const [showDialog, setShowDialog] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState<EvaluationImageSource | null>(null);
    const [blobUrls, setBlobUrls] = React.useState<Map<string, [string, string]>>(new Map());

    // Fetch all blob urls once images are loaded
    React.useEffect(() => {
        if (evaluationImages) {
            const newBlobUrls = new Map<string, [string, string]>();
            evaluationImages.value.forEach((item) => {
                const blobUrl = fetchBlobUrl(item.imageBase64, item.imageFileType);
                const blobThumbnailUrl = fetchBlobUrl(
                    item.imageThumbnailBase64,
                    item.imageFileType,
                );
                newBlobUrls.set(item.displayName, [blobUrl, blobThumbnailUrl]);
            });
            setBlobUrls(newBlobUrls);
        }
    }, [evaluationImages]);

    if (!evaluationImages || evaluationImages.length === 0) {
        return null;
    }

    const openFullImage = (item: EvaluationImageSource) => {
        setSelectedImage(item);
        setShowDialog(true);
    };

    // Dynamically derive the MIME type
    const getMimeType = (fileType: string) => {
        const mimeType = mime.lookup(fileType);
        return mimeType ? mimeType : '';
    };

    // Convert base64 to Blob URL
    const fetchBlobUrl = (base64: string, fileType: string) => {
        const mimeType = getMimeType(fileType);
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: mimeType});
        return URL.createObjectURL(blob);
    };

    return (
        <>
            <div>
                <Caption1 className={classes.caption}>Images</Caption1>
            </div>
            <div>
                {evaluationImages?.value?.map((item: EvaluationImageSource, index: number) => {
                    return (
                        <Image
                            className={classes.image}
                            key={index}
                            src={
                                blobUrls.get(item.displayName)
                                    ? blobUrls.get(item.displayName)?.[1]
                                    : ''
                            }
                            alt={item.description}
                            style={{cursor: 'pointer', maxWidth: '100px', maxHeight: '100px'}}
                            onClick={() => openFullImage(item)}
                        />
                    );
                })}
            </div>
            <Dialog
                open={showDialog}
                onOpenChange={(e, data) => {
                    setShowDialog(data.open);
                }}
            >
                <DialogSurface className={classes.dialogSurface}>
                    {selectedImage && (
                        <Image
                            src={
                                blobUrls.get(selectedImage.displayName)
                                    ? blobUrls.get(selectedImage.displayName)?.[0]
                                    : ''
                            }
                            alt={selectedImage.description}
                        />
                    )}
                </DialogSurface>
            </Dialog>
        </>
    );
}

export default EvaluationImages;
