import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import documentDownloadComponents from './DocumentDownloadComponents';
import {DocumentDownloadMarkdownProps} from './DocumentDownload.types';

export default function DocumentDownloadMarkdown(props: DocumentDownloadMarkdownProps) {
    return (
        <ReactMarkdown components={documentDownloadComponents} remarkPlugins={[gfm]}>
            {props.children}
        </ReactMarkdown>
    );
}
