import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    icon: {
        '& > svg.fui-Icon-regular': {
            height: '40px',
            width: '40px',
            '@media (forced-colors: active)': {
                backgroundColor: 'Canvas',
            },
        },

        marginBottom: '38px',
    },
    dropDownContainer: {
        width: 'min(600px, 70vw)',
    },
});
