import {Subtitle1, Text} from '@fluentui/react-components';
import {PromptContentLabelProps} from './PromptContent.types';
import useClasses from './PromptContent.styles';

export default function PromptContentUnknownLabel({
    prompt,
    pinned,
    className,
}: PromptContentLabelProps) {
    const classes = useClasses();
    // TODO: Remove pinning-specific styling after updating to MS Secure styles.
    const TextComponent = pinned ? Text : Subtitle1;

    return (
        <TextComponent className={className ?? classes.promptTitle}>
            {prompt?.content}
        </TextComponent>
    );
}
