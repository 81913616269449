import {useQuery} from '@tanstack/react-query';
import {GetSessionPinnedSummaryRequest} from './sessions.types';
import useSessionsFetch from '../useSessionsFetch';
import {isEmpty} from 'lodash-es';
import MedeinaFeatures from '@/util/features';
import {Session} from './sessions.types';
import {useSummarizePins} from '@/api/sessions';

export default function useGetPinboardSummary({
    sessionId,
    pinnedItems,
    sessionData,
}: GetSessionPinnedSummaryRequest) {
    const {sessionsFetch} = useSessionsFetch(sessionId);
    const {refetch: refetchSummarize} = useSummarizePins({sessionId, pinnedItems, sessionData});

    return useQuery({
        queryKey: ['sessiongetSummary', sessionId, 'pinnedItems', pinnedItems],
        queryFn: async () => {
            return await sessionsFetch<Session>(`${sessionId}`, {method: 'GET'});
        },
        enabled: MedeinaFeatures.SavePinboardSummary && !!pinnedItems && !isEmpty(pinnedItems),

        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (data: Session) => {
            if (
                MedeinaFeatures.SavePinboardSummary &&
                (data.pinboardSummary == null || data.pinboardSummary.summary == null)
            )
                refetchSummarize();
        },
    });
}
