import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    filterLabelText: {
        paddingBottom: tokens.spacingHorizontalXS,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        textAlign: 'center',
        justifyContent: 'flex-start',
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
    },
    searchImage: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(
            `calc(${tokens.spacingHorizontalXL} * 5)`,
            tokens.spacingHorizontalXXXL,
            tokens.spacingHorizontalM,
            tokens.spacingHorizontalXXXL,
        ),
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        height: `calc(${tokens.lineHeightBase300} * 6)`,
        width: `calc(${tokens.lineHeightBase300} * 6)`,
    },
    filterOptions: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        width: '100%',
    },
    filterSelect: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '26px',
        alignmentBaseline: 'middle',
        ...shorthands.gap(tokens.spacingHorizontalM),
        ...shorthands.padding(tokens.spacingHorizontalM),
        ...shorthands.borderRadius(tokens.borderRadiusCircular),
    },
    menuPopover: {
        height: '208px',
    },
});
