interface PublicVariables {
    // Medeina Portal Client ID (MSAL)
    ClientId: string;
    // Version of the Medeina Portal build
    Version: string;
    // Medeina Service Scope URI (MSAL)
    ScopeUri: string;
    // Medeina Service Fidelis Scope URI (MSAL)
    FidelisScopeUri: string;
    // Medeina Service Fidelis AFD Scope URI (MSAL)
    FidelisAfdScopeUri: string;
    // Security Graph geo/pod front door URI
    SecurityGraphUri: string;
    // Medeina Portal Endpoint URI
    PortalUri: string;
    // Medeina Service Endpoint URI
    ApiUri: string;
    // Medeina Service Local Endpoint URI (for development)
    LocalApiUri: string;
    // Medeina Service Fidelis Endpoint URI
    FidelisApiUri: string;
    // Medeina Service Fidelis AFD Endpoint URI
    FidelisAfdApiUri: string;
    // Medeina Telemetry instrumentation key (1DS Tenant Token / Application Insights)
    TelemetryInstrumentationKey: string;

    // Maximum number of prompts in a session
    MaxPromptsPerSession: number;
    // Timeout (ms) after creating an evaluation when the user should be prompted to rerun a prompt.
    CreateEvaluationRunningTimeout: number;
    // Query string parameter for tenant ID
    TenantIdUrlParam: string;
    // Privacy statement URI
    PrivacyStatementUri?: string;
    // Terms and conditions URI
    TermsAndConditionsUri?: string;
    // Support URI
    SupportUri?: string;
    // Fidelis SecoCop resource URI
    FidelisSecCopUri?: string;
    // Support Central Base URL
    SupportCentralUri?: string;
    // ARM URI
    ArmUri?: string;
    // Fidelis scope
    FidelisScope: string;
    // ARM scope
    ArmScope: string;
    // Manage Capacity Learn URI
    ManageCapacityLearnUri: string;
    // Manage Capacities Azure URI
    ManageCapacityAzureUri: string;
    // Copilot privacy and data security
    PrivacyAndDataSecurity: string;
    // Data Residency
    DataResidency: string;
    // Provisioning Audit Logging
    ProvisioningAuditLogging: string;
    // Audit solution page in Microsoft Purview
    AuditMicrosoftPurview: string;
    // Azure Portal URL
    AzurePortalUrl: string;
    // TrustCenter URL
    TrustCenterUrl?: string;
    // FRE Terms & Condition URL
    FreTermsAndConditionsUrl?: string;
    // FRE Read More URL
    FreReadMoreurl?: string;
    // Learn more link for prompting
    LearnMorePromptingUrl: string;
    // Learn more link for promptbooks
    LearnMorePromptbooksUrl: string;
    // Default capacity region
    DefaultCapacityRegion?: string;
    // Documentation URI
    DocumentationUri?: string;
    // Microsoft Entra URI
    MicrosoftEntraUri: string;
    // Security Copilot Authentication URL
    SecurityCopilotAuthenticationUri: string;
    // Read more about security compute units
    SecurityComputeUnitsUri: string;
    // FR a11y compliance page
    FRa11yComplianceUri: string;
    // IT a11y compliance page
    ITa11yComplianceUri: string;
    // Preview plugin TOS URI
    PreviewPluginTosUri: string;
    // OverView Learn more URI
    OverviewLearnMoreUri: string;
    // MS Managed Tenants
    MSManagedTenants: Array<string>;
    // CfS training external URI
    SecurityCopilotTrainingUri: string;
    // MS Clarity Project ID
    MSClarityId: string;
}

const MedeinaVariables: PublicVariables = {
    ClientId: process.env['NEXT_PUBLIC_CLIENT_ID'] ?? 'unknown',
    Version: process.env['NEXT_PUBLIC_VERSION'] ?? 'unknown',
    PortalUri: process.env['NEXT_PUBLIC_PORTAL_URI'] ?? 'unknown',
    ApiUri: process.env['NEXT_PUBLIC_API_URI'] ?? 'unknown',
    LocalApiUri:
        process.env['NEXT_PUBLIC_LOCAL_API_URI'] ?? process.env['NEXT_PUBLIC_API_URI'] ?? 'unknown',
    FidelisApiUri: process.env['NEXT_PUBLIC_FIDELIS_API_URI'] ?? 'unknown',
    FidelisAfdApiUri: process.env['NEXT_PUBLIC_FIDELIS_AFD_API_URI'] ?? 'unknown',
    SecurityGraphUri: process.env['NEXT_PUBLIC_SECURITY_GRAPH_URI'] ?? '',
    ScopeUri: process.env['NEXT_PUBLIC_SCOPE_URI'] ?? 'unknown',
    FidelisScopeUri: process.env['NEXT_PUBLIC_FIDELIS_SCOPE_URI'] ?? 'unknown',
    FidelisAfdScopeUri: process.env['NEXT_PUBLIC_FIDELIS_AFD_SCOPE_URI'] ?? 'unknown',
    PrivacyStatementUri: process.env['NEXT_PUBLIC_PRIVACY_STATEMENT_URI'],
    TermsAndConditionsUri: process.env['NEXT_PUBLIC_FRE_TERMS_AND_CONDITIONS_URI'] ?? '',
    SupportUri: process.env['NEXT_PUBLIC_SUPPORT_URI'],
    TelemetryInstrumentationKey:
        process.env['NEXT_PUBLIC_TELEMETRY_INSTRUMENTATION_KEY'] ?? 'unknown',
    MaxPromptsPerSession: parseInt(process.env['NEXT_PUBLIC_MAX_PROMPTS_PER_SESSION'] ?? '10', 10),
    CreateEvaluationRunningTimeout: parseInt(
        process.env['NEXT_PUBLIC_CREATE_EVALUATION_RUNNING_TIMEOUT'] ?? '60000',
        10,
    ),
    TenantIdUrlParam: process.env['NEXT_PUBLIC_TENANT_ID_URL_PARAM'] ?? 'tenantId',
    FidelisSecCopUri:
        process.env['NEXT_PUBLIC_FIDELIS_SECCOP_URI'] !== 'none'
            ? process.env['NEXT_PUBLIC_FIDELIS_SECCOP_URI']
            : '',
    DefaultCapacityRegion:
        process.env['NEXT_PUBLIC_DEFAULT_CAPACITY_REGION'] !== 'none'
            ? process.env['NEXT_PUBLIC_DEFAULT_CAPACITY_REGION']
            : '',
    SupportCentralUri: process.env['NEXT_PUBLIC_SUPPORT_CENTRAL_URL'] ?? 'unknown',
    ArmUri: process.env['NEXT_PUBLIC_ARM_URI'] !== 'none' ? process.env['NEXT_PUBLIC_ARM_URI'] : '',
    FidelisScope: process.env['NEXT_PUBLIC_FIDELIS_SCOPE'] ?? '',
    ArmScope: process.env['NEXT_PUBLIC_ARM_SCOPE'] ?? '',
    ManageCapacityLearnUri: process.env['NEXT_PUBLIC_MANAGE_CAPACITY_LEARN_URI'] ?? '',
    ManageCapacityAzureUri: process.env['NEXT_PUBLIC_MANAGE_CAPACITIES_AZURE_URI'] ?? '',
    PrivacyAndDataSecurity: process.env['NEXT_PUBLIC_PRIVACY_AND_DATA_SECURITY'] ?? '',
    DataResidency: process.env['NEXT_PUBLIC_DATA_RESIDENCY'] ?? '',
    ProvisioningAuditLogging: process.env['NEXT_PUBLIC_PROVISIONING_AUDIT_LOGGING'] ?? '',
    AuditMicrosoftPurview: process.env['NEXT_PUBLIC_AUDIT_MICROSOFT_PURVIEW'] ?? '',
    AzurePortalUrl: process.env['NEXT_PUBLIC_AZURE_PORTAL_URI'] ?? '',
    TrustCenterUrl: process.env['NEXT_PUBLIC_TRUST_CENTER_URI'] ?? '',
    FreTermsAndConditionsUrl: process.env['NEXT_PUBLIC_FRE_TERMS_AND_CONDITIONS_URI'] ?? '',
    FreReadMoreurl: process.env['NEXT_PUBLIC_FRE_READ_MORE_URI'] ?? '',
    LearnMorePromptingUrl: process.env['NEXT_PUBLIC_PROMPTING_GUIDE_URI'] ?? '',
    LearnMorePromptbooksUrl: process.env['NEXT_PUBLIC_PROMPTBOOK_GUIDE_URI'] ?? '',
    DocumentationUri: process.env['NEXT_PUBLIC_DOCUMENTATION_URI'] ?? '',
    MicrosoftEntraUri: process.env['NEXT_PUBLIC_MICROSOFT_ENTRA_URI'] ?? '',
    SecurityCopilotAuthenticationUri:
        process.env['NEXT_PUBLIC_SECURITY_COPILOT_AUTHENTICATION_URI'] ?? '',
    SecurityComputeUnitsUri: process.env['NEXT_PUBLIC_SECURITY_COMPUTE_UNITS_URI'] ?? '',
    // a11y uris that only show up when those languages are active
    FRa11yComplianceUri: process.env['NEXT_PUBLIC_SECURITY_FR_A11Y_PAGE_URI'] ?? '',
    ITa11yComplianceUri: process.env['NEXT_PUBLIC_SECURITY_IT_A11Y_PAGE_URI'] ?? '',
    PreviewPluginTosUri: process.env['NEXT_PUBLIC_PREVIEW_PLUGIN_TOS_URI'] ?? '',
    OverviewLearnMoreUri: process.env['NEXT_PUBLIC_OVERVIEW_LEARN_MORE_URI'] ?? '',
    MSManagedTenants: [
        '536279f6-15cc-45f2-be2d-61e352b51eef',
        '72f988bf-86f1-41af-91ab-2d7cd011db47',
        '0553df8d-f650-4a9b-b0b8-f97df0aedfce',
    ],
    SecurityCopilotTrainingUri: process.env['NEXT_PUBLIC_TRAINING_EXTERNAL_URI'] ?? '',
    MSClarityId: process.env['NEXT_PUBLIC_MS_CLARITY_PROJECT_ID'] ?? '',
};

export default MedeinaVariables;
