import {shorthands, makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        maxWidth: '600px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('28px'),
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: tokens.spacingVerticalXXXL,
    },
    leftActions: {},
    rightActions: {
        display: 'flex',
        gap: tokens.spacingVerticalS,
    },
});
