import {useState, useCallback, useEffect, useMemo} from 'react';
import {
    TourContext,
    Tour,
    TourState,
    TourStopState,
    ControlOptions,
    useTourProps,
    ValidTourErrorPropCombination,
} from './Tour.types';
import {defaultControlOptions} from './defaults';
import {useTourStopIndex} from '.';
import useTourStopState from './util/useTourStopState';

// hook that provides shared functionality for all product tours
// it manages exposing the current tour stop and moving back forth between the stop
const useTour = (props?: useTourProps): TourContext => {
    const [tour, setTour] = useState<Tour | null>(props?.tour || null);
    const {tourStops = [], telemetryOptions} = tour || {};
    const [stopIndex, setStopIndex] = useTourStopIndex(tourStops);
    const activeTour = Boolean(tourStops && tourStops.length);
    const [tourAutoplay, setTourAutoplay] = useState<boolean>(false);
    const [tourState, setTourState] = useState<TourState>(TourState.empty);
    const {currentStop, stopState, updateTourState} = useTourStopState({
        stopIndex,
        tourStops,
        activeTour,
    });
    const [showExitOverlay, setShowExitOverlay] = useState<boolean>(false);
    const mixedDefaultControlOptions = useMemo(
        () => ({...defaultControlOptions, ...(tour?.controlOptions || {})}),
        [tour?.controlOptions],
    );
    const [controls, setControlOptions] = useState<ControlOptions>(mixedDefaultControlOptions);
    // handle setting tour wide errors
    const [tourError, setTourError] = useState<string | undefined>(undefined);
    const [v2TourError, setV2TourError] = useState<ValidTourErrorPropCombination | null>(null);
    const [errorPage, setErrorPage] = useState<React.ReactNode | undefined>(undefined);
    const [tourSharedParameters, setTourSharedParameters] = useState<Record<string, any>>({});
    const [loadingState, setLoadingState] = useState<boolean>(false);

    useEffect(() => {
        setErrorPage(tour?.errorPage);
    }, [tour?.errorPage]);

    // set the tour autoplay based on the tour prop
    useEffect(() => {
        setTourAutoplay(tour?.autoPlay || false);
    }, [tour]);

    useEffect(() => {
        if (activeTour) {
            telemetryOptions?.onTourStart?.();
        }
        // only run this when activeTour changes, this means a new tour has been loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTour]);

    // any additional updates to controls from within a stop should spread out the default tour values
    const setControls = useCallback(
        (nextControls?: Partial<ControlOptions> | ControlOptions | null) => {
            const nextControlOptions = {
                ...mixedDefaultControlOptions,
                ...(currentStop?.controls || {}),
                ...(nextControls || {}),
            };
            if (nextControlOptions.showExitOnly) {
                nextControlOptions.allowBack = false;
                nextControlOptions.allowSkip = false;
                nextControlOptions.allowReplay = false;
                nextControlOptions.allowExit = false;
            }
            setControlOptions(nextControlOptions);
        },
        [currentStop?.controls, mixedDefaultControlOptions],
    );

    // Handle updates when the currentStop changes
    useEffect(() => {
        let nextTourState: TourState;
        if (!activeTour) {
            nextTourState = TourState.empty;
        } else {
            nextTourState = currentStop?.onBeforeStop
                ? TourState.preloading
                : currentStop?.autoStart
                ? TourState.playing
                : TourState.ready;
        }
        setTourState(nextTourState);
        // refresh the controls for the new stop
        setControls();
    }, [activeTour, currentStop, setControls]);

    // if we are allowed to auto advance then call the next TourStop
    useEffect(() => {
        if (!tourAutoplay && tourState === TourState.completed && currentStop?.autoAdvance) {
            // disable auto advance
            nextStop();
        }
    }, [tourState]);

    const prevStop = useCallback(() => {
        if (stopIndex !== 0) {
            setStopIndex(stopIndex - 1);
        }
    }, [stopIndex]);

    const nextStop = useCallback(() => {
        if (tourStops?.length - 1 >= stopIndex + 1) {
            setStopIndex(stopIndex + 1);
        }
    }, [stopIndex, tourStops?.length]);

    // TODO:Review - to be shared with each individual tour
    // the tour will call this function to signal to all components that all interaction/animation has ended
    const completeTourStop = useCallback(() => {
        setTourState(TourState.completed);
    }, []);

    // reset everything to the beginning
    // if an index is passed in this will reset the tour to that index
    const resetTourStop = useCallback(
        (index?: number) => {
            if (index !== undefined && index <= tourStops.length - 1) {
                setStopIndex(index);
            } else {
                setStopIndex(0);
                setTourState(tourStops[0].autoStart ? TourState.playing : TourState.ready);
            }
            //resetStamp();
        },
        [tourStops],
    );

    // pause the tour, stop the auto advance if there is one
    const pauseTour = useCallback(() => {
        if (tourState === TourState.playing) {
            setTourState(TourState.paused);
            setTourAutoplay(false);
        } else {
            setTourState(TourState.playing);
            setTourAutoplay(true);
        }
    }, [tourState]);

    // restart at the first tour
    const resetTour = useCallback(() => {
        setStopIndex(0);
        setTourState(tourStops[0]?.autoStart ? TourState.playing : TourState.ready);
        // setKeyStamp(Date.now());
    }, [tourStops]);

    // the tour will call this function to reset clear all tour components and call the onExit callback
    const quitTour = useCallback(() => {
        // run the onExit option
        if (tour?.onExit) {
            tour.onExit(currentStop?.name || '', '', () => {});
        }
        // if the exitOverlay is active we were in an early exit scenario
        if ((showExitOverlay || stopIndex !== tourStops.length - 1) && currentStop) {
            // onTourExitEarly(currentStop?.name);
            telemetryOptions?.onTourExitEarly?.(currentStop?.name || '');
        } else {
            // onTourComplete();
            telemetryOptions?.onTourComplete?.();
        }

        // if we are quitting
        setShowExitOverlay(false);
        setTour(null);
        setStopIndex(0);
    }, [currentStop, showExitOverlay, stopIndex, telemetryOptions, tour, tourStops.length]);

    // handler that evaluates whether to show the exit overlay or quit the tour
    const exitTour = useCallback(() => {
        // Check if we are at the end of the tour
        if (tour && stopIndex !== tour.tourStops.length - 1 && tour.onEarlyExit) {
            // this is an early exit, if we have a earlyExit tourStop toggle the showExitOverlay
            // it is expected that the overlay will deal with cleaning up the tour
            setShowExitOverlay(true);
        } else {
            // If no early exit is available quit the tour
            quitTour();
        }
    }, [quitTour, stopIndex, tour]);

    // Count of the number of steps that would involve the display of the progress bar
    const visibleTourStopCount = () => {
        const stepValues = tour?.tourStops?.map((stop) => stop.stepValue || -1) || [];
        const maxStepValue = Math.max(...stepValues);
        return maxStepValue;
    };

    // when showExitOverlay is toggle we will want to show/hide the controls that render on a high zIndex
    useEffect(() => {
        showExitOverlay ? setControls({showControls: false}) : setControls();
    }, [showExitOverlay]);

    // by default we will show the overlay
    const showOverlay = useMemo(() => {
        if (!activeTour) return false;
        const {spotlight} = currentStop || {};
        const showSpotlightOverlay =
            spotlight?.showOverlay !== undefined ? spotlight?.showOverlay : false;

        const {overlay} = currentStop || {};

        const showNormalOverlay = overlay !== undefined ? overlay : false;

        return Boolean(showSpotlightOverlay || showNormalOverlay || showExitOverlay);
    }, [currentStop, showExitOverlay, activeTour]);

    const loadContent = useCallback(() => {
        setTourState(TourState.playing);
    }, []);

    /**
     * This function helps the tour to render the tour page with the desired error state.
     * The error state will contain parameters such as the title of the page, the message
     * to be displayed, the text associated with the try again button , and the step to which
     * the error page should redirect to when the try again button is clicked.
     * @param errorProps - The error properties for the tour.
     */
    const setTourErrorV2 = (errorProps: ValidTourErrorPropCombination | null) => {
        setLoadingState(false);
        setV2TourError(errorProps);
    };

    const setDefaultV2TourError = (errorStep?: string) => {
        if (!tour?.errorStates) {
            return;
        }

        const defaultError = tour?.errorStates['Default'];
        if (!defaultError) {
            return;
        }

        var error: ValidTourErrorPropCombination = tour.errorStates['Default'];
        error = {
            ...error,
            actionButtonStep: errorStep || '',
        };
        setV2TourError(error);
    };

    /**
     * Sets the tour step to the specified step name.
     * The step name must correspond to the key of the step in the tourStops array.
     * @param stepName - The name of the step to set.
     */
    const setTourStep = (stepName: string) => {
        setV2TourError(null);
        const stepIndex = tourStops.findIndex((stop) => stop.key === stepName);
        setStopIndex(stepIndex);
    };

    /**
     * This function will help in sharing data objects across steps of the tour.
     * To retrieve the shared parameter in a future step, the value of the key should be passed as an argument here.
     * The value can be retrieved within the tour later as tour.getTourSharedParameter('key')
     * @param {Required<string>} key - The key of the tour shared parameter.
     * @param {Required<string>} value - The value of the tour shared parameter.
     */
    const addTourSharedParameter = (key: Required<string>, value: Required<string>) => {
        setTourSharedParameters({...tourSharedParameters, [key]: value});
    };

    /**
     * Retrieves the value of a tour shared parameter based on the provided key.
     * The expectation is for the value to have been saved earlier in the tour
     * with the addTourSharedParameter function.
     * @param key - The key of the tour shared parameter.
     * @returns The value of the tour shared parameter.
     */
    const getTourSharedParameter = (key: Required<string>) => {
        return tourSharedParameters[key];
    };

    /**
     * Clears the specified tour shared parameter from the state.
     * @param key - The key of the tour shared parameter to clear.
     */
    const clearTourSharedParameter = (key: Required<string>) => {
        const {[key]: _, ...rest} = tourSharedParameters;
        setTourSharedParameters(rest);
    };

    const clearTourSharedParameters = () => {
        setTourSharedParameters({});
    };

    const showLoadingState = (show: boolean = false) => {
        setLoadingState(show);
    };

    // a wrapper function to set a stopState for the currentState
    // stops should not be able to use the full updateStopState function
    const setStopState = useCallback(
        (newStopState: TourStopState) => {
            updateTourState(currentStop, newStopState);
        },
        [currentStop, updateTourState],
    );

    return {
        activeTour,
        showOverlay,
        tour,
        tourState,
        loadContent,
        controls,
        setControls,
        setTour,
        resetTour,
        resetTourStop,
        completeTourStop,
        pauseTour,
        quitTour,
        nextStop,
        prevStop,
        currentStop,
        stopIndex,
        exitTour,
        showExitOverlay,
        setShowExitOverlay,
        tourAutoplay,
        setTourError,
        tourError,
        setTourErrorV2,
        setDefaultV2TourError,
        v2TourError,
        errorPage,
        setTourStep,
        addTourSharedParameter,
        getTourSharedParameter,
        clearTourSharedParameter,
        clearTourSharedParameters,
        showLoadingState,
        loadingState,
        setTourState,
        visibleTourStopCount,
        stopState,
        setStopState,
    };
};

export default useTour;
