import {isUntrustedApi, isFidelisApi} from '@/api/api';
import {NavigationBannerExpiryState, NavigationBannerState} from './Navigation.types';
import {useMemo} from 'react';
import useGetEapExpired from '@/api/app/useGetEapExpired';
import {parseISO} from 'date-fns';

const _14_DAYS = 14 * 24 * 60 * 60 * 1000;
const _7_DAYS = 7 * 24 * 60 * 60 * 1000;
const _48_HOURS = 48 * 60 * 60 * 1000;
const _24_HOURS = 24 * 60 * 60 * 1000;

export default function useNavigationBanner(): NavigationBannerState {
    // Determine EAP expiry date states.
    const {data: expiryData, isError} = useGetEapExpired();

    const DEFAULT_RESPONSE = {
        eapExpiryDate: null,
        isEapExpiresIn14Days: false,
        isEapExpiresIn7Days: false,
        isEapExpiresIn1Day: false,
        isEapExpiresIn0Days: false,
        isEapExpired: false,
    };

    const expiryState = useMemo<NavigationBannerExpiryState>(() => {
        if (expiryData?.expiryDate) {
            try {
                const expiryDate: Date = new Date(expiryData?.expiryDate);
                const isoString = expiryDate.toISOString();
                const date = parseISO(isoString);
                const now = Date.now();
                const today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                today.setMilliseconds(0);

                return {
                    eapExpiryDate: date,
                    isEapExpiresIn14Days: date.getTime() - now < _14_DAYS,
                    isEapExpiresIn7Days: date.getTime() - now < _7_DAYS,
                    isEapExpiresIn1Day: date.getTime() - today.getTime() < _48_HOURS,
                    isEapExpiresIn0Days: date.getTime() - today.getTime() < _24_HOURS,
                    isEapExpired: now > date.getTime(),
                };
            } catch {
                return DEFAULT_RESPONSE;
            }
        } else {
            return DEFAULT_RESPONSE;
        }
    }, [expiryData]);

    const isWarning = useMemo<boolean>(
        () =>
            Boolean(
                isUntrustedApi ||
                    expiryState.isEapExpired ||
                    expiryState.isEapExpiresIn0Days ||
                    expiryState.isEapExpiresIn1Day,
            ),
        [expiryState, isUntrustedApi],
    );
    const isInfo = useMemo<boolean>(
        () => Boolean(expiryState.isEapExpiresIn14Days || expiryState.isEapExpiresIn7Days),
        [expiryState, isUntrustedApi],
    );
    const isVisible = useMemo<boolean>(() => isWarning || isInfo, [isWarning, isInfo]);
    return {...expiryState, isVisible, isWarning, isInfo, isUntrustedApi, isFidelisApi};
}
