import {UseQueryOptions, useMutation} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {ErrorResponse} from '@/api/api.types';
import {Hello, HelloRequest} from './hello.types';

export default function useCreateHello() {
    const {customFetch} = useFetch();
    return useMutation(['hello'], {
        mutationFn: async (body: HelloRequest) => {
            console.log('useCreateHello');
            const sanitizedBody = {
                ...body,
            };
            const result = await customFetch<Response>(
                `/hello`,
                {
                    method: 'POST',
                    body: sanitizedBody,
                },
                true,
            );

            console.log({result});
            const data = await result.json();
            console.log({data, result});
            return data;
        },
    });
}
