///<reference types="@wcp/wcp-consent" />
import MedeinaFeatures from '@/util/features';
import {createContext, useEffect, useState} from 'react';

export const CookieConsentContext = createContext<WcpConsent.SiteConsent | null>(null);

const bannerId = 'cookie-banner';
const wcpScriptUrl = 'https://wcpstatic.microsoft.com/mscc/lib/v3/wcp-consent.js';

export function CookieConsentProvider({children}: {children: React.ReactNode}) {
    const [siteConsent, setSiteConsent] = useState<WcpConsent.SiteConsent | null>(null);

    useEffect(() => {
        function onConsentChanged(categoryPreferences: any) {}

        function onInit(err: any, _siteConsent: any) {
            if (!err) {
                // siteConsent is used to get the current consent
                // can be used in component state or context
                setSiteConsent(_siteConsent);
            } else {
                console.log('Error initializing WcpConsent: ' + err);
            }
        }

        function handleScriptLoad() {
            // Initialize and display the cookie consent banner using the library's init function
            // This code will run after the script is loaded
            if (WcpConsent) {
                WcpConsent.init(
                    'en-US',
                    bannerId,
                    onInit,
                    onConsentChanged,
                    'dark',
                    // @ts-ignore nonce for Content-Security-Policy missing from TS type
                    'd2NwLWNvbnNlbnQtbGli',
                );
            }
        }

        if (MedeinaFeatures.CookieBannerExperience) {
            // Create a script element
            const script = document.createElement('script');
            script.src = wcpScriptUrl;

            // Listen for the onload event of the script
            script.onload = handleScriptLoad;

            // Append the script element to the document's head
            document.head.appendChild(script);

            // Clean up the script element when the component unmounts
            return () => {
                document.head.removeChild(script);
            };
        }
    }, []);

    return (
        <>
            <div>
                <div id={bannerId}></div>
            </div>
            <CookieConsentContext.Provider value={siteConsent}>
                {children}
            </CookieConsentContext.Provider>
        </>
    );
}
