import {AttributeRule, Condition} from '@/api/rbac/rbac.types';
import {CONDITION_ATTRIBUTES} from './rbac.constants';
import {MemberType, RoleType} from '@/components/admin/rbac/rbac.types';

const DERIVED_ATTRIBUTE_NAME = 'derived.purview.role';
const ATTRIBUE_RULE_NAME_PREFIX = 'purviewworkspacerole_builtin_workspace-';

export default function usePolicyFactory() {
    const getDefaultCondition = (attributeName: string): Condition => {
        return {
            attributeName,
            attributeValueIncludedIn: [],
        };
    };

    const getAppendedCondition = (identifier: string): Condition => {
        return {
            attributeName: DERIVED_ATTRIBUTE_NAME,
            attributeValueIncludes: `${ATTRIBUE_RULE_NAME_PREFIX}${identifier}`,
            fromRule: `${ATTRIBUE_RULE_NAME_PREFIX}${identifier}`,
        };
    };

    /**
     * Returns the default attribute rule for a given member type and role name.
     * @param memberType The type of member.
     * @param roleName The name of the role.
     * @returns An array of conditions representing the default attribute rule.
     */
    const getDefaultAttributeRuleCondition = (
        memberType: MemberType,
        roleType: RoleType,
    ): Condition[] => {
        return [
            getDefaultCondition(CONDITION_ATTRIBUTES[memberType]),
            getAppendedCondition(roleType.toLocaleLowerCase()),
        ];
    };

    //  "id": "purviewworkspacerole_builtin_workspace-owner:default",
    // "name": "purviewworkspacerole_builtin_workspace-owner:default",
    const getDefaultAttributeRule = (
        memberTypes: MemberType[],
        roleType: RoleType,
        workspaceName: string,
    ): AttributeRule => {
        const conditions: Condition[][] = memberTypes.map((memberType) =>
            getDefaultAttributeRuleCondition(memberType, roleType),
        );
        return {
            kind: 'attributerule',
            id: `${ATTRIBUE_RULE_NAME_PREFIX}${roleType.toLocaleLowerCase()}:${workspaceName}`,
            dnfCondition: conditions,
        };
    };

    return {
        getDefaultAttributeRule,
        getDefaultAttributeRuleCondition,
    };
}
