import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {customFetch} from '../api';
import {ResourceGroup} from './user.types';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';

interface GetUserResourceGroupRequest {
    subscriptionId: string;
    options: UseQueryOptions<ResourceGroup[]>;
}

export default function useGetUserResourceGroups(props: GetUserResourceGroupRequest) {
    return useQuery({
        queryKey: ['userResourceGroups', props.subscriptionId],
        queryFn: async () => {
            const response = await customFetch<Response>(
                `${MedeinaVariables.ArmUri}/subscriptions/${props.subscriptionId}/resourcegroups?api-version=2021-04-01`,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.ARM,
                },
                true,
            );
            const data = await response.json();
            return data.value as ResourceGroup[];
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        ...props.options,
    });
}
