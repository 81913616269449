import useClasses from './UsageDashboard.styles';
import {Button, Link, mergeClasses, Spinner} from '@fluentui/react-components';
import VStackedBarChart from './BarChart/VStackedBarChart';
import {
    ButtonLabels,
    ApiResponseDisplays,
    AriaLabels,
    LEARN_MORE_LINK,
    USAGE_MONITORING_DESCRIPTION,
    USAGE_MONITORING_DESCRIPTION_WORKSPACES,
    UNIT_SINGLE_TEXT,
    UNIT_PLURAL_TEXT,
    PAGE_LOADING,
    USAGE_DATA_LOADING,
    BASE_UNITS_PER_HOUR,
    CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE,
    WORKSPACE_USAGE,
    CAPACITIES_SUBHEADING,
} from './UsageDashboard.constants';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useGetUserInfo} from '@/api/app';
import {useGetCapacities, useGetCapacityByName} from '@/api/capacities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {ApiResponseDisplay} from './ApiResponseDisplay/ApiResponseDisplay';
import {subDays} from 'date-fns';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import ChangeCapacity from '../ChangeCapacityDialog/ChangeCapacity';
import DataTableV2 from './DataTable/DataTableV2';
import {NumericDisplayEmphasis, ParagraphContent, SmallTitle} from '@/components/ui/Text';
import {GridContainer} from '@/components/ui/Grid';
import UsageDashboardTitle from './UsageDashboardTitle';
import UsageDashboardFilterContainer from './Filter/UsageDashboardFilterContainer';
import useManageUsageDashboard from './useManageUsageDashboard';
import {DateFilterChangedProps} from './DateFilterDropdown/DateFilterDropdown.types';
import UsageDashboardDataExport from './UsageDashboardDataExport';
import useNavActions from '@/components/Navigation/useNavActions';
import DateFilterDropdownV2 from './DateFilterDropdown/DateFilterDropdownV2';
import {AnimatePresence, motion} from 'framer-motion';
import useDataTableV2Transitions from './DataTable/useDataTableV2Transitions';
import {convertFacetsToSelectedFilters} from './UsageDashboard.utils';
import {Capacity, KeyValueFilterDetails} from '@/api/capacities/capacities.types';
import {SearchCategory} from './UsageDashboard.types';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures, {MedeinaUserFeatures} from '@/util/features';
import {useNavigate} from 'react-router-dom';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {REGION_DATA} from '@/components/App.constants';
import {RegionName} from '@/components/App.types';
import {FilterButton, FilterButtonRow} from '@/components/ui/Buttons/FilterButton';
import {AddIcon} from '@/components/ui/icons';
import CreateCapacity from '@/components/CreateCapacity';
import SelectedFilterPillsSection from './SelectedFilterPillsSection';

//TODO: Handle scenario when no capacity is linked to workspace
export default function UsageDashboardV2() {
    const classes = useClasses();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const {data: user} = useGetUserInfo();

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    const [filterStartDate, setFilterStartDate] = useState<Date>(subDays(new Date(), 1));
    const [isFilterUpdated, setIsFilterUpdated] = useState<boolean>(false);
    const [shouldFocusFilterButton, setShouldFocusFilterButton] = useState<boolean>(false);
    const {transitionVariant} = useDataTableV2Transitions();

    const {capacityName, isProviderLoaded, isWorkspaceCapacityEAP} = useWorkspaceState();
    // manage workspaces button ref
    const manageWorkspacesButtonRef = useRef<HTMLButtonElement>(null);
    // new capacity button ref
    const newCapacityButtonRef = useRef<HTMLButtonElement>(null);
    // Used to open and close the change capacity dialog
    const changeCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isChangeCapacityDialogOpen, setChangeCapacityDialogOpen] = useState<boolean>(false);
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);
    const performActionsOnCapacityDialogClosure = () => {
        setChangeCapacityDialogOpen(false);
        changeCapacityButtonRef.current?.focus();
    };

    const enableUsageDataAlignment = Boolean(
        useFeatureFlag(MedeinaUserFeatures.EnableUsageDataAlignment),
    );

    const {
        data: capacity,
        isFetching: isGetCapacityNameFetching,
        isError: isGetCapacityNameError,
        isFetched: isGetCapacityNameFetched,
    } = useGetCapacityByName(capacityName || '', {
        enabled: !!capacityName,
    });
    const capacityUnits = useMemo(() => {
        return capacity?.properties?.numberOfUnits ?? 0;
    }, [capacity]);

    const {
        getState,
        hourlyDataModel,
        dimensionalDataModel,
        facetDataModel,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        drillDownByDateRange,
        removeDateRangeDrillDown,
        pageLoading,
        isEvaluationAggregateDataLoading,
        pageError,
        lastUpdatedAt,
        dateRangeOption,
        updateSortDirection,
        areSearchFacetsAvailable,
        shouldRefreshFullVisualization,
    } = useManageUsageDashboard({
        capacityName: capacityName || '',
    });

    const state = getState();

    const isCapacityUsageError = !capacityName || isGetCapacityNameError || pageError;
    const isCapacityUsageDataPresent =
        !pageLoading && !pageError && (hourlyDataModel?.value?.length ?? 0) > 0;

    // This will remove a flicker of loading state when the data is loaded for the first time
    const shouldShowLoadingState =
        !isProviderLoaded ||
        pageLoading ||
        (enableUsageDataAlignment &&
            shouldRefreshFullVisualization &&
            isEvaluationAggregateDataLoading);

    const showChangeUnitsComponents = isProviderLoaded && !isWorkspaceCapacityEAP;

    // Check if the page is loaded completely. This takes into account all the dependencies
    // and ensures that the page is loaded only when all the data is fetched.
    const isDashboardLoaded = useMemo((): boolean => {
        return isProviderLoaded && isGetCapacityNameFetched && !pageLoading;
    }, [isGetCapacityNameFetched, pageError, pageLoading, isProviderLoaded]);

    const getCapacityUnitsText = () => {
        if (!capacityUnits) {
            return '';
        }
        const unitText = capacityUnits === 1 ? tAdmin(UNIT_SINGLE_TEXT) : tAdmin(UNIT_PLURAL_TEXT);
        return `${capacityUnits} ${unitText}`;
    };

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const onPageRefresh = () => {
        const stateValues = getState();
        updateTimePeriod(stateValues.startDate, stateValues.endDate, stateValues.dateText);
    };

    const getApiStatusClasses = useCallback(() => {
        if (shouldShowLoadingState || isCapacityUsageError || !isCapacityUsageDataPresent) {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusWithContent);
        } else {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusEmpty);
        }
    }, [
        shouldShowLoadingState,
        isCapacityUsageError,
        pageLoading,
        hourlyDataModel,
        classes.apiStatusMessage,
        classes.apiStatusWithContent,
        classes.apiStatusEmpty,
    ]);

    const dateRangeOptionChanged = (option: DateFilterChangedProps) => {
        updateTimePeriod(option.startDate, option.endDate, option.title);
        setFilterStartDate(option.startDate);
    };

    const navigate = useNavigate();

    const shouldShowUsageDashboardV2Page = user?.isAdmin;

    const {setNavActions} = useNavActions();
    // for create capacity dialog
    // To get the capacity name from workspace
    const [selectedCapacity, setSelectedCapacity] = useState<string>(capacityName || '');
    // logic to get the capacity name from workspace
    const {data: workspacesData} = useGetWorkspaces();

    // get capacity list
    const {data: data, isSuccess: isGetCapacitiesSuccess} = useGetCapacities({});
    const capacities: {value: Capacity[]} = useMemo(() => data || {value: []}, [data]);

    const workspace = useMemo(() => {
        if (!workspacesData?.value) return null;
        if (selectedCapacity === '') {
            return workspacesData?.value?.find(
                (workspace: Workspace) => workspace?.capacity?.referenceName === capacityName,
            );
        } else {
            return workspacesData?.value?.find(
                (workspace: Workspace) => workspace?.capacity?.referenceName === selectedCapacity,
            );
        }
    }, [workspacesData, selectedCapacity, capacityName]);

    const workspaceDisplayName = workspace?.name;

    useEffect(() => {
        const actions = [];
        const stateValues = getState();
        actions.push(<UsageDashboardDataExport {...stateValues}></UsageDashboardDataExport>);
        setNavActions(actions);
    }, [getState]);

    const shouldDataTableScrollToViewOnReload = !state.needsHourlyDataRefresh;

    const copilotExperienceMap = useMemo(() => {
        return (
            facetDataModel?.value?.find(
                (value: any) => value.category === SearchCategory.CopilotExperience,
            )?.values ?? []
        );
    }, [facetDataModel]);

    const getAriaLiveText = useCallback((): string => {
        if (!pageLoading && isCapacityUsageError) {
            return tAdmin(AriaLabels.ERROR);
        } else if (!isCapacityUsageDataPresent && !pageLoading) {
            if (isWorkspacesTestingEnabled) {
                return tAdmin(AriaLabels.NO_DATA_WORKSPACES);
            } else {
                return tAdmin(AriaLabels.NO_DATA);
            }
        } else if (!isEvaluationAggregateDataLoading && dimensionalDataModel?.value?.length == 0) {
            return tAdmin(AriaLabels.NO_DIMENSIONAL_DATA);
        } else if ((dimensionalDataModel?.value?.length ?? 0) > 0) {
            return tAdmin(AriaLabels.DATA_LOADED);
        } else if (pageLoading || isEvaluationAggregateDataLoading) {
            return tAdmin(AriaLabels.USAGE_DATA_LOADING);
        } else return '';
    }, [
        isCapacityUsageDataPresent,
        pageLoading,
        isCapacityUsageError,
        isEvaluationAggregateDataLoading,
        dimensionalDataModel,
    ]);

    return (
        <>
            {shouldShowUsageDashboardV2Page && (
                <>
                    <div className={classes.visuallyHidden}>
                        <div aria-live="assertive">{getAriaLiveText()}</div>
                    </div>

                    <GridContainer className={classes.gridClasses}>
                        <div className={classes.root}>
                            <UsageDashboardTitle lastUpdatedAt={lastUpdatedAt} />
                            <div className={classes.infoContainer}>
                                <ParagraphContent>
                                    {isWorkspacesTestingEnabled
                                        ? tAdmin(USAGE_MONITORING_DESCRIPTION_WORKSPACES)
                                        : tAdmin(USAGE_MONITORING_DESCRIPTION)}{' '}
                                    <Link
                                        href={MedeinaVariables.ManageCapacityLearnUri}
                                        target="_blank"
                                    >
                                        {tAdmin(LEARN_MORE_LINK)}
                                    </Link>
                                </ParagraphContent>
                            </div>
                            {isWorkspacesTestingEnabled && (
                                <div>
                                    <ParagraphContent>
                                        {tAdmin(CAPACITIES_SUBHEADING)}
                                    </ParagraphContent>
                                    <div className={classes.FilterButtonRow}>
                                        <FilterButtonRow>
                                            {capacities?.value?.map((capacity: Capacity) => (
                                                <FilterButton
                                                    key={capacity.name}
                                                    size="medium"
                                                    name={capacity.name}
                                                    onClick={(ev) => {
                                                        setSelectedCapacity(capacity.name);
                                                    }}
                                                    checked={
                                                        selectedCapacity
                                                            ? selectedCapacity === capacity.name
                                                            : capacityName === capacity.name
                                                    }
                                                >
                                                    {
                                                        REGION_DATA[capacity.location as RegionName]
                                                            ?.displayName
                                                    }
                                                </FilterButton>
                                            ))}
                                            <FilterButton
                                                key="new-capacity"
                                                size="medium"
                                                name="new-capacity"
                                                onClick={() => {
                                                    setIsCreateCapacityDialogOpen(true);
                                                }}
                                                className={classes.addCapacityButton}
                                                icon={<AddIcon />}
                                                ref={newCapacityButtonRef}
                                            >
                                                {tAdmin('ManageWorkspaces.MenuButtons.NewCapacity')}
                                            </FilterButton>
                                        </FilterButtonRow>
                                        <CreateCapacity
                                            isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                                            onCreateCapacityDialogClose={() => {
                                                setIsCreateCapacityDialogOpen(false);
                                                newCapacityButtonRef.current?.focus();
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {!isDashboardLoaded && (
                                <div className={classes.loadingContainer}>
                                    <Spinner
                                        labelPosition="below"
                                        aria-live="polite"
                                        aria-label={tAdmin(PAGE_LOADING)}
                                        size="medium"
                                        label={`${tAdmin(PAGE_LOADING)}...`}
                                    />
                                </div>
                            )}
                            {isDashboardLoaded && (
                                <>
                                    <div className={classes.metaDataContainer}>
                                        <div className={classes.metaData}>
                                            {showChangeUnitsComponents && (
                                                <>
                                                    <div>
                                                        <SmallTitle>
                                                            {tAdmin(BASE_UNITS_PER_HOUR)}
                                                        </SmallTitle>
                                                    </div>
                                                    <div className={classes.capacityValueContainer}>
                                                        <NumericDisplayEmphasis>
                                                            {getCapacityUnitsText()}
                                                        </NumericDisplayEmphasis>
                                                    </div>
                                                </>
                                            )}
                                            <div data-testid="abc">
                                                {showChangeUnitsComponents ? (
                                                    <Button
                                                        appearance="secondary"
                                                        onClick={() =>
                                                            setChangeCapacityDialogOpen(true)
                                                        }
                                                        ref={changeCapacityButtonRef}
                                                        disabled={isGetCapacityNameFetching}
                                                    >
                                                        {tCommon(ButtonLabels.CHANGE_CAPACITY)}
                                                    </Button>
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            {isWorkspacesTestingEnabled && (
                                                <>
                                                    <div>
                                                        <SmallTitle>
                                                            {tAdmin(WORKSPACE_USAGE)}
                                                        </SmallTitle>
                                                    </div>
                                                    <div className={classes.capacityValueContainer}>
                                                        <NumericDisplayEmphasis>
                                                            {workspaceDisplayName}
                                                        </NumericDisplayEmphasis>
                                                    </div>
                                                    <Button
                                                        appearance="secondary"
                                                        onClick={() =>
                                                            navigate('/manage-workspaces')
                                                        }
                                                        ref={manageWorkspacesButtonRef}
                                                        disabled={isGetCapacityNameFetching}
                                                    >
                                                        {tAdmin(
                                                            'ManageWorkspaces.MenuButtons.ManageWorkspaces',
                                                        )}
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                        <div className={classes.actionBtnsV2}>
                                            <div className={classes.leftControlsElement}>
                                                {/* The 'isVisible' prop is used to conditionally hide the component without resetting the state to default of Last24Hours*/}
                                                <DateFilterDropdownV2
                                                    className={classes.dateFilterDropdown}
                                                    isVisible={
                                                        !pageLoading && !isCapacityUsageError
                                                    }
                                                    onOptionChanged={dateRangeOptionChanged}
                                                    selectedOptionText={dateRangeOption}
                                                    minSearchStartDate={
                                                        CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE.LAST_90_DAYS
                                                    }
                                                />
                                                {enableUsageDataAlignment &&
                                                    !isCapacityUsageError && (
                                                        <div className={classes.rightAlign}>
                                                            <SelectedFilterPillsSection
                                                                copilotExperienceMap={
                                                                    copilotExperienceMap as KeyValueFilterDetails[]
                                                                }
                                                                isHourlyBarActive={
                                                                    state.isHourlyBarActive
                                                                }
                                                                isEvaluationAggregateDataLoading={
                                                                    isEvaluationAggregateDataLoading
                                                                }
                                                                searchFilters={state.searchFilters}
                                                                shouldFocusFilterButton={
                                                                    shouldFocusFilterButton
                                                                }
                                                                onFiltersUpdated={(filters) => {
                                                                    updateFilters(filters);
                                                                }}
                                                                onFilterButtonClick={() => {
                                                                    setIsFilterOpen(true);
                                                                    setShouldFocusFilterButton(
                                                                        false,
                                                                    );
                                                                }}
                                                            ></SelectedFilterPillsSection>
                                                        </div>
                                                    )}
                                                {!pageLoading &&
                                                    !isCapacityUsageError &&
                                                    isCapacityUsageDataPresent && (
                                                        <>
                                                            <UsageDashboardFilterContainer
                                                                filterData={
                                                                    facetDataModel?.value ?? []
                                                                }
                                                                isOpen={isFilterOpen}
                                                                onFilterClose={() => {
                                                                    setIsFilterOpen(false);
                                                                    setShouldFocusFilterButton(
                                                                        true,
                                                                    );
                                                                }}
                                                                onFiltersUpdate={(filters) => {
                                                                    setShouldFocusFilterButton(
                                                                        true,
                                                                    );
                                                                    updateFilters(filters);
                                                                }}
                                                                shouldResetFilters={
                                                                    state.pristineSearchFilters
                                                                }
                                                                selectedFilters={convertFacetsToSelectedFilters(
                                                                    state.searchFilters,
                                                                )}
                                                            ></UsageDashboardFilterContainer>
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={getApiStatusClasses()}>
                                        {shouldShowLoadingState ? (
                                            <motion.div
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                variants={transitionVariant}
                                                key="ParentContainer"
                                            >
                                                <Spinner
                                                    labelPosition="below"
                                                    aria-live="polite"
                                                    aria-label={tAdmin(USAGE_DATA_LOADING)}
                                                    size="medium"
                                                    label={`${tAdmin(USAGE_DATA_LOADING)}`}
                                                />
                                            </motion.div>
                                        ) : isCapacityUsageError ? (
                                            <ApiResponseDisplay
                                                requiresFullWidth={true}
                                                title={tAdmin(ApiResponseDisplays.ERROR.title)}
                                                subtitle={tAdmin(
                                                    ApiResponseDisplays.ERROR.subtitle,
                                                )}
                                                onRefreshButtonClick={onPageRefresh}
                                                isError
                                            />
                                        ) : (
                                            !isCapacityUsageDataPresent && (
                                                <ApiResponseDisplay
                                                    requiresFullWidth={true}
                                                    title={tAdmin(
                                                        enableUsageDataAlignment
                                                            ? ApiResponseDisplays.NO_DATA_V2.title
                                                            : ApiResponseDisplays.NO_DATA.title,
                                                    )}
                                                    subtitle={tAdmin(
                                                        enableUsageDataAlignment
                                                            ? ApiResponseDisplays.NO_DATA_V2
                                                                  .subtitle
                                                            : ApiResponseDisplays.NO_DATA.subtitle,
                                                    )}
                                                    isError={false}
                                                />
                                            )
                                        )}
                                    </div>
                                    <AnimatePresence mode="wait">
                                        {isCapacityUsageDataPresent &&
                                            !pageLoading &&
                                            !isCapacityUsageError &&
                                            (enableUsageDataAlignment
                                                ? !shouldRefreshFullVisualization
                                                : true) && (
                                                <>
                                                    <motion.div
                                                        initial="initial"
                                                        animate="animate"
                                                        exit="exit"
                                                        variants={transitionVariant}
                                                        key="ParentContainer"
                                                    >
                                                        <VStackedBarChart
                                                            version="v2"
                                                            data={hourlyDataModel?.value ?? []}
                                                            onGraphBarClick={(
                                                                startDate,
                                                                endDate,
                                                            ) => {
                                                                drillDownByDateRange(
                                                                    startDate,
                                                                    endDate,
                                                                );
                                                            }}
                                                            onGraphBarBlur={() => {
                                                                if (!enableUsageDataAlignment) {
                                                                    drillDownByDateRange(
                                                                        filterStartDate,
                                                                        new Date(),
                                                                    );
                                                                } else {
                                                                    removeDateRangeDrillDown(
                                                                        filterStartDate,
                                                                        new Date(),
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </motion.div>
                                                    <DataTableV2
                                                        shouldScrolltoTableOnDataRefresh={
                                                            (!enableUsageDataAlignment &&
                                                                shouldDataTableScrollToViewOnReload) ||
                                                            (enableUsageDataAlignment &&
                                                                !state.needsHourlyDataRefresh &&
                                                                state.needsDimensionalDataRefresh)
                                                        }
                                                        data={dimensionalDataModel?.value ?? []}
                                                        onPageNumberChange={(pageNumber) => {
                                                            //Code to subscribe to the page number change will come in a future PR
                                                            updatePageNumber(pageNumber);
                                                            setIsFilterUpdated(false);
                                                        }}
                                                        // The actual total page count will come in a forthcoming PR
                                                        totalDataCount={
                                                            dimensionalDataModel?.count ?? 0
                                                        }
                                                        resetPagination={isFilterUpdated}
                                                        isTableLoading={
                                                            isEvaluationAggregateDataLoading
                                                        }
                                                        sortDirection={state.sortDirection}
                                                        onDateSortChanging={async (
                                                            sortDirection,
                                                        ) => {
                                                            updateSortDirection(sortDirection);
                                                        }}
                                                        currentPage={state.pageNumber}
                                                        shouldFocusFilterButton={
                                                            shouldFocusFilterButton
                                                        }
                                                        onFilterButtonClick={() => {
                                                            setIsFilterOpen(true);
                                                            setShouldFocusFilterButton(false);
                                                        }}
                                                        shouldShowFilterButton={
                                                            !enableUsageDataAlignment &&
                                                            areSearchFacetsAvailable
                                                        }
                                                        selectedFilters={state.searchFilters}
                                                        updateSearchFilters={(filters) => {
                                                            updateFilters(filters);
                                                        }}
                                                        copilotExperienceMap={
                                                            copilotExperienceMap as KeyValueFilterDetails[]
                                                        }
                                                        enableUsageDataAlignment={
                                                            enableUsageDataAlignment
                                                        }
                                                    />
                                                </>
                                            )}
                                    </AnimatePresence>
                                    {showChangeUnitsComponents && (
                                        <ChangeCapacity
                                            isChangeCapacityDialogOpen={isChangeCapacityDialogOpen}
                                            onChangeCapacityDialogClose={
                                                performActionsOnCapacityDialogClosure
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </GridContainer>
                </>
            )}
        </>
    );
}
